import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DescriptionButton } from 'common/components/DescriptionButton';
import { DescriptionBox } from 'common/components/DescriptionBox';
import { IOfferEventDateInput } from 'graphql/types';
import { TOfferEventFormValues } from 'offer/types';
import { LocationSelectField } from 'common/components/LocationSelectField';
import { MultipleDateRangeFields } from 'common/components/MultipleDateRangeFields';
import { Switch, LinkField } from 'common/components/FormField';

interface IProps {
    hasServiceProvider?: boolean;
}

export const MeetingFormFields = ({ hasServiceProvider }: IProps) => {
    const [webinarDescriptionOpen, setWebinarDescriptionOpen] = useState(false);
    const [translate] = useTranslation();
    const { errors } = useFormikContext<TOfferEventFormValues>();

    return (
        <>
            <MultipleDateRangeFields
                extraFields={(
                    dateFieldName,
                    index,
                    { startDate, endDate, isServiceProviderWebinar }
                ) => {
                    // Because of an issue with the FormikError type we need to cast this to the correct type
                    const dateErrors = errors?.dates?.[
                        index
                    ] as unknown as Record<
                        keyof IOfferEventDateInput,
                        string | undefined
                    >;

                    const hasError = !!(
                        dateErrors?.startDate || dateErrors?.endDate
                    );

                    const availabilityFilters =
                        hasServiceProvider && !hasError && startDate && endDate
                            ? {
                                  availabilityStartDate: startDate.toISO(),
                                  availabilityEndDate: endDate.toISO(),
                              }
                            : undefined;

                    return (
                        <>
                            <LocationSelectField
                                allowCreateString
                                allowRefetch={!hasError}
                                availabilityFilters={availabilityFilters}
                                label={translate('location')}
                                name={`${dateFieldName}.location`}
                            />

                            {!!hasServiceProvider && (
                                <>
                                    <Grid container alignItems="center">
                                        <Grid item sx={{ mr: -1 }}>
                                            <Field
                                                checked={
                                                    isServiceProviderWebinar
                                                }
                                                component={Switch}
                                                label={translate(
                                                    'offer.teamsMeeting'
                                                )}
                                                name={`${dateFieldName}.isServiceProviderWebinar`}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DescriptionButton
                                                onClick={() =>
                                                    setWebinarDescriptionOpen(
                                                        !webinarDescriptionOpen
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <DescriptionBox
                                        open={webinarDescriptionOpen}
                                        onClose={() =>
                                            setWebinarDescriptionOpen(false)
                                        }
                                    >
                                        {translate(
                                            'offer.teamsMeetingDescription'
                                        )}
                                    </DescriptionBox>
                                </>
                            )}

                            {(!hasServiceProvider ||
                                !isServiceProviderWebinar) && (
                                <LinkField
                                    label={translate('webinarLink')}
                                    name={`${dateFieldName}.webinarLink`}
                                />
                            )}
                        </>
                    );
                }}
                extraFieldsInitialValues={{
                    location: null,
                    webinarLink: '',
                    isServiceProviderWebinar: hasServiceProvider,
                }}
            />
        </>
    );
};
