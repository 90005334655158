import { Stack, SxProps } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { TableCell } from 'common/components/Table';
import { UserAvatar } from 'user/components/UserAvatar';
import { Typography } from 'common/components/Typography';
import { Chip } from 'common/components/Chip';
import { CircularProgress } from 'common/components/CircularProgress';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { ITrainingUserEdgeFragment } from 'graphql/types';
import { withSxProp } from 'common/utils/props';

interface IProps {
    participant: ITrainingUserEdgeFragment;
    canManageParticipant: boolean;
    nameCellSx?: SxProps;
    scoreCellSx?: SxProps;
}

export const TrainingParticipantSummaryCells = ({
    participant,
    canManageParticipant,
    nameCellSx,
    scoreCellSx,
}: IProps) => {
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const {
        portfolioItemId,
        node: user,
        score,
        scoreThreshold,
        progress,
    } = participant;

    const renderParticipantTrainingScore = () => {
        if (!canManageParticipant) return null;

        const showScore = progress === 1 && score;

        if (showScore) {
            return (
                <Chip
                    hover
                    bgColor={
                        !scoreThreshold || score >= scoreThreshold
                            ? 'success'
                            : 'error'
                    }
                    label={`${Math.round(score * 100)}%`}
                />
            );
        }

        if (!!progress) {
            return (
                <CircularProgress
                    filled
                    hover
                    showTooltip
                    bgColor="grey.200"
                    color="inherit"
                    size={28}
                    value={Math.round(progress * 100)}
                    variant="determinate"
                />
            );
        }

        return <Chip hover sx={{ backgroundColor: 'grey.200' }} />;
    };

    const openParticipantDrawer = () => {
        if (!canManageParticipant) {
            openGlobalDrawer({
                type: IGlobalDrawerType.UserProfile,
                itemId: user.id,
            });

            return;
        }

        if (!portfolioItemId) return;

        openGlobalDrawer(
            {
                type: IGlobalDrawerType.PortfolioItem,
                itemId: portfolioItemId,
            },
            location
        );
    };

    return (
        <>
            <TableCell
                sx={[
                    {
                        maxWidth: 300,
                        cursor: 'pointer',
                        borderRight: '1px solid',
                        borderColor: 'divider',
                    },
                    ...withSxProp(nameCellSx),
                ]}
                onClick={openParticipantDrawer}
            >
                <Stack alignItems="center" direction="row" spacing={2}>
                    <UserAvatar size={45} user={user} />
                    <Typography
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}
                        variant="body2"
                    >
                        {user.name}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell
                align="center"
                sx={[
                    {
                        cursor: 'pointer',
                        pl: 3,
                        width: 0,
                    },
                    ...withSxProp(scoreCellSx),
                ]}
                onClick={openParticipantDrawer}
            >
                {renderParticipantTrainingScore()}
            </TableCell>
        </>
    );
};
