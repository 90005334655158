import { Node as SlateNode } from 'slate';
import { jsx } from 'slate-hyperscript';

import {
    IBlockJSON,
    IDocumentJSON,
    IInlineJSON,
    ILeafJSON,
    IMarkJSON,
    TNodeJSON,
    TAttr,
} from 'common/components/ContentEditor/types';

import { options } from '../options';

const COMPONENTS: Record<string, (el: TNodeJSON, attr: TAttr) => TAttr> = {
    table: (el, attr) => ({
        ...attr,
        // type: options.table.type,
        type: 'table',
    }),
    'table-header': (el, attr) => ({
        ...attr,
        type: 'thead',
    }),
    'table-body': (el, attr) => ({
        ...attr,
        type: 'tbody',
    }),
    'table-row': (el, attr) => ({
        ...attr,
        type: options.tr.type,
    }),
    'table-cell': (el, attr) => ({
        ...attr,
        type: options.td.type,
    }),
    ol_list: (el, attr) => ({
        ...attr,
        type: options.ol.type,
    }),
    ul_list: (el, attr) => ({
        ...attr,
        // type: options.ul.type,
        type: 'ul',
    }),
    list_item: (el, attr) => ({
        ...attr,
        type: options.li.type,
    }),
    image: (el, attr) => ({
        ...attr,
        type: options.img.type,
    }),
    link: (el, { href, ...attr }) => ({
        ...attr,
        url: href,
        type: options.link.type,
    }),
    heading: (el, { level }) => ({
        type: `h${level}`,
    }),
    paragraph: (el, attr) => ({
        ...attr,
        type: options.p.type,
        variant: 'body1',
    }),
    embed: (el, attr) => ({
        ...attr,
        type: options.media_embed.type,
    }),
    file: (el, attr) => ({
        ...attr,
        type: options.file.type,
    }),
};

const LEAVES: Record<string, (el: IMarkJSON) => TAttr> = {
    bold: (el) => ({
        ...el.data,
        bold: true,
    }),
    italic: (el) => ({
        ...el.data,
        italic: true,
    }),
    underline: (el) => ({
        ...el.data,
        underline: true,
    }),
};

const getAttributes = (el: IDocumentJSON | IBlockJSON | IInlineJSON): TAttr => {
    const attributes: TAttr = {
        ...el.data,
    };

    const nodeName =
        el.object === 'block' || el.object === 'inline'
            ? el.type
            : el.object || 'document';

    if (COMPONENTS[nodeName]) {
        return COMPONENTS[nodeName](el, attributes);
    }

    return attributes;
};

const convertLeaf = (node: ILeafJSON): SlateNode => {
    const text = jsx('text', {}, [node.text]);
    if (!node.marks?.length) return text;

    return node.marks.reduce((el, mark) => {
        const attrs = LEAVES[mark.type] ? LEAVES[mark.type](mark) : mark.data;

        return jsx('text', attrs, el);
    }, text);
};

export const convertJSON = (node: TNodeJSON): SlateNode | SlateNode[] => {
    if (node.object === 'text') {
        return node.leaves.map(convertLeaf);
    }

    const attrs = getAttributes(node);

    let children = node.nodes?.map(convertJSON) || [];

    const tagName = node.object === 'document' ? 'fragment' : 'element';

    if (children.length) {
        const firstChild = children[0] as SlateNode;

        // When we get a tbody element we want to remove it by replacing the children
        if (firstChild['type'] === 'tbody') {
            children = firstChild.children as typeof children;
        }
    } else {
        children.push({ text: '' });
    }

    return jsx(tagName, attrs, children);
};
