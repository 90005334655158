import { useEffect, useMemo } from 'react';

import {
    IUserListItemFragment,
    ITrainerSelectorQueryVariables,
    useTrainerSelectorLazyQuery,
    IUserSelectorInput,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export interface IUserList {
    trainers: IUserListItemFragment[];
    alreadyAddedTrainers: string[];
    trainersCount?: number | null;
    loading: boolean;
}

export const useTrainerList = (
    trainerQueryVariables?: ITrainerSelectorQueryVariables,
    skip?: boolean,
    selectorQueryVariables?: IUserSelectorInput
): IUserList => {
    const { showApolloError } = useApolloError();
    const [fetchTrainers, { loading, data }] = useTrainerSelectorLazyQuery({
        onError: showApolloError,
    });

    useEffect(() => {
        if (!trainerQueryVariables || skip) return;

        fetchTrainers({
            variables: {
                q: trainerQueryVariables.q,
                offset: trainerQueryVariables.offset,
                ...trainerQueryVariables,
                selector: selectorQueryVariables,
            },
        });
    }, [trainerQueryVariables, selectorQueryVariables, fetchTrainers, skip]);

    const trainersObject = data?.trainers;
    const trainersCount = trainersObject?.count;
    const trainers =
        (trainersObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IUserListItemFragment[]) || [];

    const alreadyAddedTrainers = useMemo(
        () =>
            trainersObject?.edges
                .map((edge) => {
                    if (edge?.alreadyAddedToObject) return edge.node.id;

                    return null;
                })
                .filter(Boolean) || [],
        [trainersObject]
    );

    return {
        trainers,
        alreadyAddedTrainers,
        trainersCount,
        loading,
    };
};
