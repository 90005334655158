import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import SCORM_MODULE_DRAWER_HEADER from 'assets/images/scorm-module-drawer-header.webp';
import { IScormListItemFragment } from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { Button } from 'common/components/Button';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import { Loader } from 'common/components/Loader';
import { ScormFilterAddList } from 'training/components/ScormFilterAddList';

interface IProps {
    edit?: boolean;
    open?: boolean;
    inDrawer?: boolean;
    onClose?(): void;
    onClickNew?(): void;
    onSelect?(scorm: IScormListItemFragment): void;
}

export const ScormModuleSelectDrawer = ({
    edit,
    inDrawer = true,
    open,
    onClose,
    onClickNew,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();

    const titleCreate = translate(
        `moduleForm.scorm.${edit ? 'editTitleCreate' : 'newTitleCreate'}`
    );
    const textCreate = translate(
        `moduleForm.scorm.${edit ? 'editTextCreate' : 'newTextCreate'}`
    );

    const titleSelect = translate(
        `moduleForm.scorm.${edit ? 'editTitleSelect' : 'newTitleSelect'}`
    );
    const textSelect = translate(
        `moduleForm.scorm.${edit ? 'editTextSelect' : 'newTextSelect'}`
    );

    const drawerContent = open ? (
        <>
            <DrawerHeaderImage src={SCORM_MODULE_DRAWER_HEADER} />

            <Box p={{ xs: 2, sm: 4 }}>
                <Box mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleCreate}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textCreate}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Button
                            color="primary"
                            component="span"
                            variant="contained"
                            onClick={onClickNew}
                        >
                            {translate('moduleForm.scorm.createNewScormButton')}
                        </Button>
                    </Box>
                </Box>

                <Divider />

                <Box my={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleSelect}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textSelect}</Typography>
                    </Box>
                </Box>

                <ScormFilterAddList
                    listItemProps={{ px: 0, showActions: true }}
                    selectMultiple={false}
                    onSelect={onSelect}
                />
            </Box>
        </>
    ) : (
        <Loader />
    );

    if (!inDrawer) return drawerContent;

    return (
        <PageDrawer open={open} onClose={onClose}>
            {drawerContent}
        </PageDrawer>
    );
};
