import { useTranslation } from 'react-i18next';

import {
    useCreateArticleMutation,
    ICreateLibraryArticleInput,
    CollectionArticleFragmentDoc,
} from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { LibraryArticleForm } from 'library/components/forms/LibraryArticleForm';
import { ApolloError } from 'common/components/ApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

interface IProps {
    pageTitle?: string;
    open?: boolean;
    parentId: string;
    onCreate?(articleId: string): void;
    onClose?(): void;
}

export const LibraryArticleCreateDrawer = ({
    pageTitle,
    open,
    parentId,
    onCreate,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();

    const [createArticle, { error: createError }] = useCreateArticleMutation({
        update: (cache, { data }) => {
            const newArticle = data?.createLibraryArticle?.article;

            if (!newArticle) return;

            // Add new article to article list in collection
            cache.modify({
                id: `LibraryCollection:${parentId}`,
                fields: {
                    articles(cachedArticleRefs) {
                        const newArticleRef = cache.writeFragment({
                            id: cache.identify(newArticle),
                            fragment: CollectionArticleFragmentDoc,
                            data: newArticle,
                        });

                        return [...cachedArticleRefs, newArticleRef];
                    },
                },
            });

            displaySnackbar(translate('libraryArticleActionSuccess.create'), {
                variant: 'success',
            });

            onCreate && onCreate(newArticle.id);
        },
    });

    const initialValues = {
        title: '',
        description: '',
    };

    return (
        <PageDrawer
            open={open}
            title={translate('newArticle')}
            onClose={onClose}
        >
            {createError && <ApolloError error={createError} />}

            {pageTitle && (
                <PageTitle mixpanelTitle="New article - Library">{`${pageTitle} - ${translate(
                    'newArticle'
                )}`}</PageTitle>
            )}

            <LibraryArticleForm
                initialValues={initialValues}
                onSubmit={(values) => {
                    const newArticle = {
                        ...values,
                        collectionId: parentId,
                    } as ICreateLibraryArticleInput;

                    return createArticle({
                        variables: {
                            article: newArticle,
                        },
                    });
                }}
            />
        </PageDrawer>
    );
};
