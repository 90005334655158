import React from 'react';
import { Box } from '@mui/material';

import { ICourse } from 'graphql/types';
import { CourseListItem } from 'training/components/CourseListItem';

import { PortfolioItemContentBase } from './PortfolioItemContentBase';

export const CoursePortfolioItemContent = (
    props: React.ComponentProps<typeof PortfolioItemContentBase>
) => {
    const { portfolioItem } = props;

    const course = portfolioItem.relatedObject as ICourse;
    const itemUserId = portfolioItem.user?.id;

    return (
        <PortfolioItemContentBase {...props}>
            <Box px={4} py={2}>
                <CourseListItem
                    hideChips
                    linkToTrainerView
                    course={course}
                    forUserId={itemUserId}
                />
            </Box>
        </PortfolioItemContentBase>
    );
};
