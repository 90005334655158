import { useState, useEffect, useMemo } from 'react';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Embed as WendEmbed, IEmbedData, IEmbedConfig } from '@wend/embed';
import { mdiChevronRight, mdiCompass } from '@mdi/js';
import { Skeleton } from '@mui/material';

import { Icon } from 'common/components/Icon';
import { List } from 'common/components/List';
import {
    ListItem,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
    ListItemActionText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link';

interface IProps {
    src: string;
    className?: string;
    onError?(): void;
    autoplay?: boolean;
}

const BaseEmbed = ({ src, className, autoplay, onError }: IProps) => {
    const [translate] = useTranslation();

    const [embedData, setEmbedData] = useState<IEmbedData | undefined>();
    const [errorState, setErrorState] = useState<boolean>(false);

    const embed = useMemo(() => {
        const embedConfig: IEmbedConfig = {
            embedlyConfig: {
                key: import.meta.env.VITE_EMBEDLY_KEY || '',
            },
            providerConfig: {
                vimeo: {
                    token: import.meta.env.VITE_VIMEO_TOKEN,
                    autoplay,
                },
                vimeoDirect: {
                    autoplay,
                },
                pluvo: {
                    token: import.meta.env.VITE_VIMEO_TOKEN,
                },
                googleDrive: {
                    apiKey: import.meta.env.VITE_GOOGLE_DRIVE_KEY,
                },
                youtube: {
                    autoplay,
                },
                bunnyStream: {
                    autoplay,
                },
            },
        };

        return new WendEmbed(embedConfig);
    }, [autoplay]);

    useEffect(() => {
        const handleError = () => {
            setErrorState(true);
            onError && onError();
        };

        // Did cancel will be used to "cancel" setting data when the component is destroyed
        let didCancel = false;

        if (!src) {
            handleError();

            return;
        }

        async function getData() {
            let data;

            try {
                data = await embed.getData(src);
            } catch {
                handleError();

                return;
            }

            if (!didCancel) {
                if (!data) {
                    handleError();

                    return;
                }

                setEmbedData(data);
            }
        }

        getData();

        return () => {
            didCancel = true;
        };
    }, [src, embed, onError]);

    if (errorState) {
        return <>{translate('embed.error')}</>;
    }

    if (!embedData) {
        return <Skeleton height={150} variant="rectangular" />;
    }

    const { thumbnailUrl, html, title, description, type } = embedData;

    if (html) {
        if (type === 'video') {
            className = `${className} embed-responsive`;
        }

        return (
            <div
                className={className}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );
    }

    return (
        <List>
            <ListItem button component={Link} href={src} target="_blank">
                <ListItemMedia color="primary" image={thumbnailUrl}>
                    <Icon path={mdiCompass} />
                </ListItemMedia>
                <ListItemText primary={title} secondary={description} />
                <ListItemActionText>{translate('open')}</ListItemActionText>
                <ListItemSecondaryAction>
                    <Icon path={mdiChevronRight} size="4rem" />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
};

export const Embed = styled(BaseEmbed)`
    min-width: 100%;
    width: 100%;

    iframe {
        width: 100%;
        max-width: 100%;
    }

    &.embed-responsive {
        position: relative;
        display: block;
        overflow: hidden;
        padding: 0;
        pointer-events: auto;
        background-color: ${({ theme }) => theme.palette.common.black};
        min-height: 150px;

        &::before {
            display: block;
            padding-top: 56.25%;
            content: '';
        }

        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            border: 0;
        }
    }
`;
