import { Box, SxProps, Theme } from '@mui/material';

import { withSxProp } from 'common/utils/props';

interface IProps {
    children: React.ReactNode;
    leftOffset?: number;
    rightOffset?: number;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
}

export const InlineStickyWrapper = ({
    children,
    leftOffset,
    fullWidth,
    sx,
}: IProps) => (
    <Box
        sx={[
            {
                display: 'inline-flex',
                flexDirection: 'column',
                position: 'sticky',
                left: leftOffset ? (theme) => theme.spacing(leftOffset) : 0,
            },
            fullWidth && {
                maxWidth: (theme) => `calc(100vw - ${theme.spacing(6)})`,
                // Calculate container width
                width: (theme) =>
                    `calc(${theme.breakpoints.values.md}px - ${theme.spacing(6)})`,
            },
            ...withSxProp(sx),
        ]}
    >
        {children}
    </Box>
);
