import { TextField as MuiTextField, TextFieldProps } from 'formik-mui';

import { withSxProp } from 'common/utils/props';

export const TextField = ({
    placeholder,
    sx,
    multiline,
    ...other
}: TextFieldProps) => {
    const inputLabelProps = !!placeholder ? { shrink: true } : undefined;

    return (
        <>
            <MuiTextField
                fullWidth
                InputLabelProps={inputLabelProps}
                margin="normal"
                multiline={multiline}
                placeholder={placeholder}
                variant="outlined"
                {...other}
                sx={[
                    // By default the input padding is 16.5, this gave some alignment issues,
                    // That's why we remove the .5 from the padding. Also multiline padding is different so we need set
                    // that to the root element.
                    !multiline
                        ? {
                              '.MuiOutlinedInput-input': {
                                  py: 2,
                              },
                          }
                        : {
                              '.MuiOutlinedInput-input': {
                                  minHeight: 80,
                              },
                              '.MuiOutlinedInput-root': {
                                  py: 2,
                              },
                          },
                    // Spread sx from props
                    ...withSxProp(sx),
                ]}
            />
        </>
    );
};
