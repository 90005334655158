import { useRef, useState } from 'react';
import { Grid, Box, MenuItem } from '@mui/material';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiCog, mdiPlus } from '@mdi/js';
import { Node as SlateNode } from 'slate';

import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import { BasePage } from 'hydra/pages/BasePage';
import { Typography } from 'common/components/Typography';
import {
    IOfferEventType,
    useOfferQuery,
    useOffersSettingsQuery,
    useUpdateOfferMutation,
} from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { ApolloError } from 'common/components/ApolloError';
import { ActionButton } from 'common/components/ActionButton';
import { Tooltip } from 'common/components/Tooltip';
import {
    PageHeaderBackButton,
    PageIntroHeader,
} from 'common/components/PageIntroHeader';
import { isUUID } from 'common/utils/isUUID';
import { getUrl } from 'route/utils/getUrl';
import { OfferEventsBox } from 'offer/components/OfferEventsBox';
import { ContentEditor } from 'common/components/ContentEditor';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import {
    ExitPageAlert,
    IExitPageAlertRef,
} from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { deserializeContent } from 'common/components/ContentEditor/utils/deserialize';
import { Button } from 'common/components/Button';
import { UpdateOfferDrawer } from 'offer/components/UpdateOfferDrawer';
import { useOfferPermissions } from 'offer/hooks/useOfferPermissions';
import { CreateOfferEventDrawer } from 'offer/components/CreateOfferEventDrawer';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { DropdownButton } from 'common/components/Button/DropdownButton';
import { goToOfferDetail, goToOfferEditContent } from 'offer/utils/goToRoutes';
import { Chip, ChipGroup } from 'common/components/Chip';
import { PageTitle } from 'common/components/PageTitle';
import { OfferPermissionsContext } from 'offer/contexts';
import { useApolloError } from 'common/hooks/useApolloError';

const filterType = {
    all: { option: 'all', label: 'offerEventFilter.all' },
    active: { option: 'active', label: 'offerEventFilter.active' },
    expired: { option: 'expired', label: 'offerEventFilter.expired' },
};

export const OfferPage = () => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const [offerContent, setOfferContent] = useState<SlateNode[]>();
    const [contentChanged, setContentChanged] = useState<boolean>(false);
    const [filter, setFilter] = useState(filterType.all.option);
    const editing = !!useRouteMatch('OFFER_EDIT_CONTENT');
    const prevDescription = useRef<SlateNode[]>();
    const exitAlertRef = useRef<IExitPageAlertRef>(null);
    const isEditPage = !!useRouteMatch('OFFER_EDIT');
    const isEventCreatePage = !!useRouteMatch('CREATE_OFFER_EVENT');
    const { showApolloError } = useApolloError();
    const [firstOfferEventType, setFirstOfferEventType] =
        useState<IOfferEventType>();

    const {
        data,
        loading: offerLoading,
        error,
    } = useOfferQuery({
        variables: { id },
        onCompleted: (data) => {
            const offer = data?.offer;

            if (!offer) return;

            // Set current offer description
            const description = deserializeContent(offer?.description);

            prevDescription.current = description;
            setOfferContent(description);
        },
    });
    const { data: offersSettingsData, loading: offersSettingsLoading } =
        useOffersSettingsQuery({ onError: showApolloError });
    const { publishElearning, publishMeetingWebinar, publishTraining } =
        offersSettingsData?.offersSettings || {};

    const loading = offerLoading || offersSettingsLoading;

    const offer = data?.offer;
    const permissions = offer?.permissions
        ? JSON.parse(offer?.permissions)
        : undefined;
    const { canUpdate = false } = permissions || {};

    const { currentUserIsAuthor } = useOfferPermissions();

    const [
        updateOffer,
        { loading: updateOfferLoading, error: updateOfferError },
    ] = useUpdateOfferMutation();

    if (!id) return null;

    if (!isUUID(id) || (!loading && !offer)) {
        return <Navigate to={getUrl('PAGE_NOT_FOUND')} />;
    }

    // Early return when loading or error
    if (loading || error) {
        return (
            <BasePage>
                {error && <ApolloError error={error} />}
                {loading && <Loader />}
            </BasePage>
        );
    }

    // Redirect if on editing route and user does not have the correct role to edit
    if (!canUpdate && (editing || isEditPage)) {
        return (
            <Navigate
                to={getUrl('OFFER_DETAIL', {
                    id,
                })}
            />
        );
    }

    if (!loading && !offer?.hasDetailPage) {
        return <Navigate to={getUrl('OFFER_LIST')} />;
    }

    const { title, image, titleColor } = offer || {};

    let headerColor = titleColor;

    if (!headerColor) {
        headerColor = image ? 'common.white' : undefined;
    }

    const headerBackButton = (
        <PageHeaderBackButton color={headerColor} to="OFFER_LIST" />
    );

    let header;

    const authorChip = currentUserIsAuthor && canUpdate && (
        <Box mb={1}>
            <ChipGroup>
                <Chip bgColor="secondary" label={translate('author')} />
            </ChipGroup>
        </Box>
    );

    const updateButtons = canUpdate && (
        <Grid item component={Box} display="flex">
            <Box mr={1}>
                <ActionButton
                    component={Link}
                    outlined={!image}
                    params={{ id }}
                    to={editing ? 'OFFER_DETAIL' : 'OFFER_EDIT_CONTENT'}
                    variant="extended"
                >
                    <>{translate(editing ? 'closeEditor' : 'edit')}</>
                </ActionButton>
            </Box>

            <Tooltip title={translate<string>('management')}>
                <Box>
                    <ActionButton
                        component={Link}
                        outlined={!image}
                        params={{ id }}
                        to="OFFER_EDIT"
                    >
                        <Icon path={mdiCog} />
                    </ActionButton>
                </Box>
            </Tooltip>
        </Grid>
    );

    if (image) {
        header = (
            <PageIntroHeader backButton={headerBackButton} image={image}>
                {authorChip}

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item>
                        <Box
                            alignItems="center"
                            component="span"
                            display="flex"
                            flexWrap="wrap"
                        >
                            <Box mr={1}>
                                <Box mb={1}>
                                    <Typography
                                        sx={{ color: headerColor }}
                                        variant="h1"
                                    >
                                        {title}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {updateButtons}
                </Grid>
            </PageIntroHeader>
        );
    }

    const handleSaveContent = () => {
        const newDescription = JSON.stringify(offerContent);

        updateOffer({
            variables: {
                id,
                offer: { description: newDescription },
            },
        });

        setContentChanged(false);

        // Set new version as prevVersion
        prevDescription.current = offerContent;

        // Manually call unblock to make sure it's unblocked before trying to redirect
        exitAlertRef.current && exitAlertRef.current.unblock();

        // Set editing to false after saving content
        setEditing(false);
    };

    const handleCancelSaveContent = async () => {
        await setContentChanged(false);

        // Reset content
        await setOfferContent(prevDescription.current);

        setEditing(false);
    };

    const setEditing = (editing: boolean) => {
        if (!editing) {
            goToOfferDetail(navigate, id);

            return;
        }

        goToOfferEditContent(navigate, id);
    };

    let eventExpiredFilter;

    if (!canUpdate) {
        eventExpiredFilter = false;
    } else if (filter !== filterType.all.option) {
        eventExpiredFilter = filter !== filterType.active.option;
    }

    const pageTitle = `${translate('offer.offersBrowserTitle')} - ${title}`;
    const canCreateNewEvent =
        canUpdate &&
        !loading &&
        (publishElearning || publishMeetingWebinar || publishTraining);

    return (
        <OfferPermissionsContext.Provider value={permissions}>
            <BasePage header={header}>
                {updateOfferError && <ApolloError error={updateOfferError} />}

                <PageTitle mixpanelTitle="Detail - Offers">
                    {pageTitle}
                </PageTitle>

                <Box py={4}>
                    {!header && (
                        <Box mb={2}>
                            {headerBackButton}

                            {authorChip}

                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="space-between"
                                spacing={2}
                            >
                                <Grid item>
                                    <Box mb={1}>
                                        <Typography
                                            sx={{
                                                color: headerColor,
                                            }}
                                            variant="h1"
                                        >
                                            {title}
                                        </Typography>
                                    </Box>
                                </Grid>

                                {updateButtons}
                            </Grid>
                        </Box>
                    )}

                    <Box mb={4}>
                        <ContentEditor
                            content={offerContent}
                            readOnly={!editing || !canUpdate}
                            onChange={(content) => {
                                setOfferContent(content);
                                setContentChanged(true);
                            }}
                        />

                        {editing && (
                            <Button
                                color="primary"
                                disabled={!contentChanged}
                                loading={updateOfferLoading}
                                variant="contained"
                                onClick={handleSaveContent}
                            >
                                {translate('save')}
                            </Button>
                        )}
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Box alignItems="center" display="flex">
                            {canCreateNewEvent && (
                                <Box alignItems="left" display="flex">
                                    <Tooltip
                                        title={translate<string>(
                                            'offer.newEvent'
                                        )}
                                    >
                                        <Box>
                                            <ActionButton
                                                outlined
                                                component={Link}
                                                params={{ id: offer?.id || '' }}
                                                size="medium"
                                                to="CREATE_OFFER_EVENT"
                                            >
                                                <Icon path={mdiPlus} />
                                            </ActionButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            )}

                            {canUpdate && (
                                <Box ml="auto">
                                    <DropdownMenu
                                        anchor={
                                            <DropdownButton>
                                                {translate(
                                                    filterType[
                                                        filter as keyof typeof filterType
                                                    ].label
                                                )}
                                            </DropdownButton>
                                        }
                                    >
                                        <MenuItem
                                            onClick={() =>
                                                setFilter(filterType.all.option)
                                            }
                                        >
                                            {translate(filterType.all.label)}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                setFilter(
                                                    filterType.active.option
                                                )
                                            }
                                        >
                                            {translate(filterType.active.label)}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                setFilter(
                                                    filterType.expired.option
                                                )
                                            }
                                        >
                                            {translate(
                                                filterType.expired.label
                                            )}
                                        </MenuItem>
                                    </DropdownMenu>
                                </Box>
                            )}
                        </Box>

                        <OfferEventsBox
                            expired={eventExpiredFilter}
                            offerId={offer?.id}
                            setFirstOfferEventType={setFirstOfferEventType}
                        />
                    </Box>

                    {offer && canUpdate && (
                        <UpdateOfferDrawer offer={offer} open={isEditPage} />
                    )}

                    {canCreateNewEvent && !!firstOfferEventType && (
                        <CreateOfferEventDrawer
                            offerEventType={firstOfferEventType}
                            open={isEventCreatePage}
                        />
                    )}

                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        ref={exitAlertRef}
                        when={editing && contentChanged}
                        onCancel={() => {
                            handleCancelSaveContent();

                            return true;
                        }}
                        onConfirm={async () => {
                            handleSaveContent();

                            return true;
                        }}
                    />
                </Box>
            </BasePage>
        </OfferPermissionsContext.Provider>
    );
};
