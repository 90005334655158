import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import { useGroupManagersList } from 'user/hooks/group/useGroupManagersList';
import {
    GroupListItemFragmentDoc,
    IContentTypeValue,
    IUserSelectorRole,
    IGroupFragment,
    IGroupUsersQueryVariables,
    useAddManagersToGroupMutation,
    useRemoveManagersFromGroupMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useUserTableData } from 'user/hooks/useUserTableData';

import { UserSelector } from '../UserSelector';

interface IProps {
    group: IGroupFragment;
}

export const ManagersView = ({ group }: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const client = useApolloClient();

    const { id: groupId, permissions } = group;

    const {
        loading: loadingManagers,
        users: managers,
        tableDataProps,
    } = useUserTableData<IGroupUsersQueryVariables>(
        [],
        useGroupManagersList,
        undefined,
        { id: groupId }
    );
    const [addManagersToGroup, { loading: loadingAddManagers }] =
        useAddManagersToGroupMutation();
    const [removeManagersFromGroup, { loading: loadingRemoveUsers }] =
        useRemoveManagersFromGroupMutation();

    const handleAddManagersToGroup = async (selectedUsers: string[]) => {
        try {
            await addManagersToGroup({
                variables: {
                    groupId,
                    userIds: selectedUsers,
                },
                update: (cache, result) => {
                    const { succeededIds } =
                        result.data?.addManagersToGroup?.response || {};

                    if (!succeededIds?.length) return;

                    // Change group user count
                    cache.updateFragment(
                        {
                            id: `Group:${groupId}`,
                            fragment: GroupListItemFragmentDoc,
                            fragmentName: 'GroupListItem',
                        },
                        (data) => ({
                            ...data,
                            managerCount:
                                data.managerCount + succeededIds.length,
                        })
                    );

                    // Refetch the selector to make sure the correct users are selected
                    cache.evict({ fieldName: 'paginatedUsers' });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        client.refetchQueries({ include: ['GroupManagers'] });

        return;
    };

    const handleRemoveManagersFromGroup = async (selectedUsers: string[]) => {
        try {
            await removeManagersFromGroup({
                variables: {
                    groupId,
                    userIds: selectedUsers,
                },
                update: (cache, result) => {
                    const { succeededIds } =
                        result.data?.removeManagersFromGroup?.response || {};

                    if (!succeededIds?.length) return;

                    // Change group user count
                    cache.updateFragment(
                        {
                            id: `Group:${groupId}`,
                            fragment: GroupListItemFragmentDoc,
                            fragmentName: 'GroupListItem',
                        },
                        (data) => ({
                            ...data,
                            managerCount:
                                data.managerCount - succeededIds.length,
                        })
                    );

                    // Refetch the selector to make sure the correct users are selected
                    cache.evict({ fieldName: 'paginatedUsers' });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        client.refetchQueries({ include: ['GroupManagers'] });

        return;
    };

    const loading = loadingManagers || loadingAddManagers;

    const permissionsObj = JSON.parse(permissions || '{}');
    const canAddManagers = !!permissionsObj?.canAddManagers;

    return (
        <UserSelector
            addButtonTranslationKeys={{
                noSelection: 'groupForm.addGroupManagers',
                selection: 'groupForm.addGroupManagersWithCount',
            }}
            addedUsers={managers}
            deleteDialogProps={{
                title: translate('groupForm.managerDeleteDialog.title'),
                text: translate('groupForm.managerDeleteDialog.text'),
            }}
            isEditable={canAddManagers}
            label={translate('groupForm.addGroupManagers')}
            loading={loading}
            loadingRemove={loadingRemoveUsers}
            noResultsLabel={translate('noOptionsText.managers')}
            paginationTranslationKey="groupForm.groupManager"
            selectorQueryVariables={{
                contentType: IContentTypeValue.Group,
                objectId: groupId,
                objectRoles: [IUserSelectorRole.GroupManager],
            }}
            tableDataProps={tableDataProps}
            onAddUsers={handleAddManagersToGroup}
            onRemoveUsers={handleRemoveManagersFromGroup}
        />
    );
};
