import { useTheme, useMediaQuery } from '@mui/material';
import MdiIcon from '@mdi/react';

export interface IProps extends React.ComponentProps<typeof MdiIcon> {
    size?: string;
    sizeSm?: string;
}

export const Icon = ({ size, sizeSm, ...other }: IProps) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    let iconSize = size;

    if (sizeSm && isSm) iconSize = sizeSm;

    return <MdiIcon size={iconSize} {...other} />;
};
