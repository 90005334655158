import {
    ListItemButton as MuiListItemButton,
    ListItemButtonProps,
} from '@mui/material';

export const ListItemButton = (props: ListItemButtonProps) => (
    <MuiListItemButton
        sx={{ '&:hover': { backgroundColor: 'transparent' }, p: 0 }}
        {...props}
    />
);
