import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { ClipboardCopyInput } from 'common/components/FormField/ClipboardCopyInput';

export const ParameterForm = () => {
    const [translate] = useTranslation();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="openIdConnectionForm.parameterForm.parameterDescription"
                    />
                </Box>
            }
            title={translate(
                'openIdConnectionForm.parameterForm.parameterTitle'
            )}
        >
            <Box mt={1}>
                <Field
                    component={ClipboardCopyInput}
                    label={translate(
                        'openIdConnectionForm.parameterForm.authorizedJavascript'
                    )}
                    name="ltiAuthenticateUrl"
                />

                <Field
                    component={ClipboardCopyInput}
                    label={translate(
                        'openIdConnectionForm.parameterForm.authorizedRedirectURI'
                    )}
                    name="ltiLaunchUrl"
                />
            </Box>
        </FormSection>
    );
};
