import { IGlobalDrawerHandlers } from 'common/contexts/GlobalDrawerContext';
import {
    IGlobalDrawer,
    IGlobalDrawerActionType,
    IGlobalDrawerType,
} from 'common/types';
import { OfferDetailDrawer } from 'hydra/pages/OfferDetailDrawer';
import { OfferEventDetailDrawer } from 'offer/components/OfferEventDetailDrawer';
import { PortfolioItemDrawer } from 'user/components/PortfolioItemDrawer';
import { UserCreateDrawer } from 'user/components/UserCreateDrawer';
import { UserProfileDrawer } from 'user/components/UserProfileDrawer';
import { UserUpdateDrawer } from 'user/components/UserUpdateDrawer';
import {
    AssignmentDetailDrawer,
    AssignmentGradeDrawer,
} from 'training/components/AssignmentDrawer';
import { PortfolioSettingsDrawer } from 'user/components/PortfolioSettingsDrawer';
import { OfferSettingsDrawer } from 'offer/components/OfferSettingsDrawer';
import { GroupUpdateDrawer } from 'user/components/GroupUpdateDrawer';
import { SkillCreateDrawer } from 'skill/components/SkillCreateDrawer';
import { SkillUpdateDrawer } from 'skill/components/SkillUpdateDrawer';

interface IProps {
    drawer: IGlobalDrawer;
    closeGlobalDrawer: IGlobalDrawerHandlers['closeGlobalDrawer'];
    isBlocked?: boolean;
}

export const GlobalDrawer = ({ drawer, closeGlobalDrawer }: IProps) => {
    switch (drawer.type) {
        case IGlobalDrawerType.Offer: {
            return (
                <OfferDetailDrawer
                    offerId={drawer.itemId}
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    onClose={() => closeGlobalDrawer(drawer)}
                />
            );
        }

        case IGlobalDrawerType.OfferEvent: {
            return (
                <OfferEventDetailDrawer
                    offerEventId={drawer.itemId}
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    onClose={() => closeGlobalDrawer(drawer)}
                />
            );
        }

        case IGlobalDrawerType.PortfolioItem: {
            return (
                <>
                    <PortfolioItemDrawer
                        showUser
                        open={
                            drawer.actionType === IGlobalDrawerActionType.Open
                        }
                        portfolioItemId={drawer.itemId}
                        onClose={() => closeGlobalDrawer(drawer)}
                        {...drawer.props}
                    />
                </>
            );
        }

        case IGlobalDrawerType.UserCreate: {
            return (
                <UserCreateDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    onClose={() => closeGlobalDrawer(drawer)}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.UserProfile: {
            if (!drawer.itemId) return null;

            return (
                <UserProfileDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    userId={drawer.itemId}
                    onClose={() => closeGlobalDrawer(drawer)}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.UserUpdate: {
            if (!drawer.itemId) return null;

            return (
                <UserUpdateDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    userId={drawer.itemId}
                    onClose={() => closeGlobalDrawer(drawer)}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.TrainingAssignmentDetail: {
            return (
                <AssignmentDetailDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.TrainingAssignmentGrade: {
            return (
                <AssignmentGradeDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.PortfolioManagementSettings: {
            return (
                <PortfolioSettingsDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    onClose={() => closeGlobalDrawer(drawer)}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.OfferManagementSettings: {
            return (
                <OfferSettingsDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    onClose={() => closeGlobalDrawer(drawer)}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.Group: {
            return (
                <GroupUpdateDrawer
                    id={drawer.itemId}
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.SkillCreate: {
            return (
                <SkillCreateDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    {...drawer.props}
                />
            );
        }

        case IGlobalDrawerType.SkillUpdate: {
            return (
                <SkillUpdateDrawer
                    open={drawer.actionType === IGlobalDrawerActionType.Open}
                    skillId={drawer.itemId}
                    {...drawer.props}
                />
            );
        }

        default: {
            return null;
        }
    }
};
