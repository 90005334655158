import { useEffect, useState } from 'react';

import {
    IExtraCategoryFilterInput,
    IGroupsQueryVariables,
} from 'graphql/types';
import { DEFAULT_PAGINATION_SETTINGS } from 'common/hooks/usePagination';
import { TFilterBarItem } from 'common/types';
import {
    extraCategoryFiltersAreEqual,
    getExtraCategoryInputVariables,
} from 'organisation/utils/extraCategory';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
    selectedFilters: TFilterBarItem[];
}

export const useGroupQueryVariables = ({
    paginationVariables,
    searchQueryParam,
    selectedFilters,
}: IProps) => {
    const [groupQueryVariables, setGroupQueryVariables] = useState<
        IGroupsQueryVariables & {
            extraCategories?: IExtraCategoryFilterInput[];
        }
    >({
        q: '',
        ...DEFAULT_PAGINATION_SETTINGS,
    });

    useEffect(() => {
        const newGroupQueryVariables = {
            ...paginationVariables,
            q: searchQueryParam,
            extraCategories: getExtraCategoryInputVariables(selectedFilters),
        };

        const { q, offset, first, extraCategories } = groupQueryVariables;

        if (
            q === newGroupQueryVariables.q &&
            offset === newGroupQueryVariables.offset &&
            first === newGroupQueryVariables.first &&
            extraCategoryFiltersAreEqual(
                extraCategories || [],
                newGroupQueryVariables.extraCategories
            )
        ) {
            return;
        }

        setGroupQueryVariables({
            ...groupQueryVariables,
            ...newGroupQueryVariables,
            offset:
                q !== newGroupQueryVariables.q
                    ? 0
                    : newGroupQueryVariables.offset,
        });
    }, [
        groupQueryVariables,
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    ]);

    return { groupQueryVariables };
};
