import {
    Box,
    SxProps,
    LinearProgress as MuiLinearProgress,
    LinearProgressProps,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { Tooltip } from 'common/components/Tooltip';

interface IProps {
    label?: string;
    withProgressText?: boolean;
    loading?: boolean;
}

const progressBarStyle: SxProps = {
    height: 5,
    backgroundColor: 'grey.200',

    '&, > *': {
        borderRadius: 1,
    },
};

const progressTextStyle: SxProps = {
    fontSize: 14,
    color: 'text.secondary',
    lineHeight: 1.4,
};

export const LinearProgress = ({
    label,
    withProgressText,
    value,
    loading,
    ...other
}: LinearProgressProps & IProps) => {
    const [translate] = useTranslation();

    const content = (
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            {label && (
                <Box sx={{ mr: 1 }}>
                    <Typography sx={progressTextStyle}>{label}</Typography>
                </Box>
            )}
            <Box sx={{ flex: 1, height: 5 }}>
                <MuiLinearProgress
                    color="secondary"
                    sx={progressBarStyle}
                    value={value}
                    variant="determinate"
                    {...other}
                />
            </Box>
            {loading && (
                <Box sx={{ ml: 2 }}>
                    <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{
                            display: 'flex',
                            p: 0.25,
                        }}
                    />
                </Box>
            )}
            {!loading && withProgressText && (
                <Box sx={{ ml: 1 }}>
                    <Typography sx={progressTextStyle}>
                        {`${Math.round(value || 0)}%`}
                    </Typography>
                </Box>
            )}
        </Box>
    );

    // Add tooltip when loading
    if (loading) {
        return (
            <Tooltip title={translate<string>('recalculating')}>
                {content}
            </Tooltip>
        );
    }

    return content;
};
