import { useTranslation } from 'react-i18next';
import { mdiShieldCheck } from '@mdi/js';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import { Icon } from 'common/components/Icon';
import {
    ICertificateModuleListItemFragment,
    IConditionEffect,
    useTrainingQuery,
} from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import { Chip, ChipGroup } from 'common/components/Chip';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { CertificateDrawer } from 'common/components/CertificateDrawer';
import { gotoCertificate, gotoTrainingPage } from 'training/utils/gotoRoutes';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { ApolloError } from 'common/components/ApolloError';
import { formatDate } from 'common/utils/formatDate';
import { Link } from 'common/components/Link';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const CertificateModule = ({
    canManage,
    title,
    description,
    receivedCertificate,
    disabled,
    conditions,
    alwaysOpen,
    startDate,
    image,
    conditionEffect,
    groupConditionEffect,
    trainingId,
    id,
    canUpdate,
    hasProgress,
    portfolioItem,
}: TTrainingModuleProps<ICertificateModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);
    const navigate = useNavigate();
    const { certificateId } = useParams();
    const { data: trainingData, error: trainingError } = useTrainingQuery({
        variables: { id: trainingId },
    });

    const trainingTitle = trainingData?.training?.title || undefined;

    const drawerOpen =
        !!useRouteMatch('TRAINING_CERTIFICATE') &&
        !!receivedCertificate &&
        receivedCertificate.id === certificateId;

    const { file, thumbnail, isExpired } = receivedCertificate || {};

    const isDisabled = studentView && (disabled || !file?.url);
    const allConditionsFulfilled = conditionEffect === IConditionEffect.Nothing;

    let moduleDescription;

    if (!!receivedCertificate?.date) {
        moduleDescription = `${formatDate(receivedCertificate?.date, 'dd MMMM yyyy')}${
            receivedCertificate?.expiryDate
                ? ` - ${formatDate(
                      receivedCertificate?.expiryDate,
                      'dd MMMM yyyy'
                  )}`
                : ''
        }`;
    } else {
        moduleDescription = description;
    }

    const certificateDrawer = (
        <CertificateDrawer
            certificateId={certificateId}
            open={drawerOpen}
            title={title}
            trainingTitle={trainingTitle}
            onClose={() => gotoTrainingPage(navigate, trainingId)}
        />
    );

    const trainingErrorDisplay = trainingError && (
        <ApolloError error={trainingError} />
    );

    const itemContent = (
        <>
            <ListItemMedia
                color="primary"
                image={image?.url || thumbnail?.url || undefined}
            >
                <Icon path={mdiShieldCheck} size="1.8rem" sizeSm="3rem" />
            </ListItemMedia>
            <ListItemText primary={title} secondary={moduleDescription}>
                {!allConditionsFulfilled && (
                    <ModuleConditionsChips
                        alwaysOpen={alwaysOpen}
                        conditionEffect={conditionEffect}
                        conditions={conditions}
                        groupConditionEffect={groupConditionEffect}
                        startDate={startDate}
                    />
                )}
                {allConditionsFulfilled && (
                    <ChipGroup>
                        {studentView && isDisabled && (
                            <Chip label={translate('availableSoon')} />
                        )}
                        {isExpired && (
                            <Chip
                                bgColor="warning"
                                label={translate('expired')}
                            />
                        )}
                    </ChipGroup>
                )}
            </ListItemText>
        </>
    );

    if (isDisabled) {
        return (
            <>
                {trainingErrorDisplay}

                <ListItem disabled>{itemContent}</ListItem>

                {canManage && certificateDrawer}
            </>
        );
    }

    if (!studentView && canUpdate) {
        return (
            <>
                {trainingErrorDisplay}

                <ListItem
                    button
                    component={Link}
                    params={{
                        trainingId,
                        moduleId: id,
                        type: 'certificate',
                    }}
                    to="TRAINING_UPDATE_MODULE"
                >
                    {itemContent}

                    <ModuleSecondaryAction
                        forUpdate
                        hasProgress={!!hasProgress}
                        portfolioItem={portfolioItem}
                    />
                </ListItem>

                {certificateDrawer}
            </>
        );
    }

    return (
        <>
            {trainingErrorDisplay}

            <ListItem
                button
                onClick={() => {
                    gotoCertificate(
                        navigate,
                        trainingId,
                        receivedCertificate?.id
                    );
                }}
            >
                {itemContent}

                <ModuleSecondaryAction
                    actionText={translate('open')}
                    hasProgress={!!hasProgress}
                    portfolioItem={portfolioItem}
                />
            </ListItem>

            {certificateDrawer}
        </>
    );
};
