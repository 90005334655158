import React, {
    useRef,
    useState,
    useMemo,
    useCallback,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    DialogContentText,
    Grid,
    IconButton,
    MenuItem,
} from '@mui/material';
import { styled } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { mdiArrowDown } from '@mdi/js';

import {
    DiscussionFragmentDoc,
    ICommentFragment,
    useDiscussionQuery,
    useCreateCommentMutation,
    useCurrentUserQuery,
    useDeleteCommentMutation,
    useUpdatedCommentSubscription,
    useUpdateCommentMutation,
    CommentFragmentDoc,
    IFileFragment,
    ICommentWithAttachmentsFragment,
    CommentWithAttachmentsFragmentDoc,
    IDiscussionObjectType,
} from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { Loader } from 'common/components/Loader';
import { useElementScroll } from 'common/hooks/useElementScroll';

import { CommentForm, ICommentFormRef } from '../forms/CommentForm/CommentForm';
import { AlertDialog } from '../AlertDialog';
import { Button } from '../Button';
import { Icon } from '../Icon';

import { Comment } from './Comment';

interface IProps {
    className?: string;
    discussionId: string;
    parentRef?: React.RefObject<HTMLDivElement>; // Is used to check if drawer has scrolled to the bottom
    forType: IDiscussionObjectType;
    onAddFileComment?(file: IFileFragment): void;
    onAddComment?(comment: ICommentFragment): void;
    onDeleteComment?(): void;
}

const BaseDiscussionSection = ({
    className,
    discussionId,
    parentRef,
    forType,
    onAddFileComment,
    onAddComment,
    onDeleteComment,
}: IProps) => {
    const [translate] = useTranslation();
    const location = useLocation();
    const { data: currentUserData } = useCurrentUserQuery();
    const { data: discussionData, loading: loadingDiscussion } =
        useDiscussionQuery({
            variables: { id: discussionId, forType },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
        });
    const [editComment, setEditComment] = useState<ICommentFragment>();
    const [commentFormDisabled, setCommentFormDisabled] = useState(false);
    const commentFormRef = useRef<ICommentFormRef>(null);
    const latestCommentRef = useRef<HTMLDivElement>(null);
    const [linkedCommentRef, setLinkedCommentRef] = useState<HTMLDivElement>();
    const discussionWrapperRef = useRef<HTMLDivElement>(null);
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<{
        show: boolean;
        comment?: ICommentFragment;
    }>({
        show: false,
        comment: undefined,
    });
    const [commentFormIsFocused, setCommentFormIsFocused] = useState(false);
    const {
        hitBottom,
        getSpecificElementId,
        isLatestElementUrl,
        scrollToLatestElement,
        specificElementReached,
    } = useElementScroll(
        !!discussionData?.discussion?.comments?.length,
        translate('latest'),
        latestCommentRef,
        parentRef,
        linkedCommentRef
    );
    const [showCommentFormShadow, setShowCommentFormShadow] =
        useState(hitBottom);
    const [displayLatestButton, setDisplayLatestButton] = useState(false);
    const [createComment] = useCreateCommentMutation({
        fetchPolicy: 'no-cache',
    });
    const [updateComment] = useUpdateCommentMutation({
        fetchPolicy: 'no-cache',
    });
    const [deleteComment] = useDeleteCommentMutation({
        fetchPolicy: 'no-cache',
    });

    const currentUser = currentUserData?.currentUser;
    const { discussion } = discussionData || {};

    useUpdatedCommentSubscription({
        variables: {
            forType,
            discussionId: discussionId,
        },
        onData: ({ client, data: { data: newCommentsData } }) => {
            const { updatedComment } = newCommentsData || {};

            if (!updatedComment) return;

            if (
                updatedComment.__typename === 'UpdatedCommentSubscriptionType'
            ) {
                const { comment } = updatedComment;

                // Update comment data
                client.writeFragment({
                    id: client.cache.identify(updatedComment),
                    fragment: CommentFragmentDoc,
                    data: { ...comment },
                    fragmentName: 'Comment',
                });

                setEditComment(undefined);
                setCommentFormDisabled(false);

                return;
            }

            if (
                updatedComment.__typename === 'DeletedCommentSubscriptionType'
            ) {
                const { deletedCommentId } = updatedComment;

                const deletedCommentCacheId = `Comment:${deletedCommentId}`;
                const deletedComment: ICommentWithAttachmentsFragment | null =
                    client.cache.readFragment({
                        id: deletedCommentCacheId,
                        fragment: CommentWithAttachmentsFragmentDoc,
                        fragmentName: 'CommentWithAttachments',
                    });

                if (deletedComment) {
                    deletedComment.attachments.forEach((file) => {
                        const normalizedId = client.cache.identify(file);

                        // Remove file from cache
                        client.cache.evict({ id: normalizedId });
                        client.cache.gc();
                    });
                }

                // Remove comment from cache
                client.cache.evict({ id: deletedCommentCacheId });
                client.cache.gc();

                onDeleteComment?.();

                return;
            }

            if (!discussion) return;

            const { comment } = updatedComment;

            if (!comment) return;

            // Add new comment to the discussion if it's not empty
            if (comment.text) {
                const newComments = [...discussion.comments, comment];

                client.writeFragment({
                    id: client.cache.identify(discussion),
                    fragment: DiscussionFragmentDoc,
                    data: { ...discussion, comments: newComments },
                    fragmentName: 'Discussion',
                });
            }

            // When its a file comment all the subscribed users should be notified with a new file
            // so they can update their filelist accordingly
            if (comment?.attachments.length) {
                const attachment = comment?.attachments[0];
                onAddFileComment?.(attachment);
            } else {
                onAddComment?.(comment);
            }

            setCommentFormDisabled(false);

            const { current: commentForm } = commentFormRef;

            if (
                !commentForm ||
                (comment.user &&
                    currentUser &&
                    comment.user.id !== currentUser.id)
            ) {
                return;
            }

            commentForm.clear();
        },
    });

    /*
     * Checks if the url contains the hash to show a specific comment
     */
    const isSpecificUrl = useCallback(() => {
        const commentId = getSpecificElementId();

        if (!commentId) return;

        const isSpecificComment = !!discussion?.comments.filter(
            (comment) => comment.id === commentId
        ).length;

        return isSpecificComment;
    }, [discussion?.comments, getSpecificElementId]);

    const linkedCommentRefCallback = useCallback((node) => {
        if (!node) return;

        node.scrollIntoView({ behavior: 'smooth' });

        setLinkedCommentRef(node);
    }, []);

    const handleDelete = (comment: ICommentFragment) => {
        deleteComment({ variables: { id: comment.id } });
    };

    const handleEdit = (comment: ICommentFragment) => () => {
        setEditComment(comment);
    };

    const handleCancelEdit = () => {
        setCommentFormIsFocused(false);
        setEditComment(undefined);
        setLinkedCommentRef(undefined);
    };

    const handleSave = (value: string) => {
        setCommentFormDisabled(true);

        if (editComment) {
            updateComment({
                variables: {
                    id: editComment.id,
                    comment: { text: value },
                },
            });

            setLinkedCommentRef(undefined);

            return;
        }

        if (!discussion) return;

        scrollToLatestElement();

        createComment({
            variables: {
                forType,
                discussionId: discussion.id,
                comment: { text: value },
            },
        });
    };

    const sortedComments = useMemo(() => {
        // Sorted by date, latest first, also filter out the empty comments which contain only attachments
        const newComments = discussion
            ? [...discussion.comments.filter((comment) => comment.text !== '')]
            : [];

        return newComments.sort((a, b) => {
            const date1 = new Date(a.date);
            const date2 = new Date(b.date);

            return date1.getTime() - date2.getTime();
        });
    }, [discussion]);

    const handleFocusCommentForm = (isFocused: boolean, edit?: boolean) => {
        setCommentFormIsFocused(isFocused);

        if (!edit && isFocused) {
            setEditComment(undefined);
        }
    };

    /*
     * Makes sure when bottom is hit and not editing comment form is focused
     * it should correctly set the box shadow
     */
    useEffect(() => {
        if (!hitBottom || editComment) return;

        const { current } = parentRef || {};
        if (!current) return;

        const { scrollHeight, scrollTop, clientHeight } = current;

        const reachedBottom = scrollHeight - scrollTop <= clientHeight;

        if (commentFormIsFocused && !reachedBottom) {
            setShowCommentFormShadow(true);

            return;
        }

        if (!commentFormIsFocused && showCommentFormShadow) {
            setShowCommentFormShadow(false);
        }
    }, [
        commentFormIsFocused,
        parentRef,
        editComment,
        hitBottom,
        showCommentFormShadow,
    ]);

    useEffect(() => {
        const { current } = parentRef || {};

        if (!sortedComments.length && displayLatestButton) {
            setDisplayLatestButton(false);
        }

        if (!current || !sortedComments.length) return;

        const { scrollHeight, clientHeight } = current;

        setDisplayLatestButton(
            !!(scrollHeight && clientHeight && scrollHeight > clientHeight) ||
                sortedComments.length > 4
        );
    }, [sortedComments, parentRef, displayLatestButton]);

    return (
        <Box
            className={className}
            paddingBottom={
                commentFormIsFocused && !editComment ? '186.5px' : '112.5px'
            }
            ref={discussionWrapperRef}
        >
            <Box alignItems="center" display="flex" mb={2}>
                <Typography variant="h4">{translate('discussion')}</Typography>

                <Box
                    className={`showLatestButtonWrapper ${
                        displayLatestButton ? '' : 'hide'
                    }`}
                >
                    <IconButton
                        className="showLatestButton"
                        component={Link}
                        size="large"
                        to={{
                            pathname: window.location.pathname,
                            hash: `${translate('latest').toLowerCase()}`,
                        }}
                    >
                        <Box mr={1}>
                            <Typography variant="body2">
                                {translate('showLatestComment')}
                            </Typography>
                        </Box>
                        <Icon path={mdiArrowDown} size="2.2rem" />
                    </IconButton>
                </Box>
            </Box>
            {loadingDiscussion ? (
                <Box
                    display="flex"
                    height="40px"
                    justifyContent="center"
                    mb={4}
                    mt={4}
                    position="relative"
                >
                    <Loader />
                </Box>
            ) : (
                sortedComments.map((comment, index) => {
                    let actions;

                    if (currentUser?.id === comment.user.id) {
                        actions = [
                            <MenuItem key="edit" onClick={handleEdit(comment)}>
                                {translate('edit')}
                            </MenuItem>,
                            <MenuItem
                                key="delete"
                                onClick={() =>
                                    setShowOnDeleteAlert({
                                        show: true,
                                        comment,
                                    })
                                }
                            >
                                {translate('delete')}
                            </MenuItem>,
                        ];
                    }

                    let children;
                    const isEditComment = comment.id === editComment?.id;

                    if (isEditComment) {
                        children = (
                            <CommentForm
                                edit
                                disabled={commentFormDisabled}
                                isFocused={
                                    commentFormIsFocused && isEditComment
                                }
                                ref={commentFormRef}
                                submitText="updateComment"
                                value={comment.text}
                                onCancelEdit={handleCancelEdit}
                                onFocus={(isFocused) =>
                                    handleFocusCommentForm(isFocused, true)
                                }
                                onSave={handleSave}
                            />
                        );
                    }

                    const isLatestComment = index === sortedComments.length - 1;
                    const highlightLatestComment =
                        isLatestElementUrl && isLatestComment && hitBottom;

                    const isSpecificComment =
                        isSpecificUrl() &&
                        comment.id === location.hash.substring(1);

                    const highlightSpecificComment =
                        isSpecificComment && specificElementReached;

                    const highlightComment =
                        highlightLatestComment || highlightSpecificComment;

                    return (
                        <Box
                            key={index}
                            ref={isLatestComment ? latestCommentRef : undefined}
                        >
                            <Comment
                                {...comment}
                                actions={actions}
                                commentRef={
                                    isEditComment || isSpecificComment
                                        ? linkedCommentRefCallback
                                        : undefined
                                }
                                highlight={highlightComment}
                                isLast={index === sortedComments.length - 1}
                            >
                                {children}
                            </Comment>
                        </Box>
                    );
                })
            )}

            {currentUser && (
                <Grid container spacing={2}>
                    {!loadingDiscussion && (
                        <CommentForm
                            className={`stickyCommentForm ${
                                hitBottom && !showCommentFormShadow
                                    ? 'bottom'
                                    : ''
                            }`}
                            disabled={commentFormDisabled}
                            isFocused={commentFormIsFocused && !editComment}
                            ref={commentFormRef}
                            onCancelEdit={handleCancelEdit}
                            onFocus={handleFocusCommentForm}
                            onSave={handleSave}
                        />
                    )}
                </Grid>
            )}
            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={() => {
                                const { comment } = showOnDeleteAlert;

                                if (!comment) return;

                                handleDelete(comment);

                                setShowOnDeleteAlert({
                                    show: false,
                                    comment: undefined,
                                });
                            }}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowOnDeleteAlert({
                                    show: false,
                                    comment: undefined,
                                });
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showOnDeleteAlert.show}
                title={translate('deleteCommentMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteCommentMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </Box>
    );
};

export const DiscussionSection = styled(BaseDiscussionSection)`
    .stickyCommentForm {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 768px;
        right: 0;
        box-shadow: 0px 7px 19px -2px rgb(0 0 0 / 75%);
        transition: box-shadow 250ms linear;
        z-index: 1250;

        &.bottom {
            box-shadow: none;
        }
    }

    .showLatestButtonWrapper {
        display: flex;
        margin-left: auto;
        position: relative;
        right: -16px;
        align-items: center;
        visibility: visible;

        &.hide {
            visibility: hidden;
        }
    }

    .showLatestButton:hover {
        background: none;
        color: ${({ theme }) => theme.palette.text.primary};
    }
`;
