import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { IOfferTypeChoices } from 'graphql/types';
import { Select } from 'common/components/FormField';

export const OfferTypeSelect = () => {
    const { t: translate } = useTranslation();

    const offerTypeOptions = [
        {
            value: IOfferTypeChoices.Training,
            label: translate('offer.offerTypeSelect.training'),
        },
        {
            value: IOfferTypeChoices.Course,
            label: translate('offer.offerTypeSelect.course'),
        },
    ];

    return (
        <Field
            component={Select}
            defaultValue={offerTypeOptions[0].value}
            name="type"
            options={offerTypeOptions}
        />
    );
};
