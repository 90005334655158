import MuiCardActions from '@mui/material/CardActions';
import { styled } from 'styled-components';

export const CardActions = styled(MuiCardActions)`
    padding: ${({ theme }) => theme.spacing(0, 3, 3)};

    &:first-child,
    .MuiCardMedia-root + & {
        margin-top: ${({ theme }) => theme.spacing(3)};
    }
`;
