import {
    IEventReminder,
    IModuleGroupConditionOffsetScale,
    IModuleGroupConditionType,
} from 'graphql/types';

export const CONDITION_TYPE_LIST = [
    IModuleGroupConditionType.NoCondition,
    IModuleGroupConditionType.Relative,
    IModuleGroupConditionType.Absolute,
    IModuleGroupConditionType.CourseCompletion,
];

export const CONDITION_OFFSET_TYPE_LIST = [
    IModuleGroupConditionOffsetScale.Hours,
    IModuleGroupConditionOffsetScale.Days,
    IModuleGroupConditionOffsetScale.Weeks,
];

export const REMINDER_OFFSET_LIST = [
    IEventReminder.None,
    IEventReminder.AtTheStart,
    IEventReminder.OneDayBefore,
    IEventReminder.TwoDaysBefore,
    IEventReminder.ThreeDaysBefore,
    IEventReminder.FourDaysBefore,
    IEventReminder.FiveDaysBefore,
    IEventReminder.SixDaysBefore,
    IEventReminder.SevenDaysBefore,
    IEventReminder.TwoWeeksBefore,
    IEventReminder.OneMonthBefore,
];
