import { useEffect, useMemo } from 'react';

import { IRole, useTrainingTrainersQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { ITrainingTrainerListItem } from 'training/types';
import { useUserFilter } from 'user/hooks/useUserFilter';
import { usePagination } from 'common/hooks/usePagination';

export const useTrainingTrainerList = (trainingId: string) => {
    const { showApolloError } = useApolloError();

    const filterPermissions = {
        rolesFilter: [IRole.Owner, IRole.Manager, IRole.Author, IRole.Trainer],
    };

    const {
        filterValues,
        filters,
        filterValuesForQuery,
        loading: filtersLoading,
        onSearch,
        onFilterChange,
    } = useUserFilter(filterPermissions);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const trainerQueryVariables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
        }),
        [filterValuesForQuery]
    );

    const { loading: trainersLoading, data: trainersData } =
        useTrainingTrainersQuery({
            variables: {
                id: trainingId,
                ...trainerQueryVariables,
            },
            onError: showApolloError,
        });

    const trainersObject = trainersData?.training?.trainers;
    const trainersCount = trainersObject?.count;
    const trainers =
        (trainersObject?.edges
            .map((edge) => ({
                ...edge?.node,
                trainingRole: edge.trainingRole,
            }))
            .filter(Boolean) as ITrainingTrainerListItem[]) || [];

    const loading = filtersLoading || trainersLoading;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(trainersCount || 0);
    }, [trainersCount, loading, initializePagination]);

    return {
        trainers,
        trainersCount,
        loading,
        paginationSettings,
        filters,
        filterValues,
        onSearch,
        onFilterChange,
        setPage,
    };
};
