import { useState } from 'react';
import { Box, Container } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ApolloError } from 'common/components/ApolloError';
import { Link } from 'common/components/Link';
import LIBRARY_EMPTY_IMAGE from 'assets/images/library.webp';
import { BasePage } from 'hydra/pages/BasePage';
import { Button } from 'common/components/Button';
import { Img } from 'common/components/Img';
import { IFeature, useCreateCollectionMutation } from 'graphql/types';
import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { UpgradeContent } from 'library/components/UpgradeContent';
import { useFeature } from 'common/hooks/useFeature';
import { UpgradePage } from 'common/components/UpgradePage/UpgradePage';

interface IProps {
    onCreate?(): void;
    upgradeRequired?: boolean;
}

export const LibraryEmptyPage = ({ onCreate, upgradeRequired }: IProps) => {
    const [translate] = useTranslation();
    const [isCreating, setIsCreating] = useState(false);
    const { userCanSeeUpgrade } = useFeature(IFeature.Library);

    const [createCollection, { error: createError }] =
        useCreateCollectionMutation({
            onCompleted: () => {
                onCreate && onCreate();
            },
        });

    const showUpgradePage = upgradeRequired && userCanSeeUpgrade;

    return (
        <BasePage contentContainerMaxWidth={showUpgradePage ? 'lg' : undefined}>
            {createError && <ApolloError error={createError} />}

            {!upgradeRequired && (
                <Container maxWidth="sm">
                    <Box mt={8} textAlign="center">
                        <Box maxWidth={400} mb={4} mx="auto">
                            <Img src={LIBRARY_EMPTY_IMAGE} />
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h1">
                                {translate('library')}
                            </Typography>
                        </Box>
                        <Box mb={4}>
                            <Typography>
                                <Trans
                                    components={[
                                        <Link
                                            href="http://helpdesk.pluvo.nl/"
                                            key="0"
                                            target="_blank"
                                        />,
                                    ]}
                                    i18nKey="libraryEmptyPage.text"
                                />
                            </Typography>
                        </Box>

                        <>
                            <PageTitle mixpanelTitle="Create library">
                                {translate('libraryEmptyPage.title')}
                            </PageTitle>
                            <Button
                                color="primary"
                                disabled={isCreating}
                                variant="contained"
                                onClick={() => {
                                    setIsCreating(true);
                                    // Create the root collection with some default configuration
                                    const newCollection = {
                                        title: translate('library'),
                                        root: true,
                                    };
                                    createCollection({
                                        variables: {
                                            collection: newCollection,
                                        },
                                    });
                                }}
                            >
                                {translate('libraryEmptyPage.buttonText')}
                            </Button>
                        </>
                    </Box>
                </Container>
            )}

            {showUpgradePage && (
                <UpgradePage>
                    <UpgradeContent />
                </UpgradePage>
            )}
        </BasePage>
    );
};
