import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { IExtraCategorySearch } from 'graphql/types';
import {
    useOfferSearch,
    useOfferOverviewQueryVariables,
    useOfferOverviewList,
} from 'offer/hooks';
import { usePagination } from 'common/hooks/usePagination';
import { InfiniteScroll } from 'common/components/InfiniteScroll';
import { ITypeFilter } from 'offer/constants/offer';
import { FilterBar } from 'common/components/FilterBar';
import { BoxLoader } from 'common/components/Loader';
import { NoResults } from 'common/components/NoResults';
import { OfferCard } from 'offer/components/OfferCard';
import { Button } from 'common/components/Button';
import { PAGE_RESULT_CARD_AMOUNT } from 'training/constants/pagination';

interface IProps {
    typeFilter?: ITypeFilter;
    viewToggle?: React.ReactNode;
}

export const OffersList = ({ typeFilter, viewToggle }: IProps) => {
    const [translate] = useTranslation();

    const [extraCategories, setExtraCategories] = useState<
        IExtraCategorySearch[]
    >([]);

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch,
        handleFilterSelect,
    } = useOfferSearch(extraCategories);

    const { paginationSettings } = usePagination(true, {
        first: PAGE_RESULT_CARD_AMOUNT,
    });

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { offerQueryVariables } = useOfferOverviewQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        offers,
        loading: loadingOffers,
        fetchMoreOffers,
        pageInfo,
        shouldFetchMore,
        extraCategoryQueryFilters,
    } = useOfferOverviewList({
        ...offerQueryVariables,
        types: typeFilter?.types,
    });

    useEffect(() => {
        if (
            JSON.stringify(extraCategoryQueryFilters) ===
            JSON.stringify(extraCategories)
        ) {
            return;
        }

        setExtraCategories(extraCategoryQueryFilters);
    }, [extraCategoryQueryFilters]);

    const loading = filtersLoading || loadingOffers;

    const fetchMoreResults = () => {
        if (!fetchMoreOffers) return;

        fetchMoreOffers({
            variables: {
                first: paginationSettings.first,
                after: pageInfo?.endCursor,
            },
        });
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 4 }}>
                <FilterBar
                    barPrepend={viewToggle}
                    filters={filters}
                    initialSearchValue={searchQueryParam}
                    initialSelected={selectedFilters}
                    placeholder={translate('filterBarPlaceholder')}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch('')}
                    onSelect={handleFilterSelect}
                />
            </Box>

            {!offers.length && loading ? (
                <BoxLoader />
            ) : (
                <InfiniteScroll
                    shouldRunCallback={shouldFetchMore}
                    onScrollBottom={() => {
                        fetchMoreResults();
                    }}
                >
                    {!offers.length ? (
                        <NoResults variant="OFFERS" />
                    ) : (
                        <>
                            <Grid container spacing={3}>
                                {offers.map((offer) => (
                                    <Grid
                                        item
                                        key={offer.id}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                    >
                                        <OfferCard offer={offer} />
                                    </Grid>
                                ))}
                            </Grid>

                            {pageInfo?.hasNextPage && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    mt={3}
                                >
                                    <Button
                                        color="primary"
                                        loading={loadingOffers}
                                        variant="contained"
                                        onClick={fetchMoreResults}
                                    >
                                        {translate('moreResults')}
                                    </Button>
                                </Box>
                            )}
                        </>
                    )}
                </InfiniteScroll>
            )}
        </Box>
    );
};
