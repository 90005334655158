import { IAssignmentScoreType } from 'graphql/types';
import { Chip } from 'common/components/Chip';
import { formatGrade } from 'training/utils/formatGrade';

interface IProps {
    assignment: {
        score?: number | null;
        fulfilled?: boolean | null;
        module?: {
            scoreType?: IAssignmentScoreType;
            scoreThreshold?: number;
        } | null;
    };
}

export const AssignmentScoreChip = ({ assignment }: IProps) => {
    const { score, fulfilled, module } = assignment;

    if (!module) return null;

    const { scoreType, scoreThreshold } = module;

    if (scoreType === IAssignmentScoreType.None) return null;

    if (score !== undefined && score !== null) {
        return (
            <Chip
                color={score >= (scoreThreshold || 0) ? 'success' : 'error'}
                label={score !== 0 ? formatGrade(score * 10) : undefined}
            />
        );
    }

    if (fulfilled !== undefined && fulfilled !== null) {
        return (
            <Chip
                color={fulfilled ? 'success' : 'error'}
                sx={{
                    pointerEvents: 'none',
                }}
            />
        );
    }

    return null;
};
