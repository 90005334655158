import { Grid } from '@mui/material';

import { IFilterFormItem } from 'common/types';
import { FilterSectionFields } from 'common/components/FilterSectionFields';

// The filter section for date range filters will add grid layout to the filter fields
export const FilterDateRangeSection = ({ key, ...other }: IFilterFormItem) => (
    <Grid container spacing={2}>
        <FilterSectionFields key={key} {...other}>
            {({ fields, renderField }) =>
                fields.map((field, index) => (
                    <Grid item sm key={index} xs={6}>
                        {renderField(field)}
                    </Grid>
                ))
            }
        </FilterSectionFields>
    </Grid>
);
