import moment from 'moment';

import {
    IOrganisationPlanInfoFragment,
    IPlanFragment,
    IPlanInterval,
    IPlanPackageFragment,
} from 'graphql/types';
import { PLAN_PACKAGES } from 'plans/constants/plans';
import { TPlanPackage } from 'plans/types';

/*
 * Check if current plan is present in the plan packages
 */
export function currentPlanInPlans(
    currentPlan: IPlanFragment,
    planPackages: IPlanPackageFragment[]
) {
    return planPackages.some((planPackage) =>
        planPackage.plans.some((plan) => currentPlan.id === plan.id)
    );
}

/*
 * A plan is custom when it is not in the current plan packages
 * and when it is not a basic plan
 */
export function isCustomPlan(
    currentPlan: IPlanFragment,
    planPackages: IPlanPackageFragment[]
) {
    return (
        !currentPlanInPlans(currentPlan, planPackages) && !currentPlan.isBasic
    );
}

export function getPlanPackage(
    plan: IPlanFragment,
    planPackages: IPlanPackageFragment[]
) {
    return planPackages.find((planPackage) =>
        planPackage.plans.find((item) => item.id === plan.id)
    );
}

export function isWithinSameInterval(
    currentPlan: IPlanFragment,
    selectedPlan: IPlanFragment
) {
    // Give a weight to the intervals to compare whether you are downgrading or not
    const currentPlanInterval =
        currentPlan.interval === IPlanInterval.Month ? 0 : 1;
    const selectedPlanInterval =
        selectedPlan.interval === IPlanInterval.Month ? 0 : 1;

    return currentPlanInterval === selectedPlanInterval;
}

export function isIntervalDowngrade(
    currentPlan: IPlanFragment,
    selectedPlan: IPlanFragment
) {
    // Give a weight to the intervals to compare whether you are downgrading or not
    const currentPlanInterval =
        currentPlan.interval === IPlanInterval.Month ? 0 : 1;
    const selectedPlanInterval =
        selectedPlan.interval === IPlanInterval.Month ? 0 : 1;

    return selectedPlanInterval < currentPlanInterval;
}

export function isIntervalUpgrade(
    currentPlan: IPlanFragment,
    selectedPlan: IPlanFragment
) {
    // Give a weight to the intervals to compare whether you are downgrading or not
    const currentPlanInterval =
        currentPlan.interval === IPlanInterval.Month ? 0 : 1;
    const selectedPlanInterval =
        selectedPlan.interval === IPlanInterval.Month ? 0 : 1;

    return selectedPlanInterval > currentPlanInterval;
}

/*
 * Checks if user tries to downgrade its current plan
 */
export function isPlanDowngrade(
    currentPlan: IPlanFragment,
    planPackages: IPlanPackageFragment[],
    planPackage: TPlanPackage,
    selectedPlan: IPlanFragment
) {
    if (currentPlan.isBasic) return false;

    const currentPlanPackage = getPlanPackage(currentPlan, planPackages);
    const selectedPlanPackage = getPlanPackage(selectedPlan, planPackages);

    const inSamePackage =
        currentPlanPackage &&
        selectedPlanPackage &&
        currentPlanPackage?.id === selectedPlanPackage?.id;

    const withinSameInterval = isWithinSameInterval(currentPlan, selectedPlan);

    if (!currentPlanPackage || (inSamePackage && withinSameInterval)) {
        return false;
    }

    const currentPlanPackageObj =
        PLAN_PACKAGES[currentPlanPackage.frontendName];

    const intervalDowngrade = isIntervalDowngrade(currentPlan, selectedPlan);

    const isPackageDowngrade =
        planPackage.weight < currentPlanPackageObj.weight;

    const isPlanDowngrade =
        selectedPlan.userCountLimit < currentPlan.userCountLimit &&
        inSamePackage &&
        withinSameInterval;

    return intervalDowngrade || isPackageDowngrade || isPlanDowngrade;
}

/*
 * Returns the downgrade date which is based on the interval.
 * It subtracts the amount of days based on the interval.
 */
export function getDowngradeDate(
    nextPaymentDate: IOrganisationPlanInfoFragment['nextPaymentDate'],
    interval: IPlanInterval
) {
    const nextDate = moment(nextPaymentDate).startOf('day');

    if (interval === IPlanInterval.Month) {
        return nextDate.subtract(7, 'days');
    }

    return nextDate.subtract(14, 'days');
}

/*
 * Get the features that a user loses when downgrading
 */
export function getLosingFeatures(
    currentPlanPackage: TPlanPackage,
    selectedPlanPackage: TPlanPackage
) {
    const planPackages = Object.values(PLAN_PACKAGES);

    const downgradePlanPackages = planPackages
        .reverse()
        .map((planPackage) => {
            if (
                planPackage.weight === selectedPlanPackage.weight ||
                planPackage.weight < selectedPlanPackage.weight ||
                planPackage.weight > currentPlanPackage.weight
            ) {
                return null;
            }

            return planPackage;
        })
        .filter(Boolean);

    let losingFeatures: string[] = [];

    downgradePlanPackages.forEach((planPackage) => {
        if (!planPackage) return;

        losingFeatures = [...losingFeatures, ...planPackage.features];
    });

    return losingFeatures;
}
