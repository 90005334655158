import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link } from 'common/components/Link';
import FOUROFOUR_IMAGE from 'assets/images/404.webp';
import { BasePage } from 'hydra/pages/BasePage';
import { Img } from 'common/components/Img';
import { Button } from 'common/components/Button';
import { PageTitle } from 'common/components/PageTitle';

export const FourOFourPage = () => {
    const { t: translate } = useTranslation();

    return (
        <BasePage>
            <PageTitle mixpanelTitle="Page not found">
                {translate('fourOFourPage.browserTitle')}
            </PageTitle>
            <Container maxWidth="sm">
                <Box mt={8} textAlign="center">
                    <Box maxWidth={400} mb={4} mx="auto">
                        <Img src={FOUROFOUR_IMAGE} />
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h1">
                            {translate('fourOFourPage.title')}
                        </Typography>
                    </Box>
                    <Box mb={4}>
                        <Typography>
                            {translate('fourOFourPage.text')}
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        component={Link}
                        to="HOME"
                        variant="contained"
                    >
                        {translate('fourOFourPage.buttonText')}
                    </Button>
                </Box>
            </Container>
        </BasePage>
    );
};
