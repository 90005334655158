import { useRef } from 'react';
import { TableRow, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
    IModuleWeight,
    ITrainingMatrixModuleFragment,
    IPortfolioItemMatrixListItemFragment,
} from 'graphql/types';
import { TableCell } from 'common/components/Table';
import { getModuleTitle, getModuleWeightValue } from 'training/utils/module';
import {
    TrainingParticipantReportMatrixCell,
    TMatrixCellRef,
} from 'training/components/TrainingParticipantMatrix';

interface IProps {
    module: ITrainingMatrixModuleFragment;
    report?: IPortfolioItemMatrixListItemFragment;
    trainingId: string;
    userId: string;
    onClearCache?: () => void;
}

export const TrainingParticipantReportTableRow = ({
    module,
    report,
    trainingId,
    userId,
    onClearCache,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const reportCellRef = useRef<TMatrixCellRef>(null);

    const { __typename: moduleTypeName } = module;
    const canClickRow = !!report || moduleTypeName === 'AssignmentModule';

    const handleRowClick = () => {
        reportCellRef.current?.handleClick();
    };

    return (
        <TableRow
            hover={canClickRow}
            sx={{ cursor: canClickRow ? 'pointer' : 'default' }}
            onClick={handleRowClick}
            {...other}
        >
            <TableCell>{getModuleTitle(module)}</TableCell>
            <TableCell sx={{ width: '1px' }}>
                {/* Show weight value of module if set and not 1 */}
                {!!('weight' in module) &&
                    module.weight !== IModuleWeight.Weight1 && (
                        <Tooltip title={translate('moduleWeightExplanation')}>
                            <span>{getModuleWeightValue(module.weight)}</span>
                        </Tooltip>
                    )}
            </TableCell>
            <TrainingParticipantReportMatrixCell
                disableClick
                align="center"
                canManageParticipant={true}
                key={module.id}
                module={module}
                ref={reportCellRef}
                report={report}
                sx={{ width: '1px' }}
                trainingId={trainingId}
                userId={userId}
                onClearCache={onClearCache}
            />
        </TableRow>
    );
};
