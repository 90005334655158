import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import { GroupForm } from 'user/components/GroupForm';
import {
    IContentTypeValue,
    ICreateGroupInput,
    useCreateGroupMutation,
    useExtraCategoriesQuery,
    useUpdateExtraCategoryValuesMutation,
} from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { TExtraCategoriesFormInput } from 'organisation/types';
import {
    getExtraCategoryInitialFormValues,
    getExtraCategoryValues,
} from 'organisation/utils/extraCategory';
import { useApolloError } from 'common/hooks/useApolloError';
import { Loader } from 'common/components/Loader';

type TFormValues = ICreateGroupInput & TExtraCategoriesFormInput;

export const GroupCreateDrawer = ({ open }: IPageDrawerProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const { data: extraCategoryData, loading: extraCategoryLoading } =
        useExtraCategoriesQuery({
            variables: { contentType: IContentTypeValue.Group },
            onError: showApolloError,
        });
    const [createGroup, { loading: createGroupLoading }] =
        useCreateGroupMutation();
    const [
        updateExtraCategoryValues,
        { loading: updateExtraCategoryValuesLoading },
    ] = useUpdateExtraCategoryValuesMutation();

    const { extraCategories } = extraCategoryData || {};

    const handleClose = () => {
        navigate(getUrl('GROUP_MANAGEMENT_LIST'));
    };

    const handleSubmit = async (values: TFormValues) => {
        const {
            maxUsers,
            extraCategories: extraCategoryCreateValues,
            participantIds: participants,
            managerIds: managers,
            trainingIds: trainings,
            ...otherValues
        } = values;

        let groupId;

        try {
            const result = await createGroup({
                variables: {
                    group: {
                        participantIds: participants?.map(
                            (participant) => participant.id
                        ),
                        managerIds: managers?.map((manager) => manager.id),
                        trainingIds: trainings?.map((training) => training.id),
                        maxUsers: maxUsers ? +maxUsers : 0,
                        ...otherValues,
                    },
                },
                update: (cache) => {
                    cache.evict({ fieldName: 'paginatedGroups' });
                },
            });

            const extraCategoryValues = getExtraCategoryValues(
                extraCategoryCreateValues,
                extraCategories
            );

            const { group: newGroup } = result?.data?.createGroup || {};

            if (!newGroup) return;

            groupId = newGroup.id;

            // If extra categories are set update their values
            if (extraCategoryValues.length) {
                await updateExtraCategoryValues({
                    variables: {
                        contentType: IContentTypeValue.Group,
                        modelId: groupId,
                        values: extraCategoryValues,
                    },
                });
            }
        } catch (error) {
            showApolloError(error);

            return;
        }

        handleClose();

        displaySnackbar(
            translate('groupsManagementPage.groupsActionSuccess.create'),
            {
                variant: 'success',
            }
        );

        return;
    };

    const initialValues: TFormValues = {
        name: '',
        enableChat: false,
        ref: '',
        maxUsers: 0,
        extraCategories: {
            ...getExtraCategoryInitialFormValues(extraCategories, []),
        },
    };

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <PageTitle mixpanelTitle="Drawer create group">
                {`${translate('groupsManagementPage.pageTitle')} - ${translate(
                    'groupForm.createGroup'
                )}`}
            </PageTitle>

            <Box sx={{ p: { xs: 2, sm: 4 }, pt: { xs: 0, sm: 0 } }}>
                {extraCategoryLoading && <Loader />}

                {!extraCategoryLoading && (
                    <GroupForm
                        disabled={
                            createGroupLoading ||
                            updateExtraCategoryValuesLoading ||
                            extraCategoryLoading
                        }
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    />
                )}
            </Box>
        </PageDrawer>
    );
};
