import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
    IAssignmentScoreType,
    IAssignmentType,
    IBasicFileFragment,
    ICreateAssignmentModuleInput,
    IModuleWeight,
    IOpenOffsetType,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import {
    FileField,
    Select,
    SliderField,
    Switch,
    TextField,
} from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { Divider } from 'common/components/Divider';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { ASSIGNMENT_MODULE_FILE_MAX_SIZE } from 'common/constants/files';
import { ModuleWeightFormSection } from 'training/components/ModuleWeightFormSection';

export interface IAssignmentModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateAssignmentModuleInput, 'imageId' | 'offset'> {
    attachment?: IBasicFileFragment;
}

export interface IProps {
    initialValues?: Partial<IAssignmentModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    onSubmit(values: IAssignmentModuleFormValues): void;
    onDelete?(): void;
    moduleGroupId?: string;
    onDelete?(): void;
}

export const AssignmentModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
    });

    const initValues: IAssignmentModuleFormValues = {
        title: '',
        assignmentType: IAssignmentType.Individual,
        scoreType: IAssignmentScoreType.Numeral,
        scoreThreshold: 0,
        canUploadFiles: true,
        canDiscuss: true,
        weight: IModuleWeight.Weight1,
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
        ...initialValues,
    };

    const formTitle = edit
        ? translate('moduleForm.assignment.editTitle')
        : translate('moduleForm.assignment.newTitle');
    const formText = edit
        ? translate('moduleForm.assignment.editText')
        : translate('moduleForm.assignment.newText');
    const submitText = edit
        ? translate('moduleForm.assignment.editButton')
        : translate('moduleForm.assignment.newButton');

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Form>
                        {formTitle && formText && (
                            <Box mb={4}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Field
                            component={TextField}
                            label={translate('title')}
                            name="title"
                        />

                        <Field
                            multiline
                            component={TextField}
                            label={translate('description')}
                            name="description"
                        />

                        <Divider sx={{ mt: 3 }} />

                        <FormSection
                            description={
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="moduleForm.assignment.typeDescription"
                                />
                            }
                            sx={{ borderBottom: 0 }}
                            title={translate('moduleForm.assignment.type')}
                        >
                            <Field
                                component={Select}
                                disabled={edit}
                                helperText={translate(
                                    'moduleForm.assignment.assignmentTypeDisabled'
                                )}
                                label={translate(
                                    'moduleForm.assignment.assignmentType'
                                )}
                                name="assignmentType"
                                options={[
                                    {
                                        label: translate(
                                            'moduleForm.assignment.individual'
                                        ),
                                        value: IAssignmentType.Individual,
                                    },
                                    {
                                        label: translate(
                                            'moduleForm.assignment.collective'
                                        ),
                                        value: IAssignmentType.Collective,
                                    },
                                ]}
                            />

                            <Field
                                component={Select}
                                label={translate(
                                    'moduleForm.assignment.scoreType'
                                )}
                                name="scoreType"
                                options={[
                                    {
                                        label: translate(
                                            'moduleForm.assignment.numeral'
                                        ),
                                        value: IAssignmentScoreType.Numeral,
                                    },
                                    {
                                        label: translate(
                                            'moduleForm.assignment.fulfilled'
                                        ),
                                        value: IAssignmentScoreType.Fulfilled,
                                    },
                                    {
                                        label: translate(
                                            'moduleForm.assignment.none'
                                        ),
                                        value: IAssignmentScoreType.None,
                                    },
                                ]}
                            />
                            {values.scoreType ===
                                IAssignmentScoreType.Numeral && (
                                <Field
                                    component={SliderField}
                                    label={translate(
                                        'moduleForm.assignment.scoreThreshold'
                                    )}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '0',
                                        },
                                        {
                                            value: 1,
                                            label: '10',
                                        },
                                    ]}
                                    max={1}
                                    min={0}
                                    name="scoreThreshold"
                                    step={0.01}
                                    valueLabelFormat={(value: string) =>
                                        `${(+value * 10).toFixed(1)}`
                                    }
                                />
                            )}
                        </FormSection>

                        <ContentExpander
                            expand={settingsExpanded}
                            title={translate('moreSettings')}
                            onClick={() =>
                                setSettingsExpanded(!settingsExpanded)
                            }
                        >
                            <FormSection
                                description={
                                    <Trans
                                        components={{
                                            a: (
                                                <Link
                                                    rel="noreferrer"
                                                    underline="always"
                                                />
                                            ),
                                        }}
                                        i18nKey="moduleForm.assignment.socialDescription"
                                    />
                                }
                                title={translate(
                                    'moduleForm.assignment.social'
                                )}
                            >
                                <Box sx={{ mt: 2 }}>
                                    <Field
                                        checked={values.canUploadFiles}
                                        component={Switch}
                                        formControlProps={{
                                            margin: 'none',
                                        }}
                                        label={translate(
                                            'moduleForm.assignment.canUploadFiles'
                                        )}
                                        name="canUploadFiles"
                                    />
                                </Box>

                                <Field
                                    checked={values.canDiscuss}
                                    component={Switch}
                                    formControlProps={{
                                        margin: 'none',
                                    }}
                                    label={translate(
                                        'moduleForm.assignment.canDiscuss'
                                    )}
                                    name="canDiscuss"
                                />
                            </FormSection>

                            <FormSection
                                description={
                                    <Trans
                                        components={{
                                            a: (
                                                <Link
                                                    rel="noreferrer"
                                                    underline="always"
                                                />
                                            ),
                                        }}
                                        i18nKey="moduleForm.assignment.attachmentDescription"
                                    />
                                }
                                title={translate(
                                    'moduleForm.assignment.attachment'
                                )}
                            >
                                <Box sx={{ mt: 4 }}>
                                    <Field
                                        component={FileField}
                                        helperText={translate(
                                            'moduleForm.assignment.attachmentHelperText',
                                            {
                                                count: ASSIGNMENT_MODULE_FILE_MAX_SIZE,
                                            }
                                        )}
                                        label={translate(
                                            'moduleForm.assignment.attachment'
                                        )}
                                        maxUploadSize={
                                            ASSIGNMENT_MODULE_FILE_MAX_SIZE
                                        }
                                        name="attachment"
                                    />
                                </Box>
                            </FormSection>

                            <ModuleWeightFormSection />

                            <ModuleImageFormSection />
                            {/* Only show conditions section when we have a module group id */}
                            {moduleGroupId && (
                                <ModuleOffsetFormSection
                                    moduleGroupId={moduleGroupId}
                                    offsetValues={values.offset || undefined}
                                />
                            )}
                        </ContentExpander>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
