import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import {
    IContentTypeValue,
    IOfferEventType,
    useCreateOfferEventMutation,
    useCreateOfferMutation,
    useExtraCategoriesQuery,
    useUpdateExtraCategoryValuesMutation,
} from 'graphql/types';
import { EOfferEventCategoryType, TOfferFormValues } from 'offer/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getUrl } from 'route/utils/getUrl';
import { PageTitle } from 'common/components/PageTitle';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { MIXPANEL_EVENT_NAMES } from 'common/constants/mixpanel';
import { getFilterForType } from 'offer/utils/filters';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import { useApolloError } from 'common/hooks/useApolloError';
import { getExtraCategoryValues } from 'organisation/utils/extraCategory';
import { getGroupConditionMutationInputs } from 'user/utils/groups';
import { IGroupConditionInput } from 'user/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { OfferEventForm } from 'offer/components/forms/OfferEventForm';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { EFilterType } from 'offer/constants/offer';

interface IProps {
    open?: boolean;
    onClose?(): void;
}

export const CreateOfferDrawer = ({ open, onClose }: IProps) => {
    const { t: translate } = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const { openGlobalDrawer } = useGlobalDrawer();
    const [createOffer, { loading: createOfferLoading }] =
        useCreateOfferMutation();
    const [createOfferEvent, { loading: createOfferEventLoading }] =
        useCreateOfferEventMutation();
    const { data: extraCategoryData } = useExtraCategoriesQuery({
        variables: { contentType: IContentTypeValue.Offer },
        onError: showApolloError,
    });
    const [updateExtraCategoryValues] = useUpdateExtraCategoryValuesMutation();

    const { setFilterParam } = useUrlFilterParams('offer');
    const isMeetingCreatePage = !!useRouteMatch('OFFER_MEETING_CREATE');

    const { extraCategories } = extraCategoryData || {};

    const handleSubmit = async (values: TOfferFormValues) => {
        // Using awaits because of 'nested' mutations where the last one should be
        // returned to formik to check if submitted

        try {
            const {
                extraCategories: extraCategoryCreateValues,
                groupConditions,
                ...otherOfferValues
            } = values.offer;

            const offerValues = {
                groupConditions: getGroupConditionMutationInputs(
                    (groupConditions as IGroupConditionInput[]) || undefined
                ),
                ...otherOfferValues,
            };

            const offerResponse = await createOffer({
                variables: { offer: offerValues },
            });

            const { offer } = offerResponse?.data?.createOffer || {};

            if (!offer) return;

            const extraCategoryValues = getExtraCategoryValues(
                extraCategoryCreateValues,
                extraCategories
            );

            // If extra categories are set update their values
            if (extraCategoryValues.length) {
                await updateExtraCategoryValues({
                    variables: {
                        contentType: IContentTypeValue.Offer,
                        modelId: offer.id,
                        values: extraCategoryValues,
                    },
                });
            }

            const {
                minSubscriptions,
                maxSubscriptions,
                type: offerEventType,
                certificate,
                ...otherValues
            } = values.event;
            const offerEventValues = {
                ...otherValues,
                minSubscriptions: minSubscriptions
                    ? +minSubscriptions
                    : undefined,
                maxSubscriptions: maxSubscriptions
                    ? +maxSubscriptions
                    : undefined,
                type: offerEventType,
                certificateId: certificate?.id,
            };

            await createOfferEvent({
                variables: {
                    offerId: offer.id,
                    offerEvent: offerEventValues,
                },
                update: (cache) => {
                    cache.evict({ fieldName: 'offers' });
                    cache.evict({ fieldName: 'paginatedOfferEvents' });
                },
            });

            const eventType = offerEventType;

            await trackMixpanel({
                eventName: MIXPANEL_EVENT_NAMES.offers[eventType].offer.create,
            });

            displaySnackbar(translate('offerActionSuccess.create'), {
                variant: 'success',
            });

            // Set type filter to the type of the created offer
            setFilterParam('type', getFilterForType(eventType)?.key);

            if (!values.offer.hasDetailPage) {
                // Open created offer drawer on the correct location
                const newLocation = { ...location };
                newLocation.pathname = getUrl('OFFER_LIST');

                if (offerEventType === IOfferEventType.Course) {
                    // Go to the correct view when creating a course offer
                    newLocation.search = `?type=${EFilterType.elearning}`;
                }

                openGlobalDrawer(
                    {
                        type: IGlobalDrawerType.Offer,
                        itemId: offer.id,
                    },
                    newLocation
                );

                return;
            }

            const offerDetailUrl = `${getUrl('OFFER_DETAIL', {
                id: offer.id,
            })}`;

            navigate(offerDetailUrl);
        } catch (error) {
            showApolloError(error);

            return;
        }

        return;
    };

    const loading = createOfferLoading || createOfferEventLoading;

    return (
        <PageDrawer
            disableClose={loading}
            open={open}
            onClose={loading ? undefined : onClose}
        >
            <PageTitle mixpanelTitle={MIXPANEL_EVENT_NAMES.offers.offer.create}>
                {`${translate('offers')} - ${translate('offer.newOffer')}`}
            </PageTitle>

            {open && (
                <OfferEventForm
                    includeOfferFields
                    disabled={loading}
                    offerEventCategoryType={
                        isMeetingCreatePage
                            ? EOfferEventCategoryType.Meeting
                            : EOfferEventCategoryType.Offer
                    }
                    offerEventType={
                        isMeetingCreatePage
                            ? IOfferEventType.Meeting
                            : IOfferEventType.Training
                    }
                    onSubmit={handleSubmit}
                />
            )}
        </PageDrawer>
    );
};
