import { useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { useFragment } from '@apollo/client';

import {
    ICreateVideoModuleInput,
    IOpenOffsetType,
    IVideoListItemFragment,
    VideoListItemFragmentDoc,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { TextField, Switch } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { VideoFormItem } from 'video/components/VideoFormItem';

export interface IVideoModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateVideoModuleInput, 'imageId' | 'offset'> {}

export interface IProps {
    initialValues?: Partial<IVideoModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    moduleGroupId?: string;
    video?: IVideoListItemFragment;
    videoId?: string;
    onSubmit(values: IVideoModuleFormValues): void;
    onDelete?(): void;
    onReplaceVideo?(): void;
}

export const VideoModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    video,
    videoId,
    onSubmit,
    onDelete,
    onReplaceVideo,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const { data: videoData } = useFragment({
        fragment: VideoListItemFragmentDoc,
        from: `Video:${videoId}`,
        fragmentName: 'VideoListItem',
    });

    const selectedVideo = !!Object.keys(videoData).length ? videoData : video;

    const initValues: IVideoModuleFormValues = {
        title: '',
        description: '',
        videoId: '',
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
        showLarge: false,
        autoplay: true,
        ...initialValues,
    };

    // If a videoId is passed we want to use that instead of the one from initialValues
    const selectedVideoId = videoId || initialValues?.videoId;
    const videoChanged = selectedVideoId !== initialValues?.videoId;

    const permissions = !!selectedVideo?.permissions
        ? JSON.parse(selectedVideo.permissions)
        : undefined;

    const canUpdateVideo = !!permissions?.canUpdate;

    const formTitle = edit
        ? translate('moduleForm.video.editTitle')
        : translate('moduleForm.video.newTitle');
    const formText = edit
        ? translate('moduleForm.video.editText')
        : translate('moduleForm.video.newText');
    const submitText = edit
        ? translate('moduleForm.video.editButton')
        : translate('moduleForm.video.newButton');

    return (
        <Formik
            initialValues={initValues}
            onSubmit={(values) => {
                const newValues = values;

                if (videoChanged) {
                    newValues.videoId = selectedVideoId;
                }

                return onSubmit(newValues);
            }}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Form>
                        {formTitle && formText && (
                            <Box mb={2}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box mb={2} mt={2}>
                            <VideoFormItem
                                isEditable={canUpdateVideo}
                                video={selectedVideo}
                                onReplaceVideo={onReplaceVideo}
                            />
                            {!selectedVideo && videoChanged && (
                                <FormHelperText error>
                                    {translate(
                                        'moduleForm.video.videoIsRequired'
                                    )}
                                </FormHelperText>
                            )}
                        </Box>

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.video.overrideDescription"
                                        />
                                    }
                                    title={translate('titleOverride')}
                                >
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />

                                    <Field
                                        multiline
                                        component={TextField}
                                        label={translate('description')}
                                        name="description"
                                    />
                                </FormSection>

                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.video.settingsDescription"
                                        />
                                    }
                                    title={translate('settings')}
                                >
                                    <Field
                                        checked={values.showLarge}
                                        component={Switch}
                                        label={translate(
                                            'moduleForm.video.showLarge'
                                        )}
                                        name="showLarge"
                                    />
                                    <Field
                                        checked={values.autoplay}
                                        component={Switch}
                                        label={translate('autoplay')}
                                        name="autoplay"
                                    />
                                </FormSection>

                                <ModuleImageFormSection />

                                {/* Only show conditions section when we have a module group id */}
                                {moduleGroupId && (
                                    <ModuleOffsetFormSection
                                        moduleGroupId={moduleGroupId}
                                        offsetValues={
                                            values.offset || undefined
                                        }
                                    />
                                )}
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting &&
                                            !disabled &&
                                            !dirty &&
                                            !videoChanged)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
