import { Navigate, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ITokenUserFragment, IRole } from 'graphql/types';
import { CoverPage } from 'hydra/pages/CoverPage';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { ChangePassword } from 'user/components/ChangePassword';
import { TokenLogin } from 'user/components/TokenLogin';
import { getUrl } from 'route/utils/getUrl';
import { PageTitle } from 'common/components/PageTitle';
import { useMixpanel } from 'common/hooks/useMixpanel';

export const WelcomePage = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();

    const renderContent = (tokenUser: ITokenUserFragment) => {
        const userRoles = tokenUser?.roles || [];

        const isOwner = userRoles.includes(IRole.Owner);
        const redirectUrl = isOwner ? 'ORGANISATION_LANGUAGE' : 'HOME';

        if (tokenUser.hasPassword) {
            return <Navigate to={getUrl(redirectUrl)} />;
        }

        const titleKey = isOwner ? 'titleOwner' : 'title';
        const contentKey = isOwner ? 'contentOwner' : 'content';

        return (
            <>
                <PageTitle
                    mixpanelTitle={`WelcomePage${isOwner ? ' (owner)' : ''}`}
                >
                    {translate(`welcomePage.${titleKey}`)}
                </PageTitle>
                <Box mb={2}>
                    <Typography variant="h2">
                        {translate(`welcomePage.${titleKey}`)}
                    </Typography>
                </Box>

                <Text>{translate(`welcomePage.${contentKey}`)}</Text>

                <ChangePassword
                    onSuccess={async () => {
                        await trackMixpanel({
                            eventName: `Onboarding: Password set${
                                isOwner && ' (owner)'
                            }`,
                        });

                        navigate(getUrl(redirectUrl));
                    }}
                />
            </>
        );
    };

    return (
        <CoverPage>
            <TokenLogin forPage="welcome">
                {(tokenUser) => renderContent(tokenUser)}
            </TokenLogin>
        </CoverPage>
    );
};
