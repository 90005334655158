import {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import { Box, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

interface IProps {
    children: React.ReactNode;
    scrollContainer?: HTMLElement | string;
    offset?: number;
    zIndex?: number;
    outerSx?: SxProps;
    outerSxSticky?: SxProps;
    outerSxNotSticky?: SxProps;
    innerSx?: SxProps;
    innerSxSticky?: SxProps;
    innerSxNotSticky?: SxProps;
}

export const Sticky = ({
    children,
    scrollContainer,
    offset = 0,
    zIndex,
    outerSx,
    outerSxSticky,
    outerSxNotSticky,
    innerSx,
    innerSxSticky,
    innerSxNotSticky,
}: IProps) => {
    const [isSticky, setIsSticky] = useState(false);
    const outerElement = useRef<HTMLDivElement>(null);
    const innerElement = useRef<HTMLDivElement>(null);

    const [elementTop, setElementTop] = useState<number>(0);
    const [elementWidth, setElementWidth] = useState<number>(0);
    const [elementHeight, setElementHeight] = useState<number>(0);

    const getScrollContainer = () => {
        if (typeof scrollContainer === 'string') {
            return outerElement.current?.closest(scrollContainer) || undefined;
        }

        return scrollContainer;
    };

    const getScrollTop = useCallback(() => {
        const container = getScrollContainer();

        // If container is not found we use window scrollY
        if (!container) return window.scrollY;

        return container.scrollTop;
    }, [scrollContainer]);

    const checkIsSticky = useCallback(() => {
        const scrollTop = getScrollTop();
        setIsSticky(scrollTop + offset >= elementTop);
    }, [elementTop, offset, getScrollTop]);

    const updateElementMetrics = useCallback(() => {
        const scrollTop = getScrollTop();
        const outerRect = outerElement.current?.getBoundingClientRect();
        const innerRect = innerElement.current?.getBoundingClientRect();

        if (outerRect && innerRect) {
            const newElementTop = outerRect.top + scrollTop;
            const newElementWidth = outerRect.width;
            const newElementHeight = innerRect.height;

            setElementTop((prev) =>
                prev !== newElementTop ? newElementTop : prev
            );
            setElementWidth((prev) =>
                prev !== newElementWidth ? newElementWidth : prev
            );
            setElementHeight((prev) =>
                prev !== newElementHeight ? newElementHeight : prev
            );

            checkIsSticky();
        }
    }, [getScrollTop, checkIsSticky]);

    useLayoutEffect(() => {
        updateElementMetrics();
    }, [updateElementMetrics]);

    useEffect(() => {
        const container = getScrollContainer() || window;

        container.addEventListener('scroll', checkIsSticky);
        window.addEventListener('resize', updateElementMetrics);

        return () => {
            container.removeEventListener('scroll', checkIsSticky);
            window.removeEventListener('resize', updateElementMetrics);
        };
    }, [checkIsSticky, updateElementMetrics, scrollContainer]);

    let outerStyle: SxProps = [
        { height: `${elementHeight}px` },
        ...withSxProp(outerSx),
    ];

    let innerStyle: SxProps = [...withSxProp(innerSx)];

    if (isSticky) {
        innerStyle = [
            ...innerStyle,
            {
                position: 'fixed',
                top: 0,
                zIndex,
                width: `${elementWidth}px`,
                transform: `translate3d(0, ${Math.round(offset)}px, 0)`,
            },
            ...withSxProp(innerSxSticky),
        ];

        outerStyle = [...outerStyle, ...withSxProp(outerSxSticky)];
    } else {
        innerStyle = [...innerStyle, ...withSxProp(innerSxNotSticky)];
        outerStyle = [...outerStyle, ...withSxProp(outerSxNotSticky)];
    }

    return (
        <Box ref={outerElement} sx={outerStyle}>
            <Box ref={innerElement} sx={innerStyle}>
                {children}
            </Box>
        </Box>
    );
};
