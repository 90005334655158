import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateTrainingMutation } from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { TrainingForm, ITrainingFormProps } from 'training/components/forms';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { useMixpanel } from 'common/hooks/useMixpanel';

interface IProps {
    open?: boolean;
}

export const TrainingCreateDrawer = ({ open }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();

    const [createTraining] = useCreateTrainingMutation();

    const handleSubmit: ITrainingFormProps['onSubmit'] = async (values) => {
        let response;

        try {
            response = await createTraining({
                variables: { training: values },
                update: (cache) => {
                    cache.evict({ fieldName: 'overviewTrainings' });
                    cache.evict({ fieldName: 'paginatedTrainings' });
                },
            });
        } catch (error) {
            showApolloError(error);
        }

        const newTraining = response?.data?.createTraining?.training;

        if (!newTraining) return;

        await trackMixpanel({
            eventName: 'Create learning journey in drawer',
        });

        displaySnackbar(translate('trainingActionSuccess.create'), {
            variant: 'success',
        });

        gotoTrainingPage(navigate, newTraining.id);

        return;
    };

    // Redirect to the pathname to go to the page without hashtag.
    const handleClose = () => navigate(window.location.pathname);

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <PageTitle mixpanelTitle="Drawer create learning journey">
                {translate('newTraining')}
            </PageTitle>

            <TrainingForm onSubmit={handleSubmit} />
        </PageDrawer>
    );
};
