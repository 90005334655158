import { useTranslation } from 'react-i18next';
import { mdiOpenInNew } from '@mdi/js';
import { useReactiveVar } from '@apollo/client';

import { Icon } from 'common/components/Icon';
import { ILtiModuleListItemFragment } from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link';
import { ChipGroup } from 'common/components/Chip';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const LTIModule = ({
    id,
    trainingId,
    title,
    disabled,
    launchUrl,
    conditions,
    alwaysOpen,
    startDate,
    image,
    conditionEffect,
    groupConditionEffect,
    weight,
    description,
    canUpdate,
    portfolioItem,
    hasProgress,
}: TTrainingModuleProps<ILtiModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    const itemContent = (
        <>
            <ListItemMedia color="primary" image={image?.url || undefined}>
                <Icon path={mdiOpenInNew} size="1.8rem" sizeSm="3rem" />
            </ListItemMedia>
            <ListItemText primary={title} secondary={description}>
                <ChipGroup>
                    <ModuleConditionsChips
                        alwaysOpen={alwaysOpen}
                        conditionEffect={conditionEffect}
                        conditions={conditions}
                        groupConditionEffect={groupConditionEffect}
                        startDate={startDate}
                    />
                </ChipGroup>
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    if (!studentView && canUpdate) {
        return (
            <ListItem
                button
                component={Link}
                params={{
                    trainingId,
                    moduleId: id,
                    type: 'ltiModule',
                }}
                to="TRAINING_UPDATE_MODULE"
            >
                {itemContent}

                <ModuleSecondaryAction
                    forUpdate
                    hasProgress={!!hasProgress}
                    portfolioItem={portfolioItem}
                    weight={weight}
                />
            </ListItem>
        );
    }

    return (
        <ListItem button component={Link} href={launchUrl} target="_blank">
            {itemContent}

            <ModuleSecondaryAction
                actionText={translate('start')}
                hasProgress={!!hasProgress}
                portfolioItem={portfolioItem}
                weight={weight}
            />
        </ListItem>
    );
};
