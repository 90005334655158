import { Box, BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { mdiDownload } from '@mdi/js';

import { Tooltip } from 'common/components/Tooltip';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import {
    useCurrentUserQuery,
    useGeneratePortfolioExportMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { TDateRange } from 'common/types';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useSnackbar } from 'common/hooks/useSnackbar';

interface IProps extends BoxProps {
    dateFilter?: TDateRange;
}

export const PortfolioExportButton = ({ dateFilter, ...other }: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [showDialog, setShowDialog] = useState(false);
    const [displaySnackbar] = useSnackbar();
    const [generateExportPortfolio, { loading: generatePdfLoading }] =
        useGeneratePortfolioExportMutation();
    const { data: currentUserData } = useCurrentUserQuery();

    const currentUser = currentUserData?.currentUser;

    const handleGenerateExportPortfolio = async () => {
        try {
            generateExportPortfolio({
                variables: {
                    userId: currentUser?.id,
                    fromDate: dateFilter?.startDate,
                    toDate: dateFilter?.endDate,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('portfolioPdfExport.portfolioPdfExportSuccess'),
            {
                variant: 'success',
            }
        );

        setShowDialog(false);
    };

    return (
        <Box {...other}>
            <Tooltip title={translate<string>('portfolioPdfExport.export')}>
                <Box>
                    <ActionButton
                        outlined
                        disabled={generatePdfLoading}
                        size="medium"
                        onClick={() => setShowDialog(true)}
                    >
                        <Icon path={mdiDownload} size="2.4rem" />
                    </ActionButton>
                </Box>
            </Tooltip>

            <ConfirmDialog
                confirmText={translate('export')}
                loading={generatePdfLoading}
                open={showDialog}
                title={translate('portfolioPdfExport.export')}
                onCancel={() => {
                    setShowDialog(false);
                }}
                onClose={() => setShowDialog(false)}
                onConfirm={handleGenerateExportPortfolio}
            >
                {translate('portfolioPdfExport.portfolioPdfExportDialogText', {
                    userEmail: currentUser?.email,
                })}
            </ConfirmDialog>
        </Box>
    );
};
