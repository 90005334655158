import { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IVideoListItemFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { AddButton } from 'common/components/Button/AddButton';
import {
    getVideoSelectStatus,
    isVideoAdded,
    isVideoSelected,
} from 'training/utils/videoSelect';
import { VideoListItem } from 'video/components/VideoListItem';
import { VideoSelectDrawer } from 'video/components/FormField/VideoSelector/VideoSelectDrawer';

interface IProps {
    name?: string;
    value: IVideoListItemFragment[];
}

export const VideoSelector = ({ name, value }: IProps) => {
    const [translate] = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState<
        IVideoListItemFragment[]
    >([]);

    const addedVideos = value;

    const handleDrawerOpen = (open: boolean) => {
        if (!open) setSelectedVideos([]);

        setDrawerOpen(open);
    };

    const handleSelectVideo = (video: IVideoListItemFragment) => {
        const newVideos = [...selectedVideos, video].sort((a, b) =>
            a.title > b.title ? 1 : -1
        );

        setSelectedVideos(newVideos);
    };

    const handleRemoveVideo = (
        video: IVideoListItemFragment,
        arrayHelpers: FieldArrayRenderProps
    ) => {
        const { isSelected } = getVideoSelectStatus(
            selectedVideos,
            addedVideos,
            video
        );

        if (isSelected) {
            const newSelectedVideos = selectedVideos.filter(
                (selectedVideo) => selectedVideo.id !== video.id
            );

            setSelectedVideos(newSelectedVideos);

            return;
        }

        const index = addedVideos.findIndex(
            (addedVideo) => video.id === addedVideo.id
        );

        arrayHelpers.remove(index);
    };

    const handleAddVideos = (arrayHelpers: FieldArrayRenderProps) => {
        selectedVideos.forEach((selectedVideo) => {
            arrayHelpers.push(selectedVideo);
        });

        setSelectedVideos([]);

        handleDrawerOpen(false);
    };

    const fieldName = name || 'videos';

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const error = form.errors[fieldName];
                const touched = form.touched[fieldName];

                return (
                    <Box>
                        <Box
                            alignItems="left"
                            display="flex"
                            flexDirection="column"
                        >
                            <AddButton onClick={() => handleDrawerOpen(true)}>
                                <Typography>
                                    {translate('addVideosTitle')}
                                </Typography>

                                {error && touched && (
                                    <Box mt="-5px" position="absolute">
                                        <FormHelperText error>
                                            {form.errors[fieldName]}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </AddButton>

                            {!!addedVideos.length && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mt={2}
                                >
                                    {addedVideos.map((addedVideo) => (
                                        <VideoListItem
                                            addButton
                                            added={isVideoAdded(
                                                addedVideos,
                                                addedVideo
                                            )}
                                            key={`added-${addedVideo.id}`}
                                            selected={isVideoSelected(
                                                selectedVideos,
                                                addedVideo
                                            )}
                                            video={addedVideo}
                                            onClick={(
                                                video: IVideoListItemFragment
                                            ) =>
                                                handleRemoveVideo(
                                                    video,
                                                    arrayHelpers
                                                )
                                            }
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>

                        <VideoSelectDrawer
                            addedVideos={addedVideos}
                            open={drawerOpen}
                            selectedVideos={selectedVideos}
                            onAddVideos={() => handleAddVideos(arrayHelpers)}
                            onDrawerOpen={handleDrawerOpen}
                            onRemoveVideo={(video) =>
                                handleRemoveVideo(video, arrayHelpers)
                            }
                            onSelectVideo={handleSelectVideo}
                        />
                    </Box>
                );
            }}
        />
    );
};
