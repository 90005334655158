import {
    mdiSchool,
    mdiClipboardArrowDown,
    mdiPlay,
    mdiShieldCheck,
    mdiCompass,
    mdiFile,
    mdiApi,
    mdiConnection,
    mdiWebhook,
    mdiMapMarker,
} from '@mdi/js';

import { i18n } from 'utils/i18n';
import { IFeature } from 'graphql/types';
import { Icon } from 'common/components/Icon';
import { ITrainingModuleType } from 'training/types';
import {
    LinkModuleCreateDrawer,
    LinkModuleUpdateDrawer,
} from 'training/components/LinkModuleDrawer';
import {
    CourseModuleCreateDrawer,
    CourseModuleUpdateDrawer,
} from 'training/components/CourseModuleDrawer';
import {
    VideoModuleCreateDrawer,
    VideoModuleUpdateDrawer,
} from 'training/components/VideoModuleDrawer';
import VIDEO_MODULE_DRAWER_HEADER from 'assets/images/video-module-drawer-header.webp';
import CERTIFICATE_MODULE_DRAWER_HEADER from 'assets/images/certificate-module-drawer-header.webp';
import ASSIGNMENT_MODULE_DRAWER_HEADER from 'assets/images/assignment-module-drawer-header.webp';
import MEETING_MODULE_DRAWER_HEADER from 'assets/images/meeting-module-drawer-header.webp';
import {
    ExternalContentModuleCreateDrawer,
    ExternalContentModuleUpdateDrawer,
} from 'training/components/ExternalContentModuleDrawer';
import {
    FileModuleCreateDrawer,
    FileModuleUpdateDrawer,
} from 'training/components/FileModuleDrawer';
import {
    CertificateModuleCreateDrawer,
    CertificateModuleUpdateDrawer,
} from 'training/components/CertificateModuleDrawer';
import {
    LTIModuleCreateDrawer,
    LTIModuleUpdateDrawer,
} from 'training/components/LTIModuleDrawer';
import LTI_MODULE_DRAWER_HEADER from 'assets/images/sso-drawer-header.webp';
import {
    AssignmentModuleCreateDrawer,
    AssignmentModuleUpdateDrawer,
} from 'training/components/AssignmentModuleDrawer';
import {
    MeetingModuleCreateDrawer,
    MeetingModuleUpdateDrawer,
} from 'training/components/MeetingModuleDrawer';
import {
    AfasWebhookModuleCreateDrawer,
    AfasWebhookModuleUpdateDrawer,
} from 'training/components/AfasWebhookModuleDrawer';
import AFAS_WEBHOOK_MODULE_DRAWER_HEADER from 'assets/images/afas-webhook-module-drawer-header.webp';
import {
    WebhookModuleCreateDrawer,
    WebhookModuleUpdateDrawer,
} from 'training/components/WebhookModuleDrawer';
import WEBHOOK_MODULE_DRAWER_HEADER from 'assets/images/webhook-module-drawer-header.webp';
import {
    ScormModuleCreateDrawer,
    ScormModuleUpdateDrawer,
} from 'training/components/ScormModuleDrawer';
import SCORM_MODULE_DRAWER_HEADER from 'assets/images/scorm-module-drawer-header.webp';

export const TRAINING_MODULE_TYPES: ITrainingModuleType[] = [
    {
        key: 'course',
        icon: <Icon path={mdiSchool} size="3rem" />,
        createDrawerComponent: CourseModuleCreateDrawer,
        updateDrawerComponent: CourseModuleUpdateDrawer,
    },
    {
        key: 'assignment',
        icon: <Icon path={mdiClipboardArrowDown} size="3rem" />,
        feature: IFeature.Assignment,
        createDrawerComponent: AssignmentModuleCreateDrawer,
        updateDrawerComponent: AssignmentModuleUpdateDrawer,
        upgradeHeaderImage: ASSIGNMENT_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.assignment.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.assignment.upgradeDescription'),
    },
    {
        key: 'video',
        icon: <Icon path={mdiPlay} size="4rem" />,
        feature: IFeature.Video,
        createDrawerComponent: VideoModuleCreateDrawer,
        updateDrawerComponent: VideoModuleUpdateDrawer,
        upgradeHeaderImage: VIDEO_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.video.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.video.upgradeDescription'),
    },
    {
        key: 'file',
        icon: <Icon path={mdiFile} size="3rem" />,
        createDrawerComponent: FileModuleCreateDrawer,
        updateDrawerComponent: FileModuleUpdateDrawer,
    },
    {
        key: 'link',
        icon: <Icon path={mdiCompass} size="3rem" />,
        createDrawerComponent: LinkModuleCreateDrawer,
        updateDrawerComponent: LinkModuleUpdateDrawer,
    },
    {
        key: 'certificate',
        icon: <Icon path={mdiShieldCheck} size="3rem" />,
        feature: IFeature.Certificate,
        createDrawerComponent: CertificateModuleCreateDrawer,
        updateDrawerComponent: CertificateModuleUpdateDrawer,
        upgradeHeaderImage: CERTIFICATE_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.certificate.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.certificate.upgradeDescription'),
    },
    {
        key: 'externalContent',
        icon: <Icon path={mdiSchool} size="3.4rem" />,
        createDrawerComponent: ExternalContentModuleCreateDrawer,
        updateDrawerComponent: ExternalContentModuleUpdateDrawer,
    },
    {
        key: 'ltiModule',
        icon: <Icon path={mdiConnection} size="3rem" />,
        feature: IFeature.Lti,
        createDrawerComponent: LTIModuleCreateDrawer,
        updateDrawerComponent: LTIModuleUpdateDrawer,
        upgradeHeaderImage: LTI_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.lti.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.lti.upgradeDescription'),
    },
    {
        key: 'scorm',
        icon: <Icon path={mdiSchool} size="3.4rem" />,
        feature: IFeature.Scorm,
        createDrawerComponent: ScormModuleCreateDrawer,
        updateDrawerComponent: ScormModuleUpdateDrawer,
        upgradeHeaderImage: SCORM_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.scorm.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.scorm.upgradeDescription'),
    },
    {
        key: 'meeting',
        icon: <Icon path={mdiMapMarker} size="3.4rem" />,
        feature: IFeature.Offers,
        createDrawerComponent: MeetingModuleCreateDrawer,
        updateDrawerComponent: MeetingModuleUpdateDrawer,
        upgradeHeaderImage: MEETING_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.meeting.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.meeting.upgradeDescription'),
        titleTranslationKey: 'moduleForm.meeting.titleTranslation',
    },
    {
        key: 'webhook',
        icon: <Icon path={mdiApi} size="3.6rem" />,
        feature: IFeature.Webhook,
        createDrawerComponent: WebhookModuleCreateDrawer,
        updateDrawerComponent: WebhookModuleUpdateDrawer,
        upgradeHeaderImage: WEBHOOK_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.webhook.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.webhook.upgradeDescription'),
    },
    {
        key: 'afasTrigger',
        icon: <Icon path={mdiWebhook} size="3.4rem" />,
        feature: IFeature.Afas,
        createDrawerComponent: AfasWebhookModuleCreateDrawer,
        updateDrawerComponent: AfasWebhookModuleUpdateDrawer,
        upgradeHeaderImage: AFAS_WEBHOOK_MODULE_DRAWER_HEADER,
        upgradeTitle: i18n.t('moduleForm.afasWebhook.upgradeTitle'),
        upgradeDescription: i18n.t('moduleForm.afasWebhook.upgradeDescription'),
    },
];
