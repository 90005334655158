import { useMemo, useRef, useState, useEffect } from 'react';
import { Box, DialogContentText, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    ApolloCache,
    FetchResult,
    useApolloClient,
    Reference,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import { mdiFileRemove } from '@mdi/js';

import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { DevelopmentItemForm } from 'user/components/forms';
import { TFormValues } from 'user/components/forms/DevelopmentItemForm/DevelopmentItemForm';
import {
    DevelopmentItemFragmentDoc,
    FileFragmentDoc,
    IAddFilesToDevelopmentItemMutation,
    ICreateDevelopmentItemInput,
    IDevelopmentItemFragment,
    IFileFragment,
    IRemoveFilesFromDevelopmentItemMutation,
    IRole,
    useAddFilesToDevelopmentItemMutation,
    useCurrentUserQuery,
    useDeleteDevelopmentItemMutation,
    useRemoveFilesFromDevelopmentItemMutation,
    useUpdateDevelopmentItemMutation,
    useDevelopmentItemLazyQuery,
    IDiscussionObjectType,
    useUpdateNotificationSubscriptionMutation,
    INotificationSubscriptionType,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { DiscussionSection } from 'common/components/DiscussionSection';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { FileTable } from 'common/tables';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Loader } from 'common/components/Loader';
import {
    IDeleteAlertDialogOptions,
    INITIAL_DELETE_ALERT_DIALOG_VALUES,
} from 'common/constants/deleteAlertDialog';
import { PageTitle } from 'common/components/PageTitle';
import { NotificationButton } from 'notification/components/NotificationButton';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { IconButton } from 'common/components/IconButton';

interface IProps extends IPageDrawerProps {
    pageTitle: string;
    mixpanelTitle: string;
    loading?: boolean;
    withEditActions?: boolean;
    onCreateItem(values: ICreateDevelopmentItemInput): void;
    onClose?(): void;
}

export const DevelopmentItemDrawer = ({
    pageTitle,
    mixpanelTitle,
    loading,
    withEditActions,
    onCreateItem,
    onClose,
    ...other
}: IProps) => {
    const { itemId } = useParams();
    const [displaySnackbar] = useSnackbar();
    const [
        fetchDevelopmentItem,
        {
            data: developmentItemData,
            error: developmentItemError,
            loading: developmentItemLoading,
            called: developmentItemCalled,
        },
    ] = useDevelopmentItemLazyQuery();

    const item = !!itemId ? developmentItemData?.developmentItem : undefined;

    const [translate] = useTranslation();
    const [filesAreUpdating, setFilesAreUpdating] = useState(false);
    const [deleteAlertDialog, setDeleteAlertDialog] =
        useState<IDeleteAlertDialogOptions>(INITIAL_DELETE_ALERT_DIALOG_VALUES);
    const uploadingFileRefs = useRef<string[]>([]);
    const { data: currentUserData } = useCurrentUserQuery();
    const client = useApolloClient();
    const slideRef = useRef<HTMLDivElement>(null);
    const isDevelopmentItemCreateView = !!useRouteMatch(
        'DEVELOPMENT_ITEM_CREATE'
    );

    const userRoles = currentUserData?.currentUser?.roles || [];
    const isManager = userRoles.includes(IRole.Manager);

    useEffect(() => {
        if (!itemId) return;

        fetchDevelopmentItem({ variables: { id: itemId } });
    }, [itemId, fetchDevelopmentItem]);

    const handleDevelopmentItemFilesMutationUpdate = (
        cache: ApolloCache<
            | IAddFilesToDevelopmentItemMutation
            | IRemoveFilesFromDevelopmentItemMutation
        >,
        mutationResult: FetchResult,
        filterUploadingFiles?: (fileIds: string[]) => void
    ) => {
        const { data: updatedDevelopmentItemData } = mutationResult;

        const updatedDevelopmentItem: IDevelopmentItemFragment | undefined =
            updatedDevelopmentItemData?.addFilesToDevelopmentItem
                ?.developmentItem ||
            updatedDevelopmentItemData?.removeFilesFromDevelopmentItem
                ?.developmentItem;

        if (!updatedDevelopmentItem) return;

        cache.modify({
            id: `DevelopmentItem:${updatedDevelopmentItem.id}`,
            fields: {
                files() {
                    return updatedDevelopmentItem.files.map((file) =>
                        cache.writeFragment({
                            data: file,
                            fragment: FileFragmentDoc,
                            fragmentName: 'File',
                        })
                    );
                },
            },
        });

        uploadingFileRefs.current = [];

        if (filterUploadingFiles) {
            filterUploadingFiles(
                updatedDevelopmentItem.files.map((file) => file.id)
            );
        }

        setFilesAreUpdating(false);
    };

    const [addFiles, { error: addFilesError }] =
        useAddFilesToDevelopmentItemMutation();

    const [removeFiles, { error: removeFilesError }] =
        useRemoveFilesFromDevelopmentItemMutation();

    const [updateItem, { error: updateError }] =
        useUpdateDevelopmentItemMutation({
            update: () => {
                displaySnackbar(
                    translate('developmentItemActionSuccess.update'),
                    {
                        variant: 'success',
                    }
                );

                onClose && onClose();
            },
        });
    const [deleteItem, { loading: deleteLoading, error: deleteError }] =
        useDeleteDevelopmentItemMutation({
            update: (cache) => {
                if (!item) return;

                // Fetching the latest version of the development item, because the group property might be changed when moved to another group
                const developmentItem: IDevelopmentItemFragment | null =
                    cache.readFragment({
                        id: `${item.__typename}:${item.id}`,
                        fragment: DevelopmentItemFragmentDoc,
                        fragmentName: 'DevelopmentItem',
                    });

                if (!developmentItem || !developmentItem.group) return;

                cache.modify({
                    id: `DevelopmentItemGroup:${developmentItem.group.id}`,
                    fields: {
                        developmentItems(
                            existingDevelopmentItemRefs: readonly (
                                | Reference
                                | IDevelopmentItemFragment
                            )[] = [],
                            { readField }
                        ) {
                            return [
                                ...existingDevelopmentItemRefs.filter(
                                    (itemRef) =>
                                        developmentItem.id !==
                                        readField('id', itemRef)
                                ),
                            ];
                        },
                    },
                });

                displaySnackbar(
                    translate('developmentItemActionSuccess.delete'),
                    {
                        variant: 'success',
                    }
                );

                onClose && onClose();
            },
        });

    const handleSubmit = (values: TFormValues) => {
        const developmentItem = { ...values };

        if (item?.id) {
            return updateItem({
                variables: {
                    id: item.id,
                    developmentItem,
                },
            });
        }

        return onCreateItem(developmentItem);
    };

    const [
        updateSubscriptionNotification,
        {
            loading: updateSubscriptionNotificationLoading,
            error: updateSubscriptionNotificationError,
        },
    ] = useUpdateNotificationSubscriptionMutation({
        update: (cache, mutationResult) => {
            if (
                !item ||
                !mutationResult.data?.updateNotificationSubscription.ok
            ) {
                return;
            }

            cache.modify({
                id: `DevelopmentItem:${item.id}`,
                fields: {
                    notificationSubscription() {
                        return !item.notificationSubscription;
                    },
                },
            });
        },
    });

    let handleAddFile;

    if (!filesAreUpdating) {
        handleAddFile = (uploadedFile: IFileFragment) => {
            if (!item) return;

            // Collection of all the uploaded file ids in a batch
            uploadingFileRefs.current.push(uploadedFile.id);
        };
    }

    let onDeleteItem;

    if (withEditActions && item?.id && !filesAreUpdating) {
        onDeleteItem = () => {
            deleteItem({ variables: { id: item.id } });

            setDeleteAlertDialog({ ...deleteAlertDialog, show: false });
        };
    }

    const { id, title, description } = item || {};

    const sortedFiles = useMemo(() => {
        if (!item) return [];

        // Sorted by upload date, latest first
        const newFiles = [...item.files];

        return newFiles.sort((a, b) => {
            const date1 = new Date(a.uploadDate);
            const date2 = new Date(b.uploadDate);

            return date2.getTime() - date1.getTime();
        });
    }, [item]);

    const initialValues: TFormValues = {
        title: title || '',
        description: description || '',
    };

    const handleUploadFinish = (
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!item) return;

        addFiles({
            variables: {
                id,
                fileIds: uploadingFileRefs.current,
            },
            update: (cache, mutationResult) =>
                handleDevelopmentItemFilesMutationUpdate(
                    cache,
                    mutationResult,
                    filesUpdateCallback
                ),
        });
    };

    const handleRemoveFiles = (
        fileIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!item) return;

        setFilesAreUpdating(true);

        removeFiles({
            variables: {
                id,
                fileIds,
            },
            optimisticResponse: {
                __typename: 'Mutations',
                removeFilesFromDevelopmentItem: {
                    __typename: 'RemoveFilesFromDevelopmentItem',
                    developmentItem: {
                        ...item,
                        files: item.files.filter(
                            (file) => !fileIds.includes(file.id)
                        ),
                    },
                },
            },
            update: (cache, mutationResult) =>
                handleDevelopmentItemFilesMutationUpdate(
                    cache,
                    mutationResult,
                    filesUpdateCallback
                ),
        });
    };

    const fileTableActions = (
        selected: string[],
        onSelectAll: () => void,
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        const selectedFileIds =
            sortedFiles
                ?.filter((file) => {
                    const isOwner =
                        file?.owner &&
                        currentUserData?.currentUser &&
                        file.owner.id === currentUserData.currentUser.id;

                    // Check if user can select the file by checking if the user is owner or is a manager
                    return selected.includes(file.id) && (isOwner || isManager);
                })
                .map((file) => file.id) || [];

        return (
            <Tooltip title={translate<string>('delete')}>
                <Box>
                    <IconButton
                        color="inherit"
                        disabled={!selected.length}
                        iconPath={mdiFileRemove}
                        iconSize="2.4rem"
                        onClick={() => {
                            setDeleteAlertDialog({
                                show: true,
                                callback: () => {
                                    setDeleteAlertDialog({
                                        title: translate(
                                            'deleteFileMessage.title'
                                        ),
                                        text: translate(
                                            'deleteFileMessage.text'
                                        ),
                                        show: false,
                                    });

                                    handleRemoveFiles(
                                        selectedFileIds,
                                        filesUpdateCallback
                                    );

                                    // Deselect all
                                    onSelectAll();
                                },
                                title: translate('deleteFileMessage.title'),
                                text: translate('deleteFileMessage.text'),
                            });
                        }}
                    />
                </Box>
            </Tooltip>
        );
    };

    const handleAddFileComment = (file: IFileFragment) => {
        const fileAlreadyPresent = !!sortedFiles.filter(
            (existingFile) => existingFile.id === file.id
        ).length;

        if (!item || fileAlreadyPresent) return;

        client.writeFragment({
            id: client.cache.identify(item),
            fragment: DevelopmentItemFragmentDoc,
            data: { ...item, files: [...item.files, file] },
            fragmentName: 'DevelopmentItem',
        });
    };

    const handleCreateNotificationSubscription = () => {
        if (!item) return;

        updateSubscriptionNotification({
            variables: {
                active: !item.notificationSubscription,
                objectId: item.id,
                subscriptionType:
                    INotificationSubscriptionType.DevelopmentPlanItem,
            },
        });
    };

    const isLoading = loading || developmentItemLoading;

    const drawerTitle = !isLoading
        ? !item
            ? translate('newDevelopmentItem')
            : item.title
        : undefined;

    const mountPageTitle = !!itemId
        ? developmentItemCalled && !isLoading
        : isDevelopmentItemCreateView;

    const drawerActions = itemId && item && (
        <NotificationButton
            active={!!item?.notificationSubscription}
            disabled={updateSubscriptionNotificationLoading}
            onClick={handleCreateNotificationSubscription}
        />
    );

    return (
        <PageDrawer
            actions={drawerActions}
            disableClose={filesAreUpdating}
            hideCloseIcon={filesAreUpdating}
            SlideProps={{ ref: slideRef }}
            title={drawerTitle}
            onClose={filesAreUpdating ? undefined : onClose}
            {...other}
        >
            {developmentItemError && (
                <ApolloError error={developmentItemError} />
            )}
            {updateError && <ApolloError error={updateError} />}
            {deleteError && <ApolloError error={deleteError} />}
            {addFilesError && <ApolloError error={addFilesError} />}
            {removeFilesError && <ApolloError error={removeFilesError} />}
            {updateSubscriptionNotificationError && (
                <ApolloError error={updateSubscriptionNotificationError} />
            )}

            {mountPageTitle && (
                <PageTitle
                    mixpanelTitle={`${mixpanelTitle} - ${
                        item ? 'Edit' : 'New'
                    } development plan item`}
                >{`${pageTitle} - ${
                    item ? item.title : drawerTitle
                }`}</PageTitle>
            )}

            {isLoading && <Loader />}

            {!isLoading && (
                <>
                    {!withEditActions && item?.description && (
                        <Box p={4}>
                            <Text component="div">
                                <Box lineHeight="1.6">{item.description}</Box>
                            </Text>
                        </Box>
                    )}

                    {withEditActions && (
                        <DevelopmentItemForm
                            disabled={filesAreUpdating}
                            initialValues={initialValues}
                            isDeleting={deleteLoading}
                            onDelete={() =>
                                setDeleteAlertDialog({
                                    show: true,
                                    title: translate(
                                        'deleteDevelopmentItemMessage.title'
                                    ),
                                    text: translate(
                                        'deleteDevelopmentItemMessage.text'
                                    ),
                                })
                            }
                            onSubmit={handleSubmit}
                        />
                    )}

                    {!item && (
                        <Box
                            p={{ xs: 2, sm: 4 }}
                            sx={{
                                borderTop: 1,
                                borderTopColor: 'divider',
                            }}
                        >
                            <Typography>
                                {translate(
                                    'developmentItemFileDiscussionAdding'
                                )}
                            </Typography>
                        </Box>
                    )}

                    {item && (
                        <Box
                            p={{ xs: 2, sm: 4 }}
                            sx={{
                                borderTop: 1,
                                borderTopColor: 'divider',
                            }}
                        >
                            <FileTable
                                inDrawer
                                withOwnerColumn
                                currentUserSelection={!isManager}
                                id="developmentItem"
                                items={sortedFiles || []}
                                renderSelectedActions={fileTableActions}
                                onAdd={handleAddFile}
                                onRemove={handleRemoveFiles}
                                onUploadFinish={handleUploadFinish}
                                onUploadStart={() => setFilesAreUpdating(true)}
                            />
                        </Box>
                    )}

                    {item && itemId === item.id && (
                        <Box
                            p={{ xs: 2, sm: 4 }}
                            sx={{
                                borderTop: 1,
                                borderTopColor: 'divider',
                            }}
                        >
                            <DiscussionSection
                                discussionId={item.discussion?.id}
                                forType={
                                    IDiscussionObjectType.DevelopmentPlanItem
                                }
                                parentRef={slideRef}
                                onAddFileComment={handleAddFileComment}
                            />
                        </Box>
                    )}
                </>
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={deleteAlertDialog.callback || onDeleteItem}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setDeleteAlertDialog({
                                    ...deleteAlertDialog,
                                    show: false,
                                });
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={deleteAlertDialog.show}
                title={deleteAlertDialog.title}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {deleteAlertDialog.text}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
