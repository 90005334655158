import { useState, useMemo, useEffect } from 'react';
import {
    mdiChevronRight,
    mdiMinus,
    mdiPlus,
    mdiPlay,
    mdiDotsVertical,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { CircularProgress, MenuItem } from '@mui/material';

import {
    useDeleteVideoMutation,
    IVideoListItemFragment,
    VideoDocument,
    useUpdatedVideoSubscription,
    IVideoStatus,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
    ListItemFooter,
    ListItemButton,
} from 'common/components/ListItem';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { getVideoStatusChip } from 'video/utils/video';
import { VideoUpdateDrawer } from 'video/components/VideoManager';
import { VideoPlayerDialog } from 'video/components/VideoPlayerDialog';
import { useFileUpload } from 'common/hooks/useFileUpload';
import { ChipGroup } from 'common/components/Chip';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    loading?: boolean;
    selected?: boolean;
    video: IVideoListItemFragment;
    showActions?: boolean;
    footer?: React.ReactNode;
    onClick?(course: IVideoListItemFragment): void;
}

export const VideoListItem = ({
    addButton,
    added,
    disabled,
    loading,
    selected,
    video,
    onClick,
    showActions,
    footer,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const { uploads } = useFileUpload();

    const [currentVideo, setCurrentVideo] =
        useState<IVideoListItemFragment>(video);

    const [showVideoUpdateDrawer, setShowVideoUpdateDrawer] = useState(false);
    const [showVideoDialog, setShowVideoDialog] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const { id, title, thumbnail, embedUrl, status, extraCategoryValues } =
        currentVideo;

    // Create subscription for video updates
    useUpdatedVideoSubscription({
        variables: { id: video.id },
        // Don't need to subscribe to updates if video is finished
        skip: status === IVideoStatus.Finished,
        onData: ({ data: { data } }) => {
            const video = data?.updatedVideo || null;

            if (!video) return;

            setCurrentVideo(video);
        },
    });

    const [deleteVideo, { loading: loadingDelete }] = useDeleteVideoMutation();

    const listItemImage = thumbnail?.url || '';

    let mediaIconPath = mdiPlay;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }

    const hasIcon = (disabled && !listItemImage) || !disabled;

    const videoUpload = useMemo(() => {
        if (!uploads?.length) return;

        return uploads.find((upload) => upload.referenceId === id);
    }, [id, uploads]);

    useEffect(() => {
        setCurrentVideo(video);
    }, [video]);

    const handleDeleteVideo = async () => {
        try {
            await deleteVideo({
                variables: {
                    id,
                },
                update: (cache) => {
                    // Remove videos list from cache so it will be refetched
                    cache.evict({ fieldName: 'videos' });

                    // Use writeQuery to update video query cache so it's properly removed
                    cache.writeQuery({
                        query: VideoDocument,
                        data: { video: null },
                        variables: { id },
                    });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);
            setShowDeleteAlert(false);

            return;
        }

        displaySnackbar(translate('videoActionSuccess.delete'), {
            variant: 'success',
        });

        setShowDeleteAlert(false);
    };

    const itemActionMenu = showActions && (
        <DropdownMenu
            anchor={
                <IconButton
                    color="inherit"
                    iconPath={mdiDotsVertical}
                    iconSize="2.4rem"
                />
            }
            placement="bottom-end"
        >
            {embedUrl && (
                <MenuItem
                    disabled={loadingDelete}
                    onClick={() => setShowVideoDialog(true)}
                >
                    {translate('view')}
                </MenuItem>
            )}
            <MenuItem
                disabled={loadingDelete}
                onClick={() => setShowVideoUpdateDrawer(true)}
            >
                {translate('edit')}
            </MenuItem>
            <MenuItem
                disabled={loadingDelete}
                onClick={() => {
                    setShowDeleteAlert(true);
                }}
            >
                {translate('delete')}
            </MenuItem>
        </DropdownMenu>
    );

    const showActionText = !itemActionMenu && !addButton && !disabled;

    const baseContent = (
        <>
            <ListItemMedia color="primary" image={listItemImage} size="small">
                {hasIcon && <Icon path={mediaIconPath} size="3rem" />}
            </ListItemMedia>

            <ListItemText primary={title}>
                {getVideoStatusChip(status, videoUpload)}

                <ChipGroup sx={{ my: 0.25 }}>
                    {!!extraCategoryValues?.length && (
                        <ExtraCategoryLabels
                            chipProps={{ size: 'small' }}
                            extraCategoryValues={extraCategoryValues}
                        />
                    )}
                </ChipGroup>
            </ListItemText>

            {showActionText && (
                <>
                    {!loading && (
                        <ListItemActionText>
                            {translate('open')}
                        </ListItemActionText>
                    )}
                    <ListItemSecondaryAction>
                        {loading ? (
                            <CircularProgress color="primary" size={30} />
                        ) : (
                            <Icon path={mdiChevronRight} size="3rem" />
                        )}
                    </ListItemSecondaryAction>
                </>
            )}
        </>
    );

    return (
        <>
            <ListItem
                button={!disabled}
                key={id}
                px={0}
                selected={selected}
                {...other}
            >
                {disabled ? (
                    baseContent
                ) : (
                    <ListItemButton
                        onClick={() => !disabled && onClick?.(video)}
                    >
                        {baseContent}
                    </ListItemButton>
                )}

                {itemActionMenu && (
                    <ListItemSecondaryAction hideXs>
                        {itemActionMenu}
                    </ListItemSecondaryAction>
                )}

                {footer && <ListItemFooter>{footer}</ListItemFooter>}
            </ListItem>

            <VideoUpdateDrawer
                id={video.id}
                open={showVideoUpdateDrawer}
                onClose={() => setShowVideoUpdateDrawer(false)}
            />

            {embedUrl && (
                <VideoPlayerDialog
                    open={showVideoDialog}
                    src={embedUrl}
                    title={title}
                    onClose={() => setShowVideoDialog(false)}
                />
            )}

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDelete}
                open={showDeleteAlert}
                title={translate('deleteVideoMessage.title')}
                onCancel={() => {
                    setShowDeleteAlert(false);
                }}
                onClose={() => setShowDeleteAlert(false)}
                onConfirm={handleDeleteVideo}
            >
                {translate('deleteVideoMessage.text')}
            </ConfirmDialog>
        </>
    );
};
