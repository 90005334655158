import { Grid } from '@mui/material';
import { Field, FieldArray } from 'formik';
import * as Yup from 'yup';

import { IScoringSystemFragment } from 'graphql/types';
import { i18n } from 'utils';

import { TextField } from '../TextField/TextField';

export const FIELDS_VALIDATION = Yup.array()
    .of(
        Yup.object().shape({
            score: Yup.string()
                .matches(/^\d+(\.\d+)?(,\d+)?$/, i18n.t('mustBeANumber'))
                .optional(),
            scoringSystemId: Yup.string(),
        })
    )
    .optional();

interface IProps {
    scoringSystems?: IScoringSystemFragment[];
}

export const ScoringSystemFields = ({ scoringSystems }: IProps) => {
    if (!scoringSystems?.length) return null;

    return (
        <FieldArray
            name="scores"
            render={() => (
                <Grid container columnSpacing={2}>
                    {scoringSystems.map((scoringSystem, index) => (
                        <Grid item key={`score-${index}`} sm={4} xs={12}>
                            <Field
                                component={TextField}
                                label={scoringSystem.namePlural}
                                name={`scores[${index}].score`}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        />
    );
};
