import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiTablePivot, mdiViewList } from '@mdi/js';
import { Box } from '@mui/material';

import {
    ITrainingFragment,
    ITrainingUserEdgeFragment,
    ITrainingRole,
} from 'graphql/types';
import { TTableAction } from 'common/types';
import { useFrontendPermissions } from 'user/hooks';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { ButtonToggle } from 'common/components/ButtonToggle';
import { BoxLoader } from 'common/components/Loader';
import { TrainingParticipantTable } from 'training/components/TrainingParticipantTable';
import { TrainingParticipantMatrix } from 'training/components/TrainingParticipantMatrix';
import { FilterBar } from 'common/components/FilterBar';
import { parseJSON } from 'common/utils/json';
import { ITableDataProps } from 'training/components/Section/Participants';
import { InlineStickyWrapper } from 'common/components/InlineStickyWrapper';

enum EView {
    List = 'list',
    Matrix = 'matrix',
}

interface IProps {
    isEditable?: boolean;
    loading?: boolean;
    participants?: ITrainingUserEdgeFragment[];
    tableActions?: TTableAction[];
    tableDataProps: ITableDataProps;
    training: ITrainingFragment;
}

export const TrainingParticipantView = ({
    isEditable,
    loading,
    participants,
    tableActions,
    tableDataProps,
    training,
}: IProps) => {
    const [translate] = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const routeQuery = useRouteQuery();

    const { canUpdate: canUpdateTraining } = useFrontendPermissions('training');

    const localStorageView = localStorage.getItem('trainingParticipantsView');
    const currentRouteView = routeQuery.get('view');
    const parsedLocalStorageView =
        !!localStorageView && parseJSON(localStorageView);
    const viewParam = parsedLocalStorageView || currentRouteView;

    const { paginationProps, searchProps, selectionProps } = tableDataProps;
    const { setPage } = paginationProps || {};
    const {
        filters,
        filterValues,
        searchQueryParam,
        onFilterChange,
        onSearch,
    } = searchProps;
    const { selected, onSelectAll } = selectionProps || {};

    const isGroupManager = !!training.rolesForCurrentUser?.includes(
        ITrainingRole.Groupmanager
    );
    const isTrainer = !!training.rolesForCurrentUser?.includes(
        ITrainingRole.Trainer
    );
    const isMentor = !!training.rolesForCurrentUser?.includes(
        ITrainingRole.Mentor
    );

    const canManageTraining = isTrainer || isMentor || canUpdateTraining;
    const canViewMatrix = isGroupManager || canManageTraining;

    const navigateToView = (view: EView) => {
        const newSearchParams = new URLSearchParams(window.location.search);

        if (newSearchParams.get('view') === view) return;

        newSearchParams.set('view', view);

        navigate(
            { ...location, search: `?${newSearchParams.toString()}` },
            { replace: true }
        );
    };

    const setLocalStorageView = (view: EView) => {
        const newLocalStorageView = view;

        localStorage.setItem(
            'trainingParticipantsView',
            JSON.stringify(newLocalStorageView)
        );

        navigateToView(view);
    };

    useEffect(() => {
        if (!!localStorageView && !currentRouteView) {
            const currentView = parsedLocalStorageView || EView.Matrix;

            navigateToView(currentView);
        }
    }, [localStorageView, currentRouteView]);

    const currentView =
        !canViewMatrix || !viewParam || viewParam === EView.List
            ? EView.List
            : EView.Matrix;

    const viewToggle = canViewMatrix ? (
        <ButtonToggle
            buttons={[
                {
                    key: EView.List,
                    icon: mdiViewList,
                },
                {
                    key: EView.Matrix,
                    icon: mdiTablePivot,
                },
            ]}
            value={currentView}
            onClickButton={(key) => {
                if (key === EView.Matrix) {
                    setLocalStorageView(EView.Matrix);

                    return;
                }

                setLocalStorageView(EView.List);
            }}
        />
    ) : null;

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);

        if (!!selected?.length) onSelectAll?.();
    };

    return (
        <Box>
            <InlineStickyWrapper fullWidth leftOffset={3}>
                <FilterBar
                    useFilterDrawer
                    barPrepend={viewToggle}
                    disabled={loading}
                    filterDrawerProps={{
                        title: translate(
                            'filterForm.trainingParticipants.title'
                        ),
                        description: translate(
                            'filterForm.trainingParticipants.description'
                        ),
                    }}
                    formFilters={filters}
                    formFilterValues={filterValues}
                    initialSearchValue={searchQueryParam}
                    placeholder={translate('filterBarPlaceholder')}
                    onFiltersChange={(values) => {
                        setPage?.(1);
                        if (!!selected?.length) onSelectAll?.();
                        onFilterChange?.(values);
                    }}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch('')}
                />
            </InlineStickyWrapper>

            {loading ? (
                <BoxLoader />
            ) : currentView === EView.List || !canViewMatrix ? (
                <TrainingParticipantTable
                    canManageTraining={canManageTraining}
                    isEditable={isEditable}
                    isGroupManager={isGroupManager}
                    paginationProps={paginationProps}
                    participants={participants}
                    selectionProps={selectionProps}
                    tableActions={tableActions}
                    training={training}
                />
            ) : (
                <TrainingParticipantMatrix
                    canManageTraining={canManageTraining}
                    isGroupManager={isGroupManager}
                    paginationProps={paginationProps}
                    participants={participants}
                    training={training}
                />
            )}
        </Box>
    );
};
