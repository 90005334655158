import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { IsAuthenticated } from 'common/components/IsAuthenticated';
import { useFeature } from 'common/hooks/useFeature';
import { IFeature } from 'graphql/types';
import { UpgradePage } from 'common/components/UpgradePage';
import { Loader } from 'common/components/Loader';
import { UpgradeContent } from 'offer/components/UpgradeContent';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';

import { BasePage } from './BasePage';
import { FourOFourPage } from './FourOFourPage';

export const OffersBasePage = () => {
    const {
        canUse: canUseOffers,
        loading: loadingCanUse,
        userCanSeeUpgrade,
    } = useFeature(IFeature.Offers);

    const { resetFilterParams } = useUrlFilterParams('offer');

    // Reset filters initially on mount
    useEffect(() => {
        resetFilterParams();
    }, [resetFilterParams]);

    const hasOfferModule = !loadingCanUse && canUseOffers;
    const showUpgradePage = !hasOfferModule && userCanSeeUpgrade;

    if (loadingCanUse) return <Loader />;

    return (
        <IsAuthenticated>
            {!hasOfferModule && !showUpgradePage && <FourOFourPage />}

            {hasOfferModule && <Outlet />}

            {showUpgradePage && (
                <BasePage contentContainerMaxWidth="lg">
                    <UpgradePage>
                        <UpgradeContent />
                    </UpgradePage>
                </BasePage>
            )}
        </IsAuthenticated>
    );
};
