import { useEffect, useState } from 'react';

import {
    IPlanFragment,
    IRole,
    useCurrentUserQuery,
    usePlanPackagesQuery,
} from 'graphql/types';
import {
    IPlanModalType,
    TCurrentPlanAmounts,
    TPlanModal,
    TPlanPackage,
} from 'plans/types';
import {
    isCustomPlan,
    isPlanDowngrade,
    isWithinSameInterval,
} from 'plans/utils/plan';
import { getUrl } from 'route/utils/getUrl';
import { useApolloError } from 'common/hooks/useApolloError';
import { useMixpanel } from 'common/hooks/useMixpanel';

interface IProps {
    currentAmounts: TCurrentPlanAmounts;
    currentPlan: IPlanFragment;
    planPackage: TPlanPackage;
    selectedPlan: IPlanFragment;
}

export const usePlanUpgrade = ({
    currentAmounts,
    currentPlan,
    planPackage,
    selectedPlan,
}: IProps) => {
    const {
        data: currentUserData,
        loading: currentUserDataLoading,
        error: currentUserError,
    } = useCurrentUserQuery();
    const { data: planPackagesData } = usePlanPackagesQuery();
    const { showApolloError } = useApolloError();
    const { trackMixpanel } = useMixpanel();
    const [openModalType, setOpenModalType] = useState<TPlanModal>();

    const { planPackages } = planPackagesData || {};

    useEffect(() => {
        if (currentUserError) showApolloError(currentUserError);
    }, [currentUserError, showApolloError]);

    const currentUserIsOwner = () => {
        const { currentUser } = currentUserData || {};

        return !!currentUser?.roles?.includes(IRole.Owner);
    };

    const limitIsExceeded = () =>
        (currentAmounts.trainingAmount > selectedPlan.trainingCountLimit &&
            selectedPlan.trainingCountLimit > 0) ||
        (currentAmounts.userAmount > selectedPlan.userCountLimit &&
            selectedPlan.userCountLimit > 0);

    const handleClickUpgrade = () => {
        if (!planPackages) return;

        trackMixpanel({
            eventName: `Plans click upgrade - ${planPackage.title}`,
            properties: { userAmount: selectedPlan.userCountLimit },
        });

        const isCustom = isCustomPlan(currentPlan, planPackages);

        const limitExceeded = limitIsExceeded();
        const isOwner = currentUserIsOwner();

        const isDowngrade = planPackages
            ? isPlanDowngrade(
                  currentPlan,
                  planPackages,
                  planPackage,
                  selectedPlan
              )
            : false;
        const canUpgrade =
            !limitExceeded && !isCustom && isOwner && !isDowngrade;

        // Redirect to billing page if user can upgrade
        if (canUpgrade) {
            const intervalChange =
                !isWithinSameInterval(currentPlan, selectedPlan) && !isCustom;

            if (intervalChange && !currentPlan.isBasic) {
                setOpenModalType(IPlanModalType.IntervalChange);

                return;
            }

            const baseUrl = import.meta.env.VITE_BASE_URL || '';
            const paymentUrl = `${baseUrl}${getUrl(
                'MANAGEMENT_ORGANISATION_PAYMENTDETAILS'
            )}?plan=${selectedPlan.id}`;

            window.location.href = paymentUrl;

            return;
        }

        if (!isOwner) {
            setOpenModalType(IPlanModalType.PlanManager);

            return;
        }

        if (isCustom) {
            setOpenModalType(IPlanModalType.CustomPlan);

            return;
        }

        if (isDowngrade) {
            setOpenModalType(IPlanModalType.PlanDowngrade);

            return;
        }

        if (limitExceeded) setOpenModalType(IPlanModalType.PlanLimit);
    };

    return {
        currentUserDataLoading,
        openModalType,
        handleClickUpgrade,
        setOpenModalType,
    };
};
