import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import {
    IModuleGroupConditionType,
    useDeleteModuleGroupMutation,
    useModuleGroupEditQuery,
    useUpdateModuleGroupMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    getConditionFormValues,
    getConditionInitialValues,
} from 'training/utils/moduleGroup';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { TModuleGroupFormValues } from 'training/types';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { ModuleGroupForm } from 'training/components/forms/ModuleGroupForm';
import { PageTitle } from 'common/components/PageTitle';
import { Loader } from 'common/components/Loader';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const ModuleGroupUpdateDrawer = ({
    open,
    onClose,
    ...other
}: IProps) => {
    const { moduleGroupId } = useParams();
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [updateModuleGroup, { loading: updateModuleGroupLoading }] =
        useUpdateModuleGroupMutation();
    const [deleteModuleGroup, { loading: deleteModuleGroupLoading }] =
        useDeleteModuleGroupMutation();
    const { data, loading } = useModuleGroupEditQuery({
        variables: { id: moduleGroupId },
        skip: !open || !moduleGroupId || deleteModuleGroupLoading,
    });

    const { moduleGroup } = data || {};
    const { title, description, fullConditions } = moduleGroup || {};

    const condition = fullConditions?.[0];
    const conditionType =
        condition?.conditionType || IModuleGroupConditionType.NoCondition;

    const handleSubmit = async (values: TModuleGroupFormValues) => {
        if (!moduleGroupId) return;

        const {
            conditionType,
            relativeCondition,
            absoluteCondition,
            courseCompletionCondition,
            ...otherValues
        } = values;

        const conditionValues = conditionType
            ? getConditionFormValues(conditionType, values)
            : {};

        try {
            await updateModuleGroup({
                variables: {
                    id: moduleGroupId,
                    moduleGroup: {
                        conditionType,
                        ...otherValues,
                        ...conditionValues,
                    },
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleGroupForm.updateSuccess'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModuleGroup({
                variables: { id: moduleGroupId },
                update: (cache) => {
                    cache.evict({ id: `ModuleGroup:${moduleGroupId}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        setShowDeleteDialog(false);

        displaySnackbar(translate('moduleGroupForm.deleteSuccess'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    const initialConditionValues = conditionType
        ? getConditionInitialValues(
              conditionType,
              // When changing condition type and not matching the existing we want an empty form
              conditionType === condition?.conditionType ? condition : undefined
          )
        : {};

    const initialValues: TModuleGroupFormValues = {
        title,
        description,
        conditionType,
        ...initialConditionValues,
    };

    const mountPageTitle = !loading && !!moduleGroup;

    return (
        <PageDrawer
            disableClose={updateModuleGroupLoading || deleteModuleGroupLoading}
            open={open}
            onClose={onClose}
            {...other}
        >
            {loading && <Loader />}

            {open && !loading && (
                <>
                    {mountPageTitle && (
                        <PageTitle mixpanelTitle="Drawer module group update form">
                            {`${title} - ${translate(
                                'moduleGroupForm.pageTitleUpdate'
                            )}`}
                        </PageTitle>
                    )}

                    <ModuleGroupForm
                        edit
                        condition={condition}
                        initialValues={initialValues}
                        loading={
                            updateModuleGroupLoading || deleteModuleGroupLoading
                        }
                        onDelete={() => setShowDeleteDialog(true)}
                        onSubmit={handleSubmit}
                    />

                    <ConfirmDialog
                        color="error"
                        confirmText={translate('delete')}
                        loading={deleteModuleGroupLoading}
                        open={showDeleteDialog}
                        title={translate('moduleGroupForm.deleteDialog.title')}
                        onCancel={() => {
                            setShowDeleteDialog(false);
                        }}
                        onClose={() => setShowDeleteDialog(false)}
                        onConfirm={handleDelete}
                    >
                        {translate('moduleGroupForm.deleteDialog.text')}
                    </ConfirmDialog>
                </>
            )}
        </PageDrawer>
    );
};
