import { useState, useEffect } from 'react';
import { mdiFolder, mdiFileDocument } from '@mdi/js';
import { DropResult } from '@hello-pangea/dnd';

import {
    DragDropContext,
    Draggable,
    Droppable,
} from 'common/components/DragDrop';
import { LibraryListItem } from 'library/components/LibraryListItem';
import { List } from 'common/components/List';
import {
    ISubCollectionFragment,
    SubCollectionFragmentDoc,
    ICollectionArticleFragment,
    CollectionArticleFragmentDoc,
    useUpdateCollectionsMutation,
    useUpdateArticlesMutation,
} from 'graphql/types';
import { reorder } from 'common/utils/draggable';

type IListItem =
    | Partial<ISubCollectionFragment>
    | Partial<ICollectionArticleFragment>;

interface IProps {
    collectionId?: string;
    droppableType?: 'COLLECTION' | 'ARTICLE';
    canSort?: boolean;
    items: IListItem[];
}

export const LibraryItemsList = ({
    items,
    collectionId,
    droppableType,
    canSort,
}: IProps) => {
    const [listItems, setListItems] = useState<IListItem[]>([]);

    useEffect(() => {
        setListItems(items);
    }, [items]);

    const [updateCollectionsOrder] = useUpdateCollectionsMutation({
        update: (cache, { data }) => {
            const updatedCollections =
                data?.updateLibraryCollections?.collections;

            if (!updatedCollections) return;

            cache.modify({
                id: `LibraryCollection:${collectionId}`,
                fields: {
                    collections() {
                        // To make sure we update the order and references correctly we map over the
                        // updatedCollections and use writeFragment to return the ref.
                        return updatedCollections.map((collection) =>
                            cache.writeFragment({
                                id: cache.identify(collection),
                                fragment: SubCollectionFragmentDoc,
                                data: collection,
                            })
                        );
                    },
                },
            });
        },
    });
    const [updateArticlesOrder] = useUpdateArticlesMutation({
        update: (cache, { data }) => {
            const updatedArticles = data?.updateLibraryArticles?.articles;

            if (!updatedArticles) return;

            cache.modify({
                id: `LibraryCollection:${collectionId}`,
                fields: {
                    articles() {
                        // To make sure we update the order and references correctly we map over the
                        // updatedArticles and use writeFragment to return the ref.
                        return updatedArticles.map((article) =>
                            cache.writeFragment({
                                id: cache.identify(article),
                                fragment: CollectionArticleFragmentDoc,
                                data: article,
                            })
                        );
                    },
                },
            });
        },
    });

    const handleDragEnd = ({ type, destination, source }: DropResult) => {
        if (!destination) return;

        const orderedItems = reorder(
            listItems,
            source.index,
            destination.index
        );

        setListItems(orderedItems);

        if (type === 'COLLECTION') {
            updateCollectionsOrder({
                variables: {
                    orderIds: orderedItems.map((item) => item.id),
                },
            });

            return;
        }

        updateArticlesOrder({
            variables: {
                orderIds: orderedItems.map((item) => item.id),
            },
        });
    };

    const renderListItem = (listItem: IListItem) => {
        const { id, title, description } = listItem;

        if (listItem.__typename === 'LibraryCollection') {
            return (
                <LibraryListItem
                    color="secondary"
                    description={description}
                    hidden={!listItem.active}
                    icon={mdiFolder}
                    params={{ id }}
                    title={title}
                    to="LIBRARY_COLLECTION"
                />
            );
        }

        return (
            <LibraryListItem
                showFullSecondary
                color="primary"
                description={description}
                icon={mdiFileDocument}
                params={{ id }}
                title={title}
                to="LIBRARY_ARTICLE"
            />
        );
    };

    const itemNodes = listItems.map((listItem, index) => (
        <Draggable
            dragDropDisabled={!canSort}
            draggableId={listItem.id}
            index={index}
            key={listItem.id}
        >
            {renderListItem(listItem)}
        </Draggable>
    ));

    return (
        <List>
            <DragDropContext
                dragDropDisabled={!canSort}
                onDragEnd={handleDragEnd}
            >
                <Droppable
                    dragDropDisabled={!canSort}
                    droppableId={droppableType}
                    type={droppableType}
                >
                    {itemNodes}
                </Droppable>
            </DragDropContext>
        </List>
    );
};
