import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { AlertDialog, AlertDialogProps } from 'common/components/AlertDialog';
import { Typography } from 'common/components/Typography';
import { IconButton } from 'common/components/IconButton';
import { IPlanFragment } from 'graphql/types';
import { isIntervalDowngrade, isIntervalUpgrade } from 'plans/utils/plan';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';

interface IProps extends AlertDialogProps {
    buttonText: string;
    currentPlan: IPlanFragment;
    selectedPlan: IPlanFragment;
    onClose?(): void;
}

export const IntervalChangeModal = ({
    buttonText,
    currentPlan,
    selectedPlan,
    onClose,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();

    const title = (
        <Box alignItems="center" display="flex">
            <Typography fontWeight={700}>
                {translate('plans.upgradeModal.upgrade')}
            </Typography>

            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    const intervalDowngrade = isIntervalDowngrade(currentPlan, selectedPlan);
    const intervalUpgrade = isIntervalUpgrade(currentPlan, selectedPlan);

    return (
        <AlertDialog {...other} title={title} onClose={onClose}>
            {(intervalDowngrade || intervalUpgrade) && (
                <Box mb={2}>
                    <Typography>
                        {translate(
                            `plans.interval.${
                                intervalDowngrade
                                    ? 'intervalToMonth'
                                    : 'intervalToYear'
                            }`
                        )}
                    </Typography>
                </Box>
            )}

            <Button
                color="primary"
                component={Link}
                query={`?plan=${selectedPlan.id}`}
                sx={{ width: '100%' }}
                to="MANAGEMENT_ORGANISATION_PAYMENTDETAILS"
                variant="contained"
            >
                {buttonText}
            </Button>
        </AlertDialog>
    );
};
