import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    DialogContentText,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableContainer,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
    TableActionBar,
    TableCell,
    TableDropdownCell,
    useSelection,
} from 'common/components/Table';
import { IFileFragment, useCurrentUserQuery } from 'graphql/types';
import { Link } from 'common/components/Link';
import { FilePicker } from 'common/components/FilePicker';
import { Typography } from 'common/components/Typography';
import { formatDate } from 'common/utils/formatDate';
import { useUploadFiles } from 'common/hooks/useUploadFiles';
import { LinearProgress } from 'common/components/LinearProgress';
import { DEFAULT_MAX_FILE_SIZE } from 'common/constants/files';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { Checkbox } from 'common/components/Checkbox';
import { AddButton } from 'common/components/Button/AddButton';

interface IProps {
    currentUserSelection?: boolean;
    maxFileSize?: number;
    title?: string;
    id: string; // Use an unique id when you have multiple file uploads on the same page
    items: IFileFragment[];
    withOwnerColumn?: boolean;
    chips?: React.ReactNode;
    readonly?: boolean;
    inDrawer?: boolean;
    onAdd?: (uploadedFile: IFileFragment) => void;
    renderSelectedActions?: (
        selected: string[],
        onSelectAll: () => void,
        filesUpdateCallback: (fileIds: string[]) => void
    ) => React.ReactNode;
    onRemove?: (
        fileIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => void;
    onUploadFinish?: (filesUpdateCallback: (fileIds: string[]) => void) => void;
    onUploadStart?: () => void;
}

export const FileTable = ({
    currentUserSelection,
    maxFileSize,
    title = 'documents',
    id,
    items,
    withOwnerColumn,
    chips,
    readonly,
    inDrawer,
    onRemove,
    onAdd,
    onUploadFinish,
    onUploadStart,
    renderSelectedActions,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);

    // Keep value of the item where an action (e.g. delete) is applied to
    const currentActionIdRef = useRef<string>();

    const { isUploading, uploadFiles, uploadingFiles, setUploadingFiles } =
        useUploadFiles({
            onSuccess: (_fileIndex, updatedFile) => {
                onAdd && onAdd(updatedFile);
            },
            onError: (fileName, message) => {
                displaySnackbar(`${fileName}: ${message}`, {
                    autoHideDuration: 30000,
                });
            },
        });
    const { data: currentUserData } = useCurrentUserQuery();
    const prevIsUploading = useRef(isUploading);
    const [actionsDisabled, setActionsDisabled] = useState(false);

    const withSelection = !!renderSelectedActions;
    const { currentUser } = currentUserData || {};

    const selectFilter = (item: IFileFragment) =>
        !currentUserSelection ||
        !!(currentUser && item?.owner && item.owner?.id === currentUser.id);

    const { ...selectionProps } = useSelection(items, selectFilter);
    const { rows, selected, onSelectAll, isSelected } = selectionProps;

    const filesUpdateCallback = useCallback(
        (fileIds: string[]) => {
            const newUploadFiles = uploadingFiles.filter(
                (uploadingFile) => !fileIds.includes(uploadingFile.file?.id)
            );

            setUploadingFiles(newUploadFiles);

            setActionsDisabled(false);
        },
        [setUploadingFiles, uploadingFiles]
    );

    useEffect(() => {
        const { current: prevUploading } = prevIsUploading;

        if (isUploading === prevUploading) return;

        prevIsUploading.current = isUploading;

        if (!isUploading && onUploadFinish) {
            return onUploadFinish(filesUpdateCallback);
        }

        onUploadStart && onUploadStart();

        setActionsDisabled(true);
    }, [isUploading, onUploadFinish, onUploadStart, filesUpdateCallback]);

    const handleUpload = (files: File[]) => {
        uploadFiles(files, maxFileSize || DEFAULT_MAX_FILE_SIZE);
    };

    const handleRemove = () => {
        setShowOnDeleteAlert(false);
        if (!onRemove || !currentActionIdRef.current) return;

        const fileIds = [currentActionIdRef.current];

        setActionsDisabled(true);

        onRemove(fileIds, filesUpdateCallback);

        // Reset currentActionId after calling onRemove
        currentActionIdRef.current = undefined;

        if (!!selected.length) onSelectAll();
    };

    let heading = readonly ? (
        <Typography sx={{ fontWeight: 700 }}>{translate(title)}</Typography>
    ) : (
        <>
            <FilePicker
                disabled={!onAdd}
                id={id}
                inputProps={{ multiple: true }}
                onUpload={handleUpload}
            >
                <AddButton>{translate(title)}</AddButton>
            </FilePicker>
        </>
    );

    if (chips) {
        heading = (
            <Grid container spacing={2}>
                <Grid item xs>
                    {heading}
                </Grid>
                <Grid item>{chips}</Grid>
            </Grid>
        );
    }

    const isEditable = withSelection && !readonly;

    const tableActions = !readonly ? (
        <>
            {renderSelectedActions &&
                renderSelectedActions(
                    selected,
                    onSelectAll,
                    filesUpdateCallback
                )}
        </>
    ) : undefined;

    const hasFiles = !!uploadingFiles.length || !!rows.length;

    return (
        <Box pb={hasFiles ? 3 : 0}>
            <Box pb={1} position="relative">
                {heading}
            </Box>
            {(!!uploadingFiles.length || !!rows.length) && (
                <TableContainer>
                    {isEditable && (
                        <TableActionBar
                            actions={tableActions}
                            inDrawer={inDrawer}
                            selectionProps={selectionProps}
                        />
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {isEditable && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={false}
                                            disabled={isSelected}
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{translate('name')}</TableCell>
                                {withOwnerColumn && (
                                    <TableCell>{translate('owner')}</TableCell>
                                )}
                                <TableCell>{translate('date')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {uploadingFiles.map((uploadingFile, index) => {
                                const { name, status, file } = uploadingFile;

                                const isAlreadyUploaded = !!items.filter(
                                    (item) => file?.id === item.id
                                ).length;

                                if (isAlreadyUploaded) return null;

                                const {
                                    done,
                                    success,
                                    progress,
                                    errorMessage,
                                } = status;

                                if (done && !success) return null;

                                const { name: currentUserName } =
                                    currentUser || {};

                                let titleColumn: React.ReactNode = name;
                                let dateColumn;
                                const fileName = file?.name || name || '';

                                if (progress < 100) {
                                    titleColumn = (
                                        <LinearProgress
                                            color="primary"
                                            value={progress}
                                        />
                                    );
                                }

                                if (file) {
                                    titleColumn = (
                                        <Link
                                            href={file.url || ''}
                                            target="_blank"
                                        >
                                            {fileName}
                                        </Link>
                                    );

                                    dateColumn =
                                        formatDate(
                                            file.uploadDate,
                                            'd MMM yyyy HH:mm',
                                            true,
                                            false,
                                            true
                                        ) || '';
                                }

                                if (done && !success) {
                                    titleColumn = (
                                        <Typography component="div">
                                            {fileName}
                                            <Box mt={1}>
                                                <Typography
                                                    color="error"
                                                    component="div"
                                                >
                                                    {errorMessage}
                                                </Typography>
                                            </Box>
                                        </Typography>
                                    );
                                }

                                return (
                                    <TableRow key={`uploadingFile:${index}`}>
                                        {isEditable && (
                                            <TableCell padding="checkbox">
                                                <Checkbox disabled />
                                            </TableCell>
                                        )}

                                        <TableCell>{titleColumn}</TableCell>

                                        {withOwnerColumn && (
                                            <TableCell>
                                                {currentUserName}
                                            </TableCell>
                                        )}

                                        <TableCell>{dateColumn}</TableCell>
                                        {isEditable && <TableCell />}
                                    </TableRow>
                                );
                            })}

                            {rows.map(({ index, onSelect, isSelected }) => {
                                const item = items[index] || {};

                                const currentUserIsOwner =
                                    currentUser &&
                                    item?.owner &&
                                    currentUser.id === item.owner.id;

                                const canDoActions =
                                    isEditable &&
                                    !actionsDisabled &&
                                    !(
                                        currentUserSelection &&
                                        !currentUserIsOwner
                                    );

                                const checkboxCell = isEditable && (
                                    <TableCell>
                                        <Checkbox
                                            checked={isSelected}
                                            disabled={!canDoActions}
                                            onClick={onSelect}
                                        />
                                    </TableCell>
                                );

                                const dropdownActions =
                                    canDoActions && !!onRemove ? (
                                        <TableDropdownCell enablePortal={false}>
                                            <MenuItem
                                                onClick={() => {
                                                    currentActionIdRef.current =
                                                        item.id;

                                                    setShowOnDeleteAlert(true);
                                                }}
                                            >
                                                {translate('remove')}
                                            </MenuItem>
                                        </TableDropdownCell>
                                    ) : (
                                        <TableCell />
                                    );

                                return (
                                    <TableRow key={index}>
                                        {checkboxCell}

                                        <TableCell>
                                            <Link
                                                href={item.url || ''}
                                                target="_blank"
                                            >
                                                {item.name || ''}
                                            </Link>
                                        </TableCell>
                                        {withOwnerColumn && (
                                            <TableCell>
                                                {item.owner?.name || ''}
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            {formatDate(
                                                item.uploadDate,
                                                'd MMM yyyy HH:mm',
                                                true,
                                                false,
                                                true
                                            )}
                                        </TableCell>

                                        {dropdownActions}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={handleRemove}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowOnDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showOnDeleteAlert}
                title={translate('deleteFileMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteFileMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </Box>
    );
};
