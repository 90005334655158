import { useCallback, useMemo } from 'react';

import { TFilterBarItem } from 'common/types';
import { translate } from 'utils/i18n';
import { FilterDropdown } from 'common/components/FilterDropdown';
import { filterToggleSelected } from 'common/utils/filter';

import { FilterBarMenuItem } from './FilterBarMenuItem';

interface IProps {
    disabled?: boolean;
    filters?: TFilterBarItem[];
    selected?: TFilterBarItem[];
    onChange: (selected: TFilterBarItem[]) => void;
}

export const FilterBarDropdown = ({
    disabled,
    filters = [],
    selected = [],
    onChange,
    ...other
}: Partial<React.ComponentProps<typeof FilterDropdown>> & IProps) => {
    const handleChange = useCallback(
        (item: TFilterBarItem, parent?: TFilterBarItem) => {
            const items = filterToggleSelected(selected, item, parent);
            onChange(items);
        },
        [selected, onChange]
    );

    const menuItems = useMemo(
        () =>
            filters.map((item, index) => (
                <FilterBarMenuItem
                    item={item}
                    key={`${item.name}-${index}`}
                    selected={selected}
                    onClick={handleChange}
                />
            )),
        [filters, selected, handleChange]
    );

    return (
        <FilterDropdown
            buttonText={translate('filter')}
            disabled={disabled}
            {...other}
        >
            {menuItems}
        </FilterDropdown>
    );
};
