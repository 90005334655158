import { useNavigate } from 'react-router-dom';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { forwardRef } from 'react';

import { withSxProp } from 'common/utils/props';

import { PageDrawerHeader } from './PageDrawerHeader';
import { useDrawerIndex } from './hooks/useDrawerIndex';

export interface IPageDrawerProps extends Omit<DrawerProps, 'title'> {
    anchor?: DrawerProps['anchor'];
    closeIconPosition?: 'left' | 'right';
    title?: React.ReactNode;
    actions?: React.ReactNode;
    className?: string;
    disableClose?: boolean;
    hideCloseIcon?: boolean;
    headerProps?: React.ComponentProps<typeof PageDrawerHeader>;
    onClose?: (
        reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'
    ) => void;
}

export const PageDrawer = forwardRef<HTMLDivElement, IPageDrawerProps>(
    (
        {
            anchor,
            closeIconPosition,
            title,
            actions,
            hideCloseIcon,
            children,
            className,
            disableClose,
            onClose,
            open,
            headerProps,
            sx,
            ...other
        },
        ref
    ) => {
        const navigate = useNavigate();
        const [offset] = useDrawerIndex(open);

        const handleClose: IPageDrawerProps['onClose'] = (...args) => {
            if (disableClose) return;

            if (onClose) return onClose(...args);

            navigate(-1);
        };

        return (
            <MuiDrawer
                {...other}
                disableEnforceFocus
                anchor={anchor || 'right'}
                open={open}
                PaperProps={{
                    className: className,
                    style: { right: offset },
                    ref,
                    sx: [
                        { maxWidth: 768, width: '100%', paddingBottom: 2 },
                        ...withSxProp(sx),
                    ],
                }}
                onClose={handleClose}
            >
                <PageDrawerHeader
                    actions={actions}
                    closeIconPosition={closeIconPosition}
                    hideCloseIcon={hideCloseIcon || disableClose}
                    title={title}
                    onClose={handleClose}
                    {...headerProps}
                />
                {children}
            </MuiDrawer>
        );
    }
);
