import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { mdiLock, mdiFile, mdiChevronRight, mdiFileRemove } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import {
    useAssignmentQuery,
    AssignmentFragmentDoc,
    IFileFragment,
    useCurrentUserQuery,
    useAddFilesToAssignmentMutation,
    useRemoveFilesFromAssignmentMutation,
    IAssignmentFilesFragment,
    useUpdateAssignmentMutation,
    IUpdateAssignmentInput,
    IAssignmentState,
    IAssignmentScoreType,
    IDiscussionObjectType,
    IAssignmentFragment,
    AssignmentListItemFragmentDoc,
    IAssignmentListItemFragment,
} from 'graphql/types';
import { List } from 'common/components/List';
import {
    ListItem,
    ListItemAvatar,
    ListItemMedia,
    ListItemText,
    ListItemActionText,
    ListItemSecondaryAction,
} from 'common/components/ListItem';
import { Chip, ChipGroup } from 'common/components/Chip';
import { Icon } from 'common/components/Icon';
import { UserAvatar } from 'user/components/UserAvatar';
import { Tooltip } from 'common/components/Tooltip';
import { Link } from 'common/components/Link';
import { Text } from 'common/components/Text';
import { FileTable } from 'common/tables';
import { DiscussionSection } from 'common/components/DiscussionSection';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { DeleteAssignmentFilesAlert } from 'training/components/Alerts';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { AssignmentForm } from 'training/components/forms';
import { useFrontendPermissions } from 'user/hooks';
import { ASSIGNMENT_FILE_MAX_SIZE } from 'common/constants/files';
import { formatGrade } from 'training/utils/formatGrade';
import { IconButton } from 'common/components/IconButton';
import { Typography } from 'common/components/Typography';
import { NotFound } from 'common/components/NotFound';
import { AssignmentScoreChip } from 'training/components/AssignmentScoreChip';

interface IProps {
    canManageParticipants: boolean;
    currentUserIsAuthor?: boolean;
    currentUserIsParticipant?: boolean;
    drawerRef?: React.RefObject<HTMLDivElement>;
    filesAreUpdating: boolean;
    isAssignmentGradeView?: boolean;
    moduleId: string;
    userId?: string;
    setFilesAreUpdating: (isUploading: boolean) => void;
    setSelectedAssignment(assignment: IAssignmentFragment): void;
    onUpdateAssignment?(): void;
}

export const AssignmentDetail = ({
    canManageParticipants,
    currentUserIsAuthor,
    currentUserIsParticipant,
    drawerRef,
    filesAreUpdating,
    isAssignmentGradeView,
    moduleId,
    userId,
    setFilesAreUpdating,
    setSelectedAssignment,
    onUpdateAssignment,
}: IProps) => {
    const client = useApolloClient();
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { canUpdate: canUpdateTraining } = useFrontendPermissions('training');
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);
    const deleteCallbackRef = useRef<() => void>();
    const uploadingFileRefs = useRef<string[]>([]);

    const { data: currentUserData } = useCurrentUserQuery();
    const {
        loading,
        error,
        data: assignmentData,
    } = useAssignmentQuery({
        variables: { moduleId, userId },
    });

    const [updateAssignment, { error: updateError }] =
        useUpdateAssignmentMutation({
            onCompleted: () => {
                displaySnackbar(translate('assignmentActionSuccess.update'), {
                    variant: 'success',
                });
            },
        });

    const [addFiles, { error: addFilesError }] =
        useAddFilesToAssignmentMutation();

    const [removeFiles, { error: removeFilesError }] =
        useRemoveFilesFromAssignmentMutation();

    const assignment = assignmentData?.assignment;

    useEffect(() => {
        if (!assignment) return;

        setSelectedAssignment(assignment);
    }, [assignment, setSelectedAssignment]);

    const sortedFiles = useMemo(() => {
        if (!assignment) return [];

        // Sorted by upload date, latest first
        const newFiles = [...(assignment.files || [])];

        return newFiles.sort((a, b) => {
            const date1 = new Date(a.uploadDate);
            const date2 = new Date(b.uploadDate);

            return date2.getTime() - date1.getTime();
        });
    }, [assignment]);

    if (loading) return <Loader />;
    if (error) return <ApolloError error={error} />;

    if (!assignment || !assignment.module) return <NotFound />;

    const { id, discussion, score, fulfilled, state } = assignment;
    const { description, attachment, canDiscuss, canUploadFiles, scoreType } =
        assignment.module;

    const isClosed = state === IAssignmentState.Closed;
    const scoreDisplay = !!score ? String(formatGrade(score * 10)) : undefined;

    // Get user if assignment has it, this is the case for UserAssignment
    const user =
        'participant' in assignment ? assignment.participant : undefined;
    const discussionType =
        assignment.__typename === 'IndividualAssignment'
            ? IDiscussionObjectType.IndividualAssignment
            : IDiscussionObjectType.CollectiveAssignment;

    const handleAssignmentFilesMutationUpdate = (
        updatedAssignment: IAssignmentFilesFragment,
        filterUploadingFiles?: (fileIds: string[]) => void
    ) => {
        const { files } = updatedAssignment;
        if (!files) return;

        uploadingFileRefs.current = [];

        if (filterUploadingFiles) {
            filterUploadingFiles(files.map((file) => file.id));
        }

        setFilesAreUpdating(false);
    };

    let handleAddFile;

    // Don't allow adding files when files are updating or assignment is closed
    if (!filesAreUpdating) {
        handleAddFile = (uploadedFile: IFileFragment) => {
            // Collection of all the uploaded file ids in a batch
            uploadingFileRefs.current.push(uploadedFile.id);
        };
    }

    const handleUploadFinish = (
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        addFiles({
            variables: {
                id,
                fileIds: uploadingFileRefs.current,
            },
            update: (cache, result) => {
                const { addFilesToAssignment } = result?.data || {};
                const updatedAssignment = addFilesToAssignment?.assignment;

                if (!updatedAssignment) return;

                handleAssignmentFilesMutationUpdate(
                    updatedAssignment,
                    filesUpdateCallback
                );
            },
        });
    };

    const handleRemoveFiles = async (
        fileIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!assignment) return;

        setFilesAreUpdating(true);

        await removeFiles({
            variables: {
                id,
                fileIds,
            },
            optimisticResponse: {
                __typename: 'Mutations',
                removeFilesFromAssignment: {
                    __typename: 'RemoveFilesFromAssignment',
                    assignment: {
                        ...assignment,
                        files: assignment.files.filter(
                            (file) => !fileIds.includes(file.id)
                        ),
                    },
                },
            },
            update: (cache, result) => {
                const { removeFilesFromAssignment } = result?.data || {};
                const updatedAssignment = removeFilesFromAssignment?.assignment;

                if (!updatedAssignment) return;

                handleAssignmentFilesMutationUpdate(
                    updatedAssignment,
                    filesUpdateCallback
                );
            },
        });

        onUpdateAssignment?.();
    };

    /**
     * Update commentData for assignment list item
     */
    const updateCachedCommentData = (
        name: 'commentCount' | 'attachmentCount'
    ) => {
        if (!assignment) return;

        // Get related assignment list item from cache
        const assignmentListItem =
            client.readFragment<IAssignmentListItemFragment>({
                id: `${assignment.__typename}:${assignment.id}`,
                fragment: AssignmentListItemFragmentDoc,
                fragmentName: 'AssignmentListItem',
            });

        if (!assignmentListItem) return;

        const { commentData } = assignmentListItem;
        const currentCount = commentData ? commentData[name] : 0;

        // Update count in cache using the AssignmentListItem fragment
        client.writeFragment({
            id: `${assignment.__typename}:${id}`,
            fragment: AssignmentListItemFragmentDoc,
            fragmentName: 'AssignmentListItem',
            data: {
                ...assignment,
                commentData: { ...commentData, [name]: currentCount + 1 },
            },
        });
    };

    const handleAddFileComment = (file: IFileFragment) => {
        const fileAlreadyPresent = !!sortedFiles.filter(
            (existingFile) => existingFile.id === file.id
        ).length;

        if (!assignment || fileAlreadyPresent) return;

        client.writeFragment({
            id: client.cache.identify(assignment),
            fragment: AssignmentFragmentDoc,
            data: { ...assignment, files: [...assignment.files, file] },
            fragmentName: 'Assignment',
        });

        updateCachedCommentData('attachmentCount');
        onUpdateAssignment?.();
    };

    const fileTableActions = (
        selected: string[],
        onSelectAll: () => void,
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        const selectedFileIds =
            sortedFiles
                .filter((file) => {
                    const isOwner =
                        file?.owner &&
                        currentUserData?.currentUser &&
                        file.owner.id === currentUserData.currentUser.id;

                    // Check if user can select the file by checking if the user is owner or can update training
                    return (
                        selected.includes(file.id) &&
                        (isOwner || canUpdateTraining)
                    );
                })
                .map((file) => file.id) || [];

        return (
            <Tooltip title={translate<string>('delete')}>
                <Box>
                    <IconButton
                        color="inherit"
                        disabled={!selected.length}
                        iconPath={mdiFileRemove}
                        iconSize="2.4rem"
                        onClick={() => {
                            deleteCallbackRef.current = () => {
                                handleRemoveFiles(
                                    selectedFileIds,
                                    filesUpdateCallback
                                );

                                // Deselect all
                                onSelectAll();

                                // Remove callback after usage
                                deleteCallbackRef.current = undefined;
                            };

                            setShowOnDeleteAlert(true);
                        }}
                    />
                </Box>
            </Tooltip>
        );
    };

    const canSeeAssignmentGradeForm =
        isAssignmentGradeView &&
        (canUploadFiles || scoreType !== IAssignmentScoreType.None);

    // Check if the current user is a participant of this assignment
    const isOwnAssignment =
        user && user.id === currentUserData?.currentUser?.id;

    const showPreviewText =
        // User is not set for collective assignments and we don't want to show the preview text for collective
        (user || !canManageParticipants) &&
        !currentUserIsParticipant &&
        (currentUserIsAuthor || canManageParticipants);

    const showSocialSections =
        (!user && currentUserIsParticipant) ||
        (isOwnAssignment && currentUserIsParticipant) ||
        (!isOwnAssignment && canManageParticipants);

    // Check if file and discuss sections should be shown, if the users is grading or can't see the
    // grade form but are normal participants (not authors)
    const showFileSection = showSocialSections && canUploadFiles;
    const showDiscussSection = showSocialSections && canDiscuss;

    return (
        <>
            {updateError && <ApolloError error={updateError} />}
            {addFilesError && <ApolloError error={addFilesError} />}
            {removeFilesError && <ApolloError error={removeFilesError} />}

            <Box pt={2}>
                <Box pb={4} pt={2}>
                    <ListItem p={{ xs: 2, sm: 4 }} py={0}>
                        {user ? (
                            <>
                                <ListItemAvatar>
                                    <UserAvatar
                                        bgColor="primary"
                                        size={24}
                                        sizeSm={48}
                                        user={user}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={user.name} />
                            </>
                        ) : (
                            <ListItemText
                                primary={translate('groupAssignment')}
                            />
                        )}
                        <ChipGroup>
                            {isClosed && (
                                <Tooltip title={translate<string>('closed')}>
                                    <Box>
                                        <Chip
                                            icon={
                                                <Icon
                                                    path={mdiLock}
                                                    size="1.4rem"
                                                />
                                            }
                                            style={{ pointerEvents: 'none' }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}

                            <AssignmentScoreChip assignment={assignment} />
                        </ChipGroup>
                    </ListItem>
                    {canSeeAssignmentGradeForm && (
                        <Box pt={4} px={{ xs: 2, sm: 4 }}>
                            <AssignmentForm
                                canUploadFiles={canUploadFiles}
                                disabled={filesAreUpdating}
                                initialValues={{
                                    score: scoreDisplay,
                                    // Change fullfilled to 'yes', 'no' or undefined
                                    fulfilled:
                                        fulfilled !== null
                                            ? fulfilled
                                                ? 'yes'
                                                : 'no'
                                            : '',
                                    state: state || null,
                                }}
                                scoreType={scoreType || undefined}
                                onSubmit={async (values, { setSubmitting }) => {
                                    const { score, fulfilled } = values;

                                    // Convert score to number
                                    const numberScore = score
                                        ? formatGrade(Number(score) / 10)
                                        : null;

                                    // Convert form fulfilled value to boolean or null to unset
                                    let fulfilledBool: boolean | null = null;

                                    if (fulfilled) {
                                        // When fulfilled is set it will be true when 'yes' and false when 'no'
                                        fulfilledBool = fulfilled === 'yes';
                                    }

                                    const updatedAssignment = {
                                        ...values,
                                        score: numberScore,
                                        fulfilled: fulfilledBool,
                                    } as IUpdateAssignmentInput;

                                    const response = await updateAssignment({
                                        variables: {
                                            id,
                                            assignment: updatedAssignment,
                                        },
                                        update: (cache) => {
                                            setSubmitting(false);

                                            cache.evict({
                                                fieldName: 'assignments',
                                            });

                                            cache.gc();
                                        },
                                    });

                                    onUpdateAssignment?.();

                                    return response;
                                }}
                            />
                        </Box>
                    )}
                </Box>
                {(description || attachment) && (
                    <Box
                        p={{ xs: 2, sm: 4 }}
                        sx={{
                            borderTop: 1,
                            borderTopColor: 'divider',
                        }}
                    >
                        {description && (
                            <Text variant="body1">{description}</Text>
                        )}

                        {attachment && (
                            <Box mt={!!description ? 4 : 0}>
                                <List>
                                    <ListItem
                                        button
                                        component={Link}
                                        href={attachment.url}
                                        key={attachment.id}
                                        target="_blank"
                                    >
                                        <ListItemMedia
                                            color="primary"
                                            size="small"
                                        >
                                            <Icon
                                                path={mdiFile}
                                                size="2.2rem"
                                                sizeSm="3rem"
                                            />
                                        </ListItemMedia>
                                        <ListItemText
                                            primary={attachment.name}
                                        />
                                        <ListItemActionText>
                                            {translate('download')}
                                        </ListItemActionText>
                                        <ListItemSecondaryAction hideXs>
                                            <Icon
                                                path={mdiChevronRight}
                                                size="4rem"
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Box>
                        )}
                    </Box>
                )}
                {showFileSection && (
                    <Box
                        sx={{
                            py: { xs: 1, sm: 3 },
                            px: { xs: 2, sm: 4 },
                            borderTop: 1,
                            borderTopColor: 'divider',
                        }}
                    >
                        <FileTable
                            inDrawer
                            withOwnerColumn
                            currentUserSelection={!canUpdateTraining}
                            id="assignmentFiles"
                            items={sortedFiles}
                            maxFileSize={ASSIGNMENT_FILE_MAX_SIZE}
                            readonly={!canManageParticipants && isClosed}
                            renderSelectedActions={fileTableActions}
                            onAdd={handleAddFile}
                            onRemove={handleRemoveFiles}
                            onUploadFinish={handleUploadFinish}
                            onUploadStart={() => setFilesAreUpdating(true)}
                        />
                        {isClosed && !sortedFiles.length && (
                            <Box sx={{ mt: 2 }}>
                                <Typography>
                                    {translate('noResults.files')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
                {showDiscussSection && (
                    <Box
                        p={{ xs: 2, sm: 4 }}
                        sx={{
                            borderTop: 1,
                            borderTopColor: 'divider',
                        }}
                    >
                        <DiscussionSection
                            discussionId={discussion?.id}
                            forType={discussionType}
                            parentRef={drawerRef}
                            onAddComment={() => {
                                updateCachedCommentData('commentCount');
                                onUpdateAssignment?.();
                            }}
                            onAddFileComment={handleAddFileComment}
                            onDeleteComment={() => {
                                updateCachedCommentData('commentCount');
                                onUpdateAssignment?.();
                            }}
                        />
                    </Box>
                )}

                {!isAssignmentGradeView && showPreviewText && (
                    <Box>
                        <Divider sx={{ mb: 4 }} />

                        <Typography
                            sx={{
                                px: { xs: 2, sm: 4 },
                                fontStyle: 'italic',
                            }}
                        >
                            {translate('moduleForm.assignment.authorMessage')}
                        </Typography>
                    </Box>
                )}
            </Box>
            <DeleteAssignmentFilesAlert
                open={showOnDeleteAlert}
                onCancel={() => {
                    setShowOnDeleteAlert(false);
                }}
                onConfirm={() => {
                    setShowOnDeleteAlert(false);

                    if (deleteCallbackRef.current) deleteCallbackRef.current();
                }}
            />
        </>
    );
};
