import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { Link } from '../Link';
import { Typography } from '../Typography';

interface IProps {
    description?: string;
    hideButtons?: boolean;
    title?: string;
}

export const DrawerUpgradeSection = ({
    description,
    hideButtons,
    title,
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <Box>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h3">{title}</Typography>

                <Box mt={2}>
                    <Typography>{description}</Typography>
                </Box>
            </Box>

            {!hideButtons && (
                <Box sx={{ display: 'flex' }}>
                    <Box mr={2}>
                        <Button
                            color="primary"
                            component={Link}
                            to="PLANS"
                            variant="contained"
                        >
                            {translate('upgradeNow')}
                        </Button>
                    </Box>
                    <Button
                        component={Link}
                        href={translate('planDemo.buttonLink')}
                        target="_blank"
                        variant="contained"
                    >
                        {translate('requestDemo')}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
