import moment from 'moment';

import { TDate } from 'common/types';

/*
 * Format dates to: YYYYMMDDTHHmmSSZ, outcome example: 20201231T193000Z
 */
function transformDate(date: Date) {
    return `${moment(date).utcOffset(0).format('YYYYMMDDTHHmmss')}Z`;
}

/**
 * Get events based on the different dates
 */
function getEvents(
    dates: TDate[],
    title: string,
    description: string,
    location: string
) {
    return dates.map((date) => {
        const { startDate, endDate } = date;

        const event = [
            'BEGIN:VEVENT',
            `DTSTART:${transformDate(startDate)}`,
            `DTEND:${transformDate(endDate)}`,
            `SUMMARY:${title}`,
            `DESCRIPTION:${description}`,
            `LOCATION:${location}`,
            `URL:${window.location.href}`,
            'END:VEVENT',
        ];

        return event.join('\n');
    });
}

function getCalendarUrl(
    dates: TDate[],
    title: string,
    description: string,
    location: string
) {
    return [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        ...getEvents(dates, title, description, location),
        'END:VCALENDAR',
    ].join('\n');
}

/*
 * ICS file download with multiple event support
 */
export function getCalendarIcsDownload(
    dates: TDate[],
    title: string,
    description: string,
    location: string
) {
    const calendarUrl = getCalendarUrl(dates, title, description, location);

    return encodeURI('data:text/calendar;charset=utf8,' + calendarUrl);
}

/*
 * Google Calendar link for a single event
 */
export function getGoogleCalendarUrl(
    date: TDate,
    title: string,
    description: string,
    location: string
) {
    const baseLink =
        'https://calendar.google.com/calendar/render?action=TEMPLATE';

    const { startDate, endDate } = date;

    const dates = `dates=${transformDate(startDate)}/${transformDate(endDate)}`;
    const details = `details=${encodeURIComponent(description)}`;
    const loc = `location=${encodeURIComponent(location)}`;
    const text = `text=${encodeURIComponent(title)}`;

    return `${baseLink}&${dates}&${details}&${loc}&${text}`;
}

/*
 * Outlook/Office365 link for a single event
 */
export function getOutlookCalendarUrl(
    date: TDate,
    title: string,
    description: string,
    location: string
) {
    const baseLink = 'https://outlook.office.com/calendar/0/deeplink/compose?';
    const subject = `subject=${encodeURIComponent(title)}`;
    const body = `&body=${encodeURIComponent(description)}`;

    const { startDate, endDate } = date;
    const startDateString = new Date(startDate).toISOString();
    const endDateString = new Date(endDate).toISOString();

    const start = `&startdt=${startDateString}`;
    const end = `&enddt=${endDateString}`;
    const loc = `location=${encodeURIComponent(location)}`;

    return `${baseLink}&${subject}&${body}&${start}&${end}&${loc}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`;
}
