import { Box, Stack, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserAvatar } from 'user/components/UserAvatar';
import { TableCell } from 'common/components/Table';
import { Checkbox } from 'common/components/Checkbox';
import { Typography } from 'common/components/Typography';
import { ITrainingTrainerListItem } from 'training/types';
import { Chip } from 'common/components/Chip';
import { ITrainingRole } from 'graphql/types';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';

interface IProps {
    disabled?: boolean;
    isEditable?: boolean;
    isSelectable?: boolean;
    isSelected?: boolean;
    trainer: ITrainingTrainerListItem;
    onSelect?(): void;
}

export const TrainingTrainerTableRow = ({
    disabled,
    isEditable = true,
    isSelected,
    trainer,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const { id, name, trainingRole } = trainer;

    return (
        <TableRow hover>
            {isEditable && (
                <TableCell>
                    <Checkbox
                        checked={isSelected}
                        disabled={disabled}
                        onClick={(e) => {
                            onSelect?.();

                            e.stopPropagation();
                        }}
                    />
                </TableCell>
            )}

            <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    openGlobalDrawer({
                        type: IGlobalDrawerType.UserProfile,
                        itemId: id,
                    });
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar bgColor="secondary" size={45} user={trainer} />

                    <Box sx={{ ml: 2 }}>
                        <Typography
                            color={disabled ? 'grey.500' : undefined}
                            variant="body2"
                        >
                            {name}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>

            <TableCell align="right">
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    {!!trainingRole &&
                        trainingRole === ITrainingRole.Mentor && (
                            <Chip
                                key={trainingRole}
                                label={translate('mentor')}
                            />
                        )}
                </Stack>
            </TableCell>
        </TableRow>
    );
};
