import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mdiPlus, mdiViewModule, mdiViewList } from '@mdi/js';
import { Box, Grid } from '@mui/material';

import { BasePage } from 'hydra/pages/BasePage';
import { getUrl } from 'route/utils/getUrl';
import { Icon } from 'common/components/Icon';
import { NoResults } from 'common/components/NoResults';
import { BoxLoader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';
import { TrainingManagementCardList } from 'training/components/TrainingManagementCardList';
import { useTrainingList } from 'training/hooks/useTrainingList';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';
import { BackgroundImg } from 'common/components/Img';
import MANAGEMENT_HEADER from 'assets/images/training-overview-header.webp';
import { ActionButton } from 'common/components/ActionButton';
import { PageTitle } from 'common/components/PageTitle';
import { ButtonToggle } from 'common/components/ButtonToggle';

enum EView {
    Grid = 'grid',
    List = 'list',
}

export const TrainingsManagementPage = () => {
    const navigate = useNavigate();
    const [translate] = useTranslation();

    const {
        trainings,
        loading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useTrainingList(undefined, true, true);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const noResults = !loading && !trainings.length;

    const header = (
        <BackgroundImg
            src={MANAGEMENT_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            <PageTitle>
                {translate('trainingsManagementPage.pageTitle')}
            </PageTitle>

            <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Typography variant="h1">
                            {translate('trainingsManagementPage.pageTitle')}
                        </Typography>
                    </Grid>

                    <Grid item xs="auto">
                        <ActionButton
                            outlined
                            disabled={loading}
                            variant="extended"
                            onClick={() => {
                                navigate(getUrl('TRAINING_CREATE'));
                            }}
                        >
                            <Box>
                                <Icon path={mdiPlus} size="2.4rem" />
                            </Box>

                            {translate('newTraining')}
                        </ActionButton>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ mt: 4 }}>
                <Box sx={{ mb: 4 }}>
                    <FilterBar
                        useFilterDrawer
                        barPrepend={
                            <ButtonToggle
                                buttons={[
                                    {
                                        key: EView.Grid,
                                        icon: mdiViewModule,
                                    },
                                    {
                                        key: EView.List,
                                        icon: mdiViewList,
                                    },
                                ]}
                                onClickButton={(key) => {
                                    if (key === EView.Grid) {
                                        navigate(
                                            getUrl('TRAINING_MANAGEMENT_LIST')
                                        );

                                        return;
                                    }

                                    window.location.href = getUrl(
                                        'MANAGEMENT_MODULES_TRAININGS',
                                        undefined,
                                        false,
                                        true
                                    );
                                }}
                            />
                        }
                        disabled={loading}
                        filterDrawerProps={{
                            title: translate('filterForm.trainings.title'),
                            description: translate(
                                'filterForm.trainings.description'
                            ),
                        }}
                        formFilters={filters}
                        formFilterValues={filterValues}
                        initialSearchValue={filterValues.q}
                        placeholder={translate('filterBarPlaceholder')}
                        onFiltersChange={(values) => {
                            setPage(1);

                            onFilterChange?.(values);
                        }}
                        onSearch={handleSearch}
                        onSearchClear={() => handleSearch('')}
                    />
                </Box>

                {noResults && <NoResults variant="TRAININGS_MANAGEMENT" />}

                {loading && !trainings.length && <BoxLoader />}

                {!loading && !noResults && (
                    <>
                        <Box mb={4}>
                            <TrainingManagementCardList trainings={trainings} />
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Pagination
                                page={paginationSettings.page}
                                pageAmount={paginationSettings.pageAmount}
                                totalsAmount={paginationSettings.count}
                                totalsText={translate('training', {
                                    count: paginationSettings.count,
                                })}
                                onChange={(page: number) => {
                                    setPage(page);
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </BasePage>
    );
};
