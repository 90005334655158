import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from 'styled-components';

import { PRIMARY_COLOR_PRESETS } from 'theme/palette';

const ColorSwatchButton = styled(Box)`
    border: 2px solid ${({ theme }) => theme.palette.grey[200]};
    padding: ${({ theme }) => theme.spacing(0.5, 0.5)};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    overflow: hidden;
    height: 70px;
    cursor: pointer;
    display: flex;

    &:hover,
    &.active {
        border-color: ${({ theme }) => theme.palette.grey[500]};
    }
`;

interface IProps {
    selectedColor?: string;
    onChange?(color: string): void;
}

export const BaseColorPicker = ({ selectedColor, onChange }: IProps) => {
    const [selected, setSelected] = useState<string | undefined>(selectedColor);

    return (
        <Grid container spacing={2}>
            {Object.values(PRIMARY_COLOR_PRESETS).map((color) => (
                <Grid item md key={color} sm={4} xs={4}>
                    <ColorSwatchButton
                        className={color === selected ? 'active' : ''}
                        onClick={() => {
                            setSelected(color);
                            onChange && onChange(color);
                        }}
                    >
                        <Box bgcolor={color} flex="1" />
                    </ColorSwatchButton>
                </Grid>
            ))}
        </Grid>
    );
};
