import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { mdiPlus, mdiPencil } from '@mdi/js';

import {
    IConditionEffect,
    IModuleGroupFragment,
    ITrainingRole,
    ITrainingFragment,
} from 'graphql/types';
import { List } from 'common/components/List';
import { ListFooter } from 'common/components/ListFooter';
import { Accordion, AccordionSummary } from 'common/components/Accordion';
import { Chip } from 'common/components/Chip';
import { Link } from 'common/components/Link';
import { AssignmentModule } from 'training/components/AssignmentModule';
import { CertificateModule } from 'training/components/CertificateModule';
import { CourseModule } from 'training/components/CourseModule';
import { FileModule } from 'training/components/FileModule';
import { LinkModule } from 'training/components/LinkModule';
import { MeetingModule } from 'training/components/MeetingModule';
import { LTIModule } from 'training/components/LTIModule';
import { VideoModule } from 'training/components/VideoModule';
import { ExternalContentModule } from 'training/components/ExternalContentModule';
import { ScormModule } from 'training/components/ScormModule';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';
import { Tooltip } from 'common/components/Tooltip';
import { useFrontendPermissions } from 'user/hooks';
import { IconButton } from 'common/components/IconButton';
import { ConditionsDescription } from 'training/components/ModuleGroup/ConditionsDescription';
import { ConditionsDescriptionStudent } from 'training/components/ModuleGroup/ConditionsDescriptionStudent';
import { Draggable, Droppable } from 'common/components/DragDrop';
import { AfasWebhookModule } from 'training/components/AfasWebhookModule';
import { WebhookModule } from 'training/components/WebhookModule';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MODULES: Record<string, React.FC<React.PropsWithChildren<any>>> = {
    AssignmentModule,
    CertificateModule,
    CourseModule,
    FileModule,
    LinkModule,
    MeetingModule,
    LTIModule,
    VideoModule,
    ExternalContentModule,
    ScormModule,
    AfasWebhookModule,
    WebhookModule,
};

interface IProps extends IModuleGroupFragment {
    canManage?: boolean;
    canManageParticipants?: boolean;
    expanded: boolean;
    onChange?(): void;
    training?: Partial<ITrainingFragment>;
}

export const ModuleGroup = ({
    canManage,
    canManageParticipants,
    conditionEffect,
    conditions = [],
    expanded,
    onChange,
    id,
    title,
    description,
    modules = [],
    training,
    startDate,
    endDate,
    deadline,
}: IProps) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);
    const { canUpdate } = useFrontendPermissions('training');

    const { currentUserIsAuthor, rolesForCurrentUser } = training || {};

    const canUpdateTraining = canUpdate || currentUserIsAuthor;

    let labels;

    const allConditionsFulfilled = conditionEffect === IConditionEffect.Nothing;

    if (!allConditionsFulfilled && conditionEffect === IConditionEffect.Lock) {
        labels = <Chip label={translate('closed')} />;
    }

    const isHidden =
        !allConditionsFulfilled && conditionEffect === IConditionEffect.Hide;

    if (isHidden) {
        labels = <Chip bgColor="warning" label={translate('hidden')} />;
    }

    let subtitle;

    if (!!conditions.length) {
        subtitle = <ConditionsDescription conditions={conditions} />;

        if (studentView) {
            subtitle = (
                <ConditionsDescriptionStudent
                    conditions={conditions}
                    deadline={deadline}
                    endDate={endDate}
                    startDate={startDate}
                />
            );
        }
    }

    const titlePlaceholder =
        !studentView && canUpdateTraining
            ? translate('unnamedSection')
            : undefined;

    const groupHasNoTitle = !title && !subtitle && !titlePlaceholder;

    if (isHidden && studentView) return null;

    const isTrainer =
        !!rolesForCurrentUser?.includes(ITrainingRole.Trainer) ||
        !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);

    const actions =
        !studentView && canUpdateTraining ? (
            <Tooltip title={translate<string>('editSection')}>
                <Box>
                    <IconButton
                        component={Link}
                        iconPath={mdiPencil}
                        iconSize="2.4rem"
                        params={{
                            trainingId: training?.id,
                            moduleGroupId: id,
                        }}
                        size="large"
                        to="TRAINING_EDIT_MODULE_GROUP"
                    />
                </Box>
            </Tooltip>
        ) : undefined;

    const connectionModules = modules
        .filter(
            (module) =>
                module.__typename === 'AfasWebhookModule' ||
                module.__typename === 'WebhookModule'
        )
        .map((module) => {
            const Module = MODULES[module.__typename];

            if (!Module) return null;

            return (
                <Module
                    {...module}
                    canManage={canManage}
                    canManageParticipants={canManageParticipants}
                    canUpdate={canUpdateTraining}
                    groupConditionEffect={conditionEffect}
                    key={module.id}
                    moduleGroupId={id}
                    trainingId={training?.id}
                />
            );
        });

    const isDragDropDisabled = studentView || !canUpdateTraining;

    return (
        <Accordion
            details={description}
            expanded={groupHasNoTitle || expanded}
            summary={
                <AccordionSummary
                    actions={actions}
                    disableExpand={groupHasNoTitle}
                    labels={labels}
                    subtitle={subtitle}
                    title={title}
                    titlePlaceholder={titlePlaceholder}
                />
            }
            onChange={onChange}
        >
            <List noBorder>
                <Droppable
                    dragDropDisabled={isDragDropDisabled}
                    droppableId={id}
                    type="MODULE"
                >
                    {modules.map((module, index) => {
                        if (
                            module.__typename === 'AfasWebhookModule' ||
                            module.__typename === 'WebhookModule'
                        ) {
                            return null;
                        }

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const Module = MODULES[(module as any).__typename];

                        if (!Module) return null;

                        const allFulfilled =
                            module.conditionEffect === IConditionEffect.Nothing;

                        return (
                            <Draggable
                                dragDropDisabled={isDragDropDisabled}
                                draggableId={module.id}
                                index={index}
                                key={module.id}
                            >
                                <Module
                                    {...module}
                                    canManage={canManage}
                                    canManageParticipants={
                                        canManageParticipants
                                    }
                                    canUpdate={canUpdateTraining}
                                    currentUserIsAuthor={currentUserIsAuthor}
                                    currentUserIsTrainer={isTrainer}
                                    disabled={studentView && !allFulfilled}
                                    groupConditionEffect={conditionEffect}
                                    key={module.id}
                                    moduleGroupId={id}
                                    trainingId={training?.id}
                                />
                            </Draggable>
                        );
                    })}
                </Droppable>

                {!studentView && canUpdateTraining && (
                    <>
                        {connectionModules}

                        <ListFooter
                            button
                            component={Link}
                            params={{
                                trainingId: training?.id,
                                moduleGroupId: id,
                            }}
                            py={2}
                            to={
                                id
                                    ? 'TRAINING_CREATE_MODULE_IN_GROUP'
                                    : 'TRAINING_CREATE_MODULE'
                            }
                        >
                            <ActionButton outlined size="medium">
                                <Icon path={mdiPlus} />
                            </ActionButton>
                            <Box ml={2}>
                                <Typography>
                                    {translate('addModule')}
                                </Typography>
                            </Box>
                        </ListFooter>
                    </>
                )}
            </List>
        </Accordion>
    );
};
