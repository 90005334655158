import { Link, ILinkProps } from 'common/components/Link';

interface IProps extends ILinkProps {
    element: { url: string };
    children: React.ReactNode;
}

export const EditorLink = ({ element, children }: IProps) => (
    <Link href={element.url} target="_blank" underline="always">
        {children}
    </Link>
);
