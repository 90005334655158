import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { mdiArrowULeftTop } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import {
    IContentTypeValue,
    ICreatePortfolioItemInput,
    useCurrentUserQuery,
    usePortfolioItemQuery,
} from 'graphql/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { CustomPortfolioItemContent } from 'user/components/PortfolioItemDrawer/CustomPortfolioItemContent';
import { Loader } from 'common/components/Loader';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useApolloError } from 'common/hooks/useApolloError';
import { goToPortfolioPage } from 'user/utils/goToRoutes';
import { CertificatePortfolioItemContent } from 'user/components/PortfolioItemDrawer/CertificatePortfolioItemContent';
import { ExternalContentPortfolioItemContent } from 'user/components/PortfolioItemDrawer/ExternalContentPortfolioItemContent';
import { CoursePortfolioItemContent } from 'user/components/PortfolioItemDrawer/CoursePortfolioItemContent';
import { ScormPortfolioItemContent } from 'user/components/PortfolioItemDrawer/ScormPortfolioItemContent';
import { TrainingPortfolioItemActions } from 'user/components/PortfolioItemDrawer/TrainingPortfolioItemActions';
import { TrainingPortfolioItemContent } from 'user/components/PortfolioItemDrawer/TrainingPortfolioItemContent';
import { OfferEventPortfolioItemContent } from 'user/components/PortfolioItemDrawer/OfferEventPortfolioItemContent';
import { UserAvatar } from 'user/components/UserAvatar';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
} from 'common/components/ListItem';
import { ChipGroup } from 'common/components/Chip';
import { UserExtraFieldsChips } from 'user/components/UserExtraFieldsChips';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { NotFound } from 'common/components/NotFound';
import { ShowAfterDelay } from 'common/components/ShowAfterDelay';
import { PortfolioItemDetailsView } from 'user/components/PortfolioItemDrawer/PortfolioItemDetailsView';
import { UserActionBar } from 'user/UserActionBar';
import { ResetPortfolioItemProgressAction } from 'user/actions/ResetPortfolioItemProgressAction';
import { IUpdateGroupProps } from 'user/components/GroupUpdateDrawer';
import { IUserProfileProps } from 'user/components/UserProfileDrawer';

interface IProps extends IPageDrawerProps {
    groupProps?: IUpdateGroupProps;
    pageTitle?: string;
    withEditActions?: boolean;
    portfolioItemId?: string;
    showUser?: boolean;
    onCreateItem?(values: ICreatePortfolioItemInput): void;
    onClose?(): void;
    open?: boolean;
    onResetProgress?(): void;
    userProfileProps?: IUserProfileProps;
}

export const PortfolioItemDrawer = ({
    groupProps,
    portfolioItemId,
    pageTitle,
    withEditActions,
    showUser,
    open,
    userProfileProps,
    onCreateItem,
    onClose,
    onResetProgress,
}: IProps) => {
    const [translate] = useTranslation();
    const { id: userIdParam, itemId: itemIdParam } = useParams();

    const { showApolloError } = useApolloError();
    const routeQuery = useRouteQuery();
    const navigate = useNavigate();

    const { data: currentUserData } = useCurrentUserQuery();
    const [filesAreUpdating, setFilesAreUpdating] = useState(false);
    const customPortfolioItemCreate = !!useRouteMatch('PORTFOLIO_ITEM_CREATE');
    const customPortfolioItemEdit = !!useRouteMatch('PORTFOLIO_ITEM_EDIT');
    const userPortfolioItemView = !!useRouteMatch('USER_PORTFOLIO_ITEM_VIEW');
    const portfolioItemViewProfile = !!useRouteMatch('PORTFOLIO_ITEM_VIEW');
    const portfolioItemViewDrawer = !!useHashMatch('PORTFOLIO_ITEM_DRAWER');

    // Portfolio pages of a user
    const isPortfolioTrainingsPage = !!useRouteMatch('PORTFOLIO_TRAININGS');
    const isPortfolioOffersPage = !!useRouteMatch('PORTFOLIO_OFFERS');
    const isPortfolioMeetingsPage = !!useRouteMatch('PORTFOLIO_MEETINGS');
    const isUserPortfolioTrainingsPage = !!useRouteMatch('USER_TRAININGS');
    const isUserPortfolioOffersPage = !!useRouteMatch('USER_OFFERS');
    const isUserPortfolioMeetingsPage = !!useRouteMatch('USER_MEETINGS');

    // Get the portfolio item id from the query string on porfolioItemViewDrawer
    const portfolioItemIdParam = portfolioItemViewDrawer
        ? routeQuery.get('id')
        : undefined;

    const userId = userIdParam || currentUserData?.currentUser?.id;
    const itemId = portfolioItemId || portfolioItemIdParam || itemIdParam;

    const drawerOpen =
        open ||
        customPortfolioItemCreate ||
        customPortfolioItemEdit ||
        userPortfolioItemView ||
        portfolioItemViewProfile;

    const { data: portfolioItemData, loading: portfolioItemLoading } =
        usePortfolioItemQuery({
            variables: { id: itemId },
            skip: !drawerOpen || !itemId,
            fetchPolicy: 'network-only',
            onError: (error) => {
                // Do not show error when portfolio item is not found,
                // we handle this by showing the NotFound component
                if (
                    !error.graphQLErrors.length ||
                    error.graphQLErrors[0].extensions?.code ===
                        'PortfolioItemNotFound'
                ) {
                    return;
                }

                showApolloError(error);
            },
        });

    const { portfolioItem } = portfolioItemData || {};

    const isCurrentUserItem =
        portfolioItem?.user.id === currentUserData?.currentUser?.id;

    const handleClose = () => {
        if (onClose) {
            onClose?.();

            return;
        }

        goToPortfolioPage(navigate, userIdParam);
    };

    const { relatedType, user, relatedObject } = portfolioItem || {};

    // Only allow resetting progress for course and scorm
    const canResetProgress =
        relatedType &&
        [IContentTypeValue.Course, IContentTypeValue.Scorm].includes(
            relatedType
        );

    let content;

    const hideUserHeader =
        isPortfolioTrainingsPage ||
        isPortfolioOffersPage ||
        isPortfolioMeetingsPage ||
        isUserPortfolioTrainingsPage ||
        isUserPortfolioOffersPage ||
        isUserPortfolioMeetingsPage;

    if (!portfolioItemLoading) {
        if (!portfolioItem && !customPortfolioItemCreate) {
            // Use ShowAfterDelay to prevent flickering when loading from cache
            content = (
                <ShowAfterDelay show delay={200}>
                    <NotFound />
                </ShowAfterDelay>
            );
        } else if (!relatedType) {
            content = (
                <CustomPortfolioItemContent
                    filesAreUpdating={filesAreUpdating}
                    loading={portfolioItemLoading}
                    pageTitle={pageTitle}
                    portfolioItem={portfolioItem || undefined}
                    setFilesAreUpdating={setFilesAreUpdating}
                    userId={userId}
                    withEditActions={withEditActions}
                    onClose={() => handleClose()}
                    onCreateItem={(values) => onCreateItem?.(values)}
                    onSubmit={() => handleClose()}
                />
            );
        } else if (portfolioItem) {
            const itemContentProps = {
                filesAreUpdating,
                isCurrentUserItem,
                loading: portfolioItemLoading,
                pageTitle,
                portfolioItem,
                userId,
                withEditActions,
                setFilesAreUpdating,
                onSubmit: () => handleClose(),
            };

            switch (relatedType) {
                case IContentTypeValue.ReceivedCertificate:
                    content = (
                        <CertificatePortfolioItemContent
                            {...itemContentProps}
                        />
                    );

                    break;
                case IContentTypeValue.ExternalContent:
                    content = (
                        <ExternalContentPortfolioItemContent
                            {...itemContentProps}
                        />
                    );

                    break;
                case IContentTypeValue.Course:
                    content = (
                        <CoursePortfolioItemContent {...itemContentProps} />
                    );

                    break;
                case IContentTypeValue.Scorm:
                    content = (
                        <ScormPortfolioItemContent {...itemContentProps} />
                    );

                    break;
                case IContentTypeValue.Training:
                    content = (
                        <TrainingPortfolioItemContent {...itemContentProps} />
                    );

                    break;
                case IContentTypeValue.OfferEvent:
                    content = (
                        <OfferEventPortfolioItemContent {...itemContentProps} />
                    );

                    break;
                default:
                    content = (
                        <PortfolioItemDetailsView
                            portfolioItem={portfolioItem}
                            userId={userId}
                        />
                    );
            }
        }
    }

    const getPortfolioItemActions = () => {
        if (!relatedObject || !portfolioItem || !userId) return null;

        switch (relatedObject.__typename) {
            case 'TrainingListItem':
                return (
                    <TrainingPortfolioItemActions
                        portfolioItem={portfolioItem}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <PageDrawer
            disableClose={filesAreUpdating}
            open={drawerOpen}
            onClose={() => {
                if (filesAreUpdating) return;

                handleClose();
            }}
        >
            {portfolioItemLoading ? (
                <Loader />
            ) : (
                <>
                    {user && showUser && !hideUserHeader && (
                        <>
                            <Box py={2}>
                                <ListItem p={{ xs: 2, sm: 4 }} py={0}>
                                    <ListItemAvatar>
                                        <UserAvatar
                                            bgColor="primary"
                                            size={50}
                                            sizeSm={64}
                                            user={user}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={user.name}>
                                        <ChipGroup>
                                            <UserExtraFieldsChips user={user} />
                                        </ChipGroup>
                                    </ListItemText>
                                </ListItem>
                            </Box>
                            <UserActionBar
                                hideChat
                                hidePortfolio
                                groupProps={groupProps}
                                userId={user.id}
                                userProfileProps={userProfileProps}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ flex: 1 }}
                                >
                                    {canResetProgress && (
                                        <ResetPortfolioItemProgressAction
                                            portfolioItemIds={[
                                                portfolioItem?.id,
                                            ]}
                                            onSuccess={onResetProgress}
                                        >
                                            <Button
                                                startIcon={
                                                    <Icon
                                                        path={mdiArrowULeftTop}
                                                        size="1.9rem"
                                                    />
                                                }
                                                sx={{ flex: 1 }}
                                                to="USER_PORTFOLIO"
                                                variant="outlined"
                                            >
                                                {translate('reset')}
                                            </Button>
                                        </ResetPortfolioItemProgressAction>
                                    )}
                                    {getPortfolioItemActions()}
                                </Stack>
                            </UserActionBar>
                        </>
                    )}
                    {content}
                </>
            )}
        </PageDrawer>
    );
};
