import {
    Autocomplete as MuiAutocomplete,
    AutocompleteRenderInputParams,
} from 'formik-mui';
import {
    InputLabelProps,
    OutlinedTextFieldProps,
    TextField,
    CircularProgress,
} from '@mui/material';
import { getIn } from 'formik';

export interface IProps {
    name: string;
    InputProps?: OutlinedTextFieldProps;
    InputLabelProps?: InputLabelProps;
    loading?: boolean;
    onChange?(name: string, value: unknown): void;
    endAdornment?: React.ReactNode;
}

export const Autocomplete = ({
    InputProps,
    InputLabelProps,
    field,
    form,
    disabled,
    loading,
    onChange,
    endAdornment,
    ...other
}: React.ComponentProps<typeof MuiAutocomplete> & IProps) => (
    <MuiAutocomplete
        {...other}
        disabled={disabled}
        field={{
            ...field,
            value: field.value || null,
        }}
        form={form}
        renderInput={({
            InputLabelProps: InputLabelPropsAc,
            ...params
        }: AutocompleteRenderInputParams) => {
            const { isSubmitting, touched, errors } = form;
            const fieldError = getIn(errors, field.name);
            const showError = getIn(touched, field.name) && !!fieldError;

            return (
                <TextField
                    margin="normal"
                    variant="outlined"
                    {...InputProps}
                    {...params}
                    disabled={disabled ?? isSubmitting}
                    error={showError}
                    helperText={showError ? fieldError : InputProps?.helperText}
                    InputLabelProps={{
                        ...InputLabelPropsAc,
                        ...InputLabelProps,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                        sx={{ mr: 1 }}
                                    />
                                )}
                                {!loading && endAdornment}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            );
        }}
        onChange={(e, value) => {
            if (onChange) {
                onChange(field.name, value);

                return;
            }

            form.setFieldValue(field.name, value || '');
        }}
    />
);
