import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { styled } from 'styled-components';
import { Box, Divider, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useApolloClient } from '@apollo/client';

import {
    EOfferEventCategoryType,
    TOfferEventFormValues,
    TOfferFormValues,
} from 'offer/types';
import {
    IFeature,
    IOfferEventDetailFragment,
    IOfferEventType,
    IOfferEventsQuery,
    IRole,
    IUpdateOfferEventInput,
    OfferEventsDocument,
    useCurrentUserQuery,
    useOrganisationQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { formatColor } from 'common/utils/color';
import { formatDescription } from 'offer/utils/offer';
import { getOfferFormValues } from 'offer/utils/forms';
import { Button } from 'common/components/Button';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Switch } from 'common/components/FormField/Switch/Switch';
import { DescriptionButton } from 'common/components/DescriptionButton';
import { DescriptionBox } from 'common/components/DescriptionBox';
import {
    getOfferEventFormData,
    getOfferEventTypeTranslation,
} from 'offer/utils/offerEvent';
import { Typography } from 'common/components/Typography';
import { Loader } from 'common/components/Loader';
import { useOfferEventInitialValues } from 'offer/hooks/useOfferEventInitialValues';
import {
    OfferEventFormFields,
    MeetingFormFields,
    OfferFormFields,
} from 'offer/components/forms/formFields';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import {
    DatePicker,
    SliderField,
    TextField,
} from 'common/components/FormField';
import { getImageId } from 'common/utils/image';
import { OfferPermissionsContext } from 'offer/contexts';
import { Link } from 'common/components/Link';
import { ShowAfterDelay } from 'common/components/ShowAfterDelay';
import { TrainingSelector } from 'training/components/FormField/TrainingSelector';
import { CourseSelector } from 'training/components/FormField/CourseSelector';
import { FormSection } from 'common/components/FormSection';
import { ExternalContentSelector } from 'external/components/FormField/ExternalContentSelector';
import { OfferTypeSelect } from 'offer/components/OfferTypeSelect';
import { ScormSelector } from 'training/components/FormField/ScormSelector';
import { useFeature } from 'common/hooks/useFeature';
import { VideoSelector } from 'video/components/FormField/VideoSelector';
import { GroupConditionFilter } from 'user/components/GroupConditionFilter';
import { CertificateSelectField } from 'training/components/CertificateSelectField';

const PublishGrid = styled(Grid)`
    margin-right: ${({ theme }) => theme.spacing(-1)};

    > .MuiFormControl-root {
        margin: 0;
    }
`;

interface IProps {
    disabled?: boolean;
    edit?: boolean;
    includeOfferFields?: boolean;
    initialValues?: TOfferEventFormValues;
    isOfferDetailDrawer?: boolean;
    offerEvent?: IOfferEventDetailFragment;
    offerEventType: IOfferEventType;
    offerEventCategoryType: EOfferEventCategoryType;
    onCancelEdit?(): void;
    onDelete?(): void;
    onLoading?(loading: boolean): void;
    onSubmit(values: TOfferFormValues | IUpdateOfferEventInput): void;
}

export const OfferEventForm = ({
    disabled,
    edit,
    includeOfferFields,
    isOfferDetailDrawer,
    offerEvent,
    offerEventType,
    offerEventCategoryType,
    onCancelEdit,
    onDelete,
    onLoading,
    onSubmit,
}: IProps) => {
    const { showApolloError } = useApolloError();
    const { t: translate } = useTranslation();
    const { data: currentUserData } = useCurrentUserQuery();
    const { canUse: canUseScorm, loading: loadingCanUseScorm } = useFeature(
        IFeature.Scorm
    );
    const { canUse: canUseVideo, loading: loadingCanUseVideo } = useFeature(
        IFeature.Video
    );
    const { data: organisationData, loading: loadingOrg } =
        useOrganisationQuery({ onError: showApolloError });

    const currentUser = currentUserData?.currentUser;
    const currentUserIsAuthor =
        currentUser?.roles?.includes(IRole.Author) &&
        !currentUser.roles.includes(IRole.Manager);

    const { organisation } = organisationData || {};
    const hasExternalContent = !!organisation?.hasExternalContent;
    const hasServiceProvider = !!organisation?.serviceProvider;

    const { initialValues, loading: loadingExtraCategories } =
        useOfferEventInitialValues({
            edit,
            offerEvent,
            includeOfferFields,
            offerEventType,
            isOfferDetailDrawer,
            hasServiceProvider,
            currentUserIsAuthor,
        });

    const [publishDescriptionOpen, setPublishDescriptionOpen] = useState(false);
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const isNewMeetingPage = !!useRouteMatch('OFFER_MEETING_CREATE');
    const isNewOfferPage = !!useRouteMatch('OFFER_CREATE');
    const isOfferDetailPage = !!useRouteMatch('OFFER_DETAIL');
    const isOfferEventCreatePage = !!useRouteMatch('CREATE_OFFER_EVENT');
    const isOfferDetailDrawerPage =
        !!useRouteMatch('OFFER_DETAIL_DRAWER') || isOfferDetailDrawer;
    const client = useApolloClient();

    let isOnlyOfferEvent = false;

    if (isOfferDetailPage) {
        // Get offer events from the cache because at this point this is already cached
        const data: IOfferEventsQuery | null = client.cache.readQuery({
            query: OfferEventsDocument,
            variables: { offerId: offerEvent?.offer.id },
        });

        const { offerEvents } = data || {};

        isOnlyOfferEvent = offerEvents?.length === 1;
    }

    const { canDelete = false } = useContext(OfferPermissionsContext) || {};

    const loading = loadingExtraCategories || loadingOrg;
    const isOfferCategoryType =
        offerEventCategoryType === EOfferEventCategoryType.Offer;

    let offerValidationFields = {};

    if (includeOfferFields) {
        offerValidationFields = {
            title: Yup.string().required(translate('validate.required')),
            description: Yup.string().optional(),
            groupConditions: currentUserIsAuthor
                ? Yup.array().min(1, translate('validate.required'))
                : undefined,
        };
    }

    const validationSchema = Yup.object().shape({
        ...offerValidationFields,
        ...getOfferEventFormData(offerEventType).validation,
    });

    useEffect(() => {
        setSettingsExpanded(false);
    }, [offerEventType]);

    useEffect(() => {
        onLoading && onLoading(loading);
    }, [loading, onLoading]);

    const handleSubmit = (values: TOfferEventFormValues) => {
        let formValues = {
            ...values,
            type: isOfferCategoryType ? values.type : offerEventType,
        };

        if (includeOfferFields) {
            const { overviewImage: currentOverviewImage, image: currentImage } =
                offerEvent?.offer || {};

            const offerFormValues = {
                overviewImageId: getImageId(
                    currentOverviewImage,
                    values.overviewImage
                ),
                titleColor: edit
                    ? undefined
                    : formatColor(values.titleColor || '') || null,
                imageId: edit
                    ? undefined
                    : getImageId(currentImage, values.image),
                description: edit
                    ? undefined
                    : formatDescription(values.description),
            };

            formValues = { ...formValues, ...offerFormValues };
        }

        const offerFormValues = getOfferFormValues(
            formValues,
            hasServiceProvider
        );

        return onSubmit(offerFormValues);
    };

    const isTraining = offerEventType === IOfferEventType.Training;
    const isCourse = offerEventType === IOfferEventType.Course;

    const buttonEventText = {
        offerEventType: isTraining
            ? translate('subscription').toLowerCase()
            : getOfferEventTypeTranslation(offerEventType).toLowerCase(),
    };

    const buttonText = edit
        ? translate('editOfferEvent', buttonEventText)
        : translate('createOfferEvent', buttonEventText);

    if (!onLoading && loading) return <Loader />;
    if (onLoading && loading) return null;

    const moreSettingsValidatedFields = ['trainerApproval'];

    const { id: offerId, hasDetailPage } = offerEvent?.offer || {};

    let introText = translate(
        `offer.${isOfferCategoryType ? 'newOfferIntroText' : 'newMeetingIntroText'}`
    );

    if (currentUserIsAuthor && !currentUser?.isGroupManager) {
        introText = translate('offer.newOfferIntroTextAuthor');
    }

    const isNewMeetingOrOfferPage = isNewMeetingPage || isNewOfferPage;
    const canCreateOffer =
        !currentUserIsAuthor ||
        (currentUserIsAuthor && !!currentUser?.isGroupManager);

    const isWebinar = offerEventType === IOfferEventType.Webinar;
    const isMeeting = offerEventType === IOfferEventType.Meeting;
    const isWebinarOrMeeting = isWebinar || isMeeting;
    // Meeting implies also the webinar type
    const isMeetingOrNewOffer = isWebinarOrMeeting || isNewOfferPage;
    const showGroupFilters =
        isNewMeetingOrOfferPage || (!hasDetailPage && isOfferDetailDrawerPage);
    const showMoreSettings = isMeetingOrNewOffer || isOfferDetailDrawerPage;

    return (
        <Box p={{ xs: 2, sm: 4 }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    submitForm,
                    isSubmitting,
                    dirty,
                    values,
                    setFieldValue,
                    errors,
                }) => {
                    const offerEventForm = (
                        <OfferEventFormFields
                            hideExternalContent={!hasExternalContent}
                            isOfferDetailDrawer={isOfferDetailDrawer}
                            offerEventId={offerEvent?.id}
                            offerEventType={offerEventType}
                            setFieldValue={setFieldValue}
                        />
                    );

                    const moreSettingsHasError = Object.keys(errors).some(
                        (errorKey) =>
                            moreSettingsValidatedFields.includes(errorKey)
                    );

                    let title = isOfferCategoryType
                        ? translate('offers')
                        : getOfferEventTypeTranslation(offerEventType);

                    if (isTraining) title = translate('trainingSubscription');
                    if (isCourse) title = translate('courseSubscription');

                    if (isNewMeetingOrOfferPage) {
                        title = translate(
                            isOfferCategoryType
                                ? 'offer.newOfferIntroTitle'
                                : 'offer.newMeetingIntroTitle'
                        );
                    }

                    return (
                        <>
                            <ExitPageAlert
                                alert={UnsavedChangesAlert}
                                when={dirty && !isSubmitting}
                                onCancel={() => {
                                    // Added a timeout to prevent the blocking of the drawer closing
                                    setTimeout(() => {
                                        onCancelEdit?.();
                                    }, 225);

                                    return true;
                                }}
                                onConfirm={submitForm}
                            />

                            <Form>
                                <Box mb={4}>
                                    {!!offerEvent &&
                                        hasDetailPage &&
                                        !isOfferDetailPage && (
                                            <Link
                                                params={{ id: offerId }}
                                                sx={{
                                                    fontSize: '1.6rem',
                                                    textDecoration: 'underline',
                                                }}
                                                to="OFFER_DETAIL"
                                            >
                                                {offerEvent.offer.title}
                                            </Link>
                                        )}

                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {title}
                                    </Typography>

                                    {isNewMeetingOrOfferPage && (
                                        <Box mt={2}>
                                            <Typography>{introText}</Typography>
                                        </Box>
                                    )}
                                </Box>

                                {canCreateOffer && (
                                    <>
                                        {includeOfferFields && !loading && (
                                            <OfferFormFields
                                                isOfferDetailDrawer={
                                                    isOfferDetailDrawer
                                                }
                                                renderTypeSelect={
                                                    isOfferCategoryType
                                                }
                                            />
                                        )}

                                        {!includeOfferFields &&
                                            !isNewMeetingOrOfferPage && (
                                                <Box sx={{ mb: 2 }}>
                                                    <Field
                                                        component={TextField}
                                                        label={translate(
                                                            'offerEventTitle'
                                                        )}
                                                        name="offerEventTitle"
                                                        sx={{ mt: 0 }}
                                                    />
                                                </Box>
                                            )}

                                        {!isNewMeetingOrOfferPage && (
                                            <Field
                                                multiline
                                                component={TextField}
                                                label={translate(
                                                    'offer.descriptionBeforeSubscription'
                                                )}
                                                name="descriptionBeforeSubscription"
                                                sx={{
                                                    mt: includeOfferFields
                                                        ? 2
                                                        : 0,
                                                }}
                                            />
                                        )}

                                        {isOfferCategoryType && (
                                            <>
                                                {!hasDetailPage &&
                                                    !isOfferEventCreatePage && (
                                                        <>
                                                            <Box sx={{ mb: 2 }}>
                                                                <OfferTypeSelect />
                                                            </Box>

                                                            <Divider />
                                                        </>
                                                    )}

                                                <FormSection
                                                    renderExpanded
                                                    description={
                                                        <Box>
                                                            {translate(
                                                                'offer.trainingsDescription'
                                                            )}
                                                        </Box>
                                                    }
                                                    title={translate(
                                                        'offer.trainingsTitle'
                                                    )}
                                                >
                                                    <Box mt={3}>
                                                        <TrainingSelector
                                                            name="accessTrainings"
                                                            value={
                                                                values.accessTrainings
                                                            }
                                                        />
                                                    </Box>
                                                </FormSection>

                                                {hasExternalContent && (
                                                    <FormSection
                                                        renderExpanded
                                                        description={
                                                            <Box>
                                                                {translate(
                                                                    'offer.externalContentsDescription'
                                                                )}
                                                            </Box>
                                                        }
                                                        title={translate(
                                                            'offer.externalContentsTitle'
                                                        )}
                                                    >
                                                        <Box mt={3}>
                                                            <ExternalContentSelector
                                                                name="accessExternalContents"
                                                                value={
                                                                    values.accessExternalContents ||
                                                                    []
                                                                }
                                                            />
                                                        </Box>
                                                    </FormSection>
                                                )}

                                                <FormSection
                                                    renderExpanded
                                                    description={
                                                        <Box>
                                                            {translate(
                                                                'offer.coursesDescription'
                                                            )}
                                                        </Box>
                                                    }
                                                    sx={{
                                                        borderBottom:
                                                            canUseScorm ||
                                                            canUseVideo
                                                                ? 1
                                                                : 0,
                                                        borderBottomColor:
                                                            'divider',
                                                    }}
                                                    title={translate(
                                                        'offer.coursesTitle'
                                                    )}
                                                >
                                                    <Box mt={3}>
                                                        <CourseSelector
                                                            name="accessCourses"
                                                            value={
                                                                values.accessCourses ||
                                                                []
                                                            }
                                                        />
                                                    </Box>
                                                </FormSection>

                                                {!loadingCanUseScorm &&
                                                    canUseScorm && (
                                                        <FormSection
                                                            renderExpanded
                                                            description={
                                                                <Box>
                                                                    {translate(
                                                                        'offer.scormsDescription'
                                                                    )}
                                                                </Box>
                                                            }
                                                            sx={{
                                                                borderBottom:
                                                                    canUseVideo
                                                                        ? 1
                                                                        : 0,
                                                                borderBottomColor:
                                                                    'divider',
                                                            }}
                                                            title={translate(
                                                                'offer.scormsTitle'
                                                            )}
                                                        >
                                                            <Box mt={3}>
                                                                <ScormSelector
                                                                    name="accessScorms"
                                                                    value={
                                                                        values.accessScorms ||
                                                                        []
                                                                    }
                                                                />
                                                            </Box>
                                                        </FormSection>
                                                    )}

                                                {!loadingCanUseVideo &&
                                                    canUseVideo && (
                                                        <FormSection
                                                            renderExpanded
                                                            description={
                                                                <Box>
                                                                    {translate(
                                                                        'offer.videosDescription'
                                                                    )}
                                                                </Box>
                                                            }
                                                            title={translate(
                                                                'offer.videosTitle'
                                                            )}
                                                        >
                                                            <Box mt={3}>
                                                                <VideoSelector
                                                                    name="accessVideos"
                                                                    value={
                                                                        values.accessVideos ||
                                                                        []
                                                                    }
                                                                />
                                                            </Box>
                                                        </FormSection>
                                                    )}

                                                {!isWebinarOrMeeting && (
                                                    <FormSection
                                                        renderExpanded
                                                        description={
                                                            <Box>
                                                                {translate(
                                                                    'offer.certificateDescription'
                                                                )}
                                                            </Box>
                                                        }
                                                        sx={{
                                                            borderBottom: 0,
                                                        }}
                                                        title={translate(
                                                            'offer.certificateTitle'
                                                        )}
                                                    >
                                                        <CertificateSelectField name="certificate" />

                                                        <Field
                                                            component={
                                                                SliderField
                                                            }
                                                            label={translate(
                                                                'offer.certificateThreshold'
                                                            )}
                                                            marks={[
                                                                {
                                                                    value: 0,
                                                                    label: '0%',
                                                                },
                                                                {
                                                                    value: 1,
                                                                    label: '100%',
                                                                },
                                                            ]}
                                                            max={1}
                                                            min={0}
                                                            name="scoreThreshold"
                                                            step={0.01}
                                                            valueLabelFormat={(
                                                                value: string
                                                            ) =>
                                                                `${Math.floor(+value * 100)}%`
                                                            }
                                                        />
                                                    </FormSection>
                                                )}
                                            </>
                                        )}

                                        {!isOfferCategoryType && (
                                            <MeetingFormFields
                                                hasServiceProvider={
                                                    hasServiceProvider
                                                }
                                            />
                                        )}

                                        {showGroupFilters && (
                                            <FormSection
                                                renderExpanded
                                                defaultExpanded={
                                                    currentUserIsAuthor
                                                }
                                                description={
                                                    <Box>
                                                        {translate(
                                                            'offer.groupFilterDescription'
                                                        )}
                                                    </Box>
                                                }
                                                hasError={
                                                    !!errors?.groupConditions
                                                }
                                                sx={{
                                                    borderTop: 1,
                                                    borderTopColor: 'divider',
                                                    marginTop:
                                                        isOfferCategoryType
                                                            ? 0
                                                            : 3,

                                                    '&.Mui-expanded': {
                                                        marginTop:
                                                            isOfferCategoryType
                                                                ? 0
                                                                : 3,
                                                    },
                                                }}
                                                title={translate(
                                                    'offer.groupFilterTitle'
                                                )}
                                            >
                                                <Box mt={3}>
                                                    <GroupConditionFilter
                                                        error={
                                                            errors?.groupConditions
                                                        }
                                                        groupConditionInputs={
                                                            values.groupConditions
                                                        }
                                                    />
                                                </Box>
                                            </FormSection>
                                        )}

                                        <FormSection
                                            description={
                                                <Box>
                                                    {translate(
                                                        'offer.offerSubscribeSettingsDescription'
                                                    )}
                                                </Box>
                                            }
                                            hasError={!!errors?.trainerApproval}
                                            sx={{
                                                borderBottom:
                                                    isWebinarOrMeeting &&
                                                    showMoreSettings
                                                        ? 0
                                                        : 1,
                                                borderTop: showGroupFilters
                                                    ? 0
                                                    : 1,
                                                borderColor: 'divider',
                                            }}
                                            title={translate(
                                                'offer.subscribeSettings'
                                            )}
                                        >
                                            <Field
                                                multiline
                                                component={TextField}
                                                label={translate(
                                                    'offer.descriptionAfterSubscription'
                                                )}
                                                name="descriptionAfterSubscription"
                                            />

                                            {isWebinarOrMeeting && (
                                                <Grid
                                                    container
                                                    spacing={{ sm: 2 }}
                                                >
                                                    <Grid item sm xs={12}>
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            label={translate(
                                                                'offerEventForm.minSubscriptions'
                                                            )}
                                                            name="minSubscriptions"
                                                        />
                                                    </Grid>

                                                    <Grid item sm xs={12}>
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            label={translate(
                                                                'maxSubscriptions'
                                                            )}
                                                            name="maxSubscriptions"
                                                        />
                                                    </Grid>

                                                    <Grid item sm xs={12}>
                                                        <Field
                                                            component={
                                                                DatePicker
                                                            }
                                                            label={translate(
                                                                'closingDate'
                                                            )}
                                                            name="closingDate"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}

                                            <Box sx={{ mt: 1 }}>
                                                {isWebinarOrMeeting && (
                                                    <Field
                                                        checked={
                                                            values.trainerApproval
                                                        }
                                                        component={Switch}
                                                        formControlProps={{
                                                            margin: 'none',
                                                        }}
                                                        label={translate(
                                                            'offer.accessTrainerApproval'
                                                        )}
                                                        name="trainerApproval"
                                                    />
                                                )}

                                                <Field
                                                    checked={
                                                        values.showParticipantList
                                                    }
                                                    component={Switch}
                                                    formControlProps={{
                                                        margin: 'none',
                                                    }}
                                                    label={translate(
                                                        'offer.showParticipantListToParticipants'
                                                    )}
                                                    name="showParticipantList"
                                                />

                                                {isWebinarOrMeeting && (
                                                    <Field
                                                        checked={
                                                            values.waitingList
                                                        }
                                                        component={Switch}
                                                        formControlProps={{
                                                            margin: 'none',
                                                        }}
                                                        label={translate(
                                                            'offerEventForm.waitingList'
                                                        )}
                                                        name="waitingList"
                                                    />
                                                )}
                                            </Box>
                                        </FormSection>

                                        {showMoreSettings &&
                                            isWebinarOrMeeting && (
                                                <ContentExpander
                                                    expand={
                                                        moreSettingsHasError ||
                                                        settingsExpanded
                                                    }
                                                    title={translate(
                                                        'moreSettings'
                                                    )}
                                                    onClick={() =>
                                                        setSettingsExpanded(
                                                            !settingsExpanded
                                                        )
                                                    }
                                                >
                                                    {offerEventForm}
                                                </ContentExpander>
                                            )}

                                        {showMoreSettings &&
                                            !isWebinarOrMeeting &&
                                            offerEventForm}

                                        <Box mt={3}>
                                            <Box mb={3}>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                >
                                                    <PublishGrid item>
                                                        <Field
                                                            checked={
                                                                values.publish
                                                            }
                                                            component={Switch}
                                                            label={translate(
                                                                'published'
                                                            )}
                                                            name="publish"
                                                        />
                                                    </PublishGrid>

                                                    <Grid item>
                                                        <DescriptionButton
                                                            onClick={() =>
                                                                setPublishDescriptionOpen(
                                                                    !publishDescriptionOpen
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <DescriptionBox
                                                    open={
                                                        publishDescriptionOpen
                                                    }
                                                    onClose={() =>
                                                        setPublishDescriptionOpen(
                                                            false
                                                        )
                                                    }
                                                >
                                                    {translate(
                                                        'offer.offerPublishInfoDescription'
                                                    )}
                                                </DescriptionBox>
                                            </Box>

                                            <Divider />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                mt: 3,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={
                                                    isSubmitting ||
                                                    disabled ||
                                                    (!isSubmitting &&
                                                        !disabled &&
                                                        !dirty)
                                                }
                                                loading={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                onClick={(
                                                    e: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    e.preventDefault();

                                                    return submitForm();
                                                }}
                                            >
                                                {buttonText}
                                            </Button>

                                            {hasServiceProvider && (
                                                <ShowAfterDelay
                                                    delay={2000}
                                                    show={isSubmitting}
                                                >
                                                    <Box
                                                        sx={{
                                                            ml: 2,
                                                            lineHeight: 1.1,
                                                            fontSize: 14,
                                                        }}
                                                    >
                                                        {translate(
                                                            'syncingToServiceProviderMicrosoftEntra'
                                                        )}
                                                    </Box>
                                                </ShowAfterDelay>
                                            )}

                                            {canDelete &&
                                                !!onDelete &&
                                                !isOnlyOfferEvent && (
                                                    <Box ml="auto">
                                                        <FormDeleteButton
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            onClick={onDelete}
                                                        >
                                                            {translate(
                                                                'delete'
                                                            )}
                                                        </FormDeleteButton>
                                                    </Box>
                                                )}
                                        </Box>
                                    </>
                                )}
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Box>
    );
};
