type TGroupType = 'portfolio' | 'development' | 'trainingOverview';
export type TGroupsState = { [key: string]: boolean };

// Returns the groups state (collapsed or expanded) as an object of group id's with boolean values
export function getGroupsState(groupType: TGroupType): TGroupsState {
    const storageStateString = localStorage.getItem(
        `${groupType}ItemGroupsState`
    );

    if (!storageStateString) return {};

    const storageState = JSON.parse(storageStateString) as TGroupsState;

    return !storageState ? {} : storageState;
}
