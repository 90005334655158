import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, FormControlLabel, Switch, Stack } from '@mui/material';

import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import {
    ICertificateFragment,
    IScoringFragment,
    IOfferEventSubscriptionStatus,
} from 'graphql/types';
import { InfoRow } from 'common/components/InfoRow';
import { Divider } from 'common/components/Divider';
import { getExpiryDisplay } from 'common/utils/expiryScale';
import { getScoringDisplay } from 'common/utils/scoring';
import { Typography } from 'common/components/Typography';

export type TEnrollmentStatusDialogSettings = {
    open: boolean;
    selectedAmount: number;
    enrollmentId?: string;
    status?: IOfferEventSubscriptionStatus;
    certificate?: ICertificateFragment;
    scoring?: IScoringFragment[];
};

interface IProps {
    dialogSettings: TEnrollmentStatusDialogSettings;
    loading?: boolean;
    certificate?: ICertificateFragment;
    scoring?: IScoringFragment[];
    showCertificateField?: boolean;
    onClose?(): void;
    onConfirm?(reason: string, generateCertificate?: boolean): void;
}

export const EnrollmentStatusDialog = ({
    dialogSettings,
    loading,
    onClose,
    onConfirm,
    certificate,
    scoring,
    showCertificateField = false,
}: IProps) => {
    const [translate] = useTranslation();
    const [reasonValue, setReasonValue] = useState('');
    const [generateCertificate, setGenerateCertificate] = useState(false);

    const { selectedAmount, open } = dialogSettings;

    useEffect(() => {
        if (open) return;

        // Reset value when dialog is closed
        setReasonValue('');
        setGenerateCertificate(false);
    }, [open]);

    if (!open) return null;

    const count = !selectedAmount ? 1 : selectedAmount;

    let transKey = '';

    let showReasonField = false;

    switch (dialogSettings.status) {
        case IOfferEventSubscriptionStatus.Enrolled:
            transKey = 'enroll';
            break;
        case IOfferEventSubscriptionStatus.Denied:
            transKey = 'reject';
            showReasonField = true;
            break;
        case IOfferEventSubscriptionStatus.Completed:
            transKey = 'complete';
            showReasonField = true;

            if (!!certificate) {
                showCertificateField = true;
                transKey = 'completeWithCertificate';
            }
            break;
        case IOfferEventSubscriptionStatus.Absent:
            transKey = 'absent';
            showReasonField = true;
            break;
    }

    transKey = `offerEventEnrollmentStatusDialog.${transKey}`;

    const title = translate(`${transKey}.title`, { count });
    const text = translate(`${transKey}.text`, { count });
    const btnText = translate(`${transKey}.btnText`);

    return (
        <AlertDialog
            actions={
                <Box display="flex" mt={2}>
                    <Box mr={2}>
                        <Button
                            color="primary"
                            loading={loading}
                            variant="contained"
                            onClick={() =>
                                onConfirm?.(reasonValue, generateCertificate)
                            }
                        >
                            {btnText}
                        </Button>
                    </Box>

                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={() => onClose?.()}
                    >
                        {translate('close')}
                    </Button>
                </Box>
            }
            open={dialogSettings.open}
            title={title}
        >
            {text}

            {showReasonField && (
                <TextField
                    fullWidth
                    multiline
                    disabled={loading}
                    label={translate(
                        'offerEventEnrollmentStatusDialog.reasonFieldLabel'
                    )}
                    margin="normal"
                    sx={{ textarea: { minHeight: '60px' } }}
                    value={reasonValue}
                    variant="outlined"
                    onChange={(e) => setReasonValue(e.currentTarget.value)}
                />
            )}

            {showCertificateField && (
                <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={generateCertificate}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setGenerateCertificate(e.target.checked)}
                            />
                        }
                        label={translate(
                            'offerEventEnrollmentStatusDialog.certificateFieldLabel'
                        )}
                        sx={{
                            '.MuiFormControlLabel-label': {
                                fontWeight: 700,
                            },
                        }}
                    />

                    {!certificate && generateCertificate && (
                        <Typography color="error.main" variant="body2">
                            {translate(
                                'offerEventEnrollmentStatusDialog.certificateGeneralMessage'
                            )}
                        </Typography>
                    )}

                    {certificate && (
                        <>
                            <Divider sx={{ my: 2 }} />

                            <Stack spacing={2}>
                                <InfoRow description="Naam">
                                    {certificate.name}
                                </InfoRow>
                                {certificate.expiry &&
                                    certificate.expiryScale && (
                                        <InfoRow
                                            description={translate(
                                                'offerEventEnrollmentStatusDialog.certificatExpiry'
                                            )}
                                        >
                                            {getExpiryDisplay(
                                                certificate.expiry,
                                                certificate.expiryScale
                                            )}
                                        </InfoRow>
                                    )}
                                {!!scoring?.length && (
                                    <InfoRow
                                        description={translate(
                                            'offerEventEnrollmentStatusDialog.certificateScoring'
                                        )}
                                    >
                                        {getScoringDisplay(scoring)}
                                    </InfoRow>
                                )}
                            </Stack>

                            <Divider sx={{ mt: 2 }} />
                        </>
                    )}
                </Box>
            )}
        </AlertDialog>
    );
};
