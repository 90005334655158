import {
    InputAdornment as MuiInputAdornment,
    InputAdornmentProps,
    SxProps,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

const inputAdornmentStyle: SxProps = {
    mr: '1px',
    '.MuiTypography-root': {
        fontSize: 16,
    },
};

export interface IProps extends InputAdornmentProps {
    sx?: SxProps;
}

export const InputAdornment = ({ sx, ...other }: IProps) => (
    <MuiInputAdornment
        {...other}
        sx={[
            inputAdornmentStyle,
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
