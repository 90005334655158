import { Stack, Grid, Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiDotsVertical } from '@mdi/js';

import { IExternalContentListItemFragment, Maybe } from 'graphql/types';
import { ExternalContentListItem } from 'external/components/ExternalContentListItem';
import { List } from 'common/components/List';
import { Button } from 'common/components/Button';
import { BoxLoader } from 'common/components/Loader';
import { Link } from 'common/components/Link';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { IconButton } from 'common/components/IconButton';

interface IProps {
    externalContent?: Maybe<IExternalContentListItemFragment>;
    loading?: boolean;
    onReplace?(): void;
}

export const ExternalContentFormItem = ({
    externalContent,
    loading,
    onReplace,
}: IProps) => {
    const [translate] = useTranslation();

    if (loading) return <BoxLoader height="197px" />;

    if (!externalContent) {
        return (
            <Button
                sx={{ width: '100%', height: '150px' }}
                variant="contained"
                onClick={onReplace}
            >
                {translate('selectExternalContent')}
            </Button>
        );
    }

    const { link } = externalContent;

    return (
        <List>
            <ExternalContentListItem
                disabled
                button={false}
                externalContent={externalContent}
                footer={
                    <Grid container>
                        <Grid item xs>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    component={Link}
                                    href={link}
                                    target="_blank"
                                    variant="contained"
                                >
                                    {translate('view')}
                                </Button>
                            </Stack>
                        </Grid>
                        {!!onReplace && (
                            <Grid
                                item
                                component={Box}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DropdownMenu
                                    anchor={
                                        <IconButton
                                            color="inherit"
                                            iconPath={mdiDotsVertical}
                                            iconSize="2.8rem"
                                        />
                                    }
                                    placement="bottom-end"
                                >
                                    <MenuItem onClick={onReplace}>
                                        {translate('replaceExternalContent')}
                                    </MenuItem>
                                </DropdownMenu>
                            </Grid>
                        )}
                    </Grid>
                }
                px={2}
                py={0}
            />
        </List>
    );
};
