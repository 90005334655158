import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { IExternalContentListItemFragment } from 'graphql/types';
import { ExternalContentListItem } from 'external/components/ExternalContentListItem';
import {
    isExternalContentAdded,
    isExternalContentSelected,
} from 'external/utils/externalContentSelect';

interface IProps {
    addedExternalContents: IExternalContentListItemFragment[];
    selectedExternalContents: IExternalContentListItemFragment[];
    externalContents: IExternalContentListItemFragment[];
    onAddExternalContent(
        selectedExternalContent: IExternalContentListItemFragment
    ): void;
    onRemoveExternalContent(
        selectedExternalContent: IExternalContentListItemFragment
    ): void;
    listItemProps?: Partial<
        React.ComponentProps<typeof ExternalContentListItem>
    >;
}

export const ExternalContentAddList = ({
    addedExternalContents,
    selectedExternalContents,
    externalContents,
    onAddExternalContent,
    onRemoveExternalContent,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleSelectExternalContent = (
        externalContent: IExternalContentListItemFragment,
        isAdded: boolean
    ) => {
        isAdded
            ? onRemoveExternalContent(externalContent)
            : onAddExternalContent(externalContent);
    };

    return (
        <>
            {!externalContents.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>
                        {translate('noOptionsText.externalContents')}
                    </Typography>
                </Box>
            ) : (
                externalContents.filter(Boolean).map((externalContent) => {
                    const isSelected = isExternalContentSelected(
                        selectedExternalContents,
                        externalContent
                    );
                    const isAdded = isExternalContentAdded(
                        addedExternalContents,
                        externalContent
                    );

                    return (
                        <ExternalContentListItem
                            addButton
                            added={isSelected || isAdded}
                            externalContent={externalContent}
                            key={externalContent.id}
                            px={2}
                            selected={isSelected || isAdded}
                            onClick={() =>
                                handleSelectExternalContent(
                                    externalContent,
                                    isSelected || isAdded
                                )
                            }
                        />
                    );
                })
            )}
        </>
    );
};
