import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { AddButton } from 'common/components/Button/AddButton';
import { useOpenIdConnectionsQuery } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { Loader } from 'common/components/Loader';
import { Divider } from 'common/components/Divider';
import { TableCell } from 'common/components/Table';
import { Chip, ChipGroup } from 'common/components/Chip';

import { OpenIdConnectionCreateDrawer } from './OpenIdConnectionCreateDrawer';
import { OpenIdConnectionUpdateDrawer } from './OpenIdConnectionUpdateDrawer';

export const OpenIdManager = () => {
    const [translate] = useTranslation();
    const { data, loading } = useOpenIdConnectionsQuery();
    const [openIdConnectionId, setOpenIdConnectionId] = useState<string>();
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const { openIdConnections } = data || {};

    const hasOpenIdConnections = !!openIdConnections?.length;

    return (
        <Box>
            {!loading && (
                <AddButton
                    onClick={() => {
                        setCreateDrawerOpen(true);
                    }}
                >
                    <Typography>
                        {translate('openIdConnectionForm.openIdConnect')}
                    </Typography>
                </AddButton>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '200px' }}>
                    <Loader />
                </Box>
            )}

            {hasOpenIdConnections && (
                <>
                    <Box sx={{ mt: 2 }}>
                        <Divider />
                    </Box>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                {openIdConnections.map((openIdConnection) => {
                                    const {
                                        id,
                                        name,
                                        active,
                                        numberOfUsers,
                                        clientId,
                                        clientSecret,
                                        authorizationEndpoint,
                                        tokenEndpoint,
                                        userEndpoint,
                                        scope,
                                        oidcOpJwksEndpoint,
                                    } = openIdConnection;

                                    const actionRequired =
                                        !clientId ||
                                        !clientSecret ||
                                        !authorizationEndpoint ||
                                        !tokenEndpoint ||
                                        !userEndpoint ||
                                        !scope ||
                                        !oidcOpJwksEndpoint;

                                    const hasChips = !active || actionRequired;

                                    const participantInfo = `${
                                        numberOfUsers || 0
                                    } ${translate(
                                        'participants'
                                    ).toLowerCase()}`;

                                    return (
                                        <TableRow
                                            key={`openIdConnection-${id}`}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                setOpenIdConnectionId(id)
                                            }
                                        >
                                            <TableCell>
                                                {name}

                                                <Box color="grey.500" mt="2px">
                                                    {participantInfo}
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                {hasChips && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'right',
                                                        }}
                                                    >
                                                        <Box mr={2}>
                                                            <ChipGroup>
                                                                {!active && (
                                                                    <Chip
                                                                        color="error"
                                                                        label={translate(
                                                                            'inactive'
                                                                        )}
                                                                    />
                                                                )}

                                                                {actionRequired && (
                                                                    <Chip
                                                                        color="error"
                                                                        label={translate(
                                                                            'actionRequired'
                                                                        )}
                                                                    />
                                                                )}
                                                            </ChipGroup>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <OpenIdConnectionUpdateDrawer
                        open={!!openIdConnectionId}
                        openIdConnectionId={openIdConnectionId}
                        onClose={() => {
                            if (openIdConnectionId) {
                                setOpenIdConnectionId(undefined);
                            }
                        }}
                    />
                </>
            )}

            <OpenIdConnectionCreateDrawer
                open={createDrawerOpen}
                onClose={() => {
                    setCreateDrawerOpen(false);
                }}
            />
        </Box>
    );
};
