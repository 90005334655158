import {
    EFilterType,
    IFilterFormItem,
    TFilterFormValue,
    TStringFilterValue,
} from 'common/types';
import {
    IContentTypeValue,
    IExtraCategoryOption,
    useExtraCategoryFiltersQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { MultiSelect } from 'common/components/FormField';

export function useExtraCategoryFilters(contentType: IContentTypeValue): {
    loading: boolean;
    filters: IFilterFormItem[];
} {
    const { showApolloError } = useApolloError();

    const { data, loading } = useExtraCategoryFiltersQuery({
        variables: { contentType },
        onError: showApolloError,
    });

    const { extraCategoriesFilters } = data || {};

    return {
        loading,
        filters:
            extraCategoriesFilters
                ?.map((extraCategoryFilter) => {
                    const { id, name, categoryType, values } =
                        extraCategoryFilter;

                    if (
                        categoryType === IExtraCategoryOption.Datetime ||
                        categoryType === IExtraCategoryOption.Boolean
                    ) {
                        return null;
                    }

                    return {
                        key: `extraCategory-${id}`,
                        label: name,
                        fields: [
                            {
                                name: name,
                                label: name,
                                type: EFilterType.String,
                                initialValue: [],
                                component: MultiSelect,
                                componentProps: {
                                    options: values,
                                    InputProps: { label: name },
                                },
                                queryKey: 'extraCategories',
                                parseQueryValue: (values: TFilterFormValue) =>
                                    (values as TStringFilterValue[]).map(
                                        (value) => ({
                                            id: id,
                                            stringValue: value,
                                            // Both choice and string are handled as string
                                            type: IExtraCategoryOption.String,
                                        })
                                    ),
                            },
                        ],
                    } as IFilterFormItem;
                })
                .filter(Boolean) || [],
    };
}
