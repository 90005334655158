import { Field, useFormikContext } from 'formik';
import { Box, FormControlLabel } from '@mui/material';
import { Trans } from 'react-i18next';

import {
    TextField,
    Autocomplete,
    MultiSelect,
    DatePicker,
} from 'common/components/FormField';
import {
    IContentTypeValue,
    IExtraCategoryFragment,
    IExtraCategoryOption,
    useExtraCategoriesQuery,
} from 'graphql/types';
import { TExtraCategoryInput } from 'organisation/types';
import { Checkbox } from 'common/components/Checkbox';
import { Typography } from 'common/components/Typography';
import { useApolloError } from 'common/hooks/useApolloError';
import { TSelectOption } from 'common/types';
import { Link } from 'common/components/Link';

interface IProps {
    contentType: IContentTypeValue;
}

export const ExtraCategoryFormFields = ({ contentType }: IProps) => {
    const { showApolloError } = useApolloError();
    const { values, errors, setFieldValue } = useFormikContext<{
        extraCategories: TExtraCategoryInput;
    }>();
    const { data, loading } = useExtraCategoriesQuery({
        variables: { contentType },
        onError: showApolloError,
    });
    const { extraCategories } = data || {};

    return (
        <>
            {!loading && !extraCategories?.length && (
                <Trans
                    components={{
                        a: (
                            <Link
                                rel="noreferrer"
                                to="ORGANISATION_EXTRA_CATEGORIES"
                                underline="always"
                            />
                        ),
                    }}
                    i18nKey="noExtraCategories"
                />
            )}

            {extraCategories?.map((extraCategory: IExtraCategoryFragment) => {
                const { id, multipleChoices, name, choiceOptions } =
                    extraCategory;

                switch (extraCategory.categoryType) {
                    case IExtraCategoryOption.Choice:
                        return (
                            <Field
                                component={
                                    multipleChoices ? MultiSelect : Autocomplete
                                }
                                getOptionLabel={(option: TSelectOption) =>
                                    option.label
                                }
                                InputProps={{ label: name }}
                                isOptionEqualToValue={(
                                    option: TSelectOption,
                                    value: TSelectOption
                                ) => option.value === value.value}
                                key={id}
                                label={name}
                                name={`extraCategories.${id}`}
                                options={choiceOptions?.map((choiceOption) => ({
                                    label: choiceOption.stringValue,
                                    value: choiceOption.id,
                                }))}
                            />
                        );

                    case IExtraCategoryOption.Boolean:
                        return (
                            <Box key={id}>
                                <FormControlLabel
                                    checked={!!values.extraCategories?.[id]}
                                    control={<Field component={Checkbox} />}
                                    label={name}
                                    name={`extraCategories.${id}`}
                                    onChange={(_e, checked) => {
                                        setFieldValue(
                                            `extraCategories.${id}`,
                                            checked
                                        );
                                    }}
                                />

                                {errors.extraCategories?.[id] && (
                                    <Typography
                                        className="MuiFormHelperText-contained"
                                        color="error.main"
                                        variant="body2"
                                    >
                                        {errors.extraCategories?.[id]}
                                    </Typography>
                                )}
                            </Box>
                        );

                    case IExtraCategoryOption.Datetime:
                        return (
                            <Field
                                component={DatePicker}
                                key={id}
                                label={name}
                                name={`extraCategories.${id}`}
                            />
                        );

                    default:
                        return (
                            <Field
                                component={TextField}
                                key={id}
                                label={name}
                                name={`extraCategories.${id}`}
                            />
                        );
                }
            })}
        </>
    );
};
