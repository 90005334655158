import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';

import { AddButton } from 'common/components/Button/AddButton';
import { Typography } from 'common/components/Typography';
import { GroupSelectDrawer } from 'user/components/GroupSelectDrawer';
import { IGroupListItemFragment } from 'graphql/types';
import { GroupListItem } from 'user/components/GroupListItem/GroupListItem';
import { TGroupPermission } from 'user/types';
import { getGroupSelectStatus, isGroupAdded } from 'user/utils';

interface IProps {
    checkGroupPermission?: TGroupPermission;
    name: string;
    label?: string;
    nameManagerGroups?: string;
    nameParticipantGroups?: string;
    value: IGroupListItemFragment[];
}

export const FormGroupSelector = ({
    checkGroupPermission,
    name,
    label,
    value,
}: IProps) => {
    const { setFieldValue } = useFormikContext();
    const [translate] = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const addedGroups = value;

    const [selectedGroups, setSelectedGroups] =
        useState<IGroupListItemFragment[]>(addedGroups);

    const handleSelectGroup = (group: IGroupListItemFragment) => {
        const newGroups = [...selectedGroups, group].sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        setSelectedGroups(newGroups);
    };

    const handleAddGroups = () => {
        setFieldValue(name, selectedGroups);

        setDrawerOpen(false);
    };

    const handleRemoveGroup = (
        group: IGroupListItemFragment,
        removeAdded?: boolean
    ) => {
        const { isSelected } = getGroupSelectStatus(
            selectedGroups,
            addedGroups,
            group
        );

        if (isSelected) {
            const newSelectedGroups = selectedGroups.filter(
                (selectedGroup) => selectedGroup.id !== group.id
            );

            setSelectedGroups(newSelectedGroups);
        }

        // When an added group is removed, it needs to be removed from the form
        if (!removeAdded) return;

        const newGroups = addedGroups.filter(
            (addedGroup) => group.id !== addedGroup.id
        );

        setFieldValue(name, newGroups);
    };

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const addedGroups =
                    (form.values[name] as IGroupListItemFragment[]) || [];
                const error = form.errors[name];
                const touched = form.touched[name];

                return (
                    <>
                        <AddButton onClick={() => setDrawerOpen(true)}>
                            <Typography>
                                {label || translate('addGroupsTitle')}
                            </Typography>

                            {error && touched && (
                                <Box mt="-5px" position="absolute">
                                    <FormHelperText error>
                                        {form.errors[name]}
                                    </FormHelperText>
                                </Box>
                            )}
                        </AddButton>

                        {!!addedGroups.length && (
                            <Box display="flex" flexDirection="column" mt={2}>
                                {addedGroups.map((addedGroup) => {
                                    const { permissions } = addedGroup;
                                    const permissionsObj = JSON.parse(
                                        permissions || '{}'
                                    );

                                    let isDisabled = false;

                                    // Check if the user has the permission to remove this group
                                    if (
                                        (!!checkGroupPermission?.canAddParticipants &&
                                            !permissionsObj.canAddParticipants) ||
                                        (!!checkGroupPermission?.canAddManagers &&
                                            !permissionsObj.canAddManagers)
                                    ) {
                                        isDisabled = true;
                                    }

                                    return (
                                        <GroupListItem
                                            addButton
                                            added={isGroupAdded(
                                                addedGroups,
                                                addedGroup
                                            )}
                                            disabled={isDisabled}
                                            group={addedGroup}
                                            key={`added-${addedGroup.id}`}
                                            onClick={(group) => {
                                                handleRemoveGroup(group, true);
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                        )}

                        <GroupSelectDrawer
                            addedGroups={addedGroups}
                            checkGroupPermission={checkGroupPermission}
                            open={drawerOpen}
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            onAddGroups={handleAddGroups}
                            onClose={() => setDrawerOpen(false)}
                            onRemoveGroup={(group: IGroupListItemFragment) =>
                                handleRemoveGroup(group)
                            }
                            onSelectGroup={handleSelectGroup}
                        />
                    </>
                );
            }}
        />
    );
};
