import { Field, useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { DateTimePicker, Select, Switch } from 'common/components/FormField';
import { IConditionEffect, IUpdateModuleGroupInput } from 'graphql/types';
import { getEventRemindeOffsetTypeTranslation } from 'training/utils/moduleGroup';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { REMINDER_OFFSET_LIST } from 'training/constants/moduleGroup';

interface IProps {
    disabled?: boolean;
}

export const ConditionAbsoluteFormFields = ({ disabled }: IProps) => {
    const [translate] = useTranslation();
    const { values, setFieldValue } =
        useFormikContext<IUpdateModuleGroupInput>();

    const transKeyPrefix = 'moduleGroupForm.absoluteCondition';

    return (
        <>
            <FormSection
                defaultExpanded
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey={`${transKeyPrefix}.preConditionSectionDescription`}
                        />
                    </Box>
                }
                title={translate(`${transKeyPrefix}.preConditionSectionTitle`)}
            >
                <Grid container spacing={2}>
                    <Grid item sm={5} xs={12}>
                        <Field
                            required
                            component={DateTimePicker}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.date`)}
                            name="absoluteCondition.start"
                        />
                    </Grid>

                    <Grid item sm={7} xs={12}>
                        <Field
                            required
                            component={Select}
                            disabled={disabled}
                            label={translate(
                                `${transKeyPrefix}.reminderOffset`
                            )}
                            name="absoluteCondition.reminderOffset"
                            options={REMINDER_OFFSET_LIST.map(
                                (reminderOffset) => ({
                                    value: reminderOffset,
                                    label: getEventRemindeOffsetTypeTranslation(
                                        reminderOffset
                                    ),
                                })
                            )}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mb: -2 }}>
                    <Field
                        checked={
                            values.absoluteCondition?.preConditionEffect ===
                            IConditionEffect.Hide
                        }
                        component={Switch}
                        disabled={disabled}
                        label={translate('moduleGroupForm.hidden')}
                        name="absoluteCondition.preConditionEffect"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFieldValue(
                                'absoluteCondition.preConditionEffect',
                                event.target.checked
                                    ? IConditionEffect.Hide
                                    : IConditionEffect.Lock
                            );
                        }}
                    />
                </Box>
            </FormSection>

            <FormSection
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey={`${transKeyPrefix}.postConditionSectionDescription`}
                        />
                    </Box>
                }
                title={translate(`${transKeyPrefix}.postConditionSectionTitle`)}
            >
                <Grid container spacing={2}>
                    <Grid item sm={5} xs={12}>
                        <Field
                            component={DateTimePicker}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.deadlineDate`)}
                            name="absoluteCondition.deadline"
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mb: -2 }}>
                    <Field
                        checked={
                            values.absoluteCondition?.postConditionEffect ===
                            IConditionEffect.Hide
                        }
                        component={Switch}
                        disabled={disabled}
                        label={translate('moduleGroupForm.hidden')}
                        name="absoluteCondition.postConditionEffect"
                        sx={{ mt: { xs: 1, sm: 0 } }}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFieldValue(
                                'absoluteCondition.postConditionEffect',
                                event.target.checked
                                    ? IConditionEffect.Hide
                                    : IConditionEffect.Lock
                            );
                        }}
                    />
                </Box>
            </FormSection>
        </>
    );
};
