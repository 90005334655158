import {
    TDateFilterValue,
    TObjectFilterValue,
    TStringFilterValue,
} from 'common/types';
import {
    IAssignmentModule,
    ICertificateModule,
    ICourseModule,
    IFileModule,
    ILinkModule,
    IMeetingModule,
    ILtiModule,
    IVideoModule,
    IConditionEffect,
    ITrainingFragment,
    IExternalContentModule,
    IFeature,
    IFileFragment,
    IOpenOffsetType,
    ICourseFragment,
    IUpdateModuleGroupInput,
    ICourseComparison,
    IUpdateCourseCompletionConditionInput,
    ICreateModuleGroupInput,
    ICreateCourseCompletionConditionInput,
    ICreateAbsoluteConditionInput,
    IUpdateAbsoluteConditionInput,
    ICourseOrLtiModule,
    IAfasWebhookModule,
    ITrainingRole,
    IUserListItemFragment,
    IPortfolioItemMatrixListItemFragment,
} from 'graphql/types';

type TModule =
    | IAssignmentModule
    | ICertificateModule
    | ICourseModule
    | IFileModule
    | ILinkModule
    | IMeetingModule
    | ILtiModule
    | IVideoModule
    | IExternalContentModule
    | IAfasWebhookModule;

export interface ITrainingSectionProps {
    canManage?: boolean;
    canManageParticipants?: boolean;
    training: ITrainingFragment;
}

export type TTrainingModuleProps<M = TModule> = M & {
    canManage?: boolean;
    canManageParticipants?: boolean;
    canUpdate?: boolean;
    disabled?: boolean;
    moduleGroupId: string;
    groupConditionEffect: IConditionEffect;
    trainingId: string;
    currentUserIsAuthor?: boolean;
    currentUserIsTrainer?: boolean;
};

export interface IModuleCreateDrawerProps {
    trainingId: string;
    moduleGroupId?: string;
    onClose?(): void;
}

export interface IModuleUpdateDrawerProps {
    id: string;
    onClose?(): void;
}

type TModuleCreateDrawerComponent = React.ElementType<IModuleCreateDrawerProps>;
type TModuleUpdateDrawerComponent = React.ElementType<IModuleUpdateDrawerProps>;

export interface ITrainingModuleType {
    key: string;
    icon: React.ReactNode;
    feature?: IFeature;
    createDrawerComponent?: TModuleCreateDrawerComponent;
    updateDrawerComponent?: TModuleUpdateDrawerComponent;
    upgradeHeaderImage?: string;
    upgradeTitle?: string;
    upgradeDescription?: string;
    titleTranslationKey?: string;
}

export interface IModuleFormOffset {
    type: IOpenOffsetType;
    days: number;
    hours: number;
}

export interface IModuleFormValues {
    image?: IFileFragment;
    offset?: IModuleFormOffset;
}

export type TCourseFormValues = Record<'id', string> &
    Record<'ltiLaunchUrl', string> &
    Record<'ltiKey', string> &
    Record<'ltiSecret', string> &
    Pick<ICourseFragment, 'thumbnail'>;

export enum IConditionType {
    Relative = 'relativeCondition',
    CourseCompletion = 'courseCompletionCondition',
}

type TCourseCompletionCondition = Omit<
    | ICreateCourseCompletionConditionInput
    | IUpdateCourseCompletionConditionInput,
    'courseOrLti' | 'comparison'
> & {
    courseOrLti?: ICourseOrLtiModule;
    comparison?: ICourseComparison;
};

export type TModuleGroupFormValues = Omit<
    ICreateModuleGroupInput | IUpdateModuleGroupInput,
    'courseCompletionCondition' | 'absoluteCondition'
> & {
    courseCompletionCondition?: TCourseCompletionCondition;
    absoluteCondition?:
        | ICreateAbsoluteConditionInput
        | IUpdateAbsoluteConditionInput;
};

export interface ITrainingTrainerListItem extends IUserListItemFragment {
    trainingRole: ITrainingRole;
}

export interface ITrainingParticipantReport {
    userId: string;
    report: Record<string, IPortfolioItemMatrixListItemFragment>;
}

export interface ITrainingFilterValues {
    dateFrom: TDateFilterValue;
    dateTo: TDateFilterValue;
    userGroups: TObjectFilterValue[];
    archived: TStringFilterValue;
}
