import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mdiViewModule, mdiViewList } from '@mdi/js';

import { EFilterType, ITypeFilter } from 'offer/constants/offer';
import { ButtonToggle } from 'common/components/ButtonToggle';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { OffersList } from 'offer/components/OffersList';
import { OfferEventListView } from 'offer/components/OfferEventListView';
import { parseJSON } from 'common/utils/json';

interface IProps {
    typeFilter?: ITypeFilter;
}

enum EView {
    Grid = 'grid',
    List = 'list',
}

export const OfferResults = ({ typeFilter }: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const routeQuery = useRouteQuery();

    const localStorageView = localStorage.getItem('offersOverviewView');
    const currentRouteView = routeQuery.get('view');
    const parsedLocalStorageView =
        !!localStorageView && parseJSON(localStorageView);
    const viewParam =
        parsedLocalStorageView[typeFilter?.key || EFilterType.training] ||
        currentRouteView;
    const isGridView = !viewParam || viewParam === 'grid';

    const navigateToView = (view: EView) => {
        const newSearchParams = new URLSearchParams(window.location.search);

        if (newSearchParams.get('view') === view) return;

        newSearchParams.set('view', view);

        navigate(
            { ...location, search: `?${newSearchParams.toString()}` },
            { replace: true }
        );
    };

    const setLocalStorageView = (view: EView) => {
        const key = typeFilter?.key || EFilterType.training;
        const newLocalStorageView = { ...parsedLocalStorageView, [key]: view };

        localStorage.setItem(
            'offersOverviewView',
            JSON.stringify(newLocalStorageView)
        );

        navigateToView(view);
    };

    useEffect(() => {
        if (!!localStorageView && !currentRouteView) {
            const key = typeFilter?.key || EFilterType.training;

            const currentView = parsedLocalStorageView[key] || EView.Grid;

            navigateToView(currentView);
        }
    }, [localStorageView, currentRouteView]);

    const viewToggle = (
        <ButtonToggle
            buttons={[
                {
                    key: EView.Grid,
                    icon: mdiViewModule,
                },
                {
                    key: EView.List,
                    icon: mdiViewList,
                },
            ]}
            value={
                !viewParam || viewParam === EView.Grid ? EView.Grid : EView.List
            }
            onClickButton={(key) => {
                if (key === EView.Grid) {
                    setLocalStorageView(EView.Grid);

                    return;
                }

                setLocalStorageView(EView.List);
            }}
        />
    );

    return isGridView ? (
        <OffersList typeFilter={typeFilter} viewToggle={viewToggle} />
    ) : (
        <OfferEventListView typeFilter={typeFilter} viewToggle={viewToggle} />
    );
};
