import { Box, BoxProps, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const buttonGroupStyle: SxProps = {
    '> .MuiButtonBase-root': {
        ':not(:first-of-type):not(:last-of-type)': {
            borderRadius: 0,
            '&.MuiFab-extended': {
                px: 2,
            },
        },
        // Remove borders between buttons
        ':not(:first-of-type)': {
            borderLeft: 0,
        },
        ':first-of-type': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,

            '&.MuiFab-circular': {
                pl: 0.5,
            },
            '&.MuiFab-extended': {
                pr: 2,
            },
        },
        ':last-of-type': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,

            '&.MuiFab-circular': {
                pr: 0.5,
            },
            '&.MuiFab-extended': {
                pl: 2,
            },
        },
    },
};

export const ActionButtonGroup = ({ sx, ...other }: BoxProps) => (
    <Box
        {...other}
        sx={[
            buttonGroupStyle,
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
