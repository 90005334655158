import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import {
    ColorPicker,
    ImageField,
    TextField,
} from 'common/components/FormField';
import { PAGE_IMAGE_MAX_SIZE, PAGE_IMAGE_TYPE } from 'common/constants/files';
import { Banner } from 'common/components/Banner';
import { Img } from 'common/components/Img';
import CARD_PREVIEW_IMAGE from 'assets/images/training-overview-image-info.webp';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { TOfferEventFormValues } from 'offer/types';
import { FormSection } from 'common/components/FormSection';
import { Divider } from 'common/components/Divider';
import { ExtraCategoryFormFields } from 'organisation/components/ExtraCategoryFormFields';
import { IContentTypeValue } from 'graphql/types';
import { GroupConditionFilter } from 'user/components/GroupConditionFilter';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { OfferTypeSelect } from 'offer/components/OfferTypeSelect';

interface IProps {
    renderExtraCategoryFields?: boolean;
    renderTypeSelect?: boolean;
    isOfferDetailDrawer?: boolean;
}

export const OfferFormFields = ({
    renderExtraCategoryFields,
    renderTypeSelect,
    isOfferDetailDrawer,
}: IProps) => {
    const { t: translate } = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );
    const isOfferSettingsUpdatePage = !!useHashMatch('OFFER_SETTINGS_EDIT');
    const isNewMeetingPage = !!useRouteMatch('OFFER_MEETING_CREATE');
    const isNewOfferPage = !!useRouteMatch('OFFER_CREATE');
    const isUpdateOfferPage = !!useRouteMatch('OFFER_EDIT');
    const isUpdateOfferDrawerPage = !!useHashMatch('OFFER_DETAIL_DRAWER');
    const { values } = useFormikContext<TOfferEventFormValues>();

    const isOfferDetailDrawerPage =
        isOfferDetailDrawer || isUpdateOfferDrawerPage;
    const isNewMeetingOrOffer = isNewMeetingPage || isNewOfferPage;

    const showColorField =
        (isNewMeetingOrOffer && values.hasDetailPage) ||
        isUpdateOfferPage ||
        isOfferSettingsUpdatePage;

    const overviewImageFields = (
        <Grid
            container
            alignItems="stretch"
            direction={smallScreen ? 'column-reverse' : 'row'}
            spacing={2}
            sx={{ mb: 2 }}
        >
            <Grid item sm xs={12}>
                <Field
                    acceptedFileTypes={PAGE_IMAGE_TYPE}
                    component={ImageField}
                    helperText={translate('imageSizeDescription', {
                        width: 400,
                        height: 150,
                    })}
                    label={translate('offer.overviewImage')}
                    maxUploadSize={PAGE_IMAGE_MAX_SIZE}
                    name="overviewImage"
                />
            </Grid>

            <Grid item sm xs={12}>
                <Banner sx={{ overflow: 'hidden' }}>
                    <Box pl={3} pr={1} py={1}>
                        {translate('offer.overviewImageDescription')}
                    </Box>

                    <Box mt="auto">
                        <Img src={CARD_PREVIEW_IMAGE} />
                    </Box>
                </Banner>
            </Grid>
        </Grid>
    );

    return (
        <Box>
            {!isUpdateOfferPage &&
                (isNewMeetingOrOffer || isOfferDetailDrawerPage) &&
                overviewImageFields}

            <Grid container spacing={2}>
                <Grid item xs>
                    <Field
                        component={TextField}
                        label={translate('offerTitle')}
                        name="title"
                    />
                </Grid>

                {showColorField && (
                    <Grid item>
                        <Field
                            component={ColorPicker}
                            name="titleColor"
                            popoverPosition="bottom-end"
                            tooltip={translate<string>('titleColor')}
                        />
                    </Grid>
                )}
            </Grid>

            {isOfferSettingsUpdatePage && (
                <Field
                    multiline
                    component={TextField}
                    label={translate('description')}
                    name="description"
                />
            )}

            {isNewMeetingOrOffer && (
                <Field
                    multiline
                    component={TextField}
                    label={translate('offer.descriptionBeforeSubscription')}
                    name="descriptionBeforeSubscription"
                />
            )}

            {isUpdateOfferPage && (
                <Box mt={2}>
                    <Divider />

                    <FormSection
                        description={
                            <Box>
                                {translate(
                                    'offer.overviewPageSectionDescription'
                                )}
                            </Box>
                        }
                        title={translate('offer.overviewPage')}
                    >
                        <Box mt={3}>{overviewImageFields}</Box>

                        {renderTypeSelect && (
                            <Box sx={{ mb: 2 }}>
                                <OfferTypeSelect />
                            </Box>
                        )}
                    </FormSection>
                </Box>
            )}

            {renderExtraCategoryFields && (
                <>
                    <FormSection
                        description={
                            <Box>
                                {translate('offer.offerExtraFieldsDescription')}
                            </Box>
                        }
                        title={translate('offer.offerExtraFields')}
                    >
                        <ExtraCategoryFormFields
                            contentType={IContentTypeValue.Offer}
                        />
                    </FormSection>

                    <FormSection
                        renderExpanded
                        description={
                            <Box>
                                {translate('offer.groupFilterDescription')}
                            </Box>
                        }
                        title={translate('offer.groupFilterTitle')}
                    >
                        <Box mt={3}>
                            <GroupConditionFilter
                                groupConditionInputs={values.groupConditions}
                            />
                        </Box>
                    </FormSection>
                </>
            )}
        </Box>
    );
};
