import { useEffect, useState } from 'react';

import {
    IExtraCategoryFilterInput,
    IOfferEventEnrollmentFilter,
    IOfferRole,
    IOverviewPageOfferEventsQueryVariables,
} from 'graphql/types';
import { DEFAULT_PAGINATION_SETTINGS } from 'common/hooks/usePagination';
import { TFilterBarItem } from 'common/types';
import {
    extraCategoryFiltersAreEqual,
    getExtraCategoryInputVariables,
} from 'organisation/utils/extraCategory';
import { OFFER_EVENT_LIST_AMOUNT } from 'training/constants/pagination';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
    selectedFilters: TFilterBarItem[];
    otherFilters?: {
        fromDate?: IOverviewPageOfferEventsQueryVariables['fromDate'];
    };
}

export const useOfferEventOverviewQueryVariables = ({
    paginationVariables,
    searchQueryParam,
    selectedFilters,
    otherFilters = {},
}: IProps) => {
    const [offerQueryVariables, setOfferQueryVariables] = useState<
        IOverviewPageOfferEventsQueryVariables & {
            extraCategories?: IExtraCategoryFilterInput[];
        }
    >({
        q: '',
        ...DEFAULT_PAGINATION_SETTINGS,
        first: OFFER_EVENT_LIST_AMOUNT,
        ...otherFilters,
    });

    useEffect(() => {
        const roleFilters = selectedFilters.filter(
            (filter) => filter.parent === 'role_internal'
        );

        const enrollmentFilters = selectedFilters.filter(
            (filter) => filter.parent === 'enrollments'
        );

        const categoryFilters = selectedFilters.filter(
            (filter) =>
                filter.name !== 'role_internal' && filter.name !== 'enrollments'
        );

        const newOfferQueryVariables = {
            ...paginationVariables,
            q: searchQueryParam,
            extraCategories: getExtraCategoryInputVariables(categoryFilters),
            forRoles: roleFilters.length
                ? (roleFilters.map((filter) => filter.value) as IOfferRole[])
                : undefined,
            fromDate: otherFilters.fromDate,
            enrollmentStatus: enrollmentFilters.length
                ? (enrollmentFilters[0].value as IOfferEventEnrollmentFilter)
                : undefined,
        };

        const {
            q,
            offset,
            first,
            extraCategories,
            forRoles,
            fromDate,
            enrollmentStatus,
        } = offerQueryVariables;

        if (
            q === newOfferQueryVariables.q &&
            offset === newOfferQueryVariables.offset &&
            first === newOfferQueryVariables.first &&
            extraCategoryFiltersAreEqual(
                extraCategories || [],
                newOfferQueryVariables.extraCategories
            ) &&
            JSON.stringify(newOfferQueryVariables.forRoles) ===
                JSON.stringify(forRoles) &&
            fromDate === newOfferQueryVariables.fromDate &&
            newOfferQueryVariables.enrollmentStatus === enrollmentStatus
        ) {
            return;
        }

        setOfferQueryVariables({
            ...offerQueryVariables,
            ...newOfferQueryVariables,
            offset:
                q !== newOfferQueryVariables.q
                    ? 0
                    : newOfferQueryVariables.offset,
        });
    }, [
        offerQueryVariables,
        paginationVariables,
        searchQueryParam,
        selectedFilters,
        otherFilters,
    ]);

    return { offerQueryVariables };
};
