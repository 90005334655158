import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box } from '@mui/material';
import { useState } from 'react';

import { GroupGeneralForm } from 'user/components/GroupForm/GroupGeneralForm';
import { translate } from 'utils/i18n';
import { Tab, Tabs } from 'common/components/Tabs';
import {
    ICreateGroupInput,
    IGroupFragment,
    IUpdateGroupInput,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { Typography } from 'common/components/Typography';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { useFrontendPermissions } from 'user/hooks';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';

import { UsersView } from './UsersView';
import { ManagersView } from './ManagersView';

interface IProps {
    disabled?: boolean;
    group?: IGroupFragment;
    isEdit?: boolean;
    isEditable?: boolean;
    initialValues: ICreateGroupInput | IUpdateGroupInput;
    onDelete?(): void;
    onSubmit(values: ICreateGroupInput | IUpdateGroupInput): void;
    onUserUpdate?(): void;
}

export const GroupForm = ({
    disabled,
    group,
    isEdit,
    isEditable = true,
    onDelete,
    onSubmit,
    onUserUpdate,
    ...other
}: IProps) => {
    const [activeTab, setActiveTab] = useState(isEdit ? 1 : 0);
    const { canUpdate: canUpdateGroup } = useFrontendPermissions('group');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(translate('validate.required')),
    });

    const submitText = translate(
        `groupForm.${isEdit ? 'editButton' : 'createButton'}`
    );

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box pt={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Typography variant="h3">
                        {isEdit
                            ? values.name
                            : translate('groupForm.createGroup')}
                    </Typography>

                    {isEdit && (
                        <Tabs
                            sx={{
                                mb:
                                    (!isEditable || !canUpdateGroup) &&
                                    activeTab === 0
                                        ? 0
                                        : 3,
                            }}
                            value={activeTab}
                        >
                            <Tab
                                label={translate('groupForm.generalTab')}
                                value={0}
                                onClick={() => setActiveTab(0)}
                            />

                            <Tab
                                label={translate('groupForm.participantsTab')}
                                value={1}
                                onClick={() => setActiveTab(1)}
                            />
                            <Tab
                                label={translate('groupForm.groupManagersTab')}
                                value={2}
                                onClick={() => setActiveTab(2)}
                            />
                        </Tabs>
                    )}

                    <Box
                        sx={{
                            mt: isEdit ? 0 : 2,
                            display: activeTab === 0 ? 'block' : 'none',
                        }}
                    >
                        <GroupGeneralForm isEditable={isEditable} />
                    </Box>

                    {isEdit && !!group && (
                        <>
                            <Box
                                sx={{
                                    display: activeTab === 1 ? 'block' : 'none',
                                }}
                            >
                                {group && (
                                    <UsersView
                                        group={group}
                                        onUserUpdate={onUserUpdate}
                                    />
                                )}
                            </Box>

                            <Box
                                sx={{
                                    display: activeTab === 2 ? 'block' : 'none',
                                }}
                            >
                                <ManagersView group={group} />
                            </Box>
                        </>
                    )}

                    {activeTab === 0 && canUpdateGroup && (
                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting && !disabled && !dirty)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    return submitForm();
                                }}
                            >
                                {submitText}
                            </Button>

                            {isEditable && isEdit && canUpdateGroup && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting}
                                        onClick={() => onDelete?.()}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </Formik>
    );
};
