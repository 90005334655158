import { Box, BoxProps } from '@mui/material';

export const ListItemFooter = (props: BoxProps) => (
    <Box
        sx={{
            flexBasis: '100%',
            borderTop: 1,
            borderTopColor: 'divider',
            mt: 2,
            pt: 2,
        }}
        {...props}
    />
);
