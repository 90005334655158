import { useTranslation } from 'react-i18next';
import { Box, Divider, MenuItem, Stack } from '@mui/material';
import { mdiDotsVertical } from '@mdi/js';

import { DropdownMenu } from 'common/components/DropdownMenu';
import { IconButton } from 'common/components/IconButton';

interface IProps {
    isWebinarOrMeeting?: boolean;
    zIndex?: number;
    onClickEnroll?(): void;
    onClickReject?(): void;
    onClickDelete?(): void;
    onClickMail?(): void;
    onClickComplete?(): void;
    onClickAbsent?(): void;
    onSelect?: () => void;
}

export const EnrollmentsTableActions = ({
    isWebinarOrMeeting,
    zIndex,
    onClickEnroll,
    onClickReject,
    onClickDelete,
    onClickMail,
    onClickComplete,
    onClickAbsent,
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <DropdownMenu
            enablePortal
            anchor={
                <IconButton
                    iconPath={mdiDotsVertical}
                    iconSize="2.4rem"
                    sx={{
                        width: '42px',
                        height: '42px',
                    }}
                />
            }
            placement="bottom-end"
            sx={{ zIndex }}
        >
            <Stack divider={<Divider sx={{ my: 1.25 }} />}>
                {(isWebinarOrMeeting || onClickDelete) && (
                    <Box>
                        {isWebinarOrMeeting && (
                            <>
                                {onClickEnroll && (
                                    <MenuItem onClick={onClickEnroll}>
                                        {translate('enrollmentsTable.enroll')}
                                    </MenuItem>
                                )}

                                {onClickReject && (
                                    <MenuItem onClick={onClickReject}>
                                        {translate('enrollmentsTable.reject')}
                                    </MenuItem>
                                )}
                            </>
                        )}

                        {onClickDelete && (
                            <MenuItem onClick={onClickDelete}>
                                {translate('enrollmentsTable.delete')}
                            </MenuItem>
                        )}
                    </Box>
                )}

                {onClickMail && (
                    <Box>
                        <MenuItem onClick={onClickMail}>
                            {translate('enrollmentsTable.sendMail')}
                        </MenuItem>
                    </Box>
                )}

                {isWebinarOrMeeting && (onClickComplete || onClickAbsent) && (
                    <Box>
                        {onClickComplete && (
                            <MenuItem onClick={onClickComplete}>
                                {translate('enrollmentsTable.complete')}
                            </MenuItem>
                        )}

                        {onClickAbsent && (
                            <MenuItem onClick={onClickAbsent}>
                                {translate('enrollmentsTable.absent')}
                            </MenuItem>
                        )}
                    </Box>
                )}
            </Stack>
        </DropdownMenu>
    );
};
