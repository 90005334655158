import {
    setDefaults,
    DeserializeHtml,
    getNodeDeserializer,
} from '@udecode/slate-plugins';

import { IFileDeserializeOptions } from './types';
import { DEFAULTS_FILE } from './FilePlugin';

export const deserializeFile = (
    options?: IFileDeserializeOptions
): DeserializeHtml => {
    const { file } = setDefaults(options, DEFAULTS_FILE);

    return {
        element: getNodeDeserializer({
            type: file.type,
            node: (el) => ({
                type: file.type,
                url: el.getAttribute('data-slate-url'),
            }),
            rules: [{ className: file.rootProps.className }],
            ...options?.file?.deserialize,
        }),
    };
};
