import { i18n } from 'utils';

export function formatPrice(price: number) {
    const isRoundPrice = price % 1 === 0;

    return price.toLocaleString(i18n.language, {
        currency: 'EUR',
        style: 'decimal',
        currencyDisplay: 'code',
        maximumFractionDigits: 2,
        minimumFractionDigits: !isRoundPrice ? 2 : 0,
    });
}
