import { useSlate } from 'slate-react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { ImagePluginOptions } from '@udecode/slate-plugins';

import {
    EDITOR_IMAGE_TYPE,
    EDITOR_IMAGE_MAX_SIZE,
} from 'common/constants/files';

import { FileUpload } from '../file/FileUpload';

import { addOrReplaceImage } from './utils';

type IProps = ImagePluginOptions<'type'> & ImagePluginOptions<'rootProps'>;

export const BlockMenuImage = ({ img }: IProps) => {
    const editor = useSlate();

    const { t: translate } = useTranslation();

    const handleSuccess = (url: string) => {
        addOrReplaceImage(editor, url, { img });
    };

    return (
        <FileUpload
            component={MenuItem}
            id="menu-image-upload"
            inputProps={{ accept: EDITOR_IMAGE_TYPE }}
            maxSize={EDITOR_IMAGE_MAX_SIZE}
            onSuccess={handleSuccess}
        >
            {translate('edit')}
        </FileUpload>
    );
};
