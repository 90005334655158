import {
    IUserExtraCheckboxField,
    IUserExtraChoiceField,
    IUserExtraDateField,
    IUserExtraStringField,
} from 'graphql/types';
import { TExtraUserField } from 'user/types';

import { getExtraFieldsName } from './extraFields';

type TDefaultValue = string | Date | [] | undefined | boolean | null;

export function getExtraFieldInitialEmptyValue(
    field: TExtraUserField
): TDefaultValue {
    let defaultValue: TDefaultValue = '';

    if (field.__typename === 'UserExtraDateField') defaultValue = null;

    if (field.__typename === 'UserExtraChoiceField') {
        if (field.multiple) defaultValue = [];

        if (!field.multiple && field.possibleValues?.length) {
            defaultValue = undefined;
        }
    }

    if (field.__typename === 'UserExtraCheckboxField') defaultValue = false;

    return defaultValue;
}

export function getExtraFieldInitialEmptyValues(fields: TExtraUserField[]): {
    [key: string]: TDefaultValue;
} {
    return fields.reduce(
        (acc, curField) => ({
            ...acc,
            [getExtraFieldsName(curField.name)]:
                getExtraFieldInitialEmptyValue(curField),
        }),
        {}
    );
}

// Returns initial values. It transform the value when it does not match after field type change
export function getExtraFieldChoicesInitialValue(
    field:
        | IUserExtraStringField
        | IUserExtraDateField
        | IUserExtraChoiceField
        | IUserExtraCheckboxField,
    fieldValue?: string | string[] | Date | null | boolean
) {
    if (
        field.__typename === 'UserExtraCheckboxField' ||
        typeof fieldValue === 'boolean'
    ) {
        return !!fieldValue;
    }

    if (
        !fieldValue ||
        fieldValue instanceof Date ||
        field.__typename !== 'UserExtraChoiceField'
    ) {
        return fieldValue;
    }

    if (field.multiple) {
        // If field is multiple select but the user still has a string value from when the field was single select
        if (typeof fieldValue === 'string') {
            if (!field.possibleValues.includes(fieldValue)) return [];

            return [fieldValue];
        }

        // When values are removed check if all selected options are still valid
        // If not remove them from the selected options
        const newValuesList: string[] = [];

        fieldValue.forEach((value) => {
            if (!field.possibleValues.includes(value)) return;

            newValuesList.push(value);
        });

        return newValuesList;
    } else {
        let newValue: string | undefined;

        // If value is nog a string get the first value from the list
        if (typeof fieldValue !== 'string') {
            newValue = fieldValue.length ? fieldValue[0] : undefined;
        } else {
            newValue = fieldValue;
        }

        // Check if the value still exists in possible values
        // Users could have changed to single or multiple and removed values
        if (newValue && !field.possibleValues.includes(newValue)) {
            newValue = undefined;
        }

        return newValue;
    }
}
