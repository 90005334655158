import { Box, Grid } from '@mui/material';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components/Button';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';
import {
    ColorPicker,
    ImageField,
    Switch,
    TextField,
} from 'common/components/FormField';
import { IUpdatePortfolioSettingsInput } from 'graphql/types';
import { Typography } from 'common/components/Typography';

interface IProps extends FormikConfig<IUpdatePortfolioSettingsInput> {
    disabled?: boolean;
    isDeleting?: boolean;
}

export const PortfolioSettingsForm = ({
    disabled,
    isDeleting,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <Formik enableReinitialize {...other}>
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={4}>
                    <Box mb={4}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                            {translate('portfolioSettingsDrawer.title')}
                        </Typography>

                        <Box mt={1}>
                            <Typography>
                                {translate('portfolioSettingsDrawer.text')}
                            </Typography>
                        </Box>
                    </Box>

                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting && !isDeleting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        <Field
                            withDefaults
                            acceptedFileTypes={HEADER_IMAGE_TYPE}
                            component={ImageField}
                            helperText={translate('imageSizeDescription', {
                                width: 1440,
                                height: 300,
                            })}
                            label={translate('headerImage')}
                            maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                            name="image"
                        />

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />
                                </Grid>

                                <Grid item>
                                    <Field
                                        component={ColorPicker}
                                        name="titleColor"
                                        popoverPosition="bottom-end"
                                        tooltip={translate<string>(
                                            'titleColor'
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Field
                                multiline
                                component={TextField}
                                label={translate('description')}
                                name="description"
                            />
                        </Box>

                        <Field
                            checked={values.published}
                            component={Switch}
                            label={translate('published')}
                            name="published"
                        />

                        <Box mt={2}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting && !disabled && !dirty)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
