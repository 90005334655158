import { useParams } from 'react-router-dom';

import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { IPortfolioGroup } from 'user/types';

export const usePortfolioRouting = (
    groups: IPortfolioGroup[],
    portfolioLoading: boolean
) => {
    const {
        id: userIdParam,
        itemId: itemIdParam,
        groupId: groupIdParam,
    } = useParams();

    const customPorfolioItemCreate = !!useRouteMatch('PORTFOLIO_ITEM_CREATE');
    const customPortfolioItemEdit = !!useRouteMatch('PORTFOLIO_ITEM_EDIT');
    const userPortfolioItemView = !!useRouteMatch('USER_PORTFOLIO_ITEM_VIEW');
    const portfolioItemViewProfile = !!useRouteMatch('PORTFOLIO_ITEM_VIEW');

    const portfolioGroupCreate = !!useRouteMatch('PORTFOLIO_GROUP_CREATE');
    const portfolioGroupEdit = !!useRouteMatch('PORTFOLIO_GROUP_EDIT');

    let selectedGroup: IPortfolioGroup | undefined;

    if (portfolioGroupEdit && !portfolioLoading && groups.length) {
        const groupResult = groups.filter((group) => group.id === groupIdParam);

        if (groupResult.length) selectedGroup = groupResult[0];
    }

    const groupDrawerOpen = portfolioGroupCreate || !!selectedGroup;

    const portfolioItemDrawerOpen =
        customPorfolioItemCreate ||
        customPortfolioItemEdit ||
        userPortfolioItemView ||
        portfolioItemViewProfile;

    return {
        selectedGroup,
        portfolioItemDrawerOpen,
        groupDrawerOpen,
        userIdParam,
        itemIdParam: itemIdParam !== 'new' ? itemIdParam : undefined,
    };
};
