import { Field, useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { ROLE_TRANSLATIONS } from 'user/constants/roles';
import { IRole } from 'graphql/types';
import { TFrontendRole } from 'user/types';
import { useCanManageUser } from 'user/hooks';
import { Link } from 'common/components/Link';
import { Switch } from 'common/components/FormField';
import { FormSection } from 'common/components/FormSection';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

interface IProps {
    loading?: boolean;
    roleRemoveDialogOpen?: boolean;
    userId?: string;
    setRoleRemoveDialogOpen?(open: boolean): void;
    onRoleRemoved?(roleRemoved: boolean): void;
    onConfirm?(): void;
}

export const UserRolesFormSection = ({
    loading,
    roleRemoveDialogOpen,
    userId,
    setRoleRemoveDialogOpen,
    onRoleRemoved,
    onConfirm,
}: IProps) => {
    const { initialValues, values, setFieldValue } = useFormikContext<{
        roles: IRole[];
    }>();

    const [translate] = useTranslation();

    const {
        isCurrentUser,
        userRoles,
        isGroupManager,
        loading: loadingCanManageUser,
    } = useCanManageUser(userId);

    // Loading state
    if (loadingCanManageUser) return null;

    const handleChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
        checked: boolean
    ) => {
        const value = e.target.value as IRole;

        let rolesValue = values.roles || [];
        rolesValue = checked
            ? [...rolesValue, value]
            : rolesValue.filter((role) => role !== value);

        const trainerRoleRemoved =
            initialValues.roles.includes(IRole.Trainer) &&
            !rolesValue.includes(IRole.Trainer);
        const authorRoleRemoved =
            initialValues.roles.includes(IRole.Author) &&
            !rolesValue.includes(IRole.Author);

        // When author or trainer role is removed we need to show a confirmation dialog
        // The trainerAuthorRoleRemoved property is used so the form knows when to show the dialog
        if (trainerRoleRemoved || authorRoleRemoved) {
            onRoleRemoved?.(true);
        }

        if (!trainerRoleRemoved && !authorRoleRemoved) {
            onRoleRemoved?.(false);
        }

        setFieldValue('roles', rolesValue);
    };

    const fields = [
        IRole.Owner,
        IRole.Manager,
        IRole.Author,
        IRole.Trainer,
    ].map((role) => {
        let canApplyRole =
            // Owner can always apply all roles
            userRoles?.includes(TFrontendRole.Owner) ||
            // Manager can apply all roles except owner
            (userRoles?.includes(TFrontendRole.Manager) &&
                role !== IRole.Owner) ||
            // Groupmanagers of the user can apply Author and Trainer
            (isGroupManager &&
                (role === IRole.Author || role === IRole.Trainer));

        // If own profile you can never change the owner role (since there is no role above owner)
        // You can never change the manager role if you have no owner role
        if (
            isCurrentUser &&
            (role === IRole.Owner ||
                (!userRoles?.includes(TFrontendRole.Owner) &&
                    role === IRole.Manager))
        ) {
            canApplyRole = false;
        }

        return (
            <Field
                checked={(values.roles || []).includes(role)}
                component={Switch}
                disabled={!canApplyRole}
                formControlProps={{
                    margin: 'none',
                }}
                key={role}
                label={ROLE_TRANSLATIONS[role]}
                name="roles"
                value={role}
                onChange={!!onRoleRemoved ? handleChange : undefined}
            />
        );
    });

    return (
        <FormSection
            description={
                <Trans
                    components={{
                        a: <Link rel="noreferrer" underline="always" />,
                    }}
                    i18nKey="userForm.rolesDescription"
                />
            }
            title={translate('userForm.rolesTitle')}
        >
            {fields}

            {!!onRoleRemoved && (
                <ConfirmDialog
                    confirmText={translate('edit')}
                    loading={loading}
                    open={!!roleRemoveDialogOpen}
                    title={translate('userForm.roleChangeDialog.title')}
                    onCancel={() => setRoleRemoveDialogOpen?.(false)}
                    onClose={() => setRoleRemoveDialogOpen?.(false)}
                    onConfirm={() => {
                        setRoleRemoveDialogOpen?.(false);

                        onConfirm?.();
                    }}
                >
                    {translate('userForm.roleChangeDialog.text')}
                </ConfirmDialog>
            )}
        </FormSection>
    );
};
