import { Node as SlateNode } from 'slate';

import { IContentJSON } from 'common/components/ContentEditor/types';

import { convertJSON } from './convertJSON';

export const deserializeContent = (
    content?: string
): SlateNode[] | undefined => {
    let nodes = content ? JSON.parse(content) : [];

    // If we get an array we don't need to convert the data anymore.
    if (Array.isArray(nodes)) {
        if (!nodes.length) return;

        return nodes;
    }

    // If we get an empty object we want to just return null
    if (Object.keys(nodes).length === 0) {
        return;
    }

    nodes = convertJSON((nodes as IContentJSON).document);

    return !Array.isArray(nodes) ? [nodes] : nodes;
};
