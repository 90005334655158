import { Box } from '@mui/material';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useContext } from 'react';

import { Button } from 'common/components/Button';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { OfferFormFields } from 'offer/components/forms/formFields/OfferFormFields';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';
import { ImageField } from 'common/components/FormField';
import { IFileFragment, IOfferDetailFragment } from 'graphql/types';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { Typography } from 'common/components/Typography';
import { TUpdateOfferFormInput } from 'offer/types';
import { OfferPermissionsContext } from 'offer/contexts';

interface IProps extends FormikConfig<TUpdateOfferFormInput> {
    disabled?: boolean;
    imageUploaded?: boolean;
    isDeleting?: boolean;
    offer: IOfferDetailFragment;
    onChange?(name: string, file: IFileFragment): void;
    onDelete?(): void;
}

export const UpdateOfferForm = ({
    disabled,
    imageUploaded,
    isDeleting,
    offer,
    onChange,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { canDelete = false } = useContext(OfferPermissionsContext) || {};

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        description: Yup.string().optional(),
    });

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty }) => (
                <Box p={4}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={
                            (dirty || imageUploaded) &&
                            !isSubmitting &&
                            !isDeleting
                        }
                        onConfirm={submitForm}
                    />

                    <Form>
                        <Box mb={4}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h3"
                            >
                                {translate('offer.updateOfferTitle')}
                            </Typography>

                            <Box mt={2}>
                                <Typography>
                                    {translate('offer.updateOfferDescription')}
                                </Typography>
                            </Box>
                        </Box>

                        <Box mb={1}>
                            <Field
                                withDefaults
                                acceptedFileTypes={HEADER_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 1440,
                                    height: 300,
                                })}
                                label={translate('headerImage')}
                                maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                                name="image"
                            />
                        </Box>

                        <OfferFormFields
                            renderExtraCategoryFields
                            renderTypeSelect={!!offer.type}
                        />

                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting &&
                                        !disabled &&
                                        !dirty &&
                                        !imageUploaded)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>

                            {canDelete && !!onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
