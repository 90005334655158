import { Grid } from '@mui/material';

import {
    IOrganisationPlanInfoFragment,
    IPlanInterval,
    IPlanPackageFragment,
} from 'graphql/types';
import { PLAN_PACKAGES } from 'plans/constants/plans';

import { PlanPackageCard } from '../PlanPackageCard';

interface IProps {
    organisation: IOrganisationPlanInfoFragment;
    planInterval: IPlanInterval;
    planPackages: IPlanPackageFragment[];
}

export const PlanPackageList = ({
    organisation,
    planInterval,
    planPackages,
}: IProps) => {
    const sortedPlanPackages = [...planPackages].sort(
        (current, next) =>
            PLAN_PACKAGES[current.frontendName].order -
            PLAN_PACKAGES[next.frontendName].order
    );

    return (
        <Grid container spacing={3}>
            {sortedPlanPackages?.map((planPackage) => {
                if (
                    !planPackage.plans.length ||
                    !PLAN_PACKAGES[planPackage.frontendName]
                ) {
                    return null;
                }

                return (
                    <Grid
                        item
                        key={planPackage.frontendName}
                        md={4}
                        sm={6}
                        xs={12}
                    >
                        <PlanPackageCard
                            organisation={organisation}
                            planInterval={planInterval}
                            {...planPackage}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
