import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useFragment } from '@apollo/client';

import { PageTitle } from 'common/components/PageTitle';
import { PageDrawer } from 'common/components/PageDrawer';
import { TrainingCreateModuleSelect } from 'training/components/TrainingCreateModuleSelect';
import { Typography } from 'common/components/Typography';
import TRAINING_DRAWER_HEADER from 'assets/images/training-drawer-header.webp';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import { TRAINING_MODULE_TYPES } from 'training/constants/modules';
import { getUrl } from 'route/utils/getUrl';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { Loader } from 'common/components/Loader';
import { useFeature } from 'common/hooks/useFeature';
import { DrawerUpgradeSection } from 'common/components/DrawerUpgradeSection';
import { IModuleEditFragment, ModuleGroupFragmentDoc } from 'graphql/types';

interface IProps {
    open?: boolean;
    onClose?(): void;
    trainingId: string;
    trainingModelId?: string;
}

export const ModuleCreateDrawer = ({
    open,
    onClose,
    trainingId,
    trainingModelId,
}: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const { moduleGroupId, type: typeParam } = useParams() as {
        moduleGroupId?: string;
        type: string;
    };
    const { data: moduleGroup } = useFragment({
        fragment: ModuleGroupFragmentDoc,
        from: `ModuleGroup:${moduleGroupId}`,
        fragmentName: 'ModuleGroup',
    });

    const isCreateModuleTypePage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_TYPE'
    );
    const isCreateModuleTypeInGroupPage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_TYPE_IN_GROUP'
    );

    const moduleType = useMemo(
        () =>
            // When type is set in params try to find the module for it and use that one as initial
            TRAINING_MODULE_TYPES.find((type) => type.key === typeParam),
        [typeParam]
    );

    const moduleDrawerOpen =
        isCreateModuleTypePage || isCreateModuleTypeInGroupPage;

    const {
        canUse,
        userCanSeeUpgrade,
        loading: loadingCanUse,
    } = useFeature(moduleType?.feature);

    const showUpgrade = !canUse && userCanSeeUpgrade;

    // Get the create drawer component for the current moduleType
    const DrawerComponent = moduleType
        ? moduleType.createDrawerComponent
        : null;

    const webhookModules =
        moduleGroup.modules?.filter(
            (module: IModuleEditFragment) =>
                module.__typename === 'WebhookModule'
        ) || [];
    const afasWebhookModules =
        moduleGroup.modules?.filter(
            (module: IModuleEditFragment) =>
                module.__typename === 'AfasWebhookModule'
        ) || [];

    return (
        <>
            <PageDrawer open={open} onClose={onClose}>
                <PageTitle
                    mixpanelTitle={
                        moduleGroupId
                            ? 'Drawer modules'
                            : 'Drawer modules for new section'
                    }
                >
                    {translate('newModule')}
                </PageTitle>

                <Box>
                    <DrawerHeaderImage src={TRAINING_DRAWER_HEADER} />

                    <Box p={{ xs: 2, sm: 4 }}>
                        <Box mb={4}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h3"
                            >
                                {translate('trainingModule.newIntroTitle')}
                            </Typography>

                            <Box mt={1}>
                                <Typography>
                                    {translate('trainingModule.newIntroText')}
                                </Typography>
                            </Box>
                        </Box>
                        <TrainingCreateModuleSelect
                            moduleGroupId={moduleGroupId}
                            showCount={6}
                            trainingModelId={trainingModelId}
                            onClick={(moduleType) => {
                                if (!moduleType.createDrawerComponent) {
                                    return;
                                }

                                const isAfasWebhook =
                                    moduleType.key === 'afasTrigger';
                                const isWebhook = moduleType.key === 'webhook';

                                // If there is already a module of this type, navigate to the update page
                                if (
                                    (isAfasWebhook &&
                                        !!afasWebhookModules.length) ||
                                    (isWebhook && !!webhookModules.length)
                                ) {
                                    const url = getUrl(
                                        'TRAINING_UPDATE_MODULE',
                                        {
                                            trainingId,
                                            moduleId: isAfasWebhook
                                                ? afasWebhookModules[0].id
                                                : webhookModules[0].id,
                                            type: moduleType.key,
                                        }
                                    );

                                    navigate(url);

                                    return;
                                }

                                const routeName = moduleGroupId
                                    ? 'TRAINING_CREATE_MODULE_TYPE_IN_GROUP'
                                    : 'TRAINING_CREATE_MODULE_TYPE';

                                const url = getUrl(routeName, {
                                    trainingId,
                                    type: moduleType.key,
                                    moduleGroupId,
                                });

                                navigate(url);
                            }}
                        />
                    </Box>
                </Box>
                <PageDrawer
                    open={moduleDrawerOpen}
                    onClose={() => {
                        const routeName = moduleGroupId
                            ? 'TRAINING_CREATE_MODULE_IN_GROUP'
                            : 'TRAINING_CREATE_MODULE';

                        const url = getUrl(routeName, {
                            trainingId,
                            moduleGroupId,
                        });

                        navigate(url);
                    }}
                >
                    {loadingCanUse && <Loader />}
                    {open && DrawerComponent && canUse && (
                        <DrawerComponent
                            moduleGroupId={moduleGroupId}
                            trainingId={trainingId}
                            onClose={onClose}
                        />
                    )}
                    {showUpgrade && (
                        <>
                            {moduleType?.upgradeHeaderImage && (
                                <DrawerHeaderImage
                                    src={moduleType.upgradeHeaderImage}
                                />
                            )}
                            <Box p={{ xs: 2, sm: 4 }}>
                                <DrawerUpgradeSection
                                    description={moduleType?.upgradeDescription}
                                    title={moduleType?.upgradeTitle}
                                />
                            </Box>
                        </>
                    )}
                </PageDrawer>
            </PageDrawer>
        </>
    );
};
