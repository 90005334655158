import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LibraryItemsList } from 'library/components/LibraryItemsList';
import { useLibraryLazyQuery } from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { BoxLoader } from 'common/components/Loader';
import { SearchBar } from 'common/components/SearchBar';
import { PageDrawer } from 'common/components/PageDrawer';
import { NoResults } from 'common/components/NoResults';
import { Typography } from 'common/components/Typography';

interface IProps {
    open?: boolean;
    searchQuery?: string;
    onClose?(): void;
    onSearch?(value: string): void;
}

export const LibrarySearchDrawer = ({
    open,
    searchQuery,
    onSearch,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const [searchLibrary, { loading, error, data: searchResults }] =
        useLibraryLazyQuery({ fetchPolicy: 'network-only' });

    useEffect(() => {
        if (searchQuery) {
            searchLibrary({ variables: { query: searchQuery } });
        }
    }, [searchQuery, searchLibrary]);

    const handleSearch = (value: string) => {
        onSearch && onSearch(value);
    };

    const { articles = [], collections = [] } = searchResults?.library || {};

    return (
        <PageDrawer
            open={open}
            title={translate('searchResults')}
            onClose={onClose}
        >
            <Box p={4}>
                <Box mb={4}>
                    <SearchBar
                        initialValue={searchQuery || ''}
                        placeholder={translate('filterBarPlaceholder')}
                        onClickSearch={handleSearch}
                        onEnterPress={handleSearch}
                    />
                </Box>

                {loading && <BoxLoader />}

                {searchQuery && !!collections.length && (
                    <Box mb={4}>
                        <Box mb={3}>
                            <Typography variant="h2">
                                {translate('libraryCollectionsTitle')}
                            </Typography>
                        </Box>
                        <LibraryItemsList items={collections} />
                    </Box>
                )}

                {searchQuery && !!articles.length && (
                    <Box>
                        <Box mb={3}>
                            <Typography variant="h2">
                                {translate('libraryArticlesTitle')}
                            </Typography>
                        </Box>
                        <LibraryItemsList items={articles} />
                    </Box>
                )}

                {!searchQuery && (
                    <Box>
                        <NoResults variant="LIBRARY_EMPTY_SEARCH" />
                    </Box>
                )}

                {!loading &&
                    searchQuery &&
                    !collections.length &&
                    !articles.length && <NoResults variant="LIBRARY_SEARCH" />}

                {error && <ApolloError error={error} />}
            </Box>
        </PageDrawer>
    );
};
