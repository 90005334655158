import { useState, useEffect } from 'react';

import { ILoadFont } from 'common/types';
import { loadFonts } from 'common/utils/loadFonts';

/**
 * Wrapper to load fonts with state if fonts are loaded
 *
 * @param fonts fonts to load
 * @returns { fontsLoaded: boolean }
 */
export const useLoadFonts = (fonts: ILoadFont[]) => {
    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        const load = async () => {
            await loadFonts(fonts);

            setFontsLoaded(true);
        };

        load();
    }, [fonts]);

    return { fontsLoaded };
};
