import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
    IModuleType,
    useDeleteModuleMutation,
    useUpdateExternalContentModuleMutation,
    useModuleEditQuery,
} from 'graphql/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { useApolloError } from 'common/hooks/useApolloError';
import { getUrl } from 'route/utils/getUrl';
import { PageTitle } from 'common/components/PageTitle';
import {
    ExternalContentModuleForm,
    IExternalContentModuleFormProps,
} from 'training/components/forms/ExternalContentModuleForm';
import { IModuleUpdateDrawerProps } from 'training/types';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { Loader } from 'common/components/Loader';
import { getModuleOffsetFormValue } from 'training/utils/moduleOffset';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { getImageId } from 'common/utils/image';

import { ExternalContentModuleSelectDrawer } from './ExternalContentModuleSelectDrawer';

export const ExternalContentModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const navigate = useNavigate();

    const routeQuery = useRouteQuery();
    const externalContentIdParam =
        routeQuery.get('externalContent') || undefined;
    const [selectedExternalContentId, setSelectedExternalContentId] = useState(
        externalContentIdParam
    );

    const externalContentSelectDrawerOpen = useHashMatch(
        'TRAINING_MODULE_SELECT'
    );

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.ExternalContent },
        onError: showApolloError,
    });

    const [updateModule] = useUpdateExternalContentModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    const module = data?.module;

    if (loading || !module) return <Loader />;

    if (!module || module.__typename !== 'ExternalContentModule') return null;

    const handleSubmit: IExternalContentModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { image, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            imageId: getImageId(module.image, image),
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        try {
            await updateModule({
                variables: { id, module: moduleValues },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Update external content module in drawer',
        });

        displaySnackbar(
            translate('moduleActionSuccess.externalContent.update'),
            {
                variant: 'success',
            }
        );

        // Close drawer
        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.ExternalContent,
                },
                update: (cache) => {
                    cache.evict({ id: `ExternalContentModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Delete external content module in drawer',
        });

        displaySnackbar(
            translate('moduleActionSuccess.externalContent.delete'),
            {
                variant: 'success',
            }
        );

        // Close drawer
        onClose?.();

        return;
    };

    const {
        title,
        description,
        content,
        image,
        weight,
        openOffset,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        title,
        description,
        contentId: content?.id,
        image: image || undefined,
        weight,
        offset: getModuleOffsetFormValue(openOffset),
    };

    return (
        <>
            <PageTitle mixpanelTitle="'Drawer update external content module">
                {translate('updateExternalContentModule')}
            </PageTitle>

            <ExternalContentModuleSelectDrawer
                edit
                open={externalContentSelectDrawerOpen}
                onClose={() => {
                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
                onSelect={(externalContent) => {
                    // Redirect to the pathname to go to the page without hashtag.
                    setSelectedExternalContentId(externalContent.id);
                    navigate(window.location.pathname);
                }}
            />

            <ExternalContentModuleForm
                edit
                externalContentId={selectedExternalContentId}
                initialValues={initialValues}
                moduleGroupId={moduleGroupId}
                onDelete={() => setShowDeleteAlert(true)}
                onReplaceExternalContent={() => {
                    navigate(getUrl('TRAINING_MODULE_SELECT'));
                }}
                onSubmit={handleSubmit}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteExternalContentModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteExternalContentModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
