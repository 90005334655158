import { IVideoListItemFragment } from 'graphql/types';

export function isVideoSelected(
    selectedVideos: IVideoListItemFragment[],
    video: IVideoListItemFragment
) {
    return selectedVideos.some(
        (selectedVideo) => selectedVideo.id === video.id
    );
}

export function isVideoAdded(
    addedVideos: IVideoListItemFragment[],
    video: IVideoListItemFragment
) {
    return addedVideos.some((addedVideo) => addedVideo.id === video.id);
}

export function getVideoSelectStatus(
    selectedVideos: IVideoListItemFragment[],
    addedVideos: IVideoListItemFragment[],
    video: IVideoListItemFragment
) {
    const isSelected = isVideoSelected(selectedVideos, video);
    const isAdded = isVideoAdded(addedVideos, video);

    return { isSelected, isAdded };
}
