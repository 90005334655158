import { useApolloClient } from '@apollo/client';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IOfferEventDetailFragment,
    IOfferEventSubscriptionStatus,
    useCreateOfferEventEnrollmentsMutation,
    useDeleteOfferEventEnrollmentsMutation,
    useUpdateOfferEventEnrollmentsMutation,
    useCreateCertificateForEnrollmentsMutation,
    IContentTypeValue,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useOfferEventManageableEnrollmentList } from 'offer/hooks/useOfferEventManageableEnrollmentList';
import { SubscriptionTable } from 'offer/tables/SubscriptionsTable';
import { TableAddButton } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { UserSelectDrawer } from 'user/components/UserSelector/UserSelectDrawer';

interface IProps {
    drawerRef?: RefObject<HTMLDivElement>;
    offerEvent: IOfferEventDetailFragment;
}

export const OfferEventMangeableEnrollmentList = ({
    drawerRef,
    offerEvent,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const client = useApolloClient();
    const prevWindowTop = useRef(drawerRef?.current?.scrollTop || 0);

    const {
        enrollments,
        loading: subscriptionsLoading,
        handleSearch,
        handleFilterSelect,
        searchQueryParam,
        selectionProps,
        filters,
        filtersLoading,
        selectedFilters,
        paginationSettings,
        setPage,
    } = useOfferEventManageableEnrollmentList(offerEvent.id);

    const [updateEnrollment] = useUpdateOfferEventEnrollmentsMutation();
    const [deleteEnrollments, { loading: deleteEnrollmentsLoading }] =
        useDeleteOfferEventEnrollmentsMutation();
    const [createEnrollments, { loading: createEnrollmentsLoading }] =
        useCreateOfferEventEnrollmentsMutation();
    const [createCertificates, { loading: createCertificatesLoading }] =
        useCreateCertificateForEnrollmentsMutation();

    const loading =
        subscriptionsLoading ||
        createEnrollmentsLoading ||
        deleteEnrollmentsLoading ||
        createCertificatesLoading;

    useEffect(() => {
        if (subscriptionsLoading) return;

        // Makes sure the drawer is scrolled to the position it was before
        // after doing a mutation. The mutations will cause the table to refresh
        // and scroll the drawer to the top.
        if (drawerRef?.current && prevWindowTop.current) {
            drawerRef.current.scrollTo(0, prevWindowTop.current);

            prevWindowTop.current = 0;
        }
    }, [subscriptionsLoading]);

    const evictEnrollmentsFromCache = (skipForEvent?: boolean) => {
        client.cache.evict({
            fieldName: 'manageableOfferEventEnrollments',
        });

        if (!skipForEvent) {
            client.cache.evict({
                fieldName: 'manageableOfferEventEnrollmentsForEvent',
            });
        }

        client.cache.evict({
            fieldName: 'paginatedOfferEvents',
        });

        client.cache.gc();
    };

    const handleCreateEnrollments = async (users: string[]) => {
        // Save scroll position before doing the mutation
        prevWindowTop.current = drawerRef?.current?.scrollTop || 0;

        try {
            await createEnrollments({
                variables: { offerEventId: offerEvent.id, users },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        evictEnrollmentsFromCache();

        // Refetch the selector to make sure the correct users are selected
        client.cache.evict({ fieldName: 'paginatedUsers' });
        client.cache.gc();

        setDrawerOpen(false);

        return;
    };

    const handleUpdateSubscription = async (
        enrollmentIds: string[],
        status: IOfferEventSubscriptionStatus,
        reason: string,
        generateCertificate: boolean
    ) => {
        try {
            await updateEnrollment({
                variables: {
                    offerEventEnrollmentIds: enrollmentIds,
                    status,
                    reason,
                },
            });

            if (generateCertificate) {
                await createCertificates({
                    variables: {
                        offerEventEnrollmentIds: enrollmentIds,
                    },
                });
            }
        } catch (error) {
            showApolloError(error);

            return;
        }

        evictEnrollmentsFromCache(true);

        return;
    };

    const handleDeleteEnrollments = async (enrollmentIds: string[]) => {
        // Save scroll position before doing the mutation
        prevWindowTop.current = drawerRef?.current?.scrollTop || 0;

        try {
            await deleteEnrollments({
                variables: {
                    offerEventEnrollmentIds: enrollmentIds,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        evictEnrollmentsFromCache();

        // Refetch the selector to make sure the correct users are selected
        client.cache.evict({ fieldName: 'paginatedUsers' });
        client.cache.gc();

        return;
    };

    const handleDrawerOpen = (open: boolean) => {
        setDrawerOpen(open);
    };

    const addedUsers = enrollments
        .map((enrollment) => enrollment?.user)
        .filter(Boolean);

    return (
        <>
            <TableAddButton onClick={() => handleDrawerOpen(true)}>
                <Typography fontWeight={700}>
                    {translate('subscriptions')}
                </Typography>
            </TableAddButton>

            <SubscriptionTable
                isEditable
                loading={loading}
                offerEvent={offerEvent}
                paginationProps={{ paginationSettings, setPage }}
                searchProps={{
                    handleSearch: handleSearch,
                    handleFilterSelect: handleFilterSelect,
                    searchQueryParam,
                    filters,
                    filtersLoading,
                    selectedFilters,
                }}
                selectionProps={selectionProps}
                subscriptions={enrollments}
                onDelete={handleDeleteEnrollments}
                onStatusChange={handleUpdateSubscription}
            />

            <UserSelectDrawer
                addedUsers={addedUsers}
                loading={loading}
                open={drawerOpen}
                selectorQueryVariables={{
                    contentType: IContentTypeValue.OfferEvent,
                    objectId: offerEvent.id,
                }}
                onAddUsers={handleCreateEnrollments}
                onDrawerOpen={handleDrawerOpen}
            />
        </>
    );
};
