import { CircularProgress } from '@mui/material';
import { styled } from 'styled-components';

import { COLORS } from 'theme/palette';

export const Loader = styled(CircularProgress)`
    color: ${({ theme }) => theme.palette?.secondary.main || COLORS.darkGrey};
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
`;
