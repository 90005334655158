import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import { IAppPermissionsSkill, useCreateSkillMutation } from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { SkillForm } from 'skill/components/SkillForm';
import { TSkillFormValues } from 'skill/types';
import { useAppPermissions } from 'user/hooks/usePermissions';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const SkillCreateDrawer = ({ open, onClose }: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [createSkill, { loading: createSkillLoading }] =
        useCreateSkillMutation();
    const { canCreate = false }: IAppPermissionsSkill['management'] =
        useAppPermissions('skill', 'management');

    if (!canCreate) return <FourOFourPage />;

    const handleClose = () => {
        if (!!onClose) return onClose();

        const searchParams = new URLSearchParams(location.search);
        const url = `${getUrl('SKILL_MANAGEMENT_LIST')}?${searchParams}`;

        navigate(url);
    };

    const isSkillManagementPage = !!useRouteMatch('SKILL_CREATE');

    const handleSubmit = async (values: TSkillFormValues) => {
        const { groups, certificates, thumbnail, ...otherValues } = values;

        const groupIds = groups?.map((group) => group.id);
        const certificateIds = certificates?.map(
            (certificate) => certificate.id
        );

        try {
            await createSkill({
                variables: {
                    skill: {
                        groupIds,
                        certificateIds,
                        thumbnailId: thumbnail?.id || undefined,
                        ...otherValues,
                    },
                },
                update: (cache) => {
                    cache.evict({ fieldName: 'skills' });
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        handleClose();

        displaySnackbar(
            translate('skillsManagementPage.actionSuccess.create'),
            {
                variant: 'success',
            }
        );

        return;
    };

    const initialValues: TSkillFormValues = {
        name: '',
        shortName: '',
        description: '',
    };

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <PageTitle>
                {`${isSkillManagementPage ? translate('skillsManagementPage.pageTitle') : ''} - ${translate(
                    'skillForm.createSkill'
                )}`}
            </PageTitle>

            <Box sx={{ p: { xs: 2, sm: 4 }, pt: { xs: 0, sm: 0 } }}>
                <SkillForm
                    disabled={createSkillLoading}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                />
            </Box>
        </PageDrawer>
    );
};
