import { mdiLightbulbOutline } from '@mdi/js';
import { useLocation, useNavigate } from 'react-router-dom';

import { ISkillListItemFragment } from 'graphql/types';
import { TableCell, TableRowMedia } from 'common/components/Table';
import { Icon } from 'common/components/Icon';
import { ItemMedia } from 'common/components/ItemMedia';
import { Typography } from 'common/components/Typography';
import { getUrl } from 'route/utils/getUrl';
import { Checkbox } from 'common/components/Checkbox';

interface IProps {
    isSelected: boolean;
    skill: ISkillListItemFragment;
    onSelect?: () => void;
}

export const SkillsManagementTableRow = ({
    isSelected,
    skill,
    onSelect,
}: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id, name, shortName, thumbnail } = skill;

    return (
        <TableRowMedia
            hover
            key={id}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
                const searchParams = new URLSearchParams(location.search);
                const url = `${getUrl('SKILL_EDIT', {
                    itemId: id,
                    skillId: id,
                })}?${searchParams}`;

                navigate(url);
            }}
        >
            <TableCell>
                <Checkbox
                    checked={isSelected}
                    onClick={(e) => {
                        onSelect?.();

                        e.stopPropagation();
                    }}
                />
            </TableCell>

            <TableCell sx={{ width: '1px' }}>
                <ItemMedia
                    color="primary"
                    image={thumbnail?.url || ''}
                    size="small"
                >
                    <Icon
                        path={mdiLightbulbOutline}
                        size="2rem"
                        sizeSm="3rem"
                    />
                </ItemMedia>
            </TableCell>
            <TableCell>
                {name}{' '}
                <Typography color="text.secondary" variant="body2">
                    {shortName}
                </Typography>
            </TableCell>
        </TableRowMedia>
    );
};
