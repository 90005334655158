import { Navigate } from 'react-router-dom';

import { useHashMatch } from 'route/hooks/useHashMatch';
import { SingleSignOnDrawer } from 'connections/components/SingleSignOnDrawer';
import { useFrontendPermissions } from 'user/hooks';
import { getUrl } from 'route/utils/getUrl';

import { OrganisationSettingsDrawer } from '../OrganisationSettingsDrawer';
import { ExtraCategorySettingsDrawer } from '../ExtraCategorySettingsDrawer';

export const ManagementSettingsDrawers = () => {
    const { canUpdate } = useFrontendPermissions('organisationSettings');

    const organisationSettingsDrawerOpen = useHashMatch(
        'ORGANISATION_SETTINGS'
    );
    const sSOLTIDrawerOpen = useHashMatch('ORGANISATION_SINGLE_SIGN_ON');
    const extraCategoryDrawerOpen = useHashMatch(
        'ORGANISATION_EXTRA_CATEGORIES'
    );

    if (
        !canUpdate &&
        (organisationSettingsDrawerOpen ||
            sSOLTIDrawerOpen ||
            extraCategoryDrawerOpen)
    ) {
        return <Navigate replace to={getUrl('PAGE_NOT_FOUND')} />;
    }

    if (!canUpdate) return null;

    return (
        <>
            <OrganisationSettingsDrawer open={organisationSettingsDrawerOpen} />

            <SingleSignOnDrawer open={sSOLTIDrawerOpen} />

            <ExtraCategorySettingsDrawer open={extraCategoryDrawerOpen} />
        </>
    );
};
