import { Field, FieldAttributes, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { MultiSelect } from 'common/components/FormField';
import {
    useInputOptionGroupsQuery,
    IGroupInputOptionFragment,
    IExtraCategoryValueFragment,
    IExtraCategoryOption,
    IExtraCategoryStringValue,
    IExtraCategoryChoiceValue,
} from 'graphql/types';
import { Typography } from 'common/components/Typography';

interface IProps extends FieldProps {
    label?: string;
    onChange?: (name: string, value: string[]) => void;
}

export const GroupSelectField = ({
    label,
    onChange,
    ...other
}: IProps & Partial<FieldAttributes<unknown>>) => {
    const [translate] = useTranslation();

    const { loading, data: groupsData } = useInputOptionGroupsQuery();

    const options = (groupsData?.groups || []).map((group) => ({
        id: group.id,
        name: group.name,
        extraCategoryValues: group.extraCategoryValues || [],
    }));

    const {
        form: { setFieldValue },
    } = other;

    const handleChange = (name: string, value: string[]) => {
        onChange?.(name, value);
        setFieldValue(name, value);
    };

    return (
        <Field
            component={MultiSelect}
            getOptionDisplay={(option: IGroupInputOptionFragment) => {
                const extraCategoryString = (
                    option.extraCategoryValues as IExtraCategoryValueFragment[]
                )
                    .filter(
                        ({ category }) =>
                            category.categoryType !==
                                IExtraCategoryOption.Boolean &&
                            category.categoryType !==
                                IExtraCategoryOption.Datetime &&
                            category.showLabel
                    )
                    .map((extraCategoryValue) => {
                        if (
                            extraCategoryValue.category.categoryType ===
                            IExtraCategoryOption.String
                        ) {
                            return (
                                extraCategoryValue as IExtraCategoryStringValue
                            ).stringValue;
                        }

                        return (extraCategoryValue as IExtraCategoryChoiceValue)
                            .choiceValue.stringValue;
                    })
                    .join(', ');

                return (
                    <>
                        {option.name}

                        {!!extraCategoryString && (
                            <Typography
                                noWrap
                                color="grey.500"
                                component="div"
                                sx={{
                                    mt: 0.5,
                                    minWidth: '0',
                                    width: '100%',
                                }}
                                variant="caption"
                            >
                                {extraCategoryString}
                            </Typography>
                        )}
                    </>
                );
            }}
            getOptionKey={(option: IGroupInputOptionFragment) => option.id}
            getOptionLabel={(option: IGroupInputOptionFragment) => option.name}
            InputProps={{ label }}
            isOptionEqualToValue={(
                option: IGroupInputOptionFragment,
                value: IGroupInputOptionFragment
            ) => option.id === value.id}
            loading={loading}
            loadingText={translate('loadingDots')}
            noOptionsText={translate('noOptionsText.groups')}
            options={options}
            onChange={handleChange}
            {...other}
        />
    );
};
