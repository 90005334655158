import { useTranslation } from 'react-i18next';

import { RouteLink } from 'route/components/RouteLink';
import { Tab, Tabs } from 'common/components/Tabs';
import { useOfferTypeFilter } from 'offer/hooks/useOfferTypeFilter';

export const OfferTabsMenu = () => {
    const [translate] = useTranslation();
    const { typeFilters, currentTypeFilter } = useOfferTypeFilter();

    // Don't show tabs if there no current type filter
    if (!currentTypeFilter || typeFilters.length <= 1) return null;

    return (
        <Tabs value={currentTypeFilter.key}>
            {typeFilters.map((filter) => (
                <Tab
                    component={RouteLink}
                    key={filter.key}
                    label={translate(filter.labelTransKey)}
                    query={`?type=${filter.key}`}
                    to="OFFER_LIST"
                    value={filter.key}
                />
            ))}
        </Tabs>
    );
};
