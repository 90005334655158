import { Box, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    mdiAccountMinus,
    mdiAccountPlus,
    mdiAccountRemove,
    mdiClipboardCheck,
    mdiClipboardRemove,
    mdiEmail,
} from '@mdi/js';

import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';

interface IProps {
    hide?: boolean;
    isWebinarOrMeeting?: boolean;
    onClickEnroll?(): void;
    onClickReject?(): void;
    onClickDelete?(): void;
    onClickMail?(): void;
    onClickComplete?(): void;
    onClickAbsent?(): void;
}

export const EnrollmentTableHeadActions = ({
    hide,
    isWebinarOrMeeting,
    onClickEnroll,
    onClickReject,
    onClickDelete,
    onClickMail,
    onClickComplete,
    onClickAbsent,
}: IProps) => {
    const [translate] = useTranslation();

    const showMainStack =
        onClickEnroll ||
        onClickReject ||
        onClickDelete ||
        onClickMail ||
        onClickComplete ||
        onClickAbsent;

    if (!showMainStack || hide) return null;

    const showCommonStack =
        (isWebinarOrMeeting && (onClickEnroll || onClickReject)) ||
        onClickDelete;
    const showWebinarMeetingStack = onClickComplete || onClickAbsent;

    return (
        <Stack
            direction="row"
            divider={<Divider flexItem orientation="vertical" />}
            spacing={1}
            sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
            {showCommonStack && (
                <Stack direction="row">
                    {isWebinarOrMeeting && (
                        <>
                            {onClickEnroll && (
                                <Tooltip
                                    title={translate<string>(
                                        'enrollmentsTable.enroll'
                                    )}
                                >
                                    <Box>
                                        <IconButton
                                            color="inherit"
                                            iconPath={mdiAccountPlus}
                                            iconSize="2.4rem"
                                            onClick={onClickEnroll}
                                        />
                                    </Box>
                                </Tooltip>
                            )}

                            {onClickReject && (
                                <Tooltip
                                    title={translate<string>(
                                        'enrollmentsTable.reject'
                                    )}
                                >
                                    <Box>
                                        <IconButton
                                            color="inherit"
                                            iconPath={mdiAccountMinus}
                                            iconSize="2.4rem"
                                            onClick={onClickReject}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                        </>
                    )}

                    {onClickDelete && (
                        <Tooltip
                            title={translate<string>('enrollmentsTable.delete')}
                        >
                            <Box>
                                <IconButton
                                    color="inherit"
                                    iconPath={mdiAccountRemove}
                                    iconSize="2.4rem"
                                    onClick={onClickDelete}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Stack>
            )}

            {onClickMail && (
                <Box>
                    <Tooltip title={translate<string>('enrollmentsTable.mail')}>
                        <Box>
                            <IconButton
                                color="inherit"
                                iconPath={mdiEmail}
                                iconSize="2.4rem"
                                onClick={onClickMail}
                            />
                        </Box>
                    </Tooltip>
                </Box>
            )}

            {isWebinarOrMeeting && showWebinarMeetingStack && (
                <Stack direction="row">
                    {onClickComplete && (
                        <Tooltip
                            title={translate<string>(
                                'enrollmentsTable.complete'
                            )}
                        >
                            <Box>
                                <IconButton
                                    color="inherit"
                                    iconPath={mdiClipboardCheck}
                                    iconSize="2.4rem"
                                    onClick={onClickComplete}
                                />
                            </Box>
                        </Tooltip>
                    )}

                    {onClickAbsent && (
                        <Tooltip
                            title={translate<string>('enrollmentsTable.absent')}
                        >
                            <Box>
                                <IconButton
                                    color="inherit"
                                    iconPath={mdiClipboardRemove}
                                    iconSize="2.4rem"
                                    onClick={onClickAbsent}
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
