import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCreateVideoModuleMutation } from 'graphql/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import {
    VideoModuleForm,
    IVideoModuleFormProps,
} from 'training/components/forms/VideoModuleForm';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { createModuleInCache } from 'training/utils/module';
import { getUrl } from 'route/utils/getUrl';

import { VideoModuleSelectDrawer } from './VideoModuleSelectDrawer';

export const VideoModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const [searchParams, setSearchParams] = useSearchParams();

    const routeQuery = useRouteQuery();
    const videoIdParam = routeQuery.get('video') || undefined;
    const [selectedVideoId, setSelectedVideoId] = useState(videoIdParam);

    const videoSelectDrawerOpen =
        useHashMatch('TRAINING_MODULE_SELECT') && !!selectedVideoId;

    const [createModule] = useCreateVideoModuleMutation();

    useEffect(() => {
        if (!videoIdParam) return;

        // If the video id is set we can remove the video search param from the url
        const { video, ...newSearchParams } = Object.fromEntries(searchParams);
        setSearchParams(new URLSearchParams(newSearchParams));
    }, [videoIdParam, searchParams, setSearchParams]);

    const handleSubmit: IVideoModuleFormProps['onSubmit'] = async (values) => {
        const { image, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            imageId: image?.id,
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
        // in the training which will make a new moduleGroup
        const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

        try {
            await createModule({
                variables: { module: moduleValues, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createVideoModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Create video module in drawer',
        });

        displaySnackbar(translate('moduleActionSuccess.video.create'), {
            variant: 'success',
        });

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    return (
        <>
            <PageTitle mixpanelTitle="'Drawer create video module">
                {translate('newVideoModule')}
            </PageTitle>

            <VideoModuleSelectDrawer
                open={videoSelectDrawerOpen}
                onClose={() => {
                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
                onSelect={(video) => {
                    // Redirect to the pathname to go to the page without hashtag.
                    setSelectedVideoId(video.id);
                    navigate(window.location.pathname);
                }}
            />

            {!selectedVideoId ? (
                <VideoModuleSelectDrawer
                    open
                    inDrawer={false}
                    onSelect={(video) => {
                        setSelectedVideoId(video.id);
                        navigate(window.location.pathname);
                    }}
                />
            ) : (
                <VideoModuleForm
                    moduleGroupId={moduleGroupId}
                    videoId={selectedVideoId}
                    onReplaceVideo={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    );
};
