import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageDrawer } from 'common/components/PageDrawer';
import {
    useTrainingQuery,
    IAssignmentType,
    useUpdateNotificationSubscriptionMutation,
    INotificationSubscriptionType,
    IAssignmentFragment,
    useModuleQuery,
    IModuleType,
    IAssignmentModuleListItemFragment,
    ITrainingRole,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { AssignmentList } from 'training/components/AssignmentList';
import { AssignmentDetail } from 'training/components/AssignmentDetail';
import { getSearchUrlQuery } from 'common/utils/getSearchUrl';
import { getUrl } from 'route/utils/getUrl';
import { TFilterBarItem } from 'common/types';
import { useUserAssignmentSearch } from 'training/hooks/useUserAssignmentSearch';
import { FilterBar } from 'common/components/FilterBar';
import { NotificationButton } from 'notification/components/NotificationButton';
import { NotFound } from 'common/components/NotFound';
import { useFrontendPermissions } from 'user/hooks';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { closeGlobalDrawerRoute } from 'common/utils/globalDrawer';

interface IProps extends IPageDrawerProps {
    trainingId?: string;
    moduleId?: string;
    userId?: string;
    onClose?(): void;
    onUpdateAssignment?(): void;
}

export const AssignmentGradeDrawer = ({
    open,
    trainingId: trainingIdProp,
    moduleId: moduleIdProp,
    userId: userIdProp,
    onClose,
    onUpdateAssignment,
}: IProps) => {
    const navigate = useNavigate();
    const [translate] = useTranslation();
    const location = useLocation();
    const {
        trainingId: trainingIdParam,
        moduleId: moduleIdParam,
        userId: userIdParam,
    } = useParams() as {
        trainingId: string;
        moduleId: string;
        userId?: string;
    };
    const [showAssignmentDetail, setShowAssignmentDetail] = useState(false);

    const trainingId = trainingIdProp || trainingIdParam;
    const moduleId = moduleIdProp || moduleIdParam;
    const userId = userIdProp || userIdParam;

    const { canUpdate: canUpdateTraining, loading: permissionsLoading } =
        useFrontendPermissions('training');

    const slideRef = useRef<HTMLDivElement>(null);
    const [selectedAssignment, setSelectedAssignment] =
        useState<IAssignmentFragment>();
    const prevSearchParams = useRef<string>();

    const {
        data: trainingData,
        loading: trainingLoading,
        error,
    } = useTrainingQuery({
        variables: { id: trainingId },
    });

    const { data: moduleData, loading: moduleLoading } = useModuleQuery({
        variables: { id: moduleId, type: IModuleType.Assignment },
        skip: !moduleId,
    });

    const training = trainingData?.training;
    const { currentUserIsAuthor, rolesForCurrentUser } = training || {};

    const isParticipant = !!rolesForCurrentUser?.includes(
        ITrainingRole.Participant
    );
    const isTrainer =
        !!rolesForCurrentUser?.includes(ITrainingRole.Trainer) ||
        !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);
    const isGroupmanager = !!rolesForCurrentUser?.includes(
        ITrainingRole.Groupmanager
    );

    const canManageParticipants =
        isTrainer || isGroupmanager || canUpdateTraining;

    const moduleItem =
        (moduleData?.module as IAssignmentModuleListItemFragment) || undefined;

    const [
        updateSubscriptionNotification,
        {
            loading: updateSubscriptionNotificationLoading,
            error: updateSubscriptionNotificationError,
        },
    ] = useUpdateNotificationSubscriptionMutation({
        update: (cache, mutationResult) => {
            if (
                !selectedAssignment ||
                !mutationResult.data?.updateNotificationSubscription.ok
            ) {
                return;
            }

            cache.modify({
                id: cache.identify(selectedAssignment),
                fields: {
                    notificationSubscription() {
                        return !selectedAssignment.notificationSubscription;
                    },
                },
            });
        },
    });

    const { filters, searchQueryParam, queryParams, selectedFilters } =
        useUserAssignmentSearch(moduleItem);

    useEffect(() => {
        if (userId) return;

        // prevSearchParams is used to route back to the correct assignment url
        // instead of using the goBack function
        prevSearchParams.current = window.location.search;
    }, [userId]);

    const [filesAreUpdating, setFilesAreUpdating] = useState(false);

    const handleSearch = (value: string) => {
        // Redirect user to url with query
        const url = getUrl('TRAINING_ASSIGNMENT_GRADE', {
            trainingId,
            moduleId: moduleItem.id,
        });

        navigate(`${url}?${getSearchUrlQuery(value, queryParams)}`);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        // Build url with query parameters when user changes filters
        const queryParams = selected.reduce((acc, curParam) => {
            const { parent, value } = curParam;
            const key = parent || '';

            // When property is already set we want to append the value
            const newValue = Object.hasOwn(acc, key)
                ? [value, acc[key as keyof typeof acc]]
                : [value];

            return { ...acc, [key]: newValue };
        }, {});

        const url = getUrl('TRAINING_ASSIGNMENT_GRADE', {
            trainingId,
            moduleId: moduleItem.id,
        });

        navigate(`${url}?${getSearchUrlQuery(searchQueryParam, queryParams)}`);
    };

    const handleClose = () => {
        if (showAssignmentDetail) {
            setShowAssignmentDetail(false);

            return;
        }

        if (!onClose) {
            if (!!userIdParam) {
                // Navigate back to the assignment list by cutting off /user/ and
                // everything after from the route
                navigate(location.pathname.replace(/(?:\/user\/?.*)/, ''));

                return;
            }

            closeGlobalDrawerRoute(navigate, location);

            return;
        }

        onClose?.();
    };

    const handleCreateNotificationSubscription = () => {
        if (!selectedAssignment) return;

        updateSubscriptionNotification({
            variables: {
                active: !selectedAssignment.notificationSubscription,
                objectId: selectedAssignment.id,
                subscriptionType:
                    selectedAssignment.__typename === 'CollectiveAssignment'
                        ? INotificationSubscriptionType.CollectiveAssignment
                        : INotificationSubscriptionType.IndividualAssignment,
            },
        });
    };

    const loading = trainingLoading || moduleLoading || permissionsLoading;

    // Check if the user can manage the assignment
    if (!loading && !canManageParticipants) {
        return (
            <PageDrawer open={open} onClose={handleClose}>
                <NotFound />
            </PageDrawer>
        );
    }

    if (loading || error) {
        return (
            <PageDrawer open={open}>
                {error && <ApolloError error={error} />}
                {loading && <Loader />}
            </PageDrawer>
        );
    }

    const isIndividualAssignment =
        moduleItem?.assignmentType === IAssignmentType.Individual;

    const canSeeAssignmentList =
        isIndividualAssignment && canManageParticipants;

    const searchVariables = {
        searchQueryParam,
        queryParams,
    };

    const drawerActions =
        selectedAssignment &&
        (!isIndividualAssignment || (isIndividualAssignment && userId)) ? (
            <NotificationButton
                active={!!selectedAssignment.notificationSubscription}
                disabled={updateSubscriptionNotificationLoading}
                onClick={handleCreateNotificationSubscription}
            />
        ) : undefined;

    return (
        <PageDrawer
            actions={drawerActions}
            disableClose={filesAreUpdating}
            hideCloseIcon={filesAreUpdating}
            open={open}
            SlideProps={{
                ref: slideRef,
            }}
            title={moduleItem?.title}
            onClose={handleClose}
        >
            {updateSubscriptionNotificationError && (
                <ApolloError error={updateSubscriptionNotificationError} />
            )}

            {canSeeAssignmentList &&
                !showAssignmentDetail &&
                isIndividualAssignment &&
                !userId && (
                    <>
                        <Box m={{ xs: 2, sm: 4 }} mb={{ xs: 1, sm: 2 }}>
                            <FilterBar
                                filters={filters}
                                initialSearchValue={searchQueryParam}
                                initialSelected={selectedFilters}
                                placeholder={translate('filterBarPlaceholder')}
                                onSearch={handleSearch}
                                onSearchClear={() => handleSearch('')}
                                onSelect={handleFilterSelect}
                            />
                        </Box>
                        <AssignmentList
                            moduleItem={moduleItem}
                            searchVariables={searchVariables}
                            onChangePage={() => {
                                if (!slideRef.current) return;

                                slideRef.current.scrollTo(0, 0);
                            }}
                            onClickUser={(userId: string) => {
                                if (!!trainingIdParam && !!moduleIdParam) {
                                    navigate(
                                        getUrl('TRAINING_ASSIGNMENT_FOR_USER', {
                                            trainingId: trainingIdParam,
                                            moduleId: moduleIdParam,
                                            userId,
                                        })
                                    );

                                    return;
                                }

                                setShowAssignmentDetail(true);
                            }}
                        />
                    </>
                )}

            {(!isIndividualAssignment || showAssignmentDetail || !!userId) &&
                !!moduleId && (
                    <AssignmentDetail
                        isAssignmentGradeView
                        canManageParticipants={canManageParticipants}
                        currentUserIsAuthor={!!currentUserIsAuthor}
                        currentUserIsParticipant={isParticipant}
                        drawerRef={slideRef}
                        filesAreUpdating={filesAreUpdating}
                        moduleId={moduleId}
                        setFilesAreUpdating={setFilesAreUpdating}
                        setSelectedAssignment={setSelectedAssignment}
                        userId={userId}
                        onUpdateAssignment={onUpdateAssignment}
                    />
                )}
        </PageDrawer>
    );
};
