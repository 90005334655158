import { useRef } from 'react';
import { DraggableLocation, DropResult } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { Reference } from '@apollo/client';

import { findNestedDraggable, reorder } from 'common/utils/draggable';
import {
    IPortfolioItemListItemOldFragment,
    PortfolioItemGroupFragmentDoc,
    useUpdatePortfolioItemGroupMutation,
    useUpdatePortfolioItemGroupsMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { IPortfolioGroup } from 'user/types';

export const usePortfolioDND = (
    groups: IPortfolioGroup[],
    setGroups: (groups: IPortfolioGroup[]) => void
) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    // A fallback for if the sorting process fails
    const previousGroups = useRef<IPortfolioGroup[]>();

    const [updateGroupsOrder] = useUpdatePortfolioItemGroupsMutation({
        update: () => (previousGroups.current = undefined),
        onError: () => {
            displaySnackbar(translate('sortingFail'));

            if (!previousGroups.current) return;

            // Rollback of the groups
            setGroups(previousGroups.current);
        },
    });
    const [updateGroup] = useUpdatePortfolioItemGroupMutation({
        onError: () => {
            displaySnackbar(translate('sortingFail'));

            if (!previousGroups.current) return;

            // Rollback of the groups
            setGroups(previousGroups.current);
        },
    });

    const handleDragEndGroups = (
        source: DraggableLocation,
        destination?: DraggableLocation
    ) => {
        if (!destination) return;

        previousGroups.current = [...groups];

        const orderedGroups = reorder<IPortfolioGroup>(
            groups.filter((group) => !group.isInbox),
            source.index,
            destination.index
        );

        const inboxGroupResult = groups.filter((group) => group.isInbox);

        const newGroups = inboxGroupResult.length
            ? [inboxGroupResult[0], ...orderedGroups]
            : orderedGroups;

        setGroups(newGroups);

        updateGroupsOrder({
            variables: {
                groupIds: orderedGroups.map((group) => group.id),
            },
            optimisticResponse: {
                __typename: 'Mutations',
                updatePortfolioItemGroups: {
                    __typename: 'UpdatePortfolioItemGroups',
                    ok: true,
                },
            },
        });
    };

    const handleDragEndItems = (
        source: DraggableLocation,
        draggableId: string,
        destination?: DraggableLocation
    ) => {
        if (!destination) return;

        const draggableItem = findNestedDraggable(
            groups,
            'portfolioItems',
            draggableId
        );

        if (!draggableItem) return;

        previousGroups.current = [...groups];

        groups.forEach((group) => {
            if (
                source.droppableId !== group.id &&
                destination.droppableId !== group.id
            ) {
                return group;
            }

            const items = group.portfolioItems.filter(
                (item) => item.id !== draggableId
            );

            if (group.id === destination.droppableId) {
                items.splice(destination.index, 0, draggableItem);

                updateGroup({
                    variables: {
                        id: destination.droppableId,
                        portfolioItemGroup: {
                            itemOrder: items.map((item) => item.id),
                        },
                    },
                    optimisticResponse: {
                        __typename: 'Mutations',
                        updatePortfolioItemGroup: {
                            ...group,
                            portfolioItems: items,
                        },
                    },
                    update: (cache) => {
                        if (source.droppableId === destination.droppableId) {
                            return;
                        }

                        const previousPortfolioItemGroup = cache.readFragment({
                            id: `PortfolioItemGroup:${source.droppableId}`,
                            fragment: PortfolioItemGroupFragmentDoc,
                            fragmentName: 'PortfolioItemGroup',
                        });

                        if (!previousPortfolioItemGroup) return;

                        // Remove moved portfolio item from previous owning group
                        cache.modify({
                            id: `PortfolioItemGroup:${source.droppableId}`,
                            fields: {
                                portfolioItems(
                                    existingPortfolioItemRefs: readonly (
                                        | Reference
                                        | IPortfolioItemListItemOldFragment
                                    )[] = [],
                                    { readField }
                                ) {
                                    return existingPortfolioItemRefs.filter(
                                        (itemRef) => {
                                            const itemId = readField(
                                                'id',
                                                itemRef
                                            );

                                            return itemId !== draggableId;
                                        }
                                    );
                                },
                            },
                        });
                    },
                });
            }
        });
    };

    const handleDragEnd = ({
        type,
        destination,
        source,
        draggableId,
    }: DropResult) => {
        if (type === 'GROUP') {
            return handleDragEndGroups(source, destination || undefined);
        }

        handleDragEndItems(source, draggableId, destination || undefined);
    };

    return { handleDragEnd };
};
