import { Divider, Box, FormControlLabel, FormHelperText } from '@mui/material';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';

import { Button } from 'common/components/Button';
import {
    Select,
    Switch,
    TextField,
    DatePicker,
    MultiSelect,
    Autocomplete,
} from 'common/components/FormField';
import { LANGUAGES } from 'common/constants/languages';
import { NOTIFICATION_OPTIONS } from 'user/constants/notificationOptions';
import { Typography } from 'common/components/Typography';
import { ICreateUserFormValues, TExtraUserField } from 'user/types';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { LanguageSelectField } from 'common/components/LanguageSelectField';
import { getExtraFieldsName } from 'common/utils/extraFields';
import { Checkbox } from 'common/components/Checkbox';
import { Loader } from 'common/components/Loader';
import { ContentExpander } from 'common/components/ContentExpander';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import {
    UserRolesFormSection,
    UserGroupsFormSection,
} from 'user/components/forms';
import { IRole, useCurrentUserQuery } from 'graphql/types';

interface IProps extends FormikConfig<ICreateUserFormValues> {
    className?: string;
    disabled?: boolean;
    extraFields: TExtraUserField[];
    hideGroups?: boolean;
    imageUploaded?: boolean;
    loading?: boolean;
}

export const UserCreateForm = ({
    className,
    disabled,
    extraFields,
    hideGroups,
    imageUploaded,
    loading,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};

    if (loading || loadingUser) return <Loader />;

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(translate('validate.required')),
        email: Yup.string()
            .email(translate('validate.email'))
            .required(translate('validate.required')),
        language: Yup.mixed()
            .oneOf(Object.keys(LANGUAGES))
            .required(translate('validate.required')),
        notifications: Yup.mixed()
            .oneOf(Object.keys(NOTIFICATION_OPTIONS))
            .required(translate('validate.required')),
    });

    const extraFieldsShape = extraFields.reduce((acc, field) => {
        if (field.requiredManager) {
            return {
                ...acc,
                [getExtraFieldsName(field.name)]:
                    field.__typename === 'UserExtraChoiceField'
                        ? field.multiple
                            ? Yup.array()
                                  .of(Yup.string())
                                  .min(1, translate('validate.required'))
                                  .required(translate('validate.required'))
                            : Yup.string()
                                  .min(1, translate('validate.required'))
                                  .required(translate('validate.required'))
                        : field.__typename === 'UserExtraCheckboxField'
                          ? Yup.boolean().oneOf(
                                [true],
                                translate('validate.required')
                            )
                          : Yup.string()
                                .required(translate('validate.required'))
                                .nullable(),
            };
        }

        return acc;
    }, {});

    const notificationseOptions = Object.entries(NOTIFICATION_OPTIONS).map(
        ([val, label]) => ({
            value: val,
            label: translate(label as string),
        })
    );

    const isOwnerOrManager =
        currentUser?.roles?.includes(IRole.Owner) ||
        currentUser?.roles?.includes(IRole.Manager);

    return (
        <Formik
            validationSchema={validationSchema.shape(extraFieldsShape)}
            {...other}
        >
            {({
                submitForm,
                isSubmitting,
                values,
                dirty,
                setFieldValue,
                errors,
            }) => (
                <Box p={4}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={(dirty || imageUploaded) && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form className={className}>
                        <Typography gutterBottom variant="h3">
                            {translate('personalInformation')}
                        </Typography>

                        <Field
                            component={TextField}
                            label={translate('name')}
                            name="name"
                        />

                        <Field
                            component={TextField}
                            label={translate('email')}
                            name="email"
                        />

                        {extraFields.map((field, index) => {
                            const extraFieldName = getExtraFieldsName(
                                field.name
                            );

                            switch (field.__typename) {
                                case 'UserExtraDateField':
                                    return (
                                        <Field
                                            component={DatePicker}
                                            key={field.name}
                                            label={field.name}
                                            name={extraFieldName}
                                            required={field.requiredManager}
                                        />
                                    );

                                case 'UserExtraStringField':
                                    return (
                                        <Field
                                            component={TextField}
                                            key={field.name}
                                            label={field.name}
                                            name={extraFieldName}
                                            required={field.requiredManager}
                                        />
                                    );

                                case 'UserExtraChoiceField':
                                    return (
                                        <Field
                                            component={
                                                field.multiple
                                                    ? MultiSelect
                                                    : Autocomplete
                                            }
                                            InputProps={{
                                                label: field.name,
                                                required: field.requiredManager,
                                            }}
                                            key={field.name}
                                            label={field.name}
                                            name={extraFieldName}
                                            options={field.possibleValues}
                                            required={field.requiredManager}
                                        />
                                    );
                                case 'UserExtraCheckboxField':
                                    return (
                                        <Box key={field.name}>
                                            <FormControlLabel
                                                checked={
                                                    values[
                                                        extraFieldName as keyof typeof values
                                                    ] || false
                                                }
                                                control={
                                                    <Field
                                                        component={Checkbox}
                                                        inputProps={{
                                                            required:
                                                                field.requiredManager,
                                                        }}
                                                    />
                                                }
                                                label={field.name}
                                                name={extraFieldName}
                                                onChange={(_e, checked) => {
                                                    setFieldValue(
                                                        extraFieldName,
                                                        checked
                                                    );
                                                }}
                                            />
                                            {errors[
                                                extraFieldName as keyof typeof errors
                                            ] && (
                                                <FormHelperText
                                                    error
                                                    key={index}
                                                >
                                                    {
                                                        errors[
                                                            extraFieldName as keyof typeof errors
                                                        ]
                                                    }
                                                </FormHelperText>
                                            )}
                                        </Box>
                                    );

                                default:
                                    return null;
                            }
                        })}

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                {isOwnerOrManager && <UserRolesFormSection />}

                                {!hideGroups && (
                                    <UserGroupsFormSection values={values} />
                                )}

                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="userForm.languagesDescription"
                                        />
                                    }
                                    title={translate('userForm.languagesTitle')}
                                >
                                    <LanguageSelectField name="language" />
                                </FormSection>

                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="userForm.notificationsDescription"
                                        />
                                    }
                                    title={translate(
                                        'userForm.notificationsTitle'
                                    )}
                                >
                                    <Field
                                        component={Select}
                                        label={translate('notifications')}
                                        name="notifications"
                                        options={notificationseOptions}
                                    />

                                    <Field
                                        checked={values.automatedMessages}
                                        component={Switch}
                                        description={translate(
                                            'userNotifications.automatedMessagesDescription'
                                        )}
                                        label={translate(
                                            'userNotifications.automatedMessages'
                                        )}
                                        name="automatedMessages"
                                    />
                                </FormSection>
                            </ContentExpander>

                            <Box mt={3}>
                                <Field
                                    checked={values.sendInvite}
                                    component={Switch}
                                    formControlProps={{
                                        margin: 'none',
                                    }}
                                    label={translate('userForm.sendInvite')}
                                    name="sendInvite"
                                />

                                {values.sendTrainingInvite !== undefined && (
                                    <Field
                                        checked={values.sendTrainingInvite}
                                        component={Switch}
                                        formControlProps={{
                                            margin: 'none',
                                        }}
                                        label={translate(
                                            'userForm.sendTrainingInvite'
                                        )}
                                        name="sendTrainingInvite"
                                    />
                                )}

                                <Box mt={3}>
                                    <Divider />
                                </Box>
                            </Box>
                        </Box>

                        <Box mt={4}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting &&
                                        !disabled &&
                                        !dirty &&
                                        !imageUploaded)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
