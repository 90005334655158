import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import {
    useAssignmentsQuery,
    IAssignmentModuleListItemFragment,
    IUserAssignment,
} from 'graphql/types';
import { TQueryParams } from 'common/utils/getSearchUrl';

type TPaginationVariables = {
    offset?: number;
    before?: string;
    after?: string;
    first?: number;
    last?: number;
};

function getFilterValue(queryParamValue?: string | string[]) {
    // Since the value is boolean based, having both false and true just means all results
    if (!queryParamValue || queryParamValue.length > 1) return undefined;

    return !!+queryParamValue[0];
}

export const useUserAssignmentsList = (
    moduleItem: IAssignmentModuleListItemFragment,
    paginationVariables: TPaginationVariables,
    searchVariables: {
        searchQueryParam: string | null;
        queryParams: TQueryParams;
    }
) => {
    const [translate] = useTranslation();

    const { searchQueryParam, queryParams } = searchVariables;

    const scoreText = translate('score');
    const fulfilledText = translate('fulfilled');
    const closedText = translate('closed');

    const scoreParam = getFilterValue(
        queryParams[scoreText.toLocaleLowerCase()]
    );
    const fulfilledParam = getFilterValue(
        queryParams[fulfilledText.toLocaleLowerCase()]
    );
    const closedParam = getFilterValue(
        queryParams[closedText.toLocaleLowerCase()]
    );

    const {
        loading,
        error,
        data: assignmentsData,
        refetch,
    } = useAssignmentsQuery({
        variables: {
            moduleId: moduleItem.id,
            q: searchQueryParam,
            ...paginationVariables,
            score: scoreParam,
            fulfilled: fulfilledParam,
            closed: closedParam,
        },
    });

    useEffect(() => {
        // Refetch the query each time the module changes.
        // Prevents the UserAssignment to be linked to the wrong
        // IndividualAssignment after everything is cached.
        refetch();
    }, [moduleItem.id]);

    const assignmentsObject = assignmentsData?.assignments;
    const assignmentsCount = assignmentsObject?.count;
    const userAssignments = assignmentsObject?.edges
        .map((edge) => edge?.node || null)
        .filter(Boolean) as IUserAssignment[] | undefined;

    return {
        userAssignments,
        assignmentsCount,
        loading,
        error,
    };
};
