import { List as MuiList, ListProps, SxProps, useTheme } from '@mui/material';

import { withSxProp } from 'common/utils/props';
import { TColorKeys } from 'theme/palette';

interface IProps {
    noBorder?: boolean;
    color?: TColorKeys;
    noPadding?: boolean;
    sx?: SxProps;
}

export function List<C extends React.ElementType = 'div'>({
    noBorder,
    noPadding,
    color,
    sx,
    ...other
}: ListProps<C, { component?: C } & IProps>) {
    const theme = useTheme();

    const listStyle: SxProps = {
        py: { xs: 1, sm: 2 },
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'divider',

        borderRadius: 1,
    };

    return (
        <MuiList
            disablePadding
            sx={[
                listStyle,
                color && {
                    color: theme.palette[color].main,
                    backgroundColor: theme.palette[color].bgColor,
                },
                noBorder && { border: 'none', borderRadius: 0 },
                noPadding && {
                    py: { xs: 0 },
                    'div:first-of-type .MuiListItem-root': {
                        pt: 0,
                    },
                    'div:last-of-type .MuiListItem-root': {
                        pb: 0,
                    },
                },
                ...withSxProp(sx),
            ]}
            {...other}
        />
    );
}
