import React, { forwardRef } from 'react';
import { Link as MuiLink, LinkProps } from '@mui/material';

import { RouteLink } from 'route/components/RouteLink';

export function BaseLink<C extends React.ElementType>(
    { to, ...other }: LinkProps<C, { component?: C }>,
    ref: React.Ref<HTMLAnchorElement>
) {
    // When no "to" is given we just want to render the link without RouteLink component
    if (!to) {
        return (
            <MuiLink color="inherit" ref={ref} underline="hover" {...other} />
        );
    }

    return (
        <MuiLink
            color="inherit"
            component={RouteLink}
            ref={ref}
            to={to}
            underline="hover"
            {...other}
        />
    );
}

export const Link = forwardRef(BaseLink);
