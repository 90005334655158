import { useTranslation } from 'react-i18next';
import { ApolloError, useApolloClient } from '@apollo/client';
import { DialogContentText, MenuItem } from '@mui/material';
import { useState } from 'react';

import { useGroupUsersList } from 'user/hooks/group/useGroupUsersList';
import {
    GroupListItemFragmentDoc,
    IContentTypeValue,
    IFeature,
    IGroupFragment,
    IUserSelectorRole,
    IGroupUsersQueryVariables,
    useAddUsersToGroupMutation,
    useRemoveUsersFromGroupMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useUserTableData } from 'user/hooks/useUserTableData';
import { Link } from 'common/components/Link';
import { useFeature } from 'common/hooks/useFeature';
import { getErrorMessage } from 'common/utils/getErrorMessage';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { UserSelector } from 'user/components/UserSelector';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { EGroupUserRole } from 'user/types';

interface IProps {
    group: IGroupFragment;
    onUserUpdate?(): void;
}

export const UsersView = ({ group, onUserUpdate }: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const client = useApolloClient();
    const [displaySnackbar] = useSnackbar();
    const { openGlobalDrawer } = useGlobalDrawer();

    const { id: groupId, permissions } = group;

    const {
        loading: loadingUsers,
        users,
        tableDataProps,
    } = useUserTableData<IGroupUsersQueryVariables>(
        [],
        useGroupUsersList,
        undefined,
        { id: groupId }
    );
    const [addUsersToGroup, { loading: loadingAddUsers }] =
        useAddUsersToGroupMutation();
    const [removeUsersFromGroup, { loading: loadingRemoveUsers }] =
        useRemoveUsersFromGroupMutation();
    const { canUse: canCreateUsers, loading: loadingFeature } = useFeature(
        IFeature.CreateUsers
    );
    const [showMaxUsersDialog, setShowMaxUsersDialog] = useState(false);

    const userAmount = users?.length || 0;
    const maxUsers = group.maxUsers;

    const addCountToGroupInCache = (count: number) => {
        client.cache.updateFragment(
            {
                id: `Group:${groupId}`,
                fragment: GroupListItemFragmentDoc,
                fragmentName: 'GroupListItem',
            },
            (data) => ({
                ...data,
                userCount: data.userCount + count,
            })
        );
    };

    const handleAddParticipantsToGroup = async (selectedUsers: string[]) => {
        if (maxUsers > 0 && userAmount + selectedUsers.length > maxUsers) {
            setShowMaxUsersDialog(true);

            return;
        }

        try {
            await addUsersToGroup({
                variables: {
                    groupId,
                    userIds: selectedUsers,
                },
                update: (cache, result) => {
                    const { addedIds } = result.data?.addUsersToGroup || {};

                    if (!addedIds?.length) return;

                    addCountToGroupInCache(addedIds.length);

                    // Refetch the selector to make sure the correct users are selected
                    cache.evict({ fieldName: 'paginatedUsers' });
                    cache.gc();
                },
            });
        } catch (error) {
            const { graphQLErrors } = error as ApolloError;

            graphQLErrors.forEach((e) => {
                displaySnackbar(getErrorMessage(e));
            });

            return;
        }

        client.refetchQueries({ include: ['GroupUsers'] });

        onUserUpdate?.();

        return;
    };

    const handleRemoveParticipantsFromGroup = async (
        selectedUsers: string[]
    ) => {
        try {
            await removeUsersFromGroup({
                variables: {
                    groupId,
                    userIds: selectedUsers,
                },
                update: (cache, result) => {
                    const { removedIds } =
                        result.data?.removeUsersFromGroup || {};

                    if (!removedIds?.length) return;

                    addCountToGroupInCache(-removedIds.length);

                    // Refetch the selector to make sure the correct users are selected
                    cache.evict({ fieldName: 'paginatedUsers' });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        client.refetchQueries({ include: ['GroupUsers'] });

        onUserUpdate?.();

        return;
    };

    const loading = loadingUsers || loadingAddUsers || loadingFeature;
    const groupIsFull = maxUsers > 0 && userAmount >= maxUsers;

    const addButtonMenuItems =
        !loadingFeature && canCreateUsers
            ? [
                  <MenuItem
                      key={0}
                      onClick={() => {
                          if (groupIsFull) {
                              setShowMaxUsersDialog(true);

                              return;
                          }

                          openGlobalDrawer({
                              type: IGlobalDrawerType.UserCreate,
                              props: {
                                  groupId,
                                  groupUserRole: EGroupUserRole.Participant,
                                  onCreate: () => {
                                      client.refetchQueries({
                                          include: ['GroupUsers'],
                                      });

                                      addCountToGroupInCache(1);
                                  },
                              },
                          });
                      }}
                  >
                      {translate('userSelector.createUser')}
                  </MenuItem>,
                  <MenuItem
                      component={Link}
                      key={1}
                      query={`${groupId}/?next=${window.location.href}`}
                      to="MANAGEMENT_GROUPS_USER_IMPORT"
                      onClick={(e: React.MouseEvent) => {
                          if (groupIsFull) {
                              setShowMaxUsersDialog(true);

                              e.preventDefault();
                          }
                      }}
                  >
                      {translate('userSelector.importFile')}
                  </MenuItem>,
              ]
            : undefined;

    const permissionsObj = JSON.parse(permissions || '{}');
    const canAddParticipants = !!permissionsObj?.canAddParticipants;

    return (
        <>
            <UserSelector
                addButtonMenuItems={addButtonMenuItems}
                addedUsers={users}
                deleteDialogProps={{
                    title: translate('groupForm.userDeleteDialog.title'),
                    text: translate('groupForm.userDeleteDialog.text'),
                }}
                isEditable={canAddParticipants}
                label={translate('groupForm.addParticipants')}
                loading={loading}
                loadingRemove={loadingRemoveUsers}
                noResultsLabel={translate('noOptionsText.participants')}
                selectorQueryVariables={{
                    contentType: IContentTypeValue.Group,
                    objectId: group.id || groupId,
                    objectRoles: [IUserSelectorRole.Participant],
                }}
                tableDataProps={tableDataProps}
                onAddUsers={handleAddParticipantsToGroup}
                onRemoveUsers={handleRemoveParticipantsFromGroup}
            />

            <AlertDialog
                actions={
                    <Button
                        variant="contained"
                        onClick={() => {
                            setShowMaxUsersDialog(false);
                        }}
                    >
                        {translate('cancel')}
                    </Button>
                }
                open={showMaxUsersDialog}
                title={translate('addUsersToGroupLimitDialog.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('addUsersToGroupLimitDialog.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
