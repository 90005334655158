import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';

export const useSkillFilter = (filterParamsInUrl = true) => {
    const { filterParams, setFilterParam } = useUrlFilterParams(
        'skill',
        filterParamsInUrl
    );

    const searchQueryParam = filterParams.q || '';

    const handleSearch = (searchValue: string) => {
        setFilterParam('q', searchValue);
    };

    return {
        filterValues: { q: searchQueryParam },
        filterValuesForQuery: { q: searchQueryParam },
        handleSearch,
    };
};
