import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    Stack,
    Divider,
} from '@mui/material';
import { mdiAccountCheck, mdiAccountRemove } from '@mdi/js';

import {
    TableActionBar,
    TableCell,
    TableHead,
    useSelection,
} from 'common/components/Table';
import { FilterBar } from 'common/components/FilterBar';
import { Typography } from 'common/components/Typography';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import { Pagination } from 'common/components/Pagination';
import { Loader } from 'common/components/Loader';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Checkbox } from 'common/components/Checkbox';
import { useUserManagementList } from 'user/hooks/userManagement/useUserManagementList';
import { UserManagementTableRow } from 'user/components/UsersManagementTable/UsersManagementTableRow';
import { useArchiveUsersMutation } from 'graphql/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { UserEmailDropdownMenu } from 'user/components/UserEmailDropdownMenu';

interface IProps {
    canDeleteUsers?: boolean;
    isEditable?: boolean;
}

export const UsersManagementTable = ({
    canDeleteUsers,
    isEditable,
}: IProps) => {
    const [translate] = useTranslation();
    const [showArchiveDialog, setShowArchiveDialog] = useState(false);
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [archiveUsers, { loading: loadingArchiveUsers }] =
        useArchiveUsersMutation();
    const {
        users,
        loading: usersLoading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useUserManagementList();
    const isActiveUsersPage = !!useRouteMatch('USER_MANAGEMENT_LIST');

    const loading = usersLoading;

    const resetSettings = () => {
        setPage(1);
        handleSearch('');

        if (!!selected?.length) onSelectAll?.();
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
        if (!!selected?.length) onSelectAll?.();
    };

    const handleArchive = async (archive: boolean) => {
        let succeededIds = [];

        try {
            const response = await archiveUsers({
                variables: { ids: selected, archive: archive },
                update: (cache) => {
                    cache.evict({ fieldName: 'paginatedUsers' });

                    selected.forEach((id) => {
                        cache.evict({ id: `User:${id}` });
                    });

                    cache.gc();
                },
            });

            const archiveUsersResponse = response.data?.archiveUsers?.response;
            const failedIds = archiveUsersResponse?.failedIds || [];
            succeededIds = archiveUsersResponse?.succeededIds || [];

            if (failedIds?.length) {
                displaySnackbar(
                    translate(
                        archive
                            ? 'usersManagementPage.usersArchiveFailed'
                            : 'usersManagementPage.usersUnarchiveFailed',
                        {
                            count: failedIds.length,
                        }
                    ),
                    {
                        variant: 'error',
                    }
                );
            }
        } catch (error) {
            showApolloError(error);

            return;
        }

        setShowArchiveDialog(false);

        resetSettings();

        if (succeededIds.length) {
            displaySnackbar(
                translate(
                    `usersManagementPage.usersActionSuccess.${archive ? 'unarchive' : 'archive'}`
                ),
                {
                    autoHideDuration: 30000,
                    variant: 'success',
                }
            );
        }
    };

    const { ...selectionProps } = useSelection(users);
    const { isSelected, rows, selected, onSelectAll } = selectionProps;

    const tableActions = (
        <Stack
            direction="row"
            divider={<Divider flexItem orientation="vertical" />}
            spacing={1}
            sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
            {canDeleteUsers && (
                <Tooltip
                    title={translate(
                        `usersManagementPage.${isActiveUsersPage ? 'archiveUsers' : 'unarchiveUsers'}`
                    )}
                >
                    <Box>
                        <IconButton
                            color="inherit"
                            disabled={!isSelected}
                            iconPath={
                                isActiveUsersPage
                                    ? mdiAccountRemove
                                    : mdiAccountCheck
                            }
                            iconSize="2.4rem"
                            onClick={() => setShowArchiveDialog(true)}
                        />
                    </Box>
                </Tooltip>
            )}

            <UserEmailDropdownMenu
                disabled={!isSelected}
                userIds={selected}
                onSendEmailComplete={() => {
                    if (!!selected?.length) onSelectAll?.();
                }}
            />
        </Stack>
    );

    return (
        <>
            <FilterBar
                useFilterDrawer
                disabled={loading}
                filterDrawerProps={{
                    title: translate('filterForm.userManagement.title'),
                    description: translate(
                        'filterForm.userManagement.description'
                    ),
                }}
                formFilters={filters}
                formFilterValues={filterValues}
                initialSearchValue={filterValues.q}
                placeholder={translate('filterBarPlaceholder')}
                onFiltersChange={(values) => {
                    setPage(1);
                    onFilterChange(values);
                }}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />

            {!loading && !!rows.length && (
                <TableContainer>
                    <TableActionBar
                        actions={tableActions}
                        selectionProps={selectionProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                {isEditable && !!tableActions && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={false}
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{translate('name')}</TableCell>
                                <TableCell>
                                    {translate(
                                        isActiveUsersPage
                                            ? 'invitedOn'
                                            : 'deletedOn'
                                    )}
                                </TableCell>
                                <TableCell>{translate('lastLogin')}</TableCell>
                                <TableCell>{translate('via')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const user = users[index] || null;

                                if (!user) return null;

                                return (
                                    <UserManagementTableRow
                                        forArchive={!isActiveUsersPage}
                                        isEditable={isEditable}
                                        isSelected={isSelected}
                                        key={user.id}
                                        user={user}
                                        onArchive={resetSettings}
                                        onSelect={onSelect}
                                        onUpdate={resetSettings}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '100px', mt: 4 }}>
                    <Loader />
                </Box>
            )}

            {!loading && !users.length && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Typography>{translate('noOptionsText.users')}</Typography>
                </Box>
            )}

            {!loading && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={
                            paginationSettings.count === 1
                                ? translate('user')
                                : translate('users')
                        }
                        onChange={(page: number) => {
                            setPage(page);
                        }}
                    />
                </Box>
            )}

            <ConfirmDialog
                confirmText={translate(
                    isActiveUsersPage ? 'archiveItem' : 'unarchiveItem'
                )}
                loading={loadingArchiveUsers}
                open={showArchiveDialog}
                title={translate(
                    `usersManagementPage.usersArchiveDialog.${isActiveUsersPage ? 'titleArchive' : 'titleUnarchive'}`
                )}
                onCancel={() => {
                    setShowArchiveDialog(false);
                }}
                onClose={() => setShowArchiveDialog(false)}
                onConfirm={() => handleArchive(isActiveUsersPage)}
            >
                {translate(
                    `usersManagementPage.usersArchiveDialog.${isActiveUsersPage ? 'textArchive' : 'textUnarchive'}`
                )}
            </ConfirmDialog>
        </>
    );
};
