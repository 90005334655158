import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { IFilterFormItem, TFilterFormValues } from 'common/types';
import { FormSection } from 'common/components/FormSection';
import { Button } from 'common/components/Button';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { FilterSectionFields } from 'common/components/FilterSectionFields';
import { useCurrentUserQuery } from 'graphql/types';

export interface IProps {
    filters?: IFilterFormItem[];
    filterValues?: TFilterFormValues;
    onChangeField?: IFilterFormItem['onChangeField'];
    onSubmit?(values: Record<string, unknown>): void;
    onReset?(values: TFilterFormValues): void;
}

export const FilterForm = ({
    filters,
    filterValues,
    onSubmit,
    onChangeField,
    onReset,
}: IProps) => {
    const [translate] = useTranslation();
    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};
    const { roles } = currentUser || {};

    if (!filters || loadingUser) return null;

    // Build default values from filters using the initialValue set in the filter
    const defaultValues = filters.reduce((acc: TFilterFormValues, filter) => {
        filter.fields.forEach((field) => {
            acc[field.name] = field.initialValue;
        });

        return acc;
    }, {});

    // Get current set filter values to build initial values
    const curFilterValues = filters.reduce((acc: TFilterFormValues, filter) => {
        filter.fields.forEach((field) => {
            // Use current set filter values if set as initial values
            if (!filterValues || !filterValues[field.name]) return;

            acc[field.name] = filterValues[field.name];
        });

        return acc;
    }, {});

    const initialValues = {
        ...defaultValues,
        ...curFilterValues,
    };

    const filterSections = filters.map(({ key, ...filter }) => {
        // When permission roles are passed then check if current user has permission to see the filter
        if (
            !!filter?.permissionRoles &&
            !filter.permissionRoles.some((role) => roles?.includes(role))
        ) {
            return null;
        }

        let Component: React.ElementType = FilterSectionFields;
        if (filter.component) Component = filter.component;

        const hasFilterValues = filter.fields.some((field) => {
            const curVal = initialValues[field.name];

            if (!curVal) return false;

            // If current value doesn't match initial value, we know we have set a filter
            return (
                JSON.stringify(curVal) !== JSON.stringify(field.initialValue)
            );
        });

        const { componentProps: filterComponentProps, ...filterProps } = filter;

        return (
            <FormSection
                renderExpanded
                defaultExpanded={hasFilterValues}
                key={key}
                sx={{ px: { xs: 2, sm: 4 } }}
                title={filter.label}
            >
                <Component
                    {...filterProps}
                    {...filterComponentProps}
                    onChangeField={onChangeField}
                />
            </FormSection>
        );
    });

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                onSubmit?.(values);
            }}
        >
            {({ submitForm, isSubmitting, dirty, setValues }) => (
                <Form>
                    {filterSections}
                    <Box sx={{ display: 'flex', p: { xs: 2, sm: 4 } }}>
                        <Button
                            color="primary"
                            disabled={isSubmitting || (!isSubmitting && !dirty)}
                            loading={isSubmitting}
                            type="submit"
                            variant="contained"
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault();

                                return submitForm();
                            }}
                        >
                            {translate('filterForm.filterButton')}
                        </Button>

                        <Box ml="auto">
                            <FormDeleteButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    setValues(defaultValues);

                                    onReset?.(defaultValues);
                                }}
                            >
                                {translate('filterForm.resetButton')}
                            </FormDeleteButton>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
