import React from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Divider } from 'common/components/Divider';
import { Text } from 'common/components/Text';
import {
    IPortfolioItemFragment,
    IUpdatePortfolioItemInput,
    useUpdatePortfolioItemMutation,
} from 'graphql/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { useApolloError } from 'common/hooks/useApolloError';
import { RelatedItemPortfolioItemForm } from 'user/components/forms';
import { PortfolioItemFileTable } from 'user/components/PortfolioItemFileTable';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { PortfolioItemDetailsView } from 'user/components/PortfolioItemDrawer/PortfolioItemDetailsView';

interface IProps {
    filesAreUpdating: boolean;
    loading?: boolean;
    pageTitle?: string;
    portfolioItem: IPortfolioItemFragment;
    userId?: string;
    withEditActions?: boolean;
    isCurrentUserItem?: boolean;
    onSubmit?(): void;
    setFilesAreUpdating(filesAreUpdating: boolean): void;
    children?: React.ReactNode;
}

export const PortfolioItemContentBase = ({
    filesAreUpdating,
    loading,
    pageTitle,
    portfolioItem,
    userId,
    withEditActions,
    isCurrentUserItem,
    children,
    onSubmit,
    setFilesAreUpdating,
}: IProps) => {
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [translate] = useTranslation();
    const [updatePortfolioItem] = useUpdatePortfolioItemMutation();
    const userPortfolioItemView = !!useRouteMatch('USER_PORTFOLIO_ITEM_VIEW');

    const { id, description } = portfolioItem || {};

    const handleSubmit = async (values: IUpdatePortfolioItemInput) => {
        try {
            await updatePortfolioItem({
                variables: { id, portfolioItem: values },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('portfolioItemActionSuccess.update'), {
            variant: 'success',
        });

        onSubmit?.();

        return;
    };

    let descriptionView;

    if (isCurrentUserItem) {
        const initialValues = { description };

        descriptionView = (
            <RelatedItemPortfolioItemForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        );
    } else {
        descriptionView = !!description && (
            <Box p={4}>
                <Text component="div">
                    <Box sx={{ lineHeight: '1.6' }}>{description}</Box>
                </Text>
            </Box>
        );
    }

    // Makes sure the title is mounted correctly
    const mountPageTitle = !!portfolioItem && !loading;

    const showFileTable =
        !portfolioItem ||
        (withEditActions && portfolioItem) ||
        (!withEditActions && !!portfolioItem?.files?.length);

    return (
        <>
            {mountPageTitle && (
                <PageTitle
                    mixpanelTitle={`Portfolio external content portfolio item drawer - ${
                        portfolioItem
                            ? userPortfolioItemView
                                ? 'View'
                                : 'Edit'
                            : 'New'
                    } portfolio item`}
                >
                    {`${portfolioItem.title}${
                        pageTitle ? `- ${pageTitle}` : ''
                    }`}
                </PageTitle>
            )}

            <Stack divider={<Divider />}>
                <PortfolioItemDetailsView
                    portfolioItem={portfolioItem}
                    userId={userId}
                />

                {children}

                {descriptionView}

                {showFileTable && (
                    <PortfolioItemFileTable
                        filesAreUpdating={filesAreUpdating}
                        portfolioItem={portfolioItem}
                        setFilesAreUpdating={setFilesAreUpdating}
                        userId={userId}
                        withEditActions={withEditActions}
                    />
                )}
            </Stack>
        </>
    );
};
