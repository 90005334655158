import { useMemo } from 'react';

import {
    IContentTypeValue,
    IPortfolioItemMatrixListItemFragment,
    useTrainingMatrixModuleGroupsQuery,
    useTrainingPortfolioReportsQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

const MATRIX_MODULE_TYPES = [
    'CourseModule',
    'ScormModule',
    'LTIModule',
    'AssignmentModule',
    'CertificateModule',
    'ExternalContentModule',
] as const;

type MatrixModuleType = (typeof MATRIX_MODULE_TYPES)[number];

export const useTrainingParticipantReports = (
    trainingId: string,
    userIds: string[],
    ignoreModuleTypes: MatrixModuleType[] = []
) => {
    const { showApolloError } = useApolloError();

    const { data: moduleGroupsData, loading: loadingModuleGroups } =
        useTrainingMatrixModuleGroupsQuery({
            variables: {
                id: trainingId,
            },
            onError: showApolloError,
        });

    const { data: reportsData, loading: loadingReports } =
        useTrainingPortfolioReportsQuery({
            variables: {
                trainingId: trainingId,
                userIds: userIds,
            },
            fetchPolicy: 'network-only',
            onError: showApolloError,
        });

    const loading = loadingModuleGroups || loadingReports;

    const moduleGroups = useMemo(() => {
        if (loading || !moduleGroupsData?.training?.moduleGroups) {
            return [];
        }

        return moduleGroupsData.training.moduleGroups
            .map((group) => ({
                ...group,
                modules: group.modules.filter((module) => {
                    const type = module.__typename as MatrixModuleType;

                    // Check if module type is not in ignore list and is in matrix module types
                    return (
                        !ignoreModuleTypes.includes(type) &&
                        MATRIX_MODULE_TYPES.includes(type)
                    );
                }),
            }))
            .filter((group) => group.modules.length > 0);
    }, [moduleGroupsData, loading]);

    const moduleList = useMemo(
        () => moduleGroups?.flatMap((group) => group.modules) || [],
        [moduleGroups]
    );

    const trainingReports: IPortfolioItemMatrixListItemFragment[] =
        reportsData?.trainingPortfolioReports || [];

    const participantReports = useMemo<
        Map<string, Record<string, IPortfolioItemMatrixListItemFragment>>
    >(() => {
        if (!userIds.length || !moduleList.length) return new Map();

        // Return map of report data per user
        return new Map<
            string,
            Record<string, IPortfolioItemMatrixListItemFragment>
        >(
            userIds.map((id) => {
                const reportMap = new Map<
                    string,
                    IPortfolioItemMatrixListItemFragment
                >(
                    trainingReports
                        .filter((report) => report.user.id === id)
                        .map((report) => {
                            let relatedId = report.relatedId;

                            if (
                                report.relatedType ===
                                IContentTypeValue.ReceivedCertificate
                            ) {
                                relatedId = report.eventCertificateId;
                            }

                            return [relatedId, report];
                        })
                );

                const moduleReports = moduleList.reduce<
                    Record<string, IPortfolioItemMatrixListItemFragment>
                >((acc, module) => {
                    let relatedId = module.id;

                    if (module.__typename === 'CourseModule') {
                        relatedId = module.course.id;
                    } else if (module.__typename === 'ScormModule') {
                        relatedId = module.scorm.id;
                    } else if (module.__typename === 'ExternalContentModule') {
                        relatedId = module.content.id;
                    }

                    const report = reportMap.get(relatedId);

                    if (!report) return acc;

                    acc[module.id] = report;

                    return acc;
                }, {});

                return [id, moduleReports];
            })
        );
    }, [moduleList, trainingReports, userIds]);

    return {
        loading,
        moduleGroups,
        participantReports,
        loadingModuleGroups,
        loadingReports,
    };
};
