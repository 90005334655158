import { useEffect, useMemo } from 'react';

import {
    ITrainingListItemFragment,
    ITrainingSortBy,
    ITrainingsSelectorQueryVariables,
    useTrainingsQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useTrainingFilter } from 'training/hooks/useTrainingFilter';
import { usePagination } from 'common/hooks/usePagination';

export const useTrainingList = (
    trainingQueryVariables?: ITrainingsSelectorQueryVariables,
    filterParamsInUrl = true,
    showArchiveFilter = false
) => {
    const { showApolloError } = useApolloError();

    const {
        filters,
        filterValues,
        filterValuesForQuery,
        handleSearch: onSearch,
        handleFilterChange: onFilterChange,
        loading: loadingFilters,
    } = useTrainingFilter(filterParamsInUrl, showArchiveFilter);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    // Build variables for query
    const variables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
            ...trainingQueryVariables,
            sortBy: ITrainingSortBy.Title,
        }),
        [paginationVariables, filterValuesForQuery, trainingQueryVariables]
    );

    const { loading: trainingsLoading, data } = useTrainingsQuery({
        variables,
        onError: showApolloError,
    });

    const trainingsObject = data?.paginatedTrainings;
    const trainingsCount = trainingsObject?.count;
    const trainings =
        (trainingsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as ITrainingListItemFragment[]) || [];

    const loading = trainingsLoading || loadingFilters;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(trainingsCount || 0);
    }, [trainingsCount, loading, initializePagination]);

    return {
        trainings,
        trainingsCount,
        loading,
        paginationSettings,
        filters,
        filterValues,
        onSearch,
        onFilterChange,
        setPage,
    };
};
