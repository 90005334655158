import { Box } from '@mui/material';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';

import { Button } from 'common/components/Button';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { FormSection } from 'common/components/FormSection';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { OfferFormFields } from 'offer/components/forms/formFields/OfferFormFields';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';
import { ImageField, Switch } from 'common/components/FormField';
import { Link } from 'common/components/Link';
import { Divider } from 'common/components/Divider';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Typography } from 'common/components/Typography';

export type TFormValues = {
    title?: string;
    titleColor?: string;
    description?: string;
    imageId?: string;
    publish: boolean;
    publishTraining: boolean;
    publishElearning: boolean;
    publishMeetingWebinar: boolean;
};

interface IProps extends FormikConfig<TFormValues> {
    disabled?: boolean;
    isDeleting?: boolean;
    loading?: boolean;
    onSubmit(values: TFormValues): void;
}

export const OffersOverviewForm = ({
    disabled,
    isDeleting,
    initialValues,
    loading,
    onSubmit,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [unpublishDialogOpen, setUnpublishDialogOpen] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        description: Yup.string().optional(),
    });

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const {
                    publishTraining: newPublishTraining,
                    publishElearning: newPublishElearning,
                    publishMeetingWebinar: newPublishMeetingWebinar,
                } = values;
                const {
                    publishTraining,
                    publishElearning,
                    publishMeetingWebinar,
                } = initialValues || {};

                // If any of the offer types are unpublished show the dialog
                if (
                    (publishTraining !== newPublishTraining &&
                        !newPublishTraining) ||
                    (publishElearning !== newPublishElearning &&
                        !newPublishElearning) ||
                    (publishMeetingWebinar !== newPublishMeetingWebinar &&
                        !newPublishMeetingWebinar)
                ) {
                    return setUnpublishDialogOpen(true);
                }

                return onSubmit(values);
            }}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={4}>
                    <Box mb={4}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                            {translate('offerSettingsDrawer.title')}
                        </Typography>

                        <Box mt={1}>
                            <Typography>
                                {translate('offerSettingsDrawer.text')}
                            </Typography>
                        </Box>
                    </Box>

                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting && !isDeleting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        <Box mb={1}>
                            <Field
                                withDefaults
                                acceptedFileTypes={HEADER_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 1440,
                                    height: 300,
                                })}
                                label={translate('headerImage')}
                                maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                                name="image"
                            />
                        </Box>

                        <OfferFormFields />

                        <Field
                            checked={values.publish}
                            component={Switch}
                            label={translate('offer.enabledOffer')}
                            name="publish"
                        />

                        <Divider sx={{ mt: 1.5 }} />

                        <FormSection
                            description={
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="offer.publishTabsDescription"
                                />
                            }
                            title={translate('offer.publishSettings')}
                        >
                            <Box mt={1}>
                                <Field
                                    checked={values.publishTraining}
                                    component={Switch}
                                    formControlProps={{ margin: 'none' }}
                                    label={translate('offer.enabledTrainings')}
                                    name="publishTraining"
                                />
                                <Field
                                    checked={values.publishElearning}
                                    component={Switch}
                                    formControlProps={{ margin: 'none' }}
                                    label={translate('offer.enabledElearnings')}
                                    name="publishElearning"
                                />
                                <Field
                                    checked={values.publishMeetingWebinar}
                                    component={Switch}
                                    formControlProps={{ margin: 'none' }}
                                    label={translate(
                                        'offer.enabledMeetingsWebinars'
                                    )}
                                    name="publishMeetingWebinar"
                                />
                            </Box>
                        </FormSection>
                        <Box mt={4}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting && !disabled && !dirty)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </Box>
                    </Form>

                    <ConfirmDialog
                        confirmText={translate('save')}
                        loading={loading}
                        open={unpublishDialogOpen}
                        title={translate('offer.offerTypeDisabledDialog.title')}
                        onCancel={() => {
                            setUnpublishDialogOpen(false);
                        }}
                        onClose={() => {
                            setUnpublishDialogOpen(false);
                        }}
                        onConfirm={async () => {
                            await onSubmit(values);

                            setUnpublishDialogOpen(false);
                        }}
                    >
                        {translate('offer.offerTypeDisabledDialog.text')}
                    </ConfirmDialog>
                </Box>
            )}
        </Formik>
    );
};
