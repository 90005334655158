import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { Loader } from 'common/components/Loader';
import { IScormListItemFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { useScormList } from 'training/hooks/useScormList';
import { ScormAddList } from 'training/components/ScormAddList';

interface IProps extends IPageDrawerProps {
    addedScorms: IScormListItemFragment[];
    selectedScorms: IScormListItemFragment[];
    onAddScorms(): void;
    onDrawerOpen(open: boolean): void;
    onRemoveScorm(scorm: IScormListItemFragment): void;
    onSelectScorm(scorm: IScormListItemFragment): void;
}

export const ScormSelectDrawer = ({
    addedScorms,
    selectedScorms,
    open,
    onAddScorms,
    onDrawerOpen,
    onRemoveScorm,
    onSelectScorm,
}: IProps) => {
    const { t: translate } = useTranslation();
    const {
        scorms,
        loading: scormsLoading,
        setPage,
        paginationSettings,
        onSearch,
    } = useScormList();

    const resetSettings = () => {
        handleSearch('');
        setPage(1);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('addScormsTitle')}
            onClose={() => {
                onDrawerOpen(false);
                resetSettings();
            }}
        >
            <>
                <Box
                    sx={{
                        pb: 22.5,
                        pt: { xs: 2, sm: 4 },
                        px: { xs: 2, sm: 4 },
                    }}
                >
                    <FilterBar
                        placeholder={translate('filterBarPlaceholder')}
                        onSearch={handleSearch}
                        onSearchClear={() => handleSearch('')}
                    />

                    {scormsLoading && <Loader />}

                    {open && !scormsLoading && (
                        <ScormAddList
                            addedScorms={addedScorms}
                            scorms={scorms}
                            selectedScorms={selectedScorms}
                            onAddScorm={onSelectScorm}
                            onRemoveScorm={(
                                selectedScorm: IScormListItemFragment
                            ) => onRemoveScorm(selectedScorm)}
                        />
                    )}
                </Box>

                <PageDrawerFooter>
                    <Box display="flex" flexDirection="column">
                        {paginationSettings.count > -1 && (
                            <Box mb={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={
                                        paginationSettings.count === 1
                                            ? translate('scormPackage')
                                            : translate('scormPackages')
                                    }
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        )}

                        <Button
                            color="primary"
                            disabled={!selectedScorms.length}
                            variant="contained"
                            onClick={() => {
                                onAddScorms();
                                resetSettings();
                            }}
                        >
                            {!selectedScorms.length
                                ? translate('addScormsTitle')
                                : translate('addScorms', {
                                      count: selectedScorms.length,
                                  })}
                        </Button>
                    </Box>
                </PageDrawerFooter>
            </>
        </PageDrawer>
    );
};
