import { useEditor } from 'slate-react';
import { useTranslation } from 'react-i18next';
import {
    ToolbarButton,
    ToolbarButtonProps,
    MediaEmbedPluginOptions,
    someNode,
} from '@udecode/slate-plugins';

import { addOrReplaceEmbed } from './utils';

type IProps = ToolbarButtonProps &
    MediaEmbedPluginOptions<'type'> &
    MediaEmbedPluginOptions<'rootProps'>;

export const ToolbarEmbed = ({ media_embed, ...other }: IProps) => {
    const editor = useEditor();
    const { t: translate } = useTranslation();

    if (!media_embed) return null;

    return (
        <ToolbarButton
            active={someNode(editor, { match: { type: media_embed.type } })}
            onMouseDown={(event) => {
                event.preventDefault();

                const msg = translate('editor.enterEmbedUrl');

                try {
                    addOrReplaceEmbed(editor, msg, { media_embed });
                } catch (e) {
                    window.alert(translate(e as string));
                }
            }}
            {...other}
        />
    );
};
