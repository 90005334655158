import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { TRouteName } from 'route/types';

interface IState {
    activeSectionRoute: TRouteName;
}

export const useTrainingRoutes = (): IState => {
    const participants = !!useRouteMatch('TRAINING_PARTICIPANTS');
    const trainers = !!useRouteMatch('TRAINING_TRAINERS');
    const sharedFiles = !!useRouteMatch('TRAINING_SHARED_FILES');

    const getActiveSectionRoute = (): TRouteName => {
        let activeSectionRoute = 'TRAINING';

        if (participants) {
            activeSectionRoute = 'TRAINING_PARTICIPANTS';
        }

        if (trainers) {
            activeSectionRoute = 'TRAINING_TRAINERS';
        }

        if (sharedFiles) {
            activeSectionRoute = 'TRAINING_SHARED_FILES';
        }

        return activeSectionRoute as TRouteName;
    };

    const activeSectionRoute = getActiveSectionRoute();

    return { activeSectionRoute };
};
