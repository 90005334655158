import { useRef } from 'react';
import { Box, Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import {
    mdiCog,
    mdiToggleSwitch,
    mdiToggleSwitchOff,
    mdiChevronLeft,
} from '@mdi/js';

import { ITrainingFragment, ITrainingRole } from 'graphql/types';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import { ContextActionBar } from 'common/components/ContextActionBar';
import { LinearProgress } from 'common/components/LinearProgress';
import { ProgressBox } from 'common/components/ProgressBox';
import { Button } from 'common/components/Button';
import { Chip, ChipGroup } from 'common/components/Chip';
import { TrainingPortfolioItemSubscriptionData } from 'training/subscriptionComponents/TrainingPortfolioItemSubscriptionData';
import { getUrl } from 'route/utils/getUrl';

interface IProps {
    training: ITrainingFragment;
    canUpdateTraining: boolean;
    showPreviewButton: boolean;
}

export const TrainingContextActionBar = ({
    training,
    canUpdateTraining,
    showPreviewButton,
}: IProps) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);
    const location = useLocation();
    const navigate = useNavigate();
    const backButtonFromLocationRef = useRef(location.state?.from);
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    const {
        id,
        conditions,
        isActive,
        rolesForCurrentUser,
        currentUserIsAuthor,
    } = training || {};

    const isParticipant = !!rolesForCurrentUser?.includes(
        ITrainingRole.Participant
    );
    const isTrainer = !!rolesForCurrentUser?.includes(ITrainingRole.Trainer);
    const isMentor = !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);
    const isGroupmanager = !!rolesForCurrentUser?.includes(
        ITrainingRole.Groupmanager
    );

    const trainerManagerChips = conditions ? (
        <ChipGroup sx={{ borderLeft: 1, borderColor: 'divider', pl: 1.5 }}>
            {!isActive && (
                <Chip color="warning" label={translate('inactive')} />
            )}

            {isTrainer && <Chip color="default" label={translate('trainer')} />}
            {isMentor && <Chip color="default" label={translate('mentor')} />}

            {currentUserIsAuthor && (
                <Chip color="default" label={translate('author')} />
            )}

            {isGroupmanager && <Chip label={translate('groupmanager')} />}

            {/* If user is author or groupmanager and participant we want to also
                show participant label */}
            {(currentUserIsAuthor || isGroupmanager) && isParticipant && (
                <Chip label={translate('participant')} />
            )}
        </ChipGroup>
    ) : null;

    return (
        <ContextActionBar>
            <Grid
                container
                alignItems="center"
                spacing={1.5}
                sx={{ flexWrap: 'nowrap' }}
            >
                <Grid item sx={{ flexShrink: 0, pr: 0.5 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            size="small"
                            startIcon={
                                <Icon path={mdiChevronLeft} size="1.9rem" />
                            }
                            variant="text"
                            onClick={() => {
                                if (backButtonFromLocationRef.current) {
                                    navigate(backButtonFromLocationRef.current);

                                    return;
                                }

                                navigate(
                                    getUrl(
                                        canUpdateTraining
                                            ? 'TRAINING_MANAGEMENT_LIST'
                                            : 'HOME'
                                    )
                                );
                            }}
                        >
                            {translate('back')}
                        </Button>
                    </Box>
                </Grid>
                {trainerManagerChips && (
                    <Grid item sx={{ flexShrink: 0 }}>
                        {trainerManagerChips}
                    </Grid>
                )}
                <TrainingPortfolioItemSubscriptionData trainingId={id}>
                    {({ progress, score, scoreThreshold }) => {
                        if (!progress) return;

                        const showScore =
                            // Training is completed 1 = 100%
                            progress === 1 &&
                            // Score is "set"
                            score !== undefined &&
                            score !== null &&
                            // If "not" "score is 0 and not passThreshold" then we want to show score
                            !(score === 0 && !scoreThreshold);

                        let content = null;

                        if (showScore) {
                            content = (
                                <ChipGroup>
                                    <Chip
                                        bgColor={
                                            !scoreThreshold ||
                                            score >= scoreThreshold
                                                ? 'success'
                                                : 'error'
                                        }
                                        label={`${Math.round(score * 100)}%`}
                                    />
                                </ChipGroup>
                            );
                        } else {
                            content = (
                                <ProgressBox
                                    sx={{
                                        flex: 1,
                                        minWidth: '100px',
                                        maxWidth: '200px',
                                    }}
                                >
                                    <LinearProgress
                                        withProgressText
                                        color="primary"
                                        value={progress * 100}
                                    />
                                </ProgressBox>
                            );
                        }

                        return (
                            <Grid
                                item
                                xs
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {content}
                            </Grid>
                        );
                    }}
                </TrainingPortfolioItemSubscriptionData>
                {canUpdateTraining && (
                    <Grid item sx={{ ml: 'auto', flexShrink: 0 }}>
                        <Stack direction="row" spacing={1}>
                            {showPreviewButton && (
                                <Button
                                    component={Link}
                                    size="small"
                                    startIcon={
                                        <Icon
                                            path={
                                                studentView
                                                    ? mdiToggleSwitch
                                                    : mdiToggleSwitchOff
                                            }
                                            size="1.9rem"
                                        />
                                    }
                                    sx={{
                                        minWidth: 0,
                                        '.MuiButton-icon': {
                                            mr: -1,
                                            color: studentView
                                                ? 'primary.main'
                                                : undefined,
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={() => {
                                        trainingStudentView(!studentView);
                                    }}
                                >
                                    {!smallScreen && translate('preview')}
                                </Button>
                            )}
                            <Button
                                component={Link}
                                params={{ trainingId: id }}
                                size="small"
                                startIcon={<Icon path={mdiCog} size="1.9rem" />}
                                to="TRAINING_EDIT"
                                variant="outlined"
                            >
                                {!smallScreen && translate('trainingSettings')}
                            </Button>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </ContextActionBar>
    );
};
