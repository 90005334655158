import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiFileRemove } from '@mdi/js';
import { useRef } from 'react';
import { useFormikContext } from 'formik';

import HEADER_IMAGE from 'assets/images/organisation-fonts-drawer-header.webp';
import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { Typography } from 'common/components/Typography';
import { CustomFontTable } from 'organisation/tables';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import {
    CustomFontsDocument,
    IFileFragment,
    useCreateCustomFontsMutation,
    useCustomFontsQuery,
    useDeleteCustomFontsMutation,
    useUpdateOrganisationThemeMutation,
    IThemeTypographyInput,
    IFontSize,
} from 'graphql/types';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import { Loader } from 'common/components/Loader';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getDefaultFont } from 'common/utils/fonts';
import { FONT_WEIGHTS } from 'common/constants/fonts';
import { useApolloError } from 'common/hooks/useApolloError';

import { TOrganisationSettingsFormValues } from './OrganisationSettingsForm';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const CustomFontDrawer = ({ onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const { data: customFontsData, loading: customFontsLoading } =
        useCustomFontsQuery();
    const [createCustomFonts, { loading: loadingCreate }] =
        useCreateCustomFontsMutation();
    const [deleteCustomFonts, { loading: loadingDelete }] =
        useDeleteCustomFontsMutation();
    const [updateOrganisationTheme, { loading: loadingUpdateTheme }] =
        useUpdateOrganisationThemeMutation();
    const uploadingFileRefs = useRef<string[]>([]);
    const { values, initialValues, setFieldValue } =
        useFormikContext<TOrganisationSettingsFormValues>();

    const { organisation } = customFontsData || {};
    const { typography } = organisation?.theme || {};
    const { customFonts } = typography || {};

    const fileTableActions = (
        showDeleteDialog: () => void,
        hasSelection: boolean
    ) => (
        <Tooltip title={translate<string>('delete')}>
            <Box>
                <IconButton
                    color="inherit"
                    disabled={!hasSelection}
                    iconPath={mdiFileRemove}
                    iconSize="2.4rem"
                    onClick={showDeleteDialog}
                />
            </Box>
        </Tooltip>
    );

    const handleUploadFinish = async (
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!customFonts) return;

        try {
            const result = await createCustomFonts({
                variables: { fileIds: uploadingFileRefs.current },
                update: (cache, result) => {
                    const { customFonts: newCustomFonts } =
                        result.data?.createCustomFonts || {};

                    if (!organisation || !typography) return;

                    cache.modify({
                        id: cache.identify(organisation),
                        fields: {
                            theme() {
                                return {
                                    ...organisation.theme,
                                    typography: {
                                        ...typography,
                                        customFonts: [
                                            ...(customFonts || []),
                                            ...(newCustomFonts || []),
                                        ],
                                    },
                                };
                            },
                        },
                    });
                },
            });

            displaySnackbar(translate('customFontDrawer.fontsUploaded'), {
                autoHideDuration: 30000,
                variant: 'success',
            });

            uploadingFileRefs.current = [];

            const { customFonts: newCustomFonts } =
                result.data?.createCustomFonts || {};
            const customFontIds =
                newCustomFonts
                    ?.map((customFont) => customFont?.id)
                    .filter(Boolean) || [];

            filesUpdateCallback(customFontIds);
        } catch (error) {
            showApolloError(error);

            return;
        }
    };

    const handleRemoveFonts = async (
        fontIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!fontIds.length) return;

        const { body, title, subtitle } = initialValues.typography || {};

        const selectedCustomFontNames =
            customFonts
                ?.filter((customFont) => fontIds.includes(customFont.id))
                .map((customFont) => customFont.family) || [];

        const newCustomFonts =
            customFonts?.filter(
                (customFont) => !fontIds.includes(customFont.id)
            ) || [];

        let newTypography: IThemeTypographyInput | undefined;

        // Check if there are any deleted custom fonts in use to reset them to the default
        if (selectedCustomFontNames.length) {
            const bodyIsCustom = selectedCustomFontNames.includes(
                body?.fontFamily || ''
            );
            const titleIsCustom = selectedCustomFontNames.includes(
                title?.fontFamily || ''
            );
            const subtitleIsCustom = selectedCustomFontNames.includes(
                subtitle?.fontFamily || ''
            );

            // If deleted custom fonts are in use
            if (bodyIsCustom || titleIsCustom || subtitleIsCustom) {
                const defaultBoldFont = getDefaultFont(true);

                newTypography = {
                    ...values.typography,
                    ...{
                        body: bodyIsCustom ? getDefaultFont() : undefined,
                        title: titleIsCustom ? defaultBoldFont : undefined,
                        subtitle: subtitleIsCustom
                            ? defaultBoldFont
                            : undefined,
                    },
                };
            }
        }

        try {
            await deleteCustomFonts({
                variables: {
                    customFontIds: fontIds,
                },
                update(cache) {
                    if (!organisation || !typography) return;

                    cache.writeQuery({
                        query: CustomFontsDocument,
                        data: {
                            organisation: {
                                ...organisation,
                                theme: {
                                    ...organisation.theme,
                                    typography: {
                                        ...typography,
                                        customFonts: newCustomFonts,
                                    },
                                },
                            },
                        },
                    });
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        // When custom fonts are in use which are deleted, update the current typography
        if (newTypography) {
            try {
                const result = await updateOrganisationTheme({
                    variables: { theme: { typography: newTypography } },
                    update: (cache, result) => {
                        const { theme } =
                            result.data?.updateOrganisationTheme || {};

                        if (!organisation) return;

                        cache.modify({
                            id: cache.identify(organisation),
                            fields: {
                                theme() {
                                    return { ...theme };
                                },
                            },
                        });
                    },
                });

                const { typography } =
                    result.data?.updateOrganisationTheme?.theme || {};
                const { body, title, subtitle } = typography || {};

                // Update typography in form
                setFieldValue('typography', {
                    body: {
                        fontFamily: body?.fontFamily,
                        size: body?.size || IFontSize.Md,
                        weight: body?.weight
                            ? FONT_WEIGHTS[body.weight]
                            : undefined,
                    },

                    title: {
                        fontFamily: title?.fontFamily,
                        size: title?.size || IFontSize.Md,
                        weight: title?.weight
                            ? FONT_WEIGHTS[title.weight]
                            : undefined,
                    },

                    subtitle: {
                        fontFamily: subtitle?.fontFamily,
                        size: subtitle?.size || IFontSize.Md,
                        weight: subtitle?.weight
                            ? FONT_WEIGHTS[subtitle.weight]
                            : undefined,
                    },
                });
            } catch (error) {
                showApolloError(error);

                return;
            }
        }

        filesUpdateCallback(fontIds);

        displaySnackbar(translate('customFontDrawer.fontsRemoved'), {
            autoHideDuration: 30000,
            variant: 'success',
        });
    };

    const handleAddFile = async (uploadedFile: IFileFragment) => {
        uploadingFileRefs.current.push(uploadedFile.id);
    };

    const isMutating = loadingCreate || loadingDelete || loadingUpdateTheme;

    return (
        <PageDrawer {...other} disableClose={isMutating} onClose={onClose}>
            {customFontsLoading && <Loader />}

            {!customFontsLoading && (
                <>
                    <DrawerHeaderImage src={HEADER_IMAGE} />

                    <Box p={{ xs: 2, sm: 4 }}>
                        <Box mb={2}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h3"
                            >
                                {translate('customFontDrawer.title')}
                            </Typography>

                            <Box mt={1}>
                                <Typography>
                                    {translate('customFontDrawer.description')}
                                </Typography>
                            </Box>
                        </Box>

                        <CustomFontTable
                            customFonts={customFonts || []}
                            id="customFonts"
                            renderSelectedActions={fileTableActions}
                            title="customFontDrawer.addNewCustomFont"
                            onAdd={handleAddFile}
                            onRemove={handleRemoveFonts}
                            onUploadFinish={handleUploadFinish}
                        />
                    </Box>
                </>
            )}
        </PageDrawer>
    );
};
