import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Loader } from 'common/components/Loader';
import { Button } from 'common/components/Button';
import { ICourseListItemFragment } from 'graphql/types';
import { ContentContainer } from 'common/components/ContentContainer';
import { useCourseSearch } from 'training/hooks/useCourseSearch';
import { useCourseList } from 'training/hooks/useCourseList';
import { CourseAddList } from 'training/components/CourseAddList';
import { useCourseQueryVariables } from 'training/hooks/useCourseQueryVariables';
import { usePagination } from 'common/hooks/usePagination';
import { Pagination } from 'common/components/Pagination';
import { FilterBar } from 'common/components/FilterBar';
import { PageTitle } from 'common/components/PageTitle';
import { TFilterBarItem } from 'common/types';

interface IProps {
    loadingDeeplink?: boolean;
    onSelectCourses(selectedCourses: ICourseListItemFragment[]): void;
}

export const LtiDeepLinkCourses = ({
    loadingDeeplink,
    onSelectCourses,
}: IProps) => {
    const [translate] = useTranslation();
    const [selectedCourses, setSelectedCourses] = useState<
        ICourseListItemFragment[]
    >([]);

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useCourseSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { courseQueryVariables } = useCourseQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const { courses, loading, coursesCount } =
        useCourseList(courseQueryVariables);

    const handleSelectCourse = (course: ICourseListItemFragment) => {
        const newCourses = [...selectedCourses, course];

        setSelectedCourses(newCourses);
    };

    const handleRemoveCourse = (course: ICourseListItemFragment) => {
        const newSelectedCourses = selectedCourses.filter(
            (selectedCourse) => selectedCourse.id !== course.id
        );

        setSelectedCourses(newSelectedCourses);
    };

    const resetSettings = () => {
        handleSearch('');
        handleFilterSelect([]);
        setPage(1);
    };

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(coursesCount || 0);
    }, [coursesCount, loading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    return (
        <>
            <PageTitle mixpanelTitle="LTI Deeplink courses">
                {translate('lTIDeeplinkPage.coursesPageTitle')}
            </PageTitle>

            {filtersLoading ? (
                <Loader />
            ) : (
                <>
                    <Box pb={22.5} pt={4}>
                        <FilterBar
                            filters={filters}
                            initialSearchValue={searchQueryParam}
                            initialSelected={selectedFilters}
                            placeholder={translate('filterBarPlaceholder')}
                            onSearch={handleSearch}
                            onSearchClear={() => handleSearch('')}
                            onSelect={handleFilterSelect}
                        />

                        {!loading && (
                            <CourseAddList
                                addedCourses={selectedCourses}
                                courses={courses}
                                selectedCourses={selectedCourses}
                                onAddCourse={handleSelectCourse}
                                onRemoveCourse={(
                                    selectedCourse: ICourseListItemFragment
                                ) => handleRemoveCourse(selectedCourse)}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            backgroundColor: 'grey.100',
                            p: 2,
                        }}
                    >
                        <ContentContainer maxWidth="md" sx={{ pb: 0 }}>
                            {paginationSettings.count > -1 && (
                                <Box mb={2}>
                                    <Pagination
                                        page={paginationSettings.page}
                                        pageAmount={
                                            paginationSettings.pageAmount
                                        }
                                        totalsAmount={paginationSettings.count}
                                        totalsText={
                                            paginationSettings.count === 1
                                                ? translate('course')
                                                : translate('courses')
                                        }
                                        onChange={(page: number) => {
                                            setPage(page);
                                        }}
                                    />
                                </Box>
                            )}

                            <Button
                                color="primary"
                                disabled={!selectedCourses.length}
                                loading={loadingDeeplink}
                                sx={{ width: '100%' }}
                                variant="contained"
                                onClick={() => {
                                    onSelectCourses(selectedCourses);

                                    resetSettings();
                                }}
                            >
                                {!selectedCourses.length
                                    ? translate('addCoursesTitle')
                                    : translate('addCourses', {
                                          count: selectedCourses.length,
                                      })}
                            </Button>
                        </ContentContainer>
                    </Box>
                </>
            )}
        </>
    );
};
