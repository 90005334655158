import { Box } from '@mui/material';
import {
    DatePicker as MuiDatePicker,
    DatePickerProps,
} from 'formik-mui-x-date-pickers';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'formik';

import { getDateFormat } from 'common/utils/getDateFormat';

const DatePickerTabs = () => (
    <>
        <Box
            sx={{
                backgroundColor: 'primary.main',
                height: 5,
            }}
        />
    </>
);

interface IProps extends DatePickerProps, FieldProps {
    onChange?: (name: string, value: Date | null) => void;
}

export const DatePicker = ({ onChange, ...other }: IProps) => {
    const dateFormat = getDateFormat();
    const { t: translate } = useTranslation();
    const {
        field,
        form: { setFieldValue },
    } = other;

    const handleChange = (value: Date | null) => {
        onChange?.(field.name, value);
        setFieldValue(field.name, value);
    };

    return (
        <MuiDatePicker
            components={{ Tabs: DatePickerTabs }}
            hideTabs={false}
            inputFormat={dateFormat}
            inputVariant="outlined"
            invalidDateMessage={translate('invalidDateMessage')}
            invalidLabel={translate('invalidDateMessage')}
            maxDateMessage={translate('maxDateMessage')}
            minDateMessage={translate('minDateMessage')}
            okLabel={translate('ok')}
            orientation="portrait"
            textField={{ fullWidth: true, margin: 'normal' }}
            variant="dialog"
            onChange={handleChange}
            {...other}
        />
    );
};
