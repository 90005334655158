import { ILanguage } from 'graphql/types';

import enJson from './en.json';
import nlJson from './nl.json';
import csJson from './cs.json';
import deJson from './de.json';
import esJson from './es.json';
import frJson from './fr.json';
import plJson from './pl.json';

export type TTranslation = typeof nlJson;

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]> | undefined;
};

function parseLanguageJSON(json: RecursivePartial<TTranslation>) {
    const translation = JSON.parse(JSON.stringify(json));

    return { translation };
}

export const translations = {
    [ILanguage.En]: parseLanguageJSON(enJson),
    [ILanguage.Nl]: parseLanguageJSON(nlJson),
    [ILanguage.Cs]: parseLanguageJSON(csJson),
    [ILanguage.De]: parseLanguageJSON(deJson),
    [ILanguage.Es]: parseLanguageJSON(esJson),
    [ILanguage.Fr]: parseLanguageJSON(frJson),
    [ILanguage.Pl]: parseLanguageJSON(plJson),
};
