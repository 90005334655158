import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';

import { BoxLoader } from 'common/components/Loader';
import { useVideoList } from 'video/hooks';
import { TableCell, TableHead } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';

import { VideosManagementTableRow } from './VideosManagementTableRow';

export const VideosManagementTable = () => {
    const [translate] = useTranslation();

    const {
        videos,
        loading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useVideoList();

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <>
            <FilterBar
                useFilterDrawer
                disabled={loading}
                filterDrawerProps={{
                    title: translate('filterForm.videos.title'),
                    description: translate('filterForm.videos.description'),
                }}
                formFilters={filters}
                formFilterValues={filterValues}
                initialSearchValue={filterValues.q}
                placeholder={translate('filterBarPlaceholder')}
                onFiltersChange={(values) => {
                    setPage(1);

                    onFilterChange?.(values);
                }}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />

            {loading ? (
                <BoxLoader />
            ) : (
                <>
                    <Box sx={{ mt: 2 }}>
                        {!videos.length ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={7}
                            >
                                <Typography>
                                    {translate('noOptionsText.videos')}
                                </Typography>
                            </Box>
                        ) : (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                {translate('title')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translate('creationDate')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {videos?.map((video) => (
                                            <VideosManagementTableRow
                                                key={video.id}
                                                video={video}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Pagination
                            page={paginationSettings.page}
                            pageAmount={paginationSettings.pageAmount}
                            totalsAmount={paginationSettings.count}
                            totalsText={
                                paginationSettings.count === 1
                                    ? translate('video')
                                    : translate('videos')
                            }
                            onChange={(page: number) => {
                                setPage(page);
                            }}
                        />
                    </Box>
                </>
            )}
        </>
    );
};
