import { useTranslation } from 'react-i18next';

import { PageTitle } from 'common/components/PageTitle';
import { useCurrentUserQuery } from 'graphql/types';
import { IUserProfileSectionProps } from 'user/types';
import { PortfolioOfferTable } from 'user/components/PortfolioOfferTable';

export const PortfolioMeetings = ({ name, id }: IUserProfileSectionProps) => {
    const { data } = useCurrentUserQuery();
    const [translate] = useTranslation();

    const userId = id || data?.currentUser?.id;

    return (
        <>
            <PageTitle>{`${translate('portfolioOffersPage.pageTitle')} - ${name}`}</PageTitle>

            <PortfolioOfferTable isMeetingType userId={userId} />
        </>
    );
};
