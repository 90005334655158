import { mdiCheckBold, mdiCloseCircle } from '@mdi/js';
import { Box } from '@mui/material';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

interface IProps {
    feature: string;
    check?: boolean;
}

export const PlanFeature = ({ check, feature }: IProps) => (
    <Box
        key={feature}
        sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
        }}
    >
        <Box
            sx={{
                display: 'flex',
                mr: 1,
                color: check ? 'primary.main' : 'error.main',
            }}
        >
            <Icon path={check ? mdiCheckBold : mdiCloseCircle} size="2.2rem" />
        </Box>
        <Typography>{feature}</Typography>
    </Box>
);
