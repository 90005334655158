import { Table, TableContainer, TableRow, TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { AddButton } from 'common/components/Button/AddButton';
import { TableCell } from 'common/components/Table';
import { Divider } from 'common/components/Divider';
import { Loader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';
import { IExtraCategoryFragment, useExtraCategoriesQuery } from 'graphql/types';
import {
    getExtraCategoryTypeTranslation,
    getExtraCategoryModelTypeTranslation,
} from 'organisation/utils/extraCategory';
import { Chip, ChipGroup } from 'common/components/Chip';
import { useApolloError } from 'common/hooks/useApolloError';

import { ExtraCategoryUpdateDrawer } from './ExtraCategoryUpdateDrawer';
import { ExtraCategoryCreateDrawer } from './ExtraCategoryCreateDrawer';

export const ExtraCategoryManager = () => {
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const { data, loading } = useExtraCategoriesQuery({
        variables: { editable: true },
        onError: showApolloError,
    });
    const [activeExtraCategory, setActiveExtraCategory] = useState<
        IExtraCategoryFragment | undefined
    >();
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const { extraCategories } = data || {};

    const hasExtraCategories = !!extraCategories?.length;

    return (
        <Box>
            {!loading && (
                <AddButton
                    onClick={() => {
                        setCreateDrawerOpen(true);
                    }}
                >
                    <Typography>
                        {translate('extraCategoriesDrawer.extraCategory')}
                    </Typography>
                </AddButton>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '200px' }}>
                    <Loader />
                </Box>
            )}

            {hasExtraCategories && (
                <>
                    <Box sx={{ mt: 2 }}>
                        <Divider />
                    </Box>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                {extraCategories.map((extraCategory) => {
                                    const {
                                        name,
                                        categoryType,
                                        forModelTypes,
                                    } = extraCategory;

                                    const modelTypeChips = forModelTypes
                                        .map((modelType) => {
                                            if (!modelType.type) return;

                                            return (
                                                <Chip
                                                    key={`${name}-${modelType.type}`}
                                                    label={getExtraCategoryModelTypeTranslation(
                                                        modelType.type
                                                    )}
                                                />
                                            );
                                        })
                                        .filter(Boolean);

                                    return (
                                        <TableRow
                                            key={`extraCategory-${extraCategory.id}`}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                setActiveExtraCategory(
                                                    extraCategory
                                                )
                                            }
                                        >
                                            <TableCell>
                                                {name}

                                                <Box
                                                    sx={{
                                                        color: 'grey.500',
                                                        mt: '2px',
                                                    }}
                                                >
                                                    {getExtraCategoryTypeTranslation(
                                                        categoryType
                                                    )}
                                                </Box>
                                            </TableCell>

                                            {!!modelTypeChips.length && (
                                                <TableCell>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'right',
                                                        }}
                                                    >
                                                        <ChipGroup>
                                                            {modelTypeChips}
                                                        </ChipGroup>
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <ExtraCategoryUpdateDrawer
                        extraCategory={activeExtraCategory}
                        open={!!activeExtraCategory}
                        onClose={() => {
                            if (!activeExtraCategory) return;

                            setActiveExtraCategory(undefined);
                        }}
                    />
                </>
            )}

            <ExtraCategoryCreateDrawer
                open={createDrawerOpen}
                onClose={() => {
                    setCreateDrawerOpen(false);
                }}
            />
        </Box>
    );
};
