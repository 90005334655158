import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { ManagementSettingsDrawers } from 'organisation/components/ManagementSettingsDrawers';
import { useCurrentUserQuery } from 'graphql/types';
import { AppBar } from 'common/components/AppBar';
import { UserProfileDrawer } from 'user/components/UserProfileDrawer';
import { Snackbar } from 'common/components/Snackbar';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { WelcomeDrawer } from 'common/components/WelcomeDrawer';
import { HelmetBase } from 'common/components/HelmetBase';
import { TrainingCreateDrawer } from 'training/components/TrainingDrawer';
import { ContentContainer } from 'common/components/ContentContainer';
import { OrganisationFooter } from 'organisation/components/OrganisationFooter';
import { AcademyManagementDrawer } from 'common/components/AcademyManagementDrawer';
import { useSnackbar } from 'common/hooks/useSnackbar';

interface IProps {
    contentContainerMaxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    header?: React.ReactNode;
    contextActionBar?: React.ReactNode;
    children?: React.ReactNode;
    showNavigation?: boolean;
}

export const BasePage = ({
    children,
    contentContainerMaxWidth,
    header,
    contextActionBar,
    showNavigation,
}: IProps) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [displaySnackbar] = useSnackbar();

    const { data: userData } = useCurrentUserQuery();

    const profileDrawerOpen = useHashMatch('PROFILE_DRAWER');
    const trainingCreateDrawerOpen = useHashMatch('TRAINING_CREATE');

    const { currentUser } = userData || {};

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const showAddedUsersMessage = !!searchParams.get('added_users');

        if (!showAddedUsersMessage) return;

        displaySnackbar(translate('addedUsers'), {
            variant: 'success',
        });

        searchParams.delete('added_users');

        // Replace current route to the same without added_users param
        navigate(`${location.pathname}?${searchParams}`, {
            replace: true,
        });
    }, [location.search]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <HelmetBase />

            <WelcomeDrawer />

            <AppBar showNavigation={showNavigation} />

            {contextActionBar}
            {header}
            {!!children && (
                <ContentContainer maxWidth={contentContainerMaxWidth || 'md'}>
                    {children}
                </ContentContainer>
            )}

            {currentUser && (
                <UserProfileDrawer
                    open={profileDrawerOpen}
                    userId={currentUser.id}
                />
            )}

            <TrainingCreateDrawer open={trainingCreateDrawerOpen} />

            <ManagementSettingsDrawers />

            <Snackbar
                message={translate('updated')}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
            />

            <OrganisationFooter />

            <AcademyManagementDrawer />
        </Box>
    );
};
