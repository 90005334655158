import { useTranslation } from 'react-i18next';
import { Box, MenuItem } from '@mui/material';
import { mdiPlus, mdiAccountRemove } from '@mdi/js';
import { useState } from 'react';
import { useApolloClient } from '@apollo/client';

import {
    ITrainingSectionProps,
    ITrainingTrainerListItem,
} from 'training/types';
import {
    IContentTypeValue,
    ITrainingRole,
    IUserListItemFragment,
    useAddUsersToTrainingMutation,
    useRemoveUsersFromTrainingMutation,
} from 'graphql/types';
import { Icon } from 'common/components/Icon';
import { PageTitle } from 'common/components/PageTitle';
import { Tooltip } from 'common/components/Tooltip';
import { useFrontendPermissions } from 'user/hooks';
import { ActionButton } from 'common/components/ActionButton';
import { TrainerSelectDrawer } from 'user/components/TrainerSelectDrawer';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { TrainingTrainerTable } from 'training/components/TrainingTrainerTable';
import { useTrainingTrainerList } from 'training/hooks/useTrainingTrainerList';
import {
    IFilterFormItem,
    IPaginationProps,
    ITableSelectionProps,
    TFilterFormValue,
    TFilterFormValues,
} from 'common/types';
import { useSelection } from 'common/components/Table';
import { Switch } from 'common/components/Switch';

export interface ITableDataProps {
    paginationProps: IPaginationProps;
    searchProps: {
        filters: IFilterFormItem<TFilterFormValue>[];
        filterValues: TFilterFormValues;
        searchQueryParam: string;
        onSearch(searchValue: string): void;
        onFilterChange(values?: TFilterFormValues): void;
    };
    selectionProps: ITableSelectionProps<ITrainingTrainerListItem>;
}

type TTrainerRole = ITrainingRole.Trainer | ITrainingRole.Mentor;
interface IDeleteDialog {
    open: boolean;
    trainerIds: string[];
}

export const Trainers = ({ training }: ITrainingSectionProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const { canUpdate } = useFrontendPermissions('training');
    const [sendInviteMail, setSendInviteMail] = useState(true);
    const client = useApolloClient();

    const canUpdateTraining = canUpdate || training.currentUserIsAuthor;

    const [trainerDrawerOpen, setTrainerDrawerOpen] = useState(false);
    const [trainerType, setTrainerType] = useState<TTrainerRole>();
    const [deleteDialog, setDeleteDialog] = useState<IDeleteDialog>({
        open: false,
        trainerIds: [],
    });
    const [newDropdownOpen, setNewDropdownOpen] = useState(false);
    const [addUsersToTraining, { loading: addUsersToTrainingLoading }] =
        useAddUsersToTrainingMutation();
    const [
        deleteUsersFromTraining,
        { loading: deleteUsersFromTrainingLoading },
    ] = useRemoveUsersFromTrainingMutation();

    const {
        trainers,
        paginationSettings,
        loading: trainersLoading,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useTrainingTrainerList(training.id);

    const { ...selectionProps } = useSelection(trainers);

    const { id } = training;

    const handleAddTrainers = async (
        selectedTrainerIds: string[],
        selectableTrainers?: IUserListItemFragment[],
        sendInvites?: boolean
    ) => {
        try {
            const response = await addUsersToTraining({
                variables: {
                    trainingId: id,
                    userIds: selectedTrainerIds,
                    role: trainerType,
                    sendInvites,
                },
                update(cache) {
                    cache.modify({
                        id: cache.identify(training),
                        fields: {
                            trainers(_existingTrainers, { DELETE }) {
                                return DELETE;
                            },
                        },
                    });
                },
            });

            const { data } = response;
            const { addedIds, alreadyAddedIds, failedIds } =
                data?.addUsersToTraining || {};

            if (!!addedIds?.length) {
                displaySnackbar(
                    translate('trainingTrainers.trainersAddedSuccess'),
                    {
                        variant: 'success',
                    }
                );

                // Refetch the selector to make sure the correct users are selected
                client.cache.evict({ fieldName: 'trainers' });
                client.cache.gc();
            }

            if (!!alreadyAddedIds?.length && selectableTrainers?.length) {
                const alreadyAddedTrainers = selectableTrainers.filter(
                    (trainer) => alreadyAddedIds.includes(trainer.id)
                );

                const alreadyAddedTrainersNames = alreadyAddedTrainers
                    .map((trainer) => trainer.name)
                    .join(', ');

                displaySnackbar(
                    translate('trainingTrainers.trainersAlreadyAdded', {
                        trainers: alreadyAddedTrainersNames,
                        count: alreadyAddedTrainers.length,
                        interpolation: { escapeValue: false }, // Disable escaping values
                    }),
                    {
                        variant: 'error',
                    }
                );
            }

            if (failedIds?.length && !!selectableTrainers?.length) {
                const failedTrainers = selectableTrainers.filter((trainer) =>
                    failedIds.includes(trainer.id)
                );

                const failedTrainerNames = failedTrainers
                    .map((trainer) => trainer.name)
                    .join(', ');

                displaySnackbar(
                    translate('trainingTrainers.trainerAddedFailed', {
                        trainers: failedTrainerNames,
                        count: failedTrainers.length,
                        interpolation: { escapeValue: false }, // Disable escaping values
                    }),
                    {
                        variant: 'error',
                    }
                );
            }
        } catch (error) {
            showApolloError(error);
        }

        if (!sendInviteMail) setSendInviteMail(true);

        resetSettings();
        setTrainerDrawerOpen(false);
    };

    const handleRemoveTrainers = async () => {
        if (!trainers?.length || !deleteDialog.trainerIds.length) {
            return;
        }

        const selectedTrainerIds = deleteDialog.trainerIds;
        const selectedTrainers = trainers
            .filter((trainer) =>
                deleteDialog.trainerIds.find(
                    (trainerId) => trainer.id === trainerId
                )
            )
            .filter(Boolean);

        const hasMentors = selectedTrainers.some(
            (trainer) => trainer.trainingRole === ITrainingRole.Mentor
        );
        const hasTrainers = selectedTrainers.some(
            (trainer) => trainer.trainingRole === ITrainingRole.Trainer
        );

        const roles = [];
        if (hasTrainers) roles.push(ITrainingRole.Trainer);
        if (hasMentors) roles.push(ITrainingRole.Mentor);

        try {
            const response = await deleteUsersFromTraining({
                variables: {
                    trainingId: id,
                    userIds: selectedTrainerIds,
                    roles,
                },
                update(cache) {
                    cache.modify({
                        id: cache.identify(training),
                        fields: {
                            trainers(_existingTrainers, { DELETE }) {
                                return DELETE;
                            },
                        },
                    });
                },
            });

            const { data } = response;
            const { removedIds, alreadyRemovedIds, failedIds } =
                data?.removeUsersFromTraining || {};

            if (!!removedIds?.length) {
                displaySnackbar(
                    translate('trainingTrainers.trainersRemovedSuccess'),
                    {
                        variant: 'success',
                    }
                );

                // Refetch the selector to make sure the correct users are selected
                client.cache.evict({ fieldName: 'trainers' });
                client.cache.gc();
            }

            if (!!alreadyRemovedIds?.length && trainers?.length) {
                const alreadyRemovedTrainers = trainers.filter((trainer) =>
                    alreadyRemovedIds.includes(trainer.id)
                );

                const alreadyRemovedTrainerNames = alreadyRemovedTrainers
                    .map((trainer) => trainer.name)
                    .join(', ');

                displaySnackbar(
                    translate('trainingTrainers.trainersAlreadyRemoved', {
                        trainers: alreadyRemovedTrainerNames,
                        count: alreadyRemovedTrainers.length,
                        interpolation: { escapeValue: false }, // Disable escaping values
                    }),
                    {
                        variant: 'error',
                    }
                );
            }

            if (failedIds?.length && !!trainers?.length) {
                const failedTrainers = trainers.filter((trainer) =>
                    failedIds.includes(trainer.id)
                );

                const failedTrainerNames = failedTrainers
                    .map((trainer) => trainer.name)
                    .join(', ');

                displaySnackbar(
                    translate('trainingTrainers.trainerRemovedFailed', {
                        trainers: failedTrainerNames,
                        count: failedTrainers.length,
                        interpolation: { escapeValue: false }, // Disable escaping values
                    }),
                    {
                        variant: 'error',
                    }
                );
            }
        } catch (error) {
            showApolloError(error);
        }

        resetSettings();
        closeDeleteDialog();
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({ open: false, trainerIds: [] });
    };

    const resetSettings = () => {
        const { selected, onSelectAll } = selectionProps;

        setPage?.(1);
        onSearch('');
        onFilterChange();

        if (!!selected.length) onSelectAll();
    };

    const loading =
        trainersLoading ||
        addUsersToTrainingLoading ||
        deleteUsersFromTrainingLoading;

    const tableActions = canUpdateTraining
        ? [
              {
                  tooltipTitle: translate(
                      'trainingTrainers.deleteFromTraining'
                  ),
                  iconPath: mdiAccountRemove,
                  iconSize: '2.4rem',
                  color: 'inherit',
                  onClick: (selectedTrainerIds: string[]) => {
                      setDeleteDialog?.({
                          open: true,
                          trainerIds: selectedTrainerIds,
                      });
                  },
              },
          ]
        : [];

    const isMentorType = trainerType === ITrainingRole.Mentor;

    const tableDataProps: ITableDataProps = {
        paginationProps: { paginationSettings, setPage },
        searchProps: {
            filters,
            filterValues,
            onSearch,
            onFilterChange,
            searchQueryParam: filterValues.q,
        },
        selectionProps,
    };

    const selectDrawerFooterAppend = (
        <Box sx={{ mb: 2, textAlign: 'left' }}>
            <Switch
                checked={sendInviteMail}
                label={translate('sendUserTrainingInvite')}
                onChange={() => setSendInviteMail(!sendInviteMail)}
            />
        </Box>
    );

    return (
        <>
            <PageTitle mixpanelTitle="Trainers - Learning Journey">
                {`${translate('trainers')} - ${training.title}`}
            </PageTitle>

            {canUpdateTraining && (
                <Box display="flex" mb={1.5} mr={1}>
                    <Tooltip
                        placement="bottom"
                        title={
                            newDropdownOpen
                                ? undefined
                                : translate<string>(
                                      'trainingTrainers.addTrainersMentors'
                                  )
                        }
                    >
                        <Box>
                            <DropdownMenu
                                anchor={
                                    <ActionButton
                                        outlined
                                        size="medium"
                                        onClick={() => {
                                            setNewDropdownOpen(
                                                !newDropdownOpen
                                            );
                                        }}
                                    >
                                        <Icon path={mdiPlus} />
                                    </ActionButton>
                                }
                                placement="bottom-start"
                                onClose={() => setNewDropdownOpen(false)}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setTrainerType(ITrainingRole.Trainer);
                                        setTrainerDrawerOpen(true);
                                        setNewDropdownOpen(true);
                                    }}
                                >
                                    {translate(
                                        'trainingTrainers.addTrainingTrainer'
                                    )}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        setTrainerType(ITrainingRole.Mentor);
                                        setTrainerDrawerOpen(true);
                                        setNewDropdownOpen(true);
                                    }}
                                >
                                    {translate(
                                        'trainingTrainers.addTrainingMentor'
                                    )}
                                </MenuItem>
                            </DropdownMenu>
                        </Box>
                    </Tooltip>
                </Box>
            )}

            <TrainingTrainerTable
                isEditable={canUpdateTraining}
                loading={loading}
                tableActions={tableActions}
                tableDataProps={tableDataProps}
                trainers={trainers}
                training={training}
                {...tableDataProps}
            />

            <TrainerSelectDrawer
                addButtonTranslationKeys={
                    isMentorType
                        ? {
                              noSelection: 'addMentorsTitle',
                              selection: 'addMentors',
                          }
                        : undefined
                }
                footerAppend={selectDrawerFooterAppend}
                loading={loading}
                noResultsLabel={
                    isMentorType ? 'noOptionsText.mentors' : undefined
                }
                open={trainerDrawerOpen}
                paginationTranslationKey={isMentorType ? 'mentor' : undefined}
                selectorQueryVariables={{
                    contentType: IContentTypeValue.Training,
                    objectId: id,
                }}
                title={isMentorType ? translate('addMentorsTitle') : undefined}
                onAddTrainers={(
                    selectedTrainerIds: string[],
                    selectableTrainers?: IUserListItemFragment[]
                ) => {
                    handleAddTrainers(
                        selectedTrainerIds,
                        selectableTrainers,
                        sendInviteMail
                    );
                }}
                onDrawerOpen={setTrainerDrawerOpen}
            />

            <ConfirmDialog
                color="error"
                confirmText={translate('delete')}
                loading={deleteUsersFromTrainingLoading}
                open={deleteDialog.open}
                title={translate(
                    'trainingTrainers.deleteTrainingTrainerMessage.title'
                )}
                onCancel={closeDeleteDialog}
                onClose={closeDeleteDialog}
                onConfirm={handleRemoveTrainers}
            >
                {translate(
                    'trainingTrainers.deleteTrainingTrainerMessage.text'
                )}
            </ConfirmDialog>
        </>
    );
};
