import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
    ICertificateInputOptionFragment,
    ICreateCertificateModuleInput,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { CertificateSelectField } from 'training/components/CertificateSelectField';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';

export interface ICertificateModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateCertificateModuleInput, 'imageId' | 'offset'> {
    certificate?: ICertificateInputOptionFragment;
}

export interface IProps {
    initialValues?: Partial<ICertificateModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    onSubmit(values: ICertificateModuleFormValues): void;
    onDelete?(): void;
    moduleGroupId?: string;
    onDelete?(): void;
}

export const CertificateModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);

    const validationSchema = Yup.object().shape({
        certificate: Yup.object()
            .nullable()
            .required(translate('validate.required')),
    });

    const initValues: ICertificateModuleFormValues = {
        title: '',
        description: '',
        certificateId: '',
        certificate: undefined,
        ...initialValues,
    };

    const formTitle = edit
        ? translate('moduleForm.certificate.editTitle')
        : translate('moduleForm.certificate.newTitle');
    const formText = edit
        ? translate('moduleForm.certificate.editText')
        : translate('moduleForm.certificate.newText');
    const submitText = edit
        ? translate('moduleForm.certificate.editButton')
        : translate('moduleForm.certificate.newButton');

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        {formTitle && formText && (
                            <Box mb={4}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <CertificateSelectField name="certificate" />

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.course.overrideDescription"
                                        />
                                    }
                                    title={translate('titleOverride')}
                                >
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />

                                    <Field
                                        multiline
                                        component={TextField}
                                        label={translate('description')}
                                        name="description"
                                    />
                                </FormSection>

                                <ModuleImageFormSection />
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
