import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { Chip, ChipGroup } from 'common/components/Chip';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { TextField } from 'common/components/FormField';
import {
    ICourseListItemFragment,
    ILtiConfigFragment,
    ILtiPlatformFragment,
    ITrainingListItemFragment,
} from 'graphql/types';
import { TLTIPlatformFormValues } from 'connections/types';
import { Button } from 'common/components/Button';
import { AlertDialog } from 'common/components/AlertDialog';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';

import { ToolURLForm } from './ToolURLForm';
import { PlatformDetailsForm } from './PlatformDetailsForm';
import { AccessForm } from './AccessForm';
import { AddForms } from './AddForms';

interface IProps {
    lTIConfigUrls?: ILtiConfigFragment;
    disabled?: boolean;
    lTIPlatform?: ILtiPlatformFragment;
    onDelete?(): void;
    onSubmit(values: TLTIPlatformFormValues): void;
}

export const LTIPlatformForm = ({
    lTIConfigUrls,
    disabled,
    lTIPlatform,
    onDelete,
    onSubmit,
}: IProps) => {
    const [translate] = useTranslation();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const {
        title,
        isActive,
        issuer,
        clientId,
        deploymentIds,
        keySetUrl,
        authTokenUrl,
        authLoginUrl,
        maxUsers,
        startDate,
        endDate,
        trainings: trainingConnection,
        courses: courseConnection,
        allowAllMaterial,
        numberOfUsers,
    } = lTIPlatform || {};
    const { ltiAuthenticateUrl, ltiLaunchUrl, ltiJwksUrl } =
        lTIConfigUrls || {};

    const courses =
        (courseConnection?.edges
            .map((edge) => edge?.node)
            .filter(Boolean) as ICourseListItemFragment[]) || [];

    const trainings =
        (trainingConnection?.edges
            .map((edge) => edge?.node)
            .filter(Boolean) as ITrainingListItemFragment[]) || [];

    const initialValues: TLTIPlatformFormValues = {
        title: title || '',
        ltiAuthenticateUrl: ltiAuthenticateUrl || '',
        ltiLaunchUrl: ltiLaunchUrl || '',
        ltiJwksUrl: ltiJwksUrl || '',
        issuer: issuer || '',
        clientId: clientId || '',
        deploymentIds: deploymentIds
            ? JSON.parse(deploymentIds).join(', ')
            : '',
        keySetUrl: keySetUrl || '',
        authTokenUrl: authTokenUrl || '',
        authLoginUrl: authLoginUrl || '',
        isActive: isActive || false,
        maxUsers: maxUsers || '',
        startDate,
        endDate,
        allowAllMaterial:
            typeof allowAllMaterial === 'undefined' ? true : allowAllMaterial,
        trainings,
        courses,
    };

    // When any of the platform details is empty and when viewing an existing platform
    const actionRequired =
        (!deploymentIds || !keySetUrl || !authTokenUrl || !authLoginUrl) &&
        !!lTIPlatform;

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        maxUsers: Yup.number()
            .test(
                'min',
                translate('lTIPlatformForm.accessForm.maxUserValidation'),
                (val) => !val || val >= (numberOfUsers || 0)
            )
            .typeError(translate('mustBeANumber')),
        issuer: Yup.string().required(translate('validate.required')),
        clientId: Yup.string().required(translate('validate.required')),
    });

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ submitForm, isSubmitting, dirty }) => (
                    <>
                        {actionRequired && (
                            <>
                                <Box
                                    sx={{
                                        px: { xs: 2, sm: 4 },
                                        pt: { xs: 2, sm: 4 },
                                    }}
                                >
                                    <ChipGroup>
                                        <Chip
                                            color="error"
                                            label={translate('actionRequired')}
                                        />
                                    </ChipGroup>

                                    <Box sx={{ my: 2 }}>
                                        <Typography>
                                            {translate(
                                                'lTIPlatformForm.missingConfiguration'
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Divider />
                            </>
                        )}

                        <Box p={{ xs: 2, sm: 4 }}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h3"
                            >
                                {translate('lTIPlatformForm.lTIPlatform')}
                            </Typography>

                            <Box sx={{ mt: 1, mb: 2 }}>
                                <Box mb={1}>
                                    <Typography>
                                        {translate('lTIPlatformForm.formText')}
                                    </Typography>
                                </Box>

                                <Field
                                    component={TextField}
                                    label={translate('name')}
                                    name="title"
                                />
                            </Box>

                            <Divider />

                            <ToolURLForm />

                            <PlatformDetailsForm />

                            <AccessForm />

                            <AddForms />

                            <Box sx={{ display: 'flex', mt: 4 }}>
                                <Box>
                                    <Button
                                        color="primary"
                                        disabled={
                                            isSubmitting ||
                                            disabled ||
                                            (!isSubmitting &&
                                                !disabled &&
                                                !dirty)
                                        }
                                        loading={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            e.preventDefault();

                                            return submitForm();
                                        }}
                                    >
                                        {translate('save')}
                                    </Button>
                                </Box>

                                {!!lTIPlatform && (
                                    <Box ml="auto">
                                        <FormDeleteButton
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                setDeleteDialogOpen(true)
                                            }
                                        >
                                            {translate('delete')}
                                        </FormDeleteButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Formik>

            {!!lTIPlatform && (
                <AlertDialog
                    actions={
                        <Box display="flex" mt={2}>
                            <Box mr={2}>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete?.()}
                                >
                                    {translate('delete')}
                                </Button>
                            </Box>

                            <Button
                                variant="contained"
                                onClick={() => setDeleteDialogOpen(false)}
                            >
                                {translate('close')}
                            </Button>
                        </Box>
                    }
                    open={deleteDialogOpen}
                    title={translate('lTIPlatformDeleteStatusDialog.title')}
                >
                    {translate('lTIPlatformDeleteStatusDialog.text')}
                </AlertDialog>
            )}
        </>
    );
};
