import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getQueryParams } from 'common/utils/getQueryParams';
import { TFilterBarItem } from 'common/types';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import {
    IAssignmentScoreType,
    IAssignmentModuleListItemFragment,
} from 'graphql/types';

export const useUserAssignmentSearch = (
    moduleItem?: IAssignmentModuleListItemFragment
) => {
    const [translate] = useTranslation();

    const routeQuery = useRouteQuery();
    const searchQueryParam = routeQuery.get('q');
    const queryParams = getQueryParams();

    const scoreText = translate('score');
    const fulfilledText = translate('fulfilled');
    const closedText = translate('closed');
    const yesText = translate('yes');
    const noText = translate('no');

    // Build a list of filters
    const filters = useMemo(() => {
        if (!moduleItem) return [];

        const { scoreType, canUploadFiles } = moduleItem;

        return [
            scoreType === IAssignmentScoreType.Numeral && {
                name: scoreText,
                value: 'score',
                children: [
                    {
                        name: yesText,
                        value: '1',
                        chipName: `${scoreText}: ${yesText}`,
                        parent: scoreText.toLocaleLowerCase(),
                    },
                    {
                        name: noText,
                        value: '0',
                        chipName: `${scoreText}: ${noText}`,
                        parent: scoreText.toLocaleLowerCase(),
                    },
                ],
            },
            scoreType === IAssignmentScoreType.Fulfilled && {
                name: fulfilledText,
                value: 'fulfilled',
                children: [
                    {
                        name: yesText,
                        value: '1',
                        chipName: `${fulfilledText}: ${yesText}`,
                        parent: fulfilledText.toLocaleLowerCase(),
                    },
                    {
                        name: noText,
                        value: '0',
                        chipName: `${fulfilledText}: ${noText}`,
                        parent: fulfilledText.toLocaleLowerCase(),
                    },
                ],
            },
            canUploadFiles && {
                name: closedText,
                value: 'closed',
                children: [
                    {
                        name: yesText,
                        value: '1',
                        chipName: `${closedText}: ${yesText}`,
                        parent: closedText.toLocaleLowerCase(),
                    },
                    {
                        name: noText,
                        value: '0',
                        chipName: `${closedText}: ${noText}`,
                        parent: closedText.toLocaleLowerCase(),
                    },
                ],
            },
        ].filter(Boolean) as TFilterBarItem[];
    }, [moduleItem, scoreText, fulfilledText, closedText, yesText, noText]);

    // Get and apply selected filters.
    const selectedFilters = useMemo(() => {
        const selectedFilters = filters.reduce((acc, filter) => {
            const selected = filter.children?.filter((child) => {
                const { parent, value } = child;
                const key = parent || '';

                // Check if current query params contains filter
                if (!Object.hasOwn(queryParams, key)) return false;

                // Check if value is set in queryParams
                return queryParams[key].includes(value);
            });

            // Filter was not selected
            if (!selected) return acc;

            return [...acc, ...selected];
        }, [] as TFilterBarItem[]);

        return selectedFilters;
    }, [filters, queryParams]);

    return {
        filters,
        searchQueryParam: searchQueryParam || '',
        queryParams,
        selectedFilters,
    };
};
