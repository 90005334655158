import { ApolloProvider } from '@apollo/client';
import { StylesProvider } from '@mui/styles';
import { SnackbarProvider } from 'notistack';

import { apolloClient } from 'graphql/apolloClient';
import { ThemeProvider } from 'common/components/ThemeProvider';
import { I18nextProvider } from 'common/components/I18nextProvider';
import { DetectUnsupportedBrowser } from 'common/components/DetectUnsupportedBrowser';
import { MixpanelProvider } from 'common/components/MixpanelProvider';
import { FileUploadProvider } from 'common/components/FileUploadProvider';
import { ConnectionCheck } from 'common/components/ConnectionCheck';
import { Router } from 'route/components/Router';
import { CrispLayer } from 'common/components/CrispLayer';

export const App = () => (
    <ConnectionCheck>
        <ApolloProvider client={apolloClient}>
            <CrispLayer>
                <MixpanelProvider>
                    <StylesProvider injectFirst>
                        <ThemeProvider>
                            <I18nextProvider>
                                <SnackbarProvider
                                    hideIconVariant
                                    maxSnack={20}
                                    // Pointer events overide because of issue of not being able to click
                                    // The close button
                                    style={{ pointerEvents: 'all' }}
                                >
                                    <FileUploadProvider>
                                        <Router />
                                    </FileUploadProvider>
                                </SnackbarProvider>
                                <DetectUnsupportedBrowser />
                            </I18nextProvider>
                        </ThemeProvider>
                    </StylesProvider>
                </MixpanelProvider>
            </CrispLayer>
        </ApolloProvider>
    </ConnectionCheck>
);
