import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ILanguage, useUpdateOrganisationMutation } from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { CoverPage } from 'hydra/pages/CoverPage';
import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { OrganisationLanguageForm } from 'organisation/components/forms/OrganisationLanguageForm';
import { getUrl } from 'route/utils/getUrl';
import { OrganisationOnboardingLottie } from 'organisation/components/OrganisationOnboardingLottie';
import { OrganisationOnboardingNavigation } from 'organisation/components/OrganisationOnboardingNavigation';
import { useMixpanel } from 'common/hooks/useMixpanel';

export const OrganisationLanguagePage = () => {
    const [translate, i18n] = useTranslation();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();

    const [updateOrganisation, { error: updateError }] =
        useUpdateOrganisationMutation();

    return (
        <>
            {updateError && <ApolloError error={updateError} />}
            <CoverPage
                footer={<OrganisationOnboardingNavigation />}
                secondaryContent={<OrganisationOnboardingLottie />}
            >
                <PageTitle mixpanelTitle="Language select - Onboarding">
                    {translate('orgLanguagePage.browserTitle')}
                </PageTitle>

                <Box mb={2}>
                    <Typography variant="h2">
                        {translate('orgLanguagePage.title')}
                    </Typography>
                </Box>

                <Text>{translate('orgLanguagePage.content')}</Text>

                <OrganisationLanguageForm
                    initialValues={{ language: i18n.language as ILanguage }}
                    onChangeLanguage={(value) => {
                        i18n.changeLanguage(value);
                    }}
                    onSubmit={async (value) => {
                        if (!value.language) return;

                        await updateOrganisation({
                            variables: {
                                organisation: { language: value.language },
                            },
                        });

                        await trackMixpanel({
                            eventName: 'Onboarding: Language set',
                        });

                        // Redirect to Organisation Images when language has been updated
                        navigate(getUrl('ORGANISATION_IMAGES'));

                        return;
                    }}
                />
            </CoverPage>
        </>
    );
};
