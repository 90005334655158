
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Assignment": [
      "CollectiveAssignment",
      "IndividualAssignment"
    ],
    "CertificateNode": [
      "Certificate"
    ],
    "CollectionAccessType": [
      "Group",
      "TrainingListItem"
    ],
    "CommentSubscriptionType": [
      "CreatedCommentSubscriptionType",
      "DeletedCommentSubscriptionType",
      "UpdatedCommentSubscriptionType"
    ],
    "Condition": [
      "AbsoluteDateCondition",
      "CourseClearedCondition",
      "DeadlineCondition",
      "RelativeDateCondition"
    ],
    "CourseNode": [
      "CourseListItem"
    ],
    "CourseOrLTIModule": [
      "Course",
      "LTIModule"
    ],
    "ExternalContentNode": [
      "ExternalContent"
    ],
    "ExtraCategoryValue": [
      "ExtraCategoryBooleanValue",
      "ExtraCategoryChoiceValue",
      "ExtraCategoryDatetimeValue",
      "ExtraCategoryStringValue"
    ],
    "GroupNode": [
      "Group"
    ],
    "MenuBadge": [
      "StaticBadge",
      "UnreadBadge"
    ],
    "Module": [
      "AfasWebhookModule",
      "AssignmentModule",
      "CertificateModule",
      "CourseModule",
      "ExternalContentModule",
      "FileModule",
      "LTIModule",
      "LinkModule",
      "MeetingModule",
      "ScormModule",
      "VideoModule",
      "WebhookModule"
    ],
    "ModuleGroupCondition": [
      "AbsoluteModuleGroupCondition",
      "CourseCompletionModuleGroupCondition",
      "RelativeModuleGroupCondition"
    ],
    "Node": [
      "User"
    ],
    "OfferEventEnrollmentManageableNode": [
      "OfferEventEnrollmentManageable"
    ],
    "OfferEventEnrollmentNode": [
      "OfferEventEnrollment"
    ],
    "OfferEventExtraField": [
      "OfferEventExtraChoiceField",
      "OfferEventExtraStringField"
    ],
    "OfferEventNode": [
      "OfferEventListItem"
    ],
    "OfferEventSearchNode": [
      "OfferEventSearch"
    ],
    "OfferNode": [
      "Offer"
    ],
    "OfferSearchNode": [
      "OfferSearch"
    ],
    "PortfolioItemRelatedObject": [
      "CollectiveAssignment",
      "Course",
      "ExternalContent",
      "IndividualAssignment",
      "LTIModule",
      "OfferEvent",
      "ReceivedCertificate",
      "Scorm",
      "TrainingListItem"
    ],
    "PortfolioItemReportSearchNode": [
      "PortfolioItemReport"
    ],
    "ScormNode": [
      "Scorm"
    ],
    "SkillNode": [
      "Skill"
    ],
    "TrainingExtraField": [
      "TrainingExtraChoiceField",
      "TrainingExtraDateField",
      "TrainingExtraStringField"
    ],
    "TrainingNode": [
      "Training",
      "TrainingListItem"
    ],
    "UserAssignmentNode": [
      "UserAssignment"
    ],
    "UserExtraField": [
      "UserExtraCheckboxField",
      "UserExtraChoiceField",
      "UserExtraDateField",
      "UserExtraStringField"
    ],
    "VideoNode": [
      "Video"
    ]
  }
};
      export default result;
    