import { useState } from 'react';

import {
    DEFAULT_GLOBAL_DRAWER_HANDLERS,
    GlobalDrawerContext,
    IGlobalDrawerHandlers,
} from 'common/contexts/GlobalDrawerContext';
import { GlobalDrawerList } from 'common/components/GlobalDrawerProvider/GlobalDrawerList';

interface IProps {
    children?: React.ReactNode;
}

export const GlobalDrawersProvider = ({ children }: IProps) => {
    // Action handlers are set in the GlobalDrawerList component which handle the drawer states
    // This way the state updates of drawers do not affect the entire app
    const [actionHandlers, setActionHandlers] = useState<IGlobalDrawerHandlers>(
        DEFAULT_GLOBAL_DRAWER_HANDLERS
    );

    return (
        <GlobalDrawerContext.Provider value={actionHandlers}>
            <GlobalDrawerList setActionHandlers={setActionHandlers} />

            {children}
        </GlobalDrawerContext.Provider>
    );
};
