import { Radio as MuiRadio, RadioProps } from '@mui/material';
import { styled } from 'styled-components';

interface IProps extends Omit<RadioProps, 'color'> {
    color?: RadioProps['color'] | 'black';
}

const BaseRadio = ({ color = 'black', ...other }: IProps) => (
    <MuiRadio
        size="small"
        {...other}
        color={color === 'black' ? undefined : color}
    />
);

export const Radio = styled(BaseRadio)<IProps>`
    svg {
        ${({ theme, color }) =>
            color === 'black' && `color: ${theme.palette.common.black};`}
    }
`;
