import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
    IModuleGroupConditionFragment,
    IModuleGroupConditionType,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { Select, TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { Typography } from 'common/components/Typography';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { Divider } from 'common/components/Divider';
import { CONDITION_TYPE_LIST } from 'training/constants/moduleGroup';
import {
    getConditionInitialValues,
    getConditionTypeTranslation,
} from 'training/utils/moduleGroup';
import { TModuleGroupFormValues } from 'training/types';
import { Loader } from 'common/components/Loader';

import { ConditionRelativeFormFields } from './ConditionRelativeFormFields';
import { ConditionAbsoluteFormFields } from './ConditionAbsoluteFormFields';
import { ConditionCourseCompletedFormFields } from './ConditionCourseCompletedFormFields';

export interface IProps {
    condition?: IModuleGroupConditionFragment;
    disabled?: boolean;
    edit?: boolean;
    initialValues: TModuleGroupFormValues;
    loading?: boolean;
    onSubmit(values: TModuleGroupFormValues): void;
    onDelete?(): void;
}

export const ModuleGroupForm = ({
    condition,
    disabled,
    edit,
    initialValues,
    loading,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    if (loading) return <Loader />;

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        description: Yup.string().optional(),
        relativeCondition: Yup.object().when('conditionType', {
            is: IModuleGroupConditionType.Relative,
            then: Yup.object({
                startOffset: Yup.object({
                    amount: Yup.number()
                        .integer(translate('validate.integer'))
                        .typeError(translate('validate.mustBeANumber'))
                        .min(0, translate('validate.zeroOrPositive'))
                        .max(
                            1000,
                            translate('validate.maxNumber', { maxNumber: 1000 })
                        )
                        .required(translate('validate.required')),
                }),
                deadlineOffset: Yup.object({
                    amount: Yup.number()
                        .integer(translate('validate.integer'))
                        .typeError(translate('validate.mustBeANumber'))
                        .min(0, translate('validate.zeroOrPositive'))
                        .max(
                            1000,
                            translate('validate.maxNumber', { maxNumber: 1000 })
                        ),
                }),
            }),
        }),

        absoluteCondition: Yup.object().when('conditionType', {
            is: IModuleGroupConditionType.Absolute,
            then: Yup.object({
                start: Yup.date()
                    .typeError(translate('validate.required'))
                    .required(translate('validate.required')),
            }),
        }),

        courseCompletionCondition: Yup.object().when('conditionType', {
            is: IModuleGroupConditionType.CourseCompletion,
            then: Yup.object({
                courseOrLti: Yup.object().required(
                    translate('validate.required')
                ),
                scoreThreshold: Yup.number()
                    .integer(translate('validate.integer'))
                    .typeError(translate('validate.mustBeANumber'))
                    .min(0, translate('validate.zeroOrPositive'))
                    .max(
                        100,
                        translate('validate.maxNumber', { maxNumber: 100 })
                    ),
                preDelay: Yup.object({
                    amount: Yup.number()
                        .integer(translate('validate.integer'))
                        .typeError(translate('validate.mustBeANumber'))
                        .min(0, translate('validate.zeroOrPositive'))
                        .max(
                            1000,
                            translate('validate.maxNumber', { maxNumber: 1000 })
                        ),
                }),
                postDelay: Yup.object({
                    amount: Yup.number()
                        .integer(translate('validate.integer'))
                        .typeError(translate('validate.mustBeANumber'))
                        .min(0, translate('validate.zeroOrPositive'))
                        .max(
                            1000,
                            translate('validate.maxNumber', { maxNumber: 1000 })
                        ),
                }),
            }),
        }),
    });

    const formTitle = edit
        ? translate('moduleGroupForm.editTitle')
        : translate('moduleGroupForm.createTitle');
    const formText = !edit && translate('moduleGroupForm.text');

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values, setValues }) => {
                const { conditionType } = values;
                const isNoConditions =
                    conditionType === IModuleGroupConditionType.NoCondition;

                return (
                    <Box p={{ xs: 2, sm: 4 }}>
                        <ExitPageAlert
                            alert={UnsavedChangesAlert}
                            when={dirty && !isSubmitting}
                            onConfirm={submitForm}
                        />

                        <Form>
                            {(!!formTitle || !!formText) && (
                                <Box mb={2}>
                                    {!!formTitle && (
                                        <Typography
                                            sx={{ fontWeight: 'bold' }}
                                            variant="h3"
                                        >
                                            {formTitle}
                                        </Typography>
                                    )}

                                    {!!formText && (
                                        <Box mt={1}>
                                            <Typography>{formText}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}

                            <Field
                                component={TextField}
                                label={translate('title')}
                                name="title"
                            />

                            <Field
                                multiline
                                component={TextField}
                                label={translate('description')}
                                name="description"
                            />

                            <Field
                                component={Select}
                                defaultValue={
                                    IModuleGroupConditionType.NoCondition
                                }
                                disabled={disabled}
                                label={translate('moduleGroupForm.condition')}
                                name="conditionType"
                                options={CONDITION_TYPE_LIST.map(
                                    (conditionType) => ({
                                        value: conditionType,
                                        label: getConditionTypeTranslation(
                                            conditionType
                                        ),
                                    })
                                )}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    const value = event.target
                                        .value as IModuleGroupConditionType;

                                    const conditionValues =
                                        getConditionInitialValues(
                                            value,
                                            value === condition?.conditionType
                                                ? condition
                                                : undefined
                                        );

                                    setValues({
                                        ...values,
                                        conditionType: value,
                                        ...conditionValues,
                                    });
                                }}
                            />

                            <Box sx={{ mt: 2 }}>
                                {!isNoConditions && <Divider />}

                                {conditionType ===
                                    IModuleGroupConditionType.Relative &&
                                    !!values.relativeCondition && (
                                        <ConditionRelativeFormFields
                                            disabled={disabled}
                                        />
                                    )}

                                {conditionType ===
                                    IModuleGroupConditionType.Absolute &&
                                    !!values.absoluteCondition && (
                                        <ConditionAbsoluteFormFields
                                            disabled={disabled}
                                        />
                                    )}

                                {conditionType ===
                                    IModuleGroupConditionType.CourseCompletion &&
                                    !!values.courseCompletionCondition && (
                                        <ConditionCourseCompletedFormFields
                                            disabled={disabled}
                                        />
                                    )}
                            </Box>

                            <Box sx={{ display: 'flex', mt: 4 }}>
                                <Box>
                                    <Button
                                        color="primary"
                                        disabled={
                                            isSubmitting ||
                                            disabled ||
                                            (!isSubmitting &&
                                                !disabled &&
                                                !dirty)
                                        }
                                        loading={isSubmitting || loading}
                                        type="submit"
                                        variant="contained"
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            e.preventDefault();

                                            return submitForm();
                                        }}
                                    >
                                        {translate('save')}
                                    </Button>
                                </Box>

                                {onDelete && (
                                    <Box ml="auto">
                                        <FormDeleteButton
                                            disabled={isSubmitting || disabled}
                                            onClick={onDelete}
                                        >
                                            {translate('delete')}
                                        </FormDeleteButton>
                                    </Box>
                                )}
                            </Box>
                        </Form>
                    </Box>
                );
            }}
        </Formik>
    );
};
