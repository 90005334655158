import { Box, Grid } from '@mui/material';
import { Form, Formik, FormikConfig, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
    TextField,
    Switch,
    ColorPicker,
    ImageField,
} from 'common/components/FormField';
import { Button } from 'common/components/Button';
import {
    ICreateLibraryCollectionInput,
    IUpdateLibraryCollectionInput,
    ITrainingListItemFragment,
    ICollectionGroupFragment,
    IFileFragment,
} from 'graphql/types';
import { GroupSelectField } from 'user/components/GroupSelectField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { TrainingSelector } from 'training/components/FormField/TrainingSelector';
import { FormSection } from 'common/components/FormSection';
import { Divider } from 'common/components/Divider';

type InputType = Omit<
    ICreateLibraryCollectionInput | IUpdateLibraryCollectionInput,
    'libraryImageId'
> & { libraryImage?: IFileFragment | null };

type FormValues = Omit<InputType, 'access'> & {
    accessTrainings: ITrainingListItemFragment[];
    accessGroups: ICollectionGroupFragment[];
};
interface IProps extends FormikConfig<FormValues> {
    isRootCollection?: boolean;
    disabled?: boolean;
    imageUploaded?: boolean;
    isDeleting?: boolean;
    onDelete?(): void;
}

export const LibraryCollectionForm = ({
    isRootCollection,
    disabled,
    imageUploaded,
    isDeleting,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        description: Yup.string().optional(),
        active: Yup.boolean().default(false),
        libraryTitleColor: Yup.string().optional(),
    });

    return (
        <Formik validationSchema={validationSchema} {...other}>
            {({ submitForm, isSubmitting, values, dirty }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={
                            (dirty || imageUploaded) &&
                            !isSubmitting &&
                            !isDeleting
                        }
                        onConfirm={submitForm}
                    />

                    <Form>
                        {isRootCollection && (
                            <Field
                                withDefaults
                                acceptedFileTypes={HEADER_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 1440,
                                    height: 300,
                                })}
                                label={translate('headerImage')}
                                maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                                name="libraryImage"
                            />
                        )}

                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Field
                                    component={TextField}
                                    label={translate('title')}
                                    name="title"
                                />
                            </Grid>
                            {isRootCollection && (
                                <Grid item>
                                    <Field
                                        component={ColorPicker}
                                        name="libraryTitleColor"
                                        popoverPosition="bottom-end"
                                        tooltip={translate<string>(
                                            'titleColor'
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Field
                            multiline
                            component={TextField}
                            label={translate('description')}
                            name="description"
                        />
                        <Field
                            checked={values.active}
                            component={Switch}
                            label={translate('published')}
                            name="active"
                        />

                        {!isRootCollection && (
                            <>
                                <Divider sx={{ mt: 2 }} />

                                <FormSection
                                    renderExpanded
                                    description={
                                        <Box>
                                            {translate(
                                                'collectionForm.trainingsSectionDescription'
                                            )}
                                        </Box>
                                    }
                                    title={translate(
                                        'collectionForm.trainingsSectionTitle'
                                    )}
                                >
                                    <Box mt={3}>
                                        <TrainingSelector
                                            name="accessTrainings"
                                            value={values.accessTrainings}
                                        />
                                    </Box>
                                </FormSection>

                                <Box mt={1}>
                                    <Field
                                        component={GroupSelectField}
                                        label={translate(
                                            'collectionForm.accessForGroups'
                                        )}
                                        name="accessGroups"
                                    />
                                </Box>
                            </>
                        )}

                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting &&
                                        !disabled &&
                                        !dirty &&
                                        !imageUploaded)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>

                            {!!onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
