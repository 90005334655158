import { useState } from 'react';

export const useCertificateSearch = () => {
    const [searchQueryParam, setSearchQueryParam] = useState('');

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    return {
        searchQueryParam: searchQueryParam || '',
        handleSearch,
    };
};
