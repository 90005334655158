import { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    IOrganisationFragment,
    OrganisationFragmentDoc,
    useOrganisationQuery,
    useUpdateOrganisationThemeMutation,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { CoverPage } from 'hydra/pages/CoverPage';
import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { Button } from 'common/components/Button';
import { BaseColorPicker } from 'common/components/BaseColorPicker';
import { getUrl } from 'route/utils/getUrl';
import { OrganisationOnboardingLottie } from 'organisation/components/OrganisationOnboardingLottie';
import { OrganisationOnboardingNavigation } from 'organisation/components/OrganisationOnboardingNavigation';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { showConfetti } from 'common/constants/globalVars';

export const OrganisationColorsPage = () => {
    const [translate] = useTranslation();
    const { trackMixpanel } = useMixpanel();

    const navigate = useNavigate();

    const [selectedColor, setSelectedColor] = useState<string>();
    const [isUpdating, setIsUpdating] = useState(false);

    const { data: currentOrgData } = useOrganisationQuery();
    const { organisation } = currentOrgData || {};
    const primaryColor = organisation?.theme.palette.primary;

    const overrideOrganisation = {
        theme: { palette: { primary: selectedColor } },
    } as IOrganisationFragment;

    const [updateOrganisationTheme, { error: updateError }] =
        useUpdateOrganisationThemeMutation();

    return (
        <>
            {updateError && <ApolloError error={updateError} />}

            <CoverPage
                bgColor={selectedColor}
                footer={<OrganisationOnboardingNavigation activeStep={2} />}
                secondaryContent={
                    <OrganisationOnboardingLottie
                        overrideOrganisation={overrideOrganisation}
                    />
                }
            >
                <PageTitle mixpanelTitle="Color select - Onboarding">
                    {translate('orgColorsPage.browserTitle')}
                </PageTitle>

                <Box mb={2}>
                    <Typography variant="h2">
                        {translate('orgColorsPage.title')}
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Text>{translate('orgColorsPage.content')}</Text>
                </Box>
                <Box mb={4} textAlign="right">
                    <BaseColorPicker
                        selectedColor={primaryColor}
                        onChange={setSelectedColor}
                    />
                </Box>
                <Box textAlign="right">
                    <Button
                        color="primary"
                        loading={isUpdating}
                        type="submit"
                        variant="contained"
                        onClick={async (
                            e: React.MouseEvent<HTMLButtonElement>
                        ) => {
                            e.preventDefault();
                            setIsUpdating(true);

                            await updateOrganisationTheme({
                                variables: {
                                    theme: {
                                        palette: {
                                            primary: selectedColor,
                                        },
                                    },
                                },
                                update: (cache, { data }) => {
                                    const { theme } =
                                        data?.updateOrganisationTheme || {};

                                    const updatedPrimaryColor =
                                        theme?.palette?.primary;

                                    if (!updatedPrimaryColor || !organisation) {
                                        return;
                                    }

                                    // Update color in cache after updating the organisation.
                                    cache.writeFragment({
                                        id: cache.identify(organisation),
                                        fragment: OrganisationFragmentDoc,
                                        data: {
                                            ...organisation,
                                            theme: {
                                                ...organisation.theme,
                                                palette: {
                                                    ...organisation.theme
                                                        .palette,
                                                    primary:
                                                        updatedPrimaryColor,
                                                },
                                            },
                                        },
                                        fragmentName: 'Organisation',
                                    });
                                },
                            });

                            await trackMixpanel({
                                eventName: 'Onboarding: Color set',
                            });

                            // Set showConfetti to true so confetti will be shown on the HomePage
                            showConfetti(true);

                            // Redirect to Home when organisation has been updated
                            navigate(getUrl('HOME'));

                            return;
                        }}
                    >
                        {translate('openYourAcademy')}
                    </Button>
                </Box>
            </CoverPage>
        </>
    );
};
