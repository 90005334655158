import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import INTRODUCTION_PANEL from 'assets/images/introduction-panel.webp';
import { FormSection } from 'common/components/FormSection';
import { PAGE_IMAGE_MAX_SIZE, PAGE_IMAGE_TYPE } from 'common/constants/files';
import {
    ColorPicker,
    ImageField,
    Switch,
    TextField,
} from 'common/components/FormField';
import { Banner } from 'common/components/Banner';
import { Img } from 'common/components/Img';
import { Link } from 'common/components/Link';

import { TOrganisationSettingsFormValues } from './OrganisationSettingsForm';

export const IntroductionPanelForm = () => {
    const [translate] = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );
    const { values } = useFormikContext<TOrganisationSettingsFormValues>();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="academySettingsForm.introductionPanelDescription"
                    />
                </Box>
            }
            title={translate('academySettingsForm.introductionPanelTitle')}
        >
            <Grid
                container
                alignItems="stretch"
                direction={smallScreen ? 'column-reverse' : 'row'}
                spacing={2}
                sx={{ mt: 2 }}
            >
                <Grid item sm xs={12}>
                    <Field
                        acceptedFileTypes={PAGE_IMAGE_TYPE}
                        component={ImageField}
                        label={translate(
                            'academySettingsForm.introductionPanelImage.label'
                        )}
                        maxUploadSize={PAGE_IMAGE_MAX_SIZE}
                        name="homeHeaderImage"
                    />
                </Grid>

                <Grid item sm xs={12}>
                    <Banner>
                        <Box
                            sx={{
                                py: 1,
                                px: 2,
                            }}
                        >
                            {translate(
                                'academySettingsForm.introductionPanelImage.description'
                            )}
                        </Box>
                        <Box mt="auto">
                            <Img src={INTRODUCTION_PANEL} />
                        </Box>
                    </Banner>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs>
                    <Field
                        component={TextField}
                        label={translate('title')}
                        name="homeTitle"
                    />
                </Grid>
                <Grid item>
                    <Field
                        component={ColorPicker}
                        name="homeTitleColor"
                        popoverPosition="bottom-end"
                        tooltip={translate<string>('titleColor')}
                    />
                </Grid>
            </Grid>

            <Field
                multiline
                component={TextField}
                label={translate('description')}
                name="homeText"
            />

            <Box mb={-2}>
                <Field
                    checked={values.showIntroductionPanel}
                    component={Switch}
                    label={translate(
                        'academySettingsForm.introductionPanelPublish'
                    )}
                    name="showIntroductionPanel"
                />
            </Box>
        </FormSection>
    );
};
