import { Node, Element, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { options } from '../../options';

export const withNormalize = () => (editor: ReactEditor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        if (node.type !== options.li.type) {
            // Call original `normalizeNode` when this is not an list item
            normalizeNode(entry);

            return;
        }

        for (const [child, childPath] of Node.children(editor, path)) {
            if (
                Element.isElement(child) &&
                !editor.isInline(child) &&
                child.type !== options.p.type
            ) {
                // Remove the first child that is not a paragraph.
                Transforms.removeNodes(editor, {
                    at: childPath,
                });

                return;
            }
        }
    };

    return editor;
};
