import { useLocation, useNavigate } from 'react-router-dom';
import { mdiTransitConnectionVariant } from '@mdi/js';

import { IPortfolioItemReportFragment } from 'graphql/types';
import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { Chip } from 'common/components/Chip';
import { LinearProgress } from 'common/components/LinearProgress';
import {
    formatDate,
    timeDeltaToDuration,
    durationToHuman,
} from 'common/utils/formatDate';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { Icon } from 'common/components/Icon';
import { ItemMedia } from 'common/components/ItemMedia';
import { TableRowMedia } from 'common/components/Table/TableRowMedia';
import { getUrl } from 'route/utils/getUrl';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

interface IProps {
    reportItem: IPortfolioItemReportFragment;
}

export const PortfolioTrainingTableRow = ({ reportItem }: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { openGlobalDrawer } = useGlobalDrawer();

    const {
        id,
        title,
        user,
        startDate,
        completionDate,
        timeSpent,
        progress,
        score,
        scoreThreshold,
        listImage,
        relatedObjectId,
    } = reportItem;

    const isPortfolioTrainingsPage = !!useRouteMatch('PORTFOLIO_TRAININGS');

    if (!user) return null;

    const startDateString = startDate && formatDate(startDate, 'dd MMMM yyyy');
    const completionDateString =
        completionDate && formatDate(completionDate, 'dd MMMM yyyy');

    const timeSpentDuration = timeDeltaToDuration(
        timeSpent || undefined
    ).shiftTo('days', 'hours', 'minutes', 'seconds');

    const showScore = progress === 1 && score;

    const scoreChip = showScore ? (
        <Chip
            color={score >= (scoreThreshold || 0) ? 'success' : 'error'}
            label={`${Math.round(score * 100)}%`}
        />
    ) : null;

    const onClickRow = () => {
        // Open the training page directly when accessed through own portfolio
        if (isPortfolioTrainingsPage) {
            navigate(getUrl('TRAINING', { trainingId: relatedObjectId }));

            return;
        }

        openGlobalDrawer(
            {
                type: IGlobalDrawerType.PortfolioItem,
                itemId: id,
            },
            location
        );
    };

    return (
        <TableRowMedia key={id} onClick={onClickRow}>
            <TableCell sx={{ width: '1px' }}>
                <ItemMedia
                    color="primary"
                    image={listImage?.url || ''}
                    size="small"
                >
                    <Icon
                        path={mdiTransitConnectionVariant}
                        size="2rem"
                        sizeSm="3rem"
                    />
                </ItemMedia>
            </TableCell>
            <TableCell>{title}</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {startDateString}
            </TableCell>
            <TableCell>
                {completionDate ? (
                    <Typography variant="body2">
                        {completionDateString}
                    </Typography>
                ) : (
                    <LinearProgress
                        withProgressText
                        value={(progress || 0) * 100}
                    />
                )}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {timeSpentDuration &&
                    durationToHuman(timeSpentDuration, {
                        unitDisplay: 'narrow',
                    })}
            </TableCell>
            <TableCell>{scoreChip}</TableCell>
        </TableRowMedia>
    );
};
