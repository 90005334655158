import { useMemo } from 'react';

import { useOffersSettingsQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    OFFER_TYPE_FILTERS,
    ITypeFilter,
    EFilterType,
} from 'offer/constants/offer';
import { useOfferPermissions } from 'offer/hooks/useOfferPermissions';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';

export const useOfferTypeFilter = () => {
    const { showApolloError } = useApolloError();
    const { currentUserIsManager } = useOfferPermissions();

    const { filterParams } = useUrlFilterParams('offer');
    const typeFilterParam = filterParams?.type;

    const { data, loading: settingsLoading } = useOffersSettingsQuery({
        onError: showApolloError,
    });

    const {
        publish,
        publishTraining,
        publishElearning,
        publishMeetingWebinar,
    } = data?.offersSettings || {};

    // Check which filters should be visible for current user
    const visibleTypesFilters = OFFER_TYPE_FILTERS.reduce((acc, filter) => {
        // Check if filter is published, we also check if offers is published
        const isPublished =
            ((filter.key === EFilterType.training && publishTraining) ||
                (filter.key === EFilterType.elearning && publishElearning) ||
                (filter.key === EFilterType.meetingWebinar &&
                    publishMeetingWebinar)) &&
            publish;

        // If filter is not published and user is not manager, we remove it from the list
        if (!isPublished && !currentUserIsManager) return acc;

        return [...acc, { ...filter, published: isPublished }];
    }, [] as ITypeFilter[]);

    const typeFilter = useMemo(() => {
        if (!typeFilterParam) return visibleTypesFilters[0];

        return visibleTypesFilters.find(
            (filter) => filter.key === typeFilterParam
        );
    }, [visibleTypesFilters, typeFilterParam]);

    return {
        loading: settingsLoading,
        typeFilters: visibleTypesFilters,
        currentTypeFilter: typeFilter,
    };
};
