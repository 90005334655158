import { mdiFilter } from '@mdi/js';

import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { DropdownMenu } from 'common/components/DropdownMenu';

interface IProps {
    buttonText: string;
    disabled?: boolean;
    icon?: string;
    children: React.ReactNode;
    buttonProps?: React.ComponentProps<typeof Button>;
}

export const FilterDropdown = ({
    buttonText,
    disabled,
    children,
    icon,
    buttonProps,
}: IProps) => (
    <DropdownMenu
        anchor={
            <Button
                disabled={disabled}
                startIcon={<Icon path={icon || mdiFilter} size="1.9rem" />}
                variant="outlined"
                {...buttonProps}
            >
                {buttonText}
            </Button>
        }
        disabled={disabled}
        placement="bottom-end"
    >
        {children}
    </DropdownMenu>
);
