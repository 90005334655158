import { useState, useEffect, useCallback, useMemo } from 'react';
import { Portal, Box, Paper, SxProps, List } from '@mui/material';
import { mdiChevronDown } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { FileUploadListItem } from 'common/components/FileUploadListItem';
import { IFileUploadStatus } from 'common/contexts/FileUploadContext';
import { Typography } from 'common/components/Typography';
import { Icon } from 'common/components/Icon';
import { useFileUpload } from 'common/hooks/useFileUpload';

const uploaderStyle: SxProps = {
    position: 'fixed',
    bottom: 0,
    left: '50px',
    width: '400px',
    transition: 'transform 300ms',
    zIndex: 'fileUpload',
};

export const GlobalFileUploadList = () => {
    const [translate] = useTranslation();

    const [hidden, setHidden] = useState(false);
    const { uploads } = useFileUpload();

    // Create a list of active uplaods
    const activeUploads = useMemo(
        () =>
            uploads?.filter(
                (upload) => upload.status !== IFileUploadStatus.SUCCESS
            ),
        [uploads]
    );

    const onBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
        const e = event || window.event;

        e.preventDefault();

        // Chrome requires returnValue to be set
        if (e) e.returnValue = '';
    }, []);

    useEffect(() => {
        if (!activeUploads?.length) {
            // When there are no uploads, set hidden to false for when new uploads are added
            setHidden(false);

            window.removeEventListener('beforeunload', onBeforeUnload, {
                capture: true,
            });
        } else {
            window.addEventListener('beforeunload', onBeforeUnload, {
                capture: true,
            });
        }

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload, {
                capture: true,
            });
        };
    }, [activeUploads, onBeforeUnload]);

    if (!activeUploads?.length) return null;

    return (
        <Portal>
            <Paper
                elevation={3}
                sx={[
                    uploaderStyle,
                    {
                        transform: hidden
                            ? 'translateY(calc(100% - 50px))'
                            : 'translateY(0)',
                    },
                ]}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        py: 1,
                        px: 2,
                        backgroundColor: '#F7F7F7',
                        cursor: 'pointer',
                    }}
                    onClick={() => setHidden(!hidden)}
                >
                    <Typography variant="h4">
                        {translate('fileUpload.title')}
                    </Typography>
                    <Box sx={{ ml: 'auto' }}>
                        <Icon path={mdiChevronDown} size="1.8rem" />
                    </Box>
                </Box>
                <List>
                    {activeUploads.map((upload, index) => (
                        <FileUploadListItem key={index} upload={upload} />
                    ))}
                </List>
            </Paper>
        </Portal>
    );
};
