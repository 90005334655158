import { useTranslation } from 'react-i18next';
import { Box, Typography, TableRow } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { EnrollmentManagementTableRowButtons } from 'offer/components/EnrollmentsManagementTable/EnrollmentManagementTableRowButtons';
import { UserAvatar } from 'user/components/UserAvatar';
import { TableCell } from 'common/components/Table';
import {
    IOfferEventEnrollmentManagementListItemFragment,
    IOfferEventSubscriptionStatus,
} from 'graphql/types';
import { Checkbox } from 'common/components/Checkbox';
import { formatDate, getAbsoluteDate } from 'common/utils/formatDate';
import { EnrollmentsTableActions } from 'offer/components/EnrollmentsTableActions';
import { Link } from 'common/components/Link';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    enrollment: IOfferEventEnrollmentManagementListItemFragment;
    enrollmentStatus: IOfferEventSubscriptionStatus;
    isSelected: boolean;
    onClickEnroll?(): void;
    onClickReject?(): void;
    onClickDelete?(): void;
    onClickMail?(): void;
    onClickComplete?(): void;
    onClickAbsent?(): void;
    onSelect?: () => void;
}

export const EnrollmentsManagementTableRow = ({
    enrollment,
    enrollmentStatus,
    isSelected,
    onClickEnroll,
    onClickReject,
    onClickDelete,
    onClickMail,
    onClickComplete,
    onClickAbsent,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const { user, offerEvent, statusUpdatedOn } = enrollment;

    const {
        id,
        title: offerEventTitle,
        offer: { title: offerTitle },
        dates,
        dateCount,
    } = offerEvent;

    const locations = dates
        ?.map((date) => date?.location?.title || date.locationString)
        .filter(Boolean);
    const firstLocation = locations?.[0];

    const date = dates?.[0];
    const { startDate, endDate } = date;

    const dateDisplay = date
        ? `${getAbsoluteDate(startDate, 'd MMM yyyy HH:mm')} - ${getAbsoluteDate(endDate, 'd MMM yyyy HH:mm')}`
        : '';
    const moreDates = !!(dateCount - 1)
        ? translate('enrollmentsManagementTable.moreDates', {
              count: dateCount - 1,
          })
        : undefined;

    const isRequestStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Requested;
    const isWaitingListStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Full;
    const isEnrolledStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Enrolled;
    const isDeniedStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Denied;
    const isAbsentStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Absent;

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    checked={isSelected}
                    onClick={(e) => {
                        onSelect?.();

                        e.stopPropagation();
                    }}
                />
            </TableCell>

            <TableCell
                onClick={() => {
                    openGlobalDrawer({
                        type: IGlobalDrawerType.UserProfile,
                        itemId: user.id,
                    });
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <UserAvatar sizeSm={40} sx={{ mr: 2 }} user={user} />

                    {user.name}
                </Box>
            </TableCell>
            <TableCell>
                <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        openGlobalDrawer(
                            {
                                type: IGlobalDrawerType.OfferEvent,
                                itemId: id,
                            },
                            location
                        );
                    }}
                >
                    <Typography fontWeight={700} variant="body2">
                        {offerEventTitle || offerTitle}
                    </Typography>
                </Link>

                {dateDisplay}

                {!!firstLocation && <Box>{firstLocation}</Box>}

                {!!moreDates && <Box>{moreDates}</Box>}
            </TableCell>
            <TableCell>{formatDate(statusUpdatedOn, 'd MMMM yyyy')}</TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{ ml: 2, display: 'flex' }}>
                        <EnrollmentManagementTableRowButtons
                            enrollmentStatus={enrollmentStatus}
                            onClickAbsent={onClickAbsent}
                            onClickComplete={onClickComplete}
                            onClickEnroll={onClickEnroll}
                            onClickReject={onClickReject}
                        />
                    </Box>

                    <EnrollmentsTableActions
                        isWebinarOrMeeting
                        onClickAbsent={
                            !isEnrolledStatus && !isAbsentStatus
                                ? onClickAbsent
                                : undefined
                        }
                        onClickComplete={
                            !isEnrolledStatus ? onClickComplete : undefined
                        }
                        onClickDelete={onClickDelete}
                        onClickEnroll={
                            !isRequestStatus &&
                            !isWaitingListStatus &&
                            !isEnrolledStatus
                                ? onClickEnroll
                                : undefined
                        }
                        onClickMail={onClickMail}
                        onClickReject={
                            !isRequestStatus &&
                            !isWaitingListStatus &&
                            !isDeniedStatus
                                ? onClickReject
                                : undefined
                        }
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};
