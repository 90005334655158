import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { Autocomplete } from 'common/components/FormField';
import {
    useInputOptionCertificatesQuery,
    ICertificateInputOptionFragment,
} from 'graphql/types';
import { InfoRow } from 'common/components/InfoRow';
import { getExpiryDisplay } from 'common/utils/expiryScale';

export const CertificateSelectField = ({ ...other }) => {
    const [translate] = useTranslation();
    const [selectedCertificate, setSelectedCertificate] =
        useState<ICertificateInputOptionFragment>();
    const { getFieldMeta, setFieldValue } = useFormikContext();

    const { loading, data: certificatesData } =
        useInputOptionCertificatesQuery();

    const certificates =
        certificatesData?.certificates?.edges
            ?.filter((edge) => !!edge?.node)
            .map((edge) => edge?.node) || [];

    const value = getFieldMeta(other.name).value as
        | ICertificateInputOptionFragment
        | undefined;

    useEffect(() => {
        if (!certificates.length) return;

        setSelectedCertificate(value);
    }, [certificates, value]);

    return (
        <Box sx={{ mb: 1 }}>
            <Field
                component={Autocomplete}
                getOptionLabel={(option: ICertificateInputOptionFragment) =>
                    option.name
                }
                InputProps={{
                    label: translate('certificate'),
                }}
                isOptionEqualToValue={(
                    option: ICertificateInputOptionFragment,
                    value: ICertificateInputOptionFragment
                ) => option.id === value.id}
                loading={loading}
                noOptionsText={translate('noOptionsText.certificates')}
                options={certificates}
                onChange={(
                    _event: React.ChangeEvent,
                    value?: ICertificateInputOptionFragment
                ) => {
                    const { name } = other;

                    setSelectedCertificate(value);
                    setFieldValue(name, value);
                }}
                {...other}
            />

            {selectedCertificate && (
                <Box sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                        <InfoRow
                            description={translate(
                                'certificateSelectPreview.name'
                            )}
                        >
                            {selectedCertificate.name}
                        </InfoRow>

                        {!!selectedCertificate.expiryScale && (
                            <InfoRow
                                description={translate(
                                    'certificateSelectPreview.validity'
                                )}
                            >
                                {!!selectedCertificate.expiry
                                    ? getExpiryDisplay(
                                          selectedCertificate.expiry,
                                          selectedCertificate.expiryScale
                                      )
                                    : translate(
                                          'certificateSelectPreview.validityForever'
                                      )}
                            </InfoRow>
                        )}

                        {!!selectedCertificate.scoring.length && (
                            <InfoRow
                                description={translate(
                                    'certificateSelectPreview.score'
                                )}
                            >
                                {selectedCertificate.scoring.map((score) => (
                                    <Box
                                        key={score.scoringSystem.id}
                                        sx={{ mb: 1 }}
                                    >
                                        {score.score}{' '}
                                        {score.score === 1
                                            ? score.scoringSystem.nameSingular
                                            : score.scoringSystem.namePlural}
                                    </Box>
                                ))}
                            </InfoRow>
                        )}
                    </Stack>
                </Box>
            )}
        </Box>
    );
};
