import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';
import { useState } from 'react';

import { PageDrawer } from 'common/components/PageDrawer';
import { PortfolioItemGroupForm } from 'user/components/forms';
import { TFormValues } from 'user/components/forms/PortfolioItemGroupForm/PortfolioItemGroupForm';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import {
    IPortfolioQuery,
    PortfolioDocument,
    useCreatePortfolioItemGroupMutation,
    useDeletePortfolioItemGroupMutation,
    useUpdatePortfolioItemGroupMutation,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Loader } from 'common/components/Loader';
import { PageTitle } from 'common/components/PageTitle';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { IPortfolioGroup } from 'user/types';
import { TDateRange } from 'common/types';

interface IProps extends IPageDrawerProps {
    inboxGroupId?: string;
    item?: IPortfolioGroup;
    loading?: boolean;
    pageTitle: string;
    mixpanelTitle: string;
    queryVars?: { userId: string; dateFilter?: TDateRange };
    onClose?(): void;
}

export const GroupDrawer = ({
    inboxGroupId,
    item,
    loading,
    pageTitle,
    mixpanelTitle,
    queryVars,
    onClose,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);
    const [displaySnackbar] = useSnackbar();
    const isGroupCreatePage = !!useRouteMatch('PORTFOLIO_GROUP_CREATE');
    const [createGroup, { error: createError }] =
        useCreatePortfolioItemGroupMutation({
            update: (cache, { data: newPortfolioItemGroupData }) => {
                const newPortfolioItemGroup =
                    newPortfolioItemGroupData?.createPortfolioItemGroup
                        .portfolioItemGroup;

                if (!newPortfolioItemGroup) return;

                const portfolioData: IPortfolioQuery | null = cache.readQuery({
                    query: PortfolioDocument,
                    variables: queryVars,
                });

                if (!portfolioData) return;

                const { portfolio } = portfolioData || {};

                cache.updateQuery(
                    { query: PortfolioDocument, variables: queryVars },
                    () => ({
                        portfolio: {
                            ...portfolio,
                            portfolioGroups: [
                                ...(portfolio?.portfolioGroups || []),
                                newPortfolioItemGroup,
                            ],
                        },
                    })
                );

                displaySnackbar(translate('groupActionSuccess.create'), {
                    variant: 'success',
                });

                onClose && onClose();
            },
        });
    const [updateGroup, { error: updateError }] =
        useUpdatePortfolioItemGroupMutation({
            update: () => {
                displaySnackbar(translate('groupActionSuccess.update'), {
                    variant: 'success',
                });

                onClose && onClose();
            },
        });
    const [deleteGroup, { loading: deleteLoading, error: deleteError }] =
        useDeletePortfolioItemGroupMutation({
            update: (cache) => {
                if (item) {
                    const portfolioData: IPortfolioQuery | null =
                        cache.readQuery({
                            query: PortfolioDocument,
                            variables: queryVars,
                        });

                    if (!portfolioData) return;

                    const { portfolio } = portfolioData || {};

                    cache.updateQuery(
                        { query: PortfolioDocument, variables: queryVars },
                        () => ({
                            portfolio: {
                                ...portfolio,
                                portfolioGroups: [
                                    ...(portfolio?.portfolioGroups
                                        .map((group) => {
                                            // If deleted group return nothing
                                            if (group.id === item.id) {
                                                return null;
                                            }

                                            // If group is inbox we want to move the portfolio items
                                            // of the deleted group to this group.
                                            if (group.isInbox) {
                                                return {
                                                    ...group,
                                                    portfolioItems: [
                                                        ...group.portfolioItems,
                                                        ...item.portfolioItems,
                                                    ],
                                                };
                                            }

                                            return group;
                                        })
                                        .filter(Boolean) || []),
                                ],
                            },
                        })
                    );

                    // Remove portfolio item group from cache
                    cache.evict({ id: `PortfolioItemGroup:${item.id}` });
                    cache.gc();
                }

                displaySnackbar(translate('groupActionSuccess.delete'), {
                    variant: 'success',
                });

                onClose && onClose();
            },
        });

    const { id, title, description } = item || {};

    const initialValues: TFormValues = {
        title: title || '',
        description: description || '',
    };

    const handleSubmit = (values: TFormValues) => {
        const { id } = item || {};

        if (id) {
            updateGroup({
                variables: {
                    portfolioItemGroup: values,
                    id,
                },
            });

            return;
        }

        return createGroup({
            variables: {
                portfolioItemGroup: values,
            },
        });
    };

    const onDelete = () => {
        setShowOnDeleteAlert(false);

        if (id) deleteGroup({ variables: { id } });
    };

    let drawerTitle = !loading && !!item ? translate('updateGroup') : '';

    if (isGroupCreatePage) {
        drawerTitle = translate('newGroup');
    }

    return (
        <PageDrawer {...other} onClose={onClose}>
            {createError && <ApolloError error={createError} />}
            {updateError && <ApolloError error={updateError} />}
            {deleteError && <ApolloError error={deleteError} />}

            {!!drawerTitle && (
                <PageTitle
                    mixpanelTitle={`${mixpanelTitle} - ${
                        item ? 'Edit' : 'New'
                    } portfolio group`}
                >{`${drawerTitle} - ${pageTitle}`}</PageTitle>
            )}

            {loading && <Loader />}

            {!loading && (
                <PortfolioItemGroupForm
                    initialValues={initialValues}
                    isDeleting={deleteLoading}
                    onDelete={() => setShowOnDeleteAlert(true)}
                    onSubmit={handleSubmit}
                />
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={onDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowOnDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showOnDeleteAlert}
                title={translate('deleteGroupMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteGroupMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
