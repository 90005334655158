import { useState } from 'react';
import { Box } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { IFileFragment, IOrganisationFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { ImageField } from 'common/components/FormField';
import {
    LOGO_IMAGE_TYPE,
    LOGO_IMAGE_MAX_SIZE,
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';

type TUpdateOrganisationImagesFormValues = Pick<
    IOrganisationFragment,
    'logo' | 'participantIntroImage'
>;

interface IProps {
    initialValues: TUpdateOrganisationImagesFormValues;
    onSubmit(values: TUpdateOrganisationImagesFormValues): void;
    onChange?(name: string, file: IFileFragment): void;
}

export const OrganisationImagesForm = ({ onChange, ...other }: IProps) => {
    const [translate] = useTranslation();

    const [fieldsLoaded, setFieldsLoaded] = useState({
        participantIntroImage: false,
    });

    // Check if all fields are loaded
    const allFieldsLoaded = Object.values(fieldsLoaded).every(Boolean);

    return (
        <Formik enableReinitialize {...other}>
            {({ submitForm, isSubmitting }) => (
                <>
                    <Form>
                        <Box mt={4}>
                            <Field
                                acceptedFileTypes={LOGO_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 320,
                                    height: 120,
                                })}
                                imageSize="contain"
                                label={translate('academyLogo')}
                                maxUploadSize={LOGO_IMAGE_MAX_SIZE}
                                name="logo"
                                onChange={(
                                    name: string,
                                    file: IFileFragment
                                ) => {
                                    onChange && onChange(name, file);
                                }}
                            />
                        </Box>

                        <Box mt={4}>
                            <Field
                                withDefaults
                                acceptedFileTypes={HEADER_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 1440,
                                    height: 300,
                                })}
                                label={translate('headerImage')}
                                maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                                name="participantIntroImage"
                                startSlide={0}
                                onChange={(
                                    name: string,
                                    file: IFileFragment
                                ) => {
                                    onChange && onChange(name, file);
                                }}
                                onLoaded={() => {
                                    setFieldsLoaded((loaded) => ({
                                        ...loaded,
                                        participantIntroImage: true,
                                    }));
                                }}
                            />
                        </Box>

                        <Box mt={2} textAlign="right">
                            <Button
                                color="primary"
                                disabled={isSubmitting || !allFieldsLoaded}
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('nextStep')}
                            </Button>
                        </Box>
                    </Form>
                </>
            )}
        </Formik>
    );
};
