import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik, Field } from 'formik';

import {
    IUpdateScormInput,
    IScormEditFragment,
    IScormListItemFragment,
    IFile,
} from 'graphql/types';
import { TextField, FileField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { SCORM_FILE_MAX_SIZE, SCORM_FILE_TYPES } from 'common/constants/files';
import { ContentExpander } from 'common/components/ContentExpander';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { Button } from 'common/components/Button';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { Divider } from 'common/components/Divider';
import { ScormListItem } from 'training/components/ScormListItem';
import { ScormImageFormSection } from 'training/components/forms/ScormForm/ScormImageFormSection';

export type TScormFormValues = Omit<IUpdateScormInput, 'thumbnailId'> & {
    fileUrl: string;
    zipFile?: IFile;
    thumbnail?: IFile;
};

export interface IProps {
    edit?: boolean;
    initialValues: TScormFormValues;
    scorm?: IScormEditFragment;
    disabled?: boolean;
    onSubmit(values: TScormFormValues): void;
    onDelete?(): void;
    onReplaceScorm?(): void;
}

export const ScormForm = ({
    edit,
    initialValues,
    onSubmit,
    disabled,
    scorm,
    onDelete,
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);

    const fileField = (
        <Field
            acceptedFileTypes={SCORM_FILE_TYPES}
            component={FileField}
            helperText={translate('scormForm.fileHelperText', {
                count: SCORM_FILE_MAX_SIZE,
            })}
            label={translate('scormForm.file')}
            maxUploadSize={SCORM_FILE_MAX_SIZE}
            name="zipFile"
        />
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ submitForm, isSubmitting, dirty }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        {!!scorm ? (
                            <ScormListItem
                                disabled
                                px={0}
                                py={0}
                                scorm={scorm as IScormListItemFragment}
                            />
                        ) : (
                            fileField
                        )}

                        <Box sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }}>
                            <Divider />
                        </Box>

                        <Field
                            component={TextField}
                            label={translate('title')}
                            name="title"
                        />

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                {!!scorm && (
                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="scormForm.scormFileDescription"
                                            />
                                        }
                                        title={translate('scormForm.scormFile')}
                                    >
                                        <Box mt={3}>{fileField}</Box>
                                    </FormSection>
                                )}

                                <ScormImageFormSection />
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {translate(
                                        `scormForm.${edit ? 'editButton' : 'createButton'}`
                                    )}
                                </Button>
                            </Box>

                            {onDelete && edit && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
