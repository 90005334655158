import { useMemo } from 'react';

import { TFilterFormValues } from 'common/types';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import { IReportFilterValues } from 'report/types';
import {
    ITrainingExtraChoiceField,
    useExtraTrainingFieldsQuery,
} from 'graphql/types';
import {
    filtersToBase64String,
    filtersToQueryParams,
} from 'common/utils/filter';
import { getTrainingFilters } from 'training/constants/filters';
import {
    ITrainingExtraFieldsInputOption,
    useExtraFieldsFilters,
} from 'training/hooks/useExtraFieldsFilters';
import { useApolloError } from 'common/hooks/useApolloError';
import { ITrainingFilterValues } from 'training/types';
import {
    getExtraFieldsName,
    isExtraChoiceField,
} from 'common/utils/extraFields';

export const useTrainingFilter = (
    filterParamsInUrl = true,
    showArchiveFilter = false
) => {
    const { showApolloError } = useApolloError();

    const { filterParams, setFilterParam } = useUrlFilterParams(
        'training',
        filterParamsInUrl
    );

    const { extraCategoryFilters } = useExtraFieldsFilters();

    const {
        data: extraTrainingFieldsData,
        loading: extraTrainingFieldsLoading,
    } = useExtraTrainingFieldsQuery({
        onError: showApolloError,
    });

    const { extraTrainingFields } = extraTrainingFieldsData || {};

    const filters = [
        ...getTrainingFilters(showArchiveFilter),
        ...extraCategoryFilters,
    ];

    const searchQueryParam = filterParams.q || '';
    const selectedFilterParams = filterParams.filters;

    const handleSearch = (searchValue: string) => {
        setFilterParam('q', searchValue);
    };

    const handleFilterChange = (values?: TFilterFormValues) => {
        const filterString = filtersToBase64String(filters, values);

        setFilterParam('filters', filterString);
    };

    const selectedFilters: IReportFilterValues = useMemo(() => {
        if (!selectedFilterParams) return {};

        try {
            return JSON.parse(window.atob(selectedFilterParams));
        } catch {
            return {};
        }
    }, [selectedFilterParams]);

    // Parse selected filters for query
    const filterValuesForQuery = useMemo(() => {
        const filterValues = filtersToQueryParams<ITrainingFilterValues>(
            filters,
            selectedFilters
        );

        const extraFieldsObject: Record<string, string[]> = {};

        // Check if extra fields are selected, if so create the correct query object
        extraTrainingFields
            ?.filter((filter) => isExtraChoiceField(filter))
            .forEach((extraTrainingField) => {
                const { name, multiple } =
                    extraTrainingField as ITrainingExtraChoiceField;

                const filterValueKey = `extraFields-${getExtraFieldsName(name)}`;

                if (multiple) {
                    const selectedFilterValues = filterValues[
                        filterValueKey
                    ] as ITrainingExtraFieldsInputOption[] | undefined;

                    if (selectedFilterValues) {
                        extraFieldsObject[name] = selectedFilterValues.map(
                            (selectedFilterValue) => selectedFilterValue.name
                        );

                        delete filterValues[filterValueKey];
                    }

                    return;
                }

                const selectedFilterValues = filterValues[filterValueKey] as
                    | ITrainingExtraFieldsInputOption
                    | undefined;

                if (selectedFilterValues) {
                    extraFieldsObject[name] = [selectedFilterValues.name];

                    delete filterValues[filterValueKey];
                }
            });

        if (!!Object.keys(extraFieldsObject).length) {
            filterValues.extraFields = JSON.stringify(extraFieldsObject);
        }

        return filterValues;
    }, [selectedFilters, extraTrainingFields]);

    return {
        filters,
        filterValues: { ...selectedFilters, q: searchQueryParam },
        filterValuesForQuery: { ...filterValuesForQuery, q: searchQueryParam },
        handleSearch,
        handleFilterChange,
        loading: extraTrainingFieldsLoading,
    };
};
