import { createContext } from 'react';
import { Location } from 'react-router-dom';

import { IGlobalDrawer } from 'common/types';

export interface IGlobalDrawerHandlers {
    openGlobalDrawer: (drawer: IGlobalDrawer, location?: Location) => void;
    closeGlobalDrawer: (
        drawer: IGlobalDrawer,
        forceClose?: boolean,
        location?: Location
    ) => void;
}

export const DEFAULT_GLOBAL_DRAWER_HANDLERS = {
    openGlobalDrawer: () => {},
    closeGlobalDrawer: () => {},
};

export const GlobalDrawerContext = createContext<IGlobalDrawerHandlers>(
    DEFAULT_GLOBAL_DRAWER_HANDLERS
);
