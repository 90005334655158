import { Box, Table, TableBody, TableContainer } from '@mui/material';

import { Loader } from 'common/components/Loader';
import { NoResultsText } from 'common/components/NoResultsText';
import { useCourseOfferEventsQuery } from 'graphql/types';
import { CourseOfferEventTableRow } from 'training/components/CourseOfferEventTableRow';

interface IProps {
    courseId: string;
}

export const CourseOfferEventsTable = ({ courseId }: IProps) => {
    const { data, loading } = useCourseOfferEventsQuery({
        variables: { id: courseId },
    });

    if (loading) return <Loader />;

    const offerEvents = data?.course?.offerEvents?.edges
        .map((edge) => edge?.node)
        .filter(Boolean);

    if (!loading && !offerEvents?.length) {
        return <NoResultsText transKey="courseUpdate.noOfferEventsForCourse" />;
    }

    return (
        <Box sx={{ mt: -4 }}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {offerEvents?.map((offerEvent) => (
                            <CourseOfferEventTableRow
                                key={offerEvent.id}
                                offerEvent={offerEvent}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
