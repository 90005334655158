import MuiListItem, { ListItemProps } from '@mui/material/ListItem';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import { styled } from 'styled-components';
import { motion } from 'framer-motion';

import { TColorKeys } from 'theme/palette';

interface IProps {
    button?: boolean;
    disabled?: boolean;
    disableHover?: boolean;
    color?: TColorKeys;
    py?: number;
    px?: number;
}

function BaseListItem<C extends React.ElementType>({
    button,
    disabled,
    disableHover,
    py,
    px,
    ...other
}: ListItemProps<C, { component?: C } & IProps>) {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    // Add animation when listitem is a button
    if (button && !disabled && isSm) {
        return (
            <motion.div
                initial="initListItemButton"
                whileHover="hoverListItemButton"
            >
                <MuiListItem button {...other} />
            </motion.div>
        );
    }

    return (
        <Box>
            <MuiListItem disabled={disabled} {...other} />
        </Box>
    );
}

export const ListItem = styled(BaseListItem)`
    padding: ${({ theme, py, px }) =>
        theme.spacing(py !== undefined ? py : 1, px !== undefined ? px : 2)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme, py, px }) =>
            theme.spacing(
                py !== undefined ? py : 2,
                px !== undefined ? px : 4
            )};
    }

    ${({ theme, color }) =>
        color &&
        `
            color: ${theme.palette[color].main};
            background-color: ${theme.palette[color].bgColor};
        `}

    &.Mui-focusVisible,
    &.MuiListItemButton-root.Mui-focusVisible,
    &:hover {
        ${({ color, disableHover }) =>
            !color && !disableHover && `background-color: transparent;`}
    }

    &.MuiLink-underlineHover {
        text-decoration: none;
    }

    ${({ disabled }) => disabled && 'pointer-events: none'}

    &.MuiListItem-button .MuiChip-root {
        pointer-events: none;
    }
`;
