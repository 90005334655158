import {
    CardActionArea as MuiCardActionArea,
    CardActionAreaProps,
    SxProps,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

const cardActionAreaStyle: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100%',

    '&:hover .MuiCardActionArea-focusHighlight': {
        backgroundColor: 'common.black',
        opacity: '0.03',
    },
};

export function CardActionArea<C extends React.ElementType>({
    sx,
    ...other
}: CardActionAreaProps<C, { component?: C }>) {
    return (
        <MuiCardActionArea
            {...other}
            sx={[
                cardActionAreaStyle,
                // Spread sx from props
                ...withSxProp(sx),
            ]}
        />
    );
}
