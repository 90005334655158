import { Box, SxProps } from '@mui/material';
import { useRef } from 'react';
import { styled } from 'styled-components';

export interface IProps {
    /** Use an unique id when you have multiple file uploads on the same page */
    id: string;
    component?: React.ElementType;
    hoverIcon?: React.ReactNode;
    disabled?: boolean;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    className?: string;
    children?: React.ReactNode;
    sx?: SxProps;
    onUpload?: (files: File[]) => void;
}

const IconWrapper = styled.div`
    display: flex;
    transition: opacity 200ms linear;
    opacity: 0;
`;

const Label = styled.label`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &:hover {
        ${IconWrapper} {
            opacity: 1;
        }
    }
`;

const BasePicker = ({
    component: Component = 'span',
    id,
    className,
    children,
    disabled,
    hoverIcon,
    inputProps,
    onUpload,
    ...other
}: IProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const getFiles = () => {
        const { current } = fileInputRef;
        const { files } = current || {};

        if (!files || (files && !files.length)) return [];

        const fileArray = [];

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);

            if (!file) continue;

            fileArray.push(file);
        }

        return fileArray;
    };

    const handleChange = () => {
        const files = getFiles();

        if (!onUpload || !files.length) return;

        onUpload(files);
    };

    return (
        <Box className={className} component={Component} {...other}>
            {children}
            <Label
                htmlFor={id}
                style={{ cursor: onUpload ? 'pointer' : 'auto' }}
            >
                {onUpload && hoverIcon && (
                    <IconWrapper>{hoverIcon}</IconWrapper>
                )}
            </Label>
            <input
                {...inputProps}
                disabled={disabled || !onUpload}
                id={id}
                ref={fileInputRef}
                tabIndex={-1}
                type="file"
                onChange={handleChange}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    // Empty the value each time you click the input. Else you can not select the same file right after eachother
                    e.currentTarget.value = '';
                }}
            />
        </Box>
    );
};

export const FilePicker = styled(BasePicker)`
    position: relative;
    display: block;

    label {
        cursor: pointer;
    }

    input[type='file'] {
        font-size: 0;
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
    }
`;
