import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { AlertDialog, AlertDialogProps } from 'common/components/AlertDialog';
import { Typography } from 'common/components/Typography';
import { IPlanFragment } from 'graphql/types';
import { TCurrentPlanAmounts } from 'plans/types';
import { IconButton } from 'common/components/IconButton';

interface IProps extends AlertDialogProps {
    currentAmounts: TCurrentPlanAmounts;
    currentPlan: IPlanFragment;
    inSamePackage: boolean;
    selectedPlan: IPlanFragment;
}

export const PlanLimitModal = ({
    currentAmounts,
    currentPlan,
    inSamePackage,
    selectedPlan,
    onClose,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();
    const { userAmount, trainingAmount } = currentAmounts;
    const { userCountLimit, trainingCountLimit } = selectedPlan;

    const userLimitExceeded = userAmount > userCountLimit && userCountLimit > 0;
    const trainingLimitExceeded =
        trainingAmount > trainingCountLimit && trainingCountLimit > 0;

    const isDowngrade =
        inSamePackage && userCountLimit < currentPlan.userCountLimit;
    const isBasicPlan = currentPlan.isBasic;

    const titleText = isBasicPlan
        ? translate('plans.upgradeModal.limitExceeded.downgradeTitle')
        : translate(
              `plans.upgradeModal.limitExceeded.${
                  isDowngrade ? 'downgradeTitle' : 'upgradeTitle'
              }`
          );

    const limitText = isBasicPlan
        ? ''
        : translate(
              `plans.upgradeModal.limitExceeded.${
                  isDowngrade ? 'downgradeText' : 'upgradeText'
              }`
          );

    const title = (
        <Box alignItems="center" display="flex">
            <Typography fontWeight={700}>{titleText}</Typography>

            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    return (
        <AlertDialog title={title} onClose={onClose} {...other}>
            <Typography>
                {limitText}{' '}
                {userLimitExceeded && (
                    <Trans
                        components={{
                            spanColor: (
                                <Typography
                                    color={
                                        userLimitExceeded
                                            ? 'error.main'
                                            : 'success.main'
                                    }
                                    component="span"
                                    fontWeight={700}
                                />
                            ),
                            spanBold: (
                                <Typography component="span" fontWeight={700} />
                            ),
                        }}
                        i18nKey="plans.upgradeModal.limitExceeded.userLimitExceeded"
                        values={{ userAmount, userLimit: userCountLimit }}
                    />
                )}{' '}
                {trainingLimitExceeded && (
                    <Trans
                        components={{
                            spanColor: (
                                <Typography
                                    color={
                                        userLimitExceeded
                                            ? 'error.main'
                                            : 'success.main'
                                    }
                                    component="span"
                                    fontWeight={700}
                                />
                            ),
                            spanBold: (
                                <Typography component="span" fontWeight={700} />
                            ),
                        }}
                        i18nKey="plans.upgradeModal.limitExceeded.trainingLimitExceeded"
                        values={{
                            trainingAmount,
                            trainingLimit: trainingCountLimit,
                        }}
                    />
                )}
            </Typography>
        </AlertDialog>
    );
};
