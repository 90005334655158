import { Button } from '@mui/material';
import { styled } from 'styled-components';

export const DefaultButton = styled(Button)`
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    text-transform: none;
    min-width: 100px;
`;
