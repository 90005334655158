import { OfferEventDetailDrawer } from 'offer/components/OfferEventDetailDrawer';
import { useOfferQuery } from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { useRouteQuery } from 'route/hooks/useRouteQuery';

interface IProps {
    offerId?: string;
    open?: boolean;
    onClose?: () => void;
}

export const OfferDetailDrawer = ({ offerId, open, onClose }: IProps) => {
    const routeQuery = useRouteQuery();
    const id = offerId || routeQuery.get('id');

    const { data, loading, error } = useOfferQuery({
        variables: { id: id },
        skip: !id || !open,
    });

    const { offer } = data || {};
    const { detailEventId } = offer || {};

    return (
        <>
            {error && <ApolloError error={error} />}

            <OfferEventDetailDrawer
                includeOfferFields
                isOfferDetailDrawer
                loading={loading}
                offerEventId={detailEventId}
                offerLoading={loading}
                onClose={onClose}
            />
        </>
    );
};
