import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { IScormListItemFragment } from 'graphql/types';
import { ScormListItem } from 'training/components/ScormListItem';
import { isScormAdded, isScormSelected } from 'training/utils/scormSelect';

interface IProps {
    addedScorms: IScormListItemFragment[];
    selectedScorms: IScormListItemFragment[];
    scorms: IScormListItemFragment[];
    onAddScorm(selectedTraining: IScormListItemFragment): void;
    onRemoveScorm(selectedTraining: IScormListItemFragment): void;
    listItemProps?: Partial<React.ComponentProps<typeof ScormListItem>>;
}

export const ScormAddList = ({
    addedScorms,
    selectedScorms,
    scorms,
    onAddScorm,
    onRemoveScorm,
    listItemProps,
}: IProps) => {
    const [translate] = useTranslation();

    const handleSelectScorm = (
        scorm: IScormListItemFragment,
        isAdded: boolean
    ) => {
        isAdded ? onRemoveScorm(scorm) : onAddScorm(scorm);
    };

    return (
        <>
            {!scorms.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>{translate('noOptionsText.scorms')}</Typography>
                </Box>
            ) : (
                scorms.filter(Boolean).map((scorm) => {
                    const isSelected = isScormSelected(selectedScorms, scorm);
                    const isAdded = isScormAdded(addedScorms, scorm);

                    return (
                        <ScormListItem
                            addButton
                            added={isSelected || isAdded}
                            key={scorm.id}
                            px={2}
                            scorm={scorm}
                            selected={isSelected || isAdded}
                            onClick={() =>
                                handleSelectScorm(scorm, isSelected || isAdded)
                            }
                            {...listItemProps}
                        />
                    );
                })
            )}
        </>
    );
};
