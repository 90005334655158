import { TextFieldProps } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '../InputAdornment/InputAdornment';
import { TextField } from '../TextField/TextField';

export const LinkField = ({ name, ...other }: TextFieldProps) => {
    const { setFieldValue } = useFormikContext();
    const { t: translate } = useTranslation();

    return (
        <Field
            component={TextField}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">https://</InputAdornment>
                ),
            }}
            label={translate('link')}
            name={name}
            type="url"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!name) return;

                let value = e.currentTarget.value;

                // Remove protocol from url
                value = value.replace(/(^\w+:|^)\/\//, '');

                setFieldValue?.(name, value);
            }}
            {...other}
        />
    );
};
