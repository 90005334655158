import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

import { useOrganisationQuery } from 'graphql/types';
import { useMixpanel } from 'common/hooks/useMixpanel';

interface IProps {
    children: string;
    skipMixpanel?: boolean;
    mixpanelTitle?: string;
}

export const PageTitle = ({
    children: title,
    mixpanelTitle,
    skipMixpanel,
}: IProps) => {
    const { data: organisationData } = useOrganisationQuery();

    const { trackMixpanel } = useMixpanel();

    useEffect(() => {
        if ((!mixpanelTitle && !title) || skipMixpanel || !trackMixpanel) {
            return;
        }

        // Send given title to mixpanel with a fallback of the browser title
        const trackTitle = mixpanelTitle || title;

        trackMixpanel({
            eventName: `Page visit: ${trackTitle}`,
            properties: { page: trackTitle },
        });
    }, [mixpanelTitle, title, skipMixpanel, trackMixpanel]);

    if (!title) return null;

    const { name: orgName } = organisationData?.organisation || {};

    return (
        <Helmet>
            <title>
                {title}
                {orgName ? ` - ${orgName}` : ''}
            </title>
        </Helmet>
    );
};
