import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MenuItem, DialogContentText, Stack } from '@mui/material';

import {
    useTrainingEditQuery,
    useUpdateTrainingMutation,
    useCopyTrainingMutation,
    IContentTypeValue,
} from 'graphql/types';
import { PageDrawer, DrawerActionDropdown } from 'common/components/PageDrawer';
import { ApolloError } from 'common/components/ApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { TrainingForm, ITrainingFormProps } from 'training/components/forms';
import { Loader } from 'common/components/Loader';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { RouteLink } from 'route/components/RouteLink';
import { getUrl } from 'route/utils/getUrl';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { AuthorButton } from 'user/components/AuthorButton';

interface IProps {
    id?: string;
    open?: boolean;
    onClose?(): void;
}

export const TrainingUpdateDrawer = ({ id, open, onClose }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { trackMixpanel } = useMixpanel();
    const navigate = useNavigate();
    const { showApolloError } = useApolloError();

    const [showArchiveAlert, setShowArchiveAlert] = useState<boolean>(false);
    const [showCopyAlert, setShowCopyAlert] = useState<boolean>(false);

    const { data, loading, error } = useTrainingEditQuery({
        variables: { id },
        nextFetchPolicy: 'cache-only',
        returnPartialData: true,
    });

    const [updateTraining, { loading: loadingUpdate }] =
        useUpdateTrainingMutation();

    const [copyTraining, { loading: loadingCopy }] = useCopyTrainingMutation();

    const training = data?.training;

    if (!training) return null;

    const {
        image,
        title,
        titleColor,
        introduction,
        subtitle,
        overviewImage,
        estimatedTimeSpent,
        passThreshold,
        extraFields,
        startDate,
        endDate,
        isActive,
        showParticipants,
        enableChat,
        showSharedFiles,
        ref,
        syncWithAfas,
        modelId,
    } = training;

    const handleSubmit: ITrainingFormProps['onSubmit'] = async (values) => {
        let response;

        try {
            response = await updateTraining({
                variables: { id, training: values },
            });
        } catch (error) {
            showApolloError(error);
        }

        const updatedTraining = response?.data?.updateTraining?.training;

        if (!updatedTraining) return;

        await trackMixpanel({
            eventName: 'Update learning journey in drawer',
        });

        displaySnackbar(translate('trainingActionSuccess.update'), {
            variant: 'success',
        });

        // Redirect to the pathname to go to the page without hashtag.
        navigate(window.location.pathname);

        return;
    };

    const handleArchive = async () => {
        try {
            await updateTraining({
                variables: {
                    id,
                    training: { archived: true },
                },
                update: (cache) => {
                    cache.evict({
                        id: `Training:${training.id}`,
                    });
                    cache.gc();
                },
            });
        } catch (error) {
            // Close alert on error
            setShowArchiveAlert(false);
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('trainingActionSuccess.archive'), {
            variant: 'success',
        });

        // Redirect to home after archiving training
        navigate(getUrl('HOME'));
    };

    const handleCopy = async () => {
        let response;

        try {
            response = await copyTraining({
                variables: { id },
                update: (cache) => {
                    // Remove overviewTrainings from cache to force a renew of the query when opening Home
                    cache.evict({ fieldName: 'overviewTrainings' });
                    cache.evict({ fieldName: 'paginatedTrainings' });
                },
            });
        } catch (error) {
            // Close alert on error
            setShowCopyAlert(false);
            showApolloError(error);

            return;
        }

        const newTraining = response?.data?.copyTraining?.training;

        if (!newTraining) return;

        displaySnackbar(translate('trainingActionSuccess.copy'), {
            variant: 'success',
        });

        // Redirect to new copy after copying training
        gotoTrainingPage(navigate, newTraining.id);
    };

    if (loading || error) {
        return (
            <PageDrawer open={open}>
                {error && <ApolloError error={error} />}
                {loading && <Loader />}
            </PageDrawer>
        );
    }

    const drawerActions = (
        <Stack direction="row" spacing={1}>
            <AuthorButton
                contentType={IContentTypeValue.Training}
                objectId={training.id}
            />

            <DrawerActionDropdown>
                {modelId && (
                    <MenuItem
                        component={RouteLink}
                        params={{ id: modelId }}
                        to="MANAGEMENT_TRAINING"
                    >
                        {translate('toOldManagement')}
                    </MenuItem>
                )}
                <MenuItem onClick={() => setShowArchiveAlert(true)}>
                    {translate('toArchive')}
                </MenuItem>
                <MenuItem onClick={() => setShowCopyAlert(true)}>
                    {translate('copy')}
                </MenuItem>
            </DrawerActionDropdown>
        </Stack>
    );

    const initialValues = {
        image,
        title,
        titleColor,
        introduction,
        subtitle,
        overviewImage,
        estimatedTimeSpent,
        passThreshold,
        extraFields,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
        isActive,
        showParticipants,
        enableChat,
        showSharedFiles,
        ref,
        syncWithAfas,
    };

    return (
        <PageDrawer actions={drawerActions} open={open} onClose={onClose}>
            <PageTitle mixpanelTitle="Drawer update learning journey">
                {translate('editTraining')}
            </PageTitle>

            <TrainingForm
                edit
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="primary"
                            loading={loadingUpdate}
                            variant="contained"
                            onClick={handleArchive}
                        >
                            {translate('toArchive')}
                        </Button>
                        <Button
                            disabled={loadingUpdate}
                            variant="contained"
                            onClick={() => {
                                setShowArchiveAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showArchiveAlert}
                title={translate('archiveTrainingMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('archiveTrainingMessage.text')}
                </DialogContentText>
            </AlertDialog>
            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="primary"
                            loading={loadingCopy}
                            variant="contained"
                            onClick={handleCopy}
                        >
                            {translate('copy')}
                        </Button>
                        <Button
                            disabled={loadingCopy}
                            variant="contained"
                            onClick={() => {
                                setShowCopyAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showCopyAlert}
                title={translate('copyTrainingMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('copyTrainingMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
