import { translate } from 'utils/i18n';

export function getCalendarDescription(
    eventlocation: string,
    description?: string,
    webinarLocation?: string
) {
    const eventLink = `${translate(
        'eventLink'
    )}: <a href="${eventlocation}">${eventlocation}</a>`;
    const webinarLink = !!webinarLocation
        ? `<br /> ${translate(
              'webinarLink'
          )}: <a href="${webinarLocation}">${webinarLocation}</a>`
        : '';
    const spacing = !!description ? '<br /> <br />' : '';

    return `${description} ${spacing} ${eventLink} ${webinarLink}`;
}
