import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { TrainingSelector } from 'training/components/FormField/TrainingSelector';
import { CourseSelector } from 'training/components/FormField/CourseSelector';
import {
    ICourseListItemFragment,
    ILtiPlatformUpdateInput,
    ITrainingListItemFragment,
} from 'graphql/types';

type ILTIPlatformFormValues = ILtiPlatformUpdateInput &
    Record<'courses', ICourseListItemFragment[]> &
    Record<'trainings', ITrainingListItemFragment[]>;

export const AddForms = () => {
    const [translate] = useTranslation();
    const { values } = useFormikContext<ILTIPlatformFormValues>();
    const { allowAllMaterial } = values;

    return (
        <Box>
            <FormSection
                renderExpanded
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey="lTIPlatformForm.trainingsForm.trainingsDescription"
                        />
                    </Box>
                }
                title={translate('trainings')}
            >
                <Box mt={3}>
                    {allowAllMaterial &&
                        translate('lTIPlatformForm.trainingsForm.allowAll')}

                    {!allowAllMaterial && (
                        <TrainingSelector
                            name="trainings"
                            value={values.trainings}
                        />
                    )}
                </Box>
            </FormSection>

            <FormSection
                renderExpanded
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey="lTIPlatformForm.coursesForm.coursesDescription"
                        />
                    </Box>
                }
                title={translate('courses')}
            >
                <Box mt={3}>
                    {allowAllMaterial &&
                        translate('lTIPlatformForm.coursesForm.allowAll')}

                    {!allowAllMaterial && (
                        <CourseSelector
                            name="courses"
                            value={values.courses || []}
                        />
                    )}
                </Box>
            </FormSection>
        </Box>
    );
};
