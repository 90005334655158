import { mdiChevronRight } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemMediaDate,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link/Link';
import { getOfferDateDisplay } from 'offer/utils/dates';
import {
    IFeature,
    IFlatOfferEventDateFragment,
    IOfferEventOverviewListItemFragment,
    IOfferEventType,
    IRole,
    useCurrentUserQuery,
} from 'graphql/types';
import { Chip, ChipGroup, IconChip } from 'common/components/Chip';
import { getOfferEventTypeTranslation } from 'offer/utils/offerEvent';
import { OFFER_EVENT_TYPE_ICONS } from 'offer/constants/offerEvent';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { useFeature } from 'common/hooks/useFeature';

interface IProps extends IOfferEventOverviewListItemFragment {
    className?: string;
    description?: React.ReactNode;
    disabled?: boolean;
    isTrainerOrAuthor?: boolean;
    onClick?(): void;
}

const BaseOfferEventOverviewListItem = ({
    isTrainerOrAuthor,
    title,
    subtitle,
    firstDate,
    dates,
    extraCategoryStringLabels,
    trainers,
    enrolledCount,
    minSubscriptions,
    maxSubscriptions,
    type,
    image,
    publish,
    hasDetailPage,
    offerId,
    cancelled,
    extraTypes,
    full,
    usersWaiting,
    onClick,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { data: currentUserData } = useCurrentUserQuery();
    const { canUse: canUseScorm, loading: loadingCanUseScorm } = useFeature(
        IFeature.Scorm
    );
    const { canUse: canUseVideo, loading: loadingCanUseVideo } = useFeature(
        IFeature.Video
    );

    const currentUser = currentUserData?.currentUser;

    const isMeetingWebinar =
        type === IOfferEventType.Meeting || type === IOfferEventType.Webinar;

    const dateCount = dates?.length || 0;

    let mediaDate: IFlatOfferEventDateFragment | undefined;
    let itemTitle;

    let listItemMediaContent;
    const listItemMediaImage =
        !isMeetingWebinar && !!image?.url ? image.url : undefined;

    if (isMeetingWebinar) {
        mediaDate = firstDate || undefined;

        // If first date is not set, use the first date from the dates list
        if (!mediaDate) {
            const date = dates?.[0];

            mediaDate = !!date
                ? ({
                      __typename: date.__typename,
                      startDate: date.startDate,
                      endDate: date.endDate,
                  } as IFlatOfferEventDateFragment)
                : undefined;
        }

        listItemMediaContent = (
            <ListItemMediaDate date={mediaDate?.startDate} />
        );

        itemTitle =
            isMeetingWebinar && mediaDate
                ? getOfferDateDisplay(mediaDate, dateCount, true, true)
                : undefined;
    }

    const currentUserCanManage =
        currentUser?.roles?.includes(IRole.Owner) ||
        currentUser?.roles?.includes(IRole.Manager) ||
        isTrainerOrAuthor;

    const count = enrolledCount || 0;
    const minSubscriptionsNotMet =
        !!minSubscriptions && count < minSubscriptions;
    const showSubscriptionChip =
        (!!maxSubscriptions && !!enrolledCount) || !!minSubscriptions;
    const subscriptionChipColor =
        minSubscriptionsNotMet && currentUserCanManage ? 'error' : undefined;

    const locations = dates
        ?.map((date) => date?.location?.title || date.locationString)
        .filter(Boolean);
    const firstLocation = locations?.[0];
    const hasMultipleLocations = locations?.some(
        (location) => location !== firstLocation
    );

    const offerChips = (
        <ChipGroup>
            {extraTypes
                ?.map((extraType) => {
                    if (
                        (extraType === IOfferEventType.Scorm &&
                            (loadingCanUseScorm || !canUseScorm)) ||
                        (extraType === IOfferEventType.Video &&
                            (loadingCanUseVideo || !canUseVideo))
                    ) {
                        return null;
                    }

                    return (
                        <IconChip
                            iconPath={OFFER_EVENT_TYPE_ICONS[extraType]}
                            key={extraType}
                            tooltipTitle={getOfferEventTypeTranslation(
                                extraType
                            )}
                        />
                    );
                })
                .filter(Boolean)}

            {firstLocation && (
                <Chip
                    label={
                        !hasMultipleLocations
                            ? firstLocation
                            : translate('moreLocations', {
                                  location: firstLocation,
                              })
                    }
                />
            )}

            {trainers?.map((trainer, index) => (
                <Chip key={index} label={trainer?.name || ''} />
            ))}

            <ExtraCategoryLabels
                extraCategoryValues={extraCategoryStringLabels}
            />

            {showSubscriptionChip && (
                <Chip
                    bgColor={subscriptionChipColor}
                    label={
                        !!maxSubscriptions
                            ? translate('offerEventSubscriptionAmountMax', {
                                  count: enrolledCount || 0,
                                  subscriptionLimit: maxSubscriptions,
                              })
                            : translate('offerEventSubscriptionAmount', {
                                  count: enrolledCount || 0,
                              })
                    }
                />
            )}

            {!publish && (
                <Chip bgColor="warning" label={translate('concept')} />
            )}

            {cancelled && (
                <Chip bgColor="error" label={translate('cancelled')} />
            )}

            {full && !usersWaiting && (
                <Chip bgColor="error" label={translate('full')} />
            )}

            {usersWaiting && (
                <Chip
                    bgColor="warning"
                    label={translate(
                        'offerEventSubscriptionStatus.waitingListActive'
                    )}
                />
            )}
        </ChipGroup>
    );

    return (
        <ListItem button component={Link} px={0} onClick={onClick} {...other}>
            <ListItemMedia
                color="primary"
                image={listItemMediaImage}
                isText={!!mediaDate}
            >
                {!listItemMediaImage && listItemMediaContent}
            </ListItemMedia>

            <ListItemText
                heading={hasDetailPage && !!subtitle && subtitle}
                primary={title}
                secondary={itemTitle}
            >
                {offerChips}
            </ListItemText>

            <ListItemActionText>{translate('open')}</ListItemActionText>

            <ListItemSecondaryAction hideXs>
                <Icon path={mdiChevronRight} size="4rem" />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export const OfferEventOverviewListItem = styled(
    BaseOfferEventOverviewListItem
)`
    ${ListItemMedia} {
        min-height: 70px;
        min-width: 70px;
        padding: ${({ theme }) => theme.spacing(1)};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            min-height: 106px;
            min-width: 106px;
        }
    }
`;
