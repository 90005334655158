import { ApolloError as ErrorInstance } from '@apollo/client';

import { Snackbar } from 'common/components/Snackbar';
import { getErrorMessage } from 'common/utils/getErrorMessage';

interface IProps {
    error: ErrorInstance;
}

export const ApolloError = ({ error: { graphQLErrors } }: IProps) => (
    <>
        {!!graphQLErrors?.length &&
            graphQLErrors.map((error, index) => (
                <Snackbar open key={index} message={getErrorMessage(error)} />
            ))}
    </>
);
