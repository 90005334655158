import { Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { IContentTypeValue, usePortfolioReportQuery } from 'graphql/types';
import { BoxLoader } from 'common/components/Loader';
import { TrainingParticipantsCertificateTableRow } from 'training/components/TrainingParticipantsCertificateTable';

interface IProps {
    trainingId: string;
    userId: string;
    onCertificatesLoad?(hasCertificates: boolean): void;
}

export const TrainingParticipantsCertificateTable = ({
    trainingId,
    userId,
    onCertificatesLoad,
}: IProps) => {
    const [translate] = useTranslation();

    const { data: reportData, loading } = usePortfolioReportQuery({
        variables: {
            relatedObjectTypes: [IContentTypeValue.ReceivedCertificate],
            relatedObjectTrainingIds: [trainingId],
            userIds: [userId],
        },
    });

    const certificates =
        reportData?.portfolioReport?.edges.filter(Boolean) || [];

    useEffect(() => {
        if (loading || !onCertificatesLoad) return;

        onCertificatesLoad(!!certificates.length);
    }, [onCertificatesLoad, loading, certificates]);

    if (loading) return <BoxLoader />;

    if (!certificates.length) return null;

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3} sx={{ pt: 3 }}>
                            <Typography
                                sx={{ fontWeight: 700 }}
                                variant="body2"
                            >
                                {translate('certificates')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {certificates.map(({ node: certificate }) => {
                        if (!certificate) return;

                        return (
                            <TrainingParticipantsCertificateTableRow
                                certificateItem={certificate}
                                key={certificate.id}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
