import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { useEditor } from 'slate-react';
import {
    ToolbarButton,
    insertTable,
    deleteTable,
    addRow,
    deleteRow,
    addColumn,
    deleteColumn,
    ToolbarButtonProps,
    TableOptions,
    unwrapList,
} from '@udecode/slate-plugins';

import { options } from '../../options';

interface IProps extends ToolbarButtonProps, TableOptions {}

export const ToolbarTable = ({ table, ...other }: IProps) => {
    const { t: translate } = useTranslation();
    const editor = useEditor();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ToolbarButton
                onMouseDown={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
                {...other}
            />
            <Menu
                keepMounted
                anchorEl={anchorEl}
                id="simple-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        insertTable(editor, other);

                        // Reset list to prevent table in list
                        unwrapList(editor, options);
                        handleClose();
                    }}
                >
                    {translate('editor.insertTable')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        deleteTable(editor, other);
                        handleClose();
                    }}
                >
                    {translate('editor.deleteTable')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        addRow(editor, other);
                        handleClose();
                    }}
                >
                    {translate('editor.addRow')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        deleteRow(editor, other);
                        handleClose();
                    }}
                >
                    {translate('editor.deleteRow')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        addColumn(editor, other);
                        handleClose();
                    }}
                >
                    {translate('editor.addColumn')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        deleteColumn(editor, other);
                        handleClose();
                    }}
                >
                    {translate('editor.deleteColumn')}
                </MenuItem>
            </Menu>
        </>
    );
};
