import { useApolloClient } from '@apollo/client';

import { useApolloError } from 'common/hooks/useApolloError';
import {
    IOfferEventSubscriptionStatus,
    useCreateCertificateForEnrollmentsMutation,
    useDeleteOfferEventEnrollmentsMutation,
    useUpdateOfferEventEnrollmentsMutation,
} from 'graphql/types';

export const useEnrollmentsManagementMutations = () => {
    const { showApolloError } = useApolloError();
    const client = useApolloClient();
    const [updateEnrollment, { loading: updateEnrollmentLoading }] =
        useUpdateOfferEventEnrollmentsMutation();
    const [deleteEnrollments, { loading: deleteEnrollmentsLoading }] =
        useDeleteOfferEventEnrollmentsMutation();
    const [createCertificates, { loading: createCertificatesLoading }] =
        useCreateCertificateForEnrollmentsMutation();

    const evictEnrollmentsFromCache = (skipForEvent?: boolean) => {
        client.cache.evict({
            fieldName: 'manageableOfferEventEnrollments',
        });

        if (!skipForEvent) {
            client.cache.evict({
                fieldName: 'manageableOfferEventEnrollmentsForEvent',
            });
        }

        client.cache.evict({
            fieldName: 'paginatedOfferEvents',
        });

        client.cache.gc();
    };

    const handleUpdateEnrollments = async (
        enrollmentIds: string[],
        status: IOfferEventSubscriptionStatus,
        reason: string,
        generateCertificate?: boolean
    ) => {
        try {
            await updateEnrollment({
                variables: {
                    offerEventEnrollmentIds: enrollmentIds,
                    status,
                    reason,
                },
            });

            if (generateCertificate) {
                await createCertificates({
                    variables: {
                        offerEventEnrollmentIds: enrollmentIds,
                    },
                });
            }
        } catch (error) {
            showApolloError(error);

            return;
        }

        evictEnrollmentsFromCache(true);

        return;
    };

    const handleDeleteEnrollments = async (enrollmentIds: string[]) => {
        try {
            await deleteEnrollments({
                variables: {
                    offerEventEnrollmentIds: enrollmentIds,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        evictEnrollmentsFromCache();

        return;
    };

    return {
        onUpdateEnrollments: handleUpdateEnrollments,
        onDeleteEnrollments: handleDeleteEnrollments,
        loading:
            deleteEnrollmentsLoading ||
            createCertificatesLoading ||
            updateEnrollmentLoading,
    };
};
