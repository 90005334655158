import { styled } from 'styled-components';

export const DrawerHeaderImage = styled.img`
    width: 100%;
    display: none;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: block;
    }
`;
