import { useState } from 'react';
import {
    mdiChevronRight,
    mdiMinus,
    mdiPlus,
    mdiSchool,
    mdiDotsVertical,
    mdiTransitConnectionVariant,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { MenuItem, CircularProgress, Box } from '@mui/material';

import {
    useCourseStudentTokenLazyQuery,
    useCourseManagerTokenLazyQuery,
    useCourseTrainerTokenLazyQuery,
    useDeleteCourseMutation,
    ICourseFragment,
    ICourseListItemFragment,
    CourseDocument,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
    ListItemFooter,
    ListItemButton,
} from 'common/components/ListItem';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Chip, ChipGroup } from 'common/components/Chip';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { CourseCopyAction } from 'training/actions/CourseCopyAction';
import { Tooltip } from 'common/components/Tooltip';
import { useFrontendPermissions } from 'user/hooks';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    selected?: boolean;
    course: ICourseListItemFragment | ICourseFragment;
    showActions?: boolean;
    footer?: React.ReactNode;
    loading?: boolean;
    hideCategoryChips?: boolean;
    hideChips?: boolean;
    /** User id to open course trainer view for */
    forUserId?: string;
    linkToTrainerView?: boolean;
    onClick?(course: ICourseListItemFragment | ICourseFragment): void;
}

export const CourseListItem = ({
    addButton,
    added,
    disabled,
    selected,
    course,
    onClick,
    showActions,
    footer,
    loading,
    hideCategoryChips,
    hideChips,
    forUserId,
    linkToTrainerView,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const { canUpdate: canUpdateCourse } = useFrontendPermissions('course');

    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const [getCourseManagerToken, { loading: loadingManagerToken }] =
        useCourseManagerTokenLazyQuery();

    const [getCourseStudentToken, { loading: loadingStudentToken }] =
        useCourseStudentTokenLazyQuery();

    const [getTrainerToken, { loading: loadingTrainerToken }] =
        useCourseTrainerTokenLazyQuery();

    const [deleteCourse, { loading: loadingDelete }] =
        useDeleteCourseMutation();

    const loadingAction =
        loading || loadingManagerToken || loadingStudentToken || loadingDelete;

    const {
        id,
        title,
        image,
        thumbnail,
        publishedVersionNumber,
        extraCategoryValues,
        trainingCount,
        offerEventCount,
    } = course;

    const listItemImage = thumbnail?.url || image?.url || '';

    let mediaIconPath = mdiSchool;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }
    const hasIcon = (disabled && !listItemImage) || !disabled;
    const versionChipLabel = translate(
        publishedVersionNumber ? 'version' : 'draft',
        { version: publishedVersionNumber }
    );

    const openCourseEdit = async () => {
        // Get url without params
        const returnUrl = window.location.href;
        let url: string | undefined;

        try {
            const response = await getCourseManagerToken({
                variables: { id, returnUrl },
            });

            url = response.data?.courseManagerToken?.url;
        } catch (error) {
            showApolloError(error);

            return;
        }

        if (!url) return;

        window.location.href = url;
    };

    const openCourseView = async () => {
        const returnUrl = window.location.href;
        let url: string | undefined;

        try {
            const response = await getCourseStudentToken({
                variables: { id, returnUrl },
            });

            url = response.data?.courseStudentToken?.url;
        } catch (error) {
            showApolloError(error);

            return;
        }

        if (!url) return;

        window.location.href = url;
    };

    const openCourseTrainerView = async () => {
        const returnUrl = window.location.href;

        let url: string | undefined;

        try {
            const response = await getTrainerToken({
                variables: { id, returnUrl, forUser: forUserId },
            });

            url = response.data?.courseTrainerToken?.url;
        } catch (error) {
            showApolloError(error);

            return;
        }

        if (!url) return;

        window.location.href = url;
    };

    const handleDeleteCourse = async () => {
        try {
            await deleteCourse({
                variables: {
                    id,
                },
                update: (cache) => {
                    // Remove course list from cache so it will be refetched
                    cache.evict({ fieldName: 'courses' });

                    // Use writeQuery to update course query cache so it's properly removed
                    cache.writeQuery({
                        query: CourseDocument,
                        data: { course: null },
                        variables: { id },
                    });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);
            setShowDeleteAlert(false);

            return;
        }

        displaySnackbar(translate('courseActionSuccess.delete'), {
            variant: 'success',
        });

        setShowDeleteAlert(false);
    };

    const itemActionMenu = showActions && (
        <DropdownMenu
            anchor={
                <IconButton
                    color="inherit"
                    iconPath={mdiDotsVertical}
                    iconSize="2.4rem"
                />
            }
            placement="bottom-end"
        >
            {/* Only show "view" item if course is published */}
            {!!publishedVersionNumber && (
                <MenuItem disabled={loadingAction} onClick={openCourseView}>
                    {translate('view')}
                </MenuItem>
            )}
            <MenuItem disabled={loadingAction} onClick={openCourseEdit}>
                {translate('edit')}
            </MenuItem>
            <CourseCopyAction id={id}>
                {({ copyCourse, loading: loadingCopy }) => (
                    <MenuItem
                        disabled={loadingAction || loadingCopy}
                        onClick={copyCourse}
                    >
                        {translate('copy')}
                    </MenuItem>
                )}
            </CourseCopyAction>
            <MenuItem
                disabled={loadingAction}
                onClick={() => {
                    setShowDeleteAlert(true);
                }}
            >
                {translate('delete')}
            </MenuItem>
        </DropdownMenu>
    );

    const showActionText = !itemActionMenu && !addButton && !disabled;

    let actionText = (
        <>
            <ListItemActionText>{translate('open')}</ListItemActionText>
            <ListItemSecondaryAction>
                <Icon path={mdiChevronRight} size="3rem" />
            </ListItemSecondaryAction>
        </>
    );

    if (loading || loadingTrainerToken) {
        // Show progress icon when loading
        actionText = (
            <ListItemSecondaryAction>
                <CircularProgress color="primary" size={30} />
            </ListItemSecondaryAction>
        );
    }

    const baseContent = (
        <>
            <ListItemMedia color="primary" image={listItemImage} size="small">
                {hasIcon && (
                    <Icon path={mediaIconPath} size="2rem" sizeSm="3rem" />
                )}
            </ListItemMedia>

            <ListItemText primary={title}>
                {!hideChips && canUpdateCourse && (
                    <ChipGroup>
                        <Chip
                            bgColor={
                                publishedVersionNumber ? undefined : 'error'
                            }
                            label={versionChipLabel}
                        />
                        {!!trainingCount && (
                            <Tooltip
                                title={translate<string>('trainingCount', {
                                    count: trainingCount,
                                })}
                            >
                                <Box>
                                    <Chip
                                        icon={
                                            <Icon
                                                path={
                                                    mdiTransitConnectionVariant
                                                }
                                                size="1.4rem"
                                            />
                                        }
                                        label={trainingCount}
                                        size="small"
                                    />
                                </Box>
                            </Tooltip>
                        )}
                        {!!offerEventCount && (
                            <Tooltip
                                title={translate<string>('offerEventCount', {
                                    count: offerEventCount,
                                })}
                            >
                                <Box>
                                    <Chip
                                        icon={
                                            <Icon
                                                path={mdiSchool}
                                                size="1.4rem"
                                            />
                                        }
                                        label={offerEventCount}
                                        size="small"
                                    />
                                </Box>
                            </Tooltip>
                        )}
                        {!hideCategoryChips && (
                            <ExtraCategoryLabels
                                extraCategoryValues={extraCategoryValues}
                            />
                        )}
                    </ChipGroup>
                )}
            </ListItemText>

            {showActionText && actionText}
        </>
    );

    return (
        <>
            <ListItem
                button={!disabled}
                key={id}
                px={0}
                selected={selected}
                {...other}
            >
                {disabled ? (
                    baseContent
                ) : (
                    <ListItemButton
                        onClick={() => {
                            onClick?.(course);

                            // If linkToTrainerView is true, open course trainer view
                            linkToTrainerView && openCourseTrainerView();
                        }}
                    >
                        {baseContent}
                    </ListItemButton>
                )}

                {itemActionMenu && (
                    <ListItemSecondaryAction hideXs>
                        {itemActionMenu}
                    </ListItemSecondaryAction>
                )}

                {footer && <ListItemFooter>{footer}</ListItemFooter>}
            </ListItem>

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDelete}
                open={showDeleteAlert}
                title={translate('deleteCourseMessage.title')}
                onCancel={() => {
                    setShowDeleteAlert(false);
                }}
                onClose={() => setShowDeleteAlert(false)}
                onConfirm={handleDeleteCourse}
            >
                {translate('deleteCourseMessage.text')}
            </ConfirmDialog>
        </>
    );
};
