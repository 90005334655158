import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { mdiFileRemove } from '@mdi/js';
import { useState } from 'react';

import { BoxLoader } from 'common/components/Loader';
import {
    TableActionBar,
    TableCell,
    TableHead,
    useSelection,
} from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';
import { useSkillList } from 'skill/hooks/useSkillList';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import { Checkbox } from 'common/components/Checkbox';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useDeleteSkillsMutation } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';

import { SkillsManagementTableRow } from './SkillsManagementTableRow';

export const SkillsManagementTable = () => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteSkills, { loading: deleteSkillsLoading }] =
        useDeleteSkillsMutation();

    const {
        skills,
        loading,
        paginationSettings,
        filterValues,
        setPage,
        onSearch,
    } = useSkillList();
    const { ...selectionProps } = useSelection(skills);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const { isSelected, rows, selected, onSelectAll } = selectionProps;

    const tableActions = (
        <Tooltip title={translate('skillsManagementPage.deleteSkills')}>
            <Box>
                <IconButton
                    disabled={!isSelected}
                    iconPath={mdiFileRemove}
                    iconSize="2.4rem"
                    onClick={() => setShowDeleteDialog(true)}
                />
            </Box>
        </Tooltip>
    );

    const resetSettings = () => {
        setPage(1);
        handleSearch('');

        if (!!selected?.length) onSelectAll?.();
    };

    const handleDelete = async () => {
        try {
            const response = await deleteSkills({
                variables: { ids: selected },
                update: (cache) => {
                    cache.evict({ fieldName: 'skills' });
                },
            });

            const { bulkDelete } = response.data?.deleteSkills || {};

            const failedIds = bulkDelete?.failedIds || [];
            const succeededIds = bulkDelete?.succeededIds || [];

            if (failedIds?.length) {
                displaySnackbar(
                    translate('skillsManagementPage.deleteFailed', {
                        count: failedIds.length,
                    }),
                    {
                        variant: 'error',
                    }
                );
            }

            if (succeededIds.length) {
                displaySnackbar(
                    translate('skillsManagementPage.deleteSuccess', {
                        count: succeededIds.length,
                    }),
                    {
                        variant: 'success',
                    }
                );
            }
        } catch (error) {
            showApolloError(error);
        }

        setShowDeleteDialog(false);

        resetSettings();
    };

    return (
        <>
            <FilterBar
                disabled={loading}
                initialSearchValue={filterValues.q}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />

            {loading ? (
                <BoxLoader />
            ) : (
                <>
                    <Box sx={{ mt: 2 }}>
                        {!skills.length ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 7,
                                }}
                            >
                                <Typography>
                                    {translate('noOptionsText.skills')}
                                </Typography>
                            </Box>
                        ) : (
                            <TableContainer>
                                <TableActionBar
                                    actions={tableActions}
                                    selectionProps={selectionProps}
                                />

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={false}
                                                    disabled={isSelected}
                                                    onChange={onSelectAll}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                {translate('name')}
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows?.map(
                                            ({
                                                index,
                                                onSelect,
                                                isSelected,
                                            }) => {
                                                const skill = skills[index];

                                                if (!skill) return null;

                                                return (
                                                    <SkillsManagementTableRow
                                                        isSelected={isSelected}
                                                        key={skill.id}
                                                        skill={skill}
                                                        onSelect={onSelect}
                                                    />
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Pagination
                            page={paginationSettings.page}
                            pageAmount={paginationSettings.pageAmount}
                            totalsAmount={paginationSettings.count}
                            totalsText={translate('skill', {
                                count: !paginationSettings.count
                                    ? 0
                                    : paginationSettings.count,
                            })}
                            onChange={(page: number) => {
                                setPage(page);
                            }}
                        />
                    </Box>

                    <ConfirmDialog
                        confirmText={translate('delete')}
                        loading={deleteSkillsLoading}
                        open={showDeleteDialog}
                        title={translate('deleteSkillMessage.title', {
                            count: selected.length,
                        })}
                        onCancel={() => {
                            setShowDeleteDialog(false);
                        }}
                        onClose={() => setShowDeleteDialog(false)}
                        onConfirm={handleDelete}
                    >
                        {translate('deleteSkillMessage.text', {
                            count: selected.length,
                        })}
                    </ConfirmDialog>
                </>
            )}
        </>
    );
};
