import { useParams } from 'react-router-dom';

import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { TRouteName } from 'route/types';

interface IState {
    activeSectionRoute: TRouteName;
}

export const useUserRoutes = (): IState => {
    const { id: userId } = useParams();

    // Portfolio
    const portfolioProfile = !!useRouteMatch('PORTFOLIO');
    const portfolioUser = !!useRouteMatch('USER_PORTFOLIO');
    const customPorfolioItemCreate = !!useRouteMatch('PORTFOLIO_ITEM_CREATE');
    const customPortfolioItemEdit = !!useRouteMatch('PORTFOLIO_ITEM_EDIT');
    const customPortfolioItemView = !!useRouteMatch('USER_PORTFOLIO_ITEM_VIEW');
    const certificatePortfolioItemView = !!useRouteMatch('PORTFOLIO_ITEM_VIEW');
    const portfolioGroupCreate = !!useRouteMatch('PORTFOLIO_GROUP_CREATE');
    const portfolioGroupEdit = !!useRouteMatch('PORTFOLIO_GROUP_EDIT');
    const portfolioTrainings = !!useRouteMatch('PORTFOLIO_TRAININGS');
    const portfolioOffers = !!useRouteMatch('PORTFOLIO_OFFERS');
    const portfolioMeetings = !!useRouteMatch('PORTFOLIO_MEETINGS');
    const userTrainings = !!useRouteMatch('USER_TRAININGS');
    const userOffers = !!useRouteMatch('USER_OFFERS');
    const userMeetings = !!useRouteMatch('USER_MEETINGS');

    // Development plan
    const developmentPlanProfile = !!useRouteMatch('DEVELOPMENT_PLAN');
    const developmentPlanUser = !!useRouteMatch('USER_DEVELOPMENT_PLAN');
    const developmentItemCreate = !!useRouteMatch('DEVELOPMENT_ITEM_CREATE');
    const developmentItemEdit = !!useRouteMatch('DEVELOPMENT_ITEM_EDIT');
    const developmentItemView = !!useRouteMatch('USER_DEVELOPMENT_ITEM_VIEW');
    const developmentGroupCreate = !!useRouteMatch('DEVELOPMENT_GROUP_CREATE');
    const developmentGroupEdit = !!useRouteMatch('DEVELOPMENT_GROUP_EDIT');

    const getActiveSectionRoute = (): TRouteName => {
        let activeSectionRoute = 'PROFILE';

        if (
            portfolioProfile ||
            portfolioUser ||
            customPorfolioItemCreate ||
            customPortfolioItemEdit ||
            customPortfolioItemView ||
            certificatePortfolioItemView ||
            portfolioGroupCreate ||
            portfolioGroupEdit
        ) {
            activeSectionRoute = userId ? 'USER_PORTFOLIO' : 'PORTFOLIO';
        }

        if (
            developmentPlanProfile ||
            developmentPlanUser ||
            developmentItemCreate ||
            developmentItemEdit ||
            developmentItemView ||
            developmentGroupCreate ||
            developmentGroupEdit
        ) {
            activeSectionRoute = userId
                ? 'USER_DEVELOPMENT_PLAN'
                : 'DEVELOPMENT_PLAN';
        }

        if (portfolioTrainings || userTrainings) {
            activeSectionRoute = userId
                ? 'USER_TRAININGS'
                : 'PORTFOLIO_TRAININGS';
        }

        if (portfolioOffers || userOffers) {
            activeSectionRoute = userId ? 'USER_OFFERS' : 'PORTFOLIO_OFFERS';
        }

        if (portfolioMeetings || userMeetings) {
            activeSectionRoute = userId
                ? 'USER_MEETINGS'
                : 'PORTFOLIO_MEETINGS';
        }

        return activeSectionRoute as TRouteName;
    };

    const activeSectionRoute = getActiveSectionRoute();

    return { activeSectionRoute };
};
