import { Box, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiDotsVertical, mdiAccountEdit } from '@mdi/js';
import { useState } from 'react';
import { useApolloClient } from '@apollo/client';

import {
    useExtraUserFieldsQuery,
    IUserExtraFieldFragment,
    useOrganisationQuery,
    useUpdateUserMutation,
    useUserQuery,
} from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { Divider } from 'common/components/Divider';
import { InfoRow } from 'common/components/InfoRow';
import { UserPreview } from 'user/components/UserPreview';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { Loader } from 'common/components/Loader';
import { DrawerModuleSection } from 'common/components/DrawerModuleSection';
import { Typography } from 'common/components/Typography';
import { useCanManageUser } from 'user/hooks';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Icon } from 'common/components/Icon';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useApolloError } from 'common/hooks/useApolloError';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { NotFound } from 'common/components/NotFound';
import { UserActionBar } from 'user/UserActionBar';
import { UserExtendedInfoRows } from 'user/components/UserProfileDrawer/UserExtendedInfoRows';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { IUpdateGroupProps } from 'user/components/GroupUpdateDrawer';

export interface IProps extends IPageDrawerProps {
    groupProps?: IUpdateGroupProps;
    open?: boolean;
    userId: string;
    onArchive?(): void;
    onUpdate?(): void;
}

export const UserProfileDrawer = ({
    groupProps,
    open,
    userId,
    onArchive,
    onUpdate,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [unArchiveDialogOpen, setUnarchiveDialogOpen] = useState(false);
    const { showApolloError } = useApolloError();
    const client = useApolloClient();

    const { data: extraUserFieldsData } = useExtraUserFieldsQuery();

    // Reduce extraUserfields array to object
    const extraUserFields: { [key: string]: IUserExtraFieldFragment } = (
        extraUserFieldsData?.extraUserFields || []
    ).reduce((acc, field) => ({ ...acc, [field.name]: field }), {});

    const {
        canManageUser,
        canEditUser,
        isCurrentUser,
        loading: loadingCanManageUser,
    } = useCanManageUser(userId);

    const {
        data: userData,
        loading: userLoading,
        error: userError,
    } = useUserQuery({
        variables: { id: userId },
    });
    const { data: organisationData, loading: organisationLoading } =
        useOrganisationQuery();
    const { openGlobalDrawer, closeGlobalDrawer } = useGlobalDrawer();
    const [updateUser, { loading: updateUserLoading }] =
        useUpdateUserMutation();

    const isGroupEditPage = !!useRouteMatch('GROUP_EDIT');

    if (!userLoading && !!userError) {
        return (
            <PageDrawer open={open}>
                <NotFound />
            </PageDrawer>
        );
    }

    const { user } = userData || {};

    const {
        profileImage,
        name,
        email,
        roles,
        language,
        extraFieldsProfile,
        notifications,
        automatedMessages,
        isActive,
        participantGroups,
        managerGroups,
    } = user || {};

    const { organisation } = organisationData || {};

    const extraCategories = !!extraFieldsProfile
        ? JSON.parse(extraFieldsProfile)
        : undefined;
    const extraFieldsInfoRows =
        !!extraCategories && !!extraUserFields
            ? Object.keys<Record<string, string>>(extraCategories)
                  .map((fieldKey) => {
                      let value = extraCategories[fieldKey];
                      const fieldData = extraUserFields[fieldKey];

                      if (
                          !fieldData ||
                          (!canManageUser &&
                              !isCurrentUser &&
                              !fieldData?.public)
                      ) {
                          return null;
                      }

                      if (Array.isArray(value)) {
                          return (
                              <InfoRow
                                  description={fieldKey}
                                  key={`${fieldKey}-${value}`}
                              >
                                  {value.join(', ')}
                              </InfoRow>
                          );
                      }

                      if (fieldData.__typename === 'UserExtraCheckboxField') {
                          value = translate(value ? 'yes' : 'no');
                      }

                      return (
                          <InfoRow
                              description={fieldKey}
                              key={`${fieldKey}-${value}`}
                          >
                              {value}
                          </InfoRow>
                      );
                  })
                  .filter(Boolean)
            : undefined;

    const loading = userLoading || organisationLoading || loadingCanManageUser;

    const handleArchiveUser = async () => {
        try {
            await updateUser({
                variables: {
                    id: userId,
                    user: {
                        isActive: false,
                    },
                },
                update: (cache) => {
                    cache.evict({
                        id: `User:${userId}`,
                    });

                    // TODO: Dirty fix, need to find a solution to update UI when user is archived
                    if (isGroupEditPage) {
                        client.refetchQueries({
                            include: ['GroupUsers', 'GroupManagers'],
                        });
                    }

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        setArchiveDialogOpen(false);

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserProfile,
        });

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserUpdate,
        });

        displaySnackbar(translate('userActionSuccess.archive'), {
            variant: 'success',
        });

        onArchive?.();

        return;
    };

    const handleUnarchiveUser = async () => {
        try {
            await updateUser({
                variables: {
                    id: userId,
                    user: {
                        isActive: true,
                    },
                },
                update: (cache) => {
                    cache.evict({
                        id: `User:${userId}`,
                    });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        setArchiveDialogOpen(false);

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserProfile,
        });

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserUpdate,
        });

        displaySnackbar(translate('userActionSuccess.unarchive'), {
            variant: 'success',
        });

        onArchive?.();

        return;
    };

    // Explicitly check on false value because we don't want to show it when it's null
    const isInactive = isActive === false;

    const userEditMenuItem = (
        <MenuItem
            onClick={() =>
                openGlobalDrawer({
                    type: IGlobalDrawerType.UserUpdate,
                    itemId: userId,
                    props: {
                        onArchive: () => setArchiveDialogOpen(true),
                        onUpdate,
                    },
                })
            }
        >
            {translate('editUser')}
        </MenuItem>
    );

    const showUserActionBar = !isInactive || (isInactive && canEditUser);

    return (
        <PageDrawer open={open} {...other}>
            {(!user || loading) && <Loader />}

            {!!user && !loading && (
                <Box>
                    <DrawerModuleSection>
                        <UserPreview
                            editable={false}
                            name="profileImage"
                            profileImage={profileImage}
                        />
                    </DrawerModuleSection>

                    {showUserActionBar && (
                        <UserActionBar
                            hideProfile
                            hideChat={isInactive}
                            hideEmail={isInactive}
                            hidePortfolio={isInactive}
                            userId={user.id}
                        >
                            {isInactive && canEditUser && (
                                <>
                                    <DropdownMenu
                                        anchor={
                                            <Button
                                                startIcon={
                                                    <Icon
                                                        path={mdiDotsVertical}
                                                        size="1.9rem"
                                                    />
                                                }
                                                variant="outlined"
                                            >
                                                {translate('actions')}
                                            </Button>
                                        }
                                        placement="bottom-end"
                                    >
                                        {userEditMenuItem}

                                        <Box>
                                            <Divider sx={{ py: 1 }} />

                                            <MenuItem
                                                onClick={() =>
                                                    setUnarchiveDialogOpen(true)
                                                }
                                            >
                                                {translate('unarchiveUser')}
                                            </MenuItem>
                                        </Box>
                                    </DropdownMenu>

                                    <ConfirmDialog
                                        confirmText={translate(
                                            'userForm.userUnarchiveDialog.buttonText'
                                        )}
                                        loading={updateUserLoading}
                                        open={unArchiveDialogOpen}
                                        title={translate(
                                            'userForm.userUnarchiveDialog.title'
                                        )}
                                        onCancel={() =>
                                            setUnarchiveDialogOpen(false)
                                        }
                                        onClose={() =>
                                            setUnarchiveDialogOpen(false)
                                        }
                                        onConfirm={handleUnarchiveUser}
                                    >
                                        {translate(
                                            'userForm.userUnarchiveDialog.text'
                                        )}
                                    </ConfirmDialog>
                                </>
                            )}

                            {!isInactive && (
                                <>
                                    {!isCurrentUser && canEditUser && (
                                        <>
                                            <DropdownMenu
                                                anchor={
                                                    <Button
                                                        startIcon={
                                                            <Icon
                                                                path={
                                                                    mdiDotsVertical
                                                                }
                                                                size="1.9rem"
                                                            />
                                                        }
                                                        variant="outlined"
                                                    >
                                                        {translate('actions')}
                                                    </Button>
                                                }
                                                placement="bottom-end"
                                            >
                                                {userEditMenuItem}

                                                <Box>
                                                    <Divider sx={{ py: 1 }} />

                                                    <MenuItem
                                                        onClick={() =>
                                                            setArchiveDialogOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        {translate(
                                                            'archiveUser'
                                                        )}
                                                    </MenuItem>
                                                </Box>
                                            </DropdownMenu>

                                            <ConfirmDialog
                                                confirmText={translate(
                                                    'userForm.userArchiveDialog.buttonText'
                                                )}
                                                loading={updateUserLoading}
                                                open={archiveDialogOpen}
                                                title={translate(
                                                    'userForm.userArchiveDialog.title'
                                                )}
                                                onCancel={() =>
                                                    setArchiveDialogOpen(false)
                                                }
                                                onClose={() =>
                                                    setArchiveDialogOpen(false)
                                                }
                                                onConfirm={handleArchiveUser}
                                            >
                                                {translate(
                                                    'userForm.userArchiveDialog.text'
                                                )}
                                            </ConfirmDialog>
                                        </>
                                    )}

                                    {isCurrentUser && (
                                        <Button
                                            startIcon={
                                                <Icon
                                                    path={mdiAccountEdit}
                                                    size="1.9rem"
                                                />
                                            }
                                            sx={{ flex: 1 }}
                                            variant="outlined"
                                            onClick={() => {
                                                openGlobalDrawer({
                                                    type: IGlobalDrawerType.UserUpdate,
                                                    itemId: userId,
                                                    props: {
                                                        onArchive: () =>
                                                            setArchiveDialogOpen(
                                                                true
                                                            ),
                                                    },
                                                });
                                            }}
                                        >
                                            {translate('editProfile')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </UserActionBar>
                    )}

                    <Typography
                        sx={{ px: { xs: 2, sm: 4 }, py: 4 }}
                        variant="h3"
                    >
                        {name}
                    </Typography>

                    <Stack spacing={2} sx={{ px: { xs: 2, sm: 4 }, pb: 3 }}>
                        {isInactive && (
                            <InfoRow description={translate('status')}>
                                <Box sx={{ color: 'error.main' }}>
                                    {translate('archived')}
                                </Box>
                            </InfoRow>
                        )}

                        {(canManageUser || isCurrentUser) && !!email && (
                            <InfoRow description={translate('email')}>
                                <Link href={`mailto:${email}`}>{email}</Link>
                            </InfoRow>
                        )}

                        {!!participantGroups?.length && (
                            <InfoRow description={translate('groups')}>
                                {participantGroups.map((participantGroup) => {
                                    const { id, name, permissions } =
                                        participantGroup;
                                    const permissionsObj = JSON.parse(
                                        permissions || '{}'
                                    );

                                    if (permissionsObj.canUpdate) {
                                        return (
                                            <Link
                                                key={id}
                                                sx={{
                                                    textDecoration: 'underline',
                                                    display: 'block',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    openGlobalDrawer({
                                                        type: IGlobalDrawerType.Group,
                                                        itemId: id,
                                                        props: {
                                                            ...groupProps,
                                                            onClose: () =>
                                                                closeGlobalDrawer(
                                                                    {
                                                                        type: IGlobalDrawerType.Group,
                                                                    }
                                                                ),
                                                        },
                                                    });
                                                }}
                                            >
                                                {name}
                                            </Link>
                                        );
                                    }

                                    return <Box key={id}>{name}</Box>;
                                })}
                            </InfoRow>
                        )}

                        {!!managerGroups?.length && (
                            <InfoRow description={translate('managerGroups')}>
                                {managerGroups.map((managerGroup) => {
                                    const { id, name, permissions } =
                                        managerGroup;
                                    const permissionsObj = JSON.parse(
                                        permissions || '{}'
                                    );

                                    if (permissionsObj.canUpdate) {
                                        return (
                                            <Link
                                                key={id}
                                                sx={{
                                                    textDecoration: 'underline',
                                                    display: 'block',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    openGlobalDrawer({
                                                        type: IGlobalDrawerType.Group,
                                                        itemId: id,
                                                        props: {
                                                            ...groupProps,
                                                            onClose: () =>
                                                                closeGlobalDrawer(
                                                                    {
                                                                        type: IGlobalDrawerType.Group,
                                                                    }
                                                                ),
                                                        },
                                                    });
                                                }}
                                            >
                                                {name}
                                            </Link>
                                        );
                                    }

                                    return <Box key={id}>{name}</Box>;
                                })}
                            </InfoRow>
                        )}

                        {(canManageUser || isCurrentUser) &&
                            !!roles?.length && (
                                <InfoRow description={translate('rolesTitle')}>
                                    {roles
                                        .map((role) =>
                                            translate(
                                                `roles.${role?.toLowerCase()}`
                                            )
                                        )
                                        .join(`, `)}
                                </InfoRow>
                            )}

                        {!!language && language !== organisation?.language && (
                            <InfoRow description={translate('language')}>
                                {translate(
                                    `languages.${language.toLowerCase()}`
                                )}
                            </InfoRow>
                        )}

                        {extraFieldsInfoRows}

                        {(canManageUser || isCurrentUser) &&
                            !!notifications && (
                                <InfoRow
                                    description={translate(
                                        'notificationsTitle'
                                    )}
                                >
                                    {`${translate(
                                        `userNotifications.${notifications.toLowerCase()}`
                                    )}${automatedMessages ? ',' : ''}`}

                                    <Box>
                                        {automatedMessages &&
                                            translate(
                                                'userNotifications.automatedMessages'
                                            )}
                                    </Box>
                                </InfoRow>
                            )}

                        {canManageUser && (
                            <UserExtendedInfoRows userId={userId} />
                        )}
                    </Stack>
                </Box>
            )}
        </PageDrawer>
    );
};
