import { useEffect } from 'react';

import {
    IUserListItemFragment,
    IUserSelectorInput,
    IUserSelectorQueryVariables,
    useAuthorsLazyQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { IContentQueryVariables } from 'common/types';

export interface IUserList {
    authors: IUserListItemFragment[];
    alreadyAddedAuthors: string[];
    authorsCount?: number | null;
    loading: boolean;
}

export const useAuthorList = (
    queryVariables?: IUserSelectorQueryVariables,
    contentQueryVariables?: IContentQueryVariables,
    selectorQueryVariables?: IUserSelectorInput
): IUserList => {
    const { showApolloError } = useApolloError();
    const [fetchAuthors, { data, loading }] = useAuthorsLazyQuery({
        onError: showApolloError,
    });

    useEffect(() => {
        if (!queryVariables) return;

        fetchAuthors({
            variables: {
                q: queryVariables.q,
                offset: queryVariables.offset,
                ...queryVariables,
                ...contentQueryVariables,
                selector: selectorQueryVariables,
            },
        });
    }, [
        queryVariables,
        contentQueryVariables,
        selectorQueryVariables,
        fetchAuthors,
    ]);

    const authorsObject = data?.authors;
    const authorsCount = authorsObject?.count;
    const authors =
        (authorsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IUserListItemFragment[]) || [];
    const alreadyAddedAuthors =
        authorsObject?.edges
            .map((edge) => {
                if (edge?.alreadyAddedToObject) return edge.node.id;

                return null;
            })
            .filter(Boolean) || [];

    return {
        authors,
        alreadyAddedAuthors,
        authorsCount,
        loading,
    };
};
