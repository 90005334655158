import { styled } from 'styled-components';

export const AccordionMedia = styled.div`
    padding: ${({ theme }) => theme.spacing(0.5)};

    img {
        max-width: 100%;
        display: block;
    }
`;
