import { mdiAccountRemove } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { IUserListItemFragment } from 'graphql/types';
import { UserTable } from 'user/components/UserTable';
import { IUserTableData } from 'user/hooks/useUserTableData';

interface IProps {
    addedAuthors: IUserListItemFragment[];
    isEditable?: boolean;
    loading?: boolean;
    tableDataProps?: IUserTableData['tableDataProps'];
    onRemoveAuthors?(selectedAuthors: string[]): void;
}

export const AddedAuthorsTable = ({
    addedAuthors,
    isEditable,
    loading,
    tableDataProps,
    onRemoveAuthors,
}: IProps) => {
    const [translate] = useTranslation();

    const { paginationProps, searchProps, selectionProps } =
        tableDataProps || {};

    const tableActions = isEditable
        ? [
              {
                  tooltipTitle: translate('authorSelector.deleteAuthors'),
                  iconPath: mdiAccountRemove,
                  onClick: (selectedItems: string[]) => {
                      onRemoveAuthors?.(selectedItems);
                  },
              },
          ]
        : [];

    return (
        <UserTable
            inDrawer
            isEditable={isEditable}
            loading={loading}
            noResultsLabel={translate('noResults.authors')}
            paginationProps={paginationProps}
            paginationTranslationKey="author"
            searchProps={searchProps}
            selectionProps={selectionProps}
            tableActions={tableActions}
            users={addedAuthors}
        />
    );
};
