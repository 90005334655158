import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import VIDEO_MODULE_DRAWER_HEADER from 'assets/images/video-module-drawer-header.webp';
import { VideoFilterAddList } from 'video/components/VideoFilterAddList';
import { IVideoListItemFragment } from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { Button } from 'common/components/Button';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import { Loader } from 'common/components/Loader';
import { VideoUpload } from 'video/components/VideoUpload';

interface IProps {
    edit?: boolean;
    open?: boolean;
    inDrawer?: boolean;
    onClose?(): void;
    onSelect?(video: IVideoListItemFragment): void;
}

export const VideoModuleSelectDrawer = ({
    edit,
    inDrawer = true,
    open,
    onClose,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();
    const [uploadEnabled, setUploadEnabled] = useState(true);

    const titleCreate = translate(
        `moduleForm.video.${
            edit ? 'editTitleVideoCreate' : 'newTitleVideoCreate'
        }`
    );
    const textCreate = translate(
        `moduleForm.video.${
            edit ? 'editTextVideoCreate' : 'newTextVideoCreate'
        }`
    );

    const titleSelect = translate(
        `moduleForm.video.${
            edit ? 'editTitleVideoSelect' : 'newTitleVideoSelect'
        }`
    );
    const textSelect = translate(
        `moduleForm.video.${
            edit ? 'editTextVideoSelect' : 'newTextVideoSelect'
        }`
    );

    const drawerContent = open ? (
        <>
            <DrawerHeaderImage src={VIDEO_MODULE_DRAWER_HEADER} />

            <Box p={{ xs: 2, sm: 4 }}>
                <Box mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleCreate}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textCreate}</Typography>
                    </Box>
                    <Box mt={2}>
                        <VideoUpload
                            multiple
                            disabled={!uploadEnabled}
                            id="newVideo"
                            onError={() => {
                                // When upload fails we can enable the upload again
                                setUploadEnabled(true);
                            }}
                            onStart={() => {
                                setUploadEnabled(false);
                            }}
                            onStartUpload={() => {
                                // When upload is actually started we can enable the upload again
                                setUploadEnabled(true);
                            }}
                        >
                            <Button
                                color="primary"
                                component="span"
                                loading={!uploadEnabled}
                                variant="contained"
                            >
                                {translate(
                                    'moduleForm.video.createNewVideoButton'
                                )}
                            </Button>
                        </VideoUpload>
                    </Box>
                </Box>

                <Divider />

                <Box my={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleSelect}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textSelect}</Typography>
                    </Box>
                </Box>

                <VideoFilterAddList
                    listItemProps={{ px: 0, showActions: true }}
                    selectMultiple={false}
                    onSelect={onSelect}
                />
            </Box>
        </>
    ) : (
        <Loader />
    );

    if (!inDrawer) return drawerContent;

    return (
        <PageDrawer open={open} onClose={onClose}>
            {drawerContent}
        </PageDrawer>
    );
};
