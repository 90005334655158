import React, { useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiChevronRight, mdiLaptopAccount, mdiMicrosoftTeams } from '@mdi/js';
import { useLocation, useNavigate } from 'react-router-dom';

import { Text } from 'common/components/Text';
import {
    IOfferEventDetailFragment,
    IOfferEventSubscriptionStatus,
    IOfferEventType,
    IOfferEventEnrollmentCurrentUserFragment,
    useCourseStudentTokenLazyQuery,
    useCurrentUserQuery,
    IFeature,
} from 'graphql/types';
import { List } from 'common/components/List';
import { Link } from 'common/components/Link';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Icon } from 'common/components/Icon';
import { TrainingListItem } from 'training/components/TrainingListItem';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { ExternalContentListItem } from 'external/components/ExternalContentListItem';
import { FormSection } from 'common/components/FormSection';
import { useApolloError } from 'common/hooks/useApolloError';
import { CourseListItem } from 'training/components/CourseListItem';
import { getOfferEventDateTime } from 'offer/utils/dates';
import { ScormListItem } from 'training/components/ScormListItem';
import { getScormUrl } from 'training/utils/scorm';
import { useFeature } from 'common/hooks/useFeature';
import { VideoListItem } from 'video/components/VideoListItem';
import { VideoPlayerDialog } from 'video/components/VideoPlayerDialog';
import { Embed } from 'common/components/Embed';

interface IProps {
    canUpdate: boolean;
    currentUserEnrollment?: IOfferEventEnrollmentCurrentUserFragment;
    offerEvent: IOfferEventDetailFragment;
    showDivider?: boolean;
    onClickItem?(callback?: () => Promise<void>): Promise<void>;
}

export const DescriptionSection = ({
    canUpdate,
    currentUserEnrollment,
    offerEvent,
    onClickItem,
}: IProps) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { showApolloError } = useApolloError();
    const [showVideoDialog, setShowVideoDialog] = useState<{
        embedUrl?: string;
        title?: string;
    }>({});
    const { data: currentUserData } = useCurrentUserQuery();
    const { canUse: canUseScorm, loading: loadingCanUseScorm } = useFeature(
        IFeature.Scorm
    );
    const { canUse: canUseVideo, loading: loadingCanUseVideo } = useFeature(
        IFeature.Video
    );

    const currentUser = currentUserData?.currentUser;

    const [loadingItem, setLoadingItem] = useState(false);

    const [getCourseStudentToken, { loading: loadingCourse }] =
        useCourseStudentTokenLazyQuery({
            onCompleted: (data) => {
                const url = data.courseStudentToken?.url;

                if (!url) return;

                window.location.href = url;
            },
        });

    const {
        accessTrainings,
        accessExternalContents,
        accessCourses,
        descriptionAfterSubscription,
        descriptionBeforeSubscription,
        type,
        trainers: trainerConnection,
        offer: { title: offerTitle },
        firstDate,
        accessScorms,
        accessVideos,
    } = offerEvent;

    const { webinarLink, isServiceProviderWebinar } = firstDate || {};

    const trainers =
        trainerConnection?.edges.map((edge) => edge?.node).filter(Boolean) ||
        [];

    const isWebinar = type === IOfferEventType.Webinar;
    const isMeeting = type === IOfferEventType.Meeting;
    const trainings = accessTrainings.edges || [];
    const externalContents = accessExternalContents.edges;
    const courses = accessCourses.edges;
    const scorms = accessScorms.edges;
    const videos = accessVideos.edges;

    const isEnrolled =
        !!currentUserEnrollment &&
        [
            IOfferEventSubscriptionStatus.Enrolled,
            IOfferEventSubscriptionStatus.Completed,
            IOfferEventSubscriptionStatus.Absent,
        ].includes(currentUserEnrollment.status);

    const isTrainer = !!trainers.some(
        (trainer) => trainer.id === currentUser?.id
    );

    const trainingsCount = trainings.length;
    const externalContentsCount = externalContents.length;
    const coursesCount = courses.length;
    const scormsCount = scorms.length;
    const videosCount = videos.length;

    const hasTrainings = !!trainingsCount;
    const hasExternalContents = !!externalContentsCount;
    const hasCourses = !!coursesCount;
    const hasScorms = !!scormsCount;
    const hasVideos = !!videosCount;

    const itemsCount =
        trainingsCount +
        externalContentsCount +
        coursesCount +
        scormsCount +
        videosCount;
    const canSubscribeViaItem = itemsCount === 1 && !isWebinar && !isMeeting;

    // Show after description if the user is enrolled and it has a value, otherwise show the before description
    const participantDescription =
        isEnrolled && descriptionAfterSubscription
            ? descriptionAfterSubscription
            : descriptionBeforeSubscription;
    const canViewWebinar =
        (isWebinar || isMeeting) && (isEnrolled || canUpdate || isTrainer);

    const showItemsList =
        hasTrainings ||
        hasExternalContents ||
        hasCourses ||
        (canViewWebinar && !!webinarLink) ||
        hasScorms ||
        hasVideos;

    const hasDescription =
        !!descriptionBeforeSubscription ||
        ((isEnrolled || canUpdate) && !!descriptionAfterSubscription);

    const hasDescriptionSectionContent =
        hasDescription || !!participantDescription || showItemsList;

    if (!hasDescriptionSectionContent) return null;

    const showFullVideo =
        !loadingCanUseVideo &&
        itemsCount === 1 &&
        hasVideos &&
        (isEnrolled || canUpdate || isTrainer);
    const fullVideoUrl = showFullVideo ? videos[0]?.node?.embedUrl : undefined;
    const canViewVideos = !loadingCanUseVideo && canUseVideo && !showFullVideo;

    return (
        <Stack useFlexGap divider={<Divider />} spacing={{ xs: 2, sm: 3 }}>
            {canUpdate && hasDescription && (
                <Box sx={{ my: { xs: -2, sm: -3 } }}>
                    {!!descriptionBeforeSubscription && (
                        <FormSection
                            sx={[
                                !descriptionAfterSubscription && {
                                    borderBottom: 'none',
                                },
                            ]}
                            title={translate(
                                'offer.descriptionBeforeSubscription'
                            )}
                        >
                            <Box sx={{ mt: 1 }}>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: descriptionBeforeSubscription,
                                    }}
                                    variant="body1"
                                />
                            </Box>
                        </FormSection>
                    )}
                    {!!descriptionAfterSubscription && (
                        <FormSection
                            sx={{ borderBottom: 'none' }}
                            title={translate(
                                'offer.descriptionAfterSubscription'
                            )}
                        >
                            <Box
                                sx={{
                                    mt: 1,
                                }}
                            >
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: descriptionAfterSubscription,
                                    }}
                                    variant="body1"
                                />
                            </Box>
                        </FormSection>
                    )}
                </Box>
            )}

            {!canUpdate && !!participantDescription && (
                <Text
                    dangerouslySetInnerHTML={{
                        __html: participantDescription,
                    }}
                    variant="body1"
                />
            )}

            {showItemsList && (
                <Box>
                    <List noBorder noPadding>
                        {canViewWebinar && firstDate && !!webinarLink && (
                            <ListItem
                                button
                                component={Link}
                                href={webinarLink}
                                px={0}
                                py={1.5}
                                target="_blank"
                            >
                                <ListItemMedia color="primary" size="small">
                                    <Icon
                                        path={
                                            !!isServiceProviderWebinar
                                                ? mdiMicrosoftTeams
                                                : mdiLaptopAccount
                                        }
                                        size="2rem"
                                        sizeSm="3rem"
                                    />
                                </ListItemMedia>

                                <ListItemText
                                    primary={offerTitle}
                                    secondary={getOfferEventDateTime(firstDate)}
                                />
                                <ListItemActionText>
                                    {translate('open')}
                                </ListItemActionText>
                                <ListItemSecondaryAction>
                                    <Icon path={mdiChevronRight} size="3rem" />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                        {trainings.map((trainingEdge) => {
                            if (!trainingEdge?.node) return;

                            const training = trainingEdge.node;
                            const { isVisibleForUsers } = training;

                            return (
                                <TrainingListItem
                                    disabled={
                                        !canUpdate &&
                                        !isTrainer &&
                                        !isEnrolled &&
                                        !canSubscribeViaItem
                                    }
                                    key={`detail-${training.id}`}
                                    loading={loadingItem}
                                    py={1.5}
                                    showNotVisibleLabel={
                                        canUpdate && !isVisibleForUsers
                                    }
                                    training={training}
                                    onClick={async () => {
                                        setLoadingItem(true);

                                        const itemCallback = async () => {
                                            gotoTrainingPage(
                                                navigate,
                                                training.id,
                                                location
                                            );
                                        };

                                        await onClickItem?.(itemCallback);
                                    }}
                                />
                            );
                        })}
                        {externalContents.map((externalContentEdge) => {
                            if (!externalContentEdge?.node) return;

                            const externalContent = externalContentEdge.node;

                            return (
                                <ExternalContentListItem
                                    hideCategoryChips
                                    disabled={
                                        !canUpdate &&
                                        !isTrainer &&
                                        !isEnrolled &&
                                        !canSubscribeViaItem
                                    }
                                    externalContent={externalContent}
                                    key={`detail-${externalContent.id}`}
                                    loading={loadingItem}
                                    py={1.5}
                                    onClick={async () => {
                                        setLoadingItem(true);

                                        const itemCallback = async () => {
                                            setLoadingItem(false);

                                            window.open(
                                                externalContent.link,
                                                '_blank'
                                            );
                                        };

                                        // On click is async so we have the possiblity to wait for some
                                        // action to be completed before opening the link
                                        await onClickItem?.(itemCallback);
                                    }}
                                />
                            );
                        })}
                        {courses.map((courseEdge) => {
                            if (!courseEdge?.node) return;

                            const course = courseEdge.node;
                            const disabled =
                                !canUpdate &&
                                !isTrainer &&
                                !isEnrolled &&
                                !canSubscribeViaItem &&
                                !loadingItem;

                            const openCourse = async () => {
                                if (disabled || loadingCourse) return;

                                const returnUrl = window.location.href;

                                try {
                                    await getCourseStudentToken({
                                        variables: {
                                            id: course.id,
                                            returnUrl,
                                        },
                                    });
                                } catch (error) {
                                    showApolloError(error);

                                    return;
                                }
                            };

                            return (
                                <CourseListItem
                                    hideCategoryChips
                                    course={course}
                                    disabled={disabled}
                                    key={`detail-${course.id}`}
                                    loading={loadingItem}
                                    py={1.5}
                                    onClick={async () => {
                                        setLoadingItem(true);

                                        // On click is async so we have the possiblity to wait for some
                                        // action to be completed before opening the link
                                        await onClickItem?.(openCourse);
                                    }}
                                />
                            );
                        })}

                        {!loadingCanUseScorm &&
                            canUseScorm &&
                            scorms.map((scormEdge) => {
                                if (!scormEdge?.node) return;

                                const scorm = scormEdge.node;

                                return (
                                    <ScormListItem
                                        disabled={
                                            !canUpdate &&
                                            !isTrainer &&
                                            !isEnrolled &&
                                            !canSubscribeViaItem
                                        }
                                        key={`detail-${scorm.id}`}
                                        loading={loadingItem}
                                        py={1.5}
                                        scorm={scorm}
                                        onClick={async () => {
                                            setLoadingItem(true);

                                            const itemCallback = async () => {
                                                window.location.href =
                                                    getScormUrl(scorm.id);
                                            };

                                            // On click is async so we have the possiblity to wait for some
                                            // action to be completed before opening the link
                                            await onClickItem?.(itemCallback);
                                        }}
                                    />
                                );
                            })}

                        {canViewVideos &&
                            videos.map((videoEdge) => {
                                if (!videoEdge?.node) return;

                                const video = videoEdge.node;
                                const { id, title, embedUrl } = video;

                                return (
                                    <React.Fragment key={`detail-${id}`}>
                                        <VideoListItem
                                            disabled={
                                                !canUpdate &&
                                                !isTrainer &&
                                                !isEnrolled &&
                                                !canSubscribeViaItem
                                            }
                                            loading={loadingItem}
                                            py={1.5}
                                            video={video}
                                            onClick={async () => {
                                                if (!isEnrolled && !canUpdate) {
                                                    setLoadingItem(true);
                                                }

                                                const itemCallback =
                                                    async () => {
                                                        if (!embedUrl) return;

                                                        setShowVideoDialog({
                                                            embedUrl,
                                                            title,
                                                        });
                                                    };

                                                // On click is async so we have the possiblity to wait for some
                                                // action to be completed before opening the link
                                                await onClickItem?.(
                                                    itemCallback
                                                );
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })}

                        {canViewVideos && (
                            <VideoPlayerDialog
                                open={!!showVideoDialog.embedUrl}
                                src={showVideoDialog.embedUrl || ''}
                                title={showVideoDialog.title}
                                onClose={() => setShowVideoDialog({})}
                            />
                        )}

                        {!!fullVideoUrl && (
                            <Embed autoplay src={fullVideoUrl} />
                        )}
                    </List>
                </Box>
            )}
        </Stack>
    );
};
