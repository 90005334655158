import { RenderElementProps } from 'slate-react';
import { HtmlAttributesProps } from '@udecode/slate-plugins';
import { Box } from '@mui/material';

import { Blockquote } from 'common/components/Blockquote';

interface IProps extends RenderElementProps, HtmlAttributesProps {
    className?: string;
}
export const EditorBlockquote = ({
    children,
    attributes,
    htmlAttributes,
    className,
}: IProps) => (
    <div className={className} {...attributes}>
        <Box className={className} mb={2}>
            <Blockquote {...htmlAttributes}>{children}</Blockquote>
        </Box>
    </div>
);
