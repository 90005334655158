import { useEffect } from 'react';

import {
    ICertificateTypeListItemFragment,
    ICertificatesQueryVariables,
    useCertificatesLazyQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export const useCertificateList = (
    queryVariables?: ICertificatesQueryVariables
) => {
    const { showApolloError } = useApolloError();
    const [fetchCertificates, { data, loading }] = useCertificatesLazyQuery({
        onError: showApolloError,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!queryVariables) return;

        fetchCertificates({
            variables: queryVariables,
        });
    }, [queryVariables, fetchCertificates]);

    const certificatesObject = data?.certificates;
    const certificatesCount = certificatesObject?.count;
    const certificates =
        (certificatesObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as ICertificateTypeListItemFragment[]) || [];

    return {
        certificates,
        certificatesCount,
        loading,
    };
};
