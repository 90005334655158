import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { Typography } from 'common/components/Typography';
import { PageTitle } from 'common/components/PageTitle';
import { Loader } from 'common/components/Loader';
import { useFeature } from 'common/hooks/useFeature';
import { IFeature } from 'graphql/types';
import { DrawerUpgradeSection } from 'common/components/DrawerUpgradeSection';
import { Divider } from 'common/components/Divider';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import DRAWER_HEADER from 'assets/images/sso-drawer-header.webp';

import { LTIPlatformManager } from '../LTIPlatformManager';
import { OpenIdManager } from '../OpenIdManager';

export const SingleSignOnDrawer = ({ open }: IPageDrawerProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const {
        canUse: canUseLTI,
        userCanSeeUpgrade: userCanSeeUpgradeLTI,
        loading: loadingLTI,
    } = useFeature(IFeature.Lti);
    const {
        canUse: canUseOpenId,
        userCanSeeUpgrade: userCanSeeUpgradeOpenId,
        loading: loadingOpenId,
    } = useFeature(IFeature.OpenId);

    if (!open || loadingLTI || loadingOpenId) {
        return (
            <PageDrawer open={open}>
                <Loader />
            </PageDrawer>
        );
    }

    // Redirect to the pathname to go to the page without hashtag.
    const handleClose = () => navigate(window.location.pathname);

    const showLTIUpgrade = !canUseLTI && userCanSeeUpgradeLTI;
    const showOpenIdUpgrade = !canUseOpenId && userCanSeeUpgradeOpenId;

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <DrawerHeaderImage src={DRAWER_HEADER} />

            <PageTitle mixpanelTitle="Drawer Single Sign On">
                {translate('singleSignOnPageTitle')}
            </PageTitle>

            <Box p={{ xs: 2, sm: 4 }}>
                <Box mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {translate('singleSignOn')}
                    </Typography>

                    <Box mt={2}>
                        <Typography>{translate('singleSignOnText')}</Typography>
                    </Box>
                </Box>

                {showLTIUpgrade && (
                    <>
                        <Box mb={4}>
                            <Divider />
                        </Box>

                        <DrawerUpgradeSection
                            description={translate(
                                'singleSignOnUpgrade.lTIDescription'
                            )}
                            hideButtons={showOpenIdUpgrade}
                            title={translate('singleSignOnUpgrade.lTITitle')}
                        />
                    </>
                )}

                {canUseLTI && <LTIPlatformManager />}

                {showOpenIdUpgrade && (
                    <>
                        <Box mb={4} mt={5}>
                            <Divider />
                        </Box>

                        <DrawerUpgradeSection
                            description={translate(
                                'singleSignOnUpgrade.OpenIdDescription'
                            )}
                            title={translate('singleSignOnUpgrade.OpenIdTitle')}
                        />
                    </>
                )}

                {canUseOpenId && (
                    <Box mt={4}>
                        <OpenIdManager />
                    </Box>
                )}
            </Box>
        </PageDrawer>
    );
};
