import MuiCard, { CardProps } from '@mui/material/Card';

import { withSxProp } from 'common/utils/props';

export const Card = ({ sx, ...other }: CardProps) => (
    <MuiCard
        sx={[
            {
                position: 'relative',
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: 'none',
                height: '100%',
            },

            ...withSxProp(sx),
        ]}
        {...other}
    />
);
