import { split, HttpLink, InMemoryCache } from '@apollo/client';
import { ApolloClient } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import generatedIntrospection from './introspectionResult';

// Check if mock is used
const useMock = import.meta.env.VITE_GRAPHQL_URL?.includes(':4123');

const httpLink = new HttpLink({
    uri: import.meta.env.VITE_GRAPHQL_URL,
    credentials: useMock ? 'same-origin' : 'include',
});

const wsLink = new WebSocketLink({
    uri:
        import.meta.env.VITE_GRAPHQL_WS_URL ||
        `wss://${window.location.hostname}/graphql/`,
    options: {
        reconnect: true,
        lazy: true,
    },
});

// Combines the http and ws link into one link and uses one
// or the other according to the type of operation being executed.
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);

        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

export const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        possibleTypes: generatedIntrospection.possibleTypes,
        typePolicies: {
            LibraryCollection: {
                fields: {
                    access: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            PortfolioItemGroup: {
                fields: {
                    portfolioItems: {
                        keyArgs: false,
                    },
                },
            },
            DevelopmentItemGroup: {
                fields: {
                    developmentItems: {
                        merge: false,
                    },
                },
            },
            DevelopmentItem: {
                fields: {
                    files: {
                        merge: false,
                    },
                },
            },
            PortfolioItem: {
                fields: {
                    files: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                    scoring: {
                        merge: false,
                    },
                },
            },
            IndividualAssignment: {
                fields: {
                    files: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            CollectiveAssignment: {
                fields: {
                    files: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            UserAssignment: {
                fields: {
                    files: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            Discussion: {
                fields: {
                    comments: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            Subscription: {
                fields: {
                    updatedComment: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            Training: {
                fields: {
                    sharedFiles: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                    conditions: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            LinkModule: {
                fields: {
                    conditions: {
                        // Don't merge field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            Offer: {
                fields: {
                    groupConditions: {
                        merge: false,
                    },
                },
            },
            OfferEvent: {
                fields: {
                    dates: {
                        // Don't merge field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            File: {
                keyFields: ['url'],
            },
            Plan: {
                keyFields: ['name'],
            },
            Organisation: {
                keyFields: ['slug'],
                fields: {
                    theme: {
                        merge: false,
                    },
                },
            },
            Group: {
                fields: {
                    extraCategoryValues: {
                        merge: false,
                    },
                    users: {
                        merge: false,
                    },
                    managers: {
                        merge: false,
                    },
                    trainings: {
                        merge: false,
                    },
                },
            },
            ExtraCategory: {
                fields: {
                    choiceOptions: {
                        merge: false,
                    },
                },
            },
            ExtraCategoryBooleanValue: {
                keyFields: ['category', ['id'], 'modelId'],
            },
            ExtraCategoryChoiceValue: {
                keyFields: [
                    'category',
                    ['id'],
                    'modelId',
                    'choiceValue',
                    ['id'],
                ],
            },
            ExtraCategoryDatetimeValue: {
                keyFields: ['category', ['id'], 'modelId'],
            },
            ExtraCategoryStringValue: {
                keyFields: ['category', ['id'], 'modelId'],
            },
            GroupCondition: {
                merge(_existing, incoming) {
                    return incoming;
                },
            },
            ModuleGroup: {
                fields: {
                    conditions: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                    fullConditions: {
                        // Don't merge access field on update, always use incoming version
                        merge: false,
                    },
                },
            },
            ExtraCategorySearch: {
                keyFields: ['id', 'value'],
            },
            Course: {
                fields: {
                    extraCategoryValues: {
                        merge: false,
                    },
                },
            },
            User: {
                fields: {
                    participantGroups: {
                        merge: false,
                    },
                    managerGroups: {
                        merge: false,
                    },
                },
            },
            Skill: {
                fields: {
                    groups: {
                        merge: false,
                    },
                    certificates: {
                        merge: false,
                    },
                },
            },
            Query: {
                fields: {
                    overviewTrainings: {
                        keyArgs: ['q', 'extraFields', 'offset', 'role'],
                        merge(existing, incoming, { args }) {
                            // If no after cursor is set we know its a first request
                            // So we then want to start over with building the cache
                            if (!existing || !args?.after) return incoming;

                            const newEdges = [
                                ...existing.edges,
                                ...incoming.edges,
                            ];

                            return {
                                ...existing,
                                ...incoming,
                                edges: newEdges,
                            };
                        },
                    },
                    offers: {
                        keyArgs: ['q', 'extraCategories', 'types', 'forRoles'],
                        merge(existing, incoming, { args }) {
                            // If no after cursor is set we know its a first request
                            // So we then want to start over with building the cache
                            if (!existing || !args?.after) return incoming;

                            const newEdges = [
                                ...existing.edges,
                                ...incoming.edges,
                            ];

                            return {
                                ...existing,
                                ...incoming,
                                edges: newEdges,
                            };
                        },
                    },
                    paginatedOfferEvents: {
                        keyArgs: [
                            'q',
                            'extraCategories',
                            'types',
                            'forRoles',
                            'offset',
                            'fromDate',
                            'enrollmentStatus',
                            'ids',
                        ],
                        merge(existing, incoming, { args }) {
                            // If no after cursor is set we know its a first request
                            // So we then want to start over with building the cache
                            if (!existing || !args?.after) return incoming;

                            const newEdges = [
                                ...existing.edges,
                                ...incoming.edges,
                            ];

                            return {
                                ...existing,
                                ...incoming,
                                edges: newEdges,
                            };
                        },
                    },
                    moduleGroup: {
                        // This will make sure that when ModuleGroup is already in the cache and we use a different
                        // query it will also be found.
                        read(_, { args, toReference }) {
                            if (!args) return;

                            return toReference({
                                __typename: 'ModuleGroup',
                                id: args.id,
                            });
                        },
                    },
                    portfolio: {
                        merge(existing, incoming) {
                            if (!existing) return incoming;

                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                    group: {
                        merge(existing, incoming) {
                            if (!existing) return incoming;

                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                    externalContents: {
                        keyArgs: ['q', 'extraCategories', 'offset'],
                        merge(existing, incoming, { args }) {
                            // If no after cursor is set we know its a first request
                            // So we then want to start over with building the cache
                            if (!existing || !args?.after) return incoming;

                            const newEdges = [
                                ...existing.edges,
                                ...incoming.edges,
                            ];

                            return {
                                ...existing,
                                ...incoming,
                                edges: newEdges,
                            };
                        },
                    },
                    authors: {
                        merge(existing, incoming) {
                            if (!existing) return incoming;

                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                    trainingPortfolioReports: {
                        merge: false,
                    },
                },
            },
        },
    }),
    resolvers: {},
});
