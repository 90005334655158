import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IScoringFragment,
    useReceivedCertificateLazyQuery,
} from 'graphql/types';
import { CertificatePreview } from 'training/components/CertificatePreview';
import { formatDate } from 'common/utils/formatDate';

import { DrawerModuleSection } from '../DrawerModuleSection';
import { Loader } from '../Loader';
import { PageDrawer } from '../PageDrawer';
import { Typography } from '../Typography';
import { IPageDrawerProps } from '../PageDrawer/PageDrawer';
import { ApolloError } from '../ApolloError';
import { Chip, ChipGroup } from '../Chip';
import { ScoringChips } from '../ScoringChips';

interface IProps extends IPageDrawerProps {
    certificateId?: string;
    loading?: boolean;
    scoring?: IScoringFragment[];
    trainingTitle?: string;
}

export const CertificateDrawer = ({
    certificateId,
    loading,
    scoring,
    title,
    trainingTitle,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [
        fetchCertificate,
        {
            data: certificateData,
            error: certificateError,
            loading: certificateLoading,
        },
    ] = useReceivedCertificateLazyQuery();

    useEffect(() => {
        if (!certificateId) return;

        fetchCertificate({ variables: { id: certificateId } });
    }, [certificateId, fetchCertificate]);

    const certificate = certificateData?.receivedCertificate;

    const {
        title: certificateTitle,
        subtitle,
        date,
        expiryDate,
        isExpired,
    } = certificate || {};

    const isLoading = certificateLoading || loading;

    const hasChips = isExpired || !!scoring?.length;

    return (
        <PageDrawer {...other} title={title || certificateTitle}>
            {certificateError && <ApolloError error={certificateError} />}

            {isLoading && <Loader />}

            {!isLoading && certificate && (
                <>
                    <DrawerModuleSection>
                        <CertificatePreview {...certificate} />
                    </DrawerModuleSection>

                    <Box p={{ xs: 2, sm: 4 }}>
                        {hasChips && (
                            <Box mb={2}>
                                <ChipGroup>
                                    {isExpired && (
                                        <Chip
                                            bgColor="warning"
                                            label={translate('expired')}
                                        />
                                    )}

                                    <ScoringChips
                                        color="default"
                                        scoring={scoring}
                                    />
                                </ChipGroup>
                            </Box>
                        )}

                        {trainingTitle && (
                            <Typography
                                gutterBottom
                                sx={{ fontWeight: 'medium' }}
                                variant="h2"
                            >
                                {trainingTitle}
                            </Typography>
                        )}

                        <Box>
                            {certificateTitle && (
                                <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 'medium' }}
                                    variant="h3"
                                >
                                    {certificateTitle}
                                </Typography>
                            )}

                            {subtitle && (
                                <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 'medium' }}
                                    variant="h3"
                                >
                                    {subtitle}
                                </Typography>
                            )}
                        </Box>

                        <Box my={2}>
                            <Typography color="text.secondary">
                                {formatDate(date, 'dd MMMM yyyy')}{' '}
                                {expiryDate
                                    ? ` - ${formatDate(
                                          expiryDate,
                                          'dd MMMM yyyy'
                                      )}`
                                    : ''}
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}
        </PageDrawer>
    );
};
