import { Box, ChipProps, SxProps } from '@mui/material';

import { Icon } from 'common/components/Icon';
import { Tooltip } from 'common/components/Tooltip';

import { Chip } from './Chip';

const typeChipStyle: SxProps = {
    width: 28,
    height: 28,
    padding: '5px',
    backgroundColor: 'primary.main',
    borderRadius: '50%',
    color: 'primary.contrastText',

    '.MuiChip-icon': {
        margin: 0,
        color: 'primary.contrastText',
    },

    '.MuiChip-label': {
        display: 'none',
    },
};

interface IProps extends Omit<ChipProps, 'size'> {
    iconPath: string;
    size?: string;
    tooltipTitle?: string;
}

export const IconChip = ({
    iconPath,
    size,
    tooltipTitle,
    ...other
}: IProps) => (
    <Tooltip title={tooltipTitle}>
        <Box>
            <Chip
                icon={<Icon path={iconPath} size={size || '2.2rem'} />}
                sx={typeChipStyle}
                {...other}
            />
        </Box>
    </Tooltip>
);
