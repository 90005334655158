import { useCurrentUserQuery } from 'graphql/types';
import { TFrontendRole } from 'user/types';

export enum ObjectAction {
    read = 'read',
    create = 'create',
    update = 'update',
    delete = 'delete',
}

type IRoleObjectPermissions = { [key in ObjectAction]: TFrontendRole[] };
type IRolePermissions = { [key: string]: Partial<IRoleObjectPermissions> };

const permissions: IRolePermissions = {
    libraryCollection: {
        create: [TFrontendRole.Author, TFrontendRole.Manager],
        update: [TFrontendRole.Author, TFrontendRole.Manager],
        delete: [TFrontendRole.Author, TFrontendRole.Manager],
    },
    libraryArticle: {
        create: [TFrontendRole.Author, TFrontendRole.Manager],
        update: [TFrontendRole.Author, TFrontendRole.Manager],
        delete: [TFrontendRole.Author, TFrontendRole.Manager],
    },
    training: {
        create: [
            TFrontendRole.Manager,
            TFrontendRole.Planner,
            TFrontendRole.Author,
        ],
        update: [TFrontendRole.Manager, TFrontendRole.Planner],
        delete: [TFrontendRole.Manager, TFrontendRole.Planner],
    },
    organisation: {
        update: [TFrontendRole.Manager, TFrontendRole.Owner],
        delete: [TFrontendRole.Owner],
    },
    offer: {
        create: [TFrontendRole.Author, TFrontendRole.Manager],
        update: [TFrontendRole.Author, TFrontendRole.Manager],
        delete: [TFrontendRole.Author, TFrontendRole.Manager],
    },
    ltiDeepLink: {
        update: [
            TFrontendRole.Author,
            TFrontendRole.Planner,
            TFrontendRole.Manager,
        ],
    },
    helpdesk: {
        read: [
            TFrontendRole.Owner,
            TFrontendRole.Manager,
            TFrontendRole.Planner,
        ],
    },
    portfolioSettings: {
        update: [TFrontendRole.Owner, TFrontendRole.Manager],
    },
    organisationSettings: {
        update: [TFrontendRole.Manager],
    },
    video: {
        create: [TFrontendRole.Author, TFrontendRole.Manager],
        update: [TFrontendRole.Author, TFrontendRole.Manager],
        delete: [TFrontendRole.Author, TFrontendRole.Manager],
    },
    group: {
        create: [
            TFrontendRole.Manager,
            TFrontendRole.Owner,
            TFrontendRole.Planner,
        ],
        update: [
            TFrontendRole.Manager,
            TFrontendRole.Owner,
            TFrontendRole.Planner,
        ],
        delete: [
            TFrontendRole.Manager,
            TFrontendRole.Owner,
            TFrontendRole.Planner,
        ],
    },
    course: {
        create: [TFrontendRole.Author, TFrontendRole.Manager],
        update: [TFrontendRole.Author, TFrontendRole.Manager],
        delete: [TFrontendRole.Author, TFrontendRole.Manager],
    },
    user: {
        create: [TFrontendRole.Manager],
        update: [TFrontendRole.Manager],
        delete: [TFrontendRole.Manager],
    },
    userManagement: {
        create: [TFrontendRole.Owner, TFrontendRole.Manager],
        update: [
            TFrontendRole.Owner,
            TFrontendRole.Manager,
            TFrontendRole.GroupManager,
            TFrontendRole.Trainer,
        ],
        delete: [
            TFrontendRole.Owner,
            TFrontendRole.Manager,
            TFrontendRole.GroupManager,
            TFrontendRole.Author,
        ],
    },
    enrollmentsManagement: {
        read: [
            TFrontendRole.Owner,
            TFrontendRole.Manager,
            TFrontendRole.Author,
            TFrontendRole.Trainer,
            TFrontendRole.GroupManager,
        ],
    },
    trainingParticipants: {
        create: [TFrontendRole.Owner, TFrontendRole.Manager],
        update: [TFrontendRole.Owner, TFrontendRole.Manager],
        delete: [TFrontendRole.Owner, TFrontendRole.Manager],
    },
    generateCertificate: {
        create: [TFrontendRole.Manager],
    },
};

export type RoleObjectType = keyof typeof permissions;

export const useFrontendPermissions = (object?: RoleObjectType) => {
    const { data: user, called, loading } = useCurrentUserQuery();

    // When no object is given we want to return all false.
    if (!object) {
        return {
            canRead: false,
            canCreate: false,
            canUpdate: false,
            canDelete: false,
            userRoles: null,
            loading: false,
        };
    }

    const { roles, isGroupManager = false } = user?.currentUser || {};

    const userRoles = ((roles || []) as (TFrontendRole | null)[]).filter(
        Boolean
    );

    if (isGroupManager) {
        userRoles.push(TFrontendRole.GroupManager);
    }

    const {
        read: readRoles,
        create: createRoles,
        update: updateRoles,
        delete: deleteRoles,
    } = permissions[object];

    const canRead = !!readRoles?.some((role) => userRoles.includes(role));
    const canCreate = !!createRoles?.some((role) => userRoles.includes(role));
    const canUpdate = !!updateRoles?.some((role) => userRoles.includes(role));
    const canDelete = !!deleteRoles?.some((role) => userRoles.includes(role));

    return {
        canRead,
        canCreate,
        canUpdate,
        canDelete,
        userRoles,
        // Because this is a lazy query we want loading state to also be true when it's not called yet.
        loading: loading || !called,
    };
};
