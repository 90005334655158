import { rgba } from 'polished';
import { motion, MotionProps } from 'framer-motion';
import { Box, SxProps, BoxProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';

const itemMediaIconStyle: SxProps = {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '3rem',
    transform: { sm: 'scale(1)' },

    svg: {
        width: { xs: '2.3rem', sm: '3rem' },
        height: { xs: '2.3rem', sm: '3rem' },
    },
};

interface IProps extends BoxProps {
    show?: boolean;
    size?: 'small';
}

const MediaIcon = forwardRef((props, ref) => <Box ref={ref} {...props} />);

const MotionMediaIcon = motion.create(MediaIcon);

export const ItemMediaIcon = ({
    size,
    show,
    ...other
}: IProps & MotionProps) => {
    const theme = useTheme();

    return (
        <MotionMediaIcon
            sx={[
                itemMediaIconStyle,
                {
                    width: { xs: 36, sm: size === 'small' ? 50 : 60 },
                    height: { xs: 36, sm: size === 'small' ? 50 : 60 },
                    background: rgba(theme.palette.common.black, 0.62),
                },
            ]}
            transition={{ type: 'easeOut' }}
            variants={{
                initItemButton: {
                    scale: 0.8,
                    opacity: show ? 1 : 0,
                },
                hoverItemButton: {
                    opacity: 1,
                    scale: 1,
                    transition: { type: 'easeIn' },
                },
            }}
            {...other}
        />
    );
};
