import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import REPORT_HEADER from 'assets/images/report-header.webp';
import { IFeature, IOfferEventType } from 'graphql/types';
import { useFeature } from 'common/hooks/useFeature';
import { Typography } from 'common/components/Typography';
import { BackgroundImg } from 'common/components/Img';
import { PageTitle } from 'common/components/PageTitle';
import { Tab, Tabs } from 'common/components/Tabs';
import { RouteLink } from 'route/components/RouteLink';
import { PortfolioReportTable } from 'report/components/PortfolioReportTable';
import { CourseReportTable } from 'report/components/CourseReportTable';
import { TrainingReportTable } from 'report/components/TrainingReportTable';
import { Loader } from 'common/components/Loader';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { UpgradePage } from 'common/components/UpgradePage/UpgradePage';
import { UpgradeContent as PortfolioUpgradeContent } from 'user/components/UpgradeContent';
import { useAppPermissions } from 'user/hooks/usePermissions';
import { OfferReportTable } from 'report/components/OfferReportTable';
import { UpgradeContent as OffersUpgradeContent } from 'offer/components/UpgradeContent';

import { BasePage } from './BasePage';

export const PortfolioReportPage = () => {
    const [translate] = useTranslation();

    const {
        canReadPortfolio = false,
        canReadTrainings = false,
        canReadOffers = false,
        canReadModules = false,
    } = useAppPermissions('portfolioReport', 'general');

    const isPortfolioReportPage = !!useRouteMatch('PORTFOLIO_REPORT');
    const isTrainingReportPage = !!useRouteMatch('TRAINING_REPORT');
    const isOfferReportPage = !!useRouteMatch('OFFER_REPORT');
    const isMeetingReportPage = !!useRouteMatch('MEETING_REPORT');
    const isCourseReportPage = !!useRouteMatch('COURSE_REPORT');

    const canReadPortfolioReport =
        (isPortfolioReportPage && canReadPortfolio) ||
        (isTrainingReportPage && canReadTrainings) ||
        (isOfferReportPage && canReadOffers) ||
        (isMeetingReportPage && canReadOffers) ||
        (isCourseReportPage && canReadModules);

    let activeTab = 1;

    if (isOfferReportPage) activeTab = 2;
    if (isMeetingReportPage) activeTab = 3;
    if (isPortfolioReportPage) activeTab = 4;
    if (isCourseReportPage) activeTab = 5;

    const {
        userCanSeeUpgrade: userCanSeePortfolioUpgrade,
        loading: loadingCanUsePortfolio,
    } = useFeature(IFeature.Portfolio);
    const {
        userCanSeeUpgrade: userCanSeeOffersUpgrade,
        loading: loadingCanUseOffers,
    } = useFeature(IFeature.Offers);

    if (
        !loadingCanUsePortfolio &&
        !canReadPortfolioReport &&
        !userCanSeePortfolioUpgrade &&
        !loadingCanUseOffers &&
        !userCanSeeOffersUpgrade
    ) {
        return <FourOFourPage />;
    }

    const header = (
        <BackgroundImg
            src={REPORT_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            {loadingCanUsePortfolio ? (
                <Loader />
            ) : (
                <>
                    {canReadPortfolioReport && (
                        <>
                            <PageTitle mixpanelTitle="Report page">
                                {translate('reportPage.browserTitle')}
                            </PageTitle>

                            <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                                <Typography variant="h1">
                                    {translate('reportPage.pageTitle')}
                                </Typography>
                            </Box>
                            <Tabs sx={{ mb: 4, mt: 2 }} value={activeTab}>
                                <Tab
                                    component={RouteLink}
                                    label={translate('reportPage.tabTraining')}
                                    to="TRAINING_REPORT"
                                    value={1}
                                />

                                {canReadOffers && (
                                    <Tab
                                        component={RouteLink}
                                        label={translate(
                                            'reportPage.tabOffers'
                                        )}
                                        to="OFFER_REPORT"
                                        value={2}
                                    />
                                )}

                                {canReadOffers && (
                                    <Tab
                                        component={RouteLink}
                                        label={translate(
                                            'reportPage.tabMeeting'
                                        )}
                                        to="MEETING_REPORT"
                                        value={3}
                                    />
                                )}

                                {canReadPortfolio && (
                                    <Tab
                                        component={RouteLink}
                                        label={translate(
                                            'reportPage.tabPortfolio'
                                        )}
                                        to="PORTFOLIO_REPORT"
                                        value={4}
                                    />
                                )}

                                <Tab
                                    component={RouteLink}
                                    label={translate('reportPage.tabCourses')}
                                    to="COURSE_REPORT"
                                    value={5}
                                />
                            </Tabs>
                            {isPortfolioReportPage && <PortfolioReportTable />}
                            {isTrainingReportPage && <TrainingReportTable />}
                            {isOfferReportPage && (
                                <OfferReportTable
                                    offerEventType={[
                                        IOfferEventType.Training,
                                        IOfferEventType.Course,
                                        IOfferEventType.Elearning,
                                    ]}
                                />
                            )}
                            {isMeetingReportPage && (
                                <OfferReportTable
                                    offerEventType={[
                                        IOfferEventType.Meeting,
                                        IOfferEventType.Webinar,
                                    ]}
                                />
                            )}
                            {isCourseReportPage && <CourseReportTable />}
                        </>
                    )}

                    {!canReadPortfolioReport &&
                        userCanSeePortfolioUpgrade &&
                        isPortfolioReportPage && (
                            <UpgradePage>
                                <PortfolioUpgradeContent />
                            </UpgradePage>
                        )}

                    {!canReadOffers &&
                        userCanSeeOffersUpgrade &&
                        (isOfferReportPage || isMeetingReportPage) && (
                            <UpgradePage>
                                <OffersUpgradeContent />
                            </UpgradePage>
                        )}
                </>
            )}
        </BasePage>
    );
};
