import { useEffect, useMemo } from 'react';

import { IRole, useTrainingParticipantsQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { usePagination } from 'common/hooks/usePagination';
import { useUserFilter } from 'user/hooks/useUserFilter';

export const useTrainingParticipantList = (trainingId: string) => {
    const { showApolloError } = useApolloError();

    const filterPermissions = {
        rolesFilter: [IRole.Owner, IRole.Manager, IRole.Author, IRole.Trainer],
    };

    const {
        filterValues,
        filters,
        filterValuesForQuery,
        loading: filtersLoading,
        onSearch,
        onFilterChange,
    } = useUserFilter(filterPermissions);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const userQueryVariables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
        }),
        [filterValuesForQuery]
    );

    const { loading: participantsLoading, data } = useTrainingParticipantsQuery(
        {
            variables: {
                id: trainingId,
                ...userQueryVariables,
                isActive: true,
                archived: false,
            },
            fetchPolicy: 'network-only',
            onError: showApolloError,
        }
    );

    const loading = filtersLoading || participantsLoading;

    const trainingParticipants = data?.training?.participants;

    // Because training users meta setup has more information in the edges we don't get the nodes
    const items = trainingParticipants?.edges || [];
    const count = trainingParticipants?.count || 0;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(count || 0);
    }, [count, loading, initializePagination]);

    return {
        participants: items,
        count,
        loading,
        paginationSettings,
        filters,
        filterValues,
        onSearch,
        onFilterChange,
        setPage,
    };
};
