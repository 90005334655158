import { Box } from '@mui/material';
import { useState } from 'react';

import { PlanSelection } from 'plans/components/PlanSelection';
import { Loader } from 'common/components/Loader';
import { PlanDemo } from 'common/components/PlanDemo';

interface IProps {
    children?: React.ReactNode;
    showPlans?: boolean;
}

export const UpgradePage = ({ children, showPlans = true }: IProps) => {
    const [loading, setLoading] = useState(showPlans);

    return (
        <Box mb={4}>
            {loading && <Loader />}

            <Box sx={{ visibility: loading ? 'hidden' : undefined, mt: 8 }}>
                <Box mb={4}>{children}</Box>

                {showPlans && (
                    <PlanSelection onLoadingDone={() => setLoading(false)} />
                )}

                <Box mt={4}>
                    <PlanDemo />
                </Box>
            </Box>
        </Box>
    );
};
