import { useTranslation } from 'react-i18next';
import { useEditor } from 'slate-react';
import { MenuItem } from '@mui/material';
import { MediaEmbedPluginOptions } from '@udecode/slate-plugins';

import { addOrReplaceEmbed } from './utils';

type IProps = MediaEmbedPluginOptions<'type'> &
    MediaEmbedPluginOptions<'rootProps'>;

export const BlockMenuEmbed = ({ media_embed }: IProps) => {
    const { t: translate } = useTranslation();
    const editor = useEditor();

    return (
        <MenuItem
            onClick={(event) => {
                event.preventDefault();

                const msg = translate('editor.enterEmbedUrl');

                try {
                    addOrReplaceEmbed(editor, msg, { media_embed });
                } catch (e) {
                    window.alert(translate(e as string));
                }
            }}
        >
            {translate('edit')}
        </MenuItem>
    );
};
