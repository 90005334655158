import { Box, BoxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

export const ListItemAvatar = ({ sx, ...other }: BoxProps) => (
    <Box
        {...other}
        sx={[
            { mr: 2 },
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
