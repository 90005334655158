import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import { useCurrentUserQuery, useOrganisationQuery } from 'graphql/types';
import { LoaderOverlay } from 'common/components/Loader';
import { LOAD_MIXPANEL } from 'common/constants/mixpanel';
import {
    MixpanelContext,
    IMixpanelContextValue,
    DEFAULT_MIXPANEL_CONTEXT,
} from 'common/contexts/MixpanelContext';
import { trackMixpanel } from 'common/utils/mixpanel';

interface IProps {
    children?: React.ReactNode;
}

export const MixpanelProvider = ({ children }: IProps) => {
    const [translate] = useTranslation();
    const [contextValue, setContextValue] = useState<IMixpanelContextValue>(
        DEFAULT_MIXPANEL_CONTEXT
    );

    const { data: organisationData, loading: loadingOrg } =
        useOrganisationQuery();
    const { data: userData, loading: loadingUser } = useCurrentUserQuery();

    const { organisation } = organisationData || {};
    const { currentUser: user } = userData || {};

    const loading = loadingOrg || loadingUser;

    useEffect(() => {
        if (!LOAD_MIXPANEL || loading) return;

        mixpanel.init(import.meta.env.VITE_MIXPANEL_KEY || '', {
            debug: import.meta.env.MODE === 'production' ? false : true,
            ignore_dnt: true,
            ip: false,
            loaded: () => {
                setContextValue({
                    mixpanel,
                    trackMixpanel: trackMixpanel(mixpanel),
                });
            },
        });

        // When user is not set we cannot identify them.
        if (!user || !organisation) return;

        const { id } = user;

        const userProperties = { id: id };

        mixpanel.identify(id);
        mixpanel.people.set(userProperties);
    }, [user, organisation, loading]);

    return (
        <>
            <LoaderOverlay visible={loading}>
                {translate('loadingTexts.fetchingData')}
            </LoaderOverlay>

            <MixpanelContext.Provider value={contextValue}>
                {children}
            </MixpanelContext.Provider>
        </>
    );
};
