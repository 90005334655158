import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import * as Yup from 'yup';

import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Typography } from 'common/components/Typography';
import { TextField } from 'common/components/FormField';
import { IEmailTypeEnum, useSendEmailMutation } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';

type TUserEmailFormValues = {
    title?: string;
    message: string;
};

interface IProps {
    open?: boolean;
    userIds?: string[];
    onClose?: () => void;
    onComplete?: () => void;
}

export const UserEmailDialog = ({
    open,
    userIds,
    onClose,
    onComplete,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [sendEmail, { loading: loadingSendEmail }] = useSendEmailMutation();

    const initialValues = {
        title: '',
        message: '',
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .max(100, translate('validate.maxCharacters', { count: 100 }))
            .required(translate('validate.required')),
        message: Yup.string().required(translate('validate.required')),
    });
    const count = userIds?.length || 0;

    const handleSendEmail = async (values: TUserEmailFormValues) => {
        if (!userIds?.length) return;

        const { title, message } = values;

        try {
            await sendEmail({
                variables: {
                    emailType: IEmailTypeEnum.Custom,
                    title,
                    message,
                    userIds,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('userEmail.userEmailSent', { count }), {
            variant: 'success',
        });

        onComplete?.();

        onClose?.();

        return;
    };

    const title = translate('userEmail.dialog.title', {
        count,
    });
    const text = translate('userEmail.dialog.text', {
        count,
    });

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                await handleSendEmail(values);

                actions.resetForm();
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <ConfirmDialog
                    confirmText={translate('userEmail.dialog.buttonText')}
                    loading={isSubmitting || loadingSendEmail}
                    open={!!open}
                    title={title}
                    onCancel={() => onClose?.()}
                    onClose={() => onClose?.()}
                    onConfirm={submitForm}
                >
                    <Typography>{text}</Typography>

                    <Form>
                        <Stack spacing={2} sx={{ mt: 2 }}>
                            <Field
                                component={TextField}
                                label={translate('userEmail.titleField')}
                                name="title"
                            />

                            <Field
                                multiline
                                component={TextField}
                                label={translate('userEmail.messageField')}
                                name="message"
                            />
                        </Stack>
                    </Form>
                </ConfirmDialog>
            )}
        </Formik>
    );
};
