import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload } from 'tus-js-client';

import { useSnackbar } from 'common/hooks/useSnackbar';
import {
    IUploadFile,
    IFileUploadStatus,
    IUploadOptions,
    FileUploadContext,
} from 'common/contexts/FileUploadContext';

interface IProps {
    children?: React.ReactNode;
}

export const FileUploadProvider = ({ children }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();

    const [uploads, setUploads] = useState<IUploadFile[]>([]);

    const removeUploadFromState = (file: File) => {
        setUploads((prevUploads) =>
            prevUploads.filter((upload) => upload.file !== file)
        );
    };

    const updateUploadInState = (file: File, data: Partial<IUploadFile>) => {
        setUploads((prevUploads) =>
            prevUploads.map((upload) => {
                // Update the file with the new data
                if (upload.file === file) {
                    return { ...upload, ...data };
                }

                return upload;
            })
        );
    };

    const handleUploadFile = (
        file: File,
        referenceId: string,
        {
            onProgress,
            onError,
            onSuccess,
            onAbort,
            onStartUpload,
            ...options
        }: IUploadOptions
    ) => {
        const upload = new Upload(file, {
            retryDelays: [0, 3000, 5000, 10000, 20000], // Retry delays in milliseconds
            metadata: {
                filename: file.name,
                filetype: file.type,
            },
            onError: (error) => {
                updateUploadInState(file, {
                    status: IFileUploadStatus.ERROR,
                });

                onError?.(`${error}`);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const progress = +Math.round(
                    (bytesUploaded / bytesTotal) * 100
                ).toFixed(0);

                updateUploadInState(file, {
                    status: IFileUploadStatus.UPLOADING,
                    progress: progress,
                });

                onProgress?.(progress);
            },
            onSuccess: () => {
                onSuccess?.();

                updateUploadInState(file, {
                    status: IFileUploadStatus.SUCCESS,
                    progress: 100,
                });

                displaySnackbar(
                    translate('fileUpload.uploadSuccess', {
                        filename: file.name,
                    }),
                    {
                        variant: 'success',
                    }
                );
            },
            ...options,
        });

        const uploadFile: IUploadFile = {
            file,
            status: IFileUploadStatus.UPLOADING,
            progress: 0,
            referenceId,
            start: () => {
                upload.start();

                updateUploadInState(file, {
                    status: IFileUploadStatus.UPLOADING,
                    progress: 0,
                });
            },
            pause: () => {
                upload.abort();

                updateUploadInState(file, {
                    status: IFileUploadStatus.PAUSED,
                });
            },
            abort: () => {
                upload.abort(true);

                onAbort?.();

                removeUploadFromState(file);
            },
            remove: () => {
                removeUploadFromState(file);
            },
        };

        // Add file to uploads
        setUploads((prevUploads) => [...prevUploads, uploadFile]);

        onStartUpload?.();

        // Start the upload
        upload.start();
    };

    return (
        <FileUploadContext.Provider
            value={{ uploads, uploadFile: handleUploadFile }}
        >
            {children}
        </FileUploadContext.Provider>
    );
};
