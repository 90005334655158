import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { MultiSelect } from 'common/components/FormField';
import {
    useCourseTrainingsInputOptionsQuery,
    ICourseTrainingInputOptionFragment,
} from 'graphql/types';

interface IProps extends FieldProps {
    courseId: string;
    label?: string;
    onChange?: (name: string, value: string[]) => void;
}

export const CourseTrainingSelectField = ({
    courseId,
    label,
    onChange,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const {
        form: { setFieldValue },
    } = other;

    const { loading, data } = useCourseTrainingsInputOptionsQuery({
        variables: { id: courseId },
        fetchPolicy: 'network-only',
    });

    const { trainings } = data?.course || {};
    const options =
        trainings?.edges.map((edge) => {
            const training = edge?.node;

            return {
                id: training?.id,
                title: training?.title,
            };
        }) || [];

    const handleChange = (name: string, value: string[]) => {
        onChange?.(name, value);
        setFieldValue(name, value);
    };

    return (
        <Field
            component={MultiSelect}
            getOptionKey={(option: ICourseTrainingInputOptionFragment) =>
                option.id
            }
            getOptionLabel={(option: ICourseTrainingInputOptionFragment) =>
                option.title
            }
            InputProps={{ label }}
            isOptionEqualToValue={(
                option: ICourseTrainingInputOptionFragment,
                value: ICourseTrainingInputOptionFragment
            ) => option.id === value.id}
            loading={loading}
            loadingText={translate('loadingDots')}
            noOptionsText={translate('noOptionsText.trainings')}
            options={options}
            onChange={handleChange}
            {...other}
        />
    );
};
