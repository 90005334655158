import { Box } from '@mui/material';
import {
    DateTimePicker as MuiDateTimePicker,
    DateTimePickerProps,
} from 'formik-mui-x-date-pickers';
import {
    DateTimePickerTabs,
    DateTimePickerTabsProps,
} from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { mdiCalendar } from '@mdi/js';

import { getDateTimeFormat } from 'common/utils/getDateFormat';
import { Icon } from 'common/components/Icon';

const DatePickerTabs = (props: DateTimePickerTabsProps) => (
    <>
        <DateTimePickerTabs
            {...props}
            dateRangeIcon={<Icon path={mdiCalendar} size="2.4rem" />}
        />
        <Box
            sx={{
                backgroundColor: 'primary.main',
                height: 5,
            }}
        />
    </>
);

export const DateTimePicker = (props: DateTimePickerProps) => {
    const dateFormat = getDateTimeFormat();
    const { t: translate } = useTranslation();

    return (
        <MuiDateTimePicker
            ampm={false}
            components={{ Tabs: DatePickerTabs }}
            hideTabs={false}
            inputFormat={dateFormat}
            inputVariant="outlined"
            invalidDateMessage={translate('invalidDateMessage')}
            invalidLabel={translate('invalidDateMessage')}
            maxDateMessage={translate('maxDateMessage')}
            minDateMessage={translate('minDateMessage')}
            textField={{
                fullWidth: true,
                margin: 'normal',
                required: props.required,
            }}
            variant="dialog"
            {...props}
        />
    );
};
