import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import DRAWER_HEADER from 'assets/images/extra-category-drawer-header.webp';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import { Link } from 'common/components/Link';

import { ExtraCategoryManager } from '../ExtraCategoryManager';

interface IProps {
    open?: boolean;
}

export const ExtraCategorySettingsDrawer = ({ open }: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();

    // Redirect to the pathname to go to the page without hashtag.
    const handleClose = () => navigate(window.location.pathname);

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <DrawerHeaderImage src={DRAWER_HEADER} />

            <PageTitle mixpanelTitle="Drawer extra categories">
                {translate('extraCategoriesDrawer.pageTitle')}
            </PageTitle>

            <Box p={{ xs: 2, sm: 4 }}>
                <Box mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {translate('extraCategoriesDrawer.title')}
                    </Typography>

                    <Box mt={2}>
                        <Typography>
                            <Trans
                                components={{
                                    a: (
                                        <Link
                                            rel="noreferrer"
                                            to="MANAGEMENT_SETTINGS_FACETS"
                                            underline="always"
                                        />
                                    ),
                                }}
                                i18nKey="extraCategoriesDrawer.description"
                            />
                        </Typography>
                    </Box>
                </Box>

                <ExtraCategoryManager />
            </Box>
        </PageDrawer>
    );
};
