import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import * as Yup from 'yup';

import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Typography } from 'common/components/Typography';
import { TextField } from 'common/components/FormField';
import {
    IOfferEventMailType,
    useSendOfferEventEnrollmentBulkMailMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';

export type TEnrollmentBulkMailDialogSettings = {
    open: boolean;
    selectedAmount: number;
    enrollmentId?: string;
};

type TBulkEmailFormValues = {
    title?: string;
    message: string;
};

interface IProps {
    dialogSettings: TEnrollmentBulkMailDialogSettings;
    offerEventId?: string;
    enrollmentIds?: string[];
    onClose?: () => void;
    onSendBulkEmailComplete?: () => void;
}

export const EnrollmentBulkMailDialog = ({
    dialogSettings,
    offerEventId,
    enrollmentIds,
    onClose,
    onSendBulkEmailComplete,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [sendEnrollmentBulkEmail, { loading: loadingBulkEmail }] =
        useSendOfferEventEnrollmentBulkMailMutation();

    const initialValues = {
        title: '',
        message: '',
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().max(
            100,
            translate('validate.maxCharacters', { count: 100 })
        ),
        message: Yup.string().required(translate('validate.required')),
    });

    const handleBulkEmail = async (values: TBulkEmailFormValues) => {
        const { title, message } = values;

        try {
            await sendEnrollmentBulkEmail({
                variables: {
                    mailType: IOfferEventMailType.Message,
                    offerEventId: offerEventId,
                    // When offerEventId is given it sends the email to all its enrollments
                    // Otherwise it sends the email to the given enrollmentIds
                    offerEventEnrollmentIds: offerEventId
                        ? undefined
                        : enrollmentIds,
                    title,
                    message,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('offerEventForm.bulkMailSent'), {
            variant: 'success',
        });
        onClose?.();
        onSendBulkEmailComplete?.();

        return;
    };

    const { open, selectedAmount } = dialogSettings;
    const count = !selectedAmount ? 1 : selectedAmount;

    const title = translate('offerEventEnrollmentBulkMail.dialog.title', {
        count,
    });
    const text = translate('offerEventEnrollmentBulkMail.dialog.text', {
        count,
    });

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                await handleBulkEmail(values);

                actions.resetForm();
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <ConfirmDialog
                    confirmText={translate('send')}
                    loading={isSubmitting || loadingBulkEmail}
                    open={open}
                    title={title}
                    onCancel={() => onClose?.()}
                    onClose={() => onClose?.()}
                    onConfirm={submitForm}
                >
                    <Typography>{text}</Typography>

                    <Form>
                        <Stack spacing={2} sx={{ mt: 2 }}>
                            <Field
                                component={TextField}
                                label={translate(
                                    'offerEventEnrollmentBulkMail.titleField'
                                )}
                                name="title"
                            />

                            <Field
                                multiline
                                component={TextField}
                                label={translate(
                                    'offerEventEnrollmentBulkMail.messageField'
                                )}
                                name="message"
                            />
                        </Stack>
                    </Form>
                </ConfirmDialog>
            )}
        </Formik>
    );
};
