import { DEFAULTS_MEDIA_EMBED } from '@udecode/slate-plugins';

export const deserializeEmbed = {
    node: (el: HTMLElement) => ({
        type: DEFAULTS_MEDIA_EMBED.media_embed.type,
        url: el.getAttribute('data-slate-url'),
    }),
    rules: [
        {
            className: DEFAULTS_MEDIA_EMBED.media_embed.rootProps?.className,
        },
    ],
};
