const IMAGE_TYPES = 'image/png, image/jpeg, image/gif, image/webp';
export const SIZE_TO_MB_MULTIPLIER = 1000000;

export const DEFAULT_MAX_FILE_SIZE = 20; // MB

export const USER_PROFILE_IMAGE_TYPE = IMAGE_TYPES;
export const USER_PROFILE_IMAGE_MAX_SIZE = 5; // MB

export const EDITOR_IMAGE_TYPE = IMAGE_TYPES;
export const EDITOR_IMAGE_MAX_SIZE = 5; // MB

export const EDITOR_FILE_MAX_SIZE = 10; // MB

export const LIBRARY_IMAGE_TYPE = IMAGE_TYPES;
export const LIBRARY_IMAGE_MAX_SIZE = 5; // MB

export const ASSIGNMENT_FILE_MAX_SIZE = 300; // MB

export const LOGO_IMAGE_TYPE = IMAGE_TYPES;
export const LOGO_IMAGE_MAX_SIZE = 5; // MB

export const HEADER_IMAGE_TYPE = IMAGE_TYPES;
export const HEADER_IMAGE_MAX_SIZE = 5; // MB

export const CARD_IMAGE_TYPE = IMAGE_TYPES;
export const CARD_IMAGE_MAX_SIZE = 0.7; // MB

export const PAGE_IMAGE_TYPE = IMAGE_TYPES;
export const PAGE_IMAGE_MAX_SIZE = 10; // MB

export const FAVICON_IMAGE_TYPE = `${IMAGE_TYPES}, image/x-icon, image/svg+xml`;
export const FAVICON_IMAGE_MAX_SIZE = 1; // MB

export const VIDEO_FILE_TYPE =
    '.mp4,.mkv,.webm,.flv,.vod,.avi,.mov,.wmv,.amv,.m4p,.mpeg,.mpg,.4mv';

export const VIDEO_MAX_SIZE = 1024; // MB

export const FOOTER_IMAGE_TYPE = IMAGE_TYPES;
export const FOOTER_IMAGE_MAX_SIZE = 5; // MB

export const ASSIGNMENT_MODULE_FILE_MAX_SIZE = 10; // MB

export const SCORM_FILE_MAX_SIZE = 700; // MB
export const SCORM_FILE_TYPES = '.zip';
