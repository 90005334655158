import { Box, Grid, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    mdiMessageText,
    mdiBriefcaseVariant,
    mdiAccount,
    mdiEmail,
} from '@mdi/js';

import { IFeature } from 'graphql/types';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { useFeature } from 'common/hooks/useFeature';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';
import { Icon } from 'common/components/Icon';
import { useCanManageUser } from 'user/hooks/useCanManageUser';
import { UserEmailDropdownMenu } from 'user/components/UserEmailDropdownMenu';
import { IUpdateGroupProps } from 'user/components/GroupUpdateDrawer';
import { IUserProfileProps } from 'user/components/UserProfileDrawer';

const userActionBarStyle: SxProps = {
    px: { xs: 2, sm: 4 },
    py: 2,
    borderTop: '1px solid',
    borderTopColor: 'divider',
    borderBottom: '1px solid',
    borderBottomColor: 'divider',
};

interface IProps {
    userId: string;
    children?: React.ReactNode;
    groupProps?: IUpdateGroupProps;
    hideChat?: boolean;
    hideEmail?: boolean;
    hidePortfolio?: boolean;
    hideProfile?: boolean;
    userProfileProps?: IUserProfileProps;
}

export const UserActionBar = ({
    userId,
    children,
    groupProps,
    hideChat,
    hideEmail,
    hidePortfolio,
    hideProfile,
    userProfileProps,
}: IProps) => {
    const [translate] = useTranslation();
    const { openGlobalDrawer } = useGlobalDrawer();
    const { canUse: canUsePortfolio } = useFeature(IFeature.Portfolio);
    const {
        canManageUser,
        isCurrentUser,
        loading: loadingCanManageUser,
    } = useCanManageUser(userId);

    const loading = loadingCanManageUser;

    const showChat = !hideChat && !isCurrentUser;
    const showPortfolio =
        !hidePortfolio && canUsePortfolio && (canManageUser || isCurrentUser);
    const showProfile = !hideProfile;
    const showEmail = !hideEmail && !isCurrentUser && canManageUser;

    if (loading) return null;

    return (
        <Box sx={userActionBarStyle}>
            <Grid container spacing={2}>
                {showChat && (
                    <Grid item sm="auto" sx={{ display: 'flex' }} xs={6}>
                        <Button
                            component={Link}
                            params={{ id: userId }}
                            startIcon={
                                <Icon path={mdiMessageText} size="1.9rem" />
                            }
                            sx={{ flex: 1 }}
                            to="USER_CHAT"
                            variant="outlined"
                        >
                            {translate('chat')}
                        </Button>
                    </Grid>
                )}
                {showPortfolio && (
                    <Grid item sm="auto" sx={{ display: 'flex' }} xs={6}>
                        <Button
                            component={Link}
                            params={
                                isCurrentUser
                                    ? undefined
                                    : {
                                          id: userId,
                                      }
                            }
                            startIcon={
                                <Icon
                                    path={mdiBriefcaseVariant}
                                    size="1.9rem"
                                />
                            }
                            sx={{ flex: 1 }}
                            to={
                                isCurrentUser
                                    ? 'PORTFOLIO_TRAININGS'
                                    : 'USER_TRAININGS'
                            }
                            variant="outlined"
                        >
                            {translate('portfolio')}
                        </Button>
                    </Grid>
                )}

                {showProfile && (
                    <Grid item sm="auto" sx={{ display: 'flex' }} xs={6}>
                        <Button
                            startIcon={<Icon path={mdiAccount} size="1.9rem" />}
                            sx={{ flex: 1 }}
                            variant="outlined"
                            onClick={() => {
                                openGlobalDrawer({
                                    type: IGlobalDrawerType.UserProfile,
                                    itemId: userId,
                                    props: {
                                        groupProps,
                                        onUpdate: userProfileProps?.onUpdate,
                                    },
                                });
                            }}
                        >
                            {translate('profile')}
                        </Button>
                    </Grid>
                )}

                {showEmail && (
                    <Grid item sm="auto" sx={{ display: 'flex' }} xs={6}>
                        <UserEmailDropdownMenu
                            anchorButton={
                                <Button
                                    startIcon={
                                        <Icon path={mdiEmail} size="1.9rem" />
                                    }
                                    sx={{ flex: 1 }}
                                    variant="outlined"
                                >
                                    {translate('email')}
                                </Button>
                            }
                            userIds={[userId]}
                        />
                    </Grid>
                )}

                {children && (
                    <Grid
                        item
                        sm="auto"
                        sx={{ ml: 'auto', display: 'flex' }}
                        xs={6}
                    >
                        {children}
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
