import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TRouteName } from 'route/types';
import { getUrl } from 'route/utils/getUrl';
import {
    IRole,
    IStripeSubscriptionStatus,
    useCurrentUserQuery,
    useOrganisationPlanSubscriptionLazyQuery,
} from 'graphql/types';
import {
    getLoginUrl,
    getTwoFactorSetupUrl,
    getTwoFactorVerifyUrl,
} from 'common/utils/getUrl';
import { LoaderOverlay } from 'common/components/Loader';
import {
    useFrontendPermissions,
    RoleObjectType,
    ObjectAction,
} from 'user/hooks/useFrontendPermissions';
import { SessionManager } from 'user/components/SessionManager';
import { AppPermissionsContext } from 'user/contexts';
import { getParsedAppPermissions } from 'user/utils/permissions';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { EGraphQLError } from 'common/types';

interface IProps {
    permission?: RoleObjectType;
    permissionAction?: ObjectAction;
    permissionRedirectPath?: TRouteName;
    children: React.ReactNode;
}

export const IsAuthenticated = ({
    permission,
    permissionAction,
    permissionRedirectPath,
    children,
}: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const isPlanStatusErrorPage = !!useRouteMatch('PLAN_STATUS_ERROR');
    const isPlanTrialExpiredPage = !!useRouteMatch('PLAN_TRIAL_EXPIRED');

    // We use the current user query to check if a user is logged in.
    const {
        data: currentUserData,
        error,
        loading: loadingUser,
    } = useCurrentUserQuery();

    const { currentUser } = currentUserData || {};
    const { roles } = currentUser || {};
    const canSeePlanErrorPage =
        roles?.includes(IRole.Owner) || roles?.includes(IRole.Manager);

    const { loading: loadingPermissions, ...restPermissions } =
        useFrontendPermissions(permission);

    const [
        getOrganisationPlanSubscription,
        {
            data: organisationPlanSubscriptionData,
            loading: organisationPlanSubscriptionLoading,
        },
    ] = useOrganisationPlanSubscriptionLazyQuery();

    let redirectUrl = '';

    if (error) {
        const { graphQLErrors } = error;

        graphQLErrors.some(({ extensions }) => {
            const errorCode = extensions?.code;

            if (errorCode === EGraphQLError.AuthNotLoggedIn) {
                // User is not logged in
                redirectUrl = getLoginUrl();

                return true;
            }

            if (errorCode === EGraphQLError.AuthTwoFactorNotVerified) {
                // User is not verified
                redirectUrl = getTwoFactorVerifyUrl();

                return true;
            }

            if (errorCode === EGraphQLError.AuthTwoFactorNotSetup) {
                // User has not set up two factor authentication
                redirectUrl = getTwoFactorSetupUrl();

                return true;
            }

            return false;
        });
    }

    useEffect(() => {
        if (loadingUser) return;

        if (redirectUrl) {
            window.location.href = redirectUrl;

            return;
        }

        if (loadingPermissions || !permission || !permissionAction) {
            return;
        }

        // Get correct action name with first letter capital
        const actionName =
            permissionAction.charAt(0).toUpperCase() +
            permissionAction.slice(1);

        const permissionName =
            `can${actionName}` as keyof typeof restPermissions;

        if (!restPermissions[permissionName]) {
            navigate(getUrl(permissionRedirectPath || 'HOME'));
        }
    }, [
        loadingPermissions,
        permission,
        permissionAction,
        restPermissions,
        loadingUser,
        navigate,
        permissionRedirectPath,
        redirectUrl,
    ]);

    const { organisation } = organisationPlanSubscriptionData || {};
    const { subscriptionStatus } = organisation || {};

    const subscriptionError =
        subscriptionStatus === IStripeSubscriptionStatus.IncompleteExpired ||
        subscriptionStatus === IStripeSubscriptionStatus.Canceled ||
        subscriptionStatus === IStripeSubscriptionStatus.Incomplete ||
        subscriptionStatus === IStripeSubscriptionStatus.Unpaid ||
        subscriptionStatus === IStripeSubscriptionStatus.PastDue;
    const subscriptionTrialExpired =
        subscriptionStatus === IStripeSubscriptionStatus.TrialExpired;

    useEffect(() => {
        if (!canSeePlanErrorPage) return;

        getOrganisationPlanSubscription();
    }, []);

    useEffect(() => {
        if (isPlanStatusErrorPage || isPlanTrialExpiredPage) {
            if (subscriptionError && isPlanTrialExpiredPage) {
                navigate(getUrl('PLAN_STATUS_ERROR'));

                return;
            }

            if (subscriptionTrialExpired && isPlanStatusErrorPage) {
                navigate(getUrl('PLAN_TRIAL_EXPIRED'));

                return;
            }

            if (
                !organisationPlanSubscriptionLoading &&
                ((isPlanStatusErrorPage && !subscriptionError) ||
                    (isPlanTrialExpiredPage && !subscriptionTrialExpired))
            ) {
                navigate(getUrl('PLANS'));
            }

            return;
        }

        if (subscriptionError) {
            navigate(getUrl('PLAN_STATUS_ERROR'));
        }

        if (subscriptionTrialExpired) {
            navigate(getUrl('PLAN_TRIAL_EXPIRED'));
        }
    });

    if (loadingUser || redirectUrl || organisationPlanSubscriptionLoading) {
        return null;
    }

    return (
        <AppPermissionsContext.Provider
            value={getParsedAppPermissions(currentUser?.appPermissions)}
        >
            <LoaderOverlay visible={loadingPermissions}>
                {translate('loadingTexts.fetchingData')}
            </LoaderOverlay>

            {!loadingPermissions && (
                <>
                    <SessionManager />
                    {children}
                </>
            )}
        </AppPermissionsContext.Provider>
    );
};
