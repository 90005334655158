import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const simpleAccordionStyle: SxProps = {
    boxShadow: 'none',
    borderBottom: 1,
    borderBottomColor: 'divider',
    background: 'none',

    '&:before': {
        content: 'none',
    },

    '&.Mui-expanded': {
        margin: 'auto',
    },
};

export const SimpleAccordion = ({ expanded, sx, ...other }: AccordionProps) => (
    <MuiAccordion
        square
        expanded={expanded}
        sx={[simpleAccordionStyle, ...withSxProp(sx)]}
        {...other}
    />
);
