import { useSlate } from 'slate-react';
import {
    ToolbarButton,
    ToolbarButtonProps,
    someNode,
} from '@udecode/slate-plugins';
import Tippy, { TippyProps } from '@tippyjs/react';

import { EDITOR_FILE_MAX_SIZE } from 'common/constants/files';

import { FileUpload } from '../file/FileUpload';

import { addOrReplaceFile } from './utils';
import { IFilePluginOptions } from './types';

type IProps = ToolbarButtonProps &
    IFilePluginOptions<'type'> &
    IFilePluginOptions<'rootProps'>;

export const ToolbarFile = ({ file, tooltip, ...other }: IProps) => {
    const editor = useSlate();

    if (!file) return null;

    const handleSuccess = (url: string) => {
        addOrReplaceFile(editor, url, { file });
    };

    const tooltipProps: TippyProps = {
        content: '',
        arrow: true,
        offset: [0, 17],
        delay: 0,
        duration: [200, 0],
        hideOnClick: false,
        ...tooltip,
    };

    // We render Tippy manually here because it doesn't work inside the FileUpload
    return (
        <Tippy interactive={false} {...tooltipProps}>
            <span>
                <FileUpload
                    id="toolbar-file-upload"
                    maxSize={EDITOR_FILE_MAX_SIZE}
                    onSuccess={handleSuccess}
                >
                    <ToolbarButton
                        active={someNode(editor, {
                            match: { type: file.type },
                        })}
                        {...other}
                    />
                </FileUpload>
            </span>
        </Tippy>
    );
};
