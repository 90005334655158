import { ButtonProps, SxProps } from '@mui/material';
import { mdiCog } from '@mdi/js';
import { LinkProps } from 'react-router-dom';

import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { withSxProp } from 'common/utils/props';

const manageButtonStyle: SxProps = {
    height: '45px',
    borderRadius: 5,
    py: 0,

    '.MuiButton-startIcon': {
        mr: 1,
    },

    '&.MuiButton-outlined': {
        py: 0,
    },
};

interface IProps extends ButtonProps {
    outlined?: boolean;
    to?: LinkProps['to'];
}

export const ManageButton = ({ outlined, sx, ...other }: IProps) => (
    <Button
        color={!outlined ? 'white' : undefined}
        startIcon={<Icon path={mdiCog} size="1.9rem" />}
        sx={[manageButtonStyle, ...withSxProp(sx)]}
        variant={outlined ? 'outlined' : 'contained'}
        {...other}
    />
);
