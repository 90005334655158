import {
    mdiTransitConnectionVariant,
    mdiSchool,
    mdiLaptopAccount,
    mdiSeat,
    mdiPlay,
} from '@mdi/js';

import { IOfferEventType } from 'graphql/types';

type TOfferEventType =
    | IOfferEventType.Elearning
    | IOfferEventType.Training
    | IOfferEventType.Course
    | IOfferEventType.Meeting
    | IOfferEventType.Webinar
    | IOfferEventType.Scorm
    | IOfferEventType.Video;

export const OFFER_EVENT_TYPE_ICONS: {
    [key in TOfferEventType]: string;
} = {
    [IOfferEventType.Elearning]: mdiSchool,
    [IOfferEventType.Training]: mdiTransitConnectionVariant,
    [IOfferEventType.Course]: mdiSchool,
    [IOfferEventType.Meeting]: mdiSeat,
    [IOfferEventType.Webinar]: mdiLaptopAccount,
    [IOfferEventType.Scorm]: mdiSchool,
    [IOfferEventType.Video]: mdiPlay,
};
