import { useEffect, useMemo } from 'react';

import { useUserFilter } from 'user/hooks/useUserFilter';
import { IUserListItemFragment, useUsersQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { usePagination } from 'common/hooks/usePagination';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

export const useUserManagementList = () => {
    const { showApolloError } = useApolloError();

    const {
        filters,
        filterValues,
        filterValuesForQuery,
        loading: extraCategoriesLoading,
        onSearch,
        onFilterChange,
    } = useUserFilter();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);
    const isActiveUsersPage = !!useRouteMatch('USER_MANAGEMENT_LIST');
    const isArchivedUsersPage = !!useRouteMatch(
        'USER_MANAGEMENT_LIST_ARCHIVED'
    );

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const userQueryVariables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
        }),
        [filterValuesForQuery]
    );

    const { loading: usersLoading, data: usersData } = useUsersQuery({
        variables: {
            ...userQueryVariables,
            isActive: isActiveUsersPage,
            archived: isArchivedUsersPage,
        },
        onError: showApolloError,
    });

    const usersObject = usersData?.paginatedUsers;
    const usersCount = usersObject?.count;
    const users =
        (usersObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IUserListItemFragment[]) || [];

    const loading = usersLoading || extraCategoriesLoading;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(usersCount || 0);
    }, [usersCount, loading, initializePagination]);

    return {
        users,
        usersCount,
        loading,
        paginationSettings,
        filters,
        filterValues,
        onSearch,
        onFilterChange,
        setPage,
    };
};
