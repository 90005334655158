import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { Chip, ChipGroup } from 'common/components/Chip';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { Switch, TextField } from 'common/components/FormField';
import { IOpenIdConnectionFragment, useOrganisationQuery } from 'graphql/types';
import { TOpenIdConnectionFormValues } from 'connections/types';
import { Button } from 'common/components/Button';
import { AlertDialog } from 'common/components/AlertDialog';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';

import { ParameterForm } from './ParameterForm';
import { ConfigurationForm } from './ConfigurationForm';

interface IProps {
    disabled?: boolean;
    openIdConnection?: IOpenIdConnectionFragment;
    onDelete?(): void;
    onSubmit(values: TOpenIdConnectionFormValues): void;
}

export const OpenIdConnectionForm = ({
    disabled,
    openIdConnection,
    onDelete,
    onSubmit,
}: IProps) => {
    const [translate] = useTranslation();
    const { data } = useOrganisationQuery();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const {
        name,
        buttonText,
        active,
        clientId,
        clientSecret,
        authorizationEndpoint,
        tokenEndpoint,
        userEndpoint,
        scope,
        oidcSignAlgo,
        oidcOpJwksEndpoint,
    } = openIdConnection || {};

    const { organisation } = data || {};
    const baseUrl = organisation?.url || '';

    const initialValues: TOpenIdConnectionFormValues = {
        name: name || '',
        buttonText: buttonText || '',
        active: typeof active === 'undefined' ? true : active,
        clientId: clientId || '',
        clientSecret: clientSecret || '',
        authorizationEndpoint: authorizationEndpoint || '',
        tokenEndpoint: tokenEndpoint || '',
        userEndpoint: userEndpoint || '',
        scope: scope || 'openid email',
        oidcSignAlgo,
        oidcOpJwksEndpoint: oidcOpJwksEndpoint || '',
        ltiAuthenticateUrl: `${baseUrl}/`,
        ltiLaunchUrl: `${baseUrl}/oidc/callback/`,
    };

    // When any of the platform details is empty and when viewing an existing platform
    const actionRequired =
        (!clientId ||
            !clientSecret ||
            !authorizationEndpoint ||
            !tokenEndpoint ||
            !userEndpoint ||
            !scope ||
            !oidcOpJwksEndpoint) &&
        openIdConnection;

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(translate('validate.required')),
        buttonText: Yup.string().required(translate('validate.required')),
    });

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ submitForm, isSubmitting, dirty, values }) => (
                    <>
                        {actionRequired && (
                            <>
                                <Box
                                    sx={{
                                        px: { xs: 2, sm: 4 },
                                        pt: { xs: 2, sm: 4 },
                                    }}
                                >
                                    <ChipGroup>
                                        <Chip
                                            color="error"
                                            label={translate('actionRequired')}
                                        />
                                    </ChipGroup>

                                    <Box sx={{ my: 2 }}>
                                        <Typography>
                                            {translate(
                                                'openIdConnectionForm.missingConfiguration'
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Divider />
                            </>
                        )}

                        <Box p={{ xs: 2, sm: 4 }}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h3"
                            >
                                {translate('openIdConnectionForm.formTitle')}
                            </Typography>

                            <Box sx={{ mt: 1, mb: 2 }}>
                                <Box mb={1}>
                                    <Typography>
                                        {translate(
                                            'openIdConnectionForm.formText'
                                        )}
                                    </Typography>
                                </Box>

                                <Field
                                    component={TextField}
                                    label={translate('name')}
                                    name="name"
                                />

                                <Field
                                    component={TextField}
                                    label={translate(
                                        'openIdConnectionForm.buttonText'
                                    )}
                                    name="buttonText"
                                />
                            </Box>

                            <Divider />

                            <ParameterForm />

                            <ConfigurationForm />

                            <Field
                                checked={values.active}
                                component={Switch}
                                label={translate('active')}
                                name="active"
                            />

                            <Box sx={{ display: 'flex', mt: 4 }}>
                                <Box>
                                    <Button
                                        color="primary"
                                        disabled={
                                            isSubmitting ||
                                            disabled ||
                                            (!isSubmitting &&
                                                !disabled &&
                                                !dirty)
                                        }
                                        loading={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            e.preventDefault();

                                            return submitForm();
                                        }}
                                    >
                                        {translate('save')}
                                    </Button>
                                </Box>

                                {!!openIdConnection && (
                                    <Box ml="auto">
                                        <FormDeleteButton
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                setDeleteDialogOpen(true)
                                            }
                                        >
                                            {translate('delete')}
                                        </FormDeleteButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </Formik>

            {!!openIdConnection && (
                <AlertDialog
                    actions={
                        <Box display="flex" mt={2}>
                            <Box mr={2}>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete?.()}
                                >
                                    {translate('delete')}
                                </Button>
                            </Box>

                            <Button
                                variant="contained"
                                onClick={() => setDeleteDialogOpen(false)}
                            >
                                {translate('close')}
                            </Button>
                        </Box>
                    }
                    open={deleteDialogOpen}
                    title={translate(
                        'openIdConnectionDeleteStatusDialog.title'
                    )}
                >
                    {translate('openIdConnectionDeleteStatusDialog.text')}
                </AlertDialog>
            )}
        </>
    );
};
