import { Box } from '@mui/material';

import { PageDrawer } from 'common/components/PageDrawer';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { FilterForm, IFilterFormProps } from 'common/components/FilterForm';
import { TFilterFormValues } from 'common/types';

interface IProps {
    open?: boolean;
    onClose?(): void;
    title?: string;
    description?: string;
    formProps: IFilterFormProps;
    onReset?(values: TFilterFormValues): void;
}

export const FilterDrawer = ({
    open,
    onClose,
    title,
    description,
    formProps,
}: IProps) => (
    <PageDrawer open={open} sx={{ maxWidth: 468 }} onClose={onClose}>
        {open && (
            <>
                {(title || description) && (
                    <Box sx={{ p: { xs: 2, sm: 4 } }}>
                        {title && <Typography variant="h2">{title}</Typography>}
                        {description && (
                            <Typography sx={{ mt: 1 }}>
                                {description}
                            </Typography>
                        )}
                    </Box>
                )}
                <Box>
                    <Divider />
                    <FilterForm {...formProps} />
                </Box>
            </>
        )}
    </PageDrawer>
);
