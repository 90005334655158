import { Box, Pagination as MUIPagination } from '@mui/material';
import { PaginationProps } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

interface IProps extends Omit<PaginationProps, 'onChange'> {
    className?: string;
    pageAmount: number;
    totalsAmount?: number | null;
    totalsText?: string;
    onChange?(page: number): void;
}

export const BasePagination = ({
    className,
    pageAmount,
    totalsAmount,
    totalsText,
    onChange,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const handleChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        onChange && onChange(page);
    };

    const showPagination = pageAmount > 1;

    return (
        <Box className={className}>
            {showPagination && (
                <MUIPagination
                    count={pageAmount}
                    onChange={handleChange}
                    {...other}
                />
            )}

            {!!totalsAmount && totalsAmount >= 0 && (
                <Box
                    className={`totals ${
                        showPagination ? 'showPagination' : ''
                    }`}
                >
                    {translate('total')} {totalsAmount}{' '}
                    {totalsText && totalsText.toLowerCase()}
                </Box>
            )}
        </Box>
    );
};

export const Pagination = styled(BasePagination)`
    font-size: ${({ theme }) => theme.typography.body1.fontSize};

    .MuiButtonBase-root {
        font-size: ${({ theme }) => theme.typography.body1.fontSize};

        &.Mui-selected {
            border: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
            color: ${({ theme }) => theme.palette.primary.main};
            background: none;
        }

        .MuiPaginationItem-icon {
            font-size: ${({ theme }) => theme.typography.pxToRem(27)};
        }
    }

    .totals {
        &.showPagination {
            margin-top: ${({ theme }) => theme.spacing(2)};
            margin-left: ${({ theme }) => theme.spacing(2)};
        }
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
        align-items: center;

        .totals {
            margin-top: 0;

            &.showPagination {
                margin-top: 0;
                margin-left: ${({ theme }) => theme.spacing(2)};
            }
        }
    }
`;
