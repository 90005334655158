import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Typography } from 'common/components/Typography';
import { IEmailTypeEnum, useSendEmailMutation } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';

interface IProps {
    open?: boolean;
    userIds?: string[];
    onClose?: () => void;
    onComplete?: () => void;
}

export const AcademyInviteEmailDialog = ({
    open,
    userIds,
    onClose,
    onComplete,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [sendEmail, { loading: loadingSendEmail }] = useSendEmailMutation();

    const count = userIds?.length || 0;

    const handleSendEmail = async () => {
        if (!userIds?.length) return;

        try {
            await sendEmail({
                variables: {
                    emailType: IEmailTypeEnum.AcademyInvite,
                    userIds,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('academyInviteEmail.academyInviteEmailSent', {
                count,
            }),
            {
                variant: 'success',
            }
        );

        onComplete?.();

        onClose?.();
    };

    const title = translate('academyInviteEmail.dialog.title', {
        count,
    });
    const text = translate('academyInviteEmail.dialog.text', {
        count,
    });

    return (
        <ConfirmDialog
            confirmText={translate('academyInviteEmail.dialog.buttonText', {
                count,
            })}
            loading={loadingSendEmail}
            open={!!open}
            title={title}
            onCancel={() => onClose?.()}
            onClose={() => onClose?.()}
            onConfirm={handleSendEmail}
        >
            <Typography>{text}</Typography>
        </ConfirmDialog>
    );
};
