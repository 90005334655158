import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { ITrainingListItemFragment } from 'graphql/types';
import { TrainingListItem } from 'training/components/TrainingListItem';
import {
    isTrainingAdded,
    isTrainingSelected,
} from 'training/utils/trainingSelect';

interface IProps {
    addedTrainings: ITrainingListItemFragment[];
    selectedTrainings: ITrainingListItemFragment[];
    trainings: ITrainingListItemFragment[];
    onAddTraining(selectedTraining: ITrainingListItemFragment): void;
    onRemoveTraining(selectedTraining: ITrainingListItemFragment): void;
}

export const TrainingAddList = ({
    addedTrainings,
    selectedTrainings,
    trainings,
    onAddTraining,
    onRemoveTraining,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleSelectTraining = (
        training: ITrainingListItemFragment,
        isAdded: boolean
    ) => {
        isAdded ? onRemoveTraining(training) : onAddTraining(training);
    };

    return (
        <>
            {!trainings.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>
                        {translate('noOptionsText.trainings')}
                    </Typography>
                </Box>
            ) : (
                trainings.filter(Boolean).map((training) => {
                    const isSelected = isTrainingSelected(
                        selectedTrainings,
                        training
                    );
                    const isAdded = isTrainingAdded(addedTrainings, training);

                    return (
                        <TrainingListItem
                            addButton
                            added={isSelected || isAdded}
                            key={training.id}
                            px={2}
                            selected={isSelected || isAdded}
                            training={training}
                            onClick={() =>
                                handleSelectTraining(
                                    training,
                                    isSelected || isAdded
                                )
                            }
                        />
                    );
                })
            )}
        </>
    );
};
