import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';

import { MultiSelect } from 'common/components/FormField';
import { IScormSortBy, useInputOptionScormsLazyQuery } from 'graphql/types';
import { TInputOption } from 'common/types';

interface IProps {
    isFilter?: boolean;
    label?: string;
    ids?: string[];
    onChange?: (name: string, value: string[]) => void;
}

const BASE_QUERY_VARS = {
    first: 20,
    sortBy: IScormSortBy.Title,
};

export const ScormSelectField = ({
    isFilter,
    label,
    ids = [],
    onChange,
    ...other
}: IProps & FieldProps) => {
    const [inputValue, setInputValue] = useState('');
    const [translate] = useTranslation();

    const [fetchScorms, { data, loading, called }] =
        useInputOptionScormsLazyQuery();

    const { scorms: scormsData } = data || {};

    const scorms =
        scormsData?.edges.map((edge) => edge?.node).filter(Boolean) || [];
    const scormsCount = scormsData?.count || 0;

    const inputOptions: TInputOption[] = useMemo(
        () =>
            scorms.map(({ id, title }) => ({
                id,
                name: title,
                extraCategoryLabels: [],
                isInfo: false,
            })) || [],
        [scorms]
    );

    const value: TInputOption[] = other.field.value;

    // Add value options to input options if they are not already in the input options
    value.forEach((valueOption) => {
        if (
            !inputOptions.some(
                (option: TInputOption) => option.id === valueOption.id
            )
        ) {
            const { id, name, extraCategoryLabels } = valueOption;

            inputOptions.push({
                id,
                name,
                extraCategoryLabels,
                isInfo: false,
            });
        }
    });

    if (scormsCount > 5) {
        inputOptions.push({
            id: '',
            name: translate('moreResultsSelectSearch', {
                count: scormsCount - 5,
            }),
            extraCategoryLabels: [],
            isInfo: true,
        });
    }

    const {
        form: { setFieldValue },
    } = other;

    const handleChange = (name: string, value: string[]) => {
        onChange?.(name, value);
        setFieldValue(name, value);
    };

    const fetch = useMemo(
        () =>
            debounce((inputValue: string) => {
                fetchScorms({
                    variables: {
                        ...BASE_QUERY_VARS,
                        ids: ids,
                        q: inputValue || undefined,
                    },
                });
            }, 400),
        []
    );

    // Searching for results
    useEffect(() => {
        // The first query is done by opening, so we want to avoid calling it twice
        if (!called) return;

        fetch(inputValue);
    }, [inputValue, fetch]);

    // Fetch scorms when ids change
    useEffect(() => {
        if (!ids.length || !called) return;

        fetchScorms({
            variables: {
                ...BASE_QUERY_VARS,
                ids: ids,
            },
        });
    }, [ids]);

    const handleOpen = () => {
        if (!ids.length || !!scorms.length) return;

        fetchScorms({
            variables: {
                ...BASE_QUERY_VARS,
                ids: ids,
                q: inputValue || undefined,
            },
        });
    };

    return (
        <Field
            component={MultiSelect}
            filterOptions={(option: TInputOption) => option}
            getOptionDisplay={(option: TInputOption) => <>{option.name}</>}
            getOptionIsInfo={(option: TInputOption) => option.isInfo}
            getOptionKey={(option: TInputOption) => option.id}
            getOptionLabel={(option: TInputOption) => option.name}
            InputProps={{ label }}
            isOptionEqualToValue={(option: TInputOption, value: TInputOption) =>
                option.id === value.id
            }
            limitTags={5}
            loading={loading}
            loadingText={translate('loadingDots')}
            noOptionsText={translate(
                `noOptionsText.${isFilter ? 'filterScorms' : 'scorms'}`
            )}
            options={inputOptions}
            onChange={handleChange}
            onInputChange={(
                _event: React.ChangeEvent<HTMLInputElement>,
                newInputValue: string
            ) => {
                setInputValue(newInputValue);
            }}
            onOpen={handleOpen}
            {...other}
        />
    );
};
