import { useState } from 'react';
import { Stack, Grid, Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiDotsVertical } from '@mdi/js';

import { IVideoListItemFragment, Maybe } from 'graphql/types';
import { VideoListItem } from 'video/components/VideoListItem';
import { List } from 'common/components/List';
import { Button } from 'common/components/Button';
import { IconButton } from 'common/components/IconButton';
import { BoxLoader } from 'common/components/Loader';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { VideoUpdateDrawer } from 'video/components/VideoManager';
import { VideoPlayerDialog } from 'video/components/VideoPlayerDialog';

interface IProps {
    isEditable?: boolean;
    video?: Maybe<IVideoListItemFragment>;
    loading?: boolean;
    onReplaceVideo?(): void;
}

export const VideoFormItem = ({
    isEditable,
    video,
    loading,
    onReplaceVideo,
}: IProps) => {
    const [translate] = useTranslation();
    const [showVideoUpdateDrawer, setShowVideoUpdateDrawer] = useState(false);
    const [showVideoDialog, setShowVideoDialog] = useState(false);

    if (loading) return <BoxLoader height="197px" />;

    if (!video) {
        return (
            <Button
                sx={{ width: '100%', height: '150px' }}
                variant="contained"
                onClick={onReplaceVideo}
            >
                {translate('selectVideo')}
            </Button>
        );
    }

    const { id, embedUrl, title } = video;

    return (
        <List>
            <VideoListItem
                disabled
                button={false}
                footer={
                    <Grid container>
                        <Grid item xs>
                            <Stack direction="row" spacing={2}>
                                {isEditable && (
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setShowVideoUpdateDrawer(true)
                                        }
                                    >
                                        {translate('edit')}
                                    </Button>
                                )}

                                {embedUrl && (
                                    <Button
                                        variant="contained"
                                        onClick={() => setShowVideoDialog(true)}
                                    >
                                        {translate('view')}
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                        {!!onReplaceVideo && (
                            <Grid
                                item
                                component={Box}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DropdownMenu
                                    anchor={
                                        <IconButton
                                            color="inherit"
                                            iconPath={mdiDotsVertical}
                                            iconSize="2.8rem"
                                        />
                                    }
                                    placement="bottom-end"
                                >
                                    <MenuItem onClick={onReplaceVideo}>
                                        {translate('replaceVideo')}
                                    </MenuItem>
                                </DropdownMenu>
                            </Grid>
                        )}
                    </Grid>
                }
                px={2}
                py={0}
                sx={{
                    flexWrap: 'wrap',
                }}
                video={video}
            />

            <VideoUpdateDrawer
                id={id}
                open={showVideoUpdateDrawer}
                onClose={() => setShowVideoUpdateDrawer(false)}
            />

            {embedUrl && (
                <VideoPlayerDialog
                    open={showVideoDialog}
                    src={embedUrl}
                    title={title}
                    onClose={() => setShowVideoDialog(false)}
                />
            )}
        </List>
    );
};
