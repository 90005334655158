import { Box, TableRow } from '@mui/material';

import { TableCell } from 'common/components/Table';
import { Checkbox } from 'common/components/Checkbox';
import { IUserListItemFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

import { UserAvatar } from '../UserAvatar';

interface IProps {
    disabled?: boolean;
    isEditable?: boolean;
    isSelectable?: boolean;
    isSelected?: boolean;
    user: IUserListItemFragment;
    onSelect?(): void;
}

export const UserTableRow = ({
    disabled,
    isEditable = true,
    isSelectable = true,
    isSelected,
    user,
    onSelect,
}: IProps) => {
    const { openGlobalDrawer } = useGlobalDrawer();

    const { id, name } = user;

    const isDisabled = !isEditable || disabled;

    return (
        <TableRow hover>
            {isSelectable && (
                <TableCell>
                    {isEditable ? (
                        <Checkbox
                            checked={isSelected}
                            disabled={isDisabled}
                            onClick={(e) => {
                                onSelect?.();

                                e.stopPropagation();
                            }}
                        />
                    ) : (
                        <Checkbox disabled checked={false} />
                    )}
                </TableCell>
            )}

            <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    openGlobalDrawer({
                        type: IGlobalDrawerType.UserProfile,
                        itemId: id,
                    });
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar bgColor="secondary" size={45} user={user} />

                    <Box sx={{ ml: 2 }}>
                        <Typography
                            color={isDisabled ? 'grey.500' : undefined}
                            variant="body2"
                        >
                            {name}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    );
};
