import { useMemo } from 'react';

import { OFFER_EVENT_INITIAL_VALUES } from 'offer/components/forms/formFields';
import {
    IContentTypeValue,
    IOfferEventDetailFragment,
    IOfferEventType,
    useExtraCategoriesQuery,
    useGroupsQuery,
} from 'graphql/types';
import { TCreateOfferFormValues, TOfferEventFormValues } from 'offer/types';
import { getOfferEventFormData } from 'offer/utils/offerEvent';
import { getGroupConditionsFilterInputs } from 'user/utils/groups';
import { getExtraCategoryInitialFormValues } from 'organisation/utils/extraCategory';
import { useApolloError } from 'common/hooks/useApolloError';
import { TExtraCategoryInput } from 'organisation/types';
import { IGroupConditionInput } from 'user/types';
import { useHashMatch } from 'route/hooks/useHashMatch';

interface IProps {
    edit?: boolean;
    offerEvent?: IOfferEventDetailFragment;
    includeOfferFields?: boolean;
    initialExtraFields?: string;
    offerEventType: IOfferEventType;
    isOfferDetailDrawer?: boolean;
    hasServiceProvider?: boolean;
    currentUserIsAuthor?: boolean;
}

export const useOfferEventInitialValues = ({
    edit,
    offerEvent,
    offerEventType,
    includeOfferFields,
    isOfferDetailDrawer,
    hasServiceProvider,
    currentUserIsAuthor,
}: IProps) => {
    const isOfferDetailDrawerPage =
        !!useHashMatch('OFFER_DETAIL_DRAWER') || isOfferDetailDrawer;

    const { showApolloError } = useApolloError();

    const { data: extraCategoryData, loading: extraCategoryLoading } =
        useExtraCategoriesQuery({
            variables: { contentType: IContentTypeValue.Offer },
            onError: showApolloError,
        });
    const { data: groupsData, loading: loadingGroups } = useGroupsQuery({
        onError: showApolloError,
        notifyOnNetworkStatusChange: true,
        skip: !currentUserIsAuthor,
        // If the current user is author it checks if it has one or more groups
        // If it has one group it will automatically add it to the group condition filter
        // If it has more than one group it does not add anything automatically
        variables: { first: 2, offset: 0 },
    });

    const initialValues = useMemo(() => {
        let offerInitialValues:
            | (TCreateOfferFormValues & {
                  extraCategories: TExtraCategoryInput;
                  groupConditions: IGroupConditionInput[];
              })
            | Record<string, never> = {};

        const { extraCategories } = extraCategoryData || {};

        if (includeOfferFields) {
            const groupConditions: IGroupConditionInput[] = [];

            // Add initial groups for authors if only one group exists
            if (!edit && currentUserIsAuthor) {
                const groups =
                    groupsData?.paginatedGroups?.edges
                        .map((edge) => edge?.node)
                        .filter(Boolean) || [];

                if (groups.length === 1) {
                    const groupCondition: IGroupConditionInput = {
                        id: '',
                        groups: groups,
                        extraCategoryValues: [],
                    };

                    groupConditions.push(groupCondition);
                }
            }

            offerInitialValues = {
                title: '',
                titleColor: '',
                hasDetailPage: false,
                extraCategories: getExtraCategoryInitialFormValues(
                    extraCategories,
                    []
                ),
                groupConditions,
            };
        }

        if (edit && !!offerEvent) {
            const {
                title,
                offer,
                trainers: trainerConnection,
                trainerApproval,
                maxSubscriptions,
                price,
                descriptionBeforeSubscription,
                descriptionAfterSubscription,
                accessTrainings,
                accessExternalContents,
                accessCourses,
                showParticipantList,
                publish,
                waitingList,
                type,
                certificate,
                accessScorms,
                accessVideos,
                scoreThreshold,
            } = offerEvent;

            if (includeOfferFields) {
                offerInitialValues = {
                    title: offer.title,
                    titleColor: offer.titleColor?.replace('#', '') || '',
                    overviewImage: offer.overviewImage || undefined,
                    hasDetailPage: undefined, // After creation of an offer this should not be changed
                    extraCategories: getExtraCategoryInitialFormValues(
                        extraCategories,
                        offer.extraCategoryValues
                    ),
                    groupConditions: getGroupConditionsFilterInputs(
                        offer.groupConditions || []
                    ),
                };
            }

            const trainers =
                trainerConnection?.edges
                    .map((edge) => edge?.node)
                    .filter(Boolean) || [];

            const offerEventInitialValues = {
                offerEventTitle: title,
                trainers,
                trainerApproval,
                maxSubscriptions,
                price,
                descriptionBeforeSubscription,
                descriptionAfterSubscription,
                accessTrainings: accessTrainings.edges
                    .map((trainingEdge) => trainingEdge?.node)
                    .filter(Boolean),
                accessExternalContents: accessExternalContents.edges
                    .map((externalContentEdge) => externalContentEdge?.node)
                    .filter(Boolean),
                accessCourses: accessCourses.edges
                    .map((courseEdge) => courseEdge?.node)
                    .filter(Boolean),
                showParticipantList,
                waitingList,
                certificate,
                publish,
                type,
                accessScorms: accessScorms.edges
                    .map((accessScormsEdge) => accessScormsEdge?.node)
                    .filter(Boolean),
                accessVideos: accessVideos.edges
                    .map((accessVideosEdge) => accessVideosEdge?.node)
                    .filter(Boolean),
                scoreThreshold,
                ...getOfferEventFormData(type, offerEvent, hasServiceProvider)
                    .initialValues,
            } as TOfferEventFormValues;

            if (isOfferDetailDrawerPage) {
                return {
                    ...offerInitialValues,
                    ...offerEventInitialValues,
                } as TOfferEventFormValues;
            }

            return offerEventInitialValues as TOfferEventFormValues;
        }

        return {
            ...offerInitialValues,
            publish: true,
            ...OFFER_EVENT_INITIAL_VALUES,
            ...getOfferEventFormData(
                offerEventType,
                undefined,
                hasServiceProvider
            ).initialValues,
        } as TOfferEventFormValues;
    }, [
        edit,
        offerEvent,
        offerEventType,
        includeOfferFields,
        isOfferDetailDrawerPage,
        extraCategoryData,
        groupsData,
    ]);

    return {
        initialValues,
        loading: extraCategoryLoading || loadingGroups,
    };
};
