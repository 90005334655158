import { ImageElementProps } from '@udecode/slate-plugins';
import { Transforms } from 'slate';
import {
    ReactEditor,
    useSlate,
    useFocused,
    useSelected,
    useReadOnly,
} from 'slate-react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { Figure, Figcaption } from 'common/components/Figure';
import { Img } from 'common/components/Img';
import { Selectable } from 'common/components/Selectable';

export const EditorImage = ({
    element,
    attributes,
    children,
    className,
    htmlAttributes,
}: ImageElementProps) => {
    const editor = useSlate();
    const focused = useFocused();
    const selected = useSelected();
    const readOnly = useReadOnly();

    const { url } = element;

    const elementPath = ReactEditor.findPath(editor, element);

    let imageUrl = url;

    // Append base url when url doesn't contain http.
    if (url && !url.startsWith('http') && !url.startsWith('data:')) {
        imageUrl = `${import.meta.env.VITE_BASE_URL}${imageUrl}`;
    }

    const image = <Img imgProps={htmlAttributes} src={imageUrl} />;

    const { children: captionChildren } = children?.props?.node || {};

    const hasText = !!captionChildren?.length && !!captionChildren[0].text;
    const hasCaption = !readOnly || (readOnly && hasText);

    return (
        <div {...attributes} className={className} data-slate-url={url}>
            <Figure>
                {!readOnly ? (
                    <Selectable
                        contentEditable={false}
                        selected={focused && selected}
                        onClick={() => {
                            // Select whole component when clicking the img
                            Transforms.select(editor, elementPath);
                        }}
                    >
                        {image}
                    </Selectable>
                ) : (
                    <Zoom zoomMargin={50}>{image}</Zoom>
                )}

                {hasCaption && <Figcaption>{children}</Figcaption>}
            </Figure>
        </div>
    );
};
