import { Box, Stack, MenuItem } from '@mui/material';
import { mdiAccountRemove, mdiFolderMultiplePlus, mdiPlus } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useApolloClient } from '@apollo/client';

import { ITrainingSectionProps } from 'training/types';
import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import { PageTitle } from 'common/components/PageTitle';
import { Tooltip } from 'common/components/Tooltip';
import { useFrontendPermissions } from 'user/hooks';
import { ActionButton } from 'common/components/ActionButton';
import { TrainingParticipantView } from 'training/components/TrainingParticipantView';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { UserSelectDrawer } from 'user/components/UserSelector/UserSelectDrawer';
import {
    IContentTypeValue,
    IFeature,
    ITrainingRole,
    ITrainingUserEdgeFragment,
    IUserListItemFragment,
    useAddUsersToTrainingMutation,
    useRemoveUsersFromTrainingMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import {
    IFilterFormItem,
    IGlobalDrawerType,
    IPaginationProps,
    ITableSelectionProps,
    TFilterFormValue,
    TFilterFormValues,
} from 'common/types';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useFeature } from 'common/hooks/useFeature';
import { ICreateUserFormValues } from 'user/types';
import { Switch } from 'common/components/Switch';
import { useTrainingParticipantList } from 'training/hooks/useTrainingParticipantList';
import { useSelection } from 'common/components/Table';
import { InlineStickyWrapper } from 'common/components/InlineStickyWrapper';

export interface ITableDataProps {
    paginationProps: IPaginationProps;
    searchProps: {
        filters: IFilterFormItem<TFilterFormValue>[];
        filterValues: TFilterFormValues;
        searchQueryParam: string;
        onSearch(searchValue: string): void;
        onFilterChange(values?: TFilterFormValues): void;
    };
    selectionProps: ITableSelectionProps<ITrainingUserEdgeFragment>;
}

interface IDeleteDialog {
    open: boolean;
    participantIds: string[];
}

export const Participants = ({ training }: ITrainingSectionProps) => {
    const [translate] = useTranslation();
    const { canUpdate } = useFrontendPermissions('training');
    const [sendInviteMail, setSendInviteMail] = useState(true);
    const client = useApolloClient();

    const canUpdateTraining = canUpdate || training.currentUserIsAuthor;
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [deleteDialog, setDeleteDialog] = useState<IDeleteDialog>({
        open: false,
        participantIds: [],
    });
    const [newDropdownOpen, setNewDropdownOpen] = useState(false);
    const { canCreate: canCreateParticipants } = useFrontendPermissions(
        'trainingParticipants'
    );
    const [participantsDrawerOpen, setParticipantsDrawerOpen] = useState(false);
    const [addUsersToTraining, { loading: addUsersToTrainingLoading }] =
        useAddUsersToTrainingMutation();
    const { openGlobalDrawer } = useGlobalDrawer();
    const [
        deleteUsersFromTraining,
        { loading: deleteUsersFromTrainingLoading },
    ] = useRemoveUsersFromTrainingMutation();

    const { canUse: canCreateUsers, loading: loadingCanCreateUsers } =
        useFeature(IFeature.CreateUsers);

    const { id, modelId } = training;

    const {
        participants,
        paginationSettings,
        loading: participantsLoading,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useTrainingParticipantList(id);

    const { ...selectionProps } = useSelection(participants);
    const { selected, onSelectAll } = selectionProps;

    const resetSettings = () => {
        setPage?.(1);
        onSearch('');
        onFilterChange();

        if (!!selected.length) onSelectAll();
    };

    const handleAddParticipants = async (
        selectedParticipantIds: string[],
        selectableParticipants?: IUserListItemFragment[],
        sendInvites?: boolean
    ) => {
        try {
            const response = await addUsersToTraining({
                variables: {
                    trainingId: id,
                    userIds: selectedParticipantIds,
                    role: ITrainingRole.Participant,
                    sendInvites,
                },
                update(cache) {
                    cache.modify({
                        id: cache.identify(training),
                        fields: {
                            participants(_existingParticipants, { DELETE }) {
                                return DELETE;
                            },
                        },
                    });
                },
            });

            const { data } = response;
            const { addedIds, alreadyAddedIds, failedIds } =
                data?.addUsersToTraining || {};

            if (!!addedIds?.length) {
                displaySnackbar(
                    translate('trainingParticipants.participantsAddedSuccess'),
                    {
                        variant: 'success',
                    }
                );

                // Refetch the selector to make sure the correct users are selected
                client.cache.evict({ fieldName: 'paginatedUsers' });
                client.cache.gc();
            }

            if (!!alreadyAddedIds?.length && !!selectableParticipants?.length) {
                const alreadyAddedParticipants = selectableParticipants.filter(
                    (participant) => alreadyAddedIds.includes(participant.id)
                );

                const alreadyAddedParticipantNames = alreadyAddedParticipants
                    .map((participant) => participant.name)
                    .join(', ');

                displaySnackbar(
                    translate('trainingParticipants.participantsAlreadyAdded', {
                        participants: alreadyAddedParticipantNames,
                        count: alreadyAddedParticipants.length,
                        interpolation: { escapeValue: false }, // Disable escaping values
                    }),
                    {
                        variant: 'error',
                    }
                );
            }

            if (failedIds?.length && !!selectableParticipants?.length) {
                const failedParticipants = selectableParticipants.filter(
                    (participant) => failedIds.includes(participant.id)
                );

                const failedParticipantNames = failedParticipants
                    .map((participant) => participant.name)
                    .join(', ');

                displaySnackbar(
                    translate('trainingParticipants.participantsAddedFailed', {
                        participants: failedParticipantNames,
                        count: failedParticipants.length,
                        interpolation: { escapeValue: false }, // Disable escaping values
                    }),
                    {
                        variant: 'error',
                    }
                );
            }
        } catch (error) {
            showApolloError(error);
        }

        if (!sendInviteMail) setSendInviteMail(true);

        resetSettings();
        setParticipantsDrawerOpen(false);
    };

    const handleRemoveParticipants = async () => {
        if (!participants?.length || !deleteDialog.participantIds.length) {
            return;
        }

        const selectedParticipantIds = deleteDialog.participantIds;

        try {
            const response = await deleteUsersFromTraining({
                variables: {
                    trainingId: id,
                    userIds: selectedParticipantIds,
                    roles: [ITrainingRole.Participant],
                },
                update(cache) {
                    cache.modify({
                        id: cache.identify(training),
                        fields: {
                            participants(_existingParticipants, { DELETE }) {
                                return DELETE;
                            },
                        },
                    });
                },
            });

            const { data } = response;
            const {
                removedIds,
                alreadyRemovedIds,
                failedIds,
                idsLinkedViaObject,
            } = data?.removeUsersFromTraining || {};

            if (!!idsLinkedViaObject?.length) {
                const couldNotRemoveParticipants = participants.filter(
                    (participantEdge) =>
                        idsLinkedViaObject.includes(participantEdge.node.id)
                );

                const couldNotRemoveParticipantNames =
                    couldNotRemoveParticipants
                        .map((participantEdge) => participantEdge.node.name)
                        .join(', ');

                displaySnackbar(
                    translate(
                        'trainingParticipants.participantsCouldNotRemove',
                        {
                            participants: couldNotRemoveParticipantNames,
                            count: couldNotRemoveParticipants.length,
                            interpolation: { escapeValue: false }, // Disable escaping values
                        }
                    ),
                    {
                        variant: 'error',
                    }
                );
            }

            if (!!removedIds?.length) {
                displaySnackbar(
                    translate(
                        'trainingParticipants.participantsRemovedSuccess'
                    ),
                    {
                        variant: 'success',
                    }
                );

                // Refetch the selector to make sure the correct users are selected
                client.cache.evict({ fieldName: 'paginatedUsers' });
                client.cache.gc();
            }

            if (!!alreadyRemovedIds?.length && participants?.length) {
                const alreadyRemovedParticipants = participants.filter(
                    (participantEdge) =>
                        alreadyRemovedIds.includes(participantEdge.node.id)
                );

                const alreadyRemovedParticipantNames =
                    alreadyRemovedParticipants
                        .map((participantEdge) => participantEdge.node.name)
                        .join(', ');

                displaySnackbar(
                    translate(
                        'trainingParticipants.participantsAlreadyRemoved',
                        {
                            trainers: alreadyRemovedParticipantNames,
                            count: alreadyRemovedParticipants.length,
                            interpolation: { escapeValue: false }, // Disable escaping values
                        }
                    ),
                    {
                        variant: 'error',
                    }
                );
            }

            if (failedIds?.length && !!participants?.length) {
                const failedParticipants = participants.filter(
                    (participantEdge) =>
                        failedIds.includes(participantEdge.node.id)
                );

                const failedParticipantNames = failedParticipants
                    .map((participantEdge) => participantEdge.node.name)
                    .join(', ');

                displaySnackbar(
                    translate(
                        'trainingParticipants.participantsRemovedFailed',
                        {
                            trainers: failedParticipantNames,
                            count: failedParticipants.length,
                            interpolation: { escapeValue: false }, // Disable escaping values
                        }
                    ),
                    {
                        variant: 'error',
                    }
                );
            }
        } catch (error) {
            showApolloError(error);
        }

        resetSettings();
        closeDeleteDialog();
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({ open: false, participantIds: [] });
    };

    const newButton =
        canCreateParticipants && canCreateUsers ? (
            <DropdownMenu
                enablePortal
                anchor={
                    <ActionButton
                        outlined
                        size="medium"
                        onClick={() => {
                            setNewDropdownOpen(!newDropdownOpen);
                        }}
                    >
                        <Icon path={mdiPlus} />
                    </ActionButton>
                }
                placement="bottom-start"
                onClose={() => setNewDropdownOpen(false)}
            >
                <MenuItem onClick={() => setParticipantsDrawerOpen(true)}>
                    {translate('trainingParticipants.addExistingParticipants')}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        openGlobalDrawer({
                            type: IGlobalDrawerType.UserCreate,
                            props: {
                                forTraining: true,
                                onCreate: (
                                    userId: string,
                                    formValues: ICreateUserFormValues
                                ) => {
                                    const sendTrainingInvite =
                                        formValues.sendTrainingInvite;

                                    handleAddParticipants(
                                        [userId],
                                        undefined,
                                        sendTrainingInvite
                                    );
                                },
                            },
                        });
                    }}
                >
                    {translate('trainingParticipants.addNewParticipant')}
                </MenuItem>

                <MenuItem
                    component={Link}
                    params={{ id: modelId }}
                    to="MANAGEMENT_TRAINING_PARTICIPANT_IMPORT"
                >
                    {translate('trainingParticipants.importParticipants')}
                </MenuItem>
            </DropdownMenu>
        ) : (
            <ActionButton
                outlined
                size="medium"
                onClick={() => setParticipantsDrawerOpen(true)}
            >
                <Icon path={mdiPlus} />
            </ActionButton>
        );

    const tableActions = canUpdateTraining
        ? [
              {
                  tooltipTitle: translate(
                      'trainingParticipants.deleteFromTraining'
                  ),
                  iconPath: mdiAccountRemove,
                  onClick: (selectedParticipantIds: string[]) => {
                      setDeleteDialog?.({
                          open: true,
                          participantIds: selectedParticipantIds,
                      });
                  },
              },
          ]
        : [];

    const loading =
        participantsLoading ||
        addUsersToTrainingLoading ||
        loadingCanCreateUsers;

    const selectDrawerFooterAppend = (
        <Box sx={{ mb: 2, textAlign: 'left' }}>
            <Switch
                checked={sendInviteMail}
                label={translate('sendUserTrainingInvite')}
                onChange={() => setSendInviteMail(!sendInviteMail)}
            />
        </Box>
    );

    const tableDataProps: ITableDataProps = {
        paginationProps: { paginationSettings, setPage },
        searchProps: {
            filters,
            filterValues,
            onSearch,
            onFilterChange,
            searchQueryParam: filterValues.q,
        },
        selectionProps,
    };

    return (
        <Box>
            <PageTitle mixpanelTitle="Participants - Learning Journey">
                {`${translate('participants')} - ${training.title}`}
            </PageTitle>

            {canUpdateTraining && (
                <InlineStickyWrapper fullWidth leftOffset={3}>
                    <Stack direction="row" display="flex" mb={1.5} spacing={1}>
                        <Box>
                            <Tooltip
                                placement="bottom"
                                title={
                                    newDropdownOpen
                                        ? undefined
                                        : translate<string>('addParticipants')
                                }
                            >
                                <Box>{newButton}</Box>
                            </Tooltip>
                        </Box>
                        {/* Temporary disable add groups for author because they cannot add groups yet */}
                        {!training.currentUserIsAuthor && (
                            <Tooltip title={translate<string>('addGroups')}>
                                <Box>
                                    <ActionButton
                                        outlined
                                        component={Link}
                                        params={{ id: modelId }}
                                        size="medium"
                                        to="MANAGEMENT_TRAINING_GROUPS"
                                    >
                                        <Icon
                                            path={mdiFolderMultiplePlus}
                                            size="1.8rem"
                                        />
                                    </ActionButton>
                                </Box>
                            </Tooltip>
                        )}
                    </Stack>
                </InlineStickyWrapper>
            )}

            <TrainingParticipantView
                isEditable={canUpdateTraining}
                loading={loading}
                participants={participants}
                tableActions={tableActions}
                tableDataProps={tableDataProps}
                training={training}
            />

            <UserSelectDrawer
                footerAppend={selectDrawerFooterAppend}
                loading={loading}
                open={participantsDrawerOpen}
                selectorQueryVariables={{
                    contentType: IContentTypeValue.Training,
                    objectId: id,
                }}
                onAddUsers={(
                    selectedUsers: string[],
                    selectableUsers?: IUserListItemFragment[]
                ) =>
                    handleAddParticipants(
                        selectedUsers,
                        selectableUsers,
                        sendInviteMail
                    )
                }
                onDrawerOpen={setParticipantsDrawerOpen}
            />

            <ConfirmDialog
                color="error"
                confirmText={translate('delete')}
                loading={deleteUsersFromTrainingLoading}
                open={deleteDialog.open}
                title={translate(
                    'trainingParticipants.deleteTrainingParticipantMessage.title'
                )}
                onCancel={closeDeleteDialog}
                onClose={closeDeleteDialog}
                onConfirm={handleRemoveParticipants}
            >
                {translate(
                    'trainingParticipants.deleteTrainingParticipantMessage.text'
                )}
            </ConfirmDialog>
        </Box>
    );
};
