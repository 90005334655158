import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    IModuleType,
    useDeleteModuleMutation,
    useModuleEditQuery,
    useUpdateScormModuleMutation,
} from 'graphql/types';
import { IModuleUpdateDrawerProps } from 'training/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { useApolloError } from 'common/hooks/useApolloError';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { Loader } from 'common/components/Loader';
import { getModuleOffsetFormValue } from 'training/utils/moduleOffset';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { getUrl } from 'route/utils/getUrl';
import { isUUID } from 'common/utils/isUUID';
import { NotFound } from 'common/components/NotFound';
import { getImageId } from 'common/utils/image';
import {
    IScormModuleFormProps,
    ScormModuleForm,
} from 'training/components/forms/ScormModuleForm';
import { ScormModuleSelectDrawer } from 'training/components/ScormModuleDrawer/ScormModuleSelectDrawer';
import { ScormCreateDrawer } from 'training/components/ScormCreateDrawer';

export const ScormModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [searchParams, setSearchParams] = useSearchParams();
    const routeQuery = useRouteQuery();

    const scormIdParam = routeQuery.get('scorm') || undefined;
    const [selectedScormId, setSelectedScormId] = useState(scormIdParam);
    const [formDrawerOpen, setFormDrawerOpen] = useState(false);

    const scormSelectDrawerOpen = useHashMatch('TRAINING_MODULE_SELECT');

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.Scorm },
    });

    const [updateModule] = useUpdateScormModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    useEffect(() => {
        if (!scormIdParam) return;

        // Remove course search params, if the course id is set we can remove it
        const { scorm, ...newSearchParams } = Object.fromEntries(searchParams);
        setSearchParams(new URLSearchParams(newSearchParams));
    }, [scormIdParam, searchParams, setSearchParams]);

    const module = data?.module;

    if (loading) return <Loader />;

    if (!isUUID(id) || !module || module?.__typename !== 'ScormModule') {
        return <NotFound />;
    }

    const handleSubmitUpdate: IScormModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { file, image, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            imageId: getImageId(module.image, image),
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        try {
            await updateModule({
                variables: { id, module: moduleValues },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.scorm.update'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.Scorm,
                },
                update: (cache) => {
                    cache.evict({ id: `ScormModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.scorm.delete'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const {
        scorm,
        title,
        description,
        image,
        weight,
        openOffset,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        file: scorm.zipFile || undefined,
        scormId: scorm.id,
        title,
        description,
        image: image || undefined,
        weight,
        offset: getModuleOffsetFormValue(openOffset),
    };

    return (
        <>
            <PageTitle>{translate('updateScormModule')}</PageTitle>

            <ScormModuleSelectDrawer
                edit
                open={scormSelectDrawerOpen}
                onClickNew={() => {
                    setFormDrawerOpen(true);
                }}
                onClose={() => {
                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
                onSelect={(scorm) => {
                    // Redirect to the pathname to go to the page without hashtag.
                    setSelectedScormId(scorm.id);
                    navigate(window.location.pathname);
                }}
            />

            {!loading && !!scorm && (
                <ScormModuleForm
                    edit
                    initialValues={initialValues}
                    moduleGroupId={moduleGroupId}
                    scorm={scorm}
                    scormId={selectedScormId}
                    onDelete={() => setShowDeleteAlert(true)}
                    onReplaceScorm={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmitUpdate}
                />
            )}

            <ScormCreateDrawer
                open={formDrawerOpen}
                onClose={() => {
                    setFormDrawerOpen(false);
                }}
                onCreate={(scormId) => {
                    setSelectedScormId(scormId);

                    setFormDrawerOpen(false);

                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteScormModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteScormModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
