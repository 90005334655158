import i18n, { use as i18nUse, t } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { translations } from 'locale/locale';
import { ILanguage } from 'graphql/types';

i18nUse(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: translations,
        detection: { lookupLocalStorage: 'language' },
        fallbackLng: ILanguage.En,
        debug: import.meta.env.MODE === 'development',
    });

// Because the default plural resolver rules are with lowercase letters
// we want to add same rule with uppercase
i18n.services.pluralResolver.addRule(
    ILanguage.Nl,
    i18n.services.pluralResolver.rules['nl']
);

i18n.services.pluralResolver.addRule(
    ILanguage.En,
    i18n.services.pluralResolver.rules['en']
);

const translate = t.bind(i18n);

export { i18n, translate };
