import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableRow } from '@mui/material';

import DEFAULT_TRAININGCARD from 'assets/images/default-trainingcard.webp';
import { TableCell, TableImage } from 'common/components/Table';
import { ITrainingListItemFragment } from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { Chip, ChipGroup } from 'common/components/Chip';
import { ExtraTrainingFieldsChips } from 'training/components/ExtraTrainingFieldsChips';

interface IProps {
    training: ITrainingListItemFragment;
}

export const TrainingManagementTableRow = ({ training }: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();

    const {
        id,
        title,
        overviewImage,
        image,
        isActive,
        extraTrainingFieldsLabels,
    } = training;

    const trainingImage =
        overviewImage?.url || image?.url || DEFAULT_TRAININGCARD;

    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(getUrl('TRAINING', { trainingId: id }))}
        >
            <TableCell sx={{ width: '1px' }}>
                <TableImage src={trainingImage} />
            </TableCell>
            <TableCell>
                {title}

                <ChipGroup sx={{ my: 0.25 }}>
                    {!isActive && (
                        <Chip bgColor="warning" label={translate('inactive')} />
                    )}
                    {!!extraTrainingFieldsLabels?.length && (
                        <ExtraTrainingFieldsChips
                            extraTrainingFieldsLabels={
                                extraTrainingFieldsLabels
                            }
                        />
                    )}
                </ChipGroup>
            </TableCell>
        </TableRow>
    );
};
