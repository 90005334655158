import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/material';

import { DeleteButton } from '../DeleteButton';

export const FormDeleteButton = ({ sx, children, ...other }: ButtonProps) => {
    const [translate] = useTranslation();

    return (
        <DeleteButton {...other}>
            {children || translate('delete')}
        </DeleteButton>
    );
};
