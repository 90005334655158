import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';

import { useCurrentUserQuery } from 'graphql/types';
import { parseJSON } from 'common/utils/json';

interface IProps {
    children?: React.ReactNode;
}

export const CrispLayer = ({ children }: IProps) => {
    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const currentUser = currentUserData?.currentUser;

    useEffect(() => {
        if (
            import.meta.env.MODE === 'development' ||
            !import.meta.env.VITE_CRISP_WEBSITE_ID ||
            loadingUser ||
            !currentUser
        ) {
            return;
        }

        const crispSettings = parseJSON(currentUser.crispSettings);

        if (!crispSettings) return;

        const {
            email,
            name,
            company_name,
            user_role,
            language_override,
            ...otherSettings
        } = crispSettings;

        Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID, {
            locale: language_override,
        });

        // Set safe mode to disable wanings in console
        Crisp.setSafeMode(true);

        Crisp.user.setEmail(email);
        Crisp.user.setNickname(name);
        Crisp.user.setCompany(company_name, {});

        const segments = [];
        user_role.split(',').forEach((role: string) => {
            if (role.trim() !== 'participant') {
                segments.push(role.trim());
            }
        });
        segments.push('chat');
        Crisp.session.setSegments(segments, false);

        Crisp.session.setData(otherSettings);
    }, [loadingUser, currentUser]);

    return <>{children}</>;
};
