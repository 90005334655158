import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components/Button';
import { Chip } from 'common/components/Chip';
import { IOfferEventDetailFragment } from 'graphql/types';

interface IProps {
    loading?: boolean;
    offerEvent: IOfferEventDetailFragment;
    onClickSubscribe(): void;
}

export const SubscribeSection = ({
    loading,
    offerEvent,
    onClickSubscribe,
}: IProps) => {
    const [translate] = useTranslation();
    const { spotsLeft, waitingList, maxSubscriptions } = offerEvent;
    const spotsAmount = !!spotsLeft && spotsLeft > 0 ? spotsLeft : 0;

    const hasMaxSubscriptions = maxSubscriptions > 0;
    const showWaitingListText =
        waitingList && hasMaxSubscriptions && !spotsAmount;
    const showSubscriptionsFullText =
        !waitingList && !spotsAmount && hasMaxSubscriptions;
    const showSubscribeButton =
        !!spotsAmount || !hasMaxSubscriptions || waitingList;

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={3}
                sx={{ alignItems: { sm: 'center' } }}
            >
                {showSubscribeButton && (
                    <Button
                        color="primary"
                        loading={loading}
                        sx={{ flex: 'none', maxWidth: 'fit-content' }}
                        variant="contained"
                        onClick={onClickSubscribe}
                    >
                        {showWaitingListText
                            ? translate('offerEventForm.waitingListSubscribe')
                            : translate('register')}
                    </Button>
                )}

                {!!spotsAmount && (
                    <Box ml={3}>
                        <Chip
                            label={translate('spotsLeft', {
                                count: spotsAmount,
                            })}
                        />
                    </Box>
                )}

                {(showWaitingListText || showSubscriptionsFullText) && (
                    <Box>
                        <Typography variant="body2">
                            {translate(
                                showWaitingListText
                                    ? 'offerEventForm.waitingListFull'
                                    : 'offerEventForm.subscriptionsFull'
                            )}
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};
