import {
    ClickAwayListener,
    Fade,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import { PopperProps } from '@mui/material/Popper';

interface IProps extends PopperProps {
    onClose: (event: Event | React.SyntheticEvent) => void;
    disableOverflowModifier?: boolean;
}

export const DropdownMenuList = ({
    children,
    onClose,
    disableOverflowModifier,
    ...other
}: IProps) => {
    const modifiers = [
        disableOverflowModifier && { name: 'preventOverflow', enabled: false },
    ].filter(Boolean);

    return (
        <Popper disablePortal transition modifiers={modifiers} {...other}>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={150}>
                    <Paper
                        sx={{
                            boxShadow:
                                'rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;',
                        }}
                    >
                        <ClickAwayListener
                            disableReactTree
                            onClickAway={onClose}
                        >
                            <MenuList>{children}</MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
};
