import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import {
    ToolbarButton,
    ToolbarButtonProps,
    unwrapNodes,
    unwrapList,
} from '@udecode/slate-plugins';

import { options } from '../../options';

export const ToolbarClearFormat = (props: ToolbarButtonProps) => {
    const editor = useSlate();

    return (
        <ToolbarButton
            onMouseDown={(event) => {
                event.preventDefault();

                if (!editor.selection) return;

                // Remove marks
                editor.removeMark(options.bold.type);
                editor.removeMark(options.italic.type);
                editor.removeMark(options.underline.type);
                editor.removeMark(options.superscript.type);

                // Remove link
                unwrapNodes(editor, {
                    at: editor.selection,
                    match: { type: options.link.type },
                });

                // Reset lists
                unwrapList(editor, options);

                // Reset nodes to paragraph
                Transforms.setNodes(editor, {
                    type: options.p.type,
                });
            }}
            {...props}
        />
    );
};
