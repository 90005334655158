import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BoxLoader } from 'common/components/Loader';
import { IVideoListItemFragment } from 'graphql/types';
import { useVideoList } from 'video/hooks';
import { VideoAddList } from 'video/components/VideoAddList';
import { Pagination } from 'common/components/Pagination';
import { FilterBar } from 'common/components/FilterBar';
import { Divider } from 'common/components/Divider';

interface IProps {
    selectMultiple?: boolean;
    onSelect?(video: IVideoListItemFragment): void;
    selectedIds?: string[];
    listItemProps?: React.ComponentProps<typeof VideoAddList>['listItemProps'];
}

export const VideoFilterAddList = ({
    selectMultiple = true,
    onSelect,
    listItemProps,
}: IProps) => {
    const [translate] = useTranslation();
    const [selectedVideos, setSelectedVideos] = useState<
        IVideoListItemFragment[]
    >([]);

    const {
        videos,
        loading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useVideoList(false);

    const handleSelectVideo = (video: IVideoListItemFragment) => {
        onSelect?.(video);

        if (!selectMultiple) return;

        const newSelectedVideos = [...selectedVideos, video];
        setSelectedVideos(newSelectedVideos);
    };

    const handleRemoveVideo = (video: IVideoListItemFragment) => {
        if (!selectMultiple) return;

        const newSelectedVideos = selectedVideos.filter(
            (selectedVideo) => selectedVideo.id !== video.id
        );

        setSelectedVideos(newSelectedVideos);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const hasResults = !!paginationSettings.count;

    return (
        <>
            <FilterBar
                useFilterDrawer
                disabled={loading}
                filterDrawerProps={{
                    title: translate('filterForm.videos.title'),
                    description: translate('filterForm.videos.description'),
                }}
                formFilters={filters}
                formFilterValues={filterValues}
                initialSearchValue={filterValues.q}
                placeholder={translate('filterBarPlaceholder')}
                onFiltersChange={(values) => {
                    setPage(1);

                    onFilterChange?.(values);
                }}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />
            {loading ? (
                <BoxLoader />
            ) : (
                <>
                    <VideoAddList
                        addedVideos={selectedVideos}
                        listItemProps={{
                            ...listItemProps,
                            addButton: selectMultiple,
                        }}
                        selectedVideos={selectedVideos}
                        videos={videos}
                        onAddVideo={handleSelectVideo}
                        onRemoveVideo={(
                            selectedVideo: IVideoListItemFragment
                        ) => handleRemoveVideo(selectedVideo)}
                    />

                    {paginationSettings.count > -1 && (
                        <Box mt={hasResults ? 2 : 0}>
                            {hasResults && <Divider />}

                            <Box mt={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={
                                        paginationSettings.count === 1
                                            ? translate('video')
                                            : translate('videos')
                                    }
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
