import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { BackgroundImg } from 'common/components/Img';
import { BasePage } from 'hydra/pages/BasePage';
import MANAGEMENT_HEADER from 'assets/images/management-header-enrollments.webp';
import { Typography } from 'common/components/Typography';
import { Tab, Tabs } from 'common/components/Tabs';
import { RouteLink } from 'route/components/RouteLink';
import { EnrollmentsManagementTable } from 'offer/components/EnrollmentsManagementTable';
import { IFeature, IOfferEventSubscriptionStatus } from 'graphql/types';
import { useFrontendPermissions } from 'user/hooks';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { useFeature } from 'common/hooks/useFeature';
import { UpgradePage } from 'common/components/UpgradePage';
import { Loader } from 'common/components/Loader';
import { UpgradeContent } from 'offer/components/UpgradeContent/UpgradeContent';
import { PageTitle } from 'common/components/PageTitle';

export const EnrollmentsManagementPage = () => {
    const [translate] = useTranslation();
    const {
        canUse: canUseOffers,
        loading: loadingCanUse,
        userCanSeeUpgrade,
    } = useFeature(IFeature.Offers);
    const { canRead: canReadEnrollmentsManagement } = useFrontendPermissions(
        'enrollmentsManagement'
    );

    const isWaitingListPage = !!useRouteMatch(
        'MANAGEMENT_USERS_ENROLLMENTS_WAITING_LIST'
    );
    const isEnrolledPage = !!useRouteMatch(
        'MANAGEMENT_USERS_ENROLLMENTS_ENROLLED'
    );
    const isDeniedPage = !!useRouteMatch('MANAGEMENT_USERS_ENROLLMENTS_DENIED');
    const isCompletedPage = !!useRouteMatch(
        'MANAGEMENT_USERS_ENROLLMENTS_COMPLETED'
    );
    const isAbsentPage = !!useRouteMatch('MANAGEMENT_USERS_ENROLLMENTS_ABSENT');

    let defaultEnrollmentStatus = IOfferEventSubscriptionStatus.Requested;

    if (isWaitingListPage) {
        defaultEnrollmentStatus = IOfferEventSubscriptionStatus.Full;
    } else if (isEnrolledPage) {
        defaultEnrollmentStatus = IOfferEventSubscriptionStatus.Enrolled;
    } else if (isDeniedPage) {
        defaultEnrollmentStatus = IOfferEventSubscriptionStatus.Denied;
    } else if (isCompletedPage) {
        defaultEnrollmentStatus = IOfferEventSubscriptionStatus.Completed;
    } else if (isAbsentPage) {
        defaultEnrollmentStatus = IOfferEventSubscriptionStatus.Absent;
    }

    const [enrollmentStatus, setEnrollmentStatus] =
        useState<IOfferEventSubscriptionStatus>(defaultEnrollmentStatus);

    if (loadingCanUse) return <Loader />;

    if (!canUseOffers && userCanSeeUpgrade) {
        return (
            <BasePage contentContainerMaxWidth="lg">
                <UpgradePage>
                    <UpgradeContent />
                </UpgradePage>
            </BasePage>
        );
    }

    if (!canReadEnrollmentsManagement || !canUseOffers) {
        return <FourOFourPage />;
    }

    let browserTitleStatusTrans = translate(
        'enrollmentsManagementPage.tabs.pending'
    );

    switch (enrollmentStatus) {
        case IOfferEventSubscriptionStatus.Full:
            browserTitleStatusTrans = translate(
                'enrollmentsManagementPage.tabs.waitingList'
            );
            break;
        case IOfferEventSubscriptionStatus.Enrolled:
            browserTitleStatusTrans = translate(
                'enrollmentsManagementPage.tabs.enrolled'
            );
            break;
        case IOfferEventSubscriptionStatus.Denied:
            browserTitleStatusTrans = translate(
                'enrollmentsManagementPage.tabs.denied'
            );
            break;
        case IOfferEventSubscriptionStatus.Completed:
            browserTitleStatusTrans = translate(
                'enrollmentsManagementPage.tabs.completed'
            );
            break;
        case IOfferEventSubscriptionStatus.Absent:
            browserTitleStatusTrans = translate(
                'enrollmentsManagementPage.tabs.absent'
            );
            break;
    }

    const header = (
        <BackgroundImg
            src={MANAGEMENT_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            <PageTitle>
                {translate('enrollmentsManagementPage.browserTitle', {
                    status: browserTitleStatusTrans,
                })}
            </PageTitle>

            <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                <Typography variant="h1">
                    {translate('enrollmentsManagementPage.pageTitle')}
                </Typography>
            </Box>

            <Tabs sx={{ mb: 4, mt: 2 }} value={enrollmentStatus}>
                <Tab
                    component={RouteLink}
                    label={translate('enrollmentsManagementPage.tabs.pending')}
                    to="MANAGEMENT_USERS_ENROLLMENTS"
                    value={IOfferEventSubscriptionStatus.Requested}
                    onClick={() =>
                        setEnrollmentStatus(
                            IOfferEventSubscriptionStatus.Requested
                        )
                    }
                />

                <Tab
                    component={RouteLink}
                    label={translate(
                        'enrollmentsManagementPage.tabs.waitingList'
                    )}
                    to="MANAGEMENT_USERS_ENROLLMENTS_WAITING_LIST"
                    value={IOfferEventSubscriptionStatus.Full}
                    onClick={() =>
                        setEnrollmentStatus(IOfferEventSubscriptionStatus.Full)
                    }
                />

                <Tab
                    component={RouteLink}
                    label={translate('enrollmentsManagementPage.tabs.enrolled')}
                    to="MANAGEMENT_USERS_ENROLLMENTS_ENROLLED"
                    value={IOfferEventSubscriptionStatus.Enrolled}
                    onClick={() =>
                        setEnrollmentStatus(
                            IOfferEventSubscriptionStatus.Enrolled
                        )
                    }
                />

                <Tab
                    component={RouteLink}
                    label={translate('enrollmentsManagementPage.tabs.denied')}
                    to="MANAGEMENT_USERS_ENROLLMENTS_DENIED"
                    value={IOfferEventSubscriptionStatus.Denied}
                    onClick={() =>
                        setEnrollmentStatus(
                            IOfferEventSubscriptionStatus.Denied
                        )
                    }
                />

                <Tab
                    component={RouteLink}
                    label={translate(
                        'enrollmentsManagementPage.tabs.completed'
                    )}
                    to="MANAGEMENT_USERS_ENROLLMENTS_COMPLETED"
                    value={IOfferEventSubscriptionStatus.Completed}
                    onClick={() =>
                        setEnrollmentStatus(
                            IOfferEventSubscriptionStatus.Completed
                        )
                    }
                />

                <Tab
                    component={RouteLink}
                    label={translate('enrollmentsManagementPage.tabs.absent')}
                    to="MANAGEMENT_USERS_ENROLLMENTS_ABSENT"
                    value={IOfferEventSubscriptionStatus.Absent}
                    onClick={() =>
                        setEnrollmentStatus(
                            IOfferEventSubscriptionStatus.Absent
                        )
                    }
                />
            </Tabs>

            <EnrollmentsManagementTable enrollmentStatus={enrollmentStatus} />
        </BasePage>
    );
};
