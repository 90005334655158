import { Box } from '@mui/material';
import { useState } from 'react';
import { mdiCalendarFilter } from '@mdi/js';

import { Button } from 'common/components/Button';
import { TDateRange } from 'common/types';
import { getDateRangeText } from 'common/utils/formatDate';
import { Icon } from 'common/components/Icon';
import { DateRangeModal } from 'common/components/DateRangeModal';

interface IProps {
    dateRange?: TDateRange;
    dateRangeDefaultText?: string;
    disabled?: boolean;
    disableEndDate?: boolean;
    onChange?(dateRange?: TDateRange): void;
}

export const DateRangeFilter = ({
    dateRange,
    dateRangeDefaultText,
    disabled,
    disableEndDate,
    onChange,
}: IProps) => {
    const [openModal, setOpenModal] = useState(false);

    const dateRangeText = getDateRangeText(dateRange, dateRangeDefaultText);

    return (
        <Box>
            <Button
                disableElevation
                disabled={disabled}
                startIcon={<Icon path={mdiCalendarFilter} size="1.9rem" />}
                sx={{ whiteSpace: 'nowrap' }}
                variant="outlined"
                onClick={() => setOpenModal(true)}
            >
                {dateRangeText}
            </Button>

            <DateRangeModal
                defaultDateRange={dateRange}
                disableEndDate={disableEndDate}
                open={openModal}
                onChange={(dateRange) => {
                    onChange?.(dateRange);
                }}
                onClose={() => setOpenModal(false)}
            />
        </Box>
    );
};
