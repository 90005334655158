import { Box } from '@mui/material';
import { Formik, FormikConfig, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { IChangePasswordMutationVariables } from 'graphql/types';
import { TextField } from 'common/components/FormField';
import { Button } from 'common/components/Button';

type IProps = Omit<
    FormikConfig<IChangePasswordMutationVariables>,
    'initialValues'
>;

export const ChangePasswordForm = (props: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(translate('validate.required'))
            .min(8, translate('validate.passwordToShort', { minLength: 8 })),
        passwordRepeat: Yup.string()
            .required(translate('validate.required'))
            .oneOf(
                [Yup.ref('password'), null],
                translate('validate.passwordsDoNotMatch')
            ),
    });

    return (
        <Formik
            enableReinitialize
            initialValues={{ password: '', passwordRepeat: '' }}
            validationSchema={validationSchema}
            {...props}
        >
            {({ submitForm, isSubmitting, dirty }) => (
                <Form>
                    {/**
                     * Add hidden username suggested by chrome.
                     * See: https://www.chromium.org/developers/design-documents/create-amazing-password-forms/#use-hidden-fields-for-implicit-information
                     *
                     * Note: This username should be filled later when we actually are logged in and we know the
                     * email of the current user
                     *  */}
                    <Field
                        hidden
                        autoComplete="username"
                        name="username"
                        type="text"
                        value=""
                    />

                    <Field
                        autoComplete="new-password"
                        component={TextField}
                        label={translate('password')}
                        name="password"
                        type="password"
                    />

                    <Field
                        autoComplete="new-password"
                        component={TextField}
                        label={translate('repeatPassword')}
                        name="passwordRepeat"
                        type="password"
                    />

                    <Box mt={2}>
                        <Button
                            color="primary"
                            disabled={isSubmitting || (!dirty && !isSubmitting)}
                            loading={isSubmitting}
                            type="submit"
                            variant="contained"
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault();

                                submitForm();
                            }}
                        >
                            {translate('setPassword')}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
