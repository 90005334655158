import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    IGroupInputOptionFragment,
    IRole,
    ITrainingInputOptionFragment,
    useCurrentUserQuery,
    useExportCourseReportMutation,
    IExportCourseReportType,
} from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Select } from 'common/components/FormField';
import { CourseTrainingSelectField } from 'training/components/CourseTrainingSelectField';
import { GroupSelectField } from 'user/components/GroupSelectField';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';

type TCourseExportFormValues = {
    exportType: 'answers' | 'averageScore';
    training: ITrainingInputOptionFragment[];
    groups: IGroupInputOptionFragment[];
};

interface IProps {
    courseId: string;
    open: boolean;
    onClose(): void;
}

export const CourseExportReportDialog = ({
    courseId,
    open,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();

    const { trainingId } = useParams() as { trainingId?: string };

    const [exportCourseReport, { loading: loadingExport }] =
        useExportCourseReportMutation({
            onError: showApolloError,
        });

    const { data: currentUserData } = useCurrentUserQuery();
    const currentUser = currentUserData?.currentUser;

    const isManager = currentUser?.roles?.includes(IRole.Manager);
    const isGroupManager = currentUser?.isGroupManager;

    const canSelectGroups = isManager || isGroupManager;

    const initialValues: TCourseExportFormValues = {
        exportType: 'answers',
        training: [],
        groups: [],
    };

    const handleExport = async (values: TCourseExportFormValues) => {
        const { exportType, training, groups } = values;

        // If trainingId is present, we export for the specific training
        const trainingIds = trainingId
            ? [trainingId]
            : training.map(({ id }) => id);
        const groupIds = canSelectGroups ? groups.map(({ id }) => id) : [];

        const variables = {
            courseIds: [courseId],
            trainingIds,
            groupIds,
            reportType:
                exportType === 'answers'
                    ? IExportCourseReportType.Answers
                    : IExportCourseReportType.AverageScore,
        };

        await exportCourseReport({ variables });

        displaySnackbar(translate('courseExportReportDialog.exportSuccess'), {
            variant: 'success',
        });

        onClose?.();
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
                await handleExport(values);

                actions.resetForm();
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <ConfirmDialog
                    fullWidth
                    confirmText={translate('export')}
                    loading={isSubmitting || loadingExport}
                    maxWidth="sm"
                    open={open}
                    title={translate('courseExportReportDialog.title')}
                    onCancel={onClose}
                    onClose={onClose}
                    onConfirm={submitForm}
                >
                    <Typography sx={{ mb: 1 }}>
                        {translate('courseExportReportDialog.text', {
                            email: currentUser?.email,
                        })}
                    </Typography>

                    <Form>
                        <Field
                            component={Select}
                            label={translate(
                                'courseExportReportDialog.exportType'
                            )}
                            name="exportType"
                            options={[
                                {
                                    label: translate(
                                        'courseExportReportDialog.exportCourseAnswers'
                                    ),
                                    value: 'answers',
                                },
                                {
                                    label: translate(
                                        'courseExportReportDialog.exportCourseAverageScore'
                                    ),
                                    value: 'averageScore',
                                },
                            ]}
                        />

                        {!trainingId && (
                            <Field
                                component={CourseTrainingSelectField}
                                courseId={courseId}
                                label={translate('trainings')}
                                name="training"
                            />
                        )}

                        {canSelectGroups && (
                            <Field
                                component={GroupSelectField}
                                label={translate('groups')}
                                name="groups"
                            />
                        )}
                    </Form>
                </ConfirmDialog>
            )}
        </Formik>
    );
};
