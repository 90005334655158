import React, { cloneElement } from 'react';
import { Draggable as RBDDDraggable, DraggableProps } from '@hello-pangea/dnd';

interface IProps extends Omit<DraggableProps, 'children'> {
    dragDropDisabled?: boolean;
    passContext?: boolean;
    children: React.ReactNode;
}

export const Draggable = ({
    dragDropDisabled,
    children,
    passContext,
    ...other
}: IProps) => {
    if (dragDropDisabled) return <>{children}</>;

    return (
        <RBDDDraggable {...other}>
            {(draggableProvided, draggableSnapshot) => {
                if (passContext) {
                    return (
                        <>
                            {React.Children.map(children, (child) => {
                                if (!React.isValidElement(child)) return null;

                                return cloneElement(
                                    child as React.ReactElement,
                                    {
                                        draggableProvided,
                                        draggableSnapshot,
                                    }
                                );
                            })}
                        </>
                    );
                }

                return (
                    <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                    >
                        {children}
                    </div>
                );
            }}
        </RBDDDraggable>
    );
};
