import { ITimeDelta, ITimeDeltaFragment, Maybe } from 'graphql/types';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

const toFormat = (number: number) => `0${number.toFixed(0)}`.substr(-2);

export const formatTimeDelta = (time?: Maybe<ITimeDelta>): string => {
    if (!time) return '00:00';

    const hours = time.seconds / SECONDS_IN_HOUR;
    const minutes = (hours % 1) * SECONDS_IN_MINUTE;

    return `${toFormat(hours)}:${toFormat(minutes)}`;
};

export interface IParsedTimeDelta {
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    totalSeconds: number;
    isNegative: boolean;
}

export const parseTimeDelta = ({
    totalSeconds,
}: ITimeDeltaFragment): IParsedTimeDelta => {
    const isNegative = totalSeconds < 0;
    const seconds = Math.abs(totalSeconds);

    let days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor(seconds % (3600 * 24)) / 3600;
    const minutes = Math.floor(seconds % 3600) / 60;

    const weeks = Math.floor(days / 7);

    if (weeks) days -= weeks * 7;

    return {
        weeks,
        days,
        hours,
        minutes,
        totalSeconds,
        isNegative,
    };
};
