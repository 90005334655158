import { useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiFileRemove } from '@mdi/js';

import { DeleteSharedFilesAlert } from 'training/components/Alerts';
import { ITrainingSectionProps } from 'training/types';
import { ApolloError } from 'common/components/ApolloError';
import {
    IFileFragment,
    useCurrentUserQuery,
    useAddSharedFilesToTrainingMutation,
    useRemoveSharedFilesFromTrainingMutation,
    useTrainingSharedFilesQuery,
    ITrainingSharedFilesFragment,
} from 'graphql/types';
import { FileTable } from 'common/tables';
import { PageTitle } from 'common/components/PageTitle';
import { Tooltip } from 'common/components/Tooltip';
import { useFrontendPermissions } from 'user/hooks';
import { BoxLoader } from 'common/components/Loader';
import { IconButton } from 'common/components/IconButton';

export const SharedFiles = ({ training }: ITrainingSectionProps) => {
    const [translate] = useTranslation();
    const { canUpdate } = useFrontendPermissions('training');

    const canUpdateTraining = canUpdate || training.currentUserIsAuthor;

    const [filesAreUpdating, setFilesAreUpdating] = useState(false);
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);
    const deleteCallback = useRef<() => void>();
    const uploadingFileRefs = useRef<string[]>([]);
    const { data: currentUserData } = useCurrentUserQuery();

    const { id } = training;

    const { data, loading, error } = useTrainingSharedFilesQuery({
        variables: { id },
    });

    const sharedFiles = data?.training?.sharedFiles;

    const [addFiles, { error: addFilesError }] =
        useAddSharedFilesToTrainingMutation();

    const [removeFiles, { error: removeFilesError }] =
        useRemoveSharedFilesFromTrainingMutation();

    const sortedFiles = useMemo(() => {
        if (!training) return [];

        // Sorted by upload date, latest first
        const newFiles = [...(sharedFiles || [])];

        return newFiles.sort((a, b) => {
            const date1 = new Date(a.uploadDate);
            const date2 = new Date(b.uploadDate);

            return date2.getTime() - date1.getTime();
        });
    }, [training, sharedFiles]);

    if (loading) return <BoxLoader />;
    if (error) return <ApolloError error={error} />;

    const handleSharedFilesMutationUpdate = (
        updatedTraining: ITrainingSharedFilesFragment,
        filterUploadingFiles?: (fileIds: string[]) => void
    ) => {
        const { sharedFiles } = updatedTraining;
        if (!sharedFiles) return;

        uploadingFileRefs.current = [];

        if (filterUploadingFiles) {
            filterUploadingFiles(sharedFiles.map((file) => file.id));
        }

        setFilesAreUpdating(false);
    };

    let handleAddFile;

    if (!filesAreUpdating) {
        handleAddFile = (uploadedFile: IFileFragment) => {
            // Collection of all the uploaded file ids in a batch
            uploadingFileRefs.current.push(uploadedFile.id);
        };
    }

    const handleUploadFinish = (
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        addFiles({
            variables: {
                id,
                fileIds: uploadingFileRefs.current,
            },
            update: (_cache, result) => {
                const { addSharedFilesToTraining } = result?.data || {};
                const updatedTraining = addSharedFilesToTraining?.training;

                if (!updatedTraining) return;

                handleSharedFilesMutationUpdate(
                    updatedTraining,
                    filesUpdateCallback
                );
            },
        });
    };

    const handleRemoveFiles = (
        fileIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!sharedFiles) return;

        setFilesAreUpdating(true);

        removeFiles({
            variables: {
                id,
                fileIds,
            },
            optimisticResponse: {
                __typename: 'Mutations',
                removeSharedFilesFromTraining: {
                    __typename: 'RemoveSharedFilesFromTraining',
                    training: {
                        ...training,
                        sharedFiles: sharedFiles.filter(
                            (file) => !fileIds.includes(file.id)
                        ),
                    },
                },
            },
            update: (_cache, result) => {
                const { removeSharedFilesFromTraining } = result?.data || {};
                const updatedTraining = removeSharedFilesFromTraining?.training;

                if (!updatedTraining) return;

                handleSharedFilesMutationUpdate(
                    updatedTraining,
                    filesUpdateCallback
                );
            },
        });
    };

    const fileTableActions = (
        selected: string[],
        onSelectAll: () => void,
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        const selectedFileIds =
            sortedFiles
                .filter((file) => {
                    const isOwner =
                        file?.owner &&
                        currentUserData?.currentUser &&
                        file.owner.id === currentUserData.currentUser.id;

                    // Check if user can select the file by checking if the user is owner or can update training
                    return (
                        selected.includes(file.id) &&
                        (isOwner || canUpdateTraining)
                    );
                })
                .map((file) => file.id) || [];

        return (
            <Tooltip title={translate<string>('delete')}>
                <Box>
                    <IconButton
                        color="inherit"
                        disabled={!selected.length}
                        iconPath={mdiFileRemove}
                        iconSize="2.4rem"
                        onClick={() => {
                            deleteCallback.current = () => {
                                handleRemoveFiles(
                                    selectedFileIds,
                                    filesUpdateCallback
                                );

                                // Deselect all (Would be better with useImperativeHandle(ref, () => ({)
                                onSelectAll();

                                // Remove callback after usage
                                deleteCallback.current = undefined;
                            };

                            setShowOnDeleteAlert(true);
                        }}
                    />
                </Box>
            </Tooltip>
        );
    };

    return (
        <>
            <PageTitle mixpanelTitle="Shared files - Learning Journey">
                {`${translate('sharedFiles')} - ${training.title}`}
            </PageTitle>

            {addFilesError && <ApolloError error={addFilesError} />}
            {removeFilesError && <ApolloError error={removeFilesError} />}

            <FileTable
                withOwnerColumn
                currentUserSelection={!canUpdateTraining}
                id="sharedFiles"
                items={sortedFiles}
                renderSelectedActions={fileTableActions}
                onAdd={handleAddFile}
                onRemove={handleRemoveFiles}
                onUploadFinish={handleUploadFinish}
                onUploadStart={() => setFilesAreUpdating(true)}
            />

            <DeleteSharedFilesAlert
                open={showOnDeleteAlert}
                onCancel={() => {
                    setShowOnDeleteAlert(false);
                }}
                onConfirm={() => {
                    setShowOnDeleteAlert(false);

                    if (deleteCallback.current) deleteCallback.current();
                }}
            />
        </>
    );
};
