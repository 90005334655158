import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOrganisationQuery } from 'graphql/types';
import { createTheme } from 'theme';
import { GlobalStyle } from 'globalStyle';
import { LoaderOverlay } from 'common/components/Loader';

interface IProps {
    children: React.ReactNode;
}

export const ThemeProvider = ({ children }: IProps) => {
    const { t: translate } = useTranslation();

    const { data: organisationData, loading } = useOrganisationQuery();

    const organisation = organisationData?.organisation;
    const muiTheme = createTheme(organisation?.theme);

    return (
        <>
            <LoaderOverlay visible={loading}>
                {translate('loadingTexts.loadingTheme')}
            </LoaderOverlay>

            {!loading && (
                <ScThemeProvider theme={muiTheme}>
                    <StyledEngineProvider injectFirst>
                        <MuiThemeProvider theme={muiTheme}>
                            <CssBaseline />
                            <GlobalStyle />

                            {children}
                        </MuiThemeProvider>
                    </StyledEngineProvider>
                </ScThemeProvider>
            )}
        </>
    );
};
