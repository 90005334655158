import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { TableAddButton } from 'common/components/Table';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useAuthorTableData } from 'user/hooks/useAuthorTableData';
import { IUserSelectorInput } from 'graphql/types';
import { IContentQueryVariables } from 'common/types';

import { AuthorSelectDrawer } from './AuthorSelectDrawer';
import { AddedAuthorsTable } from './AddedAuthorsTable';

interface IProps {
    contentQueryVariables?: IContentQueryVariables;
    selectorQueryVariables?: IUserSelectorInput;
    isEditable?: boolean;
    loading?: boolean;
    onAddAuthors(selectedAuthors: string[]): void;
    onRemoveAuthors(selectedAuthors: string[]): void;
}

export const AuthorSelector = ({
    contentQueryVariables,
    selectorQueryVariables,
    isEditable = true,
    loading: initialLoading,
    onAddAuthors,
    onRemoveAuthors,
}: IProps) => {
    const [translate] = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const deleteAuthorsRef = useRef<string[] | undefined>();

    const {
        authors,
        loading: loadingAuthorData,
        tableDataProps,
    } = useAuthorTableData(contentQueryVariables);

    const handleDrawerOpen = (open: boolean) => {
        setDrawerOpen(open);
    };

    const resetSettings = () => {
        const { searchProps, selectionProps, paginationProps } = tableDataProps;
        const { handleSearch, handleFilterSelect } = searchProps;
        const { selected, onSelectAll } = selectionProps;
        const { setPage } = paginationProps || {};

        handleSearch('');
        handleFilterSelect([]);
        setPage?.(1);

        // Deselect all when there are selected items
        if (selected?.length) onSelectAll();
    };

    const handleAddAuthors = async (selected: string[]) => {
        await onAddAuthors(selected);

        setDrawerOpen(false);
        resetSettings();
    };

    const handleRemoveAuthors = async () => {
        if (!deleteAuthorsRef.current) return;

        await onRemoveAuthors(deleteAuthorsRef.current);

        deleteAuthorsRef.current = undefined;

        setShowDeleteDialog(false);

        resetSettings();
    };

    const handleRemoveAuthorsDialog = async (selected: string[]) => {
        deleteAuthorsRef.current = selected;

        setShowDeleteDialog(true);
    };

    const loading = initialLoading || loadingAuthorData;

    return (
        <Box>
            <Box alignItems="left" display="flex" flexDirection="column">
                {isEditable && (
                    <TableAddButton onClick={() => handleDrawerOpen(true)}>
                        <Typography fontWeight={700}>
                            {translate('authorSelector.addNewAuthorsTitle')}
                        </Typography>
                    </TableAddButton>
                )}

                <Box sx={{ mt: isEditable ? 4 : 2 }}>
                    <AddedAuthorsTable
                        addedAuthors={authors}
                        isEditable={isEditable}
                        loading={loading}
                        tableDataProps={tableDataProps}
                        onRemoveAuthors={
                            isEditable ? handleRemoveAuthorsDialog : undefined
                        }
                    />
                </Box>
            </Box>

            <AuthorSelectDrawer
                loading={loading}
                open={drawerOpen}
                selectorQueryVariables={selectorQueryVariables}
                title={translate('authorSelector.addNewAuthorsTitle')}
                onAddAuthors={handleAddAuthors}
                onClose={() => handleDrawerOpen(false)}
            />

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={initialLoading}
                open={showDeleteDialog}
                title={translate('authorSelector.deleteDialog.title')}
                onCancel={() => {
                    setShowDeleteDialog(false);

                    deleteAuthorsRef.current = undefined;
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleRemoveAuthors}
            >
                {translate('authorSelector.deleteDialog.text')}
            </ConfirmDialog>
        </Box>
    );
};
