import {
    DragDropContext as RBDDragDropContext,
    DragDropContextProps,
} from '@hello-pangea/dnd';

interface IProps extends Omit<DragDropContextProps, 'children'> {
    dragDropDisabled?: boolean;
    children: React.ReactNode;
}

export const DragDropContext = ({
    dragDropDisabled,
    children,
    ...other
}: IProps) => {
    if (dragDropDisabled) return <>{children}</>;

    return <RBDDragDropContext {...other}>{children}</RBDDragDropContext>;
};
