import { useState } from 'react';
import { MediaEmbedElementProps } from '@udecode/slate-plugins';
import { useReadOnly, useFocused, useSelected } from 'slate-react';
import { Box } from '@mui/material';

import { Embed } from 'common/components/Embed';
import { Selectable } from 'common/components/Selectable';
import { List } from 'common/components/List';
import { ListItem } from 'common/components/ListItem';

export const EditorEmbed = ({
    element,
    attributes,
    children,
    htmlAttributes,
    className,
}: MediaEmbedElementProps) => {
    const { url } = element;

    const [embedError, setEmbedError] = useState(false);

    const readOnly = useReadOnly();
    const focused = useFocused();
    const selected = useSelected();

    let embed = (
        <Embed
            src={url}
            onError={() => setEmbedError(true)}
            {...htmlAttributes}
        />
    );

    if (embedError) {
        embed = (
            <List noBorder color="body">
                <ListItem>{embed}</ListItem>
            </List>
        );
    }

    return (
        <div className={className} {...attributes} data-slate-url={url}>
            <Box contentEditable={false} mb={2}>
                {readOnly ? (
                    embed
                ) : (
                    <Selectable blockClick selected={focused && selected}>
                        {embed}
                    </Selectable>
                )}
            </Box>
            {children}
        </div>
    );
};
