import { Box, Chip, Stack, TableRow } from '@mui/material';
import { useApolloClient } from '@apollo/client';

import { ITrainingFragment, ITrainingUserEdgeFragment } from 'graphql/types';
import { LinearProgress } from 'common/components/LinearProgress';
import { TableCell } from 'common/components/Table';
import { UserAvatar } from 'user/components/UserAvatar';
import { Checkbox } from 'common/components/Checkbox';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IUserFormValues } from 'user/components/UserUpdateDrawer';
import { TGroupFormValues } from 'user/types';

interface IProps {
    canManageTraining?: boolean;
    disabled?: boolean;
    trainingUser: ITrainingUserEdgeFragment;
    training: ITrainingFragment;
    isSelected: boolean;
    isEditable?: boolean;
    isSelectable?: boolean;
    userIsGroupManager?: boolean;
    onSelect?: () => void;
}

export const TrainingParticipantTableRow = ({
    canManageTraining,
    disabled,
    trainingUser,
    training,
    isEditable = true,
    isSelectable = true,
    isSelected,
    userIsGroupManager,
    onSelect,
}: IProps) => {
    const { openGlobalDrawer, closeGlobalDrawer } = useGlobalDrawer();
    const client = useApolloClient();

    const {
        portfolioItemId,
        node: participant,
        score,
        scoreThreshold,
        progress,
    } = trainingUser;

    const { id: trainingId } = training;

    const isGroupManagerOfParticipant =
        userIsGroupManager && participant.currentUserIsGroupManager;
    const canManageParticipant =
        canManageTraining || isGroupManagerOfParticipant;

    const onGroupUpdate = () => {
        // Remove the participants field
        client.cache.evict({
            id: `Training:${trainingId}`,
            fieldName: 'participants',
        });

        client.cache.gc();

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserProfile,
        });

        closeGlobalDrawer({
            type: IGlobalDrawerType.PortfolioItem,
        });
    };

    const openParticipantDrawer = () => {
        if (!canManageParticipant) {
            openGlobalDrawer({
                type: IGlobalDrawerType.UserProfile,
                itemId: participant.id,
            });

            return;
        }

        if (!portfolioItemId) return;

        openGlobalDrawer({
            type: IGlobalDrawerType.PortfolioItem,
            itemId: portfolioItemId,
            props: {
                userProfileProps: {
                    onUpdate: (
                        initialValues: IUserFormValues,
                        newValues: IUserFormValues
                    ) => {
                        const { participantGroups: initialGroups } =
                            initialValues;
                        const { participantGroups: newGroups } = newValues;

                        let groupsChanged =
                            initialGroups?.length !== newGroups?.length;

                        if (!groupsChanged) {
                            groupsChanged = !newGroups?.every(
                                (group, index) =>
                                    group.id === initialGroups?.[index]?.id
                            );
                        }

                        if (!groupsChanged) return;

                        onGroupUpdate();
                    },
                },
                groupProps: {
                    onUpdate: (
                        initialValues: TGroupFormValues,
                        newValues: TGroupFormValues
                    ) => {
                        const { trainingIds: initialTrainings } = initialValues;
                        const { trainingIds: newTrainings } = newValues;

                        const trainingInGroupInit = initialTrainings?.some(
                            (training) => training.id === trainingId
                        );
                        const trainingInGroupNew = newTrainings?.some(
                            (training) => training.id === trainingId
                        );

                        // If current training has been added or removed in the edited group
                        if (
                            (trainingInGroupInit && !trainingInGroupNew) ||
                            (!trainingInGroupInit && trainingInGroupNew)
                        ) {
                            onGroupUpdate();
                        }
                    },
                    onUserUpdate: onGroupUpdate,
                    onDelete: onGroupUpdate,
                },
            },
        });
    };

    const isDisabled = !isEditable || disabled;

    return (
        <>
            <TableRow hover>
                {isSelectable && isEditable && (
                    <TableCell>
                        <Checkbox
                            checked={isSelected}
                            disabled={isDisabled}
                            onClick={(e) => {
                                onSelect?.();

                                e.stopPropagation();
                            }}
                        />
                    </TableCell>
                )}

                <TableCell
                    sx={{ cursor: 'pointer' }}
                    onClick={openParticipantDrawer}
                >
                    <Stack alignItems="center" direction="row" spacing={2}>
                        <UserAvatar size={45} user={participant} />
                        <Box>{participant.name}</Box>
                    </Stack>
                </TableCell>

                {(canManageTraining || userIsGroupManager) && (
                    <>
                        <TableCell onClick={openParticipantDrawer}>
                            {canManageParticipant &&
                                progress === 1 &&
                                score && (
                                    <Chip
                                        color={
                                            !scoreThreshold ||
                                            score >= scoreThreshold
                                                ? 'success'
                                                : 'error'
                                        }
                                        label={`${Math.round(score * 100)}%`}
                                    />
                                )}
                        </TableCell>
                        <TableCell onClick={openParticipantDrawer}>
                            {canManageParticipant && (
                                <LinearProgress
                                    withProgressText
                                    value={(progress || 0) * 100}
                                />
                            )}
                        </TableCell>
                    </>
                )}
            </TableRow>
        </>
    );
};
