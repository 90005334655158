import { Box, FormControlLabel, FormHelperText } from '@mui/material';
import { Field, Form, Formik, FormikConfig } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';

import {
    IRole,
    IUpdateUserInput,
    IUserProfileFragment,
    useCurrentUserQuery,
    useUserAfasQuery,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import {
    Select,
    Switch,
    TextField,
    DatePicker,
    MultiSelect,
    Autocomplete,
} from 'common/components/FormField';
import { LANGUAGES } from 'common/constants/languages';
import { NOTIFICATION_OPTIONS } from 'user/constants/notificationOptions';
import { Typography } from 'common/components/Typography';
import { TExtraUserField } from 'user/types';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { LanguageSelectField } from 'common/components/LanguageSelectField';
import { getExtraFieldsName } from 'common/utils/extraFields';
import { Checkbox } from 'common/components/Checkbox';
import { Loader } from 'common/components/Loader';
import { ContentExpander } from 'common/components/ContentExpander';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import {
    UserGroupsFormSection,
    UserRolesFormSection,
} from 'user/components/forms';

interface IProps extends FormikConfig<IUpdateUserInput> {
    canManage?: boolean;
    canUseAfasFields?: boolean;
    isCurrentUser: boolean;
    className?: string;
    disabled?: boolean;
    extraFields: TExtraUserField[];
    imageUploaded?: boolean;
    initialValues: IUpdateUserInput;
    loading?: boolean;
    user: IUserProfileFragment;
    onArchive?(): void;
}

export const UserUpdateForm = ({
    canManage,
    canUseAfasFields,
    isCurrentUser,
    className,
    disabled,
    extraFields,
    imageUploaded,
    initialValues,
    loading,
    user,
    onArchive,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const [roleRemoveDialogOpen, setRoleRemoveDialogOpen] = useState(false);
    const [trainerAuthorRoleRemoved, setTrainerAuthorRoleRemoved] =
        useState(false);

    const { data: userAfasData, loading: userAfasLoading } = useUserAfasQuery({
        variables: { id: user.id },
        skip: !canUseAfasFields,
        fetchPolicy: 'cache-and-network',
    });

    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};

    if (loading || loadingUser || userAfasLoading) {
        return <Loader />;
    }

    const { afasPersonId, afasEmployeeId, afasContactId } =
        userAfasData?.user || {};

    let afasValidation = {};

    // Require AFAS fields when they are present
    if (canUseAfasFields) {
        if (afasPersonId) {
            afasValidation = {
                afasPersonId: Yup.string().required(
                    translate('validate.required')
                ),
            };
        }

        if (afasEmployeeId) {
            afasValidation = {
                ...afasValidation,
                afasEmployeeId: Yup.string().required(
                    translate('validate.required')
                ),
            };
        }

        if (afasContactId) {
            afasValidation = {
                ...afasValidation,
                afasContactId: Yup.string().required(
                    translate('validate.required')
                ),
            };
        }
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(translate('validate.required')),
        email: Yup.string()
            .email(translate('validate.email'))
            .required(translate('validate.required')),
        language: Yup.mixed()
            .oneOf(Object.keys(LANGUAGES))
            .required(translate('validate.required')),
        notifications: Yup.mixed()
            .oneOf(Object.keys(NOTIFICATION_OPTIONS))
            .required(translate('validate.required')),
        ...afasValidation,
    });

    const extraFieldsShape = extraFields.reduce((acc, field) => {
        const required =
            (isCurrentUser && field.required) ||
            (canManage && field.requiredManager);
        const isEditable = (isCurrentUser && field.profile) || canManage;

        if (required && isEditable) {
            return {
                ...acc,
                [getExtraFieldsName(field.name)]:
                    field.__typename === 'UserExtraChoiceField'
                        ? field.multiple
                            ? Yup.array()
                                  .of(Yup.string())
                                  .min(1, translate('validate.required'))
                                  .required(translate('validate.required'))
                            : Yup.string()
                                  .min(1, translate('validate.required'))
                                  .required(translate('validate.required'))
                        : field.__typename === 'UserExtraCheckboxField'
                          ? Yup.boolean().oneOf(
                                [true],
                                translate('validate.required')
                            )
                          : Yup.string()
                                .required(translate('validate.required'))
                                .nullable(),
            };
        }

        return acc;
    }, {});

    const notificationseOptions = Object.entries(NOTIFICATION_OPTIONS).map(
        ([val, label]) => ({
            value: val,
            label: translate(label as string),
        })
    );

    const initValues = {
        ...initialValues,
        afasPersonId: afasPersonId || '',
        afasEmployeeId: afasEmployeeId || '',
        afasContactId: afasContactId || '',
    };

    const hasAfasField = !!afasPersonId || !!afasEmployeeId || !!afasContactId;

    const isManager = currentUser?.roles?.includes(IRole.Manager);
    const isOwnerOrManager =
        currentUser?.roles?.includes(IRole.Owner) || isManager;

    return (
        <>
            <Formik
                initialValues={initValues}
                validationSchema={validationSchema.shape(extraFieldsShape)}
                {...other}
            >
                {({
                    submitForm,
                    isSubmitting,
                    values,
                    dirty,
                    setFieldValue,
                    errors,
                }) => (
                    <Box p={4}>
                        <ExitPageAlert
                            alert={UnsavedChangesAlert}
                            when={(dirty || imageUploaded) && !isSubmitting}
                            onConfirm={submitForm}
                        />

                        <Form className={className}>
                            <Typography gutterBottom variant="h3">
                                {translate('personalInformation')}
                            </Typography>

                            <Field
                                component={TextField}
                                label={translate('name')}
                                name="name"
                            />

                            <Field
                                component={TextField}
                                label={translate('email')}
                                name="email"
                            />

                            {extraFields.map((field, index) => {
                                if (
                                    isCurrentUser &&
                                    !canManage &&
                                    !field.public &&
                                    !field.profile
                                ) {
                                    return null;
                                }

                                const extraFieldName = getExtraFieldsName(
                                    field.name
                                );
                                const required =
                                    (isCurrentUser && field.required) ||
                                    (canManage && field.requiredManager);
                                const isEditable =
                                    (isCurrentUser && field.profile) ||
                                    canManage;

                                switch (field.__typename) {
                                    case 'UserExtraDateField':
                                        return (
                                            <Field
                                                component={DatePicker}
                                                disabled={!isEditable}
                                                key={field.name}
                                                label={field.name}
                                                name={extraFieldName}
                                                required={required}
                                            />
                                        );

                                    case 'UserExtraStringField':
                                        return (
                                            <Field
                                                component={TextField}
                                                disabled={!isEditable}
                                                key={field.name}
                                                label={field.name}
                                                name={extraFieldName}
                                                required={required}
                                            />
                                        );

                                    case 'UserExtraChoiceField':
                                        return (
                                            <Field
                                                component={
                                                    field.multiple
                                                        ? MultiSelect
                                                        : Autocomplete
                                                }
                                                disabled={!isEditable}
                                                InputProps={{
                                                    label: field.name,
                                                    required: required,
                                                }}
                                                key={field.name}
                                                label={field.name}
                                                name={extraFieldName}
                                                options={field.possibleValues}
                                                required={required}
                                            />
                                        );
                                    case 'UserExtraCheckboxField':
                                        return (
                                            <Box key={field.name}>
                                                <FormControlLabel
                                                    checked={
                                                        values[
                                                            extraFieldName as keyof typeof values
                                                        ] || false
                                                    }
                                                    control={
                                                        <Field
                                                            component={Checkbox}
                                                            inputProps={{
                                                                required:
                                                                    required,
                                                            }}
                                                        />
                                                    }
                                                    label={field.name}
                                                    name={extraFieldName}
                                                    onChange={(_e, checked) => {
                                                        setFieldValue(
                                                            extraFieldName,
                                                            checked
                                                        );
                                                    }}
                                                />
                                                {errors[
                                                    extraFieldName as keyof typeof errors
                                                ] && (
                                                    <FormHelperText
                                                        error
                                                        key={index}
                                                    >
                                                        {
                                                            errors[
                                                                extraFieldName as keyof typeof errors
                                                            ]
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        );

                                    default:
                                        return null;
                                }
                            })}

                            <Box mt={4}>
                                <ContentExpander
                                    expand={settingsExpanded}
                                    title={translate('moreSettings')}
                                    onClick={() =>
                                        setSettingsExpanded(!settingsExpanded)
                                    }
                                >
                                    {isOwnerOrManager && (
                                        <UserRolesFormSection
                                            roleRemoveDialogOpen={
                                                roleRemoveDialogOpen
                                            }
                                            setRoleRemoveDialogOpen={
                                                setRoleRemoveDialogOpen
                                            }
                                            userId={user.id}
                                            onConfirm={submitForm}
                                            onRoleRemoved={
                                                setTrainerAuthorRoleRemoved
                                            }
                                        />
                                    )}

                                    {(!isCurrentUser ||
                                        (currentUser && isOwnerOrManager)) && (
                                        <UserGroupsFormSection
                                            values={values}
                                        />
                                    )}

                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="userForm.languagesDescription"
                                            />
                                        }
                                        title={translate(
                                            'userForm.languagesTitle'
                                        )}
                                    >
                                        <LanguageSelectField name="language" />
                                    </FormSection>

                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="userForm.notificationsDescription"
                                            />
                                        }
                                        title={translate(
                                            'userForm.notificationsTitle'
                                        )}
                                    >
                                        <Field
                                            component={Select}
                                            label={translate('notifications')}
                                            name="notifications"
                                            options={notificationseOptions}
                                        />

                                        <Field
                                            checked={values.automatedMessages}
                                            component={Switch}
                                            description={translate(
                                                'userNotifications.automatedMessagesDescription'
                                            )}
                                            label={translate(
                                                'userNotifications.automatedMessages'
                                            )}
                                            name="automatedMessages"
                                        />
                                    </FormSection>

                                    {isManager && (
                                        <FormSection
                                            description={
                                                <Trans
                                                    components={{
                                                        a: (
                                                            <Link
                                                                rel="noreferrer"
                                                                underline="always"
                                                            />
                                                        ),
                                                    }}
                                                    i18nKey="userForm.connectionDescription"
                                                />
                                            }
                                            title={translate(
                                                'userForm.connectionTitle'
                                            )}
                                        >
                                            <Field
                                                component={TextField}
                                                label={translate(
                                                    'userForm.refFieldLabel'
                                                )}
                                                name="ref"
                                            />
                                        </FormSection>
                                    )}

                                    {canUseAfasFields && hasAfasField && (
                                        <FormSection
                                            description={
                                                <Trans
                                                    components={{
                                                        a: (
                                                            <Link
                                                                rel="noreferrer"
                                                                underline="always"
                                                            />
                                                        ),
                                                    }}
                                                    i18nKey="userForm.afasDescription"
                                                />
                                            }
                                            title={translate(
                                                'userForm.afasTitle'
                                            )}
                                        >
                                            {!!afasPersonId && (
                                                <Field
                                                    component={TextField}
                                                    label={translate(
                                                        'userForm.afasPersonIdFieldLabel'
                                                    )}
                                                    name="afasPersonId"
                                                />
                                            )}

                                            {!!afasEmployeeId && (
                                                <Field
                                                    component={TextField}
                                                    label={translate(
                                                        'userForm.afasEmployeeIdFieldLabel'
                                                    )}
                                                    name="afasEmployeeId"
                                                />
                                            )}

                                            {!!afasContactId && (
                                                <Field
                                                    component={TextField}
                                                    label={translate(
                                                        'userForm.afasContactIdFieldLabel'
                                                    )}
                                                    name="afasContactId"
                                                />
                                            )}
                                        </FormSection>
                                    )}
                                </ContentExpander>
                            </Box>

                            <Box sx={{ mt: 4, display: 'flex' }}>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting &&
                                            !disabled &&
                                            !dirty &&
                                            !imageUploaded)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        if (trainerAuthorRoleRemoved) {
                                            setRoleRemoveDialogOpen(true);

                                            return;
                                        }

                                        submitForm();
                                    }}
                                >
                                    {translate('save')}
                                </Button>

                                {canManage && !isCurrentUser && (
                                    <Box ml="auto">
                                        <FormDeleteButton
                                            disabled={isSubmitting || disabled}
                                            onClick={onArchive}
                                        >
                                            {translate('archiveItem')}
                                        </FormDeleteButton>
                                    </Box>
                                )}
                            </Box>
                        </Form>
                    </Box>
                )}
            </Formik>
        </>
    );
};
