import { motion, MotionProps, Variants } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Skeleton, SxProps } from '@mui/material';
import { forwardRef } from 'react';

import { TColorKeys } from 'theme/palette';
import { ItemMediaIcon } from 'common/components/ItemMedia/ItemMediaIcon';

const itemMediaStyle: SxProps = {
    marginRight: 2,
    borderRadius: '4px',
    color: 'common.white',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 300ms',
};

interface IProps extends BoxProps {
    isText?: boolean;
    image?: string;
    color?: TColorKeys;
    children?: React.ReactNode;
    className?: string;
    contentEditable?: boolean;
    skeleton?: boolean;
    size?: 'small';
}

const Media = forwardRef((props, ref) => <Box ref={ref} {...props} />);

const MotionMedia = motion.create(Media);

export const ItemMedia = ({
    isText,
    image,
    color,
    children,
    skeleton,
    className,
    size,
    ...other
}: IProps & MotionProps) => {
    const theme = useTheme();
    const themeColor = color ? theme.palette[color] : null;

    if (skeleton) {
        return <Skeleton className={className} variant="rectangular" />;
    }

    const motionVariants: Variants =
        themeColor && !image
            ? {
                  initItemButton: {
                      backgroundColor: themeColor.main,
                  },
                  hoverItemButton: {
                      backgroundColor: themeColor.accent,
                      transition: { duration: 0.2 },
                  },
              }
            : {};

    return (
        <MotionMedia
            sx={[
                itemMediaStyle,
                {
                    minWidth: { xs: 70, sm: size === 'small' ? 130 : 150 },
                    minHeight: { xs: 50, sm: size === 'small' ? 79 : 106 },
                    backgroundImage: image ? `url('${image}')` : undefined,
                    backgroundColor: color
                        ? theme.palette[color].main
                        : undefined,
                },
            ]}
            transition={{ duration: 0.2 }}
            variants={motionVariants}
            {...other}
        >
            {children && !isText && (
                <ItemMediaIcon show={!image} size={size}>
                    {children}
                </ItemMediaIcon>
            )}

            {isText && children}
        </MotionMedia>
    );
};
