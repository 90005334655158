import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Loader } from 'common/components/Loader';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';
import { Button } from 'common/components/Button';
import { ITrainingListItemFragment } from 'graphql/types';
import { useTrainingList } from 'training/hooks/useTrainingList';
import { ContentContainer } from 'common/components/ContentContainer';
import { TrainingAddList } from 'training/components/TrainingAddList';
import { PageTitle } from 'common/components/PageTitle';

interface IProps {
    loadingDeeplink?: boolean;
    onSelectTrainings(selectedTrainings: ITrainingListItemFragment[]): void;
}

export const LtiDeepLinkTrainings = ({
    loadingDeeplink,
    onSelectTrainings,
}: IProps) => {
    const [translate] = useTranslation();
    const [selectedTrainings, setSelectedTrainings] = useState<
        ITrainingListItemFragment[]
    >([]);

    const {
        trainings,
        loading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useTrainingList(undefined, false);

    const handleSelectTraining = (training: ITrainingListItemFragment) => {
        const newTrainings = [...selectedTrainings, training];

        setSelectedTrainings(newTrainings);
    };

    const handleRemoveTraining = (training: ITrainingListItemFragment) => {
        const newSelectedTrainings = selectedTrainings.filter(
            (selectedTraining) => selectedTraining.id !== training.id
        );

        setSelectedTrainings(newSelectedTrainings);
    };

    const resetSettings = () => {
        handleSearch('');
        setPage(1);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <>
            <PageTitle mixpanelTitle="LTI Deeplink trainings">
                {translate('lTIDeeplinkPage.trainingsPageTitle')}
            </PageTitle>

            <>
                <Box pb={22.5} pt={4}>
                    <FilterBar
                        useFilterDrawer
                        filterDrawerProps={{
                            title: translate('filterForm.trainings.title'),
                            description: translate(
                                'filterForm.trainings.description'
                            ),
                        }}
                        formFilters={filters}
                        formFilterValues={filterValues}
                        initialSearchValue={filterValues.q}
                        placeholder={translate('filterBarPlaceholder')}
                        onFiltersChange={(values) => {
                            setPage(1);

                            onFilterChange?.(values);
                        }}
                        onSearch={handleSearch}
                        onSearchClear={() => handleSearch('')}
                    />

                    {loading && <Loader />}

                    {!loading && (
                        <TrainingAddList
                            addedTrainings={selectedTrainings}
                            selectedTrainings={selectedTrainings}
                            trainings={trainings}
                            onAddTraining={handleSelectTraining}
                            onRemoveTraining={(
                                selectedTraining: ITrainingListItemFragment
                            ) => handleRemoveTraining(selectedTraining)}
                        />
                    )}
                </Box>

                <Box
                    sx={{
                        position: 'fixed',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        backgroundColor: 'grey.100',
                        p: 2,
                    }}
                >
                    <ContentContainer maxWidth="md" sx={{ pb: 0 }}>
                        {paginationSettings.count > -1 && (
                            <Box mb={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={
                                        paginationSettings.count === 1
                                            ? translate('training')
                                            : translate('trainings')
                                    }
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        )}

                        <Button
                            color="primary"
                            disabled={!selectedTrainings.length}
                            loading={loadingDeeplink}
                            sx={{ width: '100%' }}
                            variant="contained"
                            onClick={() => {
                                onSelectTrainings(selectedTrainings);
                                resetSettings();
                            }}
                        >
                            {!selectedTrainings.length
                                ? translate('addTrainingsTitle')
                                : translate('addTrainings', {
                                      count: selectedTrainings.length,
                                  })}
                        </Button>
                    </ContentContainer>
                </Box>
            </>
        </>
    );
};
