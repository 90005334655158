import { Box } from '@mui/material';

import { IExtendedCertificateFragment } from 'graphql/types';
import { CertificateListItem } from 'common/components/CertificateListItem';

import { PortfolioItemContentBase } from './PortfolioItemContentBase';

export const CertificatePortfolioItemContent = (
    props: React.ComponentProps<typeof PortfolioItemContentBase>
) => {
    const { portfolioItem } = props;

    const certificate =
        portfolioItem?.relatedObject as IExtendedCertificateFragment;

    return (
        <PortfolioItemContentBase {...props}>
            <Box px={4} py={2}>
                <CertificateListItem certificate={certificate} />
            </Box>
        </PortfolioItemContentBase>
    );
};
