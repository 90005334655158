import { mdiFile } from '@mdi/js';

import { IPortfolioItemListItemOldFragment } from 'graphql/types';
import { IUserPortfolioItemProps } from 'user/types';
import { PortfolioListItem } from 'user/components/PortfolioListItem';

export const CustomPortfolioItem = ({
    className,
    withEditActions,
    onClick,
    ...item
}: IUserPortfolioItemProps<IPortfolioItemListItemOldFragment>) => (
    <PortfolioListItem
        className={className}
        icon={mdiFile}
        item={item}
        onClick={onClick}
    />
);
