import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoaderOverlay } from 'common/components/Loader';
import { PORT_REGEX } from 'common/constants/regex';
import { MaintenancePage } from 'hydra/pages/MaintenancePage';

interface IProps {
    children: React.ReactNode;
}

interface IConnectionCheckResponse {
    redirectTo?: string;
    redirectToFull?: string;
}

export const ConnectionCheck = ({ children }: IProps) => {
    const { t: translate } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [maintenanceMode, setMaintenanceMode] = useState(false);

    const isMockDevelopment =
        import.meta.env.MODE === 'development' && import.meta.env.VITE_MOCK;

    useEffect(() => {
        if (isMockDevelopment) return;

        const authCheckUrl = `${import.meta.env.VITE_GRAPHQL_URL}check/`;

        fetch(authCheckUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                // Check if response is ok
                if (response.ok) {
                    const contentType = response.headers.get('content-type');

                    if (
                        contentType &&
                        contentType.includes('application/json')
                    ) {
                        return response.json();
                    }
                } else if ([403, 503].includes(response.status)) {
                    // When error code is 403 or 503 we mark this as "Maintenance Mode"
                    setMaintenanceMode(true);
                    setIsChecked(true);
                }

                throw Error(response.statusText);
            })
            .then(
                ({ redirectTo, redirectToFull }: IConnectionCheckResponse) => {
                    if (redirectTo) {
                        const { origin, pathname, search } = window.location;

                        // Make sure the urls don't contain port numbers for the checks
                        const redirectUrl = redirectTo.replace(PORT_REGEX, '');
                        const originUrl = origin.replace(PORT_REGEX, '');

                        // We only need to redirect when the redirectUrl is not the same as the currentUrl.
                        if (redirectUrl !== originUrl) {
                            // If redirectTo is given we want to redirect the user to the correct url and append the
                            // current path and search. This mostly happens when the user uses the wrong domain.
                            window.location.href = `${redirectTo}${pathname}${search}`;

                            return;
                        }
                    } else if (redirectToFull) {
                        // If redirectToFull is given we just want to redirect to the given path. This mostly happens
                        // when no organisation was found for the current domain.
                        window.location.href = redirectToFull;

                        return;
                    }

                    setIsChecked(true);
                }
            )
            .catch(() => {
                // When we get an error we mark the connection as checked.
                // Unauthorized errors are catched by the AuthenticatedRoute component
                setIsChecked(true);

                return;
            });
    }, [setIsChecked, isMockDevelopment]);

    return (
        <>
            <LoaderOverlay visible={!isMockDevelopment && !isChecked}>
                {translate('loadingTexts.connecting')}
            </LoaderOverlay>
            {maintenanceMode ? (
                <MaintenancePage />
            ) : (
                isMockDevelopment || (isChecked && children)
            )}
        </>
    );
};
