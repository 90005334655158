import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { Img } from 'common/components/Img';
import { BasePage } from 'hydra/pages/BasePage';
import STATUS_ERROR_IMAGE from 'assets/images/plan-status-error.webp';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';
import {
    IRole,
    IStripeSubscriptionStatus,
    useCurrentUserQuery,
    useOrganisationPlanSubscriptionLazyQuery,
} from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';

export const PlanStatusErrorPage = () => {
    const [translate] = useTranslation();
    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const [
        getOrganisationPlanSubscription,
        {
            data: organisationPlanSubscriptionData,
            loading: organisationPlanSubscriptionLoading,
        },
    ] = useOrganisationPlanSubscriptionLazyQuery();

    const { currentUser } = currentUserData || {};
    const { roles } = currentUser || {};
    const canSeePlanErrorPage =
        roles?.includes(IRole.Owner) || roles?.includes(IRole.Manager);
    const { organisation } = organisationPlanSubscriptionData || {};
    const { subscriptionStatus } = organisation || {};

    let statusTranslationKey = 'plans.planStatusError';

    switch (subscriptionStatus) {
        case IStripeSubscriptionStatus.IncompleteExpired:
            statusTranslationKey += '.incompleteExpired';
            break;
        case IStripeSubscriptionStatus.Incomplete:
            statusTranslationKey += '.incomplete';
            break;
        case IStripeSubscriptionStatus.Canceled:
            statusTranslationKey += '.canceled';
            break;
        case IStripeSubscriptionStatus.Unpaid:
            statusTranslationKey += '.unpaid';
            break;
        case IStripeSubscriptionStatus.PastDue:
            statusTranslationKey += '.pastDue';
    }

    useEffect(() => {
        if (!canSeePlanErrorPage) return;

        getOrganisationPlanSubscription();
    }, []);

    if (organisationPlanSubscriptionLoading || loadingUser) return <Loader />;

    if (!canSeePlanErrorPage) return <FourOFourPage />;

    return (
        <BasePage contentContainerMaxWidth="lg" showNavigation={false}>
            <PageTitle>
                {translate('plans.planStatusError.pageTitle')}
            </PageTitle>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ maxWidth: 600 }}>
                    <Img src={STATUS_ERROR_IMAGE} />
                </Box>

                <Box sx={{ mt: 0.5 }}>
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            fontSize: 14,
                            textAlign: 'center',
                        }}
                    >
                        {translate(statusTranslationKey)}
                    </Typography>

                    <Typography sx={{ mb: 1 }} variant="h1">
                        {translate('plans.planStatusError.title')}
                    </Typography>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                    <Typography lineHeight="1.4" sx={{ maxWidth: 600 }}>
                        {translate('plans.planStatusError.text')}
                    </Typography>

                    <Box sx={{ mt: 3 }}>
                        <Button
                            color="primary"
                            component={Link}
                            href={translate('plans.planStatusError.buttonLink')}
                            sx={{ px: 4 }}
                            target="_blank"
                            variant="contained"
                        >
                            <Typography fontSize="1.6rem" fontWeight={700}>
                                {translate('plans.planStatusError.buttonText')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </BasePage>
    );
};
