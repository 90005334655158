import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
    IRole,
    ITrainingListItem,
    ITrainingRole,
    useCurrentUserQuery,
} from 'graphql/types';
import { TrainingParticipantReportTable } from 'training/components/TrainingParticipantReportTable';
import { TrainingListItem } from 'training/components/TrainingListItem';
import { Link } from 'common/components/Link';
import { useFrontendPermissions } from 'user/hooks';
import { Typography } from 'common/components/Typography';
import { TrainingParticipantsCertificateTable } from 'training/components/TrainingParticipantsCertificateTable';

import { PortfolioItemContentBase } from './PortfolioItemContentBase';

export const TrainingPortfolioItemContent = (
    props: React.ComponentProps<typeof PortfolioItemContentBase>
) => {
    const client = useApolloClient();
    const [translate] = useTranslation();
    const {
        portfolioItem: { id: portfolioItemId, relatedObject, user },
    } = props;
    const { canUpdate: canUpdateTraining } = useFrontendPermissions('training');
    const { data: currentUserData } = useCurrentUserQuery();

    const { currentUser } = currentUserData || {};
    const [hasCertificates, setHasCertificates] = useState<boolean | undefined>(
        undefined
    );

    const training = relatedObject as ITrainingListItem;

    const { rolesForCurrentUser } = training;

    const clearCacheForReports = () => {
        client.cache.evict({
            id: `PortfolioItem:${portfolioItemId}`,
        });
    };

    const isCurrentUser = user.id === currentUser?.id;
    const hasReportViewRoles =
        canUpdateTraining ||
        isCurrentUser ||
        rolesForCurrentUser?.includes(ITrainingRole.Trainer) ||
        rolesForCurrentUser?.includes(ITrainingRole.Mentor) ||
        rolesForCurrentUser?.includes(ITrainingRole.Groupmanager);

    // A user can only see the report of another user with the correct roles
    const canSeeReport = !isCurrentUser && hasReportViewRoles;

    const { id: trainingId, isVisibleForUsers } = training;

    const currentUserIsManager = currentUser?.roles?.includes(IRole.Manager);
    const listItemDisabled =
        !isVisibleForUsers && !currentUserIsManager && !canSeeReport;

    const certificateTable = (
        <TrainingParticipantsCertificateTable
            trainingId={training.id}
            userId={user.id}
            onCertificatesLoad={setHasCertificates}
        />
    );

    return (
        <PortfolioItemContentBase {...props}>
            {hasReportViewRoles && (
                <Box sx={{ py: 2, px: 4 }}>
                    <TrainingListItem
                        closed={!isVisibleForUsers}
                        component={!listItemDisabled ? Link : undefined}
                        disabled={listItemDisabled}
                        params={{
                            trainingId,
                        }}
                        showNotVisibleLabel={false}
                        to="TRAINING"
                        training={training}
                    />
                </Box>
            )}

            {canSeeReport && (
                <Box sx={{ pb: 6, pt: 2, px: 4 }}>
                    <TrainingParticipantReportTable
                        trainingId={training.id}
                        userId={user.id}
                        onClearCache={clearCacheForReports}
                    />

                    {certificateTable}
                </Box>
            )}

            {!canSeeReport && (
                <>
                    {!isCurrentUser && (
                        <Box
                            sx={{
                                py: 6,
                                px: 4,
                            }}
                        >
                            <Typography>
                                {translate('noAccessPortfolioTrainingReport')}
                            </Typography>
                        </Box>
                    )}

                    {(hasCertificates === undefined || hasCertificates) && (
                        <Box
                            sx={{
                                pb: 6,
                                pt: 2,
                                px: 4,
                                borderTop: !isCurrentUser ? 1 : 0,
                                borderColor: 'divider',
                                display: !hasCertificates ? 'none' : 'block',
                            }}
                        >
                            {certificateTable}
                        </Box>
                    )}
                </>
            )}
        </PortfolioItemContentBase>
    );
};
