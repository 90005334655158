import { Box, BoxProps, Grid } from '@mui/material';
import { styled } from 'styled-components';

import DefaultCoverImage from 'assets/images/default-cover-image.webp';
import { Logo } from 'common/components/Logo';
import { useOrganisationQuery } from 'graphql/types';
import { HelmetBase } from 'common/components/HelmetBase';

interface IProps {
    children: React.ReactNode;
    footer?: React.ReactNode;
    showPluvoLogo?: boolean;
    secondaryContent?: React.ReactNode;
    bgColor?: string;
}

interface IPropsSecondaryContent {
    image?: string;
    bgColor?: string;
}

function CoverSecondaryContent<C extends React.ElementType>({
    image,
    bgColor,
    ...other
}: BoxProps<C, { component?: C } & IPropsSecondaryContent>) {
    return (
        <Box
            {...other}
            sx={[
                !!image && { backgroundImage: `url(${image})` },
                {
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: bgColor || 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',

                    '@media (max-width: 600px)': {
                        display: 'none',
                    },
                },
            ]}
        />
    );
}

const CoverContent = styled(Box)`
    ${({ theme }) => `
        display: flex;
        flex-direction: column;
        padding: ${theme.spacing(0, 3)};

        ${theme.breakpoints.up('md')} {
            padding: ${theme.spacing(0, 6)};
        }
    `}
`;

// Use fixed height for header and footer to make sure the content is correctly centered
const HEADER_FOOTER_HEIGHT = 50;

const CoverHeader = styled(Box)`
    ${({ theme }) => `
        height: ${HEADER_FOOTER_HEIGHT}px;
        margin: ${theme.spacing(3, 0)};

        ${theme.breakpoints.up('md')} {
            margin: ${theme.spacing(6, 0)};
        }

        ${Logo} {
            max-width: 100%;
            max-height: 100%;
        }
    `}
`;

const CoverFooter = styled(Box)`
    ${({ theme }) => `
        height: ${HEADER_FOOTER_HEIGHT}px;
        display: flex;
        align-items: flex-end;

        margin: ${theme.spacing(3, 0)};

        ${theme.breakpoints.up('md')} {
            margin: ${theme.spacing(6, 0)};
        }
    `}
`;

export const CoverPage = ({
    footer,
    children,
    showPluvoLogo,
    secondaryContent,
    bgColor,
}: IProps) => {
    const { data } = useOrganisationQuery();

    const organisation = data?.organisation;
    const { image } = organisation || {};
    const orgImage = image?.url || undefined;

    const coverImage = !secondaryContent ? orgImage || DefaultCoverImage : null;

    return (
        <>
            <HelmetBase />
            <Grid container component={Box} height="100vh">
                <Grid item component={CoverContent} sm={6} xs={12}>
                    <CoverHeader>
                        <Logo pluvo={showPluvoLogo} />
                    </CoverHeader>
                    <Box m="auto" maxWidth="600px" width="100%">
                        {children}
                    </Box>
                    <CoverFooter>{footer}</CoverFooter>
                </Grid>
                <Grid
                    item
                    xs
                    bgColor={bgColor}
                    component={CoverSecondaryContent}
                    image={coverImage}
                >
                    {!!secondaryContent && (
                        <Box maxHeight="1000px">{secondaryContent}</Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
