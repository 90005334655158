import { mdiSchool } from '@mdi/js';
import { useNavigate, useParams } from 'react-router-dom';

import { IUserPortfolioItemProps } from 'user/types';
import {
    goToRelatedPortfolioItemViewPage,
    goToPortfolioItemViewPage,
} from 'user/utils/goToRoutes';
import {
    IExternalContentListItemFragment,
    IPortfolioItemListItemOldFragment,
} from 'graphql/types';
import { PortfolioListItem } from 'user/components/PortfolioListItem';

export const ExternalContentPortfolioItem = ({
    className,
    withEditActions,
    ...item
}: IUserPortfolioItemProps<IPortfolioItemListItemOldFragment>) => {
    const navigate = useNavigate();
    const { id: userId } = useParams();

    const externalContent =
        item?.relatedObject as IExternalContentListItemFragment;

    const { imageUrl } = externalContent || {};

    return (
        <PortfolioListItem
            className={className}
            icon={mdiSchool}
            image={imageUrl || undefined}
            item={item}
            onClick={(id: string) => {
                if (!userId) {
                    goToRelatedPortfolioItemViewPage(navigate, id);

                    return;
                }

                goToPortfolioItemViewPage(navigate, userId, id);
            }}
        />
    );
};
