import { useTranslation } from 'react-i18next';
import {
    Box,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    Stack,
    Divider,
} from '@mui/material';
import { useEffect, useMemo } from 'react';

import { TableActionBar, TableCell, TableHead } from 'common/components/Table';
import { FilterBar } from 'common/components/FilterBar';
import { IconButton } from 'common/components/IconButton';
import { Tooltip } from 'common/components/Tooltip';
import { Loader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';
import { TTableAction } from 'common/types';
import { Pagination } from 'common/components/Pagination';
import { TrainingTrainerTableRow } from 'training/components/TrainingTrainerTable/TrainingTrainerTableRow';
import { ITrainingTrainerListItem } from 'training/types';
import { Checkbox } from 'common/components/Checkbox';
import { ITableDataProps } from 'training/components/Section/Trainers';
import { UserTrainingEmailDropdownMenu } from 'training/components/UserTrainingEmailDropdownMenu';
import { ITrainingFragment } from 'graphql/types';

interface IProps {
    disabled?: boolean;
    disabledTrainerIds?: string[];
    isEditable?: boolean;
    loading?: boolean;
    tableActions?: TTableAction[];
    tableDataProps: ITableDataProps;
    trainers?: ITrainingTrainerListItem[];
    training: ITrainingFragment;
    onSelectTrainers?(selectedTrainers: string[]): void;
}

export const TrainingTrainerTable = ({
    disabled,
    disabledTrainerIds,
    isEditable,
    loading,
    tableActions,
    tableDataProps,
    trainers,
    training,
    onSelectTrainers,
}: IProps) => {
    const [translate] = useTranslation();

    const { paginationProps, searchProps, selectionProps } = tableDataProps;
    const { paginationSettings, setPage } = paginationProps || {};
    const {
        filters,
        filterValues,
        searchQueryParam,
        onFilterChange,
        onSearch,
    } = searchProps;
    const { rows, isSelected, selected, selectableItems, onSelectAll } =
        selectionProps || {};

    const selectedTrainerIds = useMemo(() => selected || [], [selected]);

    useEffect(() => {
        onSelectTrainers?.(selectedTrainerIds);
    }, [selectedTrainerIds, onSelectTrainers]);

    const actions = tableActions?.map(
        ({ tooltipTitle, iconPath, onClick }, index) => (
            <Stack
                direction="row"
                divider={<Divider flexItem orientation="vertical" />}
                key={index}
                spacing={1}
                sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
            >
                <Tooltip key={index} title={tooltipTitle}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 'auto',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            disabled={!isSelected}
                            iconPath={iconPath}
                            iconSize="2.4rem"
                            onClick={() => {
                                onClick?.(selectedTrainerIds);
                            }}
                        />
                    </Box>
                </Tooltip>

                <UserTrainingEmailDropdownMenu
                    disabled={!isSelected}
                    trainingId={training.id}
                    userIds={selected}
                    onSendEmailComplete={() => {
                        if (!!selected?.length) onSelectAll?.();
                    }}
                />
            </Stack>
        )
    );

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);

        if (!!selected?.length) onSelectAll?.();
    };

    return (
        <>
            {!!searchProps && (
                <FilterBar
                    useFilterDrawer
                    disabled={disabled}
                    filterDrawerProps={{
                        title: translate('filterForm.trainingTrainers.title'),
                        description: translate(
                            'filterForm.trainingTrainers.description'
                        ),
                    }}
                    formFilters={filters}
                    formFilterValues={filterValues}
                    initialSearchValue={searchQueryParam}
                    placeholder={translate('filterBarPlaceholder')}
                    onFiltersChange={(values) => {
                        setPage?.(1);
                        if (!!selected?.length) onSelectAll?.();
                        onFilterChange?.(values);
                    }}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch?.('')}
                />
            )}

            {!loading && !!rows.length && (
                <TableContainer>
                    {isEditable && (
                        <TableActionBar
                            actions={actions}
                            selectionProps={selectionProps}
                        />
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {isEditable && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={false}
                                            disabled={
                                                isSelected ||
                                                !selectableItems.length ||
                                                loading ||
                                                disabled
                                            }
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{translate('name')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const trainer = trainers?.[index] || null;
                                const isDisabled = disabledTrainerIds?.some(
                                    (disabledTrainerId) =>
                                        disabledTrainerId === trainer?.id
                                );

                                if (!trainer) return null;

                                return (
                                    <TrainingTrainerTableRow
                                        disabled={isDisabled || disabled}
                                        isEditable={isEditable && !isDisabled}
                                        isSelectable={isEditable}
                                        isSelected={isSelected}
                                        key={trainer.id}
                                        trainer={trainer}
                                        onSelect={onSelect}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '100px', mt: 4 }}>
                    <Loader />
                </Box>
            )}

            {!loading && !trainers?.length && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Typography>
                        {translate('noOptionsText.trainersMentors')}
                    </Typography>
                </Box>
            )}

            {!loading && !!paginationSettings && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate(
                            'trainingTrainers.trainerMentor',
                            {
                                count: paginationSettings.count,
                            }
                        )}
                        onChange={(page: number) => {
                            setPage?.(page);
                        }}
                    />
                </Box>
            )}
        </>
    );
};
