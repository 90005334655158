import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateWebhookModuleMutation } from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { createModuleInCache } from 'training/utils/module';
import {
    IWebhookModuleFormProps,
    WebhookModuleForm,
} from 'training/components/forms/WebhookModuleForm';

export const WebhookModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { showApolloError } = useApolloError();

    const [createModule] = useCreateWebhookModuleMutation();

    const handleSubmit: IWebhookModuleFormProps['onSubmit'] = async (
        values
    ) => {
        // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
        // in the training which will make a new moduleGroup
        const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

        try {
            await createModule({
                variables: { module: values, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createWebhookModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.webhook.create'), {
            variant: 'success',
        });

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    return (
        <>
            <PageTitle>{translate('newWebhookModule')}</PageTitle>

            <WebhookModuleForm
                moduleGroupId={moduleGroupId}
                onSubmit={handleSubmit}
            />
        </>
    );
};
