import { useTranslation } from 'react-i18next';

import { IOfferEventDetailFragment } from 'graphql/types';
import { SubscriptionTable } from 'offer/tables/SubscriptionsTable';
import { useOfferEventEnrollmentList } from 'offer/hooks/useOfferEventEnrollmentList';
import { Typography } from 'common/components/Typography';

interface IProps {
    offerEvent: IOfferEventDetailFragment;
}

export const OfferEventEnrollmentList = ({ offerEvent }: IProps) => {
    const [translate] = useTranslation();

    const {
        enrollments,
        loading,
        handleSearch,
        handleFilterSelect,
        searchQueryParam,
        selectionProps,
        filters,
        filtersLoading,
        selectedFilters,
        paginationSettings,
        setPage,
    } = useOfferEventEnrollmentList(offerEvent.id);

    return (
        <>
            <Typography fontWeight={700}>
                {translate('subscriptions')}
            </Typography>
            <SubscriptionTable
                loading={loading}
                offerEvent={offerEvent}
                paginationProps={{ paginationSettings, setPage }}
                searchProps={{
                    handleSearch: handleSearch,
                    handleFilterSelect: handleFilterSelect,
                    searchQueryParam,
                    filters,
                    filtersLoading,
                    selectedFilters,
                }}
                selectionProps={selectionProps}
                subscriptions={enrollments}
            />
        </>
    );
};
