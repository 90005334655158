import { NavigateFunction } from 'react-router-dom';

import { getUrl } from 'route/utils/getUrl';

export function goToOfferDetail(navigate: NavigateFunction, offerId: string) {
    const url = getUrl('OFFER_DETAIL', {
        id: offerId,
    });

    navigate(url);
}

export function goToOfferEditContent(
    navigate: NavigateFunction,
    offerId: string
) {
    const url = getUrl('OFFER_EDIT_CONTENT', {
        id: offerId,
    });

    navigate(url);
}
