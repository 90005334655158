import { SxProps } from '@mui/material';

import { BackgroundImg, IBackgroundImgProps } from 'common/components/Img';
import BannerBgBlue from 'assets/images/banner-bg-blue.webp';
import { withSxProp } from 'common/utils/props';

const bannerStyle: SxProps = {
    backgroundPosition: 'center bottom',
    backgroundColor: 'primary.main',
    color: 'common.white',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '150px',
    borderRadius: 1,

    '> *': {
        flex: '0 0 50%',
    },
};

export const Banner = ({ sx, ...other }: Partial<IBackgroundImgProps>) => (
    <BackgroundImg
        src={BannerBgBlue}
        sx={[bannerStyle, ...withSxProp(sx)]}
        {...other}
    />
);
