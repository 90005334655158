import { mdiAccountRemove } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { IUserListItemFragment } from 'graphql/types';
import { UserTable } from 'user/components/UserTable';
import { IUserTableData } from 'user/hooks/useUserTableData';

interface IProps {
    addedUsers: IUserListItemFragment[];
    isEditable?: boolean;
    loading?: boolean;
    noResultsLabel?: string;
    paginationTranslationKey?: string;
    tableDataProps?: IUserTableData['tableDataProps'];
    onRemoveUsers?(selectedUsers: string[]): void;
}

export const AddedUsersTable = ({
    addedUsers,
    isEditable,
    loading,
    noResultsLabel,
    paginationTranslationKey,
    tableDataProps,
    onRemoveUsers,
}: IProps) => {
    const [translate] = useTranslation();

    const { paginationProps, searchProps, selectionProps } =
        tableDataProps || {};

    const tableActions = isEditable
        ? [
              {
                  tooltipTitle: translate('groupForm.deleteFromGroup'),
                  iconPath: mdiAccountRemove,
                  onClick: (selectedItems: string[]) => {
                      onRemoveUsers?.(selectedItems);
                  },
              },
          ]
        : [];

    return (
        <UserTable
            inDrawer
            isEditable={isEditable}
            loading={loading}
            noResultsLabel={noResultsLabel}
            paginationProps={paginationProps}
            paginationTranslationKey={paginationTranslationKey}
            searchProps={searchProps}
            selectionProps={selectionProps}
            tableActions={tableActions}
            users={addedUsers}
        />
    );
};
