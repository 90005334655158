import { Box, BoxProps, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const dividerStyle: SxProps = {
    width: '100%',
    display: 'flex',

    '::before, ::after': {
        width: '100%',
        borderTop: 1,
        borderTopColor: 'divider',
        content: '""',
        transform: 'translateY(50%)',
    },

    '> *': {
        zIndex: 1,
        flex: '0 0 auto',
    },
};

export const Divider = ({ sx, ...other }: BoxProps) => (
    <Box
        {...other}
        sx={[
            dividerStyle,
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
