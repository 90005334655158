import { Box, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { mdiChevronRight } from '@mdi/js';

import {
    SimpleAccordion,
    SimpleAccordionSummary,
    SimpleAccordionDetails,
} from 'common/components/SimpleAccordion';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { DescriptionBox } from '../DescriptionBox/DescriptionBox';
import { DescriptionButton } from '../DescriptionButton';

interface IProps {
    children?: React.ReactNode;
    className?: string;
    defaultExpanded?: boolean;
    description?: React.ReactNode;
    hasError?: boolean;
    renderExpanded?: boolean; // Render children when expanded
    sx?: SxProps;
    title: string;
}

export const FormSection = ({
    children,
    defaultExpanded,
    description,
    hasError,
    renderExpanded,
    sx,
    title,
}: IProps) => {
    const [expanded, setExpanded] = useState(
        defaultExpanded || hasError || false
    );
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    useEffect(() => {
        if (hasError && !expanded) setExpanded(true);
    }, [hasError, expanded]);

    const renderChildren = (renderExpanded && expanded) || !renderExpanded;

    return (
        <SimpleAccordion expanded={expanded} sx={sx}>
            <SimpleAccordionSummary
                expandIcon={<Icon path={mdiChevronRight} size="2.4rem" />}
                onClick={() => !hasError && setExpanded(!expanded)}
            >
                <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
                {!!description && (
                    <DescriptionButton
                        onClick={(event: React.MouseEvent) => {
                            setDescriptionOpen(
                                expanded ? !descriptionOpen : true
                            );
                            setExpanded(true);
                            event.stopPropagation();
                        }}
                    />
                )}
            </SimpleAccordionSummary>
            <SimpleAccordionDetails>
                <Box flex="1">
                    {!!description && (
                        <DescriptionBox
                            open={descriptionOpen}
                            onClose={() => setDescriptionOpen(false)}
                        >
                            {description}
                        </DescriptionBox>
                    )}
                    {renderChildren && children}
                </Box>
            </SimpleAccordionDetails>
        </SimpleAccordion>
    );
};
