import { Link } from 'common/components/Link';
import { IOfferEventDateFragment } from 'graphql/types';

interface IProps {
    date: IOfferEventDateFragment;
}

export const OfferEventDateLocation = ({ date }: IProps) => {
    const { location, locationString } = date;

    if (!location) return <>{locationString}</>;

    const { title, location: address } = location;

    const addressLink = address ? (
        <>
            <Link
                href={`https://www.google.com/maps?q=${encodeURIComponent(
                    address
                )}`}
                target="_blank"
            >
                {address}
            </Link>
        </>
    ) : null;

    return (
        <>
            {title}
            {title && address && ' - '}
            {addressLink}
        </>
    );
};
