import { useParams } from 'react-router-dom';

import {
    IDevelopmentItemListItemFragment,
    IDevelopmentItemGroupFragment,
} from 'graphql/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

export const useDevelopmentPlanRouting = (
    groups: IDevelopmentItemGroupFragment[],
    developmentPlanLoading: boolean
) => {
    const {
        id: userIdParam,
        itemId: itemIdParam,
        groupId: groupIdParam,
    } = useParams();

    const developmentItemCreate = !!useRouteMatch('DEVELOPMENT_ITEM_CREATE');
    const developmentItemEdit = !!useRouteMatch('DEVELOPMENT_ITEM_EDIT');
    const developmentItemViewProfile = !!useRouteMatch(
        'USER_DEVELOPMENT_ITEM_VIEW'
    );

    let selectedDevelopmentItem: IDevelopmentItemListItemFragment | undefined;

    if (
        (developmentItemEdit || developmentItemViewProfile) &&
        !developmentPlanLoading &&
        groups.length
    ) {
        groups.forEach((group) => {
            if (selectedDevelopmentItem) return;

            const itemResult = group.developmentItems.filter(
                (developmentItem) => developmentItem.id === itemIdParam
            );

            if (itemResult.length) selectedDevelopmentItem = itemResult[0];
        });
    }

    const developmentItemDrawerOpen =
        developmentItemCreate ||
        developmentItemEdit ||
        developmentItemViewProfile;

    const developmentGroupCreate = !!useRouteMatch('DEVELOPMENT_GROUP_CREATE');
    const developmentGroupEdit = !!useRouteMatch('DEVELOPMENT_GROUP_EDIT');

    let selectedGroup: IDevelopmentItemGroupFragment | undefined;

    if (developmentGroupEdit && !developmentPlanLoading && groups.length) {
        const groupResult = groups.filter((group) => group.id === groupIdParam);

        if (groupResult.length) selectedGroup = groupResult[0];
    }

    const groupDrawerOpen = developmentGroupCreate || !!selectedGroup;

    return {
        selectedDevelopmentItem,
        selectedGroup,
        developmentItemDrawerOpen,
        groupDrawerOpen,
        userIdParam,
    };
};
