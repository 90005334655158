import { useEffect, useMemo } from 'react';

import { TPaginationSettings, usePagination } from 'common/hooks/usePagination';
import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';
import { useSelection } from 'common/components/Table';
import {
    IContentQueryVariables,
    ISearchProps,
    ITableSelectionProps,
    TFilterBarItem,
} from 'common/types';

import { useUserSearch } from './useUserSearch';
import { useUserQueryVariables } from './useUserQueryVariables';
import { useAuthorList } from './useAuthorList';

export interface IUserTableData {
    loading?: boolean;
    tableDataProps: {
        paginationProps?: {
            paginationSettings: TPaginationSettings;
            setPage: (page: number) => void;
        };
        searchProps: ISearchProps;
        selectionProps: ITableSelectionProps<IUserListItemFragment>;
    };
    authors: IUserListItemFragment[];
    alreadyAddedAuthors: string[];
}

export const useAuthorTableData = (
    contentQueryVariables?: IContentQueryVariables,
    selectorQueryVariables?: IUserSelectorInput
): IUserTableData => {
    const {
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
        ...userSearchProps
    } = useUserSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { userQueryVariables } = useUserQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        authors,
        alreadyAddedAuthors,
        loading: authorsLoading,
        authorsCount,
    } = useAuthorList(
        userQueryVariables,
        contentQueryVariables,
        selectorQueryVariables
    );

    const selectFilter = (author: IUserListItemFragment) =>
        !alreadyAddedAuthors?.some(
            (addedAuthorId) => addedAuthorId === author.id
        );

    const { ...selectionProps } = useSelection(authors, selectFilter);

    useEffect(() => {
        if (authorsLoading || !initializePagination) return;

        initializePagination(authorsCount || 0);
    }, [authorsCount, authorsLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect(selected);
    };

    return {
        loading: authorsLoading,
        tableDataProps: {
            paginationProps: { paginationSettings, setPage },
            searchProps: {
                ...userSearchProps,
                handleSearch,
                handleFilterSelect,
                searchQueryParam,
                selectedFilters,
            },
            selectionProps,
        },
        authors,
        alreadyAddedAuthors,
    };
};
