import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
    useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { IBaseAlert } from 'common/components/Alerts';
import { blockGlobalDrawer } from 'common/components/GlobalDrawerProvider/GlobalDrawerList';

interface IProps {
    when?: boolean;
    alert?: React.ComponentType<React.PropsWithChildren<IBaseAlert>>;
    /** Return true in onConfirm to follow the route after */
    onConfirm?: () => Promise<boolean>;
    /** Return true in onCancel to follow the route after */
    onCancel?(): boolean;
}

const initBeforeUnLoad = (showExitAlert: boolean) => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
        if (!showExitAlert) return;

        const e = event || window.event;

        e.preventDefault();

        // Chrome requires returnValue to be set
        if (e) e.returnValue = '';
    };
};

export interface IExitPageAlertRef {
    unblock: () => void;
}

const BaseExitPageAlert = (
    { when, alert: Alert, onConfirm, onCancel }: IProps,
    ref: React.Ref<IExitPageAlertRef>
) => {
    const navigate = useNavigate();
    const [translate] = useTranslation();

    const { navigator } = useContext(NavigationContext);

    const continueFn = useRef<() => void | undefined>();
    const goFn = useRef(navigator.go);
    const pushFn = useRef(navigator.push);
    const replaceFn = useRef(navigator.replace);
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onPopState = useCallback(() => {
        if (!window.confirm(translate('changesNotSaved'))) {
            window.history.go(1);

            // Unbind self to prevent double error
            window.removeEventListener('popstate', onPopState);
        }
    }, [translate]);

    const unblock = useCallback(() => {
        blockGlobalDrawer(null);
        continueFn.current = undefined;

        // Reset navigator function to original
        navigator.go = goFn.current;
        navigator.push = pushFn.current;
        navigator.replace = replaceFn.current;

        // Unbind pop state listener
        window.removeEventListener('popstate', onPopState);
    }, [navigator, onPopState]);

    const block = useCallback(() => {
        // Patch navigator functions to show alert when called
        const { go, push, replace } = navigator;

        blockGlobalDrawer((closeGlobalDrawer) => {
            setShowAlert(true);

            continueFn.current = closeGlobalDrawer;
        });

        navigator.go = (...args: Parameters<typeof go>) => {
            setShowAlert(true);
            continueFn.current = () => go(...args);
        };

        navigator.push = (...args: Parameters<typeof push>) => {
            setShowAlert(true);
            continueFn.current = () => push(...args);
        };

        navigator.replace = (...args: Parameters<typeof replace>) => {
            setShowAlert(true);
            continueFn.current = () => replace(...args);
        };

        // Bind function to catch navigating using back button
        window.addEventListener('popstate', onPopState);
    }, [navigator, onPopState]);

    window.onload = function () {
        initBeforeUnLoad(showAlert);
    };

    useEffect(() => {
        initBeforeUnLoad(when || false);

        if (when && !continueFn.current) {
            block();
        } else if (!when) {
            unblock();
        }

        return () => {
            // When component unmounts the browser default unsaved changes alert should not be displayed
            initBeforeUnLoad(false);

            unblock();
            setShowAlert(false);
        };
    }, [navigate, block, unblock, onPopState, when]);

    useImperativeHandle(ref, () => ({
        unblock,
    }));

    const handleConfirm = useCallback(async () => {
        if (onConfirm) {
            setIsSubmitting(true);

            const canRoute = await onConfirm();

            /* Added a timeout for the closing animation of the alert
             * This prevents multiple clicks on actions as they will be disabled
             * until the alert is fully gone
             */
            setTimeout(() => setIsSubmitting(false), 225);

            if (canRoute) {
                continueFn.current?.();
                unblock();
            }
        }
    }, [unblock, onConfirm]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());

            if (canRoute) {
                continueFn.current?.();
                unblock();
            }
        } else {
            continueFn.current?.();
            unblock();
        }
        setShowAlert(false);
    }, [unblock, onCancel]);

    if (!showAlert || !Alert) return null;

    return (
        <Alert
            disableActions={isSubmitting}
            open={showAlert}
            onCancel={handleCancel}
            onClose={() => setShowAlert(false)}
            onConfirm={handleConfirm}
        />
    );
};

export const ExitPageAlert = forwardRef(BaseExitPageAlert);
