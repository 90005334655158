import { DateTime, Duration, ToHumanDurationOptions } from 'luxon';

import { ITimeDeltaFragment } from 'graphql/types';
import { i18n } from 'utils';
import { TDateRange } from 'common/types';

/**
 * Check if the given date is the same year as the current year
 */
export const dateIsCurrentYear = (dateTime: DateTime): boolean => {
    const curYear = new Date().getFullYear();

    return dateTime.year === curYear;
};

const dateIsToday = (dateTime: DateTime): boolean => {
    const today = new Date();

    return (
        dateTime.year === today.getFullYear() &&
        dateTime.month === today.getMonth() + 1 &&
        dateTime.day === today.getDate()
    );
};

const timeIsMidnight = (dateTime: DateTime) =>
    dateTime.hour === 0 && dateTime.minute === 0;

export const formatDate = (
    dateISO?: string,
    format = 'D',
    stripSameYear = false,
    stripMidnightTime = false,
    stripSameDate = false
) => {
    if (!dateISO) return;

    const dateTime = DateTime.fromISO(dateISO);

    let dateFormat = format;

    if (stripSameYear && dateIsCurrentYear(dateTime)) {
        // Remove year from format if date has current year
        dateFormat = dateFormat.replaceAll('y', '');
    }

    if (stripMidnightTime && timeIsMidnight(dateTime)) {
        // Remove hh:mm format if time is midnight
        dateFormat = dateFormat.replace('HH:mm', '');
    }

    if (stripSameDate && dateIsToday(dateTime)) {
        // Remove date parts from format if date is current date
        dateFormat = dateFormat.replaceAll('y', ''); // Strip year
        dateFormat = dateFormat.replaceAll('M', ''); // Strip month
        dateFormat = dateFormat.replaceAll('d', ''); // Strip day
    }

    // Remove double spaces
    dateFormat = dateFormat.replace(/\s\s/g, ' ');
    // Trim "-" character
    dateFormat = dateFormat.replace(/^-+|-+$/g, '');
    // Trim
    dateFormat = dateFormat.trim();

    return dateTime.toFormat(dateFormat);
};

export function getAbsoluteDate(startDateISO?: string, format?: string) {
    if (!startDateISO) return '';

    const startFormat = format || 'd MMMM yyyy';
    const startDate = formatDate(startDateISO, startFormat, true, true);

    return startDate;
}

/**
 * Returns a moment object to be further processed
 */
export function getRoundedDateTime() {
    return DateTime.now()
        .setLocale(i18n.language.toLocaleLowerCase())
        .startOf('hour');
}

/**
 * Check if start and end dates are on the same day
 */
export function isSameDay(startDateISO: string, endDateISO: string) {
    const newStartDate = DateTime.fromISO(startDateISO);
    const newEndDate = DateTime.fromISO(endDateISO);

    return newStartDate.toISODate() === newEndDate.toISODate();
}

/**
 * Check if start and end dates are in the same month
 */
export function isSameMonth(startDateISO: string, endDateISO: string) {
    const newStartDate = DateTime.fromISO(startDateISO);
    const newEndDate = DateTime.fromISO(endDateISO);

    return newStartDate.month === newEndDate.month;
}

/**
 * Convert days + hours to seconds
 *
 * @param days number = 0
 * @param hours numer = 0
 * @returns number - total of seconds
 */
export function dayHoursToSeconds(days: number = 0, hours: number = 0) {
    const duration = Duration.fromObject({ days: days, hours: hours });

    return duration.as('seconds');
}

/**
 * Convert timeDelta to Duration
 *
 * @param timeDelta ITimeDeltaFragment
 * @returns Duration
 */
export function timeDeltaToDuration(timeDelta?: ITimeDeltaFragment) {
    const seconds = timeDelta?.totalSeconds || 0;

    return Duration.fromObject({ seconds });
}

/**
 * Convert a Duration object to a human readable string,
 * It will normalize and remove all 0 values from the duration.
 *
 * @param duration Duration
 * @returns String e.g. '1 day, 5 hr, 6 min'
 */
export function durationToHuman(
    duration: Duration,
    options?: ToHumanDurationOptions
): string {
    const durationObj = duration.normalize().toObject();

    // Remove all zero values from duration object
    const normalizedObj = Object.fromEntries(
        Object.entries(durationObj).filter(([_key, value]) => {
            if (value === 0) return;

            return value;
        })
    );

    return Duration.fromObject(normalizedObj).toHuman(options);
}

/**
 * Returns a from and until date string from a given date range
 *
 * @param dateRange TDateRange
 * @returns string
 */
export const getDateRangeText = (
    dateRange?: TDateRange,
    dateRangeDefaultText?: string
) => {
    if (!dateRange) return dateRangeDefaultText || i18n.t('selectDate');

    const { startDate, endDate } = dateRange;

    const startDateISO = startDate?.toISO();
    const endDateISO = endDate?.toISO();

    const fromText = i18n.t('from');
    const untilText = i18n.t('until');

    if (startDateISO && !endDateISO)
        return `${fromText} ${formatDate(startDateISO)}`;
    if (!startDateISO && endDateISO)
        return `${untilText} ${formatDate(endDateISO)}`;

    if (startDateISO && endDateISO) {
        return `${fromText} ${formatDate(startDateISO)} ${untilText.toLowerCase()} ${formatDate(endDateISO)}`;
    }
};
