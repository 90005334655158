import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApolloClient, Reference } from '@apollo/client';

import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { IOrganisationSettingsFormProps } from 'organisation/components/forms/OrganisationSettingsForm';
import { TOrganisationSettingsFormValues } from 'organisation/components/forms/OrganisationSettingsForm/OrganisationSettingsForm';
import {
    CustomMenuItemFragmentDoc,
    IOrganisationFragment,
    OrganisationFragmentDoc,
    useUpdateOrCreateOrganisationCustomMenuItemsMutation,
    useUpdateOrganisationMutation,
    useUpdateOrganisationThemeMutation,
    useUpdateScoringSystemsMutation,
    ScoringSystemsDocument,
} from 'graphql/types';
import { formatColor } from 'common/utils/color';
import { getImageId } from 'common/utils/image';
import { i18n } from 'utils';

import { useOrganisationSettingsData } from './useOrganisationSettingsData';

export const useOrganisationSettingsForm = () => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { organisation, currentUser, initialValues, loading, errors } =
        useOrganisationSettingsData();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [updateOrganisation] = useUpdateOrganisationMutation();
    const [updateOrganisationTheme] = useUpdateOrganisationThemeMutation();
    const [updateCustomMenuItems] =
        useUpdateOrCreateOrganisationCustomMenuItemsMutation();
    const [updateScoringSystems] = useUpdateScoringSystemsMutation();
    const client = useApolloClient();

    const handleUpdateOrganisation = async (
        values: TOrganisationSettingsFormValues
    ) => {
        const {
            customFonts,
            participantIntroTitleColor,
            participantIntroImage,
            logo,
            favicon,
            image,
            typography,
            palette,
            homeHeaderImage,
            homeTitleColor,
            customMenuIcon,
            customMenuItems: newCustomMenuItems,
            scoringSystems,
            footerImage,
            footerLink,
            ...otherValues
        } = values;
        const {
            participantIntroImage: currentParticipantIntroImage,
            footerImage: currentFooterImage,
            logo: currentLogo,
            image: currentImage,
            favicon: currentFavicon,
            homeHeaderImage: currentHomeHeaderImage,
        } = organisation || {};

        return updateOrganisation({
            variables: {
                organisation: {
                    ...otherValues,
                    participantIntroTitleColor: formatColor(
                        participantIntroTitleColor || ''
                    ),
                    participantIntroImageId: getImageId(
                        currentParticipantIntroImage,
                        participantIntroImage
                    ),
                    logoId: getImageId(currentLogo, logo),
                    faviconId: getImageId(currentFavicon, favicon),
                    imageId: getImageId(currentImage, image),
                    homeHeaderImageId: getImageId(
                        currentHomeHeaderImage,
                        homeHeaderImage
                    ),
                    homeTitleColor: formatColor(homeTitleColor || ''),
                    customMenuIcon,
                    footerImageId: getImageId(currentFooterImage, footerImage),
                    footerLink: footerLink ? `https://${footerLink}` : '',
                },
            },
        });
    };

    const handleUpdateTheme = async (
        values: TOrganisationSettingsFormValues,
        newOrganisation?: IOrganisationFragment
    ) => {
        const { palette, typography } = values;

        const {
            primary: primaryColor,
            secondary: secondaryColor,
            title: titleColor,
            subtitle: subtitleColor,
            body: bodyColor,
        } = palette || {};

        return updateOrganisationTheme({
            variables: {
                theme: {
                    typography,
                    palette: {
                        primary: formatColor(primaryColor),
                        secondary: formatColor(secondaryColor),
                        title: formatColor(titleColor),
                        subtitle: formatColor(subtitleColor),
                        body: formatColor(bodyColor),
                    },
                },
            },
            update: (cache, result) => {
                if (!newOrganisation) return;

                const { theme } = result.data?.updateOrganisationTheme || {};

                cache.writeFragment({
                    id: cache.identify(newOrganisation),
                    fragment: OrganisationFragmentDoc,
                    data: {
                        ...newOrganisation,
                        theme: { ...newOrganisation.theme, ...theme },
                    },
                    fragmentName: 'Organisation',
                });
            },
        });
    };

    const handleUpdateCustomMenuItems = async (
        values: TOrganisationSettingsFormValues,
        newOrganisation?: IOrganisationFragment
    ) => {
        const { customMenuItems: newCustomMenuItems } = values;

        const orderedCustomMenuItems = newCustomMenuItems.map(
            (menuItem, index) => ({
                ...menuItem,
                url: `https://${menuItem.url}`,
                order: index,
            })
        );

        return updateCustomMenuItems({
            variables: { customMenuItems: orderedCustomMenuItems },
            update: (cache, result) => {
                if (!newOrganisation) return;

                const { customMenuItems } =
                    result.data?.updateOrCreateOrganisationCustomMenuItems ||
                    {};

                cache.modify({
                    id: cache.identify(newOrganisation),
                    fields: {
                        customMenuItems() {
                            return customMenuItems?.map((customMenuItem) =>
                                cache.writeFragment({
                                    id: cache.identify(customMenuItem),
                                    fragment: CustomMenuItemFragmentDoc,
                                    data: customMenuItem,
                                })
                            ) as Reference[];
                        },
                    },
                });

                cache.evict({ fieldName: 'menuItems' });
            },
        });
    };

    const handleUpdateScoringSystems = async (
        values: TOrganisationSettingsFormValues,
        newOrganisation?: IOrganisationFragment
    ) => {
        const { scoringSystems } = values;

        return updateScoringSystems({
            variables: { scoringSystemInputs: scoringSystems },
            update: (cache, result) => {
                if (!newOrganisation) return;

                const { scoringSystems } =
                    result.data?.updateScoringSystems || {};

                cache.updateQuery({ query: ScoringSystemsDocument }, () => ({
                    scoringSystems,
                }));

                client.refetchQueries({ include: ['PortfolioScores'] });
            },
        });
    };

    const handleSubmit: IOrganisationSettingsFormProps['onSubmit'] = async (
        values: TOrganisationSettingsFormValues
    ) => {
        const { customMenuItems: newCustomMenuItems } = values;

        try {
            const organisationResponse = await handleUpdateOrganisation(values);
            const { organisation: newOrganisation } =
                organisationResponse.data?.updateOrganisation || {};

            await handleUpdateTheme(values, newOrganisation);

            // If custom menu items changed run the mutation
            if (
                JSON.stringify(initialValues.customMenuItems) !==
                JSON.stringify(newCustomMenuItems)
            ) {
                await handleUpdateCustomMenuItems(values, newOrganisation);
            }

            await handleUpdateScoringSystems(values, newOrganisation);
        } catch (error) {
            showApolloError(error);

            return;
        }

        // Only update language directly when there is no overriding current user language set
        if (values.language && !currentUser?.language) {
            i18n.changeLanguage(values.language);
        }

        displaySnackbar(translate('organisationSettingsActionSuccess.update'), {
            variant: 'success',
        });

        // Go back to the underlying page
        navigate(location.pathname);

        return;
    };

    return {
        initialValues,
        loading,
        errors,
        handleSubmit,
    };
};
