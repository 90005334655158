import { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { motion } from 'framer-motion';
import { Skeleton } from '@mui/material';

export interface IProps {
    src: string;
    contentEditable?: boolean;
    imgProps?: React.HTMLAttributes<HTMLImageElement>;
}

const BaseImage = ({ src, imgProps, ...other }: IProps) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;

        // Did cancel will be used to "cancel" setting data when the component is destroyed
        const didCancel = false;

        const img = new Image();

        img.onload = () => {
            if (!didCancel) {
                setLoaded(true);
            }
        };

        img.src = src;
    }, [loaded, src]);

    if (!loaded) {
        return <Skeleton height={200} variant="rectangular" />;
    }

    return (
        <motion.div
            {...other}
            animate={{ opacity: 1, filter: 'blur(0)' }}
            initial={{ opacity: 0, filter: 'blur(10px)' }}
        >
            <img alt="" src={src} {...imgProps} />
        </motion.div>
    );
};

export const Img = styled(BaseImage)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;

    img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;

        &:focus {
            outline: none;
        }
    }
`;
