import { Grid } from '@mui/material';

import { ITrainingListCardFragment } from 'graphql/types';
import { TrainingCard } from 'training/components/TrainingCard';

interface IProps {
    trainings: ITrainingListCardFragment[];
}

export const TrainingManagementCardList = ({ trainings }: IProps) => (
    <Grid container spacing={3}>
        {trainings?.map((training) => (
            <Grid item key={training.id} md={4} sm={6} xs={12}>
                <TrainingCard training={training} />
            </Grid>
        ))}
    </Grid>
);
