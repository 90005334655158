import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import {
    ILtiPlatformFragment,
    useDeleteLtiPlatformMutation,
    useLtiConfigQuery,
    useLtiPlatformLazyQuery,
    useUpdateLtiPlatformMutation,
} from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { TLTIPlatformFormValues } from 'connections/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

import { LTIPlatformForm } from '../forms/LTIPlatformForm';

interface IProps extends IPageDrawerProps {
    lTIPlatformId?: string;
    onClose?(): void;
}

export const LTIPlatformUpdateDrawer = ({
    lTIPlatformId,
    onClose,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [fetchLTIPlatform, { data, loading: loadingLTIPlatform }] =
        useLtiPlatformLazyQuery();
    const { data: lTIConfigUrlsData, loading: loadingConfigUrls } =
        useLtiConfigQuery();
    const [updateLTIPlatform, { loading: loadingUpdate }] =
        useUpdateLtiPlatformMutation();
    const [deleteLTIPlatform] = useDeleteLtiPlatformMutation();

    const { ltiPlatform } = data || {};

    useEffect(() => {
        if (!lTIPlatformId) return;

        fetchLTIPlatform({ variables: { id: lTIPlatformId } });
    }, [lTIPlatformId, fetchLTIPlatform]);

    const handleSubmit = async (values: TLTIPlatformFormValues) => {
        const {
            courses,
            trainings,
            ltiAuthenticateUrl,
            ltiLaunchUrl,
            ltiJwksUrl,
            deploymentIds,
            maxUsers,
            allowAllMaterial,
            ...otherValues
        } = values;

        const newValues = {
            ...otherValues,
            courseIds: allowAllMaterial
                ? undefined
                : values.courses.map((course) => course.id),
            trainingIds: allowAllMaterial
                ? undefined
                : values.trainings.map((training) => training.id),
            // Stringify deploymentIds to match the type of the field and also remove spaces
            deploymentIds: !!deploymentIds
                ? JSON.stringify(deploymentIds.replace(/\s+/g, '').split(','))
                : '[]',
            maxUsers: +maxUsers || 0,
            allowAllMaterial,
        };

        try {
            await updateLTIPlatform({
                variables: { id: lTIPlatformId, ltiPlatform: newValues },
            });

            displaySnackbar(translate('lTIPlatformActionSuccess.update'), {
                variant: 'success',
            });

            onClose?.();
        } catch (error) {
            showApolloError(error);

            return;
        }
    };

    const handleDelete = async () => {
        if (!lTIPlatformId) return;

        try {
            await deleteLTIPlatform({
                variables: { id: lTIPlatformId },
                update: (cache) => {
                    cache.modify({
                        fields: {
                            ltiPlatforms(
                                existingPlatforms = [],
                                { readField }
                            ) {
                                return existingPlatforms.filter(
                                    (platformRef: ILtiPlatformFragment) =>
                                        lTIPlatformId !==
                                        readField('id', platformRef)
                                );
                            },
                        },
                    });
                },
            });

            displaySnackbar(translate('lTIPlatformActionSuccess.delete'), {
                variant: 'success',
            });

            onClose?.();
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const platform = lTIPlatformId && ltiPlatform ? ltiPlatform : undefined;
    const loading = loadingLTIPlatform || loadingConfigUrls;
    const lTIConfigUrls = lTIConfigUrlsData?.organisation
        ? lTIConfigUrlsData?.organisation
        : undefined;

    return (
        <PageDrawer onClose={onClose} {...other}>
            <PageTitle mixpanelTitle="Drawer LTI Platform form">
                {translate('lTIPlatformForm.pageTitle')}
            </PageTitle>

            {loading && <Loader />}

            {!loading && (
                <LTIPlatformForm
                    disabled={loadingUpdate}
                    lTIConfigUrls={lTIConfigUrls}
                    lTIPlatform={platform}
                    onDelete={handleDelete}
                    onSubmit={handleSubmit}
                />
            )}
        </PageDrawer>
    );
};
