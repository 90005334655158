import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

import {
    IModuleType,
    useDeleteModuleMutation,
    useModuleEditQuery,
    useUpdateAssignmentModuleMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleUpdateDrawerProps } from 'training/types';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { Loader } from 'common/components/Loader';
import { getModuleOffsetFormValue } from 'training/utils/moduleOffset';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { getImageId } from 'common/utils/image';
import {
    AssignmentModuleForm,
    IAssignmentModuleFormProps,
} from 'training/components/forms/AssignmentModuleForm';

export const AssignmentModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.Assignment },
        onError: showApolloError,
    });

    const [updateModule] = useUpdateAssignmentModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    const module = data?.module;

    if (loading || !module) return <Loader />;

    if (!module || module.__typename !== 'AssignmentModule') return null;

    const handleSubmit: IAssignmentModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { attachment, image, offset, ...otherValues } = values;
        const { image: currentImage } = module;

        const moduleValues = {
            ...otherValues,
            // Remove assignmentType because it's not allowed to be updated
            assignmentType: undefined,
            attachmentId: attachment?.id,
            imageId: getImageId(currentImage, image),
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        try {
            await updateModule({
                variables: { id, module: moduleValues },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.assignment.update'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.Assignment,
                },
                update: (cache) => {
                    cache.evict({ id: `AssignmentModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.assignment.delete'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const {
        title,
        description,
        assignmentType,
        scoreType,
        scoreThreshold,
        canUploadFiles,
        canDiscuss,
        attachment,
        weight,
        image,
        openOffset,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        title,
        description,
        assignmentType,
        scoreType,
        scoreThreshold,
        canUploadFiles,
        canDiscuss,
        attachment: attachment || undefined,
        weight,
        image: image || undefined,
        offset: getModuleOffsetFormValue(openOffset),
    };

    return (
        <>
            <PageTitle>{translate('updateAssignmentModule')}</PageTitle>

            <AssignmentModuleForm
                edit
                initialValues={initialValues}
                moduleGroupId={moduleGroupId}
                onDelete={() => setShowDeleteAlert(true)}
                onSubmit={handleSubmit}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteAssignmentModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteAssignmentModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
