import { i18n } from 'utils/i18n';
import { IAssignmentStatus } from 'graphql/types';
import { TFilterBarItem, IFilterFormItem } from 'common/types';
import {
    dateRangeFilter,
    archivedFilter,
    userGroupsFilter,
} from 'common/utils/drawerFilters';

export const PROGRESS_FILTERS: TFilterBarItem[] = [
    {
        name: i18n.t('finished'),
        value: 'finished',
    },
    {
        name: i18n.t('notFinished'),
        value: 'notFinished',
    },
];

export const ASSIGNMENT_FILTERS: TFilterBarItem[] = [
    {
        name: i18n.t('status'),
        value: 'status',
        children: [
            {
                name: i18n.t('open'),
                value: IAssignmentStatus.Open,
            },
            {
                name: i18n.t('closed'),
                value: IAssignmentStatus.Closed,
            },
        ],
    },
    {
        name: i18n.t('files'),
        value: 'files',
        children: [
            {
                name: i18n.t('none'),
                value: 'files_none',
            },
        ],
    },
    {
        name: i18n.t('score'),
        value: 'score',
        children: [
            {
                name: '> 5',
                value: 'score_more',
            },
            {
                name: '< 5',
                value: 'score_less',
            },
        ],
    },
];

export function getTrainingFilters(
    showArchiveFilter = false
): IFilterFormItem[] {
    return [
        dateRangeFilter(),
        userGroupsFilter(undefined, 'groupIds'),
        ...(showArchiveFilter ? [archivedFilter()] : []),
    ] as IFilterFormItem[];
}
