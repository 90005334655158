import { useTranslation } from 'react-i18next';
import { mdiChevronRight } from '@mdi/js';

import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { IBasicOfferEventListItemFragment } from 'graphql/types';

interface IProps {
    offerEvent: IBasicOfferEventListItemFragment;
    onClick?(): void;
}

export const OfferEventBasicListItem = ({ offerEvent, ...other }: IProps) => {
    const [translate] = useTranslation();

    const { offerEventTitle, image, offer } = offerEvent;

    return (
        <ListItem button component={Link} px={0} {...other}>
            <ListItemMedia
                color="primary"
                image={image?.url || ''}
                size="small"
            />

            <ListItemText primary={offerEventTitle || offer.title} />

            <ListItemActionText>{translate('open')}</ListItemActionText>

            <ListItemSecondaryAction hideXs>
                <Icon path={mdiChevronRight} size="4rem" />
            </ListItemSecondaryAction>
        </ListItem>
    );
};
