import { useState } from 'react';
import { styled } from 'styled-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ENTER_KEY_CODE = 13;

interface IProps {
    onSave: (value: string) => void;
    className?: string;
    placeholderKey: string;
    disabled?: boolean;
    children?: React.ReactNode;
}

const BaseQuickAddForm = ({
    onSave,
    className,
    placeholderKey,
    disabled,
    children,
}: IProps) => {
    const [title, setTitle] = useState('');
    const [translate] = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value);
    };

    const handleKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === ENTER_KEY_CODE && e.currentTarget.value) {
            await onSave(e.currentTarget.value);
            setTitle('');
        }
    };

    return (
        <Typography className={className}>
            {children}

            <input
                disabled={disabled}
                placeholder={translate(placeholderKey)}
                type="text"
                value={title}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
            />
        </Typography>
    );
};

export const QuickAddForm = styled(BaseQuickAddForm)`
    padding: ${({ theme }) => theme.spacing(1, 4)};
    border-top: 1px solid #e3e3e3;
    height: 60px;
    gap: ${({ theme }) => theme.spacing(1.5)};
    align-items: center;
    display: grid;

    input {
        font-size: 16px;
        line-height: 1.38;
        border: none;
        color: inherit;
        width: 100%;

        &::placeholder {
            opacity: 0.36;
            color: #202020;
        }

        &:focus {
            outline: none;
        }
    }
`;
