import { useMemo } from 'react';

import { useExtraTrainingFieldsQuery } from 'graphql/types';
import { extraFieldsToFormValues } from 'common/utils/extraFields';

export const useTrainingExtraFields = (extraFieldsValue?: string) => {
    const {
        data: extraFieldsData,
        error,
        loading,
    } = useExtraTrainingFieldsQuery();

    const extraFields = useMemo(
        () => extraFieldsData?.extraTrainingFields || [],
        [extraFieldsData?.extraTrainingFields]
    );

    const extraFieldValues = useMemo(
        () =>
            extraFieldsToFormValues(
                extraFields,
                extraFieldsValue ? JSON.parse(extraFieldsValue) : undefined
            ),
        [extraFields, extraFieldsValue]
    );

    return { extraFields, extraFieldValues, loading, error };
};
