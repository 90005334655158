import {
    useOverviewPageOffersQuery,
    IOverviewPageOffersQueryVariables,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export const useOfferOverviewList = (
    queryVariables: IOverviewPageOffersQueryVariables
) => {
    const { showApolloError } = useApolloError();
    const {
        loading,
        data,
        fetchMore: fetchMoreOffers,
    } = useOverviewPageOffersQuery({
        variables: queryVariables,
        notifyOnNetworkStatusChange: true,
        onError: showApolloError,
    });

    const {
        count: offersCount,
        pageInfo,
        extraCategories: extraCategoryQueryFilters,
    } = data?.offers || {};

    const offersObject = data?.offers;
    const offers =
        offersObject?.edges?.map((edge) => edge?.node).filter(Boolean) || [];

    const hasAllResults = offers.length >= (offersCount || 0);
    const shouldFetchMore = !hasAllResults && pageInfo?.hasNextPage && !loading;

    return {
        offers,
        offersCount,
        loading,
        fetchMoreOffers,
        pageInfo,
        shouldFetchMore,
        extraCategoryQueryFilters: extraCategoryQueryFilters || [],
    };
};
