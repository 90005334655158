import { useEffect, useRef, useState } from 'react';

interface IProps {
    children?: React.ReactNode;
    hasNextPage?: boolean;
    isLoading?: boolean;
    shouldRunCallback?: boolean;
    onScrollBottom?(): void;
}

export const InfiniteScroll = ({
    children,
    shouldRunCallback,
    onScrollBottom,
}: IProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [hitBottom, setHitBottom] = useState(false);

    const hasReachedBottom = () =>
        contentRef.current
            ? Math.ceil(window.innerHeight + window.scrollY) ===
              document.body.offsetHeight
            : false;

    useEffect(() => {
        const handleScroll = () => {
            const reachedBottom = hasReachedBottom();

            if (reachedBottom === hitBottom) return;

            setHitBottom(reachedBottom);
        };

        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    });

    useEffect(() => {
        if (!shouldRunCallback || !hitBottom || !onScrollBottom) return;

        if (!hasReachedBottom()) return;

        setHitBottom(false);

        onScrollBottom();
    }, [hitBottom, shouldRunCallback, onScrollBottom]);

    return <div ref={contentRef}>{children}</div>;
};
