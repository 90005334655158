import React, {
    useState,
    useRef,
    useCallback,
    useMemo,
    isValidElement,
} from 'react';
import { PopperPlacementType } from '@mui/material/Popper';
import { SxProps } from '@mui/material';

import { DropdownMenuList } from 'common/components/DropdownMenu';
import { withSxProp } from 'common/utils/props';

export interface IProps {
    children: React.ReactNode;
    disabled?: boolean;
    anchor: React.ReactElement;
    placement?: PopperPlacementType;
    className?: string;
    keepMounted?: boolean;
    onClose?: () => void;
    enablePortal?: boolean;
    sx?: SxProps;
}

export const DropdownMenu = ({
    className,
    children,
    disabled,
    anchor,
    placement,
    keepMounted,
    onClose,
    enablePortal,
    sx,
    ...other
}: Partial<React.ComponentProps<typeof DropdownMenuList>> & IProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
        onClose?.();
    }, []);

    const menuItems = useMemo(
        () =>
            React.Children.map(children, (child) => {
                if (!isValidElement(child)) return null;

                return React.cloneElement(child as React.ReactElement, {
                    onClick: (e: React.MouseEvent, ...args: unknown[]) => {
                        const { onClick } = child.props;
                        if (onClick) onClick(e, ...args);

                        if (!e.defaultPrevented) {
                            handleClose(e);
                        }
                    },
                });
            }),
        [children, handleClose]
    );

    const anchorEl = useMemo(
        () =>
            React.cloneElement(anchor, {
                ref: anchorRef,
                onClick: (e: React.MouseEvent, ...args: unknown[]) => {
                    e.preventDefault();

                    if (disabled) return;

                    setOpen(!open);

                    const { onClick } = anchor.props;
                    if (onClick) onClick(e, ...args);
                },
                open,
            }),
        [anchor, open, disabled]
    );

    return (
        <>
            {anchorEl}
            <DropdownMenuList
                anchorEl={anchorRef.current}
                className={className}
                disablePortal={!enablePortal}
                keepMounted={keepMounted}
                open={open}
                placement={placement}
                sx={[
                    {
                        zIndex: 'dropdown',
                        '.MuiList-root': { py: 1.75 },
                    },
                    ...withSxProp(sx),
                ]}
                onClose={handleClose}
                {...other}
            >
                {menuItems}
            </DropdownMenuList>
        </>
    );
};
