import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import { PageDrawer } from 'common/components/PageDrawer';
import {
    useTrainingQuery,
    IAssignmentType,
    useUpdateNotificationSubscriptionMutation,
    INotificationSubscriptionType,
    IAssignmentFragment,
    useModuleQuery,
    IModuleType,
    IAssignmentModuleListItemFragment,
    ITrainingRole,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { AssignmentDetail } from 'training/components/AssignmentDetail';
import { NotificationButton } from 'notification/components/NotificationButton';
import { useFrontendPermissions } from 'user/hooks';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { closeGlobalDrawerRoute } from 'common/utils/globalDrawer';

interface IProps extends IPageDrawerProps {
    trainingId?: string;
    moduleId?: string;
    userId?: string;
    onClose?(): void;
    onUpdateAssignment?(): void;
}

export const AssignmentDetailDrawer = ({
    open,
    trainingId: trainingIdProp,
    moduleId: moduleIdProp,
    userId: userIdProp,
    onClose,
    onUpdateAssignment,
}: IProps) => {
    const {
        trainingId: trainingIdParam,
        moduleId: moduleIdParam,
        userId: userIdParam,
    } = useParams() as {
        trainingId: string;
        moduleId: string;
        userId?: string;
    };

    const trainingId = trainingIdProp || trainingIdParam;
    const moduleId = moduleIdProp || moduleIdParam;
    const userId = userIdProp || userIdParam;

    const { canUpdate: canUpdateTraining } = useFrontendPermissions('training');

    const slideRef = useRef<HTMLDivElement>(null);
    const [selectedAssignment, setSelectedAssignment] =
        useState<IAssignmentFragment>();
    const prevSearchParams = useRef<string>();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: trainingData,
        loading: trainingLoading,
        error,
    } = useTrainingQuery({
        variables: { id: trainingId },
        skip: !trainingId,
    });

    const { data: moduleData, loading: moduleLoading } = useModuleQuery({
        variables: { id: moduleId, type: IModuleType.Assignment },
        skip: !moduleId,
    });

    const training = trainingData?.training;
    const { currentUserIsAuthor, rolesForCurrentUser } = training || {};

    const isParticipant = !!rolesForCurrentUser?.includes(
        ITrainingRole.Participant
    );
    const isTrainer =
        !!rolesForCurrentUser?.includes(ITrainingRole.Trainer) ||
        !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);
    const isGroupmanager = !!rolesForCurrentUser?.includes(
        ITrainingRole.Groupmanager
    );

    const canManageParticipants =
        isTrainer || isGroupmanager || canUpdateTraining;

    const moduleItem =
        (moduleData?.module as IAssignmentModuleListItemFragment) || undefined;

    const [
        updateSubscriptionNotification,
        {
            loading: updateSubscriptionNotificationLoading,
            error: updateSubscriptionNotificationError,
        },
    ] = useUpdateNotificationSubscriptionMutation({
        update: (cache, mutationResult) => {
            if (
                !selectedAssignment ||
                !mutationResult.data?.updateNotificationSubscription.ok
            ) {
                return;
            }

            cache.modify({
                id: cache.identify(selectedAssignment),
                fields: {
                    notificationSubscription() {
                        return !selectedAssignment.notificationSubscription;
                    },
                },
            });
        },
    });

    useEffect(() => {
        if (userId) return;

        // prevSearchParams is used to route back to the correct assignment url
        // instead of using the goBack function
        prevSearchParams.current = window.location.search;
    }, [userId]);

    const [filesAreUpdating, setFilesAreUpdating] = useState(false);

    const handleCreateNotificationSubscription = () => {
        if (!selectedAssignment) return;

        updateSubscriptionNotification({
            variables: {
                active: !selectedAssignment.notificationSubscription,
                objectId: selectedAssignment.id,
                subscriptionType:
                    selectedAssignment.__typename === 'CollectiveAssignment'
                        ? INotificationSubscriptionType.CollectiveAssignment
                        : INotificationSubscriptionType.IndividualAssignment,
            },
        });
    };

    const loading = trainingLoading || moduleLoading;

    if (loading || error) {
        return (
            <PageDrawer open={open}>
                {error && <ApolloError error={error} />}
                {loading && <Loader />}
            </PageDrawer>
        );
    }

    const isIndividualAssignment =
        moduleItem?.assignmentType === IAssignmentType.Individual;

    const drawerActions =
        selectedAssignment &&
        (!isIndividualAssignment || (isIndividualAssignment && userId)) ? (
            <NotificationButton
                active={!!selectedAssignment.notificationSubscription}
                disabled={updateSubscriptionNotificationLoading}
                onClick={handleCreateNotificationSubscription}
            />
        ) : undefined;

    return (
        <PageDrawer
            actions={drawerActions}
            disableClose={filesAreUpdating}
            hideCloseIcon={filesAreUpdating}
            open={open}
            SlideProps={{
                ref: slideRef,
            }}
            title={moduleItem?.title}
            onClose={() => {
                if (!onClose) {
                    closeGlobalDrawerRoute(navigate, location);

                    return;
                }

                onClose();
            }}
        >
            {updateSubscriptionNotificationError && (
                <ApolloError error={updateSubscriptionNotificationError} />
            )}

            {!!moduleId && (
                <AssignmentDetail
                    canManageParticipants={canManageParticipants}
                    currentUserIsAuthor={!!currentUserIsAuthor}
                    currentUserIsParticipant={isParticipant}
                    drawerRef={slideRef}
                    filesAreUpdating={filesAreUpdating}
                    moduleId={moduleId}
                    setFilesAreUpdating={setFilesAreUpdating}
                    setSelectedAssignment={setSelectedAssignment}
                    userId={userId}
                    onUpdateAssignment={onUpdateAssignment}
                />
            )}
        </PageDrawer>
    );
};
