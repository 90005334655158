import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

interface IProps {
    children: React.ReactNode;
}

/**
 * A container that can be fixed to a horizontal and will scroll with the page
 * This can be changed later to support more fixed positions
 */
export const FixedContainer = ({ children }: IProps) => {
    const placeholderRef = useRef<HTMLDivElement>(null);
    const fixedRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (fixedRef.current && placeholderRef.current) {
                const rect = placeholderRef.current.getBoundingClientRect();

                fixedRef.current.style.transform = `translate3d(0, ${rect.top}px, 0)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial position

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Box ref={placeholderRef} sx={{ visibility: 'hidden' }}>
                {children}
            </Box>
            <Box
                ref={fixedRef}
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: (theme) => theme.zIndex.appBar - 2,
                    width: '100%',
                }}
            >
                {children}
            </Box>
        </>
    );
};
