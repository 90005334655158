import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useFragment } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
    ICreateLocationInput,
    ICreateMeetingModuleInput,
    ILocationFragment,
    IModuleEditFragment,
    IOpenOffsetType,
    ModuleGroupFragmentDoc,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { LocationSelectField } from 'common/components/LocationSelectField';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { InfoRow } from 'common/components/InfoRow';
import { Divider } from 'common/components/Divider';

export interface IMeetingModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateMeetingModuleInput, 'imageId' | 'offset'> {
    location?: ILocationFragment;
    newLocation?: ICreateLocationInput;
}

export interface IProps {
    initialValues?: Partial<IMeetingModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    onSubmit(values: IMeetingModuleFormValues): void;
    onDelete?(): void;
    moduleGroupId?: string;
    onDelete?(): void;
}

export const MeetingModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { moduleId } = useParams();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const [showNewLocationFields, setShowNewLocationFields] = useState(false);
    const { data: moduleGroup } = useFragment({
        fragment: ModuleGroupFragmentDoc,
        from: `ModuleGroup:${moduleGroupId}`,
        fragmentName: 'ModuleGroup',
    });

    let validationSchema;

    if (!showNewLocationFields) {
        validationSchema = Yup.object().shape({
            location: Yup.object()
                .nullable()
                .required(translate('validate.required'))
                .test(
                    'uniqueMeeting',
                    translate('moduleForm.meeting.uniqueMeeting'),
                    (value) => {
                        if (!moduleGroup || !value) return true;

                        const hasDuplicates = moduleGroup.modules.some(
                            (module: IModuleEditFragment) => {
                                if (
                                    module.__typename !== 'MeetingModule' ||
                                    module.id === moduleId
                                ) {
                                    return false;
                                }

                                return module.location?.id === value.id;
                            }
                        );

                        return hasDuplicates ? false : true;
                    }
                ),
        });
    } else {
        validationSchema = Yup.object().shape({
            newLocation: Yup.object().shape({
                title: Yup.string().required(translate('validate.required')),
                location: Yup.string().required(translate('validate.required')),
            }),
        });
    }

    const initValues: IMeetingModuleFormValues = {
        title: '',
        location: undefined,
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
        newLocation: { title: '', location: '', description: '' },
        ...initialValues,
    };

    const formTitle = edit
        ? translate('moduleForm.meeting.editTitle')
        : translate('moduleForm.meeting.newTitle');
    const formText = edit
        ? translate('moduleForm.meeting.editText')
        : translate('moduleForm.meeting.newText');
    const submitText = edit
        ? translate('moduleForm.meeting.editButton')
        : translate('moduleForm.meeting.newButton');

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        {formTitle && formText && (
                            <Box mb={4}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <LocationSelectField
                            allowCreateNew
                            label={translate(
                                'moduleForm.meeting.meetingSelectLabel'
                            )}
                            name="location"
                            showNewLocationFields={showNewLocationFields}
                            onClickNewLocation={setShowNewLocationFields}
                        />

                        {values.location && (
                            <>
                                <Divider sx={{ my: 2 }} />

                                <Stack spacing={2} sx={{ mt: 4 }}>
                                    <InfoRow description={translate('title')}>
                                        {values.location.title}
                                    </InfoRow>

                                    {values.location.description && (
                                        <InfoRow
                                            description={translate(
                                                'description'
                                            )}
                                        >
                                            {values.location.description}
                                        </InfoRow>
                                    )}

                                    {values.location.location && (
                                        <InfoRow
                                            description={translate('address')}
                                        >
                                            {values.location.location}
                                        </InfoRow>
                                    )}
                                </Stack>
                            </>
                        )}

                        <Box mt={5}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.course.overrideDescription"
                                        />
                                    }
                                    title={translate('titleOverride')}
                                >
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />

                                    <Field
                                        multiline
                                        component={TextField}
                                        label={translate('description')}
                                        name="description"
                                    />
                                </FormSection>

                                <ModuleImageFormSection />
                                {/* Only show conditions section when we have a module group id */}
                                {moduleGroupId && (
                                    <ModuleOffsetFormSection
                                        moduleGroupId={moduleGroupId}
                                        offsetValues={
                                            values.offset || undefined
                                        }
                                    />
                                )}
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
