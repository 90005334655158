import { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import {
    getUserSelectStatus,
    isUserAdded,
    isUserSelected,
} from 'user/utils/userSelect';
import { UserListItem } from 'user/components/UserListItem/UserListItem';
import { AddButton } from 'common/components/Button/AddButton';
import { FormTrainerSelectDrawer } from 'user/components/FormField/FormTrainerSelector/FormTrainerSelectDrawer';

interface IProps {
    disabledTrainerIds?: string[];
    isEditable?: boolean;
    label?: string;
    name?: string;
    noResultsLabel?: string;
    selectorQueryVariables?: IUserSelectorInput;
    value: IUserListItemFragment[];
}

export const FormTrainerSelector = ({
    disabledTrainerIds,
    isEditable = true,
    label,
    name,
    noResultsLabel,
    selectorQueryVariables,
    value,
}: IProps) => {
    const { t: translate } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedTrainers, setSelectedTrainers] = useState<
        IUserListItemFragment[]
    >([]);

    const addedTrainers = value;

    const handleDrawerOpen = (open: boolean) => {
        if (!open) setSelectedTrainers([]);

        setDrawerOpen(open);
    };

    const handleSelectTrainer = (trainer: IUserListItemFragment) => {
        setSelectedTrainers([...selectedTrainers, trainer]);
    };

    const handleRemoveTrainer = (
        trainer: IUserListItemFragment,
        arrayHelpers: FieldArrayRenderProps
    ) => {
        const { isSelected } = getUserSelectStatus(
            selectedTrainers,
            addedTrainers,
            trainer
        );

        if (isSelected) {
            const newSelectedTrainers = selectedTrainers.filter(
                (selectedTrainer) => selectedTrainer.id !== trainer.id
            );

            setSelectedTrainers(newSelectedTrainers);

            return;
        }

        const index = addedTrainers.findIndex(
            (addedTrainer) => trainer.id === addedTrainer.id
        );

        arrayHelpers.remove(index);
    };

    const handleAddTrainers = (arrayHelpers: FieldArrayRenderProps) => {
        selectedTrainers.forEach((selectedTrainer) => {
            arrayHelpers.push(selectedTrainer);
        });

        setSelectedTrainers([]);

        handleDrawerOpen(false);
    };

    const fieldName = name || 'trainers';

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const error = form.errors[fieldName];
                const touched = form.touched[fieldName];

                return (
                    <Box>
                        <Box
                            alignItems="left"
                            display="flex"
                            flexDirection="column"
                        >
                            {isEditable && (
                                <AddButton
                                    onClick={() => handleDrawerOpen(true)}
                                >
                                    <Typography>
                                        {label || translate('addTrainersTitle')}
                                    </Typography>

                                    {error && touched && (
                                        <Box mt="-5px" position="absolute">
                                            <FormHelperText error>
                                                {form.errors[fieldName]}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                </AddButton>
                            )}

                            {!!addedTrainers.length && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mt={isEditable ? 2 : 0}
                                >
                                    {addedTrainers.map((addedTrainer) => (
                                        <UserListItem
                                            addButton={isEditable}
                                            added={isUserAdded(
                                                addedTrainers,
                                                addedTrainer
                                            )}
                                            key={`added-${addedTrainer.id}`}
                                            selected={isUserSelected(
                                                selectedTrainers,
                                                addedTrainer
                                            )}
                                            user={addedTrainer}
                                            onClick={(
                                                trainer: IUserListItemFragment
                                            ) => {
                                                if (!isEditable) return;

                                                handleRemoveTrainer(
                                                    trainer,
                                                    arrayHelpers
                                                );
                                            }}
                                        />
                                    ))}
                                </Box>
                            )}

                            {!isEditable && !addedTrainers.length && (
                                <Box>
                                    <Typography>
                                        {noResultsLabel ||
                                            translate('noOptionsText.trainers')}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <FormTrainerSelectDrawer
                            addedTrainers={addedTrainers}
                            disabledTrainerIds={disabledTrainerIds}
                            open={drawerOpen}
                            selectedTrainers={selectedTrainers}
                            selectorQueryVariables={selectorQueryVariables}
                            onAddTrainers={() =>
                                handleAddTrainers(arrayHelpers)
                            }
                            onDrawerOpen={handleDrawerOpen}
                            onRemoveTrainer={(trainer) =>
                                handleRemoveTrainer(trainer, arrayHelpers)
                            }
                            onSelectTrainer={handleSelectTrainer}
                        />
                    </Box>
                );
            }}
        />
    );
};
