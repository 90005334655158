import { mdiChevronRight } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { useContext } from 'react';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemMediaDate,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link/Link';
import { getOfferDateDisplay } from 'offer/utils/dates';
import {
    IOfferEventListItemFragment,
    IOfferEventSubscriptionStatus,
    IOfferEventType,
} from 'graphql/types';
import { Chip, ChipGroup } from 'common/components/Chip';
import { getLabelTranslation } from 'offer/utils/offerEvent';
import { formatRoundPrice } from 'common/utils/price';
import { OfferPermissionsContext } from 'offer/contexts';
import { OfferEventPermissionsContext } from 'offer/contexts/OfferPermissionsContext';

import { OfferEventSubscriptionStatusChip } from '../OfferEventSubscriptionStatusChip';

interface IProps extends IOfferEventListItemFragment {
    className?: string;
    description?: React.ReactNode;
    disabled?: boolean;
    onClick?(): void;
}

const BaseOfferEventListItem = ({
    closingDateExpired,
    currentUserEnrollment,
    dates,
    dateCount,
    description,
    firstDate,
    location,
    price,
    spotsLeft,
    subscriptionStatus,
    type,
    firstTraining,
    trainingCount,
    publish,
    accessCourses,
    waitingList,
    maxSubscriptions,
    offer,
    title,
    cancelled,
    onClick,
    labels,
    ...other
}: IProps) => {
    const { canUpdate = false } = useContext(OfferPermissionsContext) || {};
    const { canManageSubscriptions = false } =
        useContext(OfferEventPermissionsContext) || {};
    const [translate] = useTranslation();

    const isTraining = type === IOfferEventType.Training;
    const isCourse = type === IOfferEventType.Course;

    let itemTitle = !title && translate('startNow');

    if (!isTraining && !isCourse && firstDate) {
        itemTitle = getOfferDateDisplay(firstDate, dateCount, true, true);
    }

    if (isTraining && firstTraining) {
        itemTitle = `${firstTraining.title} ${
            trainingCount && trainingCount > 1
                ? ` | ${translate('otherTrainings', {
                      count: trainingCount - 1,
                  })}`
                : ''
        }`;
    }

    const firstCourse = accessCourses?.edges?.[0]?.node;

    if (isCourse && !!firstCourse) {
        const courseCount = accessCourses?.count;

        itemTitle = `${firstCourse.title} ${
            courseCount && courseCount > 1
                ? ` | ${translate('otherCourses', {
                      count: courseCount - 1,
                  })}`
                : ''
        }`;
    }

    const showClosingDateExpiredChip =
        closingDateExpired &&
        (!currentUserEnrollment || canUpdate || canManageSubscriptions);

    const spotsAmount = !!spotsLeft && spotsLeft > 0 ? spotsLeft : 0;
    const currentUserOnWaitingList =
        currentUserEnrollment?.status === IOfferEventSubscriptionStatus.Full &&
        waitingList;

    const canManage = canUpdate || canManageSubscriptions;

    const locations = dates
        ?.map((date) => date?.location?.title || date.locationString)
        .filter(Boolean);
    const firstLocation = locations?.[0];
    const hasMultipleLocations = locations?.some(
        (location) => location !== firstLocation
    );

    const offerChips = (
        <ChipGroup>
            {firstLocation && (
                <Chip
                    label={
                        !hasMultipleLocations
                            ? firstLocation
                            : translate('moreLocations', {
                                  location: firstLocation,
                              })
                    }
                />
            )}

            {labels.map((label, index) => (
                <Chip
                    bgColor="secondary"
                    key={`${label}-${index}`}
                    label={getLabelTranslation(label)}
                />
            ))}

            {!!price && !!Number(price) && (
                <Chip
                    label={translate('price', {
                        price: formatRoundPrice(price),
                    })}
                />
            )}

            {currentUserOnWaitingList && (
                <Chip
                    bgColor="warning"
                    label={translate('waitingListSubscriptionLabel')}
                />
            )}

            {waitingList &&
                ((!currentUserOnWaitingList &&
                    !spotsAmount &&
                    !currentUserEnrollment &&
                    maxSubscriptions > 0) ||
                    canManage) && (
                    <Chip
                        bgColor="warning"
                        label={translate('waitingListLabel')}
                    />
                )}

            {!!spotsAmount && !subscriptionStatus && (
                <Chip label={translate('spotsLeft', { count: spotsAmount })} />
            )}

            {!spotsAmount && maxSubscriptions > 0 && !currentUserEnrollment && (
                <Chip bgColor="warning" label={translate('full')} />
            )}

            {!!subscriptionStatus && (
                <OfferEventSubscriptionStatusChip
                    subscriptionStatus={subscriptionStatus}
                />
            )}

            {!publish && (
                <Chip bgColor="warning" label={translate('concept')} />
            )}

            {showClosingDateExpiredChip && (
                <Chip
                    bgColor="warning"
                    label={translate('subscriptionClosed')}
                />
            )}

            {cancelled && (
                <Chip bgColor="error" label={translate('cancelled')} />
            )}
        </ChipGroup>
    );

    let listItemImage;

    if (isTraining && firstTraining) {
        const { image, overviewImage } = firstTraining;

        listItemImage = overviewImage
            ? overviewImage.url || ''
            : image?.url || '';
    }

    if (isCourse && firstCourse) {
        const { image } = firstCourse;

        listItemImage = image?.url || '';
    }

    let listItemMediaContent;

    if (!isTraining && !isCourse && !!dates.length) {
        listItemMediaContent = (
            <ListItemMediaDate date={firstDate?.startDate} />
        );
    }

    return (
        <ListItem button component={Link} onClick={onClick} {...other}>
            <ListItemMedia
                color="primary"
                image={listItemImage}
                isText={!!dates.length && !isCourse && !isTraining}
            >
                {listItemMediaContent}
            </ListItemMedia>

            <ListItemText
                primary={title || itemTitle}
                secondary={!title ? undefined : itemTitle}
            >
                {offerChips}
            </ListItemText>

            <ListItemActionText>{translate('open')}</ListItemActionText>

            <ListItemSecondaryAction hideXs>
                <Icon path={mdiChevronRight} size="4rem" />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export const OfferEventListItem = styled(BaseOfferEventListItem)`
    ${ListItemMedia} {
        min-height: 70px;
        min-width: 70px;
        padding: ${({ theme }) => theme.spacing(1)};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            min-height: 106px;
            min-width: 106px;
        }
    }
`;
