import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { mdiClose } from '@mdi/js';
import moment from 'moment';
import { Crisp } from 'crisp-sdk-web';

import { AlertDialog, AlertDialogProps } from 'common/components/AlertDialog';
import { Typography } from 'common/components/Typography';
import { IconButton } from 'common/components/IconButton';
import {
    IOrganisationPlanInfoFragment,
    IPlanFragment,
    IPlanInterval,
    IPlanPackageFragment,
} from 'graphql/types';
import { formatDate } from 'common/utils/formatDate';
import {
    getDowngradeDate,
    getLosingFeatures,
    isIntervalDowngrade,
    isIntervalUpgrade,
} from 'plans/utils/plan';
import { TPlanPackage } from 'plans/types';
import { PLAN_PACKAGES } from 'plans/constants/plans';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';

import { PlanFeature } from '../PlanFeature';

interface IProps extends AlertDialogProps {
    buttonText: string;
    currentPlan: IPlanFragment;
    currentPlanPackage?: IPlanPackageFragment;
    interval: IPlanInterval;
    nextPaymentDate?: IOrganisationPlanInfoFragment['nextPaymentDate'];
    selectedPlan: IPlanFragment;
    selectedPlanPackage: TPlanPackage;
    onClose?(): void;
}

export const PlanDowngradeModal = ({
    buttonText,
    currentPlan,
    currentPlanPackage,
    interval,
    nextPaymentDate,
    selectedPlan,
    selectedPlanPackage,
    onClose,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();

    const title = (
        <Box alignItems="center" display="flex">
            <Typography fontWeight={700}>
                {translate('plans.downgradeModal.title')}
            </Typography>

            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    if (!nextPaymentDate) {
        const handleClickChat = () => {
            Crisp.chat.show();

            onClose?.();
        };

        const actions = (
            <Button
                color="primary"
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleClickChat}
            >
                {translate('startChat')}
            </Button>
        );

        return (
            <AlertDialog
                {...other}
                actions={actions}
                title={title}
                onClose={onClose}
            >
                <Typography>
                    {translate('plans.downgradeModal.planError')}
                </Typography>
            </AlertDialog>
        );
    }

    const currentPlanPackageObject = currentPlanPackage
        ? PLAN_PACKAGES[currentPlanPackage.frontendName]
        : undefined;

    const isLosingFeatures =
        currentPlanPackageObject &&
        selectedPlanPackage.weight < currentPlanPackageObject.weight;

    const losingFeatures = isLosingFeatures ? (
        <Box>
            {getLosingFeatures(
                currentPlanPackageObject,
                selectedPlanPackage
            ).map((feature, index) => (
                <PlanFeature feature={feature} key={index} />
            ))}
        </Box>
    ) : undefined;

    const downgradeDate = getDowngradeDate(nextPaymentDate, interval);
    const currentDay = moment(new Date()).startOf('day');

    let canDowngrade = false;
    if (currentDay >= downgradeDate) canDowngrade = true;

    const intervalDowngrade = isIntervalDowngrade(currentPlan, selectedPlan);
    const intervalUpgrade = isIntervalUpgrade(currentPlan, selectedPlan);

    return (
        <AlertDialog {...other} title={title} onClose={onClose}>
            {!canDowngrade && (
                <Typography>
                    {translate('plans.downgradeModal.text', {
                        downgradeDate: formatDate(
                            downgradeDate.toISOString(),
                            'd MMMM yyyy'
                        ),
                    })}
                </Typography>
            )}

            {(intervalDowngrade || intervalUpgrade) && (
                <Box mb={!!losingFeatures ? 2 : 0} mt={!canDowngrade ? 2 : 0}>
                    <Typography>
                        {translate(
                            `plans.interval.${
                                intervalDowngrade
                                    ? 'intervalToMonth'
                                    : 'intervalToYear'
                            }`
                        )}
                    </Typography>
                </Box>
            )}

            {!!losingFeatures && (
                <Box mt={!intervalDowngrade && !canDowngrade ? 2 : 0}>
                    <Typography>
                        {translate('plans.downgradeModal.featureLoss')}
                    </Typography>

                    <Box mt={1}>{losingFeatures}</Box>
                </Box>
            )}

            {canDowngrade && (
                <Box mt={intervalDowngrade || !!losingFeatures ? 4 : 0}>
                    <Button
                        color="primary"
                        component={Link}
                        query={`?plan=${selectedPlan.id}`}
                        sx={{ width: '100%' }}
                        to="MANAGEMENT_ORGANISATION_PAYMENTDETAILS"
                        variant="contained"
                    >
                        {buttonText}
                    </Button>
                </Box>
            )}
        </AlertDialog>
    );
};
