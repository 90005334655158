import { useEffect, useState } from 'react';

import {
    IExtraCategoryFilterInput,
    IOfferRole,
    IOverviewPageOffersQueryVariables,
} from 'graphql/types';
import { DEFAULT_PAGINATION_SETTINGS } from 'common/hooks/usePagination';
import { TFilterBarItem } from 'common/types';
import {
    extraCategoryFiltersAreEqual,
    getExtraCategoryInputVariables,
} from 'organisation/utils/extraCategory';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
    selectedFilters: TFilterBarItem[];
}

export const useOfferOverviewQueryVariables = ({
    paginationVariables,
    searchQueryParam,
    selectedFilters,
}: IProps) => {
    const [offerQueryVariables, setOfferQueryVariables] = useState<
        IOverviewPageOffersQueryVariables & {
            extraCategories?: IExtraCategoryFilterInput[];
        }
    >({
        q: '',
        ...DEFAULT_PAGINATION_SETTINGS,
    });

    useEffect(() => {
        const roleFilters = selectedFilters.filter(
            (filter) => filter.parent === 'role_internal'
        );

        const categoryFilters = selectedFilters.filter(
            (filter) => filter.name !== 'role_internal'
        );

        const newOfferQueryVariables = {
            ...paginationVariables,
            q: searchQueryParam,
            extraCategories: getExtraCategoryInputVariables(categoryFilters),
            forRoles: roleFilters.length
                ? (roleFilters.map((filter) => filter.value) as IOfferRole[])
                : undefined,
        };

        const { q, offset, first, extraCategories, forRoles } =
            offerQueryVariables;

        if (
            q === newOfferQueryVariables.q &&
            offset === newOfferQueryVariables.offset &&
            first === newOfferQueryVariables.first &&
            extraCategoryFiltersAreEqual(
                extraCategories || [],
                newOfferQueryVariables.extraCategories
            ) &&
            JSON.stringify(newOfferQueryVariables.forRoles) ===
                JSON.stringify(forRoles)
        ) {
            return;
        }

        setOfferQueryVariables({
            ...offerQueryVariables,
            ...newOfferQueryVariables,
            offset:
                q !== newOfferQueryVariables.q
                    ? 0
                    : newOfferQueryVariables.offset,
        });
    }, [
        offerQueryVariables,
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    ]);

    return { offerQueryVariables };
};
