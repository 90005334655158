import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Reference } from '@apollo/client';

import { PageDrawer } from 'common/components/PageDrawer';
import { DevelopmentItemGroupForm } from 'user/components/forms';
import { TFormValues } from 'user/components/forms/DevelopmentItemGroupForm/DevelopmentItemGroupForm';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import {
    DevelopmentItemGroupFragmentDoc,
    IDevelopmentItemFragment,
    IDevelopmentItemGroupFragment,
    useCreateDevelopmentItemGroupMutation,
    useDeleteDevelopmentItemGroupMutation,
    useUpdateDevelopmentItemGroupMutation,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Loader } from 'common/components/Loader';
import { PageTitle } from 'common/components/PageTitle';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

interface IProps extends IPageDrawerProps {
    pageTitle: string;
    mixpanelTitle: string;
    inboxGroupId?: string;
    item?: IDevelopmentItemGroupFragment;
    loading?: boolean;
    onClose?(): void;
}

export const DevelopmentItemGroupDrawer = ({
    pageTitle,
    mixpanelTitle,
    inboxGroupId,
    item,
    loading,
    onClose,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);
    const [displaySnackbar] = useSnackbar();
    const isGroupCreatePage = !!useRouteMatch('DEVELOPMENT_GROUP_CREATE');
    const [createGroup, { error: createError }] =
        useCreateDevelopmentItemGroupMutation({
            update: (cache, { data: newDevelopmentItemGroupData }) => {
                const newDevelopmentItemGroup =
                    newDevelopmentItemGroupData?.createDevelopmentItemGroup
                        .developmentItemGroup;

                if (!newDevelopmentItemGroup) return;

                cache.modify({
                    fields: {
                        developmentPlan(existingDevelopmentItemGroupRefs = []) {
                            const newDevelopmentItemGroupRef =
                                cache.writeFragment({
                                    id: `DevelopmentItemGroup:${newDevelopmentItemGroup.id}`,
                                    fragment: DevelopmentItemGroupFragmentDoc,
                                    data: {
                                        ...newDevelopmentItemGroup,
                                        isInbox: false,
                                    },
                                    fragmentName: 'DevelopmentItemGroup',
                                });

                            return [
                                ...existingDevelopmentItemGroupRefs,
                                newDevelopmentItemGroupRef,
                            ];
                        },
                    },
                });

                displaySnackbar(translate('groupActionSuccess.create'), {
                    variant: 'success',
                });

                onClose && onClose();
            },
        });

    const [updateGroup, { error: updateError }] =
        useUpdateDevelopmentItemGroupMutation({
            update: () => {
                displaySnackbar(translate('groupActionSuccess.update'), {
                    variant: 'success',
                });

                onClose && onClose();
            },
        });

    const [deleteGroup, { loading: deleteLoading, error: deleteError }] =
        useDeleteDevelopmentItemGroupMutation({
            update: (cache) => {
                cache.modify({
                    fields: {
                        developmentPlan(
                            existingDevelopmentItemGroupRefs = [],
                            { readField }
                        ) {
                            if (item) {
                                // Move all development items from the deleted group to the inbox group
                                cache.modify({
                                    id: `DevelopmentItemGroup:${inboxGroupId}`,
                                    fields: {
                                        developmentItems(
                                            existingDevelopmentItemRefs: readonly (
                                                | Reference
                                                | IDevelopmentItemFragment
                                            )[] = []
                                        ) {
                                            // Deleted development item group
                                            const deletedDevelopmentItemGroupRef: IDevelopmentItemGroupFragment | null =
                                                cache.readFragment({
                                                    id: `DevelopmentItemGroup:${item.id}`,
                                                    fragment:
                                                        DevelopmentItemGroupFragmentDoc,
                                                    fragmentName:
                                                        'DevelopmentItemGroup',
                                                });

                                            if (
                                                !deletedDevelopmentItemGroupRef
                                            ) {
                                                return [
                                                    ...existingDevelopmentItemRefs,
                                                ];
                                            }

                                            // Set inbox group id in group property of moved development items and return the refs
                                            const developmentItemRefs =
                                                deletedDevelopmentItemGroupRef.developmentItems.map(
                                                    (item) => {
                                                        const {
                                                            __typename,
                                                            id,
                                                        } = item;

                                                        if (
                                                            __typename !==
                                                            'DevelopmentItem'
                                                        ) {
                                                            return {
                                                                __ref: `${__typename}:${id}`,
                                                            };
                                                        }

                                                        // Immediately return development item ref after group property update
                                                        return cache.writeFragment(
                                                            {
                                                                id: `${item.__typename}:${id}`,
                                                                fragment:
                                                                    DevelopmentItemGroupFragmentDoc,
                                                                data: {
                                                                    ...item,
                                                                    group: {
                                                                        __typename:
                                                                            'DevelopmentItemGroup',
                                                                        id: inboxGroupId,
                                                                    },
                                                                },
                                                                fragmentName:
                                                                    'DevelopmentItemListItem',
                                                            }
                                                        );
                                                    }
                                                );

                                            return [
                                                ...existingDevelopmentItemRefs,
                                                ...developmentItemRefs,
                                            ] as Reference[];
                                        },
                                    },
                                });
                            }

                            return existingDevelopmentItemGroupRefs.filter(
                                (group: IDevelopmentItemGroupFragment) =>
                                    readField('id', group) !== item?.id
                            );
                        },
                    },
                });

                if (item) {
                    // Remove development item group from cache
                    cache.evict({ id: `DevelopmentItemGroup:${item.id}` });
                    cache.gc();
                }

                displaySnackbar(translate('groupActionSuccess.delete'), {
                    variant: 'success',
                });

                onClose && onClose();
            },
        });

    const { id, title, description } = item || {};

    const initialValues: TFormValues = {
        title: title || '',
        description: description || '',
    };

    const handleSubmit = (values: TFormValues) => {
        if (item?.id) {
            return updateGroup({
                variables: {
                    developmentItemGroup: values,
                    id,
                },
            });
        }

        return createGroup({
            variables: {
                developmentItemGroup: values,
            },
        });
    };

    const onDelete = () => {
        setShowOnDeleteAlert(false);

        if (id) deleteGroup({ variables: { id } });
    };

    let drawerTitle = !loading && !!item ? translate('updateGroup') : '';

    if (isGroupCreatePage) {
        drawerTitle = translate('newGroup');
    }

    return (
        <PageDrawer {...other} title={drawerTitle} onClose={onClose}>
            {createError && <ApolloError error={createError} />}
            {updateError && <ApolloError error={updateError} />}
            {deleteError && <ApolloError error={deleteError} />}

            {!loading && !!drawerTitle && (
                <PageTitle
                    mixpanelTitle={`${mixpanelTitle} - ${
                        item ? 'Edit' : 'New'
                    } development plan group`}
                >{`${pageTitle} - ${drawerTitle}`}</PageTitle>
            )}

            {loading && <Loader />}

            {!loading && (
                <DevelopmentItemGroupForm
                    initialValues={initialValues}
                    isDeleting={deleteLoading}
                    onDelete={() => setShowOnDeleteAlert(true)}
                    onSubmit={handleSubmit}
                />
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={onDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowOnDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showOnDeleteAlert}
                title={translate('deleteGroupMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteGroupMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
