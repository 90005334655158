import * as Sentry from '@sentry/browser';
import { GraphQLError } from 'graphql';

import { ERROR_MESSAGES } from 'common/constants/errorMessages';

export function getErrorMessage(error: GraphQLError) {
    const errorCode: keyof typeof ERROR_MESSAGES = error?.extensions?.code;

    if (import.meta.env.MODE === 'production') Sentry.captureException(error);

    if (!errorCode || (errorCode && !ERROR_MESSAGES[errorCode])) {
        return ERROR_MESSAGES['General'];
    }

    return ERROR_MESSAGES[errorCode];
}
