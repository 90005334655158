import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { debounce } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { MultiSelect } from 'common/components/FormField';
import {
    ICourseSortBy,
    IExtraCategoryChoiceValue,
    IExtraCategoryOption,
    IExtraCategoryStringValue,
    IExtraCategoryValueFragment,
    useInputOptionCoursesLazyQuery,
} from 'graphql/types';
import { TInputOption } from 'common/types';
import { Typography } from 'common/components/Typography';

interface IProps {
    isFilter?: boolean;
    label?: string;
    ids?: string[];
    onChange?: (name: string, value: string[]) => void;
}

const BASE_QUERY_VARS = {
    first: 20,
    sortBy: ICourseSortBy.Title,
};

export const CourseSelectField = ({
    isFilter,
    label,
    ids = [],
    onChange,
    ...other
}: IProps & FieldProps) => {
    const [inputValue, setInputValue] = useState('');
    const [translate] = useTranslation();

    const [fetchCourses, { data, loading, called }] =
        useInputOptionCoursesLazyQuery();

    const { courses: coursesData } = data || {};

    const courses =
        coursesData?.edges.map((edge) => edge?.node).filter(Boolean) || [];
    const coursesCount = coursesData?.count || 0;

    const inputOptions: TInputOption[] = useMemo(
        () =>
            courses.map(({ id, title, extraCategoryValues }) => ({
                id,
                name: title,
                extraCategoryLabels: extraCategoryValues || [],
                isInfo: false,
            })) || [],
        [courses]
    );

    const value: TInputOption[] = other.field.value;

    // Add value options to input options if they are not already in the input options
    value.forEach((valueOption) => {
        if (
            !inputOptions.some(
                (option: TInputOption) => option.id === valueOption.id
            )
        ) {
            const { id, name, extraCategoryLabels } = valueOption;

            inputOptions.push({
                id,
                name,
                extraCategoryLabels,
                isInfo: false,
            });
        }
    });

    if (coursesCount > 5) {
        inputOptions.push({
            id: '',
            name: translate('moreResultsSelectSearch', {
                count: coursesCount - 5,
            }),
            extraCategoryLabels: [],
            isInfo: true,
        });
    }

    const {
        form: { setFieldValue },
    } = other;

    const handleChange = (name: string, value: string[]) => {
        onChange?.(name, value);
        setFieldValue(name, value);
    };

    const fetch = useMemo(
        () =>
            debounce((inputValue: string) => {
                fetchCourses({
                    variables: {
                        ...BASE_QUERY_VARS,
                        ids: ids,
                        q: inputValue || undefined,
                    },
                });
            }, 400),
        []
    );

    // Searching for results
    useEffect(() => {
        // The first query is done by opening, so we want to avoid calling it twice
        if (!called) return;

        fetch(inputValue);
    }, [inputValue, fetch]);

    // Fetch courses when ids change
    useEffect(() => {
        if (!ids.length || !called) return;

        fetchCourses({
            variables: {
                ...BASE_QUERY_VARS,
                ids: ids,
            },
        });
    }, [ids]);

    const handleOpen = () => {
        if (!ids.length || !!courses.length) return;

        fetchCourses({
            variables: {
                ...BASE_QUERY_VARS,
                ids: ids,
                q: inputValue || undefined,
            },
        });
    };

    return (
        <Field
            component={MultiSelect}
            filterOptions={(option: TInputOption) => option}
            getOptionDisplay={(option: TInputOption) => {
                const extraCategoryString = (
                    option.extraCategoryLabels as IExtraCategoryValueFragment[]
                )
                    .filter(
                        ({ category }) =>
                            category.categoryType !==
                                IExtraCategoryOption.Boolean &&
                            category.categoryType !==
                                IExtraCategoryOption.Datetime &&
                            category.showLabel
                    )
                    .map((extraCategoryValue) => {
                        if (
                            extraCategoryValue.category.categoryType ===
                            IExtraCategoryOption.String
                        ) {
                            return (
                                extraCategoryValue as IExtraCategoryStringValue
                            ).stringValue;
                        }

                        return (extraCategoryValue as IExtraCategoryChoiceValue)
                            .choiceValue.stringValue;
                    })
                    .join(', ');

                return (
                    <>
                        {option.name}

                        {!!extraCategoryString && (
                            <Typography
                                noWrap
                                color="grey.500"
                                component="div"
                                sx={{
                                    mt: 0.5,
                                    minWidth: '0',
                                    width: '100%',
                                }}
                                variant="caption"
                            >
                                {extraCategoryString}
                            </Typography>
                        )}
                    </>
                );
            }}
            getOptionIsInfo={(option: TInputOption) => option.isInfo}
            getOptionKey={(option: TInputOption) => option.id}
            getOptionLabel={(option: TInputOption) => option.name}
            InputProps={{ label }}
            isOptionEqualToValue={(option: TInputOption, value: TInputOption) =>
                option.id === value.id
            }
            limitTags={5}
            loading={loading}
            loadingText={translate('loadingDots')}
            noOptionsText={translate(
                `noOptionsText.${isFilter ? 'filterCourses' : 'courses'}`
            )}
            options={inputOptions}
            onChange={handleChange}
            onInputChange={(
                _event: React.ChangeEvent<HTMLInputElement>,
                newInputValue: string
            ) => {
                setInputValue(newInputValue);
            }}
            onOpen={handleOpen}
            {...other}
        />
    );
};
