import React, { forwardRef } from 'react';
import { CircularProgress, Box, SxProps } from '@mui/material';
import MuiButton, { ButtonProps } from '@mui/material/Button';

import { withSxProp } from 'common/utils/props';

const buttonStyle: SxProps = {
    textTransform: 'none',
    fontWeight: 'fontWeightBold',
    lineHeight: 1.2,
    p: '16px 24px',

    '&.MuiButton-outlined': {
        p: '15px 23px',
    },

    '&.MuiButton-textSizeSmall, &.MuiButton-containedSizeSmall': {
        p: '7px 11px',
    },

    '&.MuiButton-outlinedSizeSmall': {
        p: '6px 10px',
    },

    '&.MuiButton-sizeLarge': {
        fontSize: 18,
    },

    '&.MuiButton-textSizeLarge, &.MuiButton-containedSizeLarge': {
        p: '24px 32px',
    },

    '&.MuiButton-outlinedSizeLarge': {
        p: '23px 31px',
    },

    '&.MuiLink-underlineHover:hover': {
        textDecoration: 'none',
    },

    '.spinner': {
        display: 'flex',
        alignItems: 'center',
        color: 'action.disabled',

        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },

    '.MuiButton-startIcon': {
        mr: 1.5,
        ml: -1,
    },

    '.MuiButton-endIcon': {
        ml: 1.5,
        mr: -1,
    },

    '&.MuiButton-outlinedSizeSmall .MuiButton-startIcon': {
        mr: 0.5,
        ml: -0.5,
    },
};

// Style for buttons with no content
const noContentStyle: SxProps = {
    '&.MuiButton-outlinedSizeSmall': {
        minWidth: 0,

        '.MuiButton-startIcon': {
            mr: -0.5,
            ml: -0.5,
        },
    },
    '&.MuiButton-outlinedSizeMedium': {
        minWidth: 0,

        '.MuiButton-startIcon': {
            mr: -1,
            ml: -1,
        },
    },
};

interface IProps {
    loading?: boolean;
}

function getProgressSize(size: ButtonProps['size']) {
    switch (size) {
        case 'small':
            return 16;
        case 'large':
            return 28;
        case 'medium':
        default:
            return 20;
    }
}

export function BaseButton<C extends React.ElementType>(
    {
        children,
        color,
        disabled,
        loading,
        size,
        sx,
        ...other
    }: ButtonProps<C, { component?: C } & IProps>,
    ref: React.Ref<HTMLButtonElement>
) {
    return (
        <MuiButton
            color={color}
            disabled={disabled || loading}
            ref={ref}
            size={size}
            {...other}
            sx={[
                buttonStyle,

                loading && { '&.Mui-disabled': { color: 'transparent' } },

                !color && {
                    '&.MuiButton-contained': {
                        backgroundColor: 'grey.200',
                        color: 'grey.900',

                        '&:hover': {
                            backgroundColor: 'grey.300',
                        },
                    },

                    '&.MuiButton-outlined': {
                        backgroundColor: 'common.white',
                        color: 'text.primary',
                        borderColor: 'grey.300',

                        '&:hover': {
                            backgroundColor: 'grey.100',
                        },
                        '&.Mui-disabled': {
                            color: 'grey.600',
                            backgroundColor: 'grey.100',
                        },
                    },
                },

                !children && noContentStyle,

                // Spread sx from props
                ...withSxProp(sx),
            ]}
        >
            {children}
            {loading && (
                <Box className="spinner">
                    <CircularProgress
                        color="inherit"
                        size={getProgressSize(size)}
                    />
                </Box>
            )}
        </MuiButton>
    );
}

export const Button = forwardRef(BaseButton);
