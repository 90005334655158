import { Box, IconButton } from '@mui/material';
import { mdiBellRing, mdiBellOff } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import { Tooltip } from 'common/components/Tooltip';

interface IProps {
    active?: boolean;
    disabled?: boolean;
    onClick?(): void;
}

export const NotificationButton = ({ active, disabled, onClick }: IProps) => {
    const { t: translate } = useTranslation();

    return (
        <Tooltip
            title={translate<string>(
                active ? 'notificationsOff' : 'notificationsOn'
            )}
        >
            <Box>
                <IconButton
                    color="inherit"
                    disabled={disabled}
                    size="large"
                    onClick={onClick}
                >
                    <Icon
                        path={active ? mdiBellRing : mdiBellOff}
                        size="2.4rem"
                    />
                </IconButton>
            </Box>
        </Tooltip>
    );
};
