import { styled } from 'styled-components';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { rgba } from 'polished';

const BaseTooltip = ({ className, classes, ...other }: TooltipProps) => (
    <MuiTooltip {...other} classes={{ ...classes, tooltip: className }} />
);

export const Tooltip = styled(BaseTooltip)<TooltipProps>`
    font-weight: bold;
    background: ${({ theme }) => rgba(theme.palette.text.primary, 0.8)};
`;
