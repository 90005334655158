import { useEffect, useState } from 'react';

import { ICertificatesQueryVariables } from 'graphql/types';
import { DEFAULT_PAGINATION_SETTINGS } from 'common/hooks/usePagination';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
}

export const useCertificateQueryVariables = ({
    paginationVariables,
    searchQueryParam,
}: IProps) => {
    const [certificateQueryVariables, setCertificateQueryVariables] =
        useState<ICertificatesQueryVariables>({
            q: '',
            ...DEFAULT_PAGINATION_SETTINGS,
        });

    useEffect(() => {
        const newCertificateQueryVariables = {
            ...paginationVariables,
            q: searchQueryParam,
        };

        const { q, offset, first } = certificateQueryVariables;

        if (
            q === newCertificateQueryVariables.q &&
            offset === newCertificateQueryVariables.offset &&
            first === newCertificateQueryVariables.first
        ) {
            return;
        }

        setCertificateQueryVariables({
            ...certificateQueryVariables,
            ...newCertificateQueryVariables,
            offset:
                q !== newCertificateQueryVariables.q
                    ? 0
                    : newCertificateQueryVariables.offset,
        });
    }, [certificateQueryVariables, paginationVariables, searchQueryParam]);

    return { certificateQueryVariables };
};
