import {
    mdiHome,
    mdiBriefcaseVariant,
    mdiForum,
    mdiBookOpenVariant,
    mdiCog,
    mdiSchool,
} from '@mdi/js';

import { NavigationAvatar } from 'common/components/MainNavigation/Navigation/NavigationAvatar';
import { NavigationCreateMaterial } from 'common/components/MainNavigation/Navigation/NavigationCreateMaterial';
import { TRouteName } from 'route/types';

export interface IMenuItem {
    translation?: string;
    iconPath?: string;
    iconSize?: string;
    iconStyle?: React.CSSProperties;
    component?: React.FC<React.PropsWithChildren<unknown>>;
    url?: TRouteName;
    target?: string;
    href?: string;
    autoSize?: boolean;
    isButton?: boolean;
}
type TMainMenu = {
    [key: string]: IMenuItem;
};

export const MAIN_MENU: TMainMenu = {
    HOME: {
        translation: 'home',
        iconPath: mdiHome,
        url: 'HOME',
    },
    CHAT: {
        translation: 'chat',
        iconPath: mdiForum,
        iconStyle: {
            marginTop: '2px',
        },
        url: 'CHAT',
    },
    LIBRARY: {
        translation: 'library',
        iconPath: mdiBookOpenVariant,
        iconStyle: {
            marginTop: '-2px',
        },
        url: 'LIBRARY',
    },
    DEVELOPMENT_PLAN: {
        translation: 'portfolio',
        iconPath: mdiBriefcaseVariant,
        iconStyle: {
            marginTop: '-2px',
        },
        url: 'PORTFOLIO',
    },
    MANAGEMENT: {
        translation: 'management',
        iconPath: mdiCog,
        url: 'MANAGEMENT_ACADEMY',
    },

    MANAGEMENT_MODULES: {
        translation: 'managementModules',
    },
    MANAGEMENT_USERS: {
        translation: 'managementUsers',
    },
    MANAGEMENT_SETTINGS: {
        translation: 'managementSettings',
    },
    MANAGEMENT_ORGANISATION: {
        translation: 'managementSubscription', // Use "subscription" as translation for organisation menu
    },

    MANAGEMENT_MODULES_TRAININGS: {
        translation: 'managementTraining',
        url: 'TRAINING_MANAGEMENT_LIST',
    },
    MANAGEMENT_MODULES_COURSES: {
        translation: 'managementCourses',
        url: 'COURSE_MANAGEMENT_LIST',
    },
    MANAGEMENT_MODULES_VIDEOS: {
        translation: 'managementVideos',
        url: 'VIDEO_MANAGEMENT_LIST',
    },
    MANAGEMENT_MODULES_CERTIFICATES: {
        translation: 'managementCertificates',
        url: 'MANAGEMENT_MODULES_CERTIFICATES',
    },
    MANAGEMENT_USERS_USERS: {
        translation: 'managementUsers',
        url: 'USER_MANAGEMENT_LIST',
    },
    MANAGEMENT_USERS_GROUPS: {
        translation: 'managementGroups',
        url: 'GROUP_MANAGEMENT_LIST',
    },
    MANAGEMENT_USERS_ENROLLMENTS: {
        translation: 'managementEnrollments',
        url: 'MANAGEMENT_USERS_ENROLLMENTS',
    },
    MANAGEMENT_PORTFOLIO_REPORTING: {
        translation: 'managementReporting',
        url: 'TRAINING_REPORT',
    },
    MANAGEMENT_TRAINING_REPORTING: {
        translation: 'managementReporting',
        url: 'TRAINING_REPORT',
    },
    MANAGEMENT_USERS_REPORTING: {
        translation: 'managementReporting',
        url: 'MANAGEMENT_USERS_REPORTING',
    },

    MANAGEMENT_SETTINGS_LOCATIONS: {
        translation: 'managementLocations',
        url: 'MANAGEMENT_SETTINGS_LOCATIONS',
    },
    MANAGEMENT_SETTINGS_THEME: {
        translation: 'managementAcademy',
        url: 'ORGANISATION_SETTINGS',
    },
    MANAGEMENT_SETTINGS_OFFERS: {
        translation: 'managementOffers',
        url: 'OFFER_SETTINGS_EDIT',
    },
    MANAGEMENT_SETTINGS_FACETS: {
        translation: 'managementCategories',
        url: 'ORGANISATION_EXTRA_CATEGORIES',
    },
    MANAGEMENT_SETTINGS_PORTFOLIO: {
        translation: 'managementPortfolio',
        url: 'USER_PORTFOLIO_SETTINGS',
    },
    MANAGEMENT_SETTINGS_CALENDAR: {
        translation: 'managementCalendar',
        url: 'MANAGEMENT_SETTINGS_CALENDAR',
    },
    MANAGEMENT_SETTINGS_EMAILS: {
        translation: 'managementEmails',
        url: 'MANAGEMENT_SETTINGS_EMAILS',
    },
    MANAGEMENT_SETTINGS_CONNECTIONS: {
        translation: 'managementConnections',
        url: 'MANAGEMENT_SETTINGS_CONNECTIONS',
    },
    SINGLE_SIGN_ON: {
        translation: 'singleSignOnMenuItem',
        url: 'ORGANISATION_SINGLE_SIGN_ON',
    },
    MANAGEMENT_SETTINGS_HISTORY: {
        translation: 'managementHistory',
        url: 'MANAGEMENT_SETTINGS_HISTORY',
    },
    MANAGEMENT_SETTINGS_SKILLS: {
        translation: 'managementSkills',
        url: 'SKILL_MANAGEMENT_LIST',
    },
    MANAGEMENT_ORGANISATION_ORGANISATION: {
        translation: 'managementOrganisation',
        url: 'MANAGEMENT_ORGANISATION_ORGANISATION',
    },
    MANAGEMENT_ORGANISATION_SUBSCRIPTION: {
        translation: 'managementSubscription',
        url: 'MANAGEMENT_ORGANISATION_SUBSCRIPTION',
    },
    MANAGEMENT_ORGANISATION_PAYMENTDETAILS: {
        translation: 'managementPaymentDetails',
        url: 'MANAGEMENT_ORGANISATION_PAYMENTDETAILS',
    },
    MANAGEMENT_ORGANISATION_INVOICES: {
        translation: 'managementInvoices',
        url: 'MANAGEMENT_ORGANISATION_INVOICES',
    },
    PROFILE: {
        translation: 'profile',
        component: NavigationAvatar,
        url: 'PORTFOLIO',
    },
    PROFILE_PROFILE: {
        translation: 'profile',
        url: 'PROFILE_DRAWER',
    },
    PROFILE_INTRODUCTION: {
        translation: 'introduction',
        url: 'INTRO',
    },
    PROFILE_HELPDESK: {
        translation: 'helpdesk',
        href: 'http://helpdesk.pluvo.nl',
        target: '_blank',
    },
    PROFILE_LOGOUT: {
        translation: 'logout',
        url: 'LOGOUT',
    },
    OFFERS: {
        translation: 'offers',
        iconPath: mdiSchool,
        url: 'OFFER_LIST',
    },
    CREATE_MATERIAL: {
        translation: '',
        component: NavigationCreateMaterial,
        autoSize: true,
        isButton: true,
    },
};
