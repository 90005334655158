import { useTranslation } from 'react-i18next';

import { PageDrawer } from 'common/components/PageDrawer';
import {
    useOffersSettingsQuery,
    useUpdateOffersSettingsMutation,
    OffersSettingsDocument,
    IFileFragment,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getImageId } from 'common/utils/image';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    TOfferOverviewFormValues,
    OffersOverviewForm,
} from 'offer/components/forms/OffersOverviewForm';
import { useAppPermissions } from 'user/hooks/usePermissions';
import { PageTitle } from 'common/components/PageTitle';

type TFormValues = TOfferOverviewFormValues & { image?: IFileFragment };

interface IProps extends IPageDrawerProps {
    open?: boolean;
    onClose?: () => void;
}

export const OfferSettingsDrawer = ({ open, onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();

    const { canUpdate: canUpdateOfferSettings = false } = useAppPermissions(
        'offer',
        'management'
    );

    const { showApolloError } = useApolloError();

    const { data: offersSettingsData, loading: offersSettingsLoading } =
        useOffersSettingsQuery({
            skip: !canUpdateOfferSettings,
            onError: showApolloError,
        });
    const [updateOffersSettings, { loading: offersSettingsUpdateLoading }] =
        useUpdateOffersSettingsMutation();

    if (!canUpdateOfferSettings) return null;

    const { offersSettings } = offersSettingsData || {};

    const initialValues = {
        title: offersSettings?.title || '',
        titleColor: offersSettings?.titleColor?.replace('#', '') || '',
        description: offersSettings?.description || '',
        image: offersSettings?.image,
        publish: !!offersSettings?.publish,
        publishTraining: !!offersSettings?.publishTraining,
        publishElearning: !!offersSettings?.publishElearning,
        publishMeetingWebinar: !!offersSettings?.publishMeetingWebinar,
    };

    const handleSubmit = async (values: TFormValues) => {
        const { titleColor, image, ...otherValues } = values;
        const { image: currentImage } = offersSettings || {};

        const newValues = {
            titleColor: titleColor
                ? titleColor.includes('#')
                    ? titleColor
                    : `#${titleColor}`
                : undefined,
            imageId: getImageId(currentImage, image),
            ...otherValues,
        };

        try {
            await updateOffersSettings({
                variables: {
                    settings: newValues,
                },
                update: (cache, result) => {
                    const { offersSettings } =
                        result.data?.updateOffersSettings || {};

                    if (!offersSettings) return;

                    cache.writeQuery({
                        query: OffersSettingsDocument,
                        data: {
                            offersSettings: {
                                __typeName: 'OffersSettings',
                                ...offersSettings,
                            },
                        },
                    });
                },
            });
        } catch (error) {
            showApolloError(error);
        }

        displaySnackbar(translate('settingsUpdated'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    const loading = offersSettingsLoading || offersSettingsUpdateLoading;

    return (
        <PageDrawer open={open} onClose={onClose} {...other}>
            <PageTitle>{translate('offerSettingsDrawer.pageTitle')}</PageTitle>

            <OffersOverviewForm
                disabled={loading}
                initialValues={initialValues}
                loading={loading}
                onSubmit={handleSubmit}
            />
        </PageDrawer>
    );
};
