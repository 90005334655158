import { useTranslation } from 'react-i18next';
import { Box, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mdiSchool, mdiTransitConnectionVariant } from '@mdi/js';

import { TableCell, TableImage } from 'common/components/Table';
import { Chip, ChipGroup } from 'common/components/Chip';
import { ICourseListItemFragment } from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { Checkbox } from 'common/components/Checkbox';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { Icon } from 'common/components/Icon';
import { Tooltip } from 'common/components/Tooltip';

interface IProps {
    course: ICourseListItemFragment;
    isSelected: boolean;
    isEditable?: boolean;
    onSelect?: () => void;
}

export const CourseManagementTableRow = ({
    course,
    isEditable = true,
    isSelected,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();

    const {
        id,
        title,
        thumbnail,
        image,
        publishedVersionNumber,
        extraCategoryValues,
        trainingCount,
        offerEventCount,
    } = course;
    const courseImage = thumbnail?.url || image?.url || '';

    const versionChipLabel = translate(
        publishedVersionNumber ? 'version' : 'draft',
        { version: publishedVersionNumber }
    );

    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(getUrl('COURSE_EDIT', { id }))}
        >
            {isEditable && (
                <TableCell>
                    <Checkbox
                        checked={isSelected}
                        onClick={(e) => {
                            onSelect?.();

                            e.stopPropagation();
                        }}
                    />
                </TableCell>
            )}

            <TableCell sx={{ width: '1px' }}>
                <TableImage src={courseImage} />
            </TableCell>

            <TableCell>
                {title}

                <ChipGroup sx={{ my: 0.25 }}>
                    <Chip label={versionChipLabel} size="small" />
                    {!!trainingCount && (
                        <Tooltip
                            title={translate<string>('trainingCount', {
                                count: trainingCount,
                            })}
                        >
                            <Box>
                                <Chip
                                    icon={
                                        <Icon
                                            path={mdiTransitConnectionVariant}
                                            size="1.4rem"
                                        />
                                    }
                                    label={trainingCount}
                                    size="small"
                                />
                            </Box>
                        </Tooltip>
                    )}
                    {!!offerEventCount && (
                        <Tooltip
                            title={translate<string>('offerEventCount', {
                                count: offerEventCount,
                            })}
                        >
                            <Box>
                                <Chip
                                    icon={
                                        <Icon path={mdiSchool} size="1.4rem" />
                                    }
                                    label={offerEventCount}
                                    size="small"
                                />
                            </Box>
                        </Tooltip>
                    )}
                    {!!extraCategoryValues?.length && (
                        <ExtraCategoryLabels
                            chipProps={{ size: 'small' }}
                            extraCategoryValues={extraCategoryValues}
                        />
                    )}
                </ChipGroup>
            </TableCell>
        </TableRow>
    );
};
