import { useEffect, useMemo } from 'react';

import { IScormListItemFragment, useScormsQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { usePagination } from 'common/hooks/usePagination';
import { useScormFilter } from 'training/hooks/useScormFilter';

export const useScormList = () => {
    const { showApolloError } = useApolloError();

    const { filterValues, handleSearch: onSearch } = useScormFilter();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    // Build variables for query
    const variables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValues,
        }),
        [paginationVariables, filterValues]
    );

    const { loading, error, data } = useScormsQuery({
        variables,
        onError: showApolloError,
    });

    const scormsObject = data?.scorms;
    const scormsCount = scormsObject?.count;
    const scorms =
        (scormsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IScormListItemFragment[]) || [];

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(scormsCount || 0);
    }, [scormsCount, loading, initializePagination]);

    return {
        scorms,
        scormsCount,
        loading,
        error,
        setPage,
        paginationSettings,
        onSearch,
    };
};
