import { Box, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet';

import ERROR_IMAGE from 'assets/images/pluvo-error.webp';
import { Img } from 'common/components/Img';
import { ELoadFontWeight } from 'common/types';
import { useLoadFonts } from 'common/hooks/useLoadFonts';
import { BoxLoader } from 'common/components/Loader';

export const MaintenancePage = () => {
    const { fontsLoaded } = useLoadFonts([
        {
            family: 'Lato',
            weights: [ELoadFontWeight.Normal, ELoadFontWeight.Bold],
        },
    ]);

    // In maintenance mode we don't know the current user language, so we use browser language
    const userLang = navigator.language;
    let title = 'Academy Temporarily Unavailable';
    let text =
        "We are currently performing a brief update on our server. In approximately 15 minutes, you'll be able to use our services again. We apologize for the inconvenience and thank you for your understanding.";

    if (userLang.startsWith('nl')) {
        title = 'Academie tijdelijk niet beschikbaar';
        text =
            'We zijn momenteel bezig met een korte update aan onze server. Binnen ongeveer 15 minuten kun je weer gebruikmaken van onze diensten. Onze excuses voor het ongemak en bedankt voor je begrip.';
    }

    if (!fontsLoaded) return <BoxLoader />;

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                fontFamily: 'Lato, Helvetica, Arial, sans-serif',
            }}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container maxWidth="sm" sx={{ m: 'auto', py: 10, px: 5 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Box maxWidth={400} mb={4} mx="auto">
                        <Img src={ERROR_IMAGE} />
                    </Box>
                    <Box mb={2}>
                        <Typography
                            sx={{
                                fontSize: 26,
                                lineHeight: 1.2,
                                fontWeight: 700,
                                color: 'rgb(75, 83, 103)',
                            }}
                            variant="h1"
                        >
                            {title}
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            lineHeight: 1.3,
                            fontSize: 19,
                            color: 'rgb(75, 83, 103)',
                            fontWeight: 400,
                        }}
                        variant="h2"
                    >
                        {text}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};
