import { useMediaQuery, useTheme } from '@mui/material';

import { ApolloError } from 'common/components/ApolloError';
import {
    useMenuItemsQuery,
    useOrganisationQuery,
    IMainMenu,
} from 'graphql/types';

import { MobileNavigation } from './MobileNavigation';
import { Navigation } from './Navigation';

export const MainNavigation = () => {
    const { data, error } = useMenuItemsQuery();
    const { data: organisationData } = useOrganisationQuery();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const organisation = organisationData?.organisation;

    const hasIntroduction =
        !!organisation?.participantIntroImage ||
        !!organisation?.participantIntroTitle ||
        !!organisation?.participantIntroText;

    if (error) return <ApolloError error={error} />;

    if (!data?.menuItems?.length) return null;

    // Spread menuItems so it can be ordered
    const menuItems = [...data.menuItems];

    // Always make management upgrade plan and profileMenu the last ones, we use the indexes
    // to splice them and push them into the array.
    const managementMenuIndex = menuItems.findIndex(
        (item) => item.menu === IMainMenu.Management
    );

    menuItems.push(menuItems.splice(managementMenuIndex, 1)[0]);

    // If not mobile
    if (isSmUp) {
        const createMaterialMenuIndex = menuItems.findIndex(
            (item) => item.menu === IMainMenu.CreateMaterial
        );

        // Move button to the last position in navigation
        menuItems.push(menuItems.splice(createMaterialMenuIndex, 1)[0]);
    }

    const profileMenuIndex = menuItems.findIndex(
        (item) => item.menu === IMainMenu.Profile
    );

    menuItems.push(menuItems.splice(profileMenuIndex, 1)[0]);

    return (
        <>
            <Navigation
                hasIntroduction={hasIntroduction}
                menuItems={menuItems}
            />
            <MobileNavigation
                hasIntroduction={hasIntroduction}
                menuItems={menuItems}
            />
        </>
    );
};
