import { Box, TableRow } from '@mui/material';

import { Checkbox } from 'common/components/Checkbox';
import { LinearProgress } from 'common/components/LinearProgress';
import { Link } from 'common/components/Link';
import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { TUploadingFile } from 'common/hooks/useUploadFiles';
import { IBasicFileFragment } from 'graphql/types';

interface IProps {
    uploadingFiles: TUploadingFile[];
    customFontFiles: IBasicFileFragment[];
}

export const UploadingFileRows = ({
    uploadingFiles,
    customFontFiles,
}: IProps) => (
    <>
        {uploadingFiles.map((uploadingFile, index) => {
            const { name, status, file } = uploadingFile;

            const isAlreadyUploaded = !!customFontFiles.filter(
                (item) => file?.id === item.id
            ).length;

            if (isAlreadyUploaded) return null;

            const { done, success, progress, errorMessage } = status;

            if (done && !success) return null;

            let titleColumn: React.ReactNode = name;
            const fileName = file?.name || name || '';

            if (progress < 100) {
                titleColumn = (
                    <LinearProgress color="primary" value={progress} />
                );
            }

            if (file) {
                titleColumn = (
                    <Link href={file.url || ''} target="_blank">
                        {fileName}
                    </Link>
                );
            }

            if (done && !success) {
                titleColumn = (
                    <Typography component="div">
                        {fileName}

                        <Box mt={1}>
                            <Typography color="error" component="div">
                                {errorMessage}
                            </Typography>
                        </Box>
                    </Typography>
                );
            }

            return (
                <TableRow key={`uploadingFile:${index}`}>
                    <TableCell padding="checkbox">
                        <Checkbox disabled />
                    </TableCell>

                    <TableCell>{titleColumn}</TableCell>

                    <TableCell>-</TableCell>

                    <TableCell>-</TableCell>

                    <TableCell />
                </TableRow>
            );
        })}
    </>
);
