import { Box, DialogContentText, FormHelperText, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import {
    IContentTypeValue,
    IExtraCategoryOption,
    IGroupListItemFragment,
    useExtraCategoriesQuery,
    useExtraCategoryFiltersQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { Loader } from 'common/components/Loader';
import { getExtraCategoryFilterItems } from 'organisation/utils/extraCategory';
import { getSelectedGroupConditionFilters } from 'user/utils/groups';
import { AddButton } from 'common/components/Button/AddButton';
import { IGroupConditionInput } from 'user/types';
import { TFilterBarItem } from 'common/types';
import { GroupSelectDrawer } from 'user/components/GroupSelectDrawer';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';

import { GroupConditionListItem } from './GroupConditionListItem';

interface IProps {
    error?: string;
    groupConditionInputs?: IGroupConditionInput[];
}

export const GroupConditionFilter = ({
    error,
    groupConditionInputs,
}: IProps) => {
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const { data: extraCategoriesData, loading: extraCategoriesLoading } =
        useExtraCategoriesQuery({
            variables: { contentType: IContentTypeValue.Group },
            onError: showApolloError,
        });

    const {
        data: extraCategoryFiltersData,
        loading: extraCategoryFiltersLoading,
    } = useExtraCategoryFiltersQuery({
        variables: { contentType: IContentTypeValue.Group },
        onError: showApolloError,
    });
    const [selectedFilterIndex, setSelectedFilterIndex] = useState<
        number | undefined
    >();
    const [selectDrawerOpen, setSelectDrawerOpen] = useState(false);
    const { setFieldValue } = useFormikContext();
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState<
        IGroupListItemFragment[]
    >([]);

    const { extraCategories } = extraCategoriesData || {};
    const { extraCategoriesFilters } = extraCategoryFiltersData || {};

    // Only use choice type filters
    const filteredCategoryFilters = extraCategoriesFilters?.filter(
        (categoryFilter) =>
            categoryFilter.categoryType === IExtraCategoryOption.Choice
    );

    const loading = extraCategoriesLoading || extraCategoryFiltersLoading;

    const handleSelectGroup = (group: IGroupListItemFragment) => {
        const newGroups = [...selectedGroups, group].sort((a, b) =>
            a.name > b.name ? 1 : -1
        );

        setSelectedGroups(newGroups);
    };

    const handleAddGroups = (
        selectedGroups: IGroupListItemFragment[],
        selectedFilters: TFilterBarItem[]
    ) => {
        // If existing group condition is being edited, replace it with new values
        if (selectedFilterIndex !== undefined && groupConditionInputs?.length) {
            const newGroupConditionInputs = [...groupConditionInputs];

            newGroupConditionInputs[selectedFilterIndex] = {
                ...groupConditionInputs[selectedFilterIndex],
                extraCategoryValues: selectedFilters.map(
                    ({ extraCategoryData, value }) => {
                        const extraCategory = extraCategories?.find(
                            (extraCategory) =>
                                extraCategory.id === extraCategoryData?.id
                        );
                        const color = extraCategory?.choiceOptions?.find(
                            (option) => option.stringValue === value
                        )?.color;

                        return {
                            id: extraCategoryData?.id,
                            stringValue: value,
                            color,
                        };
                    }
                ),
                groups: selectedGroups,
            };

            setFieldValue('groupConditions', newGroupConditionInputs);
        } else {
            const newGroupConditionInput = {
                extraCategoryValues: selectedFilters.map(
                    ({ extraCategoryData, value }) => {
                        const extraCategory = extraCategories?.find(
                            (extraCategory) =>
                                extraCategory.id === extraCategoryData?.id
                        );
                        const color = extraCategory?.choiceOptions?.find(
                            (option) => option.stringValue === value
                        )?.color;

                        return {
                            id: extraCategoryData?.id,
                            stringValue: value,
                            color,
                        };
                    }
                ),
                groups: selectedGroups,
            };

            setFieldValue(
                'groupConditions',
                groupConditionInputs?.length
                    ? [...groupConditionInputs, newGroupConditionInput]
                    : [newGroupConditionInput]
            );
        }

        setSelectedFilterIndex(undefined);
        setSelectDrawerOpen(false);
    };

    const handleClickDelete = (groupConditionIndex: number) => {
        const newGroupConditions = groupConditionInputs?.filter(
            (_groupConditionInput, index) => index !== groupConditionIndex
        );

        setFieldValue('groupConditions', newGroupConditions);
    };

    const addedGroups = useMemo(() => {
        if (
            selectedFilterIndex === undefined ||
            !groupConditionInputs?.length
        ) {
            return [];
        }

        return groupConditionInputs[selectedFilterIndex].groups;
    }, [selectedFilterIndex, groupConditionInputs]);

    const selectedFilters = useMemo(() => {
        if (
            selectedFilterIndex === undefined ||
            !groupConditionInputs?.length
        ) {
            return [];
        }

        const { extraCategoryValues } =
            groupConditionInputs[selectedFilterIndex];

        return getSelectedGroupConditionFilters(
            extraCategoryValues,
            getExtraCategoryFilterItems(filteredCategoryFilters)
        );
    }, [selectedFilterIndex, groupConditionInputs, extraCategoriesFilters]);

    const drawerOpen = selectDrawerOpen || selectedFilterIndex !== undefined;

    return (
        <Box>
            {loading && (
                <Box sx={{ height: 40, position: 'relative', mb: 2 }}>
                    <Loader />
                </Box>
            )}

            {!loading && (
                <>
                    <AddButton
                        onClick={() => {
                            // Clear selected groups when adding a new group condition
                            setSelectedGroups([]);

                            setSelectDrawerOpen(true);
                        }}
                    >
                        {translate('groupConditionFilter.addButton')}
                    </AddButton>

                    {error && (
                        <FormHelperText error sx={{ mt: 2 }}>
                            {error}
                        </FormHelperText>
                    )}

                    {!!groupConditionInputs?.length && (
                        <Stack spacing={2} sx={{ mt: 2 }}>
                            {groupConditionInputs.map(
                                (groupConditionInput, index) => {
                                    const { extraCategoryValues, groups } =
                                        groupConditionInput;

                                    // If there is a group that the user does not have permission to update (is group manager of),
                                    // the user cannot delete the group condition
                                    const canDelete = !groups.some(
                                        ({ permissions }) => {
                                            const groupPermissions = permissions
                                                ? JSON.parse(permissions)
                                                : undefined;

                                            return (
                                                !groupPermissions ||
                                                !groupPermissions.canUpdate
                                            );
                                        }
                                    );

                                    return (
                                        <GroupConditionListItem
                                            extraCategoryValues={
                                                extraCategoryValues
                                            }
                                            groups={groups}
                                            key={index}
                                            onClickDelete={() => {
                                                if (canDelete) {
                                                    handleClickDelete(index);

                                                    return;
                                                }

                                                setShowOnDeleteAlert(true);
                                            }}
                                            onClickEdit={() => {
                                                const selected =
                                                    groupConditionInputs[index]
                                                        .groups;

                                                // Set the groups of the currently editing group condition
                                                if (selected) {
                                                    setSelectedGroups(selected);
                                                }

                                                setSelectedFilterIndex(index);
                                            }}
                                        />
                                    );
                                }
                            )}
                        </Stack>
                    )}

                    <AlertDialog
                        actions={
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setShowOnDeleteAlert(false);
                                }}
                            >
                                {translate('close')}
                            </Button>
                        }
                        open={showOnDeleteAlert}
                        title={translate(
                            'offerEventForm.cantDeleteGroupCondition.title'
                        )}
                    >
                        <DialogContentText color="text.primary" variant="body2">
                            {translate(
                                'offerEventForm.cantDeleteGroupCondition.text'
                            )}
                        </DialogContentText>
                    </AlertDialog>

                    <GroupSelectDrawer
                        addedGroups={addedGroups}
                        initialSelectedFilters={selectedFilters}
                        open={drawerOpen}
                        selectedGroups={selectedGroups}
                        setSelectedGroups={setSelectedGroups}
                        onAddGroups={(groups) =>
                            handleAddGroups(groups || [], selectedFilters)
                        }
                        onClose={() => {
                            setSelectedFilterIndex(undefined);
                            setSelectDrawerOpen(false);
                        }}
                        onRemoveGroup={(group) => {
                            setSelectedGroups(
                                selectedGroups.filter(
                                    (selectedGroup) =>
                                        selectedGroup.id !== group.id
                                )
                            );
                        }}
                        onSelectGroup={handleSelectGroup}
                    />
                </>
            )}
        </Box>
    );
};
