import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import {
    IOpenIdConnectionFragment,
    useDeleteOpenIdConnectionMutation,
    useOpenIdConnectionLazyQuery,
    useUpdateOpenIdConnectionMutation,
} from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { TOpenIdConnectionFormValues } from 'connections/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

import { OpenIdConnectionForm } from '../forms/OpenIdConnectionForm';

interface IProps extends IPageDrawerProps {
    openIdConnectionId?: string;
    onClose?(): void;
}

export const OpenIdConnectionUpdateDrawer = ({
    openIdConnectionId,
    onClose,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [fetchOpenIdConnection, { data, loading: loadingOpenIdConnection }] =
        useOpenIdConnectionLazyQuery();
    const [updateOpenIdConnection, { loading: loadingUpdate }] =
        useUpdateOpenIdConnectionMutation();
    const [deleteOpenIdConnection] = useDeleteOpenIdConnectionMutation();

    const { openIdConnection } = data || {};

    useEffect(() => {
        if (!openIdConnectionId) return;

        fetchOpenIdConnection({ variables: { id: openIdConnectionId } });
    }, [openIdConnectionId, fetchOpenIdConnection]);

    const handleSubmit = async (values: TOpenIdConnectionFormValues) => {
        const { ltiAuthenticateUrl, ltiLaunchUrl, ...otherValues } = values;

        try {
            await updateOpenIdConnection({
                variables: { id: openIdConnectionId, openId: otherValues },
            });

            displaySnackbar(translate('openIdConnectionActionSuccess.update'), {
                variant: 'success',
            });

            onClose?.();
        } catch (error) {
            showApolloError(error);

            return;
        }
    };

    const handleDelete = async () => {
        if (!openIdConnection) return;

        try {
            await deleteOpenIdConnection({
                variables: { id: openIdConnectionId },
                update: (cache) => {
                    cache.modify({
                        fields: {
                            openIdConnections(
                                existingConnections = [],
                                { readField }
                            ) {
                                return existingConnections.filter(
                                    (
                                        connectionRef: IOpenIdConnectionFragment
                                    ) =>
                                        openIdConnectionId !==
                                        readField('id', connectionRef)
                                );
                            },
                        },
                    });
                },
            });

            displaySnackbar(translate('openIdConnectionActionSuccess.delete'), {
                variant: 'success',
            });

            onClose?.();
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const connection =
        openIdConnection && openIdConnection ? openIdConnection : undefined;

    return (
        <PageDrawer onClose={onClose} {...other}>
            <PageTitle mixpanelTitle="Drawer Open ID connection form">
                {translate('openIdConnectionForm.pageTitle')}
            </PageTitle>

            {loadingOpenIdConnection && <Loader />}

            {!loadingOpenIdConnection && (
                <OpenIdConnectionForm
                    disabled={loadingUpdate}
                    openIdConnection={connection}
                    onDelete={handleDelete}
                    onSubmit={handleSubmit}
                />
            )}
        </PageDrawer>
    );
};
