import { useTranslation } from 'react-i18next';
import { mdiClipboardArrowDown, mdiAccountMultiple } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { Icon } from 'common/components/Icon';
import {
    IAssignmentModuleListItemFragment,
    IAssignmentType,
} from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import {
    ListItem,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { IconButton } from 'common/components/IconButton';
import { getUrl } from 'route/utils/getUrl';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const AssignmentModule = ({
    canManage,
    canUpdate,
    canManageParticipants,
    currentUserIsAuthor,
    currentUserIsTrainer,
    groupConditionEffect,
    disabled,
    trainingId,
    portfolioItem,
    hasProgress,
    ...moduleItem
}: TTrainingModuleProps<IAssignmentModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const studentView = useReactiveVar(trainingStudentView);

    const {
        id,
        title,
        description,
        conditions,
        alwaysOpen,
        startDate,
        image,
        conditionEffect,
        weight,
        assignmentType,
    } = moduleItem;

    const itemContent = (
        <>
            <ListItemMedia color="primary" image={image?.url || undefined}>
                <Icon
                    path={mdiClipboardArrowDown}
                    size="1.8rem"
                    sizeSm="3rem"
                />
            </ListItemMedia>
            <ListItemText primary={title} secondary={description}>
                <ModuleConditionsChips
                    alwaysOpen={alwaysOpen}
                    conditionEffect={conditionEffect}
                    conditions={conditions}
                    groupConditionEffect={groupConditionEffect}
                    startDate={startDate}
                />
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    const assignmentButton = canManageParticipants &&
        (!studentView || assignmentType === IAssignmentType.Individual) && (
            <ListItemSecondaryAction sx={{ mr: { xs: -1.75, sm: 0 } }}>
                <IconButton
                    iconPath={mdiAccountMultiple}
                    iconSize="2.4rem"
                    size="large"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();

                        navigate(
                            getUrl('TRAINING_ASSIGNMENT_GRADE', {
                                trainingId,
                                moduleId: id,
                            })
                        );
                    }}
                />
            </ListItemSecondaryAction>
        );

    if (!studentView && canUpdate) {
        return (
            <ListItem
                button
                component={Link}
                params={{
                    trainingId,
                    moduleId: id,
                    type: 'assignment',
                }}
                to="TRAINING_UPDATE_MODULE"
            >
                {itemContent}

                {assignmentButton}

                <ModuleSecondaryAction
                    forUpdate
                    hasProgress={!!hasProgress}
                    portfolioItem={portfolioItem}
                    weight={weight}
                />
            </ListItem>
        );
    }

    return (
        <ListItem
            button
            component={Link}
            params={{
                trainingId,
                moduleId: id,
            }}
            to="TRAINING_ASSIGNMENT"
        >
            {itemContent}

            {assignmentButton}

            <ModuleSecondaryAction
                actionText={!assignmentButton ? translate('open') : undefined}
                hasProgress={!!hasProgress}
                portfolioItem={portfolioItem}
                weight={weight}
            />
        </ListItem>
    );
};
