import { mdiChevronRight, mdiMinus, mdiPlus, mdiShieldCheck } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { ICertificateTypeListItemFragment } from 'graphql/types';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    selected?: boolean;
    certificate: ICertificateTypeListItemFragment;
    onClick?(certificate: ICertificateTypeListItemFragment): void;
}

export const CertificateSelectorListItem = ({
    addButton,
    added,
    disabled,
    selected,
    certificate,
    onClick,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { id, name, thumbnail } = certificate || {};

    let mediaIconPath = mdiShieldCheck;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }

    return (
        <ListItem
            button={!disabled}
            key={id}
            px={0}
            selected={selected}
            onClick={() => !disabled && onClick?.(certificate)}
            {...other}
        >
            <ListItemMedia
                color="primary"
                image={thumbnail?.url || ''}
                size="small"
            >
                <Icon path={mediaIconPath} size="3rem" />
            </ListItemMedia>

            <ListItemText primary={name} />

            {!addButton && !disabled && (
                <>
                    <ListItemActionText>{translate('open')}</ListItemActionText>
                    <ListItemSecondaryAction>
                        <Icon path={mdiChevronRight} size="3rem" />
                    </ListItemSecondaryAction>
                </>
            )}
        </ListItem>
    );
};
