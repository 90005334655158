import {
    ICourseListItemFragment,
    ICoursesQueryVariables,
    useCoursesQuery,
} from 'graphql/types';

export const useCourseList = (courseQueryVariables: ICoursesQueryVariables) => {
    const { loading, error, data } = useCoursesQuery({
        variables: {
            q: courseQueryVariables.q,
            offset: courseQueryVariables.offset,
            ...courseQueryVariables,
        },
    });

    const coursesObject = data?.courses;
    const coursesCount = coursesObject?.count;
    const courses =
        (coursesObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as ICourseListItemFragment[]) || [];

    return {
        courses,
        coursesCount,
        loading,
        error,
    };
};
