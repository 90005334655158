/*
 * Gets the query params except the q from the url and creates an object entry
 * for each value
 */
export function getQueryParams(): {
    [key: string]: string | string[];
} {
    const queryParams = window.location.search;

    if (!queryParams) return {};

    // Split the query params by ampersand
    // Value ampersands are encoded so not influenced by the split
    const queryArray = queryParams.replace('?', '').split('&');

    return queryArray.reduce(
        (acc: { [key: string]: string | string[] }, val) => {
            const splittedVal = val.split('=');
            const key = splittedVal[0];
            const value = splittedVal[1];

            if (!value) return acc;

            const splittedValue = value.split(',');
            const decodedKey = decodeURIComponent(key);

            acc[decodedKey] = Array.isArray(splittedValue)
                ? splittedValue.map((item) => decodeURIComponent(item))
                : decodeURIComponent(splittedValue);

            return acc;
        },
        {}
    );
}
