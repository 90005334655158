import { SxProps, ButtonProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

import { Button } from '../Button';

const buttonStyle: SxProps = {
    color: 'text.primary',

    '&:hover': {
        backgroundColor: 'error.main',
        color: 'white',
    },
};

export const DeleteButton = ({ sx, ...other }: ButtonProps) => (
    <Button sx={[buttonStyle, ...withSxProp(sx)]} {...other} />
);
