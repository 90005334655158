import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    CardSubtitle,
    CardTitle,
} from 'common/components/Card';
import { OFFER_EVENT_TYPE_ICONS } from 'offer/constants/offerEvent';
import { getOfferEventTypeTranslation } from 'offer/utils/offerEvent';
import DEFAULT_OFFERCARD from 'assets/images/default-offercard.webp';
import { IOfferEventCardFragment, IOfferEventType } from 'graphql/types';
import { getOfferDateDisplay } from 'offer/utils/dates';
import { ChipGroup, IconChip } from 'common/components/Chip';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { Typography } from 'common/components/Typography';

interface IProps {
    offerEvent: IOfferEventCardFragment;
    onClick: (offerEvent: IOfferEventCardFragment) => void;
}

export const OfferEventCard = ({ offerEvent, onClick }: IProps) => {
    const {
        title,
        subtitle,
        image,
        firstDate,
        extraCategoryStringLabels,
        type,
        dates,
        hasDetailPage,
        extraTypes,
    } = offerEvent;

    const isMeetingWebinar =
        type === IOfferEventType.Meeting || type === IOfferEventType.Webinar;

    let cardSubtitle;

    const date = firstDate || dates?.[0];

    if (isMeetingWebinar && !!date) {
        const dateDisplay = getOfferDateDisplay(
            date,
            dates?.length || 0,
            true,
            true
        );

        cardSubtitle = <CardSubtitle>{dateDisplay}</CardSubtitle>;
    }

    return (
        <Card>
            <CardActionArea onClick={() => onClick(offerEvent)}>
                <CardMedia image={image?.url || DEFAULT_OFFERCARD} />

                <CardContent>
                    {hasDetailPage && !!subtitle && (
                        <Typography
                            sx={{
                                color: 'primary.main',
                                fontSize: '1.4rem',
                                fontWeight: 700,
                                lineHeight: '1.2',
                            }}
                        >
                            {subtitle}
                        </Typography>
                    )}

                    <CardTitle>{title}</CardTitle>
                    {cardSubtitle}
                </CardContent>

                <CardActions>
                    <ChipGroup>
                        {extraTypes?.map((extraType) => (
                            <IconChip
                                iconPath={OFFER_EVENT_TYPE_ICONS[extraType]}
                                key={extraType}
                                tooltipTitle={getOfferEventTypeTranslation(
                                    extraType
                                )}
                            />
                        ))}

                        <ExtraCategoryLabels
                            extraCategoryValues={extraCategoryStringLabels}
                        />
                    </ChipGroup>
                </CardActions>
            </CardActionArea>
        </Card>
    );
};
