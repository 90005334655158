import { Box, useTheme } from '@mui/material';

import { Chip, IChipProps } from 'common/components/Chip';
import { Tooltip } from 'common/components/Tooltip';
import {
    IExtraCategoryChoiceValue,
    IExtraCategoryLabel,
    IExtraCategoryOption,
    IExtraCategoryStringValue,
    IExtraCategoryValueFragment,
} from 'graphql/types';

interface IProps {
    extraCategoryValues: (IExtraCategoryValueFragment | IExtraCategoryLabel)[];
    chipProps?: IChipProps;
}

export const ExtraCategoryLabels = ({
    extraCategoryValues,
    chipProps,
}: IProps) => {
    const theme = useTheme();

    if (!extraCategoryValues.length) return null;

    if (extraCategoryValues[0].__typename === 'ExtraCategoryLabel') {
        return (
            <>
                {extraCategoryValues.map((extraCategoryValue) => {
                    const { categoryName, value, color } =
                        extraCategoryValue as IExtraCategoryLabel;

                    return (
                        <Tooltip
                            key={`${categoryName}-${value}`}
                            title={categoryName}
                        >
                            <Box>
                                <Chip
                                    key={`${categoryName}-${value}`}
                                    label={value}
                                    sx={{
                                        backgroundColor: color,
                                        color:
                                            color &&
                                            theme.palette.getContrastText(
                                                color
                                            ),
                                    }}
                                    {...chipProps}
                                />
                            </Box>
                        </Tooltip>
                    );
                })}
            </>
        );
    }

    return (
        <>
            {(extraCategoryValues as IExtraCategoryValueFragment[])
                .filter(
                    ({ category }) =>
                        category.categoryType !==
                            IExtraCategoryOption.Boolean &&
                        category.categoryType !==
                            IExtraCategoryOption.Datetime &&
                        category.showLabel
                )
                .map((extraCategoryValue) => {
                    const { category } = extraCategoryValue;
                    const {
                        id,
                        categoryType,
                        color: categoryColor,
                        name,
                    } = category;

                    if (categoryType === IExtraCategoryOption.String) {
                        const label = (
                            extraCategoryValue as IExtraCategoryStringValue
                        ).stringValue;

                        return (
                            <Tooltip key={`${id}-${label}`} title={name}>
                                <Box>
                                    <Chip
                                        label={label}
                                        sx={{
                                            backgroundColor: categoryColor,
                                            color: categoryColor
                                                ? theme.palette.getContrastText(
                                                      categoryColor
                                                  )
                                                : '',
                                        }}
                                        {...chipProps}
                                    />
                                </Box>
                            </Tooltip>
                        );
                    } else {
                        const { choiceValue } =
                            extraCategoryValue as IExtraCategoryChoiceValue;

                        if (!choiceValue) return null;

                        const { id, stringValue, color } = choiceValue;

                        return (
                            <Tooltip key={id} title={name}>
                                <Box>
                                    <Chip
                                        label={stringValue}
                                        sx={{
                                            backgroundColor: color,
                                            color: color
                                                ? theme.palette.getContrastText(
                                                      color
                                                  )
                                                : '',
                                        }}
                                        {...chipProps}
                                    />
                                </Box>
                            </Tooltip>
                        );
                    }
                })}
        </>
    );
};
