import { useTranslation } from 'react-i18next';
import { mdiGift } from '@mdi/js';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'common/components/Icon';
import { getUrl } from 'route/utils/getUrl';

import { Button } from '../Button';

export const UpgradePlanButton = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    return (
        <Button
            color="primary"
            startIcon={<Icon path={mdiGift} size="1.9rem" />}
            sx={{
                pl: 3.5,
                py: 1.5,
                borderRadius: 5,
            }}
            variant="contained"
            onClick={() => navigate(getUrl('PLANS'))}
        >
            {translate('plans.upgrade')}
        </Button>
    );
};
