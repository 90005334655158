import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { mdiFolderRemove } from '@mdi/js';

import { usePagination } from 'common/hooks/usePagination';
import { useGroupQueryVariables, useGroupSearch } from 'user/hooks/group';
import { useGroupList } from 'user/hooks/group/useGroupList';
import {
    TableActionBar,
    TableCell,
    TableHead,
    useSelection,
} from 'common/components/Table';
import { FilterBar } from 'common/components/FilterBar';
import { Typography } from 'common/components/Typography';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import { Pagination } from 'common/components/Pagination';
import { Loader } from 'common/components/Loader';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import {
    IGroupManagementListItemFragment,
    useDeleteGroupsMutation,
} from 'graphql/types';
import { TFilterBarItem } from 'common/types';
import { Checkbox } from 'common/components/Checkbox';

import { GroupsManagementTableRow } from './GroupsManagementTableRow';

interface IProps {
    onLoading?(loading: boolean): void;
}

export const GroupsManagementTable = ({ onLoading }: IProps) => {
    const [translate] = useTranslation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [deleteGroups, { loading: loadingDeleteGroups }] =
        useDeleteGroupsMutation();
    const {
        filters,
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useGroupSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { groupQueryVariables } = useGroupQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        groups,
        groupsCount,
        loading: loadingGroups,
    } = useGroupList(groupQueryVariables);

    const loading = loadingGroups || loadingDeleteGroups;

    // Determines which groups are selectable
    const selectFilter = (item: IGroupManagementListItemFragment) =>
        item.isEditable;

    const { ...selectionProps } = useSelection(groups, selectFilter);
    const { isSelected, rows, selected, onSelectAll } = selectionProps;

    useEffect(() => {
        onLoading?.(loading);
    }, [loading, onLoading]);

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(groupsCount || 0);
    }, [groupsCount, loading, initializePagination]);

    const resetSettings = () => {
        onSelectAll();
        setPage(1);
        handleSearch('');
        handleFilterSelect([]);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);

        if (!!selected?.length) onSelectAll?.();
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);

        if (!!selected?.length) onSelectAll?.();
    };

    const handleDelete = async () => {
        setShowDeleteDialog(false);

        try {
            await deleteGroups({
                variables: { ids: selected },
                update: (cache) => {
                    // Remove all deleted groups from cache to make sure they are gone throughout the system
                    selected.forEach((groupId) => {
                        cache.evict({ id: `Group:${groupId}` });
                    });

                    cache.evict({ fieldName: 'paginatedGroups' });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        resetSettings();

        displaySnackbar(
            translate('groupsManagementPage.groupsActionSuccess.bulkDelete'),
            {
                autoHideDuration: 30000,
                variant: 'success',
            }
        );
    };

    const tableActions = (
        <Tooltip title={translate('groupsManagementPage.deleteGroups')}>
            <Box>
                <IconButton
                    color="inherit"
                    disabled={!isSelected}
                    iconPath={mdiFolderRemove}
                    iconSize="2.4rem"
                    onClick={() => setShowDeleteDialog(true)}
                />
            </Box>
        </Tooltip>
    );

    return (
        <>
            <FilterBar
                disabled={loading}
                filters={filters}
                initialSearchValue={searchQueryParam}
                initialSelected={selectedFilters}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
                onSelect={handleFilterSelect}
            />

            {!loading && !!rows.length && (
                <TableContainer>
                    <TableActionBar
                        actions={tableActions}
                        selectionProps={selectionProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={false}
                                        onChange={onSelectAll}
                                    />
                                </TableCell>
                                <TableCell>{translate('name')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const group = groups[index] || null;

                                if (!group) return null;

                                return (
                                    <GroupsManagementTableRow
                                        group={group}
                                        isSelected={isSelected}
                                        key={group.id}
                                        onSelect={onSelect}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '100px', mt: 4 }}>
                    <Loader />
                </Box>
            )}

            {!loading && !rows.length && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Typography>{translate('noOptionsText.groups')}</Typography>
                </Box>
            )}

            {!loading && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={
                            paginationSettings.count === 1
                                ? translate('group')
                                : translate('groups')
                        }
                        onChange={(page: number) => {
                            setPage(page);
                        }}
                    />
                </Box>
            )}

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDeleteGroups}
                open={showDeleteDialog}
                title={translate(
                    'groupsManagementPage.groupsDeleteDialog.title'
                )}
                onCancel={() => {
                    setShowDeleteDialog(false);
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
            >
                {translate('groupsManagementPage.groupsDeleteDialog.text')}
            </ConfirmDialog>
        </>
    );
};
