import { useEffect, useState, useMemo } from 'react';

import {
    usePortfolioReportQuery,
    IPortfolioReportSortByInput,
    IPortfolioReportOrderField,
    ISortDirection,
    IPortfolioReportQueryVariables,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { usePagination } from 'common/hooks/usePagination';
import { TReportType } from 'report/types';
import { useReportFilter, useReportQueryFilterValues } from 'report/hooks';

export const useReportList = (
    type: TReportType,
    skip?: boolean,
    extraVariables?: Partial<IPortfolioReportQueryVariables>
) => {
    const { showApolloError } = useApolloError();
    const { filters, queryFilterValues, setQueryFilterValues } =
        useReportQueryFilterValues(type);

    const [sortBy, setSortBy] = useState<IPortfolioReportSortByInput>({
        field: IPortfolioReportOrderField.StartDate,
        direction: ISortDirection.Desc,
    });

    const {
        filterValues,
        filterValuesForQuery,
        onFilterChangeValue,
        handleSearch: onSearch,
        handleFilterChange: onFilterChange,
    } = useReportFilter(filters, type);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const onSort = (sortField: IPortfolioReportOrderField) => {
        const currentSortField = sortBy.field;

        let newDirection = ISortDirection.Asc;

        // If given field is the same as the current sort, we want to toggle the direction
        if (sortField === currentSortField) {
            switch (sortBy.direction) {
                case ISortDirection.Asc:
                    newDirection = ISortDirection.Desc;
                    break;
                case ISortDirection.Desc:
                    newDirection = ISortDirection.Asc;
                    break;
            }
        }
        setSortBy({ field: sortField, direction: newDirection });
    };

    // Build variables for query
    const variables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
            ...extraVariables,
            sortBy,
        }),
        [paginationVariables, filterValuesForQuery, queryFilterValues]
    );

    const { loading, data } = usePortfolioReportQuery({
        variables,
        skip,
        onError: showApolloError,
        fetchPolicy: 'network-only',
    });

    const portfolioReport = data?.portfolioReport;
    const items =
        portfolioReport?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) || [];

    const count = portfolioReport?.count || 0;
    const reportQueryFilterValues = portfolioReport?.filterValues || undefined;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(count || 0);
    }, [count, loading, initializePagination]);

    // Update query filter values whenever there is a change in the reportQueryFilterValues
    useEffect(() => {
        if (
            (!reportQueryFilterValues && !queryFilterValues) ||
            !reportQueryFilterValues ||
            JSON.stringify(reportQueryFilterValues) ===
                JSON.stringify(queryFilterValues)
        ) {
            return;
        }

        setQueryFilterValues(reportQueryFilterValues);
    }, [reportQueryFilterValues, queryFilterValues]);

    return {
        items,
        count,
        loading,
        onSearch,
        onFilterChange,
        onFilterChangeValue,
        filters,
        filterValues,
        onSort,
        currentSort: sortBy,
        setPage,
        paginationSettings,
        filterValuesForQuery,
        sortBy,
    };
};
