import { Box } from '@mui/material';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';

import PORTFOLIO from 'assets/images/portfolio_no_result.webp';
import DEVELOPMENT_PLAN from 'assets/images/development_plan_no_result.webp';
import REGISTRATIONS from 'assets/images/registrations.webp';
import NO_TRAININGS from 'assets/images/no-trainings.webp';
import NO_OFFERS from 'assets/images/no-offers.webp';
import NO_HOMEPAGE_RESULTS from 'assets/images/no-homepage-results.webp';
import { Typography } from 'common/components/Typography';

const Wrapper = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    text-align: center;
`;

const Image = styled.img`
    width: 100%;
    max-width: 400px;
    margin-bottom: 40px;
`;

type TContent = {
    [key: string]: {
        image: string;
        translationKey: string;
    };
};

const CONTENT: TContent = {
    HOMEPAGE_RESULTS: {
        image: NO_HOMEPAGE_RESULTS,
        translationKey: 'noResults.homepage',
    },
    TRAININGS_MANAGEMENT: {
        image: NO_TRAININGS,
        translationKey: 'noResults.trainingsManagement',
    },
    USER: {
        image: PORTFOLIO,
        translationKey: 'noResults.portfolio',
    },
    USER_DEVELOPMENT_PLAN: {
        image: DEVELOPMENT_PLAN,
        translationKey: 'noResults.developmentPlan',
    },
    USER_REGISTRATIONS: {
        image: REGISTRATIONS,
        translationKey: 'noResults.registrations',
    },
    LIBRARY_EMPTY_SEARCH: {
        image: NO_TRAININGS,
        translationKey: 'libraryEmptySearch',
    },
    LIBRARY_SEARCH: {
        image: NO_TRAININGS,
        translationKey: 'noResults.library',
    },
    OFFERS: {
        image: NO_OFFERS,
        translationKey: 'noResults.offers',
    },
    COURSE_TEMPLATES: {
        image: NO_TRAININGS,
        translationKey: 'noResults.courseTemplates',
    },
};

interface IProps {
    translateObject?: { [key: string]: string };
    variant: string;
}

export const NoResults = ({ translateObject, variant }: IProps) => {
    const { t: translate } = useTranslation();

    const message = CONTENT[variant];

    if (!message) return null;
    const { image, translationKey } = message;

    return (
        <Wrapper>
            <Image src={image} />
            <Typography variant="h4">
                {translate(translationKey, translateObject)}
            </Typography>
        </Wrapper>
    );
};
