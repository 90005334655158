import {
    useTheme,
    useMediaQuery,
    SxProps,
    TableRow as MuiTableRow,
    TableRowProps,
} from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import { forwardRef } from 'react';

const tableRowMediaStyle: SxProps = {
    cursor: 'pointer',
};

interface IProps extends TableRowProps {
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?(): void;
}

const TableRow = forwardRef<HTMLTableRowElement, IProps>((props, ref) => (
    <MuiTableRow ref={ref} {...props} />
));

const MotionTableRowMedia = motion.create(TableRow);

export const TableRowMedia = ({ disabled, ...other }: IProps & MotionProps) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <MotionTableRowMedia
            initial="initItemButton"
            sx={[
                tableRowMediaStyle,
                {
                    pointerEvents: disabled ? 'none' : 'initial',
                },
            ]}
            whileHover={isSm ? 'hoverItemButton' : undefined}
            {...other}
        />
    );
};
