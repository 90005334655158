import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Trans } from 'react-i18next';

import { translate } from 'utils/i18n';
import { ISkillFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { Typography } from 'common/components/Typography';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ImageField, TextField } from 'common/components/FormField';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { FormGroupSelector } from 'user/components/FormField/FormGroupSelector';
import { TSkillFormValues } from 'skill/types';
import { CertificateSelector } from 'training/components/FormField/CertificateSelector';
import { CARD_IMAGE_MAX_SIZE, CARD_IMAGE_TYPE } from 'common/constants/files';
import { Banner } from 'common/components/Banner';
import { Img } from 'common/components/Img';
import SkillCardImage from 'assets/images/module-card-image.webp';

interface IProps {
    disabled?: boolean;
    skill?: ISkillFragment;
    isEdit?: boolean;
    isEditable?: boolean;
    initialValues: TSkillFormValues;
    onDelete?(): void;
    onSubmit(values: TSkillFormValues): void;
}

export const SkillForm = ({
    disabled,
    skill,
    isEdit,
    isEditable = true,
    onDelete,
    onSubmit,
    ...other
}: IProps) => {
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(translate('validate.required')),
        shortName: Yup.string().max(
            5,
            translate('validate.maxCharacters', { count: 5 })
        ),
    });

    const submitText = translate(
        `skillForm.${isEdit ? 'editButton' : 'createButton'}`
    );

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box pt={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Typography variant="h3">
                        {isEdit
                            ? translate('skillForm.editSkill')
                            : translate('skillForm.createSkill')}
                    </Typography>

                    {!isEdit && (
                        <Box mt={2}>
                            <Typography>
                                {translate('skillForm.createSkillIntro')}
                            </Typography>
                        </Box>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item sm={9} xs={8}>
                                <Field
                                    component={TextField}
                                    label={translate('name')}
                                    name="name"
                                />
                            </Grid>

                            <Grid item sm={3} xs={4}>
                                <Field
                                    component={TextField}
                                    label={translate('shortName')}
                                    name="shortName"
                                />
                            </Grid>
                        </Grid>

                        <Field
                            multiline
                            component={TextField}
                            label={translate('description')}
                            name="description"
                        />

                        <FormSection
                            description={
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="skillForm.imageDescription"
                                />
                            }
                            title={translate('image')}
                        >
                            <Box mt={3}>
                                <Grid
                                    container
                                    alignItems="stretch"
                                    direction={
                                        smallScreen ? 'column-reverse' : 'row'
                                    }
                                    spacing={2}
                                >
                                    <Grid item sm xs={12}>
                                        <Field
                                            acceptedFileTypes={CARD_IMAGE_TYPE}
                                            component={ImageField}
                                            helperText={translate(
                                                'imageSizeDescription',
                                                {
                                                    width: 400,
                                                    height: 150,
                                                }
                                            )}
                                            label={translate('image')}
                                            maxUploadSize={CARD_IMAGE_MAX_SIZE}
                                            name="thumbnail"
                                        />
                                    </Grid>
                                    <Grid item sm xs={12}>
                                        <Banner>
                                            <Box
                                                sx={{
                                                    py: 1,
                                                    pr: 1,
                                                    pl: 2,
                                                }}
                                            >
                                                {translate(
                                                    'skillForm.imageBannerDescription'
                                                )}
                                            </Box>
                                            <Box mt="auto">
                                                <Img src={SkillCardImage} />
                                            </Box>
                                        </Banner>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormSection>

                        <FormSection
                            renderExpanded
                            description={
                                <Box>
                                    <Trans
                                        components={{
                                            a: (
                                                <Link
                                                    rel="noreferrer"
                                                    underline="always"
                                                />
                                            ),
                                        }}
                                        i18nKey="skillForm.groupsDescription"
                                    />
                                </Box>
                            }
                            title={translate('skillForm.groups')}
                        >
                            <Box mt={2}>
                                <FormGroupSelector
                                    label={translate('skillForm.addGroups')}
                                    name="groups"
                                    value={values.groups || []}
                                />
                            </Box>
                        </FormSection>

                        <FormSection
                            renderExpanded
                            description={
                                <Box>
                                    <Trans
                                        components={{
                                            a: (
                                                <Link
                                                    rel="noreferrer"
                                                    underline="always"
                                                />
                                            ),
                                        }}
                                        i18nKey="skillForm.certificatesDescription"
                                    />
                                </Box>
                            }
                            title={translate('skillForm.certificates')}
                        >
                            <Box mt={2}>
                                <CertificateSelector
                                    name="certificates"
                                    value={values.certificates || []}
                                />
                            </Box>
                        </FormSection>
                    </Box>

                    <Box sx={{ display: 'flex', mt: 4 }}>
                        <Button
                            color="primary"
                            disabled={
                                isSubmitting ||
                                disabled ||
                                (!isSubmitting && !disabled && !dirty)
                            }
                            loading={isSubmitting}
                            type="submit"
                            variant="contained"
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault();

                                return submitForm();
                            }}
                        >
                            {submitText}
                        </Button>

                        {isEditable && isEdit && (
                            <Box ml="auto">
                                <FormDeleteButton
                                    disabled={isSubmitting}
                                    onClick={onDelete}
                                >
                                    {translate('delete')}
                                </FormDeleteButton>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Formik>
    );
};
