import { useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Formik, Field } from 'formik';

import {
    ICreateExternalContentModuleInput,
    IUpdateExternalContentModuleInput,
    useExternalContentQuery,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { ModuleWeightFormSection } from 'training/components/ModuleWeightFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { ExternalContentFormItem } from 'external/components/ExternalContentFormItem';
import { useApolloError } from 'common/hooks/useApolloError';

export interface IExternalContentFormValues
    extends IModuleFormValues,
        Omit<
            | ICreateExternalContentModuleInput
            | IUpdateExternalContentModuleInput,
            'imageId' | 'offset'
        > {}

export interface IProps {
    initialValues: IExternalContentFormValues;
    disabled?: boolean;
    edit?: boolean;
    moduleGroupId?: string;
    externalContentId?: string;
    onSubmit(values: IExternalContentFormValues): void;
    onDelete?(): void;
    onReplaceExternalContent?(): void;
}

export const ExternalContentModuleForm = ({
    initialValues,
    disabled,
    edit,
    externalContentId,
    moduleGroupId,
    onSubmit,
    onDelete,
    onReplaceExternalContent,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [settingsExpanded, setSettingsExpanded] = useState(false);

    // If a externalContentId is passed we want to use that instead of the one from initialValues
    const selectedExternalContentId =
        externalContentId || initialValues?.contentId;
    const externalContentChanged =
        selectedExternalContentId !== initialValues?.contentId;

    const { data: externalContentData, loading: loadingExternalContent } =
        useExternalContentQuery({
            variables: { id: selectedExternalContentId },
            onError: showApolloError,
        });

    const selectedExternalContent = externalContentData?.externalContent;

    const formTitle = edit
        ? translate('moduleForm.externalContent.editTitle')
        : translate('moduleForm.externalContent.newTitle');
    const formText = edit
        ? translate('moduleForm.externalContent.editText')
        : translate('moduleForm.externalContent.newText');
    const submitText = edit
        ? translate('moduleForm.externalContent.editButton')
        : translate('moduleForm.externalContent.newButton');

    return (
        <Formik
            {...other}
            initialValues={initialValues}
            onSubmit={(values) => {
                const newValues = values;

                if (externalContentChanged) {
                    newValues.contentId = selectedExternalContentId;
                }

                return onSubmit(newValues);
            }}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Form>
                        {formTitle && formText && (
                            <Box mb={4}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box my={2}>
                            <ExternalContentFormItem
                                externalContent={selectedExternalContent}
                                loading={loadingExternalContent}
                                onReplace={onReplaceExternalContent}
                            />
                            {!loadingExternalContent &&
                                !selectedExternalContent &&
                                externalContentChanged && (
                                    <FormHelperText error>
                                        {translate(
                                            'moduleForm.externalContent.externalContentIsRequired'
                                        )}
                                    </FormHelperText>
                                )}
                        </Box>

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.externalContent.overrideDescription"
                                        />
                                    }
                                    title={translate('titleOverride')}
                                >
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />

                                    <Field
                                        multiline
                                        component={TextField}
                                        label={translate('description')}
                                        name="description"
                                    />
                                </FormSection>

                                <ModuleWeightFormSection />
                                <ModuleImageFormSection />

                                {/* Only show conditions section when we have a module group id */}
                                {moduleGroupId && (
                                    <ModuleOffsetFormSection
                                        moduleGroupId={moduleGroupId}
                                        offsetValues={
                                            values.offset || undefined
                                        }
                                    />
                                )}
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting &&
                                            !disabled &&
                                            !dirty &&
                                            !externalContentChanged)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
