import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Grid, useMediaQuery, Theme, Stack } from '@mui/material';
import { Form, Formik, Field } from 'formik';

import {
    IUpdateVideoInput,
    IFileFragment,
    Maybe,
    IVideoEditFragment,
    IVideoListItemFragment,
    IContentTypeValue,
} from 'graphql/types';
import {
    TextField,
    ClipboardCopyInput,
    ImageField,
} from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { CARD_IMAGE_MAX_SIZE, CARD_IMAGE_TYPE } from 'common/constants/files';
import { ContentExpander } from 'common/components/ContentExpander';
import { FormSection } from 'common/components/FormSection';
import { Img } from 'common/components/Img';
import { Banner } from 'common/components/Banner';
import { Link } from 'common/components/Link';
import ModuleCardImage from 'assets/images/module-card-image.webp';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { Button } from 'common/components/Button';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { VideoUpload } from 'video/components/VideoUpload';
import { VideoListItem } from 'video/components/VideoListItem';
import { Divider } from 'common/components/Divider';
import { VideoPlayerDialog } from 'video/components/VideoPlayerDialog';
import { ExtraCategoryFormFields } from 'organisation/components/ExtraCategoryFormFields';
import { TExtraCategoryInput } from 'organisation/types';

export type TVideoFormValues = Omit<IUpdateVideoInput, 'thumbnailId'> &
    Record<'thumbnail', Maybe<IFileFragment> | undefined> &
    Record<'videoUrl', string> & { extraCategories: TExtraCategoryInput };

export interface IProps {
    initialValues: TVideoFormValues;
    video?: IVideoEditFragment;
    disabled?: boolean;
    onSubmit(values: TVideoFormValues): void;
    onDelete?(): void;
    onReplaceVideo?(): void;
}

export const VideoForm = ({
    initialValues,
    onSubmit,
    disabled,
    video,
    onDelete,
}: IProps) => {
    const [translate] = useTranslation();

    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );
    const [replacingVideo, setReplacingVideo] = useState(false);
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const [showVideoDialog, setShowVideoDialog] = useState(false);

    const { embedUrl } = video || {};

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ submitForm, isSubmitting, dirty }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    {embedUrl && (
                        <VideoPlayerDialog
                            open={showVideoDialog}
                            src={embedUrl}
                            title={video?.title}
                            onClose={() => setShowVideoDialog(false)}
                        />
                    )}

                    <Form>
                        <VideoListItem
                            button={!!embedUrl}
                            disabled={!embedUrl}
                            px={0}
                            py={0}
                            video={video as IVideoListItemFragment}
                            onClick={() => setShowVideoDialog(true)}
                        />

                        <Box sx={{ mt: { xs: 2, sm: 4 }, mb: 2 }}>
                            <Divider />
                        </Box>

                        <Field
                            component={TextField}
                            label={translate('title')}
                            name="title"
                        />

                        <Field
                            component={ClipboardCopyInput}
                            label={translate('videoForm.videoUrl')}
                            name="videoUrl"
                        />

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="videoForm.thumbnailDescription"
                                        />
                                    }
                                    title={translate('image')}
                                >
                                    <Box mt={3}>
                                        <Grid
                                            container
                                            alignItems="stretch"
                                            direction={
                                                smallScreen
                                                    ? 'column-reverse'
                                                    : 'row'
                                            }
                                            spacing={2}
                                        >
                                            <Grid item sm xs={12}>
                                                <Field
                                                    acceptedFileTypes={
                                                        CARD_IMAGE_TYPE
                                                    }
                                                    component={ImageField}
                                                    helperText={translate(
                                                        'imageSizeDescription',
                                                        {
                                                            width: 400,
                                                            height: 150,
                                                        }
                                                    )}
                                                    label={translate('image')}
                                                    maxUploadSize={
                                                        CARD_IMAGE_MAX_SIZE
                                                    }
                                                    name="thumbnail"
                                                />
                                            </Grid>
                                            <Grid item sm xs={12}>
                                                <Banner>
                                                    <Box
                                                        sx={{
                                                            py: 1,
                                                            pr: 1,
                                                            pl: 2,
                                                        }}
                                                    >
                                                        {translate(
                                                            'moduleForm.imageInTrainingDescription'
                                                        )}
                                                    </Box>
                                                    <Box mt="auto">
                                                        <Img
                                                            src={
                                                                ModuleCardImage
                                                            }
                                                        />
                                                    </Box>
                                                </Banner>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormSection>
                                {!!video && (
                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="videoForm.videoFileDescription"
                                            />
                                        }
                                        title={translate('videoForm.videoFile')}
                                    >
                                        <Box mt={3}>
                                            <Stack direction="row" spacing={2}>
                                                <VideoUpload
                                                    disabled={replacingVideo}
                                                    id={`replace-${
                                                        video?.id || 'new'
                                                    }`}
                                                    videoId={video?.id}
                                                    onAbort={() => {
                                                        setReplacingVideo(
                                                            false
                                                        );
                                                    }}
                                                    onError={() => {
                                                        setReplacingVideo(
                                                            false
                                                        );
                                                    }}
                                                    onStart={() => {
                                                        setReplacingVideo(true);
                                                    }}
                                                    onSuccess={() => {
                                                        setReplacingVideo(
                                                            false
                                                        );
                                                    }}
                                                >
                                                    <Button
                                                        component="span"
                                                        disabled={
                                                            replacingVideo
                                                        }
                                                        loading={replacingVideo}
                                                        variant="contained"
                                                    >
                                                        {translate('replace')}
                                                    </Button>
                                                </VideoUpload>
                                                {!!video.downloadUrl && (
                                                    <Button
                                                        download
                                                        component={Link}
                                                        href={video.downloadUrl}
                                                        target="_blank"
                                                        variant="contained"
                                                    >
                                                        {translate('download')}
                                                    </Button>
                                                )}
                                            </Stack>
                                        </Box>
                                    </FormSection>
                                )}

                                <FormSection
                                    description={
                                        <Box>
                                            {translate(
                                                'videoForm.extraCategoriesDescription'
                                            )}
                                        </Box>
                                    }
                                    title={translate(
                                        'videoForm.extraCategories'
                                    )}
                                >
                                    <ExtraCategoryFormFields
                                        contentType={IContentTypeValue.Video}
                                    />
                                </FormSection>
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {translate('videoForm.editButton')}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
