import { useApolloClient } from '@apollo/client';

import {
    useTrainingPortfolioItemsSubscription,
    TrainingModulesDocument,
    ITrainingModulesQuery,
    ITrainingModulesQueryVariables,
    IPortfolioItemTrainingModuleListItemFragment,
    ModulePortfolioItemFragmentDoc,
    IContentTypeValue,
} from 'graphql/types';

interface IProps {
    trainingId: string;
}

/**
 * Subscribes to portfolio items for a training and updates data in the cache based on the portfolio items.
 */
export const TrainingPortfolioItemsSubscription = ({ trainingId }: IProps) => {
    const client = useApolloClient();

    const updateItemsInTrainingCache = (
        portfolioItems: IPortfolioItemTrainingModuleListItemFragment[]
    ) => {
        // Create map of portfolio items
        const portfolioItemMap = new Map(
            portfolioItems.map((item) => {
                const { relatedId, relatedType } = item;
                let key: string = relatedId;

                if (relatedType === IContentTypeValue.ReceivedCertificate) {
                    key = item.eventCertificateId;
                }

                if (!key) return [undefined, null];

                return [key, item];
            })
        );

        // Get all modules from cache to create a map for updating specific modules
        const cachedTraining = client.cache.readQuery<
            ITrainingModulesQuery,
            ITrainingModulesQueryVariables
        >({
            query: TrainingModulesDocument,
            variables: { id: trainingId },
            returnPartialData: true,
        });

        const { moduleGroups } = cachedTraining?.training || {};

        // Process all modules and update cache in one pass
        moduleGroups?.forEach((moduleGroup) => {
            moduleGroup.modules?.forEach((module) => {
                let relatedId = module.id;

                // Get correct related ID for course and scorm modules
                if (module.__typename === 'CourseModule') {
                    relatedId = module.course.id;
                } else if (module.__typename === 'ScormModule') {
                    relatedId = module.scorm.id;
                } else if (module.__typename === 'ExternalContentModule') {
                    relatedId = module.content.id;
                }

                // Get portfolio item for this module if it exists
                const portfolioItem = portfolioItemMap.get(relatedId);

                if (!portfolioItem || !portfolioItem.relatedType) return;

                const moduleId = client.cache.identify({
                    __typename: module.__typename,
                    id: module.id,
                });

                if (!moduleId) return;

                // Check if portfolio item has some progress to add this to the cache
                const hasProgress =
                    !!portfolioItem.progress ||
                    !!portfolioItem.completionDate ||
                    !!portfolioItem.endDate ||
                    !!portfolioItem.relatedObjectHasFiles ||
                    !!portfolioItem.relatedObjectHasComments;

                // Update fragment with new portfolio item
                client.cache.updateFragment(
                    {
                        id: moduleId,
                        fragment: ModulePortfolioItemFragmentDoc,
                        fragmentName: 'ModulePortfolioItem',
                    },
                    () => ({
                        portfolioItem: portfolioItem,
                        hasProgress,
                    })
                );
            });
        });
    };

    useTrainingPortfolioItemsSubscription({
        variables: { trainingId },
        onData: ({ data: { data } }) => {
            const portfolioItems = data?.trainingPortfolioItems || null;

            if (portfolioItems) {
                updateItemsInTrainingCache(portfolioItems);
            }
        },
    });

    return null;
};
