import createPalette from '@mui/material/styles/createPalette';
import { Palette, PaletteOptions } from '@mui/material/styles';
import { getContrastRatio, darken, lighten, getLuminance } from '@mui/system';
import { tint } from 'polished';
import { grey as greyColors } from '@mui/material/colors';

import { IThemePalette } from 'graphql/types';

export const paletteOptions: PaletteOptions = {
    background: {
        default: '#fff',
    },
    text: {
        primary: '#303a46',
        secondary: '#7f7f7f',
    },
    grey: {
        ...greyColors,
        50: '#FBFBFB',
        100: '#F7F7F7',
        200: '#F0F0F0',
        300: '#e0e0e0',
        500: '#9c9c9c',
        600: '#878787',
        800: '#494949',
        900: '#202020',
    },
    action: {
        hover: '#fbfbfb',
        hoverOpacity: 0.03,
        selected: '#F7F7F7',
        focus: '#F7F7F7',
    },
    white: {
        main: '#FFF',
        light: '#000',
        dark: '#F7F7F7',
    },
};

export const THEME_COLORS = {
    primary: '#11b683',
    secondary: '#f46b75',
    error: '#dc3d50',
    danger: '#dc3d50',
    success: '#11b683',
    warning: '#f7c248',
};

export const BASE_COLORS = {
    'base-1': '#6058be',
    'base-2': '#f46b75',
    'base-3': '#9355a9',
    'base-4': '#00a887',
    'base-5': '#ff8e00',
    'base-6': '#448290',
    'base-7': '#8b572a',
};

export const PRIMARY_COLOR_PRESETS = {
    'preset-1': '#11B683',
    'preset-2': '#2EB3C7',
    'preset-3': '#D94905',
    'preset-4': '#F7C248',
    'preset-5': '#7E29A2',
};

export const COLORS = {
    ...THEME_COLORS,
    ...BASE_COLORS,
    darkGrey: '#4b5468',
    purple: '#6200ee',
    yellow: '#fce9c0',
    body: '#303a46',
    title: '#303a46',
    subtitle: '#303a46',
    whiteTransparent: 'rgba(255, 255, 255, 0.5)',
};

export interface IColor {
    main: string;
    contrastText?: string;
    light?: string;
    dark?: string;
    accent?: string;
    bgColor?: string;
}

export type TColors = typeof COLORS;
export type TColorKeys = keyof TColors;

const CONTRAST_THRESHOLD = 2;

// Accent color is generated based on the given color, this will be darker when color is light.
function getAccentColor(color: string) {
    const isLightColor = getLuminance(color) > 0.179;

    return isLightColor ? darken(color, 0.2) : lighten(color, 0.2);
}

function augmentColor(muiPalette: Palette, color: string) {
    const getContrastText = (background: string) =>
        getContrastRatio(background, muiPalette.common.white) >=
        CONTRAST_THRESHOLD
            ? muiPalette.common.white
            : muiPalette.text.primary;

    const augmentedColor = muiPalette.augmentColor({
        color: { main: color, contrastText: getContrastText(color) },
    });

    const accent = getAccentColor(color);
    const bgColor = tint(0.9, color);

    return { ...augmentedColor, accent, bgColor };
}

export function createPaletteSetup(
    palette: IThemePalette = { __typename: 'ThemePalette' }
): PaletteOptions {
    const muiPalette = createPalette({ ...paletteOptions, tonalOffset: 0.1 });

    return Object.keys(COLORS).reduce((acc, key) => {
        const color = palette[key as keyof IThemePalette] || COLORS[key];

        if (key === 'body' && acc.text) {
            // Copy body color to text primary
            acc.text.primary = color;
            // Generate secondary color using tint
            acc.text.secondary = tint(0.3, color);
        }

        acc[key] = augmentColor(muiPalette, color);

        return acc;
    }, muiPalette);
}

export type TColorOptions = { [key: string]: TColorKeys | undefined };

/**
 * Color options will return an object with display name as key and
 * color key as value. These options are used for color select knob in
 * storybooks
 *
 * @returns `{ none: undefined, primary: 'primary', ... }`
 */
export const colorOptions: TColorOptions = Object.keys(COLORS).reduce(
    (acc, key) => ({
        ...acc,
        [key]: key,
    }),
    { none: undefined }
);
