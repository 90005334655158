import { useMemo, ReactElement } from 'react';

import {
    IContentTypeValue,
    IPortfolioItemListItemOldFragment,
} from 'graphql/types';
import {
    CertificatePortfolioItem,
    CustomPortfolioItem,
} from 'user/components/PortfolioListItem';
import { List } from 'common/components/List';
import { Draggable, Droppable } from 'common/components/DragDrop';
import { ExternalContentPortfolioItem } from 'user/components/PortfolioListItem/ExternalContentPortfolioItem';

interface IProps {
    className?: string;
    groupId: string;
    items: IPortfolioItemListItemOldFragment[];
    withEditActions?: boolean;
    onClickCustomItem(item?: IPortfolioItemListItemOldFragment): void;
}

export const PortfolioItemList = ({
    className,
    groupId,
    items,
    withEditActions,
    onClickCustomItem,
}: IProps) => {
    const itemNodes = useMemo(
        () =>
            items
                .map((item, index) => {
                    let node: ReactElement | undefined;
                    const { relatedType } = item;

                    if (!relatedType) {
                        node = (
                            <CustomPortfolioItem
                                key={item.id}
                                withEditActions={withEditActions}
                                onClick={() => onClickCustomItem(item)}
                                {...item}
                            />
                        );
                    } else {
                        switch (relatedType) {
                            case IContentTypeValue.ReceivedCertificate:
                                node = (
                                    <CertificatePortfolioItem
                                        key={item.id}
                                        withEditActions={withEditActions}
                                        {...item}
                                    />
                                );

                                break;
                            case IContentTypeValue.ExternalContent:
                                node = (
                                    <ExternalContentPortfolioItem
                                        key={item.id}
                                        withEditActions={withEditActions}
                                        {...item}
                                    />
                                );

                                break;
                            default:
                                node = undefined;
                        }
                    }

                    if (!node) return null;

                    return (
                        <Draggable
                            dragDropDisabled={!withEditActions}
                            draggableId={item.id}
                            index={index}
                            key={item.id}
                        >
                            <div>{node}</div>
                        </Draggable>
                    );
                })
                .filter(Boolean),
        [withEditActions, items, onClickCustomItem]
    );

    return (
        <List noBorder className={className}>
            <Droppable
                dragDropDisabled={!withEditActions}
                droppableId={groupId}
                type="ITEM"
            >
                <div>{itemNodes}</div>
            </Droppable>
        </List>
    );
};
