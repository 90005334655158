import { Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { IFontSize, IThemeTypographyInput } from 'graphql/types';
import {
    TFontFamilyOption,
    TFontSizeOption,
    TFontWeightOption,
} from 'common/types';
import { TOrganisationSettingsFormValues } from 'organisation/components/forms/OrganisationSettingsForm';

import { Select } from '../Select/Select';
import { Autocomplete } from '../Autocomplete/Autocomplete';

type TLabels = { family: string; size: string; weight: string };
type TNames = { family: string; size: string; weight: string };
const DEFAULT_FAMILY = 'Lato';

interface IProps {
    familyOptions: TFontFamilyOption[];
    sizeOptions: TFontSizeOption[];
    weightOptions: TFontWeightOption[];
    labels?: TLabels;
    names: TNames;
    onChange?: (field: string, value: string) => void;
}

export const FontSelect = ({
    familyOptions,
    sizeOptions,
    weightOptions,
    labels,
    names,
    onChange,
}: IProps) => {
    const { values, setFieldValue } =
        useFormikContext<TOrganisationSettingsFormValues>();

    const familyName = names.family;
    // Matching the word between the dots. For example: body, title, or subtitle
    const fontType = familyName.match(
        /\.([^.]*)\./
    )?.[1] as keyof IThemeTypographyInput;
    const fontValue = values.typography?.[fontType];
    const fontFamilyValue = !!fontType ? fontValue?.fontFamily : undefined;

    // Set default font if font not set
    useEffect(() => {
        if (!!fontFamilyValue) return;

        const defaultFontFamily = familyOptions.find(
            (fontFamily) => fontFamily.value === DEFAULT_FAMILY
        )?.value;

        setFieldValue(names.family, defaultFontFamily);
    }, [fontFamilyValue]);

    return (
        <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
                <Field
                    component={Autocomplete}
                    groupBy={(option: TFontFamilyOption) => option.group}
                    InputProps={{
                        label: labels?.family,
                        sx: { mt: 0 },
                    }}
                    isOptionEqualToValue={(
                        option: TFontFamilyOption,
                        value: TFontFamilyOption['value']
                    ) => option.value === value}
                    name={names.family}
                    options={familyOptions}
                    onChange={(name: string, value: TFontFamilyOption) => {
                        onChange?.(name, value?.value || DEFAULT_FAMILY);
                    }}
                />
            </Grid>

            <Grid item sm={3} xs={6}>
                <Field
                    component={Select}
                    defaultValue={IFontSize.Md}
                    label={labels?.size}
                    name={names.size}
                    options={sizeOptions}
                    sx={{ mt: 0 }}
                />
            </Grid>

            {!!weightOptions.length && (
                <Grid item sm={3} xs={6}>
                    <Field
                        component={Select}
                        label={labels?.weight}
                        name={names.weight}
                        options={weightOptions}
                        sx={{ mt: 0 }}
                        value={fontValue?.weight}
                    />
                </Grid>
            )}
        </Grid>
    );
};
