import { generatePath } from 'react-router';

import { TParams, TRouteName } from 'route/types';
import { ROUTES } from 'route/constants/routes';

export function getUrl(
    routeName: TRouteName,
    params?: TParams,
    includeDomain?: boolean,
    external?: boolean
): string {
    const path = ROUTES[routeName];

    if (!path) return '/';

    if (external) {
        const baseUrl = import.meta.env.VITE_BASE_URL || '';

        return `${baseUrl}${path}`;
    }

    let generatedPath = generatePath(path, params);

    if (includeDomain) {
        const { protocol, hostname, port } = window.location;
        const portString = port ? `:${port}` : '';
        const domainUrl = `${protocol}//${hostname}${portString}`;

        generatedPath = `${domainUrl}${generatedPath}`;
    }

    return generatedPath;
}
