import {
    IAbsoluteModuleGroupConditionFragment,
    IConditionEffect,
    ICourseComparison,
    ICourseCompletionModuleGroupConditionFragment,
    IEventReminder,
    IModuleGroupConditionFragment,
    IModuleGroupConditionOffsetScale,
    IModuleGroupConditionType,
    IRelativeModuleGroupConditionFragment,
    IUpdateCourseCompletionConditionInput,
} from 'graphql/types';
import { IConditionType, TModuleGroupFormValues } from 'training/types';
import { translate } from 'utils/i18n';

/*
 * Get the translation of the given condition type
 */
export function getConditionTypeTranslation(
    conditionType: IModuleGroupConditionType
) {
    let transKey = '';

    switch (conditionType) {
        case IModuleGroupConditionType.CourseCompletion:
            transKey = 'courseCompletion';
            break;
        case IModuleGroupConditionType.Relative:
            transKey = 'relative';
            break;
        case IModuleGroupConditionType.Absolute:
            transKey = 'absolute';
            break;
        default:
            transKey = 'noCondition';
    }

    return translate(`moduleGroupForm.conditionType.${transKey}`);
}

/*
 * Get the translation of the given condition start offset type
 */
export function getConditionOffsetTypeTranslation(
    startOffsetScale: IModuleGroupConditionOffsetScale,
    conditionType: IConditionType
) {
    let transKey = '';

    switch (startOffsetScale) {
        case IModuleGroupConditionOffsetScale.Hours:
            transKey = 'offsetScale.hours';
            break;
        case IModuleGroupConditionOffsetScale.Weeks:
            transKey = 'offsetScale.weeks';
            break;
        default:
            transKey = 'offsetScale.days';
    }

    return translate(`moduleGroupForm.${conditionType}.${transKey}`);
}

/*
 * Get the translation of the event reminder offset type
 */
export function getEventRemindeOffsetTypeTranslation(
    eventReminderOffsetType: IEventReminder
) {
    let transKey = '';

    switch (eventReminderOffsetType) {
        case IEventReminder.AtTheStart:
            transKey = 'atTheStart';
            break;
        case IEventReminder.OneDayBefore:
            transKey = 'oneDayBefore';
            break;
        case IEventReminder.TwoDaysBefore:
            transKey = 'twoDaysBefore';
            break;
        case IEventReminder.ThreeDaysBefore:
            transKey = 'threeDaysBefore';
            break;
        case IEventReminder.FourDaysBefore:
            transKey = 'fourDaysBefore';
            break;
        case IEventReminder.FiveDaysBefore:
            transKey = 'fiveDaysBefore';
            break;
        case IEventReminder.SixDaysBefore:
            transKey = 'sixDaysBefore';
            break;
        case IEventReminder.SevenDaysBefore:
            transKey = 'sevenDaysBefore';
            break;
        case IEventReminder.TwoWeeksBefore:
            transKey = 'twoWeeksBefore';
            break;
        case IEventReminder.OneMonthBefore:
            transKey = 'oneMonthBefore';
            break;
        default:
            transKey = 'none';
    }

    return translate(
        `moduleGroupForm.absoluteCondition.reminderOffsetType.${transKey}`
    );
}

/*
 * Get the translation of the course comparison type
 */
export function getCourseComparisonTranslation(
    courseComparison?: ICourseComparison
) {
    let transKey = '';

    switch (courseComparison) {
        case ICourseComparison.Minimum:
            transKey = 'minimum';
            break;
        case ICourseComparison.Maximum:
            transKey = 'maximum';
            break;
        default:
            transKey = 'none';
    }

    return translate(
        `moduleGroupForm.courseCompletionCondition.courseComparison.${transKey}`
    );
}

export function getConditionInitialValues(
    conditionType: IModuleGroupConditionType,
    condition?: IModuleGroupConditionFragment
):
    | { relativeCondition: TModuleGroupFormValues['relativeCondition'] }
    | { absoluteCondition: TModuleGroupFormValues['absoluteCondition'] }
    | {
          courseCompletionCondition: TModuleGroupFormValues['courseCompletionCondition'];
      }
    | Record<string, unknown> {
    if (conditionType === IModuleGroupConditionType.Relative) {
        const relativeCondition = condition as
            | IRelativeModuleGroupConditionFragment
            | undefined;
        const {
            startOffset,
            sendReminder,
            deadlineOffset,
            preConditionEffect,
            postConditionEffect,
        } = relativeCondition || {};

        return {
            absoluteCondition: undefined,
            relativeCondition: {
                preConditionEffect: preConditionEffect || IConditionEffect.Lock,
                postConditionEffect:
                    postConditionEffect || IConditionEffect.Lock,
                startOffset: {
                    amount: +(startOffset?.amount || 0),
                    type:
                        startOffset?.type ||
                        IModuleGroupConditionOffsetScale.Days,
                },
                sendReminder:
                    sendReminder === undefined || !!sendReminder ? true : false,
                deadlineOffset: {
                    amount: +(deadlineOffset?.amount || 0),
                    type:
                        deadlineOffset?.type ||
                        IModuleGroupConditionOffsetScale.Hours,
                },
            },
            courseCompletionCondition: undefined,
        };
    }

    if (conditionType === IModuleGroupConditionType.Absolute) {
        const absoluteCondition = condition as
            | IAbsoluteModuleGroupConditionFragment
            | undefined;
        const {
            start,
            reminderOffset,
            preConditionEffect,
            postConditionEffect,
            deadline,
        } = absoluteCondition || {};

        return {
            absoluteCondition: {
                start: start || null,
                reminderOffset: reminderOffset || IEventReminder.AtTheStart,
                preConditionEffect: preConditionEffect || IConditionEffect.Lock,
                postConditionEffect:
                    postConditionEffect || IConditionEffect.Lock,
                deadline: deadline || null,
            },
            relativeCondition: undefined,
            courseCompletionCondition: undefined,
        };
    }

    if (conditionType === IModuleGroupConditionType.CourseCompletion) {
        const courseCompletionCondition = condition as
            | ICourseCompletionModuleGroupConditionFragment
            | undefined;
        const {
            module,
            comparison,
            scoreThreshold,
            preDelay,
            sendReminder,
            preConditionEffect,
            postDelay,
            postConditionEffect,
        } = courseCompletionCondition || {};

        return {
            absoluteCondition: undefined,
            relativeCondition: undefined,
            courseCompletionCondition: {
                courseOrLti: module,
                comparison: comparison || ICourseComparison.None,
                scoreThreshold: scoreThreshold || 0,
                preDelay: {
                    amount: preDelay?.amount || 0,
                    type:
                        preDelay?.type ||
                        IModuleGroupConditionOffsetScale.Hours,
                },
                sendReminder:
                    sendReminder === undefined || !!sendReminder ? true : false,
                preConditionEffect: preConditionEffect || IConditionEffect.Lock,
                postDelay: {
                    amount: postDelay?.amount || 0,
                    type:
                        postDelay?.type ||
                        IModuleGroupConditionOffsetScale.Hours,
                },
                postConditionEffect:
                    postConditionEffect || IConditionEffect.Lock,
            },
        };
    }

    return {};
}

export function getConditionFormValues(
    conditionType: IModuleGroupConditionType,
    values: TModuleGroupFormValues
) {
    switch (conditionType) {
        case IModuleGroupConditionType.Relative:
            const { relativeCondition } = values || {};
            const { startOffset, deadlineOffset } = relativeCondition || {};

            return {
                relativeCondition: {
                    ...values.relativeCondition,
                    startOffset: startOffset
                        ? {
                              amount: +startOffset.amount,
                              type: startOffset.type,
                          }
                        : undefined,
                    deadlineOffset: deadlineOffset
                        ? {
                              amount: +deadlineOffset.amount,
                              type: deadlineOffset.type,
                          }
                        : undefined,
                },
            };
        case IModuleGroupConditionType.Absolute:
            return {
                absoluteCondition: {
                    ...values.absoluteCondition,
                    deadline: values.absoluteCondition?.deadline || undefined,
                },
            };
        case IModuleGroupConditionType.CourseCompletion:
            const { courseCompletionCondition } = values || {};
            const {
                courseOrLti,
                comparison,
                scoreThreshold,
                preDelay,
                postDelay,
            } = courseCompletionCondition || {};

            const isCourseModule = courseOrLti?.__typename === 'Course';

            const courseOrLtiObj = {
                courseId: isCourseModule ? courseOrLti?.id : undefined,
                ltiModuleId: !isCourseModule ? courseOrLti?.id : undefined,
            };

            delete courseCompletionCondition?.courseOrLti;

            return {
                courseCompletionCondition: {
                    ...courseCompletionCondition,
                    ...courseOrLtiObj,
                    comparison,
                    scoreThreshold: scoreThreshold
                        ? +scoreThreshold
                        : undefined,
                    preDelay: preDelay
                        ? {
                              amount: +preDelay.amount,
                              type: preDelay.type,
                          }
                        : undefined,
                    postDelay: postDelay
                        ? {
                              amount: +postDelay.amount,
                              type: postDelay.type,
                          }
                        : undefined,
                },
            } as IUpdateCourseCompletionConditionInput;

        default:
            return {};
    }
}
