import { useEffect } from 'react';
import { Dialog, DialogContent, Grid, Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiPlus, mdiClose } from '@mdi/js';

import { useCurrentUserQuery } from 'graphql/types';
import { NoResults } from 'common/components/NoResults';
import { useCourseTemplateSearch } from 'training/hooks';
import { CardButton, CardButtonIcon } from 'common/components/Card';
import { SearchBar } from 'common/components/SearchBar';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';
import { CourseTemplateCard } from 'training/components/CourseTemplateCard';
import { BoxLoader } from 'common/components/Loader';
import { FilterDropdownGroup } from 'common/components/FilterDropdownGroup';
import { IconButton } from 'common/components/IconButton';
import { useCourseTemplateList } from 'training/hooks/useCourseTemplateList';

export interface IProps {
    open: boolean;
    onClose?(): void;
    onCreateCourse(templateId?: string): void;
}

export const CourseCreateModal = ({
    open,
    onClose,
    onCreateCourse,
}: IProps) => {
    const [translate] = useTranslation();

    const { data: user, loading: userLoading } = useCurrentUserQuery();
    const { currentUser } = user || {};

    const {
        filters,
        searchQueryParam,
        queryParams,
        selectedFilters,
        handleSearch,
        handleFilterSelect,
    } = useCourseTemplateSearch();

    // Get current user language to set as initial filter on render of modal
    useEffect(() => {
        const userLanguage = currentUser?.language;
        const initFilter = filters
            .find((f) => f.value === 'language')
            ?.children?.find((child) => child.value === userLanguage);

        if (!initFilter) return;

        handleFilterSelect([initFilter]);
    }, []);

    const { templates, loading: templatesLoading } = useCourseTemplateList(
        queryParams,
        searchQueryParam
    );

    if (!open) return null;

    const loading = templatesLoading || userLoading;

    if (!open) return null;

    return (
        <Dialog fullWidth maxWidth={false} open={open} onClose={onClose}>
            <DialogContent
                sx={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    margin: 'auto',
                    py: 10,
                }}
            >
                <Box sx={{ margin: 'auto', width: '100%' }}>
                    <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                        <IconButton
                            color="inherit"
                            iconPath={mdiClose}
                            iconSizeSm="2.8rem"
                            size="large"
                            onClick={onClose}
                        />
                    </Box>
                    <Container maxWidth="md">
                        <Typography sx={{ textAlign: 'center' }} variant="h2">
                            {translate('courseCreateModal.title')}
                        </Typography>

                        <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
                            <SearchBar
                                initialValue={searchQueryParam}
                                placeholder={translate('filterBarPlaceholder')}
                                sx={{ width: '100%' }}
                                onClear={() => handleSearch('')}
                                onClickSearch={handleSearch}
                                onEnterPress={handleSearch}
                            />
                            <Box sx={{ ml: 2 }}>
                                <FilterDropdownGroup
                                    filters={filters}
                                    handleFilterSelect={handleFilterSelect}
                                    initialSelected={selectedFilters}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            {loading ? (
                                <BoxLoader />
                            ) : !templates.length ? (
                                <NoResults variant="COURSE_TEMPLATES" />
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <CardButton
                                            minHeight="200px"
                                            onClick={() => onCreateCourse()}
                                        >
                                            <CardButtonIcon>
                                                <Icon
                                                    path={mdiPlus}
                                                    size="2.4rem"
                                                />
                                            </CardButtonIcon>
                                            <Box mt={1} px={2}>
                                                {translate(
                                                    'courseCreateModal.startEmptyButton'
                                                )}
                                            </Box>
                                        </CardButton>
                                    </Grid>

                                    {templates.map((template) => (
                                        <Grid
                                            item
                                            key={template.id}
                                            lg={3}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                        >
                                            <CourseTemplateCard
                                                template={template}
                                                onClick={onCreateCourse}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    </Container>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
