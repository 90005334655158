import { Box, BoxProps } from '@mui/material';
import { mdiPlus } from '@mdi/js';

import { ActionButton } from '../ActionButton';
import { Icon } from '../Icon';

interface IProps extends BoxProps {
    disabled?: boolean;
}

export const TableAddButton = ({
    children,
    disabled,
    onClick,
    ...other
}: IProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            alignSelf: 'flex-start',
        }}
        onClick={(e) => !disabled && onClick?.(e)}
        {...other}
    >
        <Box
            sx={{
                position: 'relative',
                color: disabled ? 'grey.400' : 'text.primary',
                mr: 1,
                fontWeight: 'bold',
            }}
        >
            {children}
        </Box>

        <ActionButton
            outlined
            sizeSm
            component={Box}
            disabled={disabled}
            sx={{ width: '30px', height: '30px' }}
        >
            <Icon path={mdiPlus} />
        </ActionButton>
    </Box>
);
