import * as Sections from 'user/components/Section';
import { IUserProfileSectionProps } from 'user/types';
import { TRouteName } from 'route/types';

export const USER_SECTIONS: {
    [K in TRouteName]?: React.FC<
        React.PropsWithChildren<IUserProfileSectionProps>
    >;
} = {
    USER_TRAININGS: Sections.PortfolioTrainings,
    USER_OFFERS: Sections.PortfolioOffers,
    USER_MEETINGS: Sections.PortfolioMeetings,
    USER_PORTFOLIO: Sections.Portfolio,
    USER_DEVELOPMENT_PLAN: Sections.DevelopmentPlan,
};

export const PROFILE_SECTIONS: {
    [K in TRouteName]?: React.FC<
        React.PropsWithChildren<IUserProfileSectionProps>
    >;
} = {
    PORTFOLIO_TRAININGS: Sections.PortfolioTrainings,
    PORTFOLIO_OFFERS: Sections.PortfolioOffers,
    PORTFOLIO_MEETINGS: Sections.PortfolioMeetings,
    PORTFOLIO: Sections.Portfolio,
    DEVELOPMENT_PLAN: Sections.DevelopmentPlan,
};
