import { useApolloError } from 'common/hooks/useApolloError';
import { useFeature } from 'common/hooks/useFeature';
import {
    IFeature,
    IRole,
    useCurrentUserQuery,
    useOffersSettingsQuery,
} from 'graphql/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

/**
 * This hook is used to check if the what the portfolio pages show based on permissions
 */
export const usePortfolioViewPermissions = () => {
    const { showApolloError } = useApolloError();
    const { data: currentUserData } = useCurrentUserQuery();
    const {
        canUse: canUsePortfolio,
        userCanSeeUpgrade,
        loading: loadingPortfolioFeature,
    } = useFeature(IFeature.Portfolio);
    const {
        canUse: canUseOffers,
        loading: loadingOffersFeature,
        userCanSeeUpgrade: userCanSeeOffersUpgrade,
    } = useFeature(IFeature.Offers);

    const isTrainingOffersPage = !!useRouteMatch('PORTFOLIO_OFFERS');
    const isTrainingOffersUserPage = !!useRouteMatch('USER_OFFERS');
    const isMeetingsPage = !!useRouteMatch('PORTFOLIO_MEETINGS');
    const isMeetingsUserPage = !!useRouteMatch('USER_MEETINGS');

    // The portfolio offers page which shows training and courses
    const isOffersPage = isTrainingOffersPage || isTrainingOffersUserPage;
    // The portfolio meetings page which shows meetings and webinars
    const isPortfolioMeetingsPage = isMeetingsPage || isMeetingsUserPage;

    const { roles } = currentUserData?.currentUser || {};
    const currentUserIsManager = roles?.includes(IRole.Manager);

    // Every offers related portfolio page
    const isPortfolioOffersPage = isOffersPage || isPortfolioMeetingsPage;

    const { data: offersSettingsData, loading: offersSettingsLoading } =
        useOffersSettingsQuery({
            onError: showApolloError,
        });

    const loading =
        loadingPortfolioFeature ||
        loadingOffersFeature ||
        offersSettingsLoading;

    let canUsePortfolioOffers = !!(
        !loadingOffersFeature &&
        !offersSettingsLoading &&
        canUseOffers
    );

    const { publish, publishTraining, publishMeetingWebinar } =
        offersSettingsData?.offersSettings || {};

    const isOffersPublished = !offersSettingsLoading && !!publish;

    // Do not show offers in portfolio when offers are not published and user is not manager
    if (!currentUserIsManager) {
        canUsePortfolioOffers = canUsePortfolioOffers && isOffersPublished;
    }

    const hideOffersSection = !currentUserIsManager && !publishTraining;
    const hideMeetingsSection = !currentUserIsManager && !publishMeetingWebinar;

    const portfolioPageNotFound = !canUsePortfolio && !userCanSeeUpgrade;

    // Every offers related portfolio page
    const portfolioOffersPageNotFound =
        isPortfolioOffersPage &&
        !canUsePortfolioOffers &&
        !userCanSeeOffersUpgrade;

    // The portfolio offers page which shows training and courses
    const offersPageNotFound = isOffersPage && hideOffersSection;
    // The portfolio meetings page which shows meetings and webinars
    const meetingsPageNotFound = isPortfolioMeetingsPage && hideMeetingsSection;

    const pageNotFound =
        !loading &&
        (portfolioPageNotFound ||
            portfolioOffersPageNotFound ||
            offersPageNotFound ||
            meetingsPageNotFound);

    const isPortfolioUpgradePage = !canUsePortfolio && userCanSeeUpgrade;

    const showUpgradePage =
        isPortfolioUpgradePage ||
        (isPortfolioOffersPage &&
            !canUsePortfolioOffers &&
            userCanSeeOffersUpgrade);

    return {
        canUsePortfolioOffers,
        userCanSeeUpgrade,
        loading,
        showUpgradePage,
        isPortfolioUpgradePage,
        pageNotFound,
        isOffersPublished,
        hideOffersSection,
        hideMeetingsSection,
    };
};
