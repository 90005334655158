import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { UserTable } from 'user/components/UserTable';
import { useAuthorTableData } from 'user/hooks/useAuthorTableData';
import { IUserSelectorInput } from 'graphql/types';

interface IProps extends IPageDrawerProps {
    selectorQueryVariables?: IUserSelectorInput;
    loading?: boolean;
    onAddAuthors(selectedAuthors: string[]): void;
    onClose?(): void;
}

export const AuthorSelectDrawer = ({
    selectorQueryVariables,
    loading,
    open,
    title,
    onAddAuthors,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const {
        loading: loadingAuthorsData,
        authors,
        alreadyAddedAuthors,
        tableDataProps,
    } = useAuthorTableData(undefined, selectorQueryVariables);
    const [selectedAuthorIds, setSelectedAuthorIds] = useState<string[]>([]);

    const { searchProps, paginationProps, selectionProps } = tableDataProps;
    const { handleSearch, handleFilterSelect } = searchProps || {};
    const { setPage } = paginationProps || {};
    const { selected, onSelectAll } = selectionProps || {};

    const resetSettings = () => {
        handleSearch('');
        handleFilterSelect([]);
        setPage?.(1);
        setSelectedAuthorIds([]);

        // Deselect all when there are selected items
        if (selected?.length) onSelectAll();
    };

    return (
        <PageDrawer
            open={open}
            title={title || translate('authorSelector.addNewAuthorsTitle')}
            onClose={() => {
                onClose?.();

                resetSettings();
            }}
        >
            <Box
                sx={{
                    pb: 13,
                    pt: { xs: 2, sm: 4 },
                    px: { xs: 2, sm: 4 },
                }}
            >
                <UserTable
                    inDrawer
                    disabled={loading || loadingAuthorsData}
                    disabledUserIds={alreadyAddedAuthors}
                    loading={loadingAuthorsData}
                    noResultsLabel={translate('noResults.authors')}
                    paginationProps={paginationProps}
                    paginationTranslationKey="author"
                    searchProps={searchProps}
                    selectionProps={selectionProps}
                    users={authors}
                    onSelectUsers={setSelectedAuthorIds}
                />
            </Box>

            <PageDrawerFooter>
                <Box display="flex" flexDirection="column">
                    <Button
                        color="primary"
                        disabled={loading || !selected?.length}
                        loading={loading}
                        variant="contained"
                        onClick={async () => {
                            await onAddAuthors(selectedAuthorIds);

                            resetSettings();
                        }}
                    >
                        {!selected?.length
                            ? translate('authorSelector.addNewAuthorsTitle')
                            : translate('authorSelector.addAuthor', {
                                  count: selected.length,
                              })}
                    </Button>
                </Box>
            </PageDrawerFooter>
        </PageDrawer>
    );
};
