import { useState, useEffect } from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { FieldProps } from 'formik';
import Slider, { SliderProps } from '@mui/material/Slider';

import { Typography } from 'common/components/Typography';

interface IProps extends Omit<SliderProps, 'onChange'>, FieldProps {
    label?: string;
    className?: string;
    helperText?: string;
    range?: boolean;
    onChange?: (name: string, value: number | number[]) => void;
}

export const SliderField = ({
    field,
    label,
    className,
    helperText,
    range,
    onChange,
    ...other
}: IProps) => {
    const { value, name } = field;

    const [curValue, setCurValue] = useState(value);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const {
        form: { setFieldValue },
    } = other;

    const handleChange = (newValue: number | number[]) => {
        if (value === newValue) return;

        setCurValue(newValue);

        // Add debounce before setting new field value
        if (timer) clearTimeout(timer);

        setTimer(
            setTimeout(() => {
                onChange?.(name, newValue);

                setFieldValue(name, newValue);
            }, 500)
        );
    };

    useEffect(() => {
        if (value !== curValue) setCurValue(value);

        // Clear timer when component is unmounted
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [value]);

    return (
        <FormControl
            fullWidth
            className={className}
            margin="normal"
            variant="standard"
        >
            <Typography>{label}</Typography>

            <Box mt={1} mx={0.75}>
                <Slider
                    defaultValue={0}
                    value={curValue}
                    valueLabelDisplay="auto"
                    onChange={(_event, value) => handleChange(value)}
                    {...other}
                />
            </Box>

            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};
