import { Location, useLocation, useNavigate } from 'react-router-dom';

import { IGlobalDrawer, IGlobalDrawerType } from 'common/types';
import { ROUTES } from 'route/constants/routes';
import { isUUID } from 'common/utils/isUUID';
import { TRouteName } from 'route/types';
import { useCurrentPath } from 'route/hooks/useCurrentPath';

type TGlobalDrawerRouteSettings = {
    [key in IGlobalDrawerType]: {
        route?: string;
        routes?: TRouteName[];
        idIsMandatory?: boolean;
    };
};

const GLOBAL_DRAWERS_SETTINGS: TGlobalDrawerRouteSettings = {
    [IGlobalDrawerType.Offer]: {
        route: ROUTES.OFFER_DETAIL_DRAWER,
        idIsMandatory: true,
    },
    [IGlobalDrawerType.OfferEvent]: {
        route: ROUTES.OFFER_EVENT_DETAIL_DRAWER,
        idIsMandatory: true,
    },
    [IGlobalDrawerType.PortfolioItem]: {
        route: ROUTES.PORTFOLIO_ITEM_DRAWER,
        idIsMandatory: true,
    },
    [IGlobalDrawerType.UserCreate]: {},
    [IGlobalDrawerType.UserUpdate]: {},
    [IGlobalDrawerType.UserProfile]: {},
    [IGlobalDrawerType.TrainingAssignmentGrade]: {
        routes: ['TRAINING_ASSIGNMENT_GRADE', 'TRAINING_ASSIGNMENT_FOR_USER'],
    },
    [IGlobalDrawerType.TrainingAssignmentDetail]: {
        routes: ['TRAINING_ASSIGNMENT'],
    },
    [IGlobalDrawerType.PortfolioManagementSettings]: {
        routes: ['USER_PORTFOLIO_SETTINGS'],
    },
    [IGlobalDrawerType.OfferManagementSettings]: {
        routes: ['OFFER_SETTINGS_EDIT'],
    },
    [IGlobalDrawerType.Group]: {
        routes: ['GROUP_EDIT'],
    },
    [IGlobalDrawerType.SkillCreate]: {
        routes: ['SKILL_CREATE'],
    },
    [IGlobalDrawerType.SkillUpdate]: {
        routes: ['SKILL_EDIT'],
    },
};

/*
 *   This hook is used to determine which global drawer should be opened based on the route
 */
export const useGlobalDrawerRouting = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = useCurrentPath();

    // Opens the global drawer route keeping the current url params intact
    const openGlobalDrawerRoute = (
        globalDrawer: IGlobalDrawer,
        location: Location // Location is passed as a parameter to avoid stale closure
    ) => {
        const routeSettings = GLOBAL_DRAWERS_SETTINGS[globalDrawer.type];

        // If no route is set we do not want to open it
        if (!routeSettings.route) return;

        const searchParams = new URLSearchParams(location.search);

        let globalDrawerRoute = '';

        if (globalDrawer.itemId) {
            searchParams.set('id', globalDrawer.itemId);
            globalDrawerRoute = `?${searchParams}`;
        }

        globalDrawerRoute = `${globalDrawerRoute}${
            GLOBAL_DRAWERS_SETTINGS[globalDrawer.type].route
        }`;

        navigate(`${location.pathname}${globalDrawerRoute}`);
    };

    // Closes the global drawer route keeping the previously existing url params intact
    // Location is passed as a parameter to avoid stale closure
    const closeGlobalDrawerRoute = (location: Location) => {
        const searchParams = new URLSearchParams(location.search);

        searchParams.delete('id');

        const hasSearchParams = !!searchParams.size;

        navigate(
            `${location.pathname}${
                hasSearchParams ? `?${searchParams.toString()}` : ''
            }`,
            { replace: true }
        );
    };

    // Sets the correct global drawer type based on the route
    const globalDrawerType = Object.keys(GLOBAL_DRAWERS_SETTINGS).find(
        (key) => {
            const { route, routes, idIsMandatory } =
                GLOBAL_DRAWERS_SETTINGS[key];

            // Check if the current path matches any of the routes of a drawer (either path or hash)
            if (!!routes?.length && !!currentPath) {
                let keyFound = false;

                routes.forEach((route) => {
                    const currentRoute = ROUTES[route];

                    if (
                        currentPath.route.path === ROUTES[route] ||
                        (location.hash === currentRoute && !keyFound)
                    ) {
                        keyFound = true;
                    }
                });

                if (keyFound) return key;
            }

            const routeMatches = route === location.hash;

            // If there is no matching route do not open any global drawer
            if (!routeMatches) return undefined;

            // If the route matches but the id is mandatory, open the global drawer if a correct id is provided
            if (idIsMandatory) {
                const searchParams = new URLSearchParams(location.search);
                const itemId = searchParams.get('id');

                if (!itemId || !isUUID(itemId)) return undefined;
            }

            return key;
        }
    );

    return { globalDrawerType, openGlobalDrawerRoute, closeGlobalDrawerRoute };
};
