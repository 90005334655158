import { Field, FieldProps } from 'formik';

import { Autocomplete, MultiSelect } from 'common/components/FormField';
import { useExtraTrainingFieldsQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { EFilterType, IFilterFormItem, TFilterFormValue } from 'common/types';
import {
    getExtraFieldsName,
    isExtraChoiceField,
} from 'common/utils/extraFields';

export interface ITrainingExtraFieldsInputOption {
    id: string;
    name: string;
}

interface IFieldComponentProps extends FieldProps {
    onChange?: (name: string, value: unknown) => void;
}

export const useExtraFieldsFilters = (): {
    extraCategoryFilters: IFilterFormItem[];
    loading: boolean;
} => {
    const { showApolloError } = useApolloError();
    const {
        data: extraTrainingFieldsData,
        loading: extraTrainingFieldsLoading,
    } = useExtraTrainingFieldsQuery({
        onError: showApolloError,
    });

    const { extraTrainingFields } = extraTrainingFieldsData || {};

    const extraCategoryFilters =
        extraTrainingFields
            ?.map((extraUserField) => {
                if (!isExtraChoiceField(extraUserField)) return null;

                const { name, possibleValues, multiple, filter } =
                    extraUserField;

                if (!filter) return null;

                const fieldName = `extraFields-${getExtraFieldsName(name)}`;

                const options = possibleValues.map((value) => ({
                    id: value,
                    name: value,
                }));

                const Component = ({
                    onChange,
                    ...other
                }: IFieldComponentProps) => {
                    const {
                        form: { setFieldValue },
                    } = other;

                    const handleChange = (
                        name: string,
                        value: TFilterFormValue
                    ) => {
                        onChange?.(name, value);
                        setFieldValue(name, value);
                    };

                    return (
                        <Field
                            component={multiple ? MultiSelect : Autocomplete}
                            getOptionLabel={(
                                option: ITrainingExtraFieldsInputOption
                            ) => option.name}
                            InputProps={{ label: name }}
                            isOptionEqualToValue={(
                                option: ITrainingExtraFieldsInputOption,
                                value: ITrainingExtraFieldsInputOption
                            ) => option.id === value.id}
                            key={name}
                            label={name}
                            name={fieldName}
                            options={options}
                            value={other.field.value || (multiple ? [] : null)}
                            onChange={handleChange}
                            {...other}
                        />
                    );
                };

                return {
                    key: `userExtraFields-${name}`,
                    label: name,
                    fields: [
                        {
                            name: fieldName,
                            type: EFilterType.Object,
                            label: name,
                            initialValue: multiple ? [] : null,
                            component: Component,
                            chip: {
                                label: name,
                            },
                            parseQueryValue: (value: TFilterFormValue) => value,
                        },
                    ],
                };
            })
            .filter(Boolean) || [];

    return { extraCategoryFilters, loading: extraTrainingFieldsLoading };
};
