import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link/Link';
import { FormGroupSelector } from 'user/components/FormField/FormGroupSelector';
import { IRole, useCurrentUserQuery } from 'graphql/types';
import { IUserFormValues } from 'user/components/UserUpdateDrawer';

interface IProps {
    values: IUserFormValues;
}

export const UserGroupsFormSection = ({ values }: IProps) => {
    const [translate] = useTranslation();
    const { data: currentUserData } = useCurrentUserQuery();

    const { currentUser } = currentUserData || {};

    const isManager = currentUser?.roles?.includes(IRole.Manager);

    return (
        <FormSection
            description={
                <Trans
                    components={{
                        a: <Link rel="noreferrer" underline="always" />,
                    }}
                    i18nKey="userForm.groupsDescription"
                />
            }
            title={translate('userForm.groupsTitle')}
        >
            <Box mt={3}>
                <FormGroupSelector
                    checkGroupPermission={{ canAddParticipants: true }}
                    label={translate('userForm.groupsAddAsParticipant')}
                    name="participantGroups"
                    value={values?.participantGroups || []}
                />
            </Box>

            {isManager && (
                <Box mt={3}>
                    <FormGroupSelector
                        checkGroupPermission={{
                            canAddManagers: true,
                        }}
                        label={translate('userForm.groupsAddAsGroupManager')}
                        name="managerGroups"
                        value={values?.managerGroups || []}
                    />
                </Box>
            )}
        </FormSection>
    );
};
