import { Box, Grid } from '@mui/material';

interface IProps {
    children?: React.ReactNode;
    description: string;
}

export const InfoRow = ({ children, description }: IProps) => (
    <Box>
        <Grid container spacing={{ xs: 1.5, sm: 1 }} sx={{ lineHeight: 1.2 }}>
            <Grid item sm={3} sx={{ fontWeight: 'bold' }} xs={12}>
                {description}
            </Grid>
            {children && (
                <Grid item xs>
                    {children}
                </Grid>
            )}
        </Grid>
    </Box>
);
