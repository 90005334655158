import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { mdiClose } from '@mdi/js';
import { Crisp } from 'crisp-sdk-web';

import { AlertDialog, AlertDialogProps } from 'common/components/AlertDialog';
import { Typography } from 'common/components/Typography';
import { Button } from 'common/components/Button';
import { IconButton } from 'common/components/IconButton';

interface IProps extends AlertDialogProps {
    onClose?(): void;
}

export const CustomPlanModal = ({ onClose, ...other }: IProps) => {
    const { t: translate } = useTranslation();

    const handleClickChat = () => {
        Crisp.chat.show();

        onClose?.();
    };

    const title = (
        <Box alignItems="center" display="flex">
            <Typography fontWeight={700}>
                {translate('plans.upgradeModal.customPlan.title')}
            </Typography>

            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    const actions = (
        <Button
            color="primary"
            sx={{ mt: 2 }}
            variant="contained"
            onClick={handleClickChat}
        >
            {translate('startChat')}
        </Button>
    );

    return (
        <AlertDialog
            {...other}
            actions={actions}
            title={title}
            onClose={onClose}
        >
            <Typography>
                {translate('plans.upgradeModal.customPlan.text')}
            </Typography>
        </AlertDialog>
    );
};
