import { TableElementProps } from '@udecode/slate-plugins';
import { Box, Table, TableContainer, TableBody } from '@mui/material';

export const EditorTable = ({
    children,
    htmlAttributes,
    className,
    attributes,
}: TableElementProps) => (
    <Box className={className} {...attributes} mb={2}>
        <TableContainer>
            <Table {...htmlAttributes}>
                <TableBody>{children}</TableBody>
            </Table>
        </TableContainer>
    </Box>
);
