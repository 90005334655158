import {
    TableCell as MuiTableCell,
    TableCellProps,
    SxProps,
    Box,
    Theme,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

const tableCellStyle: SxProps = {
    padding: 1,

    '> p': {
        margin: 0,
    },
};

const verticalTextStyle: SxProps = {
    width: 0,
    whiteSpace: 'nowrap',
    verticalAlign: 'bottom',

    '> .MuiBox-root': {
        pt: 2, // Because it's rotated we need to add padding top
        maxHeight: '150px',
        margin: 'auto',
        writingMode: 'vertical-lr',
        transform: 'rotate(180deg)',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
};

interface IProps extends TableCellProps {
    sx?: SxProps<Theme>;
    vertical?: boolean;
    dividerHead?: boolean;
}

export const TableCell = ({
    scope,
    sx,
    vertical,
    dividerHead,
    children,
    ...other
}: IProps) => {
    let content = children;

    if (vertical) {
        // Add wrapper Box to rotate text
        content = <Box>{children}</Box>;
    }

    return (
        <MuiTableCell
            {...other}
            sx={[
                tableCellStyle,
                // Set width 100% if scope is row
                scope === 'row' && { width: '100%' },
                // Apply vertical text style if vertical is set
                vertical && verticalTextStyle,
                dividerHead && { color: 'grey.500', px: 0 },
                // Spread sx from props
                ...withSxProp(sx),
            ]}
        >
            {content}
        </MuiTableCell>
    );
};
