import { useNavigate } from 'react-router-dom';
import { GraphQLError } from 'graphql';
import { useTranslation } from 'react-i18next';
import { ApolloError, GraphQLErrors } from '@apollo/client/errors';

import { useChangePasswordMutation } from 'graphql/types';
import { ChangePasswordForm } from 'user/components/forms/ChangePasswordForm';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getErrorMessage } from 'common/utils/getErrorMessage';
import { getUrl } from 'route/utils/getUrl';

interface IChangePasswordErrors {
    invalidError?: GraphQLError;
    otherErrors: GraphQLError[];
}

const parseError = (errors: GraphQLErrors) =>
    // Split the invalidError and the rest so we can show the invalid error as a
    // form field error.
    errors.reduce(
        (acc: IChangePasswordErrors, e: GraphQLError) => {
            if (e?.extensions?.code === 'PasswordInvalid') {
                return { ...acc, invalidError: e };
            }

            return {
                ...acc,
                otherErrors: [...acc.otherErrors, e],
            };
        },
        {
            invalidError: undefined,
            otherErrors: [],
        }
    ) as IChangePasswordErrors;

interface IProps {
    onSuccess?(): void;
}

export const ChangePassword = ({ onSuccess }: IProps) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const [displaySnackbar] = useSnackbar();

    const [changePassword] = useChangePasswordMutation();

    return (
        <ChangePasswordForm
            onSubmit={async (value, helpers) => {
                const { password } = value;

                try {
                    await changePassword({
                        variables: { password },
                    });
                } catch (error) {
                    const { graphQLErrors } = error as ApolloError;
                    const { invalidError, otherErrors } =
                        parseError(graphQLErrors);

                    // Set field error when an "PasswordInvalid" is returned
                    if (invalidError) {
                        helpers.setFieldError(
                            'password',
                            JSON.parse(invalidError.message).join(', ')
                        );
                    }

                    // Show snackbar for other errors
                    otherErrors.forEach((e) => {
                        displaySnackbar(getErrorMessage(e));
                    });

                    helpers.setSubmitting(false);

                    return;
                }

                // If onSuccess is given we want to call it and stop there.
                if (onSuccess) {
                    onSuccess();

                    return;
                }

                displaySnackbar(translate('passwordChanged'), {
                    variant: 'success',
                });

                // Redirect to Home when password has been changed
                navigate(getUrl('HOME'));
            }}
        />
    );
};
