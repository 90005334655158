import { styled } from 'styled-components';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'common/components/Tooltip';
import { ActionButton } from 'common/components/ActionButton';

export const BaseDescriptionButton = (
    props: React.ComponentProps<typeof ActionButton>
) => {
    const [translate] = useTranslation();

    return (
        <Box ml={1}>
            <Tooltip title={translate<string>('explanation')}>
                <Box sx={{ display: 'flex' }}>
                    <ActionButton outlined {...props}>
                        ?
                    </ActionButton>
                </Box>
            </Tooltip>
        </Box>
    );
};

export const DescriptionButton = styled(BaseDescriptionButton)`
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.palette.grey[100]};

    .MuiFab-label {
        font-weight: 700;
    }
`;
