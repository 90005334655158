import { Box, SxProps } from '@mui/material';
import { mdiCalendarBlank, mdiClockTimeFive, mdiTag } from '@mdi/js';

import { Icon } from 'common/components/Icon';
import { capitalize } from 'common/utils/capitalize';
import {
    absoluteDateConditionsToString,
    parseConditions,
} from 'common/utils/parseConditions';
import { IConditionFragment } from 'graphql/types';
import { withSxProp } from 'common/utils/props';

const infoWrapper: SxProps = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    fontWeight: 700,
    mr: 2,
    pt: 0.5,

    svg: {
        mr: '5px',
    },
};

interface IProps {
    conditions?: IConditionFragment[] | null;
    estimatedTimeSpent?: string | null;
    extraTrainingFieldsLabels?: string[] | null;
    sx?: SxProps;
}

export const TrainingInfo = ({
    conditions,
    estimatedTimeSpent,
    extraTrainingFieldsLabels,
    sx,
}: IProps) => {
    if (
        !conditions?.length &&
        !estimatedTimeSpent &&
        !extraTrainingFieldsLabels?.length
    ) {
        return null;
    }

    let dateCondition;

    if (conditions) {
        const { absoluteDates } = parseConditions(conditions);

        dateCondition = absoluteDates.length
            ? capitalize(
                  absoluteDateConditionsToString(
                      absoluteDates,
                      'd MMM yyyy',
                      'd MMM yyyy'
                  )
              )
            : undefined;
    }

    return (
        <Box sx={[{ display: 'flex', flexWrap: 'wrap' }, ...withSxProp(sx)]}>
            {!!dateCondition && (
                <Box sx={infoWrapper}>
                    <Icon path={mdiCalendarBlank} size="1.6rem" />
                    {dateCondition}
                </Box>
            )}

            {!!estimatedTimeSpent && (
                <Box sx={infoWrapper}>
                    <Icon path={mdiClockTimeFive} size="1.6rem" />
                    {estimatedTimeSpent}
                </Box>
            )}

            {extraTrainingFieldsLabels?.map((label, index) => (
                <Box key={index} sx={infoWrapper}>
                    <Icon path={mdiTag} size="1.6rem" />
                    {label}
                </Box>
            ))}
        </Box>
    );
};
