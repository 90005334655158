import { styled } from 'styled-components';

import PluvoLogo from 'assets/images/pluvo-logo.svg';
import { useOrganisationQuery } from 'graphql/types';

interface IProps {
    /** Force showing pluvo logo */
    pluvo?: boolean;
}

export const BaseLogo = ({ pluvo, ...other }: IProps) => {
    // When pluvo is given we want to skip calling the query, this
    // will let the logo fallback to the default Pluvo logo
    const { data } = useOrganisationQuery({ skip: pluvo });

    const organisation = data?.organisation;
    const { logo, name } = organisation || {};

    const src = logo?.url || PluvoLogo;
    const alt = logo?.url && name ? name : 'Pluvo';

    return <img alt={alt} src={src} {...other} />;
};

export const Logo = styled(BaseLogo)`
    display: block;
`;
