import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { Loader } from 'common/components/Loader';
import { IVideoListItemFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { useVideoList } from 'video/hooks';
import { VideoAddList } from 'video/components/VideoAddList';

interface IProps extends IPageDrawerProps {
    addedVideos: IVideoListItemFragment[];
    selectedVideos: IVideoListItemFragment[];
    onAddVideos(): void;
    onDrawerOpen(open: boolean): void;
    onRemoveVideo(video: IVideoListItemFragment): void;
    onSelectVideo(video: IVideoListItemFragment): void;
}

export const VideoSelectDrawer = ({
    addedVideos,
    selectedVideos,
    open,
    onAddVideos,
    onDrawerOpen,
    onRemoveVideo,
    onSelectVideo,
}: IProps) => {
    const [translate] = useTranslation();
    const {
        videos,
        loading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useVideoList(false);

    const resetSettings = () => {
        handleSearch('');
        setPage(1);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('addVideosTitle')}
            onClose={() => {
                onDrawerOpen(false);
                resetSettings();
            }}
        >
            <>
                <Box
                    sx={{
                        pb: 22.5,
                        pt: { xs: 2, sm: 4 },
                        px: { xs: 2, sm: 4 },
                    }}
                >
                    <FilterBar
                        useFilterDrawer
                        disabled={loading}
                        filterDrawerProps={{
                            title: translate('filterForm.videos.title'),
                            description: translate(
                                'filterForm.videos.description'
                            ),
                        }}
                        formFilters={filters}
                        formFilterValues={filterValues}
                        initialSearchValue={filterValues.q}
                        placeholder={translate('filterBarPlaceholder')}
                        onFiltersChange={(values) => {
                            setPage(1);

                            onFilterChange?.(values);
                        }}
                        onSearch={handleSearch}
                        onSearchClear={() => handleSearch('')}
                    />

                    {loading && <Loader />}

                    {open && !loading && (
                        <VideoAddList
                            addedVideos={addedVideos}
                            selectedVideos={selectedVideos}
                            videos={videos}
                            onAddVideo={onSelectVideo}
                            onRemoveVideo={(
                                selectedVideo: IVideoListItemFragment
                            ) => onRemoveVideo(selectedVideo)}
                        />
                    )}
                </Box>

                <PageDrawerFooter>
                    <Box display="flex" flexDirection="column">
                        {paginationSettings.count > -1 && (
                            <Box mb={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={
                                        paginationSettings.count === 1
                                            ? translate('video')
                                            : translate('videos')
                                    }
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        )}

                        <Button
                            color="primary"
                            disabled={!selectedVideos.length}
                            variant="contained"
                            onClick={() => {
                                onAddVideos();
                                resetSettings();
                            }}
                        >
                            {!selectedVideos.length
                                ? translate('addVideosTitle')
                                : translate('addVideos', {
                                      count: selectedVideos.length,
                                  })}
                        </Button>
                    </Box>
                </PageDrawerFooter>
            </>
        </PageDrawer>
    );
};
