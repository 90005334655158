import {
    IContentTypeValue,
    IExtraCategoryChoiceFieldFragment,
    IExtraCategoryOption,
    IExtraCategoryValueFragment,
    useExtraCategoriesQuery,
    useUpdateExtraCategoryValuesMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    extraCategoryFormValuesAreUpdated,
    getExtraCategoryInitialFormValues,
    getExtraCategoryValues,
} from 'organisation/utils/extraCategory';
import { TExtraCategoryInput } from 'organisation/types';

interface IProps {
    contentType: IContentTypeValue;
    modelId: string;
    skipQuery?: boolean;
    typeNames: string[];
}

/**
 * Hook to update the extra category values for a model
 * @param contentType The content type of the model
 * @param modelId The ID of the model
 * @param skipQuery Whether to skip the query to get the extra categories
 * @param typeName The names of the types to update in the cache
 */
export const useExtraCategoryValues = ({
    contentType,
    modelId,
    skipQuery,
    typeNames,
}: IProps) => {
    const { showApolloError } = useApolloError();

    const { data, loading } = useExtraCategoriesQuery({
        variables: { contentType },
        skip: skipQuery,
        onError: showApolloError,
    });
    const [
        updateExtraCategoryValues,
        { loading: updateExtraCategoryValuesLoading },
    ] = useUpdateExtraCategoryValuesMutation();

    const { extraCategories } = data || {};

    const handleUpdateExtraCategoryValues = async (
        extraCategoryValues: IExtraCategoryValueFragment[],
        newExtraCategoryValues: TExtraCategoryInput
    ) => {
        // Get the initial form values for the extra categories to be able to compare them
        // with the new ones
        const initialExtraCategoryValues = getExtraCategoryInitialFormValues(
            extraCategories,
            extraCategoryValues
        );

        const categoryValuesAreUpdated = extraCategoryFormValuesAreUpdated(
            extraCategories,
            initialExtraCategoryValues,
            newExtraCategoryValues
        );

        if (!categoryValuesAreUpdated) return;

        const newValues = getExtraCategoryValues(
            newExtraCategoryValues,
            extraCategories
        );

        try {
            await updateExtraCategoryValues({
                variables: {
                    contentType,
                    modelId,
                    values: newValues,
                },
                update: (cache, result) => {
                    const { updateExtraCategoryValues } = result.data || {};
                    const { extraCategoryValues: updatedExtraCategoryValues } =
                        updateExtraCategoryValues || {};

                    if (!updatedExtraCategoryValues) return;

                    typeNames.forEach((typeName) => {
                        cache.modify({
                            id: `${typeName}:${modelId}`,
                            fields: {
                                extraCategoryValues(
                                    _existingValues = [],
                                    { toReference }
                                ) {
                                    // Replace the old extra category values with the new ones
                                    return (
                                        updatedExtraCategoryValues as IExtraCategoryValueFragment[]
                                    ).map((value) => {
                                        if (
                                            value?.category.categoryType ===
                                            IExtraCategoryOption.Choice
                                        ) {
                                            const { choiceValue } =
                                                value as IExtraCategoryChoiceFieldFragment;

                                            return toReference({
                                                ...value,
                                                choiceValue:
                                                    toReference(choiceValue),
                                            });
                                        }

                                        return toReference(value);
                                    });
                                },
                            },
                        });
                    });
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        return;
    };

    return {
        extraCategories,
        loading: loading || updateExtraCategoryValuesLoading,
        updateExtraCategoryValuesLoading,
        handleUpdateExtraCategoryValues,
    };
};
