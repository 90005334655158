import { TPlanPackage } from 'plans/types';
import { translate } from 'utils/i18n';

export const PLAN_PACKAGES: Record<string, TPlanPackage> = {
    professional: {
        title: translate('plans.professional.title'),
        description: translate('plans.professional.description'),
        features: [
            translate('plans.professional.features.feature1'),
            translate('plans.professional.features.feature2'),
            translate('plans.professional.features.feature3'),
            translate('plans.professional.features.feature4'),
            translate('plans.professional.features.feature5'),
            translate('plans.professional.features.feature6'),
            translate('plans.professional.features.feature7'),
            translate('plans.professional.features.feature8'),
        ],
        order: 0,
        weight: 1,
    },
    team: {
        title: translate('plans.team.title'),
        description: translate('plans.team.description'),
        features: [
            translate('plans.team.features.feature1'),
            translate('plans.team.features.feature2'),
            translate('plans.team.features.feature3'),
            translate('plans.team.features.feature4'),
            translate('plans.team.features.feature5'),
            translate('plans.team.features.feature6'),
            translate('plans.team.features.feature7'),
        ],
        order: 1,
        topChoice: true,
        weight: 2,
    },
    company: {
        title: translate('plans.company.title'),
        description: translate('plans.company.description'),
        features: [
            translate('plans.company.features.feature1'),
            translate('plans.company.features.feature2'),
            translate('plans.company.features.feature3'),
            translate('plans.company.features.feature4'),
            translate('plans.company.features.feature5'),
            translate('plans.company.features.feature6'),
            translate('plans.company.features.feature7'),
            translate('plans.company.features.feature8'),
        ],
        order: 2,
        weight: 3,
    },
};
