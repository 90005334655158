import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'common/constants/languages';
import { TQueryParams } from 'common/utils/getSearchUrl';
import { TFilterBarItem } from 'common/types';
import { getExtraCategoryQueryParams } from 'organisation/utils/extraCategory';
import { getSelectedFilters } from 'common/utils/filter';

export const useCourseTemplateSearch = () => {
    const [translate] = useTranslation();

    const [searchQueryParam, setSearchQueryParam] = useState('');
    const [queryParams, setQueryParams] = useState<TQueryParams>({});

    const filters = useMemo(
        () => [
            {
                name: translate('language'),
                value: 'language',
                singleValue: true,
                children: Object.entries(LANGUAGES).map(([value, label]) => ({
                    name: translate(label),
                    value,
                    parent: 'language',
                })),
            },
        ],
        []
    );

    const selectedFilters = useMemo(
        () => getSelectedFilters(queryParams, filters),
        [queryParams, filters]
    );

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        const queryParams = getExtraCategoryQueryParams(selected);

        setQueryParams(queryParams);
    };

    return {
        filters,
        searchQueryParam: searchQueryParam || '',
        queryParams,
        selectedFilters,
        handleFilterSelect,
        handleSearch,
    };
};
