import { Formik, Field } from 'formik';
import { Box, useMediaQuery, Theme, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { IContentTypeValue } from 'graphql/types';
import { ExtraCategoryFormFields } from 'organisation/components/ExtraCategoryFormFields';
import { TCourseFormValues } from 'training/types';
import { Divider } from 'common/components/Divider';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { ClipboardCopyInput, ImageField } from 'common/components/FormField';
import { Button } from 'common/components/Button';
import { CARD_IMAGE_MAX_SIZE, CARD_IMAGE_TYPE } from 'common/constants/files';
import { Banner } from 'common/components/Banner';
import { Img } from 'common/components/Img';
import ModuleCardImage from 'assets/images/module-card-image.webp';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';

interface IProps {
    disabled?: boolean;
    isEdit?: boolean;
    initialValues: TCourseFormValues;
    onDelete?(): void;
    onSubmit(values: TCourseFormValues): void;
}

export const CourseForm = ({
    disabled,
    isEdit,
    onDelete,
    onSubmit,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    return (
        <Formik enableReinitialize onSubmit={onSubmit} {...other}>
            {({ submitForm, isSubmitting, dirty }) => (
                <>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Box mb={3}>
                        <Field
                            component={ClipboardCopyInput}
                            label={translate('courseForm.uniqueId')}
                            name="id"
                        />
                    </Box>

                    <Divider />

                    <FormSection
                        description={
                            <Trans
                                components={{
                                    a: (
                                        <Link
                                            rel="noreferrer"
                                            underline="always"
                                        />
                                    ),
                                }}
                                i18nKey="courseForm.imageDescription"
                            />
                        }
                        title={translate('image')}
                    >
                        <Box mt={3}>
                            <Grid
                                container
                                alignItems="stretch"
                                direction={
                                    smallScreen ? 'column-reverse' : 'row'
                                }
                                spacing={2}
                            >
                                <Grid item sm xs={12}>
                                    <Field
                                        acceptedFileTypes={CARD_IMAGE_TYPE}
                                        component={ImageField}
                                        helperText={translate(
                                            'imageSizeDescription',
                                            {
                                                width: 400,
                                                height: 150,
                                            }
                                        )}
                                        label={translate('image')}
                                        maxUploadSize={CARD_IMAGE_MAX_SIZE}
                                        name="thumbnail"
                                    />
                                </Grid>
                                <Grid item sm xs={12}>
                                    <Banner>
                                        <Box
                                            sx={{
                                                py: 1,
                                                pr: 1,
                                                pl: 2,
                                            }}
                                        >
                                            {translate(
                                                'courseForm.imageBannerDescription'
                                            )}
                                        </Box>
                                        <Box mt="auto">
                                            <Img src={ModuleCardImage} />
                                        </Box>
                                    </Banner>
                                </Grid>
                            </Grid>
                        </Box>
                    </FormSection>

                    <FormSection
                        description={
                            <Box>
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="courseForm.categoriesDescription"
                                />
                            </Box>
                        }
                        title={translate('courseForm.categories')}
                    >
                        <Box mt={2}>
                            <ExtraCategoryFormFields
                                contentType={IContentTypeValue.Course}
                            />
                        </Box>
                    </FormSection>

                    <FormSection
                        description={
                            <Box>
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="courseForm.lti1_1Description"
                                />
                            </Box>
                        }
                        title={translate('courseForm.lti1_1')}
                    >
                        <Box mt={2}>
                            <Field
                                component={ClipboardCopyInput}
                                label={translate('courseForm.ltiLaunchUrl')}
                                name="ltiLaunchUrl"
                            />
                            <Field
                                component={ClipboardCopyInput}
                                label={translate('courseForm.ltiKey')}
                                name="ltiKey"
                            />
                            <Field
                                component={ClipboardCopyInput}
                                label={translate('courseForm.ltiSecret')}
                                name="ltiSecret"
                            />
                        </Box>
                    </FormSection>

                    <Box sx={{ display: 'flex', mt: 4 }}>
                        <Button
                            color="primary"
                            disabled={
                                isSubmitting ||
                                disabled ||
                                (!isSubmitting && !disabled && !dirty)
                            }
                            loading={isSubmitting}
                            type="submit"
                            variant="contained"
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault();

                                return submitForm();
                            }}
                        >
                            {translate('courseForm.editButton')}
                        </Button>

                        <Box ml="auto">
                            <FormDeleteButton
                                disabled={isSubmitting}
                                onClick={() => onDelete?.()}
                            >
                                {translate('delete')}
                            </FormDeleteButton>
                        </Box>
                    </Box>
                </>
            )}
        </Formik>
    );
};
