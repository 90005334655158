import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { styled } from 'styled-components';
import {
    mdiCheckboxBlank,
    mdiCheckboxBlankOff,
    mdiCheckboxMarked,
    mdiMinusBox,
} from '@mdi/js';
import { rgba } from 'polished';

import { TColorKeys } from 'theme/palette';
import { Icon } from 'common/components/Icon';

interface IProps extends Omit<CheckboxProps, 'color'> {
    color?: TColorKeys;
    isSelectAll?: boolean;
}

const BaseCheckbox = ({ checked, isSelectAll, disabled, ...other }: IProps) => (
    <MuiCheckbox
        icon={
            <Icon
                path={disabled ? mdiCheckboxBlankOff : mdiCheckboxBlank}
                size="2.4rem"
            />
        }
        {...other}
        checked={checked}
        checkedIcon={<Icon path={mdiCheckboxMarked} size="2.4rem" />}
        color="default"
        disabled={disabled}
        indeterminateIcon={<Icon path={mdiMinusBox} size="2.4rem" />}
    />
);
export const Checkbox = styled(BaseCheckbox)`
    &.Mui-disabled {
        ${({ theme }) => `color: ${rgba(theme.palette.body.main, 0.1)};`}
    }

    &:hover {
        background-color: ${({ theme, checked, indeterminate, isSelectAll }) =>
            isSelectAll && (indeterminate || checked)
                ? rgba(theme.palette.primary.contrastText, 0.2)
                : 'none'};
    }

    ${({ theme, color, checked, indeterminate, isSelectAll }) => {
        if (isSelectAll && (indeterminate || checked)) {
            return `color: ${theme.palette.primary.contrastText};`;
        }

        if (checked || indeterminate) {
            return `color: ${theme.palette.primary.main};`;
        }

        if (color) {
            return `color: ${theme.palette[color].main};`;
        }

        return `color: ${rgba(theme.palette.body.main, 0.4)};`;
    }}
`;
