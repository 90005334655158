import { Box, BoxProps, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const pageDrawerFooterStyle: SxProps = {
    position: 'fixed',
    px: { xs: 2, sm: 4 },
    py: 2,
    backgroundColor: 'grey.100',
    textAlign: 'center',
    bottom: 0,
    right: 0,
    width: '100%',
    maxWidth: '768px',
};

interface IProps extends BoxProps {
    sx?: SxProps;
}

export const PageDrawerFooter = ({ sx, ...other }: IProps) => (
    <Box sx={[pageDrawerFooterStyle, ...withSxProp(sx)]} {...other} />
);
