import { useLocation } from 'react-router-dom';
import { Stack, TableRow } from '@mui/material';

import { IPortfolioItemReportFragment } from 'graphql/types';
import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { UserAvatar } from 'user/components/UserAvatar';
import { ScoringChips } from 'common/components/ScoringChips';
import { ChipGroup } from 'common/components/Chip';
import { formatDate } from 'common/utils/formatDate';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    reportItem: IPortfolioItemReportFragment;
}

export const PortfolioReportTableRow = ({ reportItem }: IProps) => {
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const { id, title, user, scoring, startDate, endDate } = reportItem;
    const { name } = user || {};

    const startDateString = startDate && formatDate(startDate, 'dd MMMM yyyy');
    const endDateString = endDate && formatDate(endDate, 'dd MMMM yyyy');

    const isExpired = !endDate ? false : new Date(endDate) < new Date();

    if (!user) return null;

    const onClickRow = () => {
        openGlobalDrawer(
            {
                type: IGlobalDrawerType.PortfolioItem,
                itemId: id,
            },
            location
        );
    };

    return (
        <TableRow
            hover
            key={id}
            sx={{ cursor: 'pointer' }}
            onClick={onClickRow}
        >
            <TableCell>
                <Stack alignItems="center" direction="row" spacing={2}>
                    <UserAvatar size={35} user={user} />
                    <Typography variant="body2">{name}</Typography>
                </Stack>
            </TableCell>
            <TableCell>{title}</TableCell>
            <TableCell>{startDateString}</TableCell>
            <TableCell>
                <Typography
                    sx={{ color: isExpired ? 'error.main' : undefined }}
                    variant="body2"
                >
                    {endDateString}
                </Typography>
            </TableCell>
            <TableCell>
                {!!scoring?.length && (
                    <ChipGroup>
                        <ScoringChips scoring={scoring.filter(Boolean)} />
                    </ChipGroup>
                )}
            </TableCell>
        </TableRow>
    );
};
