import { useEffect, useRef } from 'react';
import { InsertPhoto } from '@mui/icons-material';
import { Avatar, Box, SxProps } from '@mui/material';

import {
    USER_PROFILE_IMAGE_TYPE,
    USER_PROFILE_IMAGE_MAX_SIZE,
} from 'common/constants/files';
import { IBasicFileFragment, IFileFragment } from 'graphql/types';
import { FilePicker } from 'common/components/FilePicker';
import { useUploadFiles } from 'common/hooks/useUploadFiles';
import { OverlayCircle } from 'common/components/OverlayCircle';

const userPreviewStyle: SxProps = {
    position: 'relative',
    width: 130,
    height: 130,
    borderRadius: '50%',
};

interface IProps {
    editable?: boolean;
    name: string;
    profileImage?: IBasicFileFragment;
    onUpload?: (file: IFileFragment) => void;
    onUploadFinish?: () => void;
    onUploadStart?: () => void;
}

export const UserPreview = ({
    editable = true,
    name,
    profileImage,
    onUpload,
    onUploadFinish,
    onUploadStart,
}: IProps) => {
    const currentImage = useRef(profileImage?.url);

    const { isUploading, uploadFiles, uploadingFiles } = useUploadFiles({
        onSuccess: (_fileIndex, updatedFile) => {
            if (!updatedFile) return;

            onUpload?.(updatedFile);
            currentImage.current = updatedFile.url;
        },
    });

    const prevIsUploading = useRef(isUploading);

    const handleUpload = (files: File[]) => {
        uploadFiles(files, USER_PROFILE_IMAGE_MAX_SIZE);
    };

    useEffect(() => {
        const { current: prevUploading } = prevIsUploading;

        if (isUploading === prevUploading) return;

        prevIsUploading.current = isUploading;

        if (!isUploading && onUploadFinish) {
            return onUploadFinish();
        }

        onUploadStart && onUploadStart();
    }, [isUploading, onUploadFinish, onUploadStart]);

    useEffect(() => {
        // When the profile image changes, update the current image
        currentImage.current = profileImage?.url;
    }, [profileImage]);

    const hoverIcon = (
        <OverlayCircle>
            <InsertPhoto />
        </OverlayCircle>
    );

    const uploadingFile = uploadingFiles.length ? uploadingFiles[0] : undefined;

    const {
        done: fileDone,
        success: fileSuccess,
        progress: fileProgress,
        errorMessage: fileErrorMessage,
    } = uploadingFile?.status || {};

    const { file } = uploadingFile || {};

    const progressValue =
        !!(uploadingFile && !fileDone && fileProgress && fileProgress >= 0) &&
        `${fileProgress}%`;

    const fileUploaded = uploadingFile && fileDone;
    const noFileUploading = !uploadingFile || fileUploaded;

    const errorMessage = fileUploaded && !fileSuccess && (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                textAlign: 'center',
                color: 'error.main',
                mt: 3,
            }}
        >
            {fileErrorMessage}
        </Box>
    );

    const fileUrl = fileSuccess ? file?.url || '' : currentImage.current || '';

    const userAvatarStyle: SxProps = {
        position: 'relative',
        width: '100%',
        height: '100%',
        fontSize: 50,
    };

    if (!editable) {
        return (
            <Box>
                <Avatar
                    alt={name}
                    src={fileUrl}
                    sx={[
                        {
                            ...userPreviewStyle,
                            display: 'flex',
                            alignSelf: 'center',
                        },
                    ]}
                />
            </Box>
        );
    }

    return (
        <div>
            <FilePicker
                hoverIcon={noFileUploading ? hoverIcon : undefined}
                id="userPreview"
                inputProps={{ accept: USER_PROFILE_IMAGE_TYPE }}
                sx={userPreviewStyle}
                onUpload={noFileUploading ? handleUpload : undefined}
            >
                <Avatar alt={name} src={fileUrl} sx={userAvatarStyle} />
                {progressValue && (
                    <OverlayCircle>{progressValue}</OverlayCircle>
                )}
            </FilePicker>
            {errorMessage}
        </div>
    );
};
