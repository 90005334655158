import { useTranslation } from 'react-i18next';
import { mdiFile } from '@mdi/js';
import { useReactiveVar } from '@apollo/client';

import { Icon } from 'common/components/Icon';
import { IFileModuleListItemFragment } from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const FileModule = ({
    title,
    file,
    disabled,
    conditions,
    alwaysOpen,
    startDate,
    conditionEffect,
    groupConditionEffect,
    canUpdate,
    trainingId,
    description,
    image,
    id,
}: TTrainingModuleProps<IFileModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    if (!file?.url) return null;

    const itemContent = (
        <>
            <ListItemMedia color="primary" image={image?.url || undefined}>
                <Icon path={mdiFile} size="1.8rem" sizeSm="3rem" />
            </ListItemMedia>
            <ListItemText primary={title} secondary={description}>
                <ModuleConditionsChips
                    alwaysOpen={alwaysOpen}
                    conditionEffect={conditionEffect}
                    conditions={conditions}
                    groupConditionEffect={groupConditionEffect}
                    startDate={startDate}
                />
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    if (!studentView && canUpdate) {
        return (
            <ListItem
                button
                component={Link}
                params={{
                    trainingId,
                    moduleId: id,
                    type: 'file',
                }}
                to="TRAINING_UPDATE_MODULE"
            >
                {itemContent}

                <ModuleSecondaryAction forUpdate />
            </ListItem>
        );
    }

    return (
        <ListItem button component={Link} href={file.url} target="_blank">
            {itemContent}

            <ModuleSecondaryAction actionText={translate('start')} />
        </ListItem>
    );
};
