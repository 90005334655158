import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type TListFilterParamType =
    | 'offer'
    | 'portfolioReport'
    | 'user'
    | 'video'
    | 'skill'
    | 'training';
export type TListFilterValue = Record<string, string>;
export type TListFilterParams = Partial<
    Record<TListFilterParamType, TListFilterValue>
>;

// Get filter params from url and set in global var so we can use it in other components
export const useUrlFilterParams = (
    type: TListFilterParamType,
    setInUrl = true
) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const cacheKey = `filterParams-${type}`;

    // Get current params either from URL or session storage
    const getCurrentParams = useCallback(() => {
        if (setInUrl) {
            return new URLSearchParams(searchParams);
        }
        const cachedData = sessionStorage.getItem(cacheKey);

        return new URLSearchParams(cachedData ? JSON.parse(cachedData) : {});
    }, [searchParams, cacheKey, setInUrl]);

    const navigateNewSearchParams = (newSearchParams: URLSearchParams) => {
        navigate(
            {
                search: `?${newSearchParams.toString()}`,
                hash: window.location.hash,
            },
            { replace: true }
        );
    };

    // Reset all filter params from sessionStorage
    const resetFilterParams = useCallback(() => {
        sessionStorage.removeItem(cacheKey);
    }, [cacheKey]);

    const updateCache = useCallback(
        (params: URLSearchParams) => {
            const urlParams: TListFilterValue = Object.fromEntries(params);

            if (Object.keys(urlParams).length) {
                sessionStorage.setItem(cacheKey, JSON.stringify(urlParams));
            } else {
                resetFilterParams();
            }
        },
        [cacheKey, resetFilterParams]
    );

    const updateUrlfromCache = useCallback(() => {
        let cachedParams = {};
        const cachedData = sessionStorage.getItem(cacheKey);

        if (!cachedData || !Object.keys(cachedData).length) return;

        cachedParams = JSON.parse(cachedData);

        // If searchParams and cachedParams are the same, don't update
        if (cachedData === JSON.stringify(Object.fromEntries(searchParams))) {
            return;
        }

        // Exclude id from searchParams as this is used for global drawers
        const newSearchParams = new URLSearchParams(cachedParams);
        if (newSearchParams.has('id')) newSearchParams.delete('id');

        if (setInUrl) {
            navigateNewSearchParams(newSearchParams);
        }
    }, [searchParams, cacheKey, navigate]);

    const setFilterParam = useCallback(
        (name: string, value?: string) => {
            const currentParams = getCurrentParams();

            if (!value) {
                currentParams.delete(name);
            } else {
                currentParams.set(name, value);
            }

            updateCache(currentParams);

            if (setInUrl) {
                navigateNewSearchParams(currentParams);
            }
        },
        [getCurrentParams, updateCache]
    );

    // Update cache when search param change
    useEffect(() => {
        const urlParams: TListFilterValue = Object.fromEntries(searchParams);

        // Update cache when there are params, to prevent emptying it when switching pages
        if (!Object.keys(urlParams).length) return;

        updateCache(searchParams);
    }, [searchParams, updateCache]);

    return {
        filterParams: Object.fromEntries(getCurrentParams()),
        searchParamsStr: getCurrentParams().toString(),
        setFilterParam,
        updateUrlfromCache,
        resetFilterParams,
    };
};
