import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ScormModuleSelectDrawer } from 'training/components/ScormModuleDrawer/ScormModuleSelectDrawer';
import {
    IScormListItemFragment,
    useCreateScormModuleMutation,
    useCreateScormMutation,
} from 'graphql/types';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { getUrl } from 'route/utils/getUrl';
import {
    IScormModuleFormProps,
    ScormModuleForm,
} from 'training/components/forms/ScormModuleForm';
import { PageDrawer } from 'common/components/PageDrawer';
import { createModuleInCache } from 'training/utils/module';
import { useHashMatch } from 'route/hooks/useHashMatch';

export const ScormModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { showApolloError } = useApolloError();
    const [searchParams, setSearchParams] = useSearchParams();

    const routeQuery = useRouteQuery();
    const scormIdParam = routeQuery.get('scorm') || undefined;
    const [selectedScormId, setSelectedScormId] = useState(scormIdParam);
    const [formDrawerOpen, setFormDrawerOpen] = useState(false);

    const [createScorm] = useCreateScormMutation();
    const [createModule] = useCreateScormModuleMutation();

    const selectDrawerOpen = !!useHashMatch('TRAINING_MODULE_SELECT');

    useEffect(() => {
        if (!scormIdParam) return;

        // If the scorm id is set we can remove the scorm search param from the url
        const { scorm, ...newSearchParams } = Object.fromEntries(searchParams);
        setSearchParams(new URLSearchParams(newSearchParams));
    }, [scormIdParam, searchParams, setSearchParams]);

    const handleSubmit: IScormModuleFormProps['onSubmit'] = async (values) => {
        const { file, image, offset, ...otherValues } = values;

        try {
            let scorm;

            if (file?.id) {
                const createScormResponse = await createScorm({
                    variables: { scorm: { scormZipId: file.id } },
                });

                scorm = createScormResponse?.data?.createScorm?.scorm;
            }

            if (!scorm && !selectedScormId) return;

            const moduleValues = {
                ...otherValues,
                imageId: image?.id,
                offset: {
                    seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                    type: offset?.type,
                },
                scormId: scorm?.id || selectedScormId,
            };

            // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
            // in the training which will make a new moduleGroup
            const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

            await createModule({
                variables: { module: moduleValues, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createScormModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.scorm.create'), {
            variant: 'success',
        });

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    return (
        <>
            <PageTitle>{translate('newScormModule')}</PageTitle>

            {!selectedScormId ? (
                <ScormModuleSelectDrawer
                    open
                    inDrawer={false}
                    onClickNew={() => setFormDrawerOpen(true)}
                    onSelect={(scorm: IScormListItemFragment) => {
                        setSelectedScormId(scorm.id);
                        navigate(window.location.pathname);
                    }}
                />
            ) : (
                <ScormModuleForm
                    moduleGroupId={moduleGroupId}
                    scormId={selectedScormId}
                    onReplaceScorm={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmit}
                />
            )}

            <ScormModuleSelectDrawer
                open={selectDrawerOpen}
                onClickNew={() => setFormDrawerOpen(true)}
                onSelect={(scorm: IScormListItemFragment) => {
                    setSelectedScormId(scorm.id);
                    navigate(window.location.pathname);
                }}
            />

            <PageDrawer
                open={formDrawerOpen}
                onClose={() => {
                    setFormDrawerOpen(false);
                }}
            >
                <ScormModuleForm
                    moduleGroupId={moduleGroupId}
                    scormId={selectedScormId}
                    onReplaceScorm={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmit}
                />
            </PageDrawer>
        </>
    );
};
