import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Switch as MuiSwitch, SwitchProps } from 'formik-mui';
import { styled } from 'styled-components';

const StyledFormControlLabel = styled(FormControlLabel)`
    .MuiTypography-root {
        font-weight: 700;
        font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    }
`;

const StyledSwitch = styled(MuiSwitch)`
    .MuiSwitch-switchBase {
        + .MuiSwitch-track {
            background-color: ${({ theme }) => theme.palette.primary.light};
        }

        &.Mui-checked {
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
`;

interface IProps extends SwitchProps {
    helperText?: string;
    label?: string;
    formControlProps?: React.ComponentProps<typeof FormControl>;
}

export const Switch = ({
    helperText,
    label,
    field,
    form,
    formControlProps,
    ...other
}: IProps) => {
    const error = form.errors[field.name];

    return (
        <FormControl
            fullWidth
            margin="dense"
            variant="standard"
            {...formControlProps}
        >
            <StyledFormControlLabel
                control={
                    <StyledSwitch
                        field={field}
                        form={form}
                        type="checkbox"
                        {...other}
                    />
                }
                label={label}
            />

            {!!helperText && (
                <FormHelperText sx={{ ml: 6 }}>{helperText}</FormHelperText>
            )}

            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
};
