import { mdiEmail } from '@mdi/js';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { DropdownMenu } from 'common/components/DropdownMenu';
import { IconButton } from 'common/components/IconButton/IconButton';
import { TrainingInviteEmailDialog } from 'training/components/TrainingInviteEmailDialog';
import { UserEmailDialog } from 'user/components/UserEmailDialog';

interface IProps {
    disabled?: boolean;
    userIds?: string[];
    trainingId?: string;
    onSendEmailComplete?(): void;
}

export const UserTrainingEmailDropdownMenu = ({
    disabled,
    userIds,
    trainingId,
    onSendEmailComplete,
}: IProps) => {
    const [translate] = useTranslation();
    const [userEmailDialogOpen, setUserEmailDialogOpen] = useState(false);
    const [
        trainingInvitationEmailDialogOpen,
        setTrainingInvitationEmailDialogOpen,
    ] = useState(false);

    return (
        <>
            <DropdownMenu
                anchor={
                    <IconButton
                        color="inherit"
                        disabled={disabled}
                        iconPath={mdiEmail}
                        iconSize="2.4rem"
                    />
                }
            >
                {!!trainingId && (
                    <MenuItem
                        onClick={() =>
                            setTrainingInvitationEmailDialogOpen(true)
                        }
                    >
                        {translate('trainingInviteEmail.buttonText')}
                    </MenuItem>
                )}

                <MenuItem onClick={() => setUserEmailDialogOpen(true)}>
                    {translate('userEmail.buttonText')}
                </MenuItem>
            </DropdownMenu>

            {!!trainingId && (
                <TrainingInviteEmailDialog
                    open={trainingInvitationEmailDialogOpen}
                    trainingId={trainingId}
                    userIds={userIds || []}
                    onClose={() => setTrainingInvitationEmailDialogOpen(false)}
                    onComplete={onSendEmailComplete}
                />
            )}

            <UserEmailDialog
                open={userEmailDialogOpen}
                userIds={userIds}
                onClose={() => setUserEmailDialogOpen(false)}
                onComplete={onSendEmailComplete}
            />
        </>
    );
};
