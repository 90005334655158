import { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Box, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Transforms, Element } from 'slate';
import { useSlate } from 'slate-react';
import { getBlockAbove } from '@udecode/slate-plugins';

import { DropdownMenu } from 'common/components/DropdownMenu';

import { options } from '../../options';

import { IMenuPosition } from './types';
import { getCurrentBlockMenu, getMenuPosition } from './utils';

export const EditorBlockMenu = () => {
    const editor = useSlate();
    const { t: translate } = useTranslation();
    const [position, setPosition] = useState<IMenuPosition>();

    const node = getBlockAbove(editor, { mode: 'highest' })?.[0];

    // We only want to continue with elements/blocks
    const element = Element.isElement(node) ? node : null;

    const menuRef = useCallback(
        (menuEl: HTMLElement | null) => {
            if (menuEl !== null && element) {
                // Add small delay to getMenuPosition to prevent issues when adding new items.
                setTimeout(() => {
                    setPosition(getMenuPosition(editor, menuEl, element));
                }, 10);
            }
        },
        [editor, element]
    );

    // We don't have a selected element so we don't have to render anything.
    if (!element) return null;

    const { top, left } = position || {};

    const BlockMenuComponent = getCurrentBlockMenu(element);

    // Don't render when there is no current BlockMenuComponent. This probably means the focused element doesn't have a
    // blockMenu
    if (!BlockMenuComponent) return null;

    const handleDelete = () => {
        Transforms.removeNodes(editor);
    };

    const editorBlockMenu = (
        <Box left={left} position="absolute" ref={menuRef} top={top}>
            <DropdownMenu
                anchor={
                    <IconButton className="actions" size="large">
                        <MoreVert />
                    </IconButton>
                }
            >
                <BlockMenuComponent {...options} />

                <MenuItem onClick={handleDelete}>
                    {translate('delete')}
                </MenuItem>
            </DropdownMenu>
        </Box>
    );

    return ReactDOM.createPortal(editorBlockMenu, document.body);
};
