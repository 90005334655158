import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { Typography } from 'common/components/Typography';
import {
    IPlanInterval,
    useOrganisationPlanInfoQuery,
    usePlanPackagesQuery,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Switch } from 'common/components/Switch';
import { currentPlanInPlans } from 'plans/utils/plan';

import { PlanPackageList } from '../PlanPackageList';

interface IProps {
    onLoadingDone(): void;
}

export const PlanSelection = ({ onLoadingDone }: IProps) => {
    const { t: translate } = useTranslation();
    const {
        data: planInfoData,
        loading: loadingPlanInfoData,
        error: planInfoError,
    } = useOrganisationPlanInfoQuery();
    const {
        data: planPackagesData,
        loading: planPackagesLoading,
        error: planPackagesError,
    } = usePlanPackagesQuery();
    const [planInterval, setPlanInterval] = useState(IPlanInterval.Year);

    const { organisation } = planInfoData || {};
    const currentPlan = organisation?.plan;

    const { planPackages } = planPackagesData || {};

    const inPlanPackages =
        currentPlan && planPackages
            ? currentPlanInPlans(currentPlan, planPackages)
            : false;

    const loading = loadingPlanInfoData || planPackagesLoading;

    useEffect(() => {
        // If current plan not loaded or current plan is not in plan packages
        if (!currentPlan || !inPlanPackages) return;

        setPlanInterval(currentPlan.interval);
    }, [currentPlan, inPlanPackages]);

    useEffect(() => {
        if (!loading) onLoadingDone();
    }, [loading, onLoadingDone]);

    const isMonth = planInterval === IPlanInterval.Month;

    return (
        <Box>
            {planInfoError && <ApolloError error={planInfoError} />}
            {planPackagesError && <ApolloError error={planPackagesError} />}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography fontWeight={700} variant="h2">
                    {translate('plans.choosePlan')}
                </Typography>

                {currentPlan && (
                    <Box mb={2}>
                        <Box>
                            {translate('plans.yourPlan')}: {currentPlan.name}
                        </Box>
                    </Box>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography color={!isMonth ? 'primary.main' : undefined}>
                        {translate('plans.yearly')}
                    </Typography>

                    <Switch
                        checked={isMonth}
                        size="large"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPlanInterval(
                                e.target.checked
                                    ? IPlanInterval.Month
                                    : IPlanInterval.Year
                            )
                        }
                    />

                    <Typography color={isMonth ? 'primary.main' : undefined}>
                        {translate('plans.monthly')}
                    </Typography>
                </Box>

                {!!planPackages && !!organisation && (
                    <Box mt={2}>
                        <PlanPackageList
                            organisation={organisation}
                            planInterval={planInterval}
                            planPackages={planPackages}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
