import MuiTab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const Tab = styled(MuiTab)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(3),
    padding: theme.spacing(2.5, 0),
    borderBottomWidth: 1,
    minWidth: 'auto',
    textTransform: 'none',
})) as typeof MuiTab;
