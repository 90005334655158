import React from 'react';

import { IConditionFragment } from 'graphql/types';
import {
    parseConditions,
    absoluteDateConditionsToString,
    relativeDateConditionsToString,
    courseConditionsToString,
    deadlineDateConditionsToString,
} from 'common/utils/parseConditions';

interface IProps {
    conditions: IConditionFragment[];
}

export const ConditionsDescription = ({ conditions }: IProps) => {
    if (!conditions.length) return null;

    // Parse condition first to get object with better structure.
    const { absoluteDates, relativeDates, courseConditions, deadlineDates } =
        parseConditions(conditions);

    let conditionsArray: React.ReactNode[] = [];

    // Absolute date conditions
    if (absoluteDates.length) {
        conditionsArray = [
            ...conditionsArray,
            absoluteDateConditionsToString(absoluteDates),
        ];
    }

    // Relative date conditions
    if (relativeDates.length) {
        conditionsArray = [
            ...conditionsArray,
            relativeDateConditionsToString(relativeDates),
        ];
    }

    // Course conditions
    if (courseConditions.length) {
        conditionsArray = [
            ...conditionsArray,
            courseConditionsToString(courseConditions),
        ];
    }

    // Deadline date conditions
    if (deadlineDates.length) {
        conditionsArray = [
            ...conditionsArray,
            deadlineDateConditionsToString(deadlineDates),
        ];
    }

    return <>{conditionsArray.join(', ')}</>;
};
