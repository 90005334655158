import { motion } from 'framer-motion';
import { styled } from 'styled-components';

import { Card } from 'common/components/Card';

interface IProps {
    children?: React.ReactNode;
}

export const BaseCardIconButton = ({ children, ...other }: IProps) => (
    <Card {...other}>
        <motion.div initial="initCardButton" whileHover="hoverCardButton">
            {children}
        </motion.div>
    </Card>
);

export const CardIconButton = styled(BaseCardIconButton)`
    border: 0;

    .MuiCardActionArea-focusHighlight {
        display: none;
    }

    .MuiLink-underlineHover:hover {
        text-decoration: none;
    }
`;
