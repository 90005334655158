import { styled } from 'styled-components';
import { mdiPencil } from '@mdi/js';

import {
    ICreateDevelopmentItemInput,
    IDevelopmentItemListItemFragment,
    IDevelopmentItemGroupFragment,
} from 'graphql/types';
import { Accordion, AccordionSummary } from 'common/components/Accordion';
import { DevelopmentItemList } from 'user/components/DevelopmentItemList';
import { DevelopmentItemQuickAdd } from 'user/components/DevelopmentItemQuickAdd';
import { IconButton } from 'common/components/IconButton';

interface IProps extends IDevelopmentItemGroupFragment {
    className?: string;
    withEditActions?: boolean;
    expanded?: boolean;
    loading?: boolean;
    onClick?: () => void;
    onClickItem(item?: IDevelopmentItemListItemFragment): void;
    onEdit(): void;
    isItemVisible: (item: IDevelopmentItemListItemFragment) => boolean;
    onQuickAddItem(values: ICreateDevelopmentItemInput): void;
}

const BaseDevelopmentItemGroup = ({
    className,
    developmentItems,
    withEditActions,
    title,
    description,
    expanded,
    loading,
    id,
    onClick,
    onClickItem,
    onEdit,
    isItemVisible,
    onQuickAddItem,
}: IProps) => {
    let handleEdit;

    if (withEditActions)
        handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onEdit && onEdit();
        };

    return (
        <Accordion
            className={className}
            details={description}
            expanded={expanded}
            summary={
                <AccordionSummary
                    actions={
                        withEditActions && (
                            <IconButton
                                color="inherit"
                                iconPath={mdiPencil}
                                iconSize="2.4rem"
                                size="large"
                                onClick={handleEdit}
                            />
                        )
                    }
                    title={title}
                />
            }
            onChange={onClick}
        >
            <DevelopmentItemList
                groupId={id}
                isItemVisible={isItemVisible}
                items={developmentItems}
                withEditActions={withEditActions}
                onClickItem={onClickItem}
            />

            {withEditActions && (
                <DevelopmentItemQuickAdd
                    groupId={id}
                    loading={loading}
                    onAdd={onQuickAddItem}
                />
            )}
        </Accordion>
    );
};

export const DevelopmentItemGroup = styled(BaseDevelopmentItemGroup)`
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;
