import { mdiDotsVertical } from '@mdi/js';
import { Box, ListItem, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Chip, ChipGroup } from 'common/components/Chip';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { IconButton } from 'common/components/IconButton';
import {
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { IGroupListItemFragment } from 'graphql/types';
import { IGroupConditionInput } from 'user/types';

interface IProps {
    extraCategoryValues: IGroupConditionInput['extraCategoryValues'];
    groups: IGroupListItemFragment[];
    onClickEdit?: () => void;
    onClickDelete?: () => void;
}

export const GroupConditionListItem = ({
    extraCategoryValues,
    groups,
    onClickEdit,
    onClickDelete,
}: IProps) => {
    const [translate] = useTranslation();
    const theme = useTheme();

    const groupsDisplay = groups.map((group, index) => {
        if (groups.length - 1 === index) {
            return group.name;
        }

        return `${group.name}, `;
    });

    const hasFilters = !!extraCategoryValues?.length;

    return (
        <ListItem
            alignItems="flex-start"
            sx={{
                flexDirection: 'column',
                border: '1px solid',
                borderColor: 'grey.300',
                borderRadius: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                {!!extraCategoryValues?.length && (
                    <ChipGroup>
                        {extraCategoryValues.map(
                            (extraCategoryValue, index) => {
                                const { stringValue, color } =
                                    extraCategoryValue;

                                return (
                                    <Chip
                                        key={index}
                                        label={stringValue}
                                        sx={{
                                            backgroundColor: color,
                                            color: color
                                                ? theme.palette.getContrastText(
                                                      color
                                                  )
                                                : '',
                                        }}
                                    />
                                );
                            }
                        )}
                    </ChipGroup>
                )}

                {!hasFilters && groupsDisplay}

                <ListItemSecondaryAction sx={{ ml: 'auto' }}>
                    <DropdownMenu
                        anchor={
                            <IconButton
                                color="inherit"
                                iconPath={mdiDotsVertical}
                                iconSize="2.4rem"
                            />
                        }
                        placement="bottom-end"
                    >
                        <MenuItem onClick={onClickEdit}>
                            {translate('edit')}
                        </MenuItem>

                        {!!onClickDelete && (
                            <MenuItem onClick={onClickDelete}>
                                {translate('delete')}
                            </MenuItem>
                        )}
                    </DropdownMenu>
                </ListItemSecondaryAction>
            </Box>

            {hasFilters && <ListItemText>{groupsDisplay}</ListItemText>}
        </ListItem>
    );
};
