import { useContext } from 'react';

import {
    IOfferEventDetailFragment,
    IOfferEventSubscriptionStatus,
    IOfferEventType,
    IOfferEventEnrollmentCurrentUserFragment,
    useCurrentUserQuery,
} from 'graphql/types';
import {
    OfferEventPermissionsContext,
    OfferPermissionsContext,
} from 'offer/contexts/OfferPermissionsContext';
import {
    filterExtraCategoryValuesForOfferEvent,
    getIsOfferEventSubscribable,
    getIsOfferEventUnsubscribable,
} from 'offer/utils/offerEvent';

interface IOfferEventDetailData {
    currentUserEnrollment?: IOfferEventEnrollmentCurrentUserFragment;
    showSubscriptionStatusSection: boolean;
    showBasicInfoSection: boolean;
    showDescriptionSection: boolean;
    showSubscriptionsSection: boolean;
    showSubcribeSection: boolean;
    showUnsubscribeSection: boolean;
    canSubscribeViaItem: boolean;
}

interface IProps {
    offerEvent: IOfferEventDetailFragment;
}

export const useOfferEventDetail = ({
    offerEvent,
}: IProps): IOfferEventDetailData => {
    const { canUpdate = false } = useContext(OfferPermissionsContext) || {};
    const { canManageSubscriptions = false } =
        useContext(OfferEventPermissionsContext) || {};
    const { data: currentUserData } = useCurrentUserQuery();

    const currentUser = currentUserData?.currentUser;

    const {
        accessTrainings,
        accessExternalContents,
        accessCourses,
        closingDate,
        currentUserEnrollment,
        dates,
        descriptionBeforeSubscription,
        descriptionAfterSubscription,
        location,
        trainers: trainerConnection,
        type,
        showParticipantList,
        publish,
        firstDate,
        offer: { extraCategoryValues },
        cancelled,
        scoring,
        closingDateExpired,
        accessScorms,
        accessVideos,
    } = offerEvent;

    const { webinarLink } = firstDate || {};

    const trainers =
        trainerConnection?.edges.map((edge) => edge?.node).filter(Boolean) ||
        [];

    const isMeeting = type === IOfferEventType.Meeting;
    const isTraining = type === IOfferEventType.Training;
    const isCourse = type === IOfferEventType.Course;
    const isWebinar = type === IOfferEventType.Webinar;
    const isElearning = type === IOfferEventType.Elearning;
    const hasDates = !isTraining && !isCourse && !isElearning && !!dates.length;
    const hasLocation = isMeeting && location;
    const isEnrolled =
        !!currentUserEnrollment &&
        [
            IOfferEventSubscriptionStatus.Enrolled,
            IOfferEventSubscriptionStatus.Completed,
            IOfferEventSubscriptionStatus.Absent,
        ].includes(currentUserEnrollment.status);

    const trainings = accessTrainings.edges;
    const trainingsCount = trainings.length;
    const hasTrainings = !!trainingsCount;

    const externalContents = accessExternalContents.edges;
    const externalContentsCount = externalContents.length;
    const hasExternalContents = !!externalContentsCount;

    const courses = accessCourses.edges;
    const coursesCount = courses.length;
    const hasCourses = !!coursesCount;

    const scorms = accessScorms.edges;
    const scormsCount = scorms.length;
    const hasScorms = !!scormsCount;

    const videos = accessVideos.edges;
    const videosCount = videos.length;
    const hasVideos = !!videosCount;

    // Count all items to check if we can subscribe via the item
    const itemsCount =
        trainingsCount +
        externalContentsCount +
        coursesCount +
        scormsCount +
        videosCount;
    const canSubscribeViaItem =
        itemsCount === 1 && !isWebinar && !isMeeting && !canManageSubscriptions;

    const isTrainer = !!trainers.some(
        (trainer) => trainer.id === currentUser?.id
    );

    const isSubscribable = getIsOfferEventSubscribable(
        offerEvent,
        canUpdate,
        canManageSubscriptions
    );

    // Check if we need to show the status info
    const showStatusInfo =
        !publish ||
        cancelled ||
        (closingDateExpired &&
            (!currentUserEnrollment || canUpdate || canManageSubscriptions));

    const showSubscriptionStatusSection = !!(
        currentUserEnrollment && !canManageSubscriptions
    );

    // Check if offer event has extra category values after filtering
    const hasExtraCategoryValues =
        !!extraCategoryValues?.length &&
        !!filterExtraCategoryValuesForOfferEvent(extraCategoryValues).length;

    const showBasicInfoSection =
        showStatusInfo ||
        hasDates ||
        !!hasLocation ||
        showSubscriptionStatusSection ||
        !!scoring?.length ||
        !!trainers.length ||
        hasExtraCategoryValues;

    const canViewWebinar =
        (isWebinar || isMeeting) && (isEnrolled || canUpdate || isTrainer);

    const showItemsList =
        hasTrainings ||
        hasExternalContents ||
        hasCourses ||
        (canViewWebinar && !!webinarLink) ||
        hasScorms ||
        hasVideos;

    const hasDescription =
        !!descriptionBeforeSubscription ||
        ((isEnrolled || canUpdate) && !!descriptionAfterSubscription);

    const showDescriptionSection = hasDescription || showItemsList;

    // If a user can update or is a trainer, the user should also be able to see everything a subscribed
    // user sees
    const showSubscriptionsSection =
        canUpdate ||
        canManageSubscriptions ||
        (isEnrolled && showParticipantList);

    const showSubcribeSection = isSubscribable && !canSubscribeViaItem;

    const showUnsubscribeSection =
        currentUserEnrollment?.status ===
            IOfferEventSubscriptionStatus.Enrolled &&
        getIsOfferEventUnsubscribable(
            type,
            canUpdate,
            closingDate,
            canManageSubscriptions
        );

    return {
        currentUserEnrollment: currentUserEnrollment || undefined,
        showSubscriptionStatusSection,
        showBasicInfoSection,
        showDescriptionSection,
        showSubscriptionsSection,
        showSubcribeSection,
        showUnsubscribeSection,
        canSubscribeViaItem,
    };
};
