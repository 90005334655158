import { useEffect } from 'react';
import { mdiAccount } from '@mdi/js';

import { useCurrentUserLazyQuery, IUser } from 'graphql/types';
import { Avatar, IAvatarProps } from 'common/components/Avatar';
import { Icon } from 'common/components/Icon';

export interface IProps extends IAvatarProps {
    user?: Pick<IUser, 'name' | 'profileImage'>;
}

export const UserAvatar = ({ user, ...other }: IProps) => {
    const [getCurrentUser, { data: currentUserData }] =
        useCurrentUserLazyQuery();

    useEffect(() => {
        // When user is not given we want to get the current User
        if (!user) getCurrentUser();
    }, [user, getCurrentUser]);

    const avatarUser = user || currentUserData?.currentUser;

    const userName = avatarUser?.name;
    const userImage = avatarUser?.profileImage?.url || undefined;

    return (
        <Avatar src={userImage} {...other}>
            {userName && userName[0]}
            {!userImage && !userName && <Icon path={mdiAccount} />}
        </Avatar>
    );
};
