import { mdiChevronRight, mdiMinus, mdiPlus, mdiSchool } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { IGroupListItemFragment } from 'graphql/types';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { ChipGroup } from 'common/components/Chip';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    selected?: boolean;
    group: IGroupListItemFragment;
    onClick?(group: IGroupListItemFragment): void;
}

export const GroupListItem = ({
    addButton,
    added,
    disabled,
    selected,
    group,
    onClick,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();
    const { id, name, extraCategoryValues, isEditable } = group;

    let mediaIconPath = mdiSchool;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }

    const isDisabled = disabled !== undefined ? disabled : !isEditable;

    return (
        <ListItem
            button={!isDisabled}
            disabled={isDisabled}
            key={id}
            px={0}
            selected={selected}
            onClick={() => !isDisabled && onClick?.(group)}
            {...other}
        >
            <ListItemMedia color="primary" size="small">
                <Icon path={mediaIconPath} size="3rem" />
            </ListItemMedia>

            <ListItemText primary={name}>
                {!!extraCategoryValues?.length && (
                    <ChipGroup>
                        <ExtraCategoryLabels
                            extraCategoryValues={extraCategoryValues}
                        />
                    </ChipGroup>
                )}
            </ListItemText>

            {!addButton && !isDisabled && (
                <>
                    <ListItemActionText>{translate('open')}</ListItemActionText>
                    <ListItemSecondaryAction>
                        <Icon path={mdiChevronRight} size="3rem" />
                    </ListItemSecondaryAction>
                </>
            )}
        </ListItem>
    );
};
