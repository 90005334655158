import React from 'react';
import { Box, BoxProps, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const chipGroupStyle: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    m: -0.25,

    '> *': {
        display: 'inline-flex',
        m: 0.25,
    },

    '& + &': {
        mt: 0.25,
    },
};

export const ChipGroup = ({ children, sx, ...other }: BoxProps) => {
    // Don't render ChipGroup if there are no children, this will also exclude "null" and "false" children
    if (!React.Children.toArray(children).length) return null;

    return (
        <Box sx={[chipGroupStyle, ...withSxProp(sx)]} {...other}>
            {children}
        </Box>
    );
};
