import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { ICertificateTypeListItemFragment } from 'graphql/types';
import { isCertificateSelected } from 'training/utils/certificateSelect';
import { CertificateSelectorListItem } from 'common/components/CertificateSelectorListItem';

interface IProps {
    selectedCertificates: ICertificateTypeListItemFragment[];
    certificates: ICertificateTypeListItemFragment[];
    onAddCertificate?(
        selectedCertificate: ICertificateTypeListItemFragment
    ): void;
    onRemoveCertificate?(
        selectedCertificate: ICertificateTypeListItemFragment
    ): void;
}

export const CertificateAddList = ({
    selectedCertificates,
    certificates,
    onAddCertificate,
    onRemoveCertificate,
}: IProps) => {
    const [translate] = useTranslation();

    const handleSelectCertificate = (
        certificate: ICertificateTypeListItemFragment,
        isAdded: boolean
    ) => {
        isAdded
            ? onRemoveCertificate?.(certificate)
            : onAddCertificate?.(certificate);
    };

    return (
        <>
            {!certificates.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>
                        {translate('noOptionsText.certificates')}
                    </Typography>
                </Box>
            ) : (
                certificates.filter(Boolean).map((certificate) => {
                    const isSelected = isCertificateSelected(
                        selectedCertificates,
                        certificate
                    );

                    return (
                        <CertificateSelectorListItem
                            addButton
                            added={isSelected}
                            certificate={certificate}
                            key={certificate.id}
                            px={2}
                            selected={isSelected}
                            onClick={() =>
                                handleSelectCertificate(certificate, isSelected)
                            }
                        />
                    );
                })
            )}
        </>
    );
};
