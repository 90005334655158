import {
    SlatePlugin,
    setDefaults,
    getRenderElement,
} from '@udecode/slate-plugins';

import {
    IFileKeyOption,
    IFilePluginOptionsValues,
    IFileRenderElementOptions,
    IFilePluginOptions,
} from './types';
import { deserializeFile } from './deserialize';
//
// Defaults
//
export const DEFAULTS_FILE: Record<IFileKeyOption, IFilePluginOptionsValues> = {
    file: {
        component: 'div',
        type: 'file',
        rootProps: {
            className: 'slate-file',
        },
    },
};

export const renderElementFile = (options?: IFileRenderElementOptions) => {
    const { file } = setDefaults(options, DEFAULTS_FILE);

    return getRenderElement(file);
};

/**
 * Enables support for files.
 */
export const FilePlugin = (options?: IFilePluginOptions): SlatePlugin => ({
    renderElement: renderElementFile(options),
    deserialize: deserializeFile(options),
});
