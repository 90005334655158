import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import {
    ToolbarButton,
    someNode,
    ToolbarElementProps,
    unwrapList,
} from '@udecode/slate-plugins';

import { options } from '../../options';

export const ToolbarElement = ({
    type,
    inactiveType,
    ...props
}: ToolbarElementProps) => {
    const editor = useSlate();

    return (
        <ToolbarButton
            active={someNode(editor, { match: { type } })}
            onMouseDown={(event) => {
                event.preventDefault();

                if (!editor.selection) return;

                // Reset lists to prevent elements inside a list
                unwrapList(editor, options);

                Transforms.setNodes(editor, { type: type });
            }}
            {...props}
        />
    );
};
