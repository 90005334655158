import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

import {
    useCollectionEditQuery,
    useUpdateCollectionMutation,
    useDeleteCollectionMutation,
    ITrainingListItemFragment,
    ICollectionGroupFragment,
    IUpdateLibraryCollectionInput,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { PageDrawer } from 'common/components/PageDrawer';
import { LibraryCollectionForm } from 'library/components/forms/LibraryCollectionForm';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { AlertDialog } from 'common/components/AlertDialog';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { getImageId } from 'common/utils/image';

interface IProps {
    pageTitle?: string;
    id?: string;
    open?: boolean;
    onUpdate(): void;
    onDelete(): void;
    onClose?(): void;
}

export const LibraryCollectionDrawer = ({
    pageTitle,
    id,
    open,
    onUpdate,
    onDelete,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);
    const [libraryImageId, setLibraryImageId] = useState<string>();

    const { loading, error, data } = useCollectionEditQuery({
        variables: { id },
        onCompleted: (data) => {
            const image = data?.libraryCollection?.libraryImage?.id;

            if (image) setLibraryImageId(image);
        },
    });

    const collection = data?.libraryCollection;

    const [updateCollection, { error: updateError }] =
        useUpdateCollectionMutation({
            onCompleted: () => {
                displaySnackbar(
                    translate('libraryCollectionActionSuccess.update'),
                    {
                        variant: 'success',
                    }
                );

                onUpdate();
            },
        });

    const [deleteCollection, { loading: deleteLoading, error: deleteError }] =
        useDeleteCollectionMutation({
            update: (cache) => {
                if (!collection) return;

                // Remove collection from cache
                cache.evict({ id: `LibraryCollection:${id}` });
                cache.gc();

                setShowOnDeleteAlert(false);
                displaySnackbar(
                    translate('libraryCollectionActionSuccess.delete'),
                    {
                        variant: 'success',
                    }
                );
                onDelete();
            },
        });

    if (loading || error) {
        return (
            <PageDrawer open={open}>
                {error && <ApolloError error={error} />}
                {loading && <Loader />}
            </PageDrawer>
        );
    }

    if (!collection) return null;

    const {
        title,
        description,
        active,
        access,
        root,
        libraryImage,
        libraryTitleColor,
    } = collection;

    const accessTrainings = (access || []).filter(
        (a) => a.__typename === 'TrainingListItem'
    ) as ITrainingListItemFragment[];

    const accessGroups = (access || [])?.filter(
        (a) => a.__typename === 'Group'
    ) as ICollectionGroupFragment[];

    const initialValues = {
        title,
        description,
        active,
        accessTrainings,
        accessGroups,
        libraryTitleColor,
        libraryImage,
    };

    const libraryImageUpdated =
        libraryImageId !== data?.libraryCollection?.libraryImage?.id;

    return (
        <PageDrawer open={open} onClose={onClose}>
            {updateError && <ApolloError error={updateError} />}
            {deleteError && <ApolloError error={deleteError} />}

            {pageTitle && (
                <PageTitle mixpanelTitle="Edit collection - Library">{`${pageTitle} - ${translate(
                    'editCollection'
                )}`}</PageTitle>
            )}

            <LibraryCollectionForm
                imageUploaded={libraryImageUpdated}
                initialValues={initialValues}
                isDeleting={deleteLoading}
                isRootCollection={root}
                onDelete={() => {
                    setShowOnDeleteAlert(true);
                }}
                onSubmit={(values) => {
                    // Before submitting the collection we want to change accessGroups and accessTraining to a
                    // single array of uid's
                    const {
                        accessGroups,
                        accessTrainings,
                        libraryImage,
                        ...collectionInput
                    } = values;
                    const { libraryImage: currentLibraryImage } =
                        collection || {};

                    const access = [
                        ...accessTrainings.map((training) => training.id),
                        ...accessGroups.map((group) => group.id),
                    ];

                    const updatedCollection = {
                        ...collectionInput,
                        libraryImageId: getImageId(
                            currentLibraryImage,
                            libraryImage
                        ),
                        access,
                    } as IUpdateLibraryCollectionInput;

                    return updateCollection({
                        variables: {
                            id: collection.id,
                            collection: updatedCollection,
                        },
                    });
                }}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={() => {
                                deleteCollection({
                                    variables: { id: collection.id },
                                });
                            }}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowOnDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showOnDeleteAlert}
                title={translate(
                    root
                        ? 'deleteRootCollectionMessage.title'
                        : 'deleteCollectionMessage.title'
                )}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate(
                        root
                            ? 'deleteRootCollectionMessage.text'
                            : 'deleteCollectionMessage.text'
                    )}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
