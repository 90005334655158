import moment from 'moment';
import 'moment/dist/locale/nl';

import { i18n } from 'utils';

export function getDateFormat(): string {
    return moment
        .localeData(i18n.language.toLocaleLowerCase())
        .longDateFormat('L')
        .toLowerCase()
        .replace('mm', 'MM');
}

export function getDateTimeFormat(): string {
    const dateFormat = getDateFormat();
    const format = moment
        .localeData(i18n.language.toLocaleLowerCase())
        .longDateFormat('LT')
        .toLowerCase()
        .replace('hh', 'HH');

    return `${dateFormat} ${format}`;
}
