import React, { useMemo } from 'react';
import {
    Table,
    TableRow,
    TableBody,
    Box,
    useTheme,
    TableContainer,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ITrainingFragment, ITrainingUserEdgeFragment } from 'graphql/types';
import { IPaginationProps } from 'common/types';
import { getModuleTitle, getModuleWeightValue } from 'training/utils/module';
import {
    TableCell,
    TableCellDivider,
    TableHead,
} from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { BoxLoader } from 'common/components/Loader';
import { Pagination } from 'common/components/Pagination';
import { useTrainingParticipantReports } from 'training/hooks';
import { InlineStickyWrapper } from 'common/components/InlineStickyWrapper';

import { TrainingParticipantSummaryCells } from './TrainingParticipantSummaryCells';
import { TrainingParticipantReportMatrixCell } from './TrainingParticipantReportMatrixCell';

interface IProps {
    training: ITrainingFragment;
    participants?: ITrainingUserEdgeFragment[];
    canManageTraining?: boolean;
    isGroupManager?: boolean;
    paginationProps?: IPaginationProps;
}

export const TrainingParticipantMatrix = ({
    canManageTraining,
    isGroupManager,
    participants,
    training,
    paginationProps,
}: IProps) => {
    const [translate] = useTranslation();

    const theme = useTheme();
    // Calculate the total height of the navigation bar + context action bar
    const navHeights =
        ((theme.mixins.toolbar.height as number) || 0) +
        ((theme.mixins.contextActionBar.height as number) || 0);

    // Create a map of participants for easy access to participant data by id
    const participantsMap = useMemo<
        Map<
            ITrainingUserEdgeFragment['node']['id'],
            {
                participant: ITrainingUserEdgeFragment;
                canManageParticipant: boolean;
            }
        >
    >(() => {
        if (!participants) return new Map();

        return new Map(
            participants.map((participant) => [
                participant.node.id,
                {
                    participant,
                    canManageParticipant:
                        canManageTraining ||
                        (!!isGroupManager &&
                            participant.node.currentUserIsGroupManager),
                },
            ])
        );
    }, [participants, canManageTraining, isGroupManager]);

    const { paginationSettings, setPage } = paginationProps || {};

    const {
        moduleGroups,
        participantReports,
        loadingModuleGroups,
        loadingReports,
    } = useTrainingParticipantReports(
        training.id,
        Array.from(participantsMap.keys())
    );

    if (loadingModuleGroups) return <BoxLoader />;

    if (!participantReports.size) {
        return (
            <Box sx={{ mt: 8, textAlign: 'center' }}>
                <Typography>
                    {translate('trainingHasNoParticipantReports')}
                </Typography>
            </Box>
        );
    }

    const stickyOffsetLeft = {
        xs: 'auto',
        sm: theme.spacing(3),
    };

    const stickyOffset = {
        backgroundColor: 'common.white',
        position: {
            xs: 'static',
            sm: 'sticky',
        },
        left: stickyOffsetLeft,
    };

    return (
        <>
            <TableContainer sx={{ overflowX: 'initial' }}>
                <Table stickyHeader>
                    {moduleGroups && (
                        <TableHead>
                            <TableRow sx={{ verticalAlign: 'bottom' }}>
                                <TableCell
                                    sx={{
                                        top: navHeights,
                                        zIndex: 5,
                                        position: 'sticky',
                                        left: stickyOffsetLeft,
                                    }}
                                >
                                    {translate('participant')}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        top: navHeights,
                                        zIndex: 4,
                                        pl: 2.5,
                                        width: 0,
                                        whiteSpace: 'nowrap',
                                        left: stickyOffsetLeft,
                                    }}
                                >
                                    {translate('total')}
                                </TableCell>
                                {moduleGroups?.map((group) => (
                                    <React.Fragment key={group.id}>
                                        <TableCell
                                            dividerHead
                                            vertical
                                            sx={[
                                                {
                                                    top: navHeights,
                                                    zIndex: 4,
                                                    left: stickyOffsetLeft,
                                                },
                                            ]}
                                        >
                                            {group.title}
                                        </TableCell>

                                        {group.modules.map((module, index) => (
                                            <TableCell
                                                vertical
                                                key={module.id}
                                                sx={{
                                                    // Add padding right to last module in group
                                                    pr:
                                                        index ===
                                                        group.modules.length - 1
                                                            ? 3
                                                            : undefined,
                                                    top: navHeights,
                                                    zIndex: 4,
                                                    left: stickyOffsetLeft,
                                                }}
                                            >
                                                {getModuleTitle(module)}
                                            </TableCell>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableRow>
                        </TableHead>
                    )}
                    {!loadingReports && (
                        <TableBody>
                            {Array.from(participantReports.entries()).map(
                                ([userId, report]) => {
                                    const participantData =
                                        participantsMap.get(userId);

                                    if (!participantData) return null;

                                    return (
                                        <TableRow key={userId}>
                                            <TrainingParticipantSummaryCells
                                                canManageParticipant={
                                                    participantData?.canManageParticipant
                                                }
                                                nameCellSx={[
                                                    {
                                                        zIndex: 3,
                                                    },
                                                    stickyOffset,
                                                ]}
                                                participant={
                                                    participantData?.participant
                                                }
                                                scoreCellSx={stickyOffset}
                                            />

                                            {moduleGroups?.map((group) => (
                                                <React.Fragment key={group.id}>
                                                    <TableCellDivider
                                                        sx={stickyOffset}
                                                    />

                                                    {group.modules.map(
                                                        (module, index) => (
                                                            <TrainingParticipantReportMatrixCell
                                                                align="center"
                                                                canManageParticipant={
                                                                    participantData.canManageParticipant
                                                                }
                                                                key={module.id}
                                                                module={module}
                                                                report={
                                                                    report[
                                                                        module
                                                                            .id
                                                                    ]
                                                                }
                                                                sx={[
                                                                    stickyOffset,
                                                                    {
                                                                        // Add padding right to last module in group
                                                                        pr:
                                                                            index ===
                                                                            group
                                                                                .modules
                                                                                .length -
                                                                                1
                                                                                ? 3
                                                                                : undefined,
                                                                    },
                                                                ]}
                                                                trainingId={
                                                                    training.id
                                                                }
                                                                userId={userId}
                                                            />
                                                        )
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </TableRow>
                                    );
                                }
                            )}
                            {/* Render TabelRow for module weights */}
                            {!!participantReports.size && (
                                <TableRow>
                                    <TableCell
                                        sx={[
                                            stickyOffset,
                                            {
                                                top: navHeights,
                                                zIndex: 5,
                                                borderRight: '1px solid',
                                                borderColor: 'divider',
                                            },
                                        ]}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                            variant="body2"
                                        >
                                            {translate('weight')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                    {moduleGroups?.map((group) => (
                                        <React.Fragment key={group.id}>
                                            <TableCellDivider />

                                            {group.modules.map((module) => (
                                                <TableCell
                                                    align="center"
                                                    key={module.id}
                                                    sx={stickyOffset}
                                                >
                                                    {/* Show weight value of module if set */}
                                                    {!!('weight' in module) &&
                                                        getModuleWeightValue(
                                                            module.weight
                                                        )}
                                                </TableCell>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>

            {loadingReports && <BoxLoader />}

            {!!paginationSettings && (
                <Box sx={{ mt: 4 }}>
                    <InlineStickyWrapper fullWidth leftOffset={3}>
                        <Pagination
                            page={paginationSettings.page}
                            pageAmount={paginationSettings.pageAmount}
                            totalsAmount={paginationSettings.count}
                            totalsText={translate(
                                'trainingParticipants.participants',
                                {
                                    count: paginationSettings.count,
                                }
                            )}
                            onChange={(page: number) => {
                                setPage?.(page);
                            }}
                        />
                    </InlineStickyWrapper>
                </Box>
            )}
        </>
    );
};
