/**
 * Capitalize will convert the first char of a string to uppercase
 *
 * @example
 * // returns 'Some cool text'
 * capitalize('some cool text')
 *
 * @param s string
 * @returns string
 */
export function capitalize(s: string) {
    return s.replace(/^./, (str) => str.toUpperCase());
}
