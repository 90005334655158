import React, { cloneElement } from 'react';
import {
    Droppable as RBDDroppable,
    DroppableProps,
    DroppableId,
} from '@hello-pangea/dnd';

interface IProps extends Omit<DroppableProps, 'children' | 'droppableId'> {
    dragDropDisabled?: boolean;
    passContext?: boolean;
    children: React.ReactNode;
    droppableId?: DroppableId;
}

export const Droppable = ({
    dragDropDisabled,
    children,
    passContext,
    droppableId,
    ...other
}: IProps) => {
    if (dragDropDisabled || !droppableId) return <>{children}</>;

    return (
        <RBDDroppable droppableId={droppableId} {...other}>
            {(droppableProvided, droppableSnapshot) => {
                if (passContext) {
                    return (
                        <>
                            {React.Children.map(children, (child) => {
                                if (!React.isValidElement(child)) return null;

                                cloneElement(child as React.ReactElement, {
                                    droppableProvided,
                                    droppableSnapshot,
                                });
                            })}
                        </>
                    );
                }

                return (
                    <div ref={droppableProvided.innerRef}>
                        {children}
                        {droppableProvided.placeholder}
                    </div>
                );
            }}
        </RBDDroppable>
    );
};
