import { IUserNotifications } from 'graphql/types';

export const NOTIFICATION_OPTIONS = Object.keys(IUserNotifications).reduce(
    (acc, curVal) => ({
        ...acc,
        [IUserNotifications[curVal]]:
            `userNotifications.${curVal.toLowerCase()}`,
    }),
    {}
);
