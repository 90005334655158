import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';
import { nlNL } from '@mui/material/locale';
import { nlNL as datenlNL } from '@mui/x-date-pickers';

import { ITheme } from 'graphql/types';

import { createPaletteSetup } from './palette';
import { createTypography } from './createTypography';

export const createTheme = (theme?: ITheme): Theme =>
    createMuiTheme(
        {
            typography: createTypography(theme?.typography),
            palette: createPaletteSetup(theme?.palette),
            shape: {
                borderRadius: 5,
            },
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1280,
                    xl: 1920,
                },
            },
            mixins: {
                toolbar: {
                    height: 80,
                },
                contextActionBar: {
                    height: 49,
                },
            },
            zIndex: {
                dropdown: 1075,
                fileUpload: 1250,
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                        color: 'inherit',
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiTab: {
                    defaultProps: {
                        disableRipple: true,
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        sizeMedium: {
                            // Override chip font-size to change it in the multiple select input
                            fontSize: '1.2rem',
                            height: 28,
                            lineHeight: 1,
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            // Override font-size to change it in the InputBase components used in e.g. SearchBar
                            fontSize: '1.6rem',
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        shrink: {
                            // Set shrink font-size to fix issues for label with fontSize from input being changed.
                            fontSize: '1.6rem',
                        },
                    },
                },
                MuiTable: {
                    styleOverrides: {
                        root: {
                            borderCollapse: 'unset',
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: ({ theme }) =>
                            theme.unstable_sx({
                                minHeight: 0,
                                py: 0,
                                pr: '26px',
                                pl: 2,
                                fontSize: '1.6rem',
                            }),
                    },
                },
                MuiTableContainer: {
                    styleOverrides: {
                        root: {
                            position: 'relative',
                        },
                    },
                },
            },
        },
        datenlNL,
        nlNL
    );
