import { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    useUpdateOrganisationMutation,
    IOrganisationFragment,
    useOrganisationQuery,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { CoverPage } from 'hydra/pages/CoverPage';
import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { OrganisationImagesForm } from 'organisation/components/forms/OrganisationImagesForm';
import { getUrl } from 'route/utils/getUrl';
import { OrganisationOnboardingLottie } from 'organisation/components/OrganisationOnboardingLottie';
import { OrganisationOnboardingNavigation } from 'organisation/components/OrganisationOnboardingNavigation';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { getImageId } from 'common/utils/image';

export const OrganisationImagesPage = () => {
    const [translate] = useTranslation();

    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();

    const [overrideOrganisation, setOverrideOrganisation] = useState<
        Partial<IOrganisationFragment> | undefined
    >();

    const { data: currentOrgData } = useOrganisationQuery();
    const organisation = currentOrgData?.organisation;
    const { logo, participantIntroImage } = organisation || {};
    const [updateOrganisation, { error: updateError }] =
        useUpdateOrganisationMutation();

    return (
        <>
            {updateError && <ApolloError error={updateError} />}

            <CoverPage
                footer={<OrganisationOnboardingNavigation activeStep={1} />}
                secondaryContent={
                    <OrganisationOnboardingLottie
                        overrideOrganisation={overrideOrganisation}
                    />
                }
            >
                <PageTitle mixpanelTitle="Logo & header - Onboarding">
                    {translate('orgImagesPage.browserTitle')}
                </PageTitle>

                <Box mb={2}>
                    <Typography variant="h2">
                        {translate('orgImagesPage.title')}
                    </Typography>
                </Box>

                <Text>{translate('orgImagesPage.content')}</Text>

                <OrganisationImagesForm
                    initialValues={{
                        logo,
                        participantIntroImage,
                    }}
                    onChange={(name, file) => {
                        // Add timeout here so we wait for the slider to be finished with the animation
                        setTimeout(() => {
                            setOverrideOrganisation((org) => ({
                                ...org,
                                [name]: file,
                            }));
                        }, 250);
                    }}
                    onSubmit={async ({
                        logo: newLogo,
                        participantIntroImage: newParticipantIntroImage,
                    }) => {
                        await updateOrganisation({
                            variables: {
                                organisation: {
                                    logoId: getImageId(logo, newLogo),
                                    participantIntroImageId: getImageId(
                                        participantIntroImage,
                                        newParticipantIntroImage
                                    ),
                                },
                            },
                            update: (cache, { data }) => {
                                const organisation =
                                    data?.updateOrganisation?.organisation;

                                if (!organisation) return;

                                const { logo, participantIntroImage } =
                                    organisation;

                                // Update images in cache after updating the organisation.
                                cache.modify({
                                    fields: {
                                        organisation(org) {
                                            return {
                                                ...org,
                                                logo,
                                                participantIntroImage,
                                            };
                                        },
                                    },
                                });
                            },
                        });

                        await trackMixpanel({
                            eventName: 'Onboarding: Logo & header set',
                        });

                        // Redirect to Organiation Colors when organisation has been updated
                        navigate(getUrl('ORGANISATION_COLORS'));

                        return;
                    }}
                />
            </CoverPage>
        </>
    );
};
