import { createContext } from 'react';

import { TOfferEventPermissions, TOfferPermissions } from 'offer/types';

export const OfferPermissionsContext = createContext<
    TOfferPermissions | undefined
>(undefined);

export const OfferEventPermissionsContext = createContext<
    TOfferEventPermissions | undefined
>(undefined);
