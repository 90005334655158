import { Chip } from 'common/components/Chip';

interface IProps {
    extraTrainingFieldsLabels?: string[] | null;
    color?: React.ComponentProps<typeof Chip>['color'];
}

export const ExtraTrainingFieldsChips = ({
    extraTrainingFieldsLabels,
    color,
}: IProps) => {
    if (!extraTrainingFieldsLabels) return null;

    return (
        <>
            {extraTrainingFieldsLabels.map((extraTrainingField, index) => (
                <Chip
                    color={color}
                    key={`label-${extraTrainingFieldsLabels}-${index}`}
                    label={extraTrainingField}
                />
            ))}
        </>
    );
};
