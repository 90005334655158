import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import {
    getBlockAbove,
    setDefaults,
    DEFAULTS_MEDIA_EMBED,
    MediaEmbedPluginOptions,
    isUrl,
} from '@udecode/slate-plugins';

export const insertEmbed = (
    editor: Editor,
    url: string,
    options: MediaEmbedPluginOptions<'type'>
) => {
    const { media_embed } = setDefaults(options, DEFAULTS_MEDIA_EMBED);

    const text = { text: '' };
    const embed = { type: media_embed.type, url, children: [text] };
    Transforms.insertNodes(editor, embed);
};

export function addOrReplaceEmbed(
    editor: ReactEditor,
    msg?: string,
    options?: MediaEmbedPluginOptions<'type'>
) {
    const { media_embed } = setDefaults(options, DEFAULTS_MEDIA_EMBED);

    let prevUrl = '';

    const embedNode = getBlockAbove(editor, {
        match: { type: media_embed.type },
    });

    if (embedNode) {
        prevUrl = embedNode[0].url as string;
    }

    const url = window.prompt(msg || '', prevUrl);

    if (!url) return;

    if (!isUrl(url)) {
        throw new Error('urlNotValid');
    }

    if (prevUrl) {
        Transforms.setNodes(editor, {
            url,
        });

        return;
    }

    insertEmbed(editor, url, { media_embed });
}
