import { Chip as MuiChip, ChipProps, Box, SxProps, Theme } from '@mui/material';

import { withSxProp } from 'common/utils/props';

export interface IChipProps extends ChipProps {
    bgColor?: ChipProps['color'];
    hover?: boolean;
    showDot?: boolean;
}

const chipStyle: SxProps = {
    position: 'relative',

    '.MuiSvgIcon-root': {
        fontSize: 17,
    },
    '.MuiChip-avatarSmall .MuiSvgIcon-root': {
        fontSize: 14,
    },
    '.MuiChip-deleteIcon': {
        m: '0 3px 0 -6px',
        fill: '#e0e0e0',

        ':hover': {
            fill: '#9c9c9c',
        },
    },
    '.MuiChip-icon': {
        ml: 1,
        color: 'inherit',
    },
    '.MuiChip-label': {
        lineHeight: 1.1,
    },
    '.MuiChip-icon + .MuiChip-label': {
        // When the label is empty and next to a icon we want to fix the padding
        '&:empty': {
            pr: 0.75,
        },
    },
    '&.MuiChip-clickable:active': {
        boxShadow: 'none',
    },
    '&.MuiChip-outlined': {
        borderColor: 'grey.300',
    },
};

const defaultColorStyling: SxProps<Theme> = {
    '&.MuiChip-deletable:focus': {
        backgroundColor: 'primary.main',
    },
};

export const Chip = ({
    bgColor,
    color,
    variant,
    size,
    hover,
    showDot,
    label,
    sx,
    ...other
}: IChipProps) => {
    if (showDot) {
        label = (
            <>
                {label}

                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        pointerEvents: 'none',
                        borderRadius: '50%',
                        position: 'absolute',
                        mx: 'auto',
                        width: 7,
                        height: 7,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </>
        );
    }

    return (
        <MuiChip
            // Set given bgColor as color, this is because we use bgColor in many places
            color={bgColor ? bgColor : color}
            label={label}
            size={size}
            sx={[
                // Set minium width for chips so they are round when they have no content
                size === 'small' ? { minWidth: 24 } : { minWidth: 28 },
                chipStyle,
                variant !== 'outlined' &&
                    !bgColor &&
                    !color &&
                    defaultColorStyling,
                hover && {
                    border: '2px solid rgba(0, 0, 0, 0.2)',
                    '&:hover': { borderColor: 'rgba(0, 0, 0, 0.4)' },
                },
                // Spread sx from props
                ...withSxProp(sx),
            ]}
            variant={variant}
            {...other}
        />
    );
};
