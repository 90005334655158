import { RefObject, useContext, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import {
    IFileFragment,
    IOfferEventSubscriptionStatus,
    useUpdateOfferEventMutation,
    useUpdateOfferMutation,
    IOfferEventType,
    useOfferEventEnrollCurrentUserMutation,
    IOfferEventDetailFragment,
    IContentTypeValue,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { MIXPANEL_EVENT_NAMES } from 'common/constants/mixpanel';
import { useOfferEventDetail } from 'offer/hooks/useOfferEventDetail';
import { useApolloError } from 'common/hooks/useApolloError';
import { EOfferEventCategoryType, TOfferFormValues } from 'offer/types';
import { AlertDialog } from 'common/components/AlertDialog';
import { Typography } from 'common/components/Typography';
import { getGroupConditionMutationInputs } from 'user/utils/groups';
import { IGroupConditionInput } from 'user/types';
import { OfferPermissionsContext } from 'offer/contexts';
import { OfferEventPermissionsContext } from 'offer/contexts/OfferPermissionsContext';
import { Loader } from 'common/components/Loader';
import { useExtraCategoryValues } from 'common/hooks/useExtraCategoryValues';
import { Divider } from 'common/components/Divider';
import { OfferEventEnrollmentList } from 'offer/components/OfferEventEnrollmentList';
import { OfferEventMangeableEnrollmentList } from 'offer/components/OfferEventMangeableEnrollmentList';

import { OfferEventForm } from '../forms/OfferEventForm';

import { BasicInfoSection } from './BasicInfoSection';
import { DescriptionSection } from './DescriptionSection';
import { SubscribeSection } from './SubscribeSection';
import { UnsubscribeSection } from './UnsubscribeSection';

interface IProps {
    drawerRef?: RefObject<HTMLDivElement>;
    edit?: boolean;
    includeOfferFields?: boolean;
    isOfferDetailDrawer?: boolean;
    offerEvent: IOfferEventDetailFragment;
    onCancelEdit?(): void;
    onClose?(cancelEdit?: boolean): void;
    onDelete?(): void;
}

export const OfferEventDetail = ({
    drawerRef,
    edit,
    includeOfferFields,
    isOfferDetailDrawer,
    offerEvent,
    onCancelEdit,
    onClose,
    onDelete,
}: IProps) => {
    const { t: translate } = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const [updateOffer, { loading: updateOfferLoading }] =
        useUpdateOfferMutation();
    const [updateOfferEvent, { loading: updateOfferEventLoading }] =
        useUpdateOfferEventMutation();
    const offerPermissions = useContext(OfferPermissionsContext);
    const offerEventPermissions = useContext(OfferEventPermissionsContext);
    const client = useApolloClient();

    const { canUpdate = false } = offerPermissions || {};
    const { canManageSubscriptions = false } = offerEventPermissions || {};

    const {
        title,
        offer: {
            id: offerId,
            extraCategoryValues,
            title: offerTitle,
            hasDetailPage,
            type: offerType,
        },
        trainerApproval,
    } = offerEvent;

    const {
        currentUserEnrollment,
        showSubscriptionStatusSection,
        showBasicInfoSection,
        showDescriptionSection,
        showSubscriptionsSection,
        showSubcribeSection,
        showUnsubscribeSection,
        canSubscribeViaItem,
    } = useOfferEventDetail({
        offerEvent,
    });
    const [subscribeDialogOpen, setSubscribeDialogOpen] = useState(false);
    const [enrollCurrentUser, { loading: enrollCurrentUserLoading }] =
        useOfferEventEnrollCurrentUserMutation({ onError: showApolloError });
    const { loading: extraCategoriesLoading, handleUpdateExtraCategoryValues } =
        useExtraCategoryValues({
            contentType: IContentTypeValue.Offer,
            modelId: offerId,
            typeNames: ['Offer'],
            skipQuery: !canUpdate || !edit,
        });

    const isEnrolled =
        !!currentUserEnrollment &&
        [
            IOfferEventSubscriptionStatus.Enrolled,
            IOfferEventSubscriptionStatus.Completed,
            IOfferEventSubscriptionStatus.Absent,
        ].includes(currentUserEnrollment.status);

    const handleSubmit = async (
        values: TOfferFormValues & {
            overviewImage?: IFileFragment;
        }
    ) => {
        const {
            minSubscriptions,
            maxSubscriptions,
            offerEventTitle,
            certificate,
            ...otherValues
        } = values.event;

        if (includeOfferFields) {
            const {
                title,
                overviewImageId,
                extraCategories: extraCategoryUpdateValues,
                groupConditions,
            } = values.offer as TOfferFormValues['offer'] & {
                overviewImageId?: string;
            };

            try {
                await handleUpdateExtraCategoryValues(
                    extraCategoryValues,
                    extraCategoryUpdateValues
                );

                await updateOffer({
                    variables: {
                        id: offerId,
                        offer: {
                            title,
                            overviewImageId,
                            groupConditions: getGroupConditionMutationInputs(
                                (groupConditions as IGroupConditionInput[]) ||
                                    undefined
                            ),
                        },
                    },
                });
            } catch (error) {
                showApolloError(error);

                return;
            }
        }

        const offerEventValues = {
            ...otherValues,
            minSubscriptions: minSubscriptions ? +minSubscriptions : undefined,
            maxSubscriptions: maxSubscriptions ? +maxSubscriptions : undefined,
            title: offerEventTitle,
            certificateId: certificate?.id || null,
        };

        try {
            await updateOfferEvent({
                variables: {
                    id: offerEvent.id,
                    offerEvent: offerEventValues,
                },
                update: (cache) => {
                    onCancelEdit && onCancelEdit();

                    cache.evict({ fieldName: 'offerEvents' });
                    cache.evict({ fieldName: 'offers' });
                    cache.evict({ fieldName: 'paginatedOfferEvents' });

                    // Refetch the offer event when maxSubscriptions is updated
                    // This to reflect changes to the offer event subscriptions waiting list
                    if (
                        offerEvent.waitingList &&
                        offerEventValues.maxSubscriptions !==
                            offerEvent.maxSubscriptions
                    ) {
                        cache.evict({ id: `OfferEvent:${offerEvent.id}` });
                    }

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: includeOfferFields
                ? MIXPANEL_EVENT_NAMES.offers.offer.update
                : MIXPANEL_EVENT_NAMES.offers[offerEvent.type].offerEvent
                      .update,
        });

        displaySnackbar(
            translate(
                includeOfferFields
                    ? 'offerActionSuccess.update'
                    : 'offerEventActionSuccess.update'
            ),
            {
                variant: 'success',
            }
        );

        onClose?.(true);

        return;
    };

    const handleEnroll = async () => {
        await enrollCurrentUser({
            variables: { offerEventId: offerEvent.id },
            update: (cache) => {
                trainerApproval && setSubscribeDialogOpen(true);

                cache.evict({ fieldName: 'offerEvents' });
                cache.evict({ fieldName: 'paginatedOfferEvents' });

                cache.gc();
            },
        });
    };

    const isElearning = offerEvent?.type === IOfferEventType.Elearning;
    const offerEventCategoryType = !offerType
        ? EOfferEventCategoryType.Meeting
        : EOfferEventCategoryType.Offer;

    if (
        updateOfferEventLoading ||
        extraCategoriesLoading ||
        updateOfferLoading
    ) {
        return <Loader />;
    }

    // We cannot not edit elearning events.
    if (edit && canUpdate && !isElearning) {
        return (
            <OfferEventForm
                edit
                includeOfferFields={includeOfferFields}
                isOfferDetailDrawer={isOfferDetailDrawer}
                offerEvent={offerEvent}
                offerEventCategoryType={offerEventCategoryType}
                offerEventType={offerEvent.type}
                onCancelEdit={onCancelEdit}
                onDelete={onDelete}
                onSubmit={handleSubmit}
            />
        );
    }

    const hasOfferEventTitle = !!title;

    return (
        <Box
            sx={{
                px: { xs: 2, sm: 4 },
                pt: { xs: 2, sm: 3 },
                // The drawer already has some padding bottom so we need less here
                pb: { xs: 0, sm: 1 },
            }}
        >
            <Box sx={{ pb: { xs: 2, sm: 3 } }}>
                {hasDetailPage && hasOfferEventTitle && (
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontSize: '1.6rem',
                            fontWeight: 700,
                        }}
                    >
                        {offerTitle}
                    </Typography>
                )}

                <Typography
                    color={!hasOfferEventTitle ? 'primary.main' : undefined}
                    variant="h2"
                >
                    {hasOfferEventTitle ? title : offerTitle}
                </Typography>
            </Box>

            <Stack useFlexGap divider={<Divider />} spacing={{ xs: 2, sm: 3 }}>
                {showBasicInfoSection && (
                    <BasicInfoSection
                        offerEvent={offerEvent}
                        showSubscriptionStatusSection={
                            showSubscriptionStatusSection
                        }
                    />
                )}

                {showDescriptionSection && (
                    <>
                        {!showBasicInfoSection && <Divider />}
                        <DescriptionSection
                            canUpdate={canUpdate}
                            currentUserEnrollment={currentUserEnrollment}
                            offerEvent={offerEvent}
                            onClickItem={async (itemCallback) => {
                                // When user can subscribe via item we call the subscribe function
                                if (
                                    canSubscribeViaItem &&
                                    !isEnrolled &&
                                    !canUpdate
                                ) {
                                    await handleEnroll();

                                    // Trigger a refetch of the offer event after the enroll en item callback
                                    client.cache.evict({
                                        id: client.cache.identify(offerEvent),
                                        broadcast: true,
                                    });
                                }

                                await itemCallback?.();

                                return;
                            }}
                        />
                    </>
                )}

                {showSubscriptionsSection &&
                    (canManageSubscriptions || canUpdate ? (
                        // Show the enrollment list for users who can manage the subscriptions
                        <OfferEventMangeableEnrollmentList
                            drawerRef={drawerRef}
                            offerEvent={offerEvent}
                        />
                    ) : (
                        // Show the enrollment list for participants
                        <OfferEventEnrollmentList offerEvent={offerEvent} />
                    ))}

                {showSubcribeSection && (
                    <SubscribeSection
                        loading={enrollCurrentUserLoading}
                        offerEvent={offerEvent}
                        onClickSubscribe={async () => {
                            await handleEnroll();

                            // Trigger a refetch of the offer event
                            client.cache.evict({
                                id: client.cache.identify(offerEvent),
                                broadcast: true,
                            });
                        }}
                    />
                )}

                {showUnsubscribeSection && (
                    <Box sx={{ mt: showSubscriptionsSection ? 1 : 0 }}>
                        <UnsubscribeSection
                            offerEvent={offerEvent}
                            onClose={onClose}
                        />
                    </Box>
                )}
            </Stack>

            <AlertDialog
                actions={
                    <Box mt={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setSubscribeDialogOpen(false);
                            }}
                        >
                            {translate('close')}
                        </Button>
                    </Box>
                }
                open={subscribeDialogOpen}
                title={translate('offerEventSubscribe.dialogTitle')}
            >
                {translate('offerEventSubscribe.dialogText')}
            </AlertDialog>
        </Box>
    );
};
