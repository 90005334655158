import { Box } from '@mui/material';
import { makeVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
    ICurrentUserFragment,
    IRole,
    IUserProfileFragment,
    useCurrentUserQuery,
    useOffersSettingsQuery,
} from 'graphql/types';
import { useUserProfileTabs } from 'user/hooks';
import { TDateRange } from 'common/types';
import { Alert } from 'common/components/Alert';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

import { UserTabsMenu } from '../UserTabsMenu';

// Variable to store the previous date filter for the portfolio
// This is used to prevent refetching portfolio because of unmounting
export const prevPortfolioDateFilterVar = makeVar<TDateRange | undefined>(
    undefined
);

interface IProps {
    canUsePortfolioOffers?: boolean;
    isCurrentUser?: boolean;
    user: IUserProfileFragment | ICurrentUserFragment;
    isOffersPublished?: boolean;
    hideOffersSection?: boolean;
    hideMeetingsSection?: boolean;
}

export const UserProfile = ({
    canUsePortfolioOffers,
    isOffersPublished,
    isCurrentUser,
    hideOffersSection,
    hideMeetingsSection,
    user: { ref, ...user },
}: IProps) => {
    const [translate] = useTranslation();
    const { tabs, Section } = useUserProfileTabs(
        user.id,
        isCurrentUser,
        canUsePortfolioOffers,
        hideOffersSection,
        hideMeetingsSection
    );
    const { data: currentUserData } = useCurrentUserQuery();
    const { data: offersSettingsData } = useOffersSettingsQuery();

    const { publishTraining, publishMeetingWebinar } =
        offersSettingsData?.offersSettings || {};

    const isTrainingOffersPage = !!useRouteMatch('PORTFOLIO_OFFERS');
    const isTrainingOffersUserPage = !!useRouteMatch('USER_OFFERS');
    const isMeetingsPage = !!useRouteMatch('PORTFOLIO_MEETINGS');
    const isMeetingsUserPage = !!useRouteMatch('USER_MEETINGS');

    // The portfolio offers page which shows training and courses
    const isOffersPage = isTrainingOffersPage || isTrainingOffersUserPage;
    // The portfolio meetings page which shows meetings and webinars
    const isPortfolioMeetingsPage = isMeetingsPage || isMeetingsUserPage;
    // Every offers related portfolio page
    const isPortfolioOffersPage = isOffersPage || isPortfolioMeetingsPage;

    const { roles } = currentUserData?.currentUser || {};
    const currentUserIsManager = roles?.includes(IRole.Manager);

    const showAlert =
        (isPortfolioOffersPage && !isOffersPublished) ||
        (currentUserIsManager &&
            ((isOffersPage && !publishTraining) ||
                (isPortfolioMeetingsPage && !publishMeetingWebinar)));

    return (
        <>
            <Box>
                <Box mb={2}>
                    <UserTabsMenu tabs={tabs} />
                </Box>

                {showAlert && (
                    <Alert
                        action={
                            <Button
                                color="inherit"
                                component={Link}
                                size="small"
                                sx={{ fontWeight: 'bold', mr: 1 }}
                                to="OFFER_SETTINGS_EDIT"
                            >
                                {translate('settings')}
                            </Button>
                        }
                        severity="error"
                        sx={{ mb: 2 }}
                    >
                        {translate(
                            `${isPortfolioOffersPage ? 'portfolioOffersPage' : 'portfolioMeetingsPage'}.notPublishedMessage`
                        )}
                    </Alert>
                )}

                {Section && (
                    <Box>
                        <Section {...user} withEditActions={isCurrentUser} />
                    </Box>
                )}
            </Box>
        </>
    );
};
