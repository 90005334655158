import { Element } from 'slate';
import { ReactEditor } from 'slate-react';

import { options } from '../../options';

import { IMenuPosition } from './types';

export function getMenuPosition(
    editor: ReactEditor,
    menuRef: HTMLElement,
    element: Element
): IMenuPosition | undefined {
    const elementEl = ReactEditor.toDOMNode(editor, element);

    const menuRect = menuRef.getBoundingClientRect();
    const elementRect = elementEl.getBoundingClientRect();

    const { width: menuWidth } = menuRect;
    const extraPadding = 10;

    const top = elementRect.top + window.pageYOffset;
    const left =
        elementRect.left + window.pageXOffset - (menuWidth + extraPadding);

    return { top, left };
}

export function getCurrentBlockMenu(element: Element) {
    const elementType = element.type as keyof typeof options;

    // Check if element type is defined in the options.
    if (!(elementType in options)) return null;

    const elementOptions = options[elementType];

    // Check if element option has an blockMenu
    if (!('blockMenu' in elementOptions)) return null;

    return elementOptions.blockMenu;
}
