import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    APIProvider,
    Map as GoogleMap,
    toLatLngLiteral,
    AdvancedMarker,
} from '@vis.gl/react-google-maps';

import { Loader } from 'common/components/Loader';

import { Geocoder } from './Geocoder';

interface IProps {
    address: string;
    children?: React.ReactNode;
}

export const Map = ({ address }: IProps) => {
    const [translate] = useTranslation();

    const apiKey = import.meta.env.VITE_GOOGLE_MAPS_KEY || '';
    const mapId = import.meta.env.VITE_GOOGLE_MAPS_MAP_ID || '';

    return (
        <Box
            sx={{
                position: 'relative',
                pt: '56.6%',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backgroundColor: 'grey.100',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <APIProvider apiKey={apiKey}>
                    <Geocoder address={address}>
                        {({ loading, position }) => {
                            if (loading) return <Loader />;

                            if (!position) {
                                return (
                                    <Box p={5}>
                                        {translate(
                                            'addressLocationLoadingNotice'
                                        )}
                                    </Box>
                                );
                            }

                            const pos = toLatLngLiteral(position);

                            return (
                                <GoogleMap
                                    disableDefaultUI
                                    zoomControl
                                    defaultCenter={pos}
                                    defaultZoom={16}
                                    gestureHandling="greedy"
                                    mapId={mapId}
                                >
                                    <AdvancedMarker position={pos} />
                                </GoogleMap>
                            );
                        }}
                    </Geocoder>
                </APIProvider>
            </Box>
        </Box>
    );
};
