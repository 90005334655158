import { forwardRef } from 'react';
import { Box, ButtonBase, ButtonBaseProps } from '@mui/material';
import { styled } from 'styled-components';
import { ExpandMore } from '@mui/icons-material';

import { Typography } from 'common/components/Typography';

const ExpandMoreIcon = styled(ExpandMore)`
    transform: rotate(0deg);
    transition: ${({ theme }) =>
        theme.transitions.create('transform', {
            duration: theme.transitions.duration.short,
        })};

    &.open {
        transform: rotate(180deg);
    }
`;

const StyledButton = styled(ButtonBase)<IProp>`
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};

    ${({ theme }) => theme.breakpoints.down('sm')} {
        & .mobile-hidden {
            display: none;
        }
    }
`;

interface IProp extends ButtonBaseProps {
    open?: boolean;
    textShown?: boolean;
}

const Button = (
    { children, open, textShown, ...other }: IProp,
    ref: React.Ref<HTMLButtonElement>
) => (
    <StyledButton {...other} disableRipple ref={ref}>
        <Typography
            className={textShown ? '' : 'mobile-hidden'}
            sx={{ fontWeight: 700 }}
            variant="body2"
        >
            {children}
        </Typography>

        <Box sx={{ mt: '6px' }}>
            <ExpandMoreIcon className={open ? 'open' : ''} />
        </Box>
    </StyledButton>
);

export const DropdownButton = forwardRef(Button);
