import { Container, ContainerProps } from '@mui/material';

export const ContentContainer = ({ ...other }: ContainerProps) => (
    <Container
        sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            pb: 3,
        }}
        {...other}
    />
);
