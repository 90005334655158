import {
    Dialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogProps,
    DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export interface IProps extends Omit<DialogProps, 'title'> {
    title?: React.ReactNode;
    actions?: React.ReactNode;
    children?: React.ReactNode;
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    '.MuiDialogTitle-root + &': { paddingTop: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 3, 3, 3),
}));

export const AlertDialog = ({
    children,
    actions,
    onClose,
    title,
    ...other
}: IProps) => (
    <Dialog {...other} onClose={onClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        {children && <DialogContent>{children}</DialogContent>}
        {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
);
