import { Box, TableRow } from '@mui/material';
import { mdiCheckboxBlankOff } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'common/components/Checkbox';
import { Chip, ChipGroup } from 'common/components/Chip';
import { TableCell } from 'common/components/Table';
import { IOfferEventSubscriptionStatus, IOfferEventType } from 'graphql/types';
import { OfferEventSubscriptionStatusChip } from 'offer/components/OfferEventSubscriptionStatusChip';
import { Icon } from 'common/components/Icon';
import { UserAvatar } from 'user/components/UserAvatar';
import { Typography } from 'common/components/Typography';
import { EnrollmentsTableActions } from 'offer/components/EnrollmentsTableActions';
import { TOfferEventDetailEnrollment } from 'offer/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    disabled?: boolean;
    isEditable?: boolean;
    isSelectable?: boolean;
    isSelected?: boolean;
    subscription: TOfferEventDetailEnrollment;
    offerEventType: IOfferEventType;
    onMailDialog?(): void;
    onSelect(): void;
    onSubscribeDeleteDialog(
        open: boolean,
        selectedAmount: number,
        userId?: string
    ): void;
    onSubscribeStatusDialog(
        open: boolean,
        selectedAmount: number,
        status?: IOfferEventSubscriptionStatus,
        userId?: string
    ): void;
}

export const SubscriptionRow = ({
    disabled,
    isEditable = false,
    isSelectable = false,
    isSelected,
    subscription,
    offerEventType,
    onMailDialog,
    onSelect,
    onSubscribeDeleteDialog,
    onSubscribeStatusDialog,
}: IProps) => {
    const [translate] = useTranslation();
    const { openGlobalDrawer } = useGlobalDrawer();
    const { id: subscriptionId, user } = subscription;

    let status, waitingListNumber, hasCertificate;

    if (subscription.__typename === 'OfferEventEnrollmentManageable') {
        status = subscription.status;
        waitingListNumber = subscription.waitingListNumber;
        hasCertificate = subscription.hasCertificate;
    }

    if (!user) return null;

    const { id, name } = user;

    const isWebinarOrMeeting = [
        IOfferEventType.Webinar,
        IOfferEventType.Meeting,
    ].includes(offerEventType);

    const isDisabled = !isEditable || disabled;
    const currentUserOnWaitingList =
        status === IOfferEventSubscriptionStatus.Full && !!waitingListNumber;

    return (
        <TableRow hover key={subscriptionId}>
            {isSelectable && (
                <TableCell>
                    {isEditable ? (
                        <Checkbox
                            checked={isSelected}
                            disabled={isDisabled}
                            onClick={(e) => {
                                onSelect?.();

                                e.stopPropagation();
                            }}
                        />
                    ) : (
                        <Checkbox
                            disabled
                            checked={false}
                            icon={
                                <Icon
                                    path={mdiCheckboxBlankOff}
                                    size="2.4rem"
                                />
                            }
                        />
                    )}
                </TableCell>
            )}

            <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    openGlobalDrawer({
                        type: IGlobalDrawerType.UserProfile,
                        itemId: id,
                    });
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar bgColor="secondary" size={45} user={user} />

                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body2">{name}</Typography>
                    </Box>
                </Box>
            </TableCell>

            {isSelectable && (
                <TableCell>
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent={!isSelectable ? 'right' : 'left'}
                    >
                        <Box alignItems="center" display="flex" ml="auto">
                            <Box mr={2}>
                                <ChipGroup>
                                    {user.isActive === false && (
                                        <Chip
                                            color="error"
                                            label={translate('inactive')}
                                        />
                                    )}

                                    {!!status && (
                                        <OfferEventSubscriptionStatusChip
                                            hasCertificate={!!hasCertificate}
                                            subscriptionStatus={status}
                                        />
                                    )}

                                    {currentUserOnWaitingList && (
                                        <Chip
                                            bgColor="warning"
                                            label={translate(
                                                'waitingListSubscriptionLabelCount',
                                                { waitingListNumber }
                                            )}
                                        />
                                    )}
                                </ChipGroup>
                            </Box>
                        </Box>

                        <EnrollmentsTableActions
                            isWebinarOrMeeting={isWebinarOrMeeting}
                            zIndex={1250}
                            onClickAbsent={() => {
                                onSubscribeStatusDialog(
                                    true,
                                    1,
                                    IOfferEventSubscriptionStatus.Absent,
                                    subscriptionId
                                );
                            }}
                            onClickComplete={() => {
                                onSubscribeStatusDialog(
                                    true,
                                    1,
                                    IOfferEventSubscriptionStatus.Completed,
                                    subscriptionId
                                );
                            }}
                            onClickDelete={() => {
                                onSubscribeDeleteDialog(
                                    true,
                                    1,
                                    subscriptionId
                                );
                            }}
                            onClickEnroll={() => {
                                onSubscribeStatusDialog(
                                    true,
                                    1,
                                    IOfferEventSubscriptionStatus.Enrolled,
                                    subscriptionId
                                );
                            }}
                            onClickMail={onMailDialog}
                            onClickReject={() => {
                                onSubscribeStatusDialog(
                                    true,
                                    1,
                                    IOfferEventSubscriptionStatus.Denied,
                                    subscriptionId
                                );
                            }}
                        />
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};
