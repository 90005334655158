import { styled } from 'styled-components';
import { motion, Variants } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { Skeleton } from '@mui/material';

import { TColorKeys } from 'theme/palette';

import { ListItemMediaIcon } from './ListItemMediaIcon';

interface IProps {
    isText?: boolean;
    image?: string;
    color?: TColorKeys;
    children?: React.ReactNode;
    className?: string;
    contentEditable?: boolean;
    skeleton?: boolean;
    size?: 'small';
}

const MediaItem = ({
    isText,
    image,
    color,
    children,
    skeleton,
    className,
    size,
    ...other
}: IProps) => {
    const theme = useTheme();
    const themeColor = color ? theme.palette[color] : null;

    if (skeleton) {
        return <Skeleton className={className} variant="rectangular" />;
    }

    // Enable color animation when themeColor is found and no image is given.
    const motionVariants: Variants =
        themeColor && !image
            ? {
                  initListItemButton: {
                      backgroundColor: themeColor.main,
                  },
                  hoverListItemButton: {
                      backgroundColor: themeColor.accent,
                      transition: { duration: 0.2 },
                  },
              }
            : {};

    return (
        <motion.div
            className={className}
            transition={{ duration: 0.2 }}
            variants={motionVariants}
            {...other}
        >
            {children && !isText && (
                <ListItemMediaIcon $show={!image} size={size}>
                    {children}
                </ListItemMediaIcon>
            )}

            {isText && children}
        </motion.div>
    );
};

export const ListItemMedia = styled(MediaItem)<IProps>`
    min-height: 50px;
    min-width: 70px;

    margin-right: ${({ theme }) => theme.spacing(2)};
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.common.white};
    ${({ image }) => image && `background-image: url('${image}');`}
    ${({ color, theme }) =>
        color && `background-color: ${theme.palette[color].main};`}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        margin-right: ${({ theme }) => theme.spacing(3)};

        ${({ size }) => {
            if (!size) {
                return `
                    min-height: 106px;
                    min-width: 150px;
                `;
            }

            return (
                size === 'small' &&
                `
                    min-height: 79px;
                    min-width: 130px;
                `
            );
        }}
    }
`;
