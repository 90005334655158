import { useTheme, useMediaQuery } from '@mui/material';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import { styled } from 'styled-components';

import { TColorKeys } from 'theme/palette';

export interface IProps extends AvatarProps {
    color?: TColorKeys;
    bgColor?: TColorKeys;
    size?: number; // Size is based on Sx sizes: 10 = 10px
    sizeSm?: number; // Size is based on Sx sizes: 10 = 10px
}

const BaseAvatar = ({ color, bgColor, size, sizeSm, ...props }: IProps) => {
    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    let avatarSize = size || 64;

    if (sizeSm && isSm) avatarSize = sizeSm;

    // Calculate fontSize based on avatarSize. Avatarsize divided by default spacing.
    const fontSize = 3 * (avatarSize / 8);

    return (
        <MuiAvatar
            sx={{ height: avatarSize, width: avatarSize, fontSize }}
            {...props}
        />
    );
};

export const Avatar = styled(BaseAvatar)`
    ${({ color, theme }) => color && `color: ${theme.palette[color].main};`}
    ${({ bgColor, theme }) =>
        bgColor && `background-color: ${theme.palette[bgColor].main};`}

    font-weight: 700;
    text-transform: uppercase;
`;
