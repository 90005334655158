import { userRolesFilter } from 'user/utils/filter';
import { IFilterFormItem, IFilterPermissions } from 'common/types';
import { userGroupsFilter } from 'common/utils/drawerFilters';

export function getUserFilters(
    permissions?: IFilterPermissions
): IFilterFormItem[] {
    return [
        userRolesFilter(undefined, undefined, permissions?.rolesFilter),
        userGroupsFilter(undefined, 'groupIds'),
    ] as IFilterFormItem[];
}
