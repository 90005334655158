import { useEffect, useState } from 'react';

import {
    ICourseSortBy,
    ICoursesQueryVariables,
    IExtraCategoryFilterInput,
} from 'graphql/types';
import { DEFAULT_PAGINATION_SETTINGS } from 'common/hooks/usePagination';
import { TFilterBarItem } from 'common/types';
import {
    extraCategoryFiltersAreEqual,
    getExtraCategoryInputVariables,
} from 'organisation/utils/extraCategory';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
    selectedFilters: TFilterBarItem[];
}

export const useCourseQueryVariables = ({
    paginationVariables,
    searchQueryParam,
    selectedFilters,
}: IProps) => {
    const [courseQueryVariables, setCourseQueryVariables] = useState<
        ICoursesQueryVariables & {
            extraCategories?: IExtraCategoryFilterInput[];
        }
    >({
        sortBy: ICourseSortBy.Title,
        q: '',
        archived: false,
        ...DEFAULT_PAGINATION_SETTINGS,
    });

    useEffect(() => {
        const newCourseQueryVariables = {
            ...paginationVariables,
            q: searchQueryParam,
            extraCategories: getExtraCategoryInputVariables(selectedFilters),
        };
        const { q, offset, first, extraCategories } = courseQueryVariables;

        if (
            q === newCourseQueryVariables.q &&
            offset === newCourseQueryVariables.offset &&
            first === newCourseQueryVariables.first &&
            extraCategoryFiltersAreEqual(
                extraCategories || [],
                newCourseQueryVariables.extraCategories
            )
        ) {
            return;
        }

        setCourseQueryVariables({
            ...courseQueryVariables,
            ...newCourseQueryVariables,
            offset:
                q !== newCourseQueryVariables.q
                    ? 0
                    : newCourseQueryVariables.offset,
        });
    }, [
        courseQueryVariables,
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    ]);

    return {
        courseQueryVariables,
    };
};
