import { createContext } from 'react';
import { OverridedMixpanel } from 'mixpanel-browser';

import { trackMixpanel } from 'common/utils/mixpanel';

export interface IMixpanelContextValue {
    mixpanel?: OverridedMixpanel;
    trackMixpanel: ReturnType<typeof trackMixpanel>;
}

export const DEFAULT_MIXPANEL_CONTEXT = { trackMixpanel: trackMixpanel() };

export const MixpanelContext = createContext<IMixpanelContextValue>(
    DEFAULT_MIXPANEL_CONTEXT
);
