import { Field, FieldAttributes } from 'formik';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'common/constants/languages';
import { Select } from 'common/components/FormField';

export const LanguageSelectField = ({ ...other }: FieldAttributes<unknown>) => {
    const [translate] = useTranslation();

    const languageOptions = Object.entries(LANGUAGES).map(([value, label]) => ({
        value,
        label: translate(label),
    }));

    return (
        <Field
            component={Select}
            defaultValue=""
            label={translate('language')}
            options={languageOptions}
            {...other}
        />
    );
};
