import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    useCreateExternalContentModuleMutation,
    IOpenOffsetType,
} from 'graphql/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { useApolloError } from 'common/hooks/useApolloError';
import { getUrl } from 'route/utils/getUrl';
import {
    ExternalContentModuleForm,
    IExternalContentModuleFormProps,
} from 'training/components/forms/ExternalContentModuleForm';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { createModuleInCache } from 'training/utils/module';

import { ExternalContentModuleSelectDrawer } from './ExternalContentModuleSelectDrawer';

export const ExternalContentModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const navigate = useNavigate();

    const routeQuery = useRouteQuery();
    const externalContentIdParam =
        routeQuery.get('externalContent') || undefined;
    const [selectedExternalContentId, setSelectedExternalContentId] = useState(
        externalContentIdParam
    );

    const externalContentSelectDrawerOpen =
        useHashMatch('TRAINING_MODULE_SELECT') && !!selectedExternalContentId;

    const [createModule] = useCreateExternalContentModuleMutation();

    const handleSubmit: IExternalContentModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { image, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            imageId: image?.id,
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
        // in the training which will make a new moduleGroup
        const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

        try {
            await createModule({
                variables: { module: moduleValues, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createExternalContentModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Create external content module in drawer',
        });

        displaySnackbar(
            translate('moduleActionSuccess.externalContent.create'),
            {
                variant: 'success',
            }
        );

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    const initialValues = {
        title: '',
        description: '',
        contentId: '',
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
    };

    return (
        <>
            <PageTitle mixpanelTitle="Drawer create external content module">
                {translate('newExternalContentModule')}
            </PageTitle>

            <ExternalContentModuleSelectDrawer
                open={externalContentSelectDrawerOpen}
                onClose={() => {
                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
                onSelect={(externalContent) => {
                    // Redirect to the pathname to go to the page without hashtag.
                    setSelectedExternalContentId(externalContent.id);
                    navigate(window.location.pathname);
                }}
            />

            {!selectedExternalContentId ? (
                <ExternalContentModuleSelectDrawer
                    open
                    inDrawer={false}
                    onSelect={(externalContent) => {
                        setSelectedExternalContentId(externalContent.id);
                        navigate(window.location.pathname);
                    }}
                />
            ) : (
                <ExternalContentModuleForm
                    externalContentId={selectedExternalContentId}
                    initialValues={initialValues}
                    moduleGroupId={moduleGroupId}
                    onReplaceExternalContent={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    );
};
