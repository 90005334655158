import * as Sections from 'training/components/Section';
import { ITrainingSectionProps } from 'training/types';
import { TRouteName } from 'route/types';

export const SECTIONS: {
    [K in TRouteName]?: React.FC<
        React.PropsWithChildren<ITrainingSectionProps>
    >;
} = {
    TRAINING: Sections.Overview,
    TRAINING_PARTICIPANTS: Sections.Participants,
    TRAINING_TRAINERS: Sections.Trainers,
    TRAINING_SHARED_FILES: Sections.SharedFiles,
};
