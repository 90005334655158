import { useEffect } from 'react';

import {
    IGroupListItemFragment,
    IGroupsQueryVariables,
    useGroupsLazyQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export const useGroupList = (groupQueryVariables?: IGroupsQueryVariables) => {
    const { showApolloError } = useApolloError();
    const [fetchGroups, { data, loading }] = useGroupsLazyQuery({
        onError: showApolloError,
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!groupQueryVariables) return;

        fetchGroups({
            variables: groupQueryVariables,
        });
    }, [groupQueryVariables, fetchGroups]);

    const groupsObject = data?.paginatedGroups;
    const groupsCount = groupsObject?.count;
    const groups =
        (groupsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IGroupListItemFragment[]) || [];

    return {
        groups,
        groupsCount,
        loading,
    };
};
