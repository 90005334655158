import React from 'react';
import { Box } from '@mui/material';

import { IScorm } from 'graphql/types';
import { ScormListItem } from 'training/components/ScormListItem';

import { PortfolioItemContentBase } from './PortfolioItemContentBase';

export const ScormPortfolioItemContent = (
    props: React.ComponentProps<typeof PortfolioItemContentBase>
) => {
    const { portfolioItem } = props;

    const scorm = portfolioItem.relatedObject as IScorm;

    return (
        <PortfolioItemContentBase {...props}>
            <Box px={4} py={2}>
                <ScormListItem disabled scorm={scorm} />
            </Box>
        </PortfolioItemContentBase>
    );
};
