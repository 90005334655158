import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { mdiFileRemove } from '@mdi/js';

import {
    IRole,
    useCurrentUserQuery,
    useDeleteCoursesMutation,
} from 'graphql/types';
import { BoxLoader } from 'common/components/Loader';
import { usePagination } from 'common/hooks/usePagination';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useCourseSearch } from 'training/hooks/useCourseSearch';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    TableActionBar,
    TableCell,
    TableHead,
    useSelection,
} from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import { useCourseQueryVariables } from 'training/hooks/useCourseQueryVariables';
import { useCourseList } from 'training/hooks/useCourseList';
import { TFilterBarItem } from 'common/types';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Checkbox } from 'common/components/Checkbox';

import { CourseManagementTableRow } from './CourseManagementTableRow';

interface IProps {
    onLoading?(loading: boolean): void;
}

export const CourseManagementTable = ({ onLoading }: IProps) => {
    const [translate] = useTranslation();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [deleteCourses, { loading: loadingDeleteCourses }] =
        useDeleteCoursesMutation();

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useCourseSearch();

    const { data: currentUserData, loading: loadingCurrentUser } =
        useCurrentUserQuery();
    const currentUser = currentUserData?.currentUser;
    const currentUserIsManager = currentUser?.roles?.includes(IRole.Manager);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { courseQueryVariables } = useCourseQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        courses,
        coursesCount,
        loading: loadingCourses,
    } = useCourseList(courseQueryVariables);

    const loading =
        loadingCourses || loadingDeleteCourses || loadingCurrentUser;

    const { ...selectionProps } = useSelection(courses);
    const { isSelected, rows, selected, onSelectAll } = selectionProps;

    useEffect(() => {
        onLoading?.(loading);
    }, [loading, onLoading]);

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(coursesCount || 0);
    }, [coursesCount, loading, initializePagination]);

    const resetSettings = () => {
        onSelectAll();
        setPage(1);
        handleSearch('');
        handleFilterSelect([]);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);

        if (!!selected?.length) onSelectAll?.();
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);

        if (!!selected?.length) onSelectAll?.();
    };

    const handleDelete = async () => {
        let hasSuccess = false;
        let hasFailed = false;

        try {
            const response = await deleteCourses({
                variables: { ids: selected },
                update: (cache) => {
                    // Remove all deleted courses from cache to make sure they are gone throughout the system
                    selected.forEach((courseId) => {
                        cache.evict({ id: `Course:${courseId}` });
                    });

                    cache.evict({ fieldName: 'courses' });

                    cache.gc();
                },
            });

            const { succeededIds, failedIds } =
                response?.data?.deleteCourses?.bulkDelete || {};

            // Mark if a course delete has succeeded and/or failed
            hasSuccess = !!succeededIds?.length;
            hasFailed = !!failedIds?.length;
        } catch (error) {
            showApolloError(error);
            setShowDeleteDialog(false);

            return;
        }

        setShowDeleteDialog(false);

        resetSettings();

        // Check if a course was successfully deleted and show success message
        if (hasSuccess) {
            displaySnackbar(
                translate(
                    'courseManagementPage.courseActionSuccess.bulkDelete'
                ),
                {
                    autoHideDuration: 30000,
                    variant: 'success',
                }
            );
        }

        // Check if a course failed to delete and show a general error message
        if (hasFailed) {
            displaySnackbar(
                translate('courseManagementPage.deleteCoursesFailed'),
                { autoHideDuration: 30000 }
            );
        }
    };

    const tableActions = currentUserIsManager ? (
        <Tooltip title={translate('courseManagementPage.deleteCourses')}>
            <Box>
                <IconButton
                    disabled={!isSelected}
                    iconPath={mdiFileRemove}
                    iconSize="2.4rem"
                    onClick={() => setShowDeleteDialog(true)}
                />
            </Box>
        </Tooltip>
    ) : null;

    return (
        <>
            <FilterBar
                disabled={loading}
                filters={filters}
                initialSearchValue={searchQueryParam}
                initialSelected={selectedFilters}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
                onSelect={handleFilterSelect}
            />

            {!loading && !!rows.length && (
                <TableContainer>
                    {currentUserIsManager && (
                        <TableActionBar
                            actions={tableActions}
                            selectionProps={selectionProps}
                        />
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {currentUserIsManager && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={false}
                                            disabled={isSelected}
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{translate('title')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const course = courses[index] || null;

                                if (!course) return null;

                                return (
                                    <CourseManagementTableRow
                                        course={course}
                                        isEditable={currentUserIsManager}
                                        isSelected={isSelected}
                                        key={course.id}
                                        onSelect={onSelect}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {loading && <BoxLoader />}

            {!loading && !rows.length && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Typography>
                        {translate('noOptionsText.courses')}
                    </Typography>
                </Box>
            )}

            {!loading && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={
                            paginationSettings.count === 1
                                ? translate('course')
                                : translate('courses')
                        }
                        onChange={(page: number) => {
                            setPage(page);
                        }}
                    />
                </Box>
            )}

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDeleteCourses}
                open={showDeleteDialog}
                title={translate(
                    'courseManagementPage.coursesDeleteDialog.title'
                )}
                onCancel={() => {
                    setShowDeleteDialog(false);
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
            >
                {translate('courseManagementPage.coursesDeleteDialog.text')}
            </ConfirmDialog>
        </>
    );
};
