import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { makeVar } from '@apollo/client';

import { BasePage } from 'hydra/pages/BasePage';
import { Typography } from 'common/components/Typography';
import { useTrainingQuery, ITrainingRole } from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { useTrainingTabs } from 'training/hooks';
import { TrainingTabsMenu } from 'training/components/TrainingTabsMenu';
import { useFrontendPermissions } from 'user/hooks';
import { PageIntroHeader } from 'common/components/PageIntroHeader';
import { isUUID } from 'common/utils/isUUID';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { TrainingUpdateDrawer } from 'training/components/TrainingDrawer';
import { ModuleCreateDrawer } from 'training/components/ModuleCreateDrawer';
import { ModuleUpdateDrawer } from 'training/components/ModuleUpdateDrawer';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { TrainingInfo } from 'training/components/TrainingInfo';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { TrainingContextActionBar } from 'training/components/TrainingContextActionBar';
import { InlineStickyWrapper } from 'common/components/InlineStickyWrapper';

import { FourOFourPage } from './FourOFourPage';

// Variable to keep state for when the user is looking at studentView
export const trainingStudentView = makeVar(true);

export const TrainingPage = () => {
    const navigate = useNavigate();
    const { trainingId } = useParams() as { trainingId: string };

    const { data, loading, called } = useTrainingQuery({
        variables: { id: trainingId },
        nextFetchPolicy: 'cache-only',
        returnPartialData: true,
    });
    const { canUpdate } = useFrontendPermissions('training');

    const canUpdateTraining = canUpdate || data?.training?.currentUserIsAuthor;

    const training = data?.training;
    const { Section, tabs } = useTrainingTabs(trainingId, training);

    const isParticipantsPage = !!useRouteMatch('TRAINING_PARTICIPANTS');
    const isTrainersPage = !!useRouteMatch('TRAINING_TRAINERS');
    const isSharedFilesPage = !!useRouteMatch('TRAINING_SHARED_FILES');

    const showPreviewButton =
        !isParticipantsPage && !isTrainersPage && !isSharedFilesPage;

    const isEditPage = useHashMatch('TRAINING_EDIT');
    const isCreateModulePage = !!useRouteMatch('TRAINING_CREATE_MODULE');
    const isCreateModuleTypePage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_TYPE'
    );
    const isCreateModuleInGroupPage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_IN_GROUP'
    );

    const isCreateModuleTypeInGroupPage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_TYPE_IN_GROUP'
    );

    const isModuleGroupCreatePage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_GROUP'
    );
    const isModuleGroupEditPage = !!useRouteMatch('TRAINING_EDIT_MODULE_GROUP');

    const isUpdateModulePage = !!useRouteMatch('TRAINING_UPDATE_MODULE');

    const { rolesForCurrentUser } = training || {};

    const isTrainer =
        !!rolesForCurrentUser?.includes(ITrainingRole.Trainer) ||
        !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);
    const isGroupmanager = !!rolesForCurrentUser?.includes(
        ITrainingRole.Groupmanager
    );

    const { currentUserIsAuthor } = training || {};

    // canManage are user who can update training can see conditions effects, rate assignments, etc,
    const canManageTraining = !!(
        isTrainer ||
        isGroupmanager ||
        canUpdateTraining ||
        currentUserIsAuthor
    );

    const canManageParticipants = !!(
        isTrainer ||
        isGroupmanager ||
        // We use canUpdate because we want to exclude authors
        canUpdate
    );

    // Set student view to canManage to always set the correct initial value.
    useEffect(() => {
        trainingStudentView(!canManageTraining);
    }, [canManageTraining]);

    if (!isUUID(trainingId)) {
        return <FourOFourPage />;
    }

    // Render 404 when training is not found
    if (!loading && called && !training) {
        return <FourOFourPage />;
    }

    // When on a module group create or update route and can't manage
    const unauthorizedModuleGroupEdit =
        (isModuleGroupCreatePage || isModuleGroupEditPage) &&
        !canManageTraining;

    if (unauthorizedModuleGroupEdit) {
        return <FourOFourPage />;
    }

    const {
        modelId,
        title,
        subtitle,
        titleColor: trainingTitleColor,
        image,
        conditions,
        estimatedTimeSpent,
        extraTrainingFieldsLabels,
    } = training || {};

    const titleColor = trainingTitleColor && `#${trainingTitleColor}`;

    let headerColor = titleColor;

    if (!headerColor) {
        headerColor = image ? 'common.white' : undefined;
    }

    const trainingInfo = (
        <TrainingInfo
            conditions={conditions}
            estimatedTimeSpent={estimatedTimeSpent}
            extraTrainingFieldsLabels={extraTrainingFieldsLabels}
            sx={{ color: headerColor }}
        />
    );

    const showModuleCreateDrawer =
        isCreateModulePage ||
        isCreateModuleTypePage ||
        isCreateModuleInGroupPage ||
        isCreateModuleTypeInGroupPage;

    let header;

    if (image) {
        header = (
            <PageIntroHeader fixed={isParticipantsPage} image={image}>
                <Typography sx={{ color: headerColor }} variant="h1">
                    {title}
                </Typography>
                <Typography
                    sx={{
                        color: headerColor,
                    }}
                    variant="subtitle1"
                >
                    {subtitle}
                </Typography>

                {trainingInfo}
            </PageIntroHeader>
        );
    }

    return (
        <BasePage
            contextActionBar={
                training && (
                    <TrainingContextActionBar
                        canUpdateTraining={!!canUpdateTraining}
                        showPreviewButton={showPreviewButton}
                        training={training}
                    />
                )
            }
            header={header}
        >
            {loading && <Loader />}

            {!loading && training && (
                <Box
                    sx={{
                        pb: 8,
                        pt: conditions || header ? 4 : { xs: 4, sm: 8 },
                        // When not on participants page, set width to max-content to support sticky elements
                        width: isParticipantsPage ? 'max-content' : undefined,
                    }}
                >
                    <InlineStickyWrapper fullWidth leftOffset={3}>
                        {!header && (
                            <Box mb={2}>
                                <Box mb={1}>
                                    <Typography
                                        sx={{
                                            color: headerColor,
                                        }}
                                        variant="h1"
                                    >
                                        {title}
                                    </Typography>
                                </Box>
                                {subtitle && (
                                    <Typography
                                        sx={{
                                            color: headerColor,
                                        }}
                                        variant="subtitle1"
                                    >
                                        {subtitle}
                                    </Typography>
                                )}

                                {trainingInfo}
                            </Box>
                        )}

                        {tabs.length > 1 && <TrainingTabsMenu tabs={tabs} />}
                    </InlineStickyWrapper>

                    {Section && (
                        <Box pt={2}>
                            <Section
                                canManage={canManageTraining}
                                canManageParticipants={canManageParticipants}
                                training={training}
                            />
                        </Box>
                    )}

                    {canUpdateTraining && (
                        <>
                            <TrainingUpdateDrawer
                                id={trainingId}
                                open={isEditPage}
                            />
                            <ModuleCreateDrawer
                                open={showModuleCreateDrawer}
                                trainingId={trainingId}
                                trainingModelId={modelId || undefined}
                                onClose={() => {
                                    gotoTrainingPage(navigate, trainingId);
                                }}
                            />
                            <ModuleUpdateDrawer
                                open={isUpdateModulePage}
                                onClose={() => {
                                    gotoTrainingPage(navigate, trainingId);
                                }}
                            />
                        </>
                    )}
                </Box>
            )}
        </BasePage>
    );
};
