import { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ITrainingListItemFragment } from 'graphql/types';
import { TrainingListItem } from 'training/components/TrainingListItem';
import { Typography } from 'common/components/Typography';
import {
    getTrainingSelectStatus,
    isTrainingAdded,
    isTrainingSelected,
} from 'training/utils/trainingSelect';
import { AddButton } from 'common/components/Button/AddButton';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';

import { TrainingSelectDrawer } from './TrainingSelectDrawer';

interface IProps {
    isEditable?: boolean;
    label?: string;
    name?: string;
    noResultsLabel?: string;
    value: ITrainingListItemFragment[];
}

export const TrainingSelector = ({
    isEditable = true,
    label,
    name,
    noResultsLabel,
    value,
}: IProps) => {
    const { t: translate } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedTrainings, setSelectedTrainings] = useState<
        ITrainingListItemFragment[]
    >([]);
    const navigate = useNavigate();
    const location = useLocation();

    const addedTrainings = value;

    const handleDrawerOpen = (open: boolean) => {
        if (!open) setSelectedTrainings([]);

        setDrawerOpen(open);
    };

    const handleSelectTraining = (training: ITrainingListItemFragment) => {
        const newTrainings = [...selectedTrainings, training].sort((a, b) =>
            a.title > b.title ? 1 : -1
        );

        setSelectedTrainings(newTrainings);
    };

    const handleRemoveTraining = (
        training: ITrainingListItemFragment,
        arrayHelpers: FieldArrayRenderProps
    ) => {
        const { isSelected } = getTrainingSelectStatus(
            selectedTrainings,
            addedTrainings,
            training
        );

        if (isSelected) {
            const newSelectedTrainings = selectedTrainings.filter(
                (selectedTraining) => selectedTraining.id !== training.id
            );

            setSelectedTrainings(newSelectedTrainings);

            return;
        }

        const index = addedTrainings.findIndex(
            (addedTraining) => training.id === addedTraining.id
        );

        arrayHelpers.remove(index);
    };

    const handleAddTrainings = (arrayHelpers: FieldArrayRenderProps) => {
        selectedTrainings.forEach((selectedTraining) => {
            arrayHelpers.push(selectedTraining);
        });

        setSelectedTrainings([]);

        handleDrawerOpen(false);
    };

    const fieldName = name || 'trainings';

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const error = form.errors[fieldName];
                const touched = form.touched[fieldName];

                return (
                    <Box>
                        <Box
                            alignItems="left"
                            display="flex"
                            flexDirection="column"
                        >
                            {isEditable && (
                                <AddButton
                                    onClick={() => handleDrawerOpen(true)}
                                >
                                    <Typography>
                                        {label ||
                                            translate('addTrainingsTitle')}
                                    </Typography>

                                    {error && touched && (
                                        <Box mt="-5px" position="absolute">
                                            <FormHelperText error>
                                                {form.errors[fieldName]}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                </AddButton>
                            )}

                            {!!addedTrainings.length && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mt={isEditable ? 2 : 0}
                                >
                                    {addedTrainings.map((addedTraining) => (
                                        <TrainingListItem
                                            addButton={isEditable}
                                            added={isTrainingAdded(
                                                addedTrainings,
                                                addedTraining
                                            )}
                                            key={`added-${addedTraining.id}`}
                                            selected={isTrainingSelected(
                                                selectedTrainings,
                                                addedTraining
                                            )}
                                            training={addedTraining}
                                            onClick={(
                                                training: ITrainingListItemFragment
                                            ) => {
                                                if (!isEditable) {
                                                    gotoTrainingPage(
                                                        navigate,
                                                        training.id,
                                                        location
                                                    );

                                                    return;
                                                }

                                                handleRemoveTraining(
                                                    training,
                                                    arrayHelpers
                                                );
                                            }}
                                        />
                                    ))}
                                </Box>
                            )}

                            {!isEditable && !addedTrainings.length && (
                                <Box>
                                    <Typography>
                                        {noResultsLabel ||
                                            translate(
                                                'noOptionsText.trainings'
                                            )}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <TrainingSelectDrawer
                            addedTrainings={addedTrainings}
                            open={drawerOpen}
                            selectedTrainings={selectedTrainings}
                            onAddTrainings={() =>
                                handleAddTrainings(arrayHelpers)
                            }
                            onDrawerOpen={handleDrawerOpen}
                            onRemoveTraining={(training) =>
                                handleRemoveTraining(training, arrayHelpers)
                            }
                            onSelectTraining={handleSelectTraining}
                        />
                    </Box>
                );
            }}
        />
    );
};
