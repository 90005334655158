import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    Button as MuiButton,
} from '@mui/material';
import { mdiRefresh, mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';

import {
    TableActionBar,
    TableHead,
    TableCell,
    TableSortButton,
    useSelection,
} from 'common/components/Table';
import {
    IPortfolioReportOrderField,
    IContentTypeValue,
    useExportPortfolioReportMutation,
    useCurrentUserQuery,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { Typography } from 'common/components/Typography';
import { BoxLoader } from 'common/components/Loader';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';
import { useReportList } from 'report/hooks/useReportList';
import { Alert } from 'common/components/Alert';
import { Link } from 'common/components/Link';
import { Checkbox } from 'common/components/Checkbox';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import { Button } from 'common/components/Button';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { ResetPortfolioItemProgressAction } from 'user/actions/ResetPortfolioItemProgressAction';

import { CourseReportTableRow } from './CourseReportTableRow';

export const CourseReportTable = () => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [showExportDialog, setShowExportDialog] = useState(false);

    // Get current user email to use in export dialog
    const { data: currentUserData, loading: loadingCurrentUser } =
        useCurrentUserQuery();
    const currentUserEmail = currentUserData?.currentUser?.email;

    const relatedObjectTypes = [
        IContentTypeValue.Course,
        IContentTypeValue.EventLti,
        IContentTypeValue.Scorm,
        IContentTypeValue.ExternalContent,
    ];

    const {
        items,
        loading: loadingReport,
        count,
        onSearch,
        onFilterChange,
        filters,
        filterValues,
        onFilterChangeValue,
        currentSort,
        onSort,
        setPage,
        paginationSettings,
        filterValuesForQuery,
    } = useReportList('course', false, {
        relatedObjectTypes,
        withAggregatedFilterValues: true,
        isModuleReport: true,
        isCertificateOrModuleReport: true,
    });

    const loading = loadingReport || loadingCurrentUser;

    const [exportReport, { loading: loadingExport }] =
        useExportPortfolioReportMutation({
            variables: {
                relatedObjectTypes,
                ...filterValuesForQuery,
            },
        });

    const { ...selectionProps } = useSelection(items);
    const { rows, selected, onSelectAll, isSelected } = selectionProps;

    const handleExport = async () => {
        try {
            await exportReport();
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('reportPage.exportSuccess'), {
            variant: 'success',
        });

        setShowExportDialog(false);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const tableActions = (
        <Tooltip title={translate('resetProgress')}>
            <Box>
                <ResetPortfolioItemProgressAction
                    portfolioItemIds={selected}
                    onSuccess={() => {
                        // Deselect all
                        !!selected.length && onSelectAll();
                    }}
                >
                    <IconButton
                        disabled={!isSelected}
                        iconPath={mdiRefresh}
                        iconSize="2.4rem"
                    />
                </ResetPortfolioItemProgressAction>
            </Box>
        </Tooltip>
    );

    return (
        <>
            <Box>
                <Alert
                    action={
                        <MuiButton
                            color="inherit"
                            component={Link}
                            size="small"
                            sx={{
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold',
                                mr: 1,
                            }}
                            to="MANAGEMENT_COURSE_REPORTING"
                        >
                            {translate('reportPage.beta.buttonText')}
                        </MuiButton>
                    }
                    severity="warning"
                    sx={{ mb: 2 }}
                >
                    {translate('reportPage.beta.message')}
                </Alert>
            </Box>

            <FilterBar
                useFilterDrawer
                barAppend={
                    <Button
                        disabled={count === 0}
                        startIcon={<Icon path={mdiDownload} size="1.9rem" />}
                        sx={{ flex: 1 }}
                        variant="outlined"
                        onClick={() => setShowExportDialog(true)}
                    />
                }
                filterDrawerProps={{
                    title: translate('reportPage.filterFormCourse.title'),
                    description: translate(
                        'reportPage.filterFormCourse.description'
                    ),
                    formProps: {
                        onReset: (values) => {
                            setPage(1);
                            onFilterChange(values);
                        },
                    },
                }}
                formFilters={filters}
                formFilterValues={filterValues}
                initialSearchValue={filterValues.q}
                placeholder={translate('filterBarPlaceholder')}
                onChangeField={onFilterChangeValue}
                onFiltersChange={(values) => {
                    setPage(1);
                    onFilterChange(values);
                }}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />

            {!loading && !!rows.length && (
                <TableContainer>
                    <TableActionBar
                        actions={tableActions}
                        selectionProps={selectionProps}
                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={false}
                                        disabled={isSelected}
                                        onChange={onSelectAll}
                                    />
                                </TableCell>
                                <TableCell>{translate('user')}</TableCell>
                                <TableCell>{translate('item')}</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        {translate('start')}
                                        <TableSortButton
                                            currentSortDirection={
                                                currentSort.direction
                                            }
                                            currentSortField={
                                                currentSort.field as string
                                            }
                                            sortField={
                                                IPortfolioReportOrderField.StartDate
                                            }
                                            onClick={() =>
                                                onSort(
                                                    IPortfolioReportOrderField.StartDate
                                                )
                                            }
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {translate('fulfilledOn')}
                                </TableCell>
                                <TableCell>{translate('time')}</TableCell>
                                <TableCell>{translate('score')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const reportItem = items[index];

                                if (!reportItem) return null;

                                return (
                                    <CourseReportTableRow
                                        isSelected={isSelected}
                                        key={reportItem.id}
                                        reportItem={reportItem}
                                        onSelect={onSelect}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loading && <BoxLoader />}

            {!loading && !items.length && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Typography>
                        {translate('noOptionsText.reportItems')}
                    </Typography>
                </Box>
            )}

            {!loading && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate('portfolioReportItems', {
                            count,
                        })}
                        onChange={(page: number) => {
                            setPage(page);
                        }}
                    />
                </Box>
            )}

            <ConfirmDialog
                confirmText={translate('export')}
                loading={loadingExport}
                open={!!showExportDialog}
                title={translate('reportPage.exportCourseDialog.title')}
                onCancel={() => setShowExportDialog(false)}
                onClose={() => setShowExportDialog(false)}
                onConfirm={handleExport}
            >
                {translate(`reportPage.exportCourseDialog.text`, {
                    email: currentUserEmail,
                })}
            </ConfirmDialog>
        </>
    );
};
