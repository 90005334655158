import { useTranslation } from 'react-i18next';
import {
    mdiAccountMinus,
    mdiAccountPlus,
    mdiClipboardCheck,
    mdiClipboardRemove,
} from '@mdi/js';
import { SxProps, Tooltip } from '@mui/material';

import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { IOfferEventSubscriptionStatus } from 'graphql/types';

const buttonStyle: SxProps = {
    width: 45,
    height: 40,
    p: 0,
    '&.MuiButton-outlined': {
        p: 0,
    },
    mr: 1,
};

interface IProps {
    enrollmentStatus: IOfferEventSubscriptionStatus;
    onClickEnroll?(): void;
    onClickReject?(): void;
    onClickComplete?(): void;
    onClickAbsent?(): void;
}

export const EnrollmentManagementTableRowButtons = ({
    enrollmentStatus,
    onClickEnroll,
    onClickReject,
    onClickComplete,
    onClickAbsent,
}: IProps) => {
    const [translate] = useTranslation();

    const isRequestStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Requested;
    const isWaitingListStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Full;
    const isEnrolledStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Enrolled;

    let translationKeyPrimary = 'enroll';
    let translationKeySecondary = 'reject';
    let iconPathPrimary = mdiAccountPlus;
    let iconPathSecondary = mdiAccountMinus;
    let onClickPrimary = onClickEnroll;
    let onClickSecondary = onClickReject;

    if (enrollmentStatus === IOfferEventSubscriptionStatus.Enrolled) {
        translationKeyPrimary = 'complete';
        translationKeySecondary = 'absent';
        iconPathPrimary = mdiClipboardCheck;
        iconPathSecondary = mdiClipboardRemove;
        onClickPrimary = onClickComplete;
        onClickSecondary = onClickAbsent;
    }

    const primaryButton = (
        <Tooltip title={translate(`enrollmentsTable.${translationKeyPrimary}`)}>
            <Button
                color="primary"
                sx={buttonStyle}
                variant="contained"
                onClick={onClickPrimary}
            >
                <Icon path={iconPathPrimary} size="2.4rem" />
            </Button>
        </Tooltip>
    );

    const secondaryButton = (
        <Tooltip
            title={translate(`enrollmentsTable.${translationKeySecondary}`)}
        >
            <Button
                color="inherit"
                sx={{ ...buttonStyle, borderColor: 'divider' }}
                variant="outlined"
                onClick={onClickSecondary}
            >
                <Icon path={iconPathSecondary} size="2.4rem" />
            </Button>
        </Tooltip>
    );

    if (!isRequestStatus && !isWaitingListStatus && !isEnrolledStatus)
        return null;

    return (
        <>
            {primaryButton}
            {secondaryButton}
        </>
    );
};
