import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

import {
    IModuleFragment,
    IModuleType,
    useDeleteModuleMutation,
    useModuleEditQuery,
    useUpdateWebhookModuleMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleUpdateDrawerProps } from 'training/types';
import { Loader } from 'common/components/Loader';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import {
    IWebhookModuleFormProps,
    WebhookModuleForm,
} from 'training/components/forms/WebhookModuleForm';

export const WebhookModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.Webhook },
        onError: showApolloError,
    });

    const [updateModule] = useUpdateWebhookModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    const module = data?.module;

    if (loading || !module) return <Loader />;

    if (!module || module.__typename !== 'WebhookModule') return null;

    const handleSubmit: IWebhookModuleFormProps['onSubmit'] = async (
        values
    ) => {
        try {
            await updateModule({
                variables: { id, module: values },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.webhook.update'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.Webhook,
                },
                update: (cache) => {
                    cache.modify({
                        id: cache.identify(module.moduleGroup),
                        fields: {
                            modules(existingModuleRefs, { readField }) {
                                return existingModuleRefs.filter(
                                    (moduleRef: IModuleFragment) =>
                                        id !== readField('id', moduleRef)
                                );
                            },
                        },
                    });

                    cache.evict({ id: `WebhookModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.webhook.delete'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const {
        triggerOnUpload,
        triggerOnCondition,
        externalRef,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        triggerOnUpload,
        triggerOnCondition,
        externalRef,
    };

    return (
        <>
            <PageTitle>{translate('updateWebhookModule')}</PageTitle>

            <WebhookModuleForm
                edit
                initialValues={initialValues}
                moduleGroupId={moduleGroupId}
                onDelete={() => setShowDeleteAlert(true)}
                onSubmit={handleSubmit}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteWebhookModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteWebhookModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
