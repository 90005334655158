import { useState } from 'react';
import { Box, Grid, useMediaQuery, Theme } from '@mui/material';
import { Form, Formik, Field, FastField } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';

import { IFeature, ICreateTrainingInput, IFileFragment } from 'graphql/types';
import { useFeature } from 'common/hooks/useFeature';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
    CARD_IMAGE_MAX_SIZE,
    CARD_IMAGE_TYPE,
} from 'common/constants/files';
import {
    Switch,
    ImageField,
    TextField,
    ColorPicker,
    DatePicker,
    SliderField,
} from 'common/components/FormField';
import { Typography } from 'common/components/Typography';
import { ApolloError } from 'common/components/ApolloError';
import { Button } from 'common/components/Button';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { FormSection } from 'common/components/FormSection';
import { Img } from 'common/components/Img';
import { Banner } from 'common/components/Banner';
import { Loader } from 'common/components/Loader';
import { ExtraFieldsFormFields } from 'common/components/ExtraFieldsFormFields';
import { useTrainingExtraFields } from 'training/hooks/useTrainingExtraFields';
import {
    extraFieldsDataToJson,
    getExtraFieldsName,
} from 'common/utils/extraFields';
import TrainingOverviewImageInfo from 'assets/images/training-overview-image-info.webp';
import { Link } from 'common/components/Link';
import { getImageId } from 'common/utils/image';

interface ITrainingFormValues
    extends Omit<ICreateTrainingInput, 'imageId' | 'overviewImageId'> {
    image?: IFileFragment | null;
    overviewImage?: IFileFragment | null;
}

export interface IProps {
    initialValues?: ITrainingFormValues;
    disabled?: boolean;
    edit?: boolean;
    onSubmit(values: ICreateTrainingInput): void;
}

export const TrainingForm = ({
    initialValues,
    disabled,
    edit,
    onSubmit,
}: IProps) => {
    const [translate] = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );
    const { canUse: canUseAfas } = useFeature(IFeature.Afas);
    const {
        extraFields,
        extraFieldValues,
        loading: loadingExtraFields,
        error: extraFieldsError,
    } = useTrainingExtraFields(initialValues?.extraFields);

    const [settingsExpanded, setSettingsExpanded] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        subtitle: Yup.string().max(
            128,
            translate('validate.maxCharacters', { count: 128 })
        ),
    });

    const initValues: ITrainingFormValues = {
        title: '',
        titleColor: '',
        introduction: edit ? '' : undefined,
        subtitle: '',
        estimatedTimeSpent: '',
        passThreshold: 0,
        startDate: null,
        endDate: null,
        isActive: true,
        showParticipants: true,
        enableChat: true,
        showSharedFiles: true,
        ref: '',
        syncWithAfas: false,
        ...initialValues,
        extraFields: extraFieldValues,
    };

    const formTitle = edit
        ? translate('trainingForm.editTitle')
        : translate('trainingForm.newTitle');
    const formText = edit
        ? translate('trainingForm.editText')
        : translate('trainingForm.newText');
    const submitText = edit
        ? translate('trainingForm.editButton')
        : translate('trainingForm.newButton');

    if (loadingExtraFields) return <Loader />;

    return (
        <>
            {extraFieldsError && <ApolloError error={extraFieldsError} />}
            <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={(values: ITrainingFormValues) => {
                    // Unpack image and overview Image from values and also remove empty values
                    const {
                        image,
                        overviewImage,
                        titleColor,
                        extraFields,
                        ...otherValues
                    } = values;

                    const currentImage = initialValues?.image;
                    const currentOverviewImage = initialValues?.overviewImage;

                    const newExtraFields = Object.keys(extraFields).reduce(
                        (acc, curKey) => ({
                            ...acc,
                            [getExtraFieldsName(curKey as string, true)]:
                                values.extraFields[curKey],
                        }),
                        {}
                    );

                    const formValues: ICreateTrainingInput = {
                        ...otherValues,
                        imageId: getImageId(currentImage, image),
                        overviewImageId: getImageId(
                            currentOverviewImage,
                            overviewImage
                        ),

                        // Set titleColor to undefined when not set.
                        titleColor: !!titleColor ? `#${titleColor}` : undefined,
                        extraFields: extraFieldsDataToJson(newExtraFields),
                    };

                    return onSubmit(formValues);
                }}
            >
                {({
                    submitForm,
                    isSubmitting,
                    dirty,
                    values,
                    setFieldValue,
                }) => (
                    <Box p={{ xs: 2, sm: 4 }}>
                        <ExitPageAlert
                            alert={UnsavedChangesAlert}
                            when={dirty && !isSubmitting}
                            onConfirm={submitForm}
                        />
                        <Form>
                            {formTitle && formText && (
                                <Box mb={4}>
                                    {formTitle && (
                                        <Typography
                                            sx={{ fontWeight: 'bold' }}
                                            variant="h3"
                                        >
                                            {formTitle}
                                        </Typography>
                                    )}

                                    {formText && (
                                        <Box mt={1}>
                                            <Typography>{formText}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                            <Field
                                withDefaults
                                acceptedFileTypes={HEADER_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 1440,
                                    height: 300,
                                })}
                                label={translate('headerImage')}
                                maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                                name="image"
                                startSlide={!edit ? 0 : undefined}
                            />

                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        component={ColorPicker}
                                        name="titleColor"
                                        popoverPosition="bottom-end"
                                        tooltip={translate<string>(
                                            'titleColor'
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            {edit && (
                                <Field
                                    multiline
                                    component={TextField}
                                    label={translate('introduction')}
                                    name="introduction"
                                />
                            )}

                            <Box mt={4}>
                                <ContentExpander
                                    expand={settingsExpanded}
                                    title={translate('moreSettings')}
                                    onClick={() =>
                                        setSettingsExpanded(!settingsExpanded)
                                    }
                                >
                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="trainingForm.generalDescription"
                                            />
                                        }
                                        title={translate('general')}
                                    >
                                        <Field
                                            component={TextField}
                                            label={translate('subtitle')}
                                            name="subtitle"
                                        />

                                        <Box mt={3}>
                                            <Grid
                                                container
                                                alignItems="stretch"
                                                direction={
                                                    smallScreen
                                                        ? 'column-reverse'
                                                        : 'row'
                                                }
                                                spacing={2}
                                            >
                                                <Grid item sm xs={12}>
                                                    <Field
                                                        acceptedFileTypes={
                                                            CARD_IMAGE_TYPE
                                                        }
                                                        component={ImageField}
                                                        helperText={translate(
                                                            'imageSizeDescription',
                                                            {
                                                                width: 400,
                                                                height: 150,
                                                            }
                                                        )}
                                                        label={translate(
                                                            'imageInOverview'
                                                        )}
                                                        maxUploadSize={
                                                            CARD_IMAGE_MAX_SIZE
                                                        }
                                                        name="overviewImage"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={12}>
                                                    <Banner>
                                                        <Box
                                                            pl={3}
                                                            pr={1}
                                                            py={3}
                                                        >
                                                            {translate(
                                                                'imageInOverviewDescription'
                                                            )}
                                                        </Box>
                                                        <Box mt="auto">
                                                            <Img
                                                                src={
                                                                    TrainingOverviewImageInfo
                                                                }
                                                            />
                                                        </Box>
                                                    </Banner>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box mt={3}>
                                            <Field
                                                component={TextField}
                                                label={translate(
                                                    'estimatedTimeSpent'
                                                )}
                                                name="estimatedTimeSpent"
                                                placeholder={translate(
                                                    'estimatedTimeSpentPlaceholder'
                                                )}
                                            />
                                            <Field
                                                component={SliderField}
                                                label={translate(
                                                    'passThreshold'
                                                )}
                                                marks={[
                                                    {
                                                        value: 0,
                                                        label: '0%',
                                                    },
                                                    {
                                                        value: 1,
                                                        label: '100%',
                                                    },
                                                ]}
                                                max={1}
                                                min={0}
                                                name="passThreshold"
                                                step={0.01}
                                                valueLabelFormat={(
                                                    value: string
                                                ) =>
                                                    `${Math.floor(
                                                        +value * 100
                                                    )}%`
                                                }
                                            />
                                        </Box>
                                    </FormSection>

                                    {!!Object.keys(extraFields).length && (
                                        <FormSection
                                            description={
                                                <Trans
                                                    components={{
                                                        a: (
                                                            <Link
                                                                rel="noreferrer"
                                                                underline="always"
                                                            />
                                                        ),
                                                    }}
                                                    i18nKey="trainingForm.categoriesDescription"
                                                />
                                            }
                                            title={translate('categories')}
                                        >
                                            <ExtraFieldsFormFields
                                                extraFields={extraFields}
                                                setFieldValue={setFieldValue}
                                            />
                                        </FormSection>
                                    )}

                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="trainingForm.socialDescription"
                                            />
                                        }
                                        title={translate('social')}
                                    >
                                        <Field
                                            checked={values.showParticipants}
                                            component={Switch}
                                            label={translate(
                                                'participantsVisible'
                                            )}
                                            name="showParticipants"
                                        />
                                        <Field
                                            checked={values.enableChat}
                                            component={Switch}
                                            label={translate('groupChat')}
                                            name="enableChat"
                                        />
                                        <Field
                                            checked={values.showSharedFiles}
                                            component={Switch}
                                            label={translate('sharedFiles')}
                                            name="showSharedFiles"
                                        />
                                    </FormSection>

                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="trainingForm.accessDescription"
                                            />
                                        }
                                        title={translate('access')}
                                    >
                                        <Field
                                            checked={values.isActive}
                                            component={Switch}
                                            label={translate('active')}
                                            name="isActive"
                                        />

                                        <Grid container spacing={2}>
                                            <Grid item sm xs={12}>
                                                <FastField
                                                    component={DatePicker}
                                                    label={translate(
                                                        'startDate'
                                                    )}
                                                    maxDate={
                                                        values.endDate ||
                                                        undefined
                                                    }
                                                    name="startDate"
                                                />
                                            </Grid>

                                            <Grid item sm xs={12}>
                                                <Field
                                                    component={DatePicker}
                                                    label={translate('endDate')}
                                                    minDate={
                                                        values.startDate ||
                                                        undefined
                                                    }
                                                    name="endDate"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormSection>

                                    <FormSection
                                        description={
                                            <Trans
                                                components={{
                                                    a: (
                                                        <Link
                                                            rel="noreferrer"
                                                            underline="always"
                                                        />
                                                    ),
                                                }}
                                                i18nKey="trainingForm.connectionsDescription"
                                            />
                                        }
                                        title={translate('connections')}
                                    >
                                        <Field
                                            component={TextField}
                                            label={translate('reference')}
                                            name="ref"
                                        />
                                        {canUseAfas && (
                                            <Field
                                                checked={values.syncWithAfas}
                                                component={Switch}
                                                label={translate(
                                                    'syncWithAfas'
                                                )}
                                                name="syncWithAfas"
                                            />
                                        )}
                                    </FormSection>
                                </ContentExpander>
                            </Box>

                            <Box mt={4}>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                        </Form>
                    </Box>
                )}
            </Formik>
        </>
    );
};
