import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import { GroupForm } from 'user/components/GroupForm';
import {
    IContentTypeValue,
    useDeleteGroupMutation,
    useGroupQuery,
    useUpdateGroupMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getExtraCategoryInitialFormValues } from 'organisation/utils/extraCategory';
import { Loader } from 'common/components/Loader';
import { useApolloError } from 'common/hooks/useApolloError';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useExtraCategoryValues } from 'common/hooks/useExtraCategoryValues';
import { closeGlobalDrawerRoute } from 'common/utils/globalDrawer';
import { TGroupFormValues } from 'user/types';

export interface IProps extends IPageDrawerProps {
    id?: string;
    onClose?: () => void;
    onDelete?(): void;
    onUpdate?(
        initialValues: TGroupFormValues,
        newValues: TGroupFormValues
    ): void;
    onUserUpdate?(): void;
}

export const GroupUpdateDrawer = ({
    id: groupId,
    open,
    onClose,
    onDelete,
    onUpdate,
    onUserUpdate,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { id: groupIdParam } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const id = groupId || groupIdParam;

    const { data: groupData, loading: groupLoading } = useGroupQuery({
        variables: { id },
        skip: !id,
        onError: showApolloError,
    });
    const [updateGroup, { loading: updateGroupLoading }] =
        useUpdateGroupMutation();
    const [deleteGroup, { loading: deleteGroupLoading }] =
        useDeleteGroupMutation();

    const {
        extraCategories,
        loading: extraCategoriesLoading,
        handleUpdateExtraCategoryValues,
    } = useExtraCategoryValues({
        contentType: IContentTypeValue.Group,
        modelId: id || '',
        skipQuery: !id,
        typeNames: ['Group'],
    });

    const { group } = groupData || {};

    const {
        name,
        enableChat,
        maxUsers,
        ref,
        trainings,
        extraCategoryValues,
        isEditable,
    } = group || {};

    const initialExtraCategoryValues = getExtraCategoryInitialFormValues(
        extraCategories,
        extraCategoryValues
    );

    const initialValues: TGroupFormValues = {
        name: name || '',
        enableChat: !!enableChat,
        ref: ref || '',
        maxUsers: maxUsers || 0,
        trainingIds: trainings,
        extraCategories: {
            ...initialExtraCategoryValues,
        },
    };

    const handleSubmit = async (values: TGroupFormValues) => {
        const {
            maxUsers,
            extraCategories: extraCategoryUpdateValues,
            trainingIds: trainings,
            ...otherValues
        } = values;

        try {
            await updateGroup({
                variables: {
                    id,
                    group: {
                        trainingIds: trainings?.map((training) => training.id),
                        maxUsers: maxUsers ? +maxUsers : 0,
                        ...otherValues,
                    },
                },
                update: (cache) => {
                    if (values.name !== group?.name) {
                        // Trigger groups refetch because name changes can cause ordering issues
                        cache.evict({ fieldName: 'paginatedGroups' });
                    }
                },
            });

            await handleUpdateExtraCategoryValues(
                extraCategoryValues || [],
                extraCategoryUpdateValues
            );
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('groupsManagementPage.groupsActionSuccess.update'),
            {
                variant: 'success',
            }
        );

        handleClose();
        onUpdate?.(initialValues, values);

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteGroup({
                variables: { id },
                update: (cache) => {
                    // Remove deleted group from cache to make sure they are gone throughout the system
                    cache.evict({ id: `Group:${id}` });

                    cache.evict({ fieldName: 'paginatedGroups' });
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('groupsManagementPage.groupsActionSuccess.delete'),
            {
                variant: 'success',
            }
        );

        onDelete?.();

        handleClose();
    };

    const handleClose = () => {
        setShowDeleteDialog(false);

        if (!!groupIdParam) {
            closeGlobalDrawerRoute(navigate, location);

            return;
        }

        onClose?.();
    };

    const loading = groupLoading || extraCategoriesLoading;

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <PageTitle>
                {`${translate('groupsManagementPage.pageTitle')} - ${translate(
                    'groupForm.updateGroup'
                )}`}
            </PageTitle>

            <Box p={{ xs: 2, sm: 4 }} pt={{ xs: 0, sm: 0 }}>
                {loading && <Loader />}

                {!loading && group && (
                    <GroupForm
                        isEdit
                        disabled={updateGroupLoading || extraCategoriesLoading}
                        group={group}
                        initialValues={initialValues}
                        isEditable={isEditable}
                        onDelete={() => setShowDeleteDialog(true)}
                        onSubmit={handleSubmit}
                        onUserUpdate={onUserUpdate}
                    />
                )}
            </Box>

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={deleteGroupLoading}
                open={showDeleteDialog}
                title={translate(
                    'groupsManagementPage.groupDeleteDialog.title'
                )}
                onCancel={() => {
                    setShowDeleteDialog(false);
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
            >
                {translate('groupsManagementPage.groupDeleteDialog.text')}
            </ConfirmDialog>
        </PageDrawer>
    );
};
