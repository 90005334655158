import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import EXTERNAL_CONTENT_MODULE_DRAWER_HEADER from 'assets/images/external-content-module-drawer-header.webp';
import { IExternalContentListItemFragment } from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { Typography } from 'common/components/Typography';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import { Loader } from 'common/components/Loader';
import { ExternalContentFilterAddList } from 'external/components/ExternalContentFilterAddList';

interface IProps {
    edit?: boolean;
    open?: boolean;
    inDrawer?: boolean;
    onClose?(): void;
    onSelect?(externalContent: IExternalContentListItemFragment): void;
}

export const ExternalContentModuleSelectDrawer = ({
    edit,
    inDrawer = true,
    open,
    onClose,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();

    const titleSelect = translate(
        `moduleForm.externalContent.${
            edit ? 'editTitleSelect' : 'newTitleSelect'
        }`
    );
    const textSelect = translate(
        `moduleForm.externalContent.${
            edit ? 'editTextSelect' : 'newTextSelect'
        }`
    );

    const drawerContent = open ? (
        <>
            <DrawerHeaderImage src={EXTERNAL_CONTENT_MODULE_DRAWER_HEADER} />

            <Box p={{ xs: 2, sm: 4 }}>
                <Box mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleSelect}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textSelect}</Typography>
                    </Box>
                </Box>

                <ExternalContentFilterAddList
                    listItemProps={{ px: 0, showActions: true }}
                    selectMultiple={false}
                    onSelect={onSelect}
                />
            </Box>
        </>
    ) : (
        <Loader />
    );

    if (!inDrawer) return drawerContent;

    return (
        <PageDrawer open={open} onClose={onClose}>
            {drawerContent}
        </PageDrawer>
    );
};
