import { useTheme } from '@mui/material';
import { motion, Variants } from 'framer-motion';
import { styled } from 'styled-components';

import { TColorKeys } from 'theme/palette';
import { CardMediaIcon } from 'common/components/Card/CardMediaIcon';

interface IProps {
    children?: React.ReactNode;
    className?: string;
    color?: TColorKeys;
}

const BaseCardIconMedia = ({ children, className, color }: IProps) => {
    const theme = useTheme();
    const themeColor = color ? theme.palette[color] : null;

    // Enable color animation when themeColor is found
    const motionVariants: Variants = themeColor
        ? {
              initCardButton: {
                  backgroundColor: themeColor.main,
              },
              hoverCardButton: {
                  backgroundColor: themeColor.accent,
                  transition: { duration: 0.2 },
              },
          }
        : {};

    return (
        <motion.div
            className={className}
            transition={{ duration: 0.2 }}
            variants={motionVariants}
        >
            <CardMediaIcon>{children}</CardMediaIcon>
        </motion.div>
    );
};

export const CardIconMedia = styled(BaseCardIconMedia)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 125px;
    min-width: 225px;
    ${({ color, theme }) =>
        color && `background-color: ${theme.palette[color].main};`}
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.common.white};
    transition: background-color 300ms;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        min-width: auto;
    }
`;
