import { useEffect, useMemo } from 'react';

import { TPaginationSettings, usePagination } from 'common/hooks/usePagination';
import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';
import { useSelection } from 'common/components/Table';
import {
    ISearchProps,
    ITableSelectionProps,
    TFilterBarItem,
} from 'common/types';
import { useUserQueryVariables } from 'user/hooks/useUserQueryVariables';
import { useTrainerSearch } from 'user/hooks/useTrainerSearch';
import { useTrainerList } from 'user/hooks/useTrainerList';

export interface IUserTableData {
    loading?: boolean;
    tableDataProps: {
        paginationProps?: {
            paginationSettings: TPaginationSettings;
            setPage: (page: number) => void;
        };
        searchProps: ISearchProps;
        selectionProps: ITableSelectionProps<IUserListItemFragment>;
    };
    trainers?: IUserListItemFragment[];
    alreadyAddedTrainers: string[];
}

export const useTrainerTableData = (
    disabledTrainerIds: string[] = [],
    skip?: boolean,
    selectorQueryVariables?: IUserSelectorInput
): IUserTableData => {
    const {
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
        ...trainerSearchProps
    } = useTrainerSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { userQueryVariables } = useUserQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        trainers,
        alreadyAddedTrainers,
        loading: trainersLoading,
        trainersCount,
    } = useTrainerList(userQueryVariables, skip, selectorQueryVariables);

    const selectFilter = (user: IUserListItemFragment) =>
        !disabledTrainerIds?.some(
            (disabledTrainerId) => disabledTrainerId === user.id
        );

    const { ...selectionProps } = useSelection(trainers, selectFilter);

    useEffect(() => {
        if (trainersLoading || !initializePagination) return;

        initializePagination(trainersCount || 0);
    }, [trainersCount, trainersLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect(selected);
    };

    return {
        loading: trainersLoading,
        tableDataProps: {
            paginationProps: { paginationSettings, setPage },
            searchProps: {
                ...trainerSearchProps,
                handleSearch,
                handleFilterSelect,
                searchQueryParam,
                selectedFilters,
            },
            selectionProps,
        },
        trainers,
        alreadyAddedTrainers,
    };
};
