import { useSlate } from 'slate-react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';

import { EDITOR_FILE_MAX_SIZE } from 'common/constants/files';

import { addOrReplaceFile } from './utils';
import { IFilePluginOptions } from './types';
import { FileUpload } from './FileUpload';

type IProps = IFilePluginOptions<'type'> & IFilePluginOptions<'rootProps'>;

export const BlockMenuFile = ({ file }: IProps) => {
    const editor = useSlate();
    const { t: translate } = useTranslation();

    const handleSuccess = (url: string) => {
        addOrReplaceFile(editor, url, { file });
    };

    return (
        <FileUpload
            component={MenuItem}
            id="menu-file-upload"
            maxSize={EDITOR_FILE_MAX_SIZE}
            onSuccess={handleSuccess}
        >
            {translate('edit')}
        </FileUpload>
    );
};
