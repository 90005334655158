import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCreateCourseModuleMutation } from 'graphql/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import {
    CourseModuleForm,
    ICourseModuleFormProps,
} from 'training/components/forms/CourseModuleForm';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { createModuleInCache } from 'training/utils/module';
import { getUrl } from 'route/utils/getUrl';

import { CourseModuleSelectDrawer } from './CourseModuleSelectDrawer';

export const CourseModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const [searchParams, setSearchParams] = useSearchParams();

    const routeQuery = useRouteQuery();
    const courseIdParam = routeQuery.get('course') || undefined;
    const [selectedCourseId, setSelectedCourseId] = useState(courseIdParam);

    const courseSelectDrawerOpen =
        useHashMatch('TRAINING_MODULE_SELECT') && !!selectedCourseId;

    const [createModule] = useCreateCourseModuleMutation();

    useEffect(() => {
        if (!courseIdParam) return;

        // Remove course search params if it is given in the url
        const { course, ...newSearchParams } = Object.fromEntries(searchParams);
        setSearchParams(new URLSearchParams(newSearchParams));
    }, [courseIdParam, searchParams, setSearchParams]);

    const handleSubmit: ICourseModuleFormProps['onSubmit'] = async (values) => {
        const { image, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            imageId: image?.id,
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
        // in the training which will make a new moduleGroup
        const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

        try {
            await createModule({
                variables: { module: moduleValues, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createCourseModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Create course module in drawer',
        });

        displaySnackbar(translate('moduleActionSuccess.course.create'), {
            variant: 'success',
        });

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    return (
        <>
            <PageTitle mixpanelTitle="'Drawer create course module">
                {translate('newCourseModule')}
            </PageTitle>

            <CourseModuleSelectDrawer
                open={courseSelectDrawerOpen}
                onClose={() => {
                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
                onSelect={(course) => {
                    // Redirect to the pathname to go to the page without hashtag.
                    setSelectedCourseId(course.id);
                    navigate(window.location.pathname);
                }}
            />

            {!selectedCourseId ? (
                <CourseModuleSelectDrawer
                    open
                    inDrawer={false}
                    onSelect={(course) => {
                        setSelectedCourseId(course.id);
                        navigate(window.location.pathname);
                    }}
                />
            ) : (
                <CourseModuleForm
                    courseId={selectedCourseId}
                    moduleGroupId={moduleGroupId}
                    onReplaceCourse={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    );
};
