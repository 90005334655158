export function isWhiteColor(color: string) {
    return [
        '#fff',
        '#ffffff',
        'rgb(255, 255, 255)',
        'rgba(255, 255, 255, 1)',
    ].includes(color);
}

export function formatColor(color: string) {
    return color ? (color.includes('#') ? color : `#${color}`) : undefined;
}
