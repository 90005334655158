import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BoxLoader } from 'common/components/Loader';
import { IScormListItemFragment } from 'graphql/types';
import { Pagination } from 'common/components/Pagination';
import { FilterBar } from 'common/components/FilterBar';
import { Divider } from 'common/components/Divider';
import { ScormAddList } from 'training/components/ScormAddList';
import { useScormList } from 'training/hooks/useScormList';

interface IProps {
    selectMultiple?: boolean;
    onSelect?(video: IScormListItemFragment): void;
    selectedIds?: string[];
    listItemProps?: React.ComponentProps<typeof ScormAddList>['listItemProps'];
}

export const ScormFilterAddList = ({
    selectMultiple = true,
    onSelect,
    listItemProps,
}: IProps) => {
    const [translate] = useTranslation();
    const [selectedScorms, setSelectedScorms] = useState<
        IScormListItemFragment[]
    >([]);

    const { scorms, loading, setPage, paginationSettings, onSearch } =
        useScormList();

    const handleSelectScorm = (scorm: IScormListItemFragment) => {
        onSelect?.(scorm);

        if (!selectMultiple) return;

        const newSelectedScorms = [...selectedScorms, scorm];
        setSelectedScorms(newSelectedScorms);
    };

    const handleRemoveScorm = (scorm: IScormListItemFragment) => {
        if (!selectMultiple) return;

        const newSelectedScorms = selectedScorms.filter(
            (selectedScorm) => selectedScorm.id !== scorm.id
        );

        setSelectedScorms(newSelectedScorms);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const hasResults = !!paginationSettings.count;

    return (
        <>
            <FilterBar
                disabled={loading}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />

            {loading ? (
                <BoxLoader />
            ) : (
                <>
                    <ScormAddList
                        addedScorms={selectedScorms}
                        listItemProps={{
                            ...listItemProps,
                            addButton: selectMultiple,
                        }}
                        scorms={scorms}
                        selectedScorms={selectedScorms}
                        onAddScorm={handleSelectScorm}
                        onRemoveScorm={(
                            selectedScorm: IScormListItemFragment
                        ) => handleRemoveScorm(selectedScorm)}
                    />

                    {paginationSettings.count > -1 && (
                        <Box mt={hasResults ? 2 : 0}>
                            {hasResults && <Divider />}

                            <Box mt={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={translate('scormPackage', {
                                        count:
                                            paginationSettings.count >= 0
                                                ? paginationSettings.count
                                                : 0,
                                    })}
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
