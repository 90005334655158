import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { usePagination } from 'common/hooks/usePagination';
import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { Loader } from 'common/components/Loader';
import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { useTrainerSearch } from 'user/hooks/useTrainerSearch';
import { FormTrainerAddList } from 'user/components/FormField/FormTrainerSelector/FormTrainerSelectDrawer/FormTrainerAddList';
import { useTrainerList } from 'user/hooks/useTrainerList';
import { getExtraFieldsJSON } from 'common/utils/extraFields';
import { TFilterBarItem } from 'common/types';

interface IProps extends IPageDrawerProps {
    addedTrainers: IUserListItemFragment[];
    disabledTrainerIds?: string[];
    selectedTrainers: IUserListItemFragment[];
    selectorQueryVariables?: IUserSelectorInput;
    onAddTrainers(): void;
    onDrawerOpen(open: boolean): void;
    onRemoveTrainer(trainer: IUserListItemFragment): void;
    onSelectTrainer(trainer: IUserListItemFragment): void;
}

export const FormTrainerSelectDrawer = ({
    addedTrainers,
    disabledTrainerIds,
    selectedTrainers,
    selectorQueryVariables,
    open,
    onAddTrainers,
    onDrawerOpen,
    onRemoveTrainer,
    onSelectTrainer,
}: IProps) => {
    const { t: translate } = useTranslation();

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useTrainerSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const trainerQueryVariables = useMemo(
        () => ({
            ...paginationVariables,
            q: searchQueryParam,
            extraFields: getExtraFieldsJSON(selectedFilters),
        }),
        [paginationVariables, searchQueryParam]
    );

    const { trainers, loading, trainersCount } = useTrainerList(
        trainerQueryVariables,
        false,
        selectorQueryVariables
    );

    const resetSettings = () => {
        handleSearch('');
        onFilterSelect([]);
        setPage(1);
    };

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(trainersCount || 0);
    }, [trainersCount, loading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('addTrainersTitle')}
            onClose={() => {
                onDrawerOpen(false);
                resetSettings();
            }}
        >
            {filtersLoading ? (
                <Loader />
            ) : (
                <>
                    <Box
                        sx={{
                            pb: 22.5,
                            pt: { xs: 2, sm: 4 },
                            px: { xs: 2, sm: 4 },
                        }}
                    >
                        <FilterBar
                            filters={filters}
                            initialSearchValue={searchQueryParam}
                            initialSelected={selectedFilters}
                            placeholder={translate('filterBarPlaceholder')}
                            onSearch={handleSearch}
                            onSearchClear={() => handleSearch('')}
                            onSelect={handleFilterSelect}
                        />

                        {loading && <Loader />}

                        {open && !loading && (
                            <FormTrainerAddList
                                addedTrainers={addedTrainers}
                                disabledTrainerIds={disabledTrainerIds}
                                selectedTrainers={selectedTrainers}
                                trainers={trainers}
                                onAddTrainer={onSelectTrainer}
                                onRemoveTrainer={(
                                    selectedTrainer: IUserListItemFragment
                                ) => onRemoveTrainer(selectedTrainer)}
                            />
                        )}
                    </Box>

                    <PageDrawerFooter>
                        <Box display="flex" flexDirection="column">
                            {paginationSettings.count > -1 && (
                                <Box mb={2}>
                                    <Pagination
                                        page={paginationSettings.page}
                                        pageAmount={
                                            paginationSettings.pageAmount
                                        }
                                        totalsAmount={paginationSettings.count}
                                        totalsText={
                                            paginationSettings.count === 1
                                                ? translate('trainer')
                                                : translate('trainers')
                                        }
                                        onChange={(page: number) => {
                                            setPage(page);
                                        }}
                                    />
                                </Box>
                            )}

                            <Button
                                color="primary"
                                disabled={!selectedTrainers.length}
                                variant="contained"
                                onClick={() => {
                                    onAddTrainers();
                                    resetSettings();
                                }}
                            >
                                {!selectedTrainers.length
                                    ? translate('addTrainersTitle')
                                    : translate('addTrainers', {
                                          count: selectedTrainers.length,
                                      })}
                            </Button>
                        </Box>
                    </PageDrawerFooter>
                </>
            )}
        </PageDrawer>
    );
};
