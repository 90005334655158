import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchResult, useApolloClient, Reference } from '@apollo/client';
import { DialogContentText } from '@mui/material';

import {
    IDeleteAlertDialogOptions,
    INITIAL_DELETE_ALERT_DIALOG_VALUES,
} from 'common/constants/deleteAlertDialog';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import {
    ICreatePortfolioItemInput,
    IPortfolioItemFragment,
    IPortfolioItemListItemOldFragment,
    PortfolioItemFragmentDoc,
    useDeletePortfolioItemMutation,
    useScoringSystemsQuery,
    useUpdatePortfolioItemMutation,
} from 'graphql/types';
import { TPortfolioItemFormValues } from 'user/types';
import { ApolloError } from 'common/components/ApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { Loader } from 'common/components/Loader';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { Divider } from 'common/components/Divider';
import { CustomPortfolioItemForm } from 'user/components/forms/CustomPortfolioItemForm';
import { PortfolioItemFileTable } from 'user/components/PortfolioItemFileTable';
import { PortfolioItemDetailsView } from 'user/components/PortfolioItemDrawer/PortfolioItemDetailsView';

interface IProps {
    filesAreUpdating: boolean;
    loading?: boolean;
    open?: boolean;
    pageTitle?: string;
    portfolioItem?: IPortfolioItemFragment;
    userId?: string;
    withEditActions?: boolean;
    setFilesAreUpdating(filesAreUpdating: boolean): void;
    onClose?(): void;
    onSubmit?(): void;
    onCreateItem(values: ICreatePortfolioItemInput): void;
}

export const CustomPortfolioItemContent = ({
    filesAreUpdating,
    loading,
    pageTitle,
    portfolioItem,
    userId,
    withEditActions,
    setFilesAreUpdating,
    onClose,
    onSubmit,
    onCreateItem,
}: IProps) => {
    const [translate] = useTranslation();
    const [deleteAlertDialog, setDeleteAlertDialog] =
        useState<IDeleteAlertDialogOptions>(INITIAL_DELETE_ALERT_DIALOG_VALUES);
    const [displaySnackbar] = useSnackbar();
    const isPortfolioItemCreatePage = !!useRouteMatch('PORTFOLIO_ITEM_CREATE');
    const isCustomPortfolioEditPage = !!useRouteMatch('PORTFOLIO_ITEM_EDIT');
    const client = useApolloClient();
    const {
        data: scoringSystemsData,
        loading: scoringSystemsLoading,
        error: scoringSystemsError,
    } = useScoringSystemsQuery({
        skip: !isPortfolioItemCreatePage && !isCustomPortfolioEditPage,
    });
    const [updateItem, { error: updateError }] = useUpdatePortfolioItemMutation(
        {
            onError: () => {
                setFilesAreUpdating(false);
            },
        }
    );

    const {
        __typename,
        id,
        title,
        teacher,
        startDate,
        endDate,
        description,
        scoring,
    } = portfolioItem || {};

    const [deleteItem, { loading: deleteLoading, error: deleteError }] =
        useDeletePortfolioItemMutation({
            update: (cache) => {
                if (!portfolioItem) return;

                // Fetching the latest version of the portfolio item, because the group property might be changed when moved to another group
                const portfolioItemFragment: IPortfolioItemFragment | null =
                    cache.readFragment({
                        id: `${__typename}:${id}`,
                        fragment: PortfolioItemFragmentDoc,
                        fragmentName: 'PortfolioItem',
                    });

                if (!portfolioItemFragment || !portfolioItemFragment.group) {
                    return;
                }

                client.refetchQueries({ include: ['PortfolioScores'] });

                cache.modify({
                    id: `PortfolioItemGroup:${portfolioItemFragment.group.id}`,
                    fields: {
                        portfolioItems(
                            existingPortfolioItemRefs: readonly (
                                | Reference
                                | IPortfolioItemListItemOldFragment
                            )[] = [],
                            { readField }
                        ) {
                            return [
                                ...existingPortfolioItemRefs.filter(
                                    (itemRef) =>
                                        portfolioItemFragment.id !==
                                        readField('id', itemRef)
                                ),
                            ];
                        },
                    },
                });

                displaySnackbar(
                    translate('portfolioItemActionSuccess.delete'),
                    {
                        variant: 'success',
                    }
                );

                handleClose();
            },
        });

    const { scoringSystems } = scoringSystemsData || {};

    const handleClose = () => {
        onClose && onClose();
    };

    const handleCustomPortfolioItemMutationUpdate = (
        mutationResult: FetchResult
    ) => {
        const { data: updatedCustomPortfolioItemData } = mutationResult;

        const updatedCustomPortfolioItem =
            updatedCustomPortfolioItemData?.updatePortfolioItem.portfolioItem;

        if (!updatedCustomPortfolioItem) return;

        client.refetchQueries({ include: ['PortfolioScores'] });

        displaySnackbar(translate('portfolioItemActionSuccess.update'), {
            variant: 'success',
        });
    };

    const scoresInitialValue =
        scoringSystems?.map((scoringSystem) => {
            const score = scoring?.find(
                (score) => score.scoringSystem.id === scoringSystem.id
            )?.score;

            return {
                score: !score ? 0 : score,
                scoringSystemId: scoringSystem.id,
            };
        }) || [];

    const initialValues: TPortfolioItemFormValues = {
        title: title || '',
        teacher: teacher || '',
        startDate: startDate || null,
        endDate: endDate || null,
        description: description || '',
        scores: scoresInitialValue,
    };

    const handleSubmit = async (values: TPortfolioItemFormValues) => {
        const { scores } = values;
        const newScores = scores?.map(({ score, scoringSystemId }) => ({
            score: parseFloat(`${score}`.replace(',', '.')) || 0,
            scoringSystemId,
        }));

        const newValues = { ...values, scores: newScores };

        if (portfolioItem?.id) {
            await updateItem({
                variables: {
                    id,
                    portfolioItem: newValues,
                },
                update: (_cache, mutationResult) =>
                    handleCustomPortfolioItemMutationUpdate(mutationResult),
            });
        } else {
            await onCreateItem(newValues as ICreatePortfolioItemInput);
        }

        onSubmit?.();

        return;
    };

    let onDeleteItem;

    if (withEditActions && id && !filesAreUpdating) {
        onDeleteItem = () => {
            deleteItem({ variables: { id } });

            setDeleteAlertDialog({ ...deleteAlertDialog, show: false });
        };
    }

    const isLoading = loading || scoringSystemsLoading;

    const hasItemDetails = !!(
        startDate ||
        endDate ||
        teacher ||
        description ||
        title
    );

    // Makes sure the title does not jump from portfolio item title to new portfolio item title
    const mountPageTitle = !!portfolioItem
        ? !isLoading
        : isPortfolioItemCreatePage;

    const isCustomPortfolioItemView =
        !withEditActions && portfolioItem && hasItemDetails;

    const pageTitlePrefix = portfolioItem
        ? portfolioItem.title
        : translate('newPortfolioItem');

    return (
        <>
            {updateError && <ApolloError error={updateError} />}
            {deleteError && <ApolloError error={deleteError} />}
            {scoringSystemsError && <ApolloError error={scoringSystemsError} />}

            {mountPageTitle && (
                <PageTitle
                    mixpanelTitle={`Portfolio custom portfolio item drawer - ${
                        portfolioItem
                            ? isCustomPortfolioEditPage
                                ? 'Edit'
                                : 'View'
                            : 'New'
                    } portfolio item`}
                >
                    {`${pageTitlePrefix}${pageTitle ? `- ${pageTitle}` : ''}`}
                </PageTitle>
            )}

            {isLoading && <Loader />}

            {!isLoading && (
                <>
                    {isCustomPortfolioItemView && (
                        <PortfolioItemDetailsView
                            showDescription
                            portfolioItem={portfolioItem}
                            userId={userId}
                        />
                    )}

                    {withEditActions && (
                        <CustomPortfolioItemForm
                            disabled={filesAreUpdating}
                            initialValues={initialValues}
                            isDeleting={deleteLoading}
                            scoringSystems={scoringSystems}
                            onDelete={() =>
                                setDeleteAlertDialog({
                                    show: true,
                                    title: translate(
                                        'deletePortfolioItemMessage.title'
                                    ),
                                    text: translate(
                                        'deletePortfolioItemMessage.text'
                                    ),
                                })
                            }
                            onSubmit={handleSubmit}
                        />
                    )}
                    <Divider />
                    <PortfolioItemFileTable
                        filesAreUpdating={filesAreUpdating}
                        portfolioItem={portfolioItem}
                        setFilesAreUpdating={setFilesAreUpdating}
                        userId={userId}
                        withEditActions={withEditActions}
                    />
                </>
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={deleteAlertDialog.callback || onDeleteItem}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setDeleteAlertDialog({
                                    ...deleteAlertDialog,
                                    show: false,
                                });
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={deleteAlertDialog.show}
                title={deleteAlertDialog.title}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {deleteAlertDialog.text}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
