import { forwardRef } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from 'styled-components';
import { Box } from '@mui/material';

import { Tooltip } from 'common/components/Tooltip';
import { Link } from 'common/components/Link';
import { Badge } from 'common/components/Badge';
import { IUnreadBadge } from 'graphql/types';

interface IProps extends IconButtonProps<typeof Link> {
    autoSize?: boolean;
    badge?: IUnreadBadge;
    tooltipText: string;
    /** Open indicates that the dropdown menu is open */
    open?: boolean;
}

const Item = forwardRef<HTMLButtonElement, IProps>(function NavigationItem(
    {
        autoSize,
        badge,
        children,
        tooltipText,
        href,
        open,
        to,
        className,
        target,
        onClick,
        ...other
    },
    ref
) {
    let icon = children;

    if (badge) {
        icon = (
            <Badge badgeContent={badge.count} color="error" max={99}>
                {icon}
            </Badge>
        );
    }

    let button = (
        <Box>
            <IconButton
                className={className}
                ref={ref}
                size="large"
                onClick={onClick}
            >
                {icon}
            </IconButton>
        </Box>
    );

    if (href || to) {
        button = (
            <Box>
                <IconButton
                    className={className}
                    component={Link}
                    href={href}
                    ref={ref}
                    target={target}
                    to={to}
                    underline="none"
                    onClick={onClick}
                    {...other}
                    size="large"
                >
                    {icon}
                </IconButton>
            </Box>
        );
    }

    return <Tooltip title={!open ? tooltipText : ''}>{button}</Tooltip>;
});

export const NavigationItem = styled(Item)`
    color: ${({ theme }) => theme.palette.body.main};
    width: ${({ autoSize }) => !autoSize && '50px'};
    height: ${({ autoSize }) => !autoSize && '50px'};
    padding: 0px;
`;
