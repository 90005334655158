import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { useTrainingList } from 'training/hooks/useTrainingList';
import { Loader } from 'common/components/Loader';
import { ITrainingListItemFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { TrainingAddList } from 'training/components/TrainingAddList';

interface IProps extends IPageDrawerProps {
    addedTrainings: ITrainingListItemFragment[];
    selectedTrainings: ITrainingListItemFragment[];
    onAddTrainings(): void;
    onDrawerOpen(open: boolean): void;
    onRemoveTraining(training: ITrainingListItemFragment): void;
    onSelectTraining(training: ITrainingListItemFragment): void;
}

export const TrainingSelectDrawer = ({
    addedTrainings,
    selectedTrainings,
    open,
    onAddTrainings,
    onDrawerOpen,
    onRemoveTraining,
    onSelectTraining,
}: IProps) => {
    const [translate] = useTranslation();

    const {
        trainings,
        loading,
        paginationSettings,
        filters,
        filterValues,
        setPage,
        onSearch,
        onFilterChange,
    } = useTrainingList(undefined, false);

    const resetSettings = () => {
        handleSearch('');
        setPage(1);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('addTrainingsTitle')}
            onClose={() => {
                onDrawerOpen(false);
            }}
        >
            <>
                <Box
                    sx={{
                        pb: 22.5,
                        pt: { xs: 2, sm: 4 },
                        px: { xs: 2, sm: 4 },
                    }}
                >
                    <FilterBar
                        useFilterDrawer
                        disabled={loading}
                        filterDrawerProps={{
                            title: translate('filterForm.trainings.title'),
                            description: translate(
                                'filterForm.trainings.description'
                            ),
                        }}
                        formFilters={filters}
                        formFilterValues={filterValues}
                        initialSearchValue={filterValues.q}
                        placeholder={translate('filterBarPlaceholder')}
                        onFiltersChange={(values) => {
                            setPage(1);

                            onFilterChange?.(values);
                        }}
                        onSearch={handleSearch}
                        onSearchClear={() => handleSearch('')}
                    />

                    {loading && <Loader />}

                    {open && !loading && (
                        <TrainingAddList
                            addedTrainings={addedTrainings}
                            selectedTrainings={selectedTrainings}
                            trainings={trainings}
                            onAddTraining={onSelectTraining}
                            onRemoveTraining={(
                                selectedTraining: ITrainingListItemFragment
                            ) => onRemoveTraining(selectedTraining)}
                        />
                    )}
                </Box>

                <PageDrawerFooter>
                    <Box display="flex" flexDirection="column">
                        {paginationSettings.count > -1 && (
                            <Box mb={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={
                                        paginationSettings.count === 1
                                            ? translate('training')
                                            : translate('trainings')
                                    }
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        )}

                        <Button
                            color="primary"
                            disabled={!selectedTrainings.length}
                            variant="contained"
                            onClick={() => {
                                onAddTrainings();
                                resetSettings();
                            }}
                        >
                            {!selectedTrainings.length
                                ? translate('addTrainingsTitle')
                                : translate('addTrainings', {
                                      count: selectedTrainings.length,
                                  })}
                        </Button>
                    </Box>
                </PageDrawerFooter>
            </>
        </PageDrawer>
    );
};
