import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PLAN_DEMO_1 from 'assets/images/plandemo-1.webp';
import PLAN_DEMO_2 from 'assets/images/plandemo-2.webp';

import { Typography } from '../Typography';
import { Button } from '../Button';
import { Img } from '../Img';
import { Link } from '../Link';

export const PlanDemo = () => {
    const { t: translate } = useTranslation();

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item display={{ xs: 'none', md: 'block' }} sm={2}>
                <Img src={PLAN_DEMO_1} />
            </Grid>

            <Grid
                item
                md={8}
                sx={{
                    px: { xs: 0, sm: 14 },
                    textAlign: 'center',
                }}
            >
                <Box mb={1}>
                    <Typography variant="h2">
                        {translate('planDemo.title')}
                    </Typography>
                </Box>

                <Typography lineHeight="1.4">
                    {translate('planDemo.text')}
                </Typography>

                <Box mt={3}>
                    <Button
                        color="primary"
                        component={Link}
                        href={translate('planDemo.buttonLink')}
                        sx={{ px: 4 }}
                        target="_blank"
                        variant="contained"
                    >
                        <Typography fontSize="1.6rem" fontWeight={700}>
                            {translate('planDemo.buttonText')}
                        </Typography>
                    </Button>
                </Box>
            </Grid>

            <Grid item display={{ xs: 'none', md: 'block' }} sm={2}>
                <Img src={PLAN_DEMO_2} />
            </Grid>
        </Grid>
    );
};
