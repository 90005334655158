import { useState, useEffect, useCallback } from 'react';
import { Box, SxProps } from '@mui/material';
import { mdiChevronDown } from '@mdi/js';

import { TFilterBarItem } from 'common/types';
import { FilterDropdown } from 'common/components/FilterDropdown';
import { FilterBarMenuItem } from 'common/components/FilterBar/FilterBarMenuItem';
import { filterToggleSelected } from 'common/utils/filter';

const filterGoupStyle: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    m: -0.25,
    height: '100%',
    width: '100%',

    '> *': {
        display: 'inline-flex',
        m: 0.25,
    },

    '& + &': {
        mt: 0.25,
    },
};

interface IProps {
    filters?: TFilterBarItem[];
    initialSelected?: TFilterBarItem[];
    handleFilterSelect(selected: TFilterBarItem[]): void;
}

export const FilterDropdownGroup = ({
    filters,
    initialSelected = [],
    handleFilterSelect,
}: IProps) => {
    const [selected, setSelected] = useState<TFilterBarItem[]>(
        initialSelected || []
    );

    useEffect(() => {
        // If initialSelected isn't changed, don't update the selected state
        if (JSON.stringify(initialSelected) === JSON.stringify(selected)) {
            return;
        }

        setSelected(initialSelected || []);
    }, [initialSelected]);

    const handleChange = useCallback(
        (item: TFilterBarItem, parent: TFilterBarItem) => {
            const items = filterToggleSelected(selected, item, parent);
            handleFilterSelect(items);
        },
        [selected, handleFilterSelect]
    );

    if (!filters?.length) return null;

    return (
        <Box sx={filterGoupStyle}>
            {filters.map((filter) => {
                let buttonText = filter.name;

                // If filter only allows single value we want to use the select item as button text
                if (filter.singleValue) {
                    // Check if there's a selected filter for this filter's parent
                    const selectedItem = selected.find(
                        (s) => s.parent === filter.value
                    );

                    // If we have a selected item we change the button text
                    if (selectedItem?.name) buttonText = selectedItem.name;
                }

                return (
                    <Box key={filter.value}>
                        <FilterDropdown
                            buttonText={buttonText}
                            icon={mdiChevronDown}
                        >
                            {filter.children?.map((child, index) => (
                                <FilterBarMenuItem
                                    item={child}
                                    key={index}
                                    selected={selected}
                                    onClick={(item) =>
                                        handleChange(item, filter)
                                    }
                                />
                            ))}
                        </FilterDropdown>
                    </Box>
                );
            })}
        </Box>
    );
};
