import { Table, TableContainer, TableRow, TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { AddButton } from 'common/components/Button/AddButton';
import { TableCell } from 'common/components/Table';
import { Divider } from 'common/components/Divider';
import { Chip, ChipGroup } from 'common/components/Chip';
import { useLtiPlatformsQuery } from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';
import { getLTIPlatformDateDisplay } from 'connections/utils/date';

import { LTIPlatformUpdateDrawer } from './LTIPlatformUpdateDrawer';
import { LTIPlatformCreateDrawer } from './LTIPlatformCreateDrawer';

export const LTIPlatformManager = () => {
    const [translate] = useTranslation();
    const { data, loading } = useLtiPlatformsQuery();
    const [lTIPlatformId, setLTIPlatformId] = useState<string>();
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const { ltiPlatforms } = data || {};

    const hasLTIPlatforms = !!ltiPlatforms?.length;

    return (
        <Box>
            {!loading && (
                <AddButton
                    onClick={() => {
                        setCreateDrawerOpen(true);
                    }}
                >
                    <Typography>
                        {translate('lTIPlatformForm.lTIPlatform')}
                    </Typography>
                </AddButton>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '200px' }}>
                    <Loader />
                </Box>
            )}

            {hasLTIPlatforms && (
                <>
                    <Box sx={{ mt: 2 }}>
                        <Divider />
                    </Box>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                {ltiPlatforms.map((lTIPlatform) => {
                                    const {
                                        title,
                                        numberOfUsers,
                                        isActive,
                                        deploymentIds,
                                        keySetUrl,
                                        authTokenUrl,
                                        authLoginUrl,
                                        maxUsers,
                                        startDate,
                                        endDate,
                                    } = lTIPlatform;

                                    const actionRequired =
                                        !deploymentIds ||
                                        !keySetUrl ||
                                        !authTokenUrl ||
                                        !authLoginUrl;

                                    const hasChips =
                                        !isActive || actionRequired;

                                    const participantInfo = `${
                                        numberOfUsers || 0
                                    }${
                                        maxUsers > 0 ? `/${maxUsers}` : ''
                                    } ${translate(
                                        'participants'
                                    ).toLowerCase()}`;

                                    const dateDisplay =
                                        getLTIPlatformDateDisplay(
                                            startDate,
                                            endDate
                                        );

                                    return (
                                        <TableRow
                                            key={`ltiPlatform-${lTIPlatform.id}`}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                setLTIPlatformId(lTIPlatform.id)
                                            }
                                        >
                                            <TableCell>
                                                {title}

                                                <Box color="grey.500" mt="2px">
                                                    {`${participantInfo}${
                                                        dateDisplay
                                                            ? `, ${dateDisplay}`
                                                            : ''
                                                    }`}
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                {hasChips && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'right',
                                                        }}
                                                    >
                                                        <Box mr={2}>
                                                            <ChipGroup>
                                                                {!isActive && (
                                                                    <Chip
                                                                        color="error"
                                                                        label={translate(
                                                                            'inactive'
                                                                        )}
                                                                    />
                                                                )}

                                                                {actionRequired && (
                                                                    <Chip
                                                                        color="error"
                                                                        label={translate(
                                                                            'actionRequired'
                                                                        )}
                                                                    />
                                                                )}
                                                            </ChipGroup>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <LTIPlatformUpdateDrawer
                        lTIPlatformId={lTIPlatformId}
                        open={!!lTIPlatformId}
                        onClose={() => {
                            if (lTIPlatformId) {
                                setLTIPlatformId(undefined);
                            }
                        }}
                    />
                </>
            )}

            <LTIPlatformCreateDrawer
                open={createDrawerOpen}
                onClose={() => {
                    setCreateDrawerOpen(false);
                }}
            />
        </Box>
    );
};
