import { useState } from 'react';
import { Stack, Grid, Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiDotsVertical } from '@mdi/js';

import { IScormListItemFragment } from 'graphql/types';
import { List } from 'common/components/List';
import { Button } from 'common/components/Button';
import { IconButton } from 'common/components/IconButton';
import { BoxLoader } from 'common/components/Loader';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { ScormListItem } from 'training/components/ScormListItem';
import { getScormUrl } from 'training/utils/scorm';
import { ScormUpdateDrawer } from 'training/components/ScormUpdateDrawer';

interface IProps {
    isEditable?: boolean;
    scorm?: IScormListItemFragment;
    loading?: boolean;
    onReplaceScorm?(): void;
}

export const ScormFormItem = ({
    isEditable,
    scorm,
    loading,
    onReplaceScorm,
}: IProps) => {
    const [translate] = useTranslation();
    const [showScormUpdateDrawer, setShowScormUpdateDrawer] = useState(false);

    if (loading) return <BoxLoader height="197px" />;

    if (!scorm) return null;

    const { id } = scorm;

    return (
        <List>
            <ScormListItem
                disabled
                button={false}
                footer={
                    <Grid container>
                        <Grid item xs>
                            <Stack direction="row" spacing={2}>
                                {isEditable && (
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setShowScormUpdateDrawer(true)
                                        }
                                    >
                                        {translate('edit')}
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        (window.location.href = getScormUrl(id))
                                    }
                                >
                                    {translate('view')}
                                </Button>
                            </Stack>
                        </Grid>

                        {!!onReplaceScorm && (
                            <Grid
                                item
                                component={Box}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DropdownMenu
                                    anchor={
                                        <IconButton
                                            color="inherit"
                                            iconPath={mdiDotsVertical}
                                            iconSize="2.8rem"
                                        />
                                    }
                                    placement="bottom-end"
                                >
                                    <MenuItem onClick={onReplaceScorm}>
                                        {translate('replaceScormPackage')}
                                    </MenuItem>
                                </DropdownMenu>
                            </Grid>
                        )}
                    </Grid>
                }
                px={2}
                py={0}
                scorm={scorm}
                sx={{
                    flexWrap: 'wrap',
                }}
            />

            <ScormUpdateDrawer
                id={id}
                open={showScormUpdateDrawer}
                onClose={() => setShowScormUpdateDrawer(false)}
            />
        </List>
    );
};
