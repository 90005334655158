import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mdiFolderMultiplePlus, mdiPlus } from '@mdi/js';

import { GroupDrawer } from 'user/components/GroupDrawer';
import { IUserProfileSectionProps } from 'user/types';
import {
    ICreatePortfolioItemInput,
    IPortfolioItemFragment,
    IScoringFragment,
    useCurrentUserQuery,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { PortfolioItemGroup } from 'user/components/PortfolioItemGroup';
import { PortfolioItemList } from 'user/components/PortfolioItemList';
import { InboxGroup } from 'user/components/InboxGroup';
import { NoResults } from 'common/components/NoResults';
import { getGroupsState } from 'user/utils';
import { TGroupsState } from 'user/utils/getGroupsState';
import { useSnackbar } from 'common/hooks/useSnackbar';
import {
    goToPortfolioGroupEditPage,
    goToPortfolioItemEditPage,
    goToPortfolioItemViewPage,
    goToPortfolioPage,
} from 'user/utils/goToRoutes';
import {
    DragDropContext,
    Draggable,
    Droppable,
} from 'common/components/DragDrop';
import {
    usePortfolio,
    usePortfolioDND,
    usePortfolioRouting,
} from 'user/hooks/portfolio';
import { Tooltip } from 'common/components/Tooltip';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import { PageTitle } from 'common/components/PageTitle';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { getUrl } from 'route/utils/getUrl';
import { ScoringChips } from 'common/components/ScoringChips';
import { ChipGroup } from 'common/components/Chip';
import { DateRangeFilter } from 'common/components/filters';
import { TDateRange } from 'common/types';
import { PortfolioItemDrawer } from 'user/components/PortfolioItemDrawer';

import { PortfolioExportButton } from '../PortfolioExportButton';

const GROUP_TYPE = 'portfolio';

export const Portfolio = ({
    id: userId,
    name,
    withEditActions,
}: IUserProfileSectionProps) => {
    const [expanded, setExpanded] = useState<TGroupsState>(
        getGroupsState(GROUP_TYPE)
    );
    const inboxGroupId = useRef(undefined);
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const [translate] = useTranslation();
    const isUserPortfolioItemPage = !!useRouteMatch('USER_PORTFOLIO_ITEM_VIEW');
    const isPortfolioGroupEditPage = !!useRouteMatch('PORTFOLIO_GROUP_EDIT');
    const { data: currentUserData } = useCurrentUserQuery();
    const [dateFilter, setDateFilter] = useState<TDateRange | undefined>();

    const handleCreateCustomPortfolioItemSuccess = useCallback(
        (portfolioItemId: string) => {
            goToPortfolioItemEditPage(navigate, portfolioItemId);

            displaySnackbar(translate('portfolioItemActionSuccess.create'), {
                variant: 'success',
            });
        },
        [displaySnackbar, navigate, translate]
    );

    const {
        portfolioLoading,
        portfolioErrors,
        groups,
        scores,
        createError,
        createLoading,
        setGroups,
        createCustomPortfolioItem,
    } = usePortfolio(
        dateFilter,
        userId,
        handleCreateCustomPortfolioItemSuccess
    );
    const loading = portfolioLoading || createLoading;

    const { handleDragEnd } = usePortfolioDND(groups, setGroups);
    const {
        selectedGroup,
        portfolioItemDrawerOpen,
        groupDrawerOpen,
        userIdParam,
    } = usePortfolioRouting(groups, loading);

    useEffect(() => {
        const groupNotFound =
            isPortfolioGroupEditPage && !loading && !selectedGroup;

        if (groupNotFound) {
            navigate(getUrl('PAGE_NOT_FOUND'), { replace: true });
        }
    }, [
        isUserPortfolioItemPage,
        loading,
        isPortfolioGroupEditPage,
        selectedGroup,
        navigate,
    ]);

    const isCurrentUserPortfolio = !!(
        currentUserData?.currentUser &&
        currentUserData.currentUser.id === userId
    );

    // Returns a promise for the Formik form to know when submission is done
    const handleCreateCustomPortfolioItem = useCallback(
        (values: ICreatePortfolioItemInput) =>
            createCustomPortfolioItem({
                variables: { portfolioItem: { ...values } },
            }),
        [createCustomPortfolioItem]
    );

    const inboxGroup = useMemo(() => {
        let hasItems = false;
        groups.forEach((group) => {
            if (hasItems) return;

            if (!!group.portfolioItems.length) hasItems = true;
        });

        if (!hasItems) return null;

        const inboxGroupResult = groups
            .filter((group) => group.isInbox)
            .map((group) => {
                inboxGroupId.current = group.id;

                return (
                    <Box key={group.id} pb={2}>
                        <InboxGroup
                            as={PortfolioItemList}
                            groupId={group.id}
                            items={group.portfolioItems}
                            key={group.id}
                            withEditActions={withEditActions}
                            onClickCustomItem={(
                                item: IPortfolioItemFragment
                            ) => {
                                if (isCurrentUserPortfolio) {
                                    goToPortfolioItemEditPage(
                                        navigate,
                                        item.id
                                    );

                                    return;
                                }

                                goToPortfolioItemViewPage(
                                    navigate,
                                    userId,
                                    item.id
                                );
                            }}
                        />
                    </Box>
                );
            });

        if (inboxGroupResult.length) return inboxGroupResult[0];

        return null;
    }, [groups, navigate, userId, isCurrentUserPortfolio, withEditActions]);

    const groupItems = useMemo(() => {
        const handleExpandGroup = (groupId: string) => () => {
            const isExpanded = !(groupId in expanded) || expanded[groupId];

            const newPortfolioItemGroupsState = {
                ...expanded,
                [groupId]: !isExpanded,
            };

            setExpanded(newPortfolioItemGroupsState);

            localStorage.setItem(
                'portfolioItemGroupsState',
                JSON.stringify(newPortfolioItemGroupsState)
            );
        };

        return groups
            .filter((group) => !group.isInbox)
            .map((group, index) => {
                const isExpanded =
                    !(group.id in expanded) || expanded[group.id];

                return (
                    <Draggable
                        dragDropDisabled={!withEditActions}
                        draggableId={group.id}
                        index={index}
                        key={group.id}
                    >
                        <Box pb={2} pt={2}>
                            <PortfolioItemGroup
                                expanded={isExpanded}
                                loading={createLoading}
                                withEditActions={withEditActions}
                                onClick={handleExpandGroup(group.id)}
                                onClickCustomItem={(
                                    item: IPortfolioItemFragment
                                ) => {
                                    if (isCurrentUserPortfolio) {
                                        goToPortfolioItemEditPage(
                                            navigate,
                                            item.id
                                        );

                                        return;
                                    }

                                    goToPortfolioItemViewPage(
                                        navigate,
                                        userId,
                                        item.id
                                    );
                                }}
                                onEdit={() =>
                                    goToPortfolioGroupEditPage(
                                        navigate,
                                        group.id
                                    )
                                }
                                onQuickAddCustomItem={
                                    handleCreateCustomPortfolioItem
                                }
                                {...group}
                            />
                        </Box>
                    </Draggable>
                );
            })
            .filter(Boolean);
    }, [
        groups,
        withEditActions,
        expanded,
        createLoading,
        handleCreateCustomPortfolioItem,
        navigate,
        userId,
        isCurrentUserPortfolio,
    ]);

    const hasPortfolio =
        !portfolioLoading &&
        !!(
            groups.length > 1 ||
            (groups.length === 1 && groups[0].portfolioItems.length)
        );
    const hasPortfolioItems = groups.some(
        (group) => group.portfolioItems.length
    );

    const pageTitle = `${translate('portfolio')} - ${name}`;
    const isDrawerPage = groupDrawerOpen || portfolioItemDrawerOpen;
    const scoring = scores?.map((scoreItem) => {
        const { totalScore, nameSingular, namePlural } = scoreItem;

        return {
            score: totalScore,
            scoringSystem: {
                nameSingular,
                namePlural,
            },
        };
    }) as IScoringFragment[];

    return (
        <>
            <PageTitle mixpanelTitle="Portfolio" skipMixpanel={isDrawerPage}>
                {pageTitle}
            </PageTitle>

            {createError && <ApolloError error={createError} />}
            {portfolioErrors.map((error, index) => (
                <ApolloError error={error} key={index} />
            ))}

            {portfolioLoading && <Loader />}

            {!portfolioLoading && (
                <>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mb: 1.5, alignItems: 'center' }}
                    >
                        {withEditActions && (
                            <Grid item>
                                <Stack direction="row" spacing={1}>
                                    <Box>
                                        <Tooltip
                                            title={translate<string>(
                                                'newPortfolioItem'
                                            )}
                                        >
                                            <Box>
                                                <ActionButton
                                                    outlined
                                                    component={Link}
                                                    size="medium"
                                                    to="PORTFOLIO_ITEM_CREATE"
                                                >
                                                    <Icon path={mdiPlus} />
                                                </ActionButton>
                                            </Box>
                                        </Tooltip>
                                    </Box>

                                    <Box>
                                        <Tooltip
                                            title={translate<string>(
                                                'newGroup'
                                            )}
                                        >
                                            <Box>
                                                <ActionButton
                                                    outlined
                                                    component={Link}
                                                    size="medium"
                                                    to="PORTFOLIO_GROUP_CREATE"
                                                >
                                                    <Icon
                                                        path={
                                                            mdiFolderMultiplePlus
                                                        }
                                                        size="1.8rem"
                                                    />
                                                </ActionButton>
                                            </Box>
                                        </Tooltip>
                                    </Box>

                                    {hasPortfolioItems && (
                                        <PortfolioExportButton
                                            dateFilter={dateFilter}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                        )}

                        <Grid
                            item
                            sx={{ ml: 'auto', justifySelf: 'flex-start' }}
                        >
                            {!!scores?.length && (
                                <ChipGroup>
                                    <ScoringChips scoring={scoring} />
                                </ChipGroup>
                            )}
                        </Grid>

                        <Grid item>
                            <DateRangeFilter
                                dateRange={dateFilter}
                                onChange={(dateRange) =>
                                    setDateFilter(dateRange)
                                }
                            />
                        </Grid>
                    </Grid>

                    <DragDropContext
                        dragDropDisabled={!withEditActions}
                        onDragEnd={handleDragEnd}
                    >
                        {inboxGroup}
                        <Droppable
                            dragDropDisabled={!withEditActions}
                            droppableId="groups"
                            type="GROUP"
                        >
                            <div>{hasPortfolio && groupItems}</div>
                        </Droppable>
                    </DragDropContext>

                    {!hasPortfolio && !portfolioLoading && (
                        <NoResults variant="USER" />
                    )}
                </>
            )}

            {withEditActions && (
                <GroupDrawer
                    inboxGroupId={inboxGroupId.current}
                    item={selectedGroup}
                    loading={portfolioLoading}
                    mixpanelTitle="Portfolio group drawer"
                    open={groupDrawerOpen}
                    pageTitle={pageTitle}
                    queryVars={{ userId, dateFilter }}
                    onClose={() => goToPortfolioPage(navigate, userIdParam)}
                />
            )}

            <PortfolioItemDrawer
                pageTitle={pageTitle}
                withEditActions={withEditActions}
                onCreateItem={handleCreateCustomPortfolioItem}
            />
        </>
    );
};
