import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';

import { getRoute, getRoutes } from 'route/utils/getRoute';
import { IsAuthenticated } from 'common/components/IsAuthenticated';
import { HomePage } from 'hydra/pages/HomePage';
import { TrainingPage } from 'hydra/pages/TrainingPage';
import { LibraryCollectionPage } from 'hydra/pages/LibraryCollectionPage';
import { LibraryArticlePage } from 'hydra/pages/LibraryArticlePage';
import { LibraryPage } from 'hydra/pages/LibraryPage';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { UserPage } from 'hydra/pages/UserPage';
import { WelcomePage } from 'hydra/pages/WelcomePage';
import { ChangePasswordPage } from 'hydra/pages/ChangePasswordPage';
import { OrganisationLanguagePage } from 'hydra/pages/OrganisationLanguagePage';
import { OrganisationImagesPage } from 'hydra/pages/OrganisationImagesPage';
import { OrganisationColorsPage } from 'hydra/pages/OrganisationColorsPage';
import { TrainingsManagementPage } from 'hydra/pages/TrainingsManagementPage';
import { OffersPage } from 'hydra/pages/OffersPage';
import { OfferPage } from 'hydra/pages/OfferPage';
import { ObjectAction } from 'user/hooks/useFrontendPermissions';
import { RedirectOldRoute } from 'common/components/RedirectOldRoute';
import { ErrorPage } from 'hydra/pages/ErrorPage';
import { OffersBasePage } from 'hydra/pages/OffersBasePage';
import { ScrollReset } from 'common/components/ScrollReset';
import { PlansPage } from 'hydra/pages/PlansPage';
import { LtiDeepLinkPage } from 'hydra/pages/LtiDeepLinkPage';
import { GroupsManagementPage } from 'hydra/pages/GroupsManagementPage';
import { VideosManagementPage } from 'hydra/pages/VideosManagementPage';
import { GlobalFileUploadList } from 'common/components/GlobalFileUploadList';
import { CourseManagementPage } from 'hydra/pages/CourseManagementPage';
import { GlobalDrawersProvider } from 'common/components/GlobalDrawerProvider';
import { PortfolioReportPage } from 'hydra/pages/PortfolioReportPage';
import { EnrollmentsManagementPage } from 'hydra/pages/EnrollmentsManagementPage';
import { PlanExpiredPage } from 'hydra/pages/PlanExpiredPage';
import { PlanStatusErrorPage } from 'hydra/pages/PlanStatusErrorPage';
import { UsersManagementPage } from 'hydra/pages/UsersManagementPage';
import { SkillsManagementPage } from 'hydra/pages/SkillsManagementPage';

if (import.meta.env.MODE !== 'development' && import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.MODE.toUpperCase(),
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
                traceFetch: false,
            }),
        ],
        tracesSampleRate: 0.2,
    });
}

export const APP_ROUTES = [
    {
        path: '/',
        errorElement: <ErrorPage />,
        element: (
            <>
                <GlobalDrawersProvider>
                    <Outlet />
                </GlobalDrawersProvider>

                <ScrollReset />

                <GlobalFileUploadList />
            </>
        ),
        children: [
            {
                path: '/',
                element: (
                    <IsAuthenticated>
                        <HomePage />
                    </IsAuthenticated>
                ),
            },
            getRoute('LIBRARY', {
                element: (
                    <IsAuthenticated>
                        <LibraryPage />
                    </IsAuthenticated>
                ),
                children: [
                    ...getRoutes(
                        [
                            'LIBRARY',
                            'LIBRARY_COLLECTION',
                            'LIBRARY_COLLECTION_CREATE',
                            'LIBRARY_COLLECTION_EDIT',
                            'LIBRARY_COLLECTION_CREATE_ARTICLE',
                        ],
                        {
                            element: <LibraryCollectionPage />,
                        }
                    ),
                    ...getRoutes(
                        [
                            'LIBRARY_ARTICLE',
                            'LIBRARY_ARTICLE_EDIT',
                            'LIBRARY_ARTICLE_EDIT_CONTENT',
                        ],
                        {
                            element: <LibraryArticlePage />,
                        }
                    ),
                ],
            }),
            getRoute('OFFER_LIST', {
                element: <OffersBasePage />,
                children: [
                    ...getRoutes(
                        [
                            'OFFER_LIST',
                            'OFFER_MEETING_CREATE',
                            'OFFER_CREATE',
                            'OFFER_DETAIL_DRAWER',
                        ],
                        {
                            element: <OffersPage />,
                        }
                    ),
                    ...getRoutes(
                        [
                            'OFFER_DETAIL',
                            'OFFER_EDIT_CONTENT',
                            'OFFER_EDIT',
                            'OFFER_EVENT_DETAIL_DRAWER',
                            'CREATE_OFFER_EVENT',
                        ],
                        {
                            element: <OfferPage />,
                        }
                    ),
                ],
            }),
            ...getRoutes(
                [
                    'TRAINING',
                    'TRAINING_PARTICIPANTS',
                    'TRAINING_TRAINERS',
                    'TRAINING_SHARED_FILES',
                    'TRAINING_ASSIGNMENT',
                    'TRAINING_ASSIGNMENT_FOR_USER',
                    'TRAINING_ASSIGNMENT_GRADE',
                    'TRAINING_CERTIFICATE',
                    'TRAINING_EDIT',
                    'TRAINING_CREATE_MODULE',
                    'TRAINING_CREATE_MODULE_TYPE',
                    'TRAINING_CREATE_MODULE_IN_GROUP',
                    'TRAINING_CREATE_MODULE_TYPE_IN_GROUP',
                    'TRAINING_UPDATE_MODULE',
                    'TRAINING_CREATE_MODULE_GROUP',
                    'TRAINING_EDIT_MODULE_GROUP',
                    'TRAINING_MODULE_SELECT',
                ],
                {
                    element: (
                        <IsAuthenticated>
                            <TrainingPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            getRoute('TRAINING_MANAGEMENT_LIST', {
                element: (
                    <IsAuthenticated
                        permission="training"
                        permissionAction={ObjectAction.create}
                    >
                        <TrainingsManagementPage />
                    </IsAuthenticated>
                ),
            }),
            ...getRoutes(['VIDEO_MANAGEMENT_LIST', 'VIDEO_EDIT'], {
                element: (
                    <IsAuthenticated
                        permission="video"
                        permissionAction={ObjectAction.update}
                    >
                        <VideosManagementPage />
                    </IsAuthenticated>
                ),
            }),
            ...getRoutes(
                [
                    'USER_PORTFOLIO',
                    'USER_PORTFOLIO_ITEM_VIEW',
                    'USER_DEVELOPMENT_PLAN',
                    'USER_DEVELOPMENT_ITEM_VIEW',
                    'USER_TRAININGS',
                    'PORTFOLIO',
                    'PORTFOLIO_ITEM_CREATE',
                    'PORTFOLIO_ITEM_EDIT',
                    'PORTFOLIO_ITEM_VIEW',
                    'PORTFOLIO_GROUP_CREATE',
                    'PORTFOLIO_GROUP_EDIT',
                    'PORTFOLIO_TRAININGS',
                    'DEVELOPMENT_PLAN',
                    'DEVELOPMENT_ITEM_CREATE',
                    'DEVELOPMENT_ITEM_EDIT',
                    'DEVELOPMENT_GROUP_CREATE',
                    'DEVELOPMENT_GROUP_EDIT',
                    'USER_OFFERS',
                    'PORTFOLIO_OFFERS',
                    'USER_MEETINGS',
                    'PORTFOLIO_MEETINGS',
                ],
                {
                    element: (
                        <IsAuthenticated>
                            <UserPage />
                        </IsAuthenticated>
                    ),
                }
            ),

            ...getRoutes(
                [
                    'PORTFOLIO_REPORT',
                    'TRAINING_REPORT',
                    'OFFER_REPORT',
                    'MEETING_REPORT',
                    'COURSE_REPORT',
                ],
                {
                    element: (
                        <IsAuthenticated>
                            <PortfolioReportPage />
                        </IsAuthenticated>
                    ),
                }
            ),

            getRoute('ORGANISATION_LANGUAGE', {
                element: (
                    <IsAuthenticated
                        permission="organisation"
                        permissionAction={ObjectAction.update}
                    >
                        <OrganisationLanguagePage />
                    </IsAuthenticated>
                ),
            }),
            getRoute('ORGANISATION_IMAGES', {
                element: (
                    <IsAuthenticated
                        permission="organisation"
                        permissionAction={ObjectAction.update}
                    >
                        <OrganisationImagesPage />
                    </IsAuthenticated>
                ),
            }),
            getRoute('ORGANISATION_COLORS', {
                element: (
                    <IsAuthenticated
                        permission="organisation"
                        permissionAction={ObjectAction.update}
                    >
                        <OrganisationColorsPage />
                    </IsAuthenticated>
                ),
            }),
            getRoute('WELCOME', {
                element: <WelcomePage />,
            }),
            getRoute('PLANS', {
                element: (
                    <IsAuthenticated
                        permission="organisation"
                        permissionAction={ObjectAction.update}
                    >
                        <PlansPage />
                    </IsAuthenticated>
                ),
            }),
            getRoute('PLAN_TRIAL_EXPIRED', {
                element: (
                    <IsAuthenticated
                        permission="organisation"
                        permissionAction={ObjectAction.update}
                    >
                        <PlanExpiredPage />
                    </IsAuthenticated>
                ),
            }),
            getRoute('PLAN_STATUS_ERROR', {
                element: (
                    <IsAuthenticated
                        permission="organisation"
                        permissionAction={ObjectAction.update}
                    >
                        <PlanStatusErrorPage />
                    </IsAuthenticated>
                ),
            }),
            getRoute('CHANGE_PASSWORD', {
                element: <ChangePasswordPage />,
            }),
            ...getRoutes(
                ['CONNECTIONS_LTI_TRAININGS', 'CONNECTIONS_LTI_COURSES'],
                {
                    element: (
                        <IsAuthenticated>
                            <LtiDeepLinkPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            ...getRoutes(
                [
                    'USER_MANAGEMENT_CREATE',
                    'USER_MANAGEMENT_LIST',
                    'USER_MANAGEMENT_LIST_ARCHIVED',
                ],
                {
                    element: (
                        <IsAuthenticated>
                            <UsersManagementPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            ...getRoutes(
                ['GROUP_MANAGEMENT_LIST', 'GROUP_CREATE', 'GROUP_EDIT'],
                {
                    element: (
                        <IsAuthenticated>
                            <GroupsManagementPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            ...getRoutes(
                ['COURSE_MANAGEMENT_LIST', 'COURSE_CREATE', 'COURSE_EDIT'],
                {
                    element: (
                        <IsAuthenticated>
                            <CourseManagementPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            ...getRoutes(
                [
                    'LIBRARY_NL_REDIRECT',
                    'LIBRARY_COLLECTION_NL_REDIRECT',
                    'LIBRARY_ARTICLE_NL_REDIRECT',
                    'CHANGE_PASSWORD_NL_REDIRECT',
                    'WELCOME_NL_REDIRECT',
                ],
                { element: <RedirectOldRoute /> }
            ),
            ...getRoutes(
                [
                    'MANAGEMENT_USERS_ENROLLMENTS',
                    'MANAGEMENT_USERS_ENROLLMENTS_WAITING_LIST',
                    'MANAGEMENT_USERS_ENROLLMENTS_ENROLLED',
                    'MANAGEMENT_USERS_ENROLLMENTS_DENIED',
                    'MANAGEMENT_USERS_ENROLLMENTS_COMPLETED',
                    'MANAGEMENT_USERS_ENROLLMENTS_ABSENT',
                ],
                {
                    element: (
                        <IsAuthenticated>
                            <EnrollmentsManagementPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            ...getRoutes(
                ['SKILL_MANAGEMENT_LIST', 'SKILL_CREATE', 'SKILL_EDIT'],
                {
                    element: (
                        <IsAuthenticated>
                            <SkillsManagementPage />
                        </IsAuthenticated>
                    ),
                }
            ),
            {
                path: '*',
                element: (
                    <IsAuthenticated>
                        <FourOFourPage />
                    </IsAuthenticated>
                ),
            },
        ],
    },
];

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(APP_ROUTES);

export const Router = () => <RouterProvider router={router} />;
