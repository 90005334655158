import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { TrainerTable } from 'user/components/TrainerTable';
import { useTrainerTableData } from 'user/hooks/useTrainerTableData';
import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';

interface IProps extends IPageDrawerProps {
    addButtonTranslationKeys?: {
        noSelection?: string;
        selection?: string;
    };
    disabledTrainerIds?: string[];
    footerAppend?: React.ReactNode;
    loading?: boolean;
    noResultsLabel?: string;
    paginationTranslationKey?: string;
    selectorQueryVariables?: IUserSelectorInput;
    onAddTrainers(
        selectedTrainerIds: string[],
        selectableTrainers?: IUserListItemFragment[]
    ): void;
    onDrawerOpen(open: boolean): void;
}

export const TrainerSelectDrawer = ({
    addButtonTranslationKeys,
    disabledTrainerIds = [],
    footerAppend,
    loading,
    noResultsLabel,
    paginationTranslationKey,
    open,
    selectorQueryVariables,
    title,
    onAddTrainers,
    onDrawerOpen,
}: IProps) => {
    const { t: translate } = useTranslation();
    const footerAppendRef = useRef<HTMLDivElement>();
    const theme = useTheme();

    const {
        loading: loadingTrainerData,
        trainers,
        alreadyAddedTrainers,
        tableDataProps,
    } = useTrainerTableData(disabledTrainerIds, !open, selectorQueryVariables);
    const [selectedTrainerIds, setSelectedTrainerIds] = useState<string[]>([]);

    const { searchProps, paginationProps, selectionProps } = tableDataProps;
    const { handleSearch, handleFilterSelect } = searchProps || {};
    const { setPage } = paginationProps || {};
    const { selected, onSelectAll } = selectionProps || {};

    const resetSettings = () => {
        handleSearch('');
        handleFilterSelect([]);
        setPage?.(1);
        setSelectedTrainerIds([]);

        // Deselect all when there are selected items
        if (selected?.length) onSelectAll();
    };

    const footerAppendHeight =
        footerAppendRef.current?.getBoundingClientRect().height;
    let listPaddingBottom = theme.spacing(13);

    if (!!footerAppendHeight) {
        listPaddingBottom = `calc(${listPaddingBottom} + ${footerAppendHeight}px)`;
    }

    return (
        <PageDrawer
            open={open}
            title={title || translate('addTrainersTitle')}
            onClose={() => {
                onDrawerOpen(false);

                resetSettings();
            }}
        >
            <Box
                sx={{
                    pb: listPaddingBottom,
                    pt: { xs: 2, sm: 4 },
                    px: { xs: 2, sm: 4 },
                }}
            >
                <TrainerTable
                    inDrawer
                    disabled={loading || loadingTrainerData}
                    disabledTrainerIds={alreadyAddedTrainers}
                    loading={loadingTrainerData}
                    noResultsLabel={noResultsLabel}
                    paginationProps={paginationProps}
                    paginationTranslationKey={paginationTranslationKey}
                    searchProps={searchProps}
                    selectionProps={selectionProps}
                    trainers={trainers}
                    onSelectTrainers={setSelectedTrainerIds}
                />
            </Box>

            <PageDrawerFooter sx={{ zIndex: 2 }}>
                <Box display="flex" flexDirection="column">
                    {!!footerAppend && (
                        <Box ref={footerAppendRef}>{footerAppend}</Box>
                    )}

                    <Button
                        color="primary"
                        disabled={loading || !selected?.length}
                        loading={loading}
                        variant="contained"
                        onClick={async () => {
                            await onAddTrainers(selectedTrainerIds, trainers);

                            resetSettings();
                        }}
                    >
                        {!selected?.length
                            ? translate(
                                  addButtonTranslationKeys?.noSelection ||
                                      'addTrainersTitle'
                              )
                            : translate(
                                  addButtonTranslationKeys?.selection ||
                                      'addTrainers',
                                  {
                                      count: selected.length,
                                  }
                              )}
                    </Button>
                </Box>
            </PageDrawerFooter>
        </PageDrawer>
    );
};
