import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import { IContentTypeValue, IFeature, IOfferEventType } from 'graphql/types';
import { ImageField, Switch, TextField } from 'common/components/FormField';
import { FormSection } from 'common/components/FormSection';
import { TrainingSelector } from 'training/components/FormField/TrainingSelector';
import { ExternalContentSelector } from 'external/components/FormField/ExternalContentSelector';
import { CourseSelector } from 'training/components/FormField/CourseSelector';
import { TOfferEventFormValues, TOfferEventValues } from 'offer/types';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { ExtraCategoryFormFields } from 'organisation/components/ExtraCategoryFormFields';
import { FormTrainerSelector } from 'user/components/FormField/FormTrainerSelector';
import { CertificateSelectField } from 'training/components/CertificateSelectField';
import { ScormSelector } from 'training/components/FormField/ScormSelector';
import { useFeature } from 'common/hooks/useFeature';
import { VideoSelector } from 'video/components/FormField/VideoSelector';

export const INITIAL_VALUES: TOfferEventValues = {
    trainers: [],
    trainerApproval: false,
    minSubscriptions: 0,
    maxSubscriptions: 0,
    price: 0,
    descriptionBeforeSubscription: '',
    descriptionAfterSubscription: '',
    accessTrainings: [],
    accessExternalContents: [],
    accessCourses: [],
    showParticipantList: false,
    type: IOfferEventType.Training,
    waitingList: false,
};

interface IProps {
    offerEventId?: string;
    offerEventType: IOfferEventType;
    setFieldValue(
        field: string,
        value: boolean,
        shouldValidate?: boolean | undefined
    ): void;
    hideExternalContent?: boolean;
    isOfferDetailDrawer?: boolean;
}

export const OfferEventFormFields = ({
    offerEventId,
    offerEventType,
    hideExternalContent,
    isOfferDetailDrawer,
}: IProps) => {
    const { t: translate } = useTranslation();
    const { values } = useFormikContext<TOfferEventFormValues>();
    const { hasDetailPage } = values;
    const isNewMeeting = !!useRouteMatch('OFFER_MEETING_CREATE');
    const isNewOffer = !!useRouteMatch('OFFER_CREATE');
    const isOfferDetail =
        !!useRouteMatch('OFFER_DETAIL_DRAWER') || isOfferDetailDrawer;
    const { canUse: canUseScorm, loading: loadingCanUseScorm } = useFeature(
        IFeature.Scorm
    );
    const { canUse: canUseVideo, loading: loadingCanUseVideo } = useFeature(
        IFeature.Video
    );

    const isWebinar = offerEventType === IOfferEventType.Webinar;
    const isMeeting = offerEventType === IOfferEventType.Meeting;
    const isWebinarOrMeeting = isWebinar || isMeeting;

    const isNewMeetingOrOffer = isNewMeeting || isNewOffer;

    return (
        <>
            {(isNewMeetingOrOffer || isOfferDetail) && (
                <>
                    <FormSection
                        description={
                            <Box>
                                {translate('offer.offerExtraFieldsDescription')}
                            </Box>
                        }
                        title={translate('offer.offerExtraFields')}
                    >
                        <ExtraCategoryFormFields
                            contentType={IContentTypeValue.Offer}
                        />
                    </FormSection>
                </>
            )}

            {isWebinarOrMeeting && (
                <FormSection
                    renderExpanded
                    description={
                        <Box>
                            {translate('offer.offerTrainerSectionDescription')}
                        </Box>
                    }
                    title={translate('trainer')}
                >
                    <Box pt={2}>
                        <FormTrainerSelector
                            label={translate('addTrainer')}
                            name="trainers"
                            selectorQueryVariables={
                                isNewOffer || !offerEventId
                                    ? undefined
                                    : {
                                          contentType:
                                              IContentTypeValue.OfferEvent,
                                          objectId: offerEventId,
                                      }
                            }
                            value={values.trainers}
                        />
                    </Box>
                </FormSection>
            )}

            {isWebinarOrMeeting && (
                <FormSection
                    renderExpanded
                    description={
                        <Box>
                            {translate('offer.certificateDescriptionMeeting')}
                        </Box>
                    }
                    title={translate('offer.certificateTitle')}
                >
                    <CertificateSelectField name="certificate" />
                </FormSection>
            )}

            {isWebinarOrMeeting && (
                <>
                    <FormSection
                        renderExpanded
                        description={
                            <Box>{translate('offer.trainingsDescription')}</Box>
                        }
                        title={translate('offer.trainingsTitle')}
                    >
                        <Box mt={3}>
                            <TrainingSelector
                                name="accessTrainings"
                                value={values.accessTrainings}
                            />
                        </Box>
                    </FormSection>
                    {!hideExternalContent && (
                        <FormSection
                            renderExpanded
                            description={
                                <Box>
                                    {translate(
                                        'offer.externalContentsDescription'
                                    )}
                                </Box>
                            }
                            title={translate('offer.externalContentsTitle')}
                        >
                            <Box mt={3}>
                                <ExternalContentSelector
                                    name="accessExternalContents"
                                    value={values.accessExternalContents || []}
                                />
                            </Box>
                        </FormSection>
                    )}
                    <FormSection
                        renderExpanded
                        description={
                            <Box>{translate('offer.coursesDescription')}</Box>
                        }
                        title={translate('offer.coursesTitle')}
                    >
                        <Box mt={3}>
                            <CourseSelector
                                name="accessCourses"
                                value={values.accessCourses || []}
                            />
                        </Box>
                    </FormSection>

                    {!loadingCanUseScorm && canUseScorm && (
                        <FormSection
                            renderExpanded
                            description={
                                <Box>
                                    {translate('offer.scormsDescription')}
                                </Box>
                            }
                            title={translate('offer.scormsTitle')}
                        >
                            <Box mt={3}>
                                <ScormSelector
                                    name="accessScorms"
                                    value={values.accessScorms || []}
                                />
                            </Box>
                        </FormSection>
                    )}

                    {!loadingCanUseVideo && canUseVideo && (
                        <FormSection
                            renderExpanded
                            description={
                                <Box>
                                    {translate('offer.videosDescription')}
                                </Box>
                            }
                            title={translate('offer.videosTitle')}
                        >
                            <Box mt={3}>
                                <VideoSelector
                                    name="accessVideos"
                                    value={values.accessVideos || []}
                                />
                            </Box>
                        </FormSection>
                    )}
                </>
            )}

            {isNewMeetingOrOffer && (
                <FormSection
                    renderExpanded
                    description={
                        <Box>
                            {translate('offer.multipleEventsDescription')}
                        </Box>
                    }
                    title={translate('offer.multipleEvents')}
                >
                    <Field
                        checked={hasDetailPage}
                        component={Switch}
                        formControlProps={{ sx: { mb: hasDetailPage ? 2 : 0 } }}
                        label={translate('offer.detailPageEvents')}
                        name="hasDetailPage"
                    />

                    {hasDetailPage && (
                        <Box mt={2}>
                            <Field
                                withDefaults
                                acceptedFileTypes={HEADER_IMAGE_TYPE}
                                component={ImageField}
                                helperText={translate('imageSizeDescription', {
                                    width: 1440,
                                    height: 300,
                                })}
                                label={translate('headerImage')}
                                maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                                name="image"
                                startSlide={0}
                            />

                            {isNewMeetingOrOffer && (
                                <Field
                                    multiline
                                    component={TextField}
                                    label={translate('description')}
                                    name="description"
                                />
                            )}
                        </Box>
                    )}
                </FormSection>
            )}
        </>
    );
};
