import { Box, SxProps, Alert } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { isSupported } from 'tus-js-client';

import {
    useCreateVideoMutation,
    useDeleteVideoMutation,
    useReplaceVideoFileMutation,
    VideoDocument,
} from 'graphql/types';
import { useFileUpload } from 'common/hooks/useFileUpload';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import {
    VIDEO_FILE_TYPE,
    VIDEO_MAX_SIZE,
    SIZE_TO_MB_MULTIPLIER,
} from 'common/constants/files';
import { Link } from 'common/components/Link';
import { Text } from 'common/components/Text';

const uploadStyle: SxProps = {
    position: 'relative',
    display: 'inline-block',

    input: {
        fontSize: 0,
        width: 0,
        height: 0,
        opacity: 0,
        position: 'absolute',
    },
};

interface IProps {
    id: string; // id needs to be unique so we can use multiple upload buttons on the same page
    multiple?: boolean;
    videoId?: string;
    disabled?: boolean;
    onStart?(): void;
    onStartUpload?(): void;
    onSuccess?(): void;
    onAbort?(): void;
    onError?(): void;
    children: React.ReactNode;
}

export const VideoUpload = ({
    id,
    multiple,
    videoId,
    disabled,
    onStart,
    onStartUpload,
    onSuccess,
    onAbort,
    onError,
    children,
}: IProps) => {
    const [translate] = useTranslation();
    const { uploadFile } = useFileUpload();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();

    const [createVideo] = useCreateVideoMutation();
    const [deleteVideo] = useDeleteVideoMutation();
    const [replaceVideoFile] = useReplaceVideoFileMutation();

    const uploadVideo = async (file: File) => {
        onStart?.();

        const isNewVideo = !videoId;

        // Remove extension from file name to use as title
        const title = file.name.replace(/\.[^.]*$/, '');

        if (file.size > VIDEO_MAX_SIZE * SIZE_TO_MB_MULTIPLIER) {
            onError?.();

            displaySnackbar(
                <Text color="inherit" variant="body2">
                    {file.name}:{' '}
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                            br: <br />,
                        }}
                        i18nKey="fileLimitExceededVideo"
                        values={{ fileSize: `${VIDEO_MAX_SIZE}mb` }}
                    />
                </Text>,
                {
                    autoHideDuration: 10000,
                    variant: 'error',
                }
            );

            return;
        }

        let response;

        // If no video is given via the props, create a new video otherwise replace the video file
        if (isNewVideo) {
            try {
                const videoResponse = await createVideo({
                    variables: { video: { title } },
                    update: (cache) => {
                        // Remove video list from cache so it will be refetched
                        cache.evict({ fieldName: 'videos' });
                        cache.gc();
                    },
                });

                response = videoResponse?.data?.createVideo || undefined;
            } catch (error) {
                showApolloError(error);

                return;
            }
        } else {
            try {
                const videoResponse = await replaceVideoFile({
                    variables: { id: videoId },
                });
                response = videoResponse?.data?.replaceVideoFile || undefined;
            } catch (error) {
                showApolloError(error);

                return;
            }
        }

        const { video, uploadData } = response || {};

        if (!uploadData || !video?.providerRef) return;

        uploadFile(file, video.id, {
            endpoint: uploadData.endpoint,
            headers: {
                AuthorizationSignature: uploadData.signature,
                AuthorizationExpire: `${uploadData.expires}`,
                VideoId: video.providerRef,
                LibraryId: uploadData.libraryId,
            },
            onStartUpload,
            onSuccess,
            onError,
            onAbort: async () => {
                // When the upload is aborted, delete the video when it's a new video
                if (isNewVideo) {
                    await deleteVideo({
                        variables: { id: video.id },
                        update: (cache) => {
                            // Remove video list from cache so it will be refetched
                            cache.evict({ fieldName: 'videos' });

                            // Use writeQuery to update video query cache so it's properly removed
                            cache.writeQuery({
                                query: VideoDocument,
                                data: { video: null },
                                variables: { id },
                            });

                            cache.gc();
                        },
                    });
                }

                onAbort?.();
            },
        });
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return;

        const files = Array.from(fileList);

        // Run function async for each file, so we don't get multiple requests that will cause issues
        // when org has no library yet.
        for (const file of files) {
            await uploadVideo(file);
        }
    };

    if (!isSupported)
        // When tus protocol is not supported by the browser we show an alert
        return (
            <Alert icon={false} severity="error">
                {translate('videoUploadNotSupported')}
            </Alert>
        );
    if (disabled) return <>{children}</>;

    return (
        <Box sx={uploadStyle}>
            <label htmlFor={id}>{children}</label>
            <input
                accept={VIDEO_FILE_TYPE}
                id={id}
                multiple={multiple}
                type="file"
                onChange={handleChange}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    // Empty the value each time you click the input. Else you can not select the same file right after eachother
                    e.currentTarget.value = '';
                }}
            />
        </Box>
    );
};
