import { useMemo, useState } from 'react';

import { IContentTypeValue, useExtraCategoryFiltersQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    getExtraCategoryFilterItems,
    getExtraCategoryQueryParams,
} from 'organisation/utils/extraCategory';
import { TQueryParams } from 'common/utils/getSearchUrl';
import { TFilterBarItem } from 'common/types';
import { getSelectedFilters } from 'common/utils/filter';

export const useExternalContentSearch = () => {
    const { showApolloError } = useApolloError();
    const [searchQueryParam, setSearchQueryParam] = useState('');
    const [queryParams, setQueryParams] = useState<TQueryParams>({});
    const { data, loading } = useExtraCategoryFiltersQuery({
        variables: { contentType: IContentTypeValue.ExternalContent },
        onError: showApolloError,
    });

    const { extraCategoriesFilters } = data || {};

    // Build a list of filters
    const filters = useMemo(
        () => getExtraCategoryFilterItems(extraCategoriesFilters || []),
        [extraCategoriesFilters]
    );

    const selectedFilters = useMemo(
        () => getSelectedFilters(queryParams, filters),
        [queryParams, filters]
    );

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        const queryParams = getExtraCategoryQueryParams(selected);

        setQueryParams(queryParams);
    };

    return {
        filters,
        searchQueryParam: searchQueryParam || '',
        selectedFilters,
        filtersLoading: loading,
        handleSearch,
        handleFilterSelect,
    };
};
