import { Box, SxProps } from '@mui/material';
import { BoxProps } from '@mui/system';

import { withSxProp } from 'common/utils/props';

const labelStyle: SxProps = {
    position: 'absolute',
    left: '8px',
    top: '-10px',
    zIndex: 1,
    px: 0.7,

    borderRadius: '4px',
    backgroundColor: 'background.paper',

    '.MuiTypography-root': {
        fontSize: '1.2rem',
        fontWeight: 700,
    },
};

interface IProps extends BoxProps {
    sx?: SxProps;
}

export const FieldLabel = ({ sx, ...other }: IProps) => (
    <Box sx={[labelStyle, ...withSxProp(sx)]} {...other} />
);
