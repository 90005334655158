import { IExpiryScale } from 'graphql/types';
import { i18n } from 'utils';
import { EXPIRY_SCALES } from 'common/constants/expiryDate';

export function getExpiryDisplay(expiry: number, expiryScale: IExpiryScale) {
    const expiryScaleText = i18n.t(EXPIRY_SCALES[expiryScale], {
        count: expiry,
    });

    return `${expiry} ${expiryScaleText}`;
}
