import { Box, Grid, useMediaQuery, Theme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Field } from 'formik';

import { CARD_IMAGE_MAX_SIZE, CARD_IMAGE_TYPE } from 'common/constants/files';
import { ImageField } from 'common/components/FormField';
import { FormSection } from 'common/components/FormSection';
import { Img } from 'common/components/Img';
import { Banner } from 'common/components/Banner';
import { Link } from 'common/components/Link';
import ModuleCardImage from 'assets/images/module-card-image.webp';

export const ScormImageFormSection = () => {
    const [translate] = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    return (
        <FormSection
            description={
                <Trans
                    components={{
                        a: <Link rel="noreferrer" underline="always" />,
                    }}
                    i18nKey="scormForm.imageDescription"
                />
            }
            title={translate('image')}
        >
            <Box mt={3}>
                <Grid
                    container
                    alignItems="stretch"
                    direction={smallScreen ? 'column-reverse' : 'row'}
                    spacing={2}
                >
                    <Grid item sm xs={12}>
                        <Field
                            acceptedFileTypes={CARD_IMAGE_TYPE}
                            component={ImageField}
                            helperText={translate('imageSizeDescription', {
                                width: 400,
                                height: 150,
                            })}
                            label={translate('image')}
                            maxUploadSize={CARD_IMAGE_MAX_SIZE}
                            name="thumbnail"
                        />
                    </Grid>
                    <Grid item sm xs={12}>
                        <Banner>
                            <Box
                                sx={{
                                    py: 1,
                                    pr: 1,
                                    pl: 2,
                                }}
                            >
                                {translate('scormForm.imageInScormDescription')}
                            </Box>
                            <Box mt="auto">
                                <Img src={ModuleCardImage} />
                            </Box>
                        </Banner>
                    </Grid>
                </Grid>
            </Box>
        </FormSection>
    );
};
