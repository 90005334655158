import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, TableRow, TableBody } from '@mui/material';

import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { OfferEventReportTableRow } from 'offer/components/OfferEventReportTable/OfferEventReportTableRow';
import { IPortfolioItemReportFragment } from 'graphql/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    items: IPortfolioItemReportFragment[];
}

export const OfferEventReportTable = ({ items }: IProps) => {
    const [translate] = useTranslation();
    const { openGlobalDrawer } = useGlobalDrawer();

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <React.Fragment>
                        <TableRow>
                            <TableCell colSpan={3} sx={{ pt: 3 }}>
                                <Typography fontWeight={700} variant="body2">
                                    {translate(
                                        'report.offerEventMaterialTableTitle'
                                    )}
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {items.map((item) => (
                            <OfferEventReportTableRow
                                item={item}
                                key={item.id}
                                onClick={() => {
                                    openGlobalDrawer({
                                        type: IGlobalDrawerType.PortfolioItem,
                                        itemId: item.id,
                                    });
                                }}
                            />
                        ))}
                    </React.Fragment>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
