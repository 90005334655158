import {
    SxProps,
    Switch as MuiSwitch,
    SwitchProps,
    FormControlLabel,
} from '@mui/material';

const formControlLabelStyle: SxProps = {
    '.MuiTypography-root': {
        fontWeight: 700,
        fontSize: 16,
    },
};

const normalSwitchStyle: SxProps = {
    '.MuiSwitch-switchBase': {
        '+ .MuiSwitch-track': {
            backgroundColor: 'primary.light',
        },

        '&.Mui-checked': {
            color: 'primary.main',
        },
    },
};

const largeSwitchStyle: SxProps = {
    width: 90,
    height: 50,

    '.MuiSwitch-track': {
        height: 25,
        backgroundColor: 'grey.400',
        borderRadius: 4,
    },

    '.MuiSwitch-switchBase': {
        top: '3px',
        left: '3px',
    },

    '.MuiSwitch-thumb': {
        width: 35,
        height: 25,
        boxShadow: 'none',
        backgroundColor: 'primary.main',
        borderRadius: 4,
    },

    '.Mui-checked': {
        ':hover': {
            background: 'none',
        },

        '&.MuiSwitch-switchBase': {
            top: '3px',
            left: '14px',

            '+.MuiSwitch-track': {
                backgroundColor: 'grey.400',
            },
        },
    },
};

interface IProps extends Omit<SwitchProps, 'size'> {
    label?: string;
    size?: 'normal' | 'large';
}

export const Switch = ({ label, size = 'normal', ...other }: IProps) => {
    if (size === 'large') {
        return <MuiSwitch sx={largeSwitchStyle} {...other} />;
    }

    return (
        <FormControlLabel
            control={<MuiSwitch sx={normalSwitchStyle} {...other} />}
            label={label}
            sx={formControlLabelStyle}
        />
    );
};
