import { MenuItem, SxProps } from '@mui/material';
import { styled } from 'styled-components';
import { Trans } from 'react-i18next';
import Icon from '@mdi/react';
import * as mdiIcons from '@mdi/js';
import { Check, ChevronRight } from '@mui/icons-material';

import { IMainMenuItem, IMainMenu, IUnreadBadge } from 'graphql/types';
import { MAIN_MENU } from 'common/constants/mainMenu';
import { isStaticBadge } from 'common/utils/isStaticBadge';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Link } from 'common/components/Link';
import { useFrontendPermissions } from 'user/hooks';

import { NavigationItem } from './NavigationItem';

interface IProps {
    className?: string;
    hasIntroduction?: boolean;
    menuItems?: IMainMenuItem[];
}

const SelectedIcon = styled(Check)`
    margin-left: auto;
    padding-left: 5px;
`;

const menuItemStyle: SxProps = {
    py: 0.25,
    pr: 3,
};

const getSubMenuItem = (
    menuItem: IMainMenuItem | null,
    index: number,
    hasIntroduction?: boolean,
    canReadHelpdesk?: boolean
): JSX.Element | null => {
    if (!menuItem) return null;

    const { menu, customText, customUrl, subMenus } = menuItem;

    const menuKey = menu as keyof typeof MAIN_MENU;

    if (menuKey === 'PROFILE_HELPDESK' && !canReadHelpdesk) return null;

    if (!hasIntroduction && menuKey === 'PROFILE_INTRODUCTION') return null;

    const menuItemData = MAIN_MENU[menuKey] || undefined;

    const isCustomItem = menu === IMainMenu.CustomItem;
    if (!menuItemData && !isCustomItem) return null;

    // href is used for customUrl and external links
    const href = customUrl || menuItemData?.href;
    const target = customUrl ? '_blank' : menuItemData?.target;

    let text: string | React.ReactElement | null = customText || null;

    if (menuItemData?.translation) {
        text = <Trans>{menuItemData.translation}</Trans>;
    }

    // If menu contains subMenus we want to render another DropdownMenu
    if (!subMenus || !subMenus.length) {
        return (
            <MenuItem
                color="inherit"
                component={Link}
                href={href}
                key={index}
                sx={menuItemStyle}
                target={target}
                to={!href ? menuItemData?.url : undefined}
                underline="none"
            >
                {text}
            </MenuItem>
        );
    }

    return (
        <DropdownMenu
            anchor={
                <MenuItem>
                    {text}
                    <SelectedIcon as={ChevronRight} fontSize="small" />
                </MenuItem>
            }
            key={index}
            placement="left-start"
        >
            {subMenus.map((item, index) =>
                getSubMenuItem(item, index, hasIntroduction)
            )}
        </DropdownMenu>
    );
};

const BaseNavigation = ({ className, hasIntroduction, menuItems }: IProps) => {
    const { canRead: canReadHelpdesk } = useFrontendPermissions('helpdesk');

    const getMenuItem = (menuItem: IMainMenuItem, index: number) => {
        const { badges, subMenus, menu, customIcon, customText, customUrl } =
            menuItem;

        const subMenuItems =
            menuItem.menu === IMainMenu.Management
                ? []
                : subMenus?.map((item, index) =>
                      getSubMenuItem(
                          item,
                          index,
                          hasIntroduction,
                          canReadHelpdesk
                      )
                  );

        const menuKey = menu as keyof typeof MAIN_MENU;
        const menuItemData = MAIN_MENU[menuKey] || undefined;

        const isCustomItem = [IMainMenu.Custom, IMainMenu.CustomItem].includes(
            menu
        );

        if (!menuItemData && !isCustomItem) return null;

        let text: string | React.ReactElement | null = customText || null;

        if (menuItemData?.translation) {
            text = <Trans>{menuItemData.translation}</Trans>;
        }

        const {
            iconSize,
            iconStyle,
            component: MenuComponent,
            autoSize,
        } = menuItemData || {};

        // Create let of iconPath to allow it to be overriden
        let { iconPath } = menuItemData || {};

        // When custom icon is set we want to override the iconPath.
        if (customIcon) {
            // Override the type of the icons module to allow us to check for icons
            const icons: { [key: string]: string } = mdiIcons;

            // Convert name with hyphens to camel case
            const customIconName = `mdi-${customIcon}`.replace(
                /-([a-z])/g,
                (g) => g[1].toUpperCase()
            );

            // Check if customIcon exists in the icons module
            iconPath = Object.hasOwn(icons, customIconName)
                ? icons[customIconName]
                : icons.mdiAlertCircle;
        }

        const menuComponent = iconPath ? (
            <Icon
                path={iconPath}
                size={iconSize || '2.4rem'}
                style={{ ...iconStyle }}
            />
        ) : (
            MenuComponent && <MenuComponent />
        );

        const badge =
            !!badges && badges.length && !isStaticBadge(badges[0])
                ? (badges[0] as IUnreadBadge)
                : undefined;

        // href is used for customUrl and external links
        const href = customUrl || menuItemData?.href;
        const target = customUrl ? '_blank' : menuItemData?.target;

        if (!subMenuItems || !subMenuItems.length) {
            if (MenuComponent) {
                // Return the custom component if it exists
                return <MenuComponent key={index} />;
            }

            return (
                <NavigationItem
                    autoSize={autoSize}
                    badge={badge}
                    href={href}
                    key={index}
                    target={target}
                    to={menuItemData?.url}
                    tooltipText={text}
                >
                    {menuComponent}
                </NavigationItem>
            );
        }

        return (
            <DropdownMenu
                disableOverflowModifier
                anchor={
                    <NavigationItem badge={badge} key={menu} tooltipText={text}>
                        {menuComponent}
                    </NavigationItem>
                }
                key={index}
                placement="bottom-end"
            >
                {subMenuItems}
            </DropdownMenu>
        );
    };

    if (!menuItems) {
        return null;
    }

    return <div className={className}>{menuItems.map(getMenuItem)}</div>;
};

export const Navigation = styled(BaseNavigation)`
    display: none;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
    }
`;
