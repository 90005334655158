import { IRole, useCanManageUserQuery } from 'graphql/types';
import { useFrontendPermissions, useIsCurrentUser } from 'user/hooks';

/**
 * Hook to check if current user can manage a given user, also returns seperated values for checks that
 * can be used if needed
 *
 * @param userId id for user you want to check if current user can manage
 * @returns {canManageUser: boolean, canUpdate: boolean, isCurrentUser: boolean, loading: boolean}
 */
export const useCanManageUser = (userId?: string) => {
    const {
        canUpdate: canUpdateUsers,
        userRoles,
        loading: loadingPermissions,
    } = useFrontendPermissions('user');

    const {
        isCurrentUser,
        currentUser,
        loading: loadingIsCurrentUser,
    } = useIsCurrentUser(userId);

    const { data: userData, loading: loadingCanManageUser } =
        useCanManageUserQuery({ variables: { id: userId }, skip: !userId });

    if (!userId) {
        return {
            canUpdateUsers: canUpdateUsers,
            canManageUser: false,
            isCurrentUser: false,
            userRoles,
            loading: loadingPermissions,
        };
    }

    const { currentUserIsGroupManager: isGroupManager, roles } =
        userData?.user || {};

    const loading =
        loadingIsCurrentUser || loadingPermissions || loadingCanManageUser;

    const canManageUser = canUpdateUsers || isGroupManager;

    // Check if current user can edit another user
    const currentUserIsManager = currentUser?.roles?.includes(IRole.Manager);
    const currentUserIsOwner = currentUser?.roles?.includes(IRole.Owner);
    const userIsManager = roles?.includes(IRole.Manager);
    const userIsOwner = roles?.includes(IRole.Owner);
    let canEditUser = canManageUser;

    // Can not edit another user that has more permissions than current user
    if (
        !isCurrentUser &&
        ((userIsManager && !currentUserIsManager && !currentUserIsOwner) ||
            (userIsOwner && !currentUserIsOwner))
    ) {
        canEditUser = false;
    }

    return {
        canUpdateUsers,
        canManageUser,
        canEditUser,
        isGroupManager,
        isCurrentUser,
        userRoles,
        loading,
        currentUser,
    };
};
