import { Box, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiCalendar, mdiLaptopAccount, mdiMicrosoftTeams } from '@mdi/js';

import { IOfferEventDateFragment } from 'graphql/types';
import { CalendarMenu } from 'common/components/CalendarMenu';
import { getCalendarDescription } from 'offer/utils/calendar';
import { Link } from 'common/components/Link';
import { getOfferEventDateTime } from 'offer/utils/dates';
import { IconButton } from 'common/components/IconButton';
import { Icon } from 'common/components/Icon';

import { OfferEventDateLocation } from './OfferEventDateLocation';

interface IProps {
    date: IOfferEventDateFragment;
    offerTitle: string;
    canAccessData?: boolean;
    descriptionAfterSubscription?: string;
}

export const OfferEventDate = ({
    date,
    offerTitle,
    canAccessData,
    descriptionAfterSubscription,
}: IProps) => {
    const [translate] = useTranslation();
    const description = getCalendarDescription(
        window.location.href,
        descriptionAfterSubscription,
        date.webinarLink
    );

    const locationDisplayName = date?.location?.title || date.locationString;

    const hasServiceProvider = !!date?.serviceProvider;

    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                sx={{
                    mr: 0.25,
                    mt: -1,
                }}
            >
                {/* If you cannot access the data we just show an icon */}
                {!canAccessData ? (
                    <Box
                        sx={{
                            p: 1,
                            display: 'inline-flex',
                            verticalAlign: 'middle',
                            color: 'inherit',
                        }}
                    >
                        <Icon path={mdiCalendar} size="2.3rem" />
                    </Box>
                ) : hasServiceProvider && date.webinarLink ? (
                    <IconButton
                        component={Link}
                        href={date.webinarLink}
                        iconPath={
                            date.isServiceProviderWebinar
                                ? mdiMicrosoftTeams
                                : mdiLaptopAccount
                        }
                        iconSize="2.3rem"
                        target="_blank"
                    />
                ) : (
                    <CalendarMenu
                        date={{
                            startDate: date.startDate,
                            endDate: date.endDate,
                        }}
                        description={description}
                        extraMenuItems={
                            date?.webinarLink && (
                                <MenuItem
                                    component={Link}
                                    href={date.webinarLink}
                                    target="_blank"
                                >
                                    {translate('openWebinar')}
                                </MenuItem>
                            )
                        }
                        location={locationDisplayName}
                        title={offerTitle}
                    />
                )}
            </Box>
            <Stack spacing={1}>
                <Box>{getOfferEventDateTime(date)}</Box>
                <Box>
                    <OfferEventDateLocation date={date} />
                </Box>
            </Stack>
        </Box>
    );
};
