import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { getUrl } from 'route/utils/getUrl';
import { TRouteName, TParams } from 'route/types';

// List of all urls that are external
const EXTERNAL_URLS = [
    'LOGOUT',
    'LOGIN',
    'USER_CHAT',
    'CHAT',
    'MANAGEMENT_TRAINING',
    'MANAGEMENT_MODULES_TRAININGS',
    'MANAGEMENT_MODULES_COURSES',
    'MANAGEMENT_MODULES_CERTIFICATES',
    'MANAGEMENT_USERS_USERS',
    'MANAGEMENT_USERS_CREATE',
    'MANAGEMENT_USERS_REPORTING',
    'MANAGEMENT_COURSE_REPORTING',
    'MANAGEMENT_SETTINGS_LOCATIONS',
    'MANAGEMENT_SETTINGS_THEME',
    'MANAGEMENT_SETTINGS_FACETS',
    'MANAGEMENT_SETTINGS_STYLE',
    'MANAGEMENT_SETTINGS_CALENDAR',
    'MANAGEMENT_SETTINGS_EMAILS',
    'MANAGEMENT_SETTINGS_CONNECTIONS',
    'MANAGEMENT_SETTINGS_HISTORY',
    'MANAGEMENT_ORGANISATION_ORGANISATION',
    'MANAGEMENT_ORGANISATION_SUBSCRIPTION',
    'MANAGEMENT_ORGANISATION_PAYMENTDETAILS',
    'MANAGEMENT_ORGANISATION_INVOICES',
    'MANAGEMENT_SUBSCRIPTION',
    'TRAINING_PROGRESS',
    'TRAINING_MODULE_GROUP_CONDITIONS',
    'MANAGEMENT_TRAINING_PARTICIPANTS',
    'MANAGEMENT_TRAINING_GROUPS',
    'MANAGEMENT_TRAINING_TRAINERS',
    'MANAGEMENT_GROUPS_USER_CREATE',
    'MANAGEMENT_GROUPS_USER_IMPORT',
    'MANAGEMENT_TRAINING_PARTICIPANT_IMPORT',
    'MANAGEMENT_TRAINING_EDIT_MODULE_GROUP',
    'MANAGEMENT_TRAINING_CREATE_MODULE_GROUP',
];

export interface IProps {
    to?: TRouteName;
    params?: TParams;
    query?: string;
}

export const RouteLink = forwardRef<HTMLAnchorElement, LinkProps & IProps>(
    function BaseRouteLink(
        { to, params, query, onClick, className, children, ...other },
        ref
    ) {
        let toUrl = getUrl(to, params);
        const isHashUrl = toUrl.startsWith('#');

        if (query && !isHashUrl) toUrl += query;

        if (isHashUrl) {
            const newSearchParams = new URLSearchParams(window.location.search);

            toUrl = query
                ? `${query}${toUrl}`
                : `?${newSearchParams.toString()}${toUrl}`;
        }

        const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
            onClick?.(e);
            e.stopPropagation();
        };

        if (EXTERNAL_URLS.includes(to)) {
            const baseUrl = import.meta.env.VITE_BASE_URL || '';

            return (
                <a
                    className={className}
                    href={`${baseUrl}${toUrl}`}
                    onClick={handleOnClick}
                >
                    {children}
                </a>
            );
        }

        return (
            <RouterLink
                className={className}
                onClick={handleOnClick}
                {...other}
                ref={ref}
                to={toUrl}
            >
                {children}
            </RouterLink>
        );
    }
);
