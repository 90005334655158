import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti';
import { useRef, useState } from 'react';

import { showConfetti } from 'common/constants/globalVars';
import { BasePage } from 'hydra/pages/BasePage';
import {
    IFeature,
    IRole,
    ITrainingRoleFilter,
    useCurrentUserQuery,
    useOrganisationQuery,
} from 'graphql/types';
import { PageIntroHeader } from 'common/components/PageIntroHeader';
import { Typography } from 'common/components/Typography';
import { useFrontendPermissions } from 'user/hooks';
import { Link } from 'common/components/Link';
import { PageTitle } from 'common/components/PageTitle';
import { HomePageQuickMenu } from 'common/components/HomePageQuickMenu';
import { Text } from 'common/components/Text';
import { BoxLoader } from 'common/components/Loader';
import { HomePageSection } from 'common/components/HomePageSection';
import { IHomepageSectionStatus } from 'common/components/HomePageSection/HomePageSection';
import { useFeature } from 'common/hooks/useFeature';
import { HomePageNoResults } from 'common/components/HomePageNoResults';
import { ManageButton } from 'common/components/Button/ManageButton';

const DEFAULT_SECTION_STATUS = {
    trainerSection: { loading: true, hasResults: false },
    participantSection: { loading: true, hasResults: false },
};

type TSectionKey = 'trainerSection' | 'participantSection';

interface ISectionStatus {
    trainerSection: IHomepageSectionStatus;
    participantSection: IHomepageSectionStatus;
}

export const HomePage = () => {
    const { t: translate } = useTranslation();

    const { canUpdate: canUpdateOrganisation } =
        useFrontendPermissions('organisation');
    const { canCreate: canCreateTraining } = useFrontendPermissions('training');
    const { canUse: canUseOffers, loading: loadingFeature } = useFeature(
        IFeature.Offers
    );
    const [quickMenuLoading, setQuickMenuLoading] = useState(true);
    const prevSectionStatus = useRef<ISectionStatus>(DEFAULT_SECTION_STATUS);
    const [sectionStatus, setSectionStatus] = useState<ISectionStatus>(
        DEFAULT_SECTION_STATUS
    );
    const { data: organisationData } = useOrganisationQuery();
    const { data: user } = useCurrentUserQuery();

    const { organisation } = organisationData || {};
    const { currentUser } = user || {};

    let header;

    const {
        participantIntroImage,
        participantIntroTitle,
        participantIntroText,
        participantIntroTitleColor,
    } = organisation || {};

    const titleColor =
        participantIntroTitleColor && `#${participantIntroTitleColor}`;

    const currentUserIsManager = currentUser?.roles?.includes(IRole.Manager);
    const currentUserIsAuthor = currentUser?.roles?.includes(IRole.Author);

    const actionButtons = (
        <>
            {(canCreateTraining || canUpdateOrganisation) && (
                <Grid item component={Box} display="flex">
                    {canUpdateOrganisation && (
                        <ManageButton
                            component={Link}
                            outlined={!participantIntroImage}
                            to="MANAGEMENT_ACADEMY"
                        >
                            {translate('manageAcademy')}
                        </ManageButton>
                    )}
                </Grid>
            )}
        </>
    );

    if (participantIntroImage) {
        // Default header color is white when image is set
        const headerColor = titleColor || '#fff';

        header = (
            <PageIntroHeader
                contentContainerMaxWidth="lg"
                image={participantIntroImage}
            >
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item>
                        <Box
                            alignItems="center"
                            component="span"
                            display="flex"
                            flexWrap="wrap"
                        >
                            {participantIntroTitle && (
                                <Box mr={1}>
                                    <Typography
                                        style={{ color: headerColor }}
                                        variant="h1"
                                    >
                                        {participantIntroTitle}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    {actionButtons}
                </Grid>
            </PageIntroHeader>
        );
    }

    const handleSetSectionStatus = (
        section: TSectionKey,
        status: IHomepageSectionStatus
    ) => {
        // Uses the previous section status ref to prevent async overwriting
        const newSectionStatus = {
            ...prevSectionStatus.current,
            [section]: status,
        };

        setSectionStatus(newSectionStatus);

        prevSectionStatus.current = newSectionStatus;
    };

    const userName = currentUser?.name;
    const isTrainerOrManager = !!currentUser?.roles?.includes(IRole.Trainer);

    const hasResults =
        !!sectionStatus.trainerSection.hasResults ||
        !!sectionStatus.participantSection.hasResults;

    const loading =
        quickMenuLoading ||
        (sectionStatus.trainerSection.loading && isTrainerOrManager) ||
        sectionStatus.participantSection.loading ||
        loadingFeature;

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            {showConfetti() && (
                <Confetti
                    confettiSource={{
                        x: 0,
                        y: 80,
                        w: window.innerWidth,
                        h: 0,
                    }}
                    height={window.innerHeight}
                    numberOfPieces={300}
                    recycle={false}
                    run={!loading}
                    width={window.innerWidth}
                    onConfettiComplete={() => {
                        showConfetti(false);
                    }}
                />
            )}

            <PageTitle mixpanelTitle="Homepage">
                {translate('homeBrowserTitle')}
            </PageTitle>

            <Box sx={{ mt: !participantIntroImage ? 4 : 0 }}>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={2}
                >
                    {!header && participantIntroTitle && (
                        <Grid item>
                            <Box mb={1}>
                                <Typography
                                    style={{
                                        color: titleColor || undefined,
                                    }}
                                    variant="h1"
                                >
                                    {participantIntroTitle}
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {!header && (
                        <Grid item>
                            <Box mb={1}>{actionButtons}</Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Stack gap={4} sx={{ mt: 4 }}>
                <HomePageQuickMenu setLoading={setQuickMenuLoading} />

                {!!participantIntroText && (
                    <Text variant="body1">{participantIntroText}</Text>
                )}

                {loading && <BoxLoader />}

                <Box
                    sx={{
                        visibility: loading ? 'hidden' : 'visible',
                    }}
                >
                    {!quickMenuLoading && isTrainerOrManager && (
                        <HomePageSection
                            trainer
                            canUseOffers={canUseOffers}
                            sectionTitle={translate('homeTrainerSectionTitle')}
                            setStatus={(status) =>
                                handleSetSectionStatus('trainerSection', status)
                            }
                            trainingRole={ITrainingRoleFilter.Trainer}
                        />
                    )}

                    {!quickMenuLoading && (
                        <HomePageSection
                            canUseOffers={canUseOffers}
                            sectionTitle={
                                <>
                                    {/* Only get first name of user */}
                                    {userName && `${userName.split(' ')[0]}, `}
                                    {translate('homeInProgressSectionTitle')}
                                </>
                            }
                            setStatus={(status) =>
                                handleSetSectionStatus(
                                    'participantSection',
                                    status
                                )
                            }
                            trainingRole={ITrainingRoleFilter.Participant}
                        />
                    )}
                </Box>
            </Stack>

            {!loading && !hasResults && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 6 }}>
                    <HomePageNoResults
                        canManage={currentUserIsManager || currentUserIsAuthor}
                        canUseOffers={canUseOffers}
                    />
                </Box>
            )}
        </BasePage>
    );
};
