import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlanDemo } from 'common/components/PlanDemo';
import { BasePage } from 'hydra/pages/BasePage';
import { PlanSelection } from 'plans/components/PlanSelection';
import { Loader } from 'common/components/Loader';
import { PageTitle } from 'common/components/PageTitle';

export const PlansPage = () => {
    const [loading, setLoading] = useState(true);
    const { t: translate } = useTranslation();

    return (
        <BasePage contentContainerMaxWidth="lg">
            {loading && <Loader />}

            <PageTitle mixpanelTitle="Plans page">
                {translate('upgradePageTitle')}
            </PageTitle>

            <Box sx={{ visibility: loading ? 'hidden' : undefined, mt: 8 }}>
                <PlanSelection onLoadingDone={() => setLoading(false)} />

                <Box mt={4}>
                    <PlanDemo />
                </Box>
            </Box>
        </BasePage>
    );
};
