import { IScormListItemFragment } from 'graphql/types';

export function isScormSelected(
    selectedScorms: IScormListItemFragment[],
    scorm: IScormListItemFragment
) {
    return selectedScorms.some(
        (selectedScorm) => selectedScorm.id === scorm.id
    );
}

export function isScormAdded(
    addedScorms: IScormListItemFragment[],
    scorm: IScormListItemFragment
) {
    return addedScorms.some((addedScorm) => addedScorm.id === scorm.id);
}

export function getScormSelectStatus(
    selectedScorms: IScormListItemFragment[],
    addedScorms: IScormListItemFragment[],
    scorm: IScormListItemFragment
) {
    const isSelected = isScormSelected(selectedScorms, scorm);
    const isAdded = isScormAdded(addedScorms, scorm);

    return { isSelected, isAdded };
}
