import { Box, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field, FieldArray, useFormikContext } from 'formik';
import { mdiClose, mdiPlus } from '@mdi/js';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { IconSelect, LinkField, TextField } from 'common/components/FormField';
import { ICustomMenuItemInput } from 'graphql/types';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import { Typography } from 'common/components/Typography';

import { TOrganisationSettingsFormValues } from './OrganisationSettingsForm';

export const CustomMenuForm = () => {
    const [translate] = useTranslation();
    const { values } = useFormikContext<TOrganisationSettingsFormValues>();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="academySettingsForm.customMenuDescription"
                    />
                </Box>
            }
            title={translate('academySettingsForm.customMenuTitle')}
        >
            <Box mt={4}>
                {!!values.customMenuItems.length && (
                    <Box mb={1}>
                        <Field component={IconSelect} name="customMenuIcon" />
                    </Box>
                )}

                <FieldArray
                    name="customMenuItems"
                    render={(arrayHelpers) => {
                        const customMenuItems = arrayHelpers.form.values
                            .customMenuItems as ICustomMenuItemInput[];

                        return (
                            <Box>
                                {customMenuItems?.map((_field, index) => (
                                    <Grid
                                        container
                                        key={`menuItemRow-${index}`}
                                        spacing={1}
                                    >
                                        <Grid item sm={5} xs={12}>
                                            <Field
                                                component={TextField}
                                                label={translate('menuItem')}
                                                name={`customMenuItems[${index}].text`}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={10}>
                                            <LinkField
                                                label={translate('url')}
                                                name={`customMenuItems[${index}].url`}
                                                sx={{ mt: { xs: 0, sm: 2 } }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            sm={1}
                                            sx={{
                                                mt: { xs: '5px', sm: 2.5 },
                                                pl: 1,
                                            }}
                                            xs={2}
                                        >
                                            <IconButton
                                                iconPath={mdiClose}
                                                iconSize="2.4rem"
                                                size="large"
                                                onClick={() => {
                                                    arrayHelpers.remove(index);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        alignSelf: 'flex-start',
                                        mt: 2,
                                    }}
                                    onClick={() => {
                                        arrayHelpers.push({
                                            text: '',
                                            url: '',
                                        });
                                    }}
                                >
                                    <ActionButton outlined component={Box}>
                                        <Icon path={mdiPlus} />
                                    </ActionButton>

                                    <Box ml={2}>
                                        <Typography>
                                            {translate('addMenuItem')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    }}
                />
            </Box>
        </FormSection>
    );
};
