import { useTranslation } from 'react-i18next';

import { IConditionFragment } from 'graphql/types';
import { formatDate } from 'common/utils/formatDate';
import {
    parseConditions,
    courseConditionsToString,
    absoluteDateToString,
} from 'common/utils/parseConditions';

interface IProps {
    startDate?: string;
    endDate?: string;
    deadline?: string;
    conditions: IConditionFragment[];
}

export const ConditionsDescriptionStudent = ({
    startDate,
    endDate,
    deadline: deadlineISO,
    conditions,
}: IProps) => {
    const [translate] = useTranslation();

    if (!conditions.length) return null;

    // Parse condition first to get object with better structure.
    const { courseConditions } = parseConditions(conditions);

    let conditionsArray: React.ReactNode[] = [];

    // If startDate is given we want to add startDate with optionally an endDate
    if (startDate) {
        const absoluteDate = absoluteDateToString(startDate, endDate);
        conditionsArray.push(absoluteDate);
    }

    // Course conditions
    if (courseConditions.length) {
        conditionsArray = [
            ...conditionsArray,
            courseConditionsToString(courseConditions),
        ];
    }

    // If deadline is given we want to append it.
    if (deadlineISO) {
        const deadlineDate = formatDate(deadlineISO, 'd MMMM yyyy HH:mm', true);

        conditionsArray.push(
            translate('conditionString.deadlineDate', { deadlineDate })
        );
    }

    return <>{conditionsArray.join(', ')}</>;
};
