export function removeEmptyFromObject<
    T extends Record<string, undefined | null | unknown>,
>(obj: T): T {
    return Object.fromEntries(
        Object.entries(obj).filter(
            ([_, v]) =>
                v !== null &&
                v !== undefined &&
                v !== '' &&
                !(Array.isArray(v) && v.length === 0)
        )
    ) as T;
}
