export interface IDeleteAlertDialogOptions {
    show: boolean;
    callback?(): void;
    title: string;
    text?: string;
}

export const INITIAL_DELETE_ALERT_DIALOG_VALUES = {
    show: false,
    callback: undefined,
    title: '',
};
