import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

import {
    IModuleFragment,
    IModuleType,
    useDeleteModuleMutation,
    useModuleEditQuery,
    useUpdateAfasModuleMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleUpdateDrawerProps } from 'training/types';
import { Loader } from 'common/components/Loader';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import {
    AfasWebhookModuleForm,
    IAfasWebhookModuleFormProps,
    IAfasType,
} from 'training/components/forms/AfasWebhookModuleForm';

export const AfasWebhookModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.Afas },
        onError: showApolloError,
    });

    const [updateModule] = useUpdateAfasModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    const module = data?.module;

    if (loading || !module) return <Loader />;

    if (!module || module.__typename !== 'AfasWebhookModule') return null;

    const handleSubmit: IAfasWebhookModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { afasType, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            updateAfasSubscription: afasType === IAfasType.Subscription,
            updateAfasCourse: afasType === IAfasType.Course,
        };

        try {
            await updateModule({
                variables: { id, module: moduleValues },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.afasWebhook.update'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.Afas,
                },
                update: (cache) => {
                    cache.modify({
                        id: cache.identify(module.moduleGroup),
                        fields: {
                            modules(existingModuleRefs, { readField }) {
                                return existingModuleRefs.filter(
                                    (moduleRef: IModuleFragment) =>
                                        id !== readField('id', moduleRef)
                                );
                            },
                        },
                    });

                    cache.evict({ id: `AfasWebhookModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.afasWebhook.delete'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const {
        updateAfasSubscription,
        afasCourseOrEventCode,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        afasType: updateAfasSubscription
            ? IAfasType.Subscription
            : IAfasType.Course,
        afasCourseOrEventCode,
    };

    return (
        <>
            <PageTitle>{translate('updateAfasWebhookModule')}</PageTitle>

            <AfasWebhookModuleForm
                edit
                initialValues={initialValues}
                moduleGroupId={moduleGroupId}
                onDelete={() => setShowDeleteAlert(true)}
                onSubmit={handleSubmit}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteAfasWebhookModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteAfasWebhookModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
