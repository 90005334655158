import { IRole } from 'graphql/types';
import { translate } from 'utils/i18n';

export const ROLE_TRANSLATIONS = {
    [IRole.Owner]: translate('roles.owner'),
    [IRole.Manager]: translate('roles.manager'),
    [IRole.Planner]: translate('roles.planner'),
    [IRole.Author]: translate('roles.author'),
    [IRole.Trainer]: translate('roles.trainer'),
};
