import { useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { useFragment } from '@apollo/client';

import {
    IOpenOffsetType,
    ICreateScormModuleInput,
    IFile,
    IScormModuleEditFragment,
    ScormListItemFragmentDoc,
    IModuleWeight,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { FileField, TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { SCORM_FILE_MAX_SIZE, SCORM_FILE_TYPES } from 'common/constants/files';
import { ScormFormItem } from 'training/components/ScormFormItem';
import { ModuleWeightFormSection } from 'training/components/ModuleWeightFormSection';

export interface IScormModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateScormModuleInput, 'imageId' | 'offset'> {
    file?: IFile;
}

export interface IProps {
    initialValues?: Partial<IScormModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    moduleGroupId?: string;
    scorm?: IScormModuleEditFragment['scorm'];
    scormId?: string;
    onSubmit(values: IScormModuleFormValues): void;
    onDelete?(): void;
    onReplaceScorm?(): void;
}

export const ScormModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    scorm,
    scormId,
    onSubmit,
    onDelete,
    onReplaceScorm,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const { data: scormData } = useFragment({
        fragment: ScormListItemFragmentDoc,
        from: `Scorm:${scormId}`,
        fragmentName: 'ScormListItem',
    });

    const selectedScorm = !!Object.keys(scormData).length ? scormData : scorm;

    const initValues: IScormModuleFormValues = {
        title: '',
        scormId: '',
        description: '',
        weight: IModuleWeight.Weight1,
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
        ...initialValues,
    };

    // If a videoId is passed we want to use that instead of the one from initialValues
    const selectedScormId = scormId || initialValues?.scormId;
    const scormChanged = selectedScormId !== initialValues?.scormId;

    const formTitle = edit
        ? translate('moduleForm.scorm.editTitle')
        : translate('moduleForm.scorm.newTitle');
    const formText = edit
        ? translate('moduleForm.scorm.editText')
        : translate('moduleForm.scorm.newText');
    const submitText = edit
        ? translate('moduleForm.scorm.editButton')
        : translate('moduleForm.scorm.newButton');

    const showFileUploadField = !edit && !selectedScorm;

    const permissions = !!selectedScorm?.permissions
        ? JSON.parse(selectedScorm.permissions)
        : undefined;

    const canUpdateScorm = !!permissions?.canUpdate;

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={(values) => {
                const newValues = values;

                if (scormChanged) {
                    newValues.scormId = selectedScormId || '';
                }

                return onSubmit(newValues);
            }}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        {formTitle && formText && (
                            <Box mb={2}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {showFileUploadField && (
                            <Field
                                acceptedFileTypes={SCORM_FILE_TYPES}
                                component={FileField}
                                helperText={translate(
                                    'moduleForm.scorm.fileHelperText',
                                    {
                                        count: SCORM_FILE_MAX_SIZE,
                                    }
                                )}
                                label={translate('moduleForm.scorm.file')}
                                maxUploadSize={SCORM_FILE_MAX_SIZE}
                                name="file"
                            />
                        )}

                        <Box mb={2} mt={2}>
                            <ScormFormItem
                                isEditable={canUpdateScorm}
                                scorm={selectedScorm}
                                onReplaceScorm={onReplaceScorm}
                            />

                            {!selectedScorm && scormChanged && (
                                <FormHelperText error>
                                    {translate(
                                        'moduleForm.scorm.scormIsRequired'
                                    )}
                                </FormHelperText>
                            )}
                        </Box>

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.scorm.overrideDescription"
                                        />
                                    }
                                    title={translate('titleOverride')}
                                >
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />

                                    <Field
                                        multiline
                                        component={TextField}
                                        label={translate('description')}
                                        name="description"
                                    />
                                </FormSection>

                                <ModuleWeightFormSection />

                                <ModuleImageFormSection />

                                {/* Only show conditions section when we have a module group id */}
                                {moduleGroupId && (
                                    <ModuleOffsetFormSection
                                        moduleGroupId={moduleGroupId}
                                        offsetValues={
                                            values.offset || undefined
                                        }
                                    />
                                )}
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting &&
                                            !disabled &&
                                            !dirty &&
                                            !scormChanged)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
