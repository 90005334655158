import { RadioGroup as MuiRadioGroup, RadioGroupProps } from 'formik-mui';
import { FormControl, FormHelperText, FormLabel, SxProps } from '@mui/material';
import { FieldProps, getIn } from 'formik';

const radioGroupStyle: SxProps = {
    '.MuiFormGroup-root': { display: 'flex', flexDirection: 'row' },
};

interface IProps extends Omit<RadioGroupProps, 'onChange'>, FieldProps {
    helperText?: React.ReactNode;
    label?: React.ReactNode;
    onChange?: (name: string, value: string) => void;
}

export const RadioGroup = ({
    label,
    helperText,
    className,
    onChange,
    ...other
}: IProps) => {
    const {
        field,
        form: { touched, errors, setFieldValue },
    } = other;

    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    const handleChange = (
        _event: React.ChangeEvent<HTMLInputElement>,
        value: string
    ) => {
        onChange?.(field.name, value);
        setFieldValue(field.name, value);
    };

    return (
        <FormControl
            fullWidth
            className={className}
            color="primary"
            error={showError}
            margin="normal"
            sx={radioGroupStyle}
            variant="standard"
        >
            {label && <FormLabel>{label}</FormLabel>}

            <MuiRadioGroup color="primary" onChange={handleChange} {...other} />

            {(showError || helperText) && (
                <FormHelperText>{fieldError || helperText}</FormHelperText>
            )}
        </FormControl>
    );
};
