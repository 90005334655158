import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { UserTable } from 'user/components/UserTable';
import { useUserTableData } from 'user/hooks/useUserTableData';
import { useUserList } from 'user/hooks/useUserList';

interface IProps extends IPageDrawerProps {
    addButtonTranslationKeys?: {
        noSelection?: string;
        selection?: string;
    };
    addedUsers?: IUserListItemFragment[];
    selectorQueryVariables?: IUserSelectorInput;
    disabledUserIds?: string[];
    footerAppend?: React.ReactNode;
    loading?: boolean;
    noResultsLabel?: string;
    paginationTranslationKey?: string;
    usersCount?: number;
    onAddUsers(
        selectedUsers: string[],
        selectableUsers?: IUserListItemFragment[]
    ): void;
    onDrawerOpen(open: boolean): void;
}

export const UserSelectDrawer = ({
    addButtonTranslationKeys,
    addedUsers = [],
    selectorQueryVariables,
    footerAppend,
    loading,
    noResultsLabel,
    paginationTranslationKey,
    open,
    title,
    onAddUsers,
    onDrawerOpen,
}: IProps) => {
    const { t: translate } = useTranslation();
    const {
        loading: loadingUserData,
        users,
        alreadyAddedUsers,
        tableDataProps,
    } = useUserTableData(
        addedUsers,
        useUserList,
        !open,
        undefined,
        selectorQueryVariables
    );
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const footerAppendRef = useRef<HTMLDivElement>();
    const theme = useTheme();

    const { searchProps, paginationProps, selectionProps } = tableDataProps;
    const { handleSearch, handleFilterSelect } = searchProps || {};
    const { setPage } = paginationProps || {};
    const { selected, onSelectAll } = selectionProps || {};

    const resetSettings = () => {
        handleSearch('');
        handleFilterSelect([]);
        setPage?.(1);
        setSelectedUserIds([]);

        // Deselect all when there are selected items
        if (selected?.length) onSelectAll();
    };

    const footerAppendHeight =
        footerAppendRef.current?.getBoundingClientRect().height;
    let listPaddingBottom = theme.spacing(13);

    if (!!footerAppendHeight) {
        listPaddingBottom = `calc(${listPaddingBottom} + ${footerAppendHeight}px)`;
    }

    return (
        <PageDrawer
            open={open}
            title={title || translate('addUsersTitle')}
            onClose={() => {
                onDrawerOpen(false);

                resetSettings();
            }}
        >
            <Box
                sx={{
                    pb: listPaddingBottom,
                    pt: { xs: 2, sm: 4 },
                    px: { xs: 2, sm: 4 },
                }}
            >
                <UserTable
                    inDrawer
                    disabled={loading || loadingUserData}
                    disabledUserIds={alreadyAddedUsers}
                    loading={loadingUserData}
                    noResultsLabel={noResultsLabel}
                    paginationProps={paginationProps}
                    paginationTranslationKey={paginationTranslationKey}
                    searchProps={searchProps}
                    selectionProps={selectionProps}
                    users={users}
                    onSelectUsers={setSelectedUserIds}
                />
            </Box>

            <PageDrawerFooter sx={{ zIndex: 2 }}>
                <Box display="flex" flexDirection="column">
                    {!!footerAppend && (
                        <Box ref={footerAppendRef}>{footerAppend}</Box>
                    )}

                    <Button
                        color="primary"
                        disabled={loading || !selected?.length}
                        loading={loading}
                        variant="contained"
                        onClick={async () => {
                            await onAddUsers(selectedUserIds, users);

                            resetSettings();
                        }}
                    >
                        {!selected?.length
                            ? translate(
                                  addButtonTranslationKeys?.noSelection ||
                                      'addUsersTitle'
                              )
                            : translate(
                                  addButtonTranslationKeys?.selection ||
                                      'addUsers',
                                  {
                                      count: selected.length,
                                  }
                              )}
                    </Button>
                </Box>
            </PageDrawerFooter>
        </PageDrawer>
    );
};
