import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import {
    FontSelect,
    FontWrapper,
} from 'common/components/FormField/FontSelect';
import { FormSection } from 'common/components/FormSection';
import { Typography } from 'common/components/Typography';
import { Link } from 'common/components/Link';
import { IFontWeight } from 'graphql/types';
import { TFontWeightOption, TFontWeightOptions } from 'common/types';

import { TOrganisationSettingsFormValues } from './OrganisationSettingsForm';
import { CustomFontDrawer } from './CustomFontDrawer';

export const FontForm = () => {
    const { t: translate } = useTranslation();
    const { values, setFieldValue } =
        useFormikContext<TOrganisationSettingsFormValues>();
    const [customFontDrawerOpen, setCustomFontDrawerOpen] = useState(false);

    const handleChange = (
        familyFieldName: string,
        familyFieldValue: string,
        fieldName: string,
        defaultWeight: IFontWeight,
        fontWeightOptions: TFontWeightOptions
    ) => {
        const newFontWeights = fontWeightOptions[familyFieldValue];

        // Check if default weight is an option on the selected font else use the first option
        const fontWeightDefault = newFontWeights.some(
            (weightOption) => weightOption.value === defaultWeight
        )
            ? defaultWeight
            : newFontWeights[0].value;

        // Set font family
        setFieldValue(familyFieldName, familyFieldValue);
        // Set font weight based on font its weight options
        setFieldValue(fieldName, fontWeightDefault);
    };

    return (
        <>
            <FormSection
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey="academySettingsForm.fontSettingsDescription"
                        />
                    </Box>
                }
                title={translate('academySettingsForm.fontSettingsTitle')}
            >
                <Box>
                    <FontWrapper>
                        {(
                            fontFamilyOptions,
                            fontSizeOptions,
                            fontWeightOptions
                        ) => {
                            const { typography } = values || {};
                            const { body, title, subtitle } = typography || {};

                            let bodyFontWeightOptions: TFontWeightOption[] = [];

                            if (body?.fontFamily) {
                                bodyFontWeightOptions =
                                    fontWeightOptions[body.fontFamily] || [];
                            }

                            let titleFontWeightOptions: TFontWeightOption[] =
                                [];

                            if (title?.fontFamily) {
                                titleFontWeightOptions =
                                    fontWeightOptions[title.fontFamily] || [];
                            }

                            let subtitleFontWeightOptions: TFontWeightOption[] =
                                [];

                            if (subtitle?.fontFamily) {
                                subtitleFontWeightOptions =
                                    fontWeightOptions[subtitle.fontFamily] ||
                                    [];
                            }

                            return (
                                <Box mt={3}>
                                    <Box mb={2}>
                                        <FontSelect
                                            familyOptions={fontFamilyOptions}
                                            labels={{
                                                family: translate('general'),
                                                size: translate('size'),
                                                weight: translate('weight'),
                                            }}
                                            names={{
                                                family: 'typography.body.fontFamily',
                                                size: 'typography.body.size',
                                                weight: 'typography.body.weight',
                                            }}
                                            sizeOptions={fontSizeOptions}
                                            weightOptions={
                                                bodyFontWeightOptions
                                            }
                                            onChange={(name, value) => {
                                                handleChange(
                                                    name,
                                                    value,
                                                    'typography.body.weight',
                                                    IFontWeight.Normal,
                                                    fontWeightOptions
                                                );
                                            }}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <FontSelect
                                            familyOptions={fontFamilyOptions}
                                            labels={{
                                                family: translate('title'),
                                                size: translate('size'),
                                                weight: translate('weight'),
                                            }}
                                            names={{
                                                family: 'typography.title.fontFamily',
                                                size: 'typography.title.size',
                                                weight: 'typography.title.weight',
                                            }}
                                            sizeOptions={fontSizeOptions}
                                            weightOptions={
                                                titleFontWeightOptions
                                            }
                                            onChange={(name, value) => {
                                                handleChange(
                                                    name,
                                                    value,
                                                    'typography.title.weight',
                                                    IFontWeight.Bold,
                                                    fontWeightOptions
                                                );
                                            }}
                                        />
                                    </Box>

                                    <FontSelect
                                        familyOptions={fontFamilyOptions}
                                        labels={{
                                            family: translate('subtitle'),
                                            size: translate('size'),
                                            weight: translate('weight'),
                                        }}
                                        names={{
                                            family: 'typography.subtitle.fontFamily',
                                            size: 'typography.subtitle.size',
                                            weight: 'typography.subtitle.weight',
                                        }}
                                        sizeOptions={fontSizeOptions}
                                        weightOptions={
                                            subtitleFontWeightOptions
                                        }
                                        onChange={(name, value) => {
                                            handleChange(
                                                name,
                                                value,
                                                'typography.subtitle.weight',
                                                IFontWeight.Bold,
                                                fontWeightOptions
                                            );
                                        }}
                                    />
                                </Box>
                            );
                        }}
                    </FontWrapper>
                </Box>

                <Box mt={2}>
                    <Typography fontSize="1.6rem">
                        <Trans
                            components={{
                                a: (
                                    <Link
                                        rel="noreferrer"
                                        sx={{
                                            color: 'primary.main',
                                            cursor: 'pointer',
                                        }}
                                        underline="always"
                                        onClick={() =>
                                            setCustomFontDrawerOpen(true)
                                        }
                                    />
                                ),
                            }}
                            i18nKey="academySettingsForm.fontSettingsCustom"
                        />
                    </Typography>
                </Box>
            </FormSection>

            <CustomFontDrawer
                open={customFontDrawerOpen}
                onClose={() => setCustomFontDrawerOpen(false)}
            />
        </>
    );
};
