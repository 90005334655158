import { useLocation } from 'react-router-dom';

import { IOfferCardFragment } from 'graphql/types';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    CardSubtitle,
    CardTitle,
} from 'common/components/Card';
import DEFAULT_OFFERCARD from 'assets/images/default-offercard.webp';
import { ChipGroup } from 'common/components/Chip';
import { getOfferDateDisplay } from 'offer/utils/dates';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { RouteLink } from 'route/components/RouteLink';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { Button } from 'common/components/Button';

interface IProps {
    offer: IOfferCardFragment;
    isAuthor?: boolean;
}

export const OfferCard = ({ offer }: IProps) => {
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const {
        id,
        image,
        title,
        firstDate,
        eventCount,
        extraCategoryStringLabels,
        detailEventId,
    } = offer;

    let cardSubtitle;

    if (firstDate) {
        const dateDisplay = getOfferDateDisplay(firstDate, eventCount);

        cardSubtitle = <CardSubtitle>{dateDisplay}</CardSubtitle>;
    }

    const hasChips = !!extraCategoryStringLabels?.length;

    const cardImage = image?.url || DEFAULT_OFFERCARD;

    const urlProps = detailEventId
        ? {} // When page has detail event id we open the offer detail global drawer directly.
        : {
              to: 'OFFER_DETAIL',
              params: {
                  id,
              },
          };

    return (
        <Card>
            <CardActionArea
                component={detailEventId ? Button : RouteLink}
                onClick={
                    detailEventId
                        ? () => {
                              openGlobalDrawer(
                                  {
                                      type: IGlobalDrawerType.Offer,
                                      itemId: id,
                                  },
                                  location
                              );
                          }
                        : undefined
                }
                {...urlProps}
            >
                <CardMedia image={cardImage} />

                <CardContent>
                    <CardTitle>{title}</CardTitle>
                    {cardSubtitle}
                </CardContent>

                {hasChips && (
                    <CardActions>
                        <ChipGroup>
                            <ExtraCategoryLabels
                                extraCategoryValues={extraCategoryStringLabels}
                            />
                        </ChipGroup>
                    </CardActions>
                )}
            </CardActionArea>
        </Card>
    );
};
