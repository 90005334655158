import { useTranslation } from 'react-i18next';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import {
    LtiPlatformFragmentDoc,
    useCreateLtiPlatformMutation,
    useLtiConfigQuery,
} from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { TLTIPlatformFormValues } from 'connections/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

import { LTIPlatformForm } from '../forms/LTIPlatformForm';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const LTIPlatformCreateDrawer = ({ onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const { data: lTIConfigUrlsData, loading: loadingConfigUrls } =
        useLtiConfigQuery();
    const [createLTIPlatform, { loading: loadingCreate }] =
        useCreateLtiPlatformMutation();

    const handleSubmit = async (values: TLTIPlatformFormValues) => {
        const {
            courses,
            trainings,
            ltiAuthenticateUrl,
            ltiLaunchUrl,
            ltiJwksUrl,
            deploymentIds,
            maxUsers,
            allowAllMaterial,
            ...otherValues
        } = values;

        const newValues = {
            ...otherValues,
            courseIds: allowAllMaterial
                ? undefined
                : values.courses.map((course) => course.id),
            trainingIds: allowAllMaterial
                ? undefined
                : values.trainings.map((training) => training.id),
            // Stringify deploymentIds to match the type of the field and also remove spaces
            deploymentIds: !!deploymentIds
                ? JSON.stringify(deploymentIds.replace(/\s+/g, '').split(','))
                : '[]',
            maxUsers: +maxUsers || 0,
            allowAllMaterial,
        };

        try {
            await createLTIPlatform({
                variables: { ltiPlatform: newValues },
                update: (cache, result) => {
                    const newLTIPlatform =
                        result.data?.createLtiPlatform?.ltiPlatform;

                    if (!newLTIPlatform) return;

                    cache.modify({
                        fields: {
                            ltiPlatforms(existingPlatforms = []) {
                                const newPlatformRef = cache.writeFragment({
                                    fragment: LtiPlatformFragmentDoc,
                                    fragmentName: 'LTIPlatform',
                                    data: newLTIPlatform,
                                });

                                return [...existingPlatforms, newPlatformRef];
                            },
                        },
                    });
                },
            });

            displaySnackbar(translate('lTIPlatformActionSuccess.create'), {
                variant: 'success',
            });

            onClose?.();
        } catch (error) {
            showApolloError(error);

            return;
        }
    };

    const lTIConfigUrls = lTIConfigUrlsData?.organisation
        ? lTIConfigUrlsData?.organisation
        : undefined;

    return (
        <PageDrawer onClose={onClose} {...other}>
            <PageTitle mixpanelTitle="Drawer LTI Platform form">
                {translate('lTIPlatformForm.pageTitle')}
            </PageTitle>

            {loadingConfigUrls && <Loader />}

            {!loadingConfigUrls && (
                <LTIPlatformForm
                    disabled={loadingCreate}
                    lTIConfigUrls={lTIConfigUrls}
                    onSubmit={handleSubmit}
                />
            )}
        </PageDrawer>
    );
};
