import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerModuleSection } from 'common/components/DrawerModuleSection';
import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { useApolloError } from 'common/hooks/useApolloError';
import { getExtraFieldInitialEmptyValues } from 'common/utils/userExtraFields';
import {
    ICreateUserInput,
    IFileFragment,
    IGroupListItemFragment,
    ILanguage,
    IUserNotifications,
    useCreateUserMutation,
    useExtraUserFieldsQuery,
    useOrganisationQuery,
} from 'graphql/types';
import { UserCreateForm } from 'user/components/forms/UserCreateForm';
import { UserPreview } from 'user/components/UserPreview';
import { EGroupUserRole, ICreateUserFormValues } from 'user/types';
import { getExtraFieldsName } from 'common/utils/extraFields';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getAddedGroupIds } from 'user/utils/groups';

interface IUserFormValues extends ICreateUserFormValues {
    participantGroups?: IGroupListItemFragment[];
    managerGroups?: IGroupListItemFragment[];
}

interface IProps extends IPageDrawerProps {
    onCreate?(userId?: string, formValues?: IUserFormValues): void;
    groupId?: string;
    groupUserRole?: EGroupUserRole;
    forTraining?: boolean;
}

export const UserCreateDrawer = ({
    onCreate,
    groupId,
    groupUserRole,
    forTraining,
    ...other
}: IProps) => {
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [translate] = useTranslation();
    const { data: extraUserFieldsData, loading: extraUserFieldsLoading } =
        useExtraUserFieldsQuery({
            onError: showApolloError,
        });
    const [profileImage, setProfileImage] = useState<IFileFragment>();
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [submittedValues, setSubmittedValues] =
        useState<ICreateUserFormValues>();
    const [createUser, { loading: createUserLoading }] =
        useCreateUserMutation();
    const { data: organisationData, loading: organisationLoading } =
        useOrganisationQuery();
    const { closeGlobalDrawer } = useGlobalDrawer();

    const organisation = organisationData?.organisation;
    const extraUserFields = extraUserFieldsData?.extraUserFields || [];

    const initialValues: IUserFormValues = !!submittedValues
        ? submittedValues
        : {
              name: '',
              email: '',
              roles: [],
              // Set initial value for sendInvite based on if forTraining is given/false
              sendInvite: !forTraining,
              sendTrainingInvite: forTraining,
              notifications: IUserNotifications.Immediate,
              automatedMessages: true,
              language: organisation?.language || ILanguage.En,
              addParticipantGroups: [],
              addManagerGroups: [],
              ...getExtraFieldInitialEmptyValues(extraUserFields),
          };

    const handleSubmit = async (userFormValues: IUserFormValues) => {
        const { sendInvite, sendTrainingInvite, ...formValues } =
            userFormValues;
        let userValues = { ...formValues };

        if (extraUserFields.length) {
            const extraFields: { [key: string]: string | Date } = {};

            extraUserFields.forEach((field) => {
                const fieldKey = getExtraFieldsName(
                    field.name
                ) as keyof ICreateUserInput;

                // Retransform field name to its original field name
                extraFields[field.name] =
                    formValues[fieldKey as keyof ICreateUserInput];

                delete userValues[fieldKey];
            });

            if (Object.keys(extraFields).length) {
                userValues = {
                    ...userValues,
                    extraFields: JSON.stringify(extraFields),
                };
            }
        }

        try {
            const { participantGroups, managerGroups, ...otherUserValues } =
                userValues;

            let addParticipantGroups = getAddedGroupIds(
                initialValues.participantGroups || [],
                participantGroups || []
            );

            let addManagerGroups = getAddedGroupIds(
                initialValues.managerGroups || [],
                managerGroups || []
            );

            // If directly added to a group override the user group values
            if (!!groupId && !!groupUserRole) {
                if (groupUserRole === EGroupUserRole.Participant) {
                    addParticipantGroups = [groupId];
                } else {
                    addManagerGroups = [groupId];
                }
            }

            const reponse = await createUser({
                variables: {
                    user: {
                        ...otherUserValues,
                        profileImage: profileImage?.id,
                        addParticipantGroups,
                        addManagerGroups,
                    },
                    sendInvite,
                },
            });

            const userId = reponse.data?.createUser?.user?.id;

            onCreate?.(userId, userFormValues);
        } catch (error) {
            showApolloError(error);

            // Save submitted values. If backend throws an error we do not want to lose the values
            setSubmittedValues(userFormValues);

            return;
        }

        displaySnackbar(translate('userActionSuccess.create'), {
            variant: 'success',
        });

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserCreate,
        });

        // Reset submitted values as the form was successfully submitted
        if (submittedValues) setSubmittedValues(undefined);

        return;
    };

    const loading =
        extraUserFieldsLoading || createUserLoading || organisationLoading;

    return (
        <PageDrawer {...other}>
            <DrawerModuleSection>
                <UserPreview
                    name="profileImage"
                    profileImage={profileImage}
                    onUpload={setProfileImage}
                    onUploadFinish={() => setLoadingUpload(false)}
                    onUploadStart={() => setLoadingUpload(true)}
                />
            </DrawerModuleSection>

            <UserCreateForm
                disabled={loadingUpload}
                extraFields={extraUserFields}
                hideGroups={!!groupId}
                initialValues={initialValues}
                loading={loading}
                onSubmit={handleSubmit}
            />
        </PageDrawer>
    );
};
