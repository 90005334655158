import { useTranslation } from 'react-i18next';
import {
    mdiAccount,
    mdiAccountGroup,
    mdiBookshelf,
    mdiPlus,
    mdiSchool,
    mdiSeat,
    mdiTransitConnectionVariant,
} from '@mdi/js';
import { Box, MenuItem, Stack, SxProps } from '@mui/material';

import { Icon } from 'common/components/Icon';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Link } from 'common/components/Link';
import { Divider } from 'common/components/Divider';
import { Button } from 'common/components/Button';
import { IOfferEventType } from 'graphql/types';
import { EFilterType } from 'offer/constants/offer';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { useAppPermissions } from 'user/hooks/usePermissions';

const menuItemStyle: SxProps = {
    pr: 3,
};

export const NavigationCreateMaterial = () => {
    const { t: translate } = useTranslation();

    const {
        canCreateCourse = false,
        canCreateTraining = false,
        canCreateMeeting = false,
        canCreateOffer = false,
        canCreateUser = false,
        canCreateGroup = false,
    } = useAppPermissions('general', 'management');

    const { openGlobalDrawer } = useGlobalDrawer();

    if (
        !canCreateCourse &&
        !canCreateTraining &&
        !canCreateMeeting &&
        !canCreateOffer &&
        !canCreateUser &&
        !canCreateGroup
    ) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                mr: '5px',
                alignItems: 'center',
            }}
        >
            <DropdownMenu
                anchor={
                    <Button
                        color="primary"
                        startIcon={<Icon path={mdiPlus} size="1.9rem" />}
                        sx={{
                            height: '37px',
                            borderRadius: 5,
                            pl: 2.5,
                            ml: 0.5,

                            '.MuiButton-startIcon': {
                                mr: 1,
                            },
                        }}
                        variant="contained"
                    >
                        {translate('newMaterialButton')}
                    </Button>
                }
                placement="bottom"
            >
                <Stack divider={<Divider sx={{ my: 1.25 }} />}>
                    {(canCreateCourse || canCreateTraining) && (
                        <Box>
                            {canCreateCourse && (
                                <MenuItem
                                    component={Link}
                                    sx={menuItemStyle}
                                    to="COURSE_CREATE"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Icon path={mdiSchool} size="2.1rem" />
                                    </Box>

                                    {translate('course')}
                                </MenuItem>
                            )}

                            {canCreateTraining && (
                                <MenuItem
                                    component={Link}
                                    sx={menuItemStyle}
                                    to="TRAINING_CREATE"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Icon
                                            path={mdiTransitConnectionVariant}
                                            size="2.1rem"
                                        />
                                    </Box>

                                    {translate('training')}
                                </MenuItem>
                            )}
                        </Box>
                    )}

                    {(canCreateMeeting || canCreateOffer) && (
                        <Box>
                            {canCreateMeeting && (
                                <MenuItem
                                    component={Link}
                                    query={`?type=${EFilterType.meetingWebinar}`}
                                    state={{
                                        offerEventType: IOfferEventType.Meeting,
                                    }}
                                    sx={menuItemStyle}
                                    to="OFFER_MEETING_CREATE"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Icon path={mdiSeat} size="2.1rem" />
                                    </Box>

                                    {translate('meeting')}
                                </MenuItem>
                            )}

                            {canCreateOffer && (
                                <MenuItem
                                    component={Link}
                                    query={`?type=${EFilterType.training}`}
                                    state={{
                                        offerType: IOfferEventType.Training,
                                    }}
                                    sx={menuItemStyle}
                                    to="OFFER_CREATE"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Icon
                                            path={mdiBookshelf}
                                            size="2.1rem"
                                        />
                                    </Box>

                                    {translate('offers')}
                                </MenuItem>
                            )}
                        </Box>
                    )}

                    {(canCreateUser || canCreateGroup) && (
                        <Box>
                            {canCreateUser && (
                                <MenuItem
                                    component={Link}
                                    sx={menuItemStyle}
                                    onClick={() => {
                                        openGlobalDrawer({
                                            type: IGlobalDrawerType.UserCreate,
                                        });
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Icon path={mdiAccount} size="2.1rem" />
                                    </Box>

                                    {translate('user')}
                                </MenuItem>
                            )}

                            {canCreateGroup && (
                                <MenuItem
                                    component={Link}
                                    sx={menuItemStyle}
                                    to="GROUP_CREATE"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignContent: 'center',
                                        }}
                                    >
                                        <Icon
                                            path={mdiAccountGroup}
                                            size="2.1rem"
                                        />
                                    </Box>

                                    {translate('group')}
                                </MenuItem>
                            )}
                        </Box>
                    )}
                </Stack>
            </DropdownMenu>
        </Box>
    );
};
