import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { LibrarySearchDrawer } from 'library/components/LibrarySearchDrawer';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useFeature } from 'common/hooks/useFeature';
import { IFeature } from 'graphql/types';
import { UpgradePage } from 'common/components/UpgradePage';
import { UpgradeContent } from 'library/components/UpgradeContent/UpgradeContent';
import { Loader } from 'common/components/Loader';

import { BasePage } from './BasePage';

export const LibraryPage = () => {
    const navigate = useNavigate();

    const routeQuery = useRouteQuery();
    const searchQueryParam = routeQuery.get('q');

    const [searchQuery, setSearchQuery] = useState(searchQueryParam || '');
    const {
        canUse: canUseLibrary,
        userCanSeeUpgrade,
        loading: loadingCanUse,
    } = useFeature(IFeature.Library);

    // Check if type is string to also show the drawer when query is an empty string
    const searchDrawerOpen = typeof searchQueryParam === 'string';

    const handleSearch = (value: string) => {
        setSearchQuery(value);

        if (value) {
            // Redirect to same url with search parameter
            navigate(`${window.location.pathname}?q=${value}`);
        }
    };

    const handleCloseSearchDrawer = () => {
        navigate(window.location.pathname);
    };

    const showUpgradePage =
        !loadingCanUse && !canUseLibrary && userCanSeeUpgrade;

    return (
        <>
            {loadingCanUse && <Loader />}

            {!showUpgradePage && !loadingCanUse && (
                <>
                    <LibrarySearchDrawer
                        open={searchDrawerOpen}
                        searchQuery={searchQuery}
                        onClose={handleCloseSearchDrawer}
                        onSearch={handleSearch}
                    />
                    <Outlet context={{ searchQuery, onSearch: handleSearch }} />
                </>
            )}

            {showUpgradePage && (
                <BasePage contentContainerMaxWidth="lg">
                    <UpgradePage>
                        <UpgradeContent />
                    </UpgradePage>
                </BasePage>
            )}
        </>
    );
};
