import { IconButton, SxProps } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { DropdownMenu } from 'common/components/DropdownMenu';
import { withSxProp } from 'common/utils/props';

import { TableCell } from './TableCell';

interface IProps {
    className?: string;
    children?: React.ReactNode;
    sx?: SxProps;
    enablePortal?: boolean;
}

export const TableDropdownCell = ({
    children,
    sx,
    enablePortal = true,
    ...other
}: IProps) => (
    <TableCell
        padding="checkbox"
        sx={[
            { position: 'relative', width: 68, textAlign: 'center' },
            ...withSxProp(sx),
        ]}
        onClick={(e: React.MouseEvent<HTMLTableHeaderCellElement>) =>
            e.stopPropagation()
        }
    >
        <DropdownMenu
            {...other}
            anchor={
                <IconButton size="large">
                    <MoreVert />
                </IconButton>
            }
            enablePortal={enablePortal}
            placement="bottom-end"
        >
            {children}
        </DropdownMenu>
    </TableCell>
);
