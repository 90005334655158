import { useMemo, useEffect } from 'react';
import { I18nextProvider as Provider } from 'react-i18next';
import { Settings } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { i18n } from 'utils/i18n';
import { useCurrentUserQuery, useOrganisationQuery } from 'graphql/types';
import { Loader } from 'common/components/Loader';

interface IProps {
    children?: React.ReactNode;
}

export const I18nextProvider = ({ children }: IProps) => {
    const { data: orgData, loading: orgLoading } = useOrganisationQuery();
    const { data: user, loading: userLoading } = useCurrentUserQuery();

    const language = useMemo(() => {
        if (user && user.currentUser && user.currentUser.language) {
            return user.currentUser.language;
        }

        if (orgData && orgData.organisation && orgData.organisation.language) {
            return orgData.organisation.language;
        }

        return i18n.language;
    }, [orgData, user]);

    useEffect(() => {
        if (language !== i18n.language) {
            i18n.changeLanguage(language);
        }
        Settings.defaultLocale = i18n.language.toLowerCase();
    }, [language]);

    if (orgLoading || userLoading) return <Loader />;

    return (
        <LocalizationProvider
            adapterLocale={language.toLowerCase()}
            dateAdapter={AdapterLuxon}
        >
            <Provider i18n={i18n}>{children}</Provider>
        </LocalizationProvider>
    );
};
