import {
    Alert as MuiAlert,
    AlertTitle,
    AlertProps,
    SxProps,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

const alertStyle: SxProps = {
    lineHeight: 1.5,
    '.MuiAlertTitle-root': {
        fontWeight: 700,
        mb: 0.5,
        lineHeight: 1.5,

        '&:last-child': {
            mb: 0,
        },
    },
    '.MuiAlert-icon': {
        mt: 0.25,
    },
    '.MuiAlert-action': {
        alignItems: 'center',
        pt: 0,
    },
};

interface IProps extends AlertProps {
    title?: string;
}

export const Alert = ({ sx, title, children, ...other }: IProps) => (
    <MuiAlert {...other} sx={[alertStyle, ...withSxProp(sx)]}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
    </MuiAlert>
);
