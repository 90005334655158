import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { usePagination } from 'common/hooks/usePagination';
import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { Loader } from 'common/components/Loader';
import { IGroupListItemFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { useGroupQueryVariables, useGroupSearch } from 'user/hooks/group';
import { useGroupList } from 'user/hooks/group/useGroupList';
import { TFilterBarItem } from 'common/types';
import { GroupAddList } from 'user/components/GroupSelectDrawer/GroupAddList';
import { TGroupPermission } from 'user/types';

interface IProps extends IPageDrawerProps {
    addedGroups: IGroupListItemFragment[];
    selectedGroups: IGroupListItemFragment[];
    checkGroupPermission?: TGroupPermission;
    initialSelectedFilters?: TFilterBarItem[];
    onAddGroups(groups?: IGroupListItemFragment[]): void;
    onRemoveGroup?(selectedGroup: IGroupListItemFragment): void;
    onClose?(): void;
    onSelectGroup?(group: IGroupListItemFragment): void;
    setSelectedGroups?(groups: IGroupListItemFragment[]): void;
}

export const GroupSelectDrawer = ({
    addedGroups,
    selectedGroups,
    checkGroupPermission,
    open,
    initialSelectedFilters,
    onAddGroups,
    onRemoveGroup,
    onClose,
    onSelectGroup,
    setSelectedGroups,
}: IProps) => {
    const { t: translate } = useTranslation();

    const {
        filters,
        searchQueryParam,
        filtersLoading,
        selectedFilters,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useGroupSearch(initialSelectedFilters);
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { groupQueryVariables } = useGroupQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        groups,
        groupsCount,
        loading: groupsLoading,
    } = useGroupList(groupQueryVariables);

    const resetSettings = () => {
        handleSearch('');
        handleFilterSelect([]);
        setPage(1);
    };

    useEffect(() => {
        if (groupsLoading || !initializePagination) return;

        initializePagination(groupsCount || 0);
    }, [groupsCount, groupsLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('groupConditionFilter.selectorTitle')}
            onClose={() => {
                onClose?.();

                // When selection is changed but drawer is closed, it needs to reset the selected groups
                // else they are added, but not selected anymore
                setSelectedGroups?.(addedGroups);

                resetSettings();
            }}
        >
            {filtersLoading ? (
                <Loader />
            ) : (
                <>
                    <Box
                        sx={{
                            pb: 22.5,
                            pt: { xs: 2, sm: 4 },
                            px: { xs: 2, sm: 4 },
                        }}
                    >
                        <FilterBar
                            filters={filters}
                            initialSearchValue={searchQueryParam}
                            initialSelected={selectedFilters}
                            placeholder={translate('filterBarPlaceholder')}
                            onSearch={handleSearch}
                            onSearchClear={() => handleSearch('')}
                            onSelect={(selected) => {
                                handleFilterSelect(selected);
                            }}
                        />

                        {groupsLoading && <Loader />}

                        {open && !groupsLoading && (
                            <GroupAddList
                                checkGroupPermission={checkGroupPermission}
                                groups={groups}
                                selectedGroups={selectedGroups}
                                onAddGroup={onSelectGroup}
                                onRemoveGroup={onRemoveGroup}
                            />
                        )}
                    </Box>

                    <PageDrawerFooter>
                        <Box display="flex" flexDirection="column">
                            {!groupsLoading &&
                                paginationSettings.count > -1 && (
                                    <Box mb={2}>
                                        <Pagination
                                            page={paginationSettings.page}
                                            pageAmount={
                                                paginationSettings.pageAmount
                                            }
                                            totalsAmount={
                                                paginationSettings.count
                                            }
                                            totalsText={
                                                paginationSettings.count === 1
                                                    ? translate('group')
                                                    : translate('groups')
                                            }
                                            onChange={(page: number) => {
                                                setPage(page);
                                            }}
                                        />
                                    </Box>
                                )}

                            <Button
                                color="primary"
                                disabled={!selectedGroups.length}
                                variant="contained"
                                onClick={() => {
                                    onAddGroups(selectedGroups);
                                    resetSettings();
                                }}
                            >
                                {!selectedGroups.length
                                    ? translate(
                                          'groupConditionFilter.selectorTitle'
                                      )
                                    : translate(
                                          'groupConditionFilter.selectorAddButtonText',
                                          { count: selectedGroups.length }
                                      )}
                            </Button>
                        </Box>
                    </PageDrawerFooter>
                </>
            )}
        </PageDrawer>
    );
};
