import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { TextField } from 'common/components/FormField';
import { TLTIPlatformFormValues } from 'connections/types';

export const PlatformDetailsForm = () => {
    const [translate] = useTranslation();
    const { errors, touched } = useFormikContext<TLTIPlatformFormValues>();

    const hasError =
        (!!errors?.issuer && !!touched.issuer) ||
        (!!errors?.clientId && !!touched.clientId);

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="lTIPlatformForm.platformDetailsForm.platformDetailsDescription"
                    />
                </Box>
            }
            hasError={hasError}
            title={translate(
                'lTIPlatformForm.platformDetailsForm.platformDetailsTitle'
            )}
        >
            <Box mt={1}>
                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.platformDetailsForm.issuerId'
                    )}
                    name="issuer"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.platformDetailsForm.clientId'
                    )}
                    name="clientId"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.platformDetailsForm.deploymentId'
                    )}
                    name="deploymentIds"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.platformDetailsForm.publicKeyUrl'
                    )}
                    name="keySetUrl"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.platformDetailsForm.authTokenUrl'
                    )}
                    name="authTokenUrl"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.platformDetailsForm.authLoginUrl'
                    )}
                    name="authLoginUrl"
                />
            </Box>
        </FormSection>
    );
};
