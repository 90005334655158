import { TableRow } from '@mui/material';

import { IPortfolioItemReportFragment } from 'graphql/types';
import { TableCell } from 'common/components/Table';
import { PortfolioItemProgressScoreChip } from 'user/components/PortfolioItemProgressScoreChip';

interface IProps {
    item: IPortfolioItemReportFragment;
    onClick?(): void;
}

export const OfferEventReportTableRow = ({ item, ...other }: IProps) => (
    <TableRow hover sx={{ cursor: 'pointer' }} {...other}>
        <TableCell>{item.title}</TableCell>
        <TableCell align="right" sx={{ width: '1px' }}>
            <PortfolioItemProgressScoreChip portfolioItem={item} />
        </TableCell>
    </TableRow>
);
