import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import { Loader } from 'common/components/Loader';
import { useFrontendPermissions } from 'user/hooks';
import { getUrl } from 'route/utils/getUrl';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { ApolloError } from 'common/components/ApolloError';
import { useOrganisationSettingsForm } from 'organisation/hooks/organisationSettings/useOrganisationSettingsForm';

import { OrganisationSettingsForm } from '../forms/OrganisationSettingsForm';

interface IProps {
    open?: boolean;
}

export const OrganisationSettingsDrawer = ({ open }: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const { canUpdate } = useFrontendPermissions('organisation');
    const organisationSettingsDrawerOpen = useHashMatch(
        'ORGANISATION_SETTINGS'
    );
    const { initialValues, loading, errors, handleSubmit } =
        useOrganisationSettingsForm();

    if (!organisationSettingsDrawerOpen) {
        return (
            <PageDrawer open={open}>
                <Loader />
            </PageDrawer>
        );
    }

    if (open && !canUpdate) {
        return <Navigate replace to={getUrl('PAGE_NOT_FOUND')} />;
    }

    // Redirect to the pathname to go to the page without hashtag.
    const handleClose = () => navigate(window.location.pathname);

    return (
        <PageDrawer open={open} onClose={handleClose}>
            {errors.map((error, index) => (
                <ApolloError error={error} key={index} />
            ))}

            {loading && <Loader />}

            {!loading && (
                <>
                    <PageTitle mixpanelTitle="Drawer organisation settings">
                        {translate('academySettingsForm.pageTitle')}
                    </PageTitle>

                    <OrganisationSettingsForm
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    />
                </>
            )}
        </PageDrawer>
    );
};
