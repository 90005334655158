import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Typography,
    Stack,
    Button as MuiButton,
} from '@mui/material';
import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router-dom';

import { useAppPermissions } from 'user/hooks/usePermissions';
import { PageTitle } from 'common/components/PageTitle';
import { PageIntroHeader } from 'common/components/PageIntroHeader';
import { IAppPermissionsOffer, useOffersSettingsQuery } from 'graphql/types';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import { Text } from 'common/components/Text';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { CreateOfferDrawer } from 'offer/components/CreateOfferDrawer';
import { getUrl } from 'route/utils/getUrl';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { Chip, ChipGroup } from 'common/components/Chip';
import { Alert } from 'common/components/Alert';
import { OfferTabsMenu } from 'offer/components/OfferTabsMenu';
import { useOfferTypeFilter } from 'offer/hooks';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import { OfferResults } from 'offer/components/OfferResults';
import { EFilterType } from 'offer/constants/offer';

import { BasePage } from './BasePage';
import { FourOFourPage } from './FourOFourPage';

export const OffersPage = () => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();

    const {
        canCreate = false,
        canUpdate: canUpdateOfferSettings = false,
    }: IAppPermissionsOffer['management'] = useAppPermissions(
        'offer',
        'management'
    );

    const {
        typeFilters,
        currentTypeFilter,
        loading: loadingTypeFilter,
    } = useOfferTypeFilter();

    const {
        data: offersSettingsData,
        loading: offersSettingsLoading,
        error: offersSettingsError,
    } = useOffersSettingsQuery();

    const isMeetingCreatePage = !!useRouteMatch('OFFER_MEETING_CREATE');
    const isCreateOfferPage = !!useRouteMatch('OFFER_CREATE');

    // If we are on offer list page so we restore url if needed
    const { setFilterParam, searchParamsStr, updateUrlfromCache } =
        useUrlFilterParams('offer');

    // Initialize url from cache on mount
    useEffect(() => {
        updateUrlfromCache();
    }, [updateUrlfromCache]);

    let header;

    const loading = loadingTypeFilter;

    // Early return when loading
    if (loading) {
        return (
            <BasePage>
                <Loader />
            </BasePage>
        );
    }

    const { offersSettings } = offersSettingsData || {};
    const {
        image: headerImage,
        title,
        titleColor,
        publish,
        publishElearning,
        publishMeetingWebinar,
        publishTraining,
    } = offersSettings || {};

    // Render 404 when we have no filters published
    if (!typeFilters.length) return <FourOFourPage />;

    // Check if we have a current type filter, this will be the case when we have a type
    // that is not in the type filters list, we can just set it to undefined to make it
    // the first one
    if (!currentTypeFilter) {
        setFilterParam('type', undefined);

        return null;
    }

    const headerTitle = title || translate('offers');
    let headerTitleColor = titleColor;
    const description = offersSettings?.description;

    const canCreateOffer =
        canCreate &&
        (publishElearning || publishMeetingWebinar || publishTraining);

    const actionButtons = (
        <>
            {(canCreateOffer || canUpdateOfferSettings) && (
                <Grid item component={Box} display="flex">
                    <Stack direction="row" spacing={1}>
                        {canCreateOffer && (
                            <>
                                <ActionButton
                                    component={Link}
                                    outlined={!headerImage}
                                    query={`?type=${EFilterType.meetingWebinar}`}
                                    to="OFFER_MEETING_CREATE"
                                    variant="extended"
                                >
                                    <Box>
                                        <Icon path={mdiPlus} size="2.4rem" />
                                    </Box>
                                    {translate('offer.newMeeting')}
                                </ActionButton>

                                <ActionButton
                                    component={Link}
                                    outlined={!headerImage}
                                    query={`?type=${EFilterType.training}`}
                                    to="OFFER_CREATE"
                                    variant="extended"
                                >
                                    <Box>
                                        <Icon path={mdiPlus} size="2.4rem" />
                                    </Box>
                                    {translate('offer.newOffer')}
                                </ActionButton>
                            </>
                        )}
                    </Stack>
                </Grid>
            )}
        </>
    );

    const hiddenChip = !publish && (
        <Box sx={{ mb: 1 }}>
            <ChipGroup>
                <Chip bgColor="warning" label={translate('hidden')} />
            </ChipGroup>
        </Box>
    );

    if (headerImage) {
        // Default header color is white when image is set
        headerTitleColor = headerTitleColor || '#fff';

        header = (
            <PageIntroHeader contentContainerMaxWidth="lg" image={headerImage}>
                {hiddenChip}

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item>
                        <Box
                            alignItems="center"
                            component="span"
                            display="flex"
                            flexWrap="wrap"
                        >
                            <Box mr={1}>
                                <Typography
                                    style={{ color: headerTitleColor }}
                                    variant="h1"
                                >
                                    {headerTitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {actionButtons}
                </Grid>
            </PageIntroHeader>
        );
    }

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            {offersSettingsError && <ApolloError error={offersSettingsError} />}

            {!offersSettingsLoading && (
                <>
                    <PageTitle mixpanelTitle="Offers overview">
                        {translate('offer.offersBrowserTitle')}
                    </PageTitle>

                    <Box py={4}>
                        {!header && (
                            <Box sx={{ mb: 2 }}>
                                {hiddenChip}

                                <Grid
                                    container
                                    alignItems="flex-end"
                                    justifyContent="space-between"
                                    spacing={2}
                                >
                                    {headerTitle && (
                                        <Grid item>
                                            <Box mb={1}>
                                                <Typography
                                                    style={{
                                                        color:
                                                            headerTitleColor ||
                                                            undefined,
                                                    }}
                                                    variant="h1"
                                                >
                                                    {headerTitle}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Box mb={1}>{actionButtons}</Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {description && (
                            <Box mb={4} mt={2}>
                                <Text variant="body1">{description}</Text>
                            </Box>
                        )}

                        <OfferTabsMenu />

                        {currentTypeFilter && !currentTypeFilter.published && (
                            <Box sx={{ mt: 2 }}>
                                <Alert
                                    action={
                                        <MuiButton
                                            color="inherit"
                                            component={Link}
                                            query={`?${searchParamsStr}`}
                                            size="small"
                                            sx={{ fontWeight: 'bold', mr: 1 }}
                                            to="OFFER_SETTINGS_EDIT"
                                        >
                                            {translate('settings')}
                                        </MuiButton>
                                    }
                                    severity="error"
                                >
                                    {currentTypeFilter?.notPublishedMessage}
                                </Alert>
                            </Box>
                        )}

                        <Box mb={4}>
                            <OfferResults typeFilter={currentTypeFilter} />
                        </Box>

                        {canCreateOffer && (
                            <CreateOfferDrawer
                                open={isMeetingCreatePage || isCreateOfferPage}
                                onClose={() => {
                                    navigate(
                                        `${getUrl(
                                            'OFFER_LIST'
                                        )}?${searchParamsStr}`
                                    );
                                }}
                            />
                        )}
                    </Box>
                </>
            )}
        </BasePage>
    );
};
