import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import {
    ICreateModuleGroupInput,
    IModuleGroupConditionType,
    ITrainingModuleGroupsQuery,
    TrainingModuleGroupsDocument,
    useCreateModuleGroupMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { TModuleGroupFormValues } from 'training/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ModuleGroupForm } from 'training/components/forms/ModuleGroupForm';
import { PageTitle } from 'common/components/PageTitle';
import { getConditionFormValues } from 'training/utils/moduleGroup';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const ModuleGroupCreateDrawer = ({
    open,
    onClose,
    ...other
}: IProps) => {
    const { trainingId } = useParams() as { trainingId: string };
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [translate] = useTranslation();
    const [createModuleGroup, { loading: createModuleGroupLoading }] =
        useCreateModuleGroupMutation();

    const handleSubmit = async (values: TModuleGroupFormValues) => {
        const { conditionType, absoluteCondition, ...otherValues } = values;

        const conditionValues = conditionType
            ? getConditionFormValues(conditionType, values)
            : {};

        try {
            await createModuleGroup({
                variables: {
                    trainingId,
                    moduleGroup: {
                        conditionType,
                        ...otherValues,
                        ...conditionValues,
                    } as ICreateModuleGroupInput,
                },
                update: (cache, { data }) => {
                    const newModuleGroup = data?.createModuleGroup?.moduleGroup;

                    if (!newModuleGroup) return;

                    const trainingModuleGroupsData: ITrainingModuleGroupsQuery | null =
                        cache.readQuery({
                            query: TrainingModuleGroupsDocument,
                            // Provide any required variables in this object.
                            // Variables of mismatched types will return `null`.
                            variables: {
                                id: trainingId,
                            },
                        });

                    const { training } = trainingModuleGroupsData || {};

                    if (!training) return;

                    cache.updateQuery(
                        { query: TrainingModuleGroupsDocument },
                        () => ({
                            training: {
                                ...training,
                                moduleGroups: [
                                    ...(training?.moduleGroups || []),
                                    newModuleGroup,
                                ],
                            },
                        })
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleGroupForm.createSuccess'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    const initialValues: TModuleGroupFormValues = {
        title: '',
        description: '',
        conditionType: IModuleGroupConditionType.NoCondition,
    };

    return (
        <PageDrawer
            disableClose={createModuleGroupLoading}
            open={open}
            onClose={onClose}
            {...other}
        >
            {open && (
                <>
                    <PageTitle mixpanelTitle="Drawer module group create form">
                        {translate('moduleGroupForm.pageTitleCreate')}
                    </PageTitle>

                    <ModuleGroupForm
                        initialValues={initialValues}
                        loading={createModuleGroupLoading}
                        onSubmit={handleSubmit}
                    />
                </>
            )}
        </PageDrawer>
    );
};
