import { SxProps } from '@mui/material';
import MuiCardMedia, { CardMediaProps } from '@mui/material/CardMedia';

import { withSxProp } from 'common/utils/props';

const cardMediaStyle: SxProps = {
    position: 'relative',
    height: 220,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const CardMedia = ({ sx, ...other }: CardMediaProps) => (
    <MuiCardMedia
        {...other}
        sx={[
            cardMediaStyle,
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
