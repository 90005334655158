import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getQueryParams } from 'common/utils/getQueryParams';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { getSearchUrlQuery } from 'common/utils/getSearchUrl';

export type TPaginationSettings = {
    page: number;
    offset: number;
    pageAmount: number;
    count: number;
    first: number;
    before?: string;
    after?: string;
    last?: number;
};

export const DEFAULT_PAGINATION_SETTINGS = {
    offset: 0,
    before: undefined,
    after: undefined,
    first: 25,
    last: undefined,
};

/**
 * The usePagination hook can be used for numeric pagination. It includes url based page parameters.
 */
export const usePagination = (
    noUrlParams?: boolean,
    settings?: Partial<TPaginationSettings>,
    scrollTop?: boolean // Scroll to top when page changes, useful for large lists
) => {
    const navigate = useNavigate();
    const routeQuery = useRouteQuery();
    const location = useLocation();
    const searchQueryParam = routeQuery.get('q');
    const queryParams = getQueryParams();

    const getOffset = (page: number) =>
        (page - 1) * (settings?.first || DEFAULT_PAGINATION_SETTINGS.first);

    const getPageFromUrl = useCallback(() => {
        const { search } = location;

        if (!search) return 1;

        const searchParams = new URLSearchParams(search);
        const page: number | string | null = searchParams.get('page');

        // Return default 1 when the page parameter is not set, is malformed or is lower than 1
        return page && +page && +page > 0 ? +page : 1;
    }, [location]);

    const getPageData = useCallback(() => {
        const page = getPageFromUrl();

        return {
            page,
            offset: getOffset(page),
        };
    }, [getPageFromUrl]);

    const [paginationSettings, setPaginationSettings] = useState({
        ...DEFAULT_PAGINATION_SETTINGS,
        ...settings,
        pageAmount: -1,
        count: -1,
        ...getPageData(),
    });

    useEffect(() => {
        if (noUrlParams) return;

        const { page, offset } = getPageData();

        if (page === paginationSettings.page) return;

        // Set page and offset state when page search parameter has changed
        setPaginationSettings({
            ...paginationSettings,
            page,
            offset,
        });
    }, [paginationSettings, getPageData, noUrlParams]);

    useEffect(() => {
        if (noUrlParams) return;

        const page = getPageFromUrl();

        // Redirect to page 1 if page given exceeds the amount of pages
        if (
            paginationSettings.pageAmount !== -1 &&
            page > paginationSettings.pageAmount &&
            queryParams['page'] &&
            !queryParams['page'].includes('1')
        ) {
            const newQueryParams = { ...queryParams };
            delete newQueryParams['page'];

            const searchUrlQuery = getSearchUrlQuery(
                searchQueryParam || '',
                newQueryParams
            );

            navigate(
                `${window.location.pathname}?${
                    searchUrlQuery ? `${searchUrlQuery}&` : ''
                }page=1`
            );
        }
    }, [
        paginationSettings.pageAmount,
        getPageFromUrl,
        navigate,
        queryParams,
        searchQueryParam,
        noUrlParams,
    ]);

    const initializePagination = (count?: number | null) => {
        if (typeof count === 'undefined' || count === null) return;

        const newPageAmount = Math.ceil(count / paginationSettings.first);

        if (paginationSettings.count === count) return;

        setPaginationSettings({
            ...paginationSettings,
            pageAmount: newPageAmount,
            count: count,
        });
    };

    const setPage = (page: number) => {
        if (scrollTop) window.scrollTo(0, 0);

        if (noUrlParams) {
            setPaginationSettings({
                ...paginationSettings,
                page,
                offset: getOffset(page),
            });

            return;
        }

        const newQueryParams = { ...queryParams };
        delete newQueryParams['page'];

        const searchUrlQuery = getSearchUrlQuery(
            searchQueryParam || '',
            newQueryParams
        );

        navigate(
            `${window.location.pathname}?${
                searchUrlQuery ? `${searchUrlQuery}&` : ''
            }page=${page}`
        );
    };

    return { paginationSettings, initializePagination, setPage };
};
