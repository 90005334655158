import { Box } from '@mui/material';
import { Formik, FormikConfig, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ILanguage, IUpdateOrganisationInput } from 'graphql/types';
import { LANGUAGES } from 'common/constants/languages';
import { Button } from 'common/components/Button';
import { LanguageSelectField } from 'common/components/LanguageSelectField';

type TUpdateOrganisationLanguageInput = Pick<
    IUpdateOrganisationInput,
    'language'
>;

interface IProps extends FormikConfig<TUpdateOrganisationLanguageInput> {
    onChangeLanguage?(language: ILanguage): void;
}

export const OrganisationLanguageForm = ({
    onChangeLanguage,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        language: Yup.mixed()
            .oneOf(Object.keys(LANGUAGES))
            .required(translate('validate.required')),
    });

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <LanguageSelectField
                        name="language"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            const value = e.target.value as ILanguage;
                            onChangeLanguage && onChangeLanguage(value);
                        }}
                    />

                    <Box mt={2} textAlign="right">
                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="submit"
                            variant="contained"
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                e.preventDefault();

                                submitForm();
                            }}
                        >
                            {translate('nextStep')}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
