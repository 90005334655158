import { styled } from 'styled-components';

export interface IProps {
    selected?: boolean;
    blockClick?: boolean;
}

export const Selectable = styled.div<IProps>`
    position: relative;
    cursor: pointer;
    z-index: 0;

    ::after {
        z-index: 1;
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        content: '';

        // Block click by using point-events
        pointer-events: ${({ blockClick }) => (blockClick ? 'auto' : 'none')};

        ${({ selected, theme }) =>
            selected && `border: 2px solid ${theme.palette.primary.main};`};
    }
`;
