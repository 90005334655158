import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { ImageField, LinkField, TextField } from 'common/components/FormField';
import {
    FOOTER_IMAGE_MAX_SIZE,
    FOOTER_IMAGE_TYPE,
} from 'common/constants/files';
import { Banner } from 'common/components/Banner';
import { Img } from 'common/components/Img';
import PLUVO_FOOTER from 'assets/images/pluvo-footer.webp';

export const FooterForm = () => {
    const [translate] = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="academySettingsForm.footerDescription"
                    />
                </Box>
            }
            title={translate('academySettingsForm.footerTitle')}
        >
            <Grid
                container
                alignItems="stretch"
                direction={smallScreen ? 'column-reverse' : 'row'}
                spacing={2}
                sx={{ mt: 2 }}
            >
                <Grid item sm xs={12}>
                    <Field
                        acceptedFileTypes={FOOTER_IMAGE_TYPE}
                        component={ImageField}
                        helperText={translate('imageSizeDescription', {
                            width: 320,
                            height: 120,
                        })}
                        imageSize="contain"
                        label={translate(
                            'academySettingsForm.footerImage.label'
                        )}
                        maxUploadSize={FOOTER_IMAGE_MAX_SIZE}
                        name="footerImage"
                    />
                </Grid>

                <Grid item sm xs={12}>
                    <Banner>
                        <Box
                            sx={{
                                py: 1,
                                px: 2,
                            }}
                        >
                            {translate(
                                'academySettingsForm.footerImage.description'
                            )}
                        </Box>
                        <Box mt="auto">
                            <Img src={PLUVO_FOOTER} />
                        </Box>
                    </Banner>
                </Grid>
            </Grid>

            <Field
                component={TextField}
                label={translate('academySettingsForm.footerText')}
                name="footerText"
            />

            <LinkField
                label={translate('academySettingsForm.footerLink')}
                name="footerLink"
            />
        </FormSection>
    );
};
