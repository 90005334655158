import { Box, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import { TableCell } from 'common/components/Table';
import { Checkbox } from 'common/components/Checkbox';
import { IUserManagementListItemFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { Chip, ChipGroup } from 'common/components/Chip';
import { formatDate } from 'common/utils/formatDate';
import { UserAvatar } from 'user/components/UserAvatar';

interface IProps {
    disabled?: boolean;
    isEditable?: boolean;
    isSelected?: boolean;
    user: IUserManagementListItemFragment;
    onSelect?(): void;
    onArchive?(): void;
    onUpdate?(): void;
    forArchive?: boolean;
}

export const UserManagementTableRow = ({
    disabled,
    isEditable = true,
    isSelected,
    user,
    onSelect,
    onArchive,
    onUpdate,
    forArchive,
}: IProps) => {
    const [translate] = useTranslation();
    const { openGlobalDrawer, closeGlobalDrawer } = useGlobalDrawer();
    const client = useApolloClient();

    const { id, name, roles, invitedOn, lastLogin, userVia, archivedAt } = user;

    const evictUsers = () => {
        client.cache.evict({
            fieldName: 'paginatedUsers',
        });

        client.cache.gc();
    };

    const actionDate = forArchive ? archivedAt : invitedOn;

    return (
        <TableRow>
            {isEditable && !!onSelect && (
                <TableCell>
                    <Checkbox
                        checked={isSelected}
                        disabled={disabled}
                        onClick={(e) => {
                            onSelect?.();

                            e.stopPropagation();
                        }}
                    />
                </TableCell>
            )}

            <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    openGlobalDrawer({
                        type: IGlobalDrawerType.UserProfile,
                        itemId: id,
                        props: {
                            onUpdate: () => {
                                evictUsers();
                                onUpdate?.();
                            },
                            onArchive: () => {
                                evictUsers();

                                onArchive?.();

                                closeGlobalDrawer({
                                    type: IGlobalDrawerType.UserProfile,
                                });
                            },
                        },
                    });
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar bgColor="secondary" size={45} user={user} />

                    <Box sx={{ ml: 2 }}>
                        <Typography
                            color={disabled ? 'grey.500' : undefined}
                            variant="body2"
                        >
                            {name}
                        </Typography>

                        <ChipGroup sx={{ my: 0.25 }}>
                            {roles
                                ?.filter(Boolean)
                                .map((role) => (
                                    <Chip
                                        key={role}
                                        label={translate(
                                            `roles.${role.toLowerCase()}`
                                        )}
                                        size="small"
                                    />
                                ))}
                        </ChipGroup>
                    </Box>
                </Box>
            </TableCell>
            <TableCell>
                {actionDate ? formatDate(actionDate, 'd LLL yyyy HH:mm') : '-'}
            </TableCell>
            <TableCell>
                {!!lastLogin ? formatDate(lastLogin, 'd LLL yyyy HH:mm') : '-'}
            </TableCell>
            <TableCell>
                {!!userVia?.length ? userVia.join(', ') : '-'}
            </TableCell>
        </TableRow>
    );
};
