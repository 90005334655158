export const EXTRA_CHOICE_FIELD_TYPES = [
    'UserExtraChoiceField',
    'TrainingExtraChoiceField',
    'OfferEventExtraChoiceField',
];

export const EXTRA_STRING_FIELD_TYPES = [
    'UserExtraStringField',
    'TrainingExtraStringField',
    'OfferEventExtraStringField',
];
