import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { AlertDialog, AlertDialogProps } from 'common/components/AlertDialog';
import { Typography } from 'common/components/Typography';
import { IconButton } from 'common/components/IconButton';
import { IMinimalUserFragment, IPlanFragment } from 'graphql/types';
import { Link } from 'common/components/Link';

interface IProps extends AlertDialogProps {
    currentPlan: IPlanFragment;
    inSamePackage: boolean;
    owners: IMinimalUserFragment[];
    selectedPlan: IPlanFragment;
    onClose?(): void;
}

export const PlanManagerModal = ({
    currentPlan,
    inSamePackage,
    owners,
    selectedPlan,
    onClose,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();

    const isDowngrade =
        inSamePackage &&
        selectedPlan.userCountLimit < currentPlan.userCountLimit;

    const titleText = translate(
        `plans.upgradeModal.manager.${
            isDowngrade ? 'downgradeTitle' : 'upgradeTitle'
        }`
    );

    const limitText = translate(
        `plans.upgradeModal.manager.${
            isDowngrade ? 'downgradeText' : 'upgradeText'
        }`
    );

    const title = (
        <Box alignItems="center" display="flex">
            <Typography fontWeight={700}>{titleText}</Typography>

            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    return (
        <AlertDialog {...other} title={title} onClose={onClose}>
            <Box mb={2}>
                <Typography>{limitText}</Typography>
            </Box>

            {owners.map((owner, index) => (
                <Link href={`mailto:${owner.email}`} key={owner.id}>
                    <Typography
                        color="primary.main"
                        component="span"
                        lineHeight="1"
                    >
                        {owner.name}
                        {index < owners.length - 1 && ', '}
                    </Typography>
                </Link>
            ))}
        </AlertDialog>
    );
};
