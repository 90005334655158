import React, {
    useImperativeHandle,
    useState,
    forwardRef,
    useRef,
    useEffect,
} from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { Button } from 'common/components/Button';
import { UserAvatar } from 'user/components/UserAvatar';
import { useCurrentUserQuery } from 'graphql/types';

export interface ICommentFormRef {
    clear: () => void;
}

interface IProps {
    className?: string;
    disabled?: boolean;
    edit?: boolean;
    isFocused?: boolean;
    submitText?: string;
    value?: string;
    onCancelEdit?: () => void;
    onFocus?: (isFocused: boolean) => void;
    onSave: (value: string) => void;
}

const BaseCommentForm = forwardRef(
    (
        {
            className,
            disabled,
            edit,
            isFocused,
            submitText = 'send',
            value,
            onCancelEdit,
            onFocus,
            onSave,
        }: IProps,
        ref: React.Ref<ICommentFormRef>
    ) => {
        const [text, setText] = useState<string>(value || '');
        const [translate] = useTranslation();
        const textFieldRef = useRef<HTMLInputElement>();
        const commentFormRef = useRef<HTMLDivElement>(null);
        const { data: currentUserData } = useCurrentUserQuery();

        const currentUser = currentUserData?.currentUser;

        /*
         * When start editing comment focus on the text input
         *  and scroll to show the last typed text
         */
        useEffect(() => {
            if (!edit) return;

            textFieldRef.current?.focus();
            textFieldRef.current?.scrollTo(
                0,
                textFieldRef.current?.scrollHeight
            );
        }, [edit]);

        useImperativeHandle(ref, () => ({
            clear: () => setText(''),
        }));

        /*
         *  Handle click inside comment form to register as focus instead of
         * only focus on text input click
         */
        useEffect(() => {
            if (!commentFormRef.current) return;

            const { current } = commentFormRef;

            if (!current || edit) return;

            const handleClick = (e: MouseEvent) => {
                if (edit) return;

                const { current } = commentFormRef;
                const { target } = e;

                if (current && target && !current.contains(target as Node)) {
                    onFocus && onFocus(false);
                }
            };

            window.addEventListener('click', handleClick);

            return () => {
                window.removeEventListener('click', handleClick);
            };
        }, [edit, onFocus]);

        /*
         * Handles shift + enter as new line and enter as submit
         */
        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const enterIsPressed = e.key === 'Enter';

            if (!enterIsPressed || (enterIsPressed && e.shiftKey)) return;

            if (enterIsPressed) {
                e.preventDefault();

                onFocus && onFocus(false);

                if (text.trim().length) onSave(text);
            }
        };

        // Used to put the cursor at the end of the input on focus
        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            e.target.value = '';
            e.target.value = text;
        };

        return (
            <Box className={className} ref={commentFormRef}>
                {!edit && (
                    <Box mr={2}>
                        {currentUser && (
                            <UserAvatar size={32} user={currentUser} />
                        )}
                    </Box>
                )}

                <Box className={`inputWrapper ${isFocused ? 'isFocused' : ''}`}>
                    <TextField
                        fullWidth
                        multiline
                        className="commentTextInputWrapper"
                        disabled={disabled}
                        inputProps={{
                            className: 'commentTextInput',
                            onFocus: handleFocus,
                        }}
                        inputRef={textFieldRef}
                        placeholder={translate('comment')}
                        value={text}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            setText(e.currentTarget.value)
                        }
                        onFocus={() => {
                            onFocus && onFocus(true);
                        }}
                        onKeyDown={handleKeyDown}
                    />

                    <Box
                        className={`buttonWrapper ${
                            isFocused ? 'isFocused' : ''
                        }`}
                        display="flex"
                        onClick={() => {
                            if (isFocused) return;

                            onFocus && onFocus(true);
                        }}
                    >
                        <Box mr={1}>
                            <Button
                                color="primary"
                                disabled={!text || disabled}
                                variant="contained"
                                onClick={() => {
                                    onSave(text);

                                    onFocus && onFocus(false);
                                }}
                            >
                                {translate(submitText)}
                            </Button>
                        </Box>

                        {edit && (
                            <Button
                                disabled={!text || disabled}
                                variant="contained"
                                onClick={onCancelEdit}
                            >
                                {translate('cancel')}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    }
);

BaseCommentForm.displayName = 'CommentForm';

export const CommentForm = styled(BaseCommentForm)<IProps>`
    display: flex;
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme, edit }) => (edit ? 0 : theme.spacing(2))};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme, edit }) =>
            edit ? 0 : `${theme.spacing(2)} ${theme.spacing(4)}`};
    }

    .inputWrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        border-radius: 5px;
        padding: ${({ theme }) => theme.spacing(1)};
        border: ${({ theme, edit }) =>
            edit
                ? `2px solid ${theme.palette.primary.main}`
                : '1px solid rgba(0, 0, 0, 0.23)'};
        box-sizing: border-box;
        min-height: 50px;

        &:hover {
            border: ${({ theme, edit }) =>
                !edit && `1px solid ${theme.palette.text.primary}`};
        }

        &.isFocused {
            border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};

            .commentTextInputWrapper {
                margin-left: 0px;
            }
        }
    }

    .commentTextInputWrapper {
        min-height: 50px;
        margin-left: ${({ edit }) => !edit && '1px'};

        .MuiInputBase-root {
            padding: 0;
            margin-bottom: ${({ theme }) => theme.spacing(2)};
        }

        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }
    }

    .commentTextInput {
        min-height: 72px;
        line-height: 1.5;
    }

    .buttonWrapper {
        margin-bottom: ${({ edit }) => (edit ? 0 : '-72px')};
        transition: ${({ edit }) =>
            edit ? 'unset' : 'margin-bottom 250ms ease-in-out'};
        margin-left: ${({ edit }) => !edit && '1px'};

        &.isFocused {
            margin-left: 0;
            margin-bottom: 0;
        }
    }
`;
