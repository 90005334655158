import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { usePagination } from 'common/hooks/usePagination';
import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { useExternalContentSearch } from 'external/hooks';
import { Loader } from 'common/components/Loader';
import {
    IExternalContentListItemFragment,
    IExternalContentsSelectorQueryVariables,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { ExternalContentAddList } from 'external/components/ExternalContentAddList';
import { useExternalContentQueryVariables } from 'external/hooks/useExternalContentQueryVariables';
import { TFilterBarItem } from 'common/types';

interface IProps extends IPageDrawerProps {
    addedExternalContents: IExternalContentListItemFragment[];
    loading?: boolean;
    selectedExternalContents: IExternalContentListItemFragment[];
    externalContents: IExternalContentListItemFragment[];
    externalContentsCount?: number;
    onAddExternalContents(): void;
    onDrawerOpen(open: boolean): void;
    onRemoveExternalContent(
        externalContent: IExternalContentListItemFragment
    ): void;
    onSelectExternalContent(
        externalContent: IExternalContentListItemFragment
    ): void;
    onSetExternalContentQueryVariables(
        externalContentQueryVariables: IExternalContentsSelectorQueryVariables
    ): void;
}

export const ExternalContentSelectDrawer = ({
    addedExternalContents,
    loading,
    selectedExternalContents,
    externalContents,
    externalContentsCount,
    open,
    onAddExternalContents,
    onDrawerOpen,
    onRemoveExternalContent,
    onSelectExternalContent,
    onSetExternalContentQueryVariables,
}: IProps) => {
    const { t: translate } = useTranslation();

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useExternalContentSearch();

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { queryVariables } = useExternalContentQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const resetSettings = () => {
        handleSearch('');
        setPage(1);
    };

    useEffect(() => {
        onSetExternalContentQueryVariables(queryVariables);
    }, [queryVariables, onSetExternalContentQueryVariables]);

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(externalContentsCount || 0);
    }, [externalContentsCount, loading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('addExternalContentsTitle')}
            onClose={() => {
                onDrawerOpen(false);
                resetSettings();
            }}
        >
            {filtersLoading ? (
                <Loader />
            ) : (
                <>
                    <Box
                        sx={{
                            pb: 22.5,
                            pt: { xs: 2, sm: 4 },
                            px: { xs: 2, sm: 4 },
                        }}
                    >
                        <FilterBar
                            filters={filters}
                            initialSearchValue={searchQueryParam}
                            placeholder={translate('filterBarPlaceholder')}
                            onSearch={handleSearch}
                            onSearchClear={() => handleSearch('')}
                            onSelect={handleFilterSelect}
                        />

                        {loading && <Loader />}

                        {open && !loading && (
                            <ExternalContentAddList
                                addedExternalContents={addedExternalContents}
                                externalContents={externalContents}
                                selectedExternalContents={
                                    selectedExternalContents
                                }
                                onAddExternalContent={onSelectExternalContent}
                                onRemoveExternalContent={(
                                    selectedExternalContent: IExternalContentListItemFragment
                                ) =>
                                    onRemoveExternalContent(
                                        selectedExternalContent
                                    )
                                }
                            />
                        )}
                    </Box>

                    <PageDrawerFooter>
                        <Box display="flex" flexDirection="column">
                            {paginationSettings.count > -1 && (
                                <Box mb={2}>
                                    <Pagination
                                        page={paginationSettings.page}
                                        pageAmount={
                                            paginationSettings.pageAmount
                                        }
                                        totalsAmount={paginationSettings.count}
                                        totalsText={
                                            paginationSettings.count === 1
                                                ? translate('externalContent')
                                                : translate('externalContents')
                                        }
                                        onChange={(page: number) => {
                                            setPage(page);
                                        }}
                                    />
                                </Box>
                            )}

                            <Button
                                color="primary"
                                disabled={!selectedExternalContents.length}
                                variant="contained"
                                onClick={() => {
                                    onAddExternalContents();
                                    resetSettings();
                                }}
                            >
                                {!selectedExternalContents.length
                                    ? translate('addExternalContentsTitle')
                                    : translate('addExternalContents', {
                                          count: selectedExternalContents.length,
                                      })}
                            </Button>
                        </Box>
                    </PageDrawerFooter>
                </>
            )}
        </PageDrawer>
    );
};
