import { useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import { mdiLock, mdiChat, mdiFile } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
    IAssignmentState,
    IAssignmentModuleListItemFragment,
    IUser,
} from 'graphql/types';
import { useUserAssignmentsList } from 'training/hooks';
import { Chip, ChipGroup } from 'common/components/Chip';
import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
} from 'common/components/ListItem';
import { UserAvatar } from 'user/components/UserAvatar';
import { Tooltip } from 'common/components/Tooltip';
import { Link } from 'common/components/Link';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { Pagination } from 'common/components/Pagination';
import { usePagination } from 'common/hooks/usePagination';
import { Typography } from 'common/components/Typography';
import { AssignmentScoreChip } from 'training/components/AssignmentScoreChip';
import { TQueryParams } from 'common/utils/getSearchUrl';

interface IProps {
    moduleItem: IAssignmentModuleListItemFragment;
    searchVariables: {
        searchQueryParam: string | null;
        queryParams: TQueryParams;
    };
    onChangePage?(): void;
    onClickUser?(userId: string): void;
}

export const AssignmentList = ({
    moduleItem,
    searchVariables,
    onChangePage,
    onClickUser,
}: IProps) => {
    const [translate] = useTranslation();
    const { paginationSettings, initializePagination, setPage } =
        usePagination();

    const paginationVariables = {
        offset: paginationSettings.offset,
        before: paginationSettings.before,
        after: paginationSettings.after,
        first: paginationSettings.first,
        last: paginationSettings.last,
    };

    const { userAssignments, assignmentsCount, loading, error } =
        useUserAssignmentsList(
            moduleItem,
            paginationVariables,
            searchVariables
        );

    useEffect(() => {
        initializePagination(assignmentsCount);
    }, [assignmentsCount, initializePagination]);

    if (loading) return <Loader />;
    if (error) return <ApolloError error={error} />;

    return (
        <Box paddingBottom={4}>
            {!!userAssignments?.length ? (
                <>
                    {userAssignments.map((userAssignment) => {
                        const { assignment } = userAssignment;

                        if (!userAssignment || !assignment?.module) return null;

                        const { state, commentData } = assignment;

                        const { commentCount, attachmentCount } =
                            commentData || {};

                        const { id, name, profileImage } = userAssignment;
                        const user = { name, profileImage } as Pick<
                            IUser,
                            'name' | 'profileImage'
                        >;

                        return (
                            <ListItem
                                button
                                component={Link}
                                key={id}
                                py={1}
                                onClick={() => onClickUser?.(id)}
                            >
                                <ListItemAvatar>
                                    <UserAvatar
                                        bgColor="secondary"
                                        size={24}
                                        sizeSm={48}
                                        user={user}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={name} />
                                <ChipGroup>
                                    {state === IAssignmentState.Closed && (
                                        <Tooltip
                                            title={translate<string>('closed')}
                                        >
                                            <Box>
                                                <Chip
                                                    icon={
                                                        <Icon
                                                            path={mdiLock}
                                                            size="1.4rem"
                                                        />
                                                    }
                                                    style={{
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )}
                                    {!!attachmentCount && (
                                        <Chip
                                            icon={
                                                <Icon
                                                    path={mdiFile}
                                                    size="1.4rem"
                                                />
                                            }
                                            label={attachmentCount}
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    )}
                                    {!!commentCount && (
                                        <Chip
                                            icon={
                                                <Icon
                                                    path={mdiChat}
                                                    size="1.4rem"
                                                />
                                            }
                                            label={commentCount}
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                        />
                                    )}

                                    <AssignmentScoreChip
                                        assignment={assignment}
                                    />
                                </ChipGroup>
                            </ListItem>
                        );
                    })}

                    <Box mt={2} px={4} py={0}>
                        <Divider />

                        <Box mt={2}>
                            <Pagination
                                page={paginationSettings.page}
                                pageAmount={paginationSettings.pageAmount}
                                totalsAmount={paginationSettings.count}
                                totalsText={
                                    paginationSettings.count === 1
                                        ? translate('participant')
                                        : translate('participants')
                                }
                                onChange={(page: number) => {
                                    setPage(page);

                                    onChangePage && onChangePage();
                                }}
                            />
                        </Box>
                    </Box>
                </>
            ) : (
                <Box display="flex" justifyContent="center" marginTop={4}>
                    <Typography>{translate('noAssignments')}</Typography>
                </Box>
            )}
        </Box>
    );
};
