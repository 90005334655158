import { useTranslation } from 'react-i18next';
import {
    Box,
    CardActionArea,
    MenuItem,
    TextField,
    useTheme,
} from '@mui/material';
import { mdiCurrencyEur } from '@mdi/js';
import { useEffect, useMemo, useState } from 'react';

import { Typography } from 'common/components/Typography';
import { Card, CardContent } from 'common/components/Card';
import { Divider } from 'common/components/Divider';
import { Icon } from 'common/components/Icon';
import { Button } from 'common/components/Button';
import { PLAN_PACKAGES } from 'plans/constants/plans';
import {
    IOrganisationPlanInfoFragment,
    IPlanInterval,
    IPlanPackageFragment,
    usePlanPackagesQuery,
} from 'graphql/types';
import { formatPrice } from 'common/utils/formatPrice';
import { Text } from 'common/components/Text';
import { usePlanUpgrade } from 'plans/hooks/usePlanUpgrade';
import { IPlanModalType } from 'plans/types';
import { getPlanPackage } from 'plans/utils/plan';

import { PlanLimitModal } from '../PlanLimitModal';
import { CustomPlanModal } from '../CustomPlanModal';
import { PlanManagerModal } from '../PlanManagerModal';
import { PlanDowngradeModal } from '../PlanDowngradeModal';
import { PlanFeature } from '../PlanFeature';
import { IntervalChangeModal } from '../IntervalChangeModal';

interface IProps extends IPlanPackageFragment {
    organisation: IOrganisationPlanInfoFragment;
    planInterval: IPlanInterval;
}

export const PlanPackageCard = ({
    organisation,
    planInterval,
    plans,
    frontendName,
    id: planPackageId,
}: IProps) => {
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const { data: planPackagesData } = usePlanPackagesQuery();

    const { planPackages } = planPackagesData || {};

    const { owners, plan: currentPlan, nextPaymentDate } = organisation;

    const currentAmounts = {
        userAmount: organisation.userAmount,
        trainingAmount: organisation.trainingAmount,
    };

    // We only need to work with the plans in the current selected interval
    const filteredPlans = useMemo(
        () => plans.filter((planItem) => planItem.interval === planInterval),
        [planInterval, plans]
    );

    // Always select the first plan by default
    let currentSelectedPlan = filteredPlans[0];

    if (!currentPlan.isBasic) {
        currentSelectedPlan =
            filteredPlans.filter(
                (filteredPlan) => currentPlan.id === filteredPlan.id
            )[0] || currentSelectedPlan;
    }

    const [selectedPlan, setSelectedPlan] = useState(currentSelectedPlan);

    const planPackage = PLAN_PACKAGES[frontendName];

    const {
        currentUserDataLoading,
        openModalType,
        handleClickUpgrade,
        setOpenModalType,
    } = usePlanUpgrade({
        currentAmounts,
        currentPlan,
        planPackage,
        selectedPlan,
    });

    useEffect(() => {
        setSelectedPlan(currentSelectedPlan);
    }, [currentSelectedPlan]);

    if (!filteredPlans.length || !currentPlan) return null;

    const options = filteredPlans.map((planItem) => ({
        value: planItem.id,
        label: planItem.userCountLimit,
    }));

    const currentPlanIsSelected =
        !currentPlan.isBasic && selectedPlan.id === currentPlan.id;

    let buttonText = currentPlanIsSelected
        ? translate('plans.currentPlan')
        : `${translate('start')} ${planPackage.title}`;

    const currentPlanPackage = planPackages
        ? getPlanPackage(currentPlan, planPackages)
        : undefined;

    const inSamePackage = !!(
        currentPlanPackage && currentPlanPackage?.id === planPackageId
    );

    // A check to see if the selected plan is in the plan package where
    // the current plan resides
    if (
        inSamePackage &&
        selectedPlan.interval === currentPlan.interval &&
        !currentPlanIsSelected
    ) {
        buttonText =
            selectedPlan.userCountLimit < currentPlan.userCountLimit
                ? translate('plans.downgradePlan')
                : translate('plans.upgradePlan');
    }

    const userSelectLoading = !filteredPlans.some(
        (filteredPlan) => filteredPlan.id === selectedPlan.id
    );

    // Prices are set as cents so 1000 should be 10
    const priceToEuro = selectedPlan.cost / 100;

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            {currentUserDataLoading ? undefined : (
                <>
                    {!!planPackage.topChoice && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                                backgroundColor: 'secondary.main',
                                color: 'white',
                                px: 2,
                                borderRadius: 1,
                            }}
                        >
                            <Typography fontSize="1.4rem" fontWeight={700}>
                                {translate('plans.topChoice')}
                            </Typography>
                        </Box>
                    )}

                    <Card
                        sx={{
                            border: `1px solid ${
                                planPackage.topChoice
                                    ? theme.palette.secondary.main
                                    : theme.palette.divider
                            }`,
                        }}
                    >
                        <CardContent>
                            <Box>
                                <Typography variant="h2">
                                    {planPackage.title}
                                </Typography>

                                <Box mt={1} sx={{ minHeight: '50px' }}>
                                    <Typography lineHeight="1.4">
                                        {planPackage.description}
                                    </Typography>
                                </Box>

                                <Box my={2}>
                                    <Divider />
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mr: { sm: 3, md: 0 },
                                    }}
                                >
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'grey.400',
                                            position: 'relative',
                                            top: '1px',
                                        }}
                                    >
                                        <Icon
                                            path={mdiCurrencyEur}
                                            size="3rem"
                                        />
                                    </Box>

                                    <Typography
                                        fontSize="3rem"
                                        fontWeight={700}
                                        sx={{ ml: '-3px' }}
                                    >
                                        {selectedPlan.interval ===
                                        IPlanInterval.Month
                                            ? formatPrice(priceToEuro)
                                            : formatPrice(priceToEuro / 12)}
                                    </Typography>
                                </Box>

                                <Box ml="auto">
                                    <Text fontSize={14} lineHeight="1.2">
                                        {translate(
                                            `plans.${
                                                planInterval ===
                                                IPlanInterval.Month
                                                    ? 'billingInfoMonth'
                                                    : 'billingInfoYear'
                                            }`
                                        )}
                                    </Text>
                                </Box>
                            </Box>

                            <Box sx={{ mb: 1, mt: 1 }}>
                                <TextField
                                    fullWidth
                                    select
                                    label={translate('plans.maxParticipants')}
                                    margin="normal"
                                    value={
                                        userSelectLoading
                                            ? options[0].value
                                            : selectedPlan.id
                                    }
                                    variant="outlined"
                                    onChange={(e) => {
                                        const selectedPlanResults =
                                            filteredPlans.filter(
                                                (plan) =>
                                                    plan.id === e.target.value
                                            );

                                        if (!selectedPlanResults.length) return;

                                        setSelectedPlan(selectedPlanResults[0]);
                                    }}
                                >
                                    {!!options.length &&
                                        options.map((option) => (
                                            <MenuItem
                                                dense
                                                key={option.value}
                                                style={{
                                                    whiteSpace: 'normal',
                                                }}
                                                value={option.value}
                                            >
                                                <Typography
                                                    fontSize="1.8rem"
                                                    variant="inherit"
                                                >
                                                    {option.label}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Box>

                            <Button
                                color="primary"
                                disabled={currentPlanIsSelected}
                                sx={{ width: '100%' }}
                                variant="contained"
                                onClick={handleClickUpgrade}
                            >
                                {buttonText}
                            </Button>
                        </CardContent>

                        <CardActionArea
                            component="div"
                            sx={{
                                backgroundColor: 'grey.100',
                                cursor: 'initial',
                                height: '100%',
                            }}
                        >
                            <Divider />

                            <Box px={3} py={3}>
                                <Typography fontWeight={700} lineHeight="1.4">
                                    {translate('plans.featureDescription')}
                                </Typography>

                                <Box mt={2}>
                                    {planPackage.features.map(
                                        (feature, index) => (
                                            <PlanFeature
                                                check
                                                feature={feature}
                                                key={index}
                                            />
                                        )
                                    )}
                                </Box>
                            </Box>
                        </CardActionArea>
                    </Card>

                    {currentPlan && (
                        <PlanLimitModal
                            currentAmounts={currentAmounts}
                            currentPlan={currentPlan}
                            inSamePackage={inSamePackage}
                            open={openModalType === IPlanModalType.PlanLimit}
                            selectedPlan={selectedPlan}
                            onClose={() => setOpenModalType(undefined)}
                        />
                    )}

                    <CustomPlanModal
                        open={openModalType === IPlanModalType.CustomPlan}
                        onClose={() => setOpenModalType(undefined)}
                    />

                    <PlanManagerModal
                        currentPlan={currentPlan}
                        inSamePackage={inSamePackage}
                        open={openModalType === IPlanModalType.PlanManager}
                        owners={owners}
                        selectedPlan={selectedPlan}
                        onClose={() => setOpenModalType(undefined)}
                    />

                    <PlanDowngradeModal
                        buttonText={buttonText}
                        currentPlan={currentPlan}
                        currentPlanPackage={currentPlanPackage}
                        interval={currentPlan.interval}
                        nextPaymentDate={nextPaymentDate}
                        open={openModalType === IPlanModalType.PlanDowngrade}
                        selectedPlan={selectedPlan}
                        selectedPlanPackage={planPackage}
                        onClose={() => setOpenModalType(undefined)}
                    />

                    <IntervalChangeModal
                        buttonText={buttonText}
                        currentPlan={currentPlan}
                        open={openModalType === IPlanModalType.IntervalChange}
                        selectedPlan={selectedPlan}
                        onClose={() => setOpenModalType(undefined)}
                    />
                </>
            )}
        </Box>
    );
};
