import { RenderLeafProps } from 'slate-react';

import { TComponents, COMPONENTS } from '../options';

interface IProps extends RenderLeafProps {
    as?: TComponents;
    className?: string;
}

export const EditorLeaf = ({ as = 'span', children, className }: IProps) => {
    const Component = COMPONENTS[as] || as;

    return <Component className={className}>{children}</Component>;
};
