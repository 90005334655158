import { styled } from 'styled-components';

export const DrawerModuleSection = styled.div`
    position: relative;
    padding: ${({ theme }) => theme.spacing(3)};
    background-color: ${({ theme }) => theme.palette.grey['100']};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme }) => theme.spacing(6)};
    }

    > * {
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;
