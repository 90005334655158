import { IOpenOffsetType, ITimeDeltaFragment, Maybe } from 'graphql/types';
import { IModuleFormOffset } from 'training/types';
import { timeDeltaToDuration } from 'common/utils/formatDate';

/**
 * Return a value that can be used for offset in the form with the correct type based on the TimeDelta of the openOffset
 *
 * @param openOffset Maybe<ITimeDeltaFragment>
 * @returns offsetValue IModuleFormOffset
 */
export function getModuleOffsetFormValue(
    openOffset?: Maybe<ITimeDeltaFragment>
): IModuleFormOffset {
    if (!openOffset) {
        return {
            type: IOpenOffsetType.Unlocked,
            days: 0,
            hours: 0,
        };
    }

    let offsetDuration = timeDeltaToDuration(openOffset).shiftTo(
        'days',
        'hours'
    );

    const offsetValue = offsetDuration.valueOf();
    let offsetType = IOpenOffsetType.Zero;

    if (offsetValue < 0) {
        offsetType = IOpenOffsetType.OffsetNegative;
        offsetDuration = offsetDuration.negate();
    } else if (offsetValue > 0) {
        offsetType = IOpenOffsetType.Offset;
    }

    return {
        type: offsetType,
        days: offsetDuration.days,
        hours: offsetDuration.hours,
    };
}
