import { Box, Collapse } from '@mui/material';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import { Icon } from 'common/components/Icon';
import { Chip } from 'common/components/Chip';
import { Divider } from 'common/components/Divider';

interface IProps {
    children?: React.ReactNode;
    title: string;
    expand: boolean;
    onClick(event: React.MouseEvent<HTMLDivElement>): void;
}

export const ContentExpander = ({
    children,
    title,
    expand,
    onClick,
}: IProps) => (
    <Box width="100%">
        {/* Added negative marging to make the component 0 height and make spacing between components look better */}
        <Box my="-14px">
            <Box width="100%">
                <Divider>
                    <Chip
                        deleteIcon={
                            <Icon
                                className="icon"
                                path={expand ? mdiChevronUp : mdiChevronDown}
                                size="2.4rem"
                            />
                        }
                        label={title}
                        variant="outlined"
                        onClick={onClick}
                        onDelete={onClick}
                    />
                </Divider>
            </Box>
        </Box>
        <Collapse in={expand}>{children}</Collapse>
    </Box>
);
