import { useState, useRef, useEffect } from 'react';
import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSessionEndSubscription } from 'graphql/types';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';

export const SessionManager = () => {
    const [translate] = useTranslation();

    const isUnloading = useRef(false);
    const [showSessionEndedAlert, setShowSessionEndedAlert] = useState(false);

    useSessionEndSubscription({
        fetchPolicy: 'network-only',
        onData: ({ data: { data } }) => {
            const sessionEnded = data?.sessionEnd || false;

            // Check if we get a response and the current page isn't unloading.
            if (!sessionEnded || isUnloading.current) return;

            setShowSessionEndedAlert(true);
        },
    });

    const handleUnload = () => {
        isUnloading.current = true;
    };

    // Check if page is unloading so we can skip showing the alert when this happens.
    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
            isUnloading.current = false;
        };
    });

    if (!showSessionEndedAlert) return null;

    return (
        <AlertDialog
            actions={
                <>
                    <Button
                        autoFocus
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        {translate('reload')}
                    </Button>
                </>
            }
            open={showSessionEndedAlert}
            title={translate('reloadPageMessage.title')}
        >
            <DialogContentText
                color="text.primary"
                style={{ whiteSpace: 'pre-line' }}
                variant="body2"
            >
                {translate('reloadPageMessage.text')}
            </DialogContentText>
        </AlertDialog>
    );
};
