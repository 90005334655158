import { styled } from 'styled-components';

import { Img } from 'common/components/Img';

interface IProps extends React.HTMLAttributes<HTMLElement> {
    src?: string;
    caption?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
}

export const Figcaption = styled.figcaption`
    text-align: center;
    color: gray;
    margin-top: 10px;
`;

const BaseFigure = ({
    src,
    caption,
    className,
    children,
    ...other
}: IProps) => (
    <figure className={className} {...other}>
        {src && <Img src={src} />}
        {children}
        {caption && <Figcaption>{caption}</Figcaption>}
    </figure>
);

export const Figure = styled(BaseFigure)`
    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 0 0 16px;
`;
