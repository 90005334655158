import { useTranslation } from 'react-i18next';
import { MenuItem, TableRow } from '@mui/material';

import { TableCell, TableDropdownCell } from 'common/components/Table';
import { IRow } from 'common/types';
import { ICustomFontFragment } from 'graphql/types';
import { Checkbox } from 'common/components/Checkbox';
import { Link } from 'common/components/Link';

interface IProps {
    actionsDisabled?: boolean;
    customFonts: ICustomFontFragment[];
    rows: IRow[];
    onClickRemove: (id: string) => void;
}

export const TableRows = ({
    actionsDisabled,
    customFonts,
    rows,
    onClickRemove,
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <>
            {rows.map(({ index, onSelect, isSelected }) => {
                const customFont = customFonts[index] || {};
                const { id, family, weight, italic, file } = customFont;

                if (!id) return null;

                const checkboxCell = (
                    <TableCell>
                        <Checkbox
                            checked={isSelected}
                            disabled={actionsDisabled}
                            onClick={() => {
                                onSelect();
                            }}
                        />
                    </TableCell>
                );

                const dropdownActions = !actionsDisabled ? (
                    <TableDropdownCell>
                        <MenuItem onClick={() => onClickRemove(id)}>
                            {translate('remove')}
                        </MenuItem>
                    </TableDropdownCell>
                ) : (
                    <TableCell />
                );

                return (
                    <TableRow key={index}>
                        {checkboxCell}

                        <TableCell>
                            <Link href={file.url} target="_blank">
                                {family}
                            </Link>
                        </TableCell>

                        <TableCell>
                            {italic
                                ? translate('fonts.styles.italic')
                                : translate('fonts.styles.normal')}
                        </TableCell>

                        <TableCell>{weight}</TableCell>

                        {dropdownActions}
                    </TableRow>
                );
            })}
        </>
    );
};
