import { styled } from 'styled-components';
import { Box } from '@mui/material';

export const CardButtonIcon = styled(Box).attrs({ color: 'inherit' })`
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 1px solid ${({ theme }) => theme.palette.divider};
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardButton = styled(Box)`
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 300ms border-color;
    flex-direction: column;
    text-align: center;

    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    line-height: 1.2;

    &:hover {
        border-color: ${({ theme }) => theme.palette.divider};
    }
`;
