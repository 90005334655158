import { TFilterBarItem } from 'common/types';
import {
    IBasicGroupFragment,
    IExtraCategoryChoiceValue,
    IExtraCategoryOption,
    IGroupConditionFragment,
    IGroupListItemFragment,
} from 'graphql/types';
import { IGroupConditionInput } from 'user/types';

/*
 * GroupConditionFilter: Transforms group conditions to usable inputs for the group condition filter component
 */
export function getGroupConditionsFilterInputs(
    groupConditions?: IGroupConditionFragment[]
): IGroupConditionInput[] {
    return (
        groupConditions
            ?.map((groupCondition) => {
                const {
                    extraCategoryValues,
                    groups: groupsData,
                    id,
                } = groupCondition;

                const values = extraCategoryValues
                    .map((extraCategoryValue) => {
                        const {
                            category: { id: extraCategoryId, categoryType },
                        } = extraCategoryValue;

                        if (
                            categoryType === IExtraCategoryOption.Boolean ||
                            categoryType === IExtraCategoryOption.Datetime
                        ) {
                            return null;
                        }

                        const { choiceValue } =
                            extraCategoryValue as IExtraCategoryChoiceValue;

                        const stringValue = choiceValue?.stringValue;

                        return {
                            id: extraCategoryId,
                            stringValue,
                            color: choiceValue?.color,
                        };
                    })
                    .filter(Boolean);

                return {
                    id,
                    extraCategoryValues: values,
                    groups:
                        groupsData.edges
                            ?.map((edge) => edge?.node)
                            .filter(Boolean) || [],
                };
            })
            .filter(Boolean) || []
    );
}

/*
 * GroupConditionFilter: Transforms group conditions inputs to its actual mutation inputs
 */
export function getGroupConditionMutationInputs(
    groupConditionInputs?: IGroupConditionInput[]
) {
    return groupConditionInputs?.map((groupConditionInput) => {
        const { extraCategoryValues, groups } = groupConditionInput;

        return {
            extraCategoryValues: extraCategoryValues.map(
                ({ id, stringValue }) => ({
                    id,
                    stringValue,
                })
            ),
            groups: groups.map(({ id }) => id),
        };
    });
}

/*
 *  GroupConditionFilter: Get the filters that belong to the selected group condition filter
 */
export function getSelectedGroupConditionFilters(
    groupConditionInputValues?: IGroupConditionInput['extraCategoryValues'],
    extraCategoriesFilters?: TFilterBarItem[]
): TFilterBarItem[] {
    if (!groupConditionInputValues?.length || !extraCategoriesFilters?.length) {
        return [];
    }

    return groupConditionInputValues
        .map((groupConditionInputValue) => {
            const { id, stringValue } = groupConditionInputValue;

            const extraCategoryFilter = extraCategoriesFilters.find(
                (extraCategoryFilter) =>
                    extraCategoryFilter.children?.find(
                        ({ extraCategoryData }) => extraCategoryData?.id === id
                    )
            );

            if (!extraCategoryFilter) return null;

            const { children } = extraCategoryFilter;

            if (!children?.length) return null;

            const filter = children.find(
                (child) => child.value === stringValue
            );

            if (!filter) return null;

            return filter;
        })
        .filter(Boolean);
}

/*
 *  Get the added groups
 */
export const getAddedGroupIds = (
    initialGroups: (IGroupListItemFragment | IBasicGroupFragment)[],
    newGroups: (IGroupListItemFragment | IBasicGroupFragment)[]
) => {
    const addList = newGroups
        .filter(
            (newGroup) =>
                !initialGroups.some(
                    (initialGroup) => initialGroup.id === newGroup.id
                )
        )
        .map((group) => group.id);

    return !!addList.length ? addList : undefined;
};

/*
 *  Get the removed groups
 */
export const getRemovedGroupIds = (
    initialGroups: (IGroupListItemFragment | IBasicGroupFragment)[],
    newGroups: (IGroupListItemFragment | IBasicGroupFragment)[]
) => {
    const removeList = initialGroups
        .filter(
            (initialGroup) =>
                !newGroups.some((newGroup) => newGroup.id === initialGroup.id)
        )
        .map((group) => group.id);

    return !!removeList.length ? removeList : undefined;
};
