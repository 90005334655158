function getValidUrlRegExp() {
    const protocol = '(https?:\\/\\/)?';
    const domain = '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}';
    const port = '(\\:\\d+)?';
    const path = '(\\/[\\-a-z\\d%_.~+\\/]*\\/*)*';

    const url = `${protocol}${domain}${port}${path}`;

    const query = `(\\?[;&a-z\\d%_.~+=\\-]*)?(/|${url})?`;
    const fragment = '(#[\\/\\-a-z\\d_]*)?';
    const regexString = `^${url}${query}${fragment}$`;

    return new RegExp(regexString, 'i');
}

export const VALID_URL_REGEX = getValidUrlRegExp();

/**
 * Regex to find port number in string
 *
 * @example
 * let url = 'http://wend.pluvo.localhost:3000/path';
 * url = url.replace(PORT_REGEX, '');
 * console.log(url)
 *
 * // expected output: "http://wend.pluvo.localhost/path"
 */
export const PORT_REGEX = /:\d+/;
