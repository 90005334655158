import { FieldProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { mdiWater } from '@mdi/js';
import { Box } from '@mui/material';

import { Icon } from 'common/components/Icon';
import { Tooltip } from 'common/components/Tooltip';
import { FieldLabel } from 'common/components/FieldLabel';
import { Typography } from 'common/components/Typography';

import { ColorInputButton } from './ColorInputButton';

type TPopoverPosition = 'bottom-start' | 'bottom-end';

interface IProps extends FieldProps {
    fullWidth?: boolean;
    label?: string;
    popoverPosition?: TPopoverPosition;
    name?: string;
    tooltip?: string;
}

export const ColorPicker = ({
    fullWidth,
    label,
    popoverPosition,
    tooltip,
    field,
    form: { setFieldValue },
}: IProps) => {
    const { value, name } = field;
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState(value?.replace('#', ''));
    const wrapperRef = useRef<HTMLDivElement>();
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    /*
     * Handles outside click of the component to close the picker
     */
    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            const { current: wrapperElement } = wrapperRef || {};

            if (!wrapperElement || !event.target) return;

            if (!wrapperElement.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', onClick);

        return () => {
            document.removeEventListener('mousedown', onClick);
        };
    }, []);

    const position =
        !popoverPosition || popoverPosition === 'bottom-start'
            ? { left: 0 }
            : { right: 0 };

    return (
        <Tooltip placement="top" title={tooltip || ''}>
            <Box ref={wrapperRef} sx={{ position: 'relative', width: '100%' }}>
                {!!label && (
                    <FieldLabel>
                        <Typography>{label}</Typography>
                    </FieldLabel>
                )}

                <ColorInputButton
                    backgroundColor={`#${color || 'ffffff'}`}
                    sx={fullWidth ? { width: '100%' } : undefined}
                    onClick={() => setOpen(true)}
                >
                    <Icon path={mdiWater} size="3rem" />
                </ColorInputButton>

                {open && (
                    <>
                        <Box
                            sx={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                zIndex: 9999,
                                ...position,
                            }}
                        >
                            <ChromePicker
                                disableAlpha
                                color={color}
                                onChange={(color) => {
                                    // Remove hashtag from new color
                                    const newColor = color.hex.replace('#', '');
                                    setColor(newColor);

                                    // Add debounce before setting new field value
                                    if (timer) clearTimeout(timer);

                                    setTimer(
                                        setTimeout(() => {
                                            // Set new value for formik field.
                                            setFieldValue(name, newColor);
                                        }, 100)
                                    );
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Tooltip>
    );
};
