import { useTranslation } from 'react-i18next';

import {
    useCreateCollectionMutation,
    ICreateLibraryCollectionInput,
    SubCollectionFragmentDoc,
} from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { LibraryCollectionForm } from 'library/components/forms/LibraryCollectionForm';
import { ApolloError } from 'common/components/ApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

interface IProps {
    pageTitle?: string;
    open?: boolean;
    parentId: string;
    onCreate?(): void;
    onClose?(): void;
}

export const LibraryCollectionCreateDrawer = ({
    pageTitle,
    open,
    parentId,
    onCreate,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();

    const [createCollection, { error: createError }] =
        useCreateCollectionMutation({
            update: (cache, { data }) => {
                const newCollection = data?.createLibraryCollection?.collection;

                if (!newCollection) return;

                // Add new collection to collection list in collection
                cache.modify({
                    id: `LibraryCollection:${parentId}`,
                    fields: {
                        collections(cachedCollectionRefs) {
                            const newCollectionRef = cache.writeFragment({
                                id: cache.identify(newCollection),
                                fragment: SubCollectionFragmentDoc,
                                data: newCollection,
                            });

                            return [...cachedCollectionRefs, newCollectionRef];
                        },
                    },
                });

                displaySnackbar(
                    translate('libraryCollectionActionSuccess.create'),
                    {
                        variant: 'success',
                    }
                );

                onCreate && onCreate();
            },
        });

    const initialValues = {
        title: '',
        description: '',
        active: false,
        accessTrainings: [],
        accessGroups: [],
    };

    return (
        <PageDrawer open={open} onClose={onClose}>
            {createError && <ApolloError error={createError} />}

            {pageTitle && (
                <PageTitle mixpanelTitle="New collection - Library">{`${pageTitle} - ${translate(
                    'newCollection'
                )}`}</PageTitle>
            )}

            <LibraryCollectionForm
                initialValues={initialValues}
                onSubmit={(values) => {
                    // Before submitting the collection we want to change accessGroups and accessTraining to a
                    // single array of uid's
                    const {
                        accessGroups,
                        accessTrainings,
                        ...collectionInput
                    } = values;

                    const access = [
                        ...accessTrainings.map((training) => training.id),
                        ...accessGroups.map((group) => group.id),
                    ];

                    const newCollection = {
                        ...collectionInput,
                        access,
                        parentId,
                    } as ICreateLibraryCollectionInput;

                    return createCollection({
                        variables: {
                            collection: newCollection,
                        },
                    });
                }}
            />
        </PageDrawer>
    );
};
