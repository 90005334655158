import { IGroupListItemFragment } from 'graphql/types';

export function isGroupSelected(
    selectedGroups: IGroupListItemFragment[],
    group: IGroupListItemFragment
) {
    return selectedGroups.some(
        (selectedGroup) => selectedGroup.id === group.id
    );
}

export function isGroupAdded(
    addedGroups: IGroupListItemFragment[],
    group: IGroupListItemFragment
) {
    return addedGroups.some((addedGroup) => addedGroup.id === group.id);
}

export function getGroupSelectStatus(
    selectedGroups: IGroupListItemFragment[],
    addedGroups: IGroupListItemFragment[],
    group: IGroupListItemFragment
) {
    const isSelected = isGroupSelected(selectedGroups, group);
    const isAdded = isGroupAdded(addedGroups, group);

    return { isSelected, isAdded };
}
