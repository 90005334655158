import { useState, useEffect, useRef } from 'react';
import { IconButton, InputBase } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mdiClose, mdiMagnify } from '@mdi/js';

import { Icon } from 'common/components/Icon';

interface IProps extends Omit<InputBaseProps, 'onChange' | 'value'> {
    onClear?: () => void;
    onSearch?(searchValue: string): void;
    onEnterPress?(searchValue: string): void;
    onClickSearch?(searchValue: string): void;
    initialValue?: string;
}

export const Bar = ({
    onClear,
    onSearch,
    onEnterPress,
    onClickSearch,
    endAdornment,
    placeholder,
    initialValue = '',
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();
    const [value, setValue] = useState<string>(initialValue);
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        // Update value on changed initialValue
        setValue(initialValue || '');
    }, [initialValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onSearch && onSearch(event.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') return;

        onEnterPress && onEnterPress(value);
    };

    const handleClean = () => {
        setValue('');

        inputRef?.current?.focus();

        onSearch && onSearch('');

        onClear && onClear();
    };

    const handleClickSearch = () => {
        onClickSearch && onClickSearch(value);
    };

    const startButton = (
        <IconButton
            color="inherit"
            edge="start"
            size="small"
            onClick={handleClickSearch}
        >
            <Icon path={mdiMagnify} size="2.4rem" />
        </IconButton>
    );

    let endButtons;

    if (value) {
        endButtons = (
            <IconButton
                color="inherit"
                edge={endAdornment ? false : 'end'}
                size="small"
                onClick={handleClean}
            >
                <Icon path={mdiClose} size="2.4rem" />
            </IconButton>
        );
    }

    if (endAdornment) {
        endButtons = endButtons ? (
            <>
                {endButtons}
                {endAdornment}
            </>
        ) : (
            endAdornment
        );
    }

    return (
        <InputBase
            placeholder={placeholder || translate('filterBarPlaceholder')}
            {...other}
            fullWidth
            endAdornment={endButtons}
            inputProps={{ ref: inputRef }}
            startAdornment={startButton}
            value={value}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
        />
    );
};

export const SearchBar = styled(Bar)`
    color: ${({ theme }) => theme.palette.text.secondary};
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: ${({ theme }) => theme.spacing(0, 2)};
    min-height: 51px;

    input {
        margin: ${({ theme }) => theme.spacing(0, 2)};

        &::placeholder {
            color: ${({ theme }) => theme.palette.grey[800]};
        }
    }
`;
