import {
    Box,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    Stack,
    Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { mdiCertificate } from '@mdi/js';

import { ITrainingFragment, ITrainingUserEdgeFragment } from 'graphql/types';
import { TableActionBar, TableCell, TableHead } from 'common/components/Table';
import {
    IPaginationProps,
    ITableSelectionProps,
    TTableAction,
} from 'common/types';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import { Checkbox } from 'common/components/Checkbox';
import { Pagination } from 'common/components/Pagination';
import { Typography } from 'common/components/Typography';
import { TrainingParticipantTableRow } from 'training/components/TrainingParticipantTable/TrainingParticipantTableRow';
import { UserTrainingEmailDropdownMenu } from 'training/components/UserTrainingEmailDropdownMenu';
import { CertificateGenerateDialog } from 'common/components/CertificateGenerateDialog';
import { useFrontendPermissions } from 'user/hooks';

interface IProps {
    isEditable?: boolean;
    participants?: ITrainingUserEdgeFragment[];
    selectionProps?: ITableSelectionProps<ITrainingUserEdgeFragment>;
    tableActions?: TTableAction[];
    training: ITrainingFragment;
    canManageTraining?: boolean;
    isGroupManager?: boolean;
    paginationProps?: IPaginationProps;
}

export const TrainingParticipantTable = ({
    isEditable,
    participants,
    selectionProps,
    tableActions,
    training,
    canManageTraining,
    isGroupManager,
    paginationProps,
}: IProps) => {
    const [translate] = useTranslation();
    const [certificateGenerateDialogOpen, setCertificateGenerateDialogOpen] =
        useState(false);

    const { canCreate: canGenerateCertificate } = useFrontendPermissions(
        'generateCertificate'
    );

    const { paginationSettings, setPage } = paginationProps || {};

    const {
        rows = [],
        isSelected,
        selected,
        selectableItems = [],
        onSelectAll,
    } = selectionProps || {};

    const selectedParticipantIds = useMemo(() => {
        if (!selected || !participants) return [];

        return participants
            ?.map((participantEdge) => {
                if (!selected.includes(participantEdge.id)) return null;

                return participantEdge.node.id;
            })
            .filter(Boolean);
    }, [selected]);

    const actions = (
        <Stack
            direction="row"
            divider={<Divider flexItem orientation="vertical" />}
            spacing={1}
            sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
            <UserTrainingEmailDropdownMenu
                disabled={!isSelected}
                trainingId={training.id}
                userIds={selectedParticipantIds}
                onSendEmailComplete={() => {
                    if (!!selected?.length) onSelectAll?.();
                }}
            />

            {canGenerateCertificate && (
                <Tooltip
                    title={translate('certificateGenerateDialog.tooltip', {
                        count: selectedParticipantIds.length,
                    })}
                >
                    <IconButton
                        color="inherit"
                        disabled={!isSelected}
                        iconPath={mdiCertificate}
                        iconSize="2.4rem"
                        onClick={() => {
                            setCertificateGenerateDialogOpen(true);
                        }}
                    />
                </Tooltip>
            )}

            {tableActions?.map(({ tooltipTitle, iconPath, onClick }, index) => (
                <Tooltip key={index} title={tooltipTitle}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 'auto',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            disabled={!isSelected}
                            iconPath={iconPath}
                            iconSize="2.4rem"
                            onClick={() => {
                                onClick?.(selectedParticipantIds);
                            }}
                        />
                    </Box>
                </Tooltip>
            ))}
        </Stack>
    );

    if (!rows.length)
        return (
            <Box sx={{ mt: 8, textAlign: 'center' }}>
                <Typography>
                    {translate('trainingHasNoParticipants')}
                </Typography>
            </Box>
        );

    return (
        <>
            <TableContainer>
                {isEditable && (
                    <TableActionBar
                        actions={actions}
                        selectionProps={selectionProps}
                    />
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            {isEditable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={false}
                                        disabled={
                                            isSelected ||
                                            !selectableItems.length
                                        }
                                        onChange={onSelectAll}
                                    />
                                </TableCell>
                            )}
                            <TableCell>{translate('name')}</TableCell>
                            {(canManageTraining || isGroupManager) && [
                                <TableCell key="participant-score">
                                    {translate('score')}
                                </TableCell>,
                                <TableCell key="participant-progress">
                                    {translate('progress')}
                                </TableCell>,
                            ]}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(({ index, onSelect, isSelected }) => {
                            const trainingUser = participants?.[index] || null;

                            if (!trainingUser) return null;

                            return (
                                <TrainingParticipantTableRow
                                    canManageTraining={canManageTraining}
                                    isEditable={isEditable}
                                    isSelected={isSelected}
                                    key={trainingUser.id}
                                    training={training}
                                    trainingUser={trainingUser}
                                    userIsGroupManager={isGroupManager}
                                    onSelect={onSelect}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {!!paginationSettings && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate(
                            'trainingParticipants.participants',
                            {
                                count: paginationSettings.count,
                            }
                        )}
                        onChange={(page: number) => {
                            setPage?.(page);
                        }}
                    />
                </Box>
            )}
            {isEditable && canGenerateCertificate && (
                <CertificateGenerateDialog
                    open={certificateGenerateDialogOpen}
                    userIds={selectedParticipantIds}
                    onClose={() => setCertificateGenerateDialogOpen(false)}
                />
            )}
        </>
    );
};
