import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { Typography } from 'common/components/Typography';

interface IProps {
    activeStep?: number;
    className?: string;
}

const BaseOrganisationOnboardingNavigation = ({
    activeStep = 0,
    className,
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <Box
            alignItems="center"
            className={className}
            display="flex"
            flexWrap="wrap"
        >
            <Stepper alternativeLabel activeStep={activeStep}>
                <Step completed={activeStep > 0}>
                    <StepLabel />
                </Step>
                <Step completed={activeStep > 1}>
                    <StepLabel />
                </Step>
                <Step completed={activeStep > 2}>
                    <StepLabel />
                </Step>
            </Stepper>
            <Box>
                <Typography variant="body2">
                    {translate('canBeChangedLater')}
                </Typography>
            </Box>
        </Box>
    );
};

export const OrganisationOnboardingNavigation = styled(
    BaseOrganisationOnboardingNavigation
)`
    width: 100%;

    .MuiStepper-root {
        width: 200px;

        // Set negative margin to align it correctly
        margin-left: -21px;
        margin-right: auto;

        padding-left: 0;
        padding-right: 0;

        flex: 0 0 auto;
    }

    .MuiStepIcon-text {
        font-size: ${({ theme }) => theme.typography.pxToRem(12)};
    }
`;
