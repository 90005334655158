import { IFilterFormItem } from 'common/types';
import { userGroupsFilter } from 'common/utils/drawerFilters';
import {
    startDateRangeFilter,
    endDateRangeFilter,
    completionDateRangeFilter,
    progressFilter,
    scoreFilter,
    completionStatusFilter,
    scoreThresholdReachedFilter,
} from 'report/utils/filter';

export const PORTFOLIO_REPORT_FILTERS = [
    startDateRangeFilter('reportFilters.startDateRangePortfolio'),
    endDateRangeFilter(),
    userGroupsFilter(),
] as IFilterFormItem[];

export const COURSE_REPORT_FILTERS = [
    startDateRangeFilter(),
    completionDateRangeFilter(),
    userGroupsFilter(),
    progressFilter(),
    scoreFilter(),
    completionStatusFilter(),
    scoreThresholdReachedFilter(),
] as IFilterFormItem[];

export const TRAINING_REPORT_FILTERS = [
    startDateRangeFilter(),
    userGroupsFilter(),
    progressFilter(),
    scoreFilter(),
    scoreThresholdReachedFilter(),
] as IFilterFormItem[];

export const PORTFOLIO_TRAINING_FILTERS = [
    startDateRangeFilter(),
    progressFilter(),
    scoreFilter(),
    scoreThresholdReachedFilter(),
] as IFilterFormItem[];

export const PORTFOLIO_OFFER_FILTERS = [
    startDateRangeFilter(),
    progressFilter(),
    scoreFilter(),
    scoreThresholdReachedFilter(),
] as IFilterFormItem[];

export const REPORT_FILTERS = {
    portfolio: PORTFOLIO_REPORT_FILTERS,
    course: COURSE_REPORT_FILTERS,
    training: TRAINING_REPORT_FILTERS,
    portfolioTraining: PORTFOLIO_TRAINING_FILTERS,
    offer: TRAINING_REPORT_FILTERS,
    portfolioOffer: PORTFOLIO_OFFER_FILTERS,
};
