import { useTranslation } from 'react-i18next';
import { mdiWebhook } from '@mdi/js';
import { Box } from '@mui/material';

import { ILinkModuleListItemFragment } from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { Link } from 'common/components/Link';
import { Icon } from 'common/components/Icon';
import { ListFooter } from 'common/components/ListFooter';
import { ActionButton } from 'common/components/ActionButton';
import { Typography } from 'common/components/Typography';

export const AfasWebhookModule = ({
    id,
    trainingId,
}: TTrainingModuleProps<ILinkModuleListItemFragment>) => {
    const [translate] = useTranslation();

    return (
        <ListFooter
            disableHover
            button={false}
            component={Link}
            params={{
                trainingId,
                moduleId: id,
                type: 'afasTrigger',
            }}
            py={2}
            sx={{
                backgroundColor: 'grey.100',
            }}
            to="TRAINING_UPDATE_MODULE"
        >
            <ActionButton outlined size="medium">
                <Icon path={mdiWebhook} size="2.4rem" />
            </ActionButton>

            <Box ml={2}>
                <Typography>{translate('afasTrigger')}</Typography>
            </Box>
        </ListFooter>
    );
};
