import React, { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResetPortfolioItemsProgressMutation } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

interface IProps {
    portfolioItemIds: string[];
    onSuccess?(): void;
    children?: React.ReactNode;
}

export const ResetPortfolioItemProgressAction = ({
    portfolioItemIds,
    onSuccess,
    children,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();

    const [showResetProgressDialog, setShowResetProgressDialog] =
        useState(false);

    const [resetProgress, { loading: loadingResetProgress }] =
        useResetPortfolioItemsProgressMutation();

    const handleResetProgress = async () => {
        let response;

        try {
            response = await resetProgress({
                variables: {
                    portfolioItemIds: portfolioItemIds,
                },
                update: (cache, { data }) => {
                    const { succeededIds = [] } =
                        data?.resetPortfolioItemsProgress || {};

                    const newFieldData = {
                        progress: () => 0,
                        score: () => 0,
                        startDate: () => new Date().toISOString(),
                        completionDate: () => null,
                        timeSpent: () => null,
                    };

                    // Clear portfolio item data after reset
                    succeededIds.forEach((id) => {
                        cache.modify({
                            id: cache.identify({
                                __typename: 'PortfolioItem',
                                id,
                            }),
                            fields: newFieldData,
                        });
                        cache.modify({
                            id: cache.identify({
                                __typename: 'PortfolioItemReport',
                                id,
                            }),
                            fields: newFieldData,
                        });
                    });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        const { data } = response;
        const { succeededIds, failedIds } =
            data?.resetPortfolioItemsProgress || {};

        if (!!succeededIds?.length) {
            let messageTransKey = 'reportPage.progressResetSuccess';

            if (!failedIds?.length) {
                // If no failed ids, show success message for all
                messageTransKey = 'reportPage.progressResetSuccessAll';
            }

            displaySnackbar(
                translate(messageTransKey, {
                    count: succeededIds.length,
                }),
                {
                    variant: 'success',
                }
            );
        }

        if (failedIds?.length) {
            displaySnackbar(
                translate('reportPage.progressResetFailed', {
                    count: failedIds.length,
                }),
                {
                    variant: 'error',
                }
            );
        }

        setShowResetProgressDialog(false);
        onSuccess?.();
    };

    return (
        <>
            {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return null;

                return cloneElement(child as React.ReactElement, {
                    onClick: () => setShowResetProgressDialog(true),
                });
            })}

            <ConfirmDialog
                confirmText={translate('resetProgress')}
                loading={loadingResetProgress}
                open={showResetProgressDialog}
                title={translate('resetProgressDialog.title')}
                onCancel={() => {
                    setShowResetProgressDialog(false);
                }}
                onClose={() => setShowResetProgressDialog(false)}
                onConfirm={handleResetProgress}
            >
                {translate('resetProgressDialog.text')}
            </ConfirmDialog>
        </>
    );
};
