import {
    IExternalContentListItemFragment,
    IExternalContentsSelectorQueryVariables,
    useExternalContentsSelectorQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export const useExternalContentList = (
    queryVariables?: IExternalContentsSelectorQueryVariables
) => {
    const { showApolloError } = useApolloError();
    const { data, loading } = useExternalContentsSelectorQuery({
        variables: queryVariables,
        onError: showApolloError,
    });

    const externalContentsObject = data?.externalContents;
    const externalContentsCount = externalContentsObject?.count;
    const externalContents =
        (externalContentsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IExternalContentListItemFragment[]) || [];

    return {
        externalContents,
        externalContentsCount,
        loading,
    };
};
