import { useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useIsDetailPage } from 'common/hooks/useIsDetailPage';

export const ScrollReset = () => {
    const location = useLocation();
    const isDetailPage = useIsDetailPage();
    const prevIsDetailPageRef = useRef<boolean | undefined>();
    const prevPagePathnameRef = useRef(location.pathname);
    const match = matchPath(
        {
            path: location.pathname,
            caseSensitive: false,
            end: false,
        },
        prevPagePathnameRef.current
    );

    useEffect(() => {
        // If current page is not detail page and previous page is not detail page
        const notDetailPage = !isDetailPage && !prevIsDetailPageRef.current;

        // If current page is not detail page and came from a route thats not its parent
        const notParentPage = !isDetailPage && !match;

        // Check if current page is the same as previous page
        const isSamePage = prevPagePathnameRef.current === location.pathname;

        const resetScroll = (notDetailPage || notParentPage) && !isSamePage;

        prevIsDetailPageRef.current = isDetailPage;
        prevPagePathnameRef.current = location.pathname;

        if (resetScroll) window.scrollTo(0, 0);
    }, [location, isDetailPage, match]);

    return null;
};
