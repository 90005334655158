import { useEffect, useMemo } from 'react';

import { useManageableOfferEventEnrollmentsForEventQuery } from 'graphql/types';
import { usePagination } from 'common/hooks/usePagination';
import { useSelection } from 'common/components/Table';
import { TFilterBarItem } from 'common/types';
import { useUserSearch } from 'user/hooks/useUserSearch';
import { useUserQueryVariables } from 'user/hooks/useUserQueryVariables';
import { TOfferEventDetailEnrollment } from 'offer/types';

export const useOfferEventManageableEnrollmentList = (
    offerEventId: string,
    disabledUsers: TOfferEventDetailEnrollment[] = []
) => {
    const {
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
        filters,
    } = useUserSearch();

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { userQueryVariables } = useUserQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const { data, loading: enrollmentsLoading } =
        useManageableOfferEventEnrollmentsForEventQuery({
            variables: { offerEventId: offerEventId, ...userQueryVariables },
        });

    const enrollmentsObject = data?.manageableOfferEventEnrollmentsForEvent;
    const enrollmentsCount = enrollmentsObject?.count;
    const enrollments =
        enrollmentsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) || [];

    const selectFilter = (user: TOfferEventDetailEnrollment) =>
        !disabledUsers?.some((addedUser) => addedUser.id === user.id);

    const selectionProps = useSelection(enrollments, selectFilter);

    const loading = filtersLoading || enrollmentsLoading;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(enrollmentsCount || 0);
    }, [enrollmentsCount, loading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect(selected);
    };

    return {
        enrollments,
        selectionProps,
        loading,
        handleSearch,
        handleFilterSelect,
        searchQueryParam,
        filters,
        filtersLoading,
        selectedFilters,
        paginationSettings,
        setPage,
    };
};
