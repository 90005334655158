import { TableRow } from '@mui/material';

import DEFAULT_OFFER_CARD from 'assets/images/default-offercard.webp';
import { TableCell, TableImage } from 'common/components/Table';
import { ICourseOfferEventListItemFragment } from 'graphql/types';
import { ChipGroup } from 'common/components/Chip';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    offerEvent: ICourseOfferEventListItemFragment;
}

export const CourseOfferEventTableRow = ({ offerEvent }: IProps) => {
    const { openGlobalDrawer } = useGlobalDrawer();

    const { id, title, offer, image, extraCategories } = offerEvent;

    const offerEventImage = image?.url || DEFAULT_OFFER_CARD;

    const handleClick = () => {
        const { id: offerId, hasDetailPage } = offer;

        openGlobalDrawer({
            type: hasDetailPage
                ? IGlobalDrawerType.OfferEvent
                : IGlobalDrawerType.Offer,
            itemId: hasDetailPage ? id : offerId,
        });
    };

    return (
        <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleClick}>
            <TableCell sx={{ width: '1px' }}>
                <TableImage src={offerEventImage} />
            </TableCell>
            <TableCell>
                {title}

                <ChipGroup sx={{ my: 0.25 }}>
                    {!!extraCategories?.length && (
                        <ExtraCategoryLabels
                            extraCategoryValues={extraCategories}
                        />
                    )}
                </ChipGroup>
            </TableCell>
        </TableRow>
    );
};
