const HOME = '/';

// Training Urls
const TRAINING = '/training/:trainingId';
const TRAINING_PARTICIPANTS = `${TRAINING}/participants`;
const TRAINING_TRAINERS = `${TRAINING}/trainers`;
const TRAINING_SHARED_FILES = `${TRAINING}/shared-files`;
const TRAINING_ASSIGNMENT = `${TRAINING}/d/assignment/:moduleId`;
const TRAINING_ASSIGNMENT_GRADE = `${TRAINING}/d/assignment/:moduleId/grade`;
const TRAINING_ASSIGNMENT_FOR_USER = `${TRAINING_ASSIGNMENT_GRADE}/user/:userId/*`;
const TRAINING_CERTIFICATE = `${TRAINING}/certificate/:certificateId`;
const TRAINING_CREATE_MODULE = `${TRAINING}/new-module`;
const TRAINING_CREATE_MODULE_TYPE = `${TRAINING}/new-module/:type`;
const TRAINING_CREATE_MODULE_IN_GROUP = `${TRAINING}/:moduleGroupId/new-module`;
const TRAINING_CREATE_MODULE_TYPE_IN_GROUP = `${TRAINING}/:moduleGroupId/new-module/:type`;
const TRAINING_UPDATE_MODULE = `${TRAINING}/:type/:moduleId/edit/*`;
const TRAINING_EDIT_MODULE_GROUP = `${TRAINING}/:moduleGroupId/edit`;
const TRAINING_CREATE_MODULE_GROUP = `${TRAINING}/module-group/create`;
const TRAINING_MODULE_SELECT = '#select';
const TRAINING_CREATE = '#new-learning-journey';
const TRAINING_EDIT = '#edit-learning-journey';

// Training management urls
const MANAGEMENT_TRAINING_BASE = '/trainings/:id';
const TRAINING_PROGRESS = `${MANAGEMENT_TRAINING_BASE}/user/:userId`;
const MANAGEMENT_TRAINING_EDIT_MODULE_GROUP = `${MANAGEMENT_TRAINING_BASE}/event/:moduleGroupId/`;
const MANAGEMENT_TRAINING_CREATE_MODULE_GROUP = `${MANAGEMENT_TRAINING_BASE}/event/`;
const TRAINING_MODULE_GROUP_CONDITIONS = `${MANAGEMENT_TRAINING_BASE}/event/:moduleGroupId/conditions/`;
const MANAGEMENT_TRAINING_PARTICIPANTS = `${MANAGEMENT_TRAINING_BASE}/participants/`;
const MANAGEMENT_TRAINING_GROUPS = `${MANAGEMENT_TRAINING_BASE}/groups/`;
const MANAGEMENT_TRAINING_TRAINERS = `${MANAGEMENT_TRAINING_BASE}/trainers/`;
const MANAGEMENT_TRAINING = `${MANAGEMENT_TRAINING_BASE}/groups/`;
const TRAINING_MANAGEMENT_LIST = '/training/';

// User urls
const USER_BASE = '/user/:id';

const PORTFOLIO = '/portfolio';
const PORTFOLIO_ITEM_CREATE = `${PORTFOLIO}/item/new`;
const PORTFOLIO_ITEM_EDIT = `${PORTFOLIO}/item/:itemId/edit`;
const PORTFOLIO_ITEM_VIEW = `${PORTFOLIO}/item/:itemId`;
const PORTFOLIO_ITEM_DRAWER = '#portfolio-item';
const PORTFOLIO_GROUP_CREATE = `${PORTFOLIO}/group/new`;
const PORTFOLIO_GROUP_EDIT = `${PORTFOLIO}/group/:groupId/edit`;
const PORTFOLIO_TRAININGS = `${PORTFOLIO}/trainings`;
const PORTFOLIO_OFFERS = `${PORTFOLIO}/offers`;
const PORTFOLIO_MEETINGS = `${PORTFOLIO}/meetings`;

const USER_PORTFOLIO = `${USER_BASE}${PORTFOLIO}`;
const USER_PORTFOLIO_ITEM_VIEW = `${USER_PORTFOLIO}/item/:itemId`;
const USER_PORTFOLIO_SETTINGS = '#portfolio-settings';
const USER_TRAININGS = `${USER_PORTFOLIO}/trainings`;
const USER_OFFERS = `${USER_PORTFOLIO}/offers`;
const USER_MEETINGS = `${USER_PORTFOLIO}/meetings`;

// Development plan
const DEVELOPMENT_PLAN = '/development-plan';
const DEVELOPMENT_ITEM_CREATE = `${DEVELOPMENT_PLAN}/item/new`;
const DEVELOPMENT_ITEM_EDIT = `${DEVELOPMENT_PLAN}/item/:itemId/edit`;
const DEVELOPMENT_GROUP_CREATE = `${DEVELOPMENT_PLAN}/group/new`;
const DEVELOPMENT_GROUP_EDIT = `${DEVELOPMENT_PLAN}/group/:groupId/edit`;

const USER_DEVELOPMENT_PLAN = `${USER_BASE}/development-plan`;
const USER_DEVELOPMENT_ITEM_VIEW = `${USER_DEVELOPMENT_PLAN}/item/:itemId`;

const PROFILE_DRAWER = '#my-profile';
const CHAT = '/chat/';
const USER_CHAT = '/chat/user/:id/';

const PORTFOLIO_REPORT = '/report/portfolio';
const TRAINING_REPORT = '/report/training';
const OFFER_REPORT = '/report/offer';
const MEETING_REPORT = '/report/meeting';
const COURSE_REPORT = '/report/course';

// Library Urls
const LIBRARY = '/library';
const LIBRARY_COLLECTION = `${LIBRARY}/:id`;

const LIBRARY_COLLECTION_CREATE = `${LIBRARY_COLLECTION}/new`;
const LIBRARY_COLLECTION_EDIT = `${LIBRARY_COLLECTION}/edit`;
const LIBRARY_COLLECTION_CREATE_ARTICLE = `${LIBRARY_COLLECTION}/new-article`;
const LIBRARY_ARTICLE = `${LIBRARY}/article/:id`;
const LIBRARY_ARTICLE_EDIT = `${LIBRARY_ARTICLE}/edit`;
const LIBRARY_ARTICLE_EDIT_CONTENT = `${LIBRARY_ARTICLE}/edit-content`;

const VIDEO_MANAGEMENT_LIST = '/video';
const VIDEO_EDIT = `${VIDEO_MANAGEMENT_LIST}/:videoId/edit`;

const MANAGEMENT_MODULES_TRAININGS = '/trainings/';
const MANAGEMENT_MODULES_COURSES = '/courses/';
const MANAGEMENT_MODULES_CERTIFICATES = '/certificates/';

const USER_MANAGEMENT_LIST = '/user';
const USER_MANAGEMENT_CREATE = `${USER_MANAGEMENT_LIST}/new`;
const USER_MANAGEMENT_LIST_ARCHIVED = `${USER_MANAGEMENT_LIST}/archived`;

const MANAGEMENT_USERS_USERS = '/users/';
const MANAGEMENT_USERS_CREATE = '/users/create/';
const GROUP_MANAGEMENT_LIST = '/group';
const GROUP_CREATE = `${GROUP_MANAGEMENT_LIST}/new`;
const GROUP_EDIT = `${GROUP_MANAGEMENT_LIST}/d/:id/edit`;
const MANAGEMENT_GROUPS_USER_CREATE = `${MANAGEMENT_USERS_USERS}create/`;
const MANAGEMENT_GROUPS_USER_IMPORT = `${MANAGEMENT_USERS_USERS}import/for_group/`;
const MANAGEMENT_TRAINING_PARTICIPANT_IMPORT = `${MANAGEMENT_USERS_USERS}import/for_training/:id/participant/`;
const MANAGEMENT_USERS_ENROLLMENTS = '/enrollments/pending/';
const MANAGEMENT_USERS_ENROLLMENTS_WAITING_LIST = '/enrollments/waiting-list/';
const MANAGEMENT_USERS_ENROLLMENTS_ENROLLED = '/enrollments/enrolled/';
const MANAGEMENT_USERS_ENROLLMENTS_DENIED = '/enrollments/denied/';
const MANAGEMENT_USERS_ENROLLMENTS_COMPLETED = '/enrollments/present/';
const MANAGEMENT_USERS_ENROLLMENTS_ABSENT = '/enrollments/absent/';
const MANAGEMENT_USERS_REPORTING = '/reports/training/report/';
const MANAGEMENT_COURSE_REPORTING = '/reports/course/report/';

const COURSE_MANAGEMENT_LIST = '/course';
const COURSE_CREATE = '/course/new';
const COURSE_EDIT = `${COURSE_MANAGEMENT_LIST}/:id/edit`;

const MANAGEMENT_ACADEMY = '#academy-management';
const MANAGEMENT_SETTINGS_LOCATIONS = '/trainings/locations/';
const MANAGEMENT_SETTINGS_FACETS = '/organisation/settings/';
const MANAGEMENT_SETTINGS_STYLE = '/organisation/style/';
const MANAGEMENT_SETTINGS_CALENDAR = '/organisation/sync/';
const MANAGEMENT_SETTINGS_EMAILS = '/mailing/template_tags/';
const MANAGEMENT_SETTINGS_CONNECTIONS = '/oauth/';
const MANAGEMENT_SETTINGS_HISTORY = '/history/';

const MANAGEMENT_ORGANISATION_ORGANISATION = '/organisation/profile/';
const MANAGEMENT_ORGANISATION_SUBSCRIPTION = '/billing/usage/';
const MANAGEMENT_ORGANISATION_PAYMENTDETAILS = '/billing/pay/';
const MANAGEMENT_ORGANISATION_INVOICES = '/billing/';

const OFFER_LIST = `/offer`;
const OFFER_SETTINGS_EDIT = '#offer-settings';
const OFFER_MEETING_CREATE = `${OFFER_LIST}/new/meeting`;
const OFFER_CREATE = `${OFFER_LIST}/new/offer`;
const OFFER_DETAIL = `${OFFER_LIST}/:id`;
const OFFER_EDIT_CONTENT = `${OFFER_LIST}/:id/edit-content`;
const OFFER_EDIT = `${OFFER_LIST}/:id/edit`;
const OFFER_DETAIL_DRAWER = `#offer`;
const OFFER_EVENT_DETAIL_DRAWER = `#offer-event`;
const CREATE_OFFER_EVENT = `${OFFER_LIST}/:id/event/new`;

const ORGANISATION = '/academy';
const ORGANISATION_LANGUAGE = `${ORGANISATION}/language`;
const ORGANISATION_IMAGES = `${ORGANISATION}/images`;
const ORGANISATION_COLORS = `${ORGANISATION}/colors`;
const ORGANISATION_SETTINGS = `#academy-settings`;
const ORGANISATION_SINGLE_SIGN_ON = `#single-sign-on`;
const ORGANISATION_EXTRA_CATEGORIES = `#extra-categories`;

const INTRO = '#introduction';
const LOGIN = '/login/';
const LOGOUT = '/logout/';
const CHANGE_PASSWORD = '/change-password/:token';
const WELCOME = '/welcome/:token';

const PAGE_NOT_FOUND = '/page-not-found';

// Because we changed the urls to not be translated anymore we want some old urls to redirect
const LIBRARY_NL_REDIRECT = '/bibliotheek';
const LIBRARY_COLLECTION_NL_REDIRECT = '/bibliotheek/:id';
const LIBRARY_ARTICLE_NL_REDIRECT = '/bibliotheek/artikel/:id';
const CHANGE_PASSWORD_NL_REDIRECT = '/wijzig-wachtwoord/:token';
const WELCOME_NL_REDIRECT = '/welkom/:token';

const PLANS = '/plans';
const PLAN_TRIAL_EXPIRED = '/plans/trial-expired';
const PLAN_STATUS_ERROR = '/plans/status-error';

const CONNECTIONS_LTI_TRAININGS = '/lti/trainings/:launchId';
const CONNECTIONS_LTI_COURSES = '/lti/courses/:launchId';

const SKILL_MANAGEMENT_LIST = '/skill';
const SKILL_CREATE = `${SKILL_MANAGEMENT_LIST}/new`;
const SKILL_EDIT = `${SKILL_MANAGEMENT_LIST}/:skillId/edit`;

export const ROUTES = {
    HOME,

    TRAINING,
    TRAINING_PARTICIPANTS,
    TRAINING_TRAINERS,
    TRAINING_SHARED_FILES,
    TRAINING_ASSIGNMENT,
    TRAINING_ASSIGNMENT_FOR_USER,
    TRAINING_ASSIGNMENT_GRADE,
    TRAINING_CERTIFICATE,
    TRAINING_PROGRESS,
    TRAINING_CREATE,
    TRAINING_EDIT,
    TRAINING_CREATE_MODULE_GROUP,
    TRAINING_EDIT_MODULE_GROUP,
    TRAINING_MODULE_GROUP_CONDITIONS,
    TRAINING_CREATE_MODULE,
    TRAINING_CREATE_MODULE_TYPE,
    TRAINING_CREATE_MODULE_IN_GROUP,
    TRAINING_CREATE_MODULE_TYPE_IN_GROUP,
    TRAINING_UPDATE_MODULE,
    TRAINING_MODULE_SELECT,
    MANAGEMENT_TRAINING_PARTICIPANTS,
    MANAGEMENT_TRAINING_GROUPS,
    MANAGEMENT_TRAINING_TRAINERS,
    MANAGEMENT_TRAINING,
    TRAINING_MANAGEMENT_LIST,
    MANAGEMENT_TRAINING_EDIT_MODULE_GROUP,
    MANAGEMENT_TRAINING_CREATE_MODULE_GROUP,

    PORTFOLIO,
    PORTFOLIO_ITEM_CREATE,
    PORTFOLIO_ITEM_EDIT,
    PORTFOLIO_ITEM_VIEW,
    PORTFOLIO_ITEM_DRAWER,
    PORTFOLIO_GROUP_CREATE,
    PORTFOLIO_GROUP_EDIT,
    PORTFOLIO_TRAININGS,
    USER_PORTFOLIO,
    USER_PORTFOLIO_ITEM_VIEW,
    USER_PORTFOLIO_SETTINGS,
    USER_TRAININGS,
    USER_OFFERS,
    PORTFOLIO_OFFERS,
    USER_MEETINGS,
    PORTFOLIO_MEETINGS,
    PORTFOLIO_REPORT,
    TRAINING_REPORT,
    OFFER_REPORT,
    MEETING_REPORT,
    COURSE_REPORT,

    DEVELOPMENT_PLAN,
    DEVELOPMENT_ITEM_CREATE,
    DEVELOPMENT_ITEM_EDIT,
    DEVELOPMENT_GROUP_CREATE,
    DEVELOPMENT_GROUP_EDIT,
    USER_DEVELOPMENT_PLAN,
    USER_DEVELOPMENT_ITEM_VIEW,

    PROFILE_DRAWER,
    CHAT,
    USER_CHAT,

    LIBRARY: LIBRARY,
    LIBRARY_COLLECTION: LIBRARY_COLLECTION,
    LIBRARY_COLLECTION_CREATE,
    LIBRARY_COLLECTION_EDIT,
    LIBRARY_COLLECTION_CREATE_ARTICLE,
    LIBRARY_ARTICLE,
    LIBRARY_ARTICLE_EDIT,
    LIBRARY_ARTICLE_EDIT_CONTENT,

    MANAGEMENT_MODULES_TRAININGS,
    MANAGEMENT_MODULES_COURSES,
    MANAGEMENT_MODULES_CERTIFICATES,

    USER_MANAGEMENT_LIST,
    USER_MANAGEMENT_LIST_ARCHIVED,
    USER_MANAGEMENT_CREATE,

    MANAGEMENT_USERS_USERS,
    MANAGEMENT_USERS_CREATE,
    GROUP_MANAGEMENT_LIST,
    GROUP_CREATE,
    GROUP_EDIT,
    MANAGEMENT_GROUPS_USER_CREATE,
    MANAGEMENT_GROUPS_USER_IMPORT,
    MANAGEMENT_TRAINING_PARTICIPANT_IMPORT,
    MANAGEMENT_USERS_ENROLLMENTS,
    MANAGEMENT_USERS_ENROLLMENTS_WAITING_LIST,
    MANAGEMENT_USERS_ENROLLMENTS_ENROLLED,
    MANAGEMENT_USERS_ENROLLMENTS_DENIED,
    MANAGEMENT_USERS_ENROLLMENTS_COMPLETED,
    MANAGEMENT_USERS_ENROLLMENTS_ABSENT,
    MANAGEMENT_USERS_REPORTING,
    MANAGEMENT_COURSE_REPORTING,

    COURSE_MANAGEMENT_LIST,
    COURSE_CREATE,
    COURSE_EDIT,

    MANAGEMENT_ACADEMY,
    MANAGEMENT_SETTINGS_LOCATIONS,
    MANAGEMENT_SETTINGS_FACETS,
    MANAGEMENT_SETTINGS_STYLE,
    MANAGEMENT_SETTINGS_CALENDAR,
    MANAGEMENT_SETTINGS_EMAILS,
    MANAGEMENT_SETTINGS_CONNECTIONS,
    MANAGEMENT_SETTINGS_HISTORY,

    MANAGEMENT_ORGANISATION_ORGANISATION,
    MANAGEMENT_ORGANISATION_SUBSCRIPTION,
    MANAGEMENT_ORGANISATION_PAYMENTDETAILS,
    MANAGEMENT_ORGANISATION_INVOICES,

    OFFER_LIST,
    OFFER_SETTINGS_EDIT,
    OFFER_DETAIL,
    OFFER_DETAIL_DRAWER,
    OFFER_MEETING_CREATE,
    OFFER_CREATE,
    OFFER_EDIT_CONTENT,
    OFFER_EDIT,
    OFFER_EVENT_DETAIL_DRAWER,
    CREATE_OFFER_EVENT,

    ORGANISATION_LANGUAGE,
    ORGANISATION_IMAGES,
    ORGANISATION_COLORS,
    ORGANISATION_SETTINGS,
    ORGANISATION_SINGLE_SIGN_ON,
    ORGANISATION_EXTRA_CATEGORIES,

    INTRO,
    LOGIN,
    LOGOUT,
    CHANGE_PASSWORD,
    WELCOME,

    PAGE_NOT_FOUND,

    LIBRARY_NL_REDIRECT,
    LIBRARY_COLLECTION_NL_REDIRECT,
    LIBRARY_ARTICLE_NL_REDIRECT,
    CHANGE_PASSWORD_NL_REDIRECT,
    WELCOME_NL_REDIRECT,

    PLANS,
    PLAN_TRIAL_EXPIRED,
    PLAN_STATUS_ERROR,

    CONNECTIONS_LTI_TRAININGS,
    CONNECTIONS_LTI_COURSES,

    VIDEO_MANAGEMENT_LIST,
    VIDEO_EDIT,

    SKILL_MANAGEMENT_LIST,
    SKILL_CREATE,
    SKILL_EDIT,
};
