import { NavigateFunction } from 'react-router-dom';

import { ICollectionFragment } from 'graphql/types';
import { TParams } from 'route/types';
import { getUrl } from 'route/utils/getUrl';

export function goToArticleDetailPage(navigate: NavigateFunction, id: string) {
    const url = getUrl('LIBRARY_ARTICLE', { id });
    navigate(url);
}

export function goToArticleContentEditPage(
    navigate: NavigateFunction,
    id: string
) {
    const url = getUrl('LIBRARY_ARTICLE_EDIT_CONTENT', { id });
    navigate(url);
}

export function goToRootCollectionDetailPage(navigate: NavigateFunction) {
    const url = getUrl('LIBRARY');

    navigate(url);
}

export function goToCollectionDetailPage(
    navigate: NavigateFunction,
    id: string | undefined // Used "| undefined" here to prevent issues with order of arguments
) {
    const url = getUrl('LIBRARY_COLLECTION', { id });

    navigate(url);
}

export function goToParentCollection(
    navigate: NavigateFunction,
    parent: Pick<ICollectionFragment, 'id' | 'root'>
) {
    if (!parent) return;

    const urlParams: TParams = {};

    // When parent is not the root parent we want to link to it with an id.
    if (!parent.root) urlParams.id = parent.id;

    const url = getUrl('LIBRARY_COLLECTION', urlParams);

    navigate(url);
}
