import { useRef } from 'react';
import { DraggableLocation, DropResult } from '@hello-pangea/dnd';
import { useApolloClient } from '@apollo/client';

import { findNestedDraggable, reorder } from 'common/utils/draggable';
import {
    IModuleGroupFragment,
    ITrainingFragment,
    ModuleGroupFragmentDoc,
    TrainingModuleGroupsDocument,
    useSortModulesAndModuleGroupsMutation,
} from 'graphql/types';
import { getModuleType } from 'training/utils/module';
import { useApolloError } from 'common/hooks/useApolloError';

export const useTrainingDND = (
    training: ITrainingFragment,
    moduleGroups: IModuleGroupFragment[]
) => {
    const { showApolloError } = useApolloError();
    const client = useApolloClient();
    // A fallback for if the sorting process fails
    const previousModuleGroups = useRef<IModuleGroupFragment[]>();
    const [sortModulesAndModuleGroups] = useSortModulesAndModuleGroupsMutation({
        onError: showApolloError,
    });

    const handleDragEndModuleGroups = (
        source: DraggableLocation,
        destination?: DraggableLocation
    ) => {
        if (!destination) return;

        previousModuleGroups.current = [...moduleGroups];

        const orderedModuleGroups = reorder<IModuleGroupFragment>(
            moduleGroups,
            source.index,
            destination.index
        );

        sortModulesAndModuleGroups({
            variables: {
                trainingId: training.id,
                moduleGroupsOrder: orderedModuleGroups.map(
                    (moduleGroup) => moduleGroup.id
                ),
            },
        });

        // Reflect the ordering changes in the cache
        client.cache.writeQuery({
            query: TrainingModuleGroupsDocument,
            variables: {
                id: training.id,
            },
            data: {
                training: {
                    ...training,
                    moduleGroups: orderedModuleGroups,
                },
            },
        });
    };

    const handleDragEndModules = async (
        source: DraggableLocation,
        draggableId: string,
        destination?: DraggableLocation
    ) => {
        if (!destination) return;

        const draggableItem = findNestedDraggable(
            moduleGroups,
            'modules',
            draggableId
        );

        if (!draggableItem) return;

        previousModuleGroups.current = [...moduleGroups];

        moduleGroups.forEach((moduleGroup) => {
            if (
                source.droppableId !== moduleGroup.id &&
                destination.droppableId !== moduleGroup.id
            ) {
                return moduleGroup;
            }

            const modules = moduleGroup.modules.filter(
                (item) => item.id !== draggableId
            );

            if (moduleGroup.id === destination.droppableId) {
                modules.splice(destination.index, 0, draggableItem);

                sortModulesAndModuleGroups({
                    variables: {
                        trainingId: training.id,
                        moduleGroupId: destination.droppableId,
                        modulesOrder: modules.map((module) => ({
                            id: module.id,
                            type: getModuleType(module),
                        })),
                    },
                });

                // Reflect the ordering changes in the cache
                client.cache.writeFragment({
                    id: `ModuleGroup:${destination.droppableId}`,
                    data: {
                        ...moduleGroup,
                        modules,
                    },
                    fragment: ModuleGroupFragmentDoc,
                    fragmentName: 'ModuleGroup',
                });
            }

            // Remove the module from the source module group if
            // module was not dragged in the same module group
            if (
                source.droppableId !== destination.droppableId &&
                moduleGroup.id === source.droppableId
            ) {
                client.cache.writeFragment({
                    id: `ModuleGroup:${source.droppableId}`,
                    fragment: ModuleGroupFragmentDoc,
                    fragmentName: 'ModuleGroup',
                    data: {
                        ...moduleGroup,
                        modules: moduleGroup.modules.filter(
                            (item) => item.id !== draggableId
                        ),
                    },
                });
            }
        });
    };

    const handleDragEnd = async ({
        type,
        destination,
        source,
        draggableId,
    }: DropResult) => {
        if (type === 'MODULE_GROUP') {
            return handleDragEndModuleGroups(source, destination || undefined);
        }

        handleDragEndModules(source, draggableId, destination || undefined);
    };

    return { handleDragEnd };
};
