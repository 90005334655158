import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CoverPage } from 'hydra/pages/CoverPage';
import { Typography } from 'common/components/Typography';
import { TokenLogin } from 'user/components/TokenLogin';
import { ChangePassword } from 'user/components/ChangePassword';
import { PageTitle } from 'common/components/PageTitle';

export const ChangePasswordPage = () => {
    const { t: translate } = useTranslation();

    return (
        <CoverPage>
            <TokenLogin forPage="resetPassword">
                {() => (
                    <>
                        <PageTitle mixpanelTitle="Change Password">
                            {translate('changePasswordPage.title')}
                        </PageTitle>
                        <Box mb={2}>
                            <Typography variant="h2">
                                {translate('changePasswordPage.title')}
                            </Typography>
                        </Box>
                        <ChangePassword />
                    </>
                )}
            </TokenLogin>
        </CoverPage>
    );
};
