import { Box, ListItem, ListItemText } from '@mui/material';
import { mdiClose, mdiRefresh } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
    IUploadFile,
    IFileUploadStatus,
} from 'common/contexts/FileUploadContext';
import { IconButton } from 'common/components/IconButton';
import { LinearProgress } from 'common/components/LinearProgress';
import { humanizeFileSize } from 'common/utils/formatFile';
import { Tooltip } from 'common/components/Tooltip';

interface IProps {
    upload: IUploadFile;
}

export const FileUploadListItem = ({
    upload: {
        progress,
        file: { name, size },
        status,
        start,
        abort,
        remove,
    },
}: IProps) => {
    const [translate] = useTranslation();

    const isUploading = status === IFileUploadStatus.UPLOADING;
    const isSuccessful = status === IFileUploadStatus.SUCCESS;
    const hasError = status === IFileUploadStatus.ERROR;

    const secondaryText = hasError
        ? translate('fileUpload.error')
        : humanizeFileSize(size);

    return (
        <ListItem dense>
            <ListItemText
                primary={name}
                primaryTypographyProps={{
                    variant: 'h5',
                    noWrap: true,
                    sx: {
                        lineHeight: 1.1,
                    },
                }}
                secondary={secondaryText}
                secondaryTypographyProps={{
                    sx: {
                        fontSize: '1.4rem',
                        color: hasError ? 'error.main' : 'text.secondary',
                    },
                }}
            />
            <Box
                sx={{
                    flex: '0 0 100px',
                    mx: 2,
                }}
            >
                <LinearProgress value={progress} />
            </Box>
            {hasError && (
                <Box>
                    <Tooltip title={translate<string>('retry')}>
                        <IconButton
                            iconPath={mdiRefresh}
                            iconSize="2rem"
                            onClick={start}
                        />
                    </Tooltip>
                </Box>
            )}
            {(isUploading || hasError) && (
                <Box>
                    <Tooltip title={translate<string>('cancel')}>
                        <IconButton
                            iconPath={mdiClose}
                            iconSize="2.4rem"
                            onClick={abort}
                        />
                    </Tooltip>
                </Box>
            )}
            {isSuccessful && (
                <Box>
                    <Tooltip title={translate<string>('remove')}>
                        <IconButton
                            iconPath={mdiClose}
                            iconSize="2.4rem"
                            onClick={remove}
                        />
                    </Tooltip>
                </Box>
            )}
        </ListItem>
    );
};
