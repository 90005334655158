import { ICreatePortfolioItemInput } from 'graphql/types';
import { QuickAddForm } from 'user/components/QuickAddForm';

interface IProps {
    loading?: boolean;
    groupId: string;
    onAdd(values: ICreatePortfolioItemInput): void;
}

export const CustomPortfolioItemQuickAdd = ({
    loading,
    groupId,
    onAdd,
}: IProps) => {
    const handleSave = (title: string) => {
        if (!title) return;

        onAdd({ title, groupId });
    };

    return (
        <QuickAddForm
            disabled={loading}
            placeholderKey="portfolioItemQuickAddPlaceholder"
            onSave={handleSave}
        />
    );
};
