import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';

import { AddButton } from 'common/components/Button/AddButton';
import { Typography } from 'common/components/Typography';
import { ICertificateTypeListItemFragment } from 'graphql/types';
import {
    getCertificateSelectStatus,
    isCertificateAdded,
} from 'training/utils/certificateSelect';
import { CertificateSelectDrawer } from 'training/components/CertificateSelectDrawer';
import { CertificateSelectorListItem } from 'common/components/CertificateSelectorListItem';

interface IProps {
    name: string;
    value: ICertificateTypeListItemFragment[];
}

export const CertificateSelector = ({ name, value }: IProps) => {
    const { setFieldValue } = useFormikContext();
    const [translate] = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const addedCertificates = value;

    const [selectedCertificates, setSelectedCertificates] =
        useState<ICertificateTypeListItemFragment[]>(addedCertificates);

    const handleSelectCertificate = (
        certificate: ICertificateTypeListItemFragment
    ) => {
        const newCertificates = [...selectedCertificates, certificate].sort(
            (a, b) => (a.name > b.name ? 1 : -1)
        );

        setSelectedCertificates(newCertificates);
    };

    const handleAddCertificates = () => {
        setFieldValue(name, selectedCertificates);

        setDrawerOpen(false);
    };

    const handleRemoveCertificate = (
        certificate: ICertificateTypeListItemFragment,
        removeAdded?: boolean
    ) => {
        const { isSelected } = getCertificateSelectStatus(
            selectedCertificates,
            addedCertificates,
            certificate
        );

        if (isSelected) {
            const newSelectedCertificates = selectedCertificates.filter(
                (selectedCertificate) =>
                    selectedCertificate.id !== certificate.id
            );

            setSelectedCertificates(newSelectedCertificates);
        }

        // When an added certificate is removed, it needs to be removed from the form
        if (!removeAdded) return;

        const newCertificates = addedCertificates.filter(
            (addedCertificate) => certificate.id !== addedCertificate.id
        );

        setFieldValue(name, newCertificates);
    };

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const addedCertificates =
                    (form.values[name] as ICertificateTypeListItemFragment[]) ||
                    [];
                const error = form.errors[name];
                const touched = form.touched[name];

                return (
                    <>
                        <AddButton onClick={() => setDrawerOpen(true)}>
                            <Typography>
                                {translate('certificateSelector.addButton')}
                            </Typography>

                            {error && touched && (
                                <Box mt="-5px" position="absolute">
                                    <FormHelperText error>
                                        {form.errors[name]}
                                    </FormHelperText>
                                </Box>
                            )}
                        </AddButton>

                        {!!addedCertificates.length && (
                            <Box display="flex" flexDirection="column" mt={2}>
                                {addedCertificates.map((addedCertificate) => (
                                    <CertificateSelectorListItem
                                        addButton
                                        added={isCertificateAdded(
                                            addedCertificates,
                                            addedCertificate
                                        )}
                                        certificate={addedCertificate}
                                        key={`added-${addedCertificate.id}`}
                                        onClick={(certificate) => {
                                            handleRemoveCertificate(
                                                certificate,
                                                true
                                            );
                                        }}
                                    />
                                ))}
                            </Box>
                        )}

                        <CertificateSelectDrawer
                            addedCertificates={addedCertificates}
                            open={drawerOpen}
                            selectedCertificates={selectedCertificates}
                            setSelectedCertificates={setSelectedCertificates}
                            onAddCertificates={handleAddCertificates}
                            onClose={() => setDrawerOpen(false)}
                            onRemoveCertificate={(
                                certificate: ICertificateTypeListItemFragment
                            ) => handleRemoveCertificate(certificate)}
                            onSelectCertificate={handleSelectCertificate}
                        />
                    </>
                );
            }}
        />
    );
};
