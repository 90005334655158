import { useMemo, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

const DRAWER_OFFSET = 75;
const DRAWER_CONTEXT = gql`
    query GetActiveDrawers {
        activeDrawers @client
    }
`;

interface IDrawerContext {
    activeDrawers?: string[];
}

/**
 * When user open drawer, the previous one should be shifted to the left.
 * The function calculates the drawer offset according to its index.
 *
 * @param open
 */
export const useDrawerIndex = (open?: boolean): [number] => {
    // making component id
    const id = useMemo(() => Math.random().toString(36), []);
    // fetching array with ids of active drawers
    const { data, client } = useQuery<IDrawerContext>(DRAWER_CONTEXT);

    const active = useMemo(
        () => data?.activeDrawers || [],
        [data?.activeDrawers]
    );

    const openRef = useRef<boolean>();

    useEffect(() => {
        // check the previous state of the drawer
        if (openRef.current !== open) {
            const data: IDrawerContext = { activeDrawers: [] };

            // on open action put drawer id to the start of the drawer
            data.activeDrawers = open
                ? [id, ...active]
                : active.filter((_id) => id !== _id);

            client.writeQuery({
                query: DRAWER_CONTEXT,
                data,
            });

            openRef.current = open;
        }
    }, [open, id, active, client]);

    const index = active.indexOf(id);

    // When the drawer is already open on first render we don't get an index, to make sure we don't get a negative
    // offset we want a minimum of 0.
    const curOffset = Math.max(0, index * DRAWER_OFFSET);

    // calculates drawer offset and is it the first opened drawer
    return [curOffset];
};
