import { styled } from 'styled-components';
import { RenderLeafProps, useReadOnly } from 'slate-react';

const EmptyNode = styled.span`
    position: relative;
    width: 32px;
    display: inline-block;
    cursor: text;

    ::after {
        content: '¶';
        position: absolute;

        top: 0;
        opacity: 0.2;
    }
`;

export const EmptyLeaf = ({ children, leaf }: RenderLeafProps) => {
    const readOnly = useReadOnly();

    if (leaf.text === '' && !readOnly) {
        return <EmptyNode>{children}</EmptyNode>;
    }

    return children;
};
