import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { TableActionBar, TableCell, TableHead } from 'common/components/Table';
import { IUserListItemFragment } from 'graphql/types';
import { FilterBar } from 'common/components/FilterBar';
import { IconButton } from 'common/components/IconButton';
import { Tooltip } from 'common/components/Tooltip';
import { Loader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';
import {
    IPaginationProps,
    ISearchProps,
    ITableSelectionProps,
    TFilterBarItem,
    TTableAction,
} from 'common/types';
import { Pagination } from 'common/components/Pagination';
import { Checkbox } from 'common/components/Checkbox';

import { UserTableRow } from './UserTableRow';

interface IProps {
    disabled?: boolean;
    disabledUserIds?: string[];
    isEditable?: boolean;
    loading?: boolean;
    noResultsLabel?: string;
    paginationTranslationKey?: string;
    paginationProps?: IPaginationProps;
    tableActions?: TTableAction[];
    selectionProps?: ITableSelectionProps<IUserListItemFragment>;
    users?: IUserListItemFragment[];
    searchProps?: ISearchProps;
    inDrawer?: boolean;
    onSelectUsers?(selectedUsers: string[]): void;
}

export const UserTable = ({
    disabled,
    disabledUserIds,
    isEditable = true,
    loading,
    noResultsLabel,
    paginationTranslationKey,
    paginationProps,
    tableActions,
    selectionProps,
    users,
    searchProps,
    inDrawer,
    onSelectUsers,
}: IProps) => {
    const [translate] = useTranslation();
    const {
        filters,
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch = () => {},
        handleFilterSelect: onFilterSelect,
    } = searchProps || {};
    const { paginationSettings, setPage } = paginationProps || {};
    const {
        rows = [],
        isSelected,
        selected,
        selectableItems = [],
        onSelectAll,
    } = selectionProps || {};

    const selectedUserIds = useMemo(() => selected || [], [selected]);

    useEffect(() => {
        onSelectUsers?.(selectedUserIds);
    }, [selectedUserIds, onSelectUsers]);

    const actions = tableActions?.map(
        ({ tooltipTitle, iconPath, onClick }, index) => (
            <Tooltip key={index} title={tooltipTitle}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ml: 'auto',
                    }}
                >
                    <IconButton
                        color="inherit"
                        disabled={!isSelected}
                        iconPath={iconPath}
                        iconSize="2.4rem"
                        onClick={() => {
                            onClick?.(selectedUserIds);
                        }}
                    />
                </Box>
            </Tooltip>
        )
    );

    const handleSearch = (searchValue: string) => {
        onSearch?.(searchValue);

        if (!!selected?.length) onSelectAll?.();
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        onFilterSelect?.(selectedFilters);

        if (!!selected?.length) onSelectAll?.();
    };

    return (
        <>
            {!!searchProps && (
                <FilterBar
                    disabled={disabled}
                    filters={filters}
                    initialSearchValue={searchQueryParam}
                    initialSelected={selectedFilters}
                    placeholder={translate('filterBarPlaceholder')}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch?.('')}
                    onSelect={handleFilterSelect}
                />
            )}

            {!loading && !!rows.length && (
                <TableContainer>
                    {isEditable && (
                        <TableActionBar
                            actions={actions}
                            inDrawer={inDrawer}
                            selectionProps={selectionProps}
                        />
                    )}

                    <Table>
                        <TableHead>
                            <TableRow>
                                {isEditable && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={false}
                                            disabled={
                                                !selectableItems.length ||
                                                loading ||
                                                disabled
                                            }
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{translate('name')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const user = users?.[index] || null;

                                if (!user) return null;

                                const isDisabled = disabledUserIds?.some(
                                    (disabledUserId) =>
                                        disabledUserId === user?.id
                                );

                                return (
                                    <UserTableRow
                                        disabled={isDisabled || disabled}
                                        isEditable={!isDisabled}
                                        isSelectable={isEditable}
                                        isSelected={isSelected}
                                        key={user.id}
                                        user={user}
                                        onSelect={onSelect}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '100px', mt: 4 }}>
                    <Loader />
                </Box>
            )}

            {!loading && !users?.length && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Typography>
                        {noResultsLabel || translate('noOptionsText.users')}
                    </Typography>
                </Box>
            )}

            {!loading && !!paginationSettings && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate(
                            paginationTranslationKey || 'user',
                            {
                                count: paginationSettings.count,
                            }
                        )}
                        onChange={(page: number) => {
                            setPage?.(page);
                        }}
                    />
                </Box>
            )}
        </>
    );
};
