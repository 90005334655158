import { useState, useEffect } from 'react';

interface IProps {
    show: boolean;
    delay: number;
    children: React.ReactNode;
}

export const ShowAfterDelay = ({ show, delay, children }: IProps) => {
    const [showChildren, setShowChildren] = useState(false);

    let timerId: ReturnType<typeof setTimeout>;

    useEffect(() => {
        clearTimeout(timerId);

        if (!show) {
            setShowChildren(false);

            return;
        }

        timerId = setTimeout(() => {
            setShowChildren(show);
        }, delay);

        return () => {
            // Clear timer when component is unmounted
            clearTimeout(timerId);
        };
    }, [show, delay]);

    if (!showChildren) return null;

    return <>{children}</>;
};
