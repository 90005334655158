import { useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from 'styled-components';
import { mdiUpload } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Loader } from 'common/components/Loader';
import {
    IDefaultFileType,
    IFileFragment,
    useOrganisationDefaultFilesQuery,
} from 'graphql/types';
import { SlideButton as BaseSlideButton } from 'common/components/Button/SlideButton';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import {
    ImageUploader,
    IProps as IImageUploadProps,
} from '../ImageUploader/ImageUploader';

export interface IProps extends IImageUploadProps {
    startSlide?: number;
    id: string;
    image?: IFileFragment;
    imageSize?: 'contain' | 'cover';
    onChange?(image: IFileFragment | null): void;
    className?: string;
    onLoaded?(): void;
}

const Slider = styled(Box)`
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 150px;
    border-radius: 4px;
`;

const Slides = styled(Box)`
    display: flex;
    position: relative;
    transition: left 250ms linear;
`;

interface IPropsSlide extends BoxProps {
    imageSize?: IProps['imageSize'];
}

// Separate component for Slide so we don't pass imageSize to Box causing errors
const BaseSlide = ({ imageSize, ...other }: IPropsSlide) => <Box {...other} />;

const Slide = styled(BaseSlide)`
    width: 100%;
    height: 150px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;

    ${({ theme, imageSize }) => {
        if (!imageSize || imageSize === 'cover')
            return `background-size: cover;`;

        return `
            background-origin: content-box;
            background-size: ${imageSize};
            padding: ${theme.spacing(2, 2)};
        `;
    }}
`;

const SlideButton = styled(BaseSlideButton)`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 1;

    &.next {
        left: auto;
        right: 10px;
    }

    &.hide {
        display: none;
    }
`;

const FileLabelWrapper = styled(Box)`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    border-top-left-radius: 5px;
    cursor: pointer;
`;

const FileLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const BaseImageUploaderWithDefaults = ({
    startSlide,
    id,
    image,
    imageSize,
    className,
    onChange,
    onLoaded,
    ...other
}: IProps) => {
    const [activeSlide, setActiveSlide] = useState(startSlide || 0);
    const [images, setImages] = useState<IFileFragment[]>();
    const [uploadedImage, setUploadedImage] = useState<
        IFileFragment | undefined
    >();
    const { t: translate } = useTranslation();

    const lastSlide = (images?.length || 0) + 1; // +1 as there is a slide for the empty image
    const isLastSlide = activeSlide === lastSlide;

    const handleOnChange = (file?: IFileFragment | null) => {
        if (!onChange) return;

        onChange(file || null);
    };

    const { loading: loadingDefaultFiles } = useOrganisationDefaultFilesQuery({
        variables: { type: IDefaultFileType.Header },
        onCompleted: (data) => {
            const defaultImages = (data?.organisationDefaultFiles
                ?.filter((f) => !!f.file?.url)
                .map((f) => f.file) || []) as IFileFragment[];

            if (!defaultImages.length) return;

            const startSlideIsSet = startSlide !== undefined;
            const emptyImageSlideIndex = defaultImages.length;

            // When a image value is given we want to check if it is found in the default images
            if (image) {
                const imageIndex = defaultImages.findIndex(
                    (img) => img.id === image.id
                );

                // If image is not found in the default images we want to set is as uploaded image and switch slide.
                if (imageIndex === -1) {
                    const uploadedImageSlideIndex = emptyImageSlideIndex + 1;

                    setUploadedImage(image);
                    setActiveSlide(uploadedImageSlideIndex);
                } else {
                    setActiveSlide(imageIndex);
                }
            } else {
                const slideIndex = startSlideIsSet
                    ? startSlide
                    : emptyImageSlideIndex;

                setActiveSlide(slideIndex);

                // If startslide is set and exists as default image we want to trigger onchange
                // to make sure it submits it to the form
                if (startSlideIsSet && defaultImages[startSlide]) {
                    handleOnChange(defaultImages[startSlide]);
                }
            }

            setImages(defaultImages);
            onLoaded && onLoaded();
        },
    });

    const handleClickSliderButton = (right?: boolean) => {
        if (!images) return;

        if (
            (activeSlide === 0 && !right) ||
            (right && images && activeSlide === lastSlide)
        ) {
            return;
        }

        const newActiveSlide = right ? activeSlide + 1 : activeSlide - 1;

        setActiveSlide(newActiveSlide);

        // Either one of the default images or the uploaded image
        const selectedImage = images[newActiveSlide] || uploadedImage;
        const emptyImage = newActiveSlide === lastSlide - 1;
        // Either no image or a selected image
        const newImage = emptyImage ? null : selectedImage;

        handleOnChange(newImage);
    };

    return (
        <Box className={className}>
            {loadingDefaultFiles ? (
                <Loader />
            ) : (
                <Slider>
                    <SlideButton
                        className={`${activeSlide === 0 ? 'hide' : ''}`}
                        onClick={() => handleClickSliderButton()}
                    />
                    <Box position="relative">
                        {!isLastSlide && (
                            <FileLabelWrapper
                                onClick={() => {
                                    // Timeout is set so it still executes the file input trigger event
                                    setTimeout(() => {
                                        setActiveSlide(lastSlide);
                                    });
                                }}
                            >
                                <FileLabel htmlFor={id}>
                                    <Box mr="5px" position="relative" top="4px">
                                        <Icon path={mdiUpload} size="2rem" />
                                    </Box>

                                    {translate('uploadImage')}
                                </FileLabel>
                            </FileLabelWrapper>
                        )}

                        <Slides
                            style={{
                                left: `-${100 * activeSlide}%`,
                            }}
                        >
                            {images &&
                                images.map(
                                    (image, index) =>
                                        image.url && (
                                            <Slide
                                                imageSize={imageSize}
                                                key={index}
                                                style={{
                                                    backgroundImage: `url(${image.url})`,
                                                }}
                                            />
                                        )
                                )}

                            <Slide>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography fontWeight="bold">
                                        {translate('noImage')}
                                    </Typography>
                                </Box>
                            </Slide>

                            <Slide>
                                <ImageUploader
                                    id={id}
                                    image={uploadedImage}
                                    onUpload={handleOnChange}
                                    {...other}
                                />
                            </Slide>
                        </Slides>
                    </Box>
                    <SlideButton
                        right
                        className={`next ${
                            images && activeSlide === lastSlide ? 'hide' : ''
                        }`}
                        onClick={() => handleClickSliderButton(true)}
                    />
                </Slider>
            )}
        </Box>
    );
};

export const ImageUploaderWithDefaults = styled(BaseImageUploaderWithDefaults)`
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    height: 100%;
`;
