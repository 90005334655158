import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { usePagination } from 'common/hooks/usePagination';
import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { Loader } from 'common/components/Loader';
import { ICourseListItemFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { useCourseSearch } from 'training/hooks/useCourseSearch';
import { CourseAddList } from 'training/components/CourseAddList';
import { useCourseList } from 'training/hooks/useCourseList';
import { useCourseQueryVariables } from 'training/hooks/useCourseQueryVariables';
import { TFilterBarItem } from 'common/types';

interface IProps extends IPageDrawerProps {
    addedCourses: ICourseListItemFragment[];
    selectedCourses: ICourseListItemFragment[];
    onAddCourses(): void;
    onDrawerOpen(open: boolean): void;
    onRemoveCourse(course: ICourseListItemFragment): void;
    onSelectCourse(course: ICourseListItemFragment): void;
}

export const CourseSelectDrawer = ({
    addedCourses,
    selectedCourses,
    open,
    onAddCourses,
    onDrawerOpen,
    onRemoveCourse,
    onSelectCourse,
}: IProps) => {
    const { t: translate } = useTranslation();

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useCourseSearch();

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { courseQueryVariables } = useCourseQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        courses,
        loading: coursesLoading,
        coursesCount,
    } = useCourseList(courseQueryVariables);

    const resetSettings = () => {
        handleSearch('');
        handleFilterSelect([]);
        setPage(1);
    };

    useEffect(() => {
        if (coursesLoading || !initializePagination) return;

        initializePagination(coursesCount || 0);
    }, [coursesCount, coursesLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('addCoursesTitle')}
            onClose={() => {
                onDrawerOpen(false);
                resetSettings();
            }}
        >
            {filtersLoading ? (
                <Loader />
            ) : (
                <>
                    <Box
                        sx={{
                            pb: 22.5,
                            pt: { xs: 2, sm: 4 },
                            px: { xs: 2, sm: 4 },
                        }}
                    >
                        <FilterBar
                            filters={filters}
                            initialSearchValue={searchQueryParam}
                            initialSelected={selectedFilters}
                            placeholder={translate('filterBarPlaceholder')}
                            onSearch={handleSearch}
                            onSearchClear={() => handleSearch('')}
                            onSelect={handleFilterSelect}
                        />

                        {coursesLoading && <Loader />}

                        {open && !coursesLoading && (
                            <CourseAddList
                                addedCourses={addedCourses}
                                courses={courses}
                                selectedCourses={selectedCourses}
                                onAddCourse={onSelectCourse}
                                onRemoveCourse={(
                                    selectedCourse: ICourseListItemFragment
                                ) => onRemoveCourse(selectedCourse)}
                            />
                        )}
                    </Box>

                    <PageDrawerFooter>
                        <Box display="flex" flexDirection="column">
                            {paginationSettings.count > -1 && (
                                <Box mb={2}>
                                    <Pagination
                                        page={paginationSettings.page}
                                        pageAmount={
                                            paginationSettings.pageAmount
                                        }
                                        totalsAmount={paginationSettings.count}
                                        totalsText={
                                            paginationSettings.count === 1
                                                ? translate('course')
                                                : translate('courses')
                                        }
                                        onChange={(page: number) => {
                                            setPage(page);
                                        }}
                                    />
                                </Box>
                            )}

                            <Button
                                color="primary"
                                disabled={!selectedCourses.length}
                                variant="contained"
                                onClick={() => {
                                    onAddCourses();
                                    resetSettings();
                                }}
                            >
                                {!selectedCourses.length
                                    ? translate('addCoursesTitle')
                                    : translate('addCourses', {
                                          count: selectedCourses.length,
                                      })}
                            </Button>
                        </Box>
                    </PageDrawerFooter>
                </>
            )}
        </PageDrawer>
    );
};
