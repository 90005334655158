import { useSlate } from 'slate-react';
import {
    ToolbarButton,
    ToolbarButtonProps,
    ImagePluginOptions,
    someNode,
} from '@udecode/slate-plugins';
import Tippy, { TippyProps } from '@tippyjs/react';

import {
    EDITOR_IMAGE_TYPE,
    EDITOR_IMAGE_MAX_SIZE,
} from 'common/constants/files';

import { FileUpload } from '../file/FileUpload';

import { addOrReplaceImage } from './utils';

type IProps = ToolbarButtonProps &
    ImagePluginOptions<'type'> &
    ImagePluginOptions<'rootProps'>;

export const ToolbarImage = ({ img, tooltip, ...other }: IProps) => {
    const editor = useSlate();

    if (!img) return null;

    const handleSuccess = (url: string) => {
        addOrReplaceImage(editor, url, { img });
    };

    const tooltipProps: TippyProps = {
        content: '',
        arrow: true,
        offset: [0, 17],
        delay: 0,
        duration: [200, 0],
        hideOnClick: false,
        ...tooltip,
    };

    // We render Tippy manually here because it doesn't work inside the FileUpload
    return (
        <Tippy interactive={false} {...tooltipProps}>
            <span>
                <FileUpload
                    id="toolbar-image-upload"
                    inputProps={{ accept: EDITOR_IMAGE_TYPE }}
                    maxSize={EDITOR_IMAGE_MAX_SIZE}
                    onSuccess={handleSuccess}
                >
                    <ToolbarButton
                        active={someNode(editor, { match: { type: img.type } })}
                        {...other}
                    />
                </FileUpload>
            </span>
        </Tippy>
    );
};
