import { mdiClose, mdiPlus } from '@mdi/js';
import { Box, Grid } from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import { ColorPicker, TextField } from 'common/components/FormField';
import { IExtraCategoryChoiceOptionFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

import { TExtraCategoryFormValues } from './ExtraCategoryForm';

export const ExtraCategoryChoiceOptions = () => {
    const [translate] = useTranslation();
    const { errors } = useFormikContext<TExtraCategoryFormValues>();
    const [deleteDialogIndex, setDeleteDialogIndex] = useState<
        number | undefined
    >(undefined);

    return (
        <Box mt={1}>
            <FieldArray
                name="choiceOptions"
                render={(arrayHelpers) => {
                    const choiceOptions = arrayHelpers.form.values
                        .choiceOptions as IExtraCategoryChoiceOptionFragment[];

                    let generalError: string | undefined;

                    if (!Array.isArray(errors.choiceOptions)) {
                        generalError = errors.choiceOptions;
                    }

                    return (
                        <Box>
                            {choiceOptions?.map((choiceOption, index) => (
                                <Grid
                                    container
                                    key={choiceOption.id || index}
                                    spacing={1}
                                >
                                    <Grid item sm={4} xs={7}>
                                        <Field
                                            component={TextField}
                                            label={translate(
                                                'extraCategoriesDrawer.optionText'
                                            )}
                                            name={`choiceOptions[${index}].stringValue`}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <Field
                                            component={ColorPicker}
                                            name={`choiceOptions[${index}].color`}
                                            popoverPosition="bottom-end"
                                            tooltip={translate<string>('color')}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        sm={1}
                                        sx={{
                                            mt: { xs: 2.5 },
                                            pl: 1,
                                        }}
                                        xs={2}
                                    >
                                        <IconButton
                                            iconPath={mdiClose}
                                            iconSize="2.4rem"
                                            size="large"
                                            onClick={() => {
                                                // Only show delete dialog if the choice option does already exist
                                                if (choiceOption.id) {
                                                    setDeleteDialogIndex(index);

                                                    return;
                                                }

                                                arrayHelpers.remove(index);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    alignSelf: 'flex-start',
                                    mt: !!choiceOptions.length ? 1 : 2,
                                }}
                                onClick={() => {
                                    arrayHelpers.push({
                                        stringValue: '',
                                        color: undefined,
                                    });
                                }}
                            >
                                <ActionButton outlined component={Box}>
                                    <Icon path={mdiPlus} />
                                </ActionButton>

                                <Box ml={2}>
                                    <Typography>
                                        {translate(
                                            'extraCategoriesDrawer.addChoiceOption'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>

                            {generalError && (
                                <Box mt={2}>
                                    <Typography
                                        color="error.main"
                                        variant="body2"
                                    >
                                        {generalError}
                                    </Typography>
                                </Box>
                            )}

                            <ConfirmDialog
                                confirmText={translate('delete')}
                                open={deleteDialogIndex !== undefined}
                                title={translate(
                                    'extraCategoriesDrawer.extraCategoryChoiceOptionDeleteDialog.title'
                                )}
                                onCancel={() => {
                                    setDeleteDialogIndex(undefined);
                                }}
                                onClose={() => setDeleteDialogIndex(undefined)}
                                onConfirm={() => {
                                    if (deleteDialogIndex === undefined) {
                                        return;
                                    }

                                    setDeleteDialogIndex(undefined);

                                    arrayHelpers.remove(deleteDialogIndex);
                                }}
                            >
                                {translate(
                                    'extraCategoriesDrawer.extraCategoryChoiceOptionDeleteDialog.text'
                                )}
                            </ConfirmDialog>
                        </Box>
                    );
                }}
            />
        </Box>
    );
};
