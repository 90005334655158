import { useEffect, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

interface IChildrenParams {
    loading: boolean;
    position: google.maps.LatLng | undefined;
}

interface IProps {
    address: string;
    children(params: IChildrenParams): React.ReactNode;
}

export const Geocoder = ({ address, children }: IProps) => {
    const [position, setPosition] = useState<google.maps.LatLng>();
    const [loading, setLoading] = useState(true);
    const geocoding = useMapsLibrary('geocoding');

    useEffect(() => {
        async function getAddressPosition() {
            if (!geocoding) return null;

            const geocoder = new geocoding.Geocoder();

            let response;

            try {
                response = await geocoder.geocode({ address });
            } catch {
                setLoading(false);

                return;
            }

            if (!response) {
                setLoading(false);

                return;
            }

            setPosition(response.results[0].geometry.location);
            setLoading(false);
        }

        getAddressPosition();
    }, [address, geocoding]);

    return <>{children({ loading, position })}</>;
};
