import { Grid } from '@mui/material';
import { Form, Formik, FormikConfig, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { IAssignmentScoreType, IAssignmentState } from 'graphql/types';
import { Button } from 'common/components/Button';
import { Select, TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { translate } from 'utils/i18n';

export type TFormValues = {
    score?: string;
    fulfilled?: '' | 'yes' | 'no';
    state?: IAssignmentState;
};

interface IProps extends FormikConfig<TFormValues> {
    scoreType?: IAssignmentScoreType;
    canUploadFiles: boolean;
    disabled?: boolean;
    className?: string;
}

const validationSchema = Yup.object().shape({
    score: Yup.string()
        .matches(
            /^(10(\.0)?|[0-9](\.[0-9])?)$/,
            translate('validate.incorrectGrade')
        )
        .nullable(),
    fullfilled: Yup.string().nullable(),
    state: Yup.string(),
});

export const AssignmentForm = ({
    scoreType,
    canUploadFiles,
    disabled,
    className,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const stateOptions = Object.values(IAssignmentState).map((state) => ({
        value: state,
        label: translate(`assignmentStatusSelect.${state}`),
    }));

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, setFieldValue }) => (
                <>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form className={className}>
                        <Grid container spacing={2}>
                            {scoreType === IAssignmentScoreType.Fulfilled && (
                                <Grid
                                    item
                                    style={{ minWidth: '150px' }}
                                    xs={!canUploadFiles}
                                >
                                    <Field
                                        displayEmpty
                                        component={Select}
                                        disabled={disabled}
                                        label={translate('fulfilled')}
                                        margin="none"
                                        name="fulfilled"
                                        options={[
                                            {
                                                value: '',
                                                label: '---',
                                            },
                                            {
                                                value: 'yes',
                                                label: translate('yes'),
                                            },
                                            {
                                                value: 'no',
                                                label: translate('no'),
                                            },
                                        ]}
                                    />
                                </Grid>
                            )}
                            {scoreType === IAssignmentScoreType.Numeral && (
                                <Grid
                                    item
                                    style={{ maxWidth: '200px' }}
                                    xs={!canUploadFiles}
                                >
                                    <Field
                                        component={TextField}
                                        disabled={disabled}
                                        label={translate('score')}
                                        margin="none"
                                        name="score"
                                        placeholder="0.0"
                                        onChange={(
                                            e: React.FormEvent<HTMLInputElement>
                                        ) => {
                                            const { value } = e.currentTarget;

                                            // Transform value comma to dot to keep
                                            // consistent decimal format
                                            setFieldValue(
                                                'score',
                                                value.replace(',', '.')
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                            {canUploadFiles && (
                                <Grid item xs>
                                    <Field
                                        required
                                        component={Select}
                                        disabled={disabled}
                                        label={translate('state')}
                                        margin="none"
                                        name="state"
                                        options={stateOptions}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!dirty && !isSubmitting && !disabled)
                                    }
                                    style={{ height: 56 }}
                                    variant="contained"
                                    onClick={submitForm}
                                >
                                    {translate('update')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </>
            )}
        </Formik>
    );
};
