import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import {
    IContentTypeValue,
    IExtraCategoryOption,
    IExtraCategoryCreateInput,
    IExtraCategoryFragment,
    IExtraCategoryUpdateInput,
    useDeleteExtraCategoryMutation,
    useUpdateExtraCategoryMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { ExtraCategoryForm } from 'organisation/components/forms/ExtraCategoryForm';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { formatColor } from 'common/utils/color';

interface IProps extends IPageDrawerProps {
    extraCategory?: IExtraCategoryFragment;
    onClose?(): void;
}

export const ExtraCategoryUpdateDrawer = ({
    extraCategory,
    onClose,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [updateExtraCategory, { loading: loadingUpdate }] =
        useUpdateExtraCategoryMutation();
    const [deleteExtraCategory, { loading: loadingDelete }] =
        useDeleteExtraCategoryMutation();

    const handleSubmit = async (
        values: IExtraCategoryUpdateInput & {
            categoryType: IExtraCategoryCreateInput['categoryType'];
        }
    ) => {
        const { categoryType, choiceOptions, color, ...otherValues } = values;
        const newChoiceOptions = choiceOptions?.map((option) => ({
            ...option,
            color: formatColor(option.color),
        }));

        try {
            await updateExtraCategory({
                variables: {
                    id: extraCategory?.id,
                    extraCategoryInput: {
                        ...otherValues,
                        color: formatColor(color),
                        choiceOptions: newChoiceOptions,
                    },
                },
                update: (cache) => {
                    const deletedChoiceOptions =
                        extraCategory?.choiceOptions?.filter(
                            (option) =>
                                !newChoiceOptions?.some(
                                    (newOption) => option.id === newOption.id
                                )
                        );

                    if (deletedChoiceOptions) {
                        deletedChoiceOptions?.forEach(({ id }) => {
                            cache.evict({
                                id: `ExtraCategoryChoiceOption:${id}`,
                            });
                        });

                        cache.gc();
                    }
                },
            });

            displaySnackbar(
                translate(
                    'extraCategoriesDrawer.extraCategoryActionSuccess.update'
                ),
                {
                    variant: 'success',
                }
            );

            onClose?.();
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const handleDelete = async () => {
        if (!extraCategory) return;

        try {
            await deleteExtraCategory({
                variables: { id: extraCategory.id },
                update: (cache) => {
                    cache.modify({
                        fields: {
                            extraCategories(
                                existingExtraCategories = [],
                                { readField }
                            ) {
                                return existingExtraCategories.filter(
                                    (
                                        extraCategoryRef: IExtraCategoryFragment
                                    ) =>
                                        extraCategory.id !==
                                        readField('id', extraCategoryRef)
                                );
                            },
                        },
                    });

                    // Evict from cache so that values set on the categories used
                    // as labels are removed as well
                    cache.evict({ id: `ExtraCategory:${extraCategory.id}` });
                    cache.gc();
                },
            });

            setShowDeleteDialog(false);

            displaySnackbar(
                translate(
                    'extraCategoriesDrawer.extraCategoryActionSuccess.delete'
                ),
                {
                    variant: 'success',
                }
            );

            onClose?.();
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const {
        name,
        categoryType,
        forModelTypes,
        choiceOptions,
        multipleChoices,
        showLabel,
        filter,
        color,
    } = extraCategory || {};

    const initialValues: IExtraCategoryUpdateInput & {
        categoryType: IExtraCategoryCreateInput['categoryType'];
    } = {
        name: name || '',
        categoryType: categoryType || IExtraCategoryOption.Boolean,
        forModelTypes: (forModelTypes
            ?.map((modelType) => modelType.type)
            .filter(Boolean) as IContentTypeValue[]) || [
            IContentTypeValue.Group,
        ],
        choiceOptions: choiceOptions?.map(({ id, stringValue, color }) => ({
            id,
            stringValue,
            color,
        })),
        multipleChoices,
        showLabel,
        filter,
        color,
    };

    return (
        <PageDrawer onClose={onClose} {...other}>
            <PageTitle mixpanelTitle="Drawer extra category update">
                {`${translate('extraCategoriesDrawer.pageTitle')} - ${name}`}
            </PageTitle>

            <ExtraCategoryForm
                disabled={loadingUpdate}
                extraCategory={extraCategory}
                initialValues={initialValues}
                onDelete={() => setShowDeleteDialog(true)}
                onSubmit={handleSubmit}
            />

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDelete}
                open={showDeleteDialog}
                title={translate(
                    'extraCategoriesDrawer.extraCategoryDeleteDialog.title'
                )}
                onCancel={() => {
                    setShowDeleteDialog(false);
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
            >
                {translate(
                    'extraCategoriesDrawer.extraCategoryDeleteDialog.text'
                )}
            </ConfirmDialog>
        </PageDrawer>
    );
};
