import { useState } from 'react';
import {
    mdiChevronRight,
    mdiDotsVertical,
    mdiMinus,
    mdiPlus,
    mdiSchool,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { CircularProgress, MenuItem } from '@mui/material';

import {
    IScormListItemFragment,
    ScormEditDocument,
    useDeleteScormMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
    ListItemFooter,
    ListItemButton,
} from 'common/components/ListItem';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { ScormUpdateDrawer } from 'training/components/ScormUpdateDrawer';
import { getScormUrl } from 'training/utils/scorm';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    loading?: boolean;
    selected?: boolean;
    scorm: IScormListItemFragment;
    showActions?: boolean;
    footer?: React.ReactNode;
    onClick?(scorm: IScormListItemFragment): void;
}

export const ScormListItem = ({
    addButton,
    added,
    disabled,
    loading,
    selected,
    scorm,
    onClick,
    showActions,
    footer,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [showScormUpdateDrawer, setShowScormUpdateDrawer] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const { id, title, thumbnail } = scorm;

    const [deleteScorm, { loading: loadingDelete }] = useDeleteScormMutation();

    const handleDeleteScorm = async () => {
        try {
            await deleteScorm({
                variables: {
                    id,
                },
                update: (cache) => {
                    // Remove scorms list from cache so it will be refetched
                    cache.evict({ fieldName: 'scorms' });

                    // Use writeQuery to update scorm query cache so it's properly removed
                    cache.writeQuery({
                        query: ScormEditDocument,
                        data: { scorm: null },
                        variables: { id },
                    });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);
            setShowDeleteAlert(false);

            return;
        }

        displaySnackbar(translate('scormActionSuccess.delete'), {
            variant: 'success',
        });

        setShowDeleteAlert(false);
    };

    const itemActionMenu = showActions && (
        <DropdownMenu
            anchor={
                <IconButton
                    color="inherit"
                    iconPath={mdiDotsVertical}
                    iconSize="2.4rem"
                />
            }
            placement="bottom-end"
        >
            <MenuItem
                disabled={loadingDelete}
                onClick={() => {
                    if (!scorm) return;

                    window.location.href = getScormUrl(scorm.id);
                }}
            >
                {translate('view')}
            </MenuItem>
            <MenuItem
                disabled={loadingDelete}
                onClick={() => setShowScormUpdateDrawer(true)}
            >
                {translate('edit')}
            </MenuItem>
            <MenuItem
                disabled={loadingDelete}
                onClick={() => {
                    setShowDeleteAlert(true);
                }}
            >
                {translate('delete')}
            </MenuItem>
        </DropdownMenu>
    );

    const showActionText = !itemActionMenu && !addButton && !disabled;

    let mediaIconPath = mdiSchool;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }

    const baseContent = (
        <>
            <ListItemMedia
                color="primary"
                image={thumbnail?.url || undefined}
                size="small"
            >
                <Icon path={mediaIconPath} size="2.2rem" sizeSm="3rem" />
            </ListItemMedia>

            <ListItemText primary={title} />

            {showActionText && (
                <>
                    {!loading && (
                        <ListItemActionText>
                            {translate('open')}
                        </ListItemActionText>
                    )}
                    <ListItemSecondaryAction>
                        {loading ? (
                            <CircularProgress color="primary" size={30} />
                        ) : (
                            <Icon path={mdiChevronRight} size="3rem" />
                        )}
                    </ListItemSecondaryAction>
                </>
            )}
        </>
    );

    return (
        <>
            <ListItem
                button={!disabled}
                key={id}
                px={0}
                selected={selected}
                {...other}
            >
                {disabled ? (
                    baseContent
                ) : (
                    <ListItemButton
                        onClick={() => !disabled && onClick?.(scorm)}
                    >
                        {baseContent}
                    </ListItemButton>
                )}

                {itemActionMenu && (
                    <ListItemSecondaryAction hideXs>
                        {itemActionMenu}
                    </ListItemSecondaryAction>
                )}

                {footer && <ListItemFooter>{footer}</ListItemFooter>}
            </ListItem>

            <ScormUpdateDrawer
                id={scorm.id}
                open={showScormUpdateDrawer}
                onClose={() => setShowScormUpdateDrawer(false)}
            />

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDelete}
                open={showDeleteAlert}
                title={translate('deleteScormMessage.title')}
                onCancel={() => {
                    setShowDeleteAlert(false);
                }}
                onClose={() => setShowDeleteAlert(false)}
                onConfirm={handleDeleteScorm}
            >
                {translate('deleteScormMessage.text')}
            </ConfirmDialog>
        </>
    );
};
