import { DEFAULT_FONT, FONT_WEIGHT_OPTIONS } from 'common/constants/fonts';
import { IFontFamilyGroup } from 'common/types';
import {
    ICustomFont,
    ICustomFontFragment,
    IFontSize,
    IFontWeight,
} from 'graphql/types';
import { translate } from 'utils/i18n';

export function getCustomFontFamilyOptions(customFonts?: ICustomFont[]) {
    if (!customFonts) return [];

    // Remove duplicates
    const noDuplicates = customFonts.filter(
        (customFont, index, customFontsArr) =>
            customFontsArr.findIndex(
                (font) => font.family === customFont.family
            ) === index
    );

    // Create family options list
    const newCustomFonts = noDuplicates.map((font: ICustomFontFragment) => ({
        value: font.family,
        label: font.family,
        group: translate(`fonts.fontGroup.${IFontFamilyGroup.CustomFonts}`),
    }));

    // Return the sorted font family options list
    return newCustomFonts.sort((a, b) => a.label.localeCompare(b.label));
}

export function getFontSizeOptions() {
    return [
        { value: IFontSize.Xs, label: translate('fonts.sizes.xs') },
        { value: IFontSize.Sm, label: translate('fonts.sizes.sm') },
        { value: IFontSize.Md, label: translate('fonts.sizes.md') },
        { value: IFontSize.Lg, label: translate('fonts.sizes.lg') },
        { value: IFontSize.Xl, label: translate('fonts.sizes.xl') },
    ];
}

export function getFontWeightOption(fontWeight?: IFontWeight) {
    return (
        FONT_WEIGHT_OPTIONS.filter(
            (weightOption) => weightOption.value === fontWeight?.toUpperCase()
        )[0] || null
    );
}

export function getDefaultFont(bold?: boolean) {
    return {
        ...DEFAULT_FONT,
        weight: bold ? IFontWeight.Bold : IFontWeight.Normal,
    };
}
