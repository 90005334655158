import { Box, BoxProps, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const backgroundImageStyle: SxProps = {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
};

export interface IProps extends BoxProps {
    src: string;
    sx?: SxProps;
}

export const BackgroundImg = ({ src, minHeight, sx, ...other }: IProps) => (
    <Box
        {...other}
        sx={[
            backgroundImageStyle,
            // Set backgroundImage if src is set
            !!src && { backgroundImage: `url(${src})` },
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
