import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSlate } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import {
    ToolbarMark,
    HeadingToolbar,
    ToolbarLink,
    ToolbarList,
    ToolbarButtonProps,
    ToolbarButton,
} from '@udecode/slate-plugins';
import { Container, Divider, SxProps } from '@mui/material';
import {
    mdiFormatBold,
    mdiFormatItalic,
    mdiFormatUnderline,
    mdiLink,
    mdiFormatHeader1,
    mdiFormatHeader2,
    mdiFormatHeader3,
    mdiFormatHeader4,
    mdiFormatSuperscript,
    mdiFormatListBulleted,
    mdiFormatListNumbered,
    mdiFormatQuoteClose,
    mdiImage,
    mdiFile,
    mdiBorderAll,
    mdiXml,
    mdiFormatClear,
    mdiFormatParagraph,
    mdiRedo,
    mdiUndo,
} from '@mdi/js';

import { Sticky } from 'common/components/Sticky';
import { Icon } from 'common/components/Icon';

import { options } from '../../options';
import { ToolbarFile } from '../../elements/file';
import { ToolbarImage } from '../../elements/image';
import { ToolbarEmbed } from '../../elements/embed/ToolbarEmbed';
import { ToolbarParagraph } from '../../elements/paragraph';
import { ToolbarTable } from '../../elements/table/ToolbarTable';

import { ToolbarElement } from './ToolbarElement';
import { ToolbarClearFormat } from './ToolbarClearFormat';

interface IProps {
    className?: string;
}

const stickyInnerNotStickyStyle: SxProps = {
    '.MuiContainer-root': {
        px: 0,
    },
};

const stickyInnerStickyStyle: SxProps = {
    backgroundColor: 'common.white',
    left: 0,
    right: 0,
    minWidth: '100%',
    borderBottom: 1,
    borderBottomColor: 'grey.300',
    '.MuiContainer-root': {
        px: 3,
    },
};

export const BaseEditorToolbar = ({ className }: IProps) => {
    const { t: translate } = useTranslation();
    const editor = useSlate();

    const buttonProps: Partial<ToolbarButtonProps> = {};

    // Override active to false when theres no editor selection. This will prevent the toolbar mark buttons as
    // active when the user is not editing.
    if (!editor.selection) {
        buttonProps.active = false;
    }

    return (
        <Sticky
            innerSxNotSticky={stickyInnerNotStickyStyle}
            innerSxSticky={stickyInnerStickyStyle}
            offset={81}
            outerSx={{ mb: 2 }}
            zIndex={1}
        >
            <Container className={className} maxWidth="md">
                <HeadingToolbar>
                    <ToolbarMark
                        icon={<Icon path={mdiFormatBold} />}
                        tooltip={{ content: translate('editor.bold') }}
                        type={options.bold.type}
                        {...buttonProps}
                    />
                    <ToolbarMark
                        icon={<Icon path={mdiFormatItalic} />}
                        tooltip={{ content: translate('editor.italic') }}
                        type={options.italic.type}
                        {...buttonProps}
                    />
                    <ToolbarMark
                        icon={<Icon path={mdiFormatUnderline} />}
                        tooltip={{ content: translate('editor.underline') }}
                        type={options.underline.type}
                        {...buttonProps}
                    />
                    <ToolbarMark
                        clear={options.superscript.clear}
                        icon={<Icon path={mdiFormatSuperscript} />}
                        tooltip={{ content: translate('editor.superscript') }}
                        type={options.superscript.type}
                        {...buttonProps}
                    />

                    <ToolbarLink
                        {...options}
                        icon={<Icon path={mdiLink} />}
                        tooltip={{ content: translate('editor.link') }}
                        {...buttonProps}
                    />

                    <Divider flexItem orientation="vertical" />

                    <ToolbarParagraph
                        icon={<Icon path={mdiFormatParagraph} />}
                        tooltip={{ content: translate('editor.paragraph') }}
                        {...buttonProps}
                    />

                    <ToolbarElement
                        icon={<Icon path={mdiFormatHeader1} />}
                        tooltip={{ content: translate('editor.header1') }}
                        type={options.h1.type}
                        {...buttonProps}
                    />
                    <ToolbarElement
                        icon={<Icon path={mdiFormatHeader2} />}
                        tooltip={{ content: translate('editor.header2') }}
                        type={options.h2.type}
                        {...buttonProps}
                    />
                    <ToolbarElement
                        icon={<Icon path={mdiFormatHeader3} />}
                        tooltip={{ content: translate('editor.header3') }}
                        type={options.h3.type}
                        {...buttonProps}
                    />
                    <ToolbarElement
                        icon={<Icon path={mdiFormatHeader4} />}
                        tooltip={{ content: translate('editor.header4') }}
                        type={options.h4.type}
                        {...buttonProps}
                    />

                    <Divider flexItem orientation="vertical" />

                    <ToolbarTable
                        {...options}
                        icon={<Icon path={mdiBorderAll} />}
                        tooltip={{ content: translate('editor.table') }}
                        {...buttonProps}
                    />

                    <ToolbarList
                        {...options}
                        icon={<Icon path={mdiFormatListBulleted} />}
                        tooltip={{ content: translate('editor.ul') }}
                        typeList={options.ul.type}
                        {...buttonProps}
                    />
                    <ToolbarList
                        {...options}
                        icon={<Icon path={mdiFormatListNumbered} />}
                        tooltip={{ content: translate('editor.ol') }}
                        typeList={options.ol.type}
                        {...buttonProps}
                    />
                    <ToolbarElement
                        icon={<Icon path={mdiFormatQuoteClose} />}
                        tooltip={{ content: translate('editor.blockquote') }}
                        type={options.blockquote.type}
                        {...buttonProps}
                    />

                    <Divider flexItem orientation="vertical" />

                    <ToolbarImage
                        {...options}
                        icon={<Icon path={mdiImage} />}
                        tooltip={{ content: translate('editor.image') }}
                        {...buttonProps}
                    />
                    <ToolbarFile
                        {...options}
                        icon={<Icon path={mdiFile} />}
                        tooltip={{ content: translate('editor.file') }}
                        {...buttonProps}
                    />
                    <ToolbarEmbed
                        {...options}
                        icon={<Icon path={mdiXml} />}
                        tooltip={{ content: translate('editor.embed') }}
                        {...buttonProps}
                    />

                    <Divider flexItem orientation="vertical" />

                    <ToolbarClearFormat
                        icon={<Icon path={mdiFormatClear} />}
                        tooltip={{ content: translate('editor.clearFormat') }}
                    />

                    <ToolbarButton
                        icon={<Icon path={mdiUndo} />}
                        tooltip={{ content: translate('editor.undo') }}
                        onMouseDown={(event) => {
                            event.preventDefault();

                            if (HistoryEditor.isHistoryEditor(editor)) {
                                editor.undo();
                            }
                        }}
                        {...buttonProps}
                    />

                    <ToolbarButton
                        icon={<Icon path={mdiRedo} />}
                        tooltip={{ content: translate('editor.redo') }}
                        onMouseDown={(event) => {
                            event.preventDefault();

                            if (HistoryEditor.isHistoryEditor(editor)) {
                                editor.redo();
                            }
                        }}
                        {...buttonProps}
                    />
                </HeadingToolbar>
            </Container>
        </Sticky>
    );
};

export const EditorToolbar = styled(BaseEditorToolbar)`
    display: flex;
    margin: auto;

    .slate-HeadingToolbar {
        border-bottom: 0;
        padding: ${({ theme }) => theme.spacing(1, 0)};
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > *:not(:last-child) {
            margin-right: 6px;
        }

        /* To allow hover state for buttons with file upload we just set it for all direct span children */
        > span:hover {
            background-color: ${({ theme }) => theme.palette.grey[100]};
        }
    }

    .slate-ToolbarButton {
        padding: 5px;
        border-radius: 4px;
        height: 28px;
        width: 28px;

        &:hover {
            color: inherit;
        }

        &.slate-ToolbarButton-active {
            color: inherit;
            background-color: ${({ theme }) => theme.palette.grey[200]};
        }

        > svg {
            width: 24px;
            height: 24px;
        }
    }
`;
