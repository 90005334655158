import { useTranslation } from 'react-i18next';

import { InfoRow } from 'common/components/InfoRow';
import { formatDate } from 'common/utils/formatDate';
import { useUserProfileExtendedQuery } from 'graphql/types';

interface IProps {
    userId: string;
}

export const UserExtendedInfoRows = ({ userId }: IProps) => {
    const [translate] = useTranslation();
    const { data } = useUserProfileExtendedQuery({ variables: { id: userId } });

    const { user } = data || {};

    if (!user) return null;

    const { invitedOn, lastLogin, userVia } = user;

    if (!invitedOn && !lastLogin && !userVia?.length) return null;

    return (
        <>
            {!!invitedOn && (
                <InfoRow description={translate('invitedOn')}>
                    {formatDate(invitedOn, 'd LLL yyyy HH:mm')}
                </InfoRow>
            )}

            {!!lastLogin && (
                <InfoRow description={translate('lastLogin')}>
                    {formatDate(lastLogin, 'd LLL yyyy HH:mm')}
                </InfoRow>
            )}

            {!!userVia?.length && (
                <InfoRow description={translate('via')}>
                    {userVia.join(', ')}
                </InfoRow>
            )}
        </>
    );
};
