import { IVideoStatus } from 'graphql/types';
import { Chip } from 'common/components/Chip';
import { translate } from 'utils/i18n';
import { IUploadFile } from 'common/contexts/FileUploadContext';

export function getVideoStatusChip(
    status: IVideoStatus,
    activeUpload?: IUploadFile
) {
    // When the file has a status of uploading but is not actively uploading we want to show the failed status.
    if (!activeUpload && status === IVideoStatus.Uploading) {
        return <Chip color="error" label={translate('videoStatus.failed')} />;
    }

    switch (status) {
        case IVideoStatus.Uploading:
            return <Chip label={translate('videoStatus.uploading')} />;
        case IVideoStatus.Queued:
            return <Chip label={translate('videoStatus.queued')} />;
        case IVideoStatus.Processing:
            return <Chip label={translate('videoStatus.processing')} />;
        case IVideoStatus.Encoding:
            return <Chip label={translate('videoStatus.encoding')} />;
        case IVideoStatus.Failed:
            return <Chip label={translate('videoStatus.failed')} />;
        default:
            return null;
    }
}
