import { useTranslation } from 'react-i18next';
import { mdiCompass } from '@mdi/js';
import { useReactiveVar } from '@apollo/client';

import { Icon } from 'common/components/Icon';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { ILinkModuleListItemFragment } from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const LinkModule = ({
    id,
    trainingId,
    title,
    description,
    url,
    disabled,
    conditions,
    alwaysOpen,
    startDate,
    image,
    conditionEffect,
    groupConditionEffect,
    canUpdate,
}: TTrainingModuleProps<ILinkModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    const itemContent = (
        <>
            <ListItemMedia color="primary" image={image?.url || undefined}>
                <Icon path={mdiCompass} size="1.8rem" sizeSm="3rem" />
            </ListItemMedia>
            <ListItemText primary={title} secondary={description}>
                <ModuleConditionsChips
                    alwaysOpen={alwaysOpen}
                    conditionEffect={conditionEffect}
                    conditions={conditions}
                    groupConditionEffect={groupConditionEffect}
                    startDate={startDate}
                />
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    if (!studentView && canUpdate) {
        return (
            <>
                <ListItem
                    button
                    component={Link}
                    params={{
                        trainingId,
                        moduleId: id,
                        type: 'link',
                    }}
                    to="TRAINING_UPDATE_MODULE"
                >
                    {itemContent}

                    <ModuleSecondaryAction forUpdate />
                </ListItem>
            </>
        );
    }

    return (
        <ListItem button component={Link} href={url} target="_blank">
            {itemContent}

            <ModuleSecondaryAction actionText={translate('open')} />
        </ListItem>
    );
};
