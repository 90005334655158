import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { mdiDownload } from '@mdi/js';

import { TableHead, TableCell, TableSortButton } from 'common/components/Table';
import {
    IPortfolioReportOrderField,
    IContentTypeValue,
    useExportPortfolioReportMutation,
    useCurrentUserQuery,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { Typography } from 'common/components/Typography';
import { BoxLoader } from 'common/components/Loader';
import { FilterBar } from 'common/components/FilterBar';
import { Pagination } from 'common/components/Pagination';
import { useReportList } from 'report/hooks/useReportList';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { PortfolioTrainingTableRow } from 'user/components/PortfolioTrainingTable';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';

interface IProps {
    userId?: string;
}

export const PortfolioTrainingTable = ({ userId }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [showExportDialog, setShowExportDialog] = useState(false);

    // Get current user email to use in export dialog
    const { data: currentUserData, loading: loadingCurrentUser } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};
    const currentUserEmail = currentUser?.email;

    const relatedObjectTypes = [IContentTypeValue.Training];

    const {
        items,
        loading: loadingReport,
        count,
        onSearch,
        onFilterChange,
        filters,
        filterValues,
        onFilterChangeValue,
        currentSort,
        onSort,
        setPage,
        paginationSettings,
        filterValuesForQuery,
    } = useReportList('portfolioTraining', !userId, {
        relatedObjectTypes,
        userIds: [userId],
    });

    const loading = loadingReport || loadingCurrentUser;

    const [exportReport, { loading: loadingExport }] =
        useExportPortfolioReportMutation({
            variables: {
                relatedObjectTypes,
                userIds: [userId],
                ...filterValuesForQuery,
            },
        });

    const handleExport = async () => {
        try {
            await exportReport();
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('reportPage.exportSuccess'), {
            variant: 'success',
        });

        setShowExportDialog(false);
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <>
            <FilterBar
                useFilterDrawer
                barAppend={
                    <Button
                        disabled={count === 0}
                        startIcon={<Icon path={mdiDownload} size="1.9rem" />}
                        sx={{ flex: 1 }}
                        variant="outlined"
                        onClick={() => setShowExportDialog(true)}
                    />
                }
                filterDrawerProps={{
                    title: translate('reportPage.filterFormTraining.title'),
                    description: translate(
                        'reportPage.filterFormTraining.description'
                    ),
                }}
                formFilters={filters}
                formFilterValues={filterValues}
                initialSearchValue={filterValues.q}
                placeholder={translate('filterBarPlaceholder')}
                onChangeField={onFilterChangeValue}
                onFiltersChange={(values) => {
                    setPage(1);
                    onFilterChange(values);
                }}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
            />

            {!loading && !!items.length && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    {translate('training')}
                                </TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        {translate('start')}
                                        <TableSortButton
                                            currentSortDirection={
                                                currentSort.direction
                                            }
                                            currentSortField={
                                                currentSort.field as string
                                            }
                                            sortField={
                                                IPortfolioReportOrderField.StartDate
                                            }
                                            onClick={() =>
                                                onSort(
                                                    IPortfolioReportOrderField.StartDate
                                                )
                                            }
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {translate('fulfilledOn')}
                                </TableCell>
                                <TableCell>{translate('time')}</TableCell>
                                <TableCell>{translate('score')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((reportItem) => (
                                <PortfolioTrainingTableRow
                                    key={reportItem.id}
                                    reportItem={reportItem}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loading && <BoxLoader />}

            {!loading && !items.length && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Typography>
                        {translate('noOptionsText.reportItems')}
                    </Typography>
                </Box>
            )}

            {!loading && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate('portfolioReportItems', {
                            count,
                        })}
                        onChange={(page: number) => {
                            setPage(page);
                        }}
                    />
                </Box>
            )}

            <ConfirmDialog
                confirmText={translate('export')}
                loading={loadingExport}
                open={showExportDialog}
                title={translate('exportPortfolioTrainingDialog.title')}
                onCancel={() => setShowExportDialog(false)}
                onClose={() => setShowExportDialog(false)}
                onConfirm={handleExport}
            >
                {translate('exportPortfolioTrainingDialog.text', {
                    email: currentUserEmail,
                })}
            </ConfirmDialog>
        </>
    );
};
