import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    SxProps,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { mdiArrowULeftTop } from '@mdi/js';
import { DateTime } from 'luxon';

import { TDateRange } from 'common/types';
import { getDateRangeText } from 'common/utils/formatDate';

import { Typography } from '../Typography';
import { Tab, Tabs } from '../Tabs';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';

const calendarStyle: SxProps = {
    '.MuiPickersCalendarHeader-root': {
        px: 0,
        mb: 2,
    },

    '.MuiPickersCalendarHeader-labelContainer': {
        fontSize: '1.6rem',
        fontWeight: 700,
    },

    '.MuiPickersDay-root': {
        '&:not(.Mui-disabled):not(.Mui-selected)': {
            backgroundColor: 'grey.100',
        },
    },
};

interface IProps {
    defaultDateRange?: TDateRange;
    disableEndDate?: boolean;
    open: boolean;
    onClose(): void;
    onChange?(dateRange?: TDateRange): void;
}

export const DateRangeModal = ({
    defaultDateRange,
    disableEndDate,
    open,
    onClose,
    onChange,
}: IProps) => {
    const [translate] = useTranslation();
    const [dateRange, setDateRange] = useState<TDateRange | undefined>(
        defaultDateRange
    );
    const [activeTab, setActiveTab] = useState(0);

    const startDateTranslation = translate('dateRangeModal.startDate');
    const endDateTranslation = translate('dateRangeModal.endDate');

    useEffect(() => {
        setDateRange(defaultDateRange);
    }, [defaultDateRange]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent sx={{ pb: 0 }}>
                <Typography sx={{ fontWeight: 700 }}>
                    {getDateRangeText(dateRange)}
                </Typography>

                {!disableEndDate && (
                    <Tabs value={activeTab}>
                        <Tab
                            label={startDateTranslation}
                            value={0}
                            onClick={() => setActiveTab(0)}
                        />

                        <Tab
                            label={endDateTranslation}
                            value={1}
                            onClick={() => setActiveTab(1)}
                        />
                    </Tabs>
                )}

                <Box
                    sx={{
                        display: activeTab === 0 ? 'block' : 'none',
                        '.PrivatePickersYear-yearButton': {
                            fontSize: '1.8rem',
                        },
                    }}
                >
                    <Box
                        disableFuture
                        disableHighlightToday
                        component={StaticDatePicker}
                        displayStaticWrapperAs="desktop"
                        renderInput={(params) => <TextField {...params} />}
                        sx={calendarStyle}
                        value={dateRange?.startDate}
                        onChange={(newValue) => {
                            setDateRange({
                                startDate: (newValue as DateTime) || undefined,
                                endDate: dateRange?.endDate,
                            });
                        }}
                    />
                </Box>

                {!disableEndDate && (
                    <Box
                        sx={{
                            display: activeTab === 1 ? 'block' : 'none',
                            '.PrivatePickersYear-yearButton': {
                                fontSize: '1.8rem',
                            },
                        }}
                    >
                        <Box
                            disableFuture
                            component={StaticDatePicker}
                            displayStaticWrapperAs="desktop"
                            minDate={dateRange?.startDate}
                            renderInput={(params) => <TextField {...params} />}
                            sx={calendarStyle}
                            value={dateRange?.endDate}
                            onChange={(newValue) => {
                                setDateRange({
                                    startDate: dateRange?.startDate,
                                    endDate:
                                        (newValue as DateTime) || undefined,
                                });
                            }}
                        />
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        mr: 'auto',
                        alignItems: 'center',
                        width: '100%',
                        p: 2,
                        pt: 0,
                    }}
                >
                    <Button
                        autoFocus
                        color="primary"
                        sx={{ mr: 1 }}
                        variant="contained"
                        onClick={() => {
                            onChange?.(dateRange);

                            setActiveTab(0);

                            onClose();
                        }}
                    >
                        {translate('dateRangeModal.filter')}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            setDateRange(defaultDateRange);

                            onClose();
                        }}
                    >
                        {translate('cancel')}
                    </Button>

                    <Box ml="auto">
                        <Tooltip title={translate<string>('reset')}>
                            <Box>
                                <IconButton
                                    disabled={!dateRange}
                                    iconPath={mdiArrowULeftTop}
                                    iconSize="2.4rem"
                                    onClick={() => {
                                        setDateRange(undefined);

                                        onClose();

                                        onChange?.();
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
