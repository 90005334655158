import { mdiSortAscending, mdiSortDescending } from '@mdi/js';

import { ISortDirection, Maybe } from 'graphql/types';
import { IconButton } from 'common/components/IconButton';

interface IProps extends React.ComponentProps<typeof IconButton> {
    currentSortField?: string;
    currentSortDirection?: Maybe<ISortDirection>;
    sortField: string;
}

export const TableSortButton = ({
    currentSortField,
    currentSortDirection,
    sortField,
    ...other
}: IProps) => {
    const color = currentSortField === sortField ? 'primary' : undefined;
    const icon =
        currentSortField === sortField &&
        currentSortDirection === ISortDirection.Asc
            ? mdiSortAscending
            : mdiSortDescending;

    return (
        <IconButton
            color={color}
            iconPath={icon}
            iconSize="1.6rem"
            sx={{ ml: 0.2 }}
            {...other}
        />
    );
};
