import { useEffect, useState } from 'react';
import { Autocomplete as MuiAutocomplete, TextFieldProps } from 'formik-mui';
import { Box, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Icon } from 'common/components/Icon';

import { VirtualizedAutocomplete } from './VirtualizedAutocomplete';

export type TIconOption = {
    value: string;
    label: string;
};

export const IconSelect = ({
    label,
    ...other
}: React.ComponentProps<typeof MuiAutocomplete> & TextFieldProps) => {
    const [translate] = useTranslation();
    const { setFieldValue } = useFormikContext();
    const [iconIndex, setIconIndex] = useState<Array<{ name: string }>>([]);
    const [MuiIcons, setMuiIcons] = useState<Record<string, string>>({});

    useEffect(() => {
        const loadIconModules = async () => {
            const icons = await import('@mdi/svg/meta.json');
            const muiIcons = await import('@mdi/js');

            setIconIndex(icons.default);
            const { default: _, ...muiIconsWithoutDefault } = muiIcons;
            setMuiIcons(muiIconsWithoutDefault);
        };

        loadIconModules();
    }, []);

    const iconOptions: TIconOption[] = iconIndex.map((item) => ({
        value: item.name,
        label: item.name,
    }));

    const getIcon = (iconName: string) => {
        const mdiName = ('mdi' +
            iconName
                .split('-')
                .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                .join('')) as keyof typeof MuiIcons;

        return MuiIcons[mdiName];
    };

    return (
        <VirtualizedAutocomplete
            {...other}
            disableClearable
            defaultValue="link-box-variant"
            isOptionEqualToValue={(option, value) =>
                // Because typings for VirtualizedAutocomplete are not correct we need to cast
                (option as TIconOption).value === (value as string)
            }
            options={iconOptions}
            renderInput={(params) => {
                const curVal = params.inputProps.value as string;
                const icon = getIcon(curVal);

                const inputProps =
                    curVal && icon
                        ? {
                              startAdornment: (
                                  <InputAdornment
                                      position="start"
                                      sx={{ ml: '5px' }}
                                  >
                                      <Icon path={icon} size="2rem" />
                                  </InputAdornment>
                              ),
                          }
                        : {};

                return (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            ...inputProps,
                        }}
                        label={label || translate('menuItem')}
                    />
                );
            }}
            renderOption={(props, option) => {
                const newOption = option as TIconOption;
                const icon = getIcon(newOption.label);

                return (
                    <Box {...props} component="li" key={newOption.value}>
                        {icon && <Icon path={icon} size="2rem" />}

                        <Box component="span" ml={1} mt="-3px">
                            {newOption.label}
                        </Box>
                    </Box>
                );
            }}
            onChange={(_event, value) => {
                const newValue = value as TIconOption;

                // Used field name from other because of issues when
                // unpacking field separately and trying to add it
                // to the Autocomplete component
                setFieldValue(other.field.name, newValue?.value);
            }}
        />
    );
};
