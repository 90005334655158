import { Box } from '@mui/material';
import { mdiAccountMultiple } from '@mdi/js';
import { useState } from 'react';
import { ApolloCache } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import { PageDrawer } from 'common/components/PageDrawer';
import {
    IContentTypeValue,
    useAddAuthorsMutation,
    useRemoveAuthorsMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { Button } from 'common/components/Button';

import { AuthorSelector } from '../AuthorSelector';

interface IProps {
    contentType: IContentTypeValue;
    objectId: string;
}

export const AuthorButton = ({ contentType, objectId }: IProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const [addAuthors, { loading: loadingAddAuthors }] =
        useAddAuthorsMutation();
    const [removeAuthors, { loading: loadingRemoveAuthors }] =
        useRemoveAuthorsMutation();

    // This function removes all author queries from the cache
    // It iterates through the cache as the author queries are saved
    // with variables and we want all variations removed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removeAuthorQueriesFromCache = (cache: ApolloCache<any>) => {
        // Get all keys from the cache
        const cacheObject = cache.extract();
        // Only the root query entry is needed
        const rootQuery = cacheObject['ROOT_QUERY'];

        // Iterate through the root queries
        Object.keys(rootQuery).forEach((key) => {
            // We only want to check the string type keys
            if (typeof key !== 'string') return;

            // When the query name starts with authors we know we want to evict it
            if (key.startsWith('authors')) {
                cache.evict({ fieldName: key });
            }
        });

        cache.gc();
    };

    const handleAddAuthors = async (selectedAuthors: string[]) => {
        try {
            await addAuthors({
                variables: {
                    contentType,
                    id: objectId,
                    userIds: selectedAuthors,
                },
                update(cache) {
                    removeAuthorQueriesFromCache(cache);
                },
            });
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const handleRemoveAuthors = async (selectedAuthors: string[]) => {
        try {
            await removeAuthors({
                variables: {
                    contentType,
                    id: objectId,
                    userIds: selectedAuthors,
                },
                update(cache) {
                    removeAuthorQueriesFromCache(cache);
                },
            });
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const loading = loadingAddAuthors || loadingRemoveAuthors;

    return (
        <>
            <Button
                size="small"
                startIcon={<Icon path={mdiAccountMultiple} size="1.9rem" />}
                variant="outlined"
                onClick={() => setDrawerOpen(true)}
            >
                {translate('authorSelector.authorsButton')}
            </Button>

            <PageDrawer
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false);
                }}
            >
                <Box
                    sx={{
                        px: { xs: 2, sm: 4 },
                        pt: { xs: 2, sm: 4 },
                    }}
                >
                    <AuthorSelector
                        contentQueryVariables={{
                            contentType,
                            objectId,
                        }}
                        loading={loading}
                        selectorQueryVariables={{
                            contentType,
                            objectId,
                        }}
                        onAddAuthors={handleAddAuthors}
                        onRemoveAuthors={handleRemoveAuthors}
                    />
                </Box>
            </PageDrawer>
        </>
    );
};
