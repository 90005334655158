import {
    IDateConditionComparisonEnum,
    ICourseConditionComparisonEnum,
    IBasicCourseFragment,
    IBasicLtiModuleFragment,
    IConditionFragment,
} from 'graphql/types';
import { translate } from 'utils/i18n';
import { formatDate } from 'common/utils/formatDate';
import { parseTimeDelta, IParsedTimeDelta } from 'common/utils/formatTimeDelta';
import { capitalize } from 'common/utils/capitalize';

interface IParsedAbsoluteCondition {
    startDateISO?: string;
    endDateISO?: string;
}

interface IParsedCourseCondition {
    module: IBasicCourseFragment | IBasicLtiModuleFragment;
    scoreThreshold?: number;
    preDelay?: IParsedTimeDelta;
    comparison: ICourseConditionComparisonEnum;
}

interface IParsedCondition {
    absoluteDates: IParsedAbsoluteCondition[];
    relativeDates: IParsedTimeDelta[];
    deadlineDates: IParsedTimeDelta[];
    courseConditions: IParsedCourseCondition[];
}

export function parseConditions(conditions: IConditionFragment[]) {
    const parsed: IParsedCondition = {
        absoluteDates: [],
        relativeDates: [],
        deadlineDates: [],
        courseConditions: [],
    };

    conditions.forEach((condition) => {
        // Parse AbsoluteDateCondition with Start comparison
        if (
            condition.__typename === 'AbsoluteDateCondition' &&
            condition.comparison === IDateConditionComparisonEnum.Start
        ) {
            // If we got an end condition before the start condition we want to search for the absoluteDate with an
            // end date first
            const absoluteDate = parsed.absoluteDates.find(
                (date) => !date.startDateISO && date.endDateISO
            );

            if (absoluteDate) {
                // If we found an absoluteDate we append the endDate there.
                absoluteDate.startDateISO = condition?.date;

                return;
            }

            parsed.absoluteDates.push({
                startDateISO: condition.date,
            });

            return;
        }

        if (
            condition.__typename === 'AbsoluteDateCondition' &&
            condition.comparison === IDateConditionComparisonEnum.End
        ) {
            // We currently don't have a solution to link endDate to a startDate, so we just try to get
            // the first absolute date that has a startDate and not an endDate
            const absoluteDate = parsed.absoluteDates.find(
                (date) => date.startDateISO && !date.endDateISO
            );

            if (absoluteDate) {
                // If we found an absoluteDate we append the endDate there.
                absoluteDate.endDateISO = condition?.date;

                return;
            }

            parsed.absoluteDates.push({
                endDateISO: condition.date,
            });

            return;
        }

        // Parse RelativeDateCondition
        if (condition.__typename === 'RelativeDateCondition') {
            parsed.relativeDates.push(parseTimeDelta(condition.offset));

            return;
        }

        if (condition.__typename === 'DeadlineCondition') {
            parsed.deadlineDates.push(parseTimeDelta(condition.offset));

            return;
        }

        if (condition.__typename === 'CourseClearedCondition') {
            const { module, scoreThreshold, preDelay, courseComparison } =
                condition;

            parsed.courseConditions.push({
                module,
                comparison: courseComparison,
                scoreThreshold:
                    scoreThreshold !== null ? scoreThreshold : undefined,
                preDelay: preDelay ? parseTimeDelta(preDelay) : undefined,
            });

            return;
        }
    });

    return parsed;
}

export function timeDeltaToString(timeDelta: IParsedTimeDelta) {
    const { weeks, days, hours } = timeDelta;

    const stringArray: string[] = [];

    if (weeks) {
        stringArray.push(translate(`dateWeeks`, { count: weeks }));
    }

    if (days) {
        stringArray.push(translate(`dateDays`, { count: days }));
    }

    if (hours) {
        stringArray.push(translate(`dateHours`, { count: hours }));
    }

    if (!stringArray.length) return '';
    // If the stringArray only contains 1 item we don't need to join it.
    if (stringArray.length === 1) return stringArray[0];

    // Join all texts together and join last items with "and"
    const firstPart = stringArray.slice(0, -1).join(', ');
    const lastPart = stringArray.slice(-1);

    return `${firstPart} ${translate('en')} ${lastPart}`;
}

export function absoluteDateToString(
    startDateISO?: string,
    endDateISO?: string,
    startDateFormat?: string,
    endDateFormat?: string
) {
    const dateArray: string[] = [];

    if (startDateISO) {
        const startFormat = startDateFormat || 'd MMMM yyyy HH:mm';
        const startDate = formatDate(startDateISO, startFormat, true, true);

        dateArray.push(translate('conditionString.startDate', { startDate }));
    }

    if (endDateISO) {
        const endFormat = endDateFormat || 'HH:mm';
        const endDate = formatDate(endDateISO, endFormat, true, true);

        dateArray.push(translate('conditionString.endDate', { endDate }));
    }

    return dateArray.join(' ');
}

export function absoluteDateConditionsToString(
    absoluteDates: IParsedAbsoluteCondition[],
    startDateFormat?: string,
    endDateFormat?: string
) {
    return absoluteDates
        .map((absoluteDate) => {
            const { startDateISO, endDateISO } = absoluteDate;

            return absoluteDateToString(
                startDateISO,
                endDateISO,
                startDateFormat,
                endDateFormat
            );
        })
        .join(', ');
}

export function relativeDateConditionsToString(
    relativeDates: IParsedTimeDelta[]
) {
    if (!relativeDates.length) return '';

    return relativeDates
        .map(
            (relativeDate) =>
                `${timeDeltaToString(relativeDate)} ${translate(
                    'conditionString.afterStartingDate'
                )}`
        )
        .join(', ');
}

export function courseConditionsToString(
    courseConditions: IParsedCourseCondition[]
) {
    const courseConditionString = courseConditions
        .map((courseCondition) => {
            const courseArray = [];
            const { module, scoreThreshold, preDelay, comparison } =
                courseCondition;

            const title = module.title;

            courseArray.push(translate('conditionString.opens'));

            if (preDelay) {
                courseArray.push(timeDeltaToString(preDelay));
            }

            courseArray.push(
                translate('conditionString.clearCourse', {
                    courseTitle: title,
                })
            );

            if (scoreThreshold !== undefined && scoreThreshold >= 0) {
                const score = Math.round(scoreThreshold * 100);
                // Get comparison string < or > based on the type
                const compare =
                    comparison === ICourseConditionComparisonEnum.Min
                        ? translate('conditionString.comparisonGt')
                        : translate('conditionString.comparisonLt');

                courseArray.push(
                    translate('conditionString.withScore', {
                        comparison: compare,
                        score,
                    })
                );
            }

            return courseArray.join(' ');
        })
        .join(', ');

    return capitalize(courseConditionString);
}

export function deadlineDateConditionsToString(
    deadlineDates: IParsedTimeDelta[]
) {
    return deadlineDates
        .map((deadlineDate) => {
            const deadlineString = timeDeltaToString(deadlineDate);

            return `${translate('conditionString.deadlineDate', {
                deadlineDate: deadlineString,
            })}`;
        })
        .join(', ');
}
