import { FormControlLabel } from '@mui/material';

import { FilterDateRangeSection } from 'common/components/FilterDateRangeSection';
import {
    EFilterType,
    IFilterFormItem,
    TStringFilterValue,
    TDateFilterValue,
    TRangeFilterValue,
} from 'common/types';
import { translate } from 'utils/i18n';
import {
    getRangeFilterDisplay,
    parseDateTimeFilterValue,
} from 'common/utils/filter';
import {
    SliderField,
    RadioGroup,
    DatePicker,
} from 'common/components/FormField';
import { Radio } from 'common/components/Radio';

export function startDateRangeFilter(
    transKey = 'reportFilters.startDateRange'
): IFilterFormItem<TDateFilterValue> {
    return {
        key: 'startDateRange',
        label: translate(`${transKey}.sectionTitle`),
        component: FilterDateRangeSection,
        fields: [
            {
                name: 'startDateFrom',
                type: EFilterType.Date,
                label: translate(`${transKey}.labelFrom`),
                initialValue: null,
                component: DatePicker,
                componentProps: {
                    slotProps: {
                        field: { clearable: true },
                    },
                },
                chip: {
                    label: translate(`${transKey}.chipFrom`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
            {
                name: 'startDateTo',
                type: EFilterType.Date,
                label: translate(`${transKey}.labelTo`),
                initialValue: null,
                component: DatePicker,
                chip: {
                    label: translate(`${transKey}.chipTo`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
        ],
    };
}

export function endDateRangeFilter(
    transKey = 'reportFilters.endDateRange'
): IFilterFormItem<TDateFilterValue> {
    return {
        key: 'endDateRange',
        label: translate(`${transKey}.sectionTitle`),
        component: FilterDateRangeSection,
        fields: [
            {
                name: 'endDateFrom',
                type: EFilterType.Date,
                label: translate(`${transKey}.labelFrom`),
                initialValue: null,
                component: DatePicker,
                componentProps: {
                    slotProps: {
                        field: { clearable: true },
                    },
                },
                chip: {
                    label: translate(`${transKey}.chipFrom`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
            {
                name: 'endDateTo',
                type: EFilterType.Date,
                label: translate(`${transKey}.labelTo`),
                initialValue: null,
                component: DatePicker,
                chip: {
                    label: translate(`${transKey}.chipTo`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
        ],
    };
}

export function completionDateRangeFilter(
    transKey = 'reportFilters.completionDateRange'
): IFilterFormItem<TDateFilterValue> {
    return {
        key: 'completionDateRange',
        label: translate(`${transKey}.sectionTitle`),
        component: FilterDateRangeSection,
        fields: [
            {
                name: 'completionDateFrom',
                type: EFilterType.Date,
                label: translate('reportFilters.completionDateRange.labelFrom'),
                initialValue: null,
                component: DatePicker,
                chip: {
                    label: translate(`${transKey}.chipFrom`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
            {
                name: 'completionDateTo',
                type: EFilterType.Date,
                label: translate(`${transKey}.labelTo`),
                initialValue: null,
                component: DatePicker,
                chip: {
                    label: translate(`${transKey}.chipTo`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
        ],
    };
}

export function progressFilter(
    transKey = 'reportFilters.progress'
): IFilterFormItem<TRangeFilterValue> {
    return {
        key: 'progress',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: 'progress',
                type: EFilterType.Range,
                initialValue: [0, 1],
                component: SliderField,
                componentProps: {
                    min: 0,
                    max: 1,
                    step: 0.01,
                    valueLabelFormat: (value: string) =>
                        `${Math.floor(+value * 100)}%`,
                    marks: [
                        {
                            value: 0,
                            label: '0%',
                        },
                        {
                            value: 1,
                            label: '100%',
                        },
                    ],
                },
                chip: {
                    label: translate(`${transKey}.chip`),
                    parseValue: getRangeFilterDisplay,
                    appendValue: '%',
                },
            },
        ],
    };
}

export function scoreFilter(
    transKey = 'reportFilters.score'
): IFilterFormItem<TRangeFilterValue> {
    return {
        key: 'score',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: 'score',
                type: EFilterType.Range,
                initialValue: [0, 1],
                component: SliderField,
                componentProps: {
                    min: 0,
                    max: 1,
                    step: 0.01,
                    valueLabelFormat: (value: string) =>
                        `${Math.floor(+value * 100)}%`,
                    marks: [
                        {
                            value: 0,
                            label: '0%',
                        },
                        {
                            value: 1,
                            label: '100%',
                        },
                    ],
                },
                chip: {
                    label: translate(`${transKey}.chip`),
                    parseValue: getRangeFilterDisplay,
                    appendValue: '%',
                },
            },
        ],
    };
}

export function completionStatusFilter(
    transKey = 'reportFilters.completionStatus'
): IFilterFormItem<TStringFilterValue> {
    return {
        key: 'completionStatus',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: 'completed',
                type: EFilterType.Radio,
                component: RadioGroup,
                initialValue: 'all',
                componentProps: {
                    children: [
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="all"
                            label={translate(`${transKey}.allOption`)}
                            value="all"
                        />,
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="true"
                            label={translate(`${transKey}.completedOption`)}
                            value="true"
                        />,
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="false"
                            label={translate(`${transKey}.notCompletedOption`)}
                            value="false"
                        />,
                    ],
                },
                chip: {
                    label: translate(`${transKey}.chip`),
                    parseValue: (value) => {
                        switch (value) {
                            case 'true':
                                return translate(`${transKey}.completedValue`);
                            case 'false':
                                return translate(
                                    `${transKey}.notCompletedValue`
                                );
                            default:
                                return translate(`${transKey}.allValue`);
                        }
                    },
                },
                parseQueryValue: (value) => {
                    if (value === 'all') return;

                    return value === 'true';
                },
            },
        ],
    };
}

export function scoreThresholdReachedFilter(
    transKey = 'reportFilters.scoreThresholdReached'
): IFilterFormItem<TStringFilterValue> {
    return {
        key: 'scoreThresholdReached',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: 'scoreThresholdReached',
                type: EFilterType.Radio,
                component: RadioGroup,
                initialValue: 'all',
                componentProps: {
                    children: [
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="all"
                            label={translate(`${transKey}.allOption`)}
                            value="all"
                        />,
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="true"
                            label={translate(`${transKey}.reachedOption`)}
                            value="true"
                        />,
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="false"
                            label={translate(`${transKey}.notReachedOption`)}
                            value="false"
                        />,
                    ],
                },
                chip: {
                    label: translate(`${transKey}.chip`),
                    parseValue: (value) => {
                        switch (value) {
                            case 'true':
                                return translate(`${transKey}.reachedValue`);
                            case 'false':
                                return translate(`${transKey}.notReachedValue`);
                            default:
                                return translate(`${transKey}.allValue`);
                        }
                    },
                },
                parseQueryValue: (value) => {
                    if (value === 'all') return;

                    return value === 'true';
                },
            },
        ],
    };
}
