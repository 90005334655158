import { mdiChevronRight } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import {
    ListItem,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Chip, ChipGroup } from 'common/components/Chip';
import { TColorKeys } from 'theme/palette';

interface IProps {
    title?: string;
    description?: string;
    icon: string;
    color: TColorKeys;
    hidden?: boolean;
    showFullSecondary?: boolean;
}

export const LibraryListItem = ({
    title,
    description,
    icon,
    color,
    hidden,
    showFullSecondary,
    ...other
}: React.ComponentProps<typeof ListItem> & IProps) => {
    const [translate] = useTranslation();

    return (
        <ListItem button component={Link} {...other}>
            <ListItemMedia color={color}>
                <Icon path={icon} size="2.2rem" sizeSm="3rem" />
            </ListItemMedia>
            <ListItemText
                primary={title}
                secondary={description}
                showFullSecondary={showFullSecondary}
            >
                {hidden && (
                    <ChipGroup>
                        <Chip bgColor="warning" label={translate('hidden')} />
                    </ChipGroup>
                )}
            </ListItemText>
            <ListItemSecondaryAction hideXs>
                <Icon path={mdiChevronRight} size="4rem" />
            </ListItemSecondaryAction>
        </ListItem>
    );
};
