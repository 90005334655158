import { styled } from 'styled-components';
import { motion } from 'framer-motion';

import { getAbsoluteDate } from 'common/utils/formatDate';

import { Typography } from '../Typography';

interface IProps {
    className?: string;
    date: string;
}

export const BaseListItemMediaDate = ({
    className,
    date,
    ...other
}: IProps) => (
    <motion.div
        className={className}
        transition={{ type: 'easeOut' }}
        variants={{
            initListItemButton: {
                scale: 1,
            },
            hoverListItemButton: {
                scale: 1.2,
                transition: { type: 'easeIn' },
            },
        }}
        {...other}
    >
        <Typography className="date">
            <span className="day">{getAbsoluteDate(date, 'd')}</span>

            {getAbsoluteDate(date, 'MMM')}
        </Typography>
    </motion.div>
);

export const ListItemMediaDate = styled(BaseListItemMediaDate)`
    .date {
        text-transform: uppercase;
        font-weight: 700;
        font-size: ${({ theme }) => theme.typography.pxToRem(12)};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: ${({ theme }) => theme.spacing(-1)};

        .day {
            font-size: ${({ theme }) => theme.typography.pxToRem(40)};
            margin-bottom: ${({ theme }) => theme.spacing(-2.5)};
        }
    }
`;
