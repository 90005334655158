import MuiAccordionDetails, {
    AccordionDetailsProps,
} from '@mui/material/AccordionDetails';

export const SimpleAccordionDetails = ({ ...other }: AccordionDetailsProps) => (
    <MuiAccordionDetails
        sx={{ width: '100%', p: 0, pb: { xs: 2, sm: 3 }, mt: -2 }}
        {...other}
    />
);
