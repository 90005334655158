import { forwardRef } from 'react';
import Fab, { FabProps } from '@mui/material/Fab';
import { styled, css } from 'styled-components';

export interface IActionButtonProps {
    outlined?: boolean;
    sizeSm?: boolean;
}

export function BaseActionButton<C extends React.ElementType>(
    {
        outlined,
        children,
        sizeSm,
        ...other
    }: FabProps<C, { component?: C } & IActionButtonProps>,
    ref: React.Ref<HTMLButtonElement>
) {
    return (
        <Fab color="inherit" ref={ref} {...other}>
            {children}
        </Fab>
    );
}

export const ActionButton = styled(forwardRef(BaseActionButton))`
    box-shadow: none;

    width: ${({ sizeSm }) => (sizeSm ? '30px' : '45px')};
    height: ${({ sizeSm }) => (sizeSm ? '30px' : '45px')};
    min-height: 0;
    display: inline-flex;
    line-height: 1;
    z-index: auto;

    white-space: nowrap;
    text-transform: none;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    background-color: ${({ theme, color }) =>
        !color && theme.palette.common.white};

    &:focus {
        box-shadow: none;
    }

    &:hover {
        ${({ theme, color }) =>
            color === 'primary' || color === 'secondary'
                ? `background-color: ${
                      // Force key type to fix type error
                      theme.palette[color as 'primary' | 'secondary'].accent
                  }`
                : `background-color: ${theme.palette.action.hover}`};
    }

    svg:not(.MuiCircularProgress-svg) {
        height: 2.5rem;
        width: 2.5rem;
    }

    &.MuiFab-sizeSmall {
        width: 25px;
        height: 25px;
        font-size: ${({ theme }) => theme.typography.pxToRem(12)};

        svg:not(.MuiCircularProgress-svg) {
            height: 2.1rem;
            width: 2.1rem;
        }
    }

    &.MuiFab-sizeMedium {
        width: 33px;
        height: 33px;
        font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    }

    &.MuiFab-extended {
        width: auto;

        padding: ${({ theme }) => theme.spacing(0, 3)};

        &.MuiFab-sizeSmall {
            padding: ${({ theme }) => theme.spacing(0, 1)};
        }
        &.MuiFab-sizeMedium {
            padding: ${({ theme }) => theme.spacing(0, 2)};
        }

        > .MuiBox-root:first-child {
            display: flex;
            margin-left: -${({ theme }) => theme.spacing(1)};
            margin-right: ${({ theme }) => theme.spacing(1)};
        }
    }

    ${({ theme, outlined }) =>
        outlined &&
        css`
            border: 1px solid ${theme.palette.divider};
            background: transparent;

            &:hover {
                background-color: ${theme.palette.action.hover};
            }
        `}

    &.MuiLink-underlineHover:hover {
        text-decoration: none;
    }

    &.Mui-disabled {
        background-color: ${({ theme }) => theme.palette.grey[100]};
    }
`;
