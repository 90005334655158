import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
    ICreateFileModuleInput,
    IFileFragment,
    IOpenOffsetType,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { FileField, TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { DEFAULT_MAX_FILE_SIZE } from 'common/constants/files';

export interface IFileModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateFileModuleInput, 'fileId' | 'offset'> {
    file?: IFileFragment;
}

export interface IProps {
    initialValues?: Partial<IFileModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    onSubmit(values: IFileModuleFormValues): void;
    onDelete?(): void;
    moduleGroupId?: string;
    onDelete?(): void;
}

export const FileModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [settingsExpanded, setSettingsExpanded] = useState(false);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        file: Yup.object().required(translate('validate.required')),
    });

    const initValues: IFileModuleFormValues = {
        title: '',
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
        ...initialValues,
    };

    const formTitle = edit
        ? translate('moduleForm.file.editTitle')
        : translate('moduleForm.file.newTitle');
    const formText = edit
        ? translate('moduleForm.file.editText')
        : translate('moduleForm.file.newText');
    const submitText = edit
        ? translate('moduleForm.file.editButton')
        : translate('moduleForm.file.newButton');

    return (
        <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Form>
                        {formTitle && formText && (
                            <Box mb={4}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box sx={{ mb: 2 }}>
                            <Field
                                component={FileField}
                                helperText={translate(
                                    'moduleForm.file.fileHelperText',
                                    { count: DEFAULT_MAX_FILE_SIZE }
                                )}
                                label={translate('file')}
                                maxUploadSize={DEFAULT_MAX_FILE_SIZE}
                                name="file"
                            />
                        </Box>

                        <Field
                            component={TextField}
                            label={translate('title')}
                            name="title"
                        />

                        <Field
                            multiline
                            component={TextField}
                            label={translate('description')}
                            name="description"
                        />

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <ModuleImageFormSection />
                                {/* Only show conditions section when we have a module group id */}
                                {moduleGroupId && (
                                    <ModuleOffsetFormSection
                                        moduleGroupId={moduleGroupId}
                                        offsetValues={
                                            values.offset || undefined
                                        }
                                    />
                                )}
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
