import { Field, useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
    Autocomplete,
    InputAdornment,
    Select,
    Switch,
    TextField,
} from 'common/components/FormField';
import {
    IConditionEffect,
    ICourseComparison,
    ICourseOrLtiModule,
    IUpdateModuleGroupInput,
    useTrainingModulesQuery,
} from 'graphql/types';
import {
    getConditionOffsetTypeTranslation,
    getCourseComparisonTranslation,
} from 'training/utils/moduleGroup';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { CONDITION_OFFSET_TYPE_LIST } from 'training/constants/moduleGroup';
import { IConditionType } from 'training/types';

interface IProps {
    disabled?: boolean;
}

export const ConditionCourseCompletedFormFields = ({ disabled }: IProps) => {
    const { trainingId } = useParams() as { trainingId: string };
    const [translate] = useTranslation();
    const { values, setFieldValue } =
        useFormikContext<IUpdateModuleGroupInput>();
    const { data: trainingModulesData } = useTrainingModulesQuery({
        variables: { id: trainingId },
    });

    const { training } = trainingModulesData || {};
    const { moduleGroups } = training || {};

    // We only want the course or lti module
    const courseOrLtiModules: ICourseOrLtiModule[] = [];
    moduleGroups?.forEach((moduleGroup) => {
        const filteredModules = moduleGroup?.modules
            ?.map((module) => {
                const isCourse = module?.__typename === 'CourseModule';
                const isLti = module?.__typename === 'LTIModule';

                if (!isCourse && !isLti) return null;

                if (isCourse) return module.course;

                return module;
            })
            .filter(Boolean) as ICourseOrLtiModule[];

        courseOrLtiModules.push(...filteredModules);
    });

    const transKeyPrefix = 'moduleGroupForm.courseCompletionCondition';

    if (!courseOrLtiModules.length) {
        return (
            <Box mt={2}>{translate(`${transKeyPrefix}.noModulesFound`)}</Box>
        );
    }

    const courseComparison: ICourseComparison[] = [
        ICourseComparison.None,
        ICourseComparison.Minimum,
        ICourseComparison.Maximum,
    ];

    return (
        <>
            <FormSection
                defaultExpanded
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey={`${transKeyPrefix}.preConditionSectionDescription`}
                        />
                    </Box>
                }
                title={translate(`${transKeyPrefix}.preConditionSectionTitle`)}
            >
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <Field
                            disableClearable
                            required
                            component={Autocomplete}
                            disabled={disabled}
                            getOptionLabel={(option: ICourseOrLtiModule) =>
                                option.title
                            }
                            InputProps={{
                                label: translate(`${transKeyPrefix}.course`),
                            }}
                            isOptionEqualToValue={(
                                option: ICourseOrLtiModule,
                                value: ICourseOrLtiModule
                            ) => option.id === value.id}
                            name="courseCompletionCondition.courseOrLti"
                            options={courseOrLtiModules}
                        />
                    </Grid>

                    <Grid item sm={3} sx={{ mt: { xs: -2, sm: 0 } }} xs={8}>
                        <Field
                            component={Select}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.score`)}
                            name="courseCompletionCondition.comparison"
                            options={courseComparison.map(
                                (courseComparison) => ({
                                    value: courseComparison,
                                    label: getCourseComparisonTranslation(
                                        courseComparison
                                    ),
                                })
                            )}
                        />
                    </Grid>

                    <Grid item sm={3} sx={{ mt: { xs: -2, sm: 0 } }} xs={4}>
                        <Field
                            component={TextField}
                            disabled={disabled}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                            }}
                            name="courseCompletionCondition.scoreThreshold"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item sm={2} xs={4}>
                        <Field
                            component={TextField}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.status`)}
                            name="courseCompletionCondition.preDelay.amount"
                        />
                    </Grid>

                    <Grid item sm={10} xs={8}>
                        <Field
                            component={Select}
                            disabled={disabled}
                            name="courseCompletionCondition.preDelay.type"
                            options={CONDITION_OFFSET_TYPE_LIST.map(
                                (offsetType) => ({
                                    value: offsetType,
                                    label: getConditionOffsetTypeTranslation(
                                        offsetType,
                                        IConditionType.CourseCompletion
                                    ),
                                })
                            )}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mt: 1 }}>
                    <Field
                        checked={
                            !!values.courseCompletionCondition?.sendReminder
                        }
                        component={Switch}
                        disabled={disabled}
                        formControlProps={{
                            margin: 'none',
                        }}
                        label={translate(`${transKeyPrefix}.sendReminder`)}
                        name="courseCompletionCondition.sendReminder"
                    />
                </Box>

                <Box sx={{ mb: -1 }}>
                    <Field
                        checked={
                            values.courseCompletionCondition
                                ?.preConditionEffect === IConditionEffect.Hide
                        }
                        component={Switch}
                        disabled={disabled}
                        formControlProps={{
                            margin: 'none',
                        }}
                        label={translate('moduleGroupForm.hidden')}
                        name="courseCompletionCondition.preConditionEffect"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFieldValue(
                                'courseCompletionCondition.preConditionEffect',
                                event.target.checked
                                    ? IConditionEffect.Hide
                                    : IConditionEffect.Lock
                            );
                        }}
                    />
                </Box>
            </FormSection>

            <FormSection
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey={`${transKeyPrefix}.postConditionSectionDescription`}
                        />
                    </Box>
                }
                title={translate(`${transKeyPrefix}.postConditionSectionTitle`)}
            >
                <Grid container spacing={2}>
                    <Grid item sm={2} xs={4}>
                        <Field
                            component={TextField}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.deadline`)}
                            name="courseCompletionCondition.postDelay.amount"
                        />
                    </Grid>

                    <Grid item sm={10} xs={8}>
                        <Field
                            component={Select}
                            disabled={disabled}
                            name="courseCompletionCondition.postDelay.type"
                            options={CONDITION_OFFSET_TYPE_LIST.map(
                                (offsetType) => ({
                                    value: offsetType,
                                    label: getConditionOffsetTypeTranslation(
                                        offsetType,
                                        IConditionType.CourseCompletion
                                    ),
                                })
                            )}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mb: -1, mt: 1 }}>
                    <Field
                        checked={
                            values.courseCompletionCondition
                                ?.postConditionEffect === IConditionEffect.Hide
                        }
                        component={Switch}
                        disabled={disabled}
                        formControlProps={{
                            margin: 'none',
                        }}
                        label={translate('moduleGroupForm.hidden')}
                        name="courseCompletionCondition.postConditionEffect"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFieldValue(
                                'courseCompletionCondition.postConditionEffect',
                                event.target.checked
                                    ? IConditionEffect.Hide
                                    : IConditionEffect.Lock
                            );
                        }}
                    />
                </Box>
            </FormSection>
        </>
    );
};
