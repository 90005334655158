import { TypographyVariantsOptions } from '@mui/material/styles';

import { IFont, IThemeTypography } from 'graphql/types';
import { ELoadFontWeight, EFontStyle } from 'common/types';
import { loadFonts } from 'common/utils/loadFonts';

const DEFAULT_FONT_FAMILY = 'Lato';
export const FALLBACK_FONT_FAMILY =
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif';

const SIZE_MULTIPLIERS = {
    XS: 0.6,
    SM: 0.8,
    MD: 1,
    LG: 1.2,
    XL: 1.4,
};

interface ITypography {
    size: number;
    lineHeight?: number;
    weight?: number;
}

export const DEFAULT_TYPOGRAPHY: { [key: string]: ITypography } = {
    body1: {
        size: 1.8,
        lineHeight: 1.8,
    },
    body2: {
        size: 1.6,
        lineHeight: 1.5,
    },
    h1: {
        size: 4,
        lineHeight: 1.2,
        weight: 700,
    },
    h2: {
        size: 2.4,
        lineHeight: 1.2,
        weight: 700,
    },
    h3: {
        size: 2,
        lineHeight: 1.6,
        weight: 700,
    },
    h4: {
        size: 1.8,
        lineHeight: 1.6,
        weight: 700,
    },
    h5: {
        size: 1.8,
        lineHeight: 1.6,
        weight: 700,
    },
    h6: {
        size: 1.8,
        lineHeight: 1.6,
        weight: 700,
    },
    subtitle1: {
        size: 2.4,
        lineHeight: 1.2,
        weight: 400,
    },
    button: {
        size: 1.6,
    },
    caption: {
        size: 1.4,
    },
};

function getFontFamily(variant?: IFont | null) {
    if (!variant?.fontFamily) return DEFAULT_FONT_FAMILY;

    return variant?.fontFamily;
}

function getTypography(
    type: keyof typeof DEFAULT_TYPOGRAPHY,
    variant?: IFont | null,
    useDefaultWeight = false
) {
    const sizeMultiplier = variant
        ? SIZE_MULTIPLIERS[variant.size]
        : SIZE_MULTIPLIERS.MD;

    let fontWeight = DEFAULT_TYPOGRAPHY[type].weight;

    if (!useDefaultWeight && variant) {
        fontWeight = variant.weight ? parseInt(variant.weight) : fontWeight;
    }

    return {
        fontFamily: `"${getFontFamily(variant)}", ${FALLBACK_FONT_FAMILY}`,
        fontSize: `${DEFAULT_TYPOGRAPHY[type].size * sizeMultiplier}rem`,
        lineHeight: DEFAULT_TYPOGRAPHY[type].lineHeight,
        fontWeight,
    };
}

export const createTypography = (
    typography?: IThemeTypography
): TypographyVariantsOptions => {
    const { body, title, subtitle, customFonts } = typography || {};

    // Create array of font families to load
    const fontFamilies = [
        {
            family: getFontFamily(body),
            // Always add Bold weight for body font
            weights: [
                body?.weight || ELoadFontWeight.Normal,
                ELoadFontWeight.Bold,
            ],
            styles: [EFontStyle.Normal, EFontStyle.Italic],
        },
        {
            family: getFontFamily(title),
            weights: [title?.weight || 700],
        },
        {
            family: getFontFamily(subtitle),
            weights: [subtitle?.weight || 700],
        },
    ].filter(
        (fontFamily) =>
            // Check if fontFamily is a customFont
            !customFonts?.some((cf) => cf.family === fontFamily.family)
    );

    if (fontFamilies.length) {
        loadFonts(fontFamilies);
    }

    // Load Custom Fonts for organsation
    if (customFonts?.length) {
        customFonts.forEach((customFont) => {
            const { family, italic, weight, file } = customFont;

            const fontFace = new FontFace(family, `url(${file.url})`);
            fontFace.weight = weight ? weight : '400';
            fontFace.style = italic ? 'italic' : 'normal';

            fontFace
                .load()
                .then((loadedFace) => document.fonts.add(loadedFace));
        });
    }

    return {
        htmlFontSize: 10,
        fontFamily: `${getFontFamily(body)}, ${FALLBACK_FONT_FAMILY}`,
        // body1: getTypography('body1', body),
        body1: getTypography('body1', body),
        body2: getTypography('body2', body),
        h1: getTypography('h1', title),
        h2: getTypography('h2', subtitle),
        h3: getTypography('h3', subtitle),
        // h4, h5, h6 also use the body styling, but we always use the default weight.
        h4: getTypography('h4', body, true),
        h5: getTypography('h5', body, true),
        h6: getTypography('h6', body, true),
        subtitle1: getTypography('subtitle1', body),
        button: getTypography('button', body),
        caption: getTypography('caption', body),
    };
};
