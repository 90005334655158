import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import ERROR_IMAGE from 'assets/images/pluvo-error.webp';
import { BasePage } from 'hydra/pages/BasePage';
import { Img } from 'common/components/Img';
import { Button } from 'common/components/Button';
import { PageTitle } from 'common/components/PageTitle';

export const ErrorPage = () => {
    const navigate = useNavigate();
    const [sentryId, setSentryId] = useState<string>();
    const { t: translate } = useTranslation();
    const error = useRouteError();

    useEffect(() => {
        if (sentryId) return;

        // Manually throw error to sentry so we can show the exception id to the user.
        const exceptionId = Sentry.captureException(error);

        setSentryId(exceptionId);
    }, [error, sentryId]);

    return (
        <BasePage>
            <PageTitle mixpanelTitle="Error page">
                {translate('errorPage.browserTitle')}
            </PageTitle>
            <Container maxWidth="sm">
                <Box sx={{ mt: 10, textAlign: 'center' }}>
                    <Box maxWidth={400} mb={4} mx="auto">
                        <Img src={ERROR_IMAGE} />
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h1">
                            {translate('errorPage.title')}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography>{translate('errorPage.text')}</Typography>
                    </Box>
                    <Box mb={4}>
                        <Typography
                            sx={{ color: 'text.secondary' }}
                            variant="caption"
                        >
                            {sentryId}
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => navigate(-1)}
                    >
                        {translate('errorPage.buttonText')}
                    </Button>
                </Box>
            </Container>
        </BasePage>
    );
};
