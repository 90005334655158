import { useEffect, useMemo } from 'react';

import { TPaginationSettings, usePagination } from 'common/hooks/usePagination';
import {
    IUserListItemFragment,
    IUserSelectorInput,
    IUserSelectorQueryVariables,
} from 'graphql/types';
import { useSelection } from 'common/components/Table';
import {
    ISearchProps,
    ITableSelectionProps,
    TFilterBarItem,
} from 'common/types';

import { useUserSearch } from './useUserSearch';
import { useUserQueryVariables } from './useUserQueryVariables';
import { IUserList } from './useUserList';

export interface IUserTableData {
    loading?: boolean;
    tableDataProps: {
        paginationProps?: {
            paginationSettings: TPaginationSettings;
            setPage: (page: number) => void;
        };
        searchProps: ISearchProps;
        selectionProps: ITableSelectionProps<IUserListItemFragment>;
    };
    users?: IUserListItemFragment[];
    alreadyAddedUsers: string[];
}

export const useUserTableData = <T>(
    disabledUsers: IUserListItemFragment[] = [],
    useUserList: (
        userQueryVariables?: IUserSelectorQueryVariables,
        skip?: boolean,
        selectorQueryVariables?: IUserSelectorInput
    ) => IUserList,
    skip?: boolean,
    extraQueryVariables?: T,
    selectorQueryVariables?: IUserSelectorInput
): IUserTableData => {
    const {
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
        ...userSearchProps
    } = useUserSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { userQueryVariables } = useUserQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const queryVariables = useMemo(
        () => ({ ...userQueryVariables, ...extraQueryVariables }),
        [userQueryVariables, extraQueryVariables]
    );

    const {
        users,
        alreadyAddedUsers,
        loading: usersLoading,
        usersCount,
    } = useUserList(queryVariables, skip, selectorQueryVariables);

    const selectFilter = (user: IUserListItemFragment) =>
        !disabledUsers?.some((addedUser) => addedUser.id === user.id);

    const { ...selectionProps } = useSelection(users, selectFilter);

    useEffect(() => {
        if (usersLoading || !initializePagination) return;

        initializePagination(usersCount || 0);
    }, [usersCount, usersLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect(selected);
    };

    return {
        loading: usersLoading,
        tableDataProps: {
            paginationProps: { paginationSettings, setPage },
            searchProps: {
                ...userSearchProps,
                handleSearch,
                handleFilterSelect,
                searchQueryParam,
                selectedFilters,
            },
            selectionProps,
        },
        users,
        alreadyAddedUsers,
    };
};
