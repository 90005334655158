import { NavigateFunction } from 'react-router-dom';

import { getUrl } from 'route/utils/getUrl';

// Portfolio
export function goToPortfolioPage(navigate: NavigateFunction, userId?: string) {
    const url = userId
        ? getUrl('USER_PORTFOLIO', { id: userId })
        : getUrl('PORTFOLIO');
    navigate(url);
}

export function goToPortfolioItemEditPage(
    navigate: NavigateFunction,
    itemId: string
) {
    const url = getUrl('PORTFOLIO_ITEM_EDIT', {
        itemId,
    });
    navigate(url);
}

export function goToRelatedPortfolioItemViewPage(
    navigate: NavigateFunction,
    itemId: string
) {
    const url = getUrl('PORTFOLIO_ITEM_VIEW', {
        itemId,
    });
    navigate(url);
}

export function goToPortfolioGroupEditPage(
    navigate: NavigateFunction,
    groupId: string
) {
    const url = getUrl('PORTFOLIO_GROUP_EDIT', {
        groupId,
    });
    navigate(url);
}

export function goToPortfolioItemViewPage(
    navigate: NavigateFunction,
    userId: string,
    itemId: string
) {
    const url = getUrl('USER_PORTFOLIO_ITEM_VIEW', {
        id: userId,
        itemId,
    });
    navigate(url);
}

// Development plan
export function goToDevelopmentPlanPage(
    navigate: NavigateFunction,
    userId?: string
) {
    const url = userId
        ? getUrl('USER_DEVELOPMENT_PLAN', { id: userId })
        : getUrl('DEVELOPMENT_PLAN');
    navigate(url);
}

export function goToDevelopmentItemEditPage(
    navigate: NavigateFunction,
    itemId: string
) {
    const url = getUrl('DEVELOPMENT_ITEM_EDIT', {
        itemId,
    });
    navigate(url);
}

export function goToDevelopmentItemViewPage(
    navigate: NavigateFunction,
    userId: string,
    itemId: string
) {
    const url = getUrl('USER_DEVELOPMENT_ITEM_VIEW', {
        id: userId,
        itemId,
    });
    navigate(url);
}

export function goToDevelopmentGroupEditPage(
    navigate: NavigateFunction,
    groupId: string
) {
    const url = getUrl('DEVELOPMENT_GROUP_EDIT', {
        groupId,
    });
    navigate(url);
}
