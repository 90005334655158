import { useMatch } from 'react-router-dom';

import { TRouteName } from 'route/types';
import { ROUTES } from 'route/constants/routes';

export const useRouteMatch = <
    Params extends { [K in keyof Params]?: string } = Record<
        string,
        string | undefined
    >,
>(
    routeName: TRouteName
) => useMatch({ path: ROUTES[routeName] });
