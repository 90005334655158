import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import UPGRADE_IMAGE_VIDEO from 'assets/images/upgrade-image-video.webp';
import { Typography } from 'common/components/Typography';
import LI_DOT from 'assets/images/li-dot.webp';
import { Img } from 'common/components/Img';
import { PageTitle } from 'common/components/PageTitle';

export const UpgradeContent = () => {
    const { t: translate } = useTranslation();

    return (
        <Box>
            <PageTitle mixpanelTitle="Portfolio - Plans page">
                {translate('portfolioUpgradePageTitle')}
            </PageTitle>

            <Grid
                container
                mt={0.5}
                sx={{ flexWrap: 'wrap', alignItems: 'center' }}
            >
                <Grid item mb={2} md={6} sm={12} sx={{ pr: { md: 4 } }}>
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            fontSize: 14,
                        }}
                    >
                        {translate('plans.video.moduleTitle')}
                    </Typography>

                    <Typography sx={{ mb: 2 }} variant="h1">
                        {translate('plans.video.title')}
                    </Typography>

                    <Typography lineHeight="1.6" sx={{ mb: 2 }}>
                        {translate('plans.video.text')}
                    </Typography>

                    <Typography fontWeight={700}>
                        {translate('plans.video.advantagesText')}
                    </Typography>

                    <Box mt={1}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundImage: `url(${LI_DOT})`,
                                    backgroundSize: '20px',
                                    backgroundRepeat: 'no-repeat',
                                    height: 20,
                                    width: 20,
                                    mr: 2,
                                    flexShrink: 0,
                                }}
                            />

                            <Typography lineHeight="1.6">
                                {translate('plans.video.advantages.advantage1')}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundImage: `url(${LI_DOT})`,
                                    backgroundSize: '20px',
                                    backgroundRepeat: 'no-repeat',
                                    height: 20,
                                    width: 20,
                                    mr: 2,
                                    flexShrink: 0,
                                }}
                            />

                            <Typography lineHeight="1.6">
                                {translate('plans.video.advantages.advantage2')}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundImage: `url(${LI_DOT})`,
                                    backgroundSize: '20px',
                                    backgroundRepeat: 'no-repeat',
                                    height: 20,
                                    width: 20,
                                    mr: 2,
                                    flexShrink: 0,
                                }}
                            />

                            <Typography lineHeight="1.6">
                                {translate('plans.video.advantages.advantage3')}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundImage: `url(${LI_DOT})`,
                                    backgroundSize: '20px',
                                    backgroundRepeat: 'no-repeat',
                                    height: 20,
                                    width: 20,
                                    mr: 2,
                                    flexShrink: 0,
                                }}
                            />

                            <Typography lineHeight="1.6">
                                {translate('plans.video.advantages.advantage4')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={6} sm={12}>
                    <Img src={UPGRADE_IMAGE_VIDEO} />
                </Grid>
            </Grid>
        </Box>
    );
};
