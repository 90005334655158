import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCurrentUserQuery, useExportCourseMutation } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Select } from 'common/components/FormField';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';

type TCourseExportFormValues = {
    version: number | 'concept';
};

interface IProps {
    courseId: string;
    currentCourseVersion?: number;
    open: boolean;
    onClose(): void;
}

export const CourseExportContentDialog = ({
    courseId,
    currentCourseVersion,
    open,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();

    const [exportCourse, { loading: loadingExport }] = useExportCourseMutation({
        onError: showApolloError,
    });

    const { data: currentUserData } = useCurrentUserQuery();
    const currentUser = currentUserData?.currentUser;

    const initialValues: TCourseExportFormValues = {
        version: currentCourseVersion || 'concept',
    };

    const handleExport = async (values: TCourseExportFormValues) => {
        await exportCourse({
            variables: {
                id: courseId,
                version: values.version === 'concept' ? null : values.version,
            },
        });

        displaySnackbar(translate('courseExportContentDialog.exportSuccess'), {
            variant: 'success',
        });

        onClose?.();
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
                await handleExport(values);

                actions.resetForm();
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <ConfirmDialog
                    fullWidth
                    confirmText={translate('export')}
                    loading={isSubmitting || loadingExport}
                    maxWidth="sm"
                    open={open}
                    title={translate('courseExportContentDialog.title')}
                    onCancel={onClose}
                    onClose={onClose}
                    onConfirm={submitForm}
                >
                    <Typography sx={{ mb: 1 }}>
                        {translate('courseExportContentDialog.text', {
                            email: currentUser?.email,
                        })}
                    </Typography>

                    <Form>
                        <Field
                            component={Select}
                            label={translate(
                                'courseExportContentDialog.version'
                            )}
                            name="version"
                            options={[
                                ...Array.from(
                                    { length: currentCourseVersion || 0 },
                                    (_, i) => ({
                                        label: `${translate('version', { version: i + 1 })}`,
                                        value: i + 1,
                                    })
                                ),
                                {
                                    label: translate('concept'),
                                    value: 'concept',
                                },
                            ]}
                        />
                    </Form>
                </ConfirmDialog>
            )}
        </Formik>
    );
};
