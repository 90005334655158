import { Box } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Field } from 'formik';

import { IModuleWeight } from 'graphql/types';
import { FormSection } from 'common/components/FormSection';
import { Select } from 'common/components/FormField';
import { Link } from 'common/components/Link';

export const ModuleWeightFormSection = () => {
    const [translate] = useTranslation();

    const weightNumbersOptions = Object.values(IModuleWeight).map(
        (weight, i) => ({
            value: weight,
            label: `${i}x`,
        })
    );

    return (
        <FormSection
            description={
                <Trans
                    components={{
                        a: <Link rel="noreferrer" underline="always" />,
                    }}
                    i18nKey="moduleForm.weightDescription"
                />
            }
            title={translate('weightOfScore')}
        >
            <Box sx={{ mt: 3, maxWidth: '150px' }}>
                <Field
                    component={Select}
                    label={translate('value')}
                    margin="none"
                    name="weight"
                    options={weightNumbersOptions}
                />
            </Box>
        </FormSection>
    );
};
