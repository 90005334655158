import { useState } from 'react';
import { mdiMapMarker, mdiClose, mdiOpenInNew } from '@mdi/js';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { IMeetingModuleListItemFragment } from 'graphql/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import { ActionButton } from 'common/components/ActionButton';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { TTrainingModuleProps } from 'training/types';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { Icon } from 'common/components/Icon';
import { Map } from 'common/components/Map';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { Link } from 'common/components/Link';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const MeetingModule = ({
    id,
    trainingId,
    title,
    description,
    disabled,
    location,
    conditions,
    alwaysOpen,
    startDate,
    image,
    conditionEffect,
    groupConditionEffect,
    canUpdate,
}: TTrainingModuleProps<IMeetingModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    const [active, setActive] = useState(false);
    const address = location?.location;

    const toggleActive = () => {
        if (disabled) return;

        setActive(!active);
    };

    const titleDisplay = title || location?.title;
    const descriptionDisplay = description || location?.description;

    const googleMapLink =
        !!address &&
        `https://www.google.com/maps?q=${encodeURIComponent(address)}`;

    const itemContent = (
        <>
            <ListItemMedia color="primary" image={image?.url || undefined}>
                <Icon path={mdiMapMarker} size="2.2rem" sizeSm="3.4rem" />
            </ListItemMedia>
            <ListItemText primary={titleDisplay} secondary={descriptionDisplay}>
                <ModuleConditionsChips
                    alwaysOpen={alwaysOpen}
                    conditionEffect={conditionEffect}
                    conditions={conditions}
                    groupConditionEffect={groupConditionEffect}
                    startDate={startDate}
                />
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    if (!studentView && canUpdate) {
        return (
            <ListItem
                button
                component={Link}
                params={{
                    trainingId,
                    moduleId: id,
                    type: 'meeting',
                }}
                to="TRAINING_UPDATE_MODULE"
            >
                {itemContent}

                <ModuleSecondaryAction forUpdate />
            </ListItem>
        );
    }

    return (
        <>
            {active && !!address ? (
                <Box my={2} position="relative">
                    <Map address={address} />
                    <Box sx={{ pt: { xs: 3, sm: 4 }, px: { xs: 2, sm: 3 } }}>
                        <Typography variant="h4">
                            {location?.location}
                        </Typography>
                        <Text>{description}</Text>
                    </Box>

                    <Box p={2} position="absolute" right={0} top={0}>
                        <Box component="span" mr={1}>
                            <ActionButton
                                href={googleMapLink}
                                size="medium"
                                target="_blank"
                            >
                                <Icon path={mdiOpenInNew} size="2rem" />
                            </ActionButton>
                        </Box>
                        <ActionButton size="medium" onClick={toggleActive}>
                            <Icon path={mdiClose} />
                        </ActionButton>
                    </Box>
                </Box>
            ) : (
                <ListItem
                    button={!!address}
                    onClick={!!address ? toggleActive : undefined}
                >
                    {itemContent}

                    {!!address && (
                        <ModuleSecondaryAction actionText={translate('open')} />
                    )}
                </ListItem>
            )}
        </>
    );
};
