import { ITrainingListItemFragment } from 'graphql/types';

export function isTrainingSelected(
    selectedTrainings: ITrainingListItemFragment[],
    training: ITrainingListItemFragment
) {
    return selectedTrainings.some(
        (selectedTraining) => selectedTraining.id === training.id
    );
}

export function isTrainingAdded(
    addedTrainings: ITrainingListItemFragment[],
    training: ITrainingListItemFragment
) {
    return addedTrainings.some(
        (addedTrainings) => addedTrainings.id === training.id
    );
}

export function getTrainingSelectStatus(
    selectedTrainings: ITrainingListItemFragment[],
    addedTrainings: ITrainingListItemFragment[],
    training: ITrainingListItemFragment
) {
    const isSelected = isTrainingSelected(selectedTrainings, training);
    const isAdded = isTrainingAdded(addedTrainings, training);

    return { isSelected, isAdded };
}
