import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 62.5%;
    }

    html, body {
        min-height: 100vh;
    }

    #root {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    :focus-visible {
        outline: none;
    }

    /* Hide react-medium-image-zoom buttons from overlay */
    [data-rmiz-btn-zoom],
    [data-rmiz-btn-unzoom] {
        opacity: 0
    }
`;
