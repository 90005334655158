import { FormControlLabel, Radio } from '@mui/material';

import {
    EFilterType,
    IFilterFormItem,
    TDateFilterValue,
    TObjectFilterValue,
    TStringFilterValue,
} from 'common/types';
import { ExternalContentSelectField } from 'external/components/ExternalContentSelectField';
import { OfferEventSelectField } from 'offer/components/OfferEventSelectField';
import { CourseSelectField } from 'training/components/CourseSelectField';
import { ScormSelectField } from 'training/components/ScormSelectField';
import { TrainingSelectField } from 'training/components/TrainingSelectField';
import { GroupSelectField } from 'user/components/GroupSelectField';
import { translate } from 'utils/i18n';
import { FilterDateRangeSection } from 'common/components/FilterDateRangeSection';
import { DatePicker, RadioGroup } from 'common/components/FormField';

import { parseDateTimeFilterValue } from './filter';

export function userGroupsFilter(
    transKey = 'drawerFilters.userGroups',
    fieldName?: string
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'userGroups',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'userGroupIds',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: GroupSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                parseQueryValue: (value) => value.map((group) => group.id),
            },
        ],
    };
}

export function trainingsFilter(
    transKey = 'drawerFilters.trainings',
    fieldName?: string,
    componentProps?: {
        ids?: React.ComponentProps<typeof TrainingSelectField>['ids'];
        isFilter?: boolean;
    }
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'trainings',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'trainingIds',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: TrainingSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                componentProps,
                parseQueryValue: (value) =>
                    value.map((training) => training.id),
            },
        ],
    };
}

export function offerEventsFilter(
    transKey = 'drawerFilters.offerEvents',
    fieldName?: string,
    componentProps?: {
        ids?: React.ComponentProps<typeof OfferEventSelectField>['ids'];
        isFilter?: boolean;
    }
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'offerEvents',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'offerEventIds',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: OfferEventSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                componentProps,
                parseQueryValue: (value) =>
                    value.map((offerEvent) => offerEvent.id),
            },
        ],
    };
}

export function coursesFilter(
    transKey = 'drawerFilters.courses',
    fieldName?: string,
    componentProps?: {
        ids?: React.ComponentProps<typeof CourseSelectField>['ids'];
        isFilter?: boolean;
    }
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'courses',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'courseIds',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: CourseSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                componentProps,
                parseQueryValue: (value) => value.map((course) => course.id),
            },
        ],
    };
}

export function scormsFilter(
    transKey = 'drawerFilters.scorms',
    fieldName?: string,
    componentProps?: {
        ids?: React.ComponentProps<typeof ScormSelectField>['ids'];
        isFilter?: boolean;
    }
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'scorms',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'scormIds',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: ScormSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                componentProps,
                parseQueryValue: (value) => value.map((scorm) => scorm.id),
            },
        ],
    };
}

export function externalContentsFilter(
    transKey = 'drawerFilters.externalContents',
    fieldName?: string,
    componentProps?: {
        ids?: React.ComponentProps<typeof ExternalContentSelectField>['ids'];
        isFilter?: boolean;
    }
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'externalContents',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'externalContentIds',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: ExternalContentSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                componentProps,
                parseQueryValue: (value) =>
                    value.map((externalContent) => externalContent.id),
            },
        ],
    };
}

export function dateRangeFilter(
    transKey = 'drawerFilters.dateRange',
    fieldName?: string
): IFilterFormItem<TDateFilterValue> {
    return {
        key: `${fieldName || 'date'}Range`,
        label: translate(`${transKey}.sectionTitle`),
        component: FilterDateRangeSection,
        fields: [
            {
                // Combine fieldName with 'From' to create the name
                name: `${fieldName || 'date'}From`,
                type: EFilterType.Date,
                label: translate(`${transKey}.labelFrom`),
                initialValue: null,
                component: DatePicker,
                componentProps: {
                    slotProps: {
                        field: { clearable: true },
                    },
                },
                chip: {
                    label: translate(`${transKey}.chipFrom`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
            {
                // Combine fieldName with 'To' to create the name
                name: `${fieldName || 'date'}To`,
                type: EFilterType.Date,
                label: translate(`${transKey}.labelTo`),
                initialValue: null,
                component: DatePicker,
                chip: {
                    label: translate(`${transKey}.chipTo`),
                },
                parseFilterValue: parseDateTimeFilterValue,
            },
        ],
    };
}

export function archivedFilter(
    transKey = 'drawerFilters.archived',
    fieldName?: string
): IFilterFormItem<TStringFilterValue> {
    return {
        key: fieldName || 'archived',
        label: translate(`${transKey}.sectionTitle`),
        fields: [
            {
                name: fieldName || 'archived',
                type: EFilterType.Radio,
                component: RadioGroup,
                initialValue: 'false',
                componentProps: {
                    children: [
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="true"
                            label={translate(
                                'drawerFilters.archived.trueOption'
                            )}
                            value="true"
                        />,
                        <FormControlLabel
                            control={<Radio color="primary" />}
                            key="false"
                            label={translate(
                                'drawerFilters.archived.falseOption'
                            )}
                            value="false"
                        />,
                    ],
                },
                chip: {
                    label: translate(`${transKey}.chip`),
                    parseValue: (value) => {
                        switch (value) {
                            case 'true':
                                return translate(
                                    'drawerFilters.archived.trueValue'
                                );
                            default:
                                return translate(
                                    'drawerFilters.archived.falseValue'
                                );
                        }
                    },
                },
                parseQueryValue: (value) => {
                    if (value === 'false') return;

                    return value === 'true';
                },
            },
        ],
    };
}
