import { Box, Container } from '@mui/material';

interface IProps {
    children: React.ReactNode;
}

export const ContextActionBar = ({ children }: IProps) => (
    <>
        <Box
            position="fixed"
            sx={{
                top: (theme) => theme.mixins.toolbar.height,
                left: 0,
                right: 0,
                zIndex: (theme) => theme.zIndex.appBar - 1,
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'common.white',
                minHeight: (theme) => theme.mixins.contextActionBar.height,
            }}
        >
            <Container
                maxWidth="md"
                sx={{
                    py: 1,
                    overflowX: 'auto',
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        minWidth: 'max-content',
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
            </Container>
        </Box>
        {/* Spacer to account for the context action bar height */}
        <Box sx={{ height: (theme) => theme.mixins.contextActionBar.height }} />
    </>
);
