import { styled } from 'styled-components';
import { motion } from 'framer-motion';

export const ListItemActionText = styled(motion.div).attrs(() => ({
    transition: {
        type: 'easeOut',
    },
    variants: {
        initListItemButton: { opacity: 0 },
        hoverListItemButton: {
            opacity: 1,
            transition: { duration: 0.3, type: 'easeIn' },
        },
    },
}))`
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    display: none;

    :not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing(1)};
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
    }
`;
