import { RenderElementProps, useReadOnly } from 'slate-react';
import { HtmlAttributesProps } from '@udecode/slate-plugins';
import { useTranslation } from 'react-i18next';
import { mdiDownload, mdiChevronRight } from '@mdi/js';
import { Box } from '@mui/material';

import { List } from 'common/components/List';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Icon } from 'common/components/Icon';

interface IProps extends RenderElementProps, HtmlAttributesProps {
    className?: string;
}

export const EditorFile = ({
    element,
    children,
    attributes,
    htmlAttributes,
    className,
}: IProps) => {
    const { t: translate } = useTranslation();
    const readOnly = useReadOnly();

    const { url } = element;

    const itemProps = readOnly
        ? {
              button: true,
              component: 'a' as React.ElementType,
              href: url,
              target: '_blank',
          }
        : {
              component: 'div' as React.ElementType,
          };

    return (
        <div className={className} {...attributes} data-slate-url={url}>
            <Box mb={2}>
                <List {...htmlAttributes}>
                    <ListItem {...itemProps}>
                        <ListItemMedia color="primary" contentEditable={false}>
                            <Icon path={mdiDownload} />
                        </ListItemMedia>
                        <ListItemText>{children}</ListItemText>
                        {readOnly && (
                            <ListItemActionText>
                                {translate('download')}
                            </ListItemActionText>
                        )}
                        <ListItemSecondaryAction contentEditable={false}>
                            <Icon path={mdiChevronRight} size="4rem" />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Box>
        </div>
    );
};
