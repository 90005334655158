import { createContext } from 'react';
import { UploadOptions } from 'tus-js-client';

export enum IFileUploadStatus {
    UPLOADING = 'uploading',
    SUCCESS = 'success',
    PAUSED = 'paused',
    ERROR = 'error',
}
export interface IUploadFile {
    file: File;
    referenceId: string;
    progress: number;
    status: IFileUploadStatus;
    start: () => void;
    pause: () => void;
    abort: () => void;
    remove: () => void;
}

export interface IUploadOptions
    extends Omit<UploadOptions, 'onProgress' | 'onError' | 'onSuccess'> {
    onStartUpload?: () => void;
    onProgress?: (progress: number) => void;
    onError?: (error: string) => void;
    onAbort?: () => void;
    onSuccess?: () => void;
}

export interface IFileUploadContextValue {
    uploads?: IUploadFile[];
    uploadFile: (
        file: File,
        referenceId: string,
        options: IUploadOptions
    ) => void;
}

export const DEFAULT_FILE_UPLOAD_CONTEXT = {
    uploads: [],
    uploadFile: () => {},
};

export const FileUploadContext = createContext<IFileUploadContextValue>(
    DEFAULT_FILE_UPLOAD_CONTEXT
);
