import { Box, SxProps } from '@mui/material';

import { FilePicker } from 'common/components/FilePicker';
import { IBasicFileFragment, IFileFragment } from 'graphql/types';
import { OverlayCircle } from 'common/components/OverlayCircle';
import { Icon } from 'common/components/Icon';
import { useUploadFiles } from 'common/hooks/useUploadFiles';
import { Link } from 'common/components/Link';

const baseUploaderStyle: SxProps = {
    backgroundColor: 'grey.100',
    borderRadius: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '150px',
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: '300ms border-color',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    p: 2,

    '&:hover': {
        borderColor: 'divider',
    },

    '&.noBackground': {
        backgroundColor: 'unset',
    },
};

const filePickerStyle: SxProps = {
    width: '100%',
    height: 60,
};

const errorMessageStyle: SxProps = {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    fontSize: 16,
    color: 'error.main',
    position: 'absolute',
    top: '75%',
};

interface IProps {
    acceptedFileTypes?: string;
    iconPath?: string;
    file?: IBasicFileFragment;
    id: string;
    onUpload?(file: IFileFragment): void;
}

export const BaseUploader = ({
    acceptedFileTypes,
    file,
    iconPath,
    id,
    onUpload,
}: IProps) => {
    const { isUploading, uploadFiles, uploadingFiles } = useUploadFiles({
        onSuccess: (_fileIndex, updatedFile) => {
            if (!updatedFile) return;

            onUpload && onUpload(updatedFile);
        },
    });

    const uploadingFile = uploadingFiles.length ? uploadingFiles[0] : undefined;

    const {
        done: fileDone,
        success: fileSuccess,
        progress: fileProgress,
        errorMessage: fileErrorMessage,
    } = uploadingFile?.status || {};

    const progressValue =
        !!(uploadingFile && !fileDone && fileProgress && fileProgress >= 0) &&
        `${fileProgress}%`;

    const fileUploaded = uploadingFile && fileDone;

    const icon = !!iconPath && <Icon path={iconPath} size="3rem" />;

    const errorMessage = fileUploaded && !fileSuccess && !!fileErrorMessage && (
        <Box sx={errorMessageStyle}>{fileErrorMessage}</Box>
    );

    const handleUpload = (files: File[]) => {
        uploadFiles(files, 1000);
    };

    return (
        <Box sx={baseUploaderStyle}>
            <FilePicker
                className="filePicker"
                id={id}
                inputProps={{ accept: acceptedFileTypes }}
                sx={filePickerStyle}
                onUpload={!isUploading ? handleUpload : undefined}
            >
                <OverlayCircle>
                    {progressValue ? progressValue : icon}
                </OverlayCircle>
            </FilePicker>

            {!!file?.url && (
                <Link
                    href={file.url}
                    sx={{ fontSize: 14, wordBreak: 'break-all', mt: 2 }}
                    target="_blank"
                >
                    {file.name}
                </Link>
            )}

            {errorMessage}
        </Box>
    );
};
