import { Box, FormHelperText } from '@mui/material';
import { styled } from 'styled-components';
import { FieldProps } from 'formik';
import { mdiClose } from '@mdi/js';

import { Typography } from 'common/components/Typography';
import { IFileFragment } from 'graphql/types';
import { FieldLabel } from 'common/components/FieldLabel';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';

import { ImageUploader } from '../ImageUploader/ImageUploader';
import { ImageUploaderWithDefaults } from '../ImageUploader/ImageUploaderWithDefaults';

interface IProps extends FieldProps {
    className?: string;
    helperText?: string;
    label?: string;
    withDefaults?: boolean;
    maxUploadSize: number;
    acceptedFileTypes?: string;
    onChange?(name: string, file: IFileFragment | null): void;
    onLoaded?(): void;
}

const BaseImageField = ({
    helperText,
    label,
    withDefaults,
    className,
    field,
    onChange,
    onLoaded,
    form: { setFieldValue },
    ...other
}: IProps) => {
    const { value, name } = field;

    const handleChange = (newValue: IFileFragment | null) => {
        setFieldValue(name, newValue);

        onChange && onChange(name, newValue);
    };

    return (
        <Box className={className}>
            <Box height="150px" position="relative">
                {!withDefaults && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            zIndex: 1,
                        }}
                    >
                        <ActionButton
                            color="whiteTransparent"
                            size="medium"
                            onClick={() => handleChange(null)}
                        >
                            <Icon path={mdiClose} size="2.4rem" />
                        </ActionButton>
                    </Box>
                )}

                {!!label && (
                    <FieldLabel>
                        <Typography>{label}</Typography>
                    </FieldLabel>
                )}

                {withDefaults ? (
                    <ImageUploaderWithDefaults
                        id={name}
                        image={value}
                        onChange={handleChange}
                        onLoaded={onLoaded}
                        {...other}
                    />
                ) : (
                    <ImageUploader
                        id={name}
                        image={value}
                        onUpload={handleChange}
                        {...other}
                    />
                )}
            </Box>
            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};

export const ImageField = styled(BaseImageField)`
    .MuiFormHelperText-root {
        font-weight: ${({ theme }) => theme.typography.fontWeightBold};
        color: ${({ theme }) => theme.palette.grey[500]};
        margin-top: ${({ theme }) => theme.spacing(1)};
    }
`;
