import {
    RenderElementProps,
    ReactEditor,
    useSlate,
    useReadOnly,
} from 'slate-react';
import { HtmlAttributesProps } from '@udecode/slate-plugins';
import { Box } from '@mui/material';

import { TComponents, COMPONENTS } from '../options';

interface IProps extends RenderElementProps, HtmlAttributesProps {
    as?: TComponents;
    className?: string;
    styles?: { [key: string]: string };
}

export const EditorComponent = ({
    as = 'div',
    children,
    attributes,
    element,
    className,
    htmlAttributes,
    styles,
    ...other
}: IProps) => {
    const Component = COMPONENTS[as] || as;

    const editor = useSlate();
    const readOnly = useReadOnly();
    const path = ReactEditor.findPath(editor, element);
    const lastIndex = editor.children.length - 1;
    const isLastNode = path[0] === lastIndex;
    const isRootNode = path.length === 1;

    // Do not render the trailing p node
    if (
        isLastNode &&
        readOnly &&
        element.type === 'p' &&
        element.children.length === 1 &&
        element.children[0].text === ''
    ) {
        return null;
    }

    const editorComponent = (
        <Component
            className={!isRootNode ? className : ''}
            {...htmlAttributes}
            {...other}
        >
            {children}
        </Component>
    );

    // Add box wrapper for root level elements, Because attributes contain af ref we cannot apply it directly to the Box
    if (isRootNode) {
        return (
            <div className={className} {...attributes}>
                <Box mb={2}>{editorComponent}</Box>
            </div>
        );
    }

    return editorComponent;
};
