import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { mdiPlus } from '@mdi/js';

import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { GroupsManagementTable } from 'user/components/GroupsManagementTable';
import { GroupCreateDrawer } from 'user/components/GroupCreateDrawer';
import { getUrl } from 'route/utils/getUrl';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { useFrontendPermissions } from 'user/hooks';
import { BackgroundImg } from 'common/components/Img';
import MANAGEMENT_HEADER from 'assets/images/groups-management-header.webp';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { useCurrentUserQuery } from 'graphql/types';

import { BasePage } from './BasePage';
import { FourOFourPage } from './FourOFourPage';

export const GroupsManagementPage = () => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const [loadingGroups, setLoadingGroups] = useState(false);
    const {
        canCreate: canCreateGroup,
        canUpdate: canUpdateGroup,
        loading: loadingPermissions,
    } = useFrontendPermissions('group');
    const { data: currentUserData, loading: currentUserLoading } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};

    const createGroupDrawerOpen = !!useRouteMatch('GROUP_CREATE');

    if (
        (!loadingPermissions &&
            !canUpdateGroup &&
            !currentUserLoading &&
            !currentUser?.isGroupManager) ||
        (createGroupDrawerOpen && !canCreateGroup)
    ) {
        return <FourOFourPage />;
    }

    const header = (
        <BackgroundImg
            src={MANAGEMENT_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            <PageTitle mixpanelTitle="Groups management page">
                {translate('groupsManagementPage.browserTitle')}
            </PageTitle>

            <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Typography variant="h1">
                            {translate('groupsManagementPage.pageTitle')}
                        </Typography>
                    </Grid>

                    {canCreateGroup && (
                        <Grid item xs="auto">
                            <ActionButton
                                outlined
                                disabled={loadingGroups}
                                variant="extended"
                                onClick={() => navigate(getUrl('GROUP_CREATE'))}
                            >
                                <Box>
                                    <Icon path={mdiPlus} size="2.4rem" />
                                </Box>

                                {translate('groupsManagementPage.newGroup')}
                            </ActionButton>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box py={4}>
                <GroupsManagementTable
                    onLoading={(loading) => setLoadingGroups(loading)}
                />
            </Box>

            {canCreateGroup && (
                <GroupCreateDrawer
                    open={createGroupDrawerOpen && !loadingGroups}
                />
            )}
        </BasePage>
    );
};
