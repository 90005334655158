import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';

export type TEnrollmentDeleteDialogSettings = {
    open: boolean;
    selectedAmount: number;
    enrollmentId?: string;
};

interface IProps {
    dialogSettings: TEnrollmentDeleteDialogSettings;
    loading?: boolean;
    onClose?(): void;
    onConfirm?(): void;
}

export const EnrollmentDeleteDialog = ({
    dialogSettings,
    loading,
    onClose,
    onConfirm,
}: IProps) => {
    const [translate] = useTranslation();

    const { open, selectedAmount } = dialogSettings;
    const count = !selectedAmount ? 1 : selectedAmount;

    const title = translate('deleteOfferEventEnrollment.title', {
        count,
    });
    const text = translate('deleteOfferEventEnrollment.text', {
        count,
    });

    return (
        <AlertDialog
            actions={
                <Box display="flex" mt={2}>
                    <Box mr={2}>
                        <Button
                            color="error"
                            loading={loading}
                            variant="contained"
                            onClick={onConfirm}
                        >
                            {translate('delete')}
                        </Button>
                    </Box>

                    <Button
                        disabled={loading}
                        variant="contained"
                        onClick={onClose}
                    >
                        {translate('close')}
                    </Button>
                </Box>
            }
            open={open}
            title={title}
        >
            {text}
        </AlertDialog>
    );
};
