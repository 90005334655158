import { mdiEmail } from '@mdi/js';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { DropdownMenu } from 'common/components/DropdownMenu';
import { IconButton } from 'common/components/IconButton/IconButton';
import { UserEmailDialog } from 'user/components/UserEmailDialog';
import { AcademyInviteEmailDialog } from 'user/components/AcademyInviteEmailDialog';

interface IProps {
    anchorButton?: React.ReactElement;
    disabled?: boolean;
    userIds?: string[];
    onSendEmailComplete?(): void;
}

export const UserEmailDropdownMenu = ({
    anchorButton,
    disabled,
    userIds,
    onSendEmailComplete,
}: IProps) => {
    const [translate] = useTranslation();
    const [userEmailDialogOpen, setUserEmailDialogOpen] = useState(false);
    const [academyInviteEmailDialogOpen, setAcademyInviteEmailDialogOpen] =
        useState(false);

    const anchor = anchorButton || (
        <IconButton
            color="inherit"
            disabled={disabled}
            iconPath={mdiEmail}
            iconSize="2.4rem"
        />
    );

    return (
        <>
            <DropdownMenu anchor={anchor}>
                <MenuItem onClick={() => setAcademyInviteEmailDialogOpen(true)}>
                    {translate('academyInviteEmail.buttonText')}
                </MenuItem>

                <MenuItem onClick={() => setUserEmailDialogOpen(true)}>
                    {translate('userEmail.buttonText')}
                </MenuItem>
            </DropdownMenu>

            <AcademyInviteEmailDialog
                open={academyInviteEmailDialogOpen}
                userIds={userIds}
                onClose={() => setAcademyInviteEmailDialogOpen(false)}
                onComplete={onSendEmailComplete}
            />

            <UserEmailDialog
                open={userEmailDialogOpen}
                userIds={userIds}
                onClose={() => setUserEmailDialogOpen(false)}
                onComplete={onSendEmailComplete}
            />
        </>
    );
};
