import { useState } from 'react';
import { mdiAccountRemove } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { useFrontendPermissions } from 'user/hooks';
import { Icon } from 'common/components/Icon';
import { Button } from 'common/components/Button';
import { TrainingParticipantRemoveUserDialog } from 'training/components/TrainingParticipantRemoveUserDialog';
import { IPortfolioItemFragment, ITrainingRole } from 'graphql/types';
import { isTrainingPortfolioItem } from 'user/utils/portfolioItem';
import { closeGlobalDrawerRoute } from 'common/utils/globalDrawer';

interface IProps {
    portfolioItem: IPortfolioItemFragment;
}

export const TrainingPortfolioItemActions = ({ portfolioItem }: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [translate] = useTranslation();
    const { canUpdate: canUpdateTraining } = useFrontendPermissions('training');
    const [showRemoveUserAlert, setShowRemoveUserAlert] = useState(false);

    const { relatedObject, relatedType, metadata, user } = portfolioItem;

    if (!user || !isTrainingPortfolioItem(relatedObject, relatedType))
        return null;

    const { rolesForCurrentUser } = relatedObject;

    const isAuthor = rolesForCurrentUser?.includes(ITrainingRole.Author);

    // Can send invites and remove users
    const canManageUser = canUpdateTraining || isAuthor;

    // Check if user can see the actions
    if (!canManageUser) return null;

    const { groups, offerEvents, directly } =
        metadata?.addedToTrainingVia || {};

    let addedVia = directly
        ? [<Box key="directly">{translate('addedDirectly')}</Box>]
        : [];

    if (!!groups?.length) {
        addedVia = [
            ...addedVia,
            ...groups.map((group) => <Box key={group.id}>{group.name}</Box>),
        ];
    }

    if (!!offerEvents?.length) {
        addedVia = [
            ...addedVia,
            ...offerEvents
                .filter(({ offer }) => !!offer)
                .map(({ offer }) => <Box key={offer.id}>{offer.title}</Box>),
        ];
    }

    return (
        <>
            <Button
                startIcon={<Icon path={mdiAccountRemove} size="1.9rem" />}
                sx={{ flex: 1 }}
                variant="outlined"
                onClick={() => setShowRemoveUserAlert(true)}
            >
                {translate('remove')}
            </Button>

            <TrainingParticipantRemoveUserDialog
                addedVia={addedVia}
                open={showRemoveUserAlert}
                portfolioItemId={portfolioItem.id}
                trainingId={relatedObject.id}
                userId={user.id}
                onClose={() => setShowRemoveUserAlert(false)}
                onSuccess={() => closeGlobalDrawerRoute(navigate, location)}
            />
        </>
    );
};
