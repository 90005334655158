import { useApolloClient, Reference } from '@apollo/client';

import {
    DevelopmentItemFragmentDoc,
    DevelopmentItemGroupFragmentDoc,
    DevelopmentPlanDocument,
    IDevelopmentItemFragment,
    IDevelopmentItemGroupFragment,
    useCreateDevelopmentItemMutation,
    useDevelopmentPlanQuery,
} from 'graphql/types';

export const useDevelopmentPlan = (
    userId: string,
    onCreateDevelopmentItemSuccess: (developmentItemId: string) => void
) => {
    const client = useApolloClient();

    const getSortedDevelopmentPlan = (
        developmentPlan: IDevelopmentItemGroupFragment[]
    ) =>
        [...developmentPlan].sort(
            (a, b) => Number(b.isInbox) - Number(a.isInbox)
        );

    const {
        data: developmentPlanData,
        loading: developmentPlanLoading,
        error: developmentPlanError,
    } = useDevelopmentPlanQuery({
        variables: {
            userId,
        },
    });

    const [
        createDevelopmentItem,
        { error: createError, loading: createLoading },
    ] = useCreateDevelopmentItemMutation({
        update: (cache, { data: newDevelopmentItemData }) => {
            const newDevelopmentItem =
                newDevelopmentItemData?.createDevelopmentItem.developmentItem;

            if (!newDevelopmentItem) return;

            const developmentItemGroup: IDevelopmentItemGroupFragment | null =
                cache.readFragment({
                    id: `DevelopmentItemGroup:${newDevelopmentItem.group?.id}`,
                    fragment: DevelopmentItemGroupFragmentDoc,
                    fragmentName: 'DevelopmentItemGroup',
                });

            if (!developmentItemGroup) return;

            cache.modify({
                id: cache.identify(developmentItemGroup),
                fields: {
                    developmentItems(
                        existingDevelopmentItemRefs: readonly (
                            | Reference
                            | IDevelopmentItemFragment
                        )[] = [],
                        { readField }
                    ) {
                        const newDevelopmentItemRef = cache.writeFragment({
                            id: `DevelopmentItem:${newDevelopmentItem.id}`,
                            fragment: DevelopmentItemFragmentDoc,
                            data: { ...newDevelopmentItem },
                            fragmentName: 'DevelopmentItem',
                        });

                        // If already present in cache don't add it.
                        if (
                            existingDevelopmentItemRefs.some(
                                (ref) =>
                                    readField('id', ref) ===
                                    newDevelopmentItem.id
                            )
                        ) {
                            return existingDevelopmentItemRefs as Reference[];
                        }

                        return [
                            ...existingDevelopmentItemRefs,
                            newDevelopmentItemRef,
                        ] as Reference[];
                    },
                },
            });

            onCreateDevelopmentItemSuccess(newDevelopmentItem.id);
        },
    });

    // Set groups in cache
    const setGroups = (groups: IDevelopmentItemGroupFragment[]) => {
        client.cache.writeQuery({
            query: DevelopmentPlanDocument,
            variables: {
                userId,
            },
            data: { developmentPlan: groups },
        });
    };

    const { developmentPlan } = developmentPlanData || {};

    const groups = developmentPlan
        ? getSortedDevelopmentPlan(developmentPlan)
        : [];

    return {
        developmentPlanLoading,
        developmentPlanError,
        groups,
        createError,
        createLoading,
        setGroups,
        createDevelopmentItem,
    };
};
