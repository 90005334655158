import { styled } from 'styled-components';
import { CloudDownload } from '@mui/icons-material';
import { rgba } from 'polished';

import BASE_CERTIFICATE from 'assets/images/pluvo-certificate.webp';
import { IReceivedCertificateFragment } from 'graphql/types';
import { Link } from 'common/components/Link';

interface IProps extends IReceivedCertificateFragment {
    className?: string;
}

const StyledIcon = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.common.white};
    background: ${({ theme }) => rgba(theme.palette.common.black, 0.62)};
    border-radius: 50%;
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
`;

const BaseCertificatePreview = ({ className, file, thumbnail }: IProps) => (
    <Link download className={className} href={file?.url || ''} target="_blank">
        <img
            alt={thumbnail?.name || ''}
            src={thumbnail?.url || BASE_CERTIFICATE}
        />

        <StyledIcon>
            <CloudDownload />
        </StyledIcon>
    </Link>
);

export const CertificatePreview = styled(BaseCertificatePreview)`
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border: solid 1px ${({ theme }) => theme.palette.divider};
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme }) => theme.spacing(1.5)};
    position: relative;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
`;
