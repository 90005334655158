import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { isUUID } from 'common/utils/isUUID';

/**
 * The useElementScroll hook can be used to auto scroll to the latest element or to a specific element
 */
export const useElementScroll = (
    hasData: boolean,
    latestElementUrl?: string,
    latestCommentRef?: React.RefObject<HTMLDivElement>,
    parentRef?: React.RefObject<HTMLDivElement>,
    specificElementRef?: HTMLDivElement
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [hitBottom, setHitBottom] = useState(false);
    const [specificElementReached, setSpecificElementReached] = useState(false);

    /*
     * Check if bottom is reached at render
     */
    useEffect(() => {
        const { current } = parentRef || {};

        if (!hasData || !current) return;

        const { scrollHeight, clientHeight } = current;

        const reachedBottom = clientHeight === scrollHeight;

        if (reachedBottom && !hitBottom) setHitBottom(reachedBottom);
    }, [hasData, parentRef, hitBottom]);

    /*
     * Checks if the url contains the hash to show the latest element
     */
    const isLatestElementUrl = useCallback(() => {
        if (!location.hash) return false;

        return (
            location.hash.substring(1) === latestElementUrl?.toLocaleLowerCase()
        );
    }, [location.hash, latestElementUrl]);

    /*
     * When scrolled to bottom set hitBottom state property
     */
    useEffect(() => {
        const { current } = parentRef || {};

        if (!current) return;

        const handleScroll = () => {
            if (!parentRef?.current) return;

            const { scrollHeight, scrollTop, clientHeight } = parentRef.current;

            const reachedBottom = scrollHeight - scrollTop <= clientHeight;

            // If latest element url and bottom was reached but now leaving bottom remove latest element hash
            if (isLatestElementUrl() && hitBottom && !reachedBottom) {
                navigate({
                    pathname: location.pathname,
                    hash: '',
                });
            }

            const specificElementScrolled =
                specificElementRef &&
                Math.ceil(specificElementRef?.getBoundingClientRect().top) ===
                    0;

            if (specificElementScrolled) setSpecificElementReached(true);

            if (specificElementReached && !specificElementScrolled) {
                setSpecificElementReached(false);

                navigate({
                    pathname: location.pathname,
                    hash: '',
                });
            }

            if (reachedBottom === hitBottom) return;

            setHitBottom(reachedBottom);
        };

        current.addEventListener('scroll', handleScroll);

        return () => {
            current.removeEventListener('scroll', handleScroll);
        };
    }, [
        parentRef,
        hitBottom,
        navigate,
        isLatestElementUrl,
        location.pathname,
        specificElementRef,
        specificElementReached,
    ]);

    const scrollToLatestElement = useCallback(() => {
        const { current } = latestCommentRef || {};

        if (!current) return;

        // Timeout based on expanding and collapsing element height transition
        setTimeout(() => {
            current.scrollIntoView({ behavior: 'smooth' });
        }, 250);
    }, [latestCommentRef]);

    /*
     * Scroll down to the latest element when certain hashtag in url is present
     */
    useEffect(() => {
        if (!hasData) return;

        if (isLatestElementUrl()) scrollToLatestElement();
    }, [parentRef, hasData, isLatestElementUrl, scrollToLatestElement]);

    /*
     * Scroll the specific element into view
     */
    useEffect(() => {
        if (!specificElementRef) return;

        specificElementRef.scrollIntoView({ behavior: 'smooth' });
    }, [specificElementRef]);

    /*
     * Checks if the url contains the hash to show a specific element
     */
    const getSpecificElementId = useCallback(() => {
        const { hash } = location;

        if (!hash) return undefined;

        const hashValue = hash.substring(1);

        if (!isUUID(hashValue)) return undefined;

        return hashValue;
    }, [location]);

    return {
        hitBottom,
        getSpecificElementId,
        isLatestElementUrl: isLatestElementUrl(),
        scrollToLatestElement,
        specificElementReached,
    };
};
