import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

const SimpleAccordionSummaryStyle: SxProps = {
    padding: 0,

    '.MuiAccordionSummary-content': {
        my: 1,
        alignItems: 'center',
    },

    '.MuiAccordionSummary-expandIconWrapper': {
        '&.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
};

export const SimpleAccordionSummary = ({
    sx,
    ...other
}: AccordionSummaryProps) => (
    <MuiAccordionSummary
        sx={[SimpleAccordionSummaryStyle, ...withSxProp(sx)]}
        {...other}
    />
);
