import { Field, Form, Formik, FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';

import { DatePicker, TextField } from 'common/components/FormField';
import { Button } from 'common/components/Button';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { TPortfolioItemFormValues } from 'user/types';
import { IScoringSystemFragment } from 'graphql/types';
import {
    FIELDS_VALIDATION as SCORES_VALIDATION,
    ScoringSystemFields,
} from 'common/components/FormField/ScoringSystemFields/ScoringSystemFields';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';

interface IProps extends FormikConfig<TPortfolioItemFormValues> {
    className?: string;
    disabled?: boolean;
    isDeleting?: boolean;
    scoringSystems?: IScoringSystemFragment[];
    onDelete?(): void;
}

export const CustomPortfolioItemForm = ({
    className,
    disabled,
    isDeleting,
    scoringSystems,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        startDate: Yup.string().optional().nullable(),
        endDate: Yup.string().optional().nullable(),
        teacher: Yup.string().optional(),
        description: Yup.string().optional(),
        scores: SCORES_VALIDATION,
    });

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, values, dirty }) => (
                <Box p={4}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting && !isDeleting}
                        onConfirm={submitForm}
                    />

                    <Form className={className}>
                        <Field
                            component={TextField}
                            label={translate('title')}
                            name="title"
                        />

                        <Grid container spacing={{ xs: 0, sm: 2 }}>
                            <Grid item sm={6} xs={12}>
                                <Field
                                    component={DatePicker}
                                    label={translate('portfolioStartDate')}
                                    maxDate={values.endDate || undefined}
                                    name="startDate"
                                    value={values.startDate}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <Field
                                    component={DatePicker}
                                    label={translate('portfolioEndDate')}
                                    minDate={values.startDate || undefined}
                                    name="endDate"
                                    value={values.endDate}
                                />
                            </Grid>
                        </Grid>

                        <Field
                            component={TextField}
                            label={translate('trainerField')}
                            name="teacher"
                        />
                        <Box>
                            <Field
                                multiline
                                component={TextField}
                                label={translate('description')}
                                name="description"
                            />
                        </Box>

                        <ScoringSystemFields scoringSystems={scoringSystems} />

                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!dirty && !isSubmitting && !disabled)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>

                            {!!onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
