import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Backdrop, Box } from '@mui/material';

import { useUploadFiles } from 'common/hooks/useUploadFiles';
import { FilePicker, IFilePickerProps } from 'common/components/FilePicker';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { LinearProgress } from 'common/components/LinearProgress';

interface IProps extends IFilePickerProps {
    children: React.ReactNode;
    maxSize: number;
    onSuccess?(file: string): void;
    onError?(fileName: string, message: string): void;
    onProgress?(progress: number): void;
}

export const FileUpload = ({
    maxSize,
    onSuccess,
    onError,
    onProgress,
    ...other
}: IProps) => {
    const [uploadState, setUploadState] = useState<{
        isUploading: boolean;
        progress: number;
    }>({ isUploading: false, progress: 0 });
    const [displaySnackbar] = useSnackbar();
    const { uploadFiles } = useUploadFiles({
        publicFile: true,
        onProgress: (_fileIndex, progressValue) => {
            onProgress && onProgress(progressValue);

            setUploadState({
                isUploading: true,
                progress: progressValue || uploadState.progress,
            });
        },
        onSuccess: (_fileIndex, updatedFile) => {
            const newUrl = updatedFile?.url;

            if (!newUrl) return;

            onSuccess && onSuccess(newUrl);

            setUploadState({ isUploading: false, progress: 100 });
        },
        onError: (fileName: string, message: string) => {
            setUploadState({ isUploading: false, progress: 0 });

            displaySnackbar(`${fileName}: ${message}`, {
                autoHideDuration: 30000,
            });

            onError && onError(fileName, message);
        },
    });

    // Move to seperate function to also allow replacing from the blockMenu.
    const handleUpload = (files: File[]) => {
        uploadFiles(files, maxSize);
    };

    useEffect(() => {
        if (uploadState.isUploading) return;

        // Set progress to 0 after the backdrop closing animation has been completed
        setTimeout(() => {
            setUploadState({ isUploading: false, progress: 0 });
        }, 225);
    }, [uploadState.isUploading]);

    return (
        <>
            <FilePicker onUpload={handleUpload} {...other} />

            {ReactDOM.createPortal(
                <Backdrop
                    open={uploadState.isUploading}
                    style={{ zIndex: 9999 }}
                >
                    <Box width={200}>
                        <LinearProgress
                            color="primary"
                            value={uploadState.progress}
                            variant="determinate"
                        />
                    </Box>
                </Backdrop>,
                document.body
            )}
        </>
    );
};
