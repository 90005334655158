import {
    TableCell as MuiTableCell,
    TableCellProps,
    SxProps,
    Box,
    Divider,
    Theme,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

const tableCellDividerStyle: SxProps = {
    p: 0,
    px: 1.5,
    // Set height to 1px to allow 100% height (this is ignored anyway)
    height: '1px',
    textAlign: 'center',

    '> .MuiBox-root': {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
    },
};

interface IProps extends TableCellProps {
    sx?: SxProps<Theme>;
}

export const TableCellDivider = ({ scope, sx, ...other }: IProps) => (
    <MuiTableCell
        {...other}
        sx={[
            tableCellDividerStyle,
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    >
        <Box>
            <Divider orientation="vertical" />
        </Box>
    </MuiTableCell>
);
