import { Box, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { FormSection } from 'common/components/FormSection';
import {
    FAVICON_IMAGE_MAX_SIZE,
    FAVICON_IMAGE_TYPE,
    LOGO_IMAGE_MAX_SIZE,
    LOGO_IMAGE_TYPE,
} from 'common/constants/files';
import { ColorPicker, ImageField } from 'common/components/FormField';
import { Link } from 'common/components/Link';

export const ThemeForm = () => {
    const [translate] = useTranslation();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="academySettingsForm.themeSettingsDescription"
                    />
                </Box>
            }
            title={translate('academySettingsForm.themeSettingsTitle')}
        >
            <Grid container spacing={2} sx={{ my: 2 }}>
                <Grid item sm={6} xs={12}>
                    <Field
                        acceptedFileTypes={LOGO_IMAGE_TYPE}
                        component={ImageField}
                        helperText={translate('imageSizeDescription', {
                            width: 320,
                            height: 120,
                        })}
                        imageSize="contain"
                        label={translate('academyLogo')}
                        maxUploadSize={LOGO_IMAGE_MAX_SIZE}
                        name="logo"
                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Field
                        acceptedFileTypes={FAVICON_IMAGE_TYPE}
                        component={ImageField}
                        helperText={translate('sizeDescription', {
                            width: 256,
                            height: 256,
                        })}
                        imageSize="contain"
                        label={translate('favicon')}
                        maxUploadSize={FAVICON_IMAGE_MAX_SIZE}
                        name="favicon"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: -1 }}>
                <Grid item sm={2.4} xs={4}>
                    <Field
                        fullWidth
                        component={ColorPicker}
                        helperText="primair"
                        label={translate('colors.primary')}
                        name="palette.primary"
                    />
                </Grid>

                <Grid item sm={2.4} xs={4}>
                    <Field
                        fullWidth
                        component={ColorPicker}
                        label={translate('colors.secondary')}
                        name="palette.secondary"
                    />
                </Grid>

                <Grid item sm={2.4} xs={4}>
                    <Field
                        fullWidth
                        component={ColorPicker}
                        label={translate('colors.title')}
                        name="palette.title"
                    />
                </Grid>

                <Grid item sm={2.4} xs={4}>
                    <Field
                        fullWidth
                        component={ColorPicker}
                        label={translate('colors.subtitle')}
                        name="palette.subtitle"
                    />
                </Grid>

                <Grid item sm={2.4} xs={4}>
                    <Field
                        fullWidth
                        component={ColorPicker}
                        label={translate('colors.body')}
                        name="palette.body"
                        popoverPosition="bottom-end"
                    />
                </Grid>
            </Grid>
        </FormSection>
    );
};
