import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
    ICustomFontFragment,
    ICustomMenuItemInput,
    IDefaultFileType,
    IOrganisationFragment,
    IScoringSystemInput,
    IThemeInput,
    IUpdateOrganisationInput,
} from 'graphql/types';
import { useInitialDefaultFile } from 'common/hooks/useInitialDefaultFile';
import {
    HEADER_IMAGE_MAX_SIZE,
    HEADER_IMAGE_TYPE,
} from 'common/constants/files';
import {
    ImageField,
    TextField,
    ColorPicker,
} from 'common/components/FormField';
import { Typography } from 'common/components/Typography';
import { Button } from 'common/components/Button';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { Loader } from 'common/components/Loader';
import { LanguageSelectField } from 'common/components/LanguageSelectField';
import { useApolloError } from 'common/hooks/useApolloError';
import { ContentExpander } from 'common/components/ContentExpander';

import { ThemeForm } from './ThemeForm';
import { FontForm } from './FontForm';
import { LoginPageForm } from './LoginPageForm';
import { IntroductionPanelForm } from './IntroductionPanelForm';
import { CustomMenuForm } from './CustomMenuForm';
import { ScoringSystemForm } from './ScoringSystemForm';
import { FooterForm } from './FooterForm';

export type TOrganisationSettingsFormValues = Omit<
    IUpdateOrganisationInput,
    'participantIntroImageId'
> &
    Omit<IUpdateOrganisationInput, 'faviconId' | 'imageId'> &
    Pick<
        IOrganisationFragment,
        | 'participantIntroImage'
        | 'logo'
        | 'favicon'
        | 'image'
        | 'homeHeaderImage'
        | 'footerImage'
    > &
    IThemeInput &
    Record<'customMenuItems', ICustomMenuItemInput[]> &
    Record<'customFonts', ICustomFontFragment[]> &
    Record<'scoringSystems', IScoringSystemInput[]>;

export interface IProps {
    disabled?: boolean;
    initialValues: TOrganisationSettingsFormValues;
    onSubmit(values: TOrganisationSettingsFormValues): void;
}

export const OrganisationSettingsForm = ({
    disabled,
    initialValues,
    onSubmit,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const {
        initialFile: initialImage,
        loading: loadingInitialImage,
        error: errorInitialImage,
    } = useInitialDefaultFile({
        type: IDefaultFileType.Header,
    });

    useEffect(() => {
        if (!errorInitialImage) return;

        showApolloError(errorInitialImage);
    }, [errorInitialImage, showApolloError]);

    const initValues = {
        participantIntroImageId: initialImage,
        ...initialValues,
    };

    const formTitle = translate('academySettingsForm.formTitle');
    const formText = translate('academySettingsForm.formText');
    const submitText = translate('academySettingsForm.editButton');

    if (loadingInitialImage) return <Loader />;

    const validationSchema = Yup.object().shape({
        customMenuItems: Yup.array().of(
            Yup.object().shape({
                text: Yup.string().required(translate('validate.required')),
                url: Yup.string().required(translate('validate.required')),
            })
        ),
        scoringSystems: Yup.array().of(
            Yup.object().shape({
                nameSingular: Yup.string().required(
                    translate('validate.required')
                ),
            })
        ),
    });

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values: TOrganisationSettingsFormValues) =>
                onSubmit(values)
            }
        >
            {({ submitForm, isSubmitting, dirty }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Form>
                        {formTitle && formText && (
                            <Box mb={2}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box mb={2}>
                            <LanguageSelectField name="language" />
                        </Box>

                        <Field
                            withDefaults
                            acceptedFileTypes={HEADER_IMAGE_TYPE}
                            component={ImageField}
                            helperText={translate('imageSizeDescription', {
                                width: 1440,
                                height: 300,
                            })}
                            label={translate('headerImage')}
                            maxUploadSize={HEADER_IMAGE_MAX_SIZE}
                            name="participantIntroImage"
                        />

                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Field
                                    component={TextField}
                                    label={translate('title')}
                                    name="participantIntroTitle"
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    component={ColorPicker}
                                    name="participantIntroTitleColor"
                                    popoverPosition="bottom-end"
                                    tooltip={translate<string>('titleColor')}
                                />
                            </Grid>
                        </Grid>

                        <Field
                            multiline
                            component={TextField}
                            label={translate('description')}
                            name="participantIntroText"
                        />

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <ThemeForm />

                                <FontForm />

                                <LoginPageForm />

                                <IntroductionPanelForm />

                                <CustomMenuForm />

                                <ScoringSystemForm />

                                <FooterForm />
                            </ContentExpander>
                        </Box>

                        <Box mt={4}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!isSubmitting && !disabled && !dirty)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    return submitForm();
                                }}
                            >
                                {submitText}
                            </Button>
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
