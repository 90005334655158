import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiCancel, mdiCheckCircleOutline } from '@mdi/js';
import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { Typography } from 'common/components/Typography';
import { TextField } from 'common/components/FormField';
import {
    IOfferEventDetailFragment,
    IOfferEventMailType,
    useSendOfferEventEnrollmentBulkMailMutation,
    useUpdateOfferEventMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { Icon } from 'common/components/Icon';
import { Button } from 'common/components/Button';

interface IEmailFormValues {
    title?: string;
    message: string;
}

interface IProps {
    offerEvent: IOfferEventDetailFragment;
}

export const CancelOfferEventButton = ({ offerEvent }: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCancelled, setIsCancelled] = useState(offerEvent.cancelled);

    const [sendBulkEnrollmentMail, { loading: loadingBulkEmail }] =
        useSendOfferEventEnrollmentBulkMailMutation();
    const [updateOfferEvent, { loading: updateOfferEventLoading }] =
        useUpdateOfferEventMutation();

    const transKey = isCancelled
        ? 'offerEventUncancelMail'
        : 'offerEventCancelMail';

    const handleCancelOfferEvent = async (values: IEmailFormValues) => {
        const { title, message } = values;

        let updateResponse;

        try {
            updateResponse = await updateOfferEvent({
                variables: {
                    id: offerEvent.id,
                    offerEvent: {
                        cancelled: !isCancelled,
                        type: offerEvent.type,
                    },
                },
                update: (cache) => {
                    cache.evict({ fieldName: 'offerEvents' });
                    cache.evict({ fieldName: 'offers' });
                    cache.evict({ fieldName: 'paginatedOfferEvents' });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        const updatedOfferEvent =
            updateResponse?.data?.updateOfferEvent?.offerEvent;

        if (!updatedOfferEvent) return;

        displaySnackbar(translate('offerEventForm.offerEventCancelled'), {
            variant: 'success',
        });

        try {
            await sendBulkEnrollmentMail({
                variables: {
                    mailType: IOfferEventMailType.Cancelled,
                    offerEventId: offerEvent.id,
                    title,
                    message,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('offerEventForm.bulkMailSent'), {
            variant: 'success',
        });

        setDialogOpen(false);

        // Update the isCancelled ref to the new value, add a delay to ensure the dialog is closed
        setTimeout(() => {
            setIsCancelled(updatedOfferEvent.cancelled);
        }, 200);

        return true;
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().max(
            100,
            translate('validate.maxCharacters', { count: 100 })
        ),
        message: Yup.string().required(translate('validate.required')),
    });

    const initialValues = {
        title: '',
        message: '',
    };

    return (
        <>
            <Button
                size="small"
                startIcon={
                    <Icon
                        path={isCancelled ? mdiCheckCircleOutline : mdiCancel}
                        size="1.9rem"
                    />
                }
                variant="outlined"
                onClick={() => setDialogOpen(true)}
            >
                {translate(`${transKey}.buttonText`)}
            </Button>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                    await handleCancelOfferEvent(values);

                    actions.resetForm();
                }}
            >
                {({ submitForm, isSubmitting }) => (
                    <ConfirmDialog
                        cancelText={translate(`${transKey}.cancelButtonText`)}
                        confirmText={translate(`${transKey}.confirmButtonText`)}
                        loading={
                            isSubmitting ||
                            updateOfferEventLoading ||
                            loadingBulkEmail
                        }
                        open={dialogOpen}
                        title={translate(`${transKey}.dialog.title`)}
                        onCancel={() => {
                            setDialogOpen(false);
                        }}
                        onClose={() => setDialogOpen(false)}
                        onConfirm={submitForm}
                    >
                        <Typography>
                            {translate(`${transKey}.dialog.text`)}
                        </Typography>

                        <Form>
                            <Stack spacing={2} sx={{ mt: 2 }}>
                                <Field
                                    component={TextField}
                                    label={translate(`${transKey}.titleField`)}
                                    name="title"
                                />

                                <Field
                                    multiline
                                    component={TextField}
                                    label={translate(
                                        `${transKey}.messageField`
                                    )}
                                    name="message"
                                />
                            </Stack>
                        </Form>
                    </ConfirmDialog>
                )}
            </Formik>
        </>
    );
};
