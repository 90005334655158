import { useEffect } from 'react';
import { Grid, Box, Skeleton } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useTranslation, Trans } from 'react-i18next';

import { useApolloError } from 'common/hooks/useApolloError';
import {
    IOpenOffsetType,
    useModuleGroupConditionsLazyQuery,
} from 'graphql/types';
import { Select, TextField } from 'common/components/FormField';
import { FormSection } from 'common/components/FormSection';
import { IModuleFormValues } from 'training/types';
import { Link } from 'common/components/Link';
import { ModuleConditionDescription } from 'training/components/ModuleConditionDescription';
import { Typography } from 'common/components/Typography';

interface IProps {
    disabled?: boolean;
    offsetValues?: IModuleFormValues['offset'];
    moduleGroupId: string;
}

export const ModuleOffsetFormSection = ({
    disabled,
    offsetValues,
    moduleGroupId,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const { setFieldValue } = useFormikContext<IModuleFormValues>();

    const [
        getGroupConditions,
        {
            data: moduleGroupData,
            loading: moduleGroupLoading,
            called: moduleGroupCalled,
        },
    ] = useModuleGroupConditionsLazyQuery({
        onError: showApolloError,
    });

    useEffect(() => {
        if (!moduleGroupId) return;

        getGroupConditions({ variables: { id: moduleGroupId } });
    }, [moduleGroupId, getGroupConditions]);

    const groupConditions = moduleGroupData?.moduleGroup?.conditions || [];

    // Check if module group only has course conditions set, then we can hide the normal offset option from the types,
    // because it will not work for course conditions
    const onlyHasCourseConditions =
        groupConditions.filter(
            (condition) => condition.__typename !== 'CourseClearedCondition'
        ).length === 0;

    const { type: offsetType } = offsetValues || {};
    const loading = moduleGroupLoading || !moduleGroupCalled;

    const handleChangeOffsetValue = (
        fieldName: string,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let value = e.currentTarget.value ? parseInt(e.currentTarget.value) : 0;

        // Make sure the value cannot go below zero
        if (value < 0) value = 0;

        setFieldValue(fieldName, value);
    };

    return (
        <FormSection
            description={
                <Trans
                    components={{
                        a: <Link rel="noreferrer" underline="always" />,
                    }}
                    i18nKey="moduleForm.availabilityDescription"
                />
            }
            title={translate('availability')}
        >
            <Box mt={2}>
                {loading ? (
                    <Skeleton height={75} variant="rectangular" />
                ) : (
                    <>
                        {!groupConditions.length ? (
                            <Typography>
                                {translate(
                                    'moduleForm.moduleGroupHasNoConditions'
                                )}
                            </Typography>
                        ) : (
                            <>
                                <Field
                                    component={Select}
                                    defaultValue=""
                                    disabled={disabled}
                                    label={translate('condition')}
                                    name="offset.type"
                                    options={[
                                        {
                                            value: IOpenOffsetType.Zero,
                                            label: translate(
                                                'moduleForm.condition.zero'
                                            ),
                                        },
                                        {
                                            value: IOpenOffsetType.OffsetNegative,
                                            label: translate(
                                                'moduleForm.condition.offsetNegative'
                                            ),
                                        },
                                        // Onlyrender option when we don't have all only course conditions
                                        !onlyHasCourseConditions && {
                                            value: IOpenOffsetType.Offset,
                                            label: translate(
                                                'moduleForm.condition.offset'
                                            ),
                                        },
                                        {
                                            value: IOpenOffsetType.Unlocked,
                                            label: translate(
                                                'moduleForm.condition.unlocked'
                                            ),
                                        },
                                    ].filter(Boolean)}
                                />

                                {offsetType && (
                                    <>
                                        {/* Only show days and hours fields for offset and offset negative */}
                                        {[
                                            IOpenOffsetType.Offset,
                                            IOpenOffsetType.OffsetNegative,
                                        ].includes(offsetType) && (
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{ mt: 0.5 }}
                                            >
                                                <Grid
                                                    item
                                                    style={{
                                                        minWidth: '150px',
                                                    }}
                                                >
                                                    <Field
                                                        component={TextField}
                                                        disabled={disabled}
                                                        label={translate(
                                                            'days'
                                                        )}
                                                        margin="none"
                                                        name="offset.days"
                                                        type="number"
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            handleChangeOffsetValue(
                                                                'offset.days',
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{
                                                        minWidth: '150px',
                                                    }}
                                                >
                                                    <Field
                                                        component={TextField}
                                                        disabled={disabled}
                                                        label={translate(
                                                            'hour'
                                                        )}
                                                        margin="none"
                                                        min="0"
                                                        name="offset.hours"
                                                        type="number"
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            handleChangeOffsetValue(
                                                                'offset.hours',
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {/* Don't show condition description for type Unlocked. */}
                                        {offsetValues &&
                                            offsetType !==
                                                IOpenOffsetType.Unlocked && (
                                                <Box sx={{ mt: 3 }}>
                                                    <Box sx={{ mt: 3 }}>
                                                        <ModuleConditionDescription
                                                            groupConditions={
                                                                groupConditions
                                                            }
                                                            offset={
                                                                offsetValues
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Box>
        </FormSection>
    );
};
