import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { IUserListItemFragment } from 'graphql/types';
import { UserListItem } from 'user/components/UserListItem/UserListItem';
import { isUserAdded, isUserSelected } from 'user/utils/userSelect';

interface IProps {
    addedTrainers: IUserListItemFragment[];
    disabledTrainerIds?: string[];
    selectedTrainers: IUserListItemFragment[];
    trainers: IUserListItemFragment[];
    onAddTrainer(selectedTrainer: IUserListItemFragment): void;
    onRemoveTrainer(selectedTrainer: IUserListItemFragment): void;
}

export const FormTrainerAddList = ({
    addedTrainers,
    disabledTrainerIds,
    selectedTrainers,
    trainers,
    onAddTrainer,
    onRemoveTrainer,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleSelectTrainer = (
        trainer: IUserListItemFragment,
        isAdded: boolean
    ) => {
        isAdded ? onRemoveTrainer(trainer) : onAddTrainer(trainer);
    };

    return (
        <>
            {!trainers.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>
                        {translate('noOptionsText.trainers')}
                    </Typography>
                </Box>
            ) : (
                trainers.filter(Boolean).map((trainer) => {
                    const isSelected = isUserSelected(
                        selectedTrainers,
                        trainer
                    );
                    const isAdded = isUserAdded(addedTrainers, trainer);
                    const isDisabled = disabledTrainerIds?.length
                        ? disabledTrainerIds.includes(trainer.id)
                        : false;

                    return (
                        <UserListItem
                            addButton={!isDisabled}
                            added={isSelected || isAdded}
                            disabled={isDisabled}
                            key={trainer.id}
                            px={1}
                            selected={isSelected || isAdded}
                            user={trainer}
                            onClick={() => {
                                if (isDisabled) return;

                                handleSelectTrainer(
                                    trainer,
                                    isSelected || isAdded
                                );
                            }}
                        />
                    );
                })
            )}
        </>
    );
};
