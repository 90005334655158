import React, { useState } from 'react';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import Icon from '@mdi/react';
import * as mdiIcons from '@mdi/js';

import {
    MobileNavigationItem,
    IProps as IMobileNavigationItemProps,
} from 'common/components/MainNavigation/MobileNavigation/MobileNavigationItem';
import { IMainMenuItem, IMainMenu, IUnreadBadge } from 'graphql/types';
import { MAIN_MENU } from 'common/constants/mainMenu';
import { isStaticBadge } from 'common/utils/isStaticBadge';

interface IProps {
    className?: string;
    hasIntroduction?: boolean;
    menuItems?: IMainMenuItem[];
}

export const MobileNavigation = ({ hasIntroduction, menuItems }: IProps) => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    if (matches) {
        return null;
    }

    const getMenuItem = (
        menuItem: IMainMenuItem | null,
        index: number,
        hasIntroduction?: boolean,
        level = 0
    ): JSX.Element | null => {
        if (!menuItem) return null;

        const { menu, customText, customUrl, customIcon, subMenus, badges } =
            menuItem;
        const menuKey = menu as keyof typeof MAIN_MENU;

        if (!hasIntroduction && menuKey === 'PROFILE_INTRODUCTION') return null;

        const menuItemData = MAIN_MENU[menuKey] || undefined;

        const isCustomItem = [IMainMenu.Custom, IMainMenu.CustomItem].includes(
            menu
        );

        if (!menuItemData && !isCustomItem) return null;

        const {
            iconSize,
            iconStyle,
            component: MenuComponent,
            isButton,
        } = menuItemData || {};

        // Create let of iconPath to allow it to be overriden
        let { iconPath } = menuItemData || {};

        // When custom icon is set we want to override the iconPath.
        if (customIcon) {
            // Override the type of the icons module to allow us to check for icons
            const icons: { [key: string]: string } = mdiIcons;

            // Convert name with hyphens to camel case
            const customIconName = `mdi-${customIcon}`.replace(
                /-([a-z])/g,
                (g) => g[1].toUpperCase()
            );

            // Check if customIcon exists in the icons module
            iconPath = Object.hasOwn(icons, customIconName)
                ? icons[customIconName]
                : icons.mdiAlertCircle;
        }

        const icon = iconPath ? (
            <Icon
                path={iconPath}
                size={iconSize || '2.4rem'}
                style={{ ...iconStyle }}
            />
        ) : (
            MenuComponent && <MenuComponent />
        );

        const badge =
            !!badges && badges.length && !isStaticBadge(badges[0])
                ? (badges[0] as IUnreadBadge)
                : undefined;

        // href is used for customUrl and external links
        const href = customUrl || menuItemData?.href;
        const target = customUrl ? '_blank' : menuItemData?.target;

        const text = customText ? (
            customText
        ) : (
            <Trans>{menuItemData?.translation}</Trans>
        );

        const listItemProps: IMobileNavigationItemProps = {
            badge,
            href,
            icon,
            target,
            to: menuItemData?.url,
            children: text,
        };

        if (isButton && MenuComponent) {
            return (
                <Box key={`${level} ${index}`}>
                    <MenuComponent />
                </Box>
            );
        }

        // If menu contains doesn't contain submenus we want to render the MobileNavigation
        if (!subMenus || !subMenus.length) {
            return (
                <MobileNavigationItem
                    key={`${level} ${index}`}
                    {...listItemProps}
                />
            );
        }

        // If the item has subitems we always want to disable it
        listItemProps.disabled = true;

        const showDivider = index !== 0 && (level === 0 || level === 1);

        return (
            <React.Fragment key={`${level} ${index}`}>
                {showDivider && (
                    <Box my={1}>
                        <Divider />
                    </Box>
                )}

                {/* If we have subitems but we don't want to show the item for level 0 */}
                {level !== 0 && <MobileNavigationItem {...listItemProps} />}

                {/* Add indentation to subMenus based on level */}
                <Box ml={level - 1 + 1}>
                    {subMenus.map((item, index) =>
                        getMenuItem(item, index, hasIntroduction, level + 1)
                    )}
                </Box>
            </React.Fragment>
        );
    };

    const createMaterialButton = menuItems?.find(
        (item) => item?.menu === IMainMenu.CreateMaterial
    );

    return (
        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
            {!!createMaterialButton && getMenuItem(createMaterialButton, 0)}

            <IconButton
                className="icon"
                size="large"
                onClick={() => setOpen(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <List component="div" style={{ width: '80vw' }}>
                    {menuItems &&
                        menuItems
                            .filter(
                                (item) => item.menu !== IMainMenu.CreateMaterial
                            )
                            .map((item, index) =>
                                getMenuItem(item, index, hasIntroduction)
                            )}
                </List>
            </Drawer>
        </Box>
    );
};
