import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';

import { useOrganisationQuery } from 'graphql/types';
import { TRAINING_MODULE_TYPES } from 'training/constants/modules';
import { ContentExpander } from 'common/components/ContentExpander';
import { ITrainingModuleType } from 'training/types';
import { CreateModuleCardButton } from 'training/components/CreateModuleCardButton';
import { useApolloError } from 'common/hooks/useApolloError';
import { Loader } from 'common/components/Loader';

interface IProps {
    trainingModelId?: string;
    moduleGroupId?: string;
    showCount?: number;
    onClick?(moduleType: ITrainingModuleType): void;
}

export const TrainingCreateModuleSelect = ({
    trainingModelId,
    moduleGroupId,
    showCount = 3,
    onClick,
}: IProps) => {
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const [moreModulesExpanded, setMoreModulesExpanded] = useState(false);

    const { data: organisationData, loading: loadingOrg } =
        useOrganisationQuery({ onError: showApolloError });
    const { organisation } = organisationData || {};
    const hasExternalContent = !!organisation?.hasExternalContent;

    const moduleCardButton = (type: ITrainingModuleType) => (
        <CreateModuleCardButton
            key={type.key}
            moduleGroupId={moduleGroupId}
            trainingModelId={trainingModelId}
            type={type}
            onClick={() => onClick?.(type)}
        />
    );

    // Check if organisation has external content and if not we remove the external content module from the list
    const moduleTypes = TRAINING_MODULE_TYPES.filter((type) => {
        if (type.key === 'externalContent') return hasExternalContent;

        return true;
    });

    const hiddenModules = moduleTypes.slice(showCount);

    if (loadingOrg) return <Loader />;

    return (
        <>
            <Box mb={2}>
                <Grid container spacing={2}>
                    {TRAINING_MODULE_TYPES.slice(0, showCount).map(
                        moduleCardButton
                    )}
                </Grid>
            </Box>
            {!!hiddenModules.length && (
                <ContentExpander
                    expand={moreModulesExpanded}
                    title={translate('moreModules')}
                    onClick={() => setMoreModulesExpanded(!moreModulesExpanded)}
                >
                    <Box mt={5}>
                        <Grid container spacing={2}>
                            {hiddenModules.map(moduleCardButton)}
                        </Grid>
                    </Box>
                </ContentExpander>
            )}
        </>
    );
};
