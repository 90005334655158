import { Box, DialogContentText, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field, FieldArray } from 'formik';
import { mdiClose, mdiPlus } from '@mdi/js';
import { useRef, useState } from 'react';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { TextField } from 'common/components/FormField';
import { IScoringSystemFragment } from 'graphql/types';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import { Typography } from 'common/components/Typography';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';

export const ScoringSystemForm = () => {
    const [translate] = useTranslation();
    const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
    const deleteCallbackRef = useRef<() => void | undefined>();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="academySettingsForm.scoringSystemsDescription"
                    />
                </Box>
            }
            title={translate('academySettingsForm.scoringSystemTitle')}
        >
            <FieldArray
                name="scoringSystems"
                render={(arrayHelpers) => {
                    const scoringSystems = arrayHelpers.form.values
                        .scoringSystems as IScoringSystemFragment[];

                    return (
                        <Box>
                            {scoringSystems?.map((_scoringSystem, index) => (
                                <Grid
                                    container
                                    key={`scoringSystemRow-${index}`}
                                    spacing={1}
                                >
                                    <Grid item sm={5} xs={12}>
                                        <Field
                                            component={TextField}
                                            label={translate('nameSingular')}
                                            name={`scoringSystems[${index}].nameSingular`}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={10}>
                                        <Field
                                            component={TextField}
                                            label={translate('namePlural')}
                                            name={`scoringSystems[${index}].namePlural`}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        sm={1}
                                        sx={{
                                            mt: { xs: '5px', sm: 2.5 },
                                            pl: 1,
                                        }}
                                        xs={2}
                                    >
                                        <IconButton
                                            iconPath={mdiClose}
                                            iconSize="2.4rem"
                                            size="large"
                                            onClick={() => {
                                                // If it is a new scoring system we don't want to see the delete alert
                                                if (!scoringSystems[index].id) {
                                                    arrayHelpers.remove(index);

                                                    return;
                                                }

                                                deleteCallbackRef.current =
                                                    () => {
                                                        arrayHelpers.remove(
                                                            index
                                                        );
                                                    };

                                                setShowDeleteAlertDialog(true);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    alignSelf: 'flex-start',
                                    mt: 2,
                                }}
                                onClick={() => {
                                    arrayHelpers.push({
                                        nameSingular: '',
                                        namePlural: '',
                                    });
                                }}
                            >
                                <ActionButton outlined component={Box}>
                                    <Icon path={mdiPlus} />
                                </ActionButton>

                                <Box ml={2}>
                                    <Typography>
                                        {translate(
                                            'academySettingsForm.addScoringSystem'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                }}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={() => {
                                deleteCallbackRef.current?.();

                                setShowDeleteAlertDialog(false);
                            }}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                deleteCallbackRef.current = undefined;

                                setShowDeleteAlertDialog(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlertDialog}
                title={translate(
                    'academySettingsForm.deleteScoringSettingsMessage.title'
                )}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate(
                        'academySettingsForm.deleteScoringSettingsMessage.text'
                    )}
                </DialogContentText>
            </AlertDialog>
        </FormSection>
    );
};
