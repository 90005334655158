import { useMemo } from 'react';

import {
    ILanguage,
    ICourseTemplatesQueryVariables,
    useCourseTemplatesQuery,
} from 'graphql/types';

export const useCourseTemplateList = (
    queryVariables: ICourseTemplatesQueryVariables,
    q: string
) => {
    const { data: templatesData, loading } = useCourseTemplatesQuery({
        variables: {
            language: (queryVariables.language?.[0] as ILanguage) || undefined,
        },
    });

    const templates = templatesData?.courseTemplates || [];

    // Because we don't have a search in the backend we use a simple filter in the frontend to search.
    // Split search query into array of words
    const search = q.toLowerCase().split(' ');

    const filteredTemplates = useMemo(
        () =>
            templates.filter((template) =>
                search.every(
                    (el) =>
                        template.title?.toLowerCase().includes(el) ||
                        template.description?.toLocaleLowerCase().includes(el)
                )
            ),
        [search, templates]
    );

    return {
        templates: filteredTemplates,
        loading,
    };
};
