import { Stack, Grid, Box, MenuItem, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiDotsVertical } from '@mdi/js';
import { useState } from 'react';

import {
    ICourseFragment,
    ICourseListItemFragment,
    useCourseStudentTokenLazyQuery,
    useCourseManagerTokenLazyQuery,
    Maybe,
    useCurrentUserQuery,
    IRole,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { CourseListItem } from 'training/components/CourseListItem';
import { List } from 'common/components/List';
import { Link } from 'common/components/Link';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { BoxLoader } from 'common/components/Loader';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { CourseExportReportDialog } from 'training/components/CourseExportReportDialog';
import { CourseExportContentDialog } from 'training/components/CourseExportContentDialog';

interface IProps {
    course?: Maybe<ICourseListItemFragment | ICourseFragment>;
    isEditable?: boolean;
    loading?: boolean;
    onReplaceCourse?(): void;
}

export const CourseFormItem = ({
    course,
    isEditable,
    loading,
    onReplaceCourse,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [exportReportDialogOpen, setExportReportDialogOpen] = useState(false);
    const [exportContentDialogOpen, setExportContentDialogOpen] =
        useState(false);
    const { data: currentUserData, loading: currentUserLoading } =
        useCurrentUserQuery();
    const { currentUser } = currentUserData || {};
    const isGroupManager = currentUser?.isGroupManager;
    const isManager = currentUser?.roles?.includes(IRole.Manager);

    // Only managers and groupsmanagers can see the report button
    const showReportButton = isManager || isGroupManager;

    const [getCourseManagerToken, { loading: loadingManagerToken }] =
        useCourseManagerTokenLazyQuery();

    const [getCourseStudentToken, { loading: loadingStudentToken }] =
        useCourseStudentTokenLazyQuery();

    const isLoadingItem = loading || currentUserLoading;

    if (isLoadingItem) return <BoxLoader height="197px" />;

    if (!course) {
        return (
            <Button
                disabled={loadingStudentToken}
                loading={loadingManagerToken}
                sx={{ width: '100%', height: '150px' }}
                variant="contained"
                onClick={onReplaceCourse}
            >
                {translate('selectCourse')}
            </Button>
        );
    }

    const openCourseEdit = async () => {
        // Get url without params
        const returnUrl = `${window.location.href}?course=${course.id}`;
        let url: string | undefined;

        try {
            const response = await getCourseManagerToken({
                variables: { id: course.id, returnUrl },
            });

            url = response.data?.courseManagerToken?.url;
        } catch (error) {
            showApolloError(error);

            return;
        }

        if (!url) return;

        window.location.href = url;
    };

    const openCourseView = async () => {
        const returnUrl = `${window.location.href}?course=${course.id}`;
        let url: string | undefined;

        try {
            const response = await getCourseStudentToken({
                variables: { id: course.id, returnUrl },
            });

            url = response.data?.courseStudentToken?.url;
        } catch (error) {
            showApolloError(error);

            return;
        }

        if (!url) return;

        window.location.href = url;
    };

    const showActionDropdown =
        isManager || showReportButton || !!onReplaceCourse;

    return (
        <List sx={{ backgroundColor: 'grey.50' }}>
            <CourseListItem
                disabled
                button={false}
                course={course}
                footer={
                    <Grid container>
                        <Grid item xs>
                            <Stack direction="row" spacing={2}>
                                {isEditable && (
                                    <Button
                                        disabled={loadingStudentToken}
                                        loading={loadingManagerToken}
                                        variant="contained"
                                        onClick={openCourseEdit}
                                    >
                                        {translate('edit')}
                                    </Button>
                                )}

                                {/* Only show "view" button if course is publised */}
                                {!!course.publishedVersionNumber && (
                                    <Button
                                        disabled={loadingManagerToken}
                                        loading={loadingStudentToken}
                                        variant="contained"
                                        onClick={openCourseView}
                                    >
                                        {translate('view')}
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                        {showActionDropdown && (
                            <Grid
                                item
                                component={Box}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DropdownMenu
                                    anchor={
                                        <Button
                                            startIcon={
                                                <Icon
                                                    path={mdiDotsVertical}
                                                    size="1.9rem"
                                                />
                                            }
                                            variant="contained"
                                        >
                                            {translate('actions')}
                                        </Button>
                                    }
                                    placement="bottom-end"
                                >
                                    <Stack
                                        divider={<Divider sx={{ my: 1.25 }} />}
                                    >
                                        <Box>
                                            {showReportButton && (
                                                <>
                                                    <MenuItem
                                                        component={Link}
                                                        query={`?q=${course.title}`}
                                                        to="COURSE_REPORT"
                                                    >
                                                        {translate('reporting')}
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() =>
                                                            setExportReportDialogOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        {translate(
                                                            'exportResults'
                                                        )}
                                                    </MenuItem>
                                                </>
                                            )}
                                            {isManager && (
                                                <MenuItem
                                                    onClick={() =>
                                                        setExportContentDialogOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    {translate('exportContent')}
                                                </MenuItem>
                                            )}
                                        </Box>

                                        {!!onReplaceCourse && (
                                            <MenuItem onClick={onReplaceCourse}>
                                                {translate('replaceCourse')}
                                            </MenuItem>
                                        )}
                                    </Stack>
                                </DropdownMenu>
                            </Grid>
                        )}
                    </Grid>
                }
                px={2}
                py={0}
                sx={{
                    flexWrap: 'wrap',
                }}
            />

            <CourseExportReportDialog
                courseId={course.id}
                open={exportReportDialogOpen}
                onClose={() => setExportReportDialogOpen(false)}
            />

            <CourseExportContentDialog
                courseId={course.id}
                currentCourseVersion={
                    course.publishedVersionNumber || undefined
                }
                open={exportContentDialogOpen}
                onClose={() => setExportContentDialogOpen(false)}
            />
        </List>
    );
};
