import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { IConditionFragment, IConditionEffect } from 'graphql/types';
import { Chip, ChipGroup } from 'common/components/Chip';
import {
    parseConditions,
    timeDeltaToString,
} from 'common/utils/parseConditions';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { formatDate } from 'common/utils/formatDate';

interface IProps {
    groupConditionEffect: IConditionEffect;
    conditionEffect: IConditionEffect;
    conditions?: IConditionFragment[] | null;
    alwaysOpen?: boolean;
    startDate?: string;
}

export const ModuleConditionsChips = ({
    conditions,
    alwaysOpen,
    conditionEffect,
    groupConditionEffect,
    startDate: startDateISO,
}: IProps) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    if (alwaysOpen) {
        let alwaysOpenTransKey;

        if (!studentView) {
            // For editors we want to always show when this module is always open.
            alwaysOpenTransKey = 'alwaysAvailable';
        } else if (groupConditionEffect === IConditionEffect.Lock) {
            // When group is locked but the module is always open we want to show a label.
            alwaysOpenTransKey = 'alreadyAvailable';
        }

        if (alwaysOpenTransKey) {
            return (
                <ChipGroup>
                    <Chip label={translate(alwaysOpenTransKey)} />
                </ChipGroup>
            );
        }
    }

    const allFullfilled = conditionEffect === IConditionEffect.Nothing;

    // If all conditions has been fulfilled and we have the studentView open we don't need to show the dates
    if (allFullfilled && studentView) return null;

    // When we have a startDate and studentView is open we want to just show the startDate
    if (startDateISO && studentView) {
        const startDate = formatDate(
            startDateISO,
            'd MMMM yyyy HH:mm',
            true,
            true
        );

        return (
            <ChipGroup>
                <Chip
                    label={translate('conditionString.startDate', {
                        startDate,
                    })}
                />
            </ChipGroup>
        );
    }

    // Don't continue here if studentView is open
    if (!conditions?.length || studentView) return null;

    const { relativeDates } = parseConditions(conditions);

    const conditionChips: React.ReactNode[] = [];

    if (relativeDates.length) {
        relativeDates.map((relativeDate, index) => {
            const label = timeDeltaToString(relativeDate);

            const typeTransKey = relativeDate.isNegative
                ? 'conditionString.afterCondition'
                : 'conditionString.beforeCondition';

            conditionChips.push(
                <Chip
                    key={index}
                    label={`${label} ${translate(typeTransKey)}`}
                />
            );
        });
    }

    return <ChipGroup>{conditionChips}</ChipGroup>;
};
