import { useState, useRef, useEffect } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { mdiCog } from '@mdi/js';
import { Node as SlateNode } from 'slate';

import { Icon } from 'common/components/Icon';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { BasePage } from 'hydra/pages/BasePage';
import { Loader } from 'common/components/Loader';
import { useArticleLazyQuery, useUpdateArticleMutation } from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Breadcrumbs } from 'common/components/Breadcrumbs';
import { Link } from 'common/components/Link';
import { Typography } from 'common/components/Typography';
import { ContentEditor } from 'common/components/ContentEditor';
import { ActionButton } from 'common/components/ActionButton';
import { Tooltip } from 'common/components/Tooltip';
import { Button } from 'common/components/Button';
import { LibraryArticleDrawer } from 'library/components/LibraryArticleDrawer';
import {
    goToArticleContentEditPage,
    goToArticleDetailPage,
    goToParentCollection,
} from 'library/utils/goTo';
import { deserializeContent } from 'common/components/ContentEditor/utils/deserialize';
import { useFrontendPermissions } from 'user/hooks';
import { PageTitle } from 'common/components/PageTitle';
import {
    ExitPageAlert,
    IExitPageAlertRef,
} from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { isUUID } from 'common/utils/isUUID';
import { getUrl } from 'route/utils/getUrl';

export const LibraryArticlePage = () => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const [articleContent, setArticleContent] = useState<SlateNode[]>();
    const [contentChanged, setContentChanged] = useState<boolean>(false);

    const { canUpdate } = useFrontendPermissions('libraryArticle');

    const [updateArticle, { loading: updateLoading, error: updateError }] =
        useUpdateArticleMutation();

    const articleDrawerOpen = !!useRouteMatch('LIBRARY_ARTICLE_EDIT');
    const editing = !!useRouteMatch('LIBRARY_ARTICLE_EDIT_CONTENT');

    const prevContent = useRef<SlateNode[]>();
    const exitAlertRef = useRef<IExitPageAlertRef>(null);

    const [fetchArticle, { loading, error, data, called }] =
        useArticleLazyQuery({
            onCompleted: (data) => {
                const article = data?.libraryArticle;

                if (!article) return;

                // Set current article content
                const content = deserializeContent(article?.content);

                prevContent.current = content;
                setArticleContent(content);

                if (isUUID(id)) return;

                navigate(
                    getUrl(
                        editing
                            ? 'LIBRARY_ARTICLE_EDIT_CONTENT'
                            : 'LIBRARY_ARTICLE',
                        { id: article.id }
                    )
                );
            },
        });

    useEffect(() => {
        const articleId = data?.libraryArticle?.id || {};

        if (loading || articleId === id || error) return;

        const articleQueryVariables = isUUID(id) ? { id } : { articleId: id };

        fetchArticle({ variables: articleQueryVariables });
    }, [data, fetchArticle, id, loading, error]);

    if (!id) return null;

    if (loading || error) {
        return (
            <BasePage>
                {error && <ApolloError error={error} />}
                {loading && <Loader />}
            </BasePage>
        );
    }

    const article = data?.libraryArticle;

    // When the response doesn't contain an article we redirect to the root of the library.
    if (called && !loading && !article) {
        return <Navigate to={getUrl('LIBRARY')} />;
    }

    const { collection: parent, title } = article || {};

    const libraryTitle = translate('library');

    const breadcrumbs = [
        <Link key="l" to="LIBRARY">
            {libraryTitle}
        </Link>,
    ];

    if (parent && !parent.root && parent.id && parent.title) {
        breadcrumbs.push(
            <Link key="c" params={{ id: parent?.id }} to="LIBRARY_COLLECTION">
                {parent.title}
            </Link>
        );
    }

    const handleSaveContent = () => {
        const newContent = JSON.stringify(articleContent);

        updateArticle({
            variables: {
                id,
                article: { content: newContent },
            },
        });

        setContentChanged(false);

        // Set new version as prevVersion
        prevContent.current = articleContent;

        // Manually call unblock to make sure it's unblocked before trying to redirect
        exitAlertRef.current && exitAlertRef.current.unblock();

        // Set editing to false after saving content
        setEditing(false);
    };

    const handleCancelSaveContent = async () => {
        await setContentChanged(false);

        // Reset content
        await setArticleContent(prevContent.current);

        setEditing(false);
    };

    const setEditing = (editing: boolean) => {
        if (!editing) {
            goToArticleDetailPage(navigate, id);

            return;
        }

        goToArticleContentEditPage(navigate, id);
    };

    const pageTitle = !!title ? `${libraryTitle} - ${title}` : undefined;

    return (
        <BasePage>
            {updateError && <ApolloError error={updateError} />}

            {article && (
                <PageTitle
                    mixpanelTitle="Article - Library"
                    skipMixpanel={articleDrawerOpen}
                >
                    {pageTitle || ''}
                </PageTitle>
            )}

            <Box py={4}>
                {!!breadcrumbs.length && (
                    <Box mb={2}>
                        <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
                    </Box>
                )}
                <Box mb={3}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography variant="h1">{title}</Typography>
                        </Grid>
                        {canUpdate && (
                            <Grid item component={Box} display="flex">
                                <Box mr={1}>
                                    <ActionButton
                                        outlined
                                        variant="extended"
                                        onClick={() => {
                                            setEditing(!editing);
                                        }}
                                    >
                                        <>
                                            {translate(
                                                editing ? 'closeEditor' : 'edit'
                                            )}
                                        </>
                                    </ActionButton>
                                </Box>
                                <Tooltip
                                    title={translate<string>('editArticle')}
                                >
                                    <Box>
                                        <ActionButton
                                            outlined
                                            component={Link}
                                            params={{ id }}
                                            to="LIBRARY_ARTICLE_EDIT"
                                        >
                                            <Icon path={mdiCog} />
                                        </ActionButton>
                                    </Box>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <ContentEditor
                    content={articleContent}
                    readOnly={!editing}
                    onChange={(content) => {
                        setArticleContent(content);
                        setContentChanged(true);
                    }}
                />
                {editing && (
                    <Button
                        color="primary"
                        disabled={!contentChanged}
                        loading={updateLoading}
                        variant="contained"
                        onClick={handleSaveContent}
                    >
                        {translate('save')}
                    </Button>
                )}
            </Box>

            {canUpdate && article && (
                <LibraryArticleDrawer
                    article={article}
                    open={articleDrawerOpen}
                    pageTitle={pageTitle}
                    onClose={() => goToArticleDetailPage(navigate, id)}
                    onDelete={() => {
                        if (!parent) return;

                        goToParentCollection(navigate, parent);
                    }}
                    onUpdate={() => {
                        goToArticleDetailPage(navigate, id);
                    }}
                />
            )}

            <ExitPageAlert
                alert={UnsavedChangesAlert}
                ref={exitAlertRef}
                when={editing && contentChanged}
                onCancel={() => {
                    handleCancelSaveContent();

                    return true;
                }}
                onConfirm={async () => {
                    handleSaveContent();

                    return true;
                }}
            />
        </BasePage>
    );
};
