import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { Box, SxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

import { AccordionDetails } from './AccordionDetails';

const accordionStyle: SxProps = {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'divider',
    mt: '-1px',
    boxShadow: 'none',
    overflow: 'hidden',

    '&:first-of-type': {
        mt: 0,
    },

    '&:before': {
        content: 'none',
    },

    '&.Mui-expanded': {
        borderRadius: 1,
    },

    '&.Mui-expanded + &': {
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
    },
};

interface IProps extends Omit<AccordionProps, 'children'> {
    children?: React.ReactNode;
    details?: string;
    summary?: React.ReactNode;
}

export const Accordion = ({
    children,
    details,
    expanded,
    summary,
    sx,
    ...other
}: IProps) => (
    <MuiAccordion
        expanded={expanded}
        sx={[accordionStyle, ...withSxProp(sx)]}
        {...other}
    >
        {!!summary && summary}

        {!!details && <AccordionDetails>{details}</AccordionDetails>}

        {/*
         * Because of DND issues children will not be rendered when accordion is closed
         * All accordions should behave accordingly, also those without DND
         */}
        {children && (
            <Box display={expanded ? 'block' : 'none'}>{children}</Box>
        )}
    </MuiAccordion>
);
