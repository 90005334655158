import { Box, FormHelperText } from '@mui/material';
import { FieldProps } from 'formik';
import { mdiFile } from '@mdi/js';

import { IFileFragment } from 'graphql/types';
import { FieldLabel } from 'common/components/FieldLabel';
import { BaseUploader } from 'common/components/BaseUploader';
import { Typography } from 'common/components/Typography';

interface IProps extends FieldProps {
    helperText?: string;
    label?: string;
    maxUploadSize: number;
    acceptedFileTypes?: string;
    onChange?(name: string, file: IFileFragment | null): void;
}

export const FileField = ({
    acceptedFileTypes,
    helperText,
    label,
    field,
    onChange,
    form: { setFieldValue, getFieldMeta },
}: IProps) => {
    const { value, name } = field;
    const error = getFieldMeta(name).error;

    const handleChange = (newValue: IFileFragment | null) => {
        setFieldValue(name, newValue);

        onChange && onChange(name, newValue);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {!!label && (
                <FieldLabel>
                    <Typography>{label}</Typography>
                </FieldLabel>
            )}

            <BaseUploader
                acceptedFileTypes={acceptedFileTypes}
                file={value}
                iconPath={mdiFile}
                id="FileModule"
                onUpload={handleChange}
            />

            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}

            {!!error && <FormHelperText error>{error}</FormHelperText>}
        </Box>
    );
};
