/**
 *  Converts a file size in bytes to a human readable string.
 *
 * @param {number} sizeInBytes
 * @returns {string} The humanized file size
 */
export function humanizeFileSize(sizeInBytes: number): string {
    if (sizeInBytes === 0) {
        return '0 B';
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    const base = 1024;
    const exponent = Math.floor(Math.log(sizeInBytes) / Math.log(base));
    const sizeInUnit = sizeInBytes / Math.pow(base, exponent);
    const sizeFormatted = sizeInUnit.toFixed(sizeInUnit % 1 === 0 ? 0 : 2);

    return `${sizeFormatted} ${units[exponent]}`;
}
