import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import {
    IOfferEventCardFragment,
    IOfferEventType,
    ITrainingListCardFragment,
    ITrainingRoleFilter,
} from 'graphql/types';
import { OfferEventCard } from 'offer/components/OfferEventCard';
import { TrainingCard } from 'training/components/TrainingCard';

interface IProps {
    offerEvents: IOfferEventCardFragment[];
    trainingRoleFilter: ITrainingRoleFilter;
    trainings: ITrainingListCardFragment[];
}

export const HomePageResults = ({
    offerEvents,
    trainingRoleFilter,
    trainings,
}: IProps) => {
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const meetingWebinars = offerEvents.filter(
        (event) =>
            event.type === IOfferEventType.Meeting ||
            event.type === IOfferEventType.Webinar
    );
    const elearningCourse = offerEvents.filter(
        (event) =>
            event.type === IOfferEventType.Elearning ||
            event.type === IOfferEventType.Course
    );
    const offerEventTrainings = offerEvents.filter(
        (event) => event.type === IOfferEventType.Training
    );

    const handleClickOfferEvent = (offerEvent: IOfferEventCardFragment) => {
        const { id, offerId, hasDetailPage } = offerEvent;

        openGlobalDrawer(
            {
                type: hasDetailPage
                    ? IGlobalDrawerType.OfferEvent
                    : IGlobalDrawerType.Offer,
                itemId: hasDetailPage ? id : offerId,
            },
            location
        );
    };

    return (
        <Grid container spacing={3}>
            {meetingWebinars.map((offerEvent) => (
                <Grid item key={offerEvent.id} md={4} sm={6} xs={12}>
                    <OfferEventCard
                        offerEvent={offerEvent}
                        onClick={handleClickOfferEvent}
                    />
                </Grid>
            ))}

            {elearningCourse.map((offerEvent) => (
                <Grid item key={offerEvent.id} md={4} sm={6} xs={12}>
                    <OfferEventCard
                        offerEvent={offerEvent}
                        onClick={handleClickOfferEvent}
                    />
                </Grid>
            ))}

            {offerEventTrainings.map((offerEvent) => (
                <Grid item key={offerEvent.id} md={4} sm={6} xs={12}>
                    <OfferEventCard
                        offerEvent={offerEvent}
                        onClick={handleClickOfferEvent}
                    />
                </Grid>
            ))}

            {trainings?.map((training) => (
                <Grid item key={training.id} md={4} sm={6} xs={12}>
                    <TrainingCard
                        showTypeIcon
                        hideProgress={
                            trainingRoleFilter !==
                            ITrainingRoleFilter.Participant
                        }
                        hideTrainerChip={
                            trainingRoleFilter === ITrainingRoleFilter.Trainer
                        }
                        training={training}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
