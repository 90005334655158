import { IExternalContentListItemFragment } from 'graphql/types';

export function isExternalContentSelected(
    selectedExternalContents: IExternalContentListItemFragment[],
    externalContent: IExternalContentListItemFragment
) {
    return selectedExternalContents.some(
        (selectedExternalContent) =>
            selectedExternalContent.id === externalContent.id
    );
}

export function isExternalContentAdded(
    addedExternalContents: IExternalContentListItemFragment[],
    externalContent: IExternalContentListItemFragment
) {
    return addedExternalContents.some(
        (addedExternalContent) => addedExternalContent.id === externalContent.id
    );
}

export function getExternalContentSelectStatus(
    selectedExternalContents: IExternalContentListItemFragment[],
    addedExternalContents: IExternalContentListItemFragment[],
    externalContent: IExternalContentListItemFragment
) {
    const isSelected = isExternalContentSelected(
        selectedExternalContents,
        externalContent
    );
    const isAdded = isExternalContentAdded(
        addedExternalContents,
        externalContent
    );

    return { isSelected, isAdded };
}
