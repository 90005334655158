import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import {
    mdiShieldCheck,
    mdiDoorClosedCancel,
    mdiDoorClosedLock,
    mdiPencilRuler,
} from '@mdi/js';

import { InfoRow } from 'common/components/InfoRow';
import {
    IOfferEventDetailFragment,
    IOfferEventSubscriptionStatus,
    IOfferEventType,
    IOfferEventEnrollmentCurrentUserFragment,
} from 'graphql/types';
import { OfferPermissionsContext } from 'offer/contexts';
import { OfferEventPermissionsContext } from 'offer/contexts/OfferPermissionsContext';
import { Icon } from 'common/components/Icon';
import { getScoringDisplay } from 'common/utils/scoring';
import { filterExtraCategoryValuesForOfferEvent } from 'offer/utils/offerEvent';
import { UserInfo } from 'common/components/UserInfo';

import { ExtraCategoryInfo } from './ExtraCategoryInfo';
import { SubscriptionStatusInfo } from './SubscriptionStatusInfo';
import { OfferEventDate } from './OfferEventDate';

interface IProps {
    offerEvent: IOfferEventDetailFragment;
    showSubscriptionStatusSection?: boolean;
    currentUserEnrollment?: IOfferEventEnrollmentCurrentUserFragment;
}

export const BasicInfoSection = ({
    offerEvent,
    showSubscriptionStatusSection,
}: IProps) => {
    const { t: translate } = useTranslation();
    const { canManageSubscriptions = false } =
        useContext(OfferEventPermissionsContext) || {};

    const {
        closingDateExpired,
        currentUserEnrollment,
        dates,
        descriptionAfterSubscription,
        offer: { title, extraCategoryValues },
        publish,
        trainers: trainerConnection,
        type,
        cancelled,
        scoring,
        waitingList,
    } = offerEvent;

    const trainers =
        trainerConnection?.edges.map((edge) => edge?.node).filter(Boolean) ||
        [];

    const { canUpdate = false } = useContext(OfferPermissionsContext) || {};

    const isTraining = type === IOfferEventType.Training;
    const isCourse = type === IOfferEventType.Course;
    const hasDates = !isTraining && !isCourse && !!dates.length;

    const showClosingDateExpiredChip =
        closingDateExpired &&
        (!currentUserEnrollment || canUpdate || canManageSubscriptions);

    const isEnrolled =
        !!currentUserEnrollment &&
        [
            IOfferEventSubscriptionStatus.Enrolled,
            IOfferEventSubscriptionStatus.Completed,
            IOfferEventSubscriptionStatus.Absent,
        ].includes(currentUserEnrollment.status);

    // When the user is enrolled or can manage they can add the event to their calendar and see the webinar link
    const canAccessDateData = canUpdate || canManageSubscriptions || isEnrolled;

    let offerEventStatus: string | undefined;
    let offerEventStatusIcon: string | undefined;

    if (cancelled) {
        offerEventStatus = translate('cancelled');
        offerEventStatusIcon = mdiDoorClosedCancel;
    } else if (!publish) {
        offerEventStatus = translate('concept');
        offerEventStatusIcon = mdiPencilRuler;
    } else if (showClosingDateExpiredChip) {
        offerEventStatus = translate('subscriptionClosed');
        offerEventStatusIcon = mdiDoorClosedLock;
    }

    // Filter extra category values for offer event
    const extraCategories =
        filterExtraCategoryValuesForOfferEvent(extraCategoryValues);

    return (
        <Stack spacing={2}>
            {offerEventStatus && (
                <InfoRow description={translate('status')}>
                    <Box sx={{ display: 'flex' }}>
                        {offerEventStatusIcon && (
                            <Box
                                sx={{
                                    py: 1,
                                    px: 1.25,
                                    mt: '-7px',
                                    display: 'inline-flex',
                                    color: 'inherit',
                                }}
                            >
                                <Icon path={offerEventStatusIcon} size="2rem" />
                            </Box>
                        )}
                        <Box>{offerEventStatus}</Box>
                    </Box>
                </InfoRow>
            )}

            {!offerEventStatus &&
                showSubscriptionStatusSection &&
                currentUserEnrollment && (
                    <SubscriptionStatusInfo
                        subscription={currentUserEnrollment}
                        waitingList={waitingList}
                    />
                )}
            {hasDates && (
                <InfoRow description={translate('when_where')}>
                    <Stack spacing={1}>
                        {dates.map((date) => (
                            <OfferEventDate
                                canAccessData={canAccessDateData}
                                date={date}
                                descriptionAfterSubscription={
                                    descriptionAfterSubscription
                                }
                                key={date.id}
                                offerTitle={title}
                            />
                        ))}
                    </Stack>
                </InfoRow>
            )}

            {!!trainers.length && (
                <InfoRow description={translate('by')}>
                    <Stack spacing={1}>
                        {trainers.map((trainer) => (
                            <UserInfo
                                key={trainer.id}
                                sx={{ pl: 1, alignSelf: 'start' }}
                                user={trainer}
                            />
                        ))}
                    </Stack>
                </InfoRow>
            )}

            {!!scoring?.length && (
                <InfoRow description={translate('offerEventPoints')}>
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                py: 1,
                                px: 1.25,
                                mt: '-7px',
                                display: 'inline-flex',
                                color: 'inherit',
                            }}
                        >
                            <Icon path={mdiShieldCheck} size="2rem" />
                        </Box>
                        <Box>{getScoringDisplay(scoring)}</Box>
                    </Box>
                </InfoRow>
            )}

            {!!extraCategories?.length && (
                <ExtraCategoryInfo extraCategoryValues={extraCategories} />
            )}
        </Stack>
    );
};
