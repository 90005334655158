import { mdiChevronLeft } from '@mdi/js';
import { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TRouteName } from 'route/types';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { Link } from '../Link';

const buttonStyle: SxProps = {
    p: 0,
    ml: '-9px',
    mb: 2,

    '&:hover': {
        backgroundColor: 'unset',
    },
};

interface IProps {
    color?: string;
    to?: TRouteName;
    onClick?: () => void;
}

export const PageHeaderBackButton = ({ color, to, onClick }: IProps) => {
    const { t: translate } = useTranslation();

    return (
        <Button
            component={Link}
            sx={{
                color: color || 'text.primary',
                ...buttonStyle,
            }}
            to={to}
            onClick={onClick}
        >
            <Icon path={mdiChevronLeft} size="3.2rem" />
            {translate('back')}
        </Button>
    );
};
