import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { BoxLoader } from 'common/components/Loader';
import { ICourseListItemFragment } from 'graphql/types';
import { useCourseSearch } from 'training/hooks/useCourseSearch';
import { useCourseList } from 'training/hooks/useCourseList';
import { CourseAddList } from 'training/components/CourseAddList';
import { useCourseQueryVariables } from 'training/hooks/useCourseQueryVariables';
import { usePagination } from 'common/hooks/usePagination';
import { Pagination } from 'common/components/Pagination';
import { FilterBar } from 'common/components/FilterBar';
import { Divider } from 'common/components/Divider';
import { TFilterBarItem } from 'common/types';

interface IProps {
    selectMultiple?: boolean;
    onSelect?(course: ICourseListItemFragment): void;
    selectedIds?: string[];
    listItemProps?: React.ComponentProps<typeof CourseAddList>['listItemProps'];
}

export const CourseFilterAddList = ({
    selectMultiple = true,
    onSelect,
    listItemProps,
}: IProps) => {
    const [translate] = useTranslation();
    const [selectedCourses, setSelectedCourses] = useState<
        ICourseListItemFragment[]
    >([]);

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useCourseSearch();

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);
    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { courseQueryVariables } = useCourseQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const { courses, loading, coursesCount } =
        useCourseList(courseQueryVariables);

    const handleSelectCourse = (course: ICourseListItemFragment) => {
        onSelect?.(course);

        if (!selectMultiple) return;

        const newSelectedCourses = [...selectedCourses, course];
        setSelectedCourses(newSelectedCourses);
    };

    const handleRemoveCourse = (course: ICourseListItemFragment) => {
        if (!selectMultiple) return;

        const newSelectedCourses = selectedCourses.filter(
            (selectedCourse) => selectedCourse.id !== course.id
        );

        setSelectedCourses(newSelectedCourses);
    };

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(coursesCount || 0);
    }, [coursesCount, loading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    if (filtersLoading) return <BoxLoader />;

    return (
        <>
            <FilterBar
                disabled={loading}
                filters={filters}
                initialSearchValue={searchQueryParam}
                initialSelected={selectedFilters}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
                onSelect={handleFilterSelect}
            />
            {loading ? (
                <BoxLoader />
            ) : (
                <CourseAddList
                    addedCourses={selectedCourses}
                    courses={courses}
                    listItemProps={{
                        ...listItemProps,
                        addButton: selectMultiple,
                    }}
                    selectedCourses={selectedCourses}
                    onAddCourse={handleSelectCourse}
                    onRemoveCourse={(selectedCourse: ICourseListItemFragment) =>
                        handleRemoveCourse(selectedCourse)
                    }
                />
            )}

            {paginationSettings.count > -1 && (
                <Box mt={2}>
                    <Divider />

                    <Box mt={2}>
                        <Pagination
                            page={paginationSettings.page}
                            pageAmount={paginationSettings.pageAmount}
                            totalsAmount={paginationSettings.count}
                            totalsText={
                                paginationSettings.count === 1
                                    ? translate('course')
                                    : translate('courses')
                            }
                            onChange={(page: number) => {
                                setPage(page);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};
