import { Field, FieldAttributes, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { MultiSelect } from 'common/components/FormField';
import { IRole } from 'graphql/types';
import { ROLE_TRANSLATIONS } from 'user/constants/roles';

interface IRoleInputOption {
    id: string;
    name: string;
}

interface IProps extends FieldProps {
    label?: string;
    onChange?: (name: string, value: unknown) => void;
}

export const RoleSelectField = ({
    label,
    onChange,
    ...other
}: IProps & Partial<FieldAttributes<unknown>>) => {
    const [translate] = useTranslation();

    const {
        form: { setFieldValue },
    } = other;

    const options = [
        {
            id: IRole.Owner,
            name: ROLE_TRANSLATIONS[IRole.Owner],
        },
        {
            id: IRole.Manager,
            name: ROLE_TRANSLATIONS[IRole.Manager],
        },
        {
            id: IRole.Author,
            name: ROLE_TRANSLATIONS[IRole.Author],
        },
        {
            id: IRole.Trainer,
            name: ROLE_TRANSLATIONS[IRole.Trainer],
        },
    ];

    const handleChange = (name: string, value: unknown) => {
        onChange?.(name, value);
        setFieldValue(name, value);
    };

    return (
        <Field
            component={MultiSelect}
            getOptionLabel={(option: IRoleInputOption) => option.name}
            InputProps={{ label }}
            isOptionEqualToValue={(
                option: IRoleInputOption,
                value: IRoleInputOption
            ) => option.id === value.id}
            loadingText={translate('loadingDots')}
            options={options}
            onChange={handleChange}
            {...other}
        />
    );
};
