import {
    IPortfolioItemFragment,
    IPortfolioTrainingListItemFragment,
    IContentTypeValue,
} from 'graphql/types';

// Type guard to check if the related object is a training list item
export const isTrainingPortfolioItem = (
    relatedObject: IPortfolioItemFragment['relatedObject'],
    relatedType: IPortfolioItemFragment['relatedType']
): relatedObject is IPortfolioTrainingListItemFragment =>
    relatedType === IContentTypeValue.Training;
