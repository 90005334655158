import { Box, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    mdiAccountMinus,
    mdiAccountPlus,
    mdiAccount,
    mdiClipboardCheck,
    mdiClipboardRemove,
    mdiAccountClock,
    mdiTextLong,
} from '@mdi/js';

import {
    IOfferEventEnrollmentManageable,
    IOfferEventSubscriptionStatus,
} from 'graphql/types';
import { InfoRow } from 'common/components/InfoRow';
import { Icon } from 'common/components/Icon';

interface IProps {
    subscription?: Partial<IOfferEventEnrollmentManageable>;
    waitingList?: boolean;
}

const iconWrapperStyle: SxProps = {
    py: 1,
    px: 1.25,
    mt: '-7px',
    display: 'inline-flex',
    color: 'inherit',
};

export const SubscriptionStatusInfo = ({
    subscription,
    waitingList,
}: IProps) => {
    const { t: translate } = useTranslation();

    if (!subscription) return null;

    const { reason, status } = subscription;

    if (!waitingList && status === IOfferEventSubscriptionStatus.Full) {
        return null;
    }

    let labelTransKey;
    let iconPath;
    let showReason = false;

    switch (status) {
        case IOfferEventSubscriptionStatus.Enrolled:
            labelTransKey = 'enrolled';
            iconPath = mdiAccount;

            break;
        case IOfferEventSubscriptionStatus.Requested:
            labelTransKey = 'requested';
            iconPath = mdiAccountPlus;

            break;
        case IOfferEventSubscriptionStatus.Denied:
            labelTransKey = 'denied';
            iconPath = mdiAccountMinus;
            showReason = true;

            break;
        case IOfferEventSubscriptionStatus.Full:
            labelTransKey = 'full';
            iconPath = mdiAccountClock;

            break;
        case IOfferEventSubscriptionStatus.Completed:
            labelTransKey = 'completed';
            iconPath = mdiClipboardCheck;
            showReason = true;

            if (!!subscription.hasCertificate) {
                labelTransKey = 'completedWithCertificate';
            }

            break;
        case IOfferEventSubscriptionStatus.Absent:
            labelTransKey = 'absent';
            iconPath = mdiClipboardRemove;
            showReason = true;

            break;
    }

    // Only show when we have a status label
    if (!labelTransKey) return null;

    return (
        <InfoRow description={translate('status')}>
            <Box sx={{ display: 'flex' }}>
                {iconPath && (
                    <Box sx={iconWrapperStyle}>
                        <Icon path={iconPath} size="2rem" />
                    </Box>
                )}
                {translate(`offerEventSubscriptionStatus.${labelTransKey}`)}
            </Box>
            {showReason && reason && (
                <Box sx={{ display: 'flex' }}>
                    <Box sx={iconWrapperStyle}>
                        <Icon path={mdiTextLong} size="2rem" />
                    </Box>
                    {reason}
                </Box>
            )}
        </InfoRow>
    );
};
