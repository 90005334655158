import { isValidElement, cloneElement } from 'react';
import { Box } from '@mui/material';
import { styled } from 'styled-components';

import { Avatar } from './Avatar';

interface IProps {
    children: React.ReactNode[];
    max: number;
}

/**
 * This components is heavily based the mui lab AvatarGroup. This own component is stripped from some code and reverses
 * the z-indexes of the children.
 */
export const BaseAvatarGroup = ({ children, max, ...other }: IProps) => {
    const clampedMax = max < 2 ? 2 : max;

    const extraAvatars =
        children.length > clampedMax ? children.length - clampedMax + 1 : 0;

    return (
        <Box {...other}>
            {children
                .slice(0, children.length - extraAvatars)
                .map((child, index) => {
                    if (!isValidElement(child)) return null;

                    return cloneElement(child as React.ReactElement, {
                        style: {
                            zIndex: index,
                        },
                    });
                })}
            {extraAvatars ? (
                <Avatar
                    bgColor="primary"
                    className="avatar"
                    style={{
                        zIndex: children.length,
                    }}
                >
                    +{extraAvatars}
                </Avatar>
            ) : null}
        </Box>
    );
};

export const AvatarGroup = styled(BaseAvatarGroup)(({ theme }) => ({
    display: 'flex',

    '> *': {
        border: `2px solid ${theme.palette.background.default}`,
        marginLeft: '-25px',
        fontSize: '1.6rem',

        '&:first-child': {
            marginLeft: 0,
        },
    },
}));
