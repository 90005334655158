import * as Yup from 'yup';
import { DateTime } from 'luxon';

import {
    IOfferEventDetailFragment,
    IOfferEventSubscriptionStatus,
    IOfferEventType,
    ILabels,
    IExtraCategoryOption,
    IExtraCategoryValueFragment,
} from 'graphql/types';
import { translate } from 'utils/i18n';
import { getRoundedDateTime } from 'common/utils/formatDate';
import { TColorKeys } from 'theme/palette';
import { transformDate } from 'common/utils/form';
import { parseJSON } from 'common/utils/json';

export function getOfferEventSubscriptionStatusData(
    subscriptionStatus?: IOfferEventSubscriptionStatus,
    hasCertificate?: boolean
): { color: TColorKeys | 'default'; label: string } | undefined {
    if (!subscriptionStatus) return { color: 'primary', label: '' };

    const transPrefix = 'enrollmentsTable';

    switch (subscriptionStatus) {
        case IOfferEventSubscriptionStatus.Enrolled:
            return {
                color: 'success',
                label: translate(`${transPrefix}.labelEnrolled`),
            };
        case IOfferEventSubscriptionStatus.Requested:
            return {
                color: 'warning',
                label: translate(`${transPrefix}.labelRequested`),
            };
        case IOfferEventSubscriptionStatus.Denied:
            return {
                color: 'error',
                label: translate(`${transPrefix}.labelRejected`),
            };
        case IOfferEventSubscriptionStatus.Completed:
            if (hasCertificate) {
                return {
                    color: 'default',
                    label: translate(`${transPrefix}.labelCertificate`),
                };
            }

            return {
                color: 'default',
                label: translate(`${transPrefix}.labelCompleted`),
            };
        case IOfferEventSubscriptionStatus.Absent:
            return {
                color: 'error',
                label: translate(`${transPrefix}.labelAbsent`),
            };
        default:
            return undefined;
    }
}

export function getOfferEventTypeTranslation(type: IOfferEventType) {
    return translate(type.toLowerCase()) || '';
}

export function getOfferEventFormData(
    offerEventType: IOfferEventType,
    offerEvent?: IOfferEventDetailFragment,
    hasServiceProvider?: boolean
) {
    const baseDateValidation = {
        startDate: Yup.date()
            .transform(transformDate)
            .nullable()
            .required(translate('validate.requiredDateTime')),
        endDate: Yup.date()
            .transform(transformDate)
            .nullable()
            .required(translate('validate.requiredDateTime'))
            .when('startDate', (startDate, schema) => {
                if (!startDate) return schema;

                // Get startdate + 5 minutes
                const startAfter = new Date(startDate.getTime() + 300000);

                return schema.min(
                    startAfter,
                    translate('dateAfterStartDateMessage')
                );
            }),
    };

    const datesValidation = Yup.array()
        .of(
            Yup.object().shape(
                {
                    ...baseDateValidation,
                    location: Yup.object().nullable(),
                    webinarLink: Yup.string(),
                },
                [['startDate', 'endDate']]
            )
        )
        .min(1, translate('validate.requiredMinOneDate'))
        .required(translate('validate.requiredMinOneDate'));

    const commonValues = {
        initialValues: {
            dates: offerEvent?.dates.map((date) => {
                // If no location is set we use the locationString as location
                const location = date.location || {
                    title: date.locationString,
                };

                return {
                    ...date,
                    startDate: DateTime.fromISO(date.startDate),
                    endDate: DateTime.fromISO(date.endDate),
                    location,
                    webinarLink:
                        date.webinarLink.replace(/(^\w+:|^)\/\//, '') || '',
                };
            }) || [
                {
                    startDate: getRoundedDateTime(),
                    endDate: getRoundedDateTime().plus({ hours: 1 }),
                    isServiceProviderWebinar: hasServiceProvider,
                    location: null,
                    webinarLink: '',
                },
            ],
            closingDate: offerEvent?.closingDate,
            showParticipantList: !!offerEvent?.showParticipantList || false,
            offerEventTitle: offerEvent?.title,
            type: offerEventType,
            scoreThreshold:
                offerEventType === IOfferEventType.Meeting ||
                offerEventType === IOfferEventType.Webinar
                    ? undefined
                    : offerEvent?.scoreThreshold || 0,
        },
        validation: {
            dates: datesValidation || undefined,
            minSubscriptions: Yup.number()
                .integer(translate('validate.integer'))
                .typeError(translate('validate.mustBeANumber'))
                .min(0, translate('validate.zeroOrPositive')),
            maxSubscriptions: Yup.number()
                .integer(translate('validate.integer'))
                .typeError(translate('validate.mustBeANumber'))
                .min(0, translate('validate.zeroOrPositive')),
        },
    };

    if (
        [IOfferEventType.Meeting, IOfferEventType.Webinar].includes(
            offerEventType
        )
    ) {
        return {
            initialValues: {
                minSubscriptions: offerEvent?.minSubscriptions || 0,
                ...commonValues.initialValues,
            },
            validation: commonValues.validation,
        };
    }

    if (offerEventType === IOfferEventType.Training) {
        return {
            initialValues: {
                showParticipantList:
                    commonValues.initialValues.showParticipantList,
                type: commonValues.initialValues.type,
                scoreThreshold: commonValues.initialValues.scoreThreshold,
            },
        };
    }

    return { initialValues: {}, validation: {} };
}

/*
 * Checks if a user can subscribe to an offer event
 */
export function getIsOfferEventSubscribable(
    offerEvent: IOfferEventDetailFragment,
    canUpdate: boolean,
    canManageSubscriptions?: boolean
) {
    const { currentUserEnrollment } = offerEvent;

    // If the user can update, can manage subscriptions or the user already has a subscription the user can't subscribe
    if (canUpdate || canManageSubscriptions || currentUserEnrollment) {
        return false;
    }

    const { type, closingDate } = offerEvent;
    const isTraining = type === IOfferEventType.Training;

    // Trainings can always be subscribed to
    // If closing date is in the past it is not subscribable any longer
    if (!isTraining && closingDate) {
        const closingDatePast = DateTime.fromISO(closingDate) < DateTime.now();

        if (closingDatePast) return false;
    }

    return true;
}

/*
 * Checks if a user can unsubscribe to an offer event
 */
export function getIsOfferEventUnsubscribable(
    type: IOfferEventType,
    canUpdate: boolean,
    closingDate?: string,
    canManageSubscriptions?: boolean
) {
    // If the user can update or can manage subscriptions the user can't unsubscribe
    if (canUpdate || canManageSubscriptions) return false;

    if (
        type === IOfferEventType.Training ||
        type === IOfferEventType.Elearning ||
        type === IOfferEventType.Course
    ) {
        return true;
    }

    // If closing date is in the past it is not unsubscribable any longer
    if (closingDate) {
        const closingDatePast = DateTime.fromISO(closingDate) < DateTime.now();

        if (closingDatePast) return false;
    }

    return true;
}

/*
 * Returns the label translations for the offer event labels
 */
export function getLabelTranslation(label: ILabels) {
    if (label === ILabels.Trainer) return translate('trainer');

    return '';
}

// Get the date from local storage if present
export function getOfferEventOverviewDefaultFilterDate() {
    const nowDate = DateTime.now();
    const defaultFilterDate = nowDate.startOf('day').minus({ months: 1 });

    const storageDateFilter = localStorage.getItem(
        'offerEventOverviewDateFilter'
    );
    const storageDateFilterParsed = storageDateFilter
        ? parseJSON(storageDateFilter)
        : undefined;

    if (!storageDateFilterParsed) return defaultFilterDate;

    const storageDate = DateTime.fromISO(storageDateFilterParsed.date);
    const storageSetDate = DateTime.fromISO(storageDateFilterParsed.setDate);

    // If there is no valid storage date or storage set date
    // If the storage setDate is more than a day old its considered expired
    // If storage date is a date in the future its invalid
    if (
        !storageDate.isValid ||
        !storageSetDate.isValid ||
        storageSetDate.diffNow('days').days < -1 ||
        storageDate.startOf('day') > nowDate
    ) {
        localStorage.removeItem('offerEventOverviewDateFilter');

        return defaultFilterDate;
    }

    return storageDate;
}

export function filterExtraCategoryValuesForOfferEvent(
    extraCategoryValues: IExtraCategoryValueFragment[]
) {
    return extraCategoryValues.filter((group) => {
        const { categoryType, showLabel } = group.category;

        return (
            categoryType !== IExtraCategoryOption.Boolean &&
            categoryType !== IExtraCategoryOption.Datetime &&
            showLabel
        );
    });
}
