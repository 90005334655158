import MuiTabs from '@mui/material/Tabs';
import { styled } from 'styled-components';

export const Tabs = styled(MuiTabs)`
    position: relative;

    &:after {
        background: ${({ theme }) => theme.palette.divider};
        position: absolute;
        height: 1px;
        content: '';
        right: 0;
        bottom: 0;
        left: 0;
    }
`;
