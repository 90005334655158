import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

import {
    IModuleType,
    useDeleteModuleMutation,
    useModuleEditQuery,
    useUpdateCertificateModuleMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleUpdateDrawerProps } from 'training/types';
import { Loader } from 'common/components/Loader';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { getImageId } from 'common/utils/image';
import { CertificateModuleForm } from 'training/components/forms';

import { ICertificateModuleFormProps } from '../forms/CertificateModuleForm';

export const CertificateModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();

    const { showApolloError } = useApolloError();

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.Certificate },
        onError: showApolloError,
    });

    const [updateModule] = useUpdateCertificateModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    const module = data?.module;

    if (loading || !module) return <Loader />;

    if (!module || module.__typename !== 'CertificateModule') return null;

    const handleSubmit: ICertificateModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { image, offset, certificate, ...otherValues } = values;
        const { image: currentImage } = module;
        const moduleValues = {
            ...otherValues,
            imageId: getImageId(currentImage, image),
            certificateId: certificate?.id || null,
        };

        try {
            await updateModule({
                variables: { id, module: moduleValues },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.certificate.update'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.Certificate,
                },
                update: (cache) => {
                    cache.evict({ id: `CertificateModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.certificate.delete'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    const {
        certificate,
        title,
        description,
        image,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        certificate: certificate || undefined,
        title,
        description,
        image: image || undefined,
    };

    return (
        <>
            <PageTitle>{translate('updateCertificateModule')}</PageTitle>

            <CertificateModuleForm
                edit
                initialValues={initialValues}
                moduleGroupId={moduleGroupId}
                onDelete={() => setShowDeleteAlert(true)}
                onSubmit={handleSubmit}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteCertificateModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteCertificateModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
