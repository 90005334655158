import { useEffect, useMemo } from 'react';

import { useVideoFilter } from 'video/hooks/useVideoFilter';
import { IVideoListItemFragment, useVideosQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { usePagination } from 'common/hooks/usePagination';

export const useVideoList = (filterParamsInUrl = true) => {
    const { showApolloError } = useApolloError();

    const {
        filters,
        filterValues,
        filterValuesForQuery,
        handleSearch: onSearch,
        handleFilterChange: onFilterChange,
        loading: loadingFilters,
    } = useVideoFilter(filterParamsInUrl);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    // Build variables for query
    const variables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
        }),
        [paginationVariables, filterValuesForQuery]
    );

    const { loading: videosLoading, data: videosData } = useVideosQuery({
        variables,
        onError: showApolloError,
    });

    const videosObject = videosData?.videos;
    const videosCount = videosObject?.count;
    const videos =
        (videosObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IVideoListItemFragment[]) || [];

    const loading = videosLoading || loadingFilters;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(videosCount || 0);
    }, [videosCount, loading, initializePagination]);

    return {
        videos,
        videosCount,
        loading,
        paginationSettings,
        filters,
        filterValues,
        onSearch,
        onFilterChange,
        setPage,
    };
};
