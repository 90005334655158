import { useEffect } from 'react';

import {
    IUserListItemFragment,
    IUserSelectorInput,
    IUserSelectorQueryVariables,
    useUserSelectorLazyQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export interface IUserList {
    users: IUserListItemFragment[];
    alreadyAddedUsers: string[];
    usersCount?: number | null;
    loading: boolean;
}

export const useUserList = (
    userQueryVariables?: IUserSelectorQueryVariables,
    skip?: boolean,
    selectorQueryVariables?: IUserSelectorInput
): IUserList => {
    const { showApolloError } = useApolloError();
    const [fetchUsers, { loading, data }] = useUserSelectorLazyQuery({
        onError: showApolloError,
    });

    useEffect(() => {
        if (!userQueryVariables || skip) return;

        fetchUsers({
            variables: {
                q: userQueryVariables.q,
                offset: userQueryVariables.offset,
                ...userQueryVariables,
                selector: selectorQueryVariables,
            },
        });
    }, [userQueryVariables, skip, selectorQueryVariables, fetchUsers]);

    const usersObject = data?.paginatedUsers;
    const usersCount = usersObject?.count;
    const users =
        (usersObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as IUserListItemFragment[]) || [];
    const alreadyAddedUsers =
        usersObject?.edges
            .map((edge) => {
                if (edge?.alreadyAddedToObject) return edge.node.id;

                return null;
            })
            .filter(Boolean) || [];

    return {
        users,
        alreadyAddedUsers,
        usersCount,
        loading,
    };
};
