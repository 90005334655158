import {
    isUrl,
    isBlockAboveEmpty,
    isSelectionAtBlockStart,
    ResetBlockTypePluginOptions,
} from '@udecode/slate-plugins';
import { TableRow, TableBody } from '@mui/material';

import { Typography } from 'common/components/Typography';
import { TableHead, TableCell } from 'common/components/Table';

import { EditorBlockquote } from './elements/blockquote';
import {
    EditorEmbed,
    BlockMenuEmbed,
    deserializeEmbed,
} from './elements/embed';
import { EditorFile, BlockMenuFile } from './elements/file';
import { EditorImage, BlockMenuImage } from './elements/image';
import { EditorLink } from './elements/link';
import { EditorTable, deserializeTable } from './elements/table';
import { EditorComponent } from './components/EditorComponent';
import { EditorLeaf } from './components/EditorLeaf';

export const COMPONENTS: {
    [key: string]: React.ComponentType<{
        className?: string;
        children?: React.ReactNode;
    }>;
} = {
    Typography,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
};

export type TComponents = keyof typeof COMPONENTS;

export const options = {
    bold: {
        component: EditorLeaf,
        type: 'bold',
        hotkey: 'mod+b',
        rootProps: {
            as: 'strong',
        },
    },
    italic: {
        component: EditorLeaf,
        type: 'italic',
        hotkey: 'mod+i',
        rootProps: {
            as: 'em',
        },
    },
    underline: {
        component: EditorLeaf,
        type: 'underline',
        hotkey: 'mod+u',
        rootProps: {
            as: 'u',
        },
    },
    superscript: {
        component: EditorLeaf,
        type: 'superscript',
        hotkey: 'mod+.',
        clear: 'subscript',
        rootProps: {
            as: 'sup',
        },
    },
    p: {
        component: EditorComponent,
        type: 'p',
        hotkey: ['mod+opt+0', 'mod+shift+0'],
        rootProps: {
            as: 'Typography',
        },
    },
    h1: {
        component: EditorComponent,
        type: 'h1',
        rootProps: {
            as: 'Typography',
            variant: 'h1',
            styles: {},
        },
    },
    h2: {
        component: EditorComponent,
        type: 'h2',
        rootProps: {
            as: 'Typography',
            variant: 'h2',
            styles: {},
        },
    },
    h3: {
        component: EditorComponent,
        type: 'h3',
        rootProps: {
            as: 'Typography',
            variant: 'h3',
            styles: {},
        },
    },
    h4: {
        component: EditorComponent,
        type: 'h4',
        rootProps: {
            as: 'Typography',
            variant: 'h4',
            styles: {},
        },
    },
    li: {
        component: EditorComponent,
        type: 'li',
        rootProps: {
            as: 'li',
        },
    },
    ul: {
        component: EditorComponent,
        type: 'ul',
        rootProps: {
            as: 'ul',
        },
    },
    ol: {
        component: EditorComponent,
        type: 'ol',
        rootProps: {
            as: 'ol',
        },
    },
    link: {
        component: EditorLink,
        type: 'a',
        attribute: 'url',
        isUrl,
    },
    blockquote: {
        type: 'blockquote',
        hotkey: 'mod+shift+.',
        component: EditorBlockquote,
    },
    table: {
        component: EditorTable,
        type: 'table',
        deserialize: deserializeTable,
    },
    tbody: {
        component: EditorComponent,
        type: 'tbody',
        rootProps: {
            as: 'TableBody',
        },
    },
    tr: {
        component: EditorComponent,
        type: 'tr',
        rootProps: {
            as: 'TableRow',
        },
    },
    th: {
        component: EditorComponent,
        type: 'th',
        rootProps: {
            as: 'TableHead',
        },
    },
    td: {
        component: EditorComponent,
        type: 'td',
        rootProps: {
            as: 'TableCell',
        },
    },
    img: {
        component: EditorImage,
        type: 'img',
        blockMenu: BlockMenuImage,
    },
    file: {
        component: EditorFile,
        type: 'file',
        blockMenu: BlockMenuFile,
    },
    media_embed: {
        component: EditorEmbed,
        type: 'media_embed',
        blockMenu: BlockMenuEmbed,
        deserialize: deserializeEmbed,
    },
};

const resetBlockTypesCommonRule = {
    types: [options.blockquote.type, options.file.type],
    defaultType: options.p.type,
};

export const optionsResetBlockTypes: ResetBlockTypePluginOptions = {
    rules: [
        {
            ...resetBlockTypesCommonRule,
            hotkey: 'Enter',
            predicate: isBlockAboveEmpty,
        },
        {
            ...resetBlockTypesCommonRule,
            hotkey: 'Backspace',
            predicate: isSelectionAtBlockStart,
        },
    ],
};
