import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';

import {
    IArticleFragment,
    IUpdateLibraryArticleInput,
    useUpdateArticleMutation,
    useDeleteArticleMutation,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { PageDrawer } from 'common/components/PageDrawer';
import { LibraryArticleForm } from 'library/components/forms/LibraryArticleForm';
import { ApolloError } from 'common/components/ApolloError';
import { AlertDialog } from 'common/components/AlertDialog';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

interface IProps {
    pageTitle?: string;
    open?: boolean;
    article: IArticleFragment;
    onUpdate(): void;
    onDelete(): void;
    onClose?(): void;
}

export const LibraryArticleDrawer = ({
    pageTitle,
    open,
    article,
    onUpdate,
    onDelete,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [showOnDeleteAlert, setShowOnDeleteAlert] = useState<boolean>(false);

    const [updateArticle, { error: updateError }] = useUpdateArticleMutation({
        onCompleted: () => {
            displaySnackbar(translate('libraryArticleActionSuccess.update'), {
                variant: 'success',
            });

            onUpdate();
        },
    });

    const [deleteArticle, { loading: deleteLoading, error: deleteError }] =
        useDeleteArticleMutation({
            update: (cache) => {
                // Remove article from cache
                cache.evict({ id: `LibraryArticle:${article.id}` });
                cache.gc();

                displaySnackbar(
                    translate('libraryArticleActionSuccess.delete'),
                    {
                        variant: 'success',
                    }
                );

                onDelete();
            },
        });

    const initialValues: IUpdateLibraryArticleInput = {
        title: article.title,
        description: article.description,
    };

    return (
        <PageDrawer open={open} title={article.title} onClose={onClose}>
            {updateError && <ApolloError error={updateError} />}
            {deleteError && <ApolloError error={deleteError} />}

            {pageTitle && (
                <PageTitle mixpanelTitle="Edit article - Library">{`${pageTitle} - ${translate(
                    'edit'
                )}`}</PageTitle>
            )}

            <LibraryArticleForm
                initialValues={initialValues}
                isDeleting={deleteLoading}
                onDelete={() => {
                    setShowOnDeleteAlert(true);
                }}
                onSubmit={(values) => {
                    const updatedArticle = values as IUpdateLibraryArticleInput;

                    return updateArticle({
                        variables: {
                            id: article.id,
                            article: updatedArticle,
                        },
                    });
                }}
            />

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={() => {
                                deleteArticle({
                                    variables: { id: article.id },
                                });
                            }}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowOnDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showOnDeleteAlert}
                title={translate('deleteArticleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteArticleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
