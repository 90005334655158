import { i18n } from '../../utils/i18n';

export enum EAuthError {
    AuthNotLoggedIn = 'AuthNotLoggedIn',
    AuthTwoFactorNotVerified = 'AuthTwoFactorNotVerified',
    AuthTwoFactorNotSetup = 'AuthTwoFactorNotSetup',
}

export const ERROR_MESSAGES = {
    General: i18n.t('errors.general'),
    AuthNotLoggedIn: i18n.t('errors.authNotLoggedIn'),
    AuthNotPermitted: i18n.t('errors.authNotPermitted'),
    BadRequest: i18n.t('errors.general'),
    ExclusiveFilter: i18n.t('errors.exclusiveFilter'),
    ExtraField: i18n.t('errors.extraField'),
    Fatal: i18n.t('errors.general'),
    NoSuchExtraField: i18n.t('errors.noSuchExtraField'),
    ValueErrorList: i18n.t('errors.general'),
    WrongTypeForExtraField: i18n.t('errors.wrongTypeForExtraField'),
    ArticleNotFound: i18n.t('errors.articleNotFound'),
    CollectionNotFound: i18n.t('errors.collectionNotFound'),
    HookSubscriptionNotFound: i18n.t('errors.hookSubscriptionNotFound'),
    HookSubscriptionTargetInvalid: i18n.t(
        'errors.hookSubscriptionTargetInvalid'
    ),
    TrainingNotFound: i18n.t('errors.trainingNotFound'),
    TrainingRefAlreadyUsed: i18n.t('errors.trainingRefAlreadyUsed'),
    GroupNotFound: i18n.t('errors.groupNotFound'),
    GroupRefAlreadyUsed: i18n.t('errors.groupRefAlreadyUsed'),
    UserEmailAlreadyUsed: i18n.t('errors.userEmailAlreadyUsed'),
    UserNotFound: i18n.t('errors.userNotFound'),
    UserRefAlreadyUsed: i18n.t('errors.userRefAlreadyUsed'),
    TokenInvalid: i18n.t('errors.tokenInvalid'),
    TokenExpired: i18n.t('errors.tokenExpired'),
    LimitExceeded: i18n.t('errors.limitExceeded'),
    CourseAlreadyInModuleGroup: i18n.t('errors.courseAlreadyInModuleGroup'),
    CourseUsedInTraining: i18n.t('errors.courseUsedInTraining'),
    CourseUsedInOffer: i18n.t('errors.courseUsedInOffer'),
    VideoAlreadyInModuleGroup: i18n.t('errors.videoAlreadyInModuleGroup'),
    VideoUsedInTraining: i18n.t('errors.videoUsedInTraining'),
    MaxUsersInGroupReached: i18n.t('errors.maxUsersInGroupReached'),
    ScormAlreadyInModuleGroup: i18n.t('errors.scormAlreadyInModuleGroup'),
    ScormUsedInTraining: i18n.t('errors.scormUsedInTraining'),
};
