import { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
    IExternalContentListItemFragment,
    IExternalContentsSelectorQueryVariables,
} from 'graphql/types';
import { ExternalContentListItem } from 'external/components/ExternalContentListItem';
import { Typography } from 'common/components/Typography';
import { useExternalContentList } from 'external/hooks';
import {
    getExternalContentSelectStatus,
    isExternalContentAdded,
    isExternalContentSelected,
} from 'external/utils/externalContentSelect';
import { AddButton } from 'common/components/Button/AddButton';

import { ExternalContentSelectDrawer } from './ExternalContentSelectDrawer';

interface IProps {
    isEditable?: boolean;
    label?: string;
    name?: string;
    noResultsLabel?: string;
    value: IExternalContentListItemFragment[];
}

export const ExternalContentSelector = ({
    isEditable = true,
    label,
    name,
    noResultsLabel,
    value,
}: IProps) => {
    const [translate] = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedExternalContents, setSelectedExternalContents] = useState<
        IExternalContentListItemFragment[]
    >([]);
    const [externalContentQueryVariables, setExternalContentQueryVariables] =
        useState<IExternalContentsSelectorQueryVariables | undefined>();

    const {
        externalContents,
        loading: externalContentsLoading,
        externalContentsCount,
    } = useExternalContentList(externalContentQueryVariables);

    const addedExternalContents = value;

    const handleDrawerOpen = (open: boolean) => {
        if (!open) setSelectedExternalContents([]);

        setDrawerOpen(open);
    };

    const handleSelectExternalContent = (
        externalContent: IExternalContentListItemFragment
    ) => {
        const newExternalContents = [
            ...selectedExternalContents,
            externalContent,
        ].sort((a, b) => (a.title > b.title ? 1 : -1));

        setSelectedExternalContents(newExternalContents);
    };

    const handleRemoveExternalContent = (
        externalContent: IExternalContentListItemFragment,
        arrayHelpers: FieldArrayRenderProps
    ) => {
        const { isSelected } = getExternalContentSelectStatus(
            selectedExternalContents,
            addedExternalContents,
            externalContent
        );

        if (isSelected) {
            const newSelectedExternalContent = selectedExternalContents.filter(
                (selectedExternalContent) =>
                    selectedExternalContent.id !== externalContent.id
            );

            setSelectedExternalContents(newSelectedExternalContent);

            return;
        }

        const index = addedExternalContents.findIndex(
            (addedExternalContent) =>
                addedExternalContent.id === externalContent.id
        );

        arrayHelpers.remove(index);
    };

    const handleAddExternalContents = (arrayHelpers: FieldArrayRenderProps) => {
        selectedExternalContents.forEach((selectedExternalContent) => {
            arrayHelpers.push(selectedExternalContent);
        });

        setSelectedExternalContents([]);

        handleDrawerOpen(false);
    };

    const fieldName = name || 'externalContents';

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => (
                <Box>
                    <Box
                        alignItems="left"
                        display="flex"
                        flexDirection="column"
                    >
                        {isEditable && (
                            <AddButton onClick={() => handleDrawerOpen(true)}>
                                <Typography>
                                    {label ||
                                        translate('addExternalContentsTitle')}
                                </Typography>
                            </AddButton>
                        )}

                        {!!addedExternalContents.length && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                mt={isEditable ? 2 : 0}
                            >
                                {addedExternalContents.map(
                                    (addedExternalContent) => (
                                        <ExternalContentListItem
                                            addButton={isEditable}
                                            added={isExternalContentAdded(
                                                addedExternalContents,
                                                addedExternalContent
                                            )}
                                            externalContent={
                                                addedExternalContent
                                            }
                                            key={`added-${addedExternalContent.id}`}
                                            selected={isExternalContentSelected(
                                                selectedExternalContents,
                                                addedExternalContent
                                            )}
                                            onClick={(
                                                externalContent: IExternalContentListItemFragment
                                            ) => {
                                                if (!isEditable) return;

                                                handleRemoveExternalContent(
                                                    externalContent,
                                                    arrayHelpers
                                                );
                                            }}
                                        />
                                    )
                                )}
                            </Box>
                        )}

                        {!isEditable && !addedExternalContents.length && (
                            <Box>
                                <Typography>
                                    {noResultsLabel ||
                                        translate(
                                            'noOptionsText.externalContents'
                                        )}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <ExternalContentSelectDrawer
                        addedExternalContents={addedExternalContents}
                        externalContents={externalContents}
                        externalContentsCount={
                            externalContentsCount || undefined
                        }
                        loading={externalContentsLoading}
                        open={drawerOpen}
                        selectedExternalContents={selectedExternalContents}
                        onAddExternalContents={() =>
                            handleAddExternalContents(arrayHelpers)
                        }
                        onDrawerOpen={handleDrawerOpen}
                        onRemoveExternalContent={(externalContent) =>
                            handleRemoveExternalContent(
                                externalContent,
                                arrayHelpers
                            )
                        }
                        onSelectExternalContent={handleSelectExternalContent}
                        onSetExternalContentQueryVariables={
                            setExternalContentQueryVariables
                        }
                    />
                </Box>
            )}
        />
    );
};
