import { mdiDotsVertical } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
    DropdownMenu,
    IDropdownMenuProps,
} from 'common/components/DropdownMenu';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';

interface IProps extends Omit<IDropdownMenuProps, 'anchor'> {
    children: React.ReactNode;
}

export const DrawerActionDropdown = (props: IProps) => {
    const [translate] = useTranslation();

    return (
        <DropdownMenu
            anchor={
                <Button
                    size="small"
                    startIcon={<Icon path={mdiDotsVertical} size="1.9rem" />}
                    variant="outlined"
                >
                    {translate('actions')}
                </Button>
            }
            {...props}
        />
    );
};
