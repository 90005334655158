import { Helmet } from 'react-helmet';

import { i18n } from 'utils/i18n';
import pluvoFavicon from 'assets/images/favicon.webp';
import pluvoIcon from 'assets/images/logo192.webp';
import { useOrganisationQuery } from 'graphql/types';

export const HelmetBase = () => {
    const { data: organisationData, loading: loadingOrg } =
        useOrganisationQuery();

    const { organisation } = organisationData || {};
    const orgName = organisation?.name;

    const faviconUrl = organisation?.favicon?.url;

    // Create array of icons. We use an array here because fragments don't work in react-helmet
    const icons = faviconUrl
        ? [
              <link href={faviconUrl} key="1" rel="icon" />,
              <link href={faviconUrl} key="2" rel="apple-touch-icon" />,
              <link href={faviconUrl} key="3" rel="mask-icon" />,
              <link href={faviconUrl} key="4" rel="shortcut icon" />,
          ]
        : [
              <link href={pluvoFavicon} key="1" rel="icon" />,
              <link href={pluvoIcon} key="2" rel="apple-touch-icon" />,
              <link href={pluvoIcon} key="3" rel="mask-icon" />,
              <link href={pluvoIcon} key="4" rel="shortcut icon" />,
          ];

    return (
        <Helmet htmlAttributes={{ lang: i18n.language }}>
            {orgName && <title>{orgName}</title>}
            {!loadingOrg && icons}
        </Helmet>
    );
};
