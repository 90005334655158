import { Box, BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withSxProp } from 'common/utils/props';
import { Typography } from 'common/components/Typography';

interface IProps extends BoxProps {
    transKey: string;
}

export const NoResultsText = ({ transKey, sx, ...other }: IProps) => {
    const [translate] = useTranslation();

    return (
        <Box
            sx={[
                { mt: 6, textAlign: 'center' },
                // Spread sx from props
                ...withSxProp(sx),
            ]}
            {...other}
        >
            <Typography>{translate(transKey)}</Typography>
        </Box>
    );
};
