import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateLinkModuleMutation } from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    LinkModuleForm,
    ILinkModuleFormProps,
} from 'training/components/forms/LinkModuleForm';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { createModuleInCache } from 'training/utils/module';

export const LinkModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();

    const [createModule] = useCreateLinkModuleMutation();

    const handleSubmit: ILinkModuleFormProps['onSubmit'] = async (values) => {
        const { image, url, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            // The protocol is removed by the field so we always want to add https:// here.
            url: `https://${url}`,
            imageId: image?.id,
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
        // in the training which will make a new moduleGroup
        const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

        try {
            await createModule({
                variables: { module: moduleValues, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createLinkModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Create link module in drawer',
        });

        displaySnackbar(translate('moduleActionSuccess.link.create'), {
            variant: 'success',
        });

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    return (
        <>
            <PageTitle mixpanelTitle="Drawer create link module">
                {translate('newLinkModule')}
            </PageTitle>
            <LinkModuleForm
                moduleGroupId={moduleGroupId}
                onSubmit={handleSubmit}
            />
        </>
    );
};
