import { Fragment } from 'react';
import { Box, FormHelperText, Grid } from '@mui/material';
import { mdiClose } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import { DateTime } from 'luxon';

import { IOfferEventDateInput, IOfferEventDate } from 'graphql/types';
import { getRoundedDateTime } from 'common/utils/formatDate';
import { IconButton } from 'common/components/IconButton';
import { DateTimePicker } from 'common/components/FormField';
import { Divider } from 'common/components/Divider';

import { AddButton } from '../Button/AddButton';
import { Typography } from '../Typography';

interface IProps {
    extraFields?(
        dateFieldName: string,
        index: number,
        // Date passed to extraFields with a parsed startDate and endDate
        date: Omit<IOfferEventDateInput, 'startDate' | 'endDate'> & {
            startDate?: DateTime;
            endDate?: DateTime;
        }
    ): React.ReactNode;
    extraFieldsInitialValues?: Partial<IOfferEventDate>;
}

export const MultipleDateRangeFields = ({
    extraFields,
    extraFieldsInitialValues,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleFieldChange = (
        arrayHelpers: FieldArrayRenderProps,
        dates: IOfferEventDateInput[],
        index: number,
        newStartDate: DateTime
    ) => {
        let newEndDate: DateTime = dates[index].endDate;

        if (newStartDate?.isValid) {
            const start = newStartDate;
            const end = newEndDate;

            // When start is equal or higher as end, we change end to be start + 1 hour
            if (start >= end) {
                newEndDate = start.plus({ hours: 1 });
            }
        }

        arrayHelpers.replace(index, {
            ...dates[index],
            startDate: newStartDate,
            endDate: newEndDate,
        });
    };

    return (
        <Box>
            <FieldArray
                name="dates"
                render={(arrayHelpers) => {
                    const { name, form } = arrayHelpers;

                    const error = getIn(form.errors, name);

                    // We only want to catch the main errors for the field so we check if the error is a string
                    const dateArrayErrors =
                        form.touched && error && typeof error === 'string' ? (
                            <FormHelperText error>{error}</FormHelperText>
                        ) : null;

                    const dates = form.values.dates as IOfferEventDate[];

                    return (
                        <Box>
                            {dates?.map((dateField, index) => {
                                const startDate = dateField.startDate;
                                const endDate = dateField.endDate;

                                const minEndDate = startDate
                                    ? startDate.plus({ hours: 1 })
                                    : undefined;

                                const hasMultipleDates = dates.length > 1;

                                return (
                                    <Fragment key={`dateRow-${index}`}>
                                        <Divider sx={{ my: 2 }} />

                                        <Grid container alignItems="center">
                                            <Grid item sm>
                                                <Typography
                                                    sx={{ fontWeight: 'bold' }}
                                                    variant="h3"
                                                >
                                                    {translate('dateNumber', {
                                                        number: index + 1,
                                                    })}
                                                </Typography>
                                            </Grid>
                                            {hasMultipleDates && (
                                                <Grid item>
                                                    <IconButton
                                                        iconPath={mdiClose}
                                                        iconSize="2.4rem"
                                                        size="large"
                                                        onClick={() => {
                                                            arrayHelpers.remove(
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                sm
                                                xs={hasMultipleDates ? 5 : 6}
                                            >
                                                <Field
                                                    component={DateTimePicker}
                                                    label={translate(
                                                        'startDate'
                                                    )}
                                                    name={`dates[${index}].startDate`}
                                                    onChange={(
                                                        newStartDate: DateTime
                                                    ) =>
                                                        handleFieldChange(
                                                            arrayHelpers,
                                                            dates,
                                                            index,
                                                            newStartDate
                                                        )
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                sm
                                                sx={{ pl: 2 }}
                                                xs={hasMultipleDates ? 5 : 6}
                                            >
                                                <Field
                                                    component={DateTimePicker}
                                                    label={translate('endDate')}
                                                    minDate={minEndDate}
                                                    name={`dates[${index}].endDate`}
                                                />
                                            </Grid>
                                        </Grid>

                                        {!!extraFields &&
                                            extraFields(
                                                `dates[${index}]`,
                                                index,
                                                {
                                                    ...dates[index],
                                                    startDate:
                                                        startDate?.isValid
                                                            ? startDate
                                                            : undefined,
                                                    endDate: endDate?.isValid
                                                        ? endDate
                                                        : undefined,
                                                }
                                            )}
                                    </Fragment>
                                );
                            })}

                            <Box
                                alignItems="center"
                                display="flex"
                                mb={1}
                                mt={2}
                            >
                                <AddButton
                                    onClick={() => {
                                        arrayHelpers.push({
                                            startDate: getRoundedDateTime(),
                                            endDate: getRoundedDateTime().plus({
                                                hours: 1,
                                            }),
                                            ...extraFieldsInitialValues,
                                        });
                                    }}
                                >
                                    <Typography>
                                        {translate('addDate')}
                                    </Typography>
                                </AddButton>
                            </Box>

                            {dateArrayErrors}
                        </Box>
                    );
                }}
            />
        </Box>
    );
};
