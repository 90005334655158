import { ILocationStatus } from 'graphql/types';
import { translate } from 'utils/i18n';

export function getLocationStatusString(status?: ILocationStatus) {
    let transKey = '';

    switch (status) {
        case ILocationStatus.Available:
            transKey = 'available';
            break;
        case ILocationStatus.Tentative:
            transKey = 'tentative';
            break;
        case ILocationStatus.Unavailable:
            transKey = 'unavailable';
            break;
    }

    if (!transKey) return '';

    return translate(`locationStatus.${transKey}`);
}
