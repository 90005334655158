import { mdiChevronRight, mdiShieldCheck } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
    ListItemFooter,
} from 'common/components/ListItem';
import { IExtendedCertificateFragment } from 'graphql/types';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    selected?: boolean;
    certificate?: IExtendedCertificateFragment;
    footer?: React.ReactNode;
}

export const CertificateListItem = ({
    addButton,
    added,
    disabled,
    selected,
    certificate,
    footer,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { id, title, thumbnail, file, training } = certificate || {};
    const { title: trainingTitle } = training || {};

    const hasIcon = (disabled && !thumbnail) || !disabled;

    return (
        <ListItem
            button={!disabled && !!file?.url}
            key={id}
            px={0}
            selected={selected}
            sx={{
                flexWrap: !!footer ? 'wrap' : undefined,
            }}
            onClick={() =>
                !disabled && file?.url && window.open(file.url, '_blank')
            }
            {...other}
        >
            <ListItemMedia
                color="primary"
                image={thumbnail?.url || ''}
                size="small"
            >
                {hasIcon && <Icon path={mdiShieldCheck} size="3rem" />}
            </ListItemMedia>

            <ListItemText
                primary={
                    !!certificate
                        ? trainingTitle
                        : translate('certificateNotAvailable')
                }
                secondary={!!certificate && title}
            />

            {!addButton && !disabled && file?.url && (
                <>
                    <ListItemActionText>{translate('open')}</ListItemActionText>
                    <ListItemSecondaryAction>
                        <Icon path={mdiChevronRight} size="3rem" />
                    </ListItemSecondaryAction>
                </>
            )}

            {footer && !!certificate && (
                <ListItemFooter>{footer}</ListItemFooter>
            )}
        </ListItem>
    );
};
