import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    useCreateCourseMutation,
    useCourseManagerTokenLazyQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useFrontendPermissions } from 'user/hooks';
import { LoaderOverlay } from 'common/components/Loader';
import { CourseCreateModal } from 'training/components/CourseCreateModal';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

interface IChildrenParams {
    openCreateModal(): void;
    createCourse(templateId?: string): void;
}

interface IProps {
    children(params: IChildrenParams): React.ReactNode;
    onCourseReturnUrl?(courseId: string): string; // Url to redirect to after course editing in elearning tool
}

export const CourseCreateAction = ({ children, onCourseReturnUrl }: IProps) => {
    const [translate] = useTranslation();
    const isCourseCreate = !!useRouteMatch('COURSE_CREATE');

    const [showModal, setShowModal] = useState(isCourseCreate);
    const { showApolloError } = useApolloError();

    const [createCourse, { loading: loadingCreate }] = useCreateCourseMutation({
        onError: showApolloError,
    });

    const { canCreate: canCreateCourse, loading: loadingPermissions } =
        useFrontendPermissions('course');

    const [
        getCourseManagerToken,
        { called: managerTokenCalled, error: managerTokenError },
    ] = useCourseManagerTokenLazyQuery();

    const handleCreateCourse = async (templateId?: string) => {
        let url;

        try {
            const courseResponse = await createCourse({
                variables: { course: { templateId } },
            });
            const courseId = courseResponse.data?.createCourse?.course.id;

            if (!courseId) return;

            const returnUrl = onCourseReturnUrl?.(courseId);
            const tokenResponse = await getCourseManagerToken({
                variables: { id: courseId, returnUrl },
            });

            url = tokenResponse.data?.courseManagerToken?.url;
        } catch (error) {
            showApolloError(error);

            return;
        }

        if (!url) return;

        // Redirect to edit course
        window.location.href = url;
    };

    if (!loadingPermissions && !canCreateCourse) return null;

    return (
        <>
            {children({
                openCreateModal: () => setShowModal(true),
                createCourse: handleCreateCourse,
            })}

            {showModal && (
                <CourseCreateModal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    onCreateCourse={handleCreateCourse}
                />
            )}

            <LoaderOverlay
                fadeIn
                visible={
                    loadingCreate ||
                    // Use "called" + "error" check here to prevent it from hidding while it's
                    // still busy with the redirect
                    (managerTokenCalled && !managerTokenError)
                }
            >
                {translate('courseCreateModal.creatingCourse')}
            </LoaderOverlay>
        </>
    );
};
