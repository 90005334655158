import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
    useGenerateCertificateMutation,
    ICertificateInputOptionFragment,
} from 'graphql/types';
import { TextField } from 'common/components/FormField';
import { Typography } from 'common/components/Typography';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { CertificateSelectField } from 'training/components/CertificateSelectField';

type TCertificateGenerateDialogFormValues = {
    certificate: ICertificateInputOptionFragment | null;
    title: string;
};

interface IProps {
    userIds: string[];
    open: boolean;
    onClose(): void;
}

export const CertificateGenerateDialog = ({
    userIds,
    open,
    onClose,
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();

    const { trainingId } = useParams() as { trainingId?: string };

    const [generateCertificate, { loading: loadingGenerateCertificate }] =
        useGenerateCertificateMutation({
            onError: showApolloError,
        });

    if (!open) return null;

    const initialValues: TCertificateGenerateDialogFormValues = {
        certificate: null,
        title: '',
    };

    const validationSchema = Yup.object().shape({
        certificate: Yup.object()
            .nullable()
            .required(translate('validate.required')),
    });

    const certificateCount = userIds.length;

    const handleGenerateCertificate = async (
        values: TCertificateGenerateDialogFormValues
    ) => {
        const { certificate, title } = values;

        if (!certificate) return;

        await generateCertificate({
            variables: {
                id: certificate.id,
                title,
                userIds,
                trainingId,
            },
        });

        displaySnackbar(
            translate('certificateGenerateDialog.success', {
                count: certificateCount,
            }),
            {
                variant: 'success',
            }
        );

        onClose?.();
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                await handleGenerateCertificate(values);

                actions.resetForm();
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <ConfirmDialog
                    fullWidth
                    confirmText={translate(
                        'certificateGenerateDialog.confirmText',
                        {
                            count: certificateCount,
                        }
                    )}
                    loading={isSubmitting || loadingGenerateCertificate}
                    maxWidth="sm"
                    open={open}
                    title={translate('certificateGenerateDialog.title', {
                        count: certificateCount,
                    })}
                    onCancel={onClose}
                    onClose={onClose}
                    onConfirm={submitForm}
                >
                    <Typography sx={{ mb: 1 }}>
                        {translate('certificateGenerateDialog.text', {
                            count: certificateCount,
                        })}
                    </Typography>

                    <Form>
                        <CertificateSelectField name="certificate" />

                        {!trainingId && (
                            <Field
                                component={TextField}
                                label={translate('title')}
                                name="title"
                            />
                        )}
                    </Form>
                </ConfirmDialog>
            )}
        </Formik>
    );
};
