import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    useUpdateVideoMutation,
    useVideoEditLazyQuery,
    useDeleteVideoMutation,
    VideoDocument,
    IVideoEditFragment,
    IContentTypeValue,
} from 'graphql/types';
import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { Loader } from 'common/components/Loader';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { PageTitle } from 'common/components/PageTitle';
import { VideoForm, TVideoFormValues } from 'video/components/forms/VideoForm';
import { getImageId } from 'common/utils/image';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { AuthorButton } from 'user/components/AuthorButton';
import { getExtraCategoryInitialFormValues } from 'organisation/utils/extraCategory';
import { useExtraCategoryValues } from 'common/hooks/useExtraCategoryValues';

interface IProps extends IPageDrawerProps {
    id?: string;
    onClose?(): void;
}

export const VideoUpdateDrawer = ({ id, open, onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const [video, setVideo] = useState<IVideoEditFragment>();

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const [getVideo, { loading: loadingVideo }] = useVideoEditLazyQuery({
        onCompleted: (data) => {
            const video = data?.video;

            if (!video) return;

            setVideo(video);
        },
    });
    const [updateVideo, { loading: loadingUpdate }] = useUpdateVideoMutation();
    const [deleteVideo, { loading: loadingDelete }] = useDeleteVideoMutation();
    const {
        extraCategories,
        loading: extraCategoriesLoading,
        handleUpdateExtraCategoryValues,
    } = useExtraCategoryValues({
        contentType: IContentTypeValue.Video,
        modelId: video?.id || '',
        skipQuery: !video?.id,
        typeNames: ['Video'],
    });

    useEffect(() => {
        if (!open || !id) return;

        getVideo({ variables: { id } });
    }, [id, open, getVideo]);

    if (loadingVideo || !video || extraCategoriesLoading) {
        return (
            <PageDrawer open={open}>
                <Loader />
            </PageDrawer>
        );
    }

    const { title, thumbnail, pluvoEmbedUrl, extraCategoryValues } = video;

    const initialValues: TVideoFormValues = {
        title: title || '',
        videoUrl: pluvoEmbedUrl || '',
        thumbnail,
        extraCategories: getExtraCategoryInitialFormValues(
            extraCategories,
            extraCategoryValues
        ),
    };

    const handleClose = () => {
        // Unset video on close, this is needed to make sure we don't render the previous video when opening
        // the drawer. We do this with a delay to make sure the drawer is closed before unsetting the video
        setTimeout(() => {
            setVideo(undefined);
        }, 225);

        onClose?.();
    };

    const handleSubmit = async (values: TVideoFormValues) => {
        const {
            videoUrl,
            thumbnail,
            extraCategories: extraCategoryUpdateValues,
            ...otherValues
        } = values;
        const { thumbnail: currentThumbnail } = video;

        const newValues = {
            ...otherValues,
            thumbnailId: getImageId(currentThumbnail, thumbnail),
        };

        try {
            await updateVideo({
                variables: {
                    id,
                    video: newValues,
                },
            });

            await handleUpdateExtraCategoryValues(
                extraCategoryValues,
                extraCategoryUpdateValues
            );
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('videoActionSuccess.update'), {
            variant: 'success',
        });

        // Close drawer
        handleClose();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteVideo({
                variables: { id },
                update: (cache) => {
                    // Remove videos list from cache so it will be refetched
                    cache.evict({ fieldName: 'videos' });

                    // Use writeQuery to update video query cache so it's properly removed
                    cache.writeQuery({
                        query: VideoDocument,
                        data: { video: null },
                        variables: { id },
                    });

                    cache.gc();
                },
            });
        } catch (error) {
            setShowDeleteAlert(false);
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Delete video in drawer',
        });

        displaySnackbar(translate('moduleActionSuccess.video.delete'), {
            variant: 'success',
        });

        setShowDeleteAlert(false);

        // Close drawer
        handleClose();

        return;
    };

    const isMutating = loadingUpdate || loadingDelete || extraCategoriesLoading;

    const drawerActions = !!id && (
        <AuthorButton contentType={IContentTypeValue.Video} objectId={id} />
    );

    return (
        <PageDrawer
            actions={drawerActions}
            disableClose={isMutating}
            open={open}
            onClose={handleClose}
            {...other}
        >
            <PageTitle mixpanelTitle="Drawer Video form">
                {translate('videoForm.pageTitle')}
            </PageTitle>

            <VideoForm
                initialValues={initialValues}
                video={video}
                onDelete={() => setShowDeleteAlert(true)}
                onSubmit={handleSubmit}
            />

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDelete}
                open={showDeleteAlert}
                title={translate('deleteVideoMessage.title')}
                onCancel={() => {
                    setShowDeleteAlert(false);
                }}
                onClose={() => setShowDeleteAlert(false)}
                onConfirm={handleDelete}
            >
                {translate('deleteVideoMessage.text')}
            </ConfirmDialog>
        </PageDrawer>
    );
};
