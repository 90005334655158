import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePagination } from 'common/hooks/usePagination';
import { FilterBar } from 'common/components/FilterBar';
import { BoxLoader } from 'common/components/Loader';
import {
    useExternalContentSearch,
    useExternalContentList,
} from 'external/hooks';
import { IExternalContentListItemFragment } from 'graphql/types';
import { Pagination } from 'common/components/Pagination';
import { Divider } from 'common/components/Divider';
import { ExternalContentAddList } from 'external/components/ExternalContentAddList';
import { useExternalContentQueryVariables } from 'external/hooks/useExternalContentQueryVariables';
import { TFilterBarItem } from 'common/types';

interface IProps {
    selectMultiple?: boolean;
    onSelect?(externalContent: IExternalContentListItemFragment): void;
    selectedIds?: string[];
    listItemProps?: React.ComponentProps<
        typeof ExternalContentAddList
    >['listItemProps'];
}

export const ExternalContentFilterAddList = ({
    selectMultiple = true,
    onSelect,
    listItemProps,
}: IProps) => {
    const [translate] = useTranslation();
    const [selectedExternalContents, setSelectedExternalContents] = useState<
        IExternalContentListItemFragment[]
    >([]);

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useExternalContentSearch();

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { queryVariables } = useExternalContentQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const {
        externalContents,
        loading: externalContentsLoading,
        externalContentsCount,
    } = useExternalContentList(queryVariables);

    const handleSelectExternalContent = (
        externalContent: IExternalContentListItemFragment
    ) => {
        onSelect?.(externalContent);

        if (!selectMultiple) return;

        const newSelectedExternalContent = [
            ...selectedExternalContents,
            externalContent,
        ];
        setSelectedExternalContents(newSelectedExternalContent);
    };

    const handleRemoveExternalContent = (
        externalContent: IExternalContentListItemFragment
    ) => {
        if (!selectMultiple) return;

        const newSelectedExternalContent = selectedExternalContents.filter(
            (selectedExternalContent) =>
                selectedExternalContent.id !== externalContent.id
        );

        setSelectedExternalContents(newSelectedExternalContent);
    };
    useEffect(() => {
        if (externalContentsLoading || !initializePagination) return;

        initializePagination(externalContentsCount || 0);
    }, [externalContentsCount, externalContentsLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    const loading = filtersLoading || externalContentsLoading;

    return (
        <>
            <FilterBar
                disabled={loading}
                filters={filters}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
                onSelect={handleFilterSelect}
            />
            {loading ? (
                <BoxLoader />
            ) : (
                <>
                    <ExternalContentAddList
                        addedExternalContents={selectedExternalContents}
                        externalContents={externalContents}
                        listItemProps={{
                            ...listItemProps,
                            addButton: selectMultiple,
                        }}
                        selectedExternalContents={selectedExternalContents}
                        onAddExternalContent={handleSelectExternalContent}
                        onRemoveExternalContent={handleRemoveExternalContent}
                    />

                    {paginationSettings.count > -1 && (
                        <Box mt={2}>
                            {!!paginationSettings.count && <Divider />}

                            <Box mt={2}>
                                <Pagination
                                    page={paginationSettings.page}
                                    pageAmount={paginationSettings.pageAmount}
                                    totalsAmount={paginationSettings.count}
                                    totalsText={
                                        paginationSettings.count === 1
                                            ? translate('externalContent')
                                            : translate('externalContents')
                                    }
                                    onChange={(page: number) => {
                                        setPage(page);
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
