import { useTranslation } from 'react-i18next';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import {
    ExtraCategoryFragmentDoc,
    IContentTypeValue,
    IExtraCategoryOption,
    IExtraCategoryCreateInput,
    useCreateExtraCategoryMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { ExtraCategoryForm } from 'organisation/components/forms/ExtraCategoryForm';
import { formatColor } from 'common/utils/color';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const ExtraCategoryCreateDrawer = ({ onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [createExtraCategory, { loading: loadingCreate }] =
        useCreateExtraCategoryMutation();

    const handleSubmit = async (values: IExtraCategoryCreateInput) => {
        const { choiceOptions, filter, showLabel, color, ...otherValues } =
            values;

        let newValues: IExtraCategoryCreateInput = {
            color: formatColor(color),
            ...otherValues,
        };

        const isChoiceType =
            newValues.categoryType === IExtraCategoryOption.Choice;

        if (isChoiceType) {
            const newChoiceOptions = choiceOptions?.map((option) => ({
                ...option,
                color: formatColor(option.color),
            }));

            newValues = { ...newValues, choiceOptions: newChoiceOptions };
        }

        const isCheckboxType =
            newValues.categoryType === IExtraCategoryOption.Boolean;
        const isDateType =
            newValues.categoryType === IExtraCategoryOption.Datetime;

        // Checkboxes and date types are not used as label or filter
        if (!isCheckboxType && !isDateType) {
            newValues = { ...newValues, filter, showLabel };
        }

        try {
            await createExtraCategory({
                variables: { extraCategoryInput: newValues },
                update: (cache, result) => {
                    const newExtraCategory =
                        result.data?.createExtraCategory?.extraCategory;

                    if (!newExtraCategory) return;

                    cache.modify({
                        fields: {
                            extraCategories(existingExtraCategoryRefs = []) {
                                const newExtraCategoryRef = cache.writeFragment(
                                    {
                                        fragment: ExtraCategoryFragmentDoc,
                                        fragmentName: 'ExtraCategory',
                                        data: newExtraCategory,
                                    }
                                );

                                return [
                                    ...existingExtraCategoryRefs,
                                    newExtraCategoryRef,
                                ];
                            },
                        },
                    });
                },
            });

            displaySnackbar(
                translate(
                    'extraCategoriesDrawer.extraCategoryActionSuccess.create'
                ),
                {
                    variant: 'success',
                }
            );

            onClose?.();
        } catch (error) {
            showApolloError(error);
        }

        return;
    };

    const initialValues: IExtraCategoryCreateInput = {
        name: '',
        categoryType: IExtraCategoryOption.Choice,
        forModelTypes: [
            IContentTypeValue.Group,
            IContentTypeValue.Offer,
            IContentTypeValue.Course,
            IContentTypeValue.Video,
        ],
        choiceOptions: [],
        multipleChoices: false,
        showLabel: false,
        filter: false,
    };

    return (
        <PageDrawer onClose={onClose} {...other}>
            <PageTitle mixpanelTitle="Drawer extra category create">
                {`${translate('extraCategoriesDrawer.pageTitle')} - ${translate(
                    'extraCategoriesDrawer.newExtraCategory'
                )}`}
            </PageTitle>

            <ExtraCategoryForm
                disabled={loadingCreate}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </PageDrawer>
    );
};
