import { Scalars } from 'graphql/types';

export function reorder<T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] {
    const newList = [...list];
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    return newList;
}

export const findNestedDraggable = <T>(
    groups: T[],
    nestedField: keyof T,
    id: Scalars['UUID']['output']
) => {
    for (const group of groups) {
        const nested = group[nestedField];

        if (Array.isArray(nested)) {
            for (const item of nested) {
                if (item.id === id) return item;
            }
        }
    }
};
