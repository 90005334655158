import { Box } from '@mui/material';

import {
    useExtraUserFieldsQuery,
    IBasicUserFragment,
    IUserExtraFieldFragment,
} from 'graphql/types';
import { formatDate } from 'common/utils/formatDate';
import { Chip, ChipGroup } from 'common/components/Chip';
import { Tooltip } from 'common/components/Tooltip';

interface IProps {
    user: IBasicUserFragment;
}

export const UserExtraFieldsChips = ({ user }: IProps) => {
    const { data: extraUserFieldsData } = useExtraUserFieldsQuery();

    const { extraFields } = user;

    // Reduce extraUserfields array to object
    const extraUserFields: { [key: string]: IUserExtraFieldFragment } = (
        extraUserFieldsData?.extraUserFields || []
    ).reduce((acc, field) => ({ ...acc, [field.name]: field }), {});

    if (!extraFields || !extraUserFields) return null;

    const userExtraFields: Record<string, string> = JSON.parse(extraFields);

    return (
        <ChipGroup>
            {Object.keys(userExtraFields).map((key) => {
                const value = userExtraFields[key];
                const fieldData = extraUserFields[key];

                // Don't render chip when we have no value or the field isn't public
                if (!value || !fieldData || !fieldData.public) return null;

                const labels: string[] = [];

                if (fieldData.__typename === 'UserExtraDateField') {
                    const date = formatDate(value);
                    if (!date) return;

                    labels.push(date);
                } else if (
                    fieldData.__typename === 'UserExtraChoiceField' &&
                    Array.isArray(value)
                ) {
                    labels.push(...value);
                } else {
                    labels.push(value);
                }

                return labels.map((label, index) => (
                    <Tooltip key={index} title={key}>
                        <Box>
                            <Chip label={label} />
                        </Box>
                    </Tooltip>
                ));
            })}
        </ChipGroup>
    );
};
