import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';

import { FilterBar } from 'common/components/FilterBar';
import {
    IExtraCategorySearch,
    IOfferEventType,
    IOfferRole,
} from 'graphql/types';
import {
    useOfferEventOverviewList,
    useOfferEventOverviewQueryVariables,
    useOfferEventSearch,
} from 'offer/hooks';
import { usePagination } from 'common/hooks/usePagination';
import { OFFER_EVENT_LIST_AMOUNT } from 'training/constants/pagination';
import { EFilterType, ITypeFilter } from 'offer/constants/offer';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType, TDateRange, TFilterBarItem } from 'common/types';
import { Pagination } from 'common/components/Pagination';
import { Divider } from 'common/components/Divider';
import { BoxLoader } from 'common/components/Loader';
import { NoResults } from 'common/components/NoResults';
import { DateRangeFilter } from 'common/components/filters';
import { OfferEventOverviewListItem } from 'offer/components/OfferEventOverviewListItem';
import { getOfferEventOverviewDefaultFilterDate } from 'offer/utils/offerEvent';

interface IProps {
    typeFilter?: ITypeFilter;
    viewToggle?: React.ReactNode;
}

export const OfferEventListView = ({ typeFilter, viewToggle }: IProps) => {
    const [translate] = useTranslation();
    const location = useLocation();
    const [extraCategories, setExtraCategories] = useState<
        IExtraCategorySearch[]
    >([]);
    const { openGlobalDrawer } = useGlobalDrawer();
    const {
        filters,
        searchQueryParam,
        selectedFilters,
        filtersLoading,
        handleSearch: onSearch,
        handleFilterSelect: onFilterSelect,
    } = useOfferEventSearch(extraCategories);

    const defaultFilterDate = DateTime.now()
        .startOf('day')
        .minus({ months: 1 });

    const [dateFilter, setDateFilter] = useState<TDateRange>({
        startDate: getOfferEventOverviewDefaultFilterDate(),
        endDate: undefined,
    });

    const { paginationSettings, initializePagination, setPage } = usePagination(
        true,
        {
            first: OFFER_EVENT_LIST_AMOUNT,
        },
        true
    );

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const isELearning = typeFilter?.key === EFilterType.elearning;
    const isMeetingWebinar = typeFilter?.key === EFilterType.meetingWebinar;

    const { offerQueryVariables } = useOfferEventOverviewQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
        otherFilters: isMeetingWebinar
            ? { fromDate: dateFilter?.startDate }
            : {},
    });

    const {
        offerEvents,
        loading: loadingOfferEvents,
        offerEventsCount,
        extraCategoryQueryFilters,
    } = useOfferEventOverviewList({
        ...offerQueryVariables,
        types: typeFilter?.types || [IOfferEventType.Training],
    });

    useEffect(() => {
        const currentFilterDate = dateFilter.startDate;

        // Its always set however the type states it can be undefined
        // This is because it uses the type of the date range filter component
        // In the future it might be possible to set a date range
        // If the date equals the default date we don't save it
        if (!currentFilterDate || currentFilterDate.equals(defaultFilterDate)) {
            return;
        }

        const dateFilterStorageItem = {
            date: dateFilter.startDate,
            setDate: DateTime.now(),
        };

        localStorage.setItem(
            'offerEventOverviewDateFilter',
            JSON.stringify(dateFilterStorageItem)
        );
    }, [dateFilter?.startDate]);

    // Reset pagination when tab page type changes
    useEffect(() => {
        setPage(1);
    }, [typeFilter?.key]);

    useEffect(() => {
        if (loadingOfferEvents || !initializePagination) return;

        initializePagination(offerEventsCount || 0);
    }, [offerEventsCount, loadingOfferEvents, initializePagination]);

    useEffect(() => {
        if (
            JSON.stringify(extraCategoryQueryFilters) ===
            JSON.stringify(extraCategories)
        ) {
            return;
        }

        setExtraCategories(extraCategoryQueryFilters);
    }, [extraCategoryQueryFilters]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);
    };

    const oneResult = paginationSettings.count === 1;
    let paginationText = translate(
        `offerEventPagination.${oneResult ? 'training' : 'trainings'}`
    );

    if (isELearning) {
        paginationText = translate(
            `offerEventPagination.${oneResult ? 'eLearning' : 'eLearnings'}`
        );
    } else if (isMeetingWebinar) {
        paginationText = translate(
            `offerEventPagination.${oneResult ? 'meeting' : 'meetings'}`
        );
    }

    const isTrainerOrAuthor = !!selectedFilters.find(
        (filter) =>
            filter.value === IOfferRole.Trainer ||
            filter.value === IOfferRole.Author
    );

    const extraFilters = isMeetingWebinar && (
        <DateRangeFilter
            disableEndDate
            dateRange={dateFilter}
            onChange={(dateRange) => {
                // This indicates a date reset
                if (!dateRange?.startDate) {
                    localStorage.removeItem('offerEventOverviewDateFilter');
                }

                setDateFilter(
                    dateRange
                        ? dateRange
                        : {
                              startDate: defaultFilterDate,
                              endDate: undefined,
                          }
                );
            }}
        />
    );

    const loading = filtersLoading || loadingOfferEvents;

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex' }}>
                <FilterBar
                    barPrepend={[viewToggle, extraFilters && extraFilters]}
                    filters={filters}
                    initialSearchValue={searchQueryParam}
                    initialSelected={selectedFilters}
                    placeholder={translate('filterBarPlaceholder')}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch('')}
                    onSelect={handleFilterSelect}
                />
            </Box>

            {loading && <BoxLoader />}

            {!loading && (
                <>
                    {offerEvents.map((offerEvent) => (
                        <OfferEventOverviewListItem
                            isTrainerOrAuthor={isTrainerOrAuthor}
                            key={offerEvent.id}
                            onClick={() => {
                                openGlobalDrawer(
                                    {
                                        type: offerEvent.hasDetailPage
                                            ? IGlobalDrawerType.OfferEvent
                                            : IGlobalDrawerType.Offer,
                                        itemId: offerEvent.hasDetailPage
                                            ? offerEvent.id
                                            : offerEvent.offerId,
                                    },
                                    location
                                );
                            }}
                            {...offerEvent}
                        />
                    ))}

                    {!offerEvents.length && <NoResults variant="OFFERS" />}

                    {!!offerEvents.length && (
                        <Box sx={{ mt: 1, mb: 2 }}>
                            <Divider />
                        </Box>
                    )}

                    {paginationSettings.count > -1 && (
                        <Pagination
                            page={paginationSettings.page}
                            pageAmount={paginationSettings.pageAmount}
                            totalsAmount={paginationSettings.count}
                            totalsText={paginationText}
                            onChange={(page: number) => {
                                setPage(page);
                            }}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
