import { Box } from '@mui/material';

import { Link } from 'common/components/Link';
import { Typography } from 'common/components/Typography';
import { useOrganisationQuery } from 'graphql/types';

export const OrganisationFooter = () => {
    const { data: organisationData, loading: loadingOrg } =
        useOrganisationQuery();

    const { organisation } = organisationData || {};
    const { footerImage, footerText, footerLink } = organisation || {};

    if (!footerImage || loadingOrg) return null;

    const image = (
        <img
            alt="Footer logo"
            src={footerImage.url || ''}
            style={{
                maxHeight: 50,
                verticalAlign: 'middle',
            }}
        />
    );

    return (
        <Box
            sx={{
                justifySelf: 'flex-end',
                p: 4,
                borderTop: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {!!footerLink ? (
                    <Link href={footerLink} sx={{ mb: 2 }} target="_blank">
                        {image}
                    </Link>
                ) : (
                    <Box sx={{ mb: 2 }}>{image}</Box>
                )}

                <Typography color="text.secondary" variant="body2">
                    {footerText}
                </Typography>
            </Box>
        </Box>
    );
};
