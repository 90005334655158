import {
    TExtraChoiceField,
    TExtraField,
    TExtraStringField,
    TFilterBarItem,
} from 'common/types';
import {
    EXTRA_CHOICE_FIELD_TYPES,
    EXTRA_STRING_FIELD_TYPES,
} from 'common/constants/extraFields';

import { TQueryParams } from './getSearchUrl';
import { removeEmptyFromObject } from './object';

/** Type guard function to check if extraField is a choice field */
export function isExtraChoiceField(
    field: TExtraField
): field is TExtraChoiceField {
    return !!EXTRA_CHOICE_FIELD_TYPES.find((f) => f === field.__typename);
}

/** Type guard function to check if extraField is a string field */
export function isExtraStringField(
    field: TExtraField
): field is TExtraStringField {
    return !!EXTRA_STRING_FIELD_TYPES.find((f) => f === field.__typename);
}

function getExtraFieldChoicesInitialValue(
    field: TExtraField,
    fieldValue?: string | string[] | null
) {
    // Return the field value when it's not a choice field
    if (!isExtraChoiceField(field)) return fieldValue;

    if (field.multiple) {
        if (!fieldValue) return [];

        // If field is multiple select but it still has a string value from when the field was single select
        if (typeof fieldValue === 'string') {
            if (!field.possibleValues.includes(fieldValue)) return [];

            return [fieldValue];
        }

        // When values are removed check if all selected options are still valid
        // If not remove them from the selected options
        const newValuesList: string[] = [];

        fieldValue.forEach((value) => {
            if (!field.possibleValues.includes(value)) return;

            newValuesList.push(value);
        });

        return newValuesList;
    }
    if (!fieldValue) return;

    let newValue: string | null;

    // If value is not a string get the first value from the list
    if (typeof fieldValue !== 'string') {
        newValue = fieldValue.length ? fieldValue[0] : null;
    } else {
        newValue = fieldValue;
    }

    // Check if the value still exists in possible values
    // Users could have changed to single or multiple and removed values
    if (newValue && !field.possibleValues.includes(newValue)) {
        newValue = null;
    }

    return newValue;
}

export function extraFieldsToFormValues(
    extraFields: TExtraField[],
    fieldValues?: Record<string, string | string[]>
) {
    return extraFields.reduce((acc, extraField) => {
        // Check if we have a value for the extra field
        const currentValue = fieldValues
            ? fieldValues[extraField.name]
            : undefined;

        return {
            ...acc,
            [getExtraFieldsName(extraField.name)]:
                getExtraFieldChoicesInitialValue(extraField, currentValue) ||
                '', // When no value is found we return an empty string
        };
    }, {});
}

export function extraFieldsDataToJson(
    extraFields: Record<string, string | string[]>
) {
    const extraData = removeEmptyFromObject(extraFields);

    return JSON.stringify(extraData);
}

/*
 *  Checks if field name ends with a dot to provide a fallback as formik uses it for nesting
 */
export function getExtraFieldsName(
    extraFieldName: string,
    initialName?: boolean
) {
    const endingDotRegex = new RegExp('\\.+$', 'i'); // If string ends with a dot

    if (extraFieldName.match(endingDotRegex)) {
        return `${extraFieldName.replace(endingDotRegex, '-')}extraField`;
    }

    return !initialName
        ? extraFieldName
        : extraFieldName.replace('-extraField', '.');
}

/*
 * Transforms the extra field filterbar items query params object
 */
export function getExtraFieldsQueryParams(
    filterBarItems: TFilterBarItem[],
    url?: boolean // When its for a url then we need to encode the values
) {
    return filterBarItems.reduce(
        (acc: { [key: string]: string[] }, curParam) => {
            let paramKey = (curParam.parent ||
                curParam.name) as keyof typeof acc;
            paramKey = url ? encodeURIComponent(paramKey) : paramKey;
            const curVal = url
                ? encodeURIComponent(curParam.value)
                : curParam.value;

            const param = {
                [paramKey]: acc[paramKey]
                    ? [...acc[paramKey], curVal]
                    : [curVal],
            };

            return { ...acc, ...param };
        },
        {}
    ) as TQueryParams;
}

/*
 *   Transforms the extra field filterbar items into a json string
 */
export function getExtraFieldsJSON(filters: TFilterBarItem[]) {
    const queryParams = getExtraFieldsQueryParams(filters);

    return JSON.stringify(queryParams);
}

/*
 * Returns the selected extra field filterbar items
 * based on the queryparams
 */
export function getSelectedExtraFieldsFilters(
    queryParams: TQueryParams,
    filterBarItems: TFilterBarItem[]
) {
    const selected: TFilterBarItem[] = [];

    Object.keys(queryParams).forEach((queryParamKey) => {
        filterBarItems.forEach((filterBarItem) => {
            const filterBarResult = filterBarItem.children?.filter(
                (filterBarItemChild) =>
                    queryParamKey === filterBarItemChild.parent &&
                    queryParams[queryParamKey].includes(
                        filterBarItemChild.value
                    )
            );

            if (!filterBarResult?.length) return;

            filterBarResult.forEach((result) => {
                selected.push(result);
            });
        });
    });

    return selected;
}
