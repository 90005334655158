import { styled } from 'styled-components';

import { ListItem } from '../ListItem';

type TListItemProps = React.ComponentProps<typeof ListItem>;

interface IProps {
    py?: number;
    px?: number;
}

export const ListFooter = styled(ListItem)<IProps & TListItemProps>`
    ${({ theme, py, px }) => `
        padding: ${theme.spacing(
            py !== undefined ? py : 2,
            px !== undefined ? px : 2
        )};

        border-top: 1px solid ${theme.palette.divider};

        margin-top: ${theme.spacing(1)};
        /* Use negative margin to fix spacing for the List that has padding bottom */
        margin-bottom: -${theme.spacing(1)};

        ${theme.breakpoints.up('sm')} {
            padding: ${theme.spacing(
                py !== undefined ? py : 4,
                px !== undefined ? px : 4
            )};

            margin-top: ${theme.spacing(2)};
            margin-bottom: -${theme.spacing(2)};
        }
    `}
`;
