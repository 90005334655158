import { useTranslation } from 'react-i18next';

import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { useCreateOpenIdConnectionMutation } from 'graphql/types';
import { TOpenIdConnectionFormValues } from 'connections/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';

import { OpenIdConnectionForm } from '../forms/OpenIdConnectionForm';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
}

export const OpenIdConnectionCreateDrawer = ({ onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [createOpenIdConnection, { loading: loadingCreate }] =
        useCreateOpenIdConnectionMutation();

    const handleSubmit = async (values: TOpenIdConnectionFormValues) => {
        try {
            const { ltiAuthenticateUrl, ltiLaunchUrl, ...otherValues } = values;

            await createOpenIdConnection({
                variables: { openId: otherValues },
                update: (cache, result) => {
                    const newOpenIdConnection =
                        result.data?.createOpenIdConnection?.openIdConnection;

                    if (!newOpenIdConnection) return;

                    cache.modify({
                        fields: {
                            openIdConnections(
                                existingConnections = [],
                                { toReference }
                            ) {
                                return [
                                    ...existingConnections,
                                    toReference(newOpenIdConnection),
                                ];
                            },
                        },
                    });
                },
            });

            displaySnackbar(translate('openIdConnectionActionSuccess.create'), {
                variant: 'success',
            });

            onClose?.();
        } catch (error) {
            showApolloError(error);

            return;
        }

        return;
    };

    return (
        <PageDrawer onClose={onClose} {...other}>
            <PageTitle mixpanelTitle="Drawer Open ID connection form">
                {translate('openIdConnectionForm.pageTitle')}
            </PageTitle>

            <OpenIdConnectionForm
                disabled={loadingCreate}
                onSubmit={handleSubmit}
            />
        </PageDrawer>
    );
};
