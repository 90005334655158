import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { PAGE_IMAGE_MAX_SIZE, PAGE_IMAGE_TYPE } from 'common/constants/files';
import { ImageField } from 'common/components/FormField';
import LOGIN_PAGE_IMAGE from 'assets/images/login-page.webp';
import { Banner } from 'common/components/Banner';
import { Img } from 'common/components/Img';
import { Link } from 'common/components/Link';

export const LoginPageForm = () => {
    const { t: translate } = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="academySettingsForm.loginPageDescription"
                    />
                </Box>
            }
            title={translate('academySettingsForm.loginPageTitle')}
        >
            <Grid
                container
                alignItems="stretch"
                direction={smallScreen ? 'column-reverse' : 'row'}
                spacing={2}
                sx={{ mt: 2 }}
            >
                <Grid item sm xs={12}>
                    <Field
                        acceptedFileTypes={PAGE_IMAGE_TYPE}
                        component={ImageField}
                        label={translate(
                            'academySettingsForm.loginPageImage.label'
                        )}
                        maxUploadSize={PAGE_IMAGE_MAX_SIZE}
                        name="image"
                    />
                </Grid>
                <Grid item sm xs={12}>
                    <Banner>
                        <Box pl={3} pr={2} py={1}>
                            {translate(
                                'academySettingsForm.loginPageImage.description'
                            )}
                        </Box>
                        <Box mt="auto">
                            <Img src={LOGIN_PAGE_IMAGE} />
                        </Box>
                    </Banner>
                </Grid>
            </Grid>
        </FormSection>
    );
};
