import { useLocation } from 'react-router-dom';
import { Stack, TableRow } from '@mui/material';

import { IPortfolioItemReportFragment } from 'graphql/types';
import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { UserAvatar } from 'user/components/UserAvatar';
import { Chip } from 'common/components/Chip';
import { LinearProgress } from 'common/components/LinearProgress';
import {
    formatDate,
    timeDeltaToDuration,
    durationToHuman,
} from 'common/utils/formatDate';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    reportItem: IPortfolioItemReportFragment;
}

export const OfferReportTableRow = ({ reportItem }: IProps) => {
    const location = useLocation();
    const { openGlobalDrawer } = useGlobalDrawer();

    const {
        id,
        title,
        user,
        startDate,
        completionDate,
        timeSpent,
        progress,
        score,
        scoreThreshold,
    } = reportItem;
    const { name } = user || {};

    if (!user) return null;

    const startDateString = startDate && formatDate(startDate, 'dd MMMM yyyy');
    const completionDateString =
        completionDate && formatDate(completionDate, 'dd MMMM yyyy');

    const timeSpentDuration = timeDeltaToDuration(
        timeSpent || undefined
    ).shiftTo('days', 'hours', 'minutes', 'seconds');

    const showScore = progress === 1 && score;

    const scoreChip = showScore ? (
        <Chip
            color={score >= (scoreThreshold || 0) ? 'success' : 'error'}
            label={`${Math.round(score * 100)}%`}
        />
    ) : null;

    const onClickRow = () => {
        openGlobalDrawer(
            {
                type: IGlobalDrawerType.PortfolioItem,
                itemId: id,
            },
            location
        );
    };

    return (
        <TableRow
            hover
            key={id}
            sx={{ cursor: 'pointer' }}
            onClick={onClickRow}
        >
            <TableCell>
                <Stack alignItems="center" direction="row" spacing={2}>
                    <UserAvatar size={35} user={user} />
                    <Typography variant="body2">{name}</Typography>
                </Stack>
            </TableCell>
            <TableCell>{title}</TableCell>
            <TableCell>{startDateString}</TableCell>
            <TableCell>
                {completionDate ? (
                    <Typography variant="body2">
                        {completionDateString}
                    </Typography>
                ) : (
                    <LinearProgress
                        withProgressText
                        value={(progress || 0) * 100}
                    />
                )}
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {timeSpentDuration &&
                    durationToHuman(timeSpentDuration, {
                        unitDisplay: 'narrow',
                    })}
            </TableCell>
            <TableCell>{scoreChip}</TableCell>
        </TableRow>
    );
};
