import { ApolloError } from '@apollo/client/errors';

import { useSnackbar } from 'common/hooks/useSnackbar';
import { getErrorMessage } from 'common/utils/getErrorMessage';

/**
 * Hook to display snackbar messages for Apollo errors
 */
export const useApolloError = () => {
    const [displaySnackbar] = useSnackbar();

    // By default the error is unknown so we accept unknown here and cast it to
    // ApolloError
    const showApolloError = (error: unknown) => {
        const { graphQLErrors } = error as ApolloError;

        graphQLErrors.forEach((e) => {
            displaySnackbar(getErrorMessage(e));
        });
    };

    return { showApolloError };
};
