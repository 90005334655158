import { IAppPermissionsFragment } from 'graphql/types';
import { TAppPermissionsObj } from 'user/types';

export function getParsedAppPermissions(
    appPermissions?: IAppPermissionsFragment | null
): TAppPermissionsObj | undefined {
    if (!appPermissions) return undefined;

    return Object.entries(appPermissions).reduce(
        (acc, [key, objectPermissions]) => {
            if (key === '__typename' || !objectPermissions) return acc;

            return {
                ...acc,
                [key]: Object.entries(objectPermissions).reduce(
                    (permAcc, [permKey, permission]) => {
                        if (permKey === '__typename' || !permission)
                            return permAcc;

                        return {
                            ...permAcc,
                            [permKey]: JSON.parse(permission),
                        };
                    },
                    {}
                ),
            };
        },
        {} as TAppPermissionsObj
    );
}
