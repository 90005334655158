import { IOfferEventListItemFragment } from 'graphql/types';
import { List } from 'common/components/List';

import { OfferEventListItem } from '../OfferEventListItem';

interface IProps {
    className?: string;
    items: IOfferEventListItemFragment[];
    onClick?(offerEvent: IOfferEventListItemFragment): void;
}

export const OfferEventList = ({ className, items, onClick }: IProps) => (
    <List noBorder className={className}>
        {items.map((item) => (
            <OfferEventListItem
                key={item.id}
                onClick={() => onClick && onClick(item)}
                {...item}
            />
        ))}
    </List>
);
