import { Divider, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiCalendarPlus } from '@mdi/js';

import {
    getCalendarIcsDownload,
    getGoogleCalendarUrl,
    getOutlookCalendarUrl,
} from 'common/utils/calendar';
import { TDate } from 'common/types';

import { DropdownMenu } from '../DropdownMenu';
import { Link } from '../Link';
import { IconButton } from '../IconButton';

interface IProps {
    date: TDate;
    dates?: TDate[];
    title: string;
    description: string;
    location: string;
    extraMenuItems?: React.ReactNode;
}

export const CalendarMenu = ({
    dates,
    date,
    title,
    description,
    location,
    extraMenuItems,
}: IProps) => {
    const { t: translate } = useTranslation();

    const googleCalUrl = getGoogleCalendarUrl(
        date,
        title,
        description,
        location
    );
    const outlookLink = getOutlookCalendarUrl(
        date,
        title,
        description,
        location
    );

    // IF no date array is given we use the single date
    const dateArray = dates ? dates : [date];
    const icsUrl = getCalendarIcsDownload(
        dateArray,
        title,
        description,
        location
    );

    return (
        <DropdownMenu
            anchor={
                <IconButton
                    color="inherit"
                    iconPath={mdiCalendarPlus}
                    iconSize="2.3rem"
                />
            }
        >
            <MenuItem component={Link} href={googleCalUrl} target="_blank">
                {translate('addToCalendar.googleCalendar')}
            </MenuItem>

            <MenuItem component={Link} href={outlookLink} target="_blank">
                {translate('addToCalendar.outlook')}
            </MenuItem>

            <Divider />

            <MenuItem component={Link} download={title} href={icsUrl}>
                {translate('addToCalendar.downloadIcs')}
            </MenuItem>

            {extraMenuItems}
        </DropdownMenu>
    );
};
