import { styled } from 'styled-components';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { ILinkProps, Link } from 'common/components/Link';
import { Badge } from 'common/components/Badge';
import { IUnreadBadge } from 'graphql/types';

export interface IProps extends ILinkProps {
    className?: string;
    children: React.ReactNode;
    badge?: IUnreadBadge;
    icon?: React.ReactElement;
    href?: string;
    disabled?: boolean;
}

const NavigationItem = ({
    className,
    badge,
    children,
    icon,
    to,
    href,
    disabled,
    ...other
}: IProps) => {
    let iconComponent = icon;

    if (iconComponent && badge) {
        iconComponent = (
            <Badge badgeContent={badge.count} color="error" max={99}>
                {iconComponent}
            </Badge>
        );
    }

    return (
        <ListItem
            button
            className={className}
            component={Link}
            disabled={disabled}
            href={href}
            to={to}
            {...other}
        >
            {iconComponent && (
                <ListItemIcon className="icon">{iconComponent}</ListItemIcon>
            )}
            <ListItemText primary={children} />
        </ListItem>
    );
};

export const MobileNavigationItem = styled(NavigationItem)`
    color: ${({ theme }) => theme.palette.body.main};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};

    .icon {
        min-width: 33px;
        color: inherit;
    }

    &.MuiLink-underlineHover:hover {
        text-decoration: none;
    }
`;
