import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
    TWelcomeDrawerFormValues,
    WelcomeDrawerForm,
} from 'common/components/forms/WelcomeDrawerForm/WelcomeDrawerForm';
import { ApolloError } from 'common/components/ApolloError';
import {
    useCurrentUserQuery,
    useExtraUserFieldsQuery,
    useOrganisationQuery,
    useUpdateProfileMutation,
} from 'graphql/types';
import { BackgroundImg } from 'common/components/Img';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { getExtraFieldInitialEmptyValue } from 'common/utils/userExtraFields';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { PageTitle } from 'common/components/PageTitle';
import { getExtraFieldsName } from 'common/utils/extraFields';
import { PageDrawer } from 'common/components/PageDrawer';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';
import { Loader } from 'common/components/Loader';

export const WelcomeDrawer = () => {
    const [translate] = useTranslation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const match = useHashMatch('INTRO');

    const {
        data: organisationData,
        loading: organisationLoading,
        error: organisationError,
    } = useOrganisationQuery();
    const {
        data: extraUserFieldData,
        loading: extraUserFieldloading,
        error: extraUserFieldError,
    } = useExtraUserFieldsQuery();
    const { data: currentUserData } = useCurrentUserQuery();

    const handleClose = () => {
        if (match) {
            navigate(window.location.pathname);

            return;
        }

        localStorage.setItem('introShown', '1');
        setOpen(false);
    };

    const [updateProfile, { called }] = useUpdateProfileMutation();

    const error = organisationError || extraUserFieldError || undefined;
    const organisation = organisationData?.organisation;
    let fields = extraUserFieldData?.extraUserFields.filter(
        (field) => field.profile
    );
    const { extraFields } = currentUserData?.currentUser || {};
    const userExtraFields = extraFields && JSON.parse(extraFields);

    if (fields?.length) {
        fields = fields.map((extraField) => {
            if (!userExtraFields || !userExtraFields[extraField.name]) {
                return {
                    ...extraField,
                    initialValue: getExtraFieldInitialEmptyValue(extraField),
                };
            }

            return {
                ...extraField,
                initialValue: userExtraFields[extraField.name],
            };
        });
    }

    useEffect(() => {
        let introAlreadyShown: string | null | boolean =
            localStorage.getItem('introShown');
        introAlreadyShown = !!(introAlreadyShown && +introAlreadyShown === 1);

        const { showIntroductionPanel } = organisation || {};

        const drawerAutoOpen =
            !!showIntroductionPanel && !called && !introAlreadyShown && !match;
        const drawerManualOpen = !!showIntroductionPanel && match;
        const drawerOpen = drawerAutoOpen || drawerManualOpen;

        setOpen(drawerOpen);
    }, [fields, open, called, match, organisation]);

    if (error) return <ApolloError error={error} />;

    const handleSubmit = async (value: TWelcomeDrawerFormValues) => {
        const newExtraFields = Object.keys(value).reduce(
            (acc, curKey) => ({
                ...acc,
                [getExtraFieldsName(curKey as string, true)]: value[curKey],
            }),
            {}
        );

        await updateProfile({
            variables: {
                profile: {
                    extraFields: JSON.stringify(newExtraFields),
                },
            },
        });

        handleClose();

        return;
    };

    const { homeHeaderImage, homeTitle, homeTitleColor, homeText } =
        organisation || {};
    const introImage = homeHeaderImage?.url || undefined;
    const introTitle = homeTitle || undefined;
    const introText = homeText || undefined;

    const titleColor = homeTitleColor && `#${homeTitleColor}`;

    const hasFields = !!fields?.length;
    const hasRequiredFields = !!fields
        ?.filter((field) => field.required)
        .filter(Boolean).length;
    const loading = organisationLoading || extraUserFieldloading;
    const showCloseButton = (match || !hasRequiredFields) && !loading;

    return (
        <PageDrawer
            anchor="left"
            closeIconPosition="right"
            disableClose={!showCloseButton}
            headerProps={{ floating: !!introImage }}
            open={open}
            onClose={showCloseButton ? handleClose : undefined}
        >
            {loading && <Loader />}

            <PageTitle mixpanelTitle="Introduction">
                {translate('introduction')}
            </PageTitle>

            {!loading && (
                <>
                    {!!introImage && (
                        <BackgroundImg
                            src={introImage}
                            sx={{ minHeight: '250px' }}
                        >
                            <Box
                                alignItems="flex-end"
                                display="flex"
                                height="100%"
                                p={{ xs: 2, sm: 4 }}
                            >
                                <Typography
                                    style={{ color: titleColor || '#fff' }}
                                    variant="h1"
                                >
                                    {introTitle}
                                </Typography>
                            </Box>
                        </BackgroundImg>
                    )}
                    <Box pb={8} pt={introImage ? 4 : 8} px={{ xs: 2, sm: 4 }}>
                        {!!(introTitle || introText) && (
                            <Box mb={2}>
                                {!!introTitle && !introImage && (
                                    <Typography
                                        style={{
                                            color: titleColor || undefined,
                                        }}
                                        variant="h1"
                                    >
                                        {introTitle}
                                    </Typography>
                                )}

                                {!!introText && (
                                    <Text
                                        dangerouslySetInnerHTML={{
                                            __html: introText,
                                        }}
                                        variant="body1"
                                    />
                                )}
                            </Box>
                        )}

                        {!loading && !hasFields && (
                            <Box mt={4}>
                                <Button
                                    color="primary"
                                    component={Link}
                                    variant="contained"
                                    onClick={handleClose}
                                >
                                    {translate('toAcademy')}
                                </Button>
                            </Box>
                        )}

                        {!loading && hasFields && (
                            <WelcomeDrawerForm
                                fields={fields || []}
                                onSubmit={handleSubmit}
                            />
                        )}
                    </Box>
                </>
            )}
        </PageDrawer>
    );
};
