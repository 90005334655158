import { useEffect, useReducer, useRef } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { makeVar, useReactiveVar } from '@apollo/client';

import {
    IGlobalDrawer,
    IGlobalDrawerAction,
    IGlobalDrawerActionType,
} from 'common/types';
import { IGlobalDrawerHandlers } from 'common/contexts/GlobalDrawerContext';
import { GlobalDrawer } from 'common/components/GlobalDrawerProvider/GlobalDrawer';
import { useGlobalDrawerRouting } from 'common/hooks/useGlobalDrawerRouting';
import { IsAuthenticated } from 'common/components/IsAuthenticated';

function globalDrawersReducer(
    globalDrawers: IGlobalDrawer[],
    action: IGlobalDrawerAction
) {
    switch (action.type) {
        case IGlobalDrawerActionType.Add: {
            return [
                ...globalDrawers,
                {
                    ...action.globalDrawer,
                    actionType: action.type,
                },
            ];
        }

        case IGlobalDrawerActionType.Open: {
            return globalDrawers.map((drawer) => {
                // Compare properties of the action.globalDrawer with drawer.
                // We only compare the properties that are set
                if (
                    Object.entries(action.globalDrawer).every(
                        ([key, value]) =>
                            key === 'actionType' ||
                            Object.is(drawer[key as keyof IGlobalDrawer], value)
                    )
                ) {
                    return { ...drawer, actionType: action.type };
                }

                return drawer;
            });
        }

        case IGlobalDrawerActionType.Close: {
            return globalDrawers.map((drawer) => {
                // Compare properties of the action.globalDrawer with drawer.
                // We only compare the properties that are set
                if (
                    Object.entries(action.globalDrawer).every(
                        ([key, value]) =>
                            key === 'actionType' ||
                            Object.is(drawer[key as keyof IGlobalDrawer], value)
                    )
                ) {
                    return { ...drawer, actionType: action.type };
                }

                return drawer;
            });
        }

        case IGlobalDrawerActionType.Delete: {
            return globalDrawers.filter(
                (drawer) =>
                    // Compare properties of the action.globalDrawer with drawer.
                    // We only compare the properties that are set
                    !Object.entries(action.globalDrawer).every(
                        ([key, value]) =>
                            key === 'actionType' ||
                            Object.is(drawer[key as keyof IGlobalDrawer], value)
                    )
            );
        }

        default: {
            return globalDrawers;
        }
    }
}

type TBlockCloseFn = (closeGlobalDrawer: () => void) => void;
export const blockGlobalDrawer = makeVar<TBlockCloseFn | null>(null);

interface IProps {
    setActionHandlers(handlers: IGlobalDrawerHandlers): void;
}

export const GlobalDrawerList = ({ setActionHandlers }: IProps) => {
    const location = useLocation();
    const [globalDrawers, dispatch] = useReducer(globalDrawersReducer, []);
    const handlersAreSet = useRef(false);
    const { globalDrawerType, openGlobalDrawerRoute, closeGlobalDrawerRoute } =
        useGlobalDrawerRouting();
    const onBlockGlobalDrawer = useReactiveVar(blockGlobalDrawer);

    const openGlobalDrawer = (
        globalDrawer: IGlobalDrawer,
        location?: Location
    ) => {
        // If drawer is already open, do not open it again
        if (globalDrawers.some((drawer) => drawer.type === globalDrawer.type)) {
            return;
        }

        // Navigate to the drawer its route before dispatching the action
        if (location && !globalDrawers.length) {
            openGlobalDrawerRoute(globalDrawer, location);

            return;
        }

        dispatch({ type: IGlobalDrawerActionType.Add, globalDrawer });
    };

    const closeGlobalDrawer = (
        globalDrawer: IGlobalDrawer,
        forceClose: boolean = false
    ) => {
        if (!!onBlockGlobalDrawer && !forceClose) {
            const closeBlockedGlobalDrawer = () => {
                closeGlobalDrawer(globalDrawer, true);
            };

            onBlockGlobalDrawer(closeBlockedGlobalDrawer);

            return;
        }

        if (globalDrawer?.location) closeGlobalDrawerRoute(location);

        dispatch({ type: IGlobalDrawerActionType.Close, globalDrawer });
    };

    // Open global drawer through routing when directly opening the url
    useEffect(() => {
        if (!globalDrawerType) {
            // If a drawer is open and the global drawer type is not set, close the drawer
            if (globalDrawers.length) {
                closeGlobalDrawer(globalDrawers[0]);
            }

            return;
        }

        openGlobalDrawer({ type: globalDrawerType, location });
    }, [globalDrawerType]);

    // Passes action handlers to the global drawer provider to pass it to the context
    useEffect(() => {
        if (handlersAreSet.current) return;

        setActionHandlers({ openGlobalDrawer, closeGlobalDrawer });

        handlersAreSet.current = true;
    }, [openGlobalDrawer, closeGlobalDrawer]);

    useEffect(() => {
        if (!globalDrawers.length) return;

        // Check all global drawers if route changed while drawer is open
        globalDrawers.forEach((gd) => {
            if (gd.location?.hash !== location.hash) {
                dispatch({
                    type: IGlobalDrawerActionType.Close,
                    globalDrawer: gd,
                });
            }
        });
    }, [location]);

    /*
     * Makes sure the drawer correctly animates when opening and closing
     * Makes sure the drawer first mounts before opening
     * Makes sure the drawer first closes before unmounting
     */
    useEffect(() => {
        const lastGlobalDrawer = globalDrawers[globalDrawers.length - 1];
        const isAddAction =
            lastGlobalDrawer?.actionType === IGlobalDrawerActionType.Add;
        const isCloseAction =
            lastGlobalDrawer?.actionType === IGlobalDrawerActionType.Close;

        if (!isAddAction && !isCloseAction) return;

        // The timeout is used so the drawer animates correctly
        setTimeout(() => {
            dispatch({
                type: isAddAction
                    ? IGlobalDrawerActionType.Open
                    : IGlobalDrawerActionType.Delete,
                globalDrawer: lastGlobalDrawer,
            });
        }, 100);
    }, [globalDrawers]);

    if (!globalDrawers.length) return null;

    return (
        <IsAuthenticated>
            {globalDrawers.map((drawer, index) => (
                <GlobalDrawer
                    closeGlobalDrawer={closeGlobalDrawer}
                    drawer={drawer}
                    isBlocked={!!onBlockGlobalDrawer}
                    key={`${drawer.type}-${index}`}
                />
            ))}
        </IsAuthenticated>
    );
};
