import { RouteObject } from 'react-router';

import { TRouteName } from 'route/types';
import { ROUTES } from 'route/constants/routes';

// Return a RouteObject by using the route name
export function getRoute(routeName: TRouteName, params?: RouteObject) {
    return {
        path: ROUTES[routeName],
        ...params,
    };
}

/**
 * Map given route names to an react router "route". This way you can render children easily
 */
export function getRoutes(routeNames: TRouteName[], params?: RouteObject) {
    return routeNames.map((routeName) => getRoute(routeName, params));
}
