import {
    Navigate,
    useParams,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { mdiCog, mdiPlus } from '@mdi/js';
import { useEffect } from 'react';

import { Icon } from 'common/components/Icon';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { BasePage } from 'hydra/pages/BasePage';
import { Loader } from 'common/components/Loader';
import { useCollectionLazyQuery } from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { PageIntroHeader } from 'common/components/PageIntroHeader';
import { LibraryItemsList } from 'library/components/LibraryItemsList';
import { SearchBar } from 'common/components/SearchBar';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { Breadcrumbs } from 'common/components/Breadcrumbs';
import { Link } from 'common/components/Link';
import { ActionButton } from 'common/components/ActionButton';
import { Tooltip } from 'common/components/Tooltip';
import { List } from 'common/components/List';
import { ListItem } from 'common/components/ListItem';
import { Chip, ChipGroup } from 'common/components/Chip';
import {
    LibraryCollectionDrawer,
    LibraryCollectionCreateDrawer,
} from 'library/components/LibraryCollectionDrawer';
import { LibraryArticleCreateDrawer } from 'library/components/LibraryArticleDrawer';
import { getUrl } from 'route/utils/getUrl';
import {
    goToCollectionDetailPage,
    goToParentCollection,
    goToArticleContentEditPage,
    goToRootCollectionDetailPage,
} from 'library/utils/goTo';
import { useFrontendPermissions } from 'user/hooks';
import { PageTitle } from 'common/components/PageTitle';
import { isUUID } from 'common/utils/isUUID';

import { LibraryEmptyPage } from './LibraryEmptyPage';
import { FourOFourPage } from './FourOFourPage';

export const LibraryCollectionPage = () => {
    const [translate] = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { searchQuery, onSearch } = useOutletContext<{
        searchQuery?: string;
        onSearch(value: string): void;
    }>();

    const { canCreate: canCreateCollection, canUpdate: canUpdateCollection } =
        useFrontendPermissions('libraryCollection');
    const { canCreate: canCreateArticle } =
        useFrontendPermissions('libraryArticle');

    const createCollectionDrawerOpen = !!useRouteMatch(
        'LIBRARY_COLLECTION_CREATE'
    );
    const createArticleDrawerOpen = !!useRouteMatch(
        'LIBRARY_COLLECTION_CREATE_ARTICLE'
    );
    const collectionDrawerOpen = !!useRouteMatch('LIBRARY_COLLECTION_EDIT');

    const [
        fetchCollection,
        {
            loading: loadingCollection,
            error,
            data,
            called,
            refetch: refetchCollection,
        },
    ] = useCollectionLazyQuery({
        onCompleted: (data) => {
            const collection = data?.libraryCollection;

            if (!collection || collection.root || isUUID(id)) return;

            // GET Route function
            navigate(
                getUrl(
                    collectionDrawerOpen
                        ? 'LIBRARY_COLLECTION_EDIT'
                        : 'LIBRARY_COLLECTION',
                    {
                        id: collection.id,
                    }
                )
            );
        },
    });

    const loading = loadingCollection;

    useEffect(() => {
        const collectionId = data?.libraryCollection?.id || {};

        if (loading || collectionId === id || error) return;

        const collectionQueryVariables = isUUID(id)
            ? { id }
            : { collectionId: id };

        fetchCollection({ variables: collectionQueryVariables });
    }, [data, fetchCollection, id, loading, error]);

    // Early return when loading or error
    if (loading || !called || error) {
        return (
            <BasePage>
                {error && <ApolloError error={error} />}
                {(loading || !called) && <Loader />}
            </BasePage>
        );
    }

    const collection = data?.libraryCollection;

    // When the response doesn't contain an collection we redirect to the root of the library.
    if (called && !loading && !collection) {
        if (!id) {
            if (canCreateCollection) {
                // To get the new root collection on create we call refetch
                return (
                    <LibraryEmptyPage
                        onCreate={() =>
                            refetchCollection && refetchCollection()
                        }
                    />
                );
            }

            // We don't have a root collection and the user cannot create it. We return a 404 page
            return <FourOFourPage />;
        }

        return <Navigate to={getUrl('LIBRARY')} />;
    }

    const {
        id: collectionId,
        parent,
        title,
        description,
        root,
        active,
        libraryImage,
        libraryTitleColor,
        collections,
        articles,
    } = collection || {};

    const breadcrumbs = [];

    const libraryTitle = translate('library');

    if (!root) {
        breadcrumbs.push(
            <Link key="l" to="LIBRARY">
                {libraryTitle}
            </Link>
        );

        if (parent && !parent.root && parent.id && parent.title) {
            breadcrumbs.push(
                <Link
                    key="c"
                    params={{ id: parent?.id }}
                    to="LIBRARY_COLLECTION"
                >
                    {parent?.title}
                </Link>
            );
        }
    }

    let header;

    if (root && libraryImage) {
        // Default header color is white when image is set

        header = (
            <PageIntroHeader image={libraryImage}>
                {!active && (
                    <Box mb={1}>
                        <ChipGroup>
                            <Chip
                                bgColor="warning"
                                label={translate('hidden')}
                            />
                        </ChipGroup>
                    </Box>
                )}

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item>
                        <Box
                            alignItems="center"
                            component="span"
                            display="flex"
                            flexWrap="wrap"
                        >
                            <Box mr={1}>
                                <Typography
                                    style={{
                                        color: `#${libraryTitleColor || 'fff'}`,
                                    }}
                                    variant="h1"
                                >
                                    {title}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {canUpdateCollection && (
                        <Grid item>
                            <Tooltip
                                title={translate<string>('editCollection')}
                            >
                                <Box>
                                    <ActionButton
                                        component={Link}
                                        params={{ id: collectionId }}
                                        to="LIBRARY_COLLECTION_EDIT"
                                    >
                                        <Icon path={mdiCog} />
                                    </ActionButton>
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </PageIntroHeader>
        );
    }

    const pageTitle = root
        ? libraryTitle
        : title
          ? `${libraryTitle} - ${title}`
          : undefined;
    const isDrawerPage =
        createCollectionDrawerOpen ||
        createArticleDrawerOpen ||
        collectionDrawerOpen;

    return (
        <BasePage header={header}>
            {collection && (
                <PageTitle
                    mixpanelTitle={`Collection${root ? '' : ' - Library'}`}
                    skipMixpanel={isDrawerPage}
                >
                    {pageTitle || ''}
                </PageTitle>
            )}

            <Box py={4}>
                {!!breadcrumbs.length && (
                    <Box mb={2}>
                        <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
                    </Box>
                )}

                {(!root || !header) && (
                    <Box mb={2}>
                        {!active && (
                            <Box mb={1}>
                                <Chip
                                    bgColor="warning"
                                    label={translate('hidden')}
                                />
                            </Box>
                        )}

                        <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Grid item>
                                <Box
                                    alignItems="center"
                                    component="span"
                                    display="flex"
                                    flexWrap="wrap"
                                >
                                    <Box mb={1} mr={1}>
                                        <Typography variant="h1">
                                            {title}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {canUpdateCollection && (
                                <Grid item>
                                    <Tooltip
                                        title={translate<string>(
                                            'editCollection'
                                        )}
                                    >
                                        <Box>
                                            <ActionButton
                                                outlined
                                                component={Link}
                                                params={{ id: collectionId }}
                                                to="LIBRARY_COLLECTION_EDIT"
                                            >
                                                <Icon path={mdiCog} />
                                            </ActionButton>
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}
                {description && (
                    <Box mb={6}>
                        <Text variant="body1">{description}</Text>
                    </Box>
                )}

                {root && (
                    <Box mb={6}>
                        <SearchBar
                            initialValue={searchQuery}
                            placeholder={translate('filterBarPlaceholder')}
                            onClickSearch={onSearch}
                            onEnterPress={onSearch}
                        />
                    </Box>
                )}

                {/* Only show collection list when we have collections or when user can create collections */}
                {(!!collections?.length || canCreateCollection) && (
                    <Box mb={4}>
                        <Box alignItems="center" display="flex" mb={1.5}>
                            <Box mr={1}>
                                <Typography variant="h3">
                                    {translate('libraryCollectionsTitle')}
                                </Typography>
                            </Box>
                            {canCreateCollection && (
                                <Tooltip
                                    title={translate<string>('newCollection')}
                                >
                                    <Box>
                                        <ActionButton
                                            outlined
                                            component={Link}
                                            params={{ id: collectionId }}
                                            size="medium"
                                            to="LIBRARY_COLLECTION_CREATE"
                                        >
                                            <Icon path={mdiPlus} />
                                        </ActionButton>
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                        {!!collections?.length ? (
                            <LibraryItemsList
                                canSort={canUpdateCollection}
                                collectionId={collectionId}
                                droppableType="COLLECTION"
                                items={collections}
                            />
                        ) : (
                            <List>
                                <ListItem>
                                    <Typography color="text.secondary">
                                        {translate('containsNoCollections')}
                                    </Typography>
                                </ListItem>
                            </List>
                        )}
                    </Box>
                )}

                {/* Only show articles list when we have articles or when user can create articles */}
                {(!!articles?.length || canCreateArticle) && (
                    <Box>
                        <Box alignItems="center" display="flex" mb={1.5}>
                            <Box mr={1}>
                                <Typography variant="h3">
                                    {translate('libraryArticlesTitle')}
                                </Typography>
                            </Box>
                            {canCreateArticle && (
                                <Tooltip
                                    title={translate<string>('newArticle')}
                                >
                                    <Box>
                                        <ActionButton
                                            outlined
                                            component={Link}
                                            params={{ id: collectionId }}
                                            size="medium"
                                            to="LIBRARY_COLLECTION_CREATE_ARTICLE"
                                        >
                                            <Icon path={mdiPlus} />
                                        </ActionButton>
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                        {!!articles?.length ? (
                            <LibraryItemsList
                                canSort={canUpdateCollection}
                                collectionId={collectionId}
                                droppableType="ARTICLE"
                                items={articles}
                            />
                        ) : (
                            <List>
                                <ListItem>
                                    <Typography color="text.secondary">
                                        {translate('containsNoArticles')}
                                    </Typography>
                                </ListItem>
                            </List>
                        )}
                    </Box>
                )}

                {canUpdateCollection && collectionId && (
                    <LibraryCollectionDrawer
                        id={collectionId}
                        open={collectionDrawerOpen}
                        pageTitle={pageTitle}
                        onClose={() => {
                            if (!parent) {
                                // If the root collection is deleted we want to redirect to the detail, the detail page
                                // will then render the empty library response
                                goToRootCollectionDetailPage(navigate);

                                return;
                            }

                            goToCollectionDetailPage(navigate, id);
                        }}
                        onDelete={() => {
                            if (!parent) {
                                // If the root collection is deleted we want to redirect to the detail, the detail page
                                // will then render the empty library response
                                goToRootCollectionDetailPage(navigate);

                                return;
                            }
                            goToParentCollection(navigate, parent);
                        }}
                        onUpdate={() => {
                            if (!parent) {
                                goToRootCollectionDetailPage(navigate);

                                return;
                            }

                            goToCollectionDetailPage(navigate, id);
                        }}
                    />
                )}

                {canCreateCollection && (
                    <LibraryCollectionCreateDrawer
                        open={createCollectionDrawerOpen}
                        pageTitle={pageTitle}
                        parentId={collectionId}
                        onClose={() => goToCollectionDetailPage(navigate, id)}
                        onCreate={() => goToCollectionDetailPage(navigate, id)}
                    />
                )}

                {canCreateArticle && (
                    <LibraryArticleCreateDrawer
                        open={createArticleDrawerOpen}
                        pageTitle={pageTitle}
                        parentId={collectionId}
                        onClose={() => goToCollectionDetailPage(navigate, id)}
                        onCreate={(articleId) => {
                            goToArticleContentEditPage(navigate, articleId);
                        }}
                    />
                )}
            </Box>
        </BasePage>
    );
};
