import { useTranslation } from 'react-i18next';
import { mdiTransitConnectionVariant } from '@mdi/js';
import { useLocation } from 'react-router-dom';

import {
    Card,
    CardActionLinkArea,
    CardActions,
    CardContent,
    CardMedia,
    CardProgress,
    CardSubtitle,
    CardTitle,
} from 'common/components/Card';
import { Chip, ChipGroup, IconChip } from 'common/components/Chip';
import {
    ITrainingCardFragment,
    ITrainingListCardFragment,
    ITrainingRole,
} from 'graphql/types';
import { ExtraTrainingFieldsChips } from 'training/components/ExtraTrainingFieldsChips';
import DEFAULT_TRAININGCARD from 'assets/images/default-trainingcard.webp';

interface IProps {
    hideProgress?: boolean;
    hideTrainerChip?: boolean;
    showTypeIcon?: boolean;
    training: ITrainingCardFragment | ITrainingListCardFragment;
}

export const TrainingCard = ({
    hideProgress,
    hideTrainerChip,
    showTypeIcon,
    training,
}: IProps) => {
    const [translate] = useTranslation();
    const location = useLocation();

    const {
        title,
        subtitle,
        overviewImage,
        image,
        trainingPortfolioItem,
        rolesForCurrentUser,
    } = training;

    const isParticipant = !!rolesForCurrentUser?.includes(
        ITrainingRole.Participant
    );

    const getTrainerManagerChips = (
        training: ITrainingCardFragment | ITrainingListCardFragment
    ) => {
        const { rolesForCurrentUser, isActive } = training;

        const isTrainer = !!rolesForCurrentUser?.includes(
            ITrainingRole.Trainer
        );

        const isMentor = !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);

        const isGroupmanager = !!rolesForCurrentUser?.includes(
            ITrainingRole.Groupmanager
        );

        if (!isTrainer && !isMentor && !isGroupmanager && isActive) return null;

        return (
            <>
                {isTrainer && !hideTrainerChip && (
                    <Chip bgColor="secondary" label={translate('trainer')} />
                )}

                {isMentor && !hideTrainerChip && (
                    <Chip bgColor="secondary" label={translate('mentor')} />
                )}

                {isGroupmanager && (
                    <>
                        <Chip
                            bgColor="secondary"
                            label={translate('groupmanager')}
                        />
                        {/* If user is groupmanager and participant we want to also
                            show participant label */}
                        {isParticipant && (
                            <Chip
                                bgColor="secondary"
                                label={translate('participant')}
                            />
                        )}
                    </>
                )}
                {!isActive && (
                    <Chip bgColor="warning" label={translate('inactive')} />
                )}
            </>
        );
    };

    const { estimatedTimeSpent, extraTrainingFieldsLabels } = training;

    const trainerManagerChips = getTrainerManagerChips(training);
    const estimatedTimeSpentChip = !!estimatedTimeSpent && (
        <Chip label={estimatedTimeSpent} />
    );
    const showActions =
        !!trainerManagerChips ||
        !!estimatedTimeSpentChip ||
        !!extraTrainingFieldsLabels?.length ||
        showTypeIcon;

    const trainingProgress = trainingPortfolioItem?.progress;
    const progress = !!trainingProgress && isParticipant && !hideProgress && (
        <CardProgress
            withProgressText
            color="primary"
            value={trainingProgress * 100}
        />
    );

    return (
        <Card>
            <CardActionLinkArea
                params={{ trainingId: training.id }}
                state={{ from: location }}
                to="TRAINING"
            >
                <CardMedia
                    image={
                        overviewImage?.url || image?.url || DEFAULT_TRAININGCARD
                    }
                />

                <CardContent>
                    <CardTitle>{title}</CardTitle>
                    {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
                </CardContent>

                {showActions && (
                    <CardActions>
                        <ChipGroup>
                            {showTypeIcon && (
                                <IconChip
                                    iconPath={mdiTransitConnectionVariant}
                                    tooltipTitle={translate('training')}
                                />
                            )}

                            {trainerManagerChips}

                            {estimatedTimeSpentChip}

                            <ExtraTrainingFieldsChips
                                extraTrainingFieldsLabels={
                                    extraTrainingFieldsLabels
                                }
                            />
                        </ChipGroup>
                    </CardActions>
                )}

                {progress}
            </CardActionLinkArea>
        </Card>
    );
};
