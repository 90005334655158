import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiFilter } from '@mdi/js';
import { FormikContextType } from 'formik';

import {
    IFilterFormField,
    TFilterBarItem,
    TFilterFormValues,
    TFilterFormValue,
    IFilterFormItem,
} from 'common/types';
import {
    isRangeFilterValue,
    isObjectFilterValue,
    isStringFilterValue,
} from 'common/utils/filter';
import { SearchBar } from 'common/components/SearchBar';
import { Chip, ChipGroup } from 'common/components/Chip';
import { FilterDrawer } from 'common/components/FilterDrawer';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';

import { Tooltip } from '../Tooltip';

import { FilterBarDropdown } from './FilterBarDropdown';

export interface IFilterBarProps {
    disabled?: boolean;
    disabledFilterSelection?: boolean; // Hides the filter selection dropdown and existing filters can't be altered
    placeholder?: string;
    filters?: TFilterBarItem[];
    // Form filters are used to display in a filter form
    formFilters?: IFilterFormItem[];
    formFilterValues?: TFilterFormValues;
    useFilterDrawer?: boolean;
    filterDrawerProps?: Partial<React.ComponentProps<typeof FilterDrawer>>;
    initialSearchValue?: string;
    initialSelected?: TFilterBarItem[];
    barPrepend?: React.ReactNode;
    barAppend?: React.ReactNode;
    onSearch: (search: string) => void;
    onSearchClear?: () => void;
    onSelect?: (selected: TFilterBarItem[]) => void;
    onFiltersChange?: (values?: TFilterFormValues) => void;
    onChangeField?: (
        name: string,
        value: TFilterFormValue,
        field: IFilterFormField,
        formikContext: FormikContextType<TFilterFormValues>
    ) => void;
}

export const FilterBar = ({
    disabled,
    disabledFilterSelection,
    placeholder,
    barPrepend,
    barAppend,
    formFilters,
    formFilterValues,
    filters = [],
    useFilterDrawer,
    filterDrawerProps,
    initialSearchValue,
    initialSelected,
    onSearch,
    onSearchClear,
    onSelect,
    onFiltersChange,
    onChangeField,
}: IFilterBarProps) => {
    const [translate] = useTranslation();
    const [selected, setSelected] = useState<TFilterBarItem[]>(
        initialSelected || []
    );

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

    useEffect(() => {
        // If initialSelected isn't changed, don't update the selected state
        if (JSON.stringify(initialSelected) === JSON.stringify(selected)) {
            return;
        }

        setSelected(initialSelected || []);
    }, [initialSelected]);

    const handleChange = (selected: TFilterBarItem[]) => {
        setSelected(selected);
        if (!onSelect) return;
        onSelect(selected);
    };

    const onDelete = (item: TFilterBarItem) => () => {
        handleChange(
            selected.filter(
                ({ value, parent }) =>
                    value !== item.value || parent !== item.parent
            )
        );
    };

    const handleFormSubmit = (values: TFilterFormValues) => {
        onFiltersChange?.(values);
        setFilterDrawerOpen(false);
    };

    const prependChildren = React.Children.map(barPrepend, (child, index) => {
        if (!React.isValidElement(child)) return null;

        return (
            <Grid item xs key={index} sm="auto" sx={{ display: 'flex' }}>
                {child}
            </Grid>
        );
    });

    const appendChildren = React.Children.map(barAppend, (child, index) => {
        if (!React.isValidElement(child)) return null;

        return (
            <Grid item xs key={index} sm="auto" sx={{ display: 'flex' }}>
                {child}
            </Grid>
        );
    });

    const searchBarComponent = (
        <Grid container alignItems="center" spacing={2}>
            {prependChildren}

            <Grid item sm order={{ xs: 1, sm: 0 }} xs={12}>
                <SearchBar
                    disabled={disabled}
                    initialValue={initialSearchValue}
                    placeholder={placeholder}
                    sx={{ width: '100%' }}
                    onClear={onSearchClear}
                    onClickSearch={onSearch}
                    onEnterPress={onSearch}
                />
            </Grid>

            {useFilterDrawer ? (
                <>
                    {!!formFilters?.length && (
                        <Grid item xs sm="auto" sx={{ display: 'flex' }}>
                            <Button
                                startIcon={
                                    <Icon path={mdiFilter} size="1.9rem" />
                                }
                                sx={{ flex: 1 }}
                                variant="outlined"
                                onClick={() => setFilterDrawerOpen(true)}
                            >
                                {translate('filter')}
                            </Button>

                            <FilterDrawer
                                open={filterDrawerOpen}
                                onClose={() => setFilterDrawerOpen(false)}
                                {...filterDrawerProps}
                                formProps={{
                                    ...filterDrawerProps?.formProps,
                                    onChangeField,
                                    filters: formFilters,
                                    filterValues: formFilterValues,
                                    onSubmit: handleFormSubmit,
                                }}
                            />
                        </Grid>
                    )}
                </>
            ) : (
                !!filters.length &&
                !disabledFilterSelection && (
                    <Grid item xs sm="auto" sx={{ display: 'flex' }}>
                        <FilterBarDropdown
                            buttonProps={{ sx: { flex: 1 } }}
                            disabled={disabled}
                            filters={filters}
                            selected={selected}
                            onChange={handleChange}
                        />
                    </Grid>
                )
            )}

            {appendChildren}
        </Grid>
    );

    const deleteFormFilter = (
        field: IFilterFormField,
        value?: TFilterFormValue
    ) => {
        const { name, type } = field;
        const newValues = { ...formFilterValues };

        const formFilter = newValues[name];

        if (!formFilter) return;

        // If we have an object filter array
        if (
            isObjectFilterValue(type, formFilter) &&
            isObjectFilterValue(type, value) &&
            Array.isArray(formFilter) &&
            !Array.isArray(value)
        ) {
            const newFilter = formFilter.filter(
                (filterValue) => filterValue.id !== value?.id
            );

            newValues[name] = newFilter;
        } else if (
            // If we have a string filter array remove the value from the array
            isStringFilterValue(type, formFilter) &&
            isStringFilterValue(type, value) &&
            Array.isArray(formFilter) &&
            !Array.isArray(value)
        ) {
            const newFilter = formFilter.filter(
                (filterValue) => filterValue !== value
            );
            newValues[name] = newFilter;
        } else {
            delete newValues[name];
        }

        onFiltersChange?.(newValues);
    };

    const renderFilterChip = (
        field: IFilterFormField,
        value: TFilterFormValue
    ): React.ReactNode => {
        if (!value) return null;

        const { name, chip, label, type } = field;
        const {
            label: chipLabel,
            parseValue: parseChipValue,
            appendValue = '',
        } = chip || {};

        const labelPrefix = chipLabel || label;

        if (
            parseChipValue &&
            (!Array.isArray(value) || isRangeFilterValue(type, value))
        ) {
            value = parseChipValue(value);
        }

        let valueString = value;

        if (isRangeFilterValue(type, value)) {
            const [from, to] = value;

            valueString = `${from}-${to}${appendValue}`;
        }

        if (isStringFilterValue(type, value) && Array.isArray(value)) {
            // If value is array of strings we render a chip for each string
            return value.map((v) => renderFilterChip(field, v));
        }

        if (isObjectFilterValue(type, value)) {
            // If value is array of objects we render a chip for each object
            if (Array.isArray(value)) {
                return value.map((v) => renderFilterChip(field, v));
            }

            valueString = value.name;
        }

        return (
            <Box key={`${name}-${valueString}`}>
                <Chip
                    label={`${labelPrefix}: ${valueString}`}
                    variant="outlined"
                    onDelete={() => deleteFormFilter(field, value)}
                />
            </Box>
        );
    };

    const filterChips = formFilterValues && formFilters && (
        <ChipGroup sx={{ mt: 2 }}>
            {formFilters.map((filter) =>
                filter.fields.map((field) =>
                    renderFilterChip(field, formFilterValues[field.name])
                )
            )}
        </ChipGroup>
    );

    return (
        <Box sx={{ width: '100%', mb: 1, zIndex: 10 }}>
            {searchBarComponent}
            {filterChips}
            {!!selected.length && (
                <ChipGroup sx={{ mt: 2 }}>
                    {selected.map((item) => (
                        <Tooltip
                            key={`${item.parent}-${item.value}`}
                            sx={{ mt: 1 }}
                            title={item.parentChipName || item.parent || ''}
                        >
                            <Box>
                                <Chip
                                    label={
                                        item.chipName ||
                                        item.displayName ||
                                        item.name
                                    }
                                    variant="outlined"
                                    onDelete={
                                        disabledFilterSelection
                                            ? undefined
                                            : onDelete(item)
                                    }
                                />
                            </Box>
                        </Tooltip>
                    ))}
                </ChipGroup>
            )}
        </Box>
    );
};
