import { TextFieldProps } from 'formik-mui';
import { mdiContentCopy } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Box, SxProps } from '@mui/material';
import { useState } from 'react';

import { Tooltip } from 'common/components/Tooltip';
import { Icon } from 'common/components/Icon';

import { TextField } from '../TextField/TextField';
import { InputAdornment } from '../InputAdornment/InputAdornment';

const mainPopperStyling: SxProps = {
    '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
        {
            mt: 1,
        },
};

const textFieldStyle: SxProps = {
    backgroundColor: 'grey.200',
    zIndex: -1,
    margin: 0,

    '.MuiInputBase-input.Mui-disabled': {
        color: 'text.primary',
        WebkitTextFillColor: 'inherit',
    },
};

export const ClipboardCopyInput = ({ field, ...other }: TextFieldProps) => {
    const [translate] = useTranslation();
    const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);

    let tooltipTimeout: ReturnType<typeof setTimeout>;

    const copyToClipboard = () => {
        if (!field?.value) return;

        clearTimeout(tooltipTimeout);

        setCopiedTooltipOpen(true);

        tooltipTimeout = setTimeout(() => {
            setCopiedTooltipOpen(false);
        }, 1500);

        // Will only work with https
        if (!window.isSecureContext) return;

        // Copy value to the clipboard
        navigator.clipboard.writeText(field?.value);
    };

    return (
        <Tooltip
            open={copiedTooltipOpen}
            PopperProps={{ sx: mainPopperStyling }}
            title={translate('copiedToClipboard')}
        >
            <Box
                sx={{ position: 'relative', cursor: 'pointer', mt: 3 }}
                onClick={copyToClipboard}
            >
                <TextField
                    disabled
                    field={field}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon path={mdiContentCopy} size="2.4rem" />
                            </InputAdornment>
                        ),
                    }}
                    sx={textFieldStyle}
                    {...other}
                />

                <Tooltip title={translate<string>('copy')}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 1,
                            right: '7px',
                            width: 40,
                            height: 40,
                        }}
                    />
                </Tooltip>
            </Box>
        </Tooltip>
    );
};
