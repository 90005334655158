import { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ScormSelectDrawer } from 'training/components/FormField/ScormSelector/ScormSelectDrawer';
import { IScormListItemFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { AddButton } from 'common/components/Button/AddButton';
import {
    getScormSelectStatus,
    isScormAdded,
    isScormSelected,
} from 'training/utils/scormSelect';
import { ScormListItem } from 'training/components/ScormListItem';

interface IProps {
    name?: string;
    value: IScormListItemFragment[];
}

export const ScormSelector = ({ name, value }: IProps) => {
    const { t: translate } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedScorms, setSelectedScorms] = useState<
        IScormListItemFragment[]
    >([]);

    const addedScorms = value;

    const handleDrawerOpen = (open: boolean) => {
        if (!open) setSelectedScorms([]);

        setDrawerOpen(open);
    };

    const handleSelectScorm = (scorm: IScormListItemFragment) => {
        const newScorms = [...selectedScorms, scorm].sort((a, b) =>
            a.title > b.title ? 1 : -1
        );

        setSelectedScorms(newScorms);
    };

    const handleRemoveScorm = (
        scorm: IScormListItemFragment,
        arrayHelpers: FieldArrayRenderProps
    ) => {
        const { isSelected } = getScormSelectStatus(
            selectedScorms,
            addedScorms,
            scorm
        );

        if (isSelected) {
            const newSelectedScorms = selectedScorms.filter(
                (selectedScorm) => selectedScorm.id !== scorm.id
            );

            setSelectedScorms(newSelectedScorms);

            return;
        }

        const index = addedScorms.findIndex(
            (addedScorm) => scorm.id === addedScorm.id
        );

        arrayHelpers.remove(index);
    };

    const handleAddScorms = (arrayHelpers: FieldArrayRenderProps) => {
        selectedScorms.forEach((selectedScorm) => {
            arrayHelpers.push(selectedScorm);
        });

        setSelectedScorms([]);

        handleDrawerOpen(false);
    };

    const fieldName = name || 'scorms';

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const error = form.errors[fieldName];
                const touched = form.touched[fieldName];

                return (
                    <Box>
                        <Box
                            alignItems="left"
                            display="flex"
                            flexDirection="column"
                        >
                            <AddButton onClick={() => handleDrawerOpen(true)}>
                                <Typography>
                                    {translate('addScormsTitle')}
                                </Typography>

                                {error && touched && (
                                    <Box mt="-5px" position="absolute">
                                        <FormHelperText error>
                                            {form.errors[fieldName]}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </AddButton>

                            {!!addedScorms.length && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mt={2}
                                >
                                    {addedScorms.map((addedScorm) => (
                                        <ScormListItem
                                            addButton
                                            added={isScormAdded(
                                                addedScorms,
                                                addedScorm
                                            )}
                                            key={`added-${addedScorm.id}`}
                                            scorm={addedScorm}
                                            selected={isScormSelected(
                                                selectedScorms,
                                                addedScorm
                                            )}
                                            onClick={(
                                                scorm: IScormListItemFragment
                                            ) =>
                                                handleRemoveScorm(
                                                    scorm,
                                                    arrayHelpers
                                                )
                                            }
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>

                        <ScormSelectDrawer
                            addedScorms={addedScorms}
                            open={drawerOpen}
                            selectedScorms={selectedScorms}
                            onAddScorms={() => handleAddScorms(arrayHelpers)}
                            onDrawerOpen={handleDrawerOpen}
                            onRemoveScorm={(scorm) =>
                                handleRemoveScorm(scorm, arrayHelpers)
                            }
                            onSelectScorm={handleSelectScorm}
                        />
                    </Box>
                );
            }}
        />
    );
};
