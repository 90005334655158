import { useMemo, useRef, useState } from 'react';

import { TFilterBarItem } from 'common/types';
import { IContentTypeValue, useExtraCategoryFiltersQuery } from 'graphql/types';
import { TQueryParams } from 'common/utils/getSearchUrl';
import {
    getExtraCategoryFilterItems,
    getExtraCategoryQueryParams,
} from 'organisation/utils/extraCategory';
import { useApolloError } from 'common/hooks/useApolloError';
import { getSelectedFilters } from 'common/utils/filter';

export const useGroupSearch = (initialSelectedFilters?: TFilterBarItem[]) => {
    const { showApolloError } = useApolloError();
    const [searchQueryParam, setSearchQueryParam] = useState('');
    const [queryParams, setQueryParams] = useState<TQueryParams>({});
    const { data, loading } = useExtraCategoryFiltersQuery({
        variables: { contentType: IContentTypeValue.Group },
        onError: showApolloError,
    });
    const prevInitialFilters = useRef(initialSelectedFilters);

    const { extraCategoriesFilters } = data || {};

    // Build a list of filters
    const filters = useMemo(
        () => getExtraCategoryFilterItems(extraCategoriesFilters || []),
        [extraCategoriesFilters]
    );

    const selectedFilters = useMemo(() => {
        const selected = getSelectedFilters(queryParams, filters);

        // If there are no initial selected filters or they havent changed
        // we don't want to add them to the current selected filters
        if (
            !initialSelectedFilters?.length ||
            prevInitialFilters.current === initialSelectedFilters
        ) {
            return selected;
        }

        prevInitialFilters.current = initialSelectedFilters;

        return [...initialSelectedFilters, ...selected];
    }, [queryParams, filters, initialSelectedFilters]);

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        const queryParams = getExtraCategoryQueryParams(selected);

        setQueryParams(queryParams);
    };

    return {
        filters,
        searchQueryParam: searchQueryParam || '',
        selectedFilters,
        filtersLoading: loading,
        handleSearch,
        handleFilterSelect,
    };
};
