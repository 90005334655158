import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import { useApolloError } from 'common/hooks/useApolloError';
import {
    IOfferRole,
    ITrainingRoleFilter,
    useHomePageOfferEventsQuery,
    useHomepageTrainingsQuery,
} from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { HomePageResults } from 'common/components/HomePageResults';

export interface IHomepageSectionStatus {
    loading: boolean;
    hasResults: boolean;
}

interface IProps {
    canUseOffers?: boolean;
    sectionTitle?: React.ReactFragment;
    trainingRole: ITrainingRoleFilter;
    trainer?: boolean;
    setStatus: (status: IHomepageSectionStatus) => void;
}

const OFFER_EVENTS_FROM_DATE = DateTime.now();

export const HomePageSection = ({
    canUseOffers,
    sectionTitle,
    trainingRole,
    trainer,
    setStatus,
}: IProps) => {
    const { showApolloError } = useApolloError();
    const { data: offerEventsData, loading: offerEventsLoading } =
        useHomePageOfferEventsQuery({
            variables: {
                forRoles: trainer
                    ? [IOfferRole.Trainer]
                    : [IOfferRole.Enrolled],
                fromDate: OFFER_EVENTS_FROM_DATE,
            },
            notifyOnNetworkStatusChange: true,
            onError: showApolloError,
            skip: !canUseOffers,
        });
    const { data: trainingsData, loading: trainingsLoading } =
        useHomepageTrainingsQuery({
            variables: {
                role: trainer
                    ? ITrainingRoleFilter.Trainer
                    : ITrainingRoleFilter.Participant,
            },
            notifyOnNetworkStatusChange: true,
            onError: showApolloError,
        });

    const { paginatedOfferEvents } = offerEventsData || {};
    const offerEvents =
        paginatedOfferEvents?.edges
            ?.map((edge) => edge?.node)
            .filter(Boolean) || [];

    const { overviewTrainings } = trainingsData || {};
    const trainings =
        overviewTrainings?.edges?.map((edge) => edge?.node).filter(Boolean) ||
        [];

    const loading = offerEventsLoading || trainingsLoading;

    const hasResults = !loading && (!!offerEvents.length || !!trainings.length);

    useEffect(() => {
        setStatus({ loading, hasResults });
    }, [loading, hasResults]);

    if (loading || !hasResults) return null;

    return (
        <Box sx={{ mb: 6 }}>
            <Box>
                {!!sectionTitle && (
                    <Box alignItems="center" display="flex" mb={1.5}>
                        <Typography variant="h3">{sectionTitle}</Typography>
                    </Box>
                )}

                <HomePageResults
                    offerEvents={offerEvents}
                    trainingRoleFilter={trainingRole}
                    trainings={trainings}
                />
            </Box>
        </Box>
    );
};
