import { Grid, SxProps, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import {
    useCurrentUserQuery,
    usePortfolioSettingsQuery,
    IFeature,
} from 'graphql/types';
import { UserAvatar } from 'user/components/UserAvatar';
import { ListItemAvatar, ListItemText } from 'common/components/ListItem';
import { Avatar } from 'common/components/Avatar';
import { Icon } from 'common/components/Icon';
import { RouteLink } from 'route/components/RouteLink';
import { useOfferTypeFilter } from 'offer/hooks/useOfferTypeFilter';
import { useFeature } from 'common/hooks/useFeature';

const listItemStyle: SxProps = {
    border: '1px solid',
    borderColor: 'divider',
    p: 2,
    color: 'inherit',
    borderRadius: 1,
    height: '100%',

    ':hover': {
        borderColor: 'grey.400',
    },
};

type TRouteProps = React.ComponentProps<typeof RouteLink>;

interface IMenuItem {
    avatar: React.ReactNode;
    title: string;
    routeProps: TRouteProps;
}

interface IProps {
    setLoading: (loading: boolean) => void;
}

export const HomePageQuickMenu = ({ setLoading }: IProps) => {
    const [translate] = useTranslation();
    const { data: user, loading: userLoading } = useCurrentUserQuery();
    const { data: portfolioSettingsData, loading: portfolioSettingsLoading } =
        usePortfolioSettingsQuery();

    const { typeFilters, loading: offerTypesLoading } = useOfferTypeFilter();

    const { canUse: canUseOffers, loading: loadingCanUseOffers } = useFeature(
        IFeature.Offers
    );
    const { canUse: canUsePortfolio, loading: loadingCanUsePortfolio } =
        useFeature(IFeature.Portfolio);

    const loading =
        userLoading ||
        portfolioSettingsLoading ||
        offerTypesLoading ||
        loadingCanUseOffers ||
        loadingCanUsePortfolio;

    const { currentUser } = user || {};
    const userName = currentUser?.name;

    const portfolioPublished =
        !!portfolioSettingsData?.portfolioSettings?.published;

    const menuItems: IMenuItem[] = [];

    if (canUsePortfolio && portfolioPublished) {
        menuItems.push({
            avatar: (
                <UserAvatar
                    bgColor="primary"
                    size={24}
                    sizeSm={48}
                    user={currentUser || undefined}
                />
            ),
            title: userName || translate('portfolio'),
            routeProps: {
                to: 'PORTFOLIO',
            },
        });
    }

    if (canUseOffers) {
        menuItems.push(
            ...typeFilters
                .filter((type) => type.published)
                .map((type) => ({
                    avatar: (
                        <Avatar bgColor="primary" size={24} sizeSm={48}>
                            <Icon
                                path={type.icon}
                                size="1.4rem"
                                sizeSm="2.4rem"
                            />
                        </Avatar>
                    ),
                    title: translate(type.labelTransKey),
                    routeProps: {
                        to: 'OFFER_LIST',
                        query: `?type=${type.key}`,
                    } as TRouteProps,
                }))
        );
    }

    // If we are not loading, and we have more than one menu item or we can use offers, show the menu.
    const showMenu = !loading && menuItems.length > 1;

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    if (!showMenu) return null;

    return (
        <Grid container spacing={{ xs: 1, sm: 3 }}>
            {menuItems.map(({ avatar, title, routeProps }, index) => (
                <Grid item md key={index} sm={6} xs={12}>
                    <ListItem
                        component={RouteLink}
                        sx={listItemStyle}
                        {...routeProps}
                    >
                        <ListItemAvatar>{avatar}</ListItemAvatar>
                        <ListItemText wrapTitle primary={title} />
                    </ListItem>
                </Grid>
            ))}
        </Grid>
    );
};
