import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import { ICreateUpdateAfasModuleInput } from 'graphql/types';
import { Button } from 'common/components/Button';
import { TextField, RadioGroup } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { Radio } from 'common/components/Radio';
import { Divider } from 'common/components/Divider';

export interface IAfasWebhookModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateUpdateAfasModuleInput, 'imageId' | 'offset'> {
    afasType: 'subscription' | 'course';
}

export enum IAfasType {
    Subscription = 'subscription',
    Course = 'course',
}

export interface IProps {
    initialValues?: Partial<IAfasWebhookModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    onSubmit(values: IAfasWebhookModuleFormValues): void;
    onDelete?(): void;
    moduleGroupId?: string;
    onDelete?(): void;
}

export const AfasWebhookModuleForm = ({
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        afasCourseOrEventCode: Yup.string().required(
            translate('validate.required')
        ),
    });

    const initValues: IAfasWebhookModuleFormValues = {
        afasType: IAfasType.Subscription,
        afasCourseOrEventCode: '',
        ...initialValues,
    };

    const formTitle = edit
        ? translate('moduleForm.afasWebhook.editTitle')
        : translate('moduleForm.afasWebhook.newTitle');
    const formText = edit
        ? translate('moduleForm.afasWebhook.editText')
        : translate('moduleForm.afasWebhook.newText');
    const submitText = edit
        ? translate('moduleForm.afasWebhook.editButton')
        : translate('moduleForm.afasWebhook.newButton');

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        {formTitle && formText && (
                            <Box mb={2}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Field component={RadioGroup} name="afasType">
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label={translate(
                                    'moduleForm.afasWebhook.subscription'
                                )}
                                value="subscription"
                            />

                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label={translate(
                                    'moduleForm.afasWebhook.course'
                                )}
                                value="course"
                            />
                        </Field>

                        <Field
                            component={TextField}
                            label={translate(
                                values.afasType === IAfasType.Subscription
                                    ? 'moduleForm.afasWebhook.subscriptionId'
                                    : 'moduleForm.afasWebhook.courseId'
                            )}
                            name="afasCourseOrEventCode"
                        />

                        <Divider sx={{ mt: 3 }} />

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting && !disabled && !dirty)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
