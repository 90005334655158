import { useMemo } from 'react';

import { IContentTypeValue } from 'graphql/types';
import { TFilterFormValues } from 'common/types';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import { IReportFilterValues } from 'report/types';
import {
    filtersToBase64String,
    filtersToQueryParams,
} from 'common/utils/filter';
import { useExtraCategoryFilters } from 'common/hooks/useExtraCategoryFilters';

export const useVideoFilter = (filterParamsInUrl = true) => {
    const { filterParams, setFilterParam } = useUrlFilterParams(
        'video',
        filterParamsInUrl
    );

    const { loading, filters: extraCategoryFilters } = useExtraCategoryFilters(
        IContentTypeValue.Video
    );

    const filters = [...extraCategoryFilters];

    const searchQueryParam = filterParams.q || '';
    const selectedFilterParams = filterParams.filters;

    const handleSearch = (searchValue: string) => {
        setFilterParam('q', searchValue);
    };

    const handleFilterChange = (values?: TFilterFormValues) => {
        const filterString = filtersToBase64String(filters, values);

        setFilterParam('filters', filterString);
    };

    const selectedFilters: IReportFilterValues = useMemo(() => {
        if (!selectedFilterParams) return {};

        try {
            return JSON.parse(window.atob(selectedFilterParams));
        } catch {
            return {};
        }
    }, [selectedFilterParams]);

    // Parse selected filters for query
    const filterValuesForQuery = useMemo(
        () =>
            filtersToQueryParams<IReportFilterValues>(filters, selectedFilters),
        [selectedFilters]
    );

    return {
        filters,
        filterValues: { ...selectedFilters, q: searchQueryParam },
        filterValuesForQuery: { ...filterValuesForQuery, q: searchQueryParam },
        handleSearch,
        handleFilterChange,
        loading,
    };
};
