import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useCourseEditQuery,
    useDeleteCourseMutation,
    IContentTypeValue,
    useUpdateCourseMutation,
} from 'graphql/types';
import { PageDrawer, DrawerActionDropdown } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import { Tab, Tabs } from 'common/components/Tabs';
import { getUrl } from 'route/utils/getUrl';
import { getImageId } from 'common/utils/image';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { getExtraCategoryInitialFormValues } from 'organisation/utils/extraCategory';
import { TCourseFormValues } from 'training/types';
import { TExtraCategoriesFormInput } from 'organisation/types';
import { Loader } from 'common/components/Loader';
import { Link } from 'common/components/Link';
import { Typography } from 'common/components/Typography';
import { Button } from 'common/components/Button';
import { CourseFormItem } from 'training/components/forms/CourseModuleForm/CourseFormItem';
import { CourseForm } from 'training/components/CourseForm';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DescriptionBox } from 'common/components/DescriptionBox';
import { CourseCopyAction } from 'training/actions/CourseCopyAction';
import { AuthorButton } from 'user/components/AuthorButton';
import { TrainingManagementTable } from 'training/components/TrainingManagementTable';
import { NoResultsText } from 'common/components/NoResultsText';
import { useExtraCategoryValues } from 'common/hooks/useExtraCategoryValues';
import { CourseOfferEventsTable } from 'training/components/CourseOfferEventsTable';

type TFormValues = TCourseFormValues & TExtraCategoriesFormInput;

export const CourseUpdateDrawer = ({ open }: IPageDrawerProps) => {
    const [activeTab, setActiveTab] = useState(0);

    const [translate] = useTranslation();
    const navigate = useNavigate();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { id } = useParams();

    const { data: courseData, loading: courseLoading } = useCourseEditQuery({
        variables: { id },
        skip: !id,
        onError: showApolloError,
    });
    const [updateCourse, { loading: updateCourseLoading }] =
        useUpdateCourseMutation();
    const [deleteCourse, { loading: deleteCourseLoading }] =
        useDeleteCourseMutation();

    const {
        extraCategories,
        loading: extraCategoriesLoading,
        handleUpdateExtraCategoryValues,
    } = useExtraCategoryValues({
        contentType: IContentTypeValue.Course,
        modelId: id || '',
        skipQuery: !id,
        typeNames: ['Course', 'CourseListItem'],
    });

    // Make sure we reset the active tab when the drawer is closed
    useEffect(() => {
        if (!open) setActiveTab(0);
    }, [open]);

    const { course } = courseData || {};
    // const { extraCategories } = extraCategoriesData || {};
    const {
        id: courseId,
        ltiInfo,
        extraCategoryValues,
        thumbnail,
        thumbnail: currentThumbnail,
        trainings,
    } = course || {};

    const initialExtraCategoryValues = getExtraCategoryInitialFormValues(
        extraCategories,
        extraCategoryValues
    );

    const initialValues: TFormValues = {
        id: courseId,
        ltiLaunchUrl: ltiInfo?.launchUrl || '',
        ltiKey: ltiInfo?.key || '',
        ltiSecret: ltiInfo?.secret || '',
        thumbnail,
        extraCategories: {
            ...initialExtraCategoryValues,
        },
    };

    const handleSubmit = async (values: TFormValues) => {
        const { thumbnail, extraCategories: extraCategoryUpdateValues } =
            values;

        try {
            await updateCourse({
                variables: {
                    id: courseId,
                    course: {
                        thumbnailId: getImageId(currentThumbnail, thumbnail),
                    },
                },
            });

            await handleUpdateExtraCategoryValues(
                extraCategoryValues || [],
                extraCategoryUpdateValues
            );
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('courseManagementPage.courseActionSuccess.update'),
            {
                variant: 'success',
            }
        );

        handleClose();
    };

    const handleClose = () => {
        navigate(getUrl('COURSE_MANAGEMENT_LIST'));
        setShowDeleteDialog(false);
    };

    const handleDelete = async () => {
        try {
            await deleteCourse({
                variables: { id },
                update: (cache) => {
                    // Remove deleted course from cache to make sure they are gone throughout the system
                    cache.evict({ id: `Course:${id}` });
                    cache.evict({ fieldName: 'courses' });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('courseManagementPage.courseActionSuccess.delete'),
            {
                variant: 'success',
            }
        );

        handleClose();
    };

    const loading = courseLoading || extraCategoriesLoading;

    const trainingList = trainings?.edges
        .map((edge) => edge?.node)
        .filter(Boolean);

    const drawerActions = (
        <Stack direction="row" spacing={1}>
            <AuthorButton
                contentType={IContentTypeValue.Course}
                objectId={courseId}
            />

            <DrawerActionDropdown keepMounted>
                <CourseCopyAction
                    id={courseId}
                    onSuccess={({ id }) =>
                        navigate(getUrl('COURSE_EDIT', { id }))
                    }
                >
                    {({ copyCourse, loading: loadingCopy }) => (
                        <MenuItem disabled={loadingCopy} onClick={copyCourse}>
                            {translate('copy')}
                        </MenuItem>
                    )}
                </CourseCopyAction>
            </DrawerActionDropdown>
        </Stack>
    );

    const permissions = !!course?.permissions
        ? JSON.parse(course.permissions)
        : undefined;

    const canUpdateCourse = permissions?.canUpdate || false;

    const isOfferEventTab = activeTab === 3;

    return (
        <PageDrawer actions={drawerActions} open={open} onClose={handleClose}>
            <PageTitle mixpanelTitle="Drawer update course">
                {`${translate('courseManagementPage.pageTitle')} - ${translate(
                    'courseUpdate.pageTitle'
                )}`}
            </PageTitle>

            <Box p={{ xs: 2, sm: 4 }}>
                {loading && <Loader />}

                {!loading && course && (
                    <>
                        <CourseFormItem
                            course={course}
                            isEditable={canUpdateCourse}
                        />
                        <Tabs sx={{ mt: 1, mb: 4 }} value={activeTab}>
                            <Tab
                                label={translate('courseUpdate.generalTab')}
                                value={0}
                                onClick={() => setActiveTab(0)}
                            />
                            <Tab
                                label={translate('courseUpdate.settingsTab')}
                                value={1}
                                onClick={() => setActiveTab(1)}
                            />
                            <Tab
                                label={translate('courseUpdate.trainingsTab')}
                                value={2}
                                onClick={() => setActiveTab(2)}
                            />
                            <Tab
                                label={translate('courseUpdate.offerEventsTab')}
                                value={3}
                                onClick={() => setActiveTab(3)}
                            />
                        </Tabs>

                        <Box
                            sx={{
                                display: activeTab === 0 ? 'block' : 'none',
                            }}
                        >
                            <DescriptionBox>
                                <Typography
                                    sx={{ fontWeight: 'bold' }}
                                    variant="h3"
                                >
                                    {translate(
                                        'courseUpdate.addCourseToOffer.title'
                                    )}
                                </Typography>
                                <Box mt={1}>
                                    <Typography>
                                        {translate(
                                            'courseUpdate.addCourseToOffer.text'
                                        )}
                                    </Typography>
                                </Box>
                                <Box mt={2}>
                                    <Button
                                        color="primary"
                                        component={Link}
                                        to="OFFER_CREATE"
                                        variant="contained"
                                    >
                                        {translate(
                                            'courseUpdate.addCourseToOffer.button'
                                        )}
                                    </Button>
                                </Box>
                            </DescriptionBox>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                display: activeTab === 1 ? 'block' : 'none',
                            }}
                        >
                            <CourseForm
                                disabled={
                                    updateCourseLoading ||
                                    extraCategoriesLoading
                                }
                                initialValues={initialValues}
                                onDelete={() => setShowDeleteDialog(true)}
                                onSubmit={handleSubmit}
                            />
                        </Box>
                        <Box
                            sx={{ display: activeTab === 2 ? 'block' : 'none' }}
                        >
                            {trainingList?.length ? (
                                <Box sx={{ mt: -4 }}>
                                    <TrainingManagementTable
                                        trainings={trainingList}
                                    />
                                </Box>
                            ) : (
                                <NoResultsText transKey="courseUpdate.noTrainingsForCourse" />
                            )}
                        </Box>

                        <Box
                            sx={{ display: isOfferEventTab ? 'block' : 'none' }}
                        >
                            {isOfferEventTab && (
                                <CourseOfferEventsTable courseId={courseId} />
                            )}
                        </Box>
                    </>
                )}
            </Box>

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={deleteCourseLoading}
                open={showDeleteDialog}
                title={translate('courseUpdate.courseDeleteDialog.title')}
                onCancel={() => {
                    setShowDeleteDialog(false);
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
            >
                {translate('courseUpdate.courseDeleteDialog.text')}
            </ConfirmDialog>
        </PageDrawer>
    );
};
