import { DateTime } from 'luxon';

import { TSelectOption, TFilterBarItem } from 'common/types';
import { TQueryParams } from 'common/utils/getSearchUrl';
import {
    IExtraCategoryFilterEnum,
    IExtraCategoryFilterFragment,
    IExtraCategoryFilterInput,
    IExtraCategoryOption,
    IExtraCategoryFragment,
    IExtraCategoryValuesUpdateInput,
    IExtraCategoryBooleanValue,
    IExtraCategoryChoiceValue,
    IExtraCategoryDatetimeValue,
    IExtraCategoryStringValue,
    IExtraCategoryValueFragment,
    IExtraCategoryChoiceOptionIdRefInput,
    IContentTypeValue,
} from 'graphql/types';
import { i18n } from 'utils';
import {
    TExtraCategoryInput,
    TGroupedExtraCategoryValues,
} from 'organisation/types';

export function getExtraCategoryTypeTranslation(type: IExtraCategoryOption) {
    switch (type) {
        case IExtraCategoryOption.Boolean:
            return i18n.t('extraCategoriesDrawer.types.boolean');
        case IExtraCategoryOption.Choice:
            return i18n.t('extraCategoriesDrawer.types.choice');
        case IExtraCategoryOption.Datetime:
            return i18n.t('extraCategoriesDrawer.types.datetime');
        default:
            return i18n.t('extraCategoriesDrawer.types.string');
    }
}

export function getExtraCategoryModelTypeTranslation(
    modelType: IContentTypeValue
) {
    return i18n.t(
        `extraCategoriesDrawer.modelTypes.${modelType.toLowerCase()}`
    );
}

/*
 * Transforms extra categories into filterbar items
 */
export function getExtraCategoryFilterItems(
    extraCategoryFilters?: IExtraCategoryFilterFragment[]
) {
    if (!extraCategoryFilters) return [];

    return extraCategoryFilters
        .map((extraCategoryFilter: IExtraCategoryFilterFragment) => {
            const { id, name, values, categoryType } = extraCategoryFilter;

            if (
                categoryType === IExtraCategoryOption.Boolean ||
                categoryType === IExtraCategoryOption.Datetime
            ) {
                return null;
            }

            return {
                name,
                children: values.map((value) => ({
                    name: value,
                    value,
                    parent: name,
                    extraCategoryData: {
                        id,
                        type: IExtraCategoryFilterEnum.String, // Both choice and string are handled as string
                    },
                })),
            };
        })
        .filter(Boolean) as TFilterBarItem[];
}

/*
 * Transforms the extra category filterbar items into a query params object
 */
export function getExtraCategoryQueryParams(
    filterBarItems: TFilterBarItem[],
    url?: boolean // When its for a url then we need to encode the values
) {
    return filterBarItems.reduce(
        (acc: { [key: string]: string[] }, curParam) => {
            let paramKey = (curParam.parent ||
                curParam.name) as keyof typeof acc;
            paramKey = url ? encodeURIComponent(paramKey) : paramKey;
            const curVal = url
                ? encodeURIComponent(curParam.value)
                : curParam.value;

            const param = {
                [paramKey]: acc[paramKey]
                    ? [...acc[paramKey], curVal]
                    : [curVal],
            };

            return { ...acc, ...param };
        },
        {}
    ) as TQueryParams;
}

/*
 * Returns the extra category filterbar items as GraphQL query input variables
 */
export function getExtraCategoryInputVariables(
    extraCategoryFilters: TFilterBarItem[]
): IExtraCategoryFilterInput[] {
    return extraCategoryFilters
        .map((filter) => {
            const { value, extraCategoryData } = filter;

            if (!extraCategoryData) return null;

            const { id, type } = extraCategoryData || {};

            return {
                id,
                type,
                stringValue: value,
            };
        })
        .filter(Boolean) as IExtraCategoryFilterInput[];
}

/*
 * Returns the extra category update values
 * Used when updating extra category values in forms of specific content such as groups
 */
export function getExtraCategoryValues(
    extraCategoryValues: TExtraCategoryInput,
    extraCategories?: IExtraCategoryFragment[]
): IExtraCategoryValuesUpdateInput[] {
    if (
        !extraCategoryValues ||
        !Object.keys(extraCategoryValues).length ||
        !extraCategories
    ) {
        return [];
    }

    return Object.keys(extraCategoryValues)
        .map((extraCategoryKey) => {
            let input: IExtraCategoryValuesUpdateInput = {
                categoryId: extraCategoryKey,
            };
            const value = extraCategoryValues[extraCategoryKey];

            const { categoryType, multipleChoices } =
                extraCategories.find(
                    (extraCategory) => extraCategory.id === extraCategoryKey
                ) || {};

            if (!categoryType || !value) return null;

            switch (categoryType) {
                case IExtraCategoryOption.Boolean:
                    input = {
                        ...input,
                        booleanValue: value as boolean,
                    };

                    break;
                case IExtraCategoryOption.Choice:
                    let values: string[];

                    // Convert choice options to uid array (which is an array of values)
                    if (multipleChoices) {
                        values = (value as TSelectOption[]).map(
                            (choiceOption: TSelectOption) => choiceOption.value
                        ) as string[];
                    } else {
                        values = [(value as TSelectOption).value] as string[];
                    }

                    input = {
                        ...input,
                        choiceValues: values.map((choiceValue) => ({
                            id: choiceValue,
                        })) as IExtraCategoryChoiceOptionIdRefInput[],
                    };

                    break;
                case IExtraCategoryOption.Datetime:
                    input = {
                        ...input,
                        datetimeValue: value as DateTime,
                    };

                    break;
                default:
                    input = {
                        ...input,
                        stringValue: value as string,
                    };
            }

            return input;
        })
        .filter(Boolean) as IExtraCategoryValuesUpdateInput[];
}

export function extraCategoryFiltersAreEqual(
    extraCategoryFilters1: IExtraCategoryFilterInput[],
    extraCategoryFilters2: IExtraCategoryFilterInput[]
) {
    if (extraCategoryFilters1.length !== extraCategoryFilters2.length)
        return false;

    extraCategoryFilters1.sort((a, b) => a.id.localeCompare(b.id));
    extraCategoryFilters2.sort((a, b) => a.id.localeCompare(b.id));

    return (
        JSON.stringify(extraCategoryFilters1) ===
        JSON.stringify(extraCategoryFilters2)
    );
}

/*
 * Returns the extra category initial form values
 */
export function getExtraCategoryInitialFormValues(
    extraCategories?: IExtraCategoryFragment[],
    extraCategoryValues: IExtraCategoryValueFragment[] = []
): TExtraCategoryInput {
    if (!extraCategories) return {};

    return extraCategories.reduce((acc, cur) => {
        let value;

        switch (cur.categoryType) {
            case IExtraCategoryOption.Boolean: {
                const extraCategoryWithValue = extraCategoryValues.find(
                    ({ category }) => category.id === cur.id
                );

                const extraCategoryValue = extraCategoryWithValue as
                    | IExtraCategoryBooleanValue
                    | undefined;

                value = extraCategoryValue?.booleanValue || false;

                break;
            }

            case IExtraCategoryOption.Choice: {
                if (cur.multipleChoices) {
                    const extraCategoryChoiceOptions: TSelectOption[] = (
                        extraCategoryValues as IExtraCategoryChoiceValue[]
                    )
                        .filter(({ category }) => category.id === cur.id)
                        .map(({ choiceValue }) => ({
                            label: choiceValue.stringValue,
                            value: choiceValue.id,
                        }));

                    value = extraCategoryChoiceOptions || [];
                } else {
                    const extraCategoryWithValue = extraCategoryValues.find(
                        ({ category }) => category.id === cur.id
                    );

                    const choiceValue = (
                        extraCategoryWithValue as IExtraCategoryChoiceValue
                    )?.choiceValue;

                    value = !!choiceValue
                        ? {
                              label: choiceValue?.stringValue,
                              value: choiceValue?.id,
                          }
                        : undefined;
                }

                break;
            }

            case IExtraCategoryOption.Datetime: {
                const extraCategoryWithValue = extraCategoryValues.find(
                    ({ category }) => category.id === cur.id
                );

                const extraCategoryValue = extraCategoryWithValue as
                    | IExtraCategoryDatetimeValue
                    | undefined;

                value = extraCategoryValue?.datetimeValue || null;

                break;
            }

            default:
                const extraCategoryWithValue = extraCategoryValues.find(
                    ({ category }) => category.id === cur.id
                );

                const extraCategoryValue = extraCategoryWithValue as
                    | IExtraCategoryStringValue
                    | undefined;

                value = extraCategoryValue?.stringValue || '';
        }

        return {
            ...acc,
            [cur.id]: value,
        };
    }, {});
}

/*
 * Checks if the extra category values are updated
 */
export function extraCategoryFormValuesAreUpdated(
    extraCategories?: IExtraCategoryFragment[],
    initialValues?: TExtraCategoryInput,
    newValues?: TExtraCategoryInput
) {
    if (!extraCategories || (!initialValues && !initialValues)) {
        return false;
    }

    const extraCategoryValues = getExtraCategoryValues(
        initialValues || {},
        extraCategories
    );

    const newExtraCategoryValues = getExtraCategoryValues(
        newValues || {},
        extraCategories
    );

    return (
        JSON.stringify(extraCategoryValues) !==
        JSON.stringify(newExtraCategoryValues)
    );
}

/*
 * Type guard function to check if the category group is a string value
 */
function isExtraCategoryStringValue(
    categoryGroup: IExtraCategoryValueFragment
): categoryGroup is IExtraCategoryStringValue {
    return categoryGroup.category.categoryType === IExtraCategoryOption.String;
}

/*
 * Type guard function to check if the category group is a choice value
 */
function isExtraCategoryChoiceValue(
    categoryGroup: IExtraCategoryValueFragment
): categoryGroup is IExtraCategoryChoiceValue {
    return categoryGroup.category.categoryType === IExtraCategoryOption.Choice;
}

/*
 * Group extra category values by category name
 */
export function groupCategoryValuesByCategoryName(
    extraCategoryValues: IExtraCategoryValueFragment[]
) {
    return extraCategoryValues.reduce<TGroupedExtraCategoryValues>(
        (acc, categoryValue) => {
            const { id: categoryId, name } = categoryValue.category;

            let id: string | undefined;
            let value: string | undefined;

            if (isExtraCategoryStringValue(categoryValue)) {
                id = categoryId;
                value = categoryValue.stringValue;
            } else if (isExtraCategoryChoiceValue(categoryValue)) {
                id = categoryValue.choiceValue.id;
                value = categoryValue.choiceValue.stringValue;
            }

            if (id && value) {
                acc[name] = [...(acc[name] || []), { id, label: value }];
            }

            return acc;
        },
        {}
    );
}
