import { Table, TableContainer, TableRow, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { IPortfolioItemReportFragment } from 'graphql/types';
import { OfferEventCertificateTableRow } from 'offer/components/OfferEventCertificateTable';

interface IProps {
    items: IPortfolioItemReportFragment[];
}

export const OfferEventCertificateTable = ({ items }: IProps) => {
    const [translate] = useTranslation();

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3} sx={{ pt: 3 }}>
                            <Typography
                                sx={{ fontWeight: 700 }}
                                variant="body2"
                            >
                                {translate('certificates')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {items.map((item) => (
                        <OfferEventCertificateTableRow
                            certificateItem={item}
                            key={item.id}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
