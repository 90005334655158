import { useEffect, useState } from 'react';

import {
    IExtraCategoryFilterInput,
    IOfferEventEnrollmentManagementQueryVariables,
    IOfferEventSubscriptionStatus,
} from 'graphql/types';
import { DEFAULT_PAGINATION_SETTINGS } from 'common/hooks/usePagination';
import { TFilterBarItem } from 'common/types';
import { getExtraFieldsJSON } from 'common/utils/extraFields';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
    selectedFilters: TFilterBarItem[];
}

export const useEnrollmentsManagementQueryVariables = ({
    paginationVariables,
    searchQueryParam,
    selectedFilters,
}: IProps) => {
    const [queryVariables, setQueryVariables] = useState<
        IOfferEventEnrollmentManagementQueryVariables & {
            extraCategories?: IExtraCategoryFilterInput[];
        }
    >({
        q: '',
        status: IOfferEventSubscriptionStatus.Requested,
        ...DEFAULT_PAGINATION_SETTINGS,
    });

    useEffect(() => {
        let newExtraFields: string | undefined =
            getExtraFieldsJSON(selectedFilters);

        if (Object.keys(JSON.parse(newExtraFields)).length === 0) {
            newExtraFields = undefined;
        }

        const newQueryVariables = {
            ...paginationVariables,
            q: searchQueryParam,
            extraFields: newExtraFields,
        };

        const { q, offset, first, extraFields } = queryVariables;

        if (
            q === newQueryVariables.q &&
            offset === newQueryVariables.offset &&
            first === newQueryVariables.first &&
            extraFields === newQueryVariables.extraFields
        ) {
            return;
        }

        setQueryVariables({
            ...queryVariables,
            ...newQueryVariables,
            offset: q !== newQueryVariables.q ? 0 : newQueryVariables.offset,
        });
    }, [
        queryVariables,
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    ]);

    return { queryVariables };
};
