import MenuItem from '@mui/material/MenuItem';
import { TextField as FormikTextField, TextFieldProps } from 'formik-mui';
import { getIn } from 'formik';

import { Typography } from 'common/components/Typography';

interface IProps extends TextFieldProps {
    options: { value: string; label: string }[];
    displayEmpty?: boolean;
}

export const Select = ({
    options,
    form,
    field,
    displayEmpty,
    ...other
}: IProps) => {
    const fieldError = !!form.submitCount && getIn(form.errors, field.name);

    return (
        <FormikTextField
            fullWidth
            select
            defaultValue=""
            error={!!fieldError}
            field={field}
            form={form}
            helperText={fieldError}
            InputLabelProps={{ shrink: displayEmpty }}
            margin="normal"
            SelectProps={{
                displayEmpty,
            }}
            variant="outlined"
            {...other}
        >
            {options &&
                options.map((option) => (
                    <MenuItem
                        key={option.value}
                        style={{ whiteSpace: 'normal' }}
                        sx={{ py: 0.5 }}
                        value={option.value}
                    >
                        <Typography variant="inherit">
                            {option.label}
                        </Typography>
                    </MenuItem>
                ))}
        </FormikTextField>
    );
};
