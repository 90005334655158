export function getCurrentUrl(encoded: boolean = true) {
    const curPath = window.location.pathname;
    const curSearch = window.location.search;
    const curHash = window.location.hash;

    const curUrl = `${curPath}${curSearch}${curHash}`;

    if (!encoded) return curUrl;

    return encodeURIComponent(curUrl);
}

export function getLoginUrl() {
    const baseUrl = import.meta.env.VITE_BASE_URL || '';
    const curUrl = getCurrentUrl();

    return `${baseUrl}/login?next=${curUrl}`;
}

export function getTwoFactorVerifyUrl() {
    const baseUrl = import.meta.env.VITE_BASE_URL || '';
    const curUrl = getCurrentUrl();

    return `${baseUrl}/otp/verify-token/?next=${curUrl}`;
}

export function getTwoFactorSetupUrl() {
    const baseUrl = import.meta.env.VITE_BASE_URL || '';
    const curUrl = getCurrentUrl();

    return `${baseUrl}/otp/setup-token/?next=${curUrl}`;
}
