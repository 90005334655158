import { useEffect } from 'react';

import {
    useOrganisationLazyQuery,
    IFeature,
    useCurrentUserLazyQuery,
    IRole,
} from 'graphql/types';

const CAN_UPGRADE_ROLES = [IRole.Owner];
const CAN_SEE_UPGRADE_ROLES = [IRole.Owner, IRole.Manager];

/**
 * The useFeature hook can be used to check if a certain feature is enabled for the organisation. You can also use
 * this to check if a user can see the upgrade page and/or can actually upgrade plan
 */
export const useFeature = (feature?: IFeature) => {
    const [
        getOrganisation,
        {
            data: organisationData,
            called: calledOrganisation,
            loading: loadingOrganisation,
        },
    ] = useOrganisationLazyQuery();
    const [
        getCurrentUser,
        { data: userData, called: calledUser, loading: loadingUser },
    ] = useCurrentUserLazyQuery();

    const called = calledOrganisation || calledUser;
    const loading = loadingOrganisation || loadingUser;

    useEffect(() => {
        // When we don't have a feature we don't need to get the organisation and user
        if (!feature) return;

        getOrganisation();
        getCurrentUser();
    }, [feature, getOrganisation, getCurrentUser]);

    // When no feature is given we want to return fixed values
    if (!feature) {
        return {
            canUse: true,
            userCanSeeUpgrade: false,
            userCanUpgrade: false,
            loading: false,
        };
    }

    const organisationFeatures = organisationData?.organisation?.features || [];
    const userRoles = userData?.currentUser?.roles || [];

    // User can see the upgrade page
    const userCanSeeUpgrade = CAN_SEE_UPGRADE_ROLES.some((role) =>
        userRoles.includes(role)
    );

    // User can upgrade organisation plan
    const userCanUpgrade = CAN_UPGRADE_ROLES.some((role) =>
        userRoles.includes(role)
    );

    // Check if organisation features contains the given feature.
    const canUse = organisationFeatures.includes(feature);

    return {
        canUse,
        userCanSeeUpgrade,
        userCanUpgrade,
        loading: loading || !called,
    };
};
