import {
    OptionsObject,
    SnackbarKey,
    SnackbarMessage,
    useSnackbar as useNotiSnackbar,
} from 'notistack';
import { IconButton } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { Icon } from 'common/components/Icon';

const action = (
    key: SnackbarKey,
    closeSnackbar: (key: SnackbarKey) => void
) => (
    <IconButton
        color="inherit"
        size="large"
        onClick={() => {
            closeSnackbar(key);
        }}
    >
        <Icon path={mdiClose} size="2.4rem" />
    </IconButton>
);

export const useSnackbar = () => {
    const { closeSnackbar, enqueueSnackbar } = useNotiSnackbar();

    const displaySnackbar = (
        message: SnackbarMessage,
        options?: OptionsObject
    ) => {
        const snackbarOptions: OptionsObject = {
            anchorOrigin: { vertical: 'top', horizontal: 'left' },
            autoHideDuration: 5000,
            action: (key) => action(key, closeSnackbar),
            disableWindowBlurListener: true,
            ...options,
        };

        enqueueSnackbar(message, snackbarOptions);
    };

    return [displaySnackbar];
};
