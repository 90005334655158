import {
    AccordionSummary as MuiAccordionSummary,
    AccordionSummaryProps,
    Box,
    SxProps,
    Stack,
} from '@mui/material';
import { mdiChevronDown } from '@mdi/js';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';
import { withSxProp } from 'common/utils/props';

const accordionSummaryStyle: SxProps = {
    py: 0,
    px: { xs: 2, sm: 4 },
    minHeight: '64px',
    position: 'relative',

    '&, &.Mui-expanded': {
        py: 0,
        pl: { xs: 2, sm: 4 },
        pr: { xs: 0.75, sm: 2.75 },
    },

    '.MuiAccordionSummary-content': {
        my: { xs: 0, sm: 3 },

        '&, &.Mui-expanded ': {
            my: { xs: 1.5, sm: 3 },
            mx: 0,
        },
    },

    '.MuiAccordionSummary-expandIconWrapper': {
        color: 'inherit',
    },
};

interface IProps extends Omit<AccordionSummaryProps, 'title'> {
    className?: string;
    labels?: React.ReactNode;
    title?: string;
    titlePlaceholder?: string;
    subtitle?: React.ReactNode;
    actions?: React.ReactNode;
    disableExpand?: boolean;
}

export const AccordionSummary = ({
    className,
    disableExpand,
    actions,
    labels,
    title,
    titlePlaceholder,
    subtitle,
    sx,
    ...other
}: IProps) => {
    let expandIcon;

    if (!disableExpand) {
        expandIcon = <Icon path={mdiChevronDown} size="4rem" />;
    }

    let titleComponent;

    if (title) {
        titleComponent = (
            <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                {title}
            </Typography>
        );
    }

    if (!title && titlePlaceholder) {
        titleComponent = (
            <Typography
                sx={{ fontWeight: 'bold', color: 'grey.400' }}
                variant="h3"
            >
                {titlePlaceholder}
            </Typography>
        );
    }

    // Don't render when there's no title and subtitle
    if (!titleComponent && !subtitle) return null;

    return (
        <MuiAccordionSummary
            {...other}
            expandIcon={expandIcon}
            sx={[
                accordionSummaryStyle,
                {
                    '&:hover': {
                        backgroundColor: !disableExpand
                            ? 'action.selected'
                            : 'initial',

                        ':not(.Mui-disabled)': {
                            cursor: disableExpand ? 'default' : 'initial',
                        },
                    },
                },
                ...withSxProp(sx),
            ]}
        >
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={1}
                sx={{ flex: 1, alignItems: { sm: 'center' } }}
            >
                <Box sx={{ flex: 1 }}>
                    {titleComponent}

                    {subtitle && (
                        <Typography color="text.secondary" variant="body2">
                            {subtitle}
                        </Typography>
                    )}
                </Box>
                {labels && (
                    <Box
                        sx={{
                            display: 'flex',
                            mt: { xs: 0, sm: 1 },
                        }}
                    >
                        {labels}
                    </Box>
                )}
            </Stack>

            {!!actions && (
                <Box
                    sx={{
                        display: 'flex',
                        flexShrink: 0,
                        alignItems: 'center',
                        ml: !labels ? 0 : 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            my: -1.25,
                        }}
                    >
                        {actions}
                    </Box>
                </Box>
            )}
        </MuiAccordionSummary>
    );
};
