import _ from 'lodash';

import { IFileFragment } from 'graphql/types';

/*
 * Gets the image id of the newly submitted image
 * Used for the image slider component inside a form
 */
export function getImageId(
    currentImage?: IFileFragment | null,
    newImage?: IFileFragment | null
) {
    let isNewImage = false;

    // Only set new image if it is different from the current one
    if (
        !_.isEqual(newImage, currentImage) || // Deep compare objects using lodash, If one is undefined and the other is null they are not equal
        (newImage && currentImage && currentImage.id !== newImage.id) // If both are set compare by id
    ) {
        isNewImage = true;
    }

    const isEmptyImage = newImage === null;
    let imageId = isNewImage ? newImage?.id : undefined;
    if (isEmptyImage && isNewImage) imageId = null;

    return imageId;
}
