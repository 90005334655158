import { useTranslation } from 'react-i18next';

import { useCreateScormMutation } from 'graphql/types';
import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { ScormForm } from 'training/components/forms/';
import { TScormFormValues } from 'training/components/forms/ScormForm';

interface IProps extends IPageDrawerProps {
    onClose?(): void;
    onCreate?(scormId: string): void;
}

export const ScormCreateDrawer = ({
    open,
    onClose,
    onCreate,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [createScorm, { loading }] = useCreateScormMutation();

    const initialValues: TScormFormValues = {
        title: '',
        fileUrl: '',
        zipFile: undefined,
        thumbnail: undefined,
    };

    const handleSubmit = async (values: TScormFormValues) => {
        const { fileUrl, zipFile, thumbnail, ...otherValues } = values;

        const newValues = {
            ...otherValues,
            scormZipId: zipFile?.id || undefined,
            thumbnailId: thumbnail?.id || undefined,
        };

        try {
            const response = await createScorm({
                variables: {
                    scorm: newValues,
                },
            });

            const scorm = response?.data?.createScorm?.scorm;

            if (scorm) onCreate?.(scorm.id);
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('scormActionSuccess.create'), {
            variant: 'success',
        });

        onClose?.();

        return;
    };

    return (
        <PageDrawer
            disableClose={loading}
            open={open}
            onClose={onClose}
            {...other}
        >
            <PageTitle>{translate('scormForm.pageTitle')}</PageTitle>

            <ScormForm initialValues={initialValues} onSubmit={handleSubmit} />
        </PageDrawer>
    );
};
