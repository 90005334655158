import { mdiChevronRight, mdiMinus, mdiPlus, mdiSchool } from '@mdi/js';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
    ListItemFooter,
} from 'common/components/ListItem';
import { IExternalContentListItemFragment } from 'graphql/types';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    disabled?: boolean;
    selected?: boolean;
    externalContent?: IExternalContentListItemFragment;
    footer?: React.ReactNode;
    hideCategoryChips?: boolean;
    onClick?(externalContent: IExternalContentListItemFragment): void;
}

export const ExternalContentListItem = ({
    addButton,
    added,
    disabled,
    selected,
    externalContent,
    footer,
    onClick,
    hideCategoryChips,
    loading,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();
    const { id, imageUrl, title, extraCategoryValues } = externalContent || {};

    let mediaIconPath = mdiSchool;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }

    const hasIcon = (disabled && !imageUrl) || !disabled;

    const showActionText = !addButton && !disabled && !!externalContent;
    let actionText = (
        <>
            <ListItemActionText>{translate('open')}</ListItemActionText>
            <ListItemSecondaryAction>
                <Icon path={mdiChevronRight} size="3rem" />
            </ListItemSecondaryAction>
        </>
    );

    if (loading) {
        // Show progress icon when loading
        actionText = (
            <ListItemSecondaryAction>
                <CircularProgress color="primary" size={30} />
            </ListItemSecondaryAction>
        );
    }

    return (
        <ListItem
            button={!disabled && !!externalContent}
            key={id}
            px={0}
            selected={selected}
            sx={{
                flexWrap: !!footer ? 'wrap' : undefined,
            }}
            onClick={() =>
                !disabled && !!externalContent && onClick?.(externalContent)
            }
            {...other}
        >
            <ListItemMedia color="primary" image={imageUrl} size="small">
                {hasIcon && (
                    <Icon path={mediaIconPath} size="2rem" sizeSm="3rem" />
                )}
            </ListItemMedia>

            <ListItemText
                primary={
                    !!externalContent ? title : translate('moduleNotAvailable')
                }
            >
                {!!externalContent &&
                    !!extraCategoryValues &&
                    !hideCategoryChips && (
                        <ExtraCategoryLabels
                            extraCategoryValues={extraCategoryValues}
                        />
                    )}
            </ListItemText>

            {showActionText && actionText}

            {footer && !!externalContent && (
                <ListItemFooter>{footer}</ListItemFooter>
            )}
        </ListItem>
    );
};
