import { Chip } from 'common/components/Chip';
import { IOfferEventSubscriptionStatus } from 'graphql/types';
import { getOfferEventSubscriptionStatusData } from 'offer/utils/offerEvent';

interface IProps {
    subscriptionStatus: IOfferEventSubscriptionStatus;
    hasCertificate?: boolean;
}

export const OfferEventSubscriptionStatusChip = ({
    subscriptionStatus,
    hasCertificate,
}: IProps) => {
    const chipData = getOfferEventSubscriptionStatusData(
        subscriptionStatus,
        hasCertificate
    );

    if (!chipData) return null;

    const { color, label } = chipData;

    return <Chip bgColor={color} key={subscriptionStatus} label={label} />;
};
