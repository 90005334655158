import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ICourseFragment, useCopyCourseMutation } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

interface IChildrenParams {
    loading: boolean;
    copyCourse(): void;
}

interface IProps {
    id: string;
    onSuccess?(newCourse: ICourseFragment): void;
    children(params: IChildrenParams): React.ReactNode;
}

export const CourseCopyAction = ({ id, onSuccess, children }: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [displaySnackbar] = useSnackbar();

    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const [copyCourse, { loading: loadingCopy }] = useCopyCourseMutation();

    const handleCopyCourse = async () => {
        let newCourse: ICourseFragment | undefined;

        try {
            const response = await copyCourse({
                variables: {
                    id,
                },
                update: (cache) => {
                    // Remove course list from cache so it will be refetched
                    cache.evict({ fieldName: 'courses' });
                    cache.gc();
                },
            });

            newCourse = response.data?.copyCourse?.course;
        } catch (error) {
            showApolloError(error);
            setShowCopyDialog(false);

            return;
        }

        if (!newCourse) return;

        displaySnackbar(translate('courseActionSuccess.copy'), {
            variant: 'success',
        });

        setShowCopyDialog(false);
        onSuccess?.(newCourse);
    };

    return (
        <>
            {children({
                copyCourse: () => {
                    !loadingCopy && setShowCopyDialog(true);
                },
                loading: loadingCopy,
            })}

            <ConfirmDialog
                confirmText={translate('copy')}
                loading={loadingCopy}
                open={showCopyDialog}
                title={translate('copyCourseMessage.title')}
                onCancel={() => {
                    setShowCopyDialog(false);
                }}
                onClose={() => setShowCopyDialog(false)}
                onConfirm={handleCopyCourse}
            >
                {translate('copyCourseMessage.text')}
            </ConfirmDialog>
        </>
    );
};
