import { CircularProgress, Box } from '@mui/material';
import { styled } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { COLORS } from 'theme/palette';
import {
    FALLBACK_FONT_FAMILY,
    DEFAULT_TYPOGRAPHY,
} from 'theme/createTypography';

interface IProps {
    children?: React.ReactNode;
    visible?: boolean;
    fadeIn?: boolean;
}

const BaseLoaderOverlay = ({ children, visible, fadeIn, ...other }: IProps) => (
    <AnimatePresence>
        {visible && (
            <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: fadeIn ? 0 : 1 }}
                transition={{ duration: 0.3 }}
                {...other}
            >
                <div className="content">
                    <CircularProgress className="progress" />
                    {!!children && <Box>{children}</Box>}
                </div>
            </motion.div>
        )}
    </AnimatePresence>
);

// This component has theme fallbacks so it can also be used outside the theme context
export const LoaderOverlay = styled(BaseLoaderOverlay)`
    ${({ theme }) => `
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        padding: ${theme.spacing ? theme.spacing(4) : 32}px;

        background-color: ${
            theme.palette ? theme.palette.background.default : '#fff'
        };

        display: flex;
        justify-content: center;
        text-align: center;

        // The content of this component is positioned absolute so that the spinner is center and
        // doesn't jump when we load another LoaderOverlay
        .content {
            position: absolute;
            top: calc(50% - 20px); // 20px is half of the spinner
            max-width: 400px;

            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            font-size: ${
                theme.typography
                    ? theme.typography.h5.fontSize
                    : DEFAULT_TYPOGRAPHY.h5.size * 10 + 'px' // Calculate to px
            };
            font-family: ${
                theme.typography
                    ? theme.typography.h5.fontFamily
                    : FALLBACK_FONT_FAMILY
            };
            font-weight: ${
                theme.typography
                    ? theme.typography.h5.fontWeight
                    : DEFAULT_TYPOGRAPHY.h5.weight
            };
            line-height: ${
                theme.typography
                    ? theme.typography.h5.lineHeight
                    : DEFAULT_TYPOGRAPHY.h5.lineHeight
            };
            color: ${theme.palette ? theme.palette.body.main : COLORS.body};
        }

        .progress {
            color: ${theme.palette?.secondary.main || COLORS.darkGrey};
            margin-bottom: ${theme.spacing ? theme.spacing(4) : 32}px;
        }
    `}
`;
