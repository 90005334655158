import { ILoadFont, ELoadFontWeight, EFontStyle } from 'common/types';
import { getEnumKeyByValue } from 'common/utils/enum';

const FONTS_BASE_URL = 'https://fonts.pluvo.com';

/**
 * Load fonts
 *
 * @param fonts Fonts to load
 * @returns Promise that resolves when all fonts are loaded
 */
export function loadFonts(fonts: ILoadFont[]) {
    const loadFontsPromise = fonts.map(
        ({ family, weights, styles = [EFontStyle.Normal] }) =>
            Promise.all(
                styles?.map((style) => {
                    weights.map(async (weight) => {
                        // If weight is a string, convert it to a number.
                        if (typeof weight === 'number') weight = `${weight}`;

                        // Get name of the font weight, e.g. Normal or Bold
                        let weightName: string = getEnumKeyByValue(
                            ELoadFontWeight,
                            weight
                        );
                        // Get name of the font style, e.g. Normal or Italic
                        let styleName: string = getEnumKeyByValue(
                            EFontStyle,
                            style
                        );
                        // If style is Normal clear the style name
                        if (style === EFontStyle.Normal) styleName = '';

                        // If style is Italic and weight is Normal, clear the weight
                        if (
                            style === EFontStyle.Italic &&
                            weight === ELoadFontWeight.Normal
                        ) {
                            weightName = '';
                        }

                        // Get the family name without spaces
                        const familyName = decodeURI(family).replace(/\s/g, '');

                        const fontDirName = familyName.toLowerCase();

                        const fontFileName = `${weightName}${styleName}`;
                        const fontUrl = `${FONTS_BASE_URL}/${fontDirName}/${familyName}-${fontFileName}`;
                        const fontFace = new FontFace(
                            family,
                            `url("${fontUrl}.woff2") format("woff2"), url("${fontUrl}.woff") format("woff")`,
                            { weight, style }
                        );

                        try {
                            const loadedFace = await fontFace.load();
                            document.fonts.add(loadedFace);
                        } catch (e) {
                            // If font cannot be loaded we just continue;
                            console.error(
                                `Font ${family} ${weight} ${style} could not be loaded: ${e}`
                            );
                        }
                    });
                })
            )
    );

    return Promise.all(loadFontsPromise);
}
