import { ApolloError, useApolloClient, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { TDateRange } from 'common/types';
import {
    PortfolioDocument,
    useCreatePortfolioItemMutation,
    usePortfolioQuery,
    usePortfolioScoresQuery,
} from 'graphql/types';
import { IPortfolioGroup } from 'user/types';
import { prevPortfolioDateFilterVar } from 'user/components/UserProfile/UserProfile';

export const usePortfolio = (
    dateFilter: TDateRange | undefined,
    userId: string,
    onCreateCustomPortfolioItemSuccess: (portfolioItemId: string) => void
) => {
    const client = useApolloClient();
    const prevDateFilter = useReactiveVar(prevPortfolioDateFilterVar);

    const getSortedPortfolio = (portfolio: IPortfolioGroup[]) =>
        [...portfolio].sort((a, b) => Number(b.isInbox) - Number(a.isInbox));

    const {
        data: portfolioData,
        loading: portfolioLoading,
        error: portfolioError,
        refetch: portfolioRefetch,
    } = usePortfolioQuery({
        variables: {
            userId,
            startDate: dateFilter?.startDate?.toISODate(),
            endDate: dateFilter?.endDate?.toISODate(),
        },
    });
    const {
        data: portfolioScoresData,
        loading: portfolioScoresLoading,
        error: portfolioScoresError,
    } = usePortfolioScoresQuery({
        variables: {
            userId,
            startDate: dateFilter?.startDate?.toISODate(),
            endDate: dateFilter?.endDate?.toISODate(),
        },
    });

    useEffect(() => {
        if (
            dateFilter?.startDate === prevDateFilter?.startDate &&
            dateFilter?.endDate === prevDateFilter?.endDate
        ) {
            return;
        }

        // Set current date filter as previous date filter
        prevPortfolioDateFilterVar(dateFilter);

        // Manually refetching portfolio because the query setup won't allow
        // to cache properly
        portfolioRefetch({
            userId,
            startDate: dateFilter?.startDate?.toISODate(),
            endDate: dateFilter?.endDate?.toISODate(),
        });
    }, [dateFilter, prevDateFilter, portfolioRefetch, userId]);

    const [
        createCustomPortfolioItem,
        { error: createError, loading: createLoading },
    ] = useCreatePortfolioItemMutation({
        update: (cache, { data: newCustomPortfolioItemData }) => {
            const newCustomPortfolioItem =
                newCustomPortfolioItemData?.createPortfolioItem.portfolioItem;

            const portfolioItemGroup = newCustomPortfolioItem?.group;

            if (!newCustomPortfolioItem || !portfolioItemGroup) {
                return;
            }

            client.refetchQueries({ include: ['PortfolioScores'] });

            onCreateCustomPortfolioItemSuccess(newCustomPortfolioItem.id);
        },
    });

    // Set groups in cache
    const setGroups = (groups: IPortfolioGroup[]) => {
        client.cache.writeQuery({
            query: PortfolioDocument,
            variables: {
                userId,
            },
            data: {
                portfolio: {
                    ...portfolioData?.portfolio,
                    portfolioGroups: groups,
                },
            },
        });
    };

    const { portfolioGroups } = portfolioData?.portfolio || {};
    const groups = portfolioGroups ? getSortedPortfolio(portfolioGroups) : [];

    const { scores } = portfolioScoresData?.portfolio || {};

    return {
        portfolioLoading: portfolioLoading || portfolioScoresLoading,
        portfolioErrors: [portfolioError, portfolioScoresError].filter(
            Boolean
        ) as ApolloError[],
        groups,
        scores,
        createError,
        createLoading,
        setGroups,
        createCustomPortfolioItem,
    };
};
