import {
    Box,
    Stack,
    SxProps,
    useMediaQuery,
    Theme,
    Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ITableSelectionProps } from 'common/types';
import { Checkbox } from 'common/components/Checkbox';
import { Typography } from 'common/components/Typography';
import { Sticky } from 'common/components/Sticky';

interface IProps<T> {
    actions?: React.ReactNode;
    selectionProps?: ITableSelectionProps<T>;
    disabled?: boolean;
    inDrawer?: boolean;
}

const tableActionBarStyle: SxProps = {
    display: 'flex',
    alignItems: 'center',
    p: 1,
    borderBottom: 1,
    borderBottomColor: 'divider',
    zIndex: 9,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    // Height is the same is the default TableHead - 1px border
    height: '59px',
    backgroundColor: 'common.white',
};

export const TableActionBar = <T extends object>({
    actions,
    disabled,
    selectionProps,
    inDrawer,
}: IProps<T>) => {
    const [translate] = useTranslation();
    const smallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.only('xs')
    );

    const { isSelected, isSelectedAll, selected, onSelectAll } =
        selectionProps || {};

    if (!isSelected || !selected) return null;

    return (
        <Sticky
            offset={inDrawer ? 0 : 81}
            scrollContainer=".MuiDrawer-paper"
            zIndex={inDrawer ? 1201 : 5}
        >
            <Stack
                useFlexGap
                direction="row"
                spacing={1}
                sx={tableActionBarStyle}
            >
                <Checkbox
                    checked={isSelectedAll}
                    disabled={disabled}
                    indeterminate={!isSelectedAll && isSelected}
                    onChange={onSelectAll}
                />

                {!smallScreen && (
                    <>
                        <Box sx={{ mx: 1 }}>
                            <Typography fontWeight={700} variant="body2">
                                {translate('selectedCount', {
                                    value: selected.length,
                                })}
                            </Typography>
                        </Box>
                        {actions && <Divider flexItem orientation="vertical" />}
                    </>
                )}
                <Box sx={{ height: '100%' }}>{actions}</Box>
            </Stack>
        </Sticky>
    );
};
