import { styled } from 'styled-components';
import MuiListItemSecondaryAction, {
    ListItemSecondaryActionProps,
} from '@mui/material/ListItemSecondaryAction';

interface IProps extends ListItemSecondaryActionProps {
    hideXs?: boolean;
}

const BaseListItemSecondaryAction = ({ hideXs, ...other }: IProps) => (
    <MuiListItemSecondaryAction {...other} />
);

export const ListItemSecondaryAction = styled(BaseListItemSecondaryAction)`
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    position: static;
    transform: none;
    display: ${({ hideXs }) => (hideXs ? 'none' : 'flex')};
    color: ${({ theme }) => theme.palette.text.primary};

    > span {
        display: flex;
    }

    > .MuiIconButton-root,
    > span {
        color: ${({ theme }) => theme.palette.text.primary};
        height: 100%;
        width: 100%;
        padding: 5px;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
    }
`;
