import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FOUROFOUR_IMAGE from 'assets/images/404.webp';
import { Img } from 'common/components/Img';

export const NotFound = () => {
    const { t: translate } = useTranslation();

    return (
        <Box mt={15} textAlign="center">
            <Box maxWidth={400} mb={4} mx="auto">
                <Img src={FOUROFOUR_IMAGE} />
            </Box>
            <Box mb={2}>
                <Typography variant="h1">
                    {translate('notFound.title')}
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography>{translate('notFound.text')}</Typography>
            </Box>
        </Box>
    );
};
