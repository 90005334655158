import { DateTime } from 'luxon';

import {
    ICreateOfferEventInput,
    ICreateOfferInput,
    IFileFragment,
    IOfferEventDateInput,
    IOfferEventExtraChoiceField,
    IOfferEventExtraStringField,
    IUserListItemFragment,
    ITrainingListItemFragment,
    IExternalContentListItemFragment,
    ICourseListItemFragment,
    Maybe,
    IUpdateOfferInput,
    IOfferEventType,
    ICertificateInputOptionFragment,
    IOfferEventEnrollmentManageableFragment,
    IOfferEventEnrollmentFragment,
    IScormListItemFragment,
    IVideoListItemFragment,
} from 'graphql/types';
import { IGroupConditionInput } from 'user/types';
import {
    TExtraCategoryInput,
    TExtraCategoriesFormInput,
} from 'organisation/types';

export type TExtraOfferEventField =
    | IOfferEventExtraStringField
    | IOfferEventExtraChoiceField;

export type TCreateOfferFormValues = {
    image?: IFileFragment;
    title: string;
    description?: string;
    titleColor?: string | null;
    overviewImage?: IFileFragment;
    hasDetailPage?: boolean;
};

export type TOfferFormValues = {
    offer: ICreateOfferInput & TExtraCategoriesFormInput;
    event: ICreateOfferEventInput & {
        offerEventTitle?: string;
        certificate?: ICertificateInputOptionFragment;
    };
};

export type TWebinarFormValues = {
    isServiceProviderWebinar?: boolean;
    dates?: IOfferEventDateInput[];
    closingDate?: string | DateTime;
};

export type TMeetingFormValues = {
    dates?: IOfferEventDateInput[];
    closingDate?: string | DateTime;
};

export type TTrainingFormValues = {
    accessTrainings: ITrainingListItemFragment[];
    startDate?: string | DateTime;
    endDate?: string | DateTime;
    closingDate?: string | DateTime;
};

export type TOfferEventValues = {
    offerEventTitle?: string;
    certificate?: ICertificateInputOptionFragment;
    trainers: IUserListItemFragment[];
    trainerApproval: boolean;
    minSubscriptions?: number;
    maxSubscriptions?: number;
    waitingList?: boolean;
    price?: number;
    descriptionBeforeSubscription?: string;
    descriptionAfterSubscription?: string;
    accessTrainings: ITrainingListItemFragment[];
    accessExternalContents?: IExternalContentListItemFragment[];
    accessCourses?: ICourseListItemFragment[];
    showParticipantList: boolean;
    groupConditions?: IGroupConditionInput[];
    type: IOfferEventType;
    accessScorms?: IScormListItemFragment[];
    accessVideos?: IVideoListItemFragment[];
    scoreThreshold?: number;
};

export type TOfferEventFormValues = {
    publish?: boolean;
} & TOfferEventValues &
    Maybe<TCreateOfferFormValues> &
    Maybe<TExtraCategoriesFormInput> &
    Maybe<TWebinarFormValues> &
    Maybe<TMeetingFormValues> &
    Maybe<TTrainingFormValues>;

export type TUpdateOfferFormInput = IUpdateOfferInput & {
    image?: IFileFragment;
    overviewImage?: IFileFragment;
    extraCategories: TExtraCategoryInput;
};

export type TOfferPermissions = {
    canUpdate: boolean;
    canDelete: boolean;
};

export type TOfferEventPermissions = {
    canManageSubscriptions: boolean;
};

export enum EOfferEventCategoryType {
    Meeting = 'MEETING',
    Offer = 'OFFER',
}

export type TOfferEventDetailEnrollment =
    | IOfferEventEnrollmentManageableFragment
    | IOfferEventEnrollmentFragment;
