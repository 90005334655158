import { Editor, Transforms } from 'slate';
import { setDefaults, getBlockAbove } from '@udecode/slate-plugins';

import { DEFAULTS_FILE } from './FilePlugin';
import { IFilePluginOptions } from './types';

export const insertFile = (
    editor: Editor,
    url: string,
    options?: IFilePluginOptions<'type'>
) => {
    const { file } = setDefaults(options, DEFAULTS_FILE);

    const text = { text: '' };
    const newFile = { type: file.type, url, children: [text] };

    Transforms.insertNodes(editor, newFile);
};

export const addOrReplaceFile = (
    editor: Editor,
    url: string,
    options?: IFilePluginOptions<'type'>,
    progress?: number
) => {
    const { file } = setDefaults(options, DEFAULTS_FILE);

    const fileNode = getBlockAbove(editor, {
        match: { type: file.type },
    });

    if (fileNode) {
        Transforms.setNodes(editor, {
            url,
            progress,
        });

        return;
    }

    insertFile(editor, url, { file });
};
