import { Box, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { DatePicker, Switch, TextField } from 'common/components/FormField';
import { TLTIPlatformFormValues } from 'connections/types';

export const AccessForm = () => {
    const [translate] = useTranslation();
    const { values } = useFormikContext<TLTIPlatformFormValues>();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="lTIPlatformForm.accessForm.accessDescription"
                    />
                </Box>
            }
            title={translate('lTIPlatformForm.accessForm.accessTitle')}
        >
            <Box mt={1}>
                <Field
                    checked={values.isActive}
                    component={Switch}
                    label={translate('lTIPlatformForm.accessForm.active')}
                    name="isActive"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'lTIPlatformForm.accessForm.maxParticipants'
                    )}
                    name="maxUsers"
                />

                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <Field
                            component={DatePicker}
                            label={translate(
                                'lTIPlatformForm.accessForm.start'
                            )}
                            name="startDate"
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <Field
                            component={DatePicker}
                            label={translate('lTIPlatformForm.accessForm.end')}
                            name="endDate"
                        />
                    </Grid>
                </Grid>

                <Box mb={-2}>
                    <Field
                        checked={values.allowAllMaterial}
                        component={Switch}
                        label={translate(
                            'lTIPlatformForm.accessForm.allAccess'
                        )}
                        name="allowAllMaterial"
                    />
                </Box>
            </Box>
        </FormSection>
    );
};
