import { mdiDotsVertical } from '@mdi/js';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';

import { AppBar } from 'common/components/AppBar';
import { IconButton } from 'common/components/IconButton';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Link } from 'common/components/Link';
import { ContentContainer } from 'common/components/ContentContainer';
import { LtiDeepLinkTabsMenu } from 'connections/components/LtiDeepLinkTabsMenu';
import { LtiDeepLinkTrainings } from 'connections/components/LtiDeepLinkTrainings';
import {
    ICourseListItemFragment,
    ITrainingListItemFragment,
    useLtiDeepLinkResponseMutation,
} from 'graphql/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { LtiDeepLinkCourses } from 'connections/components/LtiDeepLinkCourses';
import { getUrl } from 'route/utils/getUrl';
import { useFrontendPermissions } from 'user/hooks';
import { useApolloError } from 'common/hooks/useApolloError';

export const LtiDeepLinkPage = () => {
    const [translate] = useTranslation();
    const { launchId } = useParams();
    const [LtiDeepLink, { loading: loadingDeeplinkResponse }] =
        useLtiDeepLinkResponseMutation();
    const trainings = !!useRouteMatch('CONNECTIONS_LTI_TRAININGS');
    const { canUpdate } = useFrontendPermissions('ltiDeepLink');
    const { showApolloError } = useApolloError();

    if (!launchId || !canUpdate) {
        return <Navigate to={getUrl('PAGE_NOT_FOUND')} />;
    }

    const handleLTIDeepLink = async (
        selectedTrainings?: ITrainingListItemFragment[],
        selectedCourses?: ICourseListItemFragment[]
    ) => {
        if (!selectedTrainings && !selectedCourses) return;

        let response;

        try {
            response = await LtiDeepLink({
                variables: {
                    launchId,
                    trainingIds:
                        selectedTrainings?.map((training) => training.id) || [],
                    courseIds:
                        selectedCourses?.map((course) => course.id) || [],
                },
            });
        } catch (error) {
            showApolloError(error);
        }

        const { returnUrl, token } =
            response?.data?.lti1p3DeepLinkResponse || {};

        if (!returnUrl || !token) return;

        // Create form with JWT input and add it to the body to be able to submit
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = returnUrl;

        const jwtInput = document.createElement('input');
        jwtInput.setAttribute('name', 'JWT');
        jwtInput.setAttribute('value', token);

        form.appendChild(jwtInput);
        document.body.appendChild(form);

        form.submit();
    };

    const navigation = (
        <Box color="text.primary" ml="auto">
            <DropdownMenu
                anchor={
                    <IconButton
                        color="inherit"
                        iconPath={mdiDotsVertical}
                        iconSize="2.4rem"
                    />
                }
            >
                <MenuItem component={Link} target="_blank" to="HOME">
                    {translate('openPluvo')}
                </MenuItem>
            </DropdownMenu>
        </Box>
    );

    return (
        <>
            <AppBar navigation={navigation} />

            <ContentContainer maxWidth="md">
                <Box py={{ xs: 4, sm: 8 }}>
                    <LtiDeepLinkTabsMenu />

                    {trainings && (
                        <LtiDeepLinkTrainings
                            loadingDeeplink={loadingDeeplinkResponse}
                            onSelectTrainings={(
                                selectedTrainings: ITrainingListItemFragment[]
                            ) => handleLTIDeepLink(selectedTrainings)}
                        />
                    )}

                    {!trainings && (
                        <LtiDeepLinkCourses
                            loadingDeeplink={loadingDeeplinkResponse}
                            onSelectCourses={(
                                selectedCourses: ICourseListItemFragment[]
                            ) => handleLTIDeepLink(undefined, selectedCourses)}
                        />
                    )}
                </Box>
            </ContentContainer>
        </>
    );
};
