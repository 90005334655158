import { TObjectFilterValue } from 'common/types';
import {
    IUserProfileFragment,
    IFile,
    IUserExtraDateField,
    IUserExtraStringField,
    IUserExtraChoiceField,
    IUserExtraCheckboxField,
    IPortfolioItemGroupFragment,
    IPortfolioItemListItemOldFragment,
    IGroupListItemFragment,
    IUpdatePortfolioItemInput,
    ICreatePortfolioItemInput,
    IAppPermissionsFragment,
    ICreateUserInput,
    IRole,
    IUpdateGroupInput,
} from 'graphql/types';
import { TExtraCategoriesFormInput } from 'organisation/types';

export interface IUserProfileSectionProps
    extends Omit<IUserProfileFragment, 'currentUserIsGroupManager'> {
    withEditActions?: boolean;
    className?: string;
}

export type IUserPortfolioItemProps<T> = T & {
    className?: string;
    withEditActions?: boolean;
    onClick?(): void;
};

export interface IUserAvatar {
    profileImage: IFile;
    name: string;
}

export type TExtraUserField =
    | IUserExtraDateField
    | IUserExtraStringField
    | IUserExtraChoiceField
    | IUserExtraCheckboxField;

export enum IPortfolioItemType {
    Custom = 'CustomPortfolioItem',
    RelatedItem = 'RelatedPortfolioItem',
}

export type TPortfolioItemFormValues =
    | ICreatePortfolioItemInput
    | IUpdatePortfolioItemInput;

export interface IPortfolioGroup extends IPortfolioItemGroupFragment {
    portfolioItems: IPortfolioItemListItemOldFragment[];
}

export interface IGroupConditionInput {
    id: string;
    groups: IGroupListItemFragment[];
    extraCategoryValues: {
        id: string;
        stringValue: string;
        color: string;
    }[];
}

export type TPermissionValue = Record<string, boolean | undefined>;

export type TPermissionsKey = keyof Omit<
    Required<IAppPermissionsFragment>,
    '__typename'
>;
export type TPermissionTypeKey<T extends TPermissionsKey> = keyof Omit<
    NonNullable<IAppPermissionsFragment[T]>,
    '__typename'
>;

export type TAppPermissionsObj = {
    [K in TPermissionsKey]: {
        [P in TPermissionTypeKey<K>]: TPermissionValue;
    };
};

export interface ICreateUserFormValues extends ICreateUserInput {
    sendInvite?: boolean;
    sendTrainingInvite?: boolean;
}

export enum TFrontendRole {
    Author = IRole.Author,
    Manager = IRole.Manager,
    Owner = IRole.Owner,
    Planner = IRole.Planner,
    Trainer = IRole.Trainer,
    GroupManager = 'GROUP_MANAGER',
}

export interface IUserFilterValues {
    userGroups: TObjectFilterValue[];
}

export type TGroupPermission = {
    canAddManagers?: boolean;
    canAddParticipants?: boolean;
};

export type TGroupFormValues = IUpdateGroupInput & TExtraCategoriesFormInput;

export enum EGroupUserRole {
    Participant = 'Participant',
    Manager = 'Manager',
}
