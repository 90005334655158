import { Box, Grid, Stack, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

import HEADER_IMAGE from 'assets/images/academy-management-header.webp';
import { PageDrawer } from 'common/components/PageDrawer';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { UpgradePlanButton } from 'common/components/Button/UpgradePlanButton';
import {
    IMainMenu,
    IRole,
    useCurrentUserQuery,
    useMenuItemsQuery,
    useOrganisationPlanSubscriptionLazyQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { MAIN_MENU } from 'common/constants/mainMenu';
import { Loader } from 'common/components/Loader';
import { Link } from 'common/components/Link';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';

const subItemLinkStyle: SxProps = {
    ':hover': {
        color: 'text.secondary',
    },
};

export const AcademyManagementDrawer = () => {
    const [translate] = useTranslation();
    const isManagementPage = useHashMatch('MANAGEMENT_ACADEMY');
    const { showApolloError } = useApolloError();
    const { data: menuItemsData, loading: menuItemsLoading } =
        useMenuItemsQuery({ onError: showApolloError });
    const [
        getOrganisationPlanSubscription,
        {
            data: organisationPlanSubscriptionData,
            loading: organisationPlanSubscriptionLoading,
        },
    ] = useOrganisationPlanSubscriptionLazyQuery();
    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};
    const { roles } = currentUser || {};
    const canSeePlanTrialExpiredPage =
        roles?.includes(IRole.Owner) || roles?.includes(IRole.Manager);

    const { organisation } = organisationPlanSubscriptionData || {};
    const { menuItems } = menuItemsData || {};

    const showUpgradePlanButton = !!menuItems?.find(
        (item) => item.menu === IMainMenu.UpgradePlan
    );
    const managementMenu = menuItems?.find(
        (item) => item.menu === IMainMenu.Management
    );

    const loading =
        menuItemsLoading || organisationPlanSubscriptionLoading || loadingUser;

    const { trialExpires } = organisation || {};

    let trialPeriodDays;

    if (canSeePlanTrialExpiredPage && !!trialExpires) {
        const dateNow = DateTime.now();
        const trialExpiryDate = DateTime.fromISO(trialExpires);
        const dayDifference = trialExpiryDate.diff(dateNow, 'days').toObject();
        trialPeriodDays = dayDifference.days
            ? Math.ceil(dayDifference.days)
            : 0;
    }

    useEffect(() => {
        if (!canSeePlanTrialExpiredPage) return;

        getOrganisationPlanSubscription();
    }, []);

    return (
        <PageDrawer open={isManagementPage}>
            <DrawerHeaderImage src={HEADER_IMAGE} />

            {loading && <Loader />}

            {!loading && (
                <Box p={{ xs: 2, sm: 4 }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        sx={{
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h2">
                            {translate('academyManagement')}
                        </Typography>

                        {showUpgradePlanButton && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: { sm: 'center' },
                                    mt: { xs: 2, sm: 0 },
                                    flexDirection: { xs: 'column', sm: 'row' },
                                }}
                            >
                                {!!trialPeriodDays && (
                                    <Typography
                                        color="error.main"
                                        sx={{
                                            mr: { sm: 2 },
                                            mb: { xs: 1, sm: 0 },
                                        }}
                                    >
                                        {translate(
                                            'planSubscriptionExpiryDays',
                                            { count: trialPeriodDays }
                                        )}
                                    </Typography>
                                )}

                                <UpgradePlanButton />
                            </Box>
                        )}
                    </Stack>

                    <Divider sx={{ mt: 2, mb: 3 }} />

                    <Grid
                        container
                        display="grid"
                        gridTemplateColumns="repeat(auto-fit, minmax(170px, 1fr))"
                        gridTemplateRows="auto 1fr"
                        rowSpacing={4}
                    >
                        {managementMenu?.subMenus?.map((item) => {
                            if (!item) return null;

                            const { customText } = item;

                            const menuData = MAIN_MENU[item.menu];

                            let text: string | null = customText || null;

                            if (menuData?.translation) {
                                text = translate(menuData.translation);
                            }

                            return (
                                <Grid item key={item.menu}>
                                    <Typography
                                        sx={{ fontWeight: 700 }}
                                        variant="body1"
                                    >
                                        {text}
                                    </Typography>

                                    <Box>
                                        {item.subMenus?.map((subItem) => {
                                            if (!subItem) return null;

                                            const subMenuData =
                                                MAIN_MENU[subItem.menu];

                                            if (!subMenuData?.translation) {
                                                return;
                                            }

                                            return (
                                                <Box key={subItem.menu}>
                                                    <Link
                                                        href={subMenuData.href}
                                                        key={subItem.menu}
                                                        sx={subItemLinkStyle}
                                                        to={subMenuData.url}
                                                        underline="none"
                                                    >
                                                        {translate(
                                                            subMenuData.translation
                                                        )}
                                                    </Link>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
        </PageDrawer>
    );
};
