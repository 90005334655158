import { useEffect, useRef, useState } from 'react';

/**
 * Hook to check if an element has content
 * @param children The children to check for content
 * @returns Object containing hasContent boolean and contentRef to attach to element
 */
export const useHasContent = (children: React.ReactNode) => {
    const [hasContent, setHasContent] = useState(false);
    const contentRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            setHasContent(contentRef.current.innerHTML.trim().length > 0);
        }
    }, [children]);

    return {
        hasContent,
        contentRef,
    };
};
