import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, MenuItem } from '@mui/material';
import { mdiPlus, mdiMenuDown } from '@mdi/js';

import { PageTitle } from 'common/components/PageTitle';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Typography } from 'common/components/Typography';
import { BackgroundImg } from 'common/components/Img';
import { useFrontendPermissions } from 'user/hooks';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { Icon } from 'common/components/Icon';
import MANAGEMENT_HEADER from 'assets/images/course-module-drawer-header.webp';
import { CourseManagementTable } from 'training/components/CourseManagementTable';
import { CourseUpdateDrawer } from 'training/components/CourseUpdateDrawer';
import { CourseCreateAction } from 'training/actions/CourseCreateAction';
import {
    ActionButton,
    ActionButtonGroup,
} from 'common/components/ActionButton';
import { getUrl } from 'route/utils/getUrl';

import { BasePage } from './BasePage';
import { FourOFourPage } from './FourOFourPage';

export const CourseManagementPage = () => {
    const [translate] = useTranslation();
    const [loadingCourses, setLoadingCourses] = useState(false);

    const {
        canCreate: canCreateCourse,
        canUpdate: canUpdateCourse,
        loading: loadingPermissions,
    } = useFrontendPermissions('course');

    const updateCourseDrawerOpen = !!useRouteMatch('COURSE_EDIT');

    if (!loadingPermissions && !canUpdateCourse) {
        return <FourOFourPage />;
    }

    const header = (
        <BackgroundImg
            src={MANAGEMENT_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    const handleCourseReturnUrl = (courseId: string) =>
        getUrl('COURSE_EDIT', { id: courseId }, true);

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            <PageTitle mixpanelTitle="Courses management page">
                {translate('courseManagementPage.browserTitle')}
            </PageTitle>

            <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Typography variant="h1">
                            {translate('courseManagementPage.pageTitle')}
                        </Typography>
                    </Grid>
                    {canCreateCourse && (
                        <Grid item xs="auto">
                            <CourseCreateAction
                                onCourseReturnUrl={handleCourseReturnUrl}
                            >
                                {({ openCreateModal, createCourse }) => (
                                    <ActionButtonGroup>
                                        <ActionButton
                                            outlined
                                            disabled={loadingCourses}
                                            variant="extended"
                                            onClick={openCreateModal}
                                        >
                                            <Box>
                                                <Icon
                                                    path={mdiPlus}
                                                    size="2.4rem"
                                                />
                                            </Box>

                                            {translate(
                                                'courseManagementPage.createCourseButton'
                                            )}
                                        </ActionButton>
                                        <DropdownMenu
                                            anchor={
                                                <ActionButton
                                                    outlined
                                                    disabled={loadingCourses}
                                                >
                                                    <Icon
                                                        path={mdiMenuDown}
                                                        size="3rem"
                                                    />
                                                </ActionButton>
                                            }
                                            placement="bottom-end"
                                        >
                                            <MenuItem
                                                onClick={() => createCourse()}
                                            >
                                                {translate(
                                                    'courseManagementPage.createEmptyCourse'
                                                )}
                                            </MenuItem>
                                            <MenuItem onClick={openCreateModal}>
                                                {translate(
                                                    'courseManagementPage.createCourseFromTemplate'
                                                )}
                                            </MenuItem>
                                        </DropdownMenu>
                                    </ActionButtonGroup>
                                )}
                            </CourseCreateAction>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box py={4}>
                <CourseManagementTable
                    onLoading={(loading) => setLoadingCourses(loading)}
                />
            </Box>

            <CourseUpdateDrawer open={updateCourseDrawerOpen} />
        </BasePage>
    );
};
