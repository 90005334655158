import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Icon } from 'common/components/Icon';

export interface IToggleButton<Key extends string> {
    key: Key;
    icon: string;
}

interface IProps<Key extends string> {
    value?: Key;
    buttons: IToggleButton<Key>[];
    onClickButton: (viewKey: Key) => void;
}

export const ButtonToggle = <Key extends string>({
    value,
    buttons = [],
    onClickButton,
}: IProps<Key>) => {
    const initView = value || buttons[0].key;

    return (
        <ToggleButtonGroup exclusive fullWidth value={initView}>
            {buttons.map((view) => (
                <ToggleButton
                    key={view.key}
                    value={view.key}
                    onClick={() => onClickButton(view.key)}
                >
                    <Icon color="body" path={view.icon} size="2.4rem" />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};
