import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mdiFolderMultiplePlus, mdiPlus } from '@mdi/js';

import { IUserProfileSectionProps } from 'user/types';
import {
    ICreateDevelopmentItemInput,
    IDevelopmentItemFragment,
    IDevelopmentItemListItemFragment,
    useCurrentUserQuery,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { DropdownButton } from 'common/components/Button/DropdownButton';
import { DevelopmentItemGroup } from 'user/components/DevelopmentItemGroup';
import { DevelopmentItemDrawer } from 'user/components/DevelopmentItemDrawer';
import { DevelopmentItemGroupDrawer } from 'user/components/DevelopmentItemGroupDrawer';
import { InboxGroup } from 'user/components/InboxGroup';
import { DevelopmentItemList } from 'user/components/DevelopmentItemList';
import { NoResults } from 'common/components/NoResults';
import { getGroupsState } from 'user/utils';
import { TGroupsState } from 'user/utils/getGroupsState';
import { useSnackbar } from 'common/hooks/useSnackbar';
import {
    goToDevelopmentGroupEditPage,
    goToDevelopmentItemEditPage,
    goToDevelopmentItemViewPage,
    goToDevelopmentPlanPage,
} from 'user/utils/goToRoutes';
import {
    useDevelopmentPlan,
    useDevelopmentPlanDND,
    useDevelopmentPlanRouting,
} from 'user/hooks/developmentPlan';
import {
    DragDropContext,
    Draggable,
    Droppable,
} from 'common/components/DragDrop';
import { Tooltip } from 'common/components/Tooltip';
import { ActionButton } from 'common/components/ActionButton';
import { Link } from 'common/components/Link';
import { Icon } from 'common/components/Icon';
import { PageTitle } from 'common/components/PageTitle';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { getUrl } from 'route/utils/getUrl';

enum FilterType {
    all = 'allItems',
    open = 'openItems',
    closed = 'closedItems',
}

const GROUP_TYPE = 'development';

export const DevelopmentPlan = ({
    id: userId,
    name,
    withEditActions,
}: IUserProfileSectionProps) => {
    const [expanded, setExpanded] = useState<TGroupsState>(
        getGroupsState(GROUP_TYPE)
    );
    const [filter, setFilter] = useState<FilterType>(FilterType.all);
    const inboxGroupId = useRef(undefined);
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const [translate] = useTranslation();
    const { data: currentUserData } = useCurrentUserQuery();
    const isItemEditPage = !!useRouteMatch('DEVELOPMENT_ITEM_EDIT');
    const isDevelopmentItemProfilePage = !!useRouteMatch(
        'USER_DEVELOPMENT_ITEM_VIEW'
    );
    const isEditGroupPage = !!useRouteMatch('DEVELOPMENT_GROUP_EDIT');

    const handleCreateDevelopmentItemSuccess = useCallback(
        (developmentItemId: string) => {
            goToDevelopmentItemEditPage(navigate, developmentItemId);

            displaySnackbar(translate('developmentItemActionSuccess.create'), {
                variant: 'success',
            });
        },
        [displaySnackbar, navigate, translate]
    );

    const {
        developmentPlanLoading,
        developmentPlanError,
        groups,
        createError,
        createLoading,
        setGroups,
        createDevelopmentItem,
    } = useDevelopmentPlan(userId, handleCreateDevelopmentItemSuccess);
    const loading = developmentPlanLoading || createLoading;

    const { handleDragEnd } = useDevelopmentPlanDND(groups, setGroups);
    const {
        selectedDevelopmentItem,
        selectedGroup,
        developmentItemDrawerOpen,
        groupDrawerOpen,
        userIdParam,
    } = useDevelopmentPlanRouting(
        groups,
        developmentPlanLoading || createLoading
    );

    useEffect(() => {
        const itemNotFound =
            (isItemEditPage || isDevelopmentItemProfilePage) &&
            !loading &&
            !selectedDevelopmentItem;
        const groupNotFound = isEditGroupPage && !loading && !selectedGroup;

        if (itemNotFound || groupNotFound) {
            navigate(getUrl('PAGE_NOT_FOUND'), { replace: true });
        }
    }, [
        isItemEditPage,
        isDevelopmentItemProfilePage,
        loading,
        isEditGroupPage,
        selectedDevelopmentItem,
        selectedGroup,
        navigate,
    ]);

    const isCurrentUserDevelopmentPlan = !!(
        currentUserData?.currentUser &&
        currentUserData.currentUser.id === userId
    );

    // Returns a promise for the Formik form to know when submission is done
    const handleCreateDevelopmentItem = useCallback(
        (values: ICreateDevelopmentItemInput) =>
            createDevelopmentItem({
                variables: { developmentItem: { ...values } },
            }),
        [createDevelopmentItem]
    );

    const isItemVisible = useCallback(
        (item: IDevelopmentItemListItemFragment) =>
            filter === FilterType.all ||
            (filter === FilterType.closed && item.checked) ||
            (filter === FilterType.open && !item.checked),
        [filter]
    );

    const inboxGroup = useMemo(() => {
        let hasItems = false;
        groups.forEach((group) => {
            if (hasItems) return;

            if (group.developmentItems.length) hasItems = true;
        });

        if (!hasItems) return null;

        const inboxGroupResult = groups
            .filter((group) => group.isInbox)
            .map((group) => {
                inboxGroupId.current = group.id;

                return (
                    <Box key={group.id} pb={2}>
                        <InboxGroup
                            as={DevelopmentItemList}
                            groupId={group.id}
                            isItemVisible={isItemVisible}
                            items={group.developmentItems}
                            key={group.id}
                            withEditActions={withEditActions}
                            onClickItem={(
                                item: IDevelopmentItemListItemFragment
                            ) => {
                                if (isCurrentUserDevelopmentPlan) {
                                    goToDevelopmentItemEditPage(
                                        navigate,
                                        item.id
                                    );

                                    return;
                                }

                                goToDevelopmentItemViewPage(
                                    navigate,
                                    userId,
                                    item.id
                                );
                            }}
                        />
                    </Box>
                );
            });

        if (inboxGroupResult.length) return inboxGroupResult[0];

        return null;
    }, [
        groups,
        navigate,
        userId,
        isCurrentUserDevelopmentPlan,
        withEditActions,
        isItemVisible,
    ]);

    const groupItems = useMemo(() => {
        const handleExpandGroup = (groupId: string) => () => {
            const isExpanded = !(groupId in expanded) || expanded[groupId];

            const newDevelopmentItemGroupsState = {
                ...expanded,
                [groupId]: !isExpanded,
            };

            setExpanded(newDevelopmentItemGroupsState);

            localStorage.setItem(
                'developmentItemGroupsState',
                JSON.stringify(newDevelopmentItemGroupsState)
            );
        };

        return groups
            .filter((group) => !group.isInbox)
            .map((group, index) => {
                const isExpanded =
                    !(group.id in expanded) || expanded[group.id];

                return (
                    <Draggable
                        dragDropDisabled={!withEditActions}
                        draggableId={group.id}
                        index={index}
                        key={group.id}
                    >
                        <Box pb={2} pt={2}>
                            <DevelopmentItemGroup
                                expanded={isExpanded}
                                isItemVisible={isItemVisible}
                                key={group.id}
                                loading={createLoading}
                                withEditActions={withEditActions}
                                onClick={handleExpandGroup(group.id)}
                                onClickItem={(
                                    item: IDevelopmentItemFragment
                                ) => {
                                    if (isCurrentUserDevelopmentPlan) {
                                        goToDevelopmentItemEditPage(
                                            navigate,
                                            item.id
                                        );

                                        return;
                                    }

                                    goToDevelopmentItemViewPage(
                                        navigate,
                                        userId,
                                        item.id
                                    );
                                }}
                                onEdit={() =>
                                    goToDevelopmentGroupEditPage(
                                        navigate,
                                        group.id
                                    )
                                }
                                onQuickAddItem={handleCreateDevelopmentItem}
                                {...group}
                            />
                        </Box>
                    </Draggable>
                );
            });
    }, [
        groups,
        withEditActions,
        expanded,
        createLoading,
        handleCreateDevelopmentItem,
        navigate,
        userId,
        isCurrentUserDevelopmentPlan,
        isItemVisible,
    ]);

    const hasDevelopmentPlan =
        !developmentPlanLoading &&
        !!(
            groups.length > 1 ||
            (groups.length === 1 && groups[0].developmentItems.length)
        );

    const pageTitle = `${translate('developmentPlan')} - ${name}`;
    const isDrawerPage =
        isItemEditPage ||
        isEditGroupPage ||
        groupDrawerOpen ||
        developmentItemDrawerOpen;

    return (
        <>
            <PageTitle
                mixpanelTitle="Development plan"
                skipMixpanel={isDrawerPage}
            >
                {pageTitle}
            </PageTitle>

            {createError && <ApolloError error={createError} />}
            {developmentPlanError && (
                <ApolloError error={developmentPlanError} />
            )}
            {developmentPlanLoading && <Loader />}

            {!developmentPlanLoading && (
                <Box display="flex" mb={1.5}>
                    {withEditActions && (
                        <Box display="flex">
                            <Box mr={1}>
                                <Tooltip
                                    title={translate<string>(
                                        'newDevelopmentItem'
                                    )}
                                >
                                    <Box>
                                        <ActionButton
                                            outlined
                                            component={Link}
                                            size="medium"
                                            to="DEVELOPMENT_ITEM_CREATE"
                                        >
                                            <Icon path={mdiPlus} />
                                        </ActionButton>
                                    </Box>
                                </Tooltip>
                            </Box>

                            <Tooltip title={translate<string>('newGroup')}>
                                <Box>
                                    <ActionButton
                                        outlined
                                        component={Link}
                                        size="medium"
                                        to="DEVELOPMENT_GROUP_CREATE"
                                    >
                                        <Icon
                                            path={mdiFolderMultiplePlus}
                                            size="1.8rem"
                                        />
                                    </ActionButton>
                                </Box>
                            </Tooltip>
                        </Box>
                    )}

                    <Box alignItems="center" display="flex" ml="auto">
                        <DropdownMenu
                            anchor={
                                <DropdownButton>
                                    {translate(filter)}
                                </DropdownButton>
                            }
                        >
                            {Object.values(FilterType).map(
                                (key) =>
                                    filter !== key && (
                                        <MenuItem
                                            key={key}
                                            onClick={() => setFilter(key)}
                                        >
                                            {translate(key)}
                                        </MenuItem>
                                    )
                            )}
                        </DropdownMenu>
                    </Box>
                </Box>
            )}

            <DragDropContext
                dragDropDisabled={!withEditActions}
                onDragEnd={handleDragEnd}
            >
                {inboxGroup}
                <Droppable
                    dragDropDisabled={!withEditActions}
                    droppableId="groups"
                    type="GROUP"
                >
                    <div>{hasDevelopmentPlan && groupItems}</div>
                </Droppable>
            </DragDropContext>

            {!hasDevelopmentPlan && !developmentPlanLoading && (
                <NoResults variant="USER_DEVELOPMENT_PLAN" />
            )}

            <DevelopmentItemDrawer
                loading={developmentPlanLoading}
                mixpanelTitle="Development plan development item drawer"
                open={developmentItemDrawerOpen}
                pageTitle={pageTitle}
                withEditActions={withEditActions}
                onClose={() => goToDevelopmentPlanPage(navigate, userIdParam)}
                onCreateItem={handleCreateDevelopmentItem}
            />

            {withEditActions && (
                <DevelopmentItemGroupDrawer
                    inboxGroupId={inboxGroupId.current}
                    item={selectedGroup}
                    loading={developmentPlanLoading}
                    mixpanelTitle="Development plan group drawer"
                    open={groupDrawerOpen}
                    pageTitle={pageTitle}
                    onClose={() => {
                        goToDevelopmentPlanPage(navigate, userIdParam);
                    }}
                />
            )}
        </>
    );
};
