import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';

import { useRemoveUserFromTrainingMutation } from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

interface IProps {
    userId: string;
    trainingId: string;
    addedVia?: JSX.Element[];
    open: boolean;
    onClose(): void;
    onSuccess?: () => void;
    portfolioItemId?: string;
}

export const TrainingParticipantRemoveUserDialog = ({
    userId,
    trainingId,
    addedVia,
    open,
    onClose,
    onSuccess,
    portfolioItemId,
}: IProps) => {
    const [translate] = useTranslation();
    const client = useApolloClient();

    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [removeUserFromTraining, { loading: loadingRemoveUser }] =
        useRemoveUserFromTrainingMutation();

    const removeUser = async () => {
        try {
            await removeUserFromTraining({
                variables: { trainingId, userId },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('deleteFromTrainingSuccess'), {
            variant: 'success',
        });

        const { cache } = client;

        // Remove participants from training cache so it will be refetched
        cache.evict({
            id: `Training:${trainingId}`,
            fieldName: 'participants',
        });

        if (portfolioItemId) {
            cache.evict({
                id: `PortfolioItemReport:${portfolioItemId}`,
            });

            cache.evict({
                id: `PortfolioItem:${portfolioItemId}`,
            });
        }

        cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'portfolioReport',
        });

        cache.gc();

        onSuccess?.();
        onClose();
    };

    return (
        <ConfirmDialog
            confirmText={translate(addedVia ? 'close' : 'delete')}
            loading={loadingRemoveUser}
            open={open}
            title={translate('removeUserFromTrainingMessage.title')}
            onCancel={
                !addedVia
                    ? () => {
                          onClose();
                      }
                    : undefined
            }
            onClose={() => {
                onClose();
            }}
            onConfirm={() => {
                if (addedVia) {
                    onClose();

                    return;
                }
                removeUser();
            }}
        >
            {addedVia
                ? translate('removeUserFromTrainingMessage.userAddedViaText', {
                      count: addedVia.length,
                  })
                : translate('removeUserFromTrainingMessage.text')}

            <Box sx={{ mt: 2 }}>{addedVia}</Box>
        </ConfirmDialog>
    );
};
