import { styled } from 'styled-components';
import Typography from '@mui/material/Typography';
import { rgba } from 'polished';

export const CardMediaTitle = styled(Typography)`
    background-image: linear-gradient(
        to top,
        ${({ theme }) => rgba(theme.palette.common.black, 0.74)},
        transparent 100px
    );
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: ${({ theme }) => theme.spacing(2)};
    color: ${({ theme }) => theme.palette.common.white};
`;
