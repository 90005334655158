import { Box, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiClose } from '@mdi/js';

import { Button } from 'common/components/Button';
import { AlertDialog } from 'common/components/AlertDialog';
import { IconButton } from 'common/components/IconButton';

import { IBaseAlert } from './';

export const UnsavedChangesAlert = ({
    disableActions,
    open,
    onConfirm,
    onCancel,
    onClose,
}: IBaseAlert) => {
    const [translate] = useTranslation();

    const header = (
        <Box alignItems="center" display="flex">
            {translate('unsavedChangesMessage.title')}
            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    return (
        <AlertDialog
            actions={
                <>
                    <Button
                        autoFocus
                        color="primary"
                        disabled={disableActions}
                        variant="contained"
                        onClick={onConfirm}
                    >
                        {translate('save')}
                    </Button>
                    <Button
                        disabled={disableActions}
                        variant="contained"
                        onClick={onCancel}
                    >
                        {translate('delete')}
                    </Button>
                </>
            }
            open={!!open}
            title={header}
        >
            <DialogContentText color="text.primary" variant="body2">
                {translate('unsavedChangesMessage.text')}
            </DialogContentText>
        </AlertDialog>
    );
};
