import MuiAccordionDetails, {
    AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { styled } from 'styled-components';

import { Text } from 'common/components/Text';

const BaseAccordionDetails = ({
    children,
    ...other
}: AccordionDetailsProps) => (
    <MuiAccordionDetails {...other}>
        <Text>{children}</Text>
    </MuiAccordionDetails>
);

export const AccordionDetails = styled(BaseAccordionDetails)`
    padding: ${({ theme }) => theme.spacing(2, 2)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme }) => theme.spacing(4, 4)};
    }

    &:not(:last-child) {
        padding-bottom: 0;
    }
`;
