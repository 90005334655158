import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IFileFragment,
    useCreateFileMutation,
    useUpdateFileMutation,
} from 'graphql/types';
import { TFileUploadStatus } from 'common/types';
import { INITIAL_FILE_STATUS } from 'common/constants/initialFileStatus';
import { SIZE_TO_MB_MULTIPLIER } from 'common/constants/files';

export type TUploadingFile = {
    name: string;
    status: TFileUploadStatus;
    file?: IFileFragment;
};

type TReturnData = {
    isUploading: boolean;
    uploadFiles(files: File[], maxFileSize: number): void;
    uploadingFiles: TUploadingFile[];
    setUploadingFiles(uploadingFiles: TUploadingFile[]): void;
};

type TOptions = {
    onError?(fileName: string, message: string): void;
    onProgress?(fileIndex: number, progressValue: number): void;
    onSuccess?(fileIndex: number, updatedFile: IFileFragment): void;
    publicFile?: boolean;
};

export const useUploadFiles = (options?: TOptions): TReturnData => {
    const { onError, onProgress, onSuccess, publicFile } = options || {};
    const filesToUpload = useRef<File[]>([]);
    const [uploadingFiles, setUploadingFiles] = useState<TUploadingFile[]>([]);
    const [createFile] = useCreateFileMutation();
    const [updateFile] = useUpdateFileMutation();
    const [translate] = useTranslation();

    /*
     * Loop through each input selected file and create a file status objects in the state
     * maxFileSize in MB. 5 = 5MB
     */
    const uploadFiles = (files: File[], maxFileSize: number) => {
        if (!files) return;

        // Set files that still need to be uploaded. Reverse the list because the latest uploading items should be on top.
        filesToUpload.current = files.reverse();

        const newUploadingFiles: TUploadingFile[] = [];
        /*
         *  Loop through each input selected file and set file statuses and check if filesize is not exceeded
         */
        filesToUpload.current.forEach((file) => {
            if (file.size > maxFileSize * SIZE_TO_MB_MULTIPLIER) {
                newUploadingFiles.push({
                    name: file.name,
                    status: {
                        ...INITIAL_FILE_STATUS,
                        done: true,
                        errorMessage: translate('fileLimitExceeded', {
                            fileSize: `${maxFileSize}mb`,
                        }),
                    },
                });

                setUploadingFiles([...newUploadingFiles, ...uploadingFiles]);

                if (!onError) return;

                return onError(
                    file.name,
                    translate('fileLimitExceeded', {
                        fileSize: `${maxFileSize}mb`,
                    })
                );
            }

            newUploadingFiles.push({
                name: file.name,
                status: INITIAL_FILE_STATUS,
            });
        });

        setUploadingFiles([...newUploadingFiles, ...uploadingFiles]);
    };

    /*
     *  Update a file its status
     */
    const updateFileStatus = useCallback(
        (
            fileIndex: number,
            status: TFileUploadStatus,
            file?: IFileFragment
        ) => {
            if (!uploadingFiles.length) return;

            const newUploadingFiles = [...uploadingFiles];

            newUploadingFiles[fileIndex].status = status;

            if (file) newUploadingFiles[fileIndex].file = file;

            setUploadingFiles(newUploadingFiles);
        },
        [uploadingFiles]
    );

    /*
     *  Loop through each input selected file and create a file object
     *  in the backend
     */
    const startUpload = useCallback(
        (files: File[]) => {
            // Clear the files that still need to be uploaded
            filesToUpload.current = [];

            files.forEach((file, index) => {
                const fileIndex = index;
                const {
                    status: { done, success },
                } = uploadingFiles[index];

                if (done && !success) return;

                createFile({
                    variables: {
                        file: { name: file.name, public: publicFile },
                    },
                    update(_, result) {
                        const {
                            s3Url,
                            s3Data,
                            file: createdFile,
                        } = result?.data?.createFile || {};

                        if (!s3Url || !s3Data) {
                            updateFileStatus(fileIndex, {
                                ...INITIAL_FILE_STATUS,
                                done: true,
                                errorMessage: translate('fileUploadFailed'),
                            });

                            if (!onError) return;

                            return onError(
                                file.name,
                                translate('fileUploadFailed')
                            );
                        }

                        const oReq = new XMLHttpRequest();

                        // Handling of the progress of the upload
                        oReq.upload.addEventListener(
                            'progress',
                            (e) => {
                                const progress = Math.ceil(
                                    (e.loaded / e.total) * 100
                                );

                                updateFileStatus(fileIndex, {
                                    done: false,
                                    success: false,
                                    progress: progress,
                                });

                                onProgress && onProgress(fileIndex, progress);
                            },
                            false
                        );

                        // Handling of errors during upload
                        oReq.upload.addEventListener('error', () => {
                            updateFileStatus(fileIndex, {
                                ...INITIAL_FILE_STATUS,
                                done: true,
                                errorMessage: translate('fileUploadFailed'),
                            });

                            if (!onError) return;

                            onError(file.name, translate('fileUploadFailed'));
                        });

                        // Handling of a successful upload
                        oReq.upload.addEventListener('load', () => {
                            /*
                             * If no created file exists something went wrong so
                             * throw an error
                             */
                            if (!createdFile) {
                                updateFileStatus(fileIndex, {
                                    ...INITIAL_FILE_STATUS,
                                    done: true,
                                    errorMessage: translate('fileUploadFailed'),
                                });

                                if (!onError) return;

                                return onError(
                                    file.name,
                                    translate('fileUploadFailed')
                                );
                            }

                            /*
                             * When the file is created we make sure the backend
                             * gets notified that the upload was successful
                             */
                            updateFile({
                                variables: {
                                    id: createdFile.id,
                                    file: { uploaded: true },
                                },
                                update(_, result) {
                                    const { file: updatedFile } =
                                        result?.data?.updateFile || {};

                                    if (!updatedFile) {
                                        updateFileStatus(fileIndex, {
                                            ...INITIAL_FILE_STATUS,
                                            done: true,
                                            errorMessage:
                                                translate('fileUploadFailed'),
                                        });

                                        if (!onError) return;

                                        return onError(
                                            file.name,
                                            translate('fileUploadFailed')
                                        );
                                    }

                                    updateFileStatus(
                                        fileIndex,
                                        {
                                            ...uploadingFiles[fileIndex].status,
                                            done: true,
                                            success: true,
                                        },
                                        updatedFile
                                    );

                                    onSuccess &&
                                        onSuccess(fileIndex, updatedFile);
                                },
                            });
                        });

                        // Handling of errors during the s3 request
                        oReq.addEventListener('error', () => {
                            updateFileStatus(fileIndex, {
                                ...INITIAL_FILE_STATUS,
                                done: true,
                                errorMessage: translate('fileUploadFailed'),
                            });

                            if (!onError) return;

                            onError(file.name, translate('fileUploadFailed'));
                        });

                        function formData(
                            data: { [key: string]: string | Blob },
                            file: File
                        ) {
                            const res = new FormData();

                            for (const k in data) res.append(k, data[k]);

                            res.append('file', file, file.name);

                            return res;
                        }

                        oReq.open('POST', s3Url, true);
                        oReq.send(formData(JSON.parse(s3Data), file));
                    },
                });
            });
        },
        [
            uploadingFiles,
            createFile,
            onError,
            onProgress,
            onSuccess,
            translate,
            updateFile,
            updateFileStatus,
            publicFile,
        ]
    );

    useEffect(() => {
        const { current: files } = filesToUpload;

        if (!files.length || !uploadingFiles.length) return;

        startUpload(files);
    }, [uploadingFiles, startUpload]);

    const isUploading = !!uploadingFiles.filter(
        (uploadingFile) => !uploadingFile.status.done
    ).length;

    return {
        isUploading,
        uploadFiles,
        uploadingFiles,
        setUploadingFiles,
    };
};
