import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITrainingFragment, ITrainingRole, Maybe } from 'graphql/types';
import { SECTIONS } from 'training/constants/sections';
import { TTabsMenuItem } from 'common/types';
import { ITrainingSectionProps } from 'training/types';
import { useFrontendPermissions } from 'user/hooks';

import { useTrainingRoutes } from '.';

interface IState {
    tabs: TTabsMenuItem[];
    Section?: React.FC<React.PropsWithChildren<ITrainingSectionProps>>;
}

export const useTrainingTabs = (
    id?: string,
    training?: Maybe<ITrainingFragment>
): IState => {
    const { activeSectionRoute } = useTrainingRoutes();

    const [translate] = useTranslation();
    const { canUpdate } = useFrontendPermissions('training');

    const {
        rolesForCurrentUser,
        showSharedFiles,
        showParticipants,
        currentUserIsAuthor,
    } = training || {};

    const canUpdateTraining = canUpdate || currentUserIsAuthor;

    const sections = useMemo(() => {
        const isTrainer =
            !!rolesForCurrentUser?.includes(ITrainingRole.Trainer) ||
            !!rolesForCurrentUser?.includes(ITrainingRole.Mentor);
        const isGroupmanager = !!rolesForCurrentUser?.includes(
            ITrainingRole.Groupmanager
        );

        return Object.keys(SECTIONS).filter((key) => {
            switch (key) {
                case 'TRAINING':
                    return true;
                case 'TRAINING_PARTICIPANTS':
                    return (
                        showParticipants ||
                        isTrainer ||
                        isGroupmanager ||
                        canUpdateTraining
                    );
                case 'TRAINING_TRAINERS':
                    return true;
                case 'TRAINING_SHARED_FILES':
                    return showSharedFiles;
            }
        });
    }, [
        rolesForCurrentUser,
        showSharedFiles,
        showParticipants,
        canUpdateTraining,
    ]);

    const tabs = sections.map((section) => ({
        label: translate(`trainingTabs.${section}`),
        to: section,
        params: { trainingId: id },
        value: section,
    }));

    const Section = sections.includes(activeSectionRoute)
        ? SECTIONS[activeSectionRoute]
        : undefined;

    return {
        tabs,
        Section,
    };
};
