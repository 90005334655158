import { ChipProps } from '@mui/material';

import { IScoringFragment } from 'graphql/types';

import { Chip } from '../Chip';

interface IProps extends ChipProps {
    scoring?: IScoringFragment[];
}

export const ScoringChips = ({ color, scoring, ...other }: IProps) => {
    if (!scoring?.length) return null;

    const scores = scoring?.map((scoreItem) => {
        const {
            score,
            scoringSystem: { nameSingular, namePlural },
        } = scoreItem;

        return {
            score,
            nameSingular,
            namePlural,
        };
    });

    return (
        <>
            {scores
                .filter((scoreItem) => scoreItem.score)
                .map((scoreItem, index) => {
                    const { score, nameSingular, namePlural } = scoreItem;

                    const name = score === 1 ? nameSingular : namePlural;

                    return (
                        <Chip
                            color={color || 'primary'}
                            {...other}
                            key={`${nameSingular}-${index}`}
                            label={`${score} ${name}`}
                        />
                    );
                })}
        </>
    );
};
