import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Box, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
    IExtraCategoryOption,
    IExtraCategoryCreateInput,
    IExtraCategoryFragment,
    IExtraCategoryUpdateInput,
    IContentTypeValue,
} from 'graphql/types';
import {
    ColorPicker,
    MultiSelect,
    Select,
    Switch,
    TextField,
} from 'common/components/FormField';
import { Button } from 'common/components/Button';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import {
    getExtraCategoryTypeTranslation,
    getExtraCategoryModelTypeTranslation,
} from 'organisation/utils/extraCategory';
import { Typography } from 'common/components/Typography';

import { ExtraCategoryChoiceOptions } from './ExtraCategoryChoiceOptions';

export type TExtraCategoryFormValues =
    | IExtraCategoryCreateInput
    | IExtraCategoryUpdateInput;

export interface IProps {
    disabled?: boolean;
    extraCategory?: IExtraCategoryFragment;
    initialValues:
        | IExtraCategoryCreateInput
        | (IExtraCategoryUpdateInput & {
              categoryType: IExtraCategoryCreateInput['categoryType'];
          });
    onDelete?(): void;
    onSubmit(values: TExtraCategoryFormValues): void;
}

export const ExtraCategoryForm = ({
    disabled,
    extraCategory,
    initialValues,
    onDelete,
    onSubmit,
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        forModelTypes: Yup.array().min(1, translate('minOneOption')),
        name: Yup.string().required(translate('validate.required')),
        choiceOptions: Yup.array()
            .of(
                Yup.object().shape({
                    stringValue: Yup.string().required(
                        translate('validate.required')
                    ),
                })
            )
            .when('categoryType', {
                is: IExtraCategoryOption.Choice,
                then: Yup.array().min(1, translate('minOneOption')),
            }),
    });

    const buttonText = !!extraCategory
        ? translate('extraCategoriesDrawer.editButton')
        : translate('extraCategoriesDrawer.createButton');

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ submitForm, isSubmitting, dirty, values }) => {
                    const isChoiceType =
                        values.categoryType === IExtraCategoryOption.Choice;
                    const isCheckboxType =
                        values.categoryType === IExtraCategoryOption.Boolean;
                    const isDateType =
                        values.categoryType === IExtraCategoryOption.Datetime;

                    const isTextType =
                        values.categoryType === IExtraCategoryOption.String;

                    return (
                        <Box p={{ xs: 2, sm: 4 }}>
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                                variant="h3"
                            >
                                {translate('extraCategoriesDrawer.formTitle')}
                            </Typography>

                            <Box mb={1}>
                                <Typography>
                                    {translate(
                                        'extraCategoriesDrawer.formText'
                                    )}
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 1, mb: 2 }}>
                                <Field
                                    component={MultiSelect}
                                    getOptionLabel={(
                                        option: IContentTypeValue
                                    ) =>
                                        getExtraCategoryModelTypeTranslation(
                                            option
                                        )
                                    }
                                    InputProps={{
                                        label: translate(
                                            'extraCategoriesDrawer.modelType'
                                        ),
                                    }}
                                    label="ModelType"
                                    name="forModelTypes"
                                    options={[
                                        IContentTypeValue.Group,
                                        IContentTypeValue.Offer,
                                        IContentTypeValue.Course,
                                    ]}
                                />

                                <Field
                                    component={Select}
                                    disabled={!!extraCategory}
                                    label={translate(
                                        'extraCategoriesDrawer.type'
                                    )}
                                    name="categoryType"
                                    options={[
                                        {
                                            label: getExtraCategoryTypeTranslation(
                                                IExtraCategoryOption.Boolean
                                            ),
                                            value: IExtraCategoryOption.Boolean,
                                        },
                                        {
                                            label: getExtraCategoryTypeTranslation(
                                                IExtraCategoryOption.Choice
                                            ),
                                            value: IExtraCategoryOption.Choice,
                                        },
                                        {
                                            label: getExtraCategoryTypeTranslation(
                                                IExtraCategoryOption.Datetime
                                            ),
                                            value: IExtraCategoryOption.Datetime,
                                        },
                                        {
                                            label: getExtraCategoryTypeTranslation(
                                                IExtraCategoryOption.String
                                            ),
                                            value: IExtraCategoryOption.String,
                                        },
                                    ]}
                                />

                                <Divider sx={{ mt: 3, mb: 2 }} />

                                <Typography variant="h3">
                                    {translate(
                                        'extraCategoriesDrawer.settings'
                                    )}
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <Field
                                            component={TextField}
                                            label={translate('name')}
                                            name="name"
                                            sx={{ mb: isTextType ? 2 : 0 }}
                                        />
                                    </Grid>

                                    {!isChoiceType && (
                                        <Grid item>
                                            <Field
                                                component={ColorPicker}
                                                name={'color'}
                                                popoverPosition="bottom-end"
                                                tooltip={translate<string>(
                                                    'color'
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                {isChoiceType && <ExtraCategoryChoiceOptions />}

                                {!isCheckboxType && !isDateType && (
                                    <>
                                        <Divider sx={{ my: 2 }} />

                                        {isChoiceType && (
                                            <Field
                                                checked={values.multipleChoices}
                                                component={Switch}
                                                formControlProps={{
                                                    margin: 'none',
                                                }}
                                                label={translate(
                                                    'extraCategoriesDrawer.multipleChoices'
                                                )}
                                                name="multipleChoices"
                                            />
                                        )}

                                        <Field
                                            checked={values.filter}
                                            component={Switch}
                                            formControlProps={{
                                                margin: 'none',
                                            }}
                                            label={translate(
                                                'extraCategoriesDrawer.filter'
                                            )}
                                            name="filter"
                                        />

                                        <Field
                                            checked={values.showLabel}
                                            component={Switch}
                                            formControlProps={{
                                                margin: 'none',
                                            }}
                                            label={translate(
                                                'extraCategoriesDrawer.showLabel'
                                            )}
                                            name="showLabel"
                                        />

                                        <Divider sx={{ mt: 2 }} />
                                    </>
                                )}
                            </Box>

                            <Box sx={{ display: 'flex', mt: 4 }}>
                                <Box>
                                    <Button
                                        color="primary"
                                        disabled={
                                            isSubmitting ||
                                            disabled ||
                                            (!isSubmitting &&
                                                !disabled &&
                                                !dirty)
                                        }
                                        loading={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            e.preventDefault();

                                            return submitForm();
                                        }}
                                    >
                                        {buttonText}
                                    </Button>
                                </Box>

                                {!!extraCategory && (
                                    <Box ml="auto">
                                        <FormDeleteButton
                                            disabled={isSubmitting}
                                            onClick={() => onDelete?.()}
                                        >
                                            {translate('delete')}
                                        </FormDeleteButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                }}
            </Formik>
        </>
    );
};
