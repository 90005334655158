import { useRef, useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiPlus } from '@mdi/js';

import { IUserListItemFragment, IUserSelectorInput } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { IUserTableData } from 'user/hooks/useUserTableData';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';

import { UserSelectDrawer } from './UserSelectDrawer';
import { AddedUsersTable } from './AddedUsersTable';

interface IProps {
    addButtonMenuItems?: React.ReactNode;
    addButtonTranslationKeys?: {
        noSelection?: string;
        selection?: string;
    };
    addedUsers?: IUserListItemFragment[];
    deleteDialogProps?: {
        title: string;
        text: string;
    };
    disabledUserIds?: string[];
    isEditable?: boolean;
    label?: string;
    loading?: boolean;
    loadingRemove?: boolean;
    noResultsLabel?: string;
    paginationTranslationKey?: string; // The translation locale key for the pagination text
    selectorQueryVariables?: IUserSelectorInput;
    tableDataProps?: IUserTableData['tableDataProps'];
    onAddUsers(selectedUsers: string[]): void;
    onRemoveUsers(selectedUsers: string[]): void;
}

export const UserSelector = ({
    addButtonMenuItems,
    addButtonTranslationKeys,
    addedUsers = [],
    deleteDialogProps,
    disabledUserIds,
    isEditable = true,
    label,
    loading,
    loadingRemove,
    noResultsLabel,
    paginationTranslationKey,
    selectorQueryVariables,
    tableDataProps,
    onAddUsers,
    onRemoveUsers,
}: IProps) => {
    const { t: translate } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const deleteUsersRef = useRef<string[] | undefined>();

    const handleDrawerOpen = (open: boolean) => {
        setDrawerOpen(open);
    };

    const handleAddUsers = async (selected: string[]) => {
        await onAddUsers(selected);

        setDrawerOpen(false);
    };

    const handleRemoveUsers = async () => {
        if (!deleteUsersRef.current) return;

        await onRemoveUsers(deleteUsersRef.current);

        deleteUsersRef.current = undefined;

        setShowDeleteDialog(false);

        tableDataProps?.selectionProps.onSelectAll();
    };

    const handleRemoveUsersDialog = async (selected: string[]) => {
        deleteUsersRef.current = selected;

        setShowDeleteDialog(true);
    };

    return (
        <Box>
            <Box alignItems="left" display="flex" flexDirection="column">
                {isEditable && (
                    <>
                        {!!addButtonMenuItems ? (
                            <DropdownMenu
                                anchor={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Typography
                                            fontWeight={700}
                                            sx={{ mr: 1 }}
                                        >
                                            {label ||
                                                translate('addUsersTitle')}
                                        </Typography>

                                        <ActionButton
                                            outlined
                                            sizeSm
                                            component={Box}
                                            sx={{
                                                width: '30px',
                                                height: '30px',
                                            }}
                                        >
                                            <Icon path={mdiPlus} />
                                        </ActionButton>
                                    </Box>
                                }
                                placement="bottom-start"
                            >
                                <MenuItem
                                    onClick={() => handleDrawerOpen(true)}
                                >
                                    {translate('userSelector.addExistingUsers')}
                                </MenuItem>

                                {addButtonMenuItems}
                            </DropdownMenu>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography fontWeight={700} sx={{ mr: 1 }}>
                                    {label || translate('addUsersTitle')}
                                </Typography>

                                <ActionButton
                                    outlined
                                    sizeSm
                                    component={Box}
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                    }}
                                    onClick={() => handleDrawerOpen(true)}
                                >
                                    <Icon path={mdiPlus} />
                                </ActionButton>
                            </Box>
                        )}
                    </>
                )}

                <Box sx={{ mt: isEditable ? 4 : 2 }}>
                    <AddedUsersTable
                        addedUsers={addedUsers}
                        isEditable={isEditable}
                        loading={loading}
                        noResultsLabel={noResultsLabel}
                        paginationTranslationKey={paginationTranslationKey}
                        tableDataProps={tableDataProps}
                        onRemoveUsers={
                            isEditable ? handleRemoveUsersDialog : undefined
                        }
                    />
                </Box>
            </Box>

            <UserSelectDrawer
                addButtonTranslationKeys={addButtonTranslationKeys}
                addedUsers={addedUsers}
                disabledUserIds={disabledUserIds}
                loading={loading}
                noResultsLabel={noResultsLabel}
                open={drawerOpen}
                paginationTranslationKey={paginationTranslationKey}
                selectorQueryVariables={selectorQueryVariables}
                title={label}
                onAddUsers={handleAddUsers}
                onDrawerOpen={handleDrawerOpen}
            />

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingRemove}
                open={showDeleteDialog}
                title={
                    deleteDialogProps?.title ||
                    translate('userSelectorDelete.title')
                }
                onCancel={() => {
                    setShowDeleteDialog(false);

                    deleteUsersRef.current = undefined;
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleRemoveUsers}
            >
                {deleteDialogProps?.text ||
                    translate('userSelectorDelete.text')}
            </ConfirmDialog>
        </Box>
    );
};
