import { IOfferEventType } from 'graphql/types';

const { VITE_MIXPANEL_KEY, MODE, VITE_DEBUG_MIXPANEL } = import.meta.env;

export const LOAD_MIXPANEL =
    VITE_MIXPANEL_KEY &&
    (MODE !== 'development' || VITE_DEBUG_MIXPANEL === '1');

export const MIXPANEL_EVENT_NAMES = {
    offers: {
        offer: {
            create: 'Drawer create offer',
            update: 'Drawer update offer',
        },
        offerEvent: {
            create: 'Drawer create offer event',
        },
        [IOfferEventType.Elearning]: {
            offer: {
                create: 'Create e-learning journey in drawer',
            },
            offerEvent: {
                create: 'Create offer event e-learning in drawer',
                update: 'Update offer event e-learning in drawer',
            },
        },
        [IOfferEventType.Training]: {
            offer: {
                create: 'Create offer learning journey in drawer',
            },
            offerEvent: {
                create: 'Create offer event learning journey in drawer',
                update: 'Update offer event learning journey in drawer',
            },
        },
        [IOfferEventType.Course]: {
            offer: {
                create: 'Create offer course module in drawer',
            },
            offerEvent: {
                create: 'Create offer event course module in drawer',
                update: 'Update offer event course module in drawer',
            },
        },
        [IOfferEventType.Webinar]: {
            offer: {
                create: 'Create offer webinar in drawer',
            },
            offerEvent: {
                create: 'Create offer event webinar in drawer',
                update: 'Update offer event webinar in drawer',
            },
        },
        [IOfferEventType.Meeting]: {
            offer: {
                create: 'Create offer meeting in drawer',
            },
            offerEvent: {
                create: 'Create offer event meeting in drawer',
                update: 'Update offer event meeting in drawer',
            },
        },
        [IOfferEventType.Scorm]: {
            offer: {
                create: 'Create offer SCORM package in drawer',
            },
            offerEvent: {
                create: 'Create offer event SCORM package in drawer',
                update: 'Update offer event SCORM package in drawer',
            },
        },
        [IOfferEventType.Video]: {
            offer: {
                create: 'Create offer video in drawer',
            },
            offerEvent: {
                create: 'Create offer event video in drawer',
                update: 'Update offer event video in drawer',
            },
        },
    },
};
