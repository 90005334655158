import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { ClipboardCopyInput } from 'common/components/FormField/ClipboardCopyInput';

export const ToolURLForm = () => {
    const [translate] = useTranslation();

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="lTIPlatformForm.toolUrlForm.toolUrlDescription"
                    />
                </Box>
            }
            title={translate('lTIPlatformForm.toolUrlForm.toolUrlTitle')}
        >
            <Box mt={1}>
                <Field
                    component={ClipboardCopyInput}
                    label={translate(
                        'lTIPlatformForm.toolUrlForm.initLoginUrl'
                    )}
                    name="ltiAuthenticateUrl"
                />

                <Field
                    component={ClipboardCopyInput}
                    label={translate('lTIPlatformForm.toolUrlForm.redirectUrl')}
                    name="ltiLaunchUrl"
                />

                <Field
                    component={ClipboardCopyInput}
                    label={translate('lTIPlatformForm.toolUrlForm.jwksUrl')}
                    name="ltiJwksUrl"
                />
            </Box>
        </FormSection>
    );
};
