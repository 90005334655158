import { Box, SxProps } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { Typography } from 'common/components/Typography';
import { withSxProp } from 'common/utils/props';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';

interface IProps {
    closeIconPosition?: 'left' | 'right';
    title?: React.ReactNode;
    actions?: React.ReactNode;
    hideCloseIcon?: boolean;
    onClose?: (
        reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'
    ) => void;
    sx?: SxProps;
    floating?: boolean;
}

const pageDrawerHeaderStyle: SxProps = {
    display: 'flex',
    backgroundColor: 'grey.100',
    alignItems: 'center',
    p: 1,
};

const floatingPageDrawerHeaderStyle: SxProps = {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'transparent',
};

export const PageDrawerHeader = ({
    closeIconPosition = 'left',
    title,
    actions,
    hideCloseIcon,
    onClose,
    sx,
    floating,
    ...other
}: IProps) => (
    <Box
        {...other}
        sx={[
            pageDrawerHeaderStyle,
            floating && floatingPageDrawerHeaderStyle,
            ...withSxProp(sx),
        ]}
    >
        <Box
            display="flex"
            sx={[
                {
                    display: 'flex',
                    width: '35px',
                },
                closeIconPosition === 'left' ? { mr: 'auto' } : { ml: 'auto' },
            ]}
        >
            {!hideCloseIcon && (
                <ActionButton
                    color="whiteTransparent"
                    size="medium"
                    onClick={() => onClose?.('closeButtonClick')}
                >
                    <Icon path={mdiClose} size="2.4rem" />
                </ActionButton>
            )}
        </Box>
        {title && (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: 0,
                    px: 2,
                    width: '100%',
                }}
            >
                <Typography noWrap sx={{ fontWeight: 'bold' }} variant="body2">
                    {title}
                </Typography>
            </Box>
        )}

        {!!actions && <Box sx={{ display: 'flex', ml: 'auto' }}>{actions}</Box>}
    </Box>
);
