import { useTranslation } from 'react-i18next';
import { lazy } from 'react';

import {
    IOrganisationFragment,
    useCurrentUserQuery,
    useOrganisationQuery,
} from 'graphql/types';
import { ELoadFontWeight } from 'common/types';
import { useLoadFonts } from 'common/hooks/useLoadFonts';

const LottieAnimation = lazy(() =>
    import('common/components/Lottie').then((module) => ({
        default: module.LottieAnimation,
    }))
);

interface IProps {
    overrideOrganisation?: Partial<IOrganisationFragment>;
}

export const OrganisationOnboardingLottie = ({
    overrideOrganisation,
}: IProps) => {
    const { t: translate } = useTranslation();

    const { fontsLoaded } = useLoadFonts([
        {
            family: 'Kreon',
            weights: [ELoadFontWeight.Normal, ELoadFontWeight.Bold],
        },
        {
            family: 'Lato',
            weights: [ELoadFontWeight.Normal, ELoadFontWeight.Bold],
        },
    ]);

    const { data: currentUserData } = useCurrentUserQuery();
    const { currentUser } = currentUserData || {};
    const firstName = currentUser?.name.split(' ')[0];

    const { data: currentOrgData } = useOrganisationQuery();
    let organisation = currentOrgData?.organisation;

    if (organisation && overrideOrganisation) {
        // Merge organisation with given overrides for organisation
        organisation = { ...organisation, ...overrideOrganisation };
    }

    const { logo, participantIntroImage, theme } = organisation || {};
    const primaryColor = theme?.palette.primary;

    if (!fontsLoaded) return null;

    return (
        <LottieAnimation
            lottieAttrs={[
                {
                    selector: '.progressbar path',
                    attributes: {
                        fill: primaryColor,
                    },
                },
                {
                    selector: '#logo image',
                    attributes: {
                        href: logo?.url || undefined,
                        preserveAspectRatio: 'xMinYMid',
                    },
                },
                {
                    selector: '#headerImage image',
                    attributes: {
                        href: participantIntroImage?.url || undefined,
                    },
                },
            ]}
            texts={[
                {
                    selector: '#title',
                    text: translate('orgOnboardingLottie.title'),
                },
                {
                    selector: '#subtitle',
                    text: translate('orgOnboardingLottie.subtitle', {
                        name: firstName || '',
                    }),
                },
                {
                    selector: '#coursetitle-1',
                    text: translate('orgOnboardingLottie.card1Title'),
                },
                {
                    selector: '#coursetitle-2',
                    text: translate('orgOnboardingLottie.card2Title'),
                },
                {
                    selector: '#coursetitle-3',
                    text: translate('orgOnboardingLottie.card3Title'),
                },
            ]}
            type="organisationOnboarding"
        />
    );
};
