import { Box } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { AlertDialog } from 'common/components/AlertDialog';
import { IconButton } from 'common/components/IconButton';
import { Embed } from 'common/components/Embed';

interface IProps {
    open: boolean;
    title?: string;
    onClose(): void;
    src: string;
}

export const VideoPlayerDialog = ({ title, src, open, onClose }: IProps) => {
    const header = (
        <Box alignItems="center" display="flex">
            {title}
            <Box ml="auto">
                <IconButton
                    color="inherit"
                    iconPath={mdiClose}
                    iconSize="2.4rem"
                    size="large"
                    onClick={onClose}
                />
            </Box>
        </Box>
    );

    return (
        <AlertDialog
            fullWidth
            maxWidth="lg"
            open={open}
            title={header}
            onClose={onClose}
        >
            <Embed autoplay src={src} />
        </AlertDialog>
    );
};
