import {
    getAbsoluteDate,
    isSameDay,
    isSameMonth,
    dateIsCurrentYear,
} from 'common/utils/formatDate';
import { IFlatOfferEventDateFragment } from 'graphql/types';
import { i18n } from 'utils';

export function getOfferDateDisplay(
    date: IFlatOfferEventDateFragment,
    count: number,
    event?: boolean,
    withTimes?: boolean
) {
    const { startDate, endDate } = date;

    const sameDay = isSameDay(startDate, endDate);
    const sameMonth = isSameMonth(startDate, endDate);
    const endDateIsCurrentYear = dateIsCurrentYear(endDate);

    let dateDisplay = sameDay
        ? getAbsoluteDate(startDate)
        : `${getAbsoluteDate(
              startDate,
              // Show only day of startDate when it's the same month and the endDate year is the current year
              sameMonth && endDateIsCurrentYear ? 'd' : undefined
          )} - ${getAbsoluteDate(endDate)}`;

    if (withTimes && sameDay) {
        dateDisplay = `${dateDisplay} ${getOfferEventTimes(date)}`;
    }

    return `${dateDisplay}${
        count > 1
            ? ` |  ${i18n.t(event ? 'otherDates' : 'otherEvents', {
                  count: count - 1,
              })}`
            : ''
    }`;
}

export function getOfferEventDateTime(date: IFlatOfferEventDateFragment) {
    const { startDate, endDate } = date;

    const startDateFormatted = getAbsoluteDate(startDate, 'd MMMM yyyy HH:mm');

    if (isSameDay(startDate, endDate)) {
        return `${startDateFormatted} - ${getAbsoluteDate(endDate, 'HH:mm')}`;
    }

    return `${startDateFormatted} - ${getAbsoluteDate(
        endDate,
        'd MMMM yyyy HH:mm'
    )}`;
}

export function getOfferEventTimes(date: IFlatOfferEventDateFragment) {
    const { startDate, endDate } = date;

    const startTime = getAbsoluteDate(startDate, 'HH:mm');
    const endTime = getAbsoluteDate(endDate, 'HH:mm');

    return `${startTime} - ${endTime}`;
}
