import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, CircularProgress } from '@mui/material';
import { mdiPlus } from '@mdi/js';
import { useParams, useNavigate } from 'react-router-dom';

import { IFeature } from 'graphql/types';
import { useFeature } from 'common/hooks/useFeature';
import { Typography } from 'common/components/Typography';
import { ActionButton } from 'common/components/ActionButton';
import { VideoUpload } from 'video/components/VideoUpload';
import { VideosManagementTable } from 'video/components/VideosManagementTable';
import { PageTitle } from 'common/components/PageTitle';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { getUrl } from 'route/utils/getUrl';
import VIDEO_MODULE_DRAWER_HEADER from 'assets/images/video-module-drawer-header.webp';
import { VideoUpdateDrawer } from 'video/components/VideoManager';
import { Icon } from 'common/components/Icon';
import { BackgroundImg } from 'common/components/Img';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { UpgradePage } from 'common/components/UpgradePage/UpgradePage';
import { UpgradeContent } from 'video/components/UpgradeContent';

import { BasePage } from './BasePage';

export const VideosManagementPage = () => {
    const [uploadEnabled, setUploadEnabled] = useState(true);
    const [translate] = useTranslation();
    const { videoId } = useParams();
    const navigate = useNavigate();
    const {
        canUse: canUseVideo,
        userCanSeeUpgrade,
        loading: loadingFeature,
    } = useFeature(IFeature.Video);

    const updateVideoDrawerOpen = !!useRouteMatch('VIDEO_EDIT');

    const showUpgradePage = !canUseVideo && userCanSeeUpgrade;

    const header = (
        <BackgroundImg
            src={VIDEO_MODULE_DRAWER_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    // User cannot use video module and cannot see upgrade page
    if (!loadingFeature && !canUseVideo && !userCanSeeUpgrade) {
        return <FourOFourPage />;
    }

    return (
        <BasePage
            contentContainerMaxWidth="lg"
            header={showUpgradePage ? undefined : header}
        >
            <PageTitle mixpanelTitle="Video management page">
                {translate('videosManagementPage.browserTitle')}
            </PageTitle>

            {!showUpgradePage ? (
                <>
                    <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs>
                                <Typography variant="h1">
                                    {translate(
                                        'videosManagementPage.pageTitle'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <VideoUpload
                                    multiple
                                    disabled={!uploadEnabled}
                                    id="newVideo"
                                    onError={() => {
                                        // When upload fails we can enable the upload again
                                        setUploadEnabled(true);
                                    }}
                                    onStart={() => {
                                        setUploadEnabled(false);
                                    }}
                                    onStartUpload={() => {
                                        // When upload is actually started we can enable the upload again
                                        setUploadEnabled(true);
                                    }}
                                >
                                    <ActionButton
                                        outlined
                                        component="span"
                                        disabled={!uploadEnabled}
                                        variant="extended"
                                    >
                                        <Box>
                                            {uploadEnabled ? (
                                                <Icon
                                                    path={mdiPlus}
                                                    size="2.4rem"
                                                />
                                            ) : (
                                                <CircularProgress
                                                    color="inherit"
                                                    size="2.4rem"
                                                    sx={{ p: '2px' }}
                                                />
                                            )}
                                        </Box>
                                        {translate('uploadVideo')}
                                    </ActionButton>
                                </VideoUpload>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box py={4}>
                        <VideosManagementTable />
                    </Box>

                    <VideoUpdateDrawer
                        id={videoId}
                        open={updateVideoDrawerOpen}
                        onClose={() => {
                            const searchParams = new URLSearchParams(
                                location.search
                            );
                            const url = `${getUrl('VIDEO_MANAGEMENT_LIST')}?${searchParams}`;

                            navigate(url);
                        }}
                    />
                </>
            ) : (
                <UpgradePage>
                    <UpgradeContent />
                </UpgradePage>
            )}
        </BasePage>
    );
};
