import { useMemo, useState } from 'react';

import { IPortfolioItemReportSearchFilterValues } from 'graphql/types';
import { REPORT_FILTERS } from 'report/constants/filter';
import { TReportType } from 'report/types';
import {
    coursesFilter,
    offerEventsFilter,
    trainingsFilter,
    scormsFilter,
    externalContentsFilter,
} from 'common/utils/drawerFilters';
import { IFilterFormItem } from 'common/types';

/*
 * This hook is used to manage the aggregated query filter values
 */
export const useReportQueryFilterValues = (type: TReportType) => {
    const [queryFilterValues, setQueryFilterValues] =
        useState<IPortfolioItemReportSearchFilterValues>();

    const filters = useMemo(() => {
        let trainingFilters;
        let offerEventFilters;
        const baseFilters = [...REPORT_FILTERS[type]];

        switch (type) {
            case 'training':
                trainingFilters = queryFilterValues?.trainings || undefined;

                return [
                    ...baseFilters,
                    trainingsFilter(undefined, undefined, {
                        ids: trainingFilters?.map((training) => training.id),
                        isFilter: true,
                    }) as IFilterFormItem,
                ];

            case 'offer':
                offerEventFilters = queryFilterValues?.offerEvents || undefined;

                return [
                    ...baseFilters,
                    offerEventsFilter(undefined, undefined, {
                        ids: offerEventFilters?.map(
                            (offerEvent) => offerEvent.id
                        ),
                        isFilter: true,
                    }) as IFilterFormItem,
                ];
            case 'portfolio':
                trainingFilters =
                    queryFilterValues?.parentsTrainings || undefined;
                offerEventFilters =
                    queryFilterValues?.parentsOfferEvents || undefined;

                return [
                    ...baseFilters,
                    trainingsFilter(undefined, undefined, {
                        ids: trainingFilters?.map((training) => training.id),
                        isFilter: true,
                    }) as IFilterFormItem,
                    offerEventsFilter(undefined, undefined, {
                        ids: offerEventFilters?.map(
                            (offerEvent) => offerEvent.id
                        ),
                        isFilter: true,
                    }) as IFilterFormItem,
                ];
            case 'course':
                trainingFilters =
                    queryFilterValues?.parentsTrainings || undefined;
                offerEventFilters =
                    queryFilterValues?.parentsOfferEvents || undefined;
                const courseFilters = queryFilterValues?.courses || undefined;
                const scormFilters = queryFilterValues?.scorms || undefined;
                const externalContentFilters =
                    queryFilterValues?.externalContents || undefined;

                return [
                    ...baseFilters,
                    trainingsFilter(undefined, undefined, {
                        ids: trainingFilters?.map((training) => training.id),
                        isFilter: true,
                    }) as IFilterFormItem,
                    offerEventsFilter(undefined, undefined, {
                        ids: offerEventFilters?.map(
                            (offerEvent) => offerEvent.id
                        ),
                        isFilter: true,
                    }) as IFilterFormItem,
                    coursesFilter(undefined, undefined, {
                        ids: courseFilters?.map((course) => course.id),
                        isFilter: true,
                    }) as IFilterFormItem,
                    scormsFilter(undefined, undefined, {
                        ids: scormFilters?.map((scorm) => scorm.id),
                        isFilter: true,
                    }) as IFilterFormItem,
                    externalContentsFilter(undefined, undefined, {
                        ids: externalContentFilters?.map(
                            (externalContent) => externalContent.id
                        ),
                        isFilter: true,
                    }) as IFilterFormItem,
                ];
            default:
                return baseFilters;
        }
    }, [type, queryFilterValues]);

    return {
        filters,
        queryFilterValues,
        setQueryFilterValues,
    };
};
