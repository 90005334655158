import { ApolloError } from '@apollo/client';

import {
    IFontSize,
    IFontWeight,
    useCurrentUserQuery,
    useOrganisationQuery,
    useScoringSystemsQuery,
} from 'graphql/types';
import { FONT_WEIGHTS } from 'common/constants/fonts';
import { useHashMatch } from 'route/hooks/useHashMatch';

export const useOrganisationSettingsData = () => {
    const organisationSettingsDrawerOpen = useHashMatch(
        'ORGANISATION_SETTINGS'
    );
    const {
        data: organisationData,
        loading: organisationLoading,
        error: organisationError,
    } = useOrganisationQuery();
    const {
        data: scoringSystemsData,
        loading: scoringSystemsLoading,
        error: scoringSystemsError,
    } = useScoringSystemsQuery({
        skip: !organisationSettingsDrawerOpen,
    });
    const {
        data: currentUserData,
        loading: currentUserLoading,
        error: currentUserError,
    } = useCurrentUserQuery({
        skip: !organisationSettingsDrawerOpen,
    });

    const { organisation } = organisationData || {};
    const { scoringSystems } = scoringSystemsData || {};
    const { currentUser } = currentUserData || {};

    const {
        language,
        participantIntroTitle,
        participantIntroTitleColor,
        participantIntroText,
        participantIntroImage,
        logo,
        favicon,
        image,
        theme,
        homeHeaderImage,
        homeTitle,
        homeTitleColor,
        homeText,
        showIntroductionPanel,
        customMenuIcon,
        customMenuItems,
        footerImage,
        footerText,
        footerLink,
    } = organisation || {};
    const { palette, typography } = theme || {};
    const { body, title, subtitle, customFonts } = typography || {};
    const {
        primary: primaryColor,
        secondary: secondaryColor,
        title: titleColor,
        subtitle: subtitleColor,
        body: bodyColor,
    } = palette || {};

    const typographyInitialValues = {
        body: {
            fontFamily: body?.fontFamily,
            size: body?.size || IFontSize.Md,
            weight:
                body?.fontFamily && body?.weight
                    ? FONT_WEIGHTS[body.weight]
                    : IFontWeight.Normal,
        },

        title: {
            fontFamily: title?.fontFamily,
            size: title?.size || IFontSize.Md,
            weight:
                title?.fontFamily && title?.weight
                    ? FONT_WEIGHTS[title.weight]
                    : IFontWeight.Bold,
        },

        subtitle: {
            fontFamily: subtitle?.fontFamily,
            size: subtitle?.size || IFontSize.Md,
            weight:
                subtitle?.fontFamily && subtitle?.weight
                    ? FONT_WEIGHTS[subtitle.weight]
                    : IFontWeight.Bold,
        },
    };

    const initialValues = {
        language,
        participantIntroTitle: participantIntroTitle || '',
        participantIntroTitleColor:
            participantIntroTitleColor?.replace('#', '') || undefined,
        participantIntroText: participantIntroText || '',
        participantIntroImage,
        logo,
        favicon,
        image,
        palette: {
            primary: primaryColor?.replace('#', '') || undefined,
            secondary: secondaryColor?.replace('#', '') || undefined,
            title: titleColor?.replace('#', '') || undefined,
            subtitle: subtitleColor?.replace('#', '') || undefined,
            body: bodyColor?.replace('#', '') || undefined,
        },
        typography: typographyInitialValues,
        homeHeaderImage,
        homeTitle,
        homeTitleColor: homeTitleColor?.replace('#', '') || undefined,
        homeText,
        showIntroductionPanel,
        customMenuIcon,
        customMenuItems:
            customMenuItems?.map((menuItem) => ({
                text: menuItem.text,
                url: menuItem.url.replace(/(^\w+:|^)\/\//, ''),
                order: menuItem.order,
            })) || [],
        customFonts: customFonts || [],
        scoringSystems:
            scoringSystems?.map((scoringSystem) => ({
                id: scoringSystem.id,
                nameSingular: scoringSystem.nameSingular,
                namePlural: scoringSystem.namePlural,
            })) || [],
        footerImage: footerImage || null,
        footerText: footerText || '',
        footerLink: footerLink?.replace(/(^\w+:|^)\/\//, '') || '',
    };

    return {
        organisation,
        currentUser,
        initialValues,
        loading:
            organisationLoading || scoringSystemsLoading || currentUserLoading,
        errors: [
            organisationError,
            scoringSystemsError,
            currentUserError,
        ].filter(Boolean) as ApolloError[],
    };
};
