import { Field, Form, Formik, FormikConfig } from 'formik';
import { Box, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'common/components/Button';
import {
    DatePicker,
    TextField,
    MultiSelect,
    Autocomplete,
} from 'common/components/FormField';
import { TExtraUserField } from 'user/types';
import { getExtraFieldChoicesInitialValue } from 'common/utils/userExtraFields';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { Typography } from 'common/components/Typography';
import { getExtraFieldsName } from 'common/utils/extraFields';
import { Checkbox } from 'common/components/Checkbox';

export type TWelcomeDrawerFormValues = {
    [key: string]: string | Date | [] | null;
};

type TWelcomeDrawerExtraField = TExtraUserField & {
    initialValue?: string | Date | [] | null | boolean;
};

interface IProps
    extends Omit<FormikConfig<TWelcomeDrawerFormValues>, 'initialValues'> {
    className?: string;
    fields: TWelcomeDrawerExtraField[];
}

export const WelcomeDrawerForm = ({ fields, className, ...other }: IProps) => {
    const [translate] = useTranslation();

    const initialValues = fields.reduce(
        (acc, curVal) => ({
            ...acc,
            [getExtraFieldsName(curVal.name)]: getExtraFieldChoicesInitialValue(
                curVal,
                curVal.initialValue
            ),
        }),
        {}
    );

    const shape = fields.reduce((acc, field) => {
        if (!field.required) return acc;

        return {
            ...acc,
            [getExtraFieldsName(field.name)]:
                field.__typename === 'UserExtraChoiceField'
                    ? field.multiple
                        ? Yup.array()
                              .of(Yup.string())
                              .min(1, translate('validate.required'))
                              .required(translate('validate.required'))
                        : Yup.string()
                              .min(1, translate('validate.required'))
                              .required(translate('validate.required'))
                    : field.__typename === 'UserExtraCheckboxField'
                      ? Yup.boolean().oneOf(
                            [true],
                            translate('validate.required')
                        )
                      : Yup.string()
                            .required(translate('validate.required'))
                            .nullable(),
        };
    }, {});

    const hasValues = !!Object.keys(initialValues).length;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape(shape)}
            {...other}
        >
            {({
                submitForm,
                isSubmitting,
                dirty,
                setFieldValue,
                values,
                errors,
            }) => (
                <>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form className={className}>
                        {fields.map((field) => {
                            const extraFieldName = getExtraFieldsName(
                                field.name
                            );

                            switch (field.__typename) {
                                case 'UserExtraDateField':
                                    return (
                                        <Field
                                            component={DatePicker}
                                            key={field.name}
                                            label={field.name}
                                            name={extraFieldName}
                                            required={field.required}
                                        />
                                    );

                                case 'UserExtraStringField':
                                    return (
                                        <Field
                                            component={TextField}
                                            key={field.name}
                                            label={field.name}
                                            name={extraFieldName}
                                            required={field.required}
                                        />
                                    );

                                case 'UserExtraChoiceField':
                                    return (
                                        <Field
                                            component={
                                                field.multiple
                                                    ? MultiSelect
                                                    : Autocomplete
                                            }
                                            disableClearable={!field.multiple}
                                            InputProps={{
                                                label: field.name,
                                                required: field.required,
                                            }}
                                            key={field.name}
                                            label={field.name}
                                            name={extraFieldName}
                                            options={field.possibleValues}
                                            required={field.required}
                                        />
                                    );
                                case 'UserExtraCheckboxField':
                                    return (
                                        <Box key={field.name}>
                                            <FormControlLabel
                                                checked={
                                                    values[
                                                        extraFieldName as keyof typeof values
                                                    ] || false
                                                }
                                                control={
                                                    <Field
                                                        component={Checkbox}
                                                        required={
                                                            field.required
                                                        }
                                                    />
                                                }
                                                label={field.name}
                                                name={extraFieldName}
                                                onChange={(_e, checked) => {
                                                    setFieldValue(
                                                        extraFieldName,
                                                        checked
                                                    );
                                                }}
                                            />
                                            {errors[
                                                extraFieldName as keyof typeof errors
                                            ] && (
                                                <Typography
                                                    className="MuiFormHelperText-contained"
                                                    color="error.main"
                                                    variant="body2"
                                                >
                                                    {
                                                        errors[
                                                            extraFieldName as keyof typeof errors
                                                        ]
                                                    }
                                                </Typography>
                                            )}
                                        </Box>
                                    );

                                default:
                                    return null;
                            }
                        })}

                        <Box mt={2}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    (!isSubmitting && !hasValues)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </Box>
                    </Form>
                </>
            )}
        </Formik>
    );
};
