import { Portal, Snackbar as MuiSnackbar, SnackbarProps } from '@mui/material';

export const Snackbar = ({ open, onClose, ...other }: SnackbarProps) => (
    <Portal>
        <MuiSnackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            autoHideDuration={5000}
            disableWindowBlurListener={true}
            open={open}
            onClose={onClose}
            {...other}
        />
    </Portal>
);
