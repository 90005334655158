import {
    Theme,
    Typography as MuiTypography,
    TypographyProps,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

export function Typography<C extends React.ElementType>({
    sx,
    color,
    variant,
    ...other
}: TypographyProps<C, { component?: C }>) {
    const titles = ['h1'];
    const subtitles = ['h2', 'h3'];

    const getTextColor = (theme: Theme) => {
        if (color) return color as string;

        if (!variant) return;

        if (titles.includes(variant)) {
            return theme.palette.title.main;
        }

        if (subtitles.includes(variant)) {
            return theme.palette.subtitle.main;
        }

        if (variant === 'body1') return theme.palette.body?.main;
    };

    return (
        <MuiTypography
            sx={[
                {
                    color: getTextColor,
                },
                ...withSxProp(sx),
            ]}
            variant={variant}
            {...other}
        />
    );
}
