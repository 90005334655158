import { useContext } from 'react';

import { AppPermissionsContext } from 'user/contexts/AppPermissionsContext';
import {
    TPermissionsKey,
    TPermissionTypeKey,
    TPermissionValue,
} from 'user/types';

export function useAppPermissions<
    T extends TPermissionsKey,
    K extends TPermissionTypeKey<T>,
>(objectTypeKey: T, typeKey: K): TPermissionValue {
    const appPermissions = useContext(AppPermissionsContext);

    return appPermissions?.[objectTypeKey]?.[typeKey] || {};
}
