import { track, OverridedMixpanel } from 'mixpanel-browser';

type TTrackParams = Parameters<typeof track>;

interface ITrackOptions {
    eventName: TTrackParams[0];
    properties?: TTrackParams[1];
    options?: TTrackParams[2];
    callback?: TTrackParams[3];
}

/**
 * Function to return the mixpanel track function that uses the mixpanel given argument.
 */
export function trackMixpanel(mixpanel?: OverridedMixpanel) {
    return function _trackMixpanel(trackOptions: ITrackOptions) {
        return new Promise((resolve) => {
            const defaultOptions = { send_immediately: true };

            const { eventName, properties, options } = trackOptions;

            // When Mixpanel is not loaded we want to call the callback directly. we pass 0 to callback because it will pass
            // a response when using mixpanel and 0 is an accepted response.
            if (!mixpanel) return resolve(0);

            const requestOptions = { ...defaultOptions, ...options };

            try {
                // Call mixpanel track function with the given option values
                mixpanel.track(eventName, properties, requestOptions, resolve);
            } catch {
                // When tracking fails we just call the callback directly.
                // return callback ? callback(0) : null;
                return resolve(0);
            }
        });
    };
}
