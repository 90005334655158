import { styled } from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

interface IProps {
    size?: 'small';
}

export const CardMediaIcon = styled(motion.div).attrs(() =>
    // Animation properties
    ({
        transition: {
            type: 'easeOut',
        },
        variants: {
            initCardButton: {
                scale: 0.8,
            },
            hoverCardButton: {
                scale: 1,
                transition: { type: 'easeIn' },
            },
        },
    })
)<IProps>`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => rgba(theme.palette.common.black, 0.62)};
    border-radius: 50%;
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};

    svg {
        width: 2.3rem;
        height: 2.3rem;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        transform: scale(1);

        ${({ size }) => {
            if (!size) {
                return `
                    width: 60px;
                    height: 60px;
                `;
            }

            return (
                size === 'small' &&
                `
                    height: 50px;
                    width: 50px;
                `
            );
        }}

        svg {
            width: 3rem;
            height: 3rem;
        }
    }
`;
