import { TableRow, Typography } from '@mui/material';

import { IPortfolioItemReportFragment } from 'graphql/types';
import { TableCell } from 'common/components/Table';
import { formatDate } from 'common/utils/formatDate';
import { Chip } from 'common/components/Chip';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    certificateItem: IPortfolioItemReportFragment;
}

export const TrainingParticipantsCertificateTableRow = ({
    certificateItem,
}: IProps) => {
    const { openGlobalDrawer, closeGlobalDrawer } = useGlobalDrawer();

    const { id, title, endDate } = certificateItem;

    const endDateString = endDate && formatDate(endDate, 'd LLL yyyy');

    const isExpired = !endDate ? false : new Date(endDate) < new Date();

    const handleClick = () => {
        openGlobalDrawer({
            type: IGlobalDrawerType.PortfolioItem,
            itemId: id,
            props: {
                onClose: () => {
                    closeGlobalDrawer({
                        type: IGlobalDrawerType.PortfolioItem,
                        itemId: id,
                    });
                },
            },
        });
    };

    return (
        <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleClick}>
            <TableCell>{title}</TableCell>
            <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                <Typography
                    sx={{ color: isExpired ? 'error.main' : undefined }}
                    variant="body2"
                >
                    {endDateString}
                </Typography>
            </TableCell>
            <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                <Chip hover color={isExpired ? 'error' : 'success'} />
            </TableCell>
        </TableRow>
    );
};
