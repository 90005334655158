import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { isVideoAdded, isVideoSelected } from 'training/utils/videoSelect';
import { Typography } from 'common/components/Typography';
import { IVideoListItemFragment } from 'graphql/types';

import { VideoListItem } from '../VideoListItem';

interface IProps {
    addedVideos: IVideoListItemFragment[];
    selectedVideos: IVideoListItemFragment[];
    videos: IVideoListItemFragment[];
    onAddVideo(selectedTraining: IVideoListItemFragment): void;
    onRemoveVideo(selectedTraining: IVideoListItemFragment): void;
    listItemProps?: Partial<React.ComponentProps<typeof VideoListItem>>;
}

export const VideoAddList = ({
    addedVideos,
    selectedVideos,
    videos,
    onAddVideo,
    onRemoveVideo,
    listItemProps,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleSelectVideo = (
        video: IVideoListItemFragment,
        isAdded: boolean
    ) => {
        isAdded ? onRemoveVideo(video) : onAddVideo(video);
    };

    return (
        <>
            {!videos.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>{translate('noOptionsText.videos')}</Typography>
                </Box>
            ) : (
                videos.filter(Boolean).map((video) => {
                    const isSelected = isVideoSelected(selectedVideos, video);
                    const isAdded = isVideoAdded(addedVideos, video);

                    return (
                        <VideoListItem
                            addButton
                            added={isSelected || isAdded}
                            key={video.id}
                            px={2}
                            selected={isSelected || isAdded}
                            video={video}
                            onClick={() =>
                                handleSelectVideo(video, isSelected || isAdded)
                            }
                            {...listItemProps}
                        />
                    );
                })
            )}
        </>
    );
};
