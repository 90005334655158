import { useMemo, ReactElement } from 'react';

import { IDevelopmentItemListItemFragment } from 'graphql/types';
import { DevelopmentListItem } from 'user/components/DevelopmentListItem';
import { List } from 'common/components/List';
import { Draggable, Droppable } from 'common/components/DragDrop';

interface IProps {
    className?: string;
    groupId: string;
    items: IDevelopmentItemListItemFragment[];
    withEditActions?: boolean;
    isItemVisible: (item: IDevelopmentItemListItemFragment) => boolean;
    onClickItem(item?: IDevelopmentItemListItemFragment): void;
}

export const DevelopmentItemList = ({
    className,
    groupId,
    items,
    withEditActions,
    isItemVisible,
    onClickItem,
}: IProps) => {
    const itemNodes = useMemo(
        () =>
            items.map((item, index) => {
                const node: ReactElement = (
                    <DevelopmentListItem
                        isHidden={!isItemVisible(item)}
                        key={item.id}
                        withEditActions={withEditActions}
                        onClick={() => onClickItem(item)}
                        {...item}
                    />
                );

                return (
                    <Draggable
                        dragDropDisabled={!withEditActions}
                        draggableId={item.id}
                        index={index}
                        key={item.id}
                    >
                        <div>{node}</div>
                    </Draggable>
                );
            }),
        [withEditActions, items, isItemVisible, onClickItem]
    );

    return (
        <List noBorder className={className}>
            <Droppable
                dragDropDisabled={!withEditActions}
                droppableId={groupId}
                type="ITEM"
            >
                <div>{itemNodes}</div>
            </Droppable>
        </List>
    );
};
