import {
    IBasicUserFragment,
    ICreateOfferEventInput,
    ICreateOfferInput,
    IFileFragment,
    IOfferEventDate,
    ITrainingInputOptionFragment,
} from 'graphql/types';
import { TOfferFormValues } from 'offer/types';
import { IGroupConditionInput } from 'user/types';

type TValues = ICreateOfferInput &
    Omit<ICreateOfferEventInput, 'groupConditions'> & {
        image?: IFileFragment;
        overviewImage?: IFileFragment;
        groupConditions?: IGroupConditionInput[];
        isServiceProviderWebinar?: boolean;
    };

/**
 * Separates offer and offer event values into an object
 */
export function getOfferFormValues(
    values: TValues,
    hasServiceProvider?: boolean
) {
    return Object.keys(values).reduce(
        (acc, field) => {
            // Removes image with file from the eventual form values
            if (field === 'image' || field === 'overviewImage') return acc;

            const offerFields = [
                'title',
                'description',
                'imageId',
                'titleColor',
                'overviewImageId',
                'hasDetailPage',
                'extraCategories',
                'groupConditions',
            ];

            if (offerFields.includes(field)) {
                return {
                    ...acc,
                    offer: { ...acc.offer, [field]: values[field] },
                };
            }

            let newValue = values[field];

            // Makes sure we get lists with id's
            if (
                field === 'accessTrainings' ||
                field === 'accessExternalContents' ||
                field === 'accessCourses' ||
                field === 'trainers' ||
                field === 'accessScorms' ||
                field === 'accessVideos'
            ) {
                newValue = newValue.map(
                    (item: ITrainingInputOptionFragment | IBasicUserFragment) =>
                        item.id
                );
            }

            if (field === 'dates') {
                newValue = newValue.map((offerEventDate: IOfferEventDate) => {
                    const webinarLink = offerEventDate.webinarLink
                        ? `https://${offerEventDate.webinarLink}`
                        : null;

                    const location = offerEventDate.location;
                    const locationId = location ? location?.id : null;
                    const locationString = location?.title || '';

                    // When organisation doesn't have service provider, we always set isServiceProviderWebinar to false
                    const isServiceProviderWebinar = hasServiceProvider
                        ? offerEventDate.isServiceProviderWebinar
                        : false;

                    return {
                        id: offerEventDate.id,
                        startDate: offerEventDate.startDate,
                        endDate: offerEventDate.endDate,
                        webinarLink,
                        locationId,
                        locationString,
                        isServiceProviderWebinar,
                    };
                });
            }

            return {
                ...acc,
                event: { ...acc.event, [field]: newValue },
            };
        },
        { offer: {}, event: {} }
    ) as TOfferFormValues;
}
