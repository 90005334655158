import 'tippy.js/dist/tippy.css';
import { useMemo, useState, useEffect } from 'react';
import { createEditor, Node as SlateNode } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import {
    ParagraphPlugin,
    HeadingPlugin,
    BoldPlugin,
    EditablePlugins,
    ItalicPlugin,
    UnderlinePlugin,
    BlockquotePlugin,
    SuperscriptPlugin,
    ResetBlockTypePlugin,
    MediaEmbedPlugin,
    pipe,
    withTable,
    withList,
    withLink,
    withDeserializeHTML,
    LinkPlugin,
    ListPlugin,
    SoftBreakPlugin,
    ExitBreakPlugin,
    withMarks,
    withTrailingNode,
    withInlineVoid,
} from '@udecode/slate-plugins';

import { options, optionsResetBlockTypes } from './options';
import { FilePlugin } from './elements/file';
import { ImagePlugin } from './elements/image';
import { TablePlugin } from './elements/table';
import { EmptyLeafPlugin } from './plugins/empty-leaf';
import { withNormalize } from './plugins/normalize';
import { EditorToolbar } from './components/EditorToolbar';
import { EditorBlockMenu } from './components/EditorBlockMenu';

const plugins = [
    ParagraphPlugin(options),
    HeadingPlugin(options),
    BoldPlugin(options),
    ItalicPlugin(options),
    UnderlinePlugin(options),
    LinkPlugin(options),
    TablePlugin(options),
    ListPlugin(options),
    BlockquotePlugin(options),
    SuperscriptPlugin(options),
    MediaEmbedPlugin(options),
    ImagePlugin(options),
    FilePlugin(options),
    EmptyLeafPlugin(),

    ResetBlockTypePlugin(optionsResetBlockTypes),
    SoftBreakPlugin({
        rules: [
            { hotkey: 'shift+enter' },
            {
                hotkey: 'enter',
                query: {
                    allow: [options.td.type, options.blockquote.type],
                },
            },
        ],
    }),

    ExitBreakPlugin({
        rules: [
            {
                hotkey: 'mod+enter',
                level: 0,
            },
            {
                level: 0,
                hotkey: 'mod+shift+enter',
                before: true,
                query: {
                    exclude: [options.file.type, options.img.type],
                },
            },
            {
                hotkey: 'enter',
                level: 0,
                query: {
                    start: false,
                    end: true,
                    allow: [
                        options.h1.type,
                        options.h2.type,
                        options.h3.type,
                        options.h4.type,
                    ],
                },
            },
            {
                hotkey: 'enter',
                level: 0,
                query: {
                    allow: [options.file.type, options.img.type],
                },
            },
        ],
    }),
];

const withPlugins = [
    withReact,
    withHistory,
    withTable(options),
    withLink(),
    withList(options),
    // There's a bug with preInsert that will breaks pasting embeds in empty nodes, so we
    // disable the preInsert function
    withDeserializeHTML({ plugins, preInsert: (fragment) => fragment }),
    withMarks(),
    withTrailingNode({ type: options.p.type, level: 0 }),
    withInlineVoid({ plugins }),
    withNormalize(),
] as const;

const emptyValue = [
    {
        type: options.p.type,
        children: [{ text: '' }],
    },
];

interface IProps {
    content?: SlateNode[];
    placeholder?: string;
    readOnly?: boolean;
    role?: string;
    onChange?(value: SlateNode[]): void;
}

export const ContentEditor = ({
    content,
    readOnly,
    onChange,
    ...other
}: IProps) => {
    const [value, setValue] = useState<SlateNode[]>(content || emptyValue);

    useEffect(() => {
        if (!content) return;

        setValue(content);
    }, [content]);

    const editor = useMemo(() => pipe(createEditor(), ...withPlugins), []);

    const handleChange = (value: SlateNode[]) => {
        setValue(value);
        onChange && onChange(value);
    };

    return (
        <Slate editor={editor} value={value || []} onChange={handleChange}>
            {!readOnly && (
                <>
                    <EditorToolbar />
                    <EditorBlockMenu />
                </>
            )}
            <EditablePlugins plugins={plugins} readOnly={readOnly} {...other} />
        </Slate>
    );
};
