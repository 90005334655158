import { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import { styled } from 'styled-components';
import { mdiImage } from '@mdi/js';

import { Icon } from 'common/components/Icon';
import { useUploadFiles } from 'common/hooks/useUploadFiles';
import { IBasicFileFragment, IFileFragment } from 'graphql/types';
import { OverlayCircle } from 'common/components/OverlayCircle';
import { FilePicker } from 'common/components/FilePicker';

export interface IProps {
    id: string;
    className?: string;
    description?: string;
    label?: string;
    image?: IBasicFileFragment;
    imageSize?: 'contain' | 'cover';
    maxUploadSize: number;
    /** Pass accepted file types as a comma separated string with accepted mime types. e.g. "image/png, image/jpeg" */
    acceptedFileTypes?: string;
    onUpload?(file: IFileFragment): void;
    onUploadFinish?: () => void;
    onUploadStart?: () => void;
    onError?(message: string): void;
}

const ErrorMessage = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    color: ${({ theme }) => theme.palette.error.main};
    position: absolute;
    top: 75%;
`;

const BaseImageUploader = ({
    className,
    id,
    image,
    maxUploadSize,
    acceptedFileTypes,
    onUpload,
    onUploadFinish,
    onUploadStart,
}: IProps) => {
    const currentImage = useRef(image?.url);

    const { isUploading, uploadFiles, uploadingFiles } = useUploadFiles({
        onSuccess: (_fileIndex, updatedFile) => {
            if (!updatedFile) return;

            onUpload && onUpload(updatedFile);

            currentImage.current = updatedFile.url;
        },
    });

    const prevIsUploading = useRef(isUploading);

    useEffect(() => {
        const { current: prevUploading } = prevIsUploading;

        if (isUploading === prevUploading) return;

        prevIsUploading.current = isUploading;

        if (!isUploading && onUploadFinish) {
            return onUploadFinish();
        }

        onUploadStart && onUploadStart();
    }, [isUploading, onUploadFinish, onUploadStart]);

    const handleUpload = (files: File[]) => {
        uploadFiles(files, maxUploadSize);
    };

    useEffect(() => {
        // Update the current image if an action like deletion is performed
        currentImage.current = image?.url;
    }, [image]);

    const uploadingFile = uploadingFiles.length ? uploadingFiles[0] : undefined;

    const {
        done: fileDone,
        success: fileSuccess,
        progress: fileProgress,
        errorMessage: fileErrorMessage,
    } = uploadingFile?.status || {};

    const { file } = uploadingFile || {};

    const progressValue =
        !!(uploadingFile && !fileDone && fileProgress && fileProgress >= 0) &&
        `${fileProgress}%`;

    const fileUploaded = uploadingFile && fileDone;
    const noFileUploading = !uploadingFile || fileUploaded;

    const errorMessage = fileUploaded && !fileSuccess && (
        <ErrorMessage>{fileErrorMessage}</ErrorMessage>
    );

    let fileUrl = currentImage.current || image?.url || '';

    // If file is uploaded and not deleted by the user afterwards
    if (fileSuccess && currentImage.current) fileUrl = file?.url || '';

    return (
        <Box
            className={className}
            style={{ backgroundImage: `url(${fileUrl})` }}
        >
            <FilePicker
                className="filePicker"
                id={id}
                inputProps={{ accept: acceptedFileTypes }}
                onUpload={noFileUploading ? handleUpload : undefined}
            >
                <OverlayCircle>
                    {progressValue ? (
                        progressValue
                    ) : (
                        <Icon path={mdiImage} size="3rem" />
                    )}
                </OverlayCircle>
            </FilePicker>

            {errorMessage}
        </Box>
    );
};

export const ImageUploader = styled(BaseImageUploader)`
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 300ms border-color;
    background-repeat: no-repeat;
    background-position: center;

    ${({ theme, imageSize }) => {
        if (!imageSize || imageSize === 'cover')
            return `background-size: cover;`;

        return `
            background-origin: content-box;
            background-size: ${imageSize};
            padding: ${theme.spacing(2, 2)};
        `;
    }}

    &:hover {
        border-color: ${({ theme }) => theme.palette.divider};
    }

    &.noBackground {
        background-color: unset;
    }

    .filePicker {
        width: 100%;
        height: 100%;
    }
`;
