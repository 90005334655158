import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PROFILE_SECTIONS, USER_SECTIONS } from 'user/constants/sections';
import { TTabsMenuItem } from 'common/types';
import { IUserProfileSectionProps } from 'user/types';

import { useUserRoutes } from '.';

interface IState {
    tabs: TTabsMenuItem[];
    Section?: React.FC<React.PropsWithChildren<IUserProfileSectionProps>>;
    canUsePortfolioOffers?: boolean;
    hideOffersSection?: boolean;
    hideMeetingsSection?: boolean;
}

export const useUserProfileTabs = (
    id: string,
    isCurrentUser?: boolean,
    canUsePortfolioOffers?: boolean,
    hideOffersSection?: boolean,
    hideMeetingsSection?: boolean
): IState => {
    const { activeSectionRoute } = useUserRoutes();
    const [translate] = useTranslation();

    const getState = useCallback(() => {
        const tabs: TTabsMenuItem[] = Object.keys(
            isCurrentUser ? PROFILE_SECTIONS : USER_SECTIONS
        )
            .map((section) => {
                const isOffersSection =
                    section === 'PORTFOLIO_OFFERS' || section === 'USER_OFFERS';
                const isMeetingsSection =
                    section === 'PORTFOLIO_MEETINGS' ||
                    section === 'USER_MEETINGS';
                const offerOrMeetingSection =
                    isOffersSection || isMeetingsSection;
                const hideAllOfferTabs =
                    offerOrMeetingSection && !canUsePortfolioOffers;

                if (
                    hideAllOfferTabs ||
                    (hideOffersSection && isOffersSection) ||
                    (hideMeetingsSection && isMeetingsSection)
                ) {
                    return null;
                }

                return {
                    label: translate(`userProfileTabs.${section}`),
                    to: section,
                    params: { id },
                    value: section,
                };
            })
            .filter(Boolean);

        const hasCurrentTab = tabs.find(
            (item) => item.value === activeSectionRoute
        );

        let Section = isCurrentUser
            ? PROFILE_SECTIONS.PORTFOLIO_TRAININGS
            : USER_SECTIONS.USER_TRAININGS;

        if (hasCurrentTab) {
            Section = (isCurrentUser ? PROFILE_SECTIONS : USER_SECTIONS)[
                activeSectionRoute
            ];
        }

        return { tabs, Section };
    }, [
        activeSectionRoute,
        translate,
        id,
        isCurrentUser,
        canUsePortfolioOffers,
    ]);

    const [state, setState] = useState<IState>(getState());

    useEffect(() => {
        setState(getState());
    }, [activeSectionRoute, translate, id, isCurrentUser, getState]);

    return state;
};
