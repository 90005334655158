import ReactDOM from 'react-dom';
import { styled } from 'styled-components';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import UnsupportedBrowser from 'assets/images/unsupported-browser.webp';
import ChromeLogo from 'assets/images/browsers/chrome.webp';
import EdgeLogo from 'assets/images/browsers/edge.webp';
import FirefoxLogo from 'assets/images/browsers/firefox.webp';
import SafariLogo from 'assets/images/browsers/safari.webp';

const BaseUnsupportedBrowser = ({ className }: { className?: string }) => {
    const [translate] = useTranslation();

    return ReactDOM.createPortal(
        <div className={className}>
            <div>
                <img alt="unsupported browser" src={UnsupportedBrowser} />
                <Typography variant="h2">
                    {translate('unsupportedBrowser.title')}
                </Typography>
                <Typography variant="body1">
                    {translate('unsupportedBrowser.description')}
                </Typography>

                <Link
                    href="https://google.com/chrome/"
                    target="_blank"
                    underline="hover"
                >
                    <img alt="chrome logo" src={ChromeLogo} width="30" />
                    Chrome
                </Link>
                <Link
                    href="https://microsoft.com/edge"
                    target="_blank"
                    underline="hover"
                >
                    <img alt="edge logo" src={EdgeLogo} width="30" />
                    Edge
                </Link>
                <Link
                    href="https://mozilla.org/firefox/new/"
                    target="_blank"
                    underline="hover"
                >
                    <img alt="firefox logo" src={FirefoxLogo} width="30" />
                    Firefox
                </Link>
                <Link
                    href="https://support.apple.com/HT204416"
                    target="_blank"
                    underline="hover"
                >
                    <img alt="safari logo" src={SafariLogo} width="30" />
                    Safari
                </Link>
            </div>
        </div>,
        document.body
    );
};

export const DetectUnsupportedBrowser = styled(BaseUnsupportedBrowser)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 0 15px;
    z-index: 3000;
    display: table;
    color: #000;

    & > div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    h2 {
        max-width: 600px;
        margin: 20px auto;
    }

    p {
        max-width: 600px;
        margin: auto;
    }

    img {
        max-width: 400px;
        width: 100%;
    }

    a {
        display: inline-block;
        width: 100px;
        margin: 15px;
    }

    @supports (display: grid) {
        display: none;
    }
`;
