import { styled } from 'styled-components';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

export const Breadcrumbs = styled(MuiBreadcrumbs).attrs({
    separator: '→',
})`
    &,
    a {
        color: ${({ theme }) => theme.palette.text.primary};
    }

    a,
    p {
        font-weight: 700;
    }
`;
