import { useTranslation } from 'react-i18next';
import { mdiSchool } from '@mdi/js';
import { useReactiveVar } from '@apollo/client';

import { Icon } from 'common/components/Icon';
import {
    ICourseModuleListItemFragment,
    useCourseStudentTokenLazyQuery,
} from 'graphql/types';
import { TTrainingModuleProps } from 'training/types';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import { ApolloError } from 'common/components/ApolloError';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { Link } from 'common/components/Link';

import { ModuleSecondaryAction } from '../ModuleSecondaryAction';

export const CourseModule = ({
    id,
    trainingId,
    title,
    description,
    course,
    disabled,
    conditions,
    alwaysOpen,
    startDate,
    image,
    conditionEffect,
    groupConditionEffect,
    weight,
    canUpdate,
    portfolioItem,
    hasProgress,
}: TTrainingModuleProps<ICourseModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    const [getCourseStudentToken, { loading, error }] =
        useCourseStudentTokenLazyQuery({
            onCompleted: (data) => {
                const url = data.courseStudentToken?.url;

                if (!url) return;

                window.location.href = url;
            },
        });

    const openCourse = () => {
        if (disabled) return;

        const returnUrl = window.location.href;
        getCourseStudentToken({
            variables: { id: course.id, returnUrl },
        });
    };

    const itemContent = (
        <>
            <ListItemMedia
                color="primary"
                image={
                    image?.url ||
                    course.thumbnail?.url ||
                    course?.image?.url ||
                    undefined
                }
            >
                <Icon path={mdiSchool} size="1.8rem" sizeSm="3rem" />
            </ListItemMedia>
            <ListItemText
                primary={title || course.title}
                secondary={description}
            >
                <ModuleConditionsChips
                    alwaysOpen={alwaysOpen}
                    conditionEffect={conditionEffect}
                    conditions={conditions}
                    groupConditionEffect={groupConditionEffect}
                    startDate={startDate}
                />
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    if (!studentView && canUpdate) {
        return (
            <ListItem
                button
                component={Link}
                params={{
                    trainingId,
                    moduleId: id,
                    type: 'course',
                }}
                to="TRAINING_UPDATE_MODULE"
            >
                {itemContent}

                <ModuleSecondaryAction
                    forUpdate
                    hasProgress={!!hasProgress}
                    loading={loading}
                    portfolioItem={portfolioItem}
                    weight={weight}
                />
            </ListItem>
        );
    }

    return (
        <>
            <ListItem button onClick={openCourse}>
                {itemContent}

                <ModuleSecondaryAction
                    actionText={translate('start')}
                    hasProgress={!!hasProgress}
                    loading={loading}
                    portfolioItem={portfolioItem}
                    weight={weight}
                />
            </ListItem>
            {error && <ApolloError error={error} />}
        </>
    );
};
