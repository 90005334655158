import { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ICourseListItemFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { AddButton } from 'common/components/Button/AddButton';
import {
    getCourseSelectStatus,
    isCourseAdded,
    isCourseSelected,
} from 'training/utils/courseSelect';
import { CourseListItem } from 'training/components/CourseListItem';

import { CourseSelectDrawer } from './CourseSelectDrawer';

interface IProps {
    name?: string;
    value: ICourseListItemFragment[];
}

export const CourseSelector = ({ name, value }: IProps) => {
    const { t: translate } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState<
        ICourseListItemFragment[]
    >([]);

    const addedCourses = value;

    const handleDrawerOpen = (open: boolean) => {
        if (!open) setSelectedCourses([]);

        setDrawerOpen(open);
    };

    const handleSelectCourse = (course: ICourseListItemFragment) => {
        const newCourses = [...selectedCourses, course].sort((a, b) =>
            a.title > b.title ? 1 : -1
        );

        setSelectedCourses(newCourses);
    };

    const handleRemoveCourse = (
        course: ICourseListItemFragment,
        arrayHelpers: FieldArrayRenderProps
    ) => {
        const { isSelected } = getCourseSelectStatus(
            selectedCourses,
            addedCourses,
            course
        );

        if (isSelected) {
            const newSelectedCourses = selectedCourses.filter(
                (selectedCourse) => selectedCourse.id !== course.id
            );

            setSelectedCourses(newSelectedCourses);

            return;
        }

        const index = addedCourses.findIndex(
            (addedCourse) => course.id === addedCourse.id
        );

        arrayHelpers.remove(index);
    };

    const handleAddCourses = (arrayHelpers: FieldArrayRenderProps) => {
        selectedCourses.forEach((selectedCourse) => {
            arrayHelpers.push(selectedCourse);
        });

        setSelectedCourses([]);

        handleDrawerOpen(false);
    };

    const fieldName = name || 'courses';

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const error = form.errors[fieldName];
                const touched = form.touched[fieldName];

                return (
                    <Box>
                        <Box
                            alignItems="left"
                            display="flex"
                            flexDirection="column"
                        >
                            <AddButton onClick={() => handleDrawerOpen(true)}>
                                <Typography>
                                    {translate('addCoursesTitle')}
                                </Typography>

                                {error && touched && (
                                    <Box mt="-5px" position="absolute">
                                        <FormHelperText error>
                                            {form.errors[fieldName]}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </AddButton>

                            {!!addedCourses.length && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mt={2}
                                >
                                    {addedCourses.map((addedCourse) => (
                                        <CourseListItem
                                            addButton
                                            added={isCourseAdded(
                                                addedCourses,
                                                addedCourse
                                            )}
                                            course={addedCourse}
                                            key={`added-${addedCourse.id}`}
                                            selected={isCourseSelected(
                                                selectedCourses,
                                                addedCourse
                                            )}
                                            onClick={(
                                                course: ICourseListItemFragment
                                            ) =>
                                                handleRemoveCourse(
                                                    course,
                                                    arrayHelpers
                                                )
                                            }
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>

                        <CourseSelectDrawer
                            addedCourses={addedCourses}
                            open={drawerOpen}
                            selectedCourses={selectedCourses}
                            onAddCourses={() => handleAddCourses(arrayHelpers)}
                            onDrawerOpen={handleDrawerOpen}
                            onRemoveCourse={(course) =>
                                handleRemoveCourse(course, arrayHelpers)
                            }
                            onSelectCourse={handleSelectCourse}
                        />
                    </Box>
                );
            }}
        />
    );
};
