import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { Select, TextField } from 'common/components/FormField';
import { IOidcSignAlgo } from 'graphql/types';

export const ConfigurationForm = () => {
    const [translate] = useTranslation();

    const oidcSignAlgoOptions = [
        {
            value: IOidcSignAlgo.Hs256,
            label: IOidcSignAlgo.Hs256,
        },
        {
            value: IOidcSignAlgo.Rs256,
            label: IOidcSignAlgo.Rs256,
        },
    ];

    return (
        <FormSection
            description={
                <Box>
                    <Trans
                        components={{
                            a: <Link rel="noreferrer" underline="always" />,
                        }}
                        i18nKey="openIdConnectionForm.configurationForm.configurationDescription"
                    />
                </Box>
            }
            title={translate(
                'openIdConnectionForm.configurationForm.configurationTitle'
            )}
        >
            <Box mt={1}>
                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.clientId'
                    )}
                    name="clientId"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.clientSecret'
                    )}
                    name="clientSecret"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.authorizationEndpoint'
                    )}
                    name="authorizationEndpoint"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.tokenEndpoint'
                    )}
                    name="tokenEndpoint"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.userEndpoint'
                    )}
                    name="userEndpoint"
                />

                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.scope'
                    )}
                    name="scope"
                />

                <Field
                    component={Select}
                    defaultValue={oidcSignAlgoOptions[0].value}
                    label={translate(
                        'openIdConnectionForm.configurationForm.signAlgo'
                    )}
                    name="oidcSignAlgo"
                    options={oidcSignAlgoOptions}
                />

                <Field
                    component={TextField}
                    label={translate(
                        'openIdConnectionForm.configurationForm.jwksEndpoint'
                    )}
                    name="oidcOpJwksEndpoint"
                />
            </Box>
        </FormSection>
    );
};
