import { FormikHelpers, Field } from 'formik';

import { TExtraField } from 'common/types';
import {
    TextField,
    Autocomplete,
    MultiSelect,
} from 'common/components/FormField';
import {
    getExtraFieldsName,
    isExtraChoiceField,
    isExtraStringField,
} from 'common/utils/extraFields';

interface IProps {
    extraFields: TExtraField[];
    setFieldValue: FormikHelpers<unknown>['setFieldValue'];
}

export const ExtraFieldsFormFields = ({ extraFields }: IProps) => (
    <>
        {extraFields.map((field: TExtraField) => {
            if (isExtraChoiceField(field)) {
                return (
                    <Field
                        component={field.multiple ? MultiSelect : Autocomplete}
                        InputProps={{
                            label: field.name,
                        }}
                        key={field.name}
                        label={field.name}
                        name={`extraFields.${getExtraFieldsName(field.name)}`}
                        options={field.possibleValues || []}
                    />
                );
            } else if (isExtraStringField(field)) {
                return (
                    <Field
                        component={TextField}
                        key={field.name}
                        label={field.name}
                        name={`extraFields.${getExtraFieldsName(field.name)}`}
                    />
                );
            }

            return null;
        })}
    </>
);
