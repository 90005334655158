import { IUserExtraFieldFragment } from 'graphql/types';
import { TFilterBarItem } from 'common/types';

// Transforms extra user fields into filterbar items
export function getExtraUserFieldsFilters(
    extraUserFields?: IUserExtraFieldFragment[]
) {
    if (!extraUserFields) return [];

    return extraUserFields
        .map((field: IUserExtraFieldFragment) => {
            if (
                field.__typename === 'UserExtraDateField' ||
                field.__typename === 'UserExtraCheckboxField' ||
                !field.possibleValues.length
            ) {
                return null;
            }

            return {
                name: field.name,
                children: field.possibleValues.map((value) => ({
                    name: value,
                    value,
                    parent: field.name,
                })),
            };
        })
        .filter(Boolean) as TFilterBarItem[];
}
