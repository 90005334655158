import { createRoot } from 'react-dom/client';

import { App } from 'common/components/App';

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);
    root.render(<App />);
}
