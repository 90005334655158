import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { IGroupListItemFragment } from 'graphql/types';
import { GroupListItem } from 'user/components/GroupListItem/GroupListItem';
import { TGroupPermission } from 'user/types';
import { isGroupSelected } from 'user/utils';

interface IProps {
    checkGroupPermission?: TGroupPermission;
    selectedGroups: IGroupListItemFragment[];
    groups: IGroupListItemFragment[];
    onAddGroup?(selectedGroup: IGroupListItemFragment): void;
    onRemoveGroup?(selectedGroup: IGroupListItemFragment): void;
}

export const GroupAddList = ({
    checkGroupPermission,
    selectedGroups,
    groups,
    onAddGroup,
    onRemoveGroup,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleSelectGroup = (
        group: IGroupListItemFragment,
        isAdded: boolean
    ) => {
        isAdded ? onRemoveGroup?.(group) : onAddGroup?.(group);
    };

    return (
        <>
            {!groups.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>{translate('noOptionsText.groups')}</Typography>
                </Box>
            ) : (
                groups.filter(Boolean).map((group) => {
                    const isSelected = isGroupSelected(selectedGroups, group);
                    const { permissions } = group;
                    const permissionsObj = JSON.parse(permissions || '{}');

                    let isDisabled = false;

                    // Check if the user has the permission to add this group
                    if (
                        (!!checkGroupPermission?.canAddParticipants &&
                            !permissionsObj.canAddParticipants) ||
                        (!!checkGroupPermission?.canAddManagers &&
                            !permissionsObj.canAddManagers)
                    ) {
                        isDisabled = true;
                    }

                    return (
                        <GroupListItem
                            addButton
                            added={isSelected}
                            disabled={isDisabled}
                            group={group}
                            key={group.id}
                            px={2}
                            selected={isSelected}
                            onClick={() => handleSelectGroup(group, isSelected)}
                        />
                    );
                })
            )}
        </>
    );
};
