import { Box, AppBar as MuiAppBar, Toolbar } from '@mui/material';
import { styled } from 'styled-components';

import { Link } from 'common/components/Link';
import { Logo } from 'common/components/Logo';

import { MainNavigation } from '../MainNavigation';

interface IProps {
    className?: string;
    navigation?: React.ReactNode;
    showNavigation?: boolean;
}

export const BaseAppBar = ({
    className,
    navigation,
    showNavigation = true,
}: IProps) => (
    <>
        <MuiAppBar className={className} id="mainNav">
            <Toolbar>
                <Link className="logoLink" to="HOME">
                    <Logo />
                </Link>

                {showNavigation && (navigation || <MainNavigation />)}
            </Toolbar>
        </MuiAppBar>
        {/* Spacer to account for the app bar height */}
        <Box sx={{ height: (theme) => theme.mixins.toolbar.height }} />
    </>
);

export const AppBar = styled(BaseAppBar)`
    background: ${({ theme }) => theme.palette.common.white};
    box-shadow: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    height: ${({ theme }) => theme.mixins.toolbar.height}px;

    .MuiToolbar-regular {
        height: ${({ theme }) => theme.mixins.toolbar.height}px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            height: ${({ theme }) => theme.mixins.toolbar.height}px;
        }
    }

    .logoLink {
        height: 100%;
        padding: ${({ theme }) => theme.spacing(2, 0)};

        ${Logo} {
            max-height: 48px;
            display: block;
        }
    }
`;
