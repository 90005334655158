import { ButtonProps, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components/Button';
import { AlertDialog } from 'common/components/AlertDialog';

interface IProps extends React.ComponentProps<typeof AlertDialog> {
    color?: ButtonProps['color'];
    open: boolean;
    title: string;
    children: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
    loading?: boolean;
    onConfirm(): void;
    onCancel?(): void;
    onClose(): void;
}

export const ConfirmDialog = ({
    color,
    open,
    title,
    children,
    confirmText,
    cancelText,
    loading,
    onConfirm,
    onCancel,
    onClose,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <AlertDialog
            actions={
                <>
                    <Button
                        autoFocus
                        color={color || 'primary'}
                        loading={loading}
                        variant="contained"
                        onClick={onConfirm}
                    >
                        {confirmText || translate('ok')}
                    </Button>
                    {onCancel && (
                        <Button
                            disabled={loading}
                            variant="contained"
                            onClick={onCancel}
                        >
                            {cancelText || translate('cancel')}
                        </Button>
                    )}
                </>
            }
            open={open}
            title={title}
            onClose={onClose}
            {...other}
        >
            {/* When children is a sring we want to render it using DialogContextText */}
            {typeof children === 'string' ? (
                <DialogContentText
                    color="text.primary"
                    sx={{ whiteSpace: 'pre-line' }}
                    variant="body2"
                >
                    {children}
                </DialogContentText>
            ) : (
                children
            )}
        </AlertDialog>
    );
};
