import { Box } from '@mui/material';
import { mdiMinus, mdiPlus } from '@mdi/js';
import { styled } from 'styled-components';

import { Chip, ChipGroup } from 'common/components/Chip';
import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
} from 'common/components/ListItem';
import { IUserListItemFragment } from 'graphql/types';
import { translate } from 'utils/i18n';

import { UserAvatar } from '../UserAvatar';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    className?: string;
    selected?: boolean;
    user: IUserListItemFragment;
    onClick?(user: IUserListItemFragment): void;
}

export const BaseUserListItem = ({
    addButton,
    added,
    className,
    selected,
    user,
    onClick,
    ...other
}: IProps) => (
    <ListItem
        button
        className={className}
        px={0}
        py={1}
        selected={selected}
        onClick={() => onClick && onClick(user)}
        {...other}
    >
        <ListItemAvatar className="userAvatar">
            <UserAvatar bgColor="secondary" size={45} user={user} />

            {addButton && (
                <Box className="addRemoveIconWrapper">
                    <Icon
                        className="addRemoveIcon"
                        path={selected || added ? mdiMinus : mdiPlus}
                        size="3rem"
                    />
                </Box>
            )}
        </ListItemAvatar>

        <ListItemText primary={user.name} />

        {!user.isActive && (
            <ChipGroup>
                <Chip color="error" label={translate('inactive')} />
            </ChipGroup>
        )}
    </ListItem>
);

export const UserListItem = styled(BaseUserListItem)`
    .userAvatar {
        position: relative;
    }

    .addRemoveIconWrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.62);
        opacity: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 300ms linear;
    }

    .addRemoveIcon {
        path {
            color: ${({ theme }) => theme.palette.common.white};
        }
    }

    &:hover {
        .addRemoveIconWrapper {
            opacity: 1;
            transform: scale(1);
        }
    }
`;
