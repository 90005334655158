import { Box } from '@mui/material';
import { Form, Formik, FormikConfig, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { TextField } from 'common/components/FormField';
import { Button } from 'common/components/Button';
import {
    ICreateLibraryArticleInput,
    IUpdateLibraryArticleInput,
} from 'graphql/types';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';

type InputType = ICreateLibraryArticleInput | IUpdateLibraryArticleInput;
interface IProps extends FormikConfig<InputType> {
    isDeleting?: boolean;
    onDelete?(): void;
}

export const LibraryArticleForm = ({
    isDeleting,
    onDelete,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(translate('validate.required')),
        description: Yup.string().optional(),
    });

    return (
        <Formik validationSchema={validationSchema} {...other}>
            {({ submitForm, isSubmitting, dirty }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting && !isDeleting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        <Field
                            component={TextField}
                            label={translate('title')}
                            name="title"
                        />
                        <Field
                            component={TextField}
                            label={translate('description')}
                            name="description"
                        />
                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting || (!dirty && !isSubmitting)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>

                            {!!onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
