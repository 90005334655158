import {
    Box,
    useTheme,
    SxProps,
    CircularProgress as MuiCircularProgress,
    CircularProgressProps,
} from '@mui/material';
import { darken, getPath } from '@mui/system';

import { withSxProp } from 'common/utils/props';
import { Tooltip } from 'common/components/Tooltip';

interface IProps {
    filled?: boolean;
    bgColor?: string;
    hover?: boolean;
    showDot?: boolean;
    showTooltip?: boolean;
}

export const CircularProgress = ({
    filled,
    thickness,
    size,
    bgColor,
    hover,
    showDot,
    showTooltip,
    value,
    sx,
    ...other
}: IProps & CircularProgressProps) => {
    const theme = useTheme();

    // If progress is filled we need to set the thickness
    const progressThickness = filled ? 22 : thickness || 3.6;
    const progressSize: number = size ? parseFloat(`${size}`) : 40;

    let progressStyle: SxProps = { borderRadius: '50%' };
    let themeBgColor: string | undefined;
    let borderColor: string = 'grey.400';

    if (bgColor) {
        const bgShadowSize = (progressThickness / 44) * progressSize;
        themeBgColor = getPath(theme.palette, bgColor) as string;

        progressStyle = {
            ...progressStyle,
            boxShadow: `inset 0 0 0 ${bgShadowSize}px ${themeBgColor}`,
        };
    }

    // When we have a bgColor we can calculate border color
    if (themeBgColor) {
        borderColor = darken(themeBgColor, 0.2);
    }

    if (hover) {
        progressStyle = {
            ...progressStyle,
            border: `2px solid transparent`,
            background: borderColor,
            '&:hover': { borderColor: 'rgba(0, 0, 0, 0.2)' },
        };
    }

    const content = (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                height: progressSize,
                lineHeight: 1,
                verticalAlign: 'middle',
            }}
        >
            <MuiCircularProgress
                size={progressSize}
                sx={[
                    progressStyle,
                    // Spread sx from props
                    ...withSxProp(sx),
                ]}
                thickness={progressThickness}
                value={value}
                {...other}
            />

            {showDot && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        pointerEvents: 'none',
                        borderRadius: '50%',
                        position: 'absolute',
                        width: 7,
                        height: 7,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
        </Box>
    );

    if (value && showTooltip) {
        return <Tooltip title={`${value}%`}>{content}</Tooltip>;
    }

    return content;
};
