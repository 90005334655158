export type TQueryParams = { [key: string]: string | string[] };

// Returns a string of the query params for use in the url
function getQueryParamString(queryParams?: TQueryParams) {
    if (!queryParams) return '';

    let queryParamsString = '';

    Object.keys(queryParams).forEach((queryParamKey) => {
        if (queryParamKey === 'q') return;

        queryParamsString += `&${queryParamKey}=${queryParams[queryParamKey]}`;
    });

    return queryParamsString;
}

export function getSearchUrlQuery(query: string, queryParams?: TQueryParams) {
    const queryParam = query.trim().length ? `q=${query}` : '';

    const queryParamsString = getQueryParamString(queryParams);

    return `${queryParam}${queryParamsString}`;
}
