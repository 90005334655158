import { styled } from 'styled-components';

export const InboxGroup = styled.div`
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;

    &:empty {
        display: none;
    }
`;
