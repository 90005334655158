import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DialogContentText } from '@mui/material';
import { useState } from 'react';

import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageDrawer } from 'common/components/PageDrawer';
import {
    IContentTypeValue,
    IOfferDetailQueryFragment,
    useDeleteOfferMutation,
    useUpdateOfferMutation,
} from 'graphql/types';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { ApolloError } from 'common/components/ApolloError';
import {
    IDeleteAlertDialogOptions,
    INITIAL_DELETE_ALERT_DIALOG_VALUES,
} from 'common/constants/deleteAlertDialog';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { PageTitle } from 'common/components/PageTitle';
import { MIXPANEL_EVENT_NAMES } from 'common/constants/mixpanel';
import { getImageId } from 'common/utils/image';
import { getUrl } from 'route/utils/getUrl';
import { getExtraCategoryInitialFormValues } from 'organisation/utils/extraCategory';
import { useApolloError } from 'common/hooks/useApolloError';
import { Loader } from 'common/components/Loader';
import { TUpdateOfferFormInput } from 'offer/types';
import {
    getGroupConditionMutationInputs,
    getGroupConditionsFilterInputs,
} from 'user/utils/groups';
import { IGroupConditionInput } from 'user/types';
import { AuthorButton } from 'user/components/AuthorButton';
import { UpdateOfferForm } from 'offer/components/forms/UpdateOfferForm';
import { useExtraCategoryValues } from 'common/hooks/useExtraCategoryValues';

interface IProps extends IPageDrawerProps {
    offer: IOfferDetailQueryFragment;
}

export const UpdateOfferDrawer = ({ offer, open }: IProps) => {
    const navigate = useNavigate();
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [deleteAlertDialog, setDeleteAlertDialog] =
        useState<IDeleteAlertDialogOptions>(INITIAL_DELETE_ALERT_DIALOG_VALUES);
    const [
        updateOffer,
        { loading: updateOfferLoading, error: updateOfferError },
    ] = useUpdateOfferMutation();
    const [deleteOffer, { error: deleteOfferError }] = useDeleteOfferMutation();
    const {
        extraCategories,
        loading: extraCategoriesLoading,
        handleUpdateExtraCategoryValues,
    } = useExtraCategoryValues({
        contentType: IContentTypeValue.Offer,
        modelId: offer.id,
        typeNames: ['Offer'],
    });

    const {
        title,
        titleColor,
        image,
        overviewImage,
        extraCategoryValues,
        groupConditions,
        type,
    } = offer;

    const initialExtraCategoryValues = getExtraCategoryInitialFormValues(
        extraCategories,
        extraCategoryValues
    );

    const initialValues: TUpdateOfferFormInput = {
        title: title || '',
        titleColor: titleColor?.replace('#', '') || '',
        image: image || undefined,
        overviewImage: overviewImage || undefined,
        extraCategories: initialExtraCategoryValues,
        groupConditions: getGroupConditionsFilterInputs(groupConditions || []),
        type: type,
    };

    const handleClose = (deleteOffer?: boolean) => {
        const url = deleteOffer
            ? getUrl('OFFER_LIST')
            : getUrl('OFFER_DETAIL', {
                  id: offer.id,
              });

        navigate(url);
    };

    const handleSubmit = async (values: TUpdateOfferFormInput) => {
        const {
            titleColor,
            image,
            overviewImage,
            extraCategories: extraCategoryUpdateValues,
            groupConditions,
            ...otherValues
        } = values;
        const { image: currentImage, overviewImage: currentOverviewImage } =
            offer || {};

        const newValues = {
            titleColor: titleColor
                ? titleColor.includes('#')
                    ? titleColor
                    : `#${titleColor}`
                : undefined,
            imageId: getImageId(currentImage, image),
            overviewImageId: getImageId(currentOverviewImage, overviewImage),
            groupConditions: getGroupConditionMutationInputs(
                (groupConditions as IGroupConditionInput[]) || undefined
            ),

            ...otherValues,
        };

        try {
            await handleUpdateExtraCategoryValues(
                extraCategoryValues,
                extraCategoryUpdateValues
            );

            await updateOffer({
                variables: {
                    id: offer.id,
                    offer: newValues,
                },
                update: (cache) => {
                    cache.evict({ fieldName: 'offers' });
                    cache.evict({ fieldName: 'paginatedOfferEvents' });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('offerActionSuccess.update'), {
            variant: 'success',
        });

        handleClose();
    };

    const handleDelete = () => {
        deleteOffer({
            variables: { id: offer.id },
            update: (cache) => {
                // Remove item from cache
                cache.evict({ id: `Offer:${offer.id}` });
                cache.gc();

                displaySnackbar(translate('offerActionSuccess.delete'), {
                    variant: 'success',
                });

                handleClose(true);
            },
        });

        setDeleteAlertDialog({ ...deleteAlertDialog, show: false });
    };

    const loading = extraCategoriesLoading || updateOfferLoading;

    const drawerActions = loading ? undefined : (
        <AuthorButton
            contentType={IContentTypeValue.Offer}
            objectId={offer.id}
        />
    );

    return (
        <PageDrawer
            actions={drawerActions}
            open={open}
            onClose={() => handleClose()}
        >
            {updateOfferError && <ApolloError error={updateOfferError} />}
            {deleteOfferError && <ApolloError error={deleteOfferError} />}

            <PageTitle mixpanelTitle={MIXPANEL_EVENT_NAMES.offers.offer.update}>
                {`${translate('offers')} - ${translate(
                    'offer.editOffersSettings'
                )}`}
            </PageTitle>

            {loading && <Loader />}

            {!loading && (
                <UpdateOfferForm
                    initialValues={initialValues}
                    offer={offer}
                    onDelete={() => {
                        setDeleteAlertDialog({
                            show: true,
                            title: translate('deleteOfferMessage.title'),
                            text: translate('deleteOfferMessage.text'),
                        });
                    }}
                    onSubmit={handleSubmit}
                />
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={deleteAlertDialog.callback || handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setDeleteAlertDialog({
                                    ...deleteAlertDialog,
                                    show: false,
                                });
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={deleteAlertDialog.show}
                title={deleteAlertDialog.title}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {deleteAlertDialog.text}
                </DialogContentText>
            </AlertDialog>
        </PageDrawer>
    );
};
