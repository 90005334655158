import { forwardRef } from 'react';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

import { Icon, IconProps } from 'common/components/Icon';

export interface IProps {
    iconPath: IconProps['path'];
    iconSize?: IconProps['size'];
    iconSizeSm?: IconProps['sizeSm'];
}

export function BaseIconButton<C extends React.ElementType>(
    {
        iconPath,
        iconSize,
        iconSizeSm,
        ...other
    }: IconButtonProps<C, { component?: C } & IProps>,
    ref: React.Ref<HTMLButtonElement>
) {
    return (
        <MuiIconButton color="inherit" ref={ref} {...other}>
            <Icon path={iconPath} size={iconSize} sizeSm={iconSizeSm} />
        </MuiIconButton>
    );
}

export const IconButton = forwardRef(BaseIconButton);
