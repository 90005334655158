import { useState } from 'react';

import {
    ITrainingPortfolioItemFragment,
    useTrainingPortfolioItemSubscription,
} from 'graphql/types';

interface IProps {
    children(data: ITrainingPortfolioItemFragment): React.ReactNode;
    trainingId: string;
    initialData?: ITrainingPortfolioItemFragment | null;
}

export const TrainingPortfolioItemSubscriptionData = ({
    children,
    trainingId,
    initialData,
}: IProps) => {
    const [portfolioItem, setPortfolioItem] = useState(initialData);

    useTrainingPortfolioItemSubscription({
        variables: { trainingId },
        onData: ({ data: { data } }) => {
            const portfolioItem = data?.trainingPortfolioItem || null;

            setPortfolioItem(portfolioItem);
        },
    });

    if (!portfolioItem) return null;

    return <>{children(portfolioItem)}</>;
};
