import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Box, Table, TableContainer, TableRow, TableBody } from '@mui/material';

import { EnrollmentsManagementTableRow } from 'offer/components/EnrollmentsManagementTable';
import { FilterBar } from 'common/components/FilterBar';
import {
    useEnrollmentsManagementList,
    useEnrollmentsManagementMutations,
} from 'offer/hooks/enrollment';
import { TFilterBarItem } from 'common/types';
import {
    IOfferEventEnrollmentSortField,
    IOfferEventSubscriptionStatus,
} from 'graphql/types';
import {
    TableActionBar,
    TableHead,
    TableCell,
    TableSortButton,
    useSelection,
} from 'common/components/Table';
import { Checkbox } from 'common/components/Checkbox';
import { Loader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';
import { Pagination } from 'common/components/Pagination';
import {
    EnrollmentStatusDialog,
    TEnrollmentStatusDialogSettings,
} from 'offer/components/EnrollmentStatusDialog';
import {
    EnrollmentDeleteDialog,
    TEnrollmentDeleteDialogSettings,
} from 'offer/components/EnrollmentDeleteDialog';
import { EnrollmentTableHeadActions } from 'offer/components/EnrollmentTableHeadActions';
import { EnrollmentBulkMailDialog } from 'offer/components/EnrollmentBulkMailDialog';
import { TEnrollmentBulkMailDialogSettings } from 'offer/components/EnrollmentBulkMailDialog/EnrollmentBulkMailDialog';

interface IProps {
    enrollmentStatus: IOfferEventSubscriptionStatus;
}

export const EnrollmentsManagementTable = ({ enrollmentStatus }: IProps) => {
    const [translate] = useTranslation();
    const [enrollmentStatusDialogSettings, setEnrollmentStatusDialogSettings] =
        useState<TEnrollmentStatusDialogSettings>({
            open: false,
            selectedAmount: 0,
        });
    const [enrollmentDeleteDialogSettings, setEnrollmentDeleteDialogSettings] =
        useState<TEnrollmentDeleteDialogSettings>({
            open: false,
            selectedAmount: 0,
        });
    const [bulkMailDialogSettings, setBulkEmailDialogSettings] =
        useState<TEnrollmentBulkMailDialogSettings>({
            open: false,
            selectedAmount: 0,
        });

    const {
        enrollments,
        loading: loadingEnrollments,
        currentSort,
        filters,
        paginationSettings,
        searchQueryParam,
        selectedFilters,
        onSearch,
        onFilterSelect,
        onSort,
        setPage,
    } = useEnrollmentsManagementList(enrollmentStatus);

    const { ...selectionProps } = useSelection(enrollments);
    const { rows, selected, onSelectAll, isSelected } = selectionProps;

    const {
        onUpdateEnrollments,
        onDeleteEnrollments,
        loading: enrollmentMutationsLoading,
    } = useEnrollmentsManagementMutations();

    const deselectAllEnrollments = () => {
        if (!!selected?.length) onSelectAll?.();
    };

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);

        deselectAllEnrollments();
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        setPage(1);
        onFilterSelect?.(selectedFilters);

        deselectAllEnrollments();
    };

    const handleEnrollmentStatusDialog = (
        open: boolean,
        selectedAmount: number,
        status: IOfferEventSubscriptionStatus,
        enrollmentId?: string
    ) => {
        setEnrollmentStatusDialogSettings({
            open,
            status,
            enrollmentId,
            selectedAmount,
        });
    };

    useEffect(() => {
        deselectAllEnrollments();
        handleSearch('');
        onFilterSelect?.([]);
    }, [enrollmentStatus]);

    const selectedAmount = selected?.length || 0;

    const isEnrolledStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Enrolled;
    const isDeniedStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Denied;
    const isAbsentStatus =
        enrollmentStatus === IOfferEventSubscriptionStatus.Absent;

    const tableActions = (
        <EnrollmentTableHeadActions
            isWebinarOrMeeting
            hide={!selectedAmount}
            onClickAbsent={
                !isAbsentStatus
                    ? () => {
                          handleEnrollmentStatusDialog(
                              true,
                              selectedAmount,
                              IOfferEventSubscriptionStatus.Absent
                          );
                      }
                    : undefined
            }
            onClickComplete={() => {
                handleEnrollmentStatusDialog(
                    true,
                    selectedAmount,
                    IOfferEventSubscriptionStatus.Completed
                );
            }}
            onClickDelete={() => {
                setEnrollmentDeleteDialogSettings({
                    open: true,
                    selectedAmount,
                });
            }}
            onClickEnroll={
                !isEnrolledStatus
                    ? () => {
                          handleEnrollmentStatusDialog(
                              true,
                              selectedAmount,
                              IOfferEventSubscriptionStatus.Enrolled
                          );
                      }
                    : undefined
            }
            onClickMail={() => {
                setBulkEmailDialogSettings({
                    ...enrollmentDeleteDialogSettings,
                    enrollmentId: undefined,
                    open: true,
                    selectedAmount,
                });
            }}
            onClickReject={
                !isDeniedStatus
                    ? () => {
                          handleEnrollmentStatusDialog(
                              true,
                              selectedAmount,
                              IOfferEventSubscriptionStatus.Denied
                          );
                      }
                    : undefined
            }
        />
    );

    return (
        <>
            <FilterBar
                disabled={loadingEnrollments}
                filters={filters}
                initialSearchValue={searchQueryParam}
                initialSelected={selectedFilters}
                placeholder={translate('filterBarPlaceholder')}
                onSearch={handleSearch}
                onSearchClear={() => handleSearch('')}
                onSelect={handleFilterSelect}
            />

            {!loadingEnrollments && !!rows.length && (
                <TableContainer>
                    <TableActionBar
                        actions={tableActions}
                        selectionProps={selectionProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={false}
                                        disabled={isSelected}
                                        onChange={onSelectAll}
                                    />
                                </TableCell>
                                <TableCell>
                                    {translate(
                                        'enrollmentsManagementTable.name'
                                    )}
                                </TableCell>
                                <TableCell>
                                    {translate(
                                        'enrollmentsManagementTable.meeting'
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex' }}>
                                        {translate(
                                            'enrollmentsManagementTable.lastUpdated'
                                        )}

                                        <TableSortButton
                                            currentSortDirection={
                                                currentSort.direction
                                            }
                                            currentSortField={
                                                currentSort.field as string
                                            }
                                            sortField={
                                                IOfferEventEnrollmentSortField.StatusUpdatedOn
                                            }
                                            onClick={() =>
                                                onSort(
                                                    IOfferEventEnrollmentSortField.StatusUpdatedOn
                                                )
                                            }
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map(({ index, onSelect, isSelected }) => {
                                const enrollment = enrollments[index] || null;

                                if (!enrollment) return null;

                                return (
                                    <EnrollmentsManagementTableRow
                                        enrollment={enrollment}
                                        enrollmentStatus={enrollmentStatus}
                                        isSelected={isSelected}
                                        key={enrollment.id}
                                        onClickAbsent={() => {
                                            handleEnrollmentStatusDialog(
                                                true,
                                                1,
                                                IOfferEventSubscriptionStatus.Absent,
                                                enrollment.id
                                            );
                                        }}
                                        onClickComplete={() => {
                                            handleEnrollmentStatusDialog(
                                                true,
                                                1,
                                                IOfferEventSubscriptionStatus.Completed,
                                                enrollment.id
                                            );
                                        }}
                                        onClickDelete={() => {
                                            setEnrollmentDeleteDialogSettings({
                                                open: true,
                                                selectedAmount: 1,
                                                enrollmentId: enrollment.id,
                                            });
                                        }}
                                        onClickEnroll={() => {
                                            handleEnrollmentStatusDialog(
                                                true,
                                                1,
                                                IOfferEventSubscriptionStatus.Enrolled,
                                                enrollment.id
                                            );
                                        }}
                                        onClickMail={() => {
                                            setBulkEmailDialogSettings({
                                                open: true,
                                                selectedAmount: 1,
                                                enrollmentId: enrollment.id,
                                            });
                                        }}
                                        onClickReject={() => {
                                            handleEnrollmentStatusDialog(
                                                true,
                                                1,
                                                IOfferEventSubscriptionStatus.Denied,
                                                enrollment.id
                                            );
                                        }}
                                        onSelect={onSelect}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {loadingEnrollments && (
                <Box sx={{ position: 'relative', height: '100px', mt: 4 }}>
                    <Loader />
                </Box>
            )}

            {!loadingEnrollments && !enrollments.length && (
                <Box sx={{ mt: 8, textAlign: 'center' }}>
                    <Typography>
                        {translate('noOptionsText.enrollments')}
                    </Typography>
                </Box>
            )}

            {!loadingEnrollments && paginationSettings.count !== -1 && (
                <Box sx={{ mt: 4 }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate('subscription', {
                            count: paginationSettings.count,
                        })}
                        onChange={(page: number) => {
                            setPage(page);
                        }}
                    />
                </Box>
            )}

            <EnrollmentStatusDialog
                dialogSettings={enrollmentStatusDialogSettings}
                loading={enrollmentMutationsLoading}
                showCertificateField={
                    enrollmentStatusDialogSettings.status ===
                    IOfferEventSubscriptionStatus.Completed
                }
                onClose={() =>
                    setEnrollmentStatusDialogSettings({
                        ...enrollmentStatusDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    })
                }
                onConfirm={async (reason, generateCertificate) => {
                    if (!enrollmentStatusDialogSettings.status) return;

                    const { enrollmentId } = enrollmentStatusDialogSettings;

                    const enrollmentIds = enrollmentId
                        ? [enrollmentId]
                        : selected;

                    await onUpdateEnrollments(
                        enrollmentIds,
                        enrollmentStatusDialogSettings.status,
                        reason,
                        generateCertificate
                    );

                    setEnrollmentStatusDialogSettings({
                        ...enrollmentStatusDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    });

                    deselectAllEnrollments();
                }}
            />

            <EnrollmentDeleteDialog
                dialogSettings={enrollmentDeleteDialogSettings}
                loading={enrollmentMutationsLoading}
                onClose={() =>
                    setEnrollmentDeleteDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        open: false,
                    })
                }
                onConfirm={async () => {
                    const { enrollmentId } = enrollmentDeleteDialogSettings;

                    const userIds = enrollmentId ? [enrollmentId] : selected;

                    await onDeleteEnrollments(userIds);

                    setEnrollmentDeleteDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    });

                    deselectAllEnrollments();
                }}
            />

            <EnrollmentBulkMailDialog
                dialogSettings={bulkMailDialogSettings}
                enrollmentIds={
                    bulkMailDialogSettings.enrollmentId
                        ? [bulkMailDialogSettings.enrollmentId]
                        : selected
                }
                onClose={() => {
                    setBulkEmailDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    });
                }}
                onSendBulkEmailComplete={() => {
                    deselectAllEnrollments();

                    setBulkEmailDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    });
                }}
            />
        </>
    );
};
