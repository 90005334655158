import {
    ListItemText as MuiListItemText,
    ListItemTextProps,
    TypographyProps,
    Box,
} from '@mui/material';
import { styled } from 'styled-components';

import { useHasContent } from 'common/hooks/useHasContent';
import { Typography } from 'common/components/Typography';

interface IProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    secondaryTypographyProps?: TypographyProps<any>;
    className?: string;
    children?: React.ReactNode;
    heading?: React.ReactNode;
    showFullSecondary?: boolean;
    // Wrap title over multiple lines
    wrapTitle?: boolean;
}

const ItemText = ({
    className,
    children,
    heading,
    primary,
    secondary,
    showFullSecondary,
    wrapTitle,
    ...other
}: ListItemTextProps & IProps) => {
    const { hasContent, contentRef } = useHasContent(children);

    return (
        <div className={className}>
            <MuiListItemText disableTypography {...other}>
                {heading && (
                    <Typography
                        sx={{
                            color: 'primary.main',
                            fontSize: '1.4rem',
                            fontWeight: 700,
                            lineHeight: '1.2',
                        }}
                    >
                        {heading}
                    </Typography>
                )}

                {primary && (
                    <Typography
                        lineHeight={1.1}
                        noWrap={!wrapTitle}
                        variant="h4"
                    >
                        {primary}
                    </Typography>
                )}
                {secondary && (
                    <Typography color="text.secondary" variant="body2">
                        {secondary}
                    </Typography>
                )}

                {children && (
                    <Box
                        mt={(primary || secondary) && hasContent ? 1 : 0}
                        ref={contentRef}
                    >
                        {children}
                    </Box>
                )}
            </MuiListItemText>
        </div>
    );
};
export const ListItemText = styled(ItemText)`
    overflow: hidden;
    flex: 1;

    ${({ showFullSecondary }) =>
        !showFullSecondary &&
        `p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }`}

    ${({ theme }) => theme.breakpoints.up('sm')} {
        > :not(:last-child) {
            margin-right: ${({ theme }) => theme.spacing(3)};
        }
    }

    ${({ theme, showFullSecondary }) => {
        if (showFullSecondary) return;

        return `
            ${theme.breakpoints.up('sm')} {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    white-space: normal;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        `;
    }}
`;
