import { Table, TableContainer, TableBody } from '@mui/material';

import { ITrainingListItemFragment } from 'graphql/types';
import { TrainingManagementTableRow } from 'training/components/TrainingManagementTableRow';

interface IProps {
    trainings: ITrainingListItemFragment[];
}

export const TrainingManagementTable = ({ trainings }: IProps) => {
    if (!trainings.length) return null;

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {trainings?.map((training) => (
                        <TrainingManagementTableRow
                            key={training.id}
                            training={training}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
