import { useMemo, useRef } from 'react';

import { IUserSelectorQueryVariables } from 'graphql/types';
import { TFilterBarItem } from 'common/types';
import { getExtraFieldsJSON } from 'common/utils/extraFields';

interface IProps {
    paginationVariables?: {
        offset: number;
        first: number;
    };
    searchQueryParam?: string;
    selectedFilters: TFilterBarItem[];
}

export const useUserQueryVariables = ({
    paginationVariables,
    searchQueryParam,
    selectedFilters,
}: IProps) => {
    const prevQueryVariablesRef = useRef<
        Omit<IUserSelectorQueryVariables, 'selector'> | undefined
    >();

    const queryVariables = useMemo(() => {
        const { current } = prevQueryVariablesRef;

        const newQueryVariables = {
            ...paginationVariables,
            isActive: true,
            archived: false,
            q: searchQueryParam,
            extraFields: getExtraFieldsJSON(selectedFilters),
        };

        const { q, offset, first, extraFields, isActive, archived } =
            current || {};

        if (
            isActive === newQueryVariables.isActive &&
            archived === newQueryVariables.archived &&
            q === newQueryVariables.q &&
            offset === newQueryVariables.offset &&
            first === newQueryVariables.first &&
            extraFields === newQueryVariables.extraFields
        ) {
            return current;
        }

        prevQueryVariablesRef.current = newQueryVariables;

        return {
            ...paginationVariables,
            ...newQueryVariables,
            q: searchQueryParam,
            offset: q !== newQueryVariables.q ? 0 : newQueryVariables.offset,
        };
    }, [paginationVariables, searchQueryParam, selectedFilters]);

    return { userQueryVariables: queryVariables };
};
