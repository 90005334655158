import {
    Box,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { TableActionBar, TableCell, TableHead } from 'common/components/Table';
import {
    IOfferEventSubscriptionStatus,
    IOfferEventDetailFragment,
    IOfferEventType,
} from 'graphql/types';
import { FilterBar } from 'common/components/FilterBar';
import { Checkbox } from 'common/components/Checkbox';
import { Loader } from 'common/components/Loader';
import {
    IPaginationProps,
    ISearchProps,
    ITableSelectionProps,
    TFilterBarItem,
} from 'common/types';
import { Pagination } from 'common/components/Pagination';
import { EnrollmentStatusDialog } from 'offer/components/EnrollmentStatusDialog';
import { TEnrollmentStatusDialogSettings } from 'offer/components/EnrollmentStatusDialog/EnrollmentStatusDialog';
import {
    EnrollmentDeleteDialog,
    TEnrollmentDeleteDialogSettings,
} from 'offer/components/EnrollmentDeleteDialog';
import {
    EnrollmentBulkMailDialog,
    TEnrollmentBulkMailDialogSettings,
} from 'offer/components/EnrollmentBulkMailDialog';
import { EnrollmentTableHeadActions } from 'offer/components/EnrollmentTableHeadActions';
import { TOfferEventDetailEnrollment } from 'offer/types';

import { SubscriptionRow } from './SubscriptionRow';

interface IProps {
    className?: string;
    disabled?: boolean;
    isEditable?: boolean;
    loading?: boolean;
    paginationProps?: IPaginationProps;
    searchProps?: ISearchProps;
    selectionProps?: ITableSelectionProps<TOfferEventDetailEnrollment>;
    subscriptions: TOfferEventDetailEnrollment[];
    offerEvent: IOfferEventDetailFragment;
    onDelete?(enrollmentIds: string[]): void;
    onStatusChange?(
        enrollmentIds: string[],
        status: IOfferEventSubscriptionStatus,
        reason: string,
        generateCertificate: boolean
    ): void;
}

export const SubscriptionTable = ({
    className,
    disabled,
    isEditable,
    loading,
    paginationProps,
    searchProps,
    selectionProps,
    subscriptions,
    offerEvent,
    onDelete,
    onStatusChange,
}: IProps) => {
    const [translate] = useTranslation();
    const [subscriptionDialogSettings, setSubscriptionDialogSettings] =
        useState<TEnrollmentStatusDialogSettings>({
            open: false,
            selectedAmount: 0,
        });
    const [enrollmentDeleteDialogSettings, setEnrollmentDeleteDialogSettings] =
        useState<TEnrollmentDeleteDialogSettings>({
            open: false,
            selectedAmount: 0,
        });
    const [bulkMailDialogSettings, setBulkEmailDialogSettings] =
        useState<TEnrollmentBulkMailDialogSettings>({
            open: false,
            selectedAmount: 0,
        });
    const [dialogLoading, setDialogloading] = useState(false);

    const { certificate, scoring } = offerEvent;

    const isWebinarOrMeeting = [
        IOfferEventType.Webinar,
        IOfferEventType.Meeting,
    ].includes(offerEvent.type);

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        handleSearch: onSearch = () => {},
        handleFilterSelect: onFilterSelect,
    } = searchProps || {};
    const { paginationSettings, setPage } = paginationProps || {};

    const {
        rows = [],
        selected,
        selectableItems = [],
        onSelectAll,
        isSelected,
    } = selectionProps || {};

    const handleSubscribeStatusDialog = (
        open: boolean,
        selectedAmount: number,
        status: IOfferEventSubscriptionStatus,
        enrollmentId?: string
    ) => {
        setSubscriptionDialogSettings({
            open,
            status,
            enrollmentId,
            selectedAmount,
        });
    };

    const handleEnrollmentDeleteDialog = (
        open: boolean,
        selectedAmount: number,
        enrollmentId?: string
    ) => {
        setEnrollmentDeleteDialogSettings({
            open,
            selectedAmount,
            enrollmentId,
        });
    };

    const deselectAllEnrollments = () => {
        if (!!selected?.length) onSelectAll?.();
    };

    const handleSubscribeStatusChange = async (
        reason: string,
        generateCertificate?: boolean
    ) => {
        const { status, enrollmentId } = subscriptionDialogSettings;

        if (!status) return;

        const enrollmentIds = enrollmentId ? [enrollmentId] : selected || [];
        setDialogloading(true);

        await onStatusChange?.(
            enrollmentIds,
            status,
            reason,
            !!generateCertificate
        );

        setDialogloading(false);

        setSubscriptionDialogSettings({
            ...subscriptionDialogSettings,
            open: false,
        });

        deselectAllEnrollments();
    };

    const handleSubscriptionDelete = async () => {
        const { enrollmentId } = enrollmentDeleteDialogSettings;

        setDialogloading(true);

        const enrollmentIds = enrollmentId ? [enrollmentId] : selected || [];

        await onDelete?.(enrollmentIds);

        setDialogloading(false);

        setEnrollmentDeleteDialogSettings({
            ...enrollmentDeleteDialogSettings,
            open: false,
        });

        deselectAllEnrollments();
    };

    const handleSearch = (searchValue: string) => {
        onSearch?.(searchValue);

        deselectAllEnrollments();
    };

    const handleFilterSelect = (selectedFilters: TFilterBarItem[]) => {
        onFilterSelect?.(selectedFilters);

        deselectAllEnrollments();
    };

    const selectedAmount = selected?.length || 0;

    const actions = isEditable ? (
        <EnrollmentTableHeadActions
            hide={!selectedAmount}
            isWebinarOrMeeting={isWebinarOrMeeting}
            onClickAbsent={() => {
                handleSubscribeStatusDialog(
                    true,
                    selectedAmount,
                    IOfferEventSubscriptionStatus.Absent
                );
            }}
            onClickComplete={() => {
                handleSubscribeStatusDialog(
                    true,
                    selectedAmount,
                    IOfferEventSubscriptionStatus.Completed
                );
            }}
            onClickDelete={() => {
                setEnrollmentDeleteDialogSettings({
                    open: true,
                    selectedAmount,
                });
            }}
            onClickEnroll={() => {
                handleSubscribeStatusDialog(
                    true,
                    selectedAmount,
                    IOfferEventSubscriptionStatus.Enrolled
                );
            }}
            onClickMail={() => {
                setBulkEmailDialogSettings({
                    ...enrollmentDeleteDialogSettings,
                    enrollmentId: undefined,
                    open: true,
                    selectedAmount,
                });
            }}
            onClickReject={() => {
                handleSubscribeStatusDialog(
                    true,
                    selectedAmount,
                    IOfferEventSubscriptionStatus.Denied
                );
            }}
        />
    ) : (
        []
    );

    const showTable = !!(!loading && subscriptions.length);

    return (
        <Box className={className}>
            {!!searchProps && (
                <FilterBar
                    disabled={disabled}
                    filters={filters}
                    initialSearchValue={searchQueryParam}
                    initialSelected={selectedFilters}
                    placeholder={translate('filterBarPlaceholder')}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch?.('')}
                    onSelect={handleFilterSelect}
                />
            )}

            {loading && (
                <Box sx={{ position: 'relative', height: '100px', mt: 4 }}>
                    <Loader />
                </Box>
            )}

            {!!(!loading && !subscriptions.length) && (
                <Box mt={7} textAlign="center">
                    <Typography>{translate('noSubscriptions')}</Typography>
                </Box>
            )}

            {showTable && (
                <TableContainer>
                    {isEditable && (
                        <TableActionBar
                            inDrawer
                            actions={actions}
                            disabled={
                                !selectableItems.length || loading || disabled
                            }
                            selectionProps={selectionProps}
                        />
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {isEditable && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={false}
                                            disabled={isSelected}
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                                <TableCell>{translate('name')}</TableCell>
                                {isEditable && <TableCell />}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map(({ index, isSelected, onSelect }) => {
                                const subscription =
                                    subscriptions[index] || undefined;

                                if (!subscription) return null;

                                return (
                                    <SubscriptionRow
                                        isEditable={isEditable}
                                        isSelectable={isEditable}
                                        isSelected={isSelected}
                                        key={`subscription-${
                                            subscription.id || index
                                        }`}
                                        offerEventType={offerEvent.type}
                                        subscription={subscription}
                                        onMailDialog={() => {
                                            setBulkEmailDialogSettings({
                                                open: true,
                                                selectedAmount: 1,
                                                enrollmentId: subscription.id,
                                            });
                                        }}
                                        onSelect={onSelect}
                                        onSubscribeDeleteDialog={
                                            handleEnrollmentDeleteDialog
                                        }
                                        onSubscribeStatusDialog={
                                            handleSubscribeStatusDialog
                                        }
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {!loading && !!paginationSettings && (
                <Box sx={{ mt: { xs: 2, sm: 3 } }}>
                    <Pagination
                        page={paginationSettings.page}
                        pageAmount={paginationSettings.pageAmount}
                        totalsAmount={paginationSettings.count}
                        totalsText={translate('subscription', {
                            count: paginationSettings.count,
                        })}
                        onChange={(page: number) => {
                            setPage?.(page);
                        }}
                    />
                </Box>
            )}

            {!!onStatusChange && (
                <EnrollmentStatusDialog
                    certificate={certificate || undefined}
                    dialogSettings={subscriptionDialogSettings}
                    loading={dialogLoading}
                    scoring={scoring || undefined}
                    onClose={() =>
                        setSubscriptionDialogSettings({
                            ...subscriptionDialogSettings,
                            enrollmentId: undefined,
                            open: false,
                        })
                    }
                    onConfirm={handleSubscribeStatusChange}
                />
            )}

            <EnrollmentDeleteDialog
                dialogSettings={enrollmentDeleteDialogSettings}
                loading={dialogLoading}
                onClose={() =>
                    setEnrollmentDeleteDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    })
                }
                onConfirm={handleSubscriptionDelete}
            />

            <EnrollmentBulkMailDialog
                dialogSettings={bulkMailDialogSettings}
                enrollmentIds={
                    bulkMailDialogSettings.enrollmentId
                        ? [bulkMailDialogSettings.enrollmentId]
                        : selected
                }
                onClose={() => {
                    setBulkEmailDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    });
                }}
                onSendBulkEmailComplete={() => {
                    deselectAllEnrollments();

                    setBulkEmailDialogSettings({
                        ...enrollmentDeleteDialogSettings,
                        enrollmentId: undefined,
                        open: false,
                    });
                }}
            />
        </Box>
    );
};
