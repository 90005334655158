import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { PageDrawer } from 'common/components/PageDrawer';
import {
    IFileFragment,
    useUpdatePortfolioSettingsMutation,
    IUpdatePortfolioSettingsInput,
    PortfolioSettingsDocument,
    usePortfolioSettingsQuery,
    IFeature,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { useApolloError } from 'common/hooks/useApolloError';
import { Loader } from 'common/components/Loader';
import { PageTitle } from 'common/components/PageTitle';
import { getImageId } from 'common/utils/image';
import { useFrontendPermissions } from 'user/hooks';
import { useFeature } from 'common/hooks/useFeature';

import { PortfolioSettingsForm } from '../forms/PortfolioSettingsForm';

export const PortfolioSettingsDrawer = ({
    open,
    ...other
}: IPageDrawerProps) => {
    const navigate = useNavigate();
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [
        updatePortfolioSettings,
        { loading: portfolioSettingsUpdateLoading },
    ] = useUpdatePortfolioSettingsMutation();
    const { showApolloError } = useApolloError();
    const { canUpdate: canUpdateSettings, loading: loadingPermissions } =
        useFrontendPermissions('portfolioSettings');
    const { canUse: canUsePortfolio, loading: loadingCanUsePortfolio } =
        useFeature(IFeature.Portfolio);

    // Check if you have the correct roles and you can use the portfolio feature
    const canUpdatePortfolioSettings =
        !loadingPermissions &&
        canUpdateSettings &&
        !loadingCanUsePortfolio &&
        canUsePortfolio;

    const {
        data: portfolioSettingsData,
        loading: portfolioSettingsLoading,
        error: portfolioSettingsError,
    } = usePortfolioSettingsQuery({ skip: !canUpdatePortfolioSettings });
    const { portfolioSettings } = portfolioSettingsData || {};

    useEffect(() => {
        if (portfolioSettingsError) showApolloError(portfolioSettingsError);
    }, [portfolioSettingsError, showApolloError]);

    if (!open) {
        return (
            <PageDrawer open={open}>
                <Loader />
            </PageDrawer>
        );
    }

    if (!canUpdatePortfolioSettings) return null;

    const initialValues = {
        title: portfolioSettings?.title || '',
        titleColor:
            portfolioSettings?.titleColor?.replace('#', '') || undefined,
        description: portfolioSettings?.description || '',
        image: portfolioSettings?.image,
        published: !!portfolioSettings?.published,
    };

    // Redirect to the pathname to go to the page without hashtag.
    const handleClose = () => navigate(window.location.pathname);

    const handleSubmit = async (
        values: IUpdatePortfolioSettingsInput & { image?: IFileFragment }
    ) => {
        const { titleColor, image, ...otherValues } = values;
        const { image: currentImage } = portfolioSettings || {};

        const newValues = {
            titleColor: titleColor
                ? titleColor.includes('#')
                    ? titleColor
                    : `#${titleColor}`
                : undefined,
            imageId: getImageId(currentImage, image),
            ...otherValues,
        };

        try {
            await updatePortfolioSettings({
                variables: { settings: newValues },
                update: (cache, result) => {
                    const { settings } =
                        result.data?.updatePortfolioSettings || {};

                    if (!settings) return;

                    cache.writeQuery({
                        query: PortfolioSettingsDocument,
                        data: {
                            portfolioSettings: {
                                __typeName: 'PortfolioSettings',
                                ...settings,
                            },
                        },
                    });
                },
            });

            displaySnackbar(translate('settingsUpdated'), {
                variant: 'success',
            });

            handleClose();
        } catch (error) {
            showApolloError(error);

            return;
        }

        return;
    };

    const loading =
        loadingPermissions ||
        loadingCanUsePortfolio ||
        portfolioSettingsLoading ||
        portfolioSettingsUpdateLoading;

    return (
        <PageDrawer open onClose={handleClose} {...other}>
            {loading && <Loader />}

            {!loading && (
                <>
                    <PageTitle mixpanelTitle="Drawer portfolio settings">
                        {translate('portfolioSettingsDrawer.pageTitle')}
                    </PageTitle>

                    <PortfolioSettingsForm
                        disabled={portfolioSettingsUpdateLoading}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    />
                </>
            )}
        </PageDrawer>
    );
};
