import { useState, useEffect, useRef } from 'react';

import { IRow, ITableSelectionProps } from 'common/types';

export const useSelection = <T extends { id: string }>(
    items: T[],
    selectFilter?: (item: T) => boolean
): ITableSelectionProps<T> => {
    // A list based on item ids
    const [selected, setSelected] = useState<string[]>([]);
    const [rows, setRows] = useState<IRow[]>([]);
    const prevItems = useRef<T[]>();
    const prevSelected = useRef<string[]>([]);

    let selectableItems = items;

    // If a selectFilter is given we filter the items, so we have a list of selectable items
    if (selectFilter) {
        selectableItems = selectableItems.filter(selectFilter);
    }

    const setNewSelected = () => {
        setRows(
            items.map((item, index) => {
                const isSelected = selected.indexOf(item.id) > -1;

                return {
                    index,
                    isSelected,
                    onSelect: () => {
                        if (isSelected) {
                            return setSelected(
                                selected.filter(
                                    (selectedItemId) =>
                                        item.id !== selectedItemId
                                )
                            );
                        }

                        setSelected([...selected, item.id]);
                    },
                };
            })
        );
    };

    useEffect(() => {
        // If the items are the same as the previous items, we don't need to update the rows
        // With some implementations it could cause infinite loops when not checked
        if (
            JSON.stringify(items) === JSON.stringify(prevItems.current) &&
            JSON.stringify(selected) === JSON.stringify(prevSelected.current)
        ) {
            return;
        }

        setNewSelected();

        prevItems.current = items;
        prevSelected.current = selected;
    }, [selected, items]);

    const isSelected = selected.length > 0;
    const isSelectedAll =
        !!selectableItems.length && selected.length === selectableItems.length;

    return {
        rows,
        selected,
        isSelected,
        isSelectedAll,
        selectableItems,
        onSelectAll: () => {
            if (!isSelected) {
                return setSelected(
                    selectableItems.map((selectableItem) => selectableItem.id)
                );
            }

            setSelected([]);
        },
    };
};
