import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { useFrontendPermissions } from 'user/hooks';
import { Typography } from 'common/components/Typography';
import { List } from 'common/components/List';
import { ListItem } from 'common/components/ListItem';
import { TrainingCreateModuleSelect } from 'training/components/TrainingCreateModuleSelect';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { getUrl } from 'route/utils/getUrl';

interface IProps {
    currentUserIsAuthor?: boolean;
    trainingModelId?: string;
    trainingId: string;
}

export const TrainingNoModuleGroups = ({
    currentUserIsAuthor,
    trainingModelId,
    trainingId,
}: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const { canUpdate } = useFrontendPermissions('training');

    const canUpdateTraining = canUpdate || currentUserIsAuthor;

    const studentView = useReactiveVar(trainingStudentView);

    if (canUpdateTraining && !studentView) {
        return (
            <Box maxWidth={700} mt={6} mx="auto">
                <Box mb={6} px={4} textAlign="center">
                    <Typography gutterBottom variant="h2">
                        {translate('trainingNoModuleGroups.title')}
                    </Typography>
                    <Typography>
                        {translate('trainingNoModuleGroups.text')}
                    </Typography>
                </Box>
                <TrainingCreateModuleSelect
                    trainingModelId={trainingModelId}
                    onClick={(moduleType) => {
                        // Only link to create module drawer when moduleType has a create drawer component set
                        if (!moduleType.createDrawerComponent) return;

                        const url = getUrl('TRAINING_CREATE_MODULE_TYPE', {
                            trainingId,
                            type: moduleType.key,
                        });
                        navigate(url);
                    }}
                />
            </Box>
        );
    }

    return (
        <List>
            <ListItem>
                <Typography color="text.secondary">
                    {translate('emptyTraining')}
                </Typography>
            </ListItem>
        </List>
    );
};
