import { mdiPlus } from '@mdi/js';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { Link } from 'common/components/Link';
import { NoResults } from 'common/components/NoResults';
import { Typography } from 'common/components/Typography';
import CREATE_NEW_ITEM_IMAGE from 'assets/images/create-new-item-box-image.webp';
import { useOffersSettingsQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { Loader } from 'common/components/Loader';

interface IProps {
    canManage?: boolean;
    canUseOffers?: boolean;
}

export const HomePageNoResults = ({ canManage, canUseOffers }: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const { data: offerSettingsData, loading: offerSettingsLoading } =
        useOffersSettingsQuery({
            skip: canManage,
            onError: showApolloError,
        });

    if (offerSettingsLoading) return <Loader />;

    if (!canManage) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <NoResults variant="HOMEPAGE_RESULTS" />

                {canUseOffers && offerSettingsData?.offersSettings?.publish && (
                    <Button
                        color="primary"
                        component={Link}
                        sx={{ mt: 4 }}
                        to="OFFER_LIST"
                        variant="contained"
                    >
                        {translate('homepage.showOffersButton')}
                    </Button>
                )}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: '500px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                alt=""
                component="img"
                src={CREATE_NEW_ITEM_IMAGE}
                sx={{ width: '100%' }}
            />

            <Typography sx={{ mb: 2 }} variant="h3">
                {translate('homepage.title')}
            </Typography>

            <Typography sx={{ mb: 3, textAlign: 'center' }} variant="body1">
                {translate('homepage.description')}
            </Typography>

            <Stack direction="row" spacing={2}>
                <Button
                    color="primary"
                    component={Link}
                    startIcon={<Icon path={mdiPlus} size="1.9rem" />}
                    to="TRAINING_CREATE"
                    variant="contained"
                >
                    {translate('homepage.trainingButton')}
                </Button>

                <Button
                    color="primary"
                    component={Link}
                    startIcon={<Icon path={mdiPlus} size="1.9rem" />}
                    to="COURSE_CREATE"
                    variant="contained"
                >
                    {translate('homepage.courseButton')}
                </Button>
            </Stack>
        </Box>
    );
};
