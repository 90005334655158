import { mdiPlus } from '@mdi/js';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';

import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';

interface IProps extends BoxProps {
    disabled?: boolean;
}

export const AddButton = ({
    children,
    disabled,
    onClick,
    ...other
}: IProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            alignSelf: 'flex-start',
        }}
        onClick={(e) => !disabled && onClick?.(e)}
        {...other}
    >
        <ActionButton outlined component={Box} disabled={disabled}>
            <Icon path={mdiPlus} />
        </ActionButton>

        <Box
            ml={2}
            position="relative"
            sx={{
                ml: 2,
                position: 'relative',
                color: disabled ? 'grey.400' : 'text.primary',
            }}
        >
            {children}
        </Box>
    </Box>
);
