import {
    mdiChevronRight,
    mdiMinus,
    mdiPlus,
    mdiTransitConnectionVariant,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { ITrainingListItemFragment } from 'graphql/types';
import { Chip } from 'common/components/Chip';

interface IProps extends React.ComponentProps<typeof ListItem> {
    addButton?: boolean;
    added?: boolean;
    closed?: boolean;
    disabled?: boolean;
    selected?: boolean;
    showNotVisibleLabel?: boolean;
    training: ITrainingListItemFragment;
    loading?: boolean;
    onClick?(training: ITrainingListItemFragment): void;
}

export const TrainingListItem = ({
    addButton,
    added,
    closed,
    disabled,
    selected,
    showNotVisibleLabel = true, // Want to show this by default unless a certain condition
    training,
    onClick,
    loading,
    ...other
}: IProps) => {
    const { t: translate } = useTranslation();
    const { id, image, overviewImage, title, isVisibleForUsers } = training;

    const listItemImage = overviewImage
        ? overviewImage.url || ''
        : image?.url || '';

    let mediaIconPath = mdiTransitConnectionVariant;

    if (addButton) {
        mediaIconPath = selected || added ? mdiMinus : mdiPlus;
    }

    const showActionText = !addButton && !disabled;
    let actionText = (
        <>
            <ListItemActionText>{translate('open')}</ListItemActionText>
            <ListItemSecondaryAction>
                <Icon path={mdiChevronRight} size="3rem" />
            </ListItemSecondaryAction>
        </>
    );

    if (loading) {
        // Show progress icon when loading
        actionText = (
            <ListItemSecondaryAction>
                <CircularProgress color="primary" size={30} />
            </ListItemSecondaryAction>
        );
    }

    const hasIcon = (disabled && !listItemImage) || !disabled;

    return (
        <ListItem
            button={!disabled}
            key={id}
            px={0}
            selected={selected}
            onClick={() => !disabled && onClick?.(training)}
            {...other}
        >
            <ListItemMedia color="primary" image={listItemImage} size="small">
                {hasIcon && (
                    <Icon path={mediaIconPath} size="2rem" sizeSm="3rem" />
                )}
            </ListItemMedia>

            <ListItemText primary={title}>
                {showNotVisibleLabel && !isVisibleForUsers && !disabled && (
                    <Chip
                        bgColor="warning"
                        label={translate('notVisibleForUser')}
                    />
                )}

                {closed && <Chip bgColor="error" label={translate('closed')} />}
            </ListItemText>

            {showActionText && actionText}
        </ListItem>
    );
};
