import {
    IOverviewPageOffersQueryVariables,
    useOverviewPageOfferEventsQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export const useOfferEventOverviewList = (
    queryVariables: IOverviewPageOffersQueryVariables
) => {
    const { showApolloError } = useApolloError();
    const { loading, data } = useOverviewPageOfferEventsQuery({
        variables: queryVariables,
        notifyOnNetworkStatusChange: true,
        onError: showApolloError,
    });

    const {
        count: offerEventsCount,
        edges,
        extraCategories: extraCategoryQueryFilters,
    } = data?.paginatedOfferEvents || {};

    const offerEvents = edges?.map((edge) => edge?.node).filter(Boolean) || [];

    return {
        offerEvents,
        offerEventsCount,
        loading,
        extraCategoryQueryFilters:
            extraCategoryQueryFilters?.filter(Boolean) || [],
    };
};
