import { Box, Grid, CardActionArea } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiLock } from '@mdi/js';
import { styled } from 'styled-components';

import {
    CardContent,
    CardIconButton,
    CardIconButtonTitle,
    CardIconMedia,
} from 'common/components/Card';
import { Tooltip } from 'common/components/Tooltip';
import { Link } from 'common/components/Link';
import { Icon } from 'common/components/Icon';
import { useFeature } from 'common/hooks/useFeature';
import { ITrainingModuleType } from 'training/types';

// Component to fix position for "temporary" tooltip
const GridItemTooltip = styled(Grid)`
    position: relative;

    /*
        Position tooltip just below the title. Because the tooltip always
        wants to be inside the viewport we do a strange "hack" to keep it
        always in the correct spot by offsetting it from the center.
    */
    .MuiTooltip-popper {
        margin-top: calc(-50% + 90px);
        margin-bottom: calc(-50% - 90px);
    }
`;

interface IProps {
    type: ITrainingModuleType;
    trainingModelId?: string;
    moduleGroupId?: string;
    onClick?(): void;
}

export const CreateModuleCardButton = ({
    type,
    moduleGroupId,
    trainingModelId,
    onClick,
}: IProps) => {
    const { canUse, userCanSeeUpgrade } = useFeature(type.feature);
    const [translate] = useTranslation();

    let linkProps: React.ComponentProps<typeof Link> = {};

    // Don't render the button when the user can't use it and can't upgrade
    if (!canUse && !userCanSeeUpgrade) return null;

    if (canUse) {
        // Only set route props when it doesn't have a form in the frontend yet, so it will redirect to old management
        if (!type.createDrawerComponent) {
            linkProps = {
                to: moduleGroupId
                    ? 'MANAGEMENT_TRAINING_EDIT_MODULE_GROUP'
                    : 'MANAGEMENT_TRAINING_CREATE_MODULE_GROUP',
                params: {
                    id: trainingModelId,
                    moduleGroupId: moduleGroupId,
                },
                query: `?create_module=${type.key}&return_to_training`,
            };
        }
    } else if (!type.createDrawerComponent) {
        // When the user needs to upgrade to use this module and there is not drawer for it we link to the
        // plans page
        linkProps = {
            to: 'PLANS',
        };
    }

    return (
        <Grid item xs component={GridItemTooltip} key={type.key} sm={4}>
            <Tooltip
                PopperProps={{ disablePortal: true }}
                title={!canUse ? translate<string>('upgradeNow') : ''}
            >
                <Box>
                    <CardIconButton>
                        <CardActionArea
                            component={Link}
                            onClick={onClick}
                            {...linkProps}
                        >
                            <CardIconMedia color="primary">
                                {type.icon}
                            </CardIconMedia>

                            <CardContent>
                                <CardIconButtonTitle>
                                    {!canUse && (
                                        <Box
                                            alignItems="center"
                                            color="primary.main"
                                            component="span"
                                            display="inline-flex"
                                            mr={1}
                                        >
                                            <Icon path={mdiLock} size="2rem" />
                                        </Box>
                                    )}
                                    {translate(
                                        type.titleTranslationKey || type.key
                                    )}
                                </CardIconButtonTitle>
                            </CardContent>
                        </CardActionArea>
                    </CardIconButton>
                </Box>
            </Tooltip>
        </Grid>
    );
};
