import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TableRow } from '@mui/material';

import { TableCell } from 'common/components/Table';
import { Chip, ChipGroup } from 'common/components/Chip';
import { IGroupManagementListItemFragment } from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { Checkbox } from 'common/components/Checkbox';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';

interface IProps {
    group: IGroupManagementListItemFragment;
    isSelected: boolean;
    onSelect?: () => void;
}

export const GroupsManagementTableRow = ({
    group,
    isSelected,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();

    const {
        id,
        isEditable,
        name,
        userCount,
        managerCount,
        extraCategoryValues,
    } = group;

    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(getUrl('GROUP_EDIT', { id }))}
        >
            <TableCell>
                <Checkbox
                    checked={isSelected}
                    disabled={!isEditable}
                    onClick={(e) => {
                        onSelect?.();

                        e.stopPropagation();
                    }}
                />
            </TableCell>

            <TableCell colSpan={2}>
                {name}

                <ChipGroup sx={{ my: 0.25 }}>
                    {!!userCount && (
                        <Chip
                            label={translate('userCount', {
                                count: userCount,
                            })}
                            size="small"
                        />
                    )}

                    {!!managerCount && (
                        <Chip
                            label={translate('managerCount', {
                                count: managerCount,
                            })}
                            size="small"
                        />
                    )}
                    {!!extraCategoryValues?.length && (
                        <ExtraCategoryLabels
                            chipProps={{ size: 'small' }}
                            extraCategoryValues={extraCategoryValues}
                        />
                    )}
                </ChipGroup>
            </TableCell>
        </TableRow>
    );
};
