import { Navigate, useParams } from 'react-router-dom';

import { getUrl } from 'route/utils/getUrl';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

/**
 * Component that will redirect old translated routes to newer routes
 */
export const RedirectOldRoute = () => {
    const { id, token } = useParams();

    const library = !!useRouteMatch('LIBRARY_NL_REDIRECT');
    const libraryCollection = !!useRouteMatch('LIBRARY_COLLECTION_NL_REDIRECT');
    const libraryArticle = !!useRouteMatch('LIBRARY_ARTICLE_NL_REDIRECT');
    const changePassword = !!useRouteMatch('CHANGE_PASSWORD_NL_REDIRECT');
    const welcome = !!useRouteMatch('WELCOME_NL_REDIRECT');

    let redirectUrl;

    if (library) {
        redirectUrl = getUrl('LIBRARY');
    } else if (libraryCollection) {
        redirectUrl = getUrl('LIBRARY_COLLECTION', { id });
    } else if (libraryArticle) {
        redirectUrl = getUrl('LIBRARY_ARTICLE', { id });
    } else if (changePassword) {
        redirectUrl = getUrl('CHANGE_PASSWORD', { token });
    } else if (welcome) {
        redirectUrl = getUrl('WELCOME', { token });
    }

    if (!redirectUrl) return null;

    return <Navigate to={redirectUrl} />;
};
