import { useParams } from 'react-router-dom';

import {
    IUserListItemFragment,
    IUserSelectorQueryVariables,
    useGroupManagersQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';

export interface IUserList {
    users: IUserListItemFragment[];
    alreadyAddedUsers: string[];
    usersCount?: number | null;
    loading: boolean;
}

export const useGroupManagersList = (
    userQueryVariables?: IUserSelectorQueryVariables
): IUserList => {
    const { showApolloError } = useApolloError();
    const { id: groupId } = useParams() as { id: string };
    const { loading, data } = useGroupManagersQuery({
        variables: {
            id: groupId,
            q: userQueryVariables?.q,
            offset: userQueryVariables?.offset,
            ...userQueryVariables,
        },
        notifyOnNetworkStatusChange: true,
        onError: showApolloError,
    });

    const usersObject = data?.group?.managers;
    const usersCount = usersObject?.count;
    const users =
        usersObject?.edges.map((edge) => edge?.node || null).filter(Boolean) ||
        [];

    return {
        users,
        alreadyAddedUsers: [],
        usersCount,
        loading,
    };
};
