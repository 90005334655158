import { Box, Container } from '@mui/material';

import { IFileFragment } from 'graphql/types';
import { FixedContainer } from 'common/components/FixedContainer';

const headerImageStyle = {
    display: 'flex',
    width: '100%',
    minHeight: 300,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
};

interface IProps {
    backButton?: React.ReactFragment;
    contentContainerMaxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    className?: string;
    image?: IFileFragment | null;
    children?: React.ReactNode;
    fixed?: boolean;
}

export const PageIntroHeader = ({
    contentContainerMaxWidth,
    backButton,
    image,
    children,
    fixed,
    ...other
}: IProps) => {
    const content = (
        <Box
            py={4}
            sx={[
                headerImageStyle,
                !!image && { backgroundImage: `url(${image.url})` },
            ]}
            {...other}
        >
            <Container
                maxWidth={contentContainerMaxWidth || 'md'}
                sx={{ display: 'flex', flexDirection: 'column' }}
            >
                {!!backButton && <Box>{backButton}</Box>}

                {children && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: 'auto',
                            width: '100%',
                        }}
                    >
                        {children}
                    </Box>
                )}
            </Container>
        </Box>
    );

    return fixed ? <FixedContainer>{content}</FixedContainer> : content;
};
