import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BasePage } from 'hydra/pages/BasePage';
import { UserProfile } from 'user/components/UserProfile';
import {
    IRole,
    useCurrentUserQuery,
    usePortfolioSettingsQuery,
    useUserLazyQuery,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';
import { Loader } from 'common/components/Loader';
import { PageIntroHeader } from 'common/components/PageIntroHeader';
import { Typography } from 'common/components/Typography';
import { Avatar } from 'common/components/Avatar';
import { Text } from 'common/components/Text';
import { useCanManageUser, useFrontendPermissions } from 'user/hooks';
import { Link } from 'common/components/Link';
import { useApolloError } from 'common/hooks/useApolloError';
import { Chip, ChipGroup } from 'common/components/Chip';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { ManageButton } from 'common/components/Button/ManageButton';
import { usePortfolioViewPermissions } from 'user/hooks/portfolio/usePortfolioViewPermissions';
import { UpgradePage } from 'common/components/UpgradePage';
import { UpgradeContent as PortfolioUpgradeContent } from 'user/components/UpgradeContent';
import { UpgradeContent as OffersUpgradeContent } from 'offer/components/UpgradeContent';

import { FourOFourPage } from './FourOFourPage';

export const UserPage = () => {
    const { data: currentUserData } = useCurrentUserQuery();
    const { openGlobalDrawer } = useGlobalDrawer();
    const { id } = useParams();
    const {
        canUsePortfolioOffers,
        showUpgradePage,
        isPortfolioUpgradePage,
        loading: loadingViewPermissions,
        pageNotFound,
        isOffersPublished,
        hideOffersSection,
        hideMeetingsSection,
    } = usePortfolioViewPermissions();

    const [translate] = useTranslation();

    const { canManageUser, loading: loadingCanManageUser } =
        useCanManageUser(id);

    const [
        loadUser,
        { data, loading: userLoading, called: userCalled, error },
    ] = useUserLazyQuery();
    const {
        data: portfolioSettingsData,
        loading: portfolioSettingsLoading,
        error: portfolioSettingsError,
    } = usePortfolioSettingsQuery();
    const {
        canUpdate: canUpdatePortfolioSettings,
        loading: loadingPermissions,
    } = useFrontendPermissions('portfolioSettings');
    const { showApolloError } = useApolloError();

    useEffect(() => {
        if (id && !userLoading && !error && data?.user?.id !== id) {
            loadUser({
                variables: { id },
            });
        }
    }, [loadUser, userLoading, error, data, id]);

    useEffect(() => {
        if (portfolioSettingsError) showApolloError(portfolioSettingsError);
    }, [portfolioSettingsError, showApolloError]);

    const { currentUser } = currentUserData || {};
    const isCurrentUser = !id || id === currentUser?.id;
    const user = id ? data?.user : currentUser;
    const userNotFound = userCalled && !userLoading && !user;

    if (userNotFound || pageNotFound) return <FourOFourPage />;

    const { portfolioSettings } = portfolioSettingsData || {};

    const { title, titleColor, description, image, published } =
        portfolioSettings || {};

    // If the portfolio is published or
    // if not published you need to have the roles to update the portfolio settings
    const currentUserCanViewProfilePage =
        published || (!published && canUpdatePortfolioSettings);

    // The roles a user needs to see another user's profile
    // userCalled is only called when checking another user's profile
    // This check is always false when checking your own profile
    const currentUserCanViewOtherUser =
        !userCalled ||
        (userCalled &&
            (data?.user?.currentUserIsGroupManager ||
                currentUser?.roles?.includes(IRole.Manager)));

    if (
        !portfolioSettingsLoading &&
        !userLoading &&
        (!currentUserCanViewOtherUser || !currentUserCanViewProfilePage)
    ) {
        return <FourOFourPage />;
    }

    let header;

    const actionButtons = ((!loadingCanManageUser && canManageUser) ||
        isCurrentUser) && (
        <ManageButton
            component={Link}
            outlined={!image}
            to={isCurrentUser ? 'PROFILE_DRAWER' : undefined}
            onClick={
                !isCurrentUser
                    ? () => {
                          openGlobalDrawer({
                              type: IGlobalDrawerType.UserProfile,
                              itemId: id,
                          });
                      }
                    : undefined
            }
        >
            {translate('profile')}
        </ManageButton>
    );

    let headerColor = titleColor;

    if (!headerColor) {
        headerColor = !image ? undefined : '#fff';
    }

    const hiddenChip = !published && (
        <Box sx={{ mb: 2 }}>
            <ChipGroup>
                <Chip bgColor="warning" label={translate('hidden')} />
            </ChipGroup>
        </Box>
    );

    const userDisplay = user && (
        <Box alignItems="center" display="flex">
            <Box display={{ xs: 'none', sm: 'block' }} mr={2}>
                <Avatar
                    src={user?.profileImage.url || ''}
                    sx={{ border: '4px solid #fff', width: 85, height: 85 }}
                >
                    {user?.name[0] || ''}
                </Avatar>
            </Box>

            <Box>
                {title && (
                    <Box mr={1}>
                        <Typography
                            style={{ color: headerColor }}
                            variant="subtitle1"
                        >
                            {title}
                        </Typography>
                    </Box>
                )}

                <Typography sx={{ color: headerColor }} variant="h1">
                    {user.name}
                </Typography>
            </Box>
        </Box>
    );

    if (image) {
        header = (
            <PageIntroHeader contentContainerMaxWidth="md" image={image}>
                {hiddenChip}

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item>{userDisplay}</Grid>

                    <Box sx={{ mt: { xs: 2, md: 0 } }}>{actionButtons}</Box>
                </Grid>
            </PageIntroHeader>
        );
    }

    const loading =
        userLoading ||
        portfolioSettingsLoading ||
        loadingViewPermissions ||
        loadingPermissions;

    return (
        <BasePage
            contentContainerMaxWidth={showUpgradePage ? 'lg' : undefined}
            header={showUpgradePage ? undefined : header}
        >
            {error && <ApolloError error={error} />}

            {loading && <Loader />}

            {!loading && user && !showUpgradePage && (
                <>
                    {!header && (
                        <Box mt={4}>
                            {hiddenChip}

                            <Grid
                                container
                                alignItems={!!title ? 'flex-end' : 'center'}
                                justifyContent="space-between"
                                maxWidth="md"
                                spacing={2}
                            >
                                <Grid item>{userDisplay}</Grid>

                                {!header && <Grid item>{actionButtons}</Grid>}
                            </Grid>
                        </Box>
                    )}

                    {!!description && (
                        <Box mb={2} mt={4}>
                            <Text variant="body1">{description}</Text>
                        </Box>
                    )}

                    <Box sx={{ mt: !description ? 2 : 0 }}>
                        <UserProfile
                            canUsePortfolioOffers={canUsePortfolioOffers}
                            hideMeetingsSection={hideMeetingsSection}
                            hideOffersSection={hideOffersSection}
                            isCurrentUser={isCurrentUser}
                            isOffersPublished={isOffersPublished}
                            user={user}
                        />
                    </Box>
                </>
            )}

            {showUpgradePage && (
                <UpgradePage>
                    {isPortfolioUpgradePage ? (
                        <PortfolioUpgradeContent />
                    ) : (
                        <OffersUpgradeContent />
                    )}
                </UpgradePage>
            )}
        </BasePage>
    );
};
