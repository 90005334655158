import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components/Button';
import { AlertDialog } from 'common/components/AlertDialog';

interface IProps {
    onConfirm?(): void;
    onCancel?(): void;
    open?: boolean;
}

export const DeleteAssignmentFilesAlert = ({
    open,
    onConfirm,
    onCancel,
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <AlertDialog
            actions={
                <>
                    <Button
                        autoFocus
                        color="error"
                        variant="contained"
                        onClick={onConfirm}
                    >
                        {translate('delete')}
                    </Button>
                    <Button variant="contained" onClick={onCancel}>
                        {translate('cancel')}
                    </Button>
                </>
            }
            open={!!open}
            title={translate('deleteFileMessage.title')}
        >
            <DialogContentText color="text.primary" variant="body2">
                {translate('deleteFileMessage.text')}
            </DialogContentText>
        </AlertDialog>
    );
};
