import { useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Form, Formik, Field } from 'formik';

import {
    ICreateCourseModuleInput,
    IOpenOffsetType,
    IModuleWeight,
    ICourseFragment,
    useCourseQuery,
} from 'graphql/types';
import { Button } from 'common/components/Button';
import { TextField } from 'common/components/FormField';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ContentExpander } from 'common/components/ContentExpander';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { Typography } from 'common/components/Typography';
import { IModuleFormValues } from 'training/types';
import { ModuleImageFormSection } from 'training/components/ModuleImageFormSection';
import { ModuleOffsetFormSection } from 'training/components/ModuleOffsetFormSection';
import { ModuleWeightFormSection } from 'training/components/ModuleWeightFormSection';
import { FormDeleteButton } from 'common/components/Button/FormDeleteButton';
import { useApolloError } from 'common/hooks/useApolloError';

import { CourseFormItem } from './CourseFormItem';

export interface ICourseModuleFormValues
    extends IModuleFormValues,
        Omit<ICreateCourseModuleInput, 'imageId' | 'offset'> {}

export interface IProps {
    course?: ICourseFragment;
    initialValues?: Partial<ICourseModuleFormValues>;
    disabled?: boolean;
    edit?: boolean;
    moduleGroupId?: string;
    courseId?: string;
    onSubmit(values: ICourseModuleFormValues): void;
    onDelete?(): void;
    onReplaceCourse?(): void;
}

export const CourseModuleForm = ({
    course,
    initialValues,
    disabled,
    edit,
    moduleGroupId,
    courseId,
    onSubmit,
    onDelete,
    onReplaceCourse,
    ...other
}: IProps) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const { data: courseData, loading: loadingCourseData } = useCourseQuery({
        variables: { id: courseId },
        skip: !courseId,
        onError: showApolloError,
    });

    const newCourse = courseData?.course;

    const selectedCourse = !!newCourse ? newCourse : course;

    const initValues: ICourseModuleFormValues = {
        title: '',
        description: '',
        courseId: '',
        weight: IModuleWeight.Weight1,
        offset: {
            type: IOpenOffsetType.Zero,
            days: 0,
            hours: 0,
        },
        ...initialValues,
    };

    // If a courseId is passed we want to use that instead of the one from initialValues
    const selectedCourseId = courseId || initialValues?.courseId;
    const courseChanged = selectedCourseId !== initialValues?.courseId;

    const permissions = !!selectedCourse?.permissions
        ? JSON.parse(selectedCourse.permissions)
        : undefined;

    const canUpdateCourse = !!permissions?.canUpdate;

    const formTitle = edit
        ? translate('moduleForm.course.editTitle')
        : translate('moduleForm.course.newTitle');
    const formText = edit
        ? translate('moduleForm.course.editText')
        : translate('moduleForm.course.newText');
    const submitText = edit
        ? translate('moduleForm.course.editButton')
        : translate('moduleForm.course.newButton');

    return (
        <Formik
            initialValues={initValues}
            onSubmit={(values) => {
                const newValues = values;

                if (courseChanged) {
                    newValues.courseId = selectedCourseId;
                }

                return onSubmit(newValues);
            }}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty, values }) => (
                <Box p={{ xs: 2, sm: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />
                    <Form>
                        {formTitle && formText && (
                            <Box mb={2}>
                                {formTitle && (
                                    <Typography
                                        sx={{ fontWeight: 'bold' }}
                                        variant="h3"
                                    >
                                        {formTitle}
                                    </Typography>
                                )}

                                {formText && (
                                    <Box mt={1}>
                                        <Typography>{formText}</Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box mb={2} mt={2}>
                            <CourseFormItem
                                course={selectedCourse}
                                isEditable={canUpdateCourse}
                                loading={loadingCourseData}
                                onReplaceCourse={onReplaceCourse}
                            />

                            {!loadingCourseData &&
                                !selectedCourse &&
                                courseChanged && (
                                    <FormHelperText error>
                                        {translate(
                                            'moduleForm.course.courseIsRequired'
                                        )}
                                    </FormHelperText>
                                )}
                        </Box>

                        <Box mt={4}>
                            <ContentExpander
                                expand={settingsExpanded}
                                title={translate('moreSettings')}
                                onClick={() =>
                                    setSettingsExpanded(!settingsExpanded)
                                }
                            >
                                <FormSection
                                    description={
                                        <Trans
                                            components={{
                                                a: (
                                                    <Link
                                                        rel="noreferrer"
                                                        underline="always"
                                                    />
                                                ),
                                            }}
                                            i18nKey="moduleForm.course.overrideDescription"
                                        />
                                    }
                                    title={translate('titleOverride')}
                                >
                                    <Field
                                        component={TextField}
                                        label={translate('title')}
                                        name="title"
                                    />

                                    <Field
                                        multiline
                                        component={TextField}
                                        label={translate('description')}
                                        name="description"
                                    />
                                </FormSection>

                                <ModuleWeightFormSection />
                                <ModuleImageFormSection />

                                {/* Only show conditions section when we have a module group id */}
                                {moduleGroupId && (
                                    <ModuleOffsetFormSection
                                        moduleGroupId={moduleGroupId}
                                        offsetValues={
                                            values.offset || undefined
                                        }
                                    />
                                )}
                            </ContentExpander>
                        </Box>

                        <Box sx={{ display: 'flex', mt: 4 }}>
                            <Box>
                                <Button
                                    color="primary"
                                    disabled={
                                        isSubmitting ||
                                        disabled ||
                                        (!isSubmitting &&
                                            !disabled &&
                                            !dirty &&
                                            !courseChanged)
                                    }
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        e.preventDefault();

                                        return submitForm();
                                    }}
                                >
                                    {submitText}
                                </Button>
                            </Box>
                            {onDelete && (
                                <Box ml="auto">
                                    <FormDeleteButton
                                        disabled={isSubmitting || disabled}
                                        onClick={onDelete}
                                    >
                                        {translate('delete')}
                                    </FormDeleteButton>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
