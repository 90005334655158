import { Box } from '@mui/material';
import { rgba } from 'polished';
import { styled } from 'styled-components';

interface IProps {
    children: React.ReactNode;
    append?: React.ReactNode;
}

const OverlayCircleContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 60px;
    min-height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    flex-direction: column;
`;

const BaseOverlayCircle = ({ children, append, ...other }: IProps) => (
    <OverlayCircleContainer>
        <div {...other}>{children}</div>
        {append && <Box mt={1}>{append}</Box>}
    </OverlayCircleContainer>
);

export const OverlayCircle = styled(BaseOverlayCircle)`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.common.white};
    background: ${({ theme }) => rgba(theme.palette.common.black, 0.62)};
    border-radius: 50%;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`;
