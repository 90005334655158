import { DateTime } from 'luxon';

import {
    TFilterBarItem,
    TRangeFilterValue,
    EFilterType,
    TFilterFormValue,
    TStringFilterValue,
    TObjectFilterValue,
    TDateFilterValue,
    IFilterFormItem,
} from 'common/types';
import { TQueryParams } from 'common/utils/getSearchUrl';
import { removeEmptyFromObject } from 'common/utils/object';

/**
 * Toggle the selected state of a filter item in the given selected array.
 * @param selected current selection array
 * @param item item to be toggle
 * @param parent if a parent is given and the parent is a single value filter, we remove the current item and replace it.
 * @returns update selection array
 */
export function filterToggleSelected(
    selected: TFilterBarItem[],
    item: TFilterBarItem,
    parent?: TFilterBarItem
) {
    const items = [...selected];

    // When we only want a single value we remove the current item and replace it.
    if (parent?.singleValue) {
        const index = items.findIndex(({ parent }) => parent === item.parent);

        if (index > -1) {
            items.splice(index, 1);
        }
        items.push(item);
    } else {
        const index = items.findIndex(
            ({ value, parent }) =>
                value === item.value && parent === item.parent
        );

        if (index > -1) {
            items.splice(index, 1);
        } else {
            items.push(item);
        }
    }

    return items;
}

/*
 * Returns the selected filters items based on the queryparams
 */
export function getSelectedFilters(
    queryParams: TQueryParams,
    filterBarItems: TFilterBarItem[]
) {
    const selected: TFilterBarItem[] = [];

    Object.keys(queryParams).forEach((queryParamKey) => {
        filterBarItems.forEach((filterBarItem) => {
            const filterBarResult = filterBarItem.children?.filter(
                (filterBarItemChild) =>
                    queryParamKey === filterBarItemChild.parent &&
                    queryParams[queryParamKey].includes(
                        filterBarItemChild.value
                    )
            );

            if (!filterBarResult?.length) return;

            filterBarResult.forEach((result) => {
                selected.push(result);
            });
        });
    });

    return selected;
}

// Type guard for the filter value
export const isRangeFilterValue = (
    type: EFilterType,
    value: TFilterFormValue
): value is TRangeFilterValue => type === EFilterType.Range;

export const isRadioFilterValue = (
    type: EFilterType,
    value: TFilterFormValue
): value is TStringFilterValue => type === EFilterType.Radio;

export const isObjectFilterValue = (
    type: EFilterType,
    value: TFilterFormValue
): value is TObjectFilterValue | TObjectFilterValue[] =>
    type === EFilterType.Object;

export const isStringFilterValue = (
    type: EFilterType,
    value: TFilterFormValue
): value is TStringFilterValue => type === EFilterType.String;

export function getRangeFilterDisplay(
    value: TRangeFilterValue
): TRangeFilterValue {
    let [from, to] = value;
    from = Math.floor(from * 100);
    to = Math.floor(to * 100);

    return [from, to];
}

export function parseDateTimeFilterValue(value: TDateFilterValue) {
    if (value instanceof DateTime) {
        return value.toISODate() || value;
    }

    return value;
}

export function filtersToBase64String(
    filters: IFilterFormItem[],
    values?: Record<string, TFilterFormValue>
) {
    if (!values) return '';

    const filterValues = removeEmptyFromObject(values);

    // Loop over filters and call parse function for each filter
    filters.forEach((filter) => {
        filter.fields.forEach((field) => {
            let value = filterValues[field.name];

            if (!value) return;
            const { parseFilterValue } = field;

            if (parseFilterValue) {
                value = parseFilterValue(value);
            }

            filterValues[field.name] = value;

            // Remove value if field is same as initial value Use JSON.stringify so we can also compare
            // arrays and objects
            if (JSON.stringify(value) === JSON.stringify(field.initialValue)) {
                delete filterValues[field.name];
            }
        });
    });

    if (!!Object.keys(filterValues).length) {
        // Base64 encode the filter values
        try {
            return window.btoa(JSON.stringify(filterValues));
        } catch {
            // Something went wrong with the encoding
            return '';
        }
    }
}

export function filtersToQueryParams<SelectedFilters>(
    filters: IFilterFormItem[],
    selectedFilters: Partial<SelectedFilters>
): Record<string, unknown> {
    const filterValues: Record<string, unknown> = {};

    // Loop over filters and call parse function for each filter
    filters.forEach((filter) => {
        filter.fields.forEach((field) => {
            const value = selectedFilters[field.name as keyof SelectedFilters];

            if (!value) return;

            const { parseQueryValue, queryKey } = field;

            let parsedValue = value as TFilterFormValue;

            if (parseQueryValue) {
                parsedValue = parseQueryValue(parsedValue) as TFilterFormValue;
            }

            // Use queryKey if provided, otherwise use field name
            const key = queryKey || field.name;

            // If filter values already has a value for key, combine into array
            if (filterValues[key]) {
                const existingValue = Array.isArray(filterValues[key])
                    ? filterValues[key]
                    : [filterValues[key]];
                const newValue = Array.isArray(parsedValue)
                    ? parsedValue
                    : [parsedValue];
                filterValues[key] = [...existingValue, ...newValue];
            } else {
                filterValues[key] = parsedValue;
            }
        });
    });

    return filterValues;
}
