import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useRef, useState } from 'react';
import { mdiFileRemove } from '@mdi/js';
import { FetchResult, useApolloClient } from '@apollo/client';

import { Typography } from 'common/components/Typography';
import { FileTable } from 'common/tables';
import {
    IFileFragment,
    IPortfolioItemFragment,
    useCurrentUserQuery,
    useUpdatePortfolioItemMutation,
} from 'graphql/types';
import { Tooltip } from 'common/components/Tooltip';
import { IconButton } from 'common/components/IconButton';
import {
    IDeleteAlertDialogOptions,
    INITIAL_DELETE_ALERT_DIALOG_VALUES,
} from 'common/constants/deleteAlertDialog';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useApolloError } from 'common/hooks/useApolloError';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

interface IProps {
    filesAreUpdating: boolean;
    portfolioItem?: IPortfolioItemFragment;
    withEditActions?: boolean;
    userId?: string;
    setFilesAreUpdating(filesAreUpdating: boolean): void;
}

export const PortfolioItemFileTable = ({
    filesAreUpdating,
    portfolioItem,
    withEditActions,
    userId,
    setFilesAreUpdating,
}: IProps) => {
    const [translate] = useTranslation();
    const { data: currentUserData } = useCurrentUserQuery();
    const uploadingFileRefs = useRef<string[]>([]);
    const [deleteAlertDialog, setDeleteAlertDialog] =
        useState<IDeleteAlertDialogOptions>(INITIAL_DELETE_ALERT_DIALOG_VALUES);
    const [displaySnackbar] = useSnackbar();
    const client = useApolloClient();
    const { showApolloError } = useApolloError();
    const portfolioItemViewProfile = !!useRouteMatch('PORTFOLIO_ITEM_VIEW');
    const [updatePortfolioItem, { loading: loadingUpdatePortfolioItem }] =
        useUpdatePortfolioItemMutation({
            onError: (error) => {
                showApolloError(error);

                setFilesAreUpdating(false);
            },
        });

    const { currentUser } = currentUserData || {};

    const currentUserIsOwner =
        portfolioItemViewProfile ||
        (currentUser?.id && currentUser?.id === userId);

    const handleCustomPortfolioItemMutationUpdate = (
        mutationResult: FetchResult,
        filterUploadingFiles?: (fileIds: string[]) => void
    ) => {
        const { data: updatedCustomPortfolioItemData } = mutationResult;

        const updatedCustomPortfolioItem =
            updatedCustomPortfolioItemData?.updatePortfolioItem.portfolioItem;

        if (!updatedCustomPortfolioItem) return;

        client.refetchQueries({ include: ['PortfolioScores'] });

        handleFileMutation(updatedCustomPortfolioItem, filterUploadingFiles);

        displaySnackbar(translate('filesUploaded'), {
            variant: 'success',
        });
    };

    const handleUploadFinish = (
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!portfolioItem) return;

        const curFileIds = sortedFiles.map((file) => file.id);

        // Merge of the new currently uploading file ids from the batch (the uploadingFileRefs) with
        // the existing file ids. Filtered so only unique values exist.
        const fileIds = curFileIds.concat(
            uploadingFileRefs.current.filter(
                (item) => curFileIds.indexOf(item) < 0
            )
        );

        updatePortfolioItem({
            variables: {
                id: portfolioItem.id,
                portfolioItem: { fileIds },
            },
            update: (_cache, mutationResult) =>
                handleCustomPortfolioItemMutationUpdate(
                    mutationResult,
                    filesUpdateCallback
                ),
        });
    };

    const handleFileMutation = (
        updatedCustomPortfolioItem: IPortfolioItemFragment,
        filterUploadingFiles?: (fileIds: string[]) => void
    ) => {
        uploadingFileRefs.current = [];

        if (filterUploadingFiles) {
            filterUploadingFiles(
                updatedCustomPortfolioItem.files.map((file) => file.id)
            );
        }

        setFilesAreUpdating(false);
    };

    const handleRemoveFiles = async (
        fileIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!portfolioItem) return;

        setFilesAreUpdating(true);

        const newFileIds = sortedFiles
            .filter((file) => !fileIds.includes(file.id))
            .map((file) => file.id);

        try {
            const response = await updatePortfolioItem({
                variables: {
                    id: portfolioItem.id,
                    portfolioItem: { fileIds: newFileIds },
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    updatePortfolioItem: {
                        __typename: 'UpdatePortfolioItem',
                        portfolioItem: {
                            ...portfolioItem,
                            files: portfolioItem.files.filter(
                                (file) => !fileIds.includes(file.id)
                            ),
                        },
                    },
                },
            });

            const { data: updatedCustomPortfolioItemData } = response;
            const updatedCustomPortfolioItem = updatedCustomPortfolioItemData
                ?.updatePortfolioItem.portfolioItem as IPortfolioItemFragment;

            if (!updatedCustomPortfolioItem) return;

            handleFileMutation(updatedCustomPortfolioItem, filesUpdateCallback);
        } catch {
            // continue regardless of error
        }

        displaySnackbar(translate('filesDeleted'), {
            variant: 'success',
        });
    };

    const fileTableActions = (
        selected: string[],
        onSelectAll: () => void,
        filesUpdateCallback: (fileIds: string[]) => void
    ) => {
        if (!currentUserIsOwner) return undefined;

        const selectedFileIds =
            sortedFiles
                ?.filter((file) => selected.includes(file.id))
                .map((file) => file.id) || [];

        return (
            <Tooltip title={translate<string>('delete')}>
                <Box>
                    <IconButton
                        color="inherit"
                        disabled={!selected.length}
                        iconPath={mdiFileRemove}
                        iconSize="2.4rem"
                        onClick={() => {
                            setDeleteAlertDialog({
                                show: true,
                                callback: () => {
                                    setDeleteAlertDialog({
                                        title: translate(
                                            'deleteFileMessage.title'
                                        ),
                                        text: translate(
                                            'deleteFileMessage.text'
                                        ),
                                        show: false,
                                    });

                                    handleRemoveFiles(
                                        selectedFileIds,
                                        filesUpdateCallback
                                    );

                                    // Deselect all
                                    onSelectAll();
                                },
                                title: translate('deleteFileMessage.title'),
                                text: translate('deleteFileMessage.text'),
                            });
                        }}
                    />
                </Box>
            </Tooltip>
        );
    };

    let handleAddFile;

    if (withEditActions && !filesAreUpdating) {
        handleAddFile = (uploadedFile: IFileFragment) => {
            if (!portfolioItem) return;

            // Collection of all the uploaded file ids in a batch
            uploadingFileRefs.current.push(uploadedFile.id);
        };
    }

    const sortedFiles = useMemo(() => {
        if (!portfolioItem) return [];

        // Sorted by upload date, latest first
        const newFiles = [...portfolioItem.files];

        return newFiles.sort((a, b) => {
            const date1 = new Date(a.uploadDate);
            const date2 = new Date(b.uploadDate);

            return date2.getTime() - date1.getTime();
        });
    }, [portfolioItem]);

    return (
        <>
            {!portfolioItem && (
                <Box p={{ xs: 2, sm: 4 }}>
                    <Typography>{translate('portfolioFileAdding')}</Typography>
                </Box>
            )}

            {((withEditActions && portfolioItem) ||
                (!withEditActions && !!sortedFiles.length)) && (
                <Box p={{ xs: 2, sm: 4 }}>
                    <FileTable
                        inDrawer
                        withOwnerColumn
                        id={`portfolioItemFiles-${portfolioItem?.id}}`}
                        items={sortedFiles || []}
                        readonly={!withEditActions}
                        renderSelectedActions={fileTableActions}
                        onAdd={handleAddFile}
                        onRemove={handleRemoveFiles}
                        onUploadFinish={handleUploadFinish}
                        onUploadStart={() => setFilesAreUpdating(true)}
                    />

                    <ConfirmDialog
                        color="error"
                        confirmText={translate('delete')}
                        loading={loadingUpdatePortfolioItem}
                        open={deleteAlertDialog.show}
                        title={deleteAlertDialog.title}
                        onCancel={() => {
                            setDeleteAlertDialog({
                                ...deleteAlertDialog,
                                show: false,
                            });
                        }}
                        onClose={() => {
                            setDeleteAlertDialog({
                                ...deleteAlertDialog,
                                show: false,
                            });
                        }}
                        onConfirm={() => deleteAlertDialog.callback?.()}
                    >
                        {deleteAlertDialog.text}
                    </ConfirmDialog>
                </Box>
            )}
        </>
    );
};
