import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    useCreateLocationMutation,
    useCreateMeetingModuleMutation,
} from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { PageTitle } from 'common/components/PageTitle';
import { IModuleCreateDrawerProps } from 'training/types';
import { gotoTrainingPage } from 'training/utils/gotoRoutes';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { createModuleInCache } from 'training/utils/module';
import {
    IMeetingModuleFormProps,
    MeetingModuleForm,
} from 'training/components/forms/MeetingModuleForm';

export const MeetingModuleCreateDrawer = ({
    trainingId,
    moduleGroupId,
}: IModuleCreateDrawerProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { showApolloError } = useApolloError();

    const [createModule] = useCreateMeetingModuleMutation();
    const [createLocation] = useCreateLocationMutation();

    const handleSubmit: IMeetingModuleFormProps['onSubmit'] = async (
        values
    ) => {
        const { location, newLocation, image, offset, ...otherValues } = values;

        try {
            let newLocationObject;

            if (newLocation?.title) {
                const newLocationResponse = await createLocation({
                    variables: {
                        location: newLocation,
                    },
                });

                newLocationObject =
                    newLocationResponse.data?.createLocation?.location;
            }

            const moduleValues = {
                ...otherValues,
                locationId: newLocationObject?.id || location?.id,
                imageId: image?.id,
                offset: {
                    seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                    type: offset?.type,
                },
            };

            // When moduleGroupId is given we use that for creating the module inside the moduleGroup, otherwise create it
            // in the training which will make a new moduleGroup
            const parentId = moduleGroupId ? { moduleGroupId } : { trainingId };

            await createModule({
                variables: { module: moduleValues, ...parentId },
                update: (cache, { data }) => {
                    const { module: newModule, moduleGroup } =
                        data?.createMeetingModule || {};

                    if (!newModule || !moduleGroup) return;

                    createModuleInCache(
                        newModule,
                        moduleGroup,
                        !moduleGroupId, // Is new module group
                        trainingId,
                        cache
                    );
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.meeting.create'), {
            variant: 'success',
        });

        // Go back to training to close drawer
        gotoTrainingPage(navigate, trainingId);

        return;
    };

    return (
        <>
            <PageTitle>{translate('newMeetingModule')}</PageTitle>

            <MeetingModuleForm
                moduleGroupId={moduleGroupId}
                onSubmit={handleSubmit}
            />
        </>
    );
};
