import {
    ImagePlugin as SlateImagePlugin,
    ImagePluginOptions,
    SlatePlugin,
} from '@udecode/slate-plugins';

import { deserializeImage } from './deserialize';

export const ImagePlugin = (options?: ImagePluginOptions): SlatePlugin => ({
    ...SlateImagePlugin(options),
    deserialize: deserializeImage(options),
    voidTypes: [],
});
