import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import { ICourseListItemFragment } from 'graphql/types';
import { isCourseAdded, isCourseSelected } from 'training/utils/courseSelect';

import { CourseListItem } from '../CourseListItem';

interface IProps {
    addedCourses: ICourseListItemFragment[];
    selectedCourses: ICourseListItemFragment[];
    courses: ICourseListItemFragment[];
    onAddCourse(selectedTraining: ICourseListItemFragment): void;
    onRemoveCourse(selectedTraining: ICourseListItemFragment): void;
    listItemProps?: Partial<React.ComponentProps<typeof CourseListItem>>;
}

export const CourseAddList = ({
    addedCourses,
    selectedCourses,
    courses,
    onAddCourse,
    onRemoveCourse,
    listItemProps,
}: IProps) => {
    const { t: translate } = useTranslation();

    const handleSelectCourse = (
        course: ICourseListItemFragment,
        isAdded: boolean
    ) => {
        isAdded ? onRemoveCourse(course) : onAddCourse(course);
    };

    return (
        <>
            {!courses.length ? (
                <Box display="flex" justifyContent="center" marginTop={7}>
                    <Typography>
                        {translate('noOptionsText.courses')}
                    </Typography>
                </Box>
            ) : (
                courses.filter(Boolean).map((course) => {
                    const isSelected = isCourseSelected(
                        selectedCourses,
                        course
                    );
                    const isAdded = isCourseAdded(addedCourses, course);

                    return (
                        <CourseListItem
                            addButton
                            added={isSelected || isAdded}
                            course={course}
                            key={course.id}
                            px={2}
                            selected={isSelected || isAdded}
                            onClick={() =>
                                handleSelectCourse(
                                    course,
                                    isSelected || isAdded
                                )
                            }
                            {...listItemProps}
                        />
                    );
                })
            )}
        </>
    );
};
