import { useLocation } from 'react-router-dom';

import { TRouteName } from 'route/types';
import { ROUTES } from 'route/constants/routes';

export const useHashMatch = <
    Params extends { [K in keyof Params]?: string } = Record<
        string,
        string | undefined
    >,
>(
    routeName: TRouteName
) => {
    const { hash } = useLocation();
    const path = ROUTES[routeName];

    return hash === path;
};
