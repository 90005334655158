import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    IModuleType,
    useDeleteModuleMutation,
    useUpdateVideoModuleMutation,
    useModuleEditQuery,
} from 'graphql/types';
import {
    VideoModuleForm,
    IVideoModuleFormProps,
} from 'training/components/forms/VideoModuleForm';
import { IModuleUpdateDrawerProps } from 'training/types';
import { useHashMatch } from 'route/hooks/useHashMatch';
import { useRouteQuery } from 'route/hooks/useRouteQuery';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { PageTitle } from 'common/components/PageTitle';
import { useApolloError } from 'common/hooks/useApolloError';
import { dayHoursToSeconds } from 'common/utils/formatDate';
import { Loader } from 'common/components/Loader';
import { getModuleOffsetFormValue } from 'training/utils/moduleOffset';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { getUrl } from 'route/utils/getUrl';
import { isUUID } from 'common/utils/isUUID';
import { NotFound } from 'common/components/NotFound';
import { getImageId } from 'common/utils/image';

import { VideoModuleSelectDrawer } from './VideoModuleSelectDrawer';

export const VideoModuleUpdateDrawer = ({
    id,
    onClose,
}: IModuleUpdateDrawerProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const [displaySnackbar] = useSnackbar();
    const { trackMixpanel } = useMixpanel();
    const { showApolloError } = useApolloError();
    const [searchParams, setSearchParams] = useSearchParams();

    const routeQuery = useRouteQuery();
    const videoIdParam = routeQuery.get('video') || undefined;
    const [selectedVideoId, setSelectedVideoId] = useState(videoIdParam);

    const videoSelectDrawerOpen = useHashMatch('TRAINING_MODULE_SELECT');

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const { data, loading } = useModuleEditQuery({
        variables: { id, type: IModuleType.Video },
    });

    const [updateModule] = useUpdateVideoModuleMutation();
    const [deleteModule, { loading: loadingDelete }] =
        useDeleteModuleMutation();

    useEffect(() => {
        if (!videoIdParam) return;

        // Remove course search params, if the course id is set we can remove it
        const { video, ...newSearchParams } = Object.fromEntries(searchParams);
        setSearchParams(new URLSearchParams(newSearchParams));
    }, [videoIdParam, searchParams, setSearchParams]);

    const module = data?.module;

    if (loading) return <Loader />;

    if (!isUUID(id) || !module || module?.__typename !== 'VideoModule') {
        return <NotFound />;
    }

    const handleSubmit: IVideoModuleFormProps['onSubmit'] = async (values) => {
        const { image, offset, ...otherValues } = values;

        const moduleValues = {
            ...otherValues,
            imageId: getImageId(module.image, image),
            offset: {
                seconds: dayHoursToSeconds(offset?.days, offset?.hours),
                type: offset?.type,
            },
        };

        try {
            await updateModule({
                variables: { id, module: moduleValues },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Update video module in drawer',
        });

        displaySnackbar(translate('moduleActionSuccess.video.update'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteModule({
                variables: {
                    id,
                    type: IModuleType.Video,
                },
                update: (cache) => {
                    cache.evict({ id: `VideoModule:${id}` });
                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        await trackMixpanel({
            eventName: 'Delete video module in drawer',
        });

        displaySnackbar(translate('moduleActionSuccess.video.delete'), {
            variant: 'success',
        });

        // Close drawer
        onClose?.();

        return;
    };

    const {
        title,
        description,
        showLarge,
        autoplay,
        image,
        openOffset,
        video,
        moduleGroup: { id: moduleGroupId },
    } = module;

    const initialValues = {
        title,
        description,
        showLarge,
        autoplay,
        videoId: video?.id,
        image: image || undefined,
        offset: getModuleOffsetFormValue(openOffset),
    };

    return (
        <>
            <PageTitle mixpanelTitle="'Drawer update video module">
                {translate('updateVideoModule')}
            </PageTitle>

            <VideoModuleSelectDrawer
                edit
                open={videoSelectDrawerOpen}
                onClose={() => {
                    // Redirect to the pathname to go to the page without hashtag.
                    navigate(window.location.pathname);
                }}
                onSelect={(video) => {
                    // Redirect to the pathname to go to the page without hashtag.
                    setSelectedVideoId(video.id);
                    navigate(window.location.pathname);
                }}
            />

            {!loading && !!video && (
                <VideoModuleForm
                    edit
                    initialValues={initialValues}
                    moduleGroupId={moduleGroupId}
                    video={video}
                    videoId={selectedVideoId}
                    onDelete={() => setShowDeleteAlert(true)}
                    onReplaceVideo={() => {
                        navigate(getUrl('TRAINING_MODULE_SELECT'));
                    }}
                    onSubmit={handleSubmit}
                />
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            loading={loadingDelete}
                            variant="contained"
                            onClick={handleDelete}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            disabled={loadingDelete}
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlert(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlert}
                title={translate('deleteVideoModuleMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('deleteVideoModuleMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </>
    );
};
