import { Box } from '@mui/material';

import { Autocomplete } from 'common/components/FormField/Autocomplete/Autocomplete';
import { Checkbox } from 'common/components/Checkbox';
import { Typography } from 'common/components/Typography';

interface IProps {
    getOptionKey?(option: unknown): string;
    getOptionDisplay?(option: unknown): React.ReactNode;
    getOptionIsInfo?(option: unknown): boolean; // Returns if option is an info option
}
export const MultiSelect = (({
    getOptionKey,
    getOptionDisplay,
    getOptionIsInfo,
    ...other
}: React.ComponentProps<typeof Autocomplete> & IProps) => {
    const { value } = other.field;
    let { placeholder } = other;

    placeholder = !!placeholder && !value?.length ? placeholder : undefined;

    const inputLabelProps = !!placeholder ? { shrink: true } : undefined;

    return (
        <Autocomplete
            renderOption={(renderProps, option, state) => {
                const { key, ...optionProps } = renderProps;
                const optionKey = getOptionKey?.(option);

                // If option is info show a custom greyed out text
                // that is not clickable
                if (getOptionIsInfo?.(option)) {
                    return (
                        <Box
                            component="li"
                            key={optionKey || key}
                            {...optionProps}
                            sx={{
                                pointerEvents: 'none',
                                opacity: 0.6,
                            }}
                        >
                            <Typography
                                sx={{ width: '100%', textAlign: 'center' }}
                                variant="caption"
                            >
                                {getOptionDisplay?.(option)}
                            </Typography>
                        </Box>
                    );
                }

                let optionDisplay = option;

                if (getOptionDisplay) {
                    optionDisplay = getOptionDisplay(option);
                } else if (other.getOptionLabel) {
                    optionDisplay = other.getOptionLabel(option);
                }

                return (
                    <Box component="li" key={optionKey || key} {...optionProps}>
                        <Checkbox
                            checked={state.selected}
                            sx={{ ml: '-9px' }}
                        />

                        <Box
                            sx={{
                                ml: 0.75,
                                py: 0.5,
                                lineHeight: 1.2,
                                overflow: 'hidden',
                            }}
                        >
                            {optionDisplay}
                        </Box>
                    </Box>
                );
            }}
            {...other}
            disableCloseOnSelect
            multiple
            ChipProps={{
                color: 'primary',
            }}
            InputLabelProps={inputLabelProps}
            InputProps={{
                ...other.InputProps,
                placeholder,
                variant: 'outlined',
            }}
        />
    );
}) as typeof Autocomplete;
