import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IScormEditFragment,
    useUpdateScormMutation,
    useDeleteScormMutation,
    ScormEditDocument,
    useScormEditLazyQuery,
} from 'graphql/types';
import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { Loader } from 'common/components/Loader';
import { useApolloError } from 'common/hooks/useApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { PageTitle } from 'common/components/PageTitle';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { ScormForm } from 'training/components/forms/';
import { TScormFormValues } from 'training/components/forms/ScormForm';
import { getImageId } from 'common/utils/image';

interface IProps extends IPageDrawerProps {
    id?: string;
    onClose?(): void;
}

export const ScormUpdateDrawer = ({ id, open, onClose, ...other }: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [scorm, setScorm] = useState<IScormEditFragment>();

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const [getScorm, { loading: loadingScorm, refetch }] =
        useScormEditLazyQuery({
            onCompleted: (data) => {
                const scorm = data?.scorm;

                if (!scorm) return;

                setScorm(scorm);
            },
        });
    const [updateScorm, { loading: loadingUpdate }] = useUpdateScormMutation();
    const [deleteScorm, { loading: loadingDelete }] = useDeleteScormMutation();

    useEffect(() => {
        if (!open || !id) return;

        getScorm({ variables: { id } });
    }, [id, open, getScorm]);

    if (loadingScorm || !scorm) {
        return (
            <PageDrawer open={open}>
                <Loader />
            </PageDrawer>
        );
    }

    const { title, zipFile, thumbnail } = scorm;

    const initialValues: TScormFormValues = {
        title: title || '',
        fileUrl: zipFile?.url || '',
        zipFile: zipFile || undefined,
        thumbnail: thumbnail || undefined,
    };

    const handleClose = () => {
        // Unset scorm on close, this is needed to make sure we don't render the previous scorm when opening
        // the drawer. We do this with a delay to make sure the drawer is closed before unsetting the scorm
        setTimeout(() => {
            setScorm(undefined);
        }, 225);

        onClose?.();
    };

    const handleSubmit = async (values: TScormFormValues) => {
        const { fileUrl, zipFile, thumbnail, ...otherValues } = values;

        const newValues = {
            ...otherValues,
            scormZipId: zipFile?.id || undefined,
            thumbnailId: getImageId(initialValues.thumbnail, thumbnail),
        };

        try {
            await updateScorm({
                variables: {
                    id,
                    scorm: newValues,
                },
                update(cache) {
                    // Remove scorms list from cache so it will be refetched
                    cache.evict({ fieldName: 'scorms' });

                    // Use writeQuery to update scorm query cache so it's properly removed
                    cache.writeQuery({
                        query: ScormEditDocument,
                        data: { scorm: null },
                        variables: { id },
                    });

                    cache.gc();

                    // refetch detail query
                    refetch();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('scormActionSuccess.update'), {
            variant: 'success',
        });

        // Close drawer
        handleClose();

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteScorm({
                variables: { id },
                update: (cache) => {
                    // Remove scorms list from cache so it will be refetched
                    cache.evict({ fieldName: 'scorms' });

                    // Use writeQuery to update scorm query cache so it's properly removed
                    cache.writeQuery({
                        query: ScormEditDocument,
                        data: { scorm: null },
                        variables: { id },
                    });

                    cache.gc();
                },
            });
        } catch (error) {
            setShowDeleteAlert(false);
            showApolloError(error);

            return;
        }

        displaySnackbar(translate('moduleActionSuccess.scorm.delete'), {
            variant: 'success',
        });

        setShowDeleteAlert(false);

        // Close drawer
        handleClose();

        return;
    };

    const isMutating = loadingUpdate || loadingDelete;

    return (
        <PageDrawer
            disableClose={isMutating}
            open={open}
            onClose={handleClose}
            {...other}
        >
            <PageTitle>{translate('scormForm.pageTitle')}</PageTitle>

            <ScormForm
                edit
                initialValues={initialValues}
                scorm={scorm}
                onDelete={() => setShowDeleteAlert(true)}
                onSubmit={handleSubmit}
            />

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={loadingDelete}
                open={showDeleteAlert}
                title={translate('deleteScormMessage.title')}
                onCancel={() => {
                    setShowDeleteAlert(false);
                }}
                onClose={() => setShowDeleteAlert(false)}
                onConfirm={handleDelete}
            >
                {translate('deleteScormMessage.text')}
            </ConfirmDialog>
        </PageDrawer>
    );
};
