import { ICertificateFragment } from 'graphql/types';

export function isCertificateSelected(
    selectedCertificates: ICertificateFragment[],
    certificate: ICertificateFragment
) {
    return selectedCertificates.some(
        (selectedCertificate) => selectedCertificate.id === certificate.id
    );
}

export function isCertificateAdded(
    addedCertificates: ICertificateFragment[],
    certificate: ICertificateFragment
) {
    return addedCertificates.some(
        (addedCertificate) => addedCertificate.id === certificate.id
    );
}

export function getCertificateSelectStatus(
    selectedCertificates: ICertificateFragment[],
    addedCertificates: ICertificateFragment[],
    certificate: ICertificateFragment
) {
    const isSelected = isCertificateSelected(selectedCertificates, certificate);
    const isAdded = isCertificateAdded(addedCertificates, certificate);

    return { isSelected, isAdded };
}
