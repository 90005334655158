import { Box, BoxProps } from '@mui/material';

import { withSxProp } from 'common/utils/props';

interface IProps extends BoxProps {
    outlined?: boolean;
}

export const ProgressBox = ({ outlined, sx, ...other }: IProps) => (
    <Box
        sx={[
            {
                backgroundColor: 'white',
                border: outlined ? 1 : 0,
                borderColor: 'divider',
                borderRadius: 1,
                minHeight: 30,
                display: 'flex',
                justifyContent: 'center',
                py: outlined ? 0.5 : 0,
                px: outlined ? 1.5 : 0,
            },
            ...withSxProp(sx),
        ]}
        {...other}
    />
);
