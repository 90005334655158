import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OfferEventBasicListItem } from 'offer/components/OfferEventBasicListItem';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { OfferEventReportTable } from 'offer/components/OfferEventReportTable';
import {
    IBasicOfferEventListItemFragment,
    IContentTypeValue,
    usePortfolioReportQuery,
} from 'graphql/types';
import { PortfolioItemContentBase } from 'user/components/PortfolioItemDrawer/PortfolioItemContentBase';
import { OfferEventCertificateTable } from 'offer/components/OfferEventCertificateTable';
import { BoxLoader } from 'common/components/Loader';
import { Typography } from 'common/components/Typography';

export const OfferEventPortfolioItemContent = (
    props: React.ComponentProps<typeof PortfolioItemContentBase>
) => {
    const [translate] = useTranslation();

    const {
        portfolioItem: {
            id: portfolioItemId,
            relatedObject,
            user,
            permissions,
        },
    } = props;

    const { openGlobalDrawer } = useGlobalDrawer();

    const offerEvent = relatedObject as IBasicOfferEventListItemFragment;
    const offerEventPermissions = !!permissions
        ? JSON.parse(permissions)
        : undefined;

    const { data, loading } = usePortfolioReportQuery({
        variables: {
            relatedObjectOfferEventIds: [offerEvent.id],
            userIds: [user.id],
        },
        fetchPolicy: 'network-only',
    });

    const { canReadReport = false } = offerEventPermissions || {};

    const items =
        data?.portfolioReport?.edges
            ?.map((edge) => edge?.node)
            .filter(Boolean)
            .filter((item) => item?.id !== portfolioItemId) || [];

    const modules = items.filter(
        (item) => item.relatedType !== IContentTypeValue.ReceivedCertificate
    );

    const certificates = items.filter(
        (item) => item.relatedType === IContentTypeValue.ReceivedCertificate
    );

    return (
        <PortfolioItemContentBase {...props}>
            <Box sx={{ py: 2, px: 4 }}>
                <OfferEventBasicListItem
                    offerEvent={offerEvent}
                    onClick={() => {
                        openGlobalDrawer({
                            type: IGlobalDrawerType.OfferEvent,
                            itemId: offerEvent.id,
                        });
                    }}
                />
            </Box>

            {canReadReport && (
                <Box sx={{ pb: 6, pt: 2, px: 4 }}>
                    {!loading && !!modules.length && (
                        <OfferEventReportTable items={modules} />
                    )}

                    {!loading && !!certificates.length && (
                        <OfferEventCertificateTable items={certificates} />
                    )}

                    {loading && <BoxLoader />}

                    {!loading && !items.length && (
                        <Box sx={{ mt: 3.5 }}>
                            <Typography sx={{ mb: 0 }}>
                                {translate('report.noOfferEventReports')}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
        </PortfolioItemContentBase>
    );
};
