import { IScoring } from 'graphql/types';

export function getScoringDisplay(scoring?: IScoring[] | null) {
    return scoring
        ?.map((scoreItem) => {
            const {
                score,
                scoringSystem: { nameSingular, namePlural },
            } = scoreItem;

            const name = score === 1 ? nameSingular : namePlural;

            return `${score} ${name}`;
        })
        .join(', ');
}
