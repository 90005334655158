import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { UpgradePage } from 'common/components/UpgradePage';
import { Img } from 'common/components/Img';
import { BasePage } from 'hydra/pages/BasePage';
import UPGRADE_IMAGE from 'assets/images/upgrade-image-plan-trial-expired.webp';
import LI_DOT from 'assets/images/li-dot.webp';
import { IRole, useCurrentUserQuery } from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';

export const PlanExpiredPage = () => {
    const [translate] = useTranslation();
    const { data: currentUserData, loading: loadingUser } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};
    const { roles } = currentUser || {};
    const canSeePlanTrialExpiredPage =
        roles?.includes(IRole.Owner) || roles?.includes(IRole.Manager);

    if (loadingUser) return <Loader />;

    if (!canSeePlanTrialExpiredPage) return <FourOFourPage />;

    return (
        <BasePage contentContainerMaxWidth="lg" showNavigation={false}>
            <UpgradePage>
                <Box>
                    <PageTitle>
                        {translate('plans.planTrialExpired.pageTitle')}
                    </PageTitle>

                    <Grid
                        container
                        sx={{ flexWrap: 'wrap', alignItems: 'center', mt: 0.5 }}
                    >
                        <Grid item mb={2} md={6} sm={12} sx={{ pr: { md: 4 } }}>
                            <Typography
                                sx={{
                                    color: 'primary.main',
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                    fontSize: 14,
                                }}
                            >
                                {translate('plans.planTrialExpired.smallTitle')}
                            </Typography>

                            <Typography sx={{ mb: 2 }} variant="h1">
                                {translate('plans.planTrialExpired.title')}
                            </Typography>

                            <Typography lineHeight="1.6" sx={{ mb: 2 }}>
                                {translate('plans.planTrialExpired.text')}
                            </Typography>

                            <Typography fontWeight={700}>
                                {translate(
                                    'plans.planTrialExpired.advantagesText'
                                )}
                            </Typography>

                            <Box sx={{ mt: 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${LI_DOT})`,
                                            backgroundSize: '20px',
                                            backgroundRepeat: 'no-repeat',
                                            height: 20,
                                            width: 20,
                                            mr: 2,
                                            flexShrink: 0,
                                        }}
                                    />

                                    <Typography lineHeight="1.6">
                                        {translate(
                                            'plans.planTrialExpired.advantages.advantage1'
                                        )}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${LI_DOT})`,
                                            backgroundSize: '20px',
                                            backgroundRepeat: 'no-repeat',
                                            height: 20,
                                            width: 20,
                                            mr: 2,
                                            flexShrink: 0,
                                        }}
                                    />

                                    <Typography lineHeight="1.6">
                                        {translate(
                                            'plans.planTrialExpired.advantages.advantage2'
                                        )}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${LI_DOT})`,
                                            backgroundSize: '20px',
                                            backgroundRepeat: 'no-repeat',
                                            height: 20,
                                            width: 20,
                                            mr: 2,
                                            flexShrink: 0,
                                        }}
                                    />

                                    <Typography lineHeight="1.6">
                                        {translate(
                                            'plans.planTrialExpired.advantages.advantage3'
                                        )}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${LI_DOT})`,
                                            backgroundSize: '20px',
                                            backgroundRepeat: 'no-repeat',
                                            height: 20,
                                            width: 20,
                                            mr: 2,
                                            flexShrink: 0,
                                        }}
                                    />

                                    <Typography lineHeight="1.6">
                                        {translate(
                                            'plans.planTrialExpired.advantages.advantage4'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <Img src={UPGRADE_IMAGE} />
                        </Grid>
                    </Grid>
                </Box>
            </UpgradePage>
        </BasePage>
    );
};
