import { useApolloClient } from '@apollo/client';
import { forwardRef, useImperativeHandle } from 'react';

import {
    ITrainingMatrixModuleFragment,
    IPortfolioItemMatrixListItemFragment,
} from 'graphql/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { TableCell } from 'common/components/Table';
import { withSxProp } from 'common/utils/props';
import { PortfolioItemProgressScoreChip } from 'user/components/PortfolioItemProgressScoreChip';

interface IProps extends React.ComponentProps<typeof TableCell> {
    userId: string;
    canManageParticipant: boolean;
    report?: IPortfolioItemMatrixListItemFragment;
    trainingId: string;
    module: ITrainingMatrixModuleFragment;
    disableClick?: boolean;
    onClearCache?: () => void;
}

export type TMatrixCellRef = { handleClick: () => void };

export const TrainingParticipantReportMatrixCell = forwardRef(
    (
        {
            report,
            trainingId,
            module,
            userId,
            canManageParticipant,
            sx,
            disableClick,
            onClearCache,
            ...other
        }: IProps,
        ref: React.Ref<TMatrixCellRef>
    ) => {
        const { openGlobalDrawer, closeGlobalDrawer } = useGlobalDrawer();
        const client = useApolloClient();

        const { __typename: moduleTypeName, id: moduleId } = module;

        const { id: portfolioItemId } = report || {};

        // Allow clicking when we have a report or when module is an assignment
        const canClickCell =
            canManageParticipant &&
            (!!report || moduleTypeName === 'AssignmentModule');

        const clearCacheForReports = () => {
            client.cache.evict({
                id: `Training:${trainingId}`,
                fieldName: 'participants',
            });

            client.cache.evict({
                fieldName: 'trainingPortfolioReports',
            });

            client.cache.evict({
                id: `PortfolioItem:${portfolioItemId}`,
            });

            onClearCache?.();

            client.cache.gc();
        };

        const handleClick = () => {
            if (!canClickCell) return;

            if (moduleTypeName === 'AssignmentModule') {
                openGlobalDrawer({
                    type: IGlobalDrawerType.TrainingAssignmentGrade,
                    props: {
                        trainingId,
                        moduleId,
                        userId,
                        onClose: () => {
                            closeGlobalDrawer({
                                type: IGlobalDrawerType.TrainingAssignmentGrade,
                            });
                        },
                        onUpdateAssignment: clearCacheForReports,
                    },
                });

                return;
            }

            openGlobalDrawer({
                type: IGlobalDrawerType.PortfolioItem,
                itemId: portfolioItemId,
                props: {
                    onResetProgress: clearCacheForReports,
                    onClose: () => {
                        closeGlobalDrawer({
                            type: IGlobalDrawerType.PortfolioItem,
                            itemId: portfolioItemId,
                        });
                    },
                },
            });
        };

        useImperativeHandle(ref, () => ({
            handleClick,
        }));

        return (
            <TableCell
                sx={[
                    { cursor: canClickCell ? 'pointer' : undefined },
                    ...withSxProp(sx),
                ]}
                onClick={!disableClick ? handleClick : undefined}
                {...other}
            >
                {canManageParticipant && (
                    <PortfolioItemProgressScoreChip
                        hover={canClickCell}
                        portfolioItem={report}
                    />
                )}
            </TableCell>
        );
    }
);
