import { styled } from 'styled-components';

import { QuickAddForm } from 'user/components/QuickAddForm';
import { ICreatePortfolioItemInput } from 'graphql/types';

import { DevelopmentItemCheck } from '../DevelopmentItemCheck';

interface IProps {
    loading?: boolean;
    groupId: string;
    className?: string;
    onAdd(values: ICreatePortfolioItemInput): void;
}

const BaseDevelopmentItemQuickAdd = ({
    loading,
    groupId,
    className,
    onAdd,
}: IProps) => {
    const handleSave = async (title: string) => {
        if (!title) return;

        onAdd({ title, groupId });
    };

    return (
        <QuickAddForm
            className={className}
            disabled={loading}
            placeholderKey="developmentItemQuickAddPlaceholder"
            onSave={handleSave}
        >
            <DevelopmentItemCheck className="developmentItemCheck" />
        </QuickAddForm>
    );
};

export const DevelopmentItemQuickAdd = styled(BaseDevelopmentItemQuickAdd)`
    padding: ${({ theme }) => theme.spacing(1, 4)};
    grid-template-columns: min-content 1fr;

    ::placeholder {
        color: ${({ theme }) => theme.palette.grey[300]};
    }

    ${DevelopmentItemCheck} {
        margin-right: 0;
    }
`;
