import { ROUTES } from 'route/constants/routes';
import { useCurrentPath } from 'route/hooks/useCurrentPath';

/*
 * Shows if the current page is a detail (drawer) page
 */
export const useIsDetailPage = () => {
    const currentPath = useCurrentPath();

    if (!currentPath) return false;

    const drawerDetailRoutes = [
        // Training detail routes
        ROUTES.TRAINING_ASSIGNMENT,
        ROUTES.TRAINING_ASSIGNMENT_FOR_USER,
        ROUTES.TRAINING_ASSIGNMENT_GRADE,
        ROUTES.TRAINING_CERTIFICATE,
        ROUTES.TRAINING_CREATE_MODULE,
        ROUTES.TRAINING_CREATE_MODULE_TYPE,
        ROUTES.TRAINING_CREATE_MODULE_IN_GROUP,
        ROUTES.TRAINING_CREATE_MODULE_TYPE_IN_GROUP,
        ROUTES.TRAINING_UPDATE_MODULE,
        ROUTES.TRAINING_MODULE_SELECT,
        ROUTES.TRAINING_CREATE,
        ROUTES.TRAINING_EDIT,
        ROUTES.TRAINING_CREATE_MODULE_GROUP,
        ROUTES.TRAINING_EDIT_MODULE_GROUP,

        // Portfolio detail routes
        ROUTES.PORTFOLIO_ITEM_CREATE,
        ROUTES.PORTFOLIO_ITEM_EDIT,
        ROUTES.PORTFOLIO_ITEM_VIEW,
        ROUTES.PORTFOLIO_GROUP_CREATE,
        ROUTES.PORTFOLIO_GROUP_EDIT,
        ROUTES.USER_PORTFOLIO_ITEM_VIEW,

        // Development plan detail routes
        ROUTES.DEVELOPMENT_ITEM_CREATE,
        ROUTES.DEVELOPMENT_ITEM_EDIT,
        ROUTES.DEVELOPMENT_GROUP_CREATE,
        ROUTES.DEVELOPMENT_GROUP_EDIT,
        ROUTES.USER_DEVELOPMENT_ITEM_VIEW,

        // User detail routes
        ROUTES.PROFILE_DRAWER,

        // Library detail routes
        ROUTES.LIBRARY_COLLECTION_CREATE,
        ROUTES.LIBRARY_COLLECTION_EDIT,
        ROUTES.LIBRARY_COLLECTION_CREATE_ARTICLE,
        ROUTES.LIBRARY_ARTICLE_EDIT,

        // Offer detail routes
        ROUTES.OFFER_SETTINGS_EDIT,
        ROUTES.OFFER_CREATE,
        ROUTES.OFFER_EDIT,
        ROUTES.OFFER_DETAIL_DRAWER,
        ROUTES.OFFER_EVENT_DETAIL_DRAWER,
        ROUTES.CREATE_OFFER_EVENT,

        // Common routes
        ROUTES.INTRO,

        // Group detail routes
        ROUTES.GROUP_CREATE,
        ROUTES.GROUP_EDIT,

        // Course detail routes
        ROUTES.COURSE_EDIT,

        // Video detail routes
        ROUTES.VIDEO_EDIT,
    ];

    return drawerDetailRoutes.includes(currentPath.route.path);
};
