import { useTranslation } from 'react-i18next';
import { DateTime, Duration } from 'luxon';

import {
    IConditionFragment,
    IOpenOffsetType,
    ICourseConditionComparisonEnum,
} from 'graphql/types';
import {
    parseConditions,
    absoluteDateConditionsToString,
    deadlineDateConditionsToString,
} from 'common/utils/parseConditions';
import { ILinkModuleFormValues } from 'training/components/forms/LinkModuleForm';
import { Typography } from 'common/components/Typography';
import { capitalize } from 'common/utils/capitalize';
import { durationToHuman } from 'common/utils/formatDate';

interface IProps {
    offset: ILinkModuleFormValues['offset'];
    groupConditions: IConditionFragment[];
}

export const ModuleConditionDescription = ({
    offset,
    groupConditions,
}: IProps) => {
    const [translate] = useTranslation();

    const { absoluteDates, relativeDates, courseConditions, deadlineDates } =
        parseConditions(groupConditions);

    let conditionsArray: React.ReactNode[] = [];

    const { type, days, hours } = offset || {};

    // Check if we actually have an offset type
    const isOffset =
        type &&
        [IOpenOffsetType.Offset, IOpenOffsetType.OffsetNegative].includes(type);

    const isNegative = type === IOpenOffsetType.OffsetNegative;

    // Get duration from field values if type is offset, otherwise we use an empty duration.
    const offsetDuration = Duration.fromObject(
        isOffset
            ? {
                  hours: hours && hours >= 0 ? hours : 0,
                  days: days && days >= 0 ? days : 0,
              }
            : {}
    );
    const offsetValue = offsetDuration.valueOf();
    const hasOffset = offsetValue !== 0;

    // Absolute date conditions
    if (absoluteDates.length) {
        const newAbsoluteDates = absoluteDates.map((absoluteDate) => {
            const { startDateISO, endDateISO } = absoluteDate;

            if (startDateISO) {
                let newStartDate = DateTime.fromISO(startDateISO);

                if (isNegative) {
                    newStartDate = newStartDate.plus(offsetDuration);
                } else {
                    newStartDate = newStartDate.minus(offsetDuration);
                }
                absoluteDate['startDateISO'] =
                    newStartDate.toISO() || undefined;
            }

            if (endDateISO) {
                let newEndDate = DateTime.fromISO(endDateISO);

                if (isNegative) {
                    newEndDate = newEndDate.plus(offsetDuration);
                } else {
                    newEndDate = newEndDate.minus(offsetDuration);
                }
                absoluteDate['endDateISO'] = newEndDate.toISO() || undefined;
            }

            return absoluteDate;
        });

        conditionsArray = [
            ...conditionsArray,
            absoluteDateConditionsToString(newAbsoluteDates),
        ];
    }

    // Relative date conditions
    if (relativeDates.length) {
        const relativeDatesString = relativeDates
            .map((date) => {
                const { totalSeconds } = date;

                let dateDuration = Duration.fromObject({
                    seconds: totalSeconds,
                });

                if (isNegative) {
                    dateDuration = dateDuration.plus(offsetDuration);
                } else {
                    dateDuration = dateDuration.minus(offsetDuration);
                }

                const dateDurationValue = dateDuration.valueOf();

                let offsetText = 'conditionString.afterStartingDate';

                // Negate value if it's negative and change offset text to before
                if (dateDurationValue < 0) {
                    dateDuration = dateDuration.negate();
                    offsetText = 'conditionString.beforeStartingDate';
                }
                dateDuration = dateDuration.shiftTo('weeks', 'days', 'hours');

                // If Duration value is 0 we want to show a different text
                if (dateDurationValue === 0) {
                    offsetText = 'conditionString.onStartingDate';
                }

                return `${durationToHuman(dateDuration)} ${translate(
                    offsetText
                )}`;
            })
            .join(', ')
            .trim();

        conditionsArray = [...conditionsArray, relativeDatesString];
    }

    // Course conditions
    if (courseConditions.length) {
        const courseConditionString = courseConditions
            .map((courseCondition) => {
                const courseArray = [];
                const { module, scoreThreshold, preDelay, comparison } =
                    courseCondition;

                const title = module.title;

                if (preDelay) {
                    const { totalSeconds } = preDelay;
                    const preDelayDuration = Duration.fromObject({
                        seconds: totalSeconds,
                    })
                        .plus(offsetDuration)
                        .shiftTo('weeks', 'days', 'hours');

                    courseArray.push(durationToHuman(preDelayDuration));
                } else if (!hasOffset) {
                    courseArray.push(offsetDuration.toHuman());
                }

                courseArray.push(
                    translate('conditionString.clearCourse', {
                        courseTitle: title,
                    })
                );

                if (scoreThreshold !== undefined && scoreThreshold >= 0) {
                    const score = Math.round(scoreThreshold * 100);
                    // Get comparison string < or > based on the type
                    const compare =
                        comparison === ICourseConditionComparisonEnum.Min
                            ? translate('conditionString.comparisonGt')
                            : translate('conditionString.comparisonLt');

                    courseArray.push(
                        translate('conditionString.withScore', {
                            comparison: compare,
                            score,
                        })
                    );
                }

                return courseArray.join(' ');
            })
            .join(', ')
            .trim();

        conditionsArray = [...conditionsArray, courseConditionString];
    }

    // Deadline date conditions
    if (deadlineDates.length) {
        conditionsArray = [
            ...conditionsArray,
            deadlineDateConditionsToString(deadlineDates),
        ];
    }

    return (
        <>
            <Typography sx={{ fontWeight: 'bold' }}>
                {/* Change title of the description only if we have an actual offset value */}
                {!hasOffset
                    ? translate('conditionOfSection')
                    : translate('conditionOfModule')}
            </Typography>
            {capitalize(conditionsArray.join(', '))}
        </>
    );
};
