import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { ApolloError } from '@apollo/client';

import { PageDrawer } from 'common/components/PageDrawer';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageTitle } from 'common/components/PageTitle';
import {
    IAppPermissionsSkill,
    useDeleteSkillsMutation,
    useSkillQuery,
    useUpdateSkillMutation,
} from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { SkillForm } from 'skill/components/SkillForm';
import { TSkillFormValues } from 'skill/types';
import { getImageId } from 'common/utils/image';
import { Loader } from 'common/components/Loader';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { useAppPermissions } from 'user/hooks/usePermissions';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { ERROR_CODES } from 'common/constants/errorCodes';

interface IProps extends IPageDrawerProps {
    skillId?: string;
    onClose?(): void;
}

export const SkillUpdateDrawer = ({ open, skillId, onClose }: IProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { canUpdate = false }: IAppPermissionsSkill['management'] =
        useAppPermissions('skill', 'management');

    const { skillId: skillIdParam } = useParams<{ skillId?: string }>();

    const id = skillId || skillIdParam;

    const { data: skillData, loading: skillLoading } = useSkillQuery({
        skip: !open || !id || !canUpdate,
        variables: { id },
    });
    const [updateSkill, { loading: updateSkillLoading }] =
        useUpdateSkillMutation();
    const [deleteSkills, { loading: deleteSkillsLoading }] =
        useDeleteSkillsMutation();

    if (!canUpdate) return <FourOFourPage />;

    const { name, shortName, description, groups, certificates, thumbnail } =
        skillData?.skill || {};

    const handleClose = () => {
        if (!!onClose) return onClose();

        const searchParams = new URLSearchParams(location.search);
        const url = `${getUrl('SKILL_MANAGEMENT_LIST')}?${searchParams}`;

        navigate(url);
    };

    const handleSubmit = async (values: TSkillFormValues) => {
        const {
            groups,
            certificates,
            thumbnail: newThumbnail,
            ...otherValues
        } = values;

        const groupIds = groups?.map((group) => group.id);
        const certificateIds = certificates?.map(
            (certificate) => certificate.id
        );

        try {
            await updateSkill({
                variables: {
                    id,
                    skill: {
                        groupIds,
                        certificateIds,
                        thumbnailId: getImageId(thumbnail, newThumbnail),
                        ...otherValues,
                    },
                },
                update: (cache) => {
                    cache.evict({ fieldName: 'skills' });
                },
            });
        } catch (error) {
            if (error instanceof ApolloError) {
                for (const err of error.graphQLErrors) {
                    const { code, ids } = err?.extensions || {};

                    if (!code) {
                        showApolloError(error);

                        return;
                    }

                    if (code === ERROR_CODES.GROUPS_NOT_ADDED_TO_SKILL) {
                        const groupNames = ids
                            ?.map((id: string) => {
                                const group = groups?.find(
                                    (group) => group.id === id
                                );

                                return group?.name;
                            })
                            .join(', ');

                        displaySnackbar(
                            translate('skillForm.groupsNotAdded', {
                                groupNames,
                                count: ids?.length,
                            }),
                            {
                                variant: 'error',
                            }
                        );
                    }

                    if (code === ERROR_CODES.CERTIFICATES_NOT_ADDED_TO_SKILL) {
                        const certificateNames = ids
                            ?.map((id: string) => {
                                const certificate = certificates?.find(
                                    (certificate) => certificate.id === id
                                );

                                return certificate?.name;
                            })
                            .join(', ');

                        displaySnackbar(
                            translate('skillForm.certificatesNotAdded', {
                                certificateNames,
                                count: ids?.length,
                            }),
                            {
                                variant: 'error',
                            }
                        );
                    }
                }
            }

            return;
        }

        handleClose();

        displaySnackbar(
            translate('skillsManagementPage.actionSuccess.update'),
            {
                variant: 'success',
            }
        );

        return;
    };

    const handleDelete = async () => {
        try {
            await deleteSkills({
                variables: { ids: [id] },
                update: (cache) => {
                    cache.evict({ fieldName: 'skills' });
                },
            });
        } catch (error) {
            showApolloError(error);
        }

        setShowDeleteDialog(false);

        handleClose();
    };

    const initialValues: TSkillFormValues = {
        name: name || '',
        shortName: shortName || '',
        description: description || '',
        groups: groups || [],
        certificates: certificates || [],
        thumbnail: thumbnail || undefined,
    };

    const loading = skillLoading || updateSkillLoading;

    return (
        <PageDrawer open={open} onClose={handleClose}>
            <PageTitle>
                {`${name} - ${translate('skillForm.editSkill')}`}
            </PageTitle>

            <Box sx={{ p: { xs: 2, sm: 4 }, pt: { xs: 0, sm: 0 } }}>
                {loading && <Loader />}

                {!loading && (
                    <SkillForm
                        isEdit
                        disabled={loading}
                        initialValues={initialValues}
                        onDelete={() => setShowDeleteDialog(true)}
                        onSubmit={handleSubmit}
                    />
                )}
            </Box>

            <ConfirmDialog
                confirmText={translate('delete')}
                loading={deleteSkillsLoading}
                open={showDeleteDialog}
                title={translate('deleteSkillMessage.title', { count: 1 })}
                onCancel={() => {
                    setShowDeleteDialog(false);
                }}
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={handleDelete}
            >
                {translate('deleteSkillMessage.text', { count: 1 })}
            </ConfirmDialog>
        </PageDrawer>
    );
};
