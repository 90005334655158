import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { PageDrawer } from 'common/components/PageDrawer';
import {
    IOfferEventListItemFragment,
    IOfferEventType,
    OfferEventsDocument,
    useCreateOfferEventMutation,
} from 'graphql/types';
import { EOfferEventCategoryType, TOfferFormValues } from 'offer/types';
import { ApolloError } from 'common/components/ApolloError';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { goToOfferDetail } from 'offer/utils/goToRoutes';
import { PageTitle } from 'common/components/PageTitle';
import { useMixpanel } from 'common/hooks/useMixpanel';
import { MIXPANEL_EVENT_NAMES } from 'common/constants/mixpanel';
import { OfferEventForm } from 'offer/components/forms/OfferEventForm';

interface IProps {
    offerEventType: IOfferEventType;
    open?: boolean;
    onClose?(): void;
}

export const CreateOfferEventDrawer = ({
    offerEventType,
    open,
    onClose,
}: IProps) => {
    const { t: translate } = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const navigate = useNavigate();
    const { trackMixpanel } = useMixpanel();
    const { id: offerId } = useParams();

    const [createOfferEvent, { loading, error }] =
        useCreateOfferEventMutation();

    const handleSubmit = async (values: TOfferFormValues) => {
        if (!offerId) return;

        const {
            minSubscriptions,
            maxSubscriptions,
            type: offerEventType,
            offerEventTitle,
            certificate,
            ...otherValues
        } = values.event;
        const offerEventValues = {
            ...otherValues,
            minSubscriptions: minSubscriptions ? +minSubscriptions : undefined,
            maxSubscriptions: maxSubscriptions ? +maxSubscriptions : undefined,
            type: offerEventType,
            title: offerEventTitle,
            certificateId: certificate?.id,
        };

        await createOfferEvent({
            variables: {
                offerId: offerId,
                offerEvent: offerEventValues,
            },
            update: (cache, mutationResult) => {
                const { data: updatedDevelopmentItemData } = mutationResult;

                const offerEvent =
                    updatedDevelopmentItemData?.createOfferEvent?.offerEvent;

                if (!offerEvent) return;

                const offerEventsData: {
                    offerEvents: IOfferEventListItemFragment[] | null;
                } | null = cache.readQuery({
                    query: OfferEventsDocument,
                    variables: {
                        offerId: offerEvent.offer.id,
                    },
                });

                const { offerEvents } = offerEventsData || {};

                if (!offerEvents) return;

                cache.writeQuery({
                    query: OfferEventsDocument,
                    variables: {
                        offerId: offerEvent.offer.id,
                    },
                    data: {
                        offerEvents: [...offerEvents, offerEvent],
                    },
                });

                cache.evict({ fieldName: 'offerEvents' });
                cache.evict({ fieldName: 'offers' });
            },
        });

        const eventType = values.event.type;

        await trackMixpanel({
            eventName: MIXPANEL_EVENT_NAMES.offers[eventType].offerEvent.create,
        });

        displaySnackbar(translate('offerEventActionSuccess.create'), {
            variant: 'success',
        });

        goToOfferDetail(navigate, offerId);

        return;
    };

    const isMeetingCreatePage =
        offerEventType === IOfferEventType.Meeting ||
        offerEventType === IOfferEventType.Webinar;

    return (
        <PageDrawer
            disableClose={loading}
            open={open}
            onClose={loading ? undefined : onClose}
        >
            <PageTitle
                mixpanelTitle={MIXPANEL_EVENT_NAMES.offers.offerEvent.create}
            >
                {`${translate('offers')} - ${translate('offer.newEvent')}`}
            </PageTitle>

            {error && <ApolloError error={error} />}

            <OfferEventForm
                disabled={loading}
                offerEventCategoryType={
                    isMeetingCreatePage
                        ? EOfferEventCategoryType.Meeting
                        : EOfferEventCategoryType.Offer
                }
                offerEventType={offerEventType}
                onSubmit={handleSubmit}
            />
        </PageDrawer>
    );
};
