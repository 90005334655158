import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Box,
    DialogContentText,
    Table,
    TableContainer,
    TableBody,
    TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiPlus } from '@mdi/js';

import {
    TableActionBar,
    TableCell,
    TableHead,
    useSelection,
} from 'common/components/Table';
import { ICustomFontFragment, IFileFragment } from 'graphql/types';
import { FilePicker } from 'common/components/FilePicker';
import { Typography } from 'common/components/Typography';
import { useUploadFiles } from 'common/hooks/useUploadFiles';
import { DEFAULT_MAX_FILE_SIZE } from 'common/constants/files';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { AlertDialog } from 'common/components/AlertDialog';
import { Button } from 'common/components/Button';
import { Icon } from 'common/components/Icon';
import { Tooltip } from 'common/components/Tooltip';
import { ActionButton } from 'common/components/ActionButton';
import { Checkbox } from 'common/components/Checkbox';

import { UploadingFileRows } from './UploadingFileRows';
import { TableRows } from './TableRows';

interface IProps {
    maxFileSize?: number;
    title?: string;
    id: string; // Use an unique id when you have multiple file uploads on the same page
    customFonts: ICustomFontFragment[];
    onAdd?: (uploadedFile: IFileFragment) => void;
    renderSelectedActions?: (
        showDeleteDialog: () => void,
        hasSelection: boolean
    ) => React.ReactNode;
    onRemove?: (
        fontIds: string[],
        filesUpdateCallback: (fileIds: string[]) => void
    ) => void;
    onUploadFinish?: (filesUpdateCallback: (fileIds: string[]) => void) => void;
}

export const CustomFontTable = ({
    maxFileSize,
    title,
    id,
    customFonts,
    onRemove,
    onAdd,
    onUploadFinish,
    renderSelectedActions,
}: IProps) => {
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();
    const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);

    const customFontFiles = useMemo(
        () => customFonts.map((customFont) => customFont.file),
        [customFonts]
    );

    // Keep value of the item where an action (e.g. delete) is applied to
    const currentActionIdRef = useRef<string>();

    const { isUploading, uploadFiles, uploadingFiles, setUploadingFiles } =
        useUploadFiles({
            onSuccess: (_fileIndex, updatedFile) => {
                onAdd && onAdd(updatedFile);
            },
            onError: (fileName, message) => {
                displaySnackbar(`${fileName}: ${message}`, {
                    autoHideDuration: 30000,
                });
            },
        });
    const prevIsUploading = useRef(isUploading);
    const [actionsDisabled, setActionsDisabled] = useState(false);

    const { ...selectionProps } = useSelection(customFontFiles);

    const { rows, selected, onSelectAll, isSelected } = selectionProps;

    // Callback used to indicate that the file updates have been completed
    const filesUpdateCallback = useCallback(() => {
        setUploadingFiles([]);

        setActionsDisabled(false);
    }, [setUploadingFiles]);

    useEffect(() => {
        const { current: prevUploading } = prevIsUploading;

        if (isUploading === prevUploading) return;

        prevIsUploading.current = isUploading;

        if (!isUploading && onUploadFinish) {
            onUploadFinish(filesUpdateCallback);

            return;
        }

        setActionsDisabled(true);
    }, [isUploading, onUploadFinish, filesUpdateCallback]);

    const handleUpload = (files: File[]) => {
        uploadFiles(files, maxFileSize || DEFAULT_MAX_FILE_SIZE);
    };

    const handleRemove = () => {
        if (!onRemove) return;

        const fontIds = currentActionIdRef.current
            ? [currentActionIdRef.current]
            : selected
                  .map((fileId) => {
                      const customFont = customFonts.find(
                          (customFont) => customFont.file.id === fileId
                      );

                      if (customFont) return customFont.id;

                      return null;
                  })
                  .filter(Boolean);

        setActionsDisabled(true);

        // Deselect all
        if (!!selected.length) onSelectAll();

        onRemove(fontIds, filesUpdateCallback);

        setShowDeleteAlertDialog(false);

        // Reset currentActionId after calling onRemove
        currentActionIdRef.current = undefined;
    };

    const heading = (
        <Box alignItems="center" display="flex" minHeight="33px">
            {!!title && (
                <Box>
                    <Typography variant="h4">{translate(title)}</Typography>
                </Box>
            )}

            <Tooltip title={translate<string>('uploadFile')}>
                <Box ml={1}>
                    <FilePicker
                        disabled={!onAdd}
                        id={id}
                        inputProps={{ multiple: true }}
                        onUpload={handleUpload}
                    >
                        <ActionButton outlined disabled={!onAdd} size="medium">
                            <Icon path={mdiPlus} />
                        </ActionButton>
                    </FilePicker>
                </Box>
            </Tooltip>
        </Box>
    );

    const hasFiles = !!(uploadingFiles.length || !!rows.length);

    const tableActions = (
        <>
            {renderSelectedActions &&
                renderSelectedActions(
                    () => setShowDeleteAlertDialog(true),
                    !!selected.length
                )}
        </>
    );

    return (
        <Box pb={3}>
            <Box position="relative" py={1}>
                {heading}
            </Box>
            {hasFiles && (
                <TableContainer>
                    <TableActionBar
                        inDrawer
                        actions={tableActions}
                        selectionProps={selectionProps}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={false}
                                        disabled={isSelected}
                                        onChange={onSelectAll}
                                    />
                                </TableCell>
                                <TableCell>{translate('name')}</TableCell>

                                <TableCell>{translate('style')}</TableCell>

                                <TableCell>{translate('weight')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <UploadingFileRows
                                customFontFiles={customFontFiles}
                                uploadingFiles={uploadingFiles}
                            />

                            <TableRows
                                actionsDisabled={actionsDisabled}
                                customFonts={customFonts}
                                rows={rows}
                                onClickRemove={(id: string) => {
                                    currentActionIdRef.current = id;

                                    setShowDeleteAlertDialog(true);
                                }}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <AlertDialog
                actions={
                    <>
                        <Button
                            autoFocus
                            color="error"
                            variant="contained"
                            onClick={handleRemove}
                        >
                            {translate('delete')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowDeleteAlertDialog(false);
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                    </>
                }
                open={showDeleteAlertDialog}
                title={translate('customFontDrawer.deleteMessage.title')}
            >
                <DialogContentText color="text.primary" variant="body2">
                    {translate('customFontDrawer.deleteMessage.text')}
                </DialogContentText>
            </AlertDialog>
        </Box>
    );
};
