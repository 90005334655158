import * as React from 'react';
import {
    Autocomplete as MuiAutocomplete,
    autocompleteClasses,
    Box,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Autocomplete } from 'formik-mui';

import { Typography } from 'common/components/Typography';
import { TOption } from 'common/types';

const Row = ({ data, index, style }: ListChildComponentProps) => {
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style?.top ? +style.top + 8 : 8, // 8 for default padding
    };

    return (
        <Typography
            noWrap
            component="li"
            sx={{
                ':hover': {
                    backgroundColor: 'grey.100',
                },
            }}
            {...dataSet[0]}
            style={inlineStyle}
        >
            {dataSet[1]}
        </Typography>
    );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef(
    (props, ref: React.ForwardedRef<HTMLDivElement>) => {
        const outerProps = React.useContext(OuterElementContext);

        return <Box ref={ref} {...props} {...outerProps} />;
    }
);

function useResetCache(data: number) {
    const ref = React.useRef<VariableSizeList<React.ReactNode[]>>(null);

    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);

    return ref;
}

interface IListboxComponent {
    children?: React.ReactNode;
}

const ListboxComponent = React.forwardRef(
    ({ children, ...other }: IListboxComponent, _ref) => {
        const itemData: React.ReactNode[] = [];

        React.Children.forEach(children, (child) => {
            if (!React.isValidElement(child)) return null;

            // child.props is the option component of the autocomplete
            // child.props.children are the children inside the option
            itemData.push([child.props, child.props.children]);
        });

        const itemCount = itemData.length || 0;
        const itemSize = 40;

        const getChildSize = () => itemSize;

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }

            return itemData.map(getChildSize).reduce((a, b) => a + b, 0) || 0;
        };

        const gridRef = useResetCache(itemCount);

        return (
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    height={getHeight()}
                    innerElementType="ul"
                    itemCount={itemCount}
                    itemData={itemData}
                    itemSize={() => itemSize}
                    outerElementType={OuterElementType}
                    overscanCount={5}
                    ref={gridRef}
                    width="100%"
                >
                    {Row}
                </VariableSizeList>
            </OuterElementContext.Provider>
        );
    }
);

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

interface IProps {
    label?: string;
    options: TOption[];
}

export const VirtualizedAutocomplete = ({
    options,
    label,
    ...other
}: React.ComponentProps<typeof MuiAutocomplete> & IProps) => (
    <Autocomplete
        {...other}
        disableListWrap
        // @ts-expect-error
        ListboxComponent={ListboxComponent}
        options={options}
        PopperComponent={StyledPopper}
    />
);
