import { useTranslation } from 'react-i18next';
import { Box, MenuItem, ButtonGroup } from '@mui/material';
import { mdiMenuDown } from '@mdi/js';

import { ICourseListItemFragment } from 'graphql/types';
import { PageDrawer } from 'common/components/PageDrawer';
import { Typography } from 'common/components/Typography';
import { Divider } from 'common/components/Divider';
import { Button } from 'common/components/Button';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';
import COURSE_MODULE_DRAWER_HEADER from 'assets/images/course-module-drawer-header.webp';
import { CourseFilterAddList } from 'training/components/CourseFilterAddList';
import { Loader } from 'common/components/Loader';
import { CourseCreateAction } from 'training/actions/CourseCreateAction';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { Icon } from 'common/components/Icon';

interface IProps {
    edit?: boolean;
    open?: boolean;
    inDrawer?: boolean;
    onClose?(): void;
    onSelect?(course: ICourseListItemFragment): void;
}

export const CourseModuleSelectDrawer = ({
    edit,
    inDrawer = true,
    open,
    onClose,
    onSelect,
}: IProps) => {
    const [translate] = useTranslation();

    const titleCreate = translate(
        `moduleForm.course.${
            edit ? 'editTitleCourseCreate' : 'newTitleCourseCreate'
        }`
    );
    const textCreate = translate(
        `moduleForm.course.${
            edit ? 'editTextCourseCreate' : 'newTextCourseCreate'
        }`
    );

    const titleSelect = translate(
        `moduleForm.course.${
            edit ? 'editTitleCourseSelect' : 'newTitleCourseSelect'
        }`
    );
    const textSelect = translate(
        `moduleForm.course.${
            edit ? 'editTextCourseSelect' : 'newTextCourseSelect'
        }`
    );

    const handleCourseReturnUrl = (courseId: string) =>
        `${window.location.href.split('#')[0]}?course=${courseId}`;

    const drawerContent = open ? (
        <>
            <DrawerHeaderImage src={COURSE_MODULE_DRAWER_HEADER} />

            <Box p={{ xs: 2, sm: 4 }}>
                <Box mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleCreate}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textCreate}</Typography>
                    </Box>
                    <Box mt={2}>
                        <CourseCreateAction
                            onCourseReturnUrl={handleCourseReturnUrl}
                        >
                            {({ openCreateModal, createCourse }) => (
                                <ButtonGroup variant="contained">
                                    <Button
                                        color="primary"
                                        onClick={openCreateModal}
                                    >
                                        {translate(
                                            'moduleForm.course.createNewCourseButton'
                                        )}
                                    </Button>
                                    <DropdownMenu
                                        anchor={
                                            <Button
                                                color="primary"
                                                size="small"
                                                sx={{ px: 1 }}
                                            >
                                                <Icon
                                                    path={mdiMenuDown}
                                                    size="2.4rem"
                                                />
                                            </Button>
                                        }
                                        placement="bottom-end"
                                    >
                                        <MenuItem
                                            onClick={() => createCourse()}
                                        >
                                            {translate(
                                                'moduleForm.course.createEmptyCourse'
                                            )}
                                        </MenuItem>
                                        <MenuItem onClick={openCreateModal}>
                                            {translate(
                                                'moduleForm.course.createCourseFromTemplate'
                                            )}
                                        </MenuItem>
                                    </DropdownMenu>
                                </ButtonGroup>
                            )}
                        </CourseCreateAction>
                    </Box>
                </Box>

                <Divider />

                <Box my={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3">
                        {titleSelect}
                    </Typography>
                    <Box mt={1}>
                        <Typography>{textSelect}</Typography>
                    </Box>
                </Box>

                <CourseFilterAddList
                    listItemProps={{ px: 0, showActions: true }}
                    selectMultiple={false}
                    onSelect={onSelect}
                />
            </Box>
        </>
    ) : (
        <Loader />
    );

    if (!inDrawer) return drawerContent;

    return (
        <PageDrawer open={open} onClose={onClose}>
            {drawerContent}
        </PageDrawer>
    );
};
