import { useMemo, useState } from 'react';

import { useApolloError } from 'common/hooks/useApolloError';
import { TQueryParams } from 'common/utils/getSearchUrl';
import { useExtraUserFieldsQuery } from 'graphql/types';
import { getExtraUserFieldsFilters } from 'user/hooks/extraUserFieldsFilters';
import {
    getExtraFieldsQueryParams,
    getSelectedExtraFieldsFilters,
} from 'common/utils/extraFields';
import { ISearchProps, TFilterBarItem } from 'common/types';

export const useTrainerSearch = (): ISearchProps => {
    const { showApolloError } = useApolloError();
    const [searchQueryParam, setSearchQueryParam] = useState('');
    const [queryParams, setQueryParams] = useState<TQueryParams>({});

    const { data: extraUserFieldsData, loading } = useExtraUserFieldsQuery({
        onError: showApolloError,
    });

    // Build a list of filters
    const filters = useMemo(
        () =>
            getExtraUserFieldsFilters(
                extraUserFieldsData?.extraUserFields || []
            ),
        [extraUserFieldsData]
    );

    const selectedFilters = getSelectedExtraFieldsFilters(queryParams, filters);

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        const queryParams = getExtraFieldsQueryParams(selected);

        setQueryParams(queryParams);
    };

    return {
        filters,
        searchQueryParam: searchQueryParam || '',
        selectedFilters,
        filtersLoading: loading,
        handleSearch,
        handleFilterSelect,
    };
};
