import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typography } from 'common/components/Typography';
import {
    useTokenLoginMutation,
    useResendUserEmailMutation,
    ITokenState,
    IUserEmailType,
    ITokenUserFragment,
} from 'graphql/types';
import { LoaderOverlay } from 'common/components/Loader';
import { Button } from 'common/components/Button';
import { Link } from 'common/components/Link';
import { PageTitle } from 'common/components/PageTitle';

interface IProps {
    forPage: 'resetPassword' | 'welcome';
    children(user: ITokenUserFragment): React.ReactNode;
}

export const TokenLogin = ({ forPage, children }: IProps) => {
    const { t: translate, i18n } = useTranslation();

    const { token } = useParams();

    const [tokenLogin, { data: tokenResponse, loading: loadingLogin, client }] =
        useTokenLoginMutation({
            onCompleted: (data) => {
                if (data.tokenLogin.state !== ITokenState.Valid) return;

                // Reset store after token login has completed and token is valid
                client.resetStore();
            },
        });

    const [resendUserEmail, { data: resendEmailData, loading: sendingEmail }] =
        useResendUserEmailMutation();

    useEffect(() => {
        if (!token) return;

        tokenLogin({ variables: { token } });
    }, [token, tokenLogin]);

    const { user: tokenUser, state: tokenState } =
        tokenResponse?.tokenLogin || {};
    const tokenIsValid = tokenState === ITokenState.Valid;
    const tokenIsInvalid = tokenState === ITokenState.Invalid;
    const tokenIsExpired = tokenState === ITokenState.Expired;

    const userLanguage = tokenUser?.language;

    useEffect(() => {
        if (!userLanguage) return;

        i18n.changeLanguage(userLanguage);
    }, [userLanguage, i18n]);

    const mailSent = resendEmailData?.resendUserEmail.ok || false;

    let transKey: string;
    let emailType: IUserEmailType;

    switch (forPage) {
        case 'resetPassword':
            transKey = 'tokenLogin.resetPassword';
            emailType = IUserEmailType.ResetPassword;

            break;
        case 'welcome':
            transKey = 'tokenLogin.welcome';
            emailType = IUserEmailType.Welcome;

            break;
    }

    return (
        <>
            <LoaderOverlay visible={loadingLogin}>
                {translate('loadingTexts.tokenLogin')}
            </LoaderOverlay>

            {!loadingLogin && tokenState && (
                <>
                    {/* Render children when token is valid */}
                    {tokenIsValid && tokenUser && children(tokenUser)}

                    {tokenIsInvalid && (
                        <>
                            <PageTitle mixpanelTitle="Link is not valid">
                                {translate(`${transKey}.tokenInvalidPageTitle`)}
                            </PageTitle>
                            <Box mb={2} textAlign="center">
                                <Typography variant="h2">
                                    {translate(`${transKey}.tokenInvalidTitle`)}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={4}>
                                <Button
                                    color="primary"
                                    component={Link}
                                    to="LOGIN"
                                    variant="contained"
                                >
                                    {translate('gotoLogin')}
                                </Button>
                            </Box>
                        </>
                    )}

                    {tokenIsExpired && (
                        <>
                            <PageTitle mixpanelTitle="Link has expired">
                                {translate(`${transKey}.tokenExpiredPageTitle`)}
                            </PageTitle>
                            <Box mb={2} textAlign="center">
                                <Typography variant="h2">
                                    {translate(`${transKey}.tokenExpiredTitle`)}
                                </Typography>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="center"
                                mt={4}
                            >
                                <Button
                                    color="primary"
                                    disabled={mailSent}
                                    loading={sendingEmail}
                                    variant="contained"
                                    onClick={() => {
                                        resendUserEmail({
                                            variables: {
                                                token: token || '',
                                                emailType,
                                            },
                                        });
                                    }}
                                >
                                    {mailSent
                                        ? translate('emailSent')
                                        : translate('sendNewLink')}
                                </Button>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );
};
