import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Accordion } from 'common/components/Accordion';
import {
    IOfferEventListItemFragment,
    IOfferEventType,
    useOfferEventsQuery,
} from 'graphql/types';
import { Loader } from 'common/components/Loader/Loader';
import { ApolloError } from 'common/components/ApolloError';
import { Typography } from 'common/components/Typography';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';
import { OfferEventList } from 'offer/components/OfferEventList';

interface IProps {
    offerId: string;
    expired?: boolean;
    setFirstOfferEventType?: (offerEventType: IOfferEventType) => void;
}

export const OfferEventsBox = ({
    expired,
    offerId,
    setFirstOfferEventType,
}: IProps) => {
    const location = useLocation();
    const { t: translate } = useTranslation();
    const { openGlobalDrawer } = useGlobalDrawer();
    const { data, loading, error } = useOfferEventsQuery({
        variables: { offerId, expired },
    });

    const offerEvents = data?.offerEvents || [];

    useEffect(() => {
        if (loading || !offerEvents.length) return;

        setFirstOfferEventType?.(offerEvents[0].type);
    }, [offerEvents]);

    const handleClick = (offerEvent: IOfferEventListItemFragment) => {
        openGlobalDrawer(
            {
                type: IGlobalDrawerType.OfferEvent,
                itemId: offerEvent.id,
            },
            location
        );
    };

    return (
        <>
            {error && <ApolloError error={error} />}

            {loading ? (
                <Loader />
            ) : (
                <>
                    {offerEvents.length ? (
                        <Accordion expanded>
                            <OfferEventList
                                items={offerEvents || []}
                                onClick={handleClick}
                            />
                        </Accordion>
                    ) : (
                        <Box display="flex" justifyContent="center" mt={8}>
                            <Typography>
                                {translate('noResults.offerEvents')}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
