import { SxProps } from '@mui/material';

import { Typography } from 'common/components/Typography';

/**
 * Component to display large amount of text.
 */
const textStyle: SxProps = {
    // Add newlines when linebreaks are used
    whiteSpace: 'pre-line',
    // Remove margin top from all paragraphs
    '& p': { mt: 0 },
    // Remove margin from last child
    '& > :last-child': { mb: 0 },
    // Less spacing between paragraph and list
    'p + ul': { mt: -1.5 },
    // Less spacing for list items
    ul: { paddingLeft: '25px' },
};

export const Text = (props: React.ComponentProps<typeof Typography>) => (
    <Typography sx={textStyle} {...props} />
);
