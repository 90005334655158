import { Box, BoxProps, SxProps } from '@mui/material';
import { mdiClose } from '@mdi/js';

import { IconButton } from 'common/components/IconButton';
import { withSxProp } from 'common/utils/props';
import { Text } from 'common/components/Text';

const descriptionBoxStyle: SxProps = {
    position: 'relative',
    my: 2,
    p: 4,
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1,
    backgroundColor: 'grey.50',
};

interface IProps extends BoxProps {
    children?: React.ReactNode;
    open?: boolean;
    onClose?(): void;
    sx?: SxProps;
}

export const DescriptionBox = ({
    children,
    open,
    onClose,
    sx,
    ...other
}: IProps) => {
    if (!open && !!onClose) return null;

    return (
        <Box sx={[descriptionBoxStyle, ...withSxProp(sx)]} {...other}>
            {onClose && (
                <Box position="absolute" right={3} top={3}>
                    <IconButton
                        color="inherit"
                        iconPath={mdiClose}
                        iconSize="2.4rem"
                        size="large"
                        onClick={onClose}
                    />
                </Box>
            )}

            <Text component="div">{children}</Text>
        </Box>
    );
};
