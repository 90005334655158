import { Chip, IChipProps } from 'common/components/Chip';
import {
    IContentTypeValue,
    IPortfolioItemListItem,
    IPortfolioItemScoreTypeChoices,
    Maybe,
} from 'graphql/types';
import { formatGrade } from 'training/utils/formatGrade';
import { CircularProgress } from 'common/components/CircularProgress';

interface IProps {
    portfolioItem?: Maybe<
        Pick<
            IPortfolioItemListItem,
            | 'relatedType'
            | 'score'
            | 'scoreType'
            | 'scoreThreshold'
            | 'scoreMax'
            | 'progress'
            | 'endDate'
            | 'completionDate'
            | 'relatedObjectHasComments'
            | 'relatedObjectHasFiles'
        >
    >;
    hover?: boolean;
}

export const PortfolioItemProgressScoreChip = ({
    portfolioItem,
    hover,
}: IProps) => {
    const {
        relatedType,
        score,
        scoreType,
        scoreThreshold,
        scoreMax,
        progress,
        endDate,
        completionDate,
        relatedObjectHasComments,
        relatedObjectHasFiles,
    } = portfolioItem || {};

    const showDot = !!(relatedObjectHasComments || relatedObjectHasFiles);

    if (!portfolioItem) {
        return (
            <Chip
                hover={hover}
                showDot={showDot}
                sx={{ backgroundColor: 'grey.200' }}
            />
        );
    }

    let scoreValue: React.ReactNode;
    const scoreChipProps: IChipProps = {};

    if (relatedType === IContentTypeValue.ReceivedCertificate) {
        const isExpired = !endDate ? false : new Date(endDate) < new Date();

        return (
            <Chip
                color={!isExpired ? 'success' : 'error'}
                hover={hover}
                showDot={showDot}
            />
        );
    }

    // If progress is not 1 and we don't have a completion date, show a circular progress icon
    if (progress !== 1 && !completionDate) {
        return (
            <CircularProgress
                filled
                showTooltip
                bgColor="grey.200"
                color="inherit"
                hover={hover}
                showDot={showDot}
                size={28}
                value={Math.round((progress || 0) * 100)}
                variant="determinate"
            />
        );
    }

    if (score !== undefined && score !== null) {
        const showScoreValue =
            score !== 0 &&
            scoreType !== null &&
            scoreType !== IPortfolioItemScoreTypeChoices.Fulfilled;

        if (!showScoreValue) {
            scoreValue = undefined;
        } else if (relatedType === IContentTypeValue.Assignment) {
            scoreValue = formatGrade(score * 10);
        } else {
            scoreValue = `${Math.round(score * 100)}%`;
        }

        let chipColor: IChipProps['color'];

        // Check score color based on if we have a scoreThreshold, scoreMax or score
        if (scoreThreshold) {
            chipColor = score >= scoreThreshold ? 'success' : 'error';
        } else if (scoreMax === 0) {
            chipColor = 'success';
        } else {
            chipColor = score ? 'success' : 'error';
        }

        scoreChipProps.color = chipColor;
    } else {
        // If scoreType is not set, we show a dark grey chip
        scoreChipProps.sx = {
            backgroundColor: !scoreType ? 'grey.600' : 'grey.200',
        };
    }

    return (
        <Chip
            {...scoreChipProps}
            hover={hover}
            label={scoreValue}
            showDot={!scoreValue && showDot}
        />
    );
};
