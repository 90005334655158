import { Typography } from '@mui/material';

interface IProps {
    children: React.ReactNode;
}

export const CardSubtitle = (props: IProps) => (
    <Typography
        sx={{
            color: 'text.secondary',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
        }}
        variant="body2"
        {...props}
    />
);
