import { Box, SxProps } from '@mui/material';

import { LinearProgress } from 'common/components/LinearProgress';

const cardProgressStyle: SxProps = {
    p: 3,
    pt: 0,
    '.MuiCardMedia-root + &': { pt: 3 },
};

export const CardProgress = (
    props: React.ComponentProps<typeof LinearProgress>
) => (
    <Box sx={cardProgressStyle}>
        <LinearProgress {...props} />
    </Box>
);
