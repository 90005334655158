import { Field, Form, Formik, FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box } from '@mui/material';

import { TextField } from 'common/components/FormField';
import { Button } from 'common/components/Button';
import { UnsavedChangesAlert } from 'common/components/Alerts';
import { ExitPageAlert } from 'common/components/ExitPageAlert';
import { TPortfolioItemFormValues } from 'user/types';

interface IProps extends FormikConfig<TPortfolioItemFormValues> {
    disabled?: boolean;
}

export const RelatedItemPortfolioItemForm = ({
    disabled,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const validationSchema = Yup.object().shape({
        description: Yup.string().optional(),
    });

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            {...other}
        >
            {({ submitForm, isSubmitting, dirty }) => (
                <Box sx={{ p: 4 }}>
                    <ExitPageAlert
                        alert={UnsavedChangesAlert}
                        when={dirty && !isSubmitting}
                        onConfirm={submitForm}
                    />

                    <Form>
                        <Field
                            multiline
                            component={TextField}
                            label={translate('relatedPortfolioItemDescription')}
                            margin="none"
                            name="description"
                            sx={{ mt: 1 }}
                        />

                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <Button
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    disabled ||
                                    (!dirty && !isSubmitting && !disabled)
                                }
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    e.preventDefault();

                                    submitForm();
                                }}
                            >
                                {translate('save')}
                            </Button>
                        </Box>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};
