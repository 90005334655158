import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { DeleteButton } from 'common/components/Button/DeleteButton';
import {
    IOfferEventDetailFragment,
    IOfferEventType,
    useOfferEventUnenrollCurrentUserMutation,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

interface IProps {
    offerEvent: IOfferEventDetailFragment;
    onClose?: () => void;
}

export const UnsubscribeSection = ({ offerEvent, onClose }: IProps) => {
    const [translate] = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { showApolloError } = useApolloError();

    const [unenrollCurrentUser, { loading: unenrollCurrentUserLoading }] =
        useOfferEventUnenrollCurrentUserMutation();

    const isAccessContent =
        offerEvent.type === IOfferEventType.Training ||
        offerEvent.type === IOfferEventType.Elearning ||
        offerEvent.type === IOfferEventType.Course;

    const handleUnsubscribe = async () => {
        if (!offerEvent) return;

        try {
            await unenrollCurrentUser({
                variables: {
                    offerEventId: offerEvent.id,
                },
                update: (cache) => {
                    cache.evict({
                        id: `OfferEvent:${offerEvent.id}`,
                        broadcast: true,
                    });
                    cache.evict({ fieldName: 'offerEvents' });
                    cache.evict({ fieldName: 'paginatedOfferEvents' });

                    cache.gc();
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        setDialogOpen(false);
        onClose?.();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <DeleteButton
                sx={{ ml: 'auto' }}
                onClick={() => setDialogOpen(true)}
            >
                {translate('unregister')}
            </DeleteButton>

            <ConfirmDialog
                confirmText={translate(
                    `offerEventUnsubscribeDialog.${
                        isAccessContent ? 'buttonTextAccess' : 'buttonText'
                    }`
                )}
                loading={unenrollCurrentUserLoading}
                open={dialogOpen}
                title={translate(
                    `offerEventUnsubscribeDialog.${
                        isAccessContent ? 'titleAccess' : 'title'
                    }`
                )}
                onCancel={() => setDialogOpen(false)}
                onClose={() => setDialogOpen(false)}
                onConfirm={() => handleUnsubscribe?.()}
            >
                {translate(
                    `offerEventUnsubscribeDialog.${
                        isAccessContent ? 'textAccess' : 'text'
                    }`
                )}
            </ConfirmDialog>
        </Box>
    );
};
