import { useState } from 'react';
import { styled } from 'styled-components';
import { ListItem, Typography } from '@mui/material';

import {
    IDevelopmentItemListItemFragment,
    useUpdateDevelopmentItemMutation,
} from 'graphql/types';
import { ApolloError } from 'common/components/ApolloError';

import { DevelopmentItemCheck } from '../DevelopmentItemCheck';

interface IProps extends IDevelopmentItemListItemFragment {
    className?: string;
    withEditActions?: boolean;
    isHidden?: boolean;
    onClick?(): void;
}

const BaseDevelopmentListItem = ({
    className,
    title,
    withEditActions,
    checked,
    id,
    onClick,
}: IProps) => {
    const [active, setActive] = useState(checked);

    const [updateItem, { error }] = useUpdateDevelopmentItemMutation({
        onError: () => setActive(!active),
    });

    const toggleItemState = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        // Set new active state before mutation to prevent lag. If the mutation fails it will reset the
        // active state to its previous state.
        setActive(!active);

        updateItem({
            variables: {
                id,
                developmentItem: {
                    checked: !active,
                },
            },
        });
    };

    return (
        <ListItem button className={className} onClick={onClick}>
            {error && <ApolloError error={error} />}

            <DevelopmentItemCheck
                checked={active}
                onClick={withEditActions ? toggleItemState : undefined}
            />

            <Typography>{title}</Typography>
        </ListItem>
    );
};

export const DevelopmentListItem = styled(BaseDevelopmentListItem)`
    display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
    align-items: center;
    padding: ${({ theme }) => theme.spacing(1, 2)};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme }) => theme.spacing(1, 4)};
    }
`;
