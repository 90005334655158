import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { usePagination } from 'common/hooks/usePagination';
import { PageDrawerFooter } from 'common/components/PageDrawer/PageDrawerFooter';
import { FilterBar } from 'common/components/FilterBar';
import { Loader } from 'common/components/Loader';
import { ICertificateTypeListItemFragment } from 'graphql/types';
import { Button } from 'common/components/Button';
import { IPageDrawerProps } from 'common/components/PageDrawer/PageDrawer';
import { PageDrawer } from 'common/components/PageDrawer';
import { Pagination } from 'common/components/Pagination';
import { useCertificateSearch } from 'training/hooks/useCertificateSearch';
import { useCertificateQueryVariables } from 'training/hooks/useCertificateQueryVariables';
import { useCertificateList } from 'training/hooks/useCertificateList';

import { CertificateAddList } from './CertificateAddList';

interface IProps extends IPageDrawerProps {
    addedCertificates: ICertificateTypeListItemFragment[];
    selectedCertificates: ICertificateTypeListItemFragment[];
    onAddCertificates(certificates?: ICertificateTypeListItemFragment[]): void;
    onRemoveCertificate?(
        selectedCertificate: ICertificateTypeListItemFragment
    ): void;
    onClose?(): void;
    onSelectCertificate?(certificate: ICertificateTypeListItemFragment): void;
    setSelectedCertificates?(
        certificates: ICertificateTypeListItemFragment[]
    ): void;
}

export const CertificateSelectDrawer = ({
    addedCertificates,
    selectedCertificates,
    open,
    onAddCertificates,
    onRemoveCertificate,
    onClose,
    onSelectCertificate,
    setSelectedCertificates,
}: IProps) => {
    const [translate] = useTranslation();

    const { searchQueryParam, handleSearch: onSearch } = useCertificateSearch();
    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { certificateQueryVariables } = useCertificateQueryVariables({
        paginationVariables,
        searchQueryParam,
    });

    const {
        certificates,
        certificatesCount,
        loading: certificatesLoading,
    } = useCertificateList(certificateQueryVariables);

    const resetSettings = () => {
        handleSearch('');

        setPage(1);
    };

    useEffect(() => {
        if (certificatesLoading || !initializePagination) return;

        initializePagination(certificatesCount || 0);
    }, [certificatesCount, certificatesLoading, initializePagination]);

    const handleSearch = (searchValue: string) => {
        setPage(1);
        onSearch?.(searchValue);
    };

    return (
        <PageDrawer
            open={open}
            title={translate('certificateSelector.title')}
            onClose={() => {
                onClose?.();

                // When selection is changed but drawer is closed, it needs to reset the selected certificates
                // else they are added, but not selected anymore
                setSelectedCertificates?.(addedCertificates);

                resetSettings();
            }}
        >
            <Box
                sx={{
                    pb: 22.5,
                    pt: { xs: 2, sm: 4 },
                    px: { xs: 2, sm: 4 },
                }}
            >
                <FilterBar
                    initialSearchValue={searchQueryParam}
                    placeholder={translate('filterBarPlaceholder')}
                    onSearch={handleSearch}
                    onSearchClear={() => handleSearch('')}
                />

                {certificatesLoading && <Loader />}

                {open && !certificatesLoading && (
                    <CertificateAddList
                        certificates={certificates}
                        selectedCertificates={selectedCertificates}
                        onAddCertificate={onSelectCertificate}
                        onRemoveCertificate={onRemoveCertificate}
                    />
                )}
            </Box>

            <PageDrawerFooter>
                <Box display="flex" flexDirection="column">
                    {!certificatesLoading && paginationSettings.count > -1 && (
                        <Box mb={2}>
                            <Pagination
                                page={paginationSettings.page}
                                pageAmount={paginationSettings.pageAmount}
                                totalsAmount={paginationSettings.count}
                                totalsText={translate('certificate', {
                                    count:
                                        paginationSettings.count >= 0
                                            ? paginationSettings.count
                                            : 0,
                                })}
                                onChange={(page: number) => {
                                    setPage(page);
                                }}
                            />
                        </Box>
                    )}

                    <Button
                        color="primary"
                        disabled={!selectedCertificates.length}
                        variant="contained"
                        onClick={() => {
                            onAddCertificates(selectedCertificates);
                            resetSettings();
                        }}
                    >
                        {!selectedCertificates.length
                            ? translate('certificateSelector.title')
                            : translate('certificateSelector.addButtonText', {
                                  count: selectedCertificates.length,
                              })}
                    </Button>
                </Box>
            </PageDrawerFooter>
        </PageDrawer>
    );
};
