import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Divider } from 'common/components/Divider';
import { InfoRow } from 'common/components/InfoRow';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { LinearProgress } from 'common/components/LinearProgress';
import { Chip } from 'common/components/Chip';
import {
    formatDate,
    timeDeltaToDuration,
    durationToHuman,
} from 'common/utils/formatDate';
import {
    IContentTypeValue,
    IPortfolioItemFragment,
    IRole,
    useCurrentUserQuery,
} from 'graphql/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { Link } from 'common/components/Link';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { IGlobalDrawerType } from 'common/types';

interface IProps {
    portfolioItem: IPortfolioItemFragment;
    showDescription?: boolean;
    userId?: string;
}

export const PortfolioItemDetailsView = ({
    portfolioItem,
    showDescription,
    userId,
}: IProps) => {
    const [translate] = useTranslation();
    const { data: currentUserData } = useCurrentUserQuery();
    const portfolioItemViewProfile = !!useRouteMatch('PORTFOLIO_ITEM_VIEW');
    const { openGlobalDrawer, closeGlobalDrawer } = useGlobalDrawer();

    const { currentUser } = currentUserData || {};
    const currentUserIsOwner =
        portfolioItemViewProfile ||
        (!portfolioItemViewProfile && userId === currentUser?.id);
    const currentUserIsManager = currentUser?.roles?.includes(IRole.Manager);

    const {
        title,
        startDate,
        completionDate,
        endDate,
        metadata,
        teacher,
        scoring,
        description,
        score,
        timeSpent,
        sessions,
        progress,
        scoreThreshold,
        relatedType,
    } = portfolioItem || {};

    const { courseVersionNumber, invitationSentAt, addedToTrainingVia } =
        metadata || {};

    const { groups, offerEvents, directly } = addedToTrainingVia || {};

    let addedVia = directly
        ? [<Box key="directly">{translate('addedDirectly')}</Box>]
        : [];

    if (!!groups?.length) {
        addedVia = [
            ...addedVia,
            ...groups.map((group, index) => (
                <Box
                    component={currentUserIsManager ? Link : undefined}
                    key={group.id}
                    sx={{
                        display: currentUserIsManager ? 'block' : undefined,
                        cursor: currentUserIsManager ? 'pointer' : undefined,
                        mt: !addedVia.length && index === 0 ? 0 : 0.5,
                    }}
                    onClick={
                        currentUserIsManager
                            ? () => {
                                  openGlobalDrawer({
                                      type: IGlobalDrawerType.Group,
                                      itemId: group.id,
                                      props: {
                                          onClose: () =>
                                              closeGlobalDrawer({
                                                  type: IGlobalDrawerType.Group,
                                              }),
                                      },
                                  });
                              }
                            : undefined
                    }
                >
                    {group.name}
                </Box>
            )),
        ];
    }

    if (!!offerEvents?.length) {
        addedVia = [
            ...addedVia,
            ...offerEvents
                .filter(({ offer }) => !!offer)
                .map(({ id, offer }, index) => (
                    <Box
                        component={currentUserIsManager ? Link : undefined}
                        key={offer.id}
                        sx={{
                            display: currentUserIsManager ? 'block' : undefined,
                            cursor: currentUserIsManager
                                ? 'pointer'
                                : undefined,
                            mt: !addedVia.length && index === 0 ? 0 : 0.5,
                        }}
                        onClick={
                            currentUserIsManager
                                ? () => {
                                      openGlobalDrawer({
                                          type: IGlobalDrawerType.OfferEvent,
                                          itemId: id,
                                          props: {
                                              onClose: () =>
                                                  closeGlobalDrawer({
                                                      type: IGlobalDrawerType.OfferEvent,
                                                  }),
                                          },
                                      });
                                  }
                                : undefined
                        }
                    >
                        {offer.title}
                    </Box>
                )),
        ];
    }

    const timeSpentDuration =
        timeSpent && timeSpent.totalSeconds !== 0
            ? timeDeltaToDuration(timeSpent).shiftTo(
                  'days',
                  'hours',
                  'minutes',
                  'seconds'
              )
            : undefined;

    const showScore = progress === 1 && score;

    const scoreChip = showScore ? (
        <Chip
            color={score >= (scoreThreshold || 0) ? 'success' : 'error'}
            label={`${Math.round(score * 100)}%`}
        />
    ) : null;

    const startDateDisplay = startDate
        ? formatDate(startDate, 'dd MMMM yyyy HH:mm')
        : '';
    const endDateDisplay = endDate
        ? formatDate(endDate, 'dd MMMM yyyy HH:mm')
        : '';
    const completionDateDisplay = completionDate
        ? formatDate(completionDate, 'dd MMMM yyyy HH:mm')
        : '';

    const scores = scoring?.map((scoreItem) => {
        const {
            score,
            scoringSystem: { nameSingular, namePlural },
        } = scoreItem;

        return {
            score,
            nameSingular,
            namePlural,
        };
    });

    const isCertificate = relatedType === IContentTypeValue.ReceivedCertificate;
    const startDateTransKey = isCertificate ? 'portfolioStartDate' : 'start';

    const showProgress =
        progress !== undefined &&
        progress !== null &&
        (progress !== 1 || !completionDate) &&
        !isCertificate;

    return (
        <>
            <Box p={4}>
                <Typography sx={{ mb: 3 }} variant="h2">
                    {title}
                </Typography>

                <Stack spacing={2}>
                    {!!scoreChip && (
                        <InfoRow description={translate('score')}>
                            {scoreChip}
                        </InfoRow>
                    )}

                    {showProgress && (
                        <InfoRow description={translate('progress')}>
                            <Box sx={{ maxWidth: '200px' }}>
                                <LinearProgress
                                    withProgressText
                                    color="primary"
                                    value={progress * 100}
                                />
                            </Box>
                        </InfoRow>
                    )}

                    {startDateDisplay && (
                        <InfoRow description={translate(startDateTransKey)}>
                            {startDateDisplay}
                        </InfoRow>
                    )}

                    {completionDateDisplay && (
                        <InfoRow description={translate('fulfilledOn')}>
                            {completionDateDisplay}
                        </InfoRow>
                    )}

                    {endDateDisplay && (
                        <InfoRow description={translate('portfolioEndDate')}>
                            {endDateDisplay}
                        </InfoRow>
                    )}

                    {courseVersionNumber && (
                        <InfoRow description={translate('courseVersion')}>
                            {courseVersionNumber}
                        </InfoRow>
                    )}

                    {invitationSentAt && (
                        <InfoRow description={translate('invitationSentAt')}>
                            {formatDate(
                                invitationSentAt,
                                'dd MMMM yyyy, HH:mm'
                            )}
                        </InfoRow>
                    )}

                    {!!addedVia.length && (
                        <InfoRow description={translate('addedVia')}>
                            {addedVia}
                        </InfoRow>
                    )}

                    {teacher && (
                        <InfoRow description={translate('by')}>
                            {teacher}
                        </InfoRow>
                    )}

                    {!!timeSpentDuration && (
                        <InfoRow description={translate('time')}>
                            {durationToHuman(timeSpentDuration)}
                        </InfoRow>
                    )}

                    {!!sessions?.length && (
                        <InfoRow description={translate('sessions')}>
                            <Stack spacing={1}>
                                {sessions.map((session, index) => (
                                    <Box key={index}>
                                        {formatDate(
                                            session.startDate,
                                            'd MMM yyyy HH:mm'
                                        )}{' '}
                                        -
                                        {formatDate(
                                            session.endDate,
                                            'd MMM yyyy HH:mm'
                                        )}
                                    </Box>
                                ))}
                            </Stack>
                        </InfoRow>
                    )}

                    {!!scoring?.length && (
                        <InfoRow description={translate('portfolioPoints')}>
                            {scores
                                .filter((scoreItem) => scoreItem.score)
                                .map((scoreItem, index) => {
                                    const { score, nameSingular, namePlural } =
                                        scoreItem;

                                    const name =
                                        score === 1 ? nameSingular : namePlural;

                                    return `${score} ${name}${
                                        index === scores.length - 1 ? '' : ', '
                                    }`;
                                })}
                        </InfoRow>
                    )}
                </Stack>
            </Box>

            {showDescription && !currentUserIsOwner && description && (
                <>
                    <Divider />

                    <Box p={4}>
                        <Text component="div">
                            <Box sx={{ lineHeight: '1.6' }}>{description}</Box>
                        </Text>
                    </Box>
                </>
            )}
        </>
    );
};
