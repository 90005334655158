import { useTranslation } from 'react-i18next';

import { useSendEmailMutation, IEmailTypeEnum } from 'graphql/types';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { useApolloError } from 'common/hooks/useApolloError';
import { ConfirmDialog } from 'common/components/ConfirmDialog';

interface IProps {
    trainingId: string;
    open: boolean;
    userIds: string[];
    onClose(): void;
    onComplete?: () => void;
}

export const TrainingInviteEmailDialog = ({
    userIds,
    trainingId,
    open,
    onClose,
    onComplete,
}: IProps) => {
    const [translate] = useTranslation();

    const [displaySnackbar] = useSnackbar();
    const { showApolloError } = useApolloError();

    const [sendEmail, { loading: loadingSendEmail }] = useSendEmailMutation();

    const count = userIds.length;

    const handleSendEmail = async () => {
        try {
            await sendEmail({
                variables: {
                    emailType: IEmailTypeEnum.TrainingInvite,
                    userIds,
                    trainingId,
                },
            });
        } catch (error) {
            showApolloError(error);

            return;
        }

        displaySnackbar(
            translate('trainingInviteEmail.trainingInviteEmailSent', {
                count,
            }),
            {
                variant: 'success',
            }
        );

        onComplete?.();

        onClose();
    };

    return (
        <ConfirmDialog
            confirmText={translate('trainingInviteEmail.dialog.buttonText', {
                count,
            })}
            loading={loadingSendEmail}
            open={open}
            title={translate('trainingInviteEmail.dialog.title', {
                count,
            })}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={handleSendEmail}
        >
            {translate('trainingInviteEmail.dialog.text', {
                count,
            })}
        </ConfirmDialog>
    );
};
