import { useEffect, useMemo } from 'react';

import { useSkillFilter } from 'skill/hooks/useSkillFilter';
import { ISkillListItemFragment, useSkillsQuery } from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { usePagination } from 'common/hooks/usePagination';

export const useSkillList = (filterParamsInUrl = true) => {
    const { showApolloError } = useApolloError();

    const {
        filterValues,
        filterValuesForQuery,
        handleSearch: onSearch,
    } = useSkillFilter(filterParamsInUrl);

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    // Build variables for query
    const variables = useMemo(
        () => ({
            ...paginationVariables,
            ...filterValuesForQuery,
        }),
        [paginationVariables, filterValuesForQuery]
    );

    const { loading: skillsLoading, data: skillsData } = useSkillsQuery({
        variables,
        onError: showApolloError,
    });

    const skillsObject = skillsData?.skills;
    const skillsCount = skillsObject?.count;
    const skills =
        (skillsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(Boolean) as ISkillListItemFragment[]) || [];

    const loading = skillsLoading;

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(skillsCount || 0);
    }, [skillsCount, loading, initializePagination]);

    return {
        skills,
        skillsCount,
        loading,
        paginationSettings,
        filterValues,
        onSearch,
        setPage,
    };
};
