import { useCurrentUserQuery } from 'graphql/types';

/**
 * Hook to check if current user is the user with the given id
 *
 * @param id for user you want to check if is current user
 * @returns { isCurrentUser: boolean, loading: boolean }
 */
export const useIsCurrentUser = (userId?: string) => {
    const { data: currentUserData, loading } = useCurrentUserQuery({
        skip: !userId,
    });

    if (!userId) {
        return { isCurrentUser: false, loading: false };
    }

    const { currentUser } = currentUserData || {};
    const isCurrentUser = !!currentUser && currentUser.id === userId;

    return { isCurrentUser, currentUser, loading };
};
