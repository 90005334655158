import { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RouteLink } from 'route/components/RouteLink';
import { Tab, Tabs } from 'common/components/Tabs';
import { TTabsMenuItem } from 'common/types';
import { useRouteMatch } from 'route/hooks/useRouteMatch';

export const LtiDeepLinkTabsMenu = () => {
    const [translate, { language }] = useTranslation();
    const [loaded, setLoaded] = useState(!('fonts' in document));
    const { launchId } = useParams();
    const isTrainingsTab = !!useRouteMatch('CONNECTIONS_LTI_TRAININGS');

    const activeTab = isTrainingsTab ? 'trainings' : 'courses';

    useLayoutEffect(() => {
        document.fonts.ready.then(() => {
            setLoaded(true);
        });
    }, []);

    const tabs: TTabsMenuItem[] = [
        {
            label: translate('LtiDeepLinkTabs.trainings'),
            to: 'CONNECTIONS_LTI_TRAININGS',
            params: { launchId },
            value: 'trainings',
        },
        {
            label: translate('LtiDeepLinkTabs.courses'),
            to: 'CONNECTIONS_LTI_COURSES',
            params: { launchId },
            value: 'courses',
        },
    ];

    return (
        <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={`${loaded}-${language}-${activeTab}`}
            variant="scrollable"
        >
            {tabs.map((tabItem) => (
                <Tab
                    {...tabItem}
                    component={RouteLink}
                    key={tabItem.value}
                    value={`${loaded}-${language}-${tabItem.value}`}
                />
            ))}
        </Tabs>
    );
};
