import { styled } from 'styled-components';
import { IconButton } from '@mui/material';
import { Icon } from '@mdi/react';
import { mdiCheckCircle, mdiCheckCircleOutline } from '@mdi/js';

interface IProps {
    checked?: boolean;
    className?: string;
    isHidden?: boolean;
    onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const BaseDevelopmentItemCheck = ({ className, checked, onClick }: IProps) => (
    <IconButton
        className={`${className} ${checked && 'checked'}`}
        size="large"
        onClick={onClick}
    >
        <Icon
            path={checked ? mdiCheckCircle : mdiCheckCircleOutline}
            size="2.4rem"
        />
    </IconButton>
);

export const DevelopmentItemCheck = styled(BaseDevelopmentItemCheck)`
    padding: 0;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
    margin-right: ${({ theme }) => theme.spacing(2)};

    &:hover {
        background: none;
    }

    color: ${({ theme }) => theme.palette.grey[300]};

    &.checked {
        color: ${({ theme }) => theme.palette.primary.main};
    }
`;
