import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiChevronRight, mdiPencil } from '@mdi/js';

import { IModuleWeight } from 'graphql/types';
import { Icon } from 'common/components/Icon';
import { IconButton } from 'common/components/IconButton';
import { Chip, ChipGroup } from 'common/components/Chip';
import { PortfolioItemProgressScoreChip } from 'user/components/PortfolioItemProgressScoreChip';
import { Tooltip } from 'common/components/Tooltip';
import { getModuleWeightValue } from 'training/utils/module';
import {
    ListItemActionText,
    ListItemSecondaryAction,
} from 'common/components/ListItem';

interface IProps {
    portfolioItem?: React.ComponentProps<
        typeof PortfolioItemProgressScoreChip
    >['portfolioItem'];
    hasProgress?: boolean;
    weight?: IModuleWeight;
    forUpdate?: boolean;
    actionText?: string;
    loading?: boolean;
}

export const ModuleSecondaryAction = ({
    portfolioItem,
    weight,
    hasProgress,
    forUpdate,
    actionText,
    loading,
}: IProps) => {
    const [translate] = useTranslation();

    if (!hasProgress) {
        if (loading) {
            return <CircularProgress color="inherit" size={20} />;
        }

        return (
            <>
                {actionText && (
                    <ListItemActionText>{actionText}</ListItemActionText>
                )}

                <ListItemSecondaryAction hideXs sx={{ mr: -2 }}>
                    {forUpdate ? (
                        <IconButton
                            iconPath={mdiPencil}
                            iconSize="2.4rem"
                            size="large"
                        />
                    ) : (
                        <Icon path={mdiChevronRight} size="4rem" />
                    )}
                </ListItemSecondaryAction>
            </>
        );
    }

    return (
        <ChipGroup>
            {/* Only show weight it's not 1 */}
            {!!weight && weight !== IModuleWeight.Weight1 && (
                <Tooltip title={translate<string>('weightOfScore')}>
                    <Box>
                        <Chip label={`${getModuleWeightValue(weight)}x`} />
                    </Box>
                </Tooltip>
            )}
            <PortfolioItemProgressScoreChip portfolioItem={portfolioItem} />
        </ChipGroup>
    );
};
