import { IFontSize, IFontWeight } from 'graphql/types';
import { translate } from 'utils/i18n';

export const FONT_WEIGHT_OPTIONS = [
    { value: IFontWeight.Thin, label: translate('fonts.weights.thin') },
    {
        value: IFontWeight.ExtraLight,
        label: translate('fonts.weights.extraLight'),
    },
    {
        value: IFontWeight.Light,
        label: translate('fonts.weights.light'),
    },
    {
        value: IFontWeight.Normal,
        label: translate('fonts.weights.normal'),
    },
    {
        value: IFontWeight.Medium,
        label: translate('fonts.weights.medium'),
    },
    {
        value: IFontWeight.SemiBold,
        label: translate('fonts.weights.semiBold'),
    },
    {
        value: IFontWeight.Bold,
        label: translate('fonts.weights.bold'),
    },
    {
        value: IFontWeight.ExtraBold,
        label: translate('fonts.weights.extraBold'),
    },
    {
        value: IFontWeight.Black,
        label: translate('fonts.weights.black'),
    },
];

export const FONT_WEIGHTS: { [key: string]: IFontWeight } = {
    '100': IFontWeight.Thin,
    '200': IFontWeight.ExtraLight,
    '300': IFontWeight.Light,
    '400': IFontWeight.Normal,
    '500': IFontWeight.Medium,
    '600': IFontWeight.SemiBold,
    '700': IFontWeight.Bold,
    '800': IFontWeight.ExtraBold,
    '900': IFontWeight.Black,
};

export const DEFAULT_FONT = {
    fontFamily: 'Lato',
    size: IFontSize.Md,
    weight: IFontWeight.Normal,
};
