import { styled } from 'styled-components';
import { mdiPencil } from '@mdi/js';

import { Accordion, AccordionSummary } from 'common/components/Accordion';
import { PortfolioItemList } from 'user/components/PortfolioItemList';
import { CustomPortfolioItemQuickAdd } from 'user/components/CustomPortfolioItemQuickAdd';
import { IconButton } from 'common/components/IconButton';
import { Link } from 'common/components/Link';
import { IPortfolioGroup } from 'user/types';
import {
    ICreatePortfolioItemInput,
    IPortfolioItemFragment,
} from 'graphql/types';

interface IProps extends IPortfolioGroup {
    className?: string;
    withEditActions?: boolean;
    expanded?: boolean;
    loading?: boolean;
    onClick?: () => void;
    onClickCustomItem(item?: IPortfolioItemFragment): void;
    onEdit(): void;
    onQuickAddCustomItem(values: ICreatePortfolioItemInput): void;
}

const BasePortfolioItemGroup = ({
    className,
    portfolioItems,
    withEditActions,
    title,
    id,
    description,
    expanded,
    loading,
    onClick,
    onClickCustomItem,
    onEdit,
    onQuickAddCustomItem,
}: IProps) => {
    let handleEdit;

    if (withEditActions)
        handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            onEdit && onEdit();
        };

    return (
        <Accordion
            className={className}
            details={description}
            expanded={expanded}
            summary={
                <AccordionSummary
                    actions={
                        withEditActions && (
                            <IconButton
                                color="inherit"
                                component={Link}
                                iconPath={mdiPencil}
                                iconSize="2.4rem"
                                size="large"
                                onClick={handleEdit}
                            />
                        )
                    }
                    title={title}
                />
            }
            onChange={onClick}
        >
            <PortfolioItemList
                groupId={id}
                items={portfolioItems}
                withEditActions={withEditActions}
                onClickCustomItem={onClickCustomItem}
            />

            {withEditActions && (
                <CustomPortfolioItemQuickAdd
                    groupId={id}
                    loading={loading}
                    onAdd={onQuickAddCustomItem}
                />
            )}
        </Accordion>
    );
};

export const PortfolioItemGroup = styled(BasePortfolioItemGroup)`
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;
