import { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RouteLink } from 'route/components/RouteLink';
import { Tab, Tabs } from 'common/components/Tabs';
import { TTabsMenuItem } from 'common/types';
import { useUserRoutes } from 'user/hooks';

interface IProps {
    tabs: TTabsMenuItem[];
}

export const UserTabsMenu = ({ tabs }: IProps) => {
    const [_translate, { language }] = useTranslation();
    const [loaded, setLoaded] = useState(!('fonts' in document));
    const { activeSectionRoute } = useUserRoutes();

    useLayoutEffect(() => {
        document.fonts.ready.then(() => {
            setLoaded(true);
        });
    }, []);

    return (
        <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={`${loaded}-${language}-${activeSectionRoute}`}
            variant="scrollable"
        >
            {tabs.map((tabItem) => (
                <Tab
                    {...tabItem}
                    component={RouteLink}
                    key={tabItem.value}
                    value={`${loaded}-${language}-${tabItem.value}`}
                />
            ))}
        </Tabs>
    );
};
