import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableRow } from '@mui/material';

import {
    IVideoListItemFragment,
    useUpdatedVideoSubscription,
    IVideoStatus,
} from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { TableCell, TableImage } from 'common/components/Table';
import { getVideoStatusChip } from 'video/utils/video';
import { useFileUpload } from 'common/hooks/useFileUpload';
import { formatDate } from 'common/utils/formatDate';
import { ChipGroup } from 'common/components/Chip';
import { ExtraCategoryLabels } from 'organisation/components/ExtraCategoryLabels';

interface IProps {
    video: IVideoListItemFragment;
}

export const VideosManagementTableRow = ({ video }: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentVideo, setCurrentVideo] =
        useState<IVideoListItemFragment>(video);
    const { uploads } = useFileUpload();

    const { id, title, thumbnail, creationDate, status, extraCategoryValues } =
        currentVideo;

    // Create subscription for video updates
    useUpdatedVideoSubscription({
        variables: { id: video.id },
        // Don't need to subscribe to updates if video is finished
        skip: status === IVideoStatus.Finished,
        onData: ({ data: { data } }) => {
            const video = data?.updatedVideo || null;

            if (!video) return;

            setCurrentVideo(video);
        },
    });

    useEffect(() => {
        setCurrentVideo(video);
    }, [video]);

    const image = thumbnail?.url || '';

    const videoUpload = useMemo(() => {
        if (!uploads?.length) return;

        return uploads.find((upload) => upload.referenceId === id);
    }, [id, uploads]);

    return (
        <TableRow
            hover
            key={id}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
                const searchParams = new URLSearchParams(location.search);
                const url = `${getUrl('VIDEO_EDIT', { videoId: id })}?${searchParams}`;

                navigate(url);
            }}
        >
            <TableCell sx={{ width: '1px' }}>
                <TableImage src={image} />
            </TableCell>

            <TableCell>
                {title}

                <ChipGroup sx={{ my: 0.25 }}>
                    {!!extraCategoryValues?.length && (
                        <ExtraCategoryLabels
                            chipProps={{ size: 'small' }}
                            extraCategoryValues={extraCategoryValues}
                        />
                    )}
                </ChipGroup>
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {creationDate &&
                    formatDate(
                        creationDate,
                        'd MMM yyyy HH:mm',
                        true,
                        false,
                        true
                    )}
            </TableCell>
            <TableCell align="right">
                {getVideoStatusChip(status, videoUpload)}
            </TableCell>
        </TableRow>
    );
};
