import { useTranslation } from 'react-i18next';
import { Box, Grid, Button as MuiButton } from '@mui/material';
import { mdiPlus } from '@mdi/js';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import MANAGEMENT_HEADER from 'assets/images/user-management-header.webp';
import { PageTitle } from 'common/components/PageTitle';
import { Typography } from 'common/components/Typography';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { useFrontendPermissions } from 'user/hooks';
import { BackgroundImg } from 'common/components/Img';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { UsersManagementTable } from 'user/components/UsersManagementTable';
import { BasePage } from 'hydra/pages/BasePage';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { Tab, Tabs } from 'common/components/Tabs';
import { RouteLink } from 'route/components/RouteLink';
import { Alert } from 'common/components/Alert';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { useFeature } from 'common/hooks/useFeature';
import {
    IFeature,
    IRole,
    useArchivedUsersExpirationDaysQuery,
    useCurrentUserQuery,
} from 'graphql/types';
import { getUrl } from 'route/utils/getUrl';
import { Link } from 'common/components/Link';
import { Loader } from 'common/components/Loader';

enum EUserStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
}

export const UsersManagementPage = () => {
    const [translate] = useTranslation();
    const client = useApolloClient();
    const navigate = useNavigate();
    const {
        canCreate: canCreateUser,
        canUpdate: canUpdateUser,
        canDelete: canDeleteUser,
        loading: loadingPermissions,
    } = useFrontendPermissions('userManagement');
    const { canUse: createUsersInPlan, loading: loadingCreateUsersInPlan } =
        useFeature(IFeature.CreateUsers);
    const { openGlobalDrawer } = useGlobalDrawer();
    const { data: currentUserData, loading: currentUserLoading } =
        useCurrentUserQuery();

    const { currentUser } = currentUserData || {};

    const isUserActivePage = !!useRouteMatch('USER_MANAGEMENT_LIST');

    const userStatus = isUserActivePage
        ? EUserStatus.ACTIVE
        : EUserStatus.ARCHIVED;

    const isArchiveView = userStatus === EUserStatus.ARCHIVED;

    const {
        data: archivedUsersExpirationDaysData,
        loading: archivedUsersExpirationDaysLoading,
    } = useArchivedUsersExpirationDaysQuery({
        skip: !isArchiveView,
    });

    const { archivedUsersExpirationDays } =
        archivedUsersExpirationDaysData?.organisation || {};

    const isManagerOrOwner =
        !!currentUser?.roles?.includes(IRole.Manager) ||
        !!currentUser?.roles?.includes(IRole.Owner);

    const loading =
        loadingPermissions ||
        currentUserLoading ||
        archivedUsersExpirationDaysLoading;

    const canManageUsers = !loadingPermissions && canUpdateUser;

    const canCreateUsers =
        !loadingPermissions &&
        !loadingCreateUsersInPlan &&
        createUsersInPlan &&
        canCreateUser;

    if (
        (!loading && !canManageUsers) ||
        (!isManagerOrOwner && !isUserActivePage) // If archived page and not manager
    ) {
        return <FourOFourPage />;
    }

    const header = (
        <BackgroundImg
            src={MANAGEMENT_HEADER}
            sx={{
                height: '10vw',
                maxHeight: '150px',
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                display: { xs: 'none', sm: 'block' },
            }}
        />
    );

    const handleCreateUser = () => {
        if (!isUserActivePage) navigate(getUrl('USER_MANAGEMENT_LIST'));

        client.cache.evict({ fieldName: 'paginatedUsers' });
        client.cache.gc();
    };

    return (
        <BasePage contentContainerMaxWidth="lg" header={header}>
            <PageTitle>
                {translate('usersManagementPage.browserTitle')}
            </PageTitle>

            <Box sx={{ pt: { xs: 4, sm: 2 } }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Typography variant="h1">
                            {translate('usersManagementPage.pageTitle')}
                        </Typography>
                    </Grid>

                    {canCreateUsers && (
                        <Grid item xs="auto">
                            <ActionButton
                                outlined
                                variant="extended"
                                onClick={() => {
                                    openGlobalDrawer({
                                        type: IGlobalDrawerType.UserCreate,
                                        props: {
                                            onCreate: handleCreateUser,
                                        },
                                    });
                                }}
                            >
                                <Box>
                                    <Icon path={mdiPlus} size="2.4rem" />
                                </Box>

                                {translate('usersManagementPage.newUser')}
                            </ActionButton>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Tabs sx={{ mt: 2 }} value={userStatus}>
                <Tab
                    component={RouteLink}
                    label={translate('usersManagementPage.tabs.active')}
                    to="USER_MANAGEMENT_LIST"
                    value={EUserStatus.ACTIVE}
                />

                {isManagerOrOwner && (
                    <Tab
                        component={RouteLink}
                        label={translate('usersManagementPage.tabs.archived')}
                        to="USER_MANAGEMENT_LIST_ARCHIVED"
                        value={EUserStatus.ARCHIVED}
                    />
                )}
            </Tabs>

            {archivedUsersExpirationDaysLoading && <Loader />}

            {!archivedUsersExpirationDaysLoading && (
                <>
                    {!isArchiveView && (
                        <Alert
                            action={
                                <MuiButton
                                    color="inherit"
                                    component={Link}
                                    size="small"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontWeight: 'bold',
                                        mr: 1,
                                    }}
                                    to="MANAGEMENT_USERS_USERS"
                                >
                                    {translate(
                                        'usersManagementPage.toOldManagement.buttonText'
                                    )}
                                </MuiButton>
                            }
                            severity="warning"
                            sx={{ mt: 2 }}
                        >
                            {translate(
                                'usersManagementPage.toOldManagement.text'
                            )}
                        </Alert>
                    )}

                    {isArchiveView && (
                        <Alert severity="warning" sx={{ my: 2 }}>
                            {translate('usersManagementPage.archiveMessage', {
                                expiryDays: archivedUsersExpirationDays,
                            })}
                        </Alert>
                    )}

                    <Box sx={{ my: isArchiveView ? 0 : 2 }}>
                        <UsersManagementTable
                            canDeleteUsers={canDeleteUser}
                            isEditable={canManageUsers}
                        />
                    </Box>
                </>
            )}
        </BasePage>
    );
};
