import { useEffect, useMemo, useState } from 'react';

import {
    IOfferEventEnrollmentManagementListItemFragment,
    IOfferEventEnrollmentSortByInput,
    IOfferEventEnrollmentSortField,
    IOfferEventSubscriptionStatus,
    IOfferEventType,
    ISortDirection,
    useOfferEventEnrollmentManagementQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import { useEnrollmentsManagementSearch } from 'offer/hooks/enrollment/useEnrollmentsManagementSearch';
import { useEnrollmentsManagementQueryVariables } from 'offer/hooks/enrollment/useEnrollmentsManagementQueryVariables';
import { usePagination } from 'common/hooks/usePagination';

export const useEnrollmentsManagementList = (
    enrollmentStatus: IOfferEventSubscriptionStatus
) => {
    const { showApolloError } = useApolloError();
    const [sortBy, setSortBy] = useState<IOfferEventEnrollmentSortByInput>({
        field: IOfferEventEnrollmentSortField.StatusUpdatedOn,
        direction: ISortDirection.Desc,
    });

    const {
        filters,
        searchQueryParam,
        selectedFilters,
        handleSearch,
        handleFilterSelect,
    } = useEnrollmentsManagementSearch();

    const { paginationSettings, initializePagination, setPage } =
        usePagination(true);

    const paginationVariables = useMemo(
        () => ({
            offset: paginationSettings.offset,
            first: paginationSettings.first,
        }),
        [paginationSettings]
    );

    const { queryVariables } = useEnrollmentsManagementQueryVariables({
        paginationVariables,
        searchQueryParam,
        selectedFilters,
    });

    const { data, loading } = useOfferEventEnrollmentManagementQuery({
        variables: {
            ...queryVariables,
            status: enrollmentStatus,
            sortBy,
            eventTypes: [IOfferEventType.Meeting, IOfferEventType.Webinar],
        },
        skip: !queryVariables,
        onError: showApolloError,
    });

    const enrollmentsObject = data?.manageableOfferEventEnrollments;
    const enrollmentsCount = enrollmentsObject?.count;
    const enrollments =
        (enrollmentsObject?.edges
            .map((edge) => edge?.node || null)
            .filter(
                Boolean
            ) as IOfferEventEnrollmentManagementListItemFragment[]) || [];

    const onSort = (sortField: IOfferEventEnrollmentSortField) => {
        const currentSortField = sortBy.field;

        let newDirection = ISortDirection.Asc;

        // If given field is the same as the current sort, we want to toggle the direction
        if (sortField === currentSortField) {
            switch (sortBy.direction) {
                case ISortDirection.Asc:
                    newDirection = ISortDirection.Desc;
                    break;
                case ISortDirection.Desc:
                    newDirection = ISortDirection.Asc;
                    break;
            }
        }
        setSortBy({ field: sortField, direction: newDirection });
    };

    useEffect(() => {
        if (loading || !initializePagination) return;

        initializePagination(enrollmentsCount || 0);
    }, [enrollmentsCount, loading, initializePagination]);

    return {
        enrollments,
        loading,
        currentSort: sortBy,
        filters,
        paginationSettings,
        searchQueryParam,
        selectedFilters,
        onSearch: handleSearch,
        onFilterSelect: handleFilterSelect,
        onSort,
        setPage,
    };
};
