import { ApolloCache } from '@apollo/client';

import {
    EmptyModuleGroupFragmentDoc,
    IModuleFragment,
    IEmptyModuleGroupFragment,
    IModuleWeight,
    IModuleType,
    ITrainingMatrixModuleFragment,
} from 'graphql/types';

export function createModuleInCache(
    newModule: IModuleFragment,
    moduleGroup: IEmptyModuleGroupFragment,
    isNewModuleGroup: boolean,
    trainingId: string,
    cache: ApolloCache<unknown>
) {
    // If this is a new module group we want to add the group to the training with the new module
    if (isNewModuleGroup) {
        cache.modify({
            id: `Training:${trainingId}`,
            fields: {
                moduleGroups(cachedGroupRefs) {
                    const moduleGroupRef = cache.writeFragment({
                        id: cache.identify(moduleGroup),
                        fragment: EmptyModuleGroupFragmentDoc,
                        fragmentName: 'EmptyModuleGroup',
                        data: {
                            ...moduleGroup,
                            modules: [newModule],
                        },
                    });

                    return [...cachedGroupRefs, moduleGroupRef];
                },
            },
        });

        return;
    }

    cache.modify({
        id: cache.identify(moduleGroup),
        fields: {
            modules(cachedModuleRefs = [], { toReference }) {
                return [...cachedModuleRefs, toReference(newModule)];
            },
        },
    });
}

/**
 *  Return the actual weight value of the enum
 *
 * @param weight    Module weight value of enum
 * @returns number  The actual weight value
 */
export function getModuleWeightValue(weight: IModuleWeight): number {
    return Object.values(IModuleWeight).indexOf(weight);
}

/**
 *
 * @param module
 * @returns string  The type of the module
 */
export function getModuleType(module: IModuleFragment): IModuleType {
    switch (module.__typename) {
        case 'AssignmentModule':
            return IModuleType.Assignment;
        case 'CertificateModule':
            return IModuleType.Certificate;
        case 'FileModule':
            return IModuleType.File;
        case 'LinkModule':
            return IModuleType.Link;
        case 'ExternalContentModule':
            return IModuleType.ExternalContent;
        case 'LTIModule':
            return IModuleType.Lti;
        case 'VideoModule':
            return IModuleType.Video;
        case 'MeetingModule':
            return IModuleType.Meeting;
        case 'CourseModule':
            return IModuleType.Course;
        case 'ScormModule':
            return IModuleType.Scorm;
        case 'AfasWebhookModule':
            return IModuleType.Afas;
        case 'WebhookModule':
            return IModuleType.Webhook;
    }
}

/**
 * Get Module title
 *
 * @param module ITrainingMatrixModuleFragment | IModuleFragment
 *
 * @returns string
 */
export const getModuleTitle = (
    module: ITrainingMatrixModuleFragment | IModuleFragment
) => {
    if ('title' in module && module.title) {
        return module.title;
    }

    if ('course' in module && module.course) {
        return module.course.title;
    }

    if ('scorm' in module && module.scorm) {
        return module.scorm.title;
    }

    if ('content' in module && module.content) {
        return module.content.title;
    }

    return '';
};
