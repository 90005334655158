import { EFilterType, IFilterFormItem, TObjectFilterValue } from 'common/types';
import { IRole } from 'graphql/types';
import { RoleSelectField } from 'user/components/RoleSelectField';
import { translate } from 'utils/i18n';

export function userRolesFilter(
    transKey = 'drawerFilters.userRoles',
    fieldName?: string,
    permissionRoles?: IRole[]
): IFilterFormItem<TObjectFilterValue[]> {
    return {
        key: 'userRoles',
        label: translate(`${transKey}.sectionTitle`),
        permissionRoles,
        fields: [
            {
                name: fieldName || 'userRoles',
                type: EFilterType.Object,
                label: translate(`${transKey}.label`),
                initialValue: [],
                component: RoleSelectField,
                chip: {
                    label: translate(`${transKey}.chip`),
                },
                parseQueryValue: (value) => value.map((role) => role.id),
            },
        ],
    };
}
