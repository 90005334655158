import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { TRAINING_MODULE_TYPES } from 'training/constants/modules';
import { PageDrawer } from 'common/components/PageDrawer';
import { Loader } from 'common/components/Loader';
import { useFeature } from 'common/hooks/useFeature';
import { DrawerUpgradeSection } from 'common/components/DrawerUpgradeSection';
import { DrawerHeaderImage } from 'common/components/DrawerHeaderImage';

interface IProps {
    open?: boolean;
    onClose?(): void;
}

export const ModuleUpdateDrawer = ({ open, onClose }: IProps) => {
    const { moduleId, type: typeParam } = useParams() as {
        moduleId: string;
        type: string;
    };

    const moduleType = useMemo(
        () =>
            // When type is set in params try to find the module for it and use that one as initial
            TRAINING_MODULE_TYPES.find((type) => type.key === typeParam),
        [typeParam]
    );

    const {
        canUse,
        userCanSeeUpgrade,
        loading: loadingCanUse,
    } = useFeature(moduleType?.feature);

    const showUpgrade = !canUse && userCanSeeUpgrade;

    // Get the create drawer component for the current moduleType
    const DrawerComponent = moduleType
        ? moduleType.updateDrawerComponent
        : null;

    return (
        <PageDrawer open={open} onClose={onClose}>
            {loadingCanUse && <Loader />}
            {open && DrawerComponent && canUse && (
                <DrawerComponent id={moduleId} onClose={onClose} />
            )}
            {showUpgrade && (
                <>
                    {moduleType?.upgradeHeaderImage && (
                        <DrawerHeaderImage
                            src={moduleType.upgradeHeaderImage}
                        />
                    )}
                    <Box p={{ xs: 2, sm: 4 }}>
                        <DrawerUpgradeSection
                            description={moduleType?.upgradeDescription}
                            title={moduleType?.upgradeTitle}
                        />
                    </Box>
                </>
            )}
        </PageDrawer>
    );
};
