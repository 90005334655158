import { Box, BoxProps, SxProps } from '@mui/material';

import { isWhiteColor } from 'common/utils/color';
import { withSxProp } from 'common/utils/props';

interface IProps extends BoxProps {
    backgroundColor: string;
}

const buttonStyle: SxProps = {
    height: '56px',
    width: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'divider',
    borderRadius: 1,
    mt: 2,
    mb: 1,

    ':hover': {
        borderColor: 'text.primary',
    },
};

export const ColorInputButton = ({ backgroundColor, sx, ...other }: IProps) => (
    <Box
        sx={[
            buttonStyle,

            {
                backgroundColor,
                color: isWhiteColor(backgroundColor)
                    ? 'rgba(0, 0, 0, 0.23)'
                    : 'rgba(255, 255, 255, 0.95)',
            },

            ...withSxProp(sx),
        ]}
        {...other}
    />
);
