import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerModuleSection } from 'common/components/DrawerModuleSection';
import {
    IPageDrawerProps,
    PageDrawer,
} from 'common/components/PageDrawer/PageDrawer';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    getExtraFieldChoicesInitialValue,
    getExtraFieldInitialEmptyValues,
} from 'common/utils/userExtraFields';
import {
    IFeature,
    IFileFragment,
    IGroupListItemFragment,
    ILanguage,
    IRole,
    IUpdateProfileInput,
    IUpdateUserInput,
    IUserNotifications,
    useCurrentUserQuery,
    useExtraUserFieldsQuery,
    useUpdateProfileMutation,
    useUpdateUserMutation,
    useUserQuery,
} from 'graphql/types';
import { UserPreview } from 'user/components/UserPreview';
import { getExtraFieldsName } from 'common/utils/extraFields';
import { getImageId } from 'common/utils/image';
import { UserUpdateForm } from 'user/components/forms/UserUpdateForm';
import { IGlobalDrawerType } from 'common/types';
import { useGlobalDrawer } from 'common/hooks/useGlobalDrawer';
import { useCanManageUser } from 'user/hooks';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getAddedGroupIds, getRemovedGroupIds } from 'user/utils/groups';
import { TFrontendRole } from 'user/types';
import { useFeature } from 'common/hooks/useFeature';

export interface IUserFormValues extends IUpdateUserInput {
    participantGroups?: IGroupListItemFragment[];
    managerGroups?: IGroupListItemFragment[];
}

interface IProps extends IPageDrawerProps {
    userId: string;
    onArchive?(): void;
    onUpdate?(initialValues: IUserFormValues, newValues: IUserFormValues): void;
}

export const UserUpdateDrawer = ({
    userId,
    onArchive,
    onUpdate,
    ...other
}: IProps) => {
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const [displaySnackbar] = useSnackbar();

    const { data: extraUserFieldsData, loading: extraUserFieldsLoading } =
        useExtraUserFieldsQuery({
            onError: showApolloError,
        });
    const [profileImage, setProfileImage] = useState<IFileFragment>();
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [submittedValues, setSubmittedValues] = useState<IUserFormValues>();
    const { closeGlobalDrawer } = useGlobalDrawer();
    const [updateUser, { loading: updateUserLoading }] =
        useUpdateUserMutation();
    const [updateProfile] = useUpdateProfileMutation();
    const extraUserFields = extraUserFieldsData?.extraUserFields || [];
    const { data: userData, loading: userDataLoading } = useUserQuery({
        variables: { id: userId },
    });
    const { canUse: canUseAFAS, loading: loadingAFAS } = useFeature(
        IFeature.Afas
    );

    const {
        canManageUser,
        isCurrentUser,
        userRoles,
        loading: loadingCanManageUser,
    } = useCanManageUser(userId);

    const { data: currentUserData } = useCurrentUserQuery();
    const { roles: currentUserRoles } = currentUserData?.currentUser || {};

    const { user } = userData || {};
    const {
        name,
        email,
        roles,
        notifications,
        automatedMessages,
        language,
        extraFieldsProfile,
        participantGroups,
        managerGroups,
        ref,
    } = user || {};

    const extraFieldsParsed = extraFieldsProfile
        ? JSON.parse(extraFieldsProfile)
        : {};

    const isManager = currentUserRoles?.includes(IRole.Manager);

    const canUseAfasFields = isManager && canUseAFAS;

    const initialExtraFields = extraUserFields.length
        ? Object.keys(extraFieldsParsed).reduce((acc, curKey) => {
              const currentValue = extraFieldsParsed[curKey];

              if (!currentValue) return acc;

              const result = extraUserFields.filter(
                  (extraField) => extraField.name === curKey
              );

              // If field is choice field and value is string make it an array value
              if (result.length) {
                  return {
                      ...acc,
                      [getExtraFieldsName(curKey as string)]:
                          getExtraFieldChoicesInitialValue(
                              result[0],
                              currentValue
                          ),
                  };
              }

              return { ...acc, [curKey]: extraFieldsParsed[curKey] };
          }, {})
        : {};

    const initialValues: IUserFormValues = {
        name: name || '',
        email: email || '',
        roles: roles?.filter(Boolean) || [],
        notifications: notifications || IUserNotifications.Immediate,
        automatedMessages: !!automatedMessages,
        language: language || ILanguage.Nl,
        participantGroups: participantGroups || undefined,
        managerGroups: managerGroups || undefined,
        ref,
        ...getExtraFieldInitialEmptyValues(extraUserFields),
        ...initialExtraFields,
    };

    useEffect(() => {
        if (!!profileImage) return;

        setProfileImage(user?.profileImage);
    }, [userDataLoading, user?.profileImage]);

    const handleSubmit = async (userFormValues: IUserFormValues) => {
        let userValues: IUserFormValues = { ...userFormValues };

        if (extraUserFields.length) {
            const extraFields: { [key: string]: string | Date } = {};

            extraUserFields.forEach((field) => {
                const fieldKey = getExtraFieldsName(
                    field.name
                ) as keyof IUserFormValues;

                // If you can manage or if you can update your own field
                if ((isCurrentUser && field.profile) || canManageUser) {
                    // Retransform field name to its original field name
                    extraFields[field.name] =
                        userValues[fieldKey as keyof IUserFormValues];
                }

                delete userValues[fieldKey];
            });

            if (Object.keys(extraFields).length) {
                userValues = {
                    ...userValues,
                    extraFields: JSON.stringify(extraFields),
                };
            }
        }

        try {
            let updatedUser;
            const { participantGroups, managerGroups, ...otherUserValues } =
                userValues;

            const addParticipantGroups = getAddedGroupIds(
                initialValues.participantGroups || [],
                participantGroups || []
            );

            const removeParticipantGroups = getRemovedGroupIds(
                initialValues.participantGroups || [],
                participantGroups || []
            );

            const addManagerGroups = getAddedGroupIds(
                initialValues.managerGroups || [],
                managerGroups || []
            );

            const removeManagerGroups = getRemovedGroupIds(
                initialValues.managerGroups || [],
                managerGroups || []
            );

            // When profile is of the current user call update profile mutation
            if (isCurrentUser) {
                const {
                    name,
                    extraFields,
                    language,
                    notifications,
                    automatedMessages,
                    email,
                    afasContactId,
                    afasEmployeeId,
                    afasPersonId,
                    roles,
                    ref,
                } = userValues;

                const isOwnerOrManager =
                    userRoles?.includes(TFrontendRole.Owner) || isManager;

                const profileValues: IUpdateProfileInput = {
                    name,
                    extraFields,
                    language,
                    notifications,
                    automatedMessages,
                    email,
                };

                if (isOwnerOrManager) {
                    profileValues.roles = roles;
                    profileValues.addParticipantGroups = addParticipantGroups;
                    profileValues.removeParticipantGroups =
                        removeParticipantGroups;
                    profileValues.addManagerGroups = addManagerGroups;
                    profileValues.removeManagerGroups = removeManagerGroups;
                }

                if (isManager) profileValues.ref = ref;

                if (canUseAfasFields) {
                    profileValues.afasContactId = afasContactId;
                    profileValues.afasEmployeeId = afasEmployeeId;
                    profileValues.afasPersonId = afasPersonId;
                }

                const response = await updateProfile({
                    variables: {
                        profile: {
                            ...profileValues,
                            profileImage: getImageId(
                                user?.profileImage,
                                profileImage
                            ),
                        },
                    },
                });

                updatedUser = response.data?.updateProfile?.user;
            } else {
                const response = await updateUser({
                    variables: {
                        id: userId,
                        user: {
                            ...otherUserValues,
                            profileImage: getImageId(
                                user?.profileImage,
                                profileImage
                            ),
                            addParticipantGroups,
                            removeParticipantGroups,
                            addManagerGroups,
                            removeManagerGroups,
                        },
                    },
                    update: (cache, mutationResult) => {
                        const { data: updatedUserData } = mutationResult;

                        const updatedUser = updatedUserData?.updateUser?.user;

                        if (!updatedUser) return;

                        cache.evict({ id: `User:${userId}` });
                        cache.gc();
                    },
                });
                updatedUser = response.data?.updateUser?.user;
            }

            if (!updatedUser) return;

            // Update profile image in state after successful update
            setProfileImage(updatedUser?.profileImage);

            onUpdate?.(initialValues, userFormValues);
        } catch (error) {
            showApolloError(error);

            // Save submitted values. If backend throws an error we do not want to lose the values
            setSubmittedValues(userFormValues);

            return;
        }

        displaySnackbar(
            translate(
                `userActionSuccess.${isCurrentUser ? 'profile' : 'update'}`
            ),
            {
                variant: 'success',
            }
        );

        closeGlobalDrawer({
            type: IGlobalDrawerType.UserUpdate,
        });

        // Reset submitted values as the form was successfully submitted
        if (submittedValues) setSubmittedValues(undefined);

        return;
    };

    const loading =
        extraUserFieldsLoading ||
        updateUserLoading ||
        loadingCanManageUser ||
        loadingAFAS;

    return (
        <PageDrawer {...other}>
            <DrawerModuleSection>
                <UserPreview
                    name="profileImage"
                    profileImage={profileImage}
                    onUpload={setProfileImage}
                    onUploadFinish={() => setLoadingUpload(false)}
                    onUploadStart={() => setLoadingUpload(true)}
                />
            </DrawerModuleSection>

            {!!user && (
                <UserUpdateForm
                    canManage={canManageUser}
                    canUseAfasFields={canUseAfasFields}
                    disabled={loadingUpload}
                    extraFields={extraUserFields}
                    imageUploaded={profileImage?.id !== user?.profileImage.id}
                    initialValues={initialValues}
                    isCurrentUser={isCurrentUser}
                    loading={loading}
                    user={user}
                    onArchive={onArchive}
                    onSubmit={handleSubmit}
                />
            )}
        </PageDrawer>
    );
};
