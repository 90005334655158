import { NavigateFunction, Location } from 'react-router-dom';

import { getUrl } from 'route/utils/getUrl';

export function gotoTrainingPage(
    navigate: NavigateFunction,
    trainingId: string,
    location?: Location
) {
    const url = getUrl('TRAINING', { trainingId });
    navigate(url, { state: { from: location } });
}

export function gotoAssignment(
    navigate: NavigateFunction,
    trainingId: string,
    moduleId: string,
    queryParams?: string
) {
    let url = getUrl('TRAINING_ASSIGNMENT_GRADE', {
        trainingId,
        moduleId,
    });

    if (queryParams) url += queryParams;

    navigate(url);
}

export function gotoCertificate(
    navigate: NavigateFunction,
    trainingId: string,
    certificateId: string
) {
    const url = getUrl('TRAINING_CERTIFICATE', {
        trainingId,
        certificateId,
    });
    navigate(url);
}
