import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { mdiPlus } from '@mdi/js';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { useTrainingModuleGroupsQuery } from 'graphql/types';
import { ITrainingSectionProps } from 'training/types';
import { ApolloError } from 'common/components/ApolloError';
import { ModuleGroup } from 'training/components/ModuleGroup';
import { BoxLoader } from 'common/components/Loader';
import { Text } from 'common/components/Text';
import { getGroupsState } from 'user/utils';
import { TGroupsState } from 'user/utils/getGroupsState';
import { PageTitle } from 'common/components/PageTitle';
import { TrainingNoModuleGroups } from 'training/components/TrainingNoModuleGroups';
import { ActionButton } from 'common/components/ActionButton';
import { Icon } from 'common/components/Icon';
import { Divider } from 'common/components/Divider';
import { Link } from 'common/components/Link';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import { Tooltip } from 'common/components/Tooltip';
import { useRouteMatch } from 'route/hooks/useRouteMatch';
import { getUrl } from 'route/utils/getUrl';
import { useFrontendPermissions } from 'user/hooks';
import {
    DragDropContext,
    Draggable,
    Droppable,
} from 'common/components/DragDrop';
import { useTrainingDND } from 'training/hooks/useTrainingDND';
import { TrainingPortfolioItemsSubscription } from 'training/subscriptionComponents';

import { ModuleGroupUpdateDrawer } from '../ModuleGroupUpdateDrawer';
import { ModuleGroupCreateDrawer } from '../ModuleGroupCreateDrawer';

const GROUP_TYPE = 'trainingOverview';

export const Overview = ({
    canManage,
    canManageParticipants,
    training,
}: ITrainingSectionProps) => {
    const [translate] = useTranslation();
    const navigate = useNavigate();
    const studentView = useReactiveVar(trainingStudentView);
    const [expanded, setExpanded] = useState<TGroupsState>(
        getGroupsState(GROUP_TYPE)
    );
    const { canUpdate } = useFrontendPermissions('training');
    const isModuleGroupCreatePage = !!useRouteMatch(
        'TRAINING_CREATE_MODULE_GROUP'
    );
    const isModuleGroupEditPage = !!useRouteMatch('TRAINING_EDIT_MODULE_GROUP');

    const { id, introduction, modelId, currentUserIsAuthor } = training;

    const canUpdateTraining = canUpdate || currentUserIsAuthor;

    const { data, loading, error } = useTrainingModuleGroupsQuery({
        variables: { id },
        nextFetchPolicy: 'cache-only',
        returnPartialData: true,
    });

    const moduleGroups = data?.training?.moduleGroups || [];
    const { handleDragEnd } = useTrainingDND(training, moduleGroups);

    if (loading) return <BoxLoader />;

    if (error) return <ApolloError error={error} />;

    const handleExpandGroup = (groupId: string) => () => {
        // Check if group is expanded, if its not defined it is expanded by default
        const isExpanded = !(groupId in expanded) || expanded[groupId];

        const newExpandedState = {
            ...expanded,
            [groupId]: !isExpanded,
        };

        setExpanded(newExpandedState);

        localStorage.setItem(
            `${GROUP_TYPE}ItemGroupsState`,
            JSON.stringify(newExpandedState)
        );
    };

    const isDragDropDisabled = studentView || !canUpdateTraining;

    return (
        <>
            <PageTitle mixpanelTitle="Overview - Learning Journey">
                {`${translate('overview')} - ${training.title}`}
            </PageTitle>
            {!!introduction && !!moduleGroups.length && (
                <Box mb={4} mt={2}>
                    <Text variant="body1">{introduction}</Text>
                </Box>
            )}
            {!moduleGroups.length && (
                <TrainingNoModuleGroups
                    currentUserIsAuthor={currentUserIsAuthor}
                    trainingId={id}
                    trainingModelId={modelId || undefined}
                />
            )}

            {!!moduleGroups.length && (
                <TrainingPortfolioItemsSubscription trainingId={training.id} />
            )}

            <DragDropContext
                dragDropDisabled={isDragDropDisabled}
                onDragEnd={handleDragEnd}
            >
                <Droppable
                    dragDropDisabled={isDragDropDisabled}
                    droppableId="moduleGroups"
                    type="MODULE_GROUP"
                >
                    <Box>
                        {moduleGroups.map((group, index) => (
                            <Draggable
                                dragDropDisabled={isDragDropDisabled}
                                draggableId={group.id}
                                index={index}
                                key={group.id}
                            >
                                <Box sx={{ py: 2 }}>
                                    <ModuleGroup
                                        {...group}
                                        canManage={canManage}
                                        canManageParticipants={
                                            canManageParticipants
                                        }
                                        expanded={
                                            !(group.id in expanded) ||
                                            expanded[group.id]
                                        }
                                        key={group.id}
                                        training={training}
                                        onChange={handleExpandGroup(group.id)}
                                    />
                                </Box>
                            </Draggable>
                        ))}
                    </Box>
                </Droppable>
            </DragDropContext>
            {canManage && (
                <>
                    <ModuleGroupUpdateDrawer
                        open={isModuleGroupEditPage}
                        onClose={() => {
                            navigate(getUrl('TRAINING', { trainingId: id }));
                        }}
                    />

                    <ModuleGroupCreateDrawer
                        open={isModuleGroupCreatePage}
                        onClose={() => {
                            navigate(getUrl('TRAINING', { trainingId: id }));
                        }}
                    />
                </>
            )}
            {!studentView && canUpdateTraining && !!moduleGroups.length && (
                <Box mt={4} width="100%">
                    <Divider>
                        <Tooltip title={translate<string>('newSection')}>
                            <Box>
                                <ActionButton
                                    outlined
                                    component={Link}
                                    params={{ trainingId: id }}
                                    size="medium"
                                    to="TRAINING_CREATE_MODULE_GROUP"
                                >
                                    <Icon path={mdiPlus} />
                                </ActionButton>
                            </Box>
                        </Tooltip>
                    </Divider>
                </Box>
            )}
        </>
    );
};
