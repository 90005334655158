import { Box } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { Divider } from 'common/components/Divider';
import { Switch, TextField } from 'common/components/FormField';
import { FormSection } from 'common/components/FormSection';
import { Link } from 'common/components/Link';
import { IContentTypeValue, ICreateGroupInput } from 'graphql/types';
import { ExtraCategoryFormFields } from 'organisation/components/ExtraCategoryFormFields';
import { useFrontendPermissions } from 'user/hooks';
import { TrainingSelector } from 'training/components/FormField/TrainingSelector';

interface IProps {
    isEditable?: boolean;
}

export const GroupGeneralForm = ({ isEditable }: IProps) => {
    const [translate] = useTranslation();
    const { values } = useFormikContext<ICreateGroupInput>();
    const { canUpdate: canUpdateGroup } = useFrontendPermissions('group');

    return (
        <>
            {isEditable && canUpdateGroup && (
                <>
                    <Field
                        component={TextField}
                        label={translate('name')}
                        name="name"
                    />

                    <Box sx={{ mt: canUpdateGroup ? 4 : 3 }}>
                        <Divider />
                    </Box>
                </>
            )}

            <FormSection
                defaultExpanded={!isEditable || (isEditable && !canUpdateGroup)}
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey="groupForm.trainingsDescription"
                        />
                    </Box>
                }
                title={translate('groupForm.trainings')}
            >
                <Box mt={2}>
                    <TrainingSelector
                        isEditable={canUpdateGroup}
                        label={translate('groupForm.addTrainings')}
                        name="trainingIds"
                        value={values.trainingIds || []}
                    />
                </Box>
            </FormSection>

            {isEditable && canUpdateGroup && (
                <>
                    <FormSection
                        description={
                            <Box>
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="groupForm.categoriesDescription"
                                />
                            </Box>
                        }
                        title={translate('groupForm.categories')}
                    >
                        <Box mt={2}>
                            <ExtraCategoryFormFields
                                contentType={IContentTypeValue.Group}
                            />
                        </Box>
                    </FormSection>

                    <FormSection
                        description={
                            <Box>
                                <Trans
                                    components={{
                                        a: (
                                            <Link
                                                rel="noreferrer"
                                                underline="always"
                                            />
                                        ),
                                    }}
                                    i18nKey="groupForm.settingsDescription"
                                />
                            </Box>
                        }
                        title={translate('groupForm.settings')}
                    >
                        <Box mt={2}>
                            <Field
                                component={TextField}
                                label={translate('groupForm.maxParticipants')}
                                name="maxUsers"
                            />

                            <Field
                                component={TextField}
                                helperText={
                                    <Trans
                                        components={{
                                            a: (
                                                <Link
                                                    rel="noreferrer"
                                                    to="MANAGEMENT_ORGANISATION_SUBSCRIPTION"
                                                    underline="always"
                                                />
                                            ),
                                        }}
                                        i18nKey="groupForm.externalReferenceDescription"
                                    />
                                }
                                label={translate('groupForm.externalReference')}
                                name="ref"
                            />

                            <Field
                                checked={values.enableChat}
                                component={Switch}
                                helperText={translate(
                                    'groupForm.chatDescription'
                                )}
                                label={translate('groupForm.chat')}
                                name="enableChat"
                            />
                        </Box>
                    </FormSection>
                </>
            )}
        </>
    );
};
