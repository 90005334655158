import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import {
    ToolbarButton,
    ToolbarButtonProps,
    someNode,
} from '@udecode/slate-plugins';

import { options } from '../../options';

export const ToolbarParagraph = ({ ...other }: ToolbarButtonProps) => {
    const editor = useSlate();

    return (
        <ToolbarButton
            active={someNode(editor, { match: { type: options.p.type } })}
            onMouseDown={(event) => {
                event.preventDefault();

                if (!editor.selection) return;

                // Reset nodes to paragraph
                Transforms.setNodes(editor, {
                    type: options.p.type,
                });
            }}
            {...other}
        />
    );
};
