import { useEffect, useRef, useState } from 'react';
import { Field, FieldAttributes, useFormikContext, useField } from 'formik';
import { Box, createFilterOptions, FilterOptionsState } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mdiClose, mdiPlus, mdiSync } from '@mdi/js';

import {
    useLocationsLazyQuery,
    ILocationFragment,
    ILocationsQueryVariables,
    ILocationStatus,
} from 'graphql/types';
import { Autocomplete, TextField } from 'common/components/FormField';
import { Typography } from 'common/components/Typography';
import { useApolloError } from 'common/hooks/useApolloError';
import { getLocationStatusString } from 'common/utils/location';
import { Chip } from 'common/components/Chip';
import { IconButton } from 'common/components/IconButton';
import { Icon } from 'common/components/Icon';
import { ActionButton } from 'common/components/ActionButton';

interface ILocationOption extends Partial<ILocationFragment> {
    inputValue?: string;
}

const filter = createFilterOptions<ILocationOption>();

type TAvailabilityFilters = Pick<
    ILocationsQueryVariables,
    'availabilityStartDate' | 'availabilityEndDate'
>;

interface IProps {
    allowCreateNew?: boolean;
    allowCreateString?: boolean;
    label?: string;
    disableClearable?: boolean;
    allowRefetch?: boolean;
    availabilityFilters?: TAvailabilityFilters;
    showNewLocationFields?: boolean;
    onClickNewLocation?(showNewLocationFields: boolean): void;
}

export const LocationSelectField = ({
    name,
    label,
    availabilityFilters,
    disableClearable,
    allowRefetch,
    showNewLocationFields,
    allowCreateNew,
    allowCreateString,
    onClickNewLocation,
    ...other
}: IProps & FieldAttributes<unknown>) => {
    const [translate] = useTranslation();
    const { showApolloError } = useApolloError();

    const prevFilters = useRef<TAvailabilityFilters | undefined>(
        availabilityFilters
    );
    const [filtersChanged, setFiltersChanged] = useState(false);

    const [fetchLocations, { data, loading, called, error }] =
        useLocationsLazyQuery({
            variables: availabilityFilters,
            onError: showApolloError,
            fetchPolicy: 'no-cache',
            onCompleted: () => {
                // After fetching locations, we want to reset the filters changed state
                setFiltersChanged(false);
            },
        });

    const locations = data?.locations || [];
    const [field] = useField<ILocationOption | undefined>(name);

    const { setFieldValue } = useFormikContext();

    // Hide new fields when a location is selected
    useEffect(() => {
        if (field.value && showNewLocationFields) {
            onClickNewLocation?.(false);
        }
    }, [field.value]);

    const locationOptions: ILocationOption[] = locations.map((location) => ({
        id: location.id,
        title: location.title,
        location: location.location,
        status: location.status,
        description: location.description,
    }));

    useEffect(() => {
        if (!availabilityFilters) return;

        if (
            JSON.stringify(availabilityFilters) ===
            JSON.stringify(prevFilters.current)
        ) {
            return;
        }

        setFiltersChanged(true);
        prevFilters.current = availabilityFilters;
    }, [availabilityFilters]);

    // Get status of selected location
    const selectedLocationStatus = locationOptions.find(
        (option) => field.value?.id === option.id
    )?.status;

    const handleChange = (
        _event: React.ChangeEvent,
        value?: ILocationOption
    ) => {
        const newValue = value;

        // If inputValue is set we want to use that as title, this happens when a value is created via the
        // filterOptions function, because title is a translated value
        if (newValue?.inputValue) {
            newValue.title = newValue.inputValue;
        }

        setFieldValue(name, newValue);
    };

    const handleFilterOptions = (
        options: ILocationOption[],
        params: FilterOptionsState<Partial<ILocationFragment>>
    ) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some(
            (option) => inputValue === option.title
        );

        if (!!inputValue && !isExisting) {
            filtered.push({
                inputValue: inputValue,
                title: `${translate('useLocationInputValue')} "${inputValue}"`,
            });
        }

        return filtered;
    };

    let onChange;
    let onFilterOptions;

    if (allowCreateString && !allowCreateNew) {
        onChange = handleChange;
        onFilterOptions = handleFilterOptions;
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Field
                    clearOnBlur
                    selectOnFocus
                    component={Autocomplete}
                    defaultValue=""
                    disableClearable={disableClearable}
                    endAdornment={
                        error || (allowRefetch && filtersChanged) ? (
                            <IconButton
                                iconPath={mdiSync}
                                iconSize="2.2rem"
                                onClick={() => {
                                    fetchLocations({
                                        variables: availabilityFilters,
                                    });
                                }}
                            />
                        ) : (
                            selectedLocationStatus &&
                            selectedLocationStatus !==
                                ILocationStatus.Unknown && (
                                <Chip
                                    label={getLocationStatusString(
                                        selectedLocationStatus
                                    )}
                                />
                            )
                        )
                    }
                    filterOptions={onFilterOptions}
                    freeSolo={!!allowCreateString && !allowCreateNew}
                    getOptionLabel={(option: ILocationOption) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }

                        if (option.inputValue) {
                            return option.inputValue;
                        }

                        return option.title;
                    }}
                    InputProps={{
                        label,
                    }}
                    isOptionEqualToValue={(
                        option: ILocationOption,
                        value: ILocationOption
                    ) => {
                        if (option.id) {
                            return option.id === value.id;
                        }

                        return option.title === value.title;
                    }}
                    loading={loading}
                    name={name}
                    options={locationOptions}
                    renderOption={(
                        props: React.HTMLAttributes<HTMLLIElement>,
                        option: ILocationOption
                    ) => (
                        <Box component="li" {...props}>
                            <Box>
                                <Box>
                                    <Typography
                                        sx={{ fontWeight: 700 }}
                                        variant="body1"
                                    >
                                        {option.title}
                                    </Typography>
                                </Box>
                                {option.location && (
                                    <Box>
                                        <Typography variant="body2">
                                            {option.location}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            {!!option.status &&
                                option.status !== ILocationStatus.Unknown && (
                                    <Box sx={{ ml: 'auto' }}>
                                        <Chip
                                            label={getLocationStatusString(
                                                option.status
                                            )}
                                        />
                                    </Box>
                                )}
                        </Box>
                    )}
                    sx={{ width: '100%', mr: allowCreateNew ? 2 : 0 }}
                    onChange={onChange}
                    onOpen={() => {
                        // Only refetch locations when filters are changed
                        if (
                            !error &&
                            called &&
                            (!filtersChanged || !allowRefetch)
                        ) {
                            return;
                        }

                        fetchLocations({ variables: availabilityFilters });
                    }}
                    {...other}
                />

                {allowCreateNew && !showNewLocationFields && (
                    <ActionButton
                        outlined
                        sx={{ ml: 'auto', flexShrink: 0, mt: '21px' }}
                        onClick={() => {
                            onClickNewLocation?.(true);

                            // Clear field value if new button is clicked
                            if (field.value) {
                                setFieldValue(name, '');
                            }
                        }}
                    >
                        <Icon path={mdiPlus} />
                    </ActionButton>
                )}

                {allowCreateNew && showNewLocationFields && (
                    <IconButton
                        iconPath={mdiClose}
                        iconSize="2.4rem"
                        sx={{ height: 40, mt: '24px' }}
                        onClick={() => {
                            onClickNewLocation?.(false);
                        }}
                    />
                )}
            </Box>

            {allowCreateNew && showNewLocationFields && (
                <>
                    <Field
                        component={TextField}
                        label={translate('title')}
                        name="newLocation.title"
                    />

                    <Field
                        component={TextField}
                        label={translate('address')}
                        name="newLocation.location"
                    />

                    <Field
                        multiline
                        component={TextField}
                        label={translate('description')}
                        name="newLocation.description"
                    />
                </>
            )}
        </>
    );
};
