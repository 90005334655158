import { ICourseListItemFragment } from 'graphql/types';

export function isCourseSelected(
    selectedCourses: ICourseListItemFragment[],
    course: ICourseListItemFragment
) {
    return selectedCourses.some(
        (selectedCourse) => selectedCourse.id === course.id
    );
}

export function isCourseAdded(
    addedCourses: ICourseListItemFragment[],
    course: ICourseListItemFragment
) {
    return addedCourses.some((addedCourse) => addedCourse.id === course.id);
}

export function getCourseSelectStatus(
    selectedCourses: ICourseListItemFragment[],
    addedCourses: ICourseListItemFragment[],
    course: ICourseListItemFragment
) {
    const isSelected = isCourseSelected(selectedCourses, course);
    const isAdded = isCourseAdded(addedCourses, course);

    return { isSelected, isAdded };
}
