import { CardContent } from '@mui/material';

import { ICourseTemplate } from 'graphql/types';
import {
    Card,
    CardActionArea,
    CardMedia,
    CardSubtitle,
    CardTitle,
} from 'common/components/Card';
import DEFAULT_TRAININGCARD from 'assets/images/default-trainingcard.webp';

interface IProps {
    template: ICourseTemplate;
    onClick(templateId: ICourseTemplate['id']): void;
}

export const CourseTemplateCard = ({ template, onClick }: IProps) => (
    <Card>
        <CardActionArea onClick={() => onClick(template.id)}>
            <CardMedia
                image={template.image?.url || DEFAULT_TRAININGCARD}
                sx={{ height: 150 }}
            />
            <CardContent sx={{ p: 2, flex: '1 1 auto' }}>
                <CardTitle>{template.title}</CardTitle>
                {!!template.description && (
                    <CardSubtitle>{template.description}</CardSubtitle>
                )}
            </CardContent>
        </CardActionArea>
    </Card>
);
