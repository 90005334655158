import { TabProps } from '@mui/material';
import { DateTime } from 'luxon';
import { Location } from 'react-router-dom';
import { FormikContextType } from 'formik';

import {
    IUserExtraFieldFragment,
    IExtraTrainingFieldFragment,
    IExtraOfferEventFieldFragment,
    IUserExtraChoiceField,
    ITrainingExtraChoiceField,
    IOfferEventExtraChoiceField,
    IUserExtraStringField,
    ITrainingExtraStringField,
    IOfferEventExtraStringField,
    ICustomFontFragment,
    IFontSize,
    IFontWeight,
    IExtraCategoryFilterInput,
    IContentTypeValue,
    IRole,
    IExtraCategoryLabelFragment,
    IExtraCategoryValueFragment,
} from 'graphql/types';
import { IRouteLinkProps } from 'route/components/RouteLink';

import { TPaginationSettings } from './hooks/usePagination';

export enum EGraphQLError {
    AuthNotLoggedIn = 'AuthNotLoggedIn',
    AuthTwoFactorNotVerified = 'AuthTwoFactorNotVerified',
    AuthTwoFactorNotSetup = 'AuthTwoFactorNotSetup',
}

export type TTabsMenuItem = IRouteLinkProps & TabProps;

export type TFileUploadStatus = {
    done: boolean;
    success: boolean;
    progress: number;
    fileUrl?: string;
    errorMessage?: string;
};

export interface IFilterValue {
    id?: string;
    name: string;
}

export type TStringFilterValue = string;
export type TDateFilterValue = string | DateTime;
export type TObjectFilterValue = { id: string; name: string };
export type TRangeFilterValue = [from: number, to: number];

export type TFilterValue =
    | TStringFilterValue
    | TDateFilterValue
    | TObjectFilterValue
    | TRangeFilterValue
    | null
    | undefined;

export type TFilterFormValue = TFilterValue | TFilterValue[];
export type TFilterFormValues = Record<string, TFilterFormValue>;

export enum EFilterType {
    String = 'string',
    Date = 'date',
    Object = 'object',
    Range = 'range',
    Radio = 'radio',
}

export interface IFilterFormItem<
    T extends TFilterFormValue = TFilterFormValue,
    C extends React.ElementType<IFilterFormItem> = React.ElementType,
> {
    key: string;
    label: string;
    permissionRoles?: IRole[];
    component?: C;
    componentProps?: React.ComponentProps<C>;
    initialValue?: TFilterFormValue | string | null;
    fields: IFilterFormField<T>[];
    onChangeField?: (
        name: string,
        value: TFilterFormValue,
        field: IFilterFormField<T>,
        formikContext: FormikContextType<TFilterFormValues>
    ) => void;
}

export type TParseQueryFn<T extends TFilterFormValue = TFilterFormValue> = <R>(
    value: T
) => R | unknown;

export interface IFilterFormField<
    T extends TFilterFormValue = TFilterFormValue,
    C extends React.ElementType = React.ElementType,
> {
    name: string;
    label?: string;
    type: EFilterType;
    initialValue?: T | null;
    component?: C;
    componentProps?: React.ComponentProps<C>;
    /** Function to parse the filter value before applying it as filter value */
    parseFilterValue?: (value: T) => T;
    /** Custom key to use for the query, if not set the `name` will be used */
    queryKey?: string;
    /** Function to parse the filter value before using it for the query */
    parseQueryValue?: TParseQueryFn<T>;
    onChange?: (
        value: unknown,
        formikContext: FormikContextType<TFilterFormValues>
    ) => void;
    chip?: {
        label?: string;
        parseValue?: (value: T) => T;
        appendValue?: string;
    };
}

export type TFilterBarItem = {
    name: string;
    value: string;
    children?: TFilterBarItem[];
    parent?: string;
    parentChipName?: string;
    chipName?: string;
    singleValue?: boolean;
    displayName?: string;
    urlParamName?: string;
    urlParentParamName?: string;
    extraCategoryData?: IExtraCategoryFilterInput;
};

export type TExtraField =
    | IUserExtraFieldFragment
    | IExtraTrainingFieldFragment
    | IExtraOfferEventFieldFragment;

export type TExtraChoiceField =
    | IUserExtraChoiceField
    | ITrainingExtraChoiceField
    | IOfferEventExtraChoiceField;

export type TExtraStringField =
    | IUserExtraStringField
    | ITrainingExtraStringField
    | IOfferEventExtraStringField;

export type TDate = { startDate: Date; endDate: Date };

export interface IWebfontList {
    kind: string;
    items: IWebfontFamily[];
}

export interface IWebfontFamily {
    family: string;
    subsets: string[];
    variants: string[];
    files: { [variant: string]: string };
}

export enum IFontFamilyGroup {
    CustomFonts = 'customFonts',
    BasicFonts = 'basicFonts',
}

export type TFontFamilyOption = {
    label: IWebfontFamily['family'] | ICustomFontFragment['family'];
    value: IWebfontFamily['family'] | ICustomFontFragment['family'];
    group: string;
};

// This fontWeight is used for loading the fonts
export enum ELoadFontWeight {
    Thin = '100',
    ExtraLight = '200',
    Light = '300',
    Normal = '400',
    Medium = '500',
    SemiBold = '600',
    Bold = '700',
    ExtraBold = '800',
    Black = '900',
}

export enum EFontStyle {
    Normal = 'normal',
    Italic = 'italic',
}

export interface IPluvoFontFamily {
    name: string;
    styles: (keyof typeof ELoadFontWeight)[];
}

export interface ILoadFont {
    family: string;
    weights: (ELoadFontWeight | string | number)[];
    styles?: EFontStyle[];
}

export type TFontSizeOption = { label: string; value: IFontSize };
export type TFontWeightOption = { label: string; value: IFontWeight };

export type TFontWeightOptions = { [key: string]: TFontWeightOption[] };

export type TOption = { label: React.ReactNode; value: string };

export interface IRow {
    index: number;
    isSelected: boolean;
    onSelect: () => void;
}

export type TDateRange = {
    startDate?: DateTime;
    endDate?: DateTime;
};

export type TSelectOption = {
    label: string;
    value: string;
};

export type TTableAction = {
    tooltipTitle?: string;
    iconPath: string;
    onClick: (selectedItems: string[]) => void;
};

export interface IPaginationProps {
    paginationSettings: TPaginationSettings;
    setPage: (page: number) => void;
}

export interface ITableSelectionProps<T> {
    rows: IRow[];
    selected: string[];
    isSelected: boolean;
    isSelectedAll: boolean;
    onSelectAll: () => void;
    selectableItems: T[];
}

export interface ISearchProps {
    filters: TFilterBarItem[];
    searchQueryParam: string;
    selectedFilters: TFilterBarItem[];
    filtersLoading: boolean;
    handleSearch: (searchValue: string) => void;
    handleFilterSelect: (selected: TFilterBarItem[]) => void;
}

export enum IGlobalDrawerType {
    Offer = 'OFFER',
    OfferEvent = 'OFFER_EVENT',
    PortfolioItem = 'PORTFOLIO_ITEM',
    UserCreate = 'USER_CREATE',
    UserUpdate = 'USER_UPDATE',
    UserProfile = 'USER_PROFILE',
    TrainingAssignmentGrade = 'TRAINING_ASSIGNMENT_GRADE',
    TrainingAssignmentDetail = 'TRAINING_ASSIGNMENT_DETAIL',
    PortfolioManagementSettings = 'PORTFOLIO_MANAGEMENT_SETTINGS',
    OfferManagementSettings = 'OFFER_MANAGEMENT_SETTINGS',
    Group = 'GROUP',
    SkillCreate = 'SKILL_CREATE',
    SkillUpdate = 'SKILL_UPDATE',
}

export enum IGlobalDrawerActionType {
    Add = 'ADD',
    Delete = 'DELETE',
    Open = 'OPEN',
    Close = 'CLOSE',
}

export interface IGlobalDrawerAction {
    globalDrawer: IGlobalDrawer;
    type: IGlobalDrawerActionType;
}

export interface IGlobalDrawer {
    type: IGlobalDrawerType;
    actionType?: IGlobalDrawerActionType;
    open?: boolean;
    itemId?: string;
    location?: Location;
    props?: Record<string, unknown>;
}

export interface IContentQueryVariables {
    contentType: IContentTypeValue;
    objectId: string;
}

export interface IFilterPermissions {
    rolesFilter: IRole[];
}

export type TInputOption = {
    id: string;
    name: string;
    extraCategoryLabels:
        | string[]
        | IExtraCategoryLabelFragment[]
        | IExtraCategoryValueFragment[];
    isInfo: boolean;
};
