import { useState } from 'react';

export const useScormFilter = () => {
    const [searchQueryParam, setSearchQueryParam] = useState('');

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    return {
        filterValues: { q: searchQueryParam },
        handleSearch,
    };
};
