import { IOrganisationPlanInfoFragment } from 'graphql/types';

export type TPlanPackage = {
    title: string;
    description: string;
    features: string[];
    order: number;
    weight: number;
    topChoice?: boolean;
};

export type TCurrentPlanAmounts = {
    userAmount: IOrganisationPlanInfoFragment['userAmount'];
    trainingAmount: IOrganisationPlanInfoFragment['trainingAmount'];
};

export enum IPlanModalType {
    PlanLimit = 'PLAN_LIMIT',
    CustomPlan = 'CUSTOM_PLAN',
    PlanManager = 'PLAN_MANAGER',
    PlanDowngrade = 'PLAN_DOWNGRADE',
    IntervalChange = 'INTERVAL_CHANGE',
}

export type TPlanModal =
    | IPlanModalType.PlanLimit
    | IPlanModalType.CustomPlan
    | IPlanModalType.PlanManager
    | IPlanModalType.PlanDowngrade
    | IPlanModalType.IntervalChange;
