import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiPlay, mdiClose } from '@mdi/js';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import { Icon } from 'common/components/Icon';
import { IVideoModuleListItemFragment } from 'graphql/types';
import { Embed } from 'common/components/Embed';
import { ActionButton } from 'common/components/ActionButton';
import { ModuleConditionsChips } from 'training/components/ModuleConditionsChips';
import { TTrainingModuleProps } from 'training/types';
import { trainingStudentView } from 'hydra/pages/TrainingPage';
import {
    ListItem,
    ListItemMedia,
    ListItemText,
} from 'common/components/ListItem';
import { Link } from 'common/components/Link';
import { ModuleSecondaryAction } from 'training/components/ModuleSecondaryAction';

export const VideoModule = ({
    id,
    trainingId,
    title,
    description,
    showLarge,
    autoplay,
    disabled,
    conditions,
    alwaysOpen,
    startDate,
    image,
    conditionEffect,
    groupConditionEffect,
    video,
    canUpdate,
}: TTrainingModuleProps<IVideoModuleListItemFragment>) => {
    const [translate] = useTranslation();
    const studentView = useReactiveVar(trainingStudentView);

    const [active, setActive] = useState(showLarge);
    const [embedError, setEmbedError] = useState(false);

    const { embedUrl, thumbnail } = video || {};

    const itemContent = (
        <>
            <ListItemMedia
                color="primary"
                image={image?.url || thumbnail?.url || ''}
            >
                <Icon path={mdiPlay} size="2.4rem" sizeSm="4rem" />
            </ListItemMedia>
            <ListItemText
                primary={title || video?.title}
                secondary={description}
            >
                <ModuleConditionsChips
                    alwaysOpen={alwaysOpen}
                    conditionEffect={conditionEffect}
                    conditions={conditions}
                    groupConditionEffect={groupConditionEffect}
                    startDate={startDate}
                />
            </ListItemText>
        </>
    );

    if (disabled) {
        return <ListItem disabled>{itemContent}</ListItem>;
    }

    if (!studentView && canUpdate) {
        return (
            <ListItem
                button
                component={Link}
                params={{
                    trainingId,
                    moduleId: id,
                    type: 'video',
                }}
                to="TRAINING_UPDATE_MODULE"
            >
                {itemContent}

                <ModuleSecondaryAction forUpdate />
            </ListItem>
        );
    }

    let embed = !!embedUrl ? (
        <Embed
            autoplay={autoplay}
            src={embedUrl}
            onError={() => setEmbedError(true)}
        />
    ) : (
        <ListItem color="body">{translate('videoNotLoaded')}</ListItem>
    );

    if (embedError) {
        embed = <ListItem color="body">{embed}</ListItem>;
    }

    // Show large video
    if (active) {
        return (
            <Box my={2} position="relative">
                {embed}

                <Box
                    mr={embedError ? 3.5 : 0}
                    p={embedError ? 1.5 : 2}
                    position="absolute"
                    right={0}
                    top={0}
                >
                    <ActionButton
                        size="medium"
                        onClick={() => {
                            setEmbedError(false);
                            setActive(false);
                        }}
                    >
                        <Icon path={mdiClose} />
                    </ActionButton>
                </Box>
            </Box>
        );
    }

    return (
        <ListItem button disabled={disabled} onClick={() => setActive(true)}>
            {itemContent}

            <ModuleSecondaryAction actionText={translate('play')} />
        </ListItem>
    );
};
