import {
    TableHead as MuiTableHead,
    TableHeadProps,
    SxProps,
} from '@mui/material';

import { withSxProp } from 'common/utils/props';

const tableHeadStyle: SxProps = {
    '.MuiTableCell-root': {
        fontWeight: 'bold',
        lineHeight: '3.6rem',
    },
};

export const TableHead = ({ sx, ...other }: TableHeadProps) => (
    <MuiTableHead
        {...other}
        sx={[
            tableHeadStyle,
            // Spread sx from props
            ...withSxProp(sx),
        ]}
    />
);
