import { IBasicUserFragment } from 'graphql/types';

export function isUserSelected(
    selectedUsers: IBasicUserFragment[],
    user: IBasicUserFragment
) {
    return selectedUsers.some((selectedUsers) => selectedUsers.id === user.id);
}

export function isUserAdded(
    addedUsers: IBasicUserFragment[],
    user: IBasicUserFragment
) {
    return addedUsers.some((addedUsers) => addedUsers.id === user.id);
}

export function getUserSelectStatus(
    selectedUsers: IBasicUserFragment[],
    addedUsers: IBasicUserFragment[],
    user: IBasicUserFragment
) {
    const isSelected = isUserSelected(selectedUsers, user);
    const isAdded = isUserAdded(addedUsers, user);

    return { isSelected, isAdded };
}
