import {
    IDefaultFileType,
    useOrganisationDefaultFilesQuery,
} from 'graphql/types';

interface IProps {
    type: IDefaultFileType;
}

export const useInitialDefaultFile = ({ type }: IProps) => {
    const { data, loading, error } = useOrganisationDefaultFilesQuery({
        variables: { type },
    });

    const defaultFiles = data?.organisationDefaultFiles || [];
    const initialFile = defaultFiles.length ? defaultFiles[0].file : undefined;

    return { initialFile, loading, error };
};
