import { styled } from 'styled-components';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

import { Icon } from 'common/components/Icon';
import { ActionButton } from 'common/components/ActionButton';

interface IProps {
    className?: string;
    right?: boolean;
    onClick?(): void;
}

const BaseSlideButton = ({ className, right, onClick }: IProps) => (
    <ActionButton
        className={className}
        color="whiteTransparent"
        size="medium"
        onClick={onClick}
    >
        <Icon path={right ? mdiChevronRight : mdiChevronLeft} size="3rem" />
    </ActionButton>
);

export const SlideButton = styled(BaseSlideButton)`
    background-color: rgba(255, 255, 255, 0.5);
`;
