import { Box, BoxProps, SxProps } from '@mui/material';
import { motion, Transition } from 'framer-motion';
import { mdiChat } from '@mdi/js';

import { withSxProp } from 'common/utils/props';
import { IUserListItemFragment, IUser } from 'graphql/types';
import { UserAvatar } from 'user/components/UserAvatar';
import { Link } from 'common/components/Link';
import { Icon } from 'common/components/Icon';

const userInfoStyle: SxProps = {
    display: 'inline-flex',
};

const springTransition: Transition = {
    type: 'spring',
    damping: 10,
    stiffness: 100,
};

interface IProps extends BoxProps {
    user: IUserListItemFragment | IUser;
    iconProps?: BoxProps;
}

export const UserInfo = ({ user, iconProps, sx, ...other }: IProps) => (
    <Box
        component={Link}
        params={{ id: user.id }}
        sx={[userInfoStyle, ...withSxProp(sx)]}
        to="USER_CHAT"
        {...other}
    >
        <Box
            component={motion.div}
            initial="initInfo"
            sx={{ display: 'inline-flex', position: 'relative' }}
            whileHover="hoverInfo"
        >
            <Box
                component={motion.div}
                sx={{ mr: 1.25 }}
                transition={springTransition}
                variants={{
                    initInfo: {
                        opacity: 1,
                        scale: 1,
                    },
                    hoverInfo: {
                        opacity: 0,
                        scale: 0.5,
                    },
                }}
                {...iconProps}
            >
                <UserAvatar size={23} user={user} />
            </Box>

            {!!user?.name && <Box>{user.name}</Box>}

            <Box
                component={motion.div}
                sx={{
                    position: 'absolute',
                    left: 0,
                }}
                transition={springTransition}
                variants={{
                    initInfo: {
                        opacity: 0,
                        scale: 0.5,
                    },
                    hoverInfo: {
                        opacity: 1,
                        scale: 1,
                    },
                }}
            >
                <Icon path={mdiChat} size="2.3rem" />
            </Box>
        </Box>
    </Box>
);
