import { Editor, Transforms } from 'slate';
import {
    setDefaults,
    getBlockAbove,
    DEFAULTS_IMAGE,
    ImagePluginOptions,
    insertImage,
} from '@udecode/slate-plugins';

export const addOrReplaceImage = (
    editor: Editor,
    url: string,
    options?: ImagePluginOptions<'type'>
) => {
    const { img } = setDefaults(options, DEFAULTS_IMAGE);

    const fileNode = getBlockAbove(editor, {
        match: { type: img.type },
    });

    if (fileNode) {
        Transforms.setNodes(editor, {
            url,
        });

        return;
    }

    insertImage(editor, url, { img });
};
