import { mdiTransitConnectionVariant, mdiSchool, mdiSeat } from '@mdi/js';

import { IOfferEventType } from 'graphql/types';
import { translate } from 'utils/i18n';

export enum EFilterType {
    training = 'training',
    elearning = 'elearning',
    meetingWebinar = 'meeting-webinar',
}

export interface ITypeFilter {
    key: EFilterType;
    labelTransKey: string;
    icon: string;
    types: IOfferEventType[];
    notPublishedMessage: string;
    published?: boolean;
}

export const OFFER_TYPE_FILTERS: ITypeFilter[] = [
    {
        key: EFilterType.training,
        labelTransKey: 'offer.filters.training',
        icon: mdiTransitConnectionVariant,
        types: [IOfferEventType.Training],
        notPublishedMessage: translate('offer.trainingNotPublished'),
    },
    {
        key: EFilterType.elearning,
        labelTransKey: 'offer.filters.elearning',
        icon: mdiSchool,
        types: [IOfferEventType.Elearning, IOfferEventType.Course],
        notPublishedMessage: translate('offer.elearningNotPublished'),
    },
    {
        key: EFilterType.meetingWebinar,
        labelTransKey: 'offer.filters.meetingWebinar',
        icon: mdiSeat,
        types: [IOfferEventType.Meeting, IOfferEventType.Webinar],
        notPublishedMessage: translate('offer.meetingWebinarNotPublished'),
    },
];
