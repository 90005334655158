import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import { useApolloError } from 'common/hooks/useApolloError';
import { TFilterBarItem } from 'common/types';
import {
    IContentTypeValue,
    IExtraCategoryFilter,
    IExtraCategorySearch,
    IExtraCategoryOption,
    useExtraCategoryFiltersQuery,
    IOfferRole,
    useCurrentUserQuery,
    IRole,
    IOfferEventEnrollmentFilter,
} from 'graphql/types';
import {
    getExtraCategoryFilterItems,
    getExtraCategoryQueryParams,
} from 'organisation/utils/extraCategory';
import { getSelectedFilters } from 'common/utils/filter';
import { EFilterType } from 'offer/constants/offer';

import { useOfferTypeFilter } from './useOfferTypeFilter';

export const useOfferEventSearch = (
    extraCategoryQueryFilters: IExtraCategorySearch[]
) => {
    const { showApolloError } = useApolloError();
    const [translate] = useTranslation();
    const { currentTypeFilter } = useOfferTypeFilter();

    const { filterParams, setFilterParam } = useUrlFilterParams('offer');

    const searchQueryParam = filterParams.q || '';
    const selectedFilterParams = filterParams.filters;

    const { data, loading } = useExtraCategoryFiltersQuery({
        variables: { contentType: IContentTypeValue.Offer },
        onError: showApolloError,
    });
    const { data: currentUserData } = useCurrentUserQuery();

    const { roles } = currentUserData?.currentUser || {};
    const { extraCategoriesFilters } = data || {};

    const isManager =
        roles?.includes(IRole.Owner) || roles?.includes(IRole.Manager);
    const isAuthor = roles?.includes(IRole.Author);
    const isTrainer = roles?.includes(IRole.Trainer);
    const roleTranslation = translate('userRole');

    // Filters from the offers query that are based on offer availability.
    const queryFilters = useMemo(() => {
        const roleFilterItems = [];

        if (
            (isManager || isTrainer) &&
            currentTypeFilter?.key === EFilterType.meetingWebinar
        ) {
            roleFilterItems.push({
                name: 'trainer',
                value: IOfferRole.Trainer,
                displayName: translate('trainer'),
                parent: 'role_internal',
                parentChipName: roleTranslation,
            });
        }

        if (isManager || isAuthor) {
            roleFilterItems.push({
                name: 'author',
                value: IOfferRole.Author,
                displayName: translate('author'),
                parent: 'role_internal',
                parentChipName: roleTranslation,
            });
        }

        const roleFilter: TFilterBarItem = {
            name: 'role_internal',
            value: '',
            displayName: roleTranslation,
            children: [
                {
                    name: 'enrolled',
                    value: IOfferRole.Enrolled,
                    displayName: translate('enrolled'),
                    parent: 'role_internal',
                    parentChipName: roleTranslation,
                },

                ...roleFilterItems,
            ],
        };

        let enrollmentFilter: TFilterBarItem[] = [];

        if (isManager || isAuthor || isTrainer) {
            const parentDisplayName = translate(
                'offerEventEnrollmentFilter.enrollments'
            );

            enrollmentFilter = [
                {
                    name: 'enrollments',
                    value: '',
                    displayName: parentDisplayName,
                    singleValue: true,
                    children: [
                        {
                            name: 'noEnrollments',
                            value: IOfferEventEnrollmentFilter.NoEnrollments,
                            displayName: translate(
                                'offerEventEnrollmentFilter.noEnrollments'
                            ),
                            parent: 'enrollments',
                            parentChipName: parentDisplayName,
                        },
                        {
                            name: 'minNotReached',
                            value: IOfferEventEnrollmentFilter.MinNotReached,
                            displayName: translate(
                                'offerEventEnrollmentFilter.minNotReached'
                            ),
                            parent: 'enrollments',
                            parentChipName: parentDisplayName,
                        },
                        {
                            name: 'maxReached',
                            value: IOfferEventEnrollmentFilter.MaxReached,
                            displayName: translate(
                                'offerEventEnrollmentFilter.maxReached'
                            ),
                            parent: 'enrollments',
                            parentChipName: parentDisplayName,
                        },
                        {
                            name: 'usersWaiting',
                            value: IOfferEventEnrollmentFilter.UsersWaiting,
                            displayName: translate(
                                'offerEventEnrollmentFilter.usersWaiting'
                            ),
                            parent: 'enrollments',
                            parentChipName: parentDisplayName,
                        },
                    ],
                },
            ];
        }

        const categoryFilters = extraCategoryQueryFilters.reduce(
            (acc: IExtraCategoryFilter[], curFilter) => {
                const { id, value } = curFilter;

                const extraCategoryFilter = extraCategoriesFilters?.find(
                    (extraCategoryFilter) => extraCategoryFilter.id === id
                );

                if (!extraCategoryFilter) return acc;

                if (
                    extraCategoryFilter.categoryType ===
                        IExtraCategoryOption.Choice ||
                    extraCategoryFilter.categoryType ===
                        IExtraCategoryOption.String
                ) {
                    const existingIndex = acc.findIndex((fil) => fil.id === id);

                    // If we already have a filter with the same id, we just add the value to its values
                    if (existingIndex !== -1) {
                        acc[existingIndex].values.push(value);

                        return acc;
                    }

                    // Make sure if the filter with the same id is not already present
                    // to only add the given value to its values
                    return [
                        ...acc,
                        { ...extraCategoryFilter, values: [value] },
                    ];
                }

                return [...acc, extraCategoryFilter];
            },
            []
        );

        return [
            roleFilter,
            ...enrollmentFilter,
            ...getExtraCategoryFilterItems(categoryFilters),
        ];
    }, [extraCategoryQueryFilters, extraCategoriesFilters, currentTypeFilter]);

    const selectedFilters = useMemo(() => {
        // Internal role filters should be handled separately
        const roleFilters = queryFilters.filter(
            (filter) => filter.name === 'role_internal'
        );

        const enrollmentFilters = queryFilters.filter(
            (filter) => filter.name === 'enrollments'
        );

        const filters = [
            ...roleFilters,
            ...enrollmentFilters,
            ...getExtraCategoryFilterItems(extraCategoriesFilters),
        ];

        return getSelectedFilters(
            JSON.parse(decodeURIComponent(selectedFilterParams || '{}')),
            filters
        );
    }, [filterParams, queryFilters]);

    const handleSearch = (searchValue: string) => {
        setFilterParam('q', searchValue);
    };

    const handleFilterSelect = (selected: TFilterBarItem[]) => {
        const queryParams = getExtraCategoryQueryParams(selected);

        let filterValue = '';

        // Check if queryParams is not empty.
        if (!!Object.keys(queryParams).length) {
            filterValue = encodeURIComponent(JSON.stringify(queryParams));
        }
        setFilterParam('filters', filterValue);
    };

    return {
        filters: queryFilters,
        searchQueryParam: searchQueryParam || '',
        selectedFilters,
        filtersLoading: loading,
        handleSearch,
        handleFilterSelect,
    };
};
