import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, TableRow, TableBody, Box } from '@mui/material';

import { TableCell } from 'common/components/Table';
import { Typography } from 'common/components/Typography';
import { BoxLoader } from 'common/components/Loader';
import { useTrainingParticipantReports } from 'training/hooks';

import { TrainingParticipantReportTableRow } from './TrainingParticipantReportTableRow';

interface IProps {
    trainingId: string;
    userId: string;
    onClearCache?: () => void;
}

export const TrainingParticipantReportTable = ({
    trainingId,
    userId,
    onClearCache,
}: IProps) => {
    const [translate] = useTranslation();

    const { loading, moduleGroups, participantReports } =
        useTrainingParticipantReports(
            trainingId,
            [userId],
            ['CertificateModule']
        );

    if (loading) return <BoxLoader />;

    // Get participant report of user
    const participantReport = participantReports.get(userId);

    if (!participantReport) {
        return (
            <Box sx={{ mt: 3.5 }}>
                <Typography sx={{ mb: 0 }}>
                    {translate('report.participantHasNoTrainingReports')}
                </Typography>
            </Box>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {moduleGroups.map((group) => (
                        <React.Fragment key={group.id}>
                            <TableRow>
                                <TableCell colSpan={3} sx={{ pt: 3 }}>
                                    <Typography
                                        sx={{ fontWeight: 700 }}
                                        variant="body2"
                                    >
                                        {group.title}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {group.modules.map((module) => (
                                <TrainingParticipantReportTableRow
                                    key={module.id}
                                    module={module}
                                    report={participantReport[module.id]}
                                    trainingId={trainingId}
                                    userId={userId}
                                    onClearCache={onClearCache}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
