import { useMemo } from 'react';
import { FormikContextType } from 'formik';

import {
    IFilterFormField,
    IFilterFormItem,
    TFilterFormValue,
    TFilterFormValues,
} from 'common/types';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import { IReportFilterValues, TReportType } from 'report/types';
import {
    filtersToBase64String,
    filtersToQueryParams,
} from 'common/utils/filter';
import { IPortfolioReportRelatedObjectsInput } from 'graphql/types';

// Generic filter hook for course report
export const useReportFilter = (
    filters: IFilterFormItem[],
    type?: TReportType
) => {
    const { filterParams, setFilterParam } =
        useUrlFilterParams('portfolioReport');

    const searchQueryParam = filterParams.q || '';
    const selectedFilterParams = filterParams.filters;

    const handleSearch = (searchValue: string) => {
        setFilterParam('q', searchValue);
    };

    const handleFilterChange = (values?: TFilterFormValues) => {
        const filterString = filtersToBase64String(filters, values);

        setFilterParam('filters', filterString);
    };

    const selectedFilters: IReportFilterValues = useMemo(() => {
        if (!selectedFilterParams) return {};

        try {
            return JSON.parse(window.atob(selectedFilterParams));
        } catch {
            return {};
        }
    }, [selectedFilterParams]);

    // Parse selected filters for query
    const filterValuesForQuery = useMemo(() => {
        const {
            progress,
            score,
            trainingIds,
            offerEventIds,
            courseIds,
            externalContentIds,
            scormIds,
            ...restFilters
        } = selectedFilters;

        const filterValues = filtersToQueryParams<IReportFilterValues>(
            filters,
            restFilters
        );

        if (progress) {
            const [progressFrom, progressTo] = progress;

            // Don't pass progressFrom if it's 0
            if (progressFrom !== 0) {
                filterValues.progressFrom = progressFrom;
            }

            // Don't pass progressTo if it's 100
            if (progressTo !== 100) {
                filterValues.progressTo = progressTo;
            }
        }

        if (score) {
            const [scoreFrom, scoreTo] = score;

            // Don't pass scoreFrom if it's 0
            if (scoreFrom !== 0) {
                filterValues.scoreFrom = scoreFrom;
            }

            // Don't pass scoreTo if it's 100
            if (scoreTo !== 100) {
                filterValues.scoreTo = scoreTo;
            }
        }

        let relatedObjectUids: IPortfolioReportRelatedObjectsInput = {};

        if (trainingIds) {
            // Of one of the following types, it is considered a parent related item
            if (type === 'portfolio' || type === 'course') {
                filterValues.relatedObjectTrainingIds = trainingIds.map(
                    (trainingFilter) => trainingFilter.id
                );
            } else {
                filterValues.relatedObjectUids = {
                    trainingIds: trainingIds.map(
                        (trainingFilter) => trainingFilter.id
                    ),
                } as IPortfolioReportRelatedObjectsInput;

                relatedObjectUids = {
                    trainingIds: trainingIds.map(
                        (trainingFilter) => trainingFilter.id
                    ),
                };
            }
        }

        if (offerEventIds) {
            // Of one of the following types, it is considered a parent related item
            if (type === 'portfolio' || type === 'course') {
                filterValues.relatedObjectOfferEventIds = offerEventIds.map(
                    (offerEventFilter) => offerEventFilter.id
                );
            } else {
                relatedObjectUids = {
                    ...relatedObjectUids,
                    offerEventIds: offerEventIds.map(
                        (offerEventFilter) => offerEventFilter.id
                    ),
                };
            }
        }

        if (courseIds) {
            relatedObjectUids = {
                ...relatedObjectUids,
                courseIds: courseIds.map((courseFilter) => courseFilter.id),
            };
        }

        if (externalContentIds) {
            relatedObjectUids = {
                ...relatedObjectUids,
                externalContentIds: externalContentIds.map(
                    (externalContentFilter) => externalContentFilter.id
                ),
            };
        }

        if (scormIds) {
            relatedObjectUids = {
                ...relatedObjectUids,
                scormIds: scormIds.map((scormFilter) => scormFilter.id),
            };
        }

        // When no related objects are selected prevent empty object
        // from being sent through the query
        filterValues.relatedObjectUids = !!Object.keys(relatedObjectUids).length
            ? relatedObjectUids
            : undefined;

        return filterValues;
    }, [selectedFilters, filters]);

    const onFilterChangeValue = (
        name: string,
        _value: TFilterFormValue,
        _field: IFilterFormField,
        formikContext: FormikContextType<TFilterFormValues>
    ) => {
        switch (name) {
            case 'progress':
                // Get initial value for score
                const scoreValue = filters
                    .find((filter) => filter.key === 'score')
                    ?.fields.find(
                        (field) => field.name === 'score'
                    )?.initialValue;

                formikContext.setFieldValue('score', scoreValue);
                break;
            case 'score':
                // Get initial value for progress
                const progressValue = filters
                    .find((filter) => filter.key === 'progress')
                    ?.fields.find(
                        (field) => field.name === 'progress'
                    )?.initialValue;

                formikContext.setFieldValue('progress', progressValue);
        }
    };

    return {
        filters,
        filterValues: { ...selectedFilters, q: searchQueryParam },
        filterValuesForQuery: { ...filterValuesForQuery, q: searchQueryParam },
        handleSearch,
        handleFilterChange,
        onFilterChangeValue,
    };
};
