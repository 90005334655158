import { Box, SxProps, Stack } from '@mui/material';
import { mdiDotsVertical } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'common/components/IconButton';
import { ICommentFragment } from 'graphql/types';
import { Typography } from 'common/components/Typography';
import { Text } from 'common/components/Text';
import { DropdownMenu } from 'common/components/DropdownMenu';
import { formatDate } from 'common/utils/formatDate';
import { UserAvatar } from 'user/components/UserAvatar';

interface IProps extends ICommentFragment {
    actions?: React.ReactNode;
    className?: string;
    commentRef?:
        | React.RefObject<HTMLDivElement>
        | React.RefCallback<HTMLDivElement>;
    children?: React.ReactNode;
    highlight?: boolean;
    isLast?: boolean;
}

const commentStyle: SxProps = {
    position: 'relative',
    mb: 1.5,

    // Highlight styling
    ':before': {
        position: 'absolute',
        content: '""',
        backgroundColor: 'primary.main',
        top: -5,
        right: 0,
        left: -10,
        bottom: -5,
        zIndex: -1,
        opacity: 0,
        borderRadius: 1,

        '@keyframes highlight': {
            '0%': {
                opacity: 0.2,
            },
            '75%': {
                opacity: 0.2,
            },
            '100%': {
                opacity: 0,
            },
        },
    },

    '.actionButton': {
        opacity: 0,
        transition: 'opacity 150ms linear',
    },

    '&:hover .actionButton': {
        opacity: 1,
    },
};

export const Comment = ({
    actions,
    children,
    user,
    date,
    text,
    isLast,
    highlight,
    commentRef,
}: IProps) => {
    const [translate] = useTranslation();

    return (
        <Box
            ref={commentRef}
            sx={[
                commentStyle,
                // Remove margin bottom for last comment
                !!isLast && { mb: 0 },
                // Add animation for highlight
                !!highlight && {
                    ':before': { animation: 'highlight 3000ms ease-out' },
                },
            ]}
        >
            <Box sx={{ display: 'flex' }}>
                <Box mr={2} mt={0.5}>
                    <UserAvatar size={32} user={user} />
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Stack direction="row" spacing={1}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                            variant="body2"
                        >
                            {user.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.secondary',
                            }}
                            variant="body2"
                        >
                            {formatDate(date)}
                        </Typography>
                    </Stack>
                    {!text && (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="body2">
                                {translate('fileUploaded')}
                            </Typography>
                        </Box>
                    )}
                    <Text color="text.primary" component="div" variant="body2">
                        {children || text}
                    </Text>
                </Box>

                <Box
                    className="actionButton"
                    sx={{
                        ml: 'auto',
                        mr: { xs: '-15px', sm: '-22px' },
                        minWidth: '36px',
                    }}
                >
                    {actions && (
                        <DropdownMenu
                            anchor={
                                <IconButton
                                    iconPath={mdiDotsVertical}
                                    iconSize="2rem"
                                    sx={{ position: 'relative' }}
                                />
                            }
                        >
                            {actions}
                        </DropdownMenu>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
