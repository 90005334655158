import { IRole, useCurrentUserQuery } from 'graphql/types';

export const useOfferPermissions = () => {
    const { data: currentUserData } = useCurrentUserQuery();

    const currentUser = currentUserData?.currentUser;

    const currentUserIsManager = currentUser?.roles?.includes(IRole.Manager);
    const currentUserIsAuthor = currentUser?.roles?.includes(IRole.Author);

    return {
        currentUserIsManager,
        currentUserIsAuthor,
        currentUser,
    };
};
