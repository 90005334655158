import { mdiChevronRight } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/components/Icon';
import {
    ListItem,
    ListItemActionText,
    ListItemMedia,
    ListItemSecondaryAction,
    ListItemText,
} from 'common/components/ListItem';
import { Link, ILinkProps } from 'common/components/Link';
import { TColorKeys } from 'theme/palette';
import { Chip, ChipGroup } from 'common/components/Chip';
import { ScoringChips } from 'common/components/ScoringChips';
import {
    IPortfolioItemFragment,
    IPortfolioItemListItemOldFragment,
} from 'graphql/types';
import { formatDate } from 'common/utils/formatDate';

interface IProps extends Omit<ILinkProps, 'color'> {
    disabled?: boolean;
    image?: string;
    title?: string;
    className?: string;
    description?: React.ReactNode;
    icon?: string;
    color?: TColorKeys;
    item?: IPortfolioItemFragment | IPortfolioItemListItemOldFragment;
    onClick?: (id: string) => void;
}

export const PortfolioListItem = ({
    image,
    title,
    description,
    icon,
    item,
    onClick,
    ...other
}: IProps) => {
    const [translate] = useTranslation();

    const {
        id,
        title: portfolioItemTitle,
        scoring,
        startDate,
        endDate,
    } = item || {};

    if (!!startDate && !!endDate) {
        description = `${formatDate(startDate, 'dd MMMM yyyy')} - ${formatDate(
            endDate,
            'dd MMMM yyyy'
        )}`;
    } else if (!!startDate && !endDate) {
        description = `${translate('portfolioStartDate')}: ${formatDate(
            startDate,
            'dd MMMM yyyy'
        )}`;
    } else if (!startDate && !!endDate) {
        description = `${translate('portfolioEndDate')}: ${formatDate(
            endDate,
            'dd MMMM yyyy'
        )}`;
    }

    const isExpired = !endDate ? false : new Date(endDate) < new Date();

    return (
        <ListItem
            button
            component={Link}
            sx={{ position: 'relative' }}
            onClick={() => onClick?.(id)}
            {...other}
        >
            <ListItemMedia className="itemMedia" color="primary" image={image}>
                {icon && (
                    <Icon
                        className="icon"
                        path={icon}
                        size="2.2rem"
                        sizeSm="3rem"
                    />
                )}
            </ListItemMedia>

            <ListItemText
                primary={title || portfolioItemTitle}
                secondary={description}
            >
                <ChipGroup>
                    {isExpired && (
                        <Chip bgColor="warning" label={translate('expired')} />
                    )}

                    <ScoringChips color="default" scoring={scoring} />
                </ChipGroup>
            </ListItemText>

            <ListItemActionText>{translate('open')}</ListItemActionText>

            <ListItemSecondaryAction hideXs>
                <Icon path={mdiChevronRight} size="4rem" />
            </ListItemSecondaryAction>
        </ListItem>
    );
};
