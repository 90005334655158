import { useMemo, useState } from 'react';

import {
    IRole,
    IUserExtraChoiceField,
    useCurrentUserQuery,
    useExtraUserFieldsQuery,
} from 'graphql/types';
import { useApolloError } from 'common/hooks/useApolloError';
import {
    getExtraFieldsName,
    isExtraChoiceField,
} from 'common/utils/extraFields';
import { IFilterPermissions, TFilterFormValues } from 'common/types';
import {
    IUserExtraFieldsInputOption,
    useExtraFieldsFilters,
} from 'user/hooks/useExtraFieldsFilters';
import { getUserFilters } from 'user/constants/filter';
import { useUrlFilterParams } from 'common/hooks/useUrlFilterParams';
import {
    filtersToBase64String,
    filtersToQueryParams,
} from 'common/utils/filter';
import { IUserFilterValues } from 'user/types';

export const useUserFilter = (permissions?: IFilterPermissions) => {
    const { showApolloError } = useApolloError();
    const [searchQueryParam, setSearchQueryParam] = useState('');
    const { filterParams, setFilterParam } = useUrlFilterParams('user');

    const { data: currentUserData, loading: currentUserLoading } =
        useCurrentUserQuery();
    const { currentUser } = currentUserData || {};
    const isGroupManager = currentUser?.isGroupManager;
    const isManager = currentUser?.roles?.includes(IRole.Manager);

    const canUseNonPublicExtraField =
        !currentUserLoading && (isManager || isGroupManager);

    const { extraCategoryFilters } = useExtraFieldsFilters(
        canUseNonPublicExtraField
    );

    const filters = [...getUserFilters(permissions), ...extraCategoryFilters];

    const { data: extraUserFieldsData, loading: extraUserFieldsLoading } =
        useExtraUserFieldsQuery({
            onError: showApolloError,
        });

    const { extraUserFields } = extraUserFieldsData || {};

    const selectedFilterParams = filterParams.filters;

    const handleSearch = (searchValue: string) => {
        setSearchQueryParam(searchValue);
    };

    const handleFilterChange = (values?: TFilterFormValues) => {
        const filterString = filtersToBase64String(filters, values);

        setFilterParam('filters', filterString);
    };

    const selectedFilters = useMemo(() => {
        if (!selectedFilterParams) return {};

        try {
            return JSON.parse(window.atob(selectedFilterParams));
        } catch {
            return {};
        }
    }, [selectedFilterParams]);

    const filterValuesForQuery = useMemo(() => {
        const filterValues = filtersToQueryParams<IUserFilterValues>(
            filters,
            selectedFilters
        );

        const extraFieldsObject: Record<string, string[]> = {};

        // Check if extra fields are selected, if so create the correct query object
        extraUserFields
            ?.filter((filter) => isExtraChoiceField(filter))
            .forEach((extraUserField) => {
                const { name, multiple } =
                    extraUserField as IUserExtraChoiceField;

                const filterValueKey = `extraFields-${getExtraFieldsName(name)}`;

                if (multiple) {
                    const selectedFilterValues = filterValues[
                        filterValueKey
                    ] as IUserExtraFieldsInputOption[] | undefined;

                    if (selectedFilterValues) {
                        extraFieldsObject[name] = selectedFilterValues.map(
                            (selectedFilterValue) => selectedFilterValue.name
                        );

                        delete filterValues[filterValueKey];
                    }

                    return;
                }

                const selectedFilterValues = filterValues[filterValueKey] as
                    | IUserExtraFieldsInputOption
                    | undefined;

                if (selectedFilterValues) {
                    extraFieldsObject[name] = [selectedFilterValues.name];

                    delete filterValues[filterValueKey];
                }
            });

        if (!!Object.keys(extraFieldsObject).length) {
            filterValues.extraFields = JSON.stringify(extraFieldsObject);
        }

        return filterValues;
    }, [selectedFilters, extraUserFields]);

    return {
        filters,
        filterValues: { ...selectedFilters, q: searchQueryParam },
        filterValuesForQuery: {
            ...filterValuesForQuery,
            q: searchQueryParam,
        },
        loading: extraUserFieldsLoading,
        onSearch: handleSearch,
        onFilterChange: handleFilterChange,
    };
};
