import { Box } from '@mui/material';

import { IExternalContentListItemFragment } from 'graphql/types';
import { ExternalContentListItem } from 'external/components/ExternalContentListItem';

import { PortfolioItemContentBase } from './PortfolioItemContentBase';

export const ExternalContentPortfolioItemContent = (
    props: React.ComponentProps<typeof PortfolioItemContentBase>
) => {
    const { portfolioItem } = props;

    const externalContent =
        portfolioItem.relatedObject as IExternalContentListItemFragment;

    return (
        <PortfolioItemContentBase {...props}>
            <Box px={4} py={2}>
                <ExternalContentListItem
                    externalContent={externalContent}
                    onClick={async () => {
                        window.open(externalContent.link, '_blank');
                    }}
                />
            </Box>
        </PortfolioItemContentBase>
    );
};
