import { useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import {
    getCustomFontFamilyOptions,
    getFontSizeOptions,
    getFontWeightOption,
} from 'common/utils/fonts';
import {
    IPluvoFontFamily,
    IFontFamilyGroup,
    TFontFamilyOption,
    TFontSizeOption,
    TFontWeightOptions,
} from 'common/types';
import { useCustomFontsQuery, IFontWeight } from 'graphql/types';
import { Loader } from 'common/components/Loader';
import { FONT_WEIGHT_OPTIONS, FONT_WEIGHTS } from 'common/constants/fonts';
import { useSnackbar } from 'common/hooks/useSnackbar';

interface IProps {
    children(
        families: TFontFamilyOption[],
        sizes: TFontSizeOption[],
        fontWeights: TFontWeightOptions
    ): React.ReactFragment;
}

export const FontWrapper = ({ children }: IProps) => {
    const { data, loading } = useCustomFontsQuery();
    const [displaySnackbar] = useSnackbar();
    const [basicFontWeightOptions, setBasicFontWeightOptions] =
        useState<TFontWeightOptions>({});
    const [translate] = useTranslation();

    const { customFonts } = data?.organisation?.theme.typography || {};

    const basicFontsIsSet = !!Object.keys(basicFontWeightOptions).length;

    useEffect(() => {
        if (basicFontsIsSet) return;

        const fetchData = async () => {
            // get the data from the api
            const response = await fetch('https://fonts.pluvo.com/fonts.json');

            // convert the data to json
            const data: Record<string, IPluvoFontFamily> =
                await response.json();

            const parsedFontWeightOptions: TFontWeightOptions = Object.values(
                data
            ).reduce((acc, curVal) => {
                const isCustomFont = customFonts?.some(
                    (font) => font.family === curVal.name
                );

                // If same family is present in custom fonts skip the basic font version
                if (isCustomFont) return acc;

                return {
                    ...acc,
                    [curVal.name]: curVal.styles
                        .map((style) =>
                            style === 'Normal'
                                ? getFontWeightOption(FONT_WEIGHTS['400'])
                                : getFontWeightOption(IFontWeight[style]) ||
                                  null
                        )
                        .filter(Boolean)
                        // Sort font weights by the order of the weightOptions array
                        .sort(
                            (a, b) =>
                                FONT_WEIGHT_OPTIONS.findIndex(
                                    (weight) => weight.value === a.value
                                ) -
                                FONT_WEIGHT_OPTIONS.findIndex(
                                    (weight) => weight.value === b.value
                                )
                        ),
                };
            }, {});

            setBasicFontWeightOptions(parsedFontWeightOptions);
        };

        fetchData()
            // make sure to catch any error
            .catch((error) => {
                displaySnackbar(translate('fonts.basicFontsLoadingError'), {
                    variant: 'error',
                });

                Sentry.captureException(error.message);
            });
    }, [
        basicFontWeightOptions,
        basicFontsIsSet,
        customFonts,
        displaySnackbar,
        translate,
    ]);

    // Create useMemo to prevent re-rendering of the children
    const { fontFamilyOptions, fontSizeOptions, fontWeightOptions } =
        useMemo(() => {
            const fontFamilyOptions: TFontFamilyOption[] = [];

            const customFontFamilyOptions = getCustomFontFamilyOptions(
                customFonts || undefined
            );

            // Create basic font family options from font weight option keys
            const basicFontFamilyOptions = Object.keys(basicFontWeightOptions)
                .map((fontKey: keyof TFontWeightOptions) => ({
                    value: fontKey as string,
                    label: fontKey as string,
                    group: translate(
                        `fonts.fontGroup.${IFontFamilyGroup.BasicFonts}`
                    ),
                }))
                .sort((a, b) => (a.label > b.label ? 1 : -1));

            fontFamilyOptions.push(
                ...[...customFontFamilyOptions, ...basicFontFamilyOptions]
            );
            const fontSizeOptions = getFontSizeOptions();

            const customFontWeightOptions = customFonts?.reduce(
                (acc, curVal) => {
                    const currentEntry = acc[curVal.family];

                    if (currentEntry) {
                        const fontWeight = FONT_WEIGHTS[curVal.weight] || null;

                        if (!fontWeight) return acc;

                        return {
                            ...acc,
                            [curVal.family]: [
                                ...currentEntry,
                                getFontWeightOption(fontWeight),
                            ].filter(Boolean),
                        };
                    }

                    const fontWeight = FONT_WEIGHTS[curVal.weight] || null;

                    return {
                        ...acc,
                        [curVal.family]: [
                            getFontWeightOption(fontWeight),
                        ].filter(Boolean),
                    };
                },
                {} as TFontWeightOptions
            );

            const fontWeightOptions = {
                ...basicFontWeightOptions,
                ...customFontWeightOptions,
            };

            return {
                fontFamilyOptions,
                fontSizeOptions,
                fontWeightOptions,
            };
        }, [basicFontWeightOptions, customFonts]);

    return (
        <>
            {(!basicFontsIsSet || loading) && (
                <Box height="200px">
                    <Loader />
                </Box>
            )}

            {basicFontsIsSet &&
                !loading &&
                children(fontFamilyOptions, fontSizeOptions, fontWeightOptions)}
        </>
    );
};
