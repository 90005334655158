// import { Box } from '@mui/material';
import { BackgroundImg } from 'common/components/Img';

interface IProps {
    src: string;
}

export const TableImage = ({ src }: IProps) => (
    <BackgroundImg
        src={src}
        sx={{
            width: 'auto',
            minHeight: { xs: 50, sm: 79 },
            minWidth: { xs: 70, sm: 130 },
            display: 'block',
            borderRadius: 1,
            backgroundColor: 'primary.main',
            backgroundPosition: 'center',
        }}
    />
);
