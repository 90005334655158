import { matchRoutes, useLocation } from 'react-router-dom';

import { APP_ROUTES } from 'route/components/Router';

export const useCurrentPath = () => {
    const location = useLocation();
    const currentRoutes = matchRoutes(APP_ROUTES, location);

    if (!currentRoutes) return null;

    return [...currentRoutes].pop();
};
