import { formatDate } from 'common/utils/formatDate';
import { translate } from 'utils/i18n';

export function getLTIPlatformDateDisplay(startDate: string, endDate: string) {
    if (!startDate && !endDate) return '';

    const dateFormat = 'd MMMM yyyy';

    if (startDate && endDate) {
        const dash = startDate && endDate ? '- ' : '';

        return `${startDate ? formatDate(startDate, dateFormat) : ''} ${
            endDate ? `${dash} ${formatDate(endDate, dateFormat)}` : ''
        }`;
    }

    if (startDate) {
        return `${translate('from').toLowerCase()} ${formatDate(
            startDate,
            dateFormat
        )}`;
    }

    return `${translate('until').toLowerCase()} ${formatDate(
        endDate,
        dateFormat
    )}`;
}
