import { Field, useFormikContext } from 'formik';

import {
    IFilterFormItem,
    IFilterFormField,
    TFilterFormValues,
    TFilterFormValue,
} from 'common/types';

interface IProps extends IFilterFormItem {
    children?: (data: {
        fields: IFilterFormField[];
        renderField: (field: IFilterFormField) => React.ReactNode;
    }) => React.ReactNode;
}

export const FilterSectionFields = ({
    fields,
    onChangeField,
    children,
}: IProps) => {
    const formikContext = useFormikContext<TFilterFormValues>();

    const renderField = (field: IFilterFormField) => {
        if (!field.component) return null;

        return (
            <Field
                component={field.component}
                key={field.name}
                label={field.label}
                name={field.name}
                onChange={(name: string, value: TFilterFormValue) => {
                    onChangeField?.(name, value, field, formikContext);
                    field.onChange?.(value, formikContext);
                    formikContext.setFieldValue(name, value);
                }}
                {...field.componentProps}
            />
        );
    };

    const renderFields = fields.map(renderField).filter(Boolean);

    // If children are passed, call them as function
    if (children) return <>{children({ fields, renderField })}</>;

    return <>{renderFields}</>;
};
