import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { mdiPlus } from '@mdi/js';
import { useNavigate, useLocation } from 'react-router-dom';

import { IAppPermissionsSkill, IFeature } from 'graphql/types';
import { useFeature } from 'common/hooks/useFeature';
import { Typography } from 'common/components/Typography';
import { ActionButton } from 'common/components/ActionButton';
import { SkillsManagementTable } from 'skill/components/SkillsManagementTable';
import { PageTitle } from 'common/components/PageTitle';
import { Icon } from 'common/components/Icon';
import { FourOFourPage } from 'hydra/pages/FourOFourPage';
import { UpgradePage } from 'common/components/UpgradePage/UpgradePage';
import { getUrl } from 'route/utils/getUrl';
import { UpgradeContent } from 'skill/components/UpgradeContent';
import { useAppPermissions } from 'user/hooks/usePermissions';

import { BasePage } from './BasePage';

export const SkillsManagementPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [translate] = useTranslation();

    const {
        canUse: canUseSkill,
        userCanSeeUpgrade,
        loading: loadingFeature,
    } = useFeature(IFeature.Skill);
    const {
        canCreate = false,
        canUpdate = false,
    }: IAppPermissionsSkill['management'] = useAppPermissions(
        'skill',
        'management'
    );

    const canManage = canCreate && canUpdate;

    const showUpgradePage = !canUseSkill && userCanSeeUpgrade;

    // User cannot use skill module and cannot see upgrade page
    if ((!loadingFeature && !canUseSkill && !userCanSeeUpgrade) || !canManage) {
        return <FourOFourPage />;
    }

    return (
        <BasePage contentContainerMaxWidth="lg">
            <PageTitle>
                {translate('skillsManagementPage.browserTitle')}
            </PageTitle>

            {!showUpgradePage ? (
                <>
                    <Box sx={{ pt: 4 }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs>
                                <Typography variant="h1">
                                    {translate(
                                        'skillsManagementPage.pageTitle'
                                    )}
                                </Typography>
                            </Grid>

                            <Grid item xs="auto">
                                <ActionButton
                                    outlined
                                    variant="extended"
                                    onClick={() => {
                                        const searchParams =
                                            new URLSearchParams(
                                                location.search
                                            );

                                        const url = `${getUrl('SKILL_CREATE')}?${searchParams}`;

                                        navigate(url);
                                    }}
                                >
                                    <Box>
                                        <Icon path={mdiPlus} size="2.4rem" />
                                    </Box>

                                    {translate('skillsManagementPage.newSkill')}
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Prevent unnecessary query by the table when user can not manage */}
                    <Box py={4}>{canManage && <SkillsManagementTable />}</Box>
                </>
            ) : (
                <UpgradePage>
                    <UpgradeContent />
                </UpgradePage>
            )}
        </BasePage>
    );
};
