import { Field, useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { Select, Switch, TextField } from 'common/components/FormField';
import { IConditionEffect, IUpdateModuleGroupInput } from 'graphql/types';
import { getConditionOffsetTypeTranslation } from 'training/utils/moduleGroup';
import { Link } from 'common/components/Link';
import { FormSection } from 'common/components/FormSection';
import { CONDITION_OFFSET_TYPE_LIST } from 'training/constants/moduleGroup';
import { IConditionType } from 'training/types';

interface IProps {
    disabled?: boolean;
}

export const ConditionRelativeFormFields = ({ disabled }: IProps) => {
    const [translate] = useTranslation();
    const { values, setFieldValue } =
        useFormikContext<IUpdateModuleGroupInput>();

    const transKeyPrefix = 'moduleGroupForm.relativeCondition';

    return (
        <>
            <FormSection
                defaultExpanded
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey={`${transKeyPrefix}.preConditionSectionDescription`}
                        />
                    </Box>
                }
                title={translate(`${transKeyPrefix}.preConditionSectionTitle`)}
            >
                <Grid container spacing={2}>
                    <Grid item sm={2} xs={4}>
                        <Field
                            required
                            component={TextField}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.startOffset`)}
                            name="relativeCondition.startOffset.amount"
                        />
                    </Grid>

                    <Grid item sm={10} xs={8}>
                        <Field
                            component={Select}
                            disabled={disabled}
                            name="relativeCondition.startOffset.type"
                            options={CONDITION_OFFSET_TYPE_LIST.map(
                                (conditionOffsetType) => ({
                                    value: conditionOffsetType,
                                    label: getConditionOffsetTypeTranslation(
                                        conditionOffsetType,
                                        IConditionType.Relative
                                    ),
                                })
                            )}
                        />
                    </Grid>
                </Grid>

                <Box mt={1}>
                    <Field
                        checked={!!values.relativeCondition?.sendReminder}
                        component={Switch}
                        disabled={disabled}
                        formControlProps={{
                            margin: 'none',
                        }}
                        label={translate(`${transKeyPrefix}.emailNotification`)}
                        name="relativeCondition.sendReminder"
                    />
                </Box>

                <Box sx={{ mb: -1 }}>
                    <Field
                        checked={
                            values.relativeCondition?.preConditionEffect ===
                            IConditionEffect.Hide
                        }
                        component={Switch}
                        disabled={disabled}
                        formControlProps={{
                            margin: 'none',
                        }}
                        label={translate('moduleGroupForm.hidden')}
                        name="relativeCondition.preConditionEffect"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFieldValue(
                                'relativeCondition.preConditionEffect',
                                event.target.checked
                                    ? IConditionEffect.Hide
                                    : IConditionEffect.Lock
                            );
                        }}
                    />
                </Box>
            </FormSection>

            <FormSection
                description={
                    <Box>
                        <Trans
                            components={{
                                a: <Link rel="noreferrer" underline="always" />,
                            }}
                            i18nKey={`${transKeyPrefix}.postConditionSectionDescription`}
                        />
                    </Box>
                }
                title={translate(`${transKeyPrefix}.postConditionSectionTitle`)}
            >
                <Grid container spacing={2}>
                    <Grid item sm={2} xs={4}>
                        <Field
                            component={TextField}
                            disabled={disabled}
                            label={translate(`${transKeyPrefix}.deadline`)}
                            name="relativeCondition.deadlineOffset.amount"
                        />
                    </Grid>

                    <Grid item sm={10} xs={8}>
                        <Field
                            component={Select}
                            disabled={disabled}
                            name="relativeCondition.deadlineOffset.type"
                            options={CONDITION_OFFSET_TYPE_LIST.map(
                                (conditionOffsetType) => ({
                                    value: conditionOffsetType,
                                    label: getConditionOffsetTypeTranslation(
                                        conditionOffsetType,
                                        IConditionType.Relative
                                    ),
                                })
                            )}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ mb: -1, mt: 1 }}>
                    <Field
                        checked={
                            values.relativeCondition?.postConditionEffect ===
                            IConditionEffect.Hide
                        }
                        component={Switch}
                        disabled={disabled}
                        formControlProps={{
                            margin: 'none',
                        }}
                        label={translate('moduleGroupForm.hidden')}
                        name="relativeCondition.postConditionEffect"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFieldValue(
                                'relativeCondition.postConditionEffect',
                                event.target.checked
                                    ? IConditionEffect.Hide
                                    : IConditionEffect.Lock
                            );
                        }}
                    />
                </Box>
            </FormSection>
        </>
    );
};
