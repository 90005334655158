import MenuItem from '@mui/material/MenuItem';
import { Check, ChevronRight } from '@mui/icons-material';
import { styled } from 'styled-components';

import { DropdownMenu } from 'common/components/DropdownMenu';
import { TFilterBarItem } from 'common/types';

const SelectedIcon = styled(Check)`
    margin-left: auto;
    padding-left: 5px;
`;

interface IProps {
    item: TFilterBarItem;
    selected: TFilterBarItem[];
    parent?: TFilterBarItem;
    onClick: (item: TFilterBarItem, parent?: TFilterBarItem) => void;
}

export const FilterBarMenuItem = ({
    item,
    selected,
    parent,
    onClick,
}: IProps) => {
    const isSelected = selected.find(
        ({ value, parent }) => value === item.value && parent === item.parent
    );

    if (item.children && item.children.length) {
        return (
            <DropdownMenu
                anchor={
                    <MenuItem>
                        {item.displayName || item.name}
                        <SelectedIcon as={ChevronRight} fontSize="small" />
                    </MenuItem>
                }
                key={`${item.parent}-${item.value}`}
                placement="left-start"
            >
                {item.children.map((child) => (
                    <FilterBarMenuItem
                        item={child}
                        key={`${child.parent}-${child.value}`}
                        parent={item}
                        selected={selected}
                        onClick={onClick}
                    />
                ))}
            </DropdownMenu>
        );
    }

    const handleOnClick = () => {
        onClick(item, parent);
    };

    return (
        <MenuItem onClick={handleOnClick}>
            {item.displayName || item.name}
            {isSelected && <SelectedIcon fontSize="small" />}
        </MenuItem>
    );
};
